import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  SaveInvoiceFulfilled,
  SaveInvoicePending,
  SaveInvoiceRejected,
  getInvoiceSettingsFulfilled,
  getKycSettingsFulfilled,
  getKycSettingsPending,
  getKycSettingsRejected,
  getOrganizationDetailsFulfilled,
  getOrganizationDetailsPending,
  getOrganizationDetailsRejected,
  getOrganizationPublicFulfilled,
  getOrganizationPublicPending,
  getOrganizationPublicRejected,
  getShipmentSettingsFulfilled,
  saveKycSettingsFulfilled,
  saveKycSettingsPending,
  saveOrganizationFulfilled,
  saveOrganizationRejected,
  saveShipmentSettingFulfilled,
  saveShipmentSettingPending,
  saveShipmentSettingRejected,
  updateOrganizationDetailsFulfilled,
  updateOrganizationDetailsPending,
  updateOrganizationDetailsRejected,
  getTaxInfoListFulfilled,
  pending,
  rejected,
} from './case';
import { OrganizationSetupStateType } from './Type';
import {
  saveInvoiceSettings,
  SaveKycSettings,
  SaveOrganization,
  getKycOrgSettings,
  getInvoiceSettings,
  getShipmentSettings,
  saveShipmentSettings,
  getOrganizationPublic,
  getOrganizationDetails,
  updateOrganizationDetails,
  getTaxInfoList,
} from './actions';
import { countryStateType } from '@Reducers/Global/Type';

const initialState = <OrganizationSetupStateType>{
  loading: false,
  kycSetting: null,
  invoiceSetting: null,
  shipmentSetting: null,
  shipmentSettingSuccess: false,
  resetSaveOrgSuccess: false,
  kycSettingsSuccess: false,
  saveInvoiceSuccess: false,
  organizationData: null,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  taxList: [],
  documentList: ['Passport'],
  savedOrgRes: null,
  organizationPublicData: null,
  orgNotFound: false,
  orgCountryObj: null,
};

// const taxList: string[] = useMemo(() => {
//   let list: string[] = [],
//     obj: countryStateType | undefined;
//   if (country && countries.length) {
//     obj = countries?.find(i => i.name === country);
//     list = obj?.taxInfoRequirements ?? [];
//   }
//   return list;
// }, [country, countries]);

const orgSetupSlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetSaveOrg: (state: OrganizationSetupStateType) => {
      state.resetSaveOrgSuccess = false;
      state.savedOrgRes = null;
    },
    resetOrgError: (state: OrganizationSetupStateType) => {
      state.error = initialState.error;
    },
    resetKycSettings: (state: OrganizationSetupStateType) => {
      state.kycSettingsSuccess = false;
    },
    resetSaveInvoiceSettings: (state: OrganizationSetupStateType) => {
      state.saveInvoiceSuccess = false;
    },
    resetShipmentSettings: (state: OrganizationSetupStateType) => {
      state.shipmentSettingSuccess = false;
    },
    setOrgCountry: (state: OrganizationSetupStateType, action: PayloadAction<countryStateType>) => {
      state.orgCountryObj = action.payload || null;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(SaveOrganization.pending, pending);
    addCase(SaveOrganization.fulfilled, saveOrganizationFulfilled);
    addCase(SaveOrganization.rejected, saveOrganizationRejected);

    addCase(SaveKycSettings.pending, saveKycSettingsPending);
    addCase(SaveKycSettings.fulfilled, saveKycSettingsFulfilled);
    addCase(SaveKycSettings.rejected, rejected);

    addCase(getKycOrgSettings.pending, getKycSettingsPending);
    addCase(getKycOrgSettings.fulfilled, getKycSettingsFulfilled);
    addCase(getKycOrgSettings.rejected, getKycSettingsRejected);

    addCase(getOrganizationDetails.pending, getOrganizationDetailsPending);
    addCase(getOrganizationDetails.fulfilled, getOrganizationDetailsFulfilled);
    addCase(getOrganizationDetails.rejected, getOrganizationDetailsRejected);

    addCase(updateOrganizationDetails.pending, updateOrganizationDetailsPending);
    addCase(updateOrganizationDetails.fulfilled, updateOrganizationDetailsFulfilled);
    addCase(updateOrganizationDetails.rejected, updateOrganizationDetailsRejected);

    addCase(saveInvoiceSettings.pending, SaveInvoicePending);
    addCase(saveInvoiceSettings.fulfilled, SaveInvoiceFulfilled);
    addCase(saveInvoiceSettings.rejected, SaveInvoiceRejected);

    addCase(getInvoiceSettings.pending, getKycSettingsPending);
    addCase(getInvoiceSettings.fulfilled, getInvoiceSettingsFulfilled);
    addCase(getInvoiceSettings.rejected, getKycSettingsRejected);

    addCase(getShipmentSettings.pending, getKycSettingsPending);
    addCase(getShipmentSettings.fulfilled, getShipmentSettingsFulfilled);
    addCase(getShipmentSettings.rejected, getKycSettingsRejected);

    addCase(saveShipmentSettings.pending, saveShipmentSettingPending);
    addCase(saveShipmentSettings.fulfilled, saveShipmentSettingFulfilled);
    addCase(saveShipmentSettings.rejected, saveShipmentSettingRejected);

    addCase(getOrganizationPublic.pending, getOrganizationPublicPending);
    addCase(getOrganizationPublic.fulfilled, getOrganizationPublicFulfilled);
    addCase(getOrganizationPublic.rejected, getOrganizationPublicRejected);

    addCase(getTaxInfoList.pending, pending);
    addCase(getTaxInfoList.fulfilled, getTaxInfoListFulfilled);
    addCase(getTaxInfoList.rejected, rejected);
  },
});

export {
  SaveOrganization,
  SaveKycSettings,
  saveInvoiceSettings,
  getKycOrgSettings,
  getInvoiceSettings,
  saveShipmentSettings,
  getShipmentSettings,
  getOrganizationPublic,
  getOrganizationDetails,
  updateOrganizationDetails,
  getTaxInfoList,
};
export const {
  resetSaveOrg,
  resetKycSettings,
  resetSaveInvoiceSettings,
  resetShipmentSettings,
  resetOrgError,
  setOrgCountry,
} = orgSetupSlice.actions;
export default orgSetupSlice.reducer;
