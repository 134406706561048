import React, { useContext } from 'react';

import { MenuContext } from '../../context/Menu';
import Image from '../Image';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { useNavigate } from 'react-router-dom';

const openMenuLogoStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // paddingTop: '12px',
  // paddingBottom: '22px',
  marginTop: '24px',
  cursor: 'pointer',
};
const closeMenuLogoStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // paddingTop: '12px',
  // paddingBottom: '22px',
  marginTop: '24px',
  cursor: 'pointer',
};

const LogoImg = styled('div')`
  display: flex;
  flex: 1;
  padding: 10px;
  cursor: pointer;
  height: 140px;
  width: 210px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

function Logo() {
  const { isMenuOpen } = useContext(MenuContext);
  const containerStyle = isMenuOpen ? openMenuLogoStyle : closeMenuLogoStyle;
  const orgPublicData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { logo = '', name = '' } = orgPublicData || {};
  const navigate = useNavigate();

  return (
    <div style={containerStyle}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {isMenuOpen ? (
          <LogoImg onClick={() => navigate('/')} style={{ backgroundImage: `url("${logo}")` }} />
        ) : (
          <Image src={logo} alt={name} width={43} height={44} />
        )}
      </div>
    </div>
  );
}

export default Logo;

// <Image
//   src={logo}
//   alt={name}
//   height={140}
//   width={211}
//   // style={{ aspectRatio: '17 16' }}
// />
