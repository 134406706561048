import React, { useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SbTextField from '../../../../../components/Textfield';
import { BoxButton } from '../../../../GetRatesV2/StyleComponent';
import { BoxPropsType } from '../../Type';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CreateShipmentContext from '../../../../../context/CreateShipment';
import { BoxType, createShipmentContextType } from '../../../../../types/shipment';
import updateBoxId from '@Services/updateBoxId';
import { AddressType } from '@Reducers/GetRates/Type';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getChargeableWeight } from '@Reducers/GetRates/actions';
import { SHIPMENT_TYPES } from '../../../../GetRatesV2/Constant';

interface BoxDetailsPropsType extends BoxPropsType {
  setCopyBox: (copy: boolean) => void;
  isSelected: boolean;
}

function getAddressObj({ pincode, city, country, state }: AddressType) {
  return { city, state, country, pincode };
}

function CopyBox({ setCopyBox, isSelected, boxData }: BoxDetailsPropsType) {
  const { boxes, setBoxes, shipmentType } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const dispatch = useDispatch<AppDispatch>();

  const [count, setCount] = useState<number>(0);

  // useEffect(() => {
  //   setCount(boxData?.boxCopyCount ?? 0);
  // }, [boxData?.boxCopyCount]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value) setCount(parseInt(value));
    else setCount(0);
  }

  function handleAction(type: string) {
    const countTemp = type === 'remove' ? count - 1 : count + 1;
    setCount(countTemp);
  }
  function handleConfirm() {
    const selectedBoxToCopy = boxData;
    if (selectedBoxToCopy === null) return;
    // const selectedBoxInd = boxesTemp.findIndex(box => box.id === selectedBoxToCopy.id);
    // boxesTemp[selectedBoxInd].boxCopyCount = count;

    const arr = updateBoxId<BoxType>(
      boxes,
      {
        length: selectedBoxToCopy.length,
        width: selectedBoxToCopy.width,
        height: selectedBoxToCopy.height,
        weight: selectedBoxToCopy.weight,
        products: selectedBoxToCopy.products,
        boxCopyCount: selectedBoxToCopy.boxCopyCount,
        id: selectedBoxToCopy.id,
      },
      count,
    );
    setBoxes([...arr]);
    setCopyBox(false);
    if (shipmentById?.shipmentNumber) {
      const obj = {
        destination: { ...getAddressObj(shipmentById?._consigneeId) },
        source: { ...getAddressObj(shipmentById?._shipperId) },
        package: {
          box: arr.map(box => ({
            weight: box.weight.toString(),
            length: Number(box.length),
            width: Number(box.width),
            height: Number(box.height),
          })),
          unitOfMeasurement: shipmentType.unit,
        },
        document: shipmentType.type === SHIPMENT_TYPES[1].value,
        _courierId: shipmentById?.sellingPricing?._courierId,
        shipmentEdit: true,
        inbound: shipmentById?.sellingPricing?.inbound,
        outbound: shipmentById?.sellingPricing?.outbound,
      };
      dispatch(getChargeableWeight({ ...obj }));
    }
  }

  return (
    <>
      <Grid container item style={{ height: '94px' }}>
        <Grid container justifyContent="center" columnSpacing={2}>
          <Grid item xs={12} textAlign={'center'}>
            <Typography className="sm-text">No. of boxes to duplicate</Typography>
          </Grid>
          <Grid item container alignItems={'center'} justifyContent={'center'}>
            <IconButton onClick={() => handleAction('remove')} disabled={count <= 0}>
              <RemoveCircleIcon />
            </IconButton>
            <Grid item xs={3.5}>
              <SbTextField
                inputClassName="rounded-xs input-height-small"
                type="number"
                value={count}
                onChange={handleChange}
              />
            </Grid>
            <IconButton onClick={() => handleAction('add')}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" columnSpacing={1}>
        <Grid item xs={6}>
          <BoxButton onClick={() => setCopyBox(false)} className={isSelected ? 'isSelected' : ''}>
            <CloseIcon />
            Cancel
          </BoxButton>
        </Grid>
        <Grid item xs={6}>
          <BoxButton onClick={handleConfirm} disabled={count < 0} className={isSelected ? 'isSelected' : ''}>
            <CheckIcon />
            Confirm
          </BoxButton>
        </Grid>
      </Grid>
    </>
  );
}

export default CopyBox;
