// calculate Image Height, Width
interface Params {
  maxH?: number;
  minH?: number;
  maxW?: number;
  minW?: number;
  widthMulti?: number;
  heightMulti?: number;
  imgH?: number;
  imgW?: number;
}

export function calculateImageHeightAndWidth(params: Params) {
  const { imgH = 0, imgW = 0 } = params;
  const { maxH = 100, minH = 50, maxW = 250, minW = 150, widthMulti = 150, heightMulti = 100 } = params;

  //   Image ratio
  const ratio = imgW / imgH;

  let newImgH = 200 / ratio;
  let newImgW = widthMulti * ratio;

  //   check image height
  if (imgW > imgH && (newImgW > maxW || newImgW < minW)) {
    let heightRatio = 1;
    if (newImgW > maxW) {
      newImgW = maxW;
      heightRatio = maxH;
    } else if (newImgW < minW) {
      newImgW = minW;
      heightRatio = minH;
    }

    const newWidthMulti = newImgW / ratio;

    const widthMultiPer = (newWidthMulti / widthMulti) * 100;
    newImgH = (widthMultiPer / heightRatio) * 100;
  }

  //   check image width
  if (imgH > imgW && (newImgH > maxH || newImgH < minH)) {
    let widthRatio = 1;
    if (newImgH > maxW) {
      newImgH = maxH;
      widthRatio = maxW;
    } else if (newImgH < minH) {
      newImgW = minH;
      widthRatio = minW;
    }

    const newHeightMulti = newImgW / ratio;
    const heightMultiPer = (newHeightMulti / heightMulti) * 100;
    newImgW = (heightMultiPer / widthRatio) * 100;
  }
  return { newImgH, newImgW };
}
