import { FormLabel, Grid, SelectChangeEvent, Tab } from '@mui/material';
import React, { useMemo, useState } from 'react';

import Title from '@Components/Title';

import Contact from '@Components/Contact';
import Divider from '@Components/Divider';
import SBDropdown from '@Components/Dropdown';
import SBCountryDropdown from '@Components/Dropdown/Country';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import GoogleMaps from '@Components/Location';
import SbTextField from '@Components/Textfield';
import { ExtraDetailsShipperConsignee, UserType } from '@Reducers/AdminShipment/Type';
import { getFormattedLocation } from '@Services/formateLocation';
import { RootStateType } from '@Store';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues, useFormik } from 'formik';
import { useSelector } from 'react-redux';

import useScroll from '../useScroll';
import Schema from '../Schema';

type TabValues = 'shipper' | 'consignee';

const TABS: { label: string; value: TabValues }[] = [
  { label: 'Shipper', value: 'shipper' },
  { label: 'Consignee', value: 'consignee' },
];

const initialValues = {
  phone: {
    telephoneCode: '',
    number: '',
  },
  alternatePhone: {
    name: '',
    telephoneCode: '',
    number: '',
  },
  name: '',
  email: '',
  companyName: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
  type: '',
  IdType: '',
  IdNumber: '',
};

function ShipperConsignee({
  shipperFormikRef,
  consigneeFormikRef,
}: {
  shipperFormikRef: { current: object | null };
  consigneeFormikRef: { current: object | null };
}) {
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const { shipperConsigneeRef } = useScroll();

  const [currentTap, setCurrentTap] = useState<TabValues>(TABS[0].value);
  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    setCurrentTap(currentTab);
  };

  const _shipper = shipmentById?._shipperId;
  const _consignee = shipmentById?._consigneeId;
  const _shipperExtraDetail = shipmentById?.extraDetails?.shipper;
  const _consigneeExtraDetail = shipmentById?.extraDetails?.consignee;

  const shipperFormik = useFormik({
    enableReinitialize: true,
    initialValues: _shipper ? { ..._shipper, ...(_shipperExtraDetail || {}) } : { ...initialValues },
    validationSchema: Schema.ShipperConsignee,
    onSubmit(_values) {
      console.log('submit');
    },
  });

  const consigneeFormik = useFormik({
    enableReinitialize: true,
    initialValues: _consignee ? { ..._consignee, ...(_consigneeExtraDetail || {}) } : { ...initialValues },
    validationSchema: Schema.ShipperConsignee,
    onSubmit(_values) {
      console.log('submit');
    },
  });

  shipperFormikRef.current = shipperFormik;
  consigneeFormikRef.current = consigneeFormik;
  return (
    <>
      {/* <TabPanel value="shipper_consignee" className="p-0 w-full"> */}
      <Grid container mt={4} mb={2} ref={shipperConsigneeRef}>
        <Title title="Shipper/Consignee Details" removeBorder removePadding />
      </Grid>
      <Grid container p={4} pt={1} className="mb-3 border border-solid border-light rounded">
        <TabContext value={currentTap}>
          <Grid container mb={3}>
            <TabList
              onChange={onTabChange}
              indicatorColor="primary"
              TabIndicatorProps={{ sx: { height: '4px' } }}
              scrollButtons="auto"
              variant="scrollable"
            >
              {TABS.map(cTab => (
                <Tab
                  sx={{ textTransform: 'none', padding: '16px 16px' }}
                  className="md-text font-medium sb-text-black"
                  value={cTab.value}
                  label={cTab.label}
                  key={cTab.value}
                />
              ))}
            </TabList>
          </Grid>
          <TabPanel value="shipper" className="p-0 w-full">
            <UserInfo
              type="shipper"
              value={shipperFormik.values}
              handleChange={shipperFormik.handleChange}
              errors={shipperFormik.errors}
              setFieldValue={shipperFormik.setFieldValue}
            />
            <Divider className="mt-3 mb-3" />
            <Address
              type="shipper"
              value={shipperFormik.values}
              handleChange={shipperFormik.handleChange}
              errors={shipperFormik.errors}
              setFieldValue={shipperFormik.setFieldValue}
            />

            <Divider className="mt-3 mb-3" />
            <AlternateContact
              type="shipper"
              value={shipperFormik.values}
              handleChange={shipperFormik.handleChange}
              errors={shipperFormik.errors}
              setFieldValue={shipperFormik.setFieldValue}
            />
            <Divider className="mt-3 mb-3" />
            <ShipmentExtraInfo
              type="shipper"
              value={shipperFormik.values}
              handleChange={shipperFormik.handleChange}
              errors={shipperFormik.errors}
              setFieldValue={shipperFormik.setFieldValue}
            />
          </TabPanel>
          <TabPanel value="consignee" className="p-0 w-full">
            <UserInfo
              type="consignee"
              value={consigneeFormik.values}
              handleChange={consigneeFormik.handleChange}
              errors={consigneeFormik.errors}
              setFieldValue={consigneeFormik.setFieldValue}
            />
            <Divider className="mt-3 mb-3" />
            <Address
              type="consignee"
              value={consigneeFormik.values}
              handleChange={consigneeFormik.handleChange}
              errors={consigneeFormik.errors}
              setFieldValue={consigneeFormik.setFieldValue}
            />

            <Divider className="mt-3 mb-3" />
            <AlternateContact
              type="consignee"
              value={consigneeFormik.values}
              handleChange={consigneeFormik.handleChange}
              errors={consigneeFormik.errors}
              setFieldValue={consigneeFormik.setFieldValue}
            />
            <Divider className="mt-3 mb-3" />
            <ShipmentExtraInfo
              type="consignee"
              value={consigneeFormik.values}
              handleChange={consigneeFormik.handleChange}
              errors={consigneeFormik.errors}
              setFieldValue={consigneeFormik.setFieldValue}
            />
          </TabPanel>
        </TabContext>
      </Grid>
    </>
    // {/* </TabPanel> */}
  );
}

interface FormikType {
  type: 'shipper' | 'consignee';
  value: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<UserType & ExtraDetailsShipperConsignee>['setFieldValue'];
  errors?: FormikErrors<UserType & ExtraDetailsShipperConsignee>;
}

function UserInfo(props: FormikType) {
  const { value: values, type, handleChange, errors } = props;
  const Label = type === 'shipper' ? 'Shipper' : 'Consignee';
  return (
    <Grid item container xs={12} alignItems="baseline" spacing={2}>
      <Grid item xs={12} md={2.5} lg={2}>
        <FormLabel className="md-text font-medium sb-text-black">{Label}</FormLabel>
      </Grid>
      <Grid container item xs={12} md={9.5} lg={10} columnSpacing={4} rowSpacing={2}>
        <Grid container item columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SbTextField
              required
              label={`${Label} Name`}
              name="name"
              value={values.name}
              onChange={handleChange}
              error={errors?.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              // required
              label="Company Name"
              name="companyName"
              value={values.companyName}
              onChange={handleChange}
              error={errors?.companyName}
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <Contact
              phoneName="phone.number"
              phoneNumber={values?.phone?.number || ''}
              onChangePhoneNumber={handleChange}
              label="Mobile No. *"
              telCountryCodeName="phone.telephoneCode"
              telCountryCode={values?.phone?.telephoneCode || ''}
              onChangeTelCountryCode={handleChange}
              error={errors?.phone?.number || errors?.phone?.telephoneCode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              label="Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              error={errors?.email}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
        <SBDropdown
          id="System"
          required
          label="System linked to this warehouse"
          options={[]}
          onChange={console.log}
          //   name="systemId"
          //   value={[values.systemId]}
          //   error={errors?.systemId}
        />
        
      </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
}

function Address(props: FormikType) {
  // const [reinitialize, setReinitialize] = useState<boolean>(true);

  const { value: values, handleChange, errors, setFieldValue } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleSelectedAddress(event: any) {
    const address = getFormattedLocation(event.address_components);

    // setReinitialize(false);
    if (setFieldValue) {
      setFieldValue('city', address.city);
      setFieldValue('state', address.state);
      setFieldValue('country', address.country);
      setFieldValue('pincode', address.pincode);
    }
  }
  return (
    <Grid item container xs={12} alignItems="baseline" spacing={2}>
      <Grid item xs={12} md={2.5} lg={2}>
        <FormLabel className="md-text font-medium sb-text-black">Address</FormLabel>
      </Grid>
      <Grid container item xs={12} md={9.5} lg={10} columnSpacing={4} rowSpacing={2}>
        <Grid item md={12} xs={12}>
          <GoogleMaps
            onSelect={handleSelectedAddress}
            label={'Location'}
            placeholder="Start typing to search for location"
            // reinitialize={reinitialize}
          />
        </Grid>
        <Grid container item columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SbTextField
              required
              label="Address line 1"
              name="line1"
              value={values.line1}
              error={errors?.line1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              label="Address line 2"
              name="line2"
              value={values.line2}
              error={errors?.line2}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SbTextField
              required
              label="Pincode"
              name="pincode"
              value={values.pincode}
              error={errors?.pincode}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              required
              label="City"
              name="city"
              value={values.city}
              onChange={handleChange}
              error={errors?.city}
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SbTextField
              label="State"
              name="state"
              value={values.state}
              onChange={handleChange}
              error={errors?.state}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBCountryDropdown
              value={values.country}
              onChange={([value]) => setFieldValue('country', value)}
              error={errors?.country}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function AlternateContact(props: FormikType) {
  const { value: values, handleChange, errors } = props;
  const alternatePhoneError = errors?.alternatePhone as UserType['alternatePhone'];

  return (
    <Grid item container xs={12} alignItems="baseline" spacing={2}>
      <Grid item xs={12} md={2.5} lg={2}>
        <FormLabel className="md-text font-medium sb-text-black">Alternate Contact</FormLabel>
      </Grid>
      <Grid container item xs={12} md={9.5} lg={10} columnSpacing={4} rowSpacing={2}>
        <Grid container item columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SbTextField
              label="Alternate Contact Name"
              name="alternatePhone.name"
              value={values?.alternatePhone?.name || ''}
              error={alternatePhoneError?.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Contact
              phoneName="alternatePhone.number"
              phoneNumber={values?.alternatePhone?.number || ''}
              onChangePhoneNumber={handleChange}
              label="Contact No"
              telCountryCodeName="alternatePhone.telephoneCode"
              telCountryCode={values?.alternatePhone?.telephoneCode || ''}
              onChangeTelCountryCode={handleChange}
              error={alternatePhoneError?.telephoneCode || alternatePhoneError?.number}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ShipmentExtraInfo(props: FormikType) {
  const { type, value, setFieldValue, handleChange } = props;
  const detailsFor = (type === 'shipper' && 'Shipper') || (type === 'consignee' && 'Consignee');
  const { shipmentIdTypeList } = useSelector((state: RootStateType) => state?.global);
  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const { exportShipmentId = [], personalShipmentId = [] } = shipmentSetting || {};

  const handleDropdownChange = (
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
    _child: React.ReactNode,
  ) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const typeOption = useMemo(() => {
    const options =
      value.type || personalShipmentId || exportShipmentId
        ? value.type === 'personalShipments'
          ? personalShipmentId
          : exportShipmentId
        : [];
    return options?.map(({ label, value }) => ({ display: label, value }));
  }, [value.type, exportShipmentId, personalShipmentId]);

  return (
    <Grid item container xs={12} alignItems="baseline" spacing={2}>
      <Grid item xs={12} md={2.5} lg={2}>
        <FormLabel className="md-text font-medium sb-text-black">{detailsFor} ID</FormLabel>
      </Grid>
      <Grid container item xs={12} md={9.5} lg={10} columnSpacing={4} rowSpacing={2}>
        <Grid container item columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              id="type-country-id"
              label={`${detailsFor} Type`}
              placeholder=""
              name="type"
              value={value.type ? [value.type] : []}
              options={shipmentIdTypeList}
              onChange={handleDropdownChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBDropdown
              disabled={!typeOption.length}
              id="IdType-country-id"
              label={detailsFor + ` Id Type`}
              placeholder=""
              name="IdType"
              value={value.IdType ? [value.IdType] : []}
              options={typeOption}
              onChange={handleDropdownChange}
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={4} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SbTextField
              label={detailsFor + ' Id value'}
              name="IdNumber"
              value={value.IdNumber}
              error={props.errors?.IdNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ShipperConsignee;
