import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import API from '../../../services/apiAxios';
import {
  SingleSystemResponse,
  SystemCreatePayload,
  SystemCreateResponse,
  SystemErrorType,
  SystemListQuery,
  ZonesWeightPricingResponse,
  zonesWeightPricingPayload,
} from './Type';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { ErrorType } from '../../../types';

const getSystemList = createAsyncThunk('getSystemList', async (queryParams?: SystemListQuery) => {
  const res = await API.get(`/organization/system/list?`, { params: queryParams });
  return res.data;
});

const getActiveSystemList = createAsyncThunk('getActiveSystemList', async () => {
  const res = await API.get(`/organization/system`);
  return res.data;
});

const getLoggedInUserSystemList = createAsyncThunk('getLoggedInUserSystemList', async () => {
  const res = await API.get(`/user/system`);
  return res.data;
});

const createSystem = createAsyncThunk<
  SystemCreateResponse,
  { payload: SystemCreatePayload } & SuccessErrorModalParamsType,
  { rejectValue: ErrorType }
>('createSystem', async ({ payload, showModal, navigate, zones }, { dispatch, rejectWithValue }) => {
  try {
    const res = await API.post(`/organization/system`, payload);
    const systemId = res?.data?.data?._id;
    if (navigate && systemId) {
      const systemState = encodeURIComponent(payload.address.state);
      navigate += `?systemId=${systemId}&systemState=${systemState}`;
    }
    if (showModal)
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: 'System successfully updated.',
          navigateTo: navigate || null,
        }),
      );

    if (systemId && zones) {
      const ZonesWeightPricingPayload = {
        _systemId: systemId,
        zones,
      };
      dispatch(zonesWeightPricing({ payload: ZonesWeightPricingPayload }));
    }

    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType<SystemErrorType>> = e;
    if (!error.response) throw error;
    const errorRes = error.response.data;
    if (errorRes.data?.downloadURL) {
      window.open(errorRes.data?.downloadURL);
    }
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    return rejectWithValue(error.response.data);
  }
});

const zonesWeightPricing = createAsyncThunk<
  ZonesWeightPricingResponse,
  { payload: zonesWeightPricingPayload } & SuccessErrorModalParamsType,
  { rejectValue: ErrorType }
>('zonesWeightPricing', async ({ payload }, { dispatch, rejectWithValue }) => {
  try {
    const res = await API.post(`/shipment/squad/zone`, payload);

    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType<SystemErrorType>> = e;
    if (!error.response) throw error;
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    return rejectWithValue(error.response.data);
  }
});

const getSystemById = createAsyncThunk(
  'getSystemById',
  async ({ id, navigate }: { id: string } & SuccessErrorModalParamsType, { dispatch, rejectWithValue }) => {
    try {
      const res = await API.get(`/organization/system/${id}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;

      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
          navigateTo: navigate,
        }),
      );
      return rejectWithValue(error.response.data);
    }
  },
);

const updateSystemById = createAsyncThunk<
  SingleSystemResponse,
  { id: string; payload: SystemCreatePayload } & SuccessErrorModalParamsType,
  { rejectValue: ErrorType }
>('updateSystemById', async ({ id, payload, showModal, navigate, zones }, { dispatch, rejectWithValue }) => {
  try {
    const res = await API.put(`/organization/system/${id}`, payload);
    if (showModal)
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: 'System successfully updated.',
          navigateTo: navigate || null,
        }),
      );

    if (zones) {
      const ZonesWeightPricingPayload = {
        _systemId: id,
        zones,
      };
      dispatch(zonesWeightPricing({ payload: ZonesWeightPricingPayload }));
    }

    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
        // navigateTo: navigate || null,
      }),
    );
    return rejectWithValue(error.response.data);
  }
});

const downloadPincode = createAsyncThunk('system/pincode-download', async function (id: string) {
  const res = await API.get(`/organization/download/system/${id}`);

  window.open(res.data.data, '_blank');
});

export {
  getSystemList,
  createSystem,
  getSystemById,
  updateSystemById,
  getActiveSystemList,
  getLoggedInUserSystemList,
  downloadPincode,
  zonesWeightPricing,
};
