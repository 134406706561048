// export function generatePassword(length: number) {
//   const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//   const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
//   const specialChars = '!@#$%^&*';
//   const numberChars = '0123456789';

//   const allChars = uppercaseChars + lowercaseChars + specialChars + numberChars;

//   let password = '';
//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * allChars.length);
//     password += allChars.charAt(randomIndex);
//   }

//   return password;
// }

const specialChars = '!@#$%^&*';
const chars = 'abcdefghijklmnopqrstuvwxyz';

function newChar(lower: string[], upper: string[], nums: number[], specials: string[]) {
  const set = [lower, upper, nums, specials];
  const pick = set[Math.floor(Math.random() * set.length)];
  return pick[Math.floor(Math.random() * pick.length)];
}

export const generatePassword = (length: number) => {
  const lowercase = chars.split('');
  const uppercase = chars.toUpperCase().split('');
  const arg = specialChars.split('').filter(item => item.trim().length);
  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  let hasNumber = false;
  let hasUpper = false;
  let hasLower = false;
  let hasSpecial = false;

  if (Number(length)) {
    length = Number(length);
  } else {
    return console.error('Enter a valid length for the first argument.');
  }

  let password = [];
  let lastChar;
  for (let i = 0; i < length; i++) {
    const char = newChar(lowercase, uppercase, numbers, arg);
    if (char !== lastChar) {
      password.push(char);
      lastChar = char;
      if (Number(char)) {
        hasNumber = true;
      }
      if (lowercase.indexOf(char.toString()) > -1) {
        hasLower = true;
      }
      if (uppercase.indexOf(char.toString()) > -1) {
        hasUpper = true;
      }
      if (specialChars.indexOf(char.toString()) > -1) {
        hasSpecial = true;
      }
    } else {
      i--;
    }
    if (i === length - 1 && (!hasNumber || !hasUpper || !hasLower || !hasSpecial)) {
      hasNumber = false;
      hasUpper = false;
      hasLower = false;
      hasSpecial = false;
      password = [];
      i = -1;
    }
  }

  return password.join('');
};
