import * as Yup from 'yup';

const ShipmentUserSchema = Yup.object().shape({
  name: Yup.string().required('Please enter first name'),
  companyName: Yup.string(),
  line1: Yup.string().required('Please enter address'),
  line2: Yup.string(),
  city: Yup.string().required('Please enter city'),
  state: Yup.string().required('Please enter state'),
  country: Yup.string().required('Please enter country'),
  pincode: Yup.string().required('Please enter pincode'),
  phoneNumber: Yup.number().required('Please enter phone number'),
  telephoneCode: Yup.number().required('Please select telephone code'),
  altContactName: Yup.string(),
  altContactNo: Yup.string(),
  altContactTelephoneCode: Yup.string(),
  IdNumber: Yup.string(),
  IdType: Yup.string(),
  type: Yup.string(),
  email: Yup.string()
    .email('Please enter a valid email')
    .matches(/^[^\s@]{3,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
});

export default ShipmentUserSchema;
