import React from 'react';
import { Grid, Typography, styled } from '@mui/material';
import SBDropdown from '../../../components/Dropdown';

import { DropdownOptionType, DropdownPropsType } from '../../../components/Dropdown/Type';
import { HeaderType } from '../Type';

const matchColor = `#008B59`;
const unMatchColor = `#8b0000`;

const ContainerStyled = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  background: theme.palette.background.white,
  borderTopLeftRadius: theme.spacing(1.5),
  borderTopRightRadius: theme.spacing(1.5),
  width: 300,
  borderBottom: '1px solid #E0E0E0',
}));

interface HeaderPropsType extends HeaderType {
  dropDownOptions: DropdownOptionType[];
  onChange: DropdownPropsType['onChange'];
}

function Header(props: HeaderPropsType) {
  const { isMatchHeading, selectedValue = '', dropDownOptions, onChange } = props;
  return (
    <ContainerStyled>
      <Typography
        className="font-semibold md-text text-center"
        style={{ color: isMatchHeading ? matchColor : unMatchColor }}
      >
        {isMatchHeading ? 'Matched' : 'Un-Matched'}
      </Typography>
      <Grid container mt={2}>
        <SBDropdown
          required
          id={props.id}
          placeholder="Select"
          options={dropDownOptions}
          onChange={onChange}
          value={[selectedValue]}
        />
      </Grid>
    </ContainerStyled>
  );
}

export default Header;
