import { PayloadAction } from '@reduxjs/toolkit';
import { NotificationStateType, SuccessResponse } from './Type';

function handlePending(state: NotificationStateType) {
  state.loading = true;
}

function handleRejected(state: NotificationStateType) {
  state.loading = false;
}

function getNotificationFulfilled(state: NotificationStateType, action: PayloadAction<SuccessResponse>) {
  state.loading = false;
  state.notifications = action.payload.data[0].notifications || [];
  state.count = action.payload.data[0].metadata[0].total || 0;
}

function getReadNotificationFulfilled(state: NotificationStateType, action: PayloadAction<SuccessResponse>) {
  state.loading = false;
  state.readNotifications = action.payload.data[0].notifications;
  state.totalReadNotifications = action.payload.data[0].metadata[0].total || 0;
}

export { handlePending, handleRejected, getNotificationFulfilled, getReadNotificationFulfilled };
