import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppDispatch, RootStateType } from '../../../store';
import { TabContext, TabList } from '@mui/lab';
import AccountHolder from './AccountHolder';
import NonAccountHolder from './NonAccountHolder';
import { OutletBox } from '../Style';
import { KycSettingsType, SettingsTabsPropsType, TaxType, companyAccountHolderType } from '../../../types/organization';
import Actions from '../Actions';
import { SaveKycSettings, resetKycSettings } from '../../../store/reducers/OrganizationSetup';
import { getKycOrgSettings } from '../../../store/reducers/OrganizationSetup/actions';
import { kycSettingsDefaultData } from '../constants';
import Loader from '@Components/Loader';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

type TabValues = 'non_account_holder' | 'account_holder' | string;

interface TabAccessKeyType {
  isCompanyInfoShow: boolean;
}

type TabAccessKey = keyof TabAccessKeyType;

const TABS: { label: string; value: TabValues; accessKey?: TabAccessKey }[] = [
  { label: 'Account holder', value: 'account_holder' },
  { label: 'Non-Account holder', value: 'non_account_holder' },
];

const TAB_KEYS: TabValues[] = TABS.map(x => x.value);

const ProfileContainerStyle = styled(Grid)(({ theme: _ }) => ({
  background: '#fff',
  width: '100%',
  // maxHeight: '100vh',
}));

const TitleContentStyle = styled(Grid)(({ theme: _ }) => ({
  marginBottom: '24px',
}));

function KYCSettingTabs({ handleNext, handleBack, activeStep, viewFor }: SettingsTabsPropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();

  const { documentList, loading } = useSelector((state: RootStateType) => state.orgSetup);
  const accountState = useSelector((state: RootStateType) => state.loginUser.profile?._accountId);
  const { kycSettingsSuccess, kycSetting } = useSelector((state: RootStateType) => state?.orgSetup);

  const [currentTap, setCurrentTap] = useState<TabValues>('account_holder');
  const [companyAccountHolder, setCompanyAccountHolder] = useState<companyAccountHolderType>({
    ...kycSettingsDefaultData,
    companyDetailsMandatory: true,
  });
  const [individualAccountHolder, setIndividualAccountHolder] = useState<KycSettingsType>({
    ...kycSettingsDefaultData,
  });
  const [nonAccountHolder, setNonAccountHolder] = useState<KycSettingsType>({ ...kycSettingsDefaultData });

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const isAccountHolderReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.KYC_Settings.account_holder,
    PERFORM_ACTION.read,
  );
  const isNonAccountHolderReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.KYC_Settings.non_account_holder,
    PERFORM_ACTION.read,
  );

  const isAccountHolderWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.KYC_Settings.account_holder,
    PERFORM_ACTION.write,
  );
  const isNonAccountHolderWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.KYC_Settings.non_account_holder,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    const list: TaxType[] = [];
    const list1: TaxType[] = [];
    const list2: TaxType[] = [];
    documentList.map(i => {
      list.push({
        label: i,
        isRequired: false,
      });
      list1.push({
        label: i,
        isRequired: false,
      });
      list2.push({
        label: i,
        isRequired: false,
      });
    });
    setNonAccountHolder({
      ...nonAccountHolder,
      list: [...list],
    });
    setIndividualAccountHolder({
      ...individualAccountHolder,
      list: [...list1],
    });
    setCompanyAccountHolder({
      ...companyAccountHolder,
      list: [...list2],
    });
  }, [documentList]);

  useEffect(() => {
    const currentActiveTab: TabValues = searchParams.get('tab') || 'account_holder';
    if (TAB_KEYS.includes(currentActiveTab)) setCurrentTap(currentActiveTab);
    dispatch(getKycOrgSettings());
    // dispatch(getLoggedInUser());
  }, []);

  useEffect(() => {
    if (kycSettingsSuccess) {
      dispatch(resetKycSettings());
      if (viewFor === 'org-setup' && handleNext && activeStep)
        setTimeout(() => {
          handleNext(activeStep);
        }, 10);
    }
  }, [kycSettingsSuccess]);

  useEffect(() => {
    if (kycSetting) {
      const { companyAccountHolder, individualAccountHolder, nonAccountHolder } = kycSetting;
      setCompanyAccountHolder(companyAccountHolder);
      setIndividualAccountHolder(individualAccountHolder);
      setNonAccountHolder(nonAccountHolder);
    }
  }, [kycSetting]);

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    navigation(`?tab=${currentTab}`);
    setCurrentTap(currentTab);
  };

  function handleSubmit() {
    dispatch(
      SaveKycSettings({
        payload: { companyAccountHolder, individualAccountHolder, nonAccountHolder },
        showModal: !handleNext,
      }),
    );
    // handleNext(activeStep);
  }

  const tabAccessKey: TabAccessKeyType = { isCompanyInfoShow: !accountState?.isDefaultAccount };
  return (
    <Loader loading={loading} overly>
      <>
        <OutletBox className="border-grey rounded-md" removeBorder={viewFor === 'settings'}>
          <ProfileContainerStyle container alignContent="flex-start">
            <TitleContentStyle>
              <Typography className="font-semibold l-text mb-1">KYC Settings</Typography>
              <Typography className="d-block md-text"> Update your KYC settings for your users</Typography>
            </TitleContentStyle>
            <TabContext value={currentTap}>
              <Grid container sx={{ borderBottom: '1px solid #E4E4E4', marginBottom: '50px' }}>
                <TabList
                  onChange={onTabChange}
                  indicatorColor="primary"
                  TabIndicatorProps={{ sx: { height: '4px' } }}
                  scrollButtons="auto"
                  variant="scrollable"
                >
                  {TABS.filter(
                    cTab =>
                      (cTab.accessKey ? tabAccessKey[cTab.accessKey] : true) &&
                      ((cTab.value === 'account_holder' && isAccountHolderReadAllowed) ||
                        (cTab.value === 'non_account_holder' && isNonAccountHolderReadAllowed)),
                  ).map(cTab => (
                    <Tab
                      sx={{ textTransform: 'none', padding: '16px 16px' }}
                      className="md-text font-medium sb-text-black"
                      value={cTab.value}
                      label={cTab.label}
                      key={cTab.value}
                    />
                  ))}

                  {/* {TABS.filter(cTab => (cTab.accessKey ? tabAccessKey[cTab.accessKey] : true)).map(cTab => (
                    // <Link to={`/${cTab.value}`}  >
                    <>
                      <Tab
                        sx={{ textTransform: 'none', padding: '16px 16px' }}
                        className="md-text font-medium sb-text-black"
                        value={cTab.value}
                        label={cTab.label}
                        key={cTab.value}
                      />
                    </>
                    // </Link>
                  ))} */}
                </TabList>
              </Grid>
              {isAccountHolderReadAllowed && (
                <AccountHolder
                  accountHolder={individualAccountHolder}
                  onChangeAccountHolder={setIndividualAccountHolder}
                  company={companyAccountHolder}
                  onChangeCompany={setCompanyAccountHolder}
                />
              )}
              {isNonAccountHolderReadAllowed && (
                <NonAccountHolder nonAccountHolder={nonAccountHolder} onChangeNonAccountHolder={setNonAccountHolder} />
              )}
            </TabContext>
          </ProfileContainerStyle>
        </OutletBox>
        {(isAccountHolderReadAllowed || isNonAccountHolderReadAllowed) && (
          <Actions
            onNext={handleSubmit}
            onBack={() => (handleBack ? handleBack(activeStep || 0) : {})}
            disableNext={false}
            viewFor={viewFor}
            activeStep={activeStep}
            // disabled={!isAccountHolderWriteAllowed && !isNonAccountHolderWriteAllowed}
            disabled={
              (currentTap === 'account_holder' && !isAccountHolderWriteAllowed) ||
              (currentTap === 'non_account_holder' && !isNonAccountHolderWriteAllowed)
            }
          />
        )}
      </>
    </Loader>
  );
}

export default KYCSettingTabs;
