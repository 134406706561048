import { DropdownOptionType } from '../../components/Dropdown/Type';
import { AddressType } from '../../store/reducers/GetRates/Type';
import { BoxType, LengthUnitType, RatesInputErrorType, RatesInputType, WeightUnitType } from './Type';

interface LengthUnitDropdownType {
  display: string;
  value: LengthUnitType;
}

export const WEIGHT_UNITS: DropdownOptionType[] = [
  { display: 'kg', value: 'kg' },
  { display: 'lb', value: 'lb' },
];

export const UNIT_OF_MEASUREMENT = {
  'kg-cm': { value: 'kg-cm', dimensionUnit: 'cm', weightUnit: 'kg' },
  'lb-in': { value: 'lb-in', dimensionUnit: 'in', weightUnit: 'lb' },
};

export const LENGTH_UNITS: { [key: string]: LengthUnitDropdownType } = {
  kg: { display: 'cm', value: 'cm' },
  lb: { display: 'in', value: 'in' },
};

export const SHIPMENT_TYPES: DropdownOptionType[] = [
  { display: 'Parcel', value: 'parcel' },
  { display: 'Document', value: 'document' },
];

export const DefaultAddress: AddressType = {
  city: '',
  country: '',
  pincode: '',
  state: '',
};

export const DefaultRatesInput: RatesInputType = {
  shipmentType: SHIPMENT_TYPES[0].value,
  pickup: DefaultAddress,
  destination: DefaultAddress,
  pickupPinCode: '',
  destinationPinCode: '',
  weight: 0,
  weightUnit: WEIGHT_UNITS[0].value as WeightUnitType,
  // boxCount: 0,
};

export const DefaultRatesInputError: RatesInputErrorType = {
  shipmentType: '',
  pickup: '',
  destination: '',
  pickupPinCode: '',
  destinationPinCode: '',
  weight: '',
  weightUnit: '',
  // boxCount: '',
};

export const DefaultBoxState = {
  weight: '',
  length: '',
  width: '',
  height: '',
  boxCopyCount: 0,
};

export const defaultBox: BoxType = { length: 0, height: 0, width: 0, weight: 0, boxCopyCount: 0, id: '0' };

export const defaultBoxes: BoxType[] = [{ ...defaultBox }];
