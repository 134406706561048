import React from 'react';

import { Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Title from '../../components/Title';
import { Link, Outlet, useLocation } from 'react-router-dom';
import SbButton from '../../components/Button';
import Divider from '../../components/Divider';
import Loader from '../../components/Loader';
import { RootStateType } from '../../store';
import { useSelector } from 'react-redux';
import BackButton from '@Components/BackButton';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

function Warehouse() {
  const location = useLocation();
  const warehouseLoading = useSelector((state: RootStateType) => state.Warehouse.loading);
  const showCreateBtn = location.pathname === '/warehouse';

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const showBackButton = location.pathname === '/warehouse/create' || location.pathname.startsWith('/warehouse/edit/');
  const isCreateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Warehouse.create_warehouse,
    PERFORM_ACTION.write,
  );

  return (
    <Loader loading={warehouseLoading} overly>
      <>
        <Grid container alignItems="center">
          <Grid item xs={6} sx={{ display: 'flex', gap: '0.5rem' }}>
            {showBackButton && <BackButton link="/warehouse" />}
            <Title title="Warehouses" subTitle="View and edit your warehouses" removeBorder removePadding />
          </Grid>
          {showCreateBtn && (
            <Grid item xs={6} textAlign="end">
              <Link
                className={`${isCreateAllowed ? '' : 'cursor-default'}`}
                to={isCreateAllowed ? '/warehouse/create' : ''}
              >
                <SbButton
                  variant="contained"
                  className="ml-auto"
                  sx={{ borderRadius: '8px !important' }}
                  startIcon={<AddCircleIcon fontSize="small" />}
                  disabled={!isCreateAllowed}
                >
                  Create new
                </SbButton>
              </Link>
            </Grid>
          )}
        </Grid>
        <Divider style={{ margin: `calc(var(--mui-palette-spacing) * 3.5) 0` }} />
        <Outlet />
      </>
    </Loader>
  );
}

export default Warehouse;
