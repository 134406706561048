import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@Services/apiAxios';
import {
  createSquadPayload,
  getAllSquadQueryParams,
  SquadMemberPayload,
  uploadKycPayload,
  updateSquadPayload,
  zonePayload,
  TaskManagementPayload,
  getAllTaskQueryParams,
  squadReportsPayload,
  squadForAssignedTasksParams,
  assignTaskToSquadPayload,
  UnassignTaskToSquadPayload,
  squadRoutePayload,
  optimizeRoutePayload,
  SquadMembersPayload,
  manualOptimizePayload,
  updateRoutePayload,
  AdminWarehousePayload,
  BulkAssignTaskToSquadPayload,
} from './type';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';

// Helper function for error handling
const handleApiError = (error: any, dispatch: any) => {
  const errorRes = error.response?.data;
  dispatch(
    SuccessErrorModalOpen({
      type: 'error',
      title: 'Oops!',
      subTitle: errorRes?.msg || 'Something went wrong.',
    }),
  );
  throw error;
};

/* --------------------- -------------------------- Create Squad -------------------------------------------------- */
const createSquad = createAsyncThunk(
  'createSquad',
  async ({ postData }: { postData: createSquadPayload }, { dispatch }) => {
    try {
      const res = await API.post('squad/admin/on-board', postData);
      return res.data;
    } catch (error) {
      handleApiError(error, dispatch);
    }
  },
);

/* --------------------- -------------------------- Update Squad -------------------------------------------------- */
const updateSquad = createAsyncThunk(
  'updateSquad',
  async (
    { payload, squadId, navigate }: { payload: updateSquadPayload; squadId: string } & SuccessErrorModalParamsType,
    { dispatch },
  ) => {
    try {
      await API.put(`squad/admin/update/${squadId}`, payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Squad has been successfully updated',
          subTitle: '',
          navigateTo: navigate,
        }),
      );
    } catch (error) {
      const errorRes = error.response?.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.errorDetails || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

/* --------------------- -------------------------- Upload KYC Document -------------------------------------------------- */
const uploadKycDocument = createAsyncThunk('uploadKycDocument', async (postData: uploadKycPayload) => {
  try {
    const res = await API.post('squad/admin/upload-document', postData);
    return res.data.data;
  } catch (err) {
    console.error('Error while updating', err);
    throw err;
  }
});

/* --------------------- -------------------------- Get All Squad Members -------------------------------------------------- */
const getAllSquadMember = createAsyncThunk<SquadMemberPayload, getAllSquadQueryParams>(
  'getAllSquadMember',
  async (queryParams: getAllSquadQueryParams, { dispatch }) => {
    try {
      const res = await API.get('squad/admin/getAll', { params: queryParams });
      return {
        data: res.data.data,
        totalCount: res.data.totalCount,
        currentPage: res.data.currentPage,
        perPage: res.data.perPage,
      };
    } catch (error) {
      handleApiError(error, dispatch);
      return Promise.reject();
    }
  },
);

/* --------------------- -------------------------- Get Zones from System -------------------------------------------------- */
const getZonesFromSystem = createAsyncThunk('getZonesFromSystem', async (queryParams: zonePayload, { dispatch }) => {
  try {
    const res = await API.get(`squad/admin/system-zones`, { params: queryParams });
    return res.data.data[0].zones;
  } catch (error) {
    handleApiError(error, dispatch);
  }
});

/* --------------------- -------------------------- Send Invite to Squad -------------------------------------------------- */
const sendInvite = createAsyncThunk(
  'sendInvite',
  async ({ email, squadId }: { email: string; squadId: string }, { dispatch }) => {
    try {
      const postData = { email };
      await API.post(`squad/admin/invite/${squadId}`, postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'You have succesfully invited',
          subTitle: '',
        }),
      );
    } catch (error) {
      handleApiError(error, dispatch);
    }
  },
);

/* --------------------- -------------------------- Get All Task Management Shipments -------------------------------------------------- */
const getAllTaskShipments = createAsyncThunk<TaskManagementPayload, getAllTaskQueryParams>(
  'getAllTaskShipments',
  async (queryParams: getAllTaskQueryParams, { dispatch }) => {
    try {
      const res = await API.get(`squad/task/getShipment`, { params: queryParams });

      return {
        data: res.data.data,
        totalCount: res.data.data.totalCount,
        page: res.data.data.page,
        pageSize: res.data.data.pageSize,
      };
    } catch (error) {
      handleApiError(error, dispatch);
      return Promise.reject();
    }
  },
);

/* --------------------- -------------------------- Get Squad On Off Count -------------------------------------------------- */
const squadOnOffCount = createAsyncThunk('squadOnOffCount', async (_, { dispatch }) => {
  try {
    const res = await API.get(`squad/task/squadOnOffCount`);
    return res.data.data;
  } catch (error) {
    handleApiError(error, dispatch);
    return Promise.reject();
  }
});
/* --------------------- -------------------------- Get Squad Reports -------------------------------------------------- */
const getSquadReports = createAsyncThunk('getSquadReports', async (queryParams: squadReportsPayload, { dispatch }) => {
  try {
    const res = await API.get(`squad/admin/reports`, { params: queryParams });
    return {
      data: res.data.data,
      totalCount: res.data.data.totalCount,
      currentPage: res.data.data.page,
      perPage: res.data.data.pageSize,
    };
  } catch (error) {
    handleApiError(error, dispatch);
    return Promise.reject();
  }
});

/* --------------------- -------------------------- Get Squad For Task Assignemnt -------------------------------------------------- */
const getSquadForTaskAssignment = createAsyncThunk<SquadMembersPayload, squadForAssignedTasksParams>(
  'getSquadForTaskAssignment',
  async (queryParams: squadForAssignedTasksParams, { dispatch }) => {
    try {
      const res = await API.get(`squad/task/getSquadMembers`, { params: queryParams });
      return {
        data: res.data.data,
        totalCount: res.data.totalCount,
        currentPage: res.data.currentPage,
        perPage: res.data.perPage,
      };
    } catch (error) {
      handleApiError(error, dispatch);
      return Promise.reject();
    }
  },
);
/* --------------------- -------------------------- Squad Reports Details -------------------------------------------------- */
const getSquadReportsDetails = createAsyncThunk(
  'getSquadReportsDetails',
  async (queryParams: squadReportsPayload, { dispatch }) => {
    try {
      const res = await API.get('squad/admin/reports/shipment', { params: queryParams });

      return {
        data: res.data.data,
        totalCount: res.data.data.totalCount,
        currentPage: res.data.data.currentPage,
        pageSize: res.data.data.pageSize,
      };
    } catch (error) {
      handleApiError(error, dispatch);
      return Promise.reject();
    }
  },
);
/* --------------------- -------------------------- Get Squad Route Optimizer ------------------------------------------ */
const getSquadRouteDetails = createAsyncThunk(
  'getSquadRouteDetails',
  async (queryParams: squadRoutePayload, { dispatch }) => {
    try {
      const res = await API.get('/squad/route/getSquads', { params: queryParams });
      return {
        data: res.data.data,
        currentPage: res.data.data.currentPage,
        perPage: res.data.data.perPage,
        totalCount: res.data.data.totalCount,
      };
    } catch (error) {
      handleApiError(error, dispatch);
      return Promise.reject();
    }
  },
);

/* --------------------- -------------------------- Get Squad Route Optimizer Details -------------------------------------------------- */
const getSquadRouteOptimizer = createAsyncThunk('getSquadRouteOptimizer', async (routeId: string, { dispatch }) => {
  try {
    const response = await API.get(`/squad/route/${routeId}`);
    return response.data.data;
  } catch (error) {
    handleApiError(error, dispatch);
  }
});

/* --------------------- -------------------------- Get Squad trip details -------------------------------------------------- */
const getSquadTripDetails = createAsyncThunk(
  'getSquadTripDetails',
  async ({ tripId }: { tripId: string }, { dispatch }) => {
    try {
      const response = await API.get(`squad/route/getSquadTripDetails/${tripId}`);
      return response.data.data;
    } catch (error) {
      handleApiError(error, dispatch);
    }
  },
);

/* --------------------- -------------------------- Assign Task To Squad -------------------------------------------------- */
const assignTaskToSquad = createAsyncThunk(
  'assignTaskToSquad',
  async (postData: assignTaskToSquadPayload, { dispatch }) => {
    try {
      const res = await API.post(`squad/task/assignShipment`, postData);
      return res.data.data;
    } catch (error) {
      handleApiError(error, dispatch);
    }
  },
);
/* --------------------- -------------------------- Unassign Task To Squad -------------------------------------------------- */
const UnassignTaskToSquad = createAsyncThunk(
  'UnassignTaskToSquad',
  async (postData: UnassignTaskToSquadPayload, { dispatch }) => {
    try {
      const res = await API.post(`squad/task/unassignSquad`, postData);
      return res.data.data;
    } catch (error) {
      handleApiError(error, dispatch);
    }
  },
);
/* --------------------- -------------------------- Bulk Assign Task To Squad -------------------------------------------------- */
const BulkAssignTaskToSquad = createAsyncThunk(
  'UnassignTaskToSquad',
  async (postData: BulkAssignTaskToSquadPayload, { dispatch }) => {
    try {
      const res = await API.post(`squad/task/assignBulkShipment`, postData);
      return res.data.data;
    } catch (error) {
      handleApiError(error, dispatch);
    }
  },
);
/* --------------------- -------------------------- Get Squad Optimize Click Data -------------------------------------------- */
const optimizeRoute = createAsyncThunk(
  'optimizeRoute',
  async ({ postData }: { postData: optimizeRoutePayload }, { dispatch }) => {
    try {
      const response = await API.post('/squad/route/optimizeRoute', postData);
      return response.data.data;
    } catch (error) {
      handleApiError(error, dispatch);
    }
  },
);
/* --------------------- -------------------------- Get Squad Optimize Click Data -------------------------------------------- */

const manualOptimize = createAsyncThunk(
  'manualOptimize',
  async ({ postData }: { postData: manualOptimizePayload }, { dispatch }) => {
    try {
      const response = await API.post('/squad/maps/distance', postData);
      return response.data.data;
    } catch (error) {
      handleApiError(error, dispatch);
    }
  },
);
/* --------------------- -------------------------- Route Optimize Click Data -------------------------------------------- */
const updateRouteoptimizer = createAsyncThunk<updateRoutePayload, any>(
  'updateRouteOptimizer',
  async (payload: any, { dispatch }) => {
    try {
      const response = await API.put('/squad/route/update', payload);

      // Return the typed response
      return {
        data: response.data.data,
        status: response.status,
      };
    } catch (error) {
      handleApiError(error, dispatch);
      throw error;
    }
  },
);
/* --------------------- -------------------------- Route Optimize Click Data -------------------------------------------- */
const getAdminWareHouse = createAsyncThunk(
  'getSquadRouteDetails',
  async (queryParams: AdminWarehousePayload, { dispatch }) => {
    try {
      const res = await API.get('/squad/admin/system-warehouses', { params: queryParams });
      return res.data.data;
    } catch (error) {
      handleApiError(error, dispatch);
      return Promise.reject();
    }
  },
);

export {
  createSquad,
  updateSquad,
  uploadKycDocument,
  getAllSquadMember,
  getZonesFromSystem,
  sendInvite,
  getAllTaskShipments,
  getSquadReports,
  getSquadForTaskAssignment,
  assignTaskToSquad,
  UnassignTaskToSquad,
  getSquadReportsDetails,
  getSquadRouteDetails,
  getSquadRouteOptimizer,
  getSquadTripDetails,
  optimizeRoute,
  updateRouteoptimizer,
  manualOptimize,
  squadOnOffCount,
  getAdminWareHouse,
  BulkAssignTaskToSquad,
};
