import React, { useEffect, useState } from 'react';
import { Container, Wrapper } from '../Styles';
import { Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getOrganizationPublic, resetSaveOrg } from '@Reducers/OrganizationSetup';
import API from '../../../services/apiAxios';
import { TextLoading } from '@Components/Loader/TextLoading';
import { doLogin } from '@Reducers/LoginForm';

// const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

function OrganizationSetupSuccess() {
  const dispatch = useDispatch<AppDispatch>();
  const { savedOrgRes } = useSelector((state: RootStateType) => state?.orgSetup);
  const [isCreatingUser, setIsCreatingUser] = useState(true);

  async function getFirstUser() {
    // await delay(5000);
    // return {
    //   msg: `Successfully first user created.`,
    //   data: { name: 'ABC' },
    // };
    const res = await API.get('/app/check-first-user');
    return res.data;
  }

  const login = async () => {
    if (savedOrgRes?.username && savedOrgRes?.password) {
      const res = await dispatch(
        doLogin({
          username: savedOrgRes.username,
          password: savedOrgRes.password,
        }),
      );
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(getOrganizationPublic());
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      (async () => {
        try {
          const res = await getFirstUser();
          if (res.data) {
            setIsCreatingUser(false);
            clearInterval(intervalId);
          }
        } catch (err) {
          console.log('error : ', err);
        }
      })();
    }, 5000);

    return () => {
      dispatch(resetSaveOrg());
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Container>
      <Wrapper>
        <Grid container alignItems={'center'} className="mb-2">
          <CheckCircleIcon
            sx={{
              height: { xl: 72, lg: 72, xs: 30 },
              width: { xl: 72, lg: 72, xs: 30 },
              marginRight: { xl: 2, lg: 2, sm: 0.5 },
              color: '#32BEA6',
            }}
            // className="mr-0"
          />
          <Typography className="l-text font-semibold">Congratulations!</Typography>
        </Grid>
        <Grid container sx={{ ml: { xl: 3, xs: 1 } }} className="mb-2">
          <Grid item lg={0.3} xs={1}>
            <CircleIcon sx={{ fontSize: '8px', marginTop: '10px' }} className="mr-2" />
          </Grid>
          <Grid item lg={11.7} xs={11}>
            <Typography sx={{ fontSize: '20px' }}>
              Your organisation has been established successfully.
              {/* Your details are submitted and your onboarding email has been sent to Kindly Sign up to set your
              organization{' '}
              <span style={{ color: '#000' }} className="font-medium">
                {savedOrgRes?.firstUserEmail}
              </span> */}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ ml: { xl: 3, xs: 1 } }} className="mb-2">
          <Grid item lg={0.3} xs={1}>
            <CircleIcon sx={{ fontSize: '8px', marginTop: '10px' }} className="mr-2" />
          </Grid>
          <Grid item lg={11.7} xs={11}>
            {isCreatingUser ? (
              <TextLoading textStyles={{ fontSize: '21px' }}>Fetching First User . . . </TextLoading>
            ) : (
              <Typography sx={{ fontSize: '20px' }}>
                {savedOrgRes?.username && savedOrgRes?.password ? (
                  <>
                    Kindly{' '}
                    <span onClick={login} className="font-medium cursor-pointer">
                      Click here
                    </span>{' '}
                    to set your organization.
                  </>
                ) : (
                  <a href="/login" style={{ color: '#000', textDecoration: 'none' }} className="font-medium">
                    Click here login.
                  </a>
                )}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  );
}

export default OrganizationSetupSuccess;
