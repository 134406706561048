import API from '@Services/apiAxios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the shape of the response data
interface ShipmentReport {
  _id: string;
  _createdBy: string;
  url: string;
  createdAt: string;
  fromDate: string;
  toDate: string;
  type: string;
  updatedAt: string;
  expiresAt: string;
  __v: number;
  status: string;
}

interface ShipmentReportsState {
  reports: ShipmentReport[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: ShipmentReportsState = {
  reports: [],
  loading: false,
  error: null,
};

// Async thunk for fetching shipment reports
export const fetchShipmentReports = createAsyncThunk('shipmentReports/fetch', async (_, { rejectWithValue }) => {
  try {
    const response = await API.get('/shipment/getShipmentReports');
    return response.data.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.msg || 'Something went wrong');
  }
});

// Create slice
const shipmentReportsDownloadSlice = createSlice({
  name: 'shipmentReportsDownload',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchShipmentReports.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShipmentReports.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
      })
      .addCase(fetchShipmentReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default shipmentReportsDownloadSlice.reducer;
