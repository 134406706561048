import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Grid, Link, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

import Modal from '@Components/Modal';
import SbTextField from '@Components/Textfield';
import SbButton from '@Components/Button';
import Image from '@Components/Image';
import CopyCredentials from '@Components/CopyCredentials';
import { InviteAdminSchema } from './schema';
import { AppDispatch, RootStateType } from '@Store';
import { adminSendInvite } from '@Reducers/Admin/actions';

function SuccessModal(props: {
  isOpen: boolean;
  handleClose: () => void;
  name?: string;
  email?: string;
  username?: string;
  password?: string;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const profileState = useSelector((state: RootStateType) => state.loginUser.profile);
  const textToCopy = [
    props?.email ? `Email: ${props?.email}` : props?.username ? `Username: ${props?.username}` : null, // If email exists, exclude username
    props?.password && `Password: ${props?.password}`,
  ]
    .filter(Boolean)
    .join('\n');

  const loginDetails = [
    props?.email ? `Email: ${props?.email}` : props?.username ? `Username: ${props?.username}` : null, // Use email if available, otherwise username
  ]
    .filter(Boolean)
    .join('%0A');

  const textForWhatsApp = props?.password ? `${loginDetails}%0APassword: ${props?.password}` : loginDetails;

  const credentialsToShareOnWhatsApp = `Dear ${props?.name},%0A%0AYour team member ${profileState?.name} has created an account for you.%0A%0APlease find the login details below -%0A${textForWhatsApp}%0A%0ANote: Do not share this email with anyone outside the organization.`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: '' },
    validationSchema: InviteAdminSchema,
    onSubmit(values) {
      onSendInvite(values);
    },
  });

  const { handleSubmit, handleChange, values: formkikValues, errors } = formik;

  const onSendInvite = ({ email }: { email: string }) => {
    if (props.username) {
      const payload = {
        email: email,
        username: props.username,
      };
      dispatch(adminSendInvite(payload)).then((val: { type: string }) => {
        if (val.type === 'adminSendInvite/fulfilled') {
          props.handleClose();
        }
      });
    }
    // console.log(`${email} send successfully `);
  };

  return (
    <Modal
      open={props.isOpen}
      maxWidth="sm"
      dialogContainerSx={{
        paddingTop: 1,
        paddingRight: 1,
        paddingLeft: 3,
      }}
      onClose={props.handleClose}
    >
      <Grid container justifyContent="flex-end">
        <Close onClick={props.handleClose} />
        <Grid item xs={12}>
          <Typography className="l-text font-semibold  mr-1">You’ve successfully created an account!</Typography>
          <Typography className="md-text font-normal flex items-center ">
            Share account credentials and access to admins!
          </Typography>
        </Grid>

        <Grid container item className="mt-4 mb-2" rowGap={2} columnGap={2}>
          <Grid item xs={12} sm={6}>
            <SbTextField
              required
              name="email"
              placeholder="contact@email.com"
              value={formkikValues.email}
              onChange={handleChange}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <SbButton
              fullWidth
              variant="contained"
              sx={{ borderRadius: '8px !important' }}
              onClick={() => handleSubmit()}
            >
              Send Invite
            </SbButton>
          </Grid>
        </Grid>

        <Grid container item className="mt-4" alignItems="baseline">
          <Grid item xs={3} sm={2}>
            <CopyCredentials label="Copy Admin Credentials" textToCopy={textToCopy}></CopyCredentials>
          </Grid>
          <Grid item xs={3} sm={2} textAlign="center">
            <Link
              href={`https://web.whatsapp.com/send?text=${credentialsToShareOnWhatsApp}`}
              underline="none"
              target="_blank"
              rel="nofollow noopener"
            >
              <Image src="/images/icons/social_media/WhatsApp.svg" alt="WhatsApp" />
              <Typography className="xs-text  mt-1\/2">WhatsApp</Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
export default SuccessModal;
