import SbButton from '@Components/Button';
import Modal from '@Components/Modal';
import UploadKyc from '@Components/ShipmentKycUpload/UploadKyc';
import { getShipmentKyc } from '@Reducers/Shipment';
import { ShipmentKycType } from '@Reducers/Shipment/Type';
import { AppDispatch, RootStateType } from '@Store';
import AddIcon from '@mui/icons-material/Add';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SingleDocument from './SingleDocument';
import { FedXInformationType } from '@Reducers/AdminShipment/Type';
import { onForward } from '../../../../../ViewSingleShipment/TrackingDetails/UserDHL/constants';

interface PropsType {
  open: boolean;
  shipmentNumber: string;
  handleClose?: () => void;
  dragDropWrapperOnClickRegister?: boolean;
  csp: string;
  type: string;
  fedExDetails: FedXInformationType;
  formValues: any;
  setFormValues: any;
  handleOtpModalOpen: any;
  onClickForward: any;
}
const SingleDocumentWrapper = styled(Grid)(() => ({
  border: '1px solid #b2b2b2',
  borderRadius: '8px',
}));
function UploadDHLDoc(props: PropsType) {
  const {
    dragDropWrapperOnClickRegister,
    open,
    handleClose,
    shipmentNumber,
    setFormValues,
    formValues,
    handleOtpModalOpen,
    onClickForward,
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { shipmentKyc, isShipmentKycAdded, isShipmentKycDeleted } = useSelector(
    (state: RootStateType) => state.shipment,
  );
  const [uploadDocumentModal, setUploadDocumentModal] = useState<boolean>(false);
  const [singleDoc, setSingleDoc] = useState<ShipmentKycType | null>(null);
  const [documentType, setDocumentType] = useState<string>('');
  const [document, setDocument] = useState<ShipmentKycType | null>(null);
  useEffect(() => {
    if (shipmentNumber) {
      dispatch(getShipmentKyc(shipmentNumber));
    }
  }, [shipmentNumber]);
  useEffect(() => {
    if (isShipmentKycAdded) {
      setUploadDocumentModal(false);
      dispatch(getShipmentKyc(shipmentNumber));
    }
  }, [isShipmentKycAdded]);
  useEffect(() => {
    if (isShipmentKycDeleted) {
      dispatch(getShipmentKyc(shipmentNumber));
    }
  }, [isShipmentKycDeleted]);
  const uploadDocumentModalClose = () => setUploadDocumentModal(false);
  const uploadDocumentModalOpen = () => setUploadDocumentModal(true);
  const onDocumentUploadModalOpen = useCallback(
    (_documentLabel: string, documentData: ShipmentKycType | null): void => {
      setSingleDoc(documentData);
      uploadDocumentModalOpen();
    },
    [],
  );
  const handleFileChecked = (data: ShipmentKycType) => {
    setDocument(data);
    setDocumentType('');
  };
  const onUpload = () => {
    setFormValues((prevValues: any) => {
      const { _id, ...rest } = prevValues;
      return {
        ...rest,
        csp: 'dhl',
        documentId: document?._id,
      };
    });
  };
  useEffect(() => {
    if (formValues && formValues.csp === 'dhl' && formValues.documentId) {
      onForward({
        values: formValues,
        shipmentNumber: shipmentNumber || '',
        dispatch,
        nextFunc: onClickForward,
        handleOtpModalOpen,
      });
    }
  }, [formValues]);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        dialogContainerSx={{
          width: { xs: 'unset', md: '700px' },
          maxHeight: '80vh',
        }}
      >
        <Grid container sx={{ mb: 4, flexDirection: 'column', alignItems: 'center' }}>
          <Typography className="md-text font-semibold ">Documents For Shipment Number: {shipmentNumber}</Typography>
        </Grid>
        <UploadKyc
          open={uploadDocumentModal}
          onClose={uploadDocumentModalClose}
          documentData={singleDoc}
          shipmentNumber={shipmentNumber}
          dragDropWrapperOnClickRegister={dragDropWrapperOnClickRegister}
          allowedFileTypes={['image/png', 'image/jpeg', 'application/pdf']}
        />
        <SingleDocumentWrapper container item>
          {shipmentKyc?.length > 0 && (
            <Grid
              container
              item
              p={2.5}
              sx={{
                borderBottom: '1px solid',
              }}
              className={`border-grey`}
              gap={2}
            >
              <Grid className="font-medium" item xs={12} md={4.5} alignItems="center" pl={2}>
                Name
              </Grid>
              {/* <Grid className="font-medium" item xs={12} md={3.5} alignItems="center">
                Documents
              </Grid> */}
              <Grid className="font-medium" item xs={12} md={2} alignItems="center">
                Last Sent
              </Grid>
            </Grid>
          )}
          {shipmentKyc.map((kyc, index) => (
            <SingleDocument
              key={`${kyc.name}-${index}`}
              label={kyc.documentName}
              required={false}
              documentData={kyc || null}
              handleFileChecked={handleFileChecked}
              selectedDocument={document}
              setDocumentType={setDocumentType}
              documentType={documentType}
            />
          ))}
        </SingleDocumentWrapper>
        <AddNewKycButton onClick={() => onDocumentUploadModalOpen('', null)} variant="outlined" className="w-full">
          <AddIcon />
          <Typography>Add New Document</Typography>
        </AddNewKycButton>
        <SbButton
          onClick={() => {
            onUpload();
          }}
          disabled={!document}
          variant="contained"
          sx={{
            borderRadius: '8px !important',
            mt: 5,
            mb: 1,
          }}
          className="w-full"
        >
          Upload to DHL
        </SbButton>
      </Modal>
    </>
  );
}
export default UploadDHLDoc;
export const AddNewKycButton = styled(SbButton)(({ theme }) => ({
  borderRadius: '8px !important',
  borderStyle: 'dashed',
  borderColor: theme.palette.border.grey,
  color: theme.palette.text.light,
  '&:hover': {
    borderStyle: 'dashed',
    borderColor: theme.palette.border.grey,
  },
  marginTop: '30px',
}));
