import React from 'react';
import { Grid } from '@mui/material';
import { SectionSubText, SectionTitle } from '../../Style';
import SBDropdown from '@Components/Dropdown';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { SHIPMENT_DROPDOWN_STATUS } from '@Constants/Shipment';
const OtherStatusList = Object.values(SHIPMENT_DROPDOWN_STATUS).filter(x => x.isOther);
const StatusList = [
  ...Object.values(SHIPMENT_DROPDOWN_STATUS)
    .filter(x => !x.isOther)
    .map(s => ({ display: s.label, value: s.value })),
  { display: 'Others', value: 'Others', grouped: true },
  ...OtherStatusList.map(s => ({ display: s.label, value: s.value })),
];

interface GenerateInvoicesProps {
  data: string[];
  onchange: React.Dispatch<React.SetStateAction<string[]>>;
}

function GenerateInvoices({ data: generateInvoiceStatus, onchange: setGenerateInvoicesStatus }: GenerateInvoicesProps) {
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isBillingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.billing,
    PERFORM_ACTION.write,
  );

  return (
    <>
      <Grid container className="mb-3">
        <Grid container>
          <SectionTitle className="mr-2">Generate Invoices</SectionTitle>
        </Grid>
        <SectionSubText width={{ xs: '100%', md: '60%' }}>
          Organizations can decide when a shipment should be billed based on the status of the shipment.
        </SectionSubText>
      </Grid>
      <Grid container item xs={12} sm={6} md={6} lg={4} className="mb-3">
        <SBDropdown
          id="generateInvoice-type"
          label="Select Status"
          name="statusType"
          options={StatusList || []}
          value={[generateInvoiceStatus[0]]}
          onChange={e => {
            setGenerateInvoicesStatus(e);
          }}
          // error={errors.accountType}
          disabled={!isBillingWriteAllowed}
        />
      </Grid>
    </>
  );
}

export default GenerateInvoices;
