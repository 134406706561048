import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import SbImage from '../../../components/Image';

function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid>
      <Typography sx={{ fontSize: '32px', lineHeight: '80px' }} className="font-bold">
        Welcome to
      </Typography>
      <SbImage
        src="/images/speedbox_logo.png"
        alt="logo"
        style={
          isMobile
            ? {
                height: '30px',
              }
            : {}
        }
      />
      <Typography sx={{ fontSize: '20px', color: '#737373' }}>
        Your Journey to get online{' '}
        <span style={{ color: '#000' }} className="font-black mt-1">
          begins now!
        </span>
      </Typography>
    </Grid>
  );
}

export default Header;
