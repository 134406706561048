import { styled, TableCell } from '@mui/material';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';

export const ChargesTableCell = styled(TableCell)(_ => ({
  paddingInline: 4,
  paddingBlock: 4,
}));

export const ChargesTableHead = styled(TableCell)(_ => ({
  paddingInline: 4,
  paddingBlock: 4,
}));

export const SBCalendarPicker = styled(CalendarPicker)(_ => ({
  '.PrivatePickersSlideTransition-root': {
    minHeight: '200px',
  },
  '.MuiButtonBase-root': {
    fontSize: '0.78rem',
  },
  '.MuiDayPicker-weekContainer': {
    gap: '0.4rem',
  },
}));
