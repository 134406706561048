import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { WarehouseCreatePayload, WarehouseListForShipmentQuery, WarehouseListQuery } from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';
import { rootStore } from '@Store';

const getWarehouseList = createAsyncThunk('getWarehouseList', async (queryParams: WarehouseListQuery) => {
  const res = await API.get(`/organization/warehouse/all?`, { params: queryParams });
  return res.data;
});

const getWarehouseById = createAsyncThunk(
  'getWarehouseById',
  async ({ id, navigate }: { id: string } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.get(`/organization/warehouse/${id}`);

      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
          navigateTo: navigate || null,
        }),
      );
      throw error;
    }
  },
);

const updateWarehouseById = createAsyncThunk(
  'updateWarehouseById',
  async (
    { id, payload, showModal, navigate }: { id: string; payload: WarehouseCreatePayload } & SuccessErrorModalParamsType,
    thunkAPI,
  ) => {
    try {
      const res = await API.put(`/organization/warehouse/${id}`, payload);
      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Warehouse successfully updated.',
            navigateTo: navigate || null,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const createWarehouse = createAsyncThunk(
  'createWarehouse',
  async (data: { payload: WarehouseCreatePayload } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.post(`/organization/warehouse?`, data.payload);

      if (data.showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Warehouse successfully created.',
            navigateTo: data.navigate || null,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getWarehouseListForShipment = createAsyncThunk(
  'warehouse/list-shipment',
  async (queryParams?: WarehouseListForShipmentQuery) => {
    const res = await API.get(`/shipment/warehouse/all`, { params: queryParams });
    res.data.countriesObj = rootStore.getState()?.global?.countriesObj;
    return res.data;
  },
);

const getSpecificWarehouseListForShipment = createAsyncThunk(
  'warehouse/list-shipment',
  async (accountNumber?: string) => {
    try {
      const res = await API.get(`/shipment/warehouse/${accountNumber}`);
      // console.info('res-->', res);
      res.data.countriesObj = rootStore.getState()?.global?.countriesObj;
      return res.data;
    } catch (error) {
      console.error('error', error);
    }
  },
);

export {
  getWarehouseList,
  getWarehouseById,
  createWarehouse,
  updateWarehouseById,
  getWarehouseListForShipment,
  getSpecificWarehouseListForShipment,
};
