import React from 'react';
import Image from '@Components/Image';
import { Grid } from '@mui/material';

export interface Props {
  serviceName: string;
  serviceLogo: string;
  systemName?: string;
}

function ServiceAndSystem({ serviceName, serviceLogo, systemName }: Props) {
  return (
    <Grid container alignItems="center" display="grid" gridTemplateColumns="100px 1fr" gap={1}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        p={0.5}
        className="border border-1px rounded border-light bg-white"
      >
        <Image src={serviceLogo} alt={serviceName} width={83} height={59} style={{ objectFit: 'contain' }} />
      </Grid>
      <Grid
        item
        container
        className="md-text "
        alignItems={'flex-start'}
        flexDirection={'column'}
        justifyContent={'center'}
        style={{ maxWidth: 'fit-content', height: '59px', lineHeight: '1.5' }}
      >
        <Grid item>{serviceName}</Grid>
        {systemName && (
          <Grid className="sm-text font-light" item>
            {systemName}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ServiceAndSystem;
