import React from 'react';
import SbButton from '../Button';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../store';
import { UpdateShipmentStatusPostData } from '../../store/reducers/ShipmentHistory/Type';
import { Typography, useTheme } from '@mui/material';
import { cancelShipment } from '@Reducers/ShipmentHistory';
import CloseIcon from '@mui/icons-material/Close';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

function CancelShipment() {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();

  const paramQuery = useParams();
  const shipmentNumber = paramQuery?.id || paramQuery?.sn;
  const _userId = useSelector((state: RootStateType) => state.loginUser.profile?._id) || '';

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isCancelShipmentAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.cancel_shipment,
    PERFORM_ACTION.write,
  );

  const SbActionButtonStyle = {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.dark,
  };

  function handleCancelShipment() {
    if (!shipmentNumber) return;
    const postData: UpdateShipmentStatusPostData = {
      _userId,
      shipmentNumbers: [shipmentNumber],
      location: '-',
      msg: 'Shipment cancelled.',
      status: 'cancelled',
    };
    if (isCancelShipmentAllowed) {
      dispatch(cancelShipment(postData));
    }
  }

  return (
    <div className={`${isCancelShipmentAllowed ? '' : 'cursor-not-allowed'}`}>
      <SbButton
        containerClass="py-1 px-3 normal-case rounded-xs"
        // sx={{ height: '36px' }}
        variant="contained"
        color="primary"
        size="medium"
        style={SbActionButtonStyle}
        onClick={handleCancelShipment}
        startIcon={<CloseIcon />}
        disabled={!isCancelShipmentAllowed}
      >
        <Typography className="font-semibold md-text">Cancel Order</Typography>
      </SbButton>
    </div>
  );
}

export default CancelShipment;
