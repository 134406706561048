import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  createAccount,
  deactivateUserAccount,
  getAccountList,
  getUserById,
  getUserList,
  resendInvite,
  restoreUserAccount,
  suspendUserAccount,
  updateAccount,
  viewContract,
  forgotPassword,
  confirmForgotPassword,
  getCarrierSettings,
  getCarrierSettingsList,
  addCarrierSettings,
  updateCarrierSettings,
  deleteCarrierSetting,
  userSendInvite,
} from './actions';
import { UserState } from './Type';
import {
  createAccountFullfilled,
  createAccountRejected,
  deactivateUserAccountFulfilled,
  getAccountListFulfilled,
  getUserByIdFullfilled,
  getUserListFulfilled,
  handlePending,
  handleRejected,
  resendInviteFulfilled,
  restoreUserAccountFulfilled,
  suspendUserAccountFulfilled,
  updateAccountFullfilled,
  viewContractFulfilled,
  forgotPasswordFullfilled,
  confirmForgotPasswordFullfilled,
  getCarrierSettingsFullfilled,
  getCarrierSettingsListFullfilled,
} from './case';
import {
  aramexIntialValues,
  dhlInitialValues,
  fedexInitialValues,
} from '../../../pages/CreateAccount/CarrierAccess/constant';

const initialState: UserState = {
  loading: false,
  // isActionFulfilled: false,
  success: '',
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 10,
  },
  selectedUser: {},
  accountList: [],
  accountCreated: {
    isInserted: false,
    isEmailSent: false,
    isError: false,
    msg: '',
    data: {
      name: '',
      email: '',
      signupToken: '',
      phoneNumber: '',
      telephoneCode: '',
      inviteLink: '',
    },
  },
  userDataById: null,
  isUserUpdated: false,
  resetPassword: null,
  carrierSetting: {
    fedex: { ...fedexInitialValues, _accountId: '', accountNumber: '' },
    dhl: { ...dhlInitialValues, _accountId: '', accountNumber: '' },
    aramex: { ...aramexIntialValues, _accountId: '', accountNumber: '' },
  },
  carrierSettingList: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    setSelectedShipments: function (state, action: PayloadAction<UserState['selectedUser']>) {
      const newSelectedShipments = action.payload;
      state.selectedUser = newSelectedShipments;
    },
    resetAccountCreated: function (state) {
      state.accountCreated = initialState.accountCreated;
      state.error = initialState.error;
    },
    resetAccountData: function (state) {
      state.accountCreated = { ...initialState.accountCreated };
      state.error = { ...initialState.error };
      state.userDataById = null;
      state.carrierSettingList = [];
    },
    resetUserUpdated: function (state) {
      state.isUserUpdated = initialState.isUserUpdated;
    },
    setCarrierSettings: function (state, action) {
      const { values, carrierType: carrierType } = action.payload;
      state.carrierSetting[carrierType as keyof typeof state.carrierSetting] = {
        ...state.carrierSetting[carrierType as keyof typeof state.carrierSetting],
        ...values,
      };
    },
    resetUserCarrierSetting: function (state, action: PayloadAction<string>) {
      const carrierType = action.payload;
      if (carrierType === 'fedex') {
        state.carrierSetting.fedex = { ...fedexInitialValues, _accountId: '', accountNumber: '' };
      } else if (carrierType === 'dhl') {
        state.carrierSetting.dhl = {
          ...dhlInitialValues,
          _accountId: '',
          accountNumber: '',
        };
      } else if (carrierType === 'aramex') {
        state.carrierSetting.aramex = {
          ...aramexIntialValues,
          _accountId: '',
          accountNumber: '',
        };
      }
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getUserList.pending, handlePending);
    addCase(getUserList.fulfilled, getUserListFulfilled);
    addCase(getUserList.rejected, handleRejected);

    addCase(resendInvite.pending, handlePending);
    addCase(resendInvite.fulfilled, resendInviteFulfilled);
    addCase(resendInvite.rejected, handleRejected);

    addCase(viewContract.pending, handlePending);
    addCase(viewContract.fulfilled, viewContractFulfilled);
    addCase(viewContract.rejected, handleRejected);

    addCase(deactivateUserAccount.pending, handlePending);
    addCase(deactivateUserAccount.fulfilled, deactivateUserAccountFulfilled);
    addCase(deactivateUserAccount.rejected, handleRejected);

    addCase(suspendUserAccount.pending, handlePending);
    addCase(suspendUserAccount.fulfilled, suspendUserAccountFulfilled);
    addCase(suspendUserAccount.rejected, handleRejected);

    addCase(restoreUserAccount.pending, handlePending);
    addCase(restoreUserAccount.fulfilled, restoreUserAccountFulfilled);
    addCase(restoreUserAccount.rejected, handleRejected);

    addCase(getAccountList.pending, handlePending);
    addCase(getAccountList.fulfilled, getAccountListFulfilled);
    addCase(getAccountList.rejected, handleRejected);

    addCase(createAccount.pending, handlePending);
    addCase(createAccount.fulfilled, createAccountFullfilled);
    addCase(createAccount.rejected, createAccountRejected);

    addCase(getUserById.pending, handlePending);
    addCase(getUserById.fulfilled, getUserByIdFullfilled);
    addCase(getUserById.rejected, handleRejected);

    addCase(updateAccount.pending, handlePending);
    addCase(updateAccount.fulfilled, updateAccountFullfilled);
    addCase(updateAccount.rejected, handleRejected);

    addCase(forgotPassword.pending, handlePending);
    addCase(forgotPassword.fulfilled, forgotPasswordFullfilled);
    addCase(forgotPassword.rejected, handleRejected);

    addCase(confirmForgotPassword.pending, handlePending);
    addCase(confirmForgotPassword.fulfilled, confirmForgotPasswordFullfilled);
    addCase(confirmForgotPassword.rejected, handleRejected);

    addCase(getCarrierSettings.pending, handlePending);
    addCase(getCarrierSettings.fulfilled, getCarrierSettingsFullfilled);
    addCase(getCarrierSettings.rejected, handleRejected);

    addCase(getCarrierSettingsList.pending, handlePending);
    addCase(getCarrierSettingsList.fulfilled, getCarrierSettingsListFullfilled);
    addCase(getCarrierSettingsList.rejected, handleRejected);

    addCase(addCarrierSettings.pending, handlePending);
    addCase(addCarrierSettings.fulfilled, handleRejected);
    addCase(addCarrierSettings.rejected, handleRejected);

    addCase(updateCarrierSettings.pending, handlePending);
    addCase(updateCarrierSettings.fulfilled, handleRejected);
    addCase(updateCarrierSettings.rejected, handleRejected);

    addCase(deleteCarrierSetting.pending, handlePending);
    addCase(deleteCarrierSetting.fulfilled, handleRejected);
    addCase(deleteCarrierSetting.rejected, handleRejected);

    addCase(userSendInvite.pending, handlePending);
    addCase(userSendInvite.fulfilled, handleRejected);
    addCase(userSendInvite.rejected, handleRejected);
  },
});

// export { getUserList };
export { forgotPassword, confirmForgotPassword };
export const {
  setSelectedShipments,
  resetAccountCreated,
  resetUserUpdated,
  resetAccountData,
  setCarrierSettings,
  resetUserCarrierSetting,
} = userSlice.actions;
export default userSlice.reducer;
