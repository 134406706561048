import React from 'react';
import { FormHelperText, FormLabel, Grid, styled } from '@mui/material';
import SbTooltip from '../../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues } from 'formik';
import { OrganizationType } from './type';
import RichTextEditor from '../../../components/RichTextEditor';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';

export const ContainerStyle = styled(Grid)(({ theme }) => ({
  padding: '20px 0',
  borderTop: `1px solid ${theme.palette.border.grey}`,
}));

interface FormProps {
  handleChange: FormikHandlers['handleChange'];
  setFieldValue?: FormikHelpers<OrganizationType>['setFieldValue'];
  values: FormikValues;
  errors?: FormikErrors<OrganizationType>;
}

// const RTEBlankValuesRegex = [new RegExp('<p><br></p>'), new RegExp('<p>[ ]*</p>')];
// function isRTEValueEmpty(value: string) {
//   let isEmpty = false;
//   for (const blankRegex of RTEBlankValuesRegex) {
//     if (blankRegex.test(value)) {
//       isEmpty = true;
//       break;
//     }
//   }
//   return isEmpty;
// }
function TermsAndConditions(props: FormProps) {
  const { values, setFieldValue, errors } = props;

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isTCWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Organization_Settings.t_and_c,
    PERFORM_ACTION.write,
  );
  return (
    <div>
      <ContainerStyle container alignItems="start">
        <Grid item sm={12} md={5} lg={3} xl={2.5} mb={{ xs: 1 }}>
          <SBLabel
            label="Terms and conditions"
            required
            tooltipText="These are organization terms & conditions which will be visible on the dashboard."
          />
        </Grid>
        <Grid container item xs={12} md={7} lg={9} xl={9.5}>
          <Grid item xs={12}>
            <RichTextEditor
              id="termsAndCondition"
              value={values.termsAndCondition || ''}
              onChange={value => {
                // const isEmpty = isRTEValueEmpty(value);
                // setFieldValue?.('termsAndCondition', isEmpty ? '' : value);
                setFieldValue?.('termsAndCondition', value);
              }}
              style={{ maxHeight: '300px', overflowX: 'auto' }}
              editorOptions={{ fontFamily: false }}
              disabled={!isTCWriteAllowed}
            />
            {errors?.termsAndCondition && (
              <FormHelperText error className="mt-1">
                {typeof errors?.termsAndCondition === 'string' ? errors.termsAndCondition : 'Invalid Data'}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </ContainerStyle>
      <ContainerStyle container alignItems="start">
        <Grid item sm={12} md={5} lg={3} xl={2.5} mb={{ xs: 1 }}>
          <SBLabel
            label="Privacy Policy"
            required
            tooltipText="These are Organization privacy policies  which will be visible on the dashboard."
          />
        </Grid>
        <Grid container item xs={12} md={7} lg={9} xl={9.5}>
          <Grid item xs={12}>
            {/* <SbTextField name="privacyPolicy" multiline rows={8} /> */}
            <RichTextEditor
              id="privacyPolicy"
              value={values.privacyPolicy || ''}
              onChange={value => {
                // const isEmpty = isRTEValueEmpty(value);
                // setFieldValue?.('privacyPolicy', isEmpty ? '' : value);
                setFieldValue?.('privacyPolicy', value);
              }}
              style={{ maxHeight: '300px', overflowX: 'auto' }}
              editorOptions={{ fontFamily: false }}
              disabled={!isTCWriteAllowed}
            />
            {errors?.privacyPolicy && (
              <FormHelperText error className="mt-1">
                {typeof errors?.privacyPolicy === 'string' ? errors.privacyPolicy : 'Invalid Data'}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </ContainerStyle>
    </div>
  );
}

function SBLabel({ label, tooltipText, required }: { label: string; tooltipText?: string; required?: boolean }) {
  return (
    <Grid container alignItems={'start'} className="title">
      <FormLabel required={required} className="font-medium md-text mr-1">
        {label}
      </FormLabel>
      {tooltipText && (
        <SbTooltip placement="right" title={tooltipText}>
          <InfoRounded />
        </SbTooltip>
      )}
    </Grid>
  );
}

export default TermsAndConditions;
