import { TransactionsIdType } from '@Reducers/AdminShipment/Type';
import { RootStateType } from '@Store';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

const TRANSACTION_STATUS = {
  pending: { value: 'pending', label: 'Pending' },
  successful: { value: 'successful', label: 'Paid' || 'refund' },
  cancelled: { value: 'cancelled', label: 'Cancelled' },
  failed: { value: 'failed', label: 'Failed' },
  // adhoc: { value: 'adhoc', label: 'Adhoc' },
};

function Comments() {
  const shipmentpayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);
  const { _transactionsId } = shipmentpayment || {};
  if (!_transactionsId?.length) return <></>;
  return (
    <Grid container className="mt-3">
      <Grid item xs={12} mb={1}>
        <Typography className="font-semibold">Comments</Typography>
      </Grid>
      <Grid container spacing={1}>
        {_transactionsId?.length &&
          _transactionsId.map(transaction => <SingleComment key={transaction._id} {...transaction} />)}
        {/* 
        <SingleComment />
        <SingleComment /> */}
      </Grid>
    </Grid>
  );
}

function SingleComment({ createdAt, amount, mode, status, description, type }: TransactionsIdType) {
  const displayStatus = status === 'successful' && type === 'refund' ? 'Refund' : TRANSACTION_STATUS[status].label;
  const transactionMessage =
    status === 'successful' && type === 'refund' ? `Refunded by ${mode}` : `Amount Paid by ${mode}`;
  return (
    <Grid container item spacing={1}>
      <Grid item>
        <Typography className="font-medium">{moment(createdAt).format('DD MMM YYYY')}</Typography>
      </Grid>
      <Grid item>
        <Typography className="font-medium">
          {displayStatus}: {amount}
        </Typography>
      </Grid>

      <Grid item>
        <Typography className="font-medium">{transactionMessage}</Typography>
      </Grid>
      <Grid item>
        <Typography className="font-medium">- {description}</Typography>
      </Grid>
    </Grid>
  );
}
export default Comments;
