import { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { BoxPropsType } from '../../Type';
// import { UNIT, calculateVolume } from '../../../../../services/calculateVolume';
import { createShipmentContextType } from '../../../../../types/shipment';
import CreateShipmentContext from '../../../../../context/CreateShipment';
import { BoxButton } from '../../../../GetRatesV2/StyleComponent';
// import { UNIT_OF_MEASUREMENT } from '../../../../GetRatesV2/Constant';
import Image from '../../../../../components/Image';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';

interface BoxDetailsPropsType extends BoxPropsType {
  setCopyBox: (copy: boolean) => void;
  isSelected: boolean;
  writePermission?: boolean;
  index: number;
}

function BoxDetails({ index, handleDeleteBox, boxData, isSelected, setCopyBox, writePermission }: BoxDetailsPropsType) {
  const { shipmentType, boxes, removeFirstBoxDeleteOption } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;

  const { chareableWeight } = useSelector((state: RootStateType) => state.getRates);

  // const { id = '0', length, height, width } = boxData;
  const { id = '0' } = boxData;
  const weight = typeof boxData.weight === 'string' ? parseFloat(boxData.weight) : boxData.weight;

  const { unit, type } = shipmentType;
  // const volumetricWeight = useMemo(() => {
  //   if (length && width && height)
  //     return calculateVolume(length, width, height, UNIT_OF_MEASUREMENT[unit]?.dimensionUnit as UNIT);
  //   else return 0;
  // }, [length, width, height]);
  const selectedUnit = useMemo(() => unit.split('-')[0], [unit]);
  // const minVolumetricWeight = chareableWeight?.boxWiseArr?.[index]?.minVolumetricWeight;
  // const maxVolumetricWeight = chareableWeight?.boxWiseArr?.[index]?.maxVolumetricWeight;

  // const newVolumemetricWT = chareableWeight?.boxWiseArr && key && chareableWeight?.boxWiseArr[key]?.maxVolumetricWeight;
  // const newCharegableWT = chareableWeight?.boxWiseArr && key && chareableWeight?.boxWiseArr[key]?.maxChargeableWeight;

  // let newVolumemetricWT;
  // let newCharegableWT;

  // if (chareableWeight?.boxWiseArr && index) {
  //   newVolumemetricWT = chareableWeight?.boxWiseArr[index]?.maxVolumetricWeight;
  //   newCharegableWT = chareableWeight?.boxWiseArr[index]?.maxChargeableWeight;
  // }

  // console.info('chareableWeight', chareableWeight);

  return (
    <>
      <Grid container item justifyContent="space-between" alignItems="center">
        <Grid container justifyContent="space-between">
          <Grid container item xs={11} className="mb-1">
            <Typography className="md-text font-bold">{`Box ${parseInt(id) + 1} of ${boxes.length}`}</Typography>
            {/* <Typography className="md-text font-bold">Box 1-3</Typography> */}
            {/* {boxCopyCount && boxCopyCount > 0 ? (
              <CopyCount className="sm-text ml-1 px-1 rounded-xs">x {boxCopyCount}</CopyCount>
            ) : (
              <></>
            )} */}
          </Grid>
        </Grid>
        <BoxInfo label="Actual Weight" value={`${weight || 0} ${selectedUnit}`} />
        {/* <BoxInfo label="Volumetric Weight" value={`${volumetricWeight || 0} ${selectedUnit}`} />
        <BoxInfo
          label="Chargeable Weight"
          value={parseFloat(
            `${
              weight && volumetricWeight && volumetricWeight > weight ? volumetricWeight || 0 : weight || 0
            } ${selectedUnit}`,
          ).toFixed(2)}
        /> */}
        {/* <BoxInfo
          label="Volumetric Weight"
          value={`${(minVolumetricWeight ?? 0).toFixed(2)} - ${(maxVolumetricWeight ?? 0).toFixed(2)} ${selectedUnit}`}
        />
        <BoxInfo
          label="Chargeable Weight"
          value={`${
            (chareableWeight?.boxWiseArr &&
              chareableWeight?.boxWiseArr[index]?.minChargeableWeight +
                '-' +
                chareableWeight?.boxWiseArr[index]?.maxChargeableWeight) ||
            0
          } ${selectedUnit}`}
        /> */}
        <BoxInfo
          label="Volumetric Weight"
          value={`${
            chareableWeight?.boxWiseArr && chareableWeight?.boxWiseArr[index]?.maxVolumetricWeight
              ? selectedUnit === 'lb'
                ? (chareableWeight.boxWiseArr[index].maxVolumetricWeight / 600).toFixed(2)
                : chareableWeight.boxWiseArr[index].maxVolumetricWeight.toFixed(2)
              : (0).toFixed(2)
          } ${selectedUnit}`}
        />
      </Grid>
      {/* {type === 'parcel' && ( */}
      <Grid container justifyContent="space-between" alignItems="center" columnSpacing={1}>
        <Grid item xs={6}>
          {id === '0' && removeFirstBoxDeleteOption ? (
            <></>
          ) : (
            <BoxButton
              onClick={handleDeleteBox}
              className={isSelected ? 'isSelected' : ''}
              startIcon={
                <Image
                  width={12}
                  src={`/images/Boxes/${boxes.length < 2 || type !== 'parcel' ? 'DeleteDisabled' : 'Delete'}.svg`}
                  alt="delete box"
                />
              }
              // style={{ cursor: boxes.length < 2 ? 'not-allowed' : 'pointer' }}
              disabled={!writePermission ? !writePermission : boxes.length < 2 || type !== 'parcel'}
            >
              Delete
            </BoxButton>
          )}
        </Grid>
        <Grid item xs={6}>
          <BoxButton
            onClick={() => setCopyBox(true)}
            className={isSelected ? 'isSelected' : ''}
            startIcon={
              <Image
                width={14}
                src={`/images/Boxes/${type !== 'parcel' ? 'CopyDisabled' : 'Copy'}.svg`}
                alt="duplicate box"
              />
            }
            disabled={!writePermission ? !writePermission : type !== 'parcel'}
          >
            Duplicate
          </BoxButton>
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
}

function BoxInfo({ value, label }: { label: string; value: string | number }) {
  return (
    <Grid container className="mb-1/2" display="grid" gridTemplateColumns="2fr 1fr" gap={1}>
      <Grid item>
        <Typography className="sm-text">{label}</Typography>
      </Grid>
      <Grid item textAlign="center">
        <Typography className="sm-text">{value}</Typography>
      </Grid>
    </Grid>
  );
}

export default BoxDetails;
