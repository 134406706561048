import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { DateRangePicker, RangeKeyDict, Range } from 'react-date-range';
import { styled } from '@mui/system';
import { Popover, useTheme, useMediaQuery } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';

const DateRangeContainer = styled('div')`
  width: fit-content;
  height: 100%;
  border: 0.5px solid;
  border-color: var(--mui-palette-border-light);
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const DateCotainer = styled('span')`
  color: var(--mui-palette-text-light);
`;

interface SBDateRangePickerProps {
  onChange: (rangesByKey: Range, isOpen: boolean) => void;
  value: { startDate: Date; endDate: Date };
  className?: string;
}

function SBDateRangePicker({ onChange, value, className = '' }: SBDateRangePickerProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showCalendar, setShowCalendar] = useState(false);
  const anchorEl = useRef(null);
  const [dateRange, setDateRange] = useState<Range>({});

  useEffect(() => {
    setDateRange({ ...value, key: 'selection' });
  }, [value]);

  const handleOnChange = (ranges: RangeKeyDict) => {
    const { selection } = ranges;
    const { startDate, endDate } = selection;
    setDateRange({ startDate, endDate, key: 'selection' });
    onChange?.({ startDate, endDate }, showCalendar);
  };

  const handleClose = () => {
    const { startDate, endDate } = dateRange || {};
    onChange?.({ startDate, endDate }, false);
    setShowCalendar(false);
  };

  const toggleCalendar = () => {
    setShowCalendar(isOpen => !isOpen);
  };

  return (
    <>
      <DateRangeContainer className={'rounded ' + className} onClick={toggleCalendar} ref={anchorEl}>
        <DateCotainer className="sm-text">{moment(value.startDate).format('DD/MM/YYYY')}</DateCotainer>
        <span style={{ margin: '0 8px', color: theme.palette.text.light }}>-</span>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DateCotainer className="sm-text">{moment(value.endDate).format('DD/MM/YYYY')}</DateCotainer>
          <DateRangeIcon className="ml-1" style={{ color: theme.palette.text.light }} />
        </div>
      </DateRangeContainer>
      <Popover
        id={'date-range'}
        open={showCalendar}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            overflowX: 'auto', // Enable horizontal scrolling
            maxWidth: isSmallScreen ? '100%' : 'fit-content', // Adjust width for small screens
          },
        }}
      >
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          <DateRangePicker
            onChange={handleOnChange}
            moveRangeOnFirstSelection={false}
            months={2} // Show two months for horizontal overflow
            ranges={[dateRange]}
            direction="horizontal" // Ensure horizontal layout
            rangeColors={[theme.palette.secondary.main]}
          />
        </div>
      </Popover>
    </>
  );
}

export default SBDateRangePicker;
