import { Grid, Typography } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

function Warning({ message }: WarningType) {
  return (
    <Grid container justifyContent={{ xs: 'center', sm: 'flex-end' }} alignItems="center" alignContent="center">
      <WarningIcon sx={{ height: 40, width: 45, color: '#FF9900' }} />
      <Grid item ml={2.5}>
        <Typography className="sm-text font-normal" color="#667085">
          {message}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Warning;
