import React, { useContext } from 'react';
// import { KeyboardDoubleArrowLeft as LeftIcon, KeyboardTab as RightIcon } from '@mui/icons-material';
import { Drawer, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { MenuContext } from '../../context/Menu';
import Logo from './Logo';
import Menu from './Menu';

const MenuContainer = styled('div')(`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
`);

const SidebarContainer = styled('div')(() => ({
  backgroundColor: 'var(--mui-palette-background-white)',
  height: '100vh',
  transition: 'width 0.2s ease-in',
  // [theme.breakpoints.down('md')]: {
  //   display: 'none',
  // },
}));

// const MenuCurveShapeContainer = styled('div')`
//   position: absolute;
//   top: 0;
//   right: -40px;
//   background-color: var(--mui-palette-primary-main);
//   z-index: 2;
// `;

// const MenuCurveShapeBox = styled('div')`
//   border: 20px solid var(--mui-palette-common-white);
//   border-top-left-radius: 50%;
// `;

// const MenuResizeBtnContainer = styled('div')`
//   display: flex;
//   flex: 1;
//   padding: 10px;
//   cursor: pointer;
// `;

function Sidebar() {
  const { menuWidth } = useContext(MenuContext);
  return (
    <SidebarContainer style={{ width: `${menuWidth}px` }}>
      <MenuContainer>
        {/* <MenuCurveShapeContainer>
          <MenuCurveShapeBox />
        </MenuCurveShapeContainer> */}
        <div className="w-full">
          <Logo />
          <Menu />
        </div>
      </MenuContainer>
    </SidebarContainer>
  );
}

function ResponsiveSidebar() {
  const theme = useTheme();
  const renderMobileMenu = useMediaQuery(theme.breakpoints.down('md'));
  const { menuWidth, showMobileMenu, setShowMobileMenu } = useContext(MenuContext);
  // const [showMobileMenu, setShowMobileMenu] = useState(true);
  const onClose = function () {
    setShowMobileMenu?.(false);
  };
  return (
    <>
      {renderMobileMenu && (
        <Drawer anchor={'left'} open={showMobileMenu} onClose={onClose}>
          <div style={{ width: `${menuWidth}px`, height: '100%' }}>
            <Sidebar />
          </div>
        </Drawer>
      )}
      {!renderMobileMenu && <Sidebar />}
    </>
  );
}

// function OpenCloseSideBar() {
//   const { isMenuOpen, setIsMenuOpen } = useContext(MenuContext);

//   const onMenuToggle = () => {
//     setIsMenuOpen?.((isMenuOpen: boolean) => !isMenuOpen);
//   };
//   return (
//     <div style={{ alignSelf: isMenuOpen ? 'flex-end' : '', margin: '0 13px' }} className="rounded">
//       <MenuResizeBtnContainer
//         style={{
//           justifyContent: isMenuOpen ? 'flex-end' : 'center',
//         }}
//       >
//         {isMenuOpen ? (
//           <LeftIcon
//             style={{ width: '34px', height: '50px', color: 'var(--mui-palette-background-menuResizeBtn)' }}
//             onClick={onMenuToggle}
//           />
//         ) : (
//           <RightIcon
//             style={{ width: '24px', height: '50px', color: 'var(--mui-palette-background-menuResizeBtn)' }}
//             onClick={onMenuToggle}
//           />
//         )}
//       </MenuResizeBtnContainer>
//     </div>
//   );
// }
export default ResponsiveSidebar;
