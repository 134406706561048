import React from 'react';
import { Grid, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

type TabValues = 'shipper_consignee' | 'shipment_details';
const TABS: { label: string; value: TabValues }[] = [
  { label: 'Shipper/Consignee', value: 'shipper_consignee' },
  { label: 'Shipment Details', value: 'shipment_details' },
];

function ShipmentTabs() {
  const navigation = useNavigate();

  const [currentTap, setCurrentTap] = useState<TabValues>(TABS[0].value);

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    navigation(`?tab=${currentTab}`);
    setCurrentTap(currentTab);
  };

  return (
    <TabContext value={currentTap}>
      <Grid container sx={{ marginTop: 2, borderBottom: '2px solid #E4E4E4', borderRadius: 1 }}>
        <TabList
          onChange={onTabChange}
          indicatorColor="primary"
          TabIndicatorProps={{ sx: { height: '4px' } }}
          scrollButtons="auto"
          variant="scrollable"
        >
          {TABS.map(cTab => (
            <Tab
              sx={{ textTransform: 'none', padding: '16px 16px' }}
              className="md-text font-medium sb-text-black"
              value={cTab.value}
              label={cTab.label}
              key={cTab.value}
            />
          ))}
        </TabList>
      </Grid>
    </TabContext>
  );
}

export default ShipmentTabs;
