import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import API from '@Services/apiAxios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorType } from '../../../types';
import { FCMTokenPayload, NotificationPayload, SuccessResponse } from './Type';

const putNotificationToken = createAsyncThunk<string, FCMTokenPayload, { rejectValue: ErrorType }>(
  'putNotificationToken',
  async (body, { dispatch }) => {
    try {
      const res = await API.put(
        `notification/user/user`,

        body,
      );
      return res.data.msg;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getAllUnreadNotifications = createAsyncThunk<SuccessResponse, NotificationPayload, { rejectValue: ErrorType }>(
  'getAllUnreadNotifications',
  async ({ page = 1, limit = 10 }, { dispatch }) => {
    try {
      const res = await API.get(`notification/all?isRead=false&page=${page}&limit=${limit}`);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getAllReadNotifications = createAsyncThunk<SuccessResponse, NotificationPayload, { rejectValue: ErrorType }>(
  'getAllReadNotifications',
  async ({ page = 1, limit = 10 }, { dispatch }) => {
    try {
      const res = await API.get(`notification/all?isRead=true&page=${page}&limit=${limit}`);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const putAllNotificationsRead = createAsyncThunk<SuccessResponse, undefined, { rejectValue: ErrorType }>(
  'putAllNotificationsRead',
  async (_, { dispatch }) => {
    try {
      const res = await API.put(`notification/all`);
      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit: 10,
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const putNotificationReadById = createAsyncThunk<SuccessResponse, string, { rejectValue: ErrorType }>(
  'putNotificationReadById',
  async (notificationId, { dispatch }) => {
    try {
      const res = await API.put(`notification/${notificationId}`);
      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit: 10,
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const downloadNotificationReportFile = createAsyncThunk('downloadNotificationReportFile', async (reportId: string) => {
  const res = await API.get(`notification/report/invoice?reportId=${reportId}`);
  window.open(res?.data?.data, '_blank');
});

export {
  getAllUnreadNotifications,
  putNotificationReadById,
  putAllNotificationsRead,
  putNotificationToken,
  getAllReadNotifications,
  downloadNotificationReportFile,
};
