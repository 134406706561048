import { ShipmentKycType } from '@Reducers/Shipment/Type';
import { userLogin } from '@Services/hasAdminAccess';
import { DeleteRounded } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { viewSignaleDocument } from '@Reducers/kyc/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@Store';
import { toCamelCase } from '../../utils/ToCamelCase';
const Wrapper = styled(Grid)`
  border-bottom: 1px solid;
  padding: 20px;
  &:last-child {
    border-bottom: none;
  }
`;

const StyleIconButton = styled(IconButton)(({ theme: _ }) => ({
  border: '1px solid #b2b2b2',
  padding: 8,
  borderRadius: 8,
  color: '#000',
  fontSize: '24px',
}));

interface SingleDocumentType {
  label: string;
  documentData: ShipmentKycType;
  required: boolean;
  onDocumentDeleteModalOpen: (documentData: ShipmentKycType) => void;
  onDocumentUploadModalOpen: (label: string, documentData: ShipmentKycType) => void;
  modalView?: boolean;
  IsEditDelteIconShow?: boolean;
}

function SingleDocument(props: SingleDocumentType) {
  const {
    label,
    required,
    documentData,
    onDocumentUploadModalOpen,
    onDocumentDeleteModalOpen,
    IsEditDelteIconShow = true,
  } = props;

  const isAdmin = !userLogin();
  const dispatch = useDispatch<AppDispatch>();

  const onViewDocument = () => {
    // window.open(`${CONFIG.API_URI}/shipment/file/get-url/${documentData?._id}`, '_blank');
    dispatch(viewSignaleDocument({ documentId: documentData?._id, url: 'shipment' }));
  };

  return (
    <Wrapper container item alignItems="flex-center" className={`border-grey`}>
      <Grid container item xs={12} md={8} alignItems="center" spacing={0.5}>
        <Grid item xs={12}>
          <Typography className="md-text font-semibold" sx={{ mb: { xs: 1, md: 0 } }}>
            {label !== 'GSTIN' ? toCamelCase(label) : label} {required ? '*' : ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={4} gap={2} alignSelf="flex-end" justifyContent="flex-end">
        {documentData && (
          <>
            {/* <Link to={`${CONFIG.API_URI}/shipment/file/get-url/${documentData?._id}`} target="_blank"> */}
            <StyleIconButton onClick={onViewDocument}>
              <VisibilityIcon fontSize="inherit" />
            </StyleIconButton>
            {/* </Link> */}
            {isAdmin && IsEditDelteIconShow && (
              <>
                <StyleIconButton onClick={() => onDocumentDeleteModalOpen(documentData)}>
                  <DeleteRounded fontSize="inherit" />
                </StyleIconButton>
                <StyleIconButton onClick={() => onDocumentUploadModalOpen(label, documentData)}>
                  <ModeEditIcon fontSize="inherit" />
                </StyleIconButton>
              </>
            )}
          </>
        )}
      </Grid>
    </Wrapper>
  );
}

export default SingleDocument;
