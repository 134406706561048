import { BulkOrderSummaryResponse, BulkOrderSummaryState } from './Type';

function getBulkOrderSummaryListPending(state: BulkOrderSummaryState) {
  state.loading = true;
}

function getBulkOrderSummaryListFulfilled(state: BulkOrderSummaryState, data: { payload: BulkOrderSummaryResponse }) {
  state.data = data.payload.data;
  state.loading = false;
}

function getBulkOrderSummaryListRejected(state: BulkOrderSummaryState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

export { getBulkOrderSummaryListPending, getBulkOrderSummaryListFulfilled, getBulkOrderSummaryListRejected };
