import { styled } from '@mui/material';

interface BorderedWrapperProps {
  marginBottom?: string;
}

export const BorderedWrapper = styled('div')<BorderedWrapperProps>(({ theme, marginBottom }) => ({
  border: '1px solid',
  borderColor: theme.palette.border.light,
  padding: '50px 40px',
  borderRadius: '8px',
  background: theme.palette.primary.contrastText,
  marginBottom: marginBottom || '40px',
  [theme.breakpoints.down('md')]: {
    padding: '30px 20px',
    marginBottom: marginBottom || '30px',
  },
}));

export const SelectedWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: '8px',
}));

// ############ Shipment History Actions  ############
export const actionIconStyle = { width: '20px', height: '20px', cursor: 'pointer', marginBottom: '8px' };
// ############ Shipment History Actions End  ############
