import RefreshIcon from '@mui/icons-material/Refresh';
import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import SbButton from '../../components/Button';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { userLogin } from '@Services/hasAdminAccess';

function RepeatOrder({ accountId }: { accountId?: string }) {
  const theme = useTheme();

  const { id: shipmentId, sn } = useParams();
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const isAdmin = !userLogin();

  const isRepeatShipmentAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.repeat_shipment,
    PERFORM_ACTION.write,
  );

  const SbActionButtonStyle = {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.text.dark,
    marginLeft: '10px',
  };

  return (
    <Link
      className={`${
        (shipmentById?._accountId?.isAccountHolder && isRepeatShipmentAllowed) || !isAdmin ? '' : 'cursor-not-allowed'
      }`}
      to={
        (shipmentById?._accountId?.isAccountHolder && isRepeatShipmentAllowed) || !isAdmin
          ? `/shipment/create-single?ref=${shipmentId || sn}&accountId=${accountId}`
          : // `/shipment/create-single?ref=${shipmentId || sn}`
            ''
      }
      target={(shipmentById?._accountId?.isAccountHolder && isRepeatShipmentAllowed) || !isAdmin ? '_blank' : ''}
    >
      <SbButton
        containerClass="py-1 px-3 normal-case rounded-xs"
        // sx={{ height: '36px' }}
        variant="contained"
        color="primary"
        size="medium"
        style={SbActionButtonStyle}
        startIcon={<RefreshIcon />}
        // disabled={!isRepeatShipmentAllowed}
        disabled={isAdmin && !shipmentById?._accountId?.isAccountHolder ? true : !isRepeatShipmentAllowed}
      >
        <Typography className="font-semibold md-text">Repeat Order</Typography>
      </SbButton>
    </Link>
  );
}

export default RepeatOrder;
