import { PaletteMode } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { useEffect } from 'react';
import API from '@Services/apiAxios';
import { PrimaryColor, SecondaryColor, ThemesColor } from '../pages/OrganizationSetup/OrganizationSetting/type';
export type HostName = 'allied' | 'wyngs-my' | 'uat-speedbox' | 'localhost' | 'ae';

const getOrgNameFromHostname = (): string => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.');
  return parts[0] || 'default';
};

const DARK_THEME_MODE: PaletteMode = 'dark';
const SPACING = 8;
// Create a theme instance.
const darkTheme = {
  palette: {
    mode: DARK_THEME_MODE,
    spacing: SPACING,
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#ff0000',
    },
    border: { main: '#FFC1C1', light: '#d5d5d5', grey: '#D0D0D0', tableRow: '#eaecf0', tableCell: '#E7E7E7' },
  },
};

interface Border {
  main: string;
  light: string;
  grey: string;
  tableRow: string;
  tableCell: string;
}
interface ShadowList {
  shadow: string;
}
interface Speedbox {
  light: string;
  dark: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    shadowList?: ShadowList;
    border: Border;
  }

  interface PaletteOptions {
    spacing: number;
    border: Border;
  }
  interface TypeBackground {
    white: string;
    black: string;

    light: string;
    dark: string;
    /**MENU */
    selectedMenu: string;
    profileMenu: string;
    hoverMenu: string;
    menuResizeBtn: string;
    note: string;
    grey: string;
    speedbox: Speedbox;

    shadowList?: ShadowList;
  }
  interface TypeText {
    main?: string;
    light?: string;
    dark?: string;
    purple?: string;
    red?: string;
    lightpurple?: string;
    lightred?: string;
  }
}

const LIGHT_THEME_MODE: PaletteMode = 'light';
const lightTheme: (primary: PrimaryColor, secondary: SecondaryColor) => ThemeOptions = (primary, secondary) => ({
  // spacing: 4,

  breakpoints: {
    values: {
      xs: 320,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    spacing: SPACING,
    mode: LIGHT_THEME_MODE,
    primary: {
      main: primary.main,
      dark: primary.dark,
      light: primary.light,
      contrastText: primary.contrastText,
    },
    secondary: {
      main: secondary.main,
      light: secondary.light,
    },
    error: {
      main: '#ff0000',
      light: '#FFECEC',
    },
    info: {
      main: '#BE9C8E',
      light: '#FFFFE9',
    },
    success: {
      light: '#C1F8C0',
      main: '#429E16',
    },
    background: {
      // default: 'rgb(252, 238, 238)',
      light: '#FFF5F5',
      dark: '#FCEEEE',

      white: '#fff',
      black: '#000',

      selectedMenu: 'rgba(255, 255, 255, 0.3)',
      note: '#F1F1F1',
      profileMenu: '#FFF9F9',
      hoverMenu: 'rgba(31, 30, 97, 0.05)',
      menuResizeBtn: '#424242',
      speedbox: {
        light: '#FFEBEE',
        dark: '#CE202E',
      },
      grey: '#DCDFE6',
      shadowList: {
        shadow: `0px 5px 8px 2px rgba(127, 127, 127, 0.13)`,
      },
    },
    text: {
      dark: '#080808',
      light: '#515151',
      purple: '#1F1E61',
      lightpurple: '#1F1E6129',
      red: '#FF0000',
      lightred: '#FF000029',
    },
    border: { main: secondary.main, light: '#d5d5d5', grey: '#D0D0D0', tableRow: '#eaecf0', tableCell: '#E7E7E7' },
  },
  typography: {
    fontSize: 12,
    fontFamily: `'Poppins', sans-serif`,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor: grey[900],
          color: grey[50],
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(14),
          padding: 12,
        }),
        arrow: {
          color: grey[900],
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ theme }) => ({
          border: `2px solid ${theme.palette.background.default}`,
          padding: '0 4px',
          fontSize: theme.typography.pxToRem(14),
          height: 25,
          width: 25,
          borderRadius: 50,
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: () => ({
          '&.h-3-w-3': {
            height: 30,
            width: 30,
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: '10px',
          paddingBottom: '10px',
        },
        multiline: {
          paddingTop: '0',
          paddingBottom: '0',
        },
        root: {
          borderRadius: '4px',
          // height: '44px',
          // paddingLeft: '14px',
          // paddingRight: '14px',
          fontSize: '16px',
          color: '#667085',
        },
        disabled: () => ({
          background: `#EEEEEE`,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#080808',
          fontSize: '14px',
          fontWeight: 500,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderColor: theme.palette.grey[100],
        }),
      },
    },
  },
});

// Utility function to check if an object is empty
function isEmptyObject(obj: object): boolean {
  if (!obj) return true;
  return Object.keys(obj).length === 0;
}

function getTheme(
  mode: PaletteMode,
  themeColor: ThemesColor[],
  setThemeColor: React.Dispatch<React.SetStateAction<ThemesColor[]>>,
): ThemeOptions {
  const orgName = getOrgNameFromHostname() as HostName;
  if (!orgName) return darkTheme;

  const isUserAuthenticated = () => {
    return document.cookie.split('; ').some(row => row.startsWith('sb_token='));
  };

  const fetchThemeColors = async () => {
    try {
      const response = await API.get(`/organization/organization`);
      const { primary, secondary } = response.data.data.theme as ThemesColor;

      setThemeColor([{ primary, secondary }]);
    } catch (error) {
      console.error('Error during dispatch:', error);
    }
  };
  const fetchThemeColorsBeforeAuth = async () => {
    try {
      const response = await API.get(`/organization/save`);
      const { primary, secondary } = response.data.data.theme as ThemesColor;

      setThemeColor([{ primary, secondary }]);
    } catch (error) {
      console.error('Error during dispatch:', error);
    }
  };

  useEffect(() => {
    if (isUserAuthenticated()) {
      fetchThemeColors();
    } else {
      fetchThemeColorsBeforeAuth();
    }
  }, []);

  const defaultPrimary: PrimaryColor = {
    main: '#0000',
    light: '#E8E8FF',
    dark: '#0000',
    contrastText: '#000',
  };

  const defaultSecondary: SecondaryColor = {
    main: '#CE202E',
    light: '#958DA5',
  };

  // if (isEmptyObject(primary) || isEmptyObject(secondary)) return darkTheme;
  if (!themeColor.length || isEmptyObject(themeColor[0]?.primary) || isEmptyObject(themeColor[0]?.secondary)) {
    return lightTheme(defaultPrimary, defaultSecondary);
  }
  const { primary, secondary } = themeColor[0];
  return mode === 'dark' ? darkTheme : lightTheme(primary, secondary);
}

export default getTheme;
