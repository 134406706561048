import React from 'react';
import { TextLoadingProps } from './Type';

export function TextLoading({ children, textStyles }: TextLoadingProps) {
  const strArray = children.split('');

  return (
    <div>
      {strArray.map((s, i) => (
        <span
          style={{
            ...(textStyles && textStyles),
            display: 'inline-block',
            fontSize: '20px',
            animation: 'text-loading-animate 0.7s infinite alternate',
            animationDelay: `${i * 0.1}s`,
            minWidth: '3px',
          }}
        >
          {s}
        </span>
      ))}
    </div>
  );
}
