import React, { ReactElement } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
// import SbImage from '../Image';
// import { useSelector } from 'react-redux';
// import { RootStateType } from '@Store';
import { ContactCardHeader, ContactCardWrapper, ContactCardBody, ContactLink, Root } from './style';
import PoweredBy from '@Components/PoweredBy';
import MailIcon from '@mui/icons-material/Mail';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';

interface ContactCardProps {
  title: string;
  subHeading: string;
  children: ReactElement;
  icon: ReactElement;
}

function ContactCard(props: ContactCardProps) {
  const { title, subHeading, children, icon } = props;
  return (
    <ContactCardWrapper>
      <ContactCardHeader>
        <IconButton aria-label="settings">{icon}</IconButton>
      </ContactCardHeader>
      <ContactCardBody>
        <Typography className="px_20-text font-bold mb-1/2 uppercase" color="#2C2B6D">
          {title}
        </Typography>
        <Typography className="sm-text font-medium mb-1/2" color="#2C2B6D">
          {subHeading}
        </Typography>
        <Typography className="sm-text font-medium">{children}</Typography>
      </ContactCardBody>
    </ContactCardWrapper>
  );
}
const supportEmail = 'support@speedboxapp.com';
const telephoneCode = '270';
const contactNumber = '555-0117';

function OrganizationOnboardPending() {
  // const organizationData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  // const { name = '', logo = '/images/login/logo.png' } = organizationData || {};

  return (
    <Root>
      <Grid container alignItems={'center'} className="h-full">
        <Grid item sm={7}>
          {/* <SbImage src={logo || ''} alt="logo" style={{ aspectRatio: '17 16' }} height={120} /> */}
          <Typography
            sx={{ fontSize: { md: '18px', lg: '26px', xl: '34px' }, color: '#2C2B6D' }}
            className="font-bold mb-2 mt-4"
          >
            Welcome
            {/* to {name} */}
          </Typography>

          <Typography
            sx={{
              fontSize: { md: '20px', lg: '28px', xl: '36px' },
              maxWidth: { md: '80%', xs: '90%' },
              color: '#2C2B6D',
            }}
            className="font-semibold mb-1"
          >
            Organization hasn’t been onboarded
          </Typography>
          <Typography
            sx={{
              fontSize: { md: '17px', lg: '25px', xl: '32px' },
              maxWidth: { md: '80%', xs: '90%' },
              color: '#2C2B6D',
            }}
            className="mb-4"
          >
            Please contact Speedbox team to complete onboard set up.
          </Typography>

          <Grid container>
            <ContactCard
              title="Call us"
              subHeading="Mon-Fri from 8am-9pm"
              icon={<AddIcCallIcon sx={{ color: '#2C2B6D', fontSize: '30px' }} />}
            >
              <ContactLink href={`tel:+${telephoneCode + contactNumber}`}>
                ({telephoneCode}) {contactNumber}
              </ContactLink>
            </ContactCard>

            <ContactCard
              title="Email to support"
              subHeading="We are here to help"
              icon={<MailIcon sx={{ color: '#2C2B6D', fontSize: '30px' }} />}
            >
              <ContactLink href={`mailto:${supportEmail}`}>{supportEmail}</ContactLink>
            </ContactCard>
          </Grid>

          <PoweredBy align="flex-start" />
        </Grid>
        <Grid item sm={5} sx={{ zIndex: -1 }}>
          <img src="/images/login/logistics-2.gif" alt="logistics" style={{ maxHeight: '504px', maxWidth: '504px' }} />
        </Grid>
      </Grid>
    </Root>
  );
}

export default OrganizationOnboardPending;
