import React, { useEffect, useState } from 'react';
import Title from '@Components/Title';
import { BorderedWrapper, SelectedWrapper } from './style';
import { Grid, Typography } from '@mui/material';
// ICONS

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../../store';
import { useParams } from 'react-router-dom';
import HorizontalTrack from '@Components/Tracking/HorizontalTracking';
import SbImage from '@Components/Image';
import ViewAction from '../../../ViewSingleShipment/ViewAction';
import { ScrollableBox } from '../../../CreateShipment/styles';
import { BoxType } from '../../../../store/reducers/Shipment/Type';
import SingleBox from './SingleBox';
import VerticalTracking from '@Components/Tracking/VerticalTracking';
import { trackShipment } from '../../../../store/reducers/Tracking/actions';
import { SHIPMENT_STATUS } from '../../../../constants/Shipment';
import FilterSearch from '@Components/Filter/Search';
import Divider from '@Components/Divider';
import UpdateStatusForm from './UpdateStatusForm';
import useScroll from '../../useScroll';
import { ShipmentControl } from '@Services/shipmentActionControl';
import CreateRTO from './CreateRTO';
import { RTOSuccessModal } from './RTOSuccessModal';
import { EditableAWB } from './type';
import { userLogin } from '@Services/hasAdminAccess';
import { resetRTOShipment } from '@Reducers/AdminShipment';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const SearchFilterList = [
  { label: 'Carrier AWB', value: 'carrierNumber' },
  { label: 'Shipment Number', value: 'shipmentNumber' },
];

function TrackingDetails() {
  const { trackingRef } = useScroll();
  const dispatch = useDispatch<AppDispatch>();
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [selectedBox, setSelectedBox] = useState<BoxType>();
  const [filterSelectValue, setFilterSelectValue] = useState('carrierNumber');
  const [filterText, setFilterText] = useState('');
  const [filteredBoxes, setFilteredBoxes] = useState<BoxType[]>([]);
  const [_showModal, setShowModal] = useState(false);
  const [editableAWB, setEditableAWB] = useState<EditableAWB>({
    state: '',
    boxId: '',
  });
  // const isAdmin = hasAdminAccess();
  const isUser = userLogin();

  const { shipmentById, RTOShipment, carrierNumberUpdated } = useSelector(
    (state: RootStateType) => state.adminShipment,
  );
  const shipmentpayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isTrackingDetailsWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_processing,
    PERFORM_ACTION.write,
  );

  const shipmentActionControl = new ShipmentControl(shipmentById);
  const { data: trackingData, trackingDataByShipmentId } = useSelector((state: RootStateType) => state.trackShipment);
  const courierServiceName = useSelector((state: RootStateType) => state.trackShipment?.data);
  const _userId = useSelector((state: RootStateType) => state.loginUser.profile?._id);
  const statusDetails = trackingData?.statusDetails || [];

  const boxes = shipmentById?._packageId?.box || [];

  const { sn: shipmentId } = useParams();

  useEffect(() => {
    return () => {
      dispatch(resetRTOShipment());
    };
  }, []);

  useEffect(() => {
    if (boxes?.length) {
      const newBoxCopy: BoxType[] = boxes.map(box => ({ ...box, selected: !!box.selected }));
      setFilteredBoxes(newBoxCopy);
      setSelectedBox({ ...newBoxCopy[0] });
      // dispatch(trackMultipleShipments([...boxes.map(i => i.childAWBNumber || i.shipmentNumber || '')]));
    }
  }, [boxes]);

  useEffect(() => {
    if (selectedBox && selectedBox.childAWBNumber) {
      dispatch(trackShipment(selectedBox.childAWBNumber));
    }
  }, [selectedBox]);

  function getFilteredBoxDetails() {
    const temp: BoxType[] | undefined =
      filterText && filterSelectValue === 'carrierNumber'
        ? shipmentById?._packageId?.box.filter(i => i?.carrierNumber?.toLowerCase().includes(filterText))
        : filterText && filterSelectValue === 'shipmentNumber'
        ? shipmentById?._packageId?.box.filter(i => i?.childAWBNumber?.includes(filterText))
        : boxes;
    if (temp) setFilteredBoxes(temp);
  }

  let lastTrackingStatus = '',
    lastTrackingMessage = '',
    lastTrackingLocation = '';

  if (statusDetails.length) {
    lastTrackingStatus = SHIPMENT_STATUS[statusDetails[0].status as keyof typeof SHIPMENT_STATUS]?.label;
    lastTrackingMessage = statusDetails[0].msg;
    lastTrackingLocation = statusDetails[0].location;
  }

  useEffect(() => {
    if (carrierNumberUpdated && editableAWB.state) {
      setEditableAWB({ state: '', boxId: '' });
    }
  }, [carrierNumberUpdated]);

  return (
    <Grid container ref={trackingRef}>
      <Title title="Tracking Details" removeBorder />
      <BorderedWrapper
        container
        id="tracking_detail"
        rowGap={3}
        className="p-4 mb-3 border border-solid border-light rounded"
      >
        <div>
          <Typography className="l-text font-normal">{lastTrackingStatus}</Typography>
          <Typography className="md-text font-normal sb-text-gray">{lastTrackingMessage}</Typography>
        </div>
        <Grid container>
          <HorizontalTrack data={trackingData?.statusArray || []}></HorizontalTrack>
          {lastTrackingLocation && statusDetails?.length > 1 && (
            <Typography className="w-full flex sb-text-gray" justifyContent="flex-end">
              {lastTrackingLocation}
            </Typography>
          )}
        </Grid>
        <Grid container alignItems="baseline">
          <Grid item xs={6}>
            {shipmentById?.sellingPricing?.serviceImage && (
              <SbImage
                src={shipmentById?.sellingPricing.serviceImage}
                alt="logo"
                // style={{ marginBottom: '20px' }}
                height={70}
                width={150}
              />
            )}
            {courierServiceName && (
              <Typography className="md-text" sx={{ color: '#7D878C' }}>
                {courierServiceName?.courierServiceName}
              </Typography>
            )}
            {/* <SbImage
              src="/images/speedbox_logo.png"
              alt="logo"
              // style={{ marginBottom: '20px' }}
              height={isMobile ? 20 : 30}
            /> */}
          </Grid>
          <Grid item xs={6} textAlign="end">
            <ViewAction setViewMore={setViewMore} viewMore={viewMore} />
          </Grid>
        </Grid>

        {viewMore && (
          <>
            {/* {isAdmin && ( */}
            {!isUser && (
              <>
                <Divider className="my-4" />
                <UpdateStatusForm
                  shipmentDetails={{
                    shipmentNumber: shipmentId || '',
                    userId: _userId || '',
                    boxes: boxes,
                  }}
                  disableActions={
                    shipmentById?.status !== 'forwarded' &&
                    shipmentById?._accountId?.isPrepaid &&
                    Number((shipmentpayment?.payableAmount ?? 0) - (shipmentpayment?.tdsAmount ?? 0)) > 0
                      ? false
                      : shipmentActionControl.canUpdateTrackingDetails
                  }
                  latestStatus={statusDetails[0]?.status}
                  previousStatus={statusDetails[1]?.status}
                />
              </>
            )}
            <Grid container className="my-3" spacing={2}>
              <Grid item lg={4} xl={3} md={4} sm={12} xs={12}>
                <FilterSearch
                  onSelectChange={(value: string) => {
                    setFilterSelectValue(value);
                  }}
                  list={SearchFilterList}
                  selectValue={filterSelectValue}
                  textValue={filterText}
                  onTextChange={e => {
                    setFilterText(e.target.value);
                  }}
                  onTextSearch={() => {
                    getFilteredBoxDetails();
                  }}
                  onTextClear={() => {
                    setFilterText('');
                    setFilteredBoxes(boxes);
                  }}
                  disabled={!isTrackingDetailsWriteAllowed}
                />
                <ScrollableBox container id={'fadedScroll'} sx={{ pr: { xs: 1, md: 2 }, mt: 2 }} rowGap={2}>
                  {filteredBoxes?.map((box: BoxType, i: number) => {
                    return (
                      <SingleBox
                        key={`tracking-box-${i}`}
                        box={{ ...box, id: i.toString() }}
                        setSelectedBox={setSelectedBox}
                        childAWBNumber={box.childAWBNumber || ''}
                        carrierNumber={box.carrierNumber || ''}
                        trackingData={
                          box.childAWBNumber && trackingDataByShipmentId
                            ? trackingDataByShipmentId[box.childAWBNumber]
                            : null
                        }
                        isSelected={selectedBox?.id === i.toString()}
                        setEditableAWB={setEditableAWB}
                        editableAWB={editableAWB}
                        shipmentNumber={shipmentId || ''}
                        disabled={!isTrackingDetailsWriteAllowed}
                      />
                    );
                  })}
                </ScrollableBox>
              </Grid>
              <Grid item lg={8} xl={9} md={8} sm={12} xs={12}>
                <SelectedWrapper sx={{ p: 2 }}>
                  {/* //minWidth: '700px', overflowX: 'auto' */}
                  <BorderedWrapper sx={{ py: 3, px: 2.5, width: '100%', overflowX: 'auto' }}>
                    {trackingData?.reverseDateWise ? (
                      <VerticalTracking
                        data={trackingData?.reverseDateWise}
                        rtoShipmentNumber={shipmentById?.rtoShipmentNumber || ''}
                      />
                    ) : (
                      <></>
                    )}
                  </BorderedWrapper>
                </SelectedWrapper>
              </Grid>
            </Grid>
            {shipmentById?.status === 'rto' && !shipmentById?.rtoShipmentNumber && <CreateRTO />}
            {RTOShipment && (
              <RTOSuccessModal
                open={!!RTOShipment}
                shipmentNumber={RTOShipment}
                handleClose={() => setShowModal(false)}
              />
            )}
          </>
        )}
      </BorderedWrapper>
    </Grid>
  );
}

export default TrackingDetails;
