import SBDropdown from '@Components/Dropdown';
import { updateFirstConnectingWarehouse } from '@Reducers/AdminShipment/actions';
import { getFullAddress } from '@Reducers/Warehouse/helper';
import { AppDispatch, RootStateType } from '@Store';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { Typography } from '@mui/material';
import { setIsWarehouseSelectedINFedX } from '@Reducers/Warehouse';

function FirstConnectiongWearhouseDropdown({ selectedWarehouse = '' }: { selectedWarehouse?: string }) {
  const [connectWarehouse, setConnectWarehouse] = React.useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const { warehouseListForShipent, warehouseListForShipmentOptionsHashById, isWarehouseSelectedINFedX } = useSelector(
    (state: RootStateType) => state.Warehouse,
  );
  //   const isFinalForwardEditable = useSelector((state: RootStateType) => state.adminShipment.editFinalForward);
  const countriesObj = useSelector((state: RootStateType) => state.global?.countriesObj);
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment?.shipmentById);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentProcessingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_processing,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    if (shipmentById?.firstConnectingWarehouse) {
      setConnectWarehouse(shipmentById?.firstConnectingWarehouse._warehouseId);
    }
  }, [shipmentById?.firstConnectingWarehouse]);

  const warehouseListOptions = useMemo(() => {
    if (!warehouseListForShipent.list.length || !shipmentById?.buyingPricing) return [];

    const outboundSystem = shipmentById?.buyingPricing.outbound;
    const warehouseList = warehouseListForShipent.list
      .filter(wh => wh._systemId.includes(outboundSystem))
      .filter(wh => wh._id !== selectedWarehouse)
      .map(({ name, _id, _addressId }) => ({
        display: name,
        value: _id,
        secondary: getFullAddress({ ..._addressId, country: countriesObj?.[_addressId?.country]?.name || '' }),
      }));

    return warehouseList;
  }, [warehouseListForShipent.list, shipmentById?.buyingPricing]);

  const onFirstConnectingWarehouseChange = ([warehouseAddress]: string[]) => {
    setConnectWarehouse(warehouseAddress);
    dispatch(setIsWarehouseSelectedINFedX('true'));

    dispatch(
      updateFirstConnectingWarehouse({
        shipmentNumber: shipmentById?.shipmentNumber || '',
        _warehouseId: warehouseAddress,
        _warehouseAddressId: warehouseListForShipmentOptionsHashById[warehouseAddress]._addressId._id,
      }),
    );
  };
  const isFinalForwardEditable = useSelector((state: RootStateType) => state.adminShipment.editFinalForward);

  useEffect(() => {
    if (connectWarehouse) {
      dispatch(setIsWarehouseSelectedINFedX('true'));
    } else {
      // dispatch(setIsWarehouseSelectedINFedX('false'));
    }
  }, [isWarehouseSelectedINFedX]);

  return (
    <div className="flex flex-col">
      <SBDropdown
        required
        label="Select Origin Warehouse"
        id="Select-Connecting-Warehouse"
        placeholder="Warehouse"
        options={warehouseListOptions}
        value={connectWarehouse ? [connectWarehouse] : []}
        onChange={onFirstConnectingWarehouseChange}
        disabled={
          isShipmentProcessingWriteAllowed
            ? shipmentById?.isFinalForward && !isFinalForwardEditable
            : !isShipmentProcessingWriteAllowed
        }
      />
      {isWarehouseSelectedINFedX === 'false' && (
        <Typography className="p-2" sx={{ color: 'error.main' }}>
          Please Select Warehouse
        </Typography>
      )}
    </div>
  );
}

export default FirstConnectiongWearhouseDropdown;
