import Joi from 'joi';

export const ShipmentUserSchema = Joi.object().keys({
  name: Joi.string().required().messages({ 'string.empty': `Please enter first name` }),
  companyName: Joi.optional(),
  line1: Joi.string().required().messages({ 'string.empty': `Please enter address` }),
  line2: Joi.optional(),
  city: Joi.string().required().messages({ 'string.empty': `Please enter city` }),
  // state: Joi.string().required().messages({ 'string.empty': `Please enter state` }),
  state: Joi.optional(),
  country: Joi.string().required().messages({ 'string.empty': `Please enter country` }),
  pincode: Joi.string().required().messages({ 'string.empty': `Please enter pincode` }),
  phoneNumber: Joi.number().required().messages({ 'number.base': `Please enter phone number` }),
  telephoneCode: Joi.number().required().messages({ 'number.base': `Please select  telephone code` }),
  altContactName: Joi.optional(),
  altContactNo: Joi.alternatives().conditional('altContactName', [
    {
      is: Joi.string(),
      then: Joi.string().required().messages({ 'string.empty': `Please enter phone number` }),
    },
    {
      is: '',
      then: Joi.optional(),
    },
  ]),
  altContactTelephoneCode: Joi.alternatives().conditional('altContactName', [
    {
      is: Joi.string(),
      then: Joi.string().required().messages({ 'string.empty': `Please select  telephone code` }),
    },
    {
      is: '',
      then: Joi.optional(),
    },
  ]),
  IdNumber: Joi.optional(),
  IdType: Joi.optional(),
  type: Joi.optional(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .allow('')
    .regex(/^[^\s@]{3,}@[^@\s]+\.[^\s@]{2,}$/)
    .messages({
      'string.pattern.base': 'Invalid email address ', // Override default message for pattern validation
      'string.email': 'Please enter email', // Default message for email validation
    }),
});
