import React, { useEffect } from 'react';
import Contact from '@Components/Contact';
import Divider from '@Components/Divider';
import SbTextField from '@Components/Textfield';
import { Checkbox, FormControlLabel, Grid, InputLabel } from '@mui/material';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues } from 'formik';
import SBDropdown from '@Components/Dropdown';
import { DangerousGoodsType, LithiumBatteriesOptions, SpecialType } from './constants';
import { FedXInformationType, locationsResponse } from '@Reducers/AdminShipment/Type';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { getLocations } from '@Reducers/AdminShipment/actions';
import { CSP_TYPES } from '@Constants/Shipment';
import dayjs from 'dayjs';

interface propsTypes {
  values: FormikValues;
  errors: FormikErrors<FedXInformationType>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<FedXInformationType>['setFieldValue'];
  disabled: boolean;
}

const ShipmentTypeFields = (props: propsTypes) => {
  const { handleChange, setFieldValue, values, errors, disabled } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { shipmentById, locations } = useSelector((state: RootStateType) => state.adminShipment);
  const _consigneeId = shipmentById?._consigneeId;

  useEffect(() => {
    if (shipmentById?.shipmentNumber && (values.specialHandlingOptions as string[])?.includes('HOLD_AT_LOCATION')) {
      dispatch(getLocations({ shipmentNumber: shipmentById?.shipmentNumber, csp: CSP_TYPES.fedex.value }));
    }
  }, [shipmentById?.shipmentNumber, values.specialHandlingOptions, CSP_TYPES.fedex.value]);

  const onFillBSOAddress = () => {
    setFieldValue('broker.phoneNumber', _consigneeId?.phone.number);
    setFieldValue('broker.telephoneCode', _consigneeId?.phone.telephoneCode);
    setFieldValue('broker.name', _consigneeId?.name);
    setFieldValue('broker.companyName', _consigneeId?.companyName);
    setFieldValue('broker.line1', _consigneeId?.line1);
    setFieldValue('broker.line2', _consigneeId?.line2);
    setFieldValue('broker.city', _consigneeId?.city);
    setFieldValue('broker.pincode', _consigneeId?.pincode);
    setFieldValue('broker.state', _consigneeId?.state);
    setFieldValue('broker.country', _consigneeId?.country);
    setFieldValue('broker.email', _consigneeId?.email);
  };

  const onRemoveBSOAddress = () => {
    setFieldValue('broker.phoneNumber', '');
    setFieldValue('broker.telephoneCode', '');
    setFieldValue('broker.name', '');
    setFieldValue('broker.companyName', '');
    setFieldValue('broker.line1', '');
    setFieldValue('broker.line2', '');
    setFieldValue('broker.city', '');
    setFieldValue('broker.pincode', '');
    setFieldValue('broker.state', '');
    setFieldValue('broker.country', '');
    setFieldValue('broker.email', '');
  };

  return (
    <>
      {(values.shipmentType as string[])?.includes('DG shipments') && (
        <Grid container item columnSpacing={10}>
          <Grid item xs={12}>
            <Divider className="my-3" />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBDropdown
              disabled={disabled}
              id="dangerousGoodsType"
              required
              label="Dangerous Goods Type"
              placeholder="Shipment Type"
              name="dangerousGoodsType"
              options={DangerousGoodsType}
              value={[values.dangerousGoodsType]}
              onChange={value => {
                setFieldValue('dangerousGoodsType', value[0]);
              }}
              error={errors.dangerousGoodsType}
            />
          </Grid>
        </Grid>
      )}
      {((values.shipmentType as string[])?.includes('Commercial') ||
        (values.shipmentType as string[])?.includes('DRY_ICE')) && (
        <Grid container item columnSpacing={10}>
          <Grid item xs={12}>
            <Divider className="my-3" />
          </Grid>
          {(values.shipmentType as string[])?.includes('Commercial') && (
            <Grid item xs={12} md={6}>
              <InputLabel
                sx={{
                  fontSize: '1rem',
                }}
              >
                Commercial Shipments
              </InputLabel>
              <SbTextField
                disabled={disabled}
                label={`Commercial Routing No`}
                placeholder="1738990036"
                onChange={handleChange}
                value={values?.commercialRoutingNo}
                error={errors.commercialRoutingNo}
                name="commercialRoutingNo"
              />
            </Grid>
          )}
          {(values.shipmentType as string[])?.includes('DRY_ICE') && (
            <Grid item xs={12} md={6}>
              <InputLabel
                sx={{
                  fontSize: '1rem',
                }}
              >
                Dry Ice Shipments{' '}
              </InputLabel>
              <SbTextField
                disabled={disabled}
                label={`Box 1 Dry Ice Weight (kg)`}
                value={values.dryIceShipments[0]}
                placeholder="1738990036"
                onChange={event => {
                  setFieldValue('dryIceShipments', [event.target.value]);
                }}
                error={Array.isArray(errors.dryIceShipments) ? errors.dryIceShipments[0] : errors.dryIceShipments}
              />
            </Grid>
          )}
        </Grid>
      )}

      {(values.shipmentType as string[])?.includes('Lithium Batteries') && (
        <Grid container item columnSpacing={10}>
          <Grid item xs={12}>
            <Divider className="my-3" />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBDropdown
              disabled={disabled}
              id="battery"
              multiple
              label="Lithium Batteries"
              placeholder="Select Type"
              name="battery"
              options={LithiumBatteriesOptions}
              value={values.battery}
              onChange={value => {
                setFieldValue('battery', value);
              }}
              error={Array.isArray(errors.battery) ? errors.battery[0] : errors.battery}
            />
          </Grid>
        </Grid>
      )}

      {(values.shipmentType as string[])?.includes('BROKER_SELECT_OPTION') && (
        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12}>
            <Divider className="my-3" />
            <InputLabel
              sx={{
                fontSize: '1rem',
              }}
            >
              BSO Shipment Details
            </InputLabel>

            <FormControlLabel
              disabled={disabled}
              control={
                <Checkbox
                  onChange={event => {
                    if (event.target.checked) {
                      onFillBSOAddress();
                    } else {
                      onRemoveBSOAddress();
                    }
                  }}
                />
              }
              label="Broker Address same as Consignee Address"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              required
              label={`Broker Account No`}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.accountNo"
              value={values?.broker?.accountNo}
              error={errors?.broker?.accountNo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              label={`Type of shipment `}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.shipmentType"
              value={values?.broker?.shipmentType}
              error={errors?.broker?.shipmentType}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              required
              label={`Broker Tin No`}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.tinNo"
              value={values?.broker?.tinNo}
              error={errors?.broker?.tinNo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Contact
              disabled={disabled}
              label={`Broker Contact  No*`}
              phoneName="broker.phoneNumber"
              phoneNumber={values.broker?.phoneNumber || ''}
              onChangePhoneNumber={handleChange}
              telCountryCodeName="broker.telephoneCode"
              telCountryCode={values.broker?.telephoneCode || ''}
              onChangeTelCountryCode={handleChange}
              setDefaultTelephone={(value: number) => setFieldValue('broker.telephoneCode', value.toString())}
              error={errors.broker?.phoneNumber || errors.broker?.telephoneCode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              label={`Broker Contact ID`}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.contactId"
              value={values?.broker?.contactId}
              error={errors?.broker?.contactId}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              required
              label={`Broker Company Name`}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.companyName"
              value={values?.broker?.companyName}
              error={errors?.broker?.companyName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              label={`Broker Name`}
              required
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.name"
              value={values?.broker?.name}
              error={errors?.broker?.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              label={`Broker Title`}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.title"
              value={values?.broker?.title}
              error={errors?.broker?.title}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              label={`Broker Fax No`}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.faxNo"
              value={values?.broker?.faxNo}
              error={errors?.broker?.faxNo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              required
              label={`Broker Email Id `}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.email"
              value={values?.broker?.email}
              error={errors?.broker?.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              required
              label={`Broker Address Line 1 `}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.line1"
              value={values?.broker?.line1}
              error={errors?.broker?.line1}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              label={`Broker Address Line 2 `}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.line2"
              value={values?.broker?.line2}
              error={errors?.broker?.line2}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              label={`Broker City`}
              required
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.city"
              value={values?.broker?.city}
              error={errors?.broker?.city}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              label={`Broker State `}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.state"
              value={values?.broker?.state}
              error={errors?.broker?.state}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              required
              label={`Broker Pincode `}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.pincode"
              value={values?.broker?.pincode}
              error={errors?.broker?.pincode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SbTextField
              disabled={disabled}
              required
              label={`Broker Country `}
              placeholder="1738990036"
              onChange={handleChange}
              name="broker.country"
              value={values?.broker?.country}
              error={errors?.broker?.country}
            />
          </Grid>
        </Grid>
      )}

      {(values.shipmentType as string[])?.includes('Others') && (
        <Grid container item columnSpacing={10}>
          <Grid item xs={12}>
            <Divider className="my-3" />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel
              sx={{
                fontSize: '1rem',
              }}
            >
              Special Handlings Option
            </InputLabel>
            <SBDropdown
              disabled={disabled}
              id="specialHandlingOptions"
              required
              multiple
              label="Special Type"
              placeholder="Special Type"
              name="specialHandlingOptions"
              options={SpecialType?.filter(item =>
                dayjs().format('d') === '4' ? (item?.value === 'SATURDAY_DELIVERY' ? false : true) : true,
              )}
              value={values.specialHandlingOptions}
              onChange={value => {
                setFieldValue('specialHandlingOptions', value);
              }}
              error={
                Array.isArray(errors.specialHandlingOptions)
                  ? errors.specialHandlingOptions[0]
                  : errors.specialHandlingOptions
              }
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <Grid item xs={12} md={5.5}>
              {(values.specialHandlingOptions as string[])?.includes('HOLD_AT_LOCATION') && (
                <SBDropdown
                  disabled={disabled}
                  id="locationId"
                  label={`Select Location*`}
                  required
                  placeholder="1254621561661"
                  name="locationId"
                  value={[values.locationId]}
                  onChange={value => setFieldValue('locationId', value[0])}
                  error={errors.locationId}
                  options={locations?.map((item: locationsResponse) => {
                    return {
                      value: item.locationId,
                      display: item.contactAndAddress?.contact?.companyName,
                      secondary: `${item.contactAndAddress?.address?.streetLines} ${item.contactAndAddress?.address?.city}, ${item.contactAndAddress?.address?.stateOrProvinceCode}-${item.contactAndAddress?.address?.postalCode}`,
                    };
                  })}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ShipmentTypeFields;
