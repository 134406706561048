import React from 'react';
import { Close } from '@mui/icons-material';
import { Grid, Link, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootStateType } from '@Store';

import Modal from '@Components/Modal';
import SbTextField from '@Components/Textfield';
import SbButton from '@Components/Button';
import Image from '@Components/Image';
import CopyCredentials from '@Components/CopyCredentials';

import { InviteAdminSchema } from './schema';
import { userSendInvite } from '@Reducers/User/actions';

function SuccessModal(props: { isOpen: boolean; handleClose: () => void; msg?: string; userEmail: string }) {
  const { data } = useSelector((state: RootStateType) => state.user.accountCreated);
  const profileState = useSelector((state: RootStateType) => state.loginUser.profile);
  const inviteLink = data?.inviteLink || '';
  //ask link details
  const credentialsToShareOnWhatsApp = `Dear ${data?.email},%0A%0AYour team member ${profileState?.name} has created an account for you. Invitation link is ${inviteLink}`;
  const dispatch = useDispatch<AppDispatch>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: '' },
    validationSchema: InviteAdminSchema,
    onSubmit(values) {
      onSendInvite(values);
    },
  });
  const { handleSubmit, handleChange, values: formkikValues, errors } = formik;

  const onSendInvite = ({ email }: { email: string }) => {
    if (props.userEmail) {
      const payload = {
        email: email,
        userEmail: props.userEmail,
      };
      dispatch(userSendInvite(payload)).then(val => {
        if (val.type === 'adminSendInvite/fulfilled') {
          props.handleClose();
        }
      });
    }
  };

  return (
    <Modal
      open={props.isOpen}
      maxWidth="sm"
      dialogContainerSx={{
        paddingTop: 1,
        paddingRight: 1,
        paddingLeft: 3,
      }}
      onClose={props.handleClose}
    >
      <Grid container justifyContent="flex-end">
        <Close onClick={props.handleClose} />
        <Grid item xs={12}>
          {/* <Typography className="l-text font-semibold  mr-1">You’ve successfully created an account!</Typography> */}
          <Typography className="l-text font-semibold  mr-1">
            {props?.msg ? props?.msg : 'You’ve successfully sent an invite!'}
          </Typography>
        </Grid>

        <Grid container item className="mt-4 mb-2" rowGap={2} columnGap={2}>
          <Grid item xs={12} sm={6}>
            <SbTextField
              required
              name="email"
              placeholder="contact@email.com"
              value={formkikValues.email}
              onChange={handleChange}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <SbButton
              fullWidth
              variant="contained"
              sx={{ borderRadius: '8px !important' }}
              onClick={() => handleSubmit()}
            >
              Send Invite
            </SbButton>
          </Grid>
        </Grid>

        <Grid container item className="mt-4" alignItems="baseline">
          <Grid item xs={3} sm={2}>
            <CopyCredentials label="Copy link" textToCopy={inviteLink}></CopyCredentials>
          </Grid>
          <Grid item xs={3} sm={2} textAlign="center">
            <Link
              href={`https://web.whatsapp.com/send?text=${credentialsToShareOnWhatsApp}`}
              underline="none"
              target="_blank"
              rel="nofollow noopener"
            >
              <Image src="/images/icons/social_media/WhatsApp.svg" alt="WhatsApp" />
              <Typography className="xs-text  mt-1\/2">WhatsApp</Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
export default SuccessModal;
