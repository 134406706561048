import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '../../../services/apiAxios';
import { InvoiceListQuery, CreateNonAccountInvoice, CreateAccountHolderInvoice, InvoiceDownloadPayload } from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { getShipmentWebsocketStream } from '@Reducers/AdminShipment/actions';

// const getInvoiceList = createAsyncThunk('getInvoiceList', async (_queryParams: InvoiceListQuery) => {
//   const url = '/invoice/unbilled';

//   const res = await API.get(`${url}?`, { params: _queryParams });
//   return res.data;
//   return {
//     msg: 'invoices fetched successfully',
//     data: {
//       invoiceResult: [],
//       totalCount: 0,
//       currentPage: 1,
//       perPage: _queryParams.perPage,
//     },
//   };
// });

const getNonAccountUnbilledList = createAsyncThunk(
  'getNonAccountUnbilledList',
  async (_queryParams: InvoiceListQuery, { dispatch }) => {
    try {
      const url = '/invoice/non-account/unbilled';
      const res = await API.get(`${url}?`, { params: _queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const downloadInvoiceDoc = createAsyncThunk(
  'InvoiceDownload',
  async ({ invoiceNo, annexure }: InvoiceDownloadPayload, { dispatch }) => {
    try {
      const queryParams = { invoiceNo, annexure };
      const resp = await API.get(`invoice/download`, { params: queryParams });
      window.open(resp.data.url, '_blank');
      return;
    } catch (error) {
      const errorRes = error.response?.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

const createNonAccountInvoice = createAsyncThunk(
  'createNonAccountInvoice',
  async (payload: CreateNonAccountInvoice, { dispatch }) => {
    try {
      const res = await API.post('/invoice/non-account/create', payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      dispatch(getShipmentWebsocketStream(payload?.shipmentNumbers?.map(item => 'shipment-update-' + item)));
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const createAccountInvoice = createAsyncThunk(
  'createAccountInvoice',
  async (payload: CreateAccountHolderInvoice, { dispatch }) => {
    try {
      const res = await API.post('/invoice/account/create', payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      dispatch(
        getShipmentWebsocketStream(
          payload?.accountData?.flatMap(element => element?._shipmentNumbers?.map(item => 'shipment-update-' + item)),
        ),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

// const fetchInvoiceList = createAsyncThunk('fetchInvoiceList', async (_queryParams: InvoiceListQuery, { dispatch }) => {
//   try {
//     const url = '/invoice/account/list';
//     const res = await API.get(`${url}?`, { params: { ..._queryParams, isAccountHolder: true } });
//     return res.data;
//   } catch (error) {
//     const errorRes = error.response.data;
//     dispatch(
//       SuccessErrorModalOpen({
//         type: 'error',
//         title: 'Oops!',
//         subTitle: errorRes?.msg || 'Something went to wrong.',
//       }),
//     );
//     throw error;
//   }
// });

const voidInvoice = createAsyncThunk('voidInvoice', async (payload: { _id: string }, { dispatch }) => {
  const url = `/invoice/delete/${payload._id}`;

  try {
    const res = await API.delete(url);

    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: 'Invoice deleted successfully.',
      }),
    );
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

// const sendAccountDataTo365 = createAsyncThunk('sendAccountDataTo365', async (payload, { dispatch }) => {
//   try {
//     const res = await API.post('/invoice/account/d365', payload);
//     dispatch(
//       SuccessErrorModalOpen({
//         type: 'success',
//         title: 'Success',
//         subTitle: res.data.msg,
//       }),
//     );
//     return res.data;
//   } catch (error) {
//     const errorRes = error.response.data;
//     dispatch(
//       SuccessErrorModalOpen({
//         type: 'error',
//         title: 'Oops!',
//         subTitle: errorRes?.msg || 'Something went to wrong.',
//       }),
//     );
//     throw error;
//   }
// });

type AccountD365Data = {
  accountNo: string;
  shipmentIds: string[];
};

const sendAccountDataTo365 = createAsyncThunk(
  'sendAccountDataTo365',
  async (postData: AccountD365Data[], { dispatch }) => {
    try {
      const res = await API.post('/invoice/account/365', postData);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

const sendNonAccountDataTo365 = createAsyncThunk(
  'sendNonAccountDataTo365',
  async (postData: { shipmentIds: string[] }, { dispatch }) => {
    try {
      const res = await API.post('/invoice/account/365', postData);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

// const sendDataTo365 = createAsyncThunk('getInvoiceList', async (_queryParams: InvoiceListQuery) => {
//   const url = '/invoice/unbilled';

//   const res = await API.get(`${url}?`, { params: _queryParams });
//   return res.data;
//   return {
//     msg: 'invoices fetched successfully',
//     data: {
//       invoiceResult: [],
//       totalCount: 0,
//       currentPage: 1,
//       perPage: _queryParams.perPage,
//     },
//   };
// });

export {
  // getInvoiceList,
  getNonAccountUnbilledList,
  // fetchInvoiceList,
  sendAccountDataTo365,
  sendNonAccountDataTo365,
  createNonAccountInvoice,
  createAccountInvoice,
  voidInvoice,
  downloadInvoiceDoc,
};
