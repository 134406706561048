import React, { useCallback, useState } from 'react';
import { FormHelperText, Grid, Typography } from '@mui/material';

import { alpha, styled } from '@mui/material/styles';
import Image from '../Image';
import getFileData, { FileResultType } from '../../Helper/file';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

export interface DragDropProps {
  accept:
    | string
    | {
        [key: string]: [];
      };
  subText: string;
  onChange: (fileData: FileResultType[], event?: DropEvent | React.ChangeEvent<HTMLInputElement>) => void;
  readType?: 'xls' | 'base64';
  wrapperOnClickRegister?: boolean;
}

const Wrapper = styled(Grid)(({ theme }) => ({
  padding: '16px 24px',
  border: `1px solid #EAECF0`,
  borderRadius: '8px',
  cursor: 'pointer',
  backgroundColor: alpha(theme.palette.primary.main, 0.03),
}));

const Label = styled('label')(({ theme: _ }) => ({
  width: '100%',
  height: 'inherit',
}));

function DragDrop(props: DragDropProps) {
  const [error, setError] = useState<FileRejection[]>();
  const onDrop = useCallback(
    async (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent | React.ChangeEvent<HTMLInputElement>,
    ) => {
      if ('preventDefault' in event && typeof event.preventDefault === 'function') {
        event.preventDefault();
      }

      setError(fileRejections);
      const fileResult = (await getFileData(acceptedFiles, props.readType)) as FileResultType[];
      props.onChange(fileResult, event);
    },
    [props.onChange],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: typeof props.accept === 'object' ? props.accept : { [props.accept]: [], 'application/pdf': [] },
  });

  const rootProps = getRootProps();

  const handleInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = '';
  };

  return (
    <Label>
      <Wrapper
        container
        alignContent="center"
        height="inherit"
        alignItems="center"
        onDrop={rootProps.onDrop}
        style={{ overflow: 'hidden' }}
        onClick={props.wrapperOnClickRegister ? rootProps.onClick : undefined}
      >
        <Grid container item justifyContent="center">
          {/* <CloudUploadIcon  sx={{ width: '40px', height: '40px' }} /> */}
          <Image src={'/images/upload_document.svg'} alt={'Speedbox'} width={60} height={60} />
        </Grid>
        <Grid container item justifyContent="center" className="font-semibold mt-1">
          <Typography variant="inherit" color="#5591F0" mr="5px">
            Click to Upload
          </Typography>
          or Drag & drop file
        </Grid>
        <Grid container item mt={2} justifyContent="center">
          <Typography variant="subtitle2" className="sb-text-gray">
            {props.subText}
          </Typography>
        </Grid>
        <input
          // {...getInputProps()}
          {...getInputProps({ onClick: handleInputClick })}
          accept={typeof props.accept === 'object' ? Object(props.accept).keys : props.accept}
          style={{ display: 'none' }}
        />
      </Wrapper>
      {error?.length ? (
        <>
          {
            //--------- need to implement for multiselect -----------
            error[0].errors.map(err => (
              <FormHelperText error>
                <>{err.message}</>
              </FormHelperText>
            ))

            // <FormHelperText error>
            //   <>Please Upload xlsx or xls file</>
            // </FormHelperText>
          }
        </>
      ) : (
        <></>
      )}
    </Label>
  );
}

export default DragDrop;
