import { SBTable } from '@Components/Table/Table';
import { TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { resetViewAccess, resetWriteAccess, setViewAccess, setWriteAccess } from '@Reducers/AccessGroup';
import { ActionIdsType, actionListEntity } from '@Reducers/AccessGroup/Type';
import { checkAllowedActions } from '@Reducers/AccessGroup/case';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Checkbox, Grid, TableBody } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function Actions({
  data,
  pageId,
  isAllViewTrue,
}: {
  data: actionListEntity;
  pageId: string;
  isAllViewTrue: boolean;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { actionsIds } = useSelector((state: RootStateType) => state.accessGroup);
  const isViewAccessEnabled = actionsIds?.some((item: ActionIdsType) => {
    const { isReadOnly } = checkAllowedActions(item?.selectedActions);
    return item?._id === data?._id && isReadOnly;
  });
  const isWriteAccessEnabled = actionsIds?.some((item: ActionIdsType) => {
    const { isWriteOnly } = checkAllowedActions(item?.selectedActions);
    return item?._id === data?._id && isWriteOnly;
  });
  const handleViewChange = () => {
    if (isViewAccessEnabled) {
      dispatch(
        resetViewAccess({
          pageId: pageId,
          actionId: data?._id,
        }),
      );
    } else {
      dispatch(
        setViewAccess({
          actionId: data?._id,
        }),
      );
    }
  };
  const handleEditChange = () => {
    if (isWriteAccessEnabled) {
      dispatch(
        resetWriteAccess({
          pageId: pageId,
          actionId: data?._id,
        }),
      );
    } else {
      dispatch(
        setWriteAccess({
          pageId: pageId,
          actionId: data?._id,
        }),
      );
    }
  };

  return (
    <>
      <Box>
        <SBTable border={0} sx={{ backgroundColor: 'border.tableRow' }}>
          <TableBody>
            <SBTableRow>
              <StyledTableRowCell align="left" className="md-text" width={'62%'} style={{ paddingLeft: '60px' }}>
                {data?.name}
              </StyledTableRowCell>
              <StyledTableRowCell className="md-text" width={'20%'}>
                <Grid container justifyContent="center">
                  {data?.isReadOnly ? (
                    <Checkbox
                      checked={isViewAccessEnabled}
                      onChange={handleViewChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={!isAllViewTrue}
                    />
                  ) : (
                    '-'
                  )}
                </Grid>
              </StyledTableRowCell>
              <StyledTableRowCell className="md-text">
                <Grid container justifyContent="center">
                  {data?.isWriteOnly ? (
                    <Checkbox
                      checked={isWriteAccessEnabled}
                      onChange={handleEditChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      // disabled={!isAllViewTrue ? !isAllViewTrue : !isViewAccessEnabled}
                      disabled={!isAllViewTrue}
                    />
                  ) : (
                    '-'
                  )}
                </Grid>
              </StyledTableRowCell>
            </SBTableRow>
          </TableBody>
        </SBTable>
      </Box>
    </>
  );
}

const StyledTableRowCell = styled(TableRowCell)<{ align?: string }>(({ theme, align }) => ({
  textAlign: align || 'center',
  // backgroundColor: '#ccc',
  // color: '#344054',
  paddingLeft: theme.spacing(2),
  '& div': {
    padding: '0 !important',
  },
}));
