import { DispatchError, DispatchSuccess } from '@Reducers/SuccessErrorModal';
import { PayloadAction } from '@reduxjs/toolkit';
import { CarrierState, CarrierListResponse, CarrierCreateResponse, CarrierType, ErrorType } from './Type';

function getCarrierListPending(state: CarrierState) {
  state.loading = true;
}

function getCarrierListFulfilled(state: CarrierState, data: { payload: CarrierListResponse }) {
  const list = data.payload.data;
  state.carrierList = list;
  state.activeCarrierDropdownList = list.map(carrier => ({ display: carrier.name, value: carrier._id }));
  state.loading = false;
}

function getCarrierListRejected(state: CarrierState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
  DispatchError('Oops!', 'Unable to load carrier list.');
}

// -----------------------------------------------------------------------------------------------------------------
function createCarrierPending(state: CarrierState) {
  state.loading = true;
}

function createCarrierFulfilled(state: CarrierState, _data: { payload: CarrierCreateResponse }) {
  state.isRedirect = true;
  state.loading = false;
  DispatchSuccess('Success', 'Carrier details created.', '/carrier-and-services?tab=carrier');
}

function createCarrierRejected(state: CarrierState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
  DispatchError('Oops!', action.payload?.msg || 'The desired carrier does not created.');
}

// -----------------------------------------------------------------------------------------------------------------
function getCarrierByIdPending(state: CarrierState) {
  state.loading = true;
}

function getCarrierByIdFulfilled(state: CarrierState, data: { payload: CarrierType }) {
  state.singleCarrier = data.payload;
  state.loading = false;
}

function getCarrierByIdRejected(state: CarrierState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  // state.error = { msg: action.payload?.msg || 'The desired carrier does not exist.', isError: true };
  DispatchError(
    'Oops!',
    action.payload?.msg || 'The desired carrier does not exist.',
    '/carrier-and-services?tab=carrier',
  );
}

// -----------------------------------------------------------------------------------------------------------------
function updateCarrierPending(state: CarrierState) {
  state.loading = true;
  state.error = { msg: '', isError: false };
}
function updateCarrierFulfilled(state: CarrierState, _data: { payload: { msg: string } }) {
  // state.singleCarrier = data.payload.data;
  state.loading = false;
  DispatchSuccess('Success', 'Carrier details updated.', '/carrier-and-services?tab=carrier');
}
function updateCarrierRejected(state: CarrierState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  // state.error = { msg: action.payload?.msg || 'The desired carrier does not updated.', isError: true };
  DispatchError('Oops!', action.payload?.msg || 'The desired carrier does not updated.');
}

export {
  getCarrierListPending,
  getCarrierListFulfilled,
  getCarrierListRejected,
  //
  createCarrierPending,
  createCarrierFulfilled,
  createCarrierRejected,
  //
  getCarrierByIdPending,
  getCarrierByIdFulfilled,
  getCarrierByIdRejected,
  //
  updateCarrierPending,
  updateCarrierFulfilled,
  updateCarrierRejected,
};
