import * as React from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { StepIconProps } from '@mui/material/StepIcon';
import Image from '../../Image';
import { Connector, StepIconRoot, SBStepLabel } from './styles';
import { HorizontalTrackingProps } from './type';
import { SHIPMENT_STATUS } from '../../../constants/Shipment';

function StepIcon(props: StepIconProps) {
  const { completed, className } = props;

  return (
    <StepIconRoot className={className}>
      {completed ? <Image src="/images/shipment/BoxIcon.svg" alt="Box" height={24} /> : <div className="circle-icon" />}
    </StepIconRoot>
  );
}

export default function HorizontalTracking(props: HorizontalTrackingProps) {
  const endStep = props.data?.length - 1;
  return (
    <Stack sx={{ width: '100%', marginTop: 6, marginBottom: 0 }} className={props.className}>
      <Stepper nonLinear connector={<Connector />}>
        {props.data?.length ? (
          props.data.map((label, ind) => (
            <Step key={`${label}${ind}`} style={{ padding: 0, position: 'relative' }}>
              <SBStepLabel StepIconComponent={() => <StepIcon completed={endStep === ind} icon={null} />}>
                {SHIPMENT_STATUS[label as keyof typeof SHIPMENT_STATUS]?.label}
              </SBStepLabel>
            </Step>
          ))
        ) : (
          <></>
        )}
      </Stepper>
    </Stack>
  );
}
