import React from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { doLogout } from '@Reducers/LoginForm';
import { useNavigate } from 'react-router-dom';

function UserData() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userName = useSelector(
    (state: RootStateType) =>
      state.loginUser.profile?.name || state.loginUser.profile?.email || state.loginUser.profile?.username,
  );

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleRedirectToProfile = () => {
    handleClose();
    navigate(`/profile`);
  };

  const handleLogout = () => {
    dispatch(doLogout(null));
  };

  return (
    <>
      <Typography
        aria-describedby={id}
        onClick={handleClick}
        className="flex items-center cursor-pointer md-text font-medium truncate"
        sx={{ justifyContent: 'end' }}
      >
        {userName} <KeyboardArrowDownIcon sx={{ ml: 2 }} />
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MenuItem sx={{ minWidth: '120px' }} onClick={handleRedirectToProfile}>
          Profile
        </MenuItem>
        <MenuItem sx={{ minWidth: '120px' }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserData;
