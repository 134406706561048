import React, { useEffect, useState } from 'react';
import GetRatesContext from './index';
import { BoxType, RatesInputType, LengthUnitType } from '../../pages/GetRatesV2/Type';
import { DefaultAddress, defaultBoxes, DefaultRatesInput, LENGTH_UNITS } from '../../pages/GetRatesV2/Constant';
import { calculateVolume } from '../../services/calculateVolume';
import { AddressType } from '../../store/reducers/GetRates/Type';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { resetCharges, resetFormData, setFormData } from '@Reducers/GetRates';

function GetRatesWrapper(props: { children: React.ReactNode }) {
  const { formData, routeHistory } = useSelector((state: RootStateType) => state.getRates);
  const dispatch = useDispatch<AppDispatch>();
  const [hideBoxes, setHideBoxes] = useState<boolean>(true);
  const [boxCount, setBoxCount] = useState<number>(0);
  const [boxes, setBoxes] = useState<BoxType[]>([...defaultBoxes]);
  const [ratesInput, setRateInput] = useState<RatesInputType>({ ...DefaultRatesInput });
  const [ratesInputHasError, setRatesInputHasError] = useState<boolean>(true);
  const [showRates, setShowRates] = useState<boolean>(false);
  const [chargeableWeight, setChargeableWeight] = useState<number>(0);
  const [pickup, setPickup] = useState<AddressType>({ ...DefaultAddress });
  const [destination, setDestination] = useState<AddressType>({ ...DefaultAddress });
  const [userId, setUserId] = useState<string>('');

  useEffect(() => {
    if (formData.boxes) {
      const { boxes, pickup, destination, weight, weightUnit, hideBoxes } = formData;
      setPickup(prev => ({ ...prev, ...pickup }));
      setDestination(prev => ({ ...prev, ...destination }));
      setBoxes(boxes);
      // setRateInput(prev => ({ ...prev, boxCount: boxes.length, weight, weightUnit }));
      setRateInput(prev => ({ ...prev, weight, weightUnit }));
      setBoxCount(boxes.length);
      setHideBoxes(hideBoxes);
    }
  }, [formData]);

  useEffect(() => {
    let totalValue = 0;
    totalValue = boxes.reduce((acc, { length, height, width, weight }) => {
      const unit: LengthUnitType = LENGTH_UNITS[ratesInput.weightUnit].value;
      const volumetricWeight = calculateVolume(length, width, height, unit);
      weight = typeof weight === 'string' ? parseInt(weight) : weight;
      return acc + (weight > volumetricWeight ? weight : volumetricWeight);
    }, 0);
    setChargeableWeight(totalValue);
  }, [boxes]);

  function setReduxState() {
    dispatch(setFormData({ ...ratesInput, boxes, hideBoxes }));
  }

  function resetReduxState() {
    dispatch(resetFormData());
  }

  useEffect(() => {
    if (routeHistory?.length) {
      if (!routeHistory.some(route => route.includes('redirectedFrom=GetRates'))) {
        dispatch(resetCharges());
        resetReduxState();
      }
    }
  }, [routeHistory]);

  return (
    <GetRatesContext.Provider
      value={{
        hideBoxes,
        setHideBoxes,
        boxCount,
        setBoxCount,
        boxes,
        setBoxes,
        ratesInput,
        setRateInput,
        ratesInputHasError,
        setRatesInputHasError,
        showRates,
        setShowRates,
        chargeableWeight,
        setChargeableWeight,
        pickup,
        setPickup,
        destination,
        setDestination,
        userId,
        setUserId,
        setReduxState,
        resetReduxState,
      }}
    >
      {props.children}
    </GetRatesContext.Provider>
  );
}

export default GetRatesWrapper;
