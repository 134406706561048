import axios from 'axios';
import CONFIG from '../config';
import { configHeadersType } from './Type';
// import { rootStore } from '../store';

const API = axios.create({
  baseURL: CONFIG.API_URI,
  timeout: 30000,
  headers: {},
});

const configHeaders: configHeadersType = {
  'Content-Type': 'application/json',
  credentials: 'same-origin',
};

API.interceptors.request.use(request => {
  // if (!request.headers['sb_token'])
  //   request.headers['sb_token'] =
  //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2JkNGJmM2FiY2QxM2JmNDM3ODI5YmEiLCJhY2Nlc3NHcm91cCI6InB1X2FsbCIsIm5hbWUiOiJSYW52ZWVyIFNpbmdoIiwiYWNjb3VudE51bWJlciI6IjMxMjAxNSIsImFjY291bnRJZCI6IjYzYmMwYmE4MTI5YTE5ZTMzZmEzYjM0NyIsInJlZmVyZW5jZU51bWJlciI6InByZXBhcmV6bGUiLCJfY291bnRyeUlkIjoiNWMyMzgxZWI3YTJjOTk1YjUzNDFiMjliIiwiX3N5c3RlbXMiOlsiNjNiYzEwNzdmNjBiMmQ0NzlhNGJjYjBmIiwiNjNiYzEwNzVmNjBiMmQ0NzlhNGJjYjBjIl0sIl9wcmVmZXJlbmNlIjoiNjNiZDRiZjVhYmNkMTNiZjQzNzgyOWMwIiwibG9nZ2VkSW5UaW1lIjoiMjAyMy0wOS0xNVQwODoyNzozMi41ODNaIiwiaWF0IjoxNjk0NzY2NDUyLCJleHAiOjE3MDk0NTQ0NTJ9.G9PyY3t9ifpujjV89CWolw92ZWJhm0IBLIINT1oRyTA';
  //
  /** DON'T PUSH THIS FILE */
  request.headers['org_code'] = 'stage';
  return request;
});

API.interceptors.response.use(function (response) {
  console.log('response.data?.isError', response.data?.isError);
  if (response.data?.isError) {
    throw response.data;
  }
  return response;
});

Object.keys(configHeaders).forEach((key: keyof configHeadersType) => {
  API.defaults.headers.common[key] = configHeaders[key];
});

export default API;
