import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CreateShipmentContext from '../../../context/CreateShipment';
import { createShipmentContextType, stepType, userType } from '../../../types/shipment';
import ShipmentDetails from '../ShipmentDetails';
import { useMediaQuery, useTheme } from '@mui/material';
// import PickupType from '../PickupType';
// import ShipmentTypeDetails from '../ShipmentDetails/ShipmentTypeDetails';
import { USER_TYPE } from '../constants';
// import Boxes from '../ShipmentDetails/BoxWise';
import CSPList from '../../GetRatesV2/CSPList';
import { CSPChargeType } from '../../../store/reducers/GetRates/Type';
import ShipmentSummary from '../ShipmentSummary';
import ShipmentSuccess from '../ShipmentSuccess';
import { Heading } from '../ShipmentSummary/styles';
import UserDetails from '../UserDetails';
import Loader from '../../../components/Loader';
import { AppDispatch, RootStateType } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { redirectToSuccessPage, resetShipmentError } from '../../../store/reducers/Shipment';
import ErrorModal from '../../../components/Modal/error';
import { resetGetRatesError } from '@Reducers/GetRates';
import NoCSPFound from '../../GetRatesV2/CSPList/NoCSPFound/NoCSPFound';
import { deleteDraftShipment, setDraftShipmentId } from '@Reducers/DraftShipment';

const stepWrapperStyles = ({ first, last, active, completed }: stepType) => ({
  background: active || completed ? 'unset' : '#C4C4C4',
  padding: '1px 2px',
  width: '100%',
  clipPath: active
    ? 'unset'
    : `polygon(${
        first
          ? '0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%'
          : last
          ? '0 0, 100% 0%, 100% 100%, 0 100%, 4% 50%'
          : '95% 0%, 100% 50%, 95% 100%, 0% 100%, 4% 50%, 0% 0%'
      })`,
  borderRadius: 'calc( var(--mui-palette-spacing) / 3 )',
});

const stepStyles = ({ first, last, completed, pending, active }: stepType) => ({
  background:
    first || completed
      ? 'var(--mui-palette-primary-main)'
      : active
      ? 'var(--mui-palette-primary-main)'
      : pending
      ? 'var(--mui-palette-background-white)'
      : '',
  color: pending ? 'unset' : 'var(--mui-palette-background-white)',
  padding: '16px 28px',
  clipPath: `polygon(${
    first
      ? '0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%'
      : last
      ? '0 0, 100% 0%, 100% 100%, 0 100%, 4% 50%'
      : '95% 0%, 100% 50%, 95% 100%, 0% 100%, 4% 50%, 0% 0%'
  })`,
  borderRadius: 'calc( var(--mui-palette-spacing) / 3 )',
  fontWeight: '600',
  fontSize: '16px',
});

const steps = ['Shipper ', 'Consignee ', 'Shipment ', 'Services Available', 'summary', 'Shipment Success'];

function ShipmentStepper({ modalId }: { modalId: string }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch<AppDispatch>();

  const {
    savedAddress: { loading: addressLoading },
    getRates: { loading: ratesLoading },
    DraftShipment: { loading: draftShipmentLoading, selectedDraftShipmentId: draftRefId },
    shipment: {
      loading: shipmentLoading,
      redirectToSuccess,
      error: { isError, msg: errorDetails },
    },
  } = useSelector((state: RootStateType) => state);
  const { loading: accountLookupLoading = false, selectedUser } = useSelector(
    (state: RootStateType) => state.accountLookup[modalId] || {},
  );
  const { loading: userDataByIdLoading } = useSelector((state: RootStateType) => state.user);

  const loading =
    addressLoading ||
    ratesLoading ||
    shipmentLoading ||
    accountLookupLoading ||
    userDataByIdLoading ||
    draftShipmentLoading;
  const { activeStep, setActiveStep, shipmentType, setShowRates, setSelectedCSP } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;

  React.useEffect(() => {
    if (redirectToSuccess) {
      dispatch(setDraftShipmentId({ id: null }));
      setActiveStep(5);
      dispatch(redirectToSuccessPage(false));
      if (draftRefId) {
        dispatch(
          deleteDraftShipment({
            payload: { deleteId: [draftRefId] },
            queryParams: { currentPage: 1, perPage: 0 },
            showModal: false,
          }),
        );
      }
    }
  }, [redirectToSuccess, draftRefId]);

  React.useEffect(() => {
    return () => {
      dispatch(setDraftShipmentId({ id: null }));
    };
  }, []);

  // React.useEffect(() => {
  //   if (!isMobile && activeStep > 1) {
  //     setActiveStep(2);
  //   }
  // }, [isMobile]);

  function handleBookCSP(CSPCharge: CSPChargeType) {
    setSelectedCSP(CSPCharge);
    setActiveStep(activeStep + 1);
  }

  function handleBackCSP() {
    setShowRates(false);
    setActiveStep(activeStep - 1);
  }

  const getActiveStep = (activeStep: string) => {
    switch (activeStep) {
      case 'shipment-0':
        return (
          <UserDetails
            key={USER_TYPE.SHIPPER}
            detailsFor={USER_TYPE.SHIPPER as userType}
            userId={selectedUser?._id}
            accountNumber={selectedUser?._accountId?.accountNumber}
            accountId={selectedUser?._accountId?._id}
          />
        );
      case 'shipment-1':
        return <UserDetails key={USER_TYPE.CONSIGNEE} detailsFor={USER_TYPE.CONSIGNEE as userType} />;
      case 'shipment-2':
        return <ShipmentDetails />;
      case 'shipment-3':
        return (
          <CSPList
            weightUnit={shipmentType.unit.split('-')[0]}
            setShowRates={handleBackCSP}
            handleBookCSP={handleBookCSP}
          />
        );
      case 'shipment-4':
        return <ShipmentSummary />;
      case 'shipment-5':
        return <ShipmentSuccess modalId={modalId} />;
        // case 'mobile-0':
        //   return <UserDetails detailsFor={USER_TYPE.SHIPPER as userType} />;
        // case 'mobile-1':
        //   return <UserDetails detailsFor={USER_TYPE.CONSIGNEE as userType} />;
        // case 'mobile-2':
        //   return <ShipmentDetails />;
        // case 'mobile-3':
        //   return (
        //     <CSPList
        //       weightUnit={shipmentType.unit.split('-')[0]}
        //       setShowRates={handleBackCSP}
        //       handleBookCSP={handleBookCSP}
        //     />
        //   );
        // case 'mobile-4':
        //   return <ShipmentSummary />;
        // case 'mobile-5':
        //   return <ShipmentSuccess />;
        // default:
        return <></>;
    }
  };

  // const handleNext = () => {
  //   setActiveStep(activeStep + 1);
  // };

  const handleReset = () => {
    setActiveStep(0);
  };

  const ohandleErrorModalClose = () => {
    dispatch(resetShipmentError());
    dispatch(resetGetRatesError());
  };

  return (
    <Loader loading={loading} overly>
      <>
        <Box sx={{ width: '100%', scrollMargin: '95px' }}>
          {activeStep < 3 && <Heading>Create Shipment</Heading>}
          {!isMobile && activeStep < 4 && (
            <Stepper activeStep={activeStep} sx={{ padding: '30px 0 30px 0' }}>
              {steps.map((label, index) => {
                const stepProps: stepType = {
                  first: index === 0,
                  last: index === steps.length - 1,
                  completed: index < activeStep,
                  pending: index > activeStep,
                  active: index === activeStep,
                };
                return (
                  index < 4 && (
                    <Box key={index} sx={stepWrapperStyles(stepProps)}>
                      <Box sx={stepStyles(stepProps)}>
                        {label}
                        <Typography className="xs-text">Step {index + 1}</Typography>
                      </Box>
                    </Box>
                  )
                );
              })}
            </Stepper>
          )}
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getActiveStep(`shipment-${activeStep}`)}
              {/* <Box sx={{ display: 'flex', flexDirection: 'row-reverse', my: 2 }}>
            <SbButton onClick={handleNext} variant="contained" style={{ height: '44px' }}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              <KeyboardArrowRightIcon className='ml-' />
            </SbButton>
          </Box> */}
            </React.Fragment>
          )}
        </Box>
        <ErrorModal
          icon={'/images/Boxes/WarningContrast.svg'}
          open={isError || false}
          title={'Oops!!'}
          subTitle={errorDetails}
          onClose={ohandleErrorModalClose}
          onConfirm={ohandleErrorModalClose}
          btnText="Go back"
        />
        <NoCSPFound setShowRates={setShowRates} />
      </>
    </Loader>
  );
}
export default ShipmentStepper;
