import { Grid, Typography } from '@mui/material';
import React from 'react';
import { CSPLogo } from '../../../GetRatesV2/CSPList/styles';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { createShipmentContextType } from '../../../../types/shipment';
import Image from '../../../../components/Image';

function CSPDetails() {
  const { selectedCSP } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const { serviceImage, serviceName } = selectedCSP ?? {};
  return (
    <Grid container alignItems={'center'} sx={{ height: '70%' }}>
      <CSPLogo>
        {serviceImage && (
          <Image
            src={serviceImage}
            alt="serviceImage"
            style={{ borderRadius: '10px', height: '100%', width: '100%' }}
          />
        )}
      </CSPLogo>
      <Typography className="xs-text ml-1">{serviceName}</Typography>
    </Grid>
  );
}

export default CSPDetails;
