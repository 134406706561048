import React, { useState } from 'react';
import { SBTable, SBTableWrapper } from '../../components/Table/Table';
import {
  Checkbox,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableHeadCell, TableRowCell } from '../../components/Table/TableCell';
import { SBTableRow } from '../../components/Table/TableRow';
import SbButton from '../../components/Button';
import SbTextField from '../../components/Textfield';
import DeleteIcon from '@mui/icons-material/Delete';

import SbTooltip from '../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import { FormikType, SHIPMENT_CHARGES_TYPE, SHIPMENT_STETTING_FORM } from './type';
import { DEFAULT_SHIPMENT_CHARGES_LIST, defaultChargesValues } from '../OrganizationSetup/constants';
import { FileResultType } from '../../Helper/file';
import UploadButton from '@Components/File/UploadButton';
import Image from '@Components/Image';

type PROPS_TYPE = FormikType & {
  values: SHIPMENT_STETTING_FORM['shipmentCharges'];
  disabled?: boolean;
};

function ShipmentCharges(props: PROPS_TYPE) {
  const [docName, setDocName] = useState<string>('');
  const [shipmentChargesError, setShipmentChargesError] = useState<string>('');
  const [shipmentFileError, setShipmentFileError] = useState<string>('');

  function validateDuplicate(charges: SHIPMENT_CHARGES_TYPE[], charge: string) {
    if ([...charges, ...DEFAULT_SHIPMENT_CHARGES_LIST].some(doc => charge.toLocaleLowerCase() === doc.value)) {
      setShipmentFileError('shipment charges already exists.');
      return true;
    }
  }

  function handleAddMore() {
    if ([...props.values, ...DEFAULT_SHIPMENT_CHARGES_LIST].some(doc => docName.toLocaleLowerCase() === doc.value)) {
      setShipmentChargesError('shipment charges already exists.');
      return;
    }
    if (shipmentChargesError) setShipmentChargesError('');
    const tempData = [...props.values];
    tempData.push({ label: docName.trim(), value: docName.trim().toLocaleLowerCase(), isActive: true });
    if (props.setFieldValue) props.setFieldValue('shipmentCharges', tempData);
    setDocName('');
  }

  function handleDelete(ind: number) {
    const tempData = [...props.values];
    tempData.splice(ind, 1);
    if (props.setFieldValue) props.setFieldValue('shipmentCharges', tempData);
  }

  function handleCheckboxChange(chargeName: string, checked: boolean) {
    const tempData = [...props.values];
    const index = tempData.findIndex(x => x.value === chargeName.toLocaleLowerCase());
    if (index === -1) {
      tempData.push({ value: chargeName.toLocaleLowerCase(), label: chargeName, isActive: true });
    } else {
      tempData[index] = { ...tempData[index], isActive: !checked };
      // tempData.splice(index, 1);
    }
    if (props.setFieldValue) props.setFieldValue('shipmentCharges', tempData);
  }
  function handleFileChange(data: FileResultType[]) {
    setShipmentFileError('');
    if (!data.length) return setShipmentChargesError('Please upload pincode list.');
    const [{ fileData }] = data;
    if (!(fileData instanceof Array)) return;
    const {
      data: [headers, ...body],
    } = fileData[0] as { data: string[][] };
    if (headers[0] !== 'Shipment Charges') {
      setShipmentFileError('Invalid file');
      return;
    }
    const tempData = [...props.values];
    body.forEach((data: string[]) => {
      const obj = {
        label: data[0].trim(),
        value: data[0].trim().toLocaleLowerCase(),
        isActive: true,
      };
      if (validateDuplicate(tempData, obj.value)) return;
      tempData.push(obj);
    });
    if (props.setFieldValue) props.setFieldValue('shipmentCharges', tempData);
  }

  const onDownloadSampleSheet = () => {
    window.open('https://s3.ap-south-1.amazonaws.com/speedbox-static-file-2.0/Shipment+Charges+Sample.xlsx', '_blank');
  };

  return (
    <Grid container>
      <Grid item container mb={2} justifyContent="space-between">
        <Grid container item xs={6}>
          <Typography className="md-text font-semibold mr-1">Shipment Charges</Typography>
          <SbTooltip
            placement="right"
            title="Shipment Charges are additional charges that can be added while  Shipment Processing"
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>

        <Grid display="flex" gap={2}>
          <Grid item xs={12} mt={0.5} md={'auto'}>
            <SbButton
              fullWidth
              variant="outlined"
              onClick={onDownloadSampleSheet}
              style={{ marginBottom: '0rem' }}
              startIcon={
                <Image
                  src={'/images/upload_document.svg'}
                  alt={'Speedbox'}
                  width={35}
                  height={35}
                  style={{ transform: 'rotate(180deg)' }}
                />
              }
              disabled={props.disabled}
            >
              Download sample file
            </SbButton>
          </Grid>
          <Grid item xs={12} md={'auto'}>
            <UploadButton
              title="Upload Excel"
              readType="xls"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              handleChange={handleFileChange}
              disabled={props.disabled}
            />
          </Grid>
        </Grid>
      </Grid>
      {shipmentFileError && (
        <FormHelperText error className="md-text w-full text-right mb-2">
          {shipmentFileError}
        </FormHelperText>
      )}
      <SBTableWrapper>
        <SBTable className="rounded-md">
          <TableHead>
            <TableRow>
              <TableHeadCell className="text-left">Shipment charges</TableHeadCell>
              <TableHeadCell>Mandatory</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.values.map((doc, ind) => (
              <TableRowCharge
                key={`extra-${ind}`}
                label={doc.label}
                handleDelete={handleDelete}
                index={ind}
                showDelete={!defaultChargesValues.includes(doc.value)}
                handleCheckboxChange={handleCheckboxChange}
                checked={!!doc.isActive}
                disabled={props.disabled}
              />
            ))}
          </TableBody>
          <TableFooter>
            <SBTableRow>
              <TableRowCell>
                <SbTextField
                  placeholder="Enter"
                  value={docName}
                  onChange={e => setDocName(e.target.value)}
                  error={shipmentChargesError}
                  disabled={props.disabled}
                />
              </TableRowCell>
              <TableRowCell align="center">
                <SbButton variant="contained" disabled={!docName} onClick={handleAddMore}>
                  Add more
                </SbButton>
              </TableRowCell>
            </SBTableRow>
          </TableFooter>
        </SBTable>
      </SBTableWrapper>
    </Grid>
  );
}
// ------------------------------------------------------------------------------------------------------------------
interface ROW_TYPE_PROPS {
  label: string;
  handleCheckboxChange?: (chargeName: string, checked: boolean) => void;
  handleDelete?: (ind: number) => void;
  index: number;
  showDelete?: boolean;
  checked?: boolean;
  disabled?: boolean;
}
function TableRowCharge(props: ROW_TYPE_PROPS) {
  const { checked, label, handleCheckboxChange, index, showDelete, handleDelete } = props;
  return (
    <SBTableRow>
      <TableRowCell width={'75%'} align="left" className="md-text font-medium">
        {label}
      </TableRowCell>
      <TableRowCell align="center" sx={{ px: 0 }}>
        <Grid container justifyContent="center">
          <FormGroup classes={{ root: 'flex' }} style={{ width: '15px' }}>
            <Checkbox
              onChange={_ => handleCheckboxChange?.(label, !!checked)}
              checked={!!checked}
              disabled={props.disabled}
            />
          </FormGroup>
          {props.disabled && showDelete && (
            <IconButton className="ml-2" onClick={() => handleDelete?.(index)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </TableRowCell>
    </SBTableRow>
  );
}

export default ShipmentCharges;
