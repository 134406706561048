import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import { ContractObject } from '@Reducers/Contract/Type';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@Store';
import { viewSignaleDocument } from '@Reducers/kyc/actions';

const Wrapper = styled(Grid)`
  border-bottom: 1px solid;
  padding: 20px;
  &:last-child {
    border-bottom: none;
  }
`;

const StyleIconButton = styled(IconButton)(({ theme: _ }) => ({
  border: '1px solid #b2b2b2',
  padding: 8,
  borderRadius: 8,
  color: '#000',
  fontSize: '24px',
}));

interface ContractSingleDocumentType {
  label: string;
  documentData: ContractObject;
  required: boolean;
  onDocumentDeleteModalOpen: (documentData: ContractObject) => void;
  modalView?: boolean;
}

function ContractSingleDocument(props: ContractSingleDocumentType) {
  const { label, required, documentData, onDocumentDeleteModalOpen } = props;

  const dispatch = useDispatch<AppDispatch>();

  const onViewDocument = () => {
    dispatch(viewSignaleDocument({ documentId: documentData?._document, url: 'user' }));
  };

  return (
    <Wrapper container item alignItems="flex-center" className={`border-grey`}>
      <Grid container item xs={12} md={8} alignItems="center" spacing={0.5}>
        <Grid item xs={12}>
          <Typography className="md-text font-semibold" sx={{ mb: { xs: 1, md: 0 } }}>
            {label} {required ? '*' : ''}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={4} gap={2} alignSelf="flex-end" justifyContent="flex-end">
        {documentData && (
          <>
            {/* <Link to={`${CONFIG.API_URI}/user/file/get-url/${documentData?._document}`} target="_blank"> */}
            <StyleIconButton onClick={onViewDocument}>
              <VisibilityIcon fontSize="inherit" />
            </StyleIconButton>
            {/* </Link> */}
            <StyleIconButton onClick={() => onDocumentDeleteModalOpen(documentData)}>
              <DeleteIcon fontSize="inherit" />
            </StyleIconButton>
          </>
        )}
      </Grid>
    </Wrapper>
  );
}

export default ContractSingleDocument;
