import { createSlice } from '@reduxjs/toolkit';
import { getCountryList } from './actions';
import { getCountryFullfilled, getCountryRejected } from './case';
import { globalStateType } from './Type';

import { getKycSetting } from './actions';
import { getKycSettingFulfilled, getKycSettingPending, getKycSettingRejected } from './case';

const shipmentIdTypeList = [
  {
    display: 'Personal Shipments',
    value: 'personalShipments',
  },
  {
    display: 'Export Shipment',
    value: 'exportShipment',
  },
];

const shipmentIdList = {
  personalShipments: [
    {
      display: 'PAN',
      value: 'PAN',
    },
    {
      display: 'Aadhar',
      value: 'Aadhar',
    },
  ],
  exportShipment: [
    {
      display: 'GSTIN',
      value: 'GSTIN',
    },
  ],
};
const bankAccountTypeList = [
  {
    display: 'Saving',
    value: 'saving',
  },
  {
    display: 'Current',
    value: 'current',
  },
];

const accountTypeList = [
  { display: 'All', value: '' },
  { display: 'Account holder', value: 'account_holder' },
  { display: 'Non-Account holder', value: 'non_account_holder' },
];

const initialState = <globalStateType>{
  countriesObj: {},
  alternateCountryNameObj: {},
  countriesNameObj: {},
  countries: [],
  countryList: [],
  countryCodeList: [],
  currencyList: [],
  shipmentIdTypeList,
  shipmentIdList,
  bankAccountTypeList,
  kycSetting: null,
  loading: false,
  organizationCountry: {
    currency: {
      name: 'INR',
      symbol: '₹',
      fullForm: '',
    },
    _id: '5c2381eb7a2c995b5341b29b',
    alternateNames: ['India', 'Republic of India', 'IN'],
    documentRequirements: [
      'Aadhar',
      'Passport',
      'Driving License',
      'Utility Bill(Phone/Electricity)',
      'Company PAN',
      'Certificate of Incorporation',
      'Import Export Certificate(IEC)',
      'GST Certificate',
      'TAN Certificate',
    ],
    taxInfoRequirements: ['GST', 'PAN'],
    lastUpdatedBy: null,
    isActive: true,
    name: 'India',
    timezone: '+0530',
    countryCode: 'IN',
    telephoneCode: 91,
    flagURI: 'https://s3.amazonaws.com/flags-all/in_16.png',
    __v: 0,
    createdAt: '2018-12-26T13:28:11.145Z',
    updatedAt: '2018-12-26T13:28:11.145Z',
  },
  accountTypeList,
};

const userSlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    // setUser: setCountryHandler,
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getCountryList.fulfilled, getCountryFullfilled);
    addCase(getCountryList.rejected, getCountryRejected);

    addCase(getKycSetting.fulfilled, getKycSettingFulfilled);
    addCase(getKycSetting.rejected, getKycSettingRejected);
    addCase(getKycSetting.pending, getKycSettingPending);
  },
});

// const { setUser } = userSlice.actions;

export { getCountryList, getKycSetting };
export default userSlice.reducer;
