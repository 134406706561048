import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// import { useTheme } from '@emotion/react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MailIcon from '@mui/icons-material/Mail';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import SbTextField, { TextFieldProps } from '@Components/Textfield';
// import SbDropdown from '@Components/Dropdown';
import SbButton from '@Components/Button';
import SbImage from '@Components/Image';
import Footer from '@Components/Footer';
import Loader from '@Components/Loader';
import ErrorModal from '@Components/Modal/error';
import Modal from '@Components/Modal';
import LoginSideBanner from '@Components/Login';
import Logo from '@Components/LeftSidebar/Logo';
import PoweredBy from '@Components/PoweredBy';
// import API from '../../services/apiAxios';

import { AppDispatch, RootStateType } from '@Store';
import { doLogin, doLoginWithOtp, verifyLoginOtp, resetLoginState } from '@Reducers/LoginForm';
import { verifySignupEmail, resendVerification, resetSignupState } from '@Reducers/Signup';
import { useOrgSetupStatus } from '@Hook/useOrgSetupStatus';
import SearchBox from '@Components/SearchBox';
import SuccessError from '@Components/Modal/SuccessError';
import ResetPassword from '@Components/ResetPassword';

const LoginGrid = styled(Grid)({
  boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
  borderRadius: '20px 0px 0px 20px',
  zIndex: 1,
  backgroundColor: '#fff',
  // width: '700px',
  width: '550px',
  minHeight: '100vh',
});

const WelcomeOrgh1 = styled('h1')({
  alignItems: 'center',
  color: '#2C2B6D',
  fontWeight: '700',
  fontSize: '36px',
});

const BannerContainer = styled(Grid)(({ theme }) => ({
  background: "url('/images/login/Group.png')",
  backgroundPositionX: '547px',
  backgroundSize: 'cover',
  backgroundRepeatX: 'no-repeat',
  flex: 1,
  backgroundColor: 'white',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ButtonContainer = styled(SbButton)({
  margin: '6px 0px',
  height: '48px',
  fontSize: '16px',
  letterSpacing: '0.46px',
  textTransform: 'capitalize',
});

const PContainer = styled('p')({
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: '600',
  marginTop: '25px',
});

interface loginStateFields {
  trackingNo?: string;
  email?: string;
  password?: string;
}

const Login: React.FC = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const formState = useSelector((state: RootStateType) => state?.loginForm);
  const signupForm = useSelector((state: RootStateType) => state.signupForm);
  const organizationData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { name = '' } = organizationData || {};

  const loginLoading = formState.loading || signupForm.loading;

  const [isLoginWithPassword, setLoginWithPassword] = React.useState<boolean>(true);
  const [state, setState] = React.useState<loginStateFields>({});
  const [loader, setLoader] = useState<boolean>(false);

  const { orgCurrentLevel } = useOrgSetupStatus();

  const handleLoginChange =
    (name: string): TextFieldProps['onChange'] =>
    event => {
      const { value } = event.target;

      setState({ [name]: value });
    };

  const setLoginMethod = (value: boolean | undefined = true): void => {
    setLoginWithPassword(value);
  };

  useEffect(() => {
    return () => {
      dispatch(resetLoginState());
      dispatch(resetSignupState());
    };
  }, []);

  const handleGetRates = () => {
    navigate('/login/get-rates');
  };

  // console.log({ state });

  // const bannerView = () => {
  //   return <></>;
  // };
  // console.log({ loader, loginLoading });
  return (
    <>
      <SuccessError />
      <Loader loading={loader || loginLoading} overly>
        <Grid container style={{ backgroundColor: 'transparent' }}>
          {false && (
            <BannerContainer item>
              <div
                style={{
                  height: 'calc(100vh - 160px)',
                }}
              >
                <div
                  className="header"
                  style={{
                    display: 'flex',
                    marginTop: '61px',
                    marginRight: '96px',
                  }}
                >
                  <SbImage src="/images/login/logo.png" alt="logo" style={{ width: '208px', maxHeight: '121px' }} />
                  <WelcomeOrgh1>Welcome to Allied Express</WelcomeOrgh1>
                </div>
                <div className="headerbody">
                  <Grid container>
                    <Grid item md={2}></Grid>
                    <Grid item md={6}>
                      <img
                        src="/images/login/logistics-2.gif"
                        alt="logistics"
                        style={{ height: '504px', width: '504px' }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      background: '#FFFFFF',
                      boxShadow: '0px 4px 43px rgba(206, 206, 206, 0.25)',
                      borderRadius: '20px',
                    }}
                  >
                    <Grid item md={2}></Grid>
                    <Grid item md={6}>
                      <h1 style={{ fontWeight: '700', fontSize: '32px', color: '#2C2B6D' }}>Track your Shipment</h1>
                      <SbTextField
                        placeholder="Enter your AWB"
                        label=""
                        style={{ width: '482px', height: '44px' }}
                        value={state.trackingNo || ''}
                        onChange={handleLoginChange('trackingNo')}
                        inputClassName="rounded-full"
                        startAdornment={<SearchIcon style={{ height: '44px', width: '30px', margin: '10px' }} />}
                        endAdornment={
                          <SbButton
                            variant="contained"
                            style={{ marginRight: '-13px', height: '44px', width: '114px' }}
                          >
                            Track
                          </SbButton>
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                      //
                    </Grid>
                  </Grid>
                </div>
                <div></div>
              </div>
              <Footer />
            </BannerContainer>
          )}
          <LoginSideBanner />
          <LoginGrid item>
            <div style={{ padding: '10px' }}>
              <div style={{ maxWidth: '80%', margin: '0px auto', marginTop: '30px' }}>
                <Logo />
                {/* <SbImage src="/images/login/logo.png" alt="logo" style={{ display: 'block', margin: '0 auto' }} /> */}
              </div>
              <br />

              <Grid container justifyContent="center">
                {/* <Grid item xs={1}></Grid> */}
                <Grid item xs={11}>
                  <Box
                    className="rounded"
                    style={{ border: '1px solid rgba(0, 0, 0, 0.1)', padding: '40px 35px 32px' }}
                  >
                    <Grid container justifyContent="center" mb={4} flexDirection="column" alignItems="center">
                      <h1 className="l-text">Welcome to {name}</h1>
                      <p>Enter your details to get signed in to your account.</p>
                    </Grid>

                    {isLoginWithPassword ? (
                      <LoginWithPassword setLoginMethod={setLoginMethod} setLoader={setLoader} data={state} />
                    ) : (
                      <LoginWithOtp setLoginMethod={setLoginMethod} setLoader={setLoader} data={state} />
                    )}

                    <br />
                    {/* <hr /> */}
                    <div style={{ opacity: '0.1', border: '1px solid #000000' }}></div>
                    {orgCurrentLevel === 'shipment-settings' && (
                      <PContainer>
                        Don't have an account?{' '}
                        <Link to="/signup" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                          <span>Sign up</span>
                        </Link>
                      </PContainer>
                    )}
                    {isMobile ? (
                      <Grid
                        container
                        sx={{
                          gap: '2rem',
                          marginTop: '2rem',
                          background: '#FFFFFF',
                          boxShadow: '0px 4px 43px rgba(206, 206, 206, 0.25)',
                          borderRadius: '20px',
                          padding: '1rem',
                          justifyContent: 'center',
                        }}
                      >
                        <Grid item sm={7} md={5.5} lg={5.5} xl={7}>
                          <Typography variant="subtitle1" className=" px_20-text font-bold text-center">
                            Tracking Status
                          </Typography>
                          <Box mt={'1rem'}>
                            <SearchBox
                              id="search_shipment"
                              label=""
                              onSearch={() => {
                                //
                              }}
                              onClear={() => {
                                //
                              }}
                              value={''}
                              placeholder="Enter you AWB"
                            />
                          </Box>
                        </Grid>
                        <Grid xs={9} sx={{ justifyContent: 'center' }}>
                          <div style={{ opacity: '0.1', border: '1px solid #000000' }}></div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4.5} lg={5} xl={4}>
                          <Typography variant="subtitle1" className=" px_20-text font-bold text-center">
                            Instant Quotes
                          </Typography>
                          <Grid item xs={12}>
                            <SbButton
                              variant="contained"
                              sx={{ backgroundColor: 'secondary.main', marginTop: '1rem', width: '100%' }}
                              onClick={handleGetRates}
                            >
                              Get Rates
                            </SbButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                  </Box>
                  <PoweredBy />
                </Grid>
              </Grid>
            </div>
          </LoginGrid>
        </Grid>
      </Loader>
    </>
  );
};

interface LoginProps {
  setLoginMethod: (t1: boolean) => void;
  setLoader: (t1: boolean) => void;
  data: {
    email?: string;
    password?: string;
    otp?: string;
  };
}

interface loginWithPasswordState {
  email: string;
  password?: string;
  verificationOtp?: string;
}

const LoginWithPassword: React.FC<LoginProps> = (props: LoginProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const loginFormState = useSelector((state: RootStateType) => state?.loginForm);
  const signupState = useSelector((state: RootStateType) => state.signupForm);

  // const loginUser = useSelector((state: RootStateType) => state.loginUser);
  // const loginLoading = loginFormState.loading;

  const [state, setState] = useState<loginWithPasswordState>({ email: '', password: '' });
  const [errorModal, setErrorModal] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState<boolean>(false);
  const [viewPassword, setViewPassword] = useState<boolean>(false);

  const { email, password, verificationOtp } = state;

  const togglePasswordView = () => {
    setViewPassword(!viewPassword);
  };

  useEffect(() => {
    const { user, loading, error } = loginFormState;
    if (user?.user?.name && !loading) {
      localStorage.setItem('token', user?.user?.token);
      navigate('/');
    }
    if (error?.isError) {
      props.setLoader(false);
      setErrorModal(true);
    }
  }, [loginFormState.user]);

  useEffect(
    () => () => {
      dispatch(resetLoginState());
    },
    [],
  );
  useEffect(() => {
    // if (signupState.verifyEmail) {
    //   setSignupSuccess(true);
    // }
  }, [signupState.verifyEmail]);

  useEffect(() => {
    if (loginFormState.error?.verificationPending) {
      handleResendVerification();
    }
  }, [loginFormState?.error]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;
    const newState = state;
    if (name === 'email') {
      newState['email'] = value;
    } else {
      newState['password'] = value;
    }
    setState({ ...newState });
  };
  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    setState({ ...state, verificationOtp: value });
  };
  const handleLoginWithPassword = async () => {
    const { email, password } = state;
    if (!email || !password) return;
    props.setLoader(true);
    dispatch(doLogin({ username: email || '', password: password || '' })).then(() => {
      props.setLoader(false);
    });
  };

  const handleResendVerification = () => {
    const payload = { username: email || '' };
    dispatch(resendVerification(payload));
  };

  const verifySignupOtp = () => {
    if (!verificationOtp) return;
    const userId = signupState?.resendVerification?.data?.userId || '';
    dispatch(verifySignupEmail({ otp: verificationOtp, userId: userId }));
  };

  const handleVerifyRefresh = () => {
    setSignupSuccess(!signupSuccess);
    window.location.reload();
  };

  // console.log('loginState', state);
  // const isLoading = false;

  // if (loginLoading) return <Loader loading={true} />;
  return (
    <>
      {/* <SuccessError /> */}
      <form action="">
        <SbTextField
          placeholder="Email ID/Phone no"
          label="Email ID/Phone no"
          style={{ marginBottom: '10px' }}
          value={email || ''}
          name="email"
          onChange={handleChange}
        />
        <SbTextField
          placeholder="Password"
          label="Enter Password"
          type={viewPassword ? 'text' : 'password'}
          name="password"
          style={{ marginBottom: '15px' }}
          value={password || ''}
          onChange={handleChange}
          endAdornment={
            viewPassword ? (
              <VisibilityIcon
                onClick={togglePasswordView}
                style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
              />
            ) : (
              <VisibilityOffIcon
                onClick={togglePasswordView}
                style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
              />
            )
          }
        />
        <p style={{ color: 'red' }}>
          <Link to="/forgotpassword" style={{ textDecoration: 'none' }}>
            Forgot Password?
          </Link>
        </p>
        <br />
        <ButtonContainer fullWidth variant="contained" onClick={handleLoginWithPassword}>
          Login
        </ButtonContainer>
        <br />
        <ButtonContainer fullWidth variant="outlined" onClick={() => props.setLoginMethod(false)}>
          Login With OTP
        </ButtonContainer>
      </form>
      <ErrorModal
        icon={'/images/Boxes/WarningContrast.svg'}
        open={errorModal}
        btnText="Close"
        subTitle={loginFormState?.error?.msg}
        onClose={() => {
          setErrorModal(false);
        }}
        onConfirm={() => {
          setErrorModal(false);
        }}
      />
      {loginFormState?.error?.verificationPending && (
        <Modal
          open={true}
          dialogContainerSx={{
            padding: 6,
          }}
          maxWidth={'xl'}
          // onClose={toggleModalView}
        >
          <Grid container>
            <Grid item>
              <p>Verify OTP</p>
              <br />
            </Grid>
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item className="mb-2">
              <SbTextField
                name="email"
                label="Email/Phone no."
                style={{ marginBottom: '10px', fontSize: '0.5rem' }}
                value={email}
                // onChange={handleChange}
                // onBlur={handleBlur}

                endAdornment={
                  <span style={{ cursor: 'pointer', color: 'rgba(220, 54, 46, 1)' }} onClick={handleResendVerification}>
                    Resend OTP
                  </span>
                }
                startAdornment={<MailIcon />}
              />
              <SbTextField
                name="otp"
                label="OTP"
                placeholder="Enter OTP"
                style={{ marginBottom: '10px' }}
                value={verificationOtp || ''}
                onChange={handleOtpChange}
                // onBlur={handleBlur}
              />
              <p>A 6-digit code has been sent to your email/phone no.</p>
              <br />
              <ButtonContainer
                fullWidth
                variant="contained"
                onClick={verifySignupOtp}
                disabled={verificationOtp && verificationOtp.length === 6 ? false : true}
              >
                Verify
              </ButtonContainer>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={5}></Grid>
          </Grid>
        </Modal>
      )}
      {loginFormState?.resetRequired && (
        <Modal
          open={true}
          dialogContainerSx={{
            padding: 6,
          }}
          maxWidth={'xl'}
        >
          <ResetPassword username={email} />
        </Modal>
      )}
      <ErrorModal
        icon={'/images/icons/SuccessIcon.svg'}
        open={signupSuccess}
        btnText="Close"
        subTitle={'Account Verified'}
        onClose={() => {
          handleVerifyRefresh();
        }}
        onConfirm={() => {
          handleVerifyRefresh();
        }}
      />
    </>
  );
};

interface loginWithOtpState {
  username?: string;
  otp?: string;
}
const LoginWithOtp: React.FC<LoginProps> = (props: LoginProps): JSX.Element => {
  // const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  // const formState = useSelector((state: RootStateType) => state?.loginform?.value);
  const [state, setState] = useState<loginWithOtpState>({ username: '', otp: '' });
  const [viewOtp, setViewOtp] = useState<boolean>(false);
  const [_isOtpSent, setIsOtpSent] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;
    const newState = state;
    if (name === 'username') {
      newState['username'] = value;
    } else {
      // newState['otp'] = value;
      const numericValue = value.replace(/\D/g, '');
      newState['otp'] = numericValue;
    }
    setState({ ...newState });
  };
  const handleLoginWithOTP = () => {
    const { username } = state;
    if (!username) {
      return;
    }

    dispatch(doLoginWithOtp({ username }));
    setIsOtpSent(true);
    setViewOtp(true);

    // if (username && !otp) {
    //   setViewOtp(true);
    //   const otpResult = await API.post('/app/login/otp', { username });
    //   const { data } = otpResult;
    //   // console.log(data);
    //   setIsOtpSent(true);
    //   // return;
    // } else if (username && !isOtpSent) {
    //   const otpResult = await API.post('/app/login/verify-otp', { username });
    //   const { data } = otpResult;
    // }
  };
  const handleVerifyOTP = () => {
    const { username = '', otp = '' } = state;

    dispatch(verifyLoginOtp({ username, otp }));
  };
  // const { data } = props;
  // const { email } = data;
  const { username, otp } = state;
  return (
    <form action="">
      <SbTextField
        placeholder="Email ID/Phone no"
        label="Email ID/Phone no"
        name="username"
        value={username || ''}
        onChange={handleChange}
        style={{ marginBottom: '10px' }}
      />
      <br />
      {viewOtp && (
        <>
          <SbTextField
            placeholder="OTP"
            label="Enter OTP"
            name="otp"
            value={otp || ''}
            onChange={handleChange}
            style={{ marginBottom: '10px' }}
            // disabled={!viewOtp}
          />
          <div className="flex justify-between">
            <p>A 4-digit code has been sent to your Email ID/phone no.</p>
            <p style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={handleLoginWithOTP}>
              Resend OTP
            </p>
          </div>
          <br />
          <ButtonContainer fullWidth variant="contained" onClick={handleVerifyOTP}>
            Login
          </ButtonContainer>
        </>
      )}
      {!viewOtp && (
        <ButtonContainer fullWidth variant="contained" onClick={handleLoginWithOTP}>
          Login With OTP
        </ButtonContainer>
      )}
      <br />
      <ButtonContainer fullWidth variant="outlined" onClick={() => props.setLoginMethod(true)}>
        Login With Password
      </ButtonContainer>
    </form>
  );
};

interface LoginProps {
  setLoginMethod: (t1: boolean) => void;
  data: {
    email?: string;
    password?: string;
    otp?: string;
  };
}

// const SignupComponent: React.FC<LoginProps> = (props: LoginProps): JSX.Element => {
//   // const dispatch = useDispatch();
//   // const formState = useSelector((state: RootStateType) => state?.loginform?.value);
//   const { data } = props;
//   const { email, password } = data;

//   // const handleChange = () => {};
//   return (
//     <form action="">
//       <SbTextField placeholder="Enter name" label="Name" style={{ marginBottom: '10px' }} value={email || ''} />
//       <SbTextField
//         placeholder="Email ID/Phone no"
//         label="Email ID/Phone no"
//         style={{ marginBottom: '10px' }}
//         value={email || ''}
//       />
//       <SbDropdown
//         id="country"
//         options={[{ display: 'india', value: 'in' }]}
//         placeholder="country"
//         label="Select Country"
//         onChange={() => ({})}
//       />
//       <SbTextField
//         placeholder="Password"
//         label="Enter Password"
//         type="password"
//         style={{ marginBottom: '15px' }}
//         value={password || ''}
//         endAdornment={<VisibilityIcon style={{ fontSize: '17px', marginRight: '5px' }} />}
//       />
//       <SbTextField
//         placeholder="Password"
//         label="Re - Enter Password"
//         type="password"
//         style={{ marginBottom: '15px' }}
//         value={password || ''}
//         endAdornment={<VisibilityIcon style={{ fontSize: '17px', marginRight: '5px' }} />}
//       />
//       {/* <p style={{ color: 'red' }}>Forgot Password?</p> */}
//       <br />
//       <ButtonContainer fullWidth variant="contained">
//         Signup
//       </ButtonContainer>
//       <br />
//       {/* <ButtonContainer fullWidth variant="outlined" onClick={() => props.setLoginMethod(false)}>
//         Login With OTP
//       </ButtonContainer> */}
//     </form>
//   );
// };

export default Login;
