import SbButton from '@Components/Button';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import Loader from '@Components/Loader';
import Title from '@Components/Title';
import { getServiceList } from '@Reducers/CarrierAndService';
import { ServiceObj } from '@Reducers/CarrierAndService/Type';
import { onChangePagination, resetRateSummary, updateFilters } from '@Reducers/RateSummary';
import { getRatesSheet } from '@Reducers/RateSummary/actions';
import { getActiveSystemList } from '@Reducers/Systems';
import { SystemObj } from '@Reducers/Systems/Type';
import { AppDispatch, RootStateType } from '@Store';
import { TabContext, TabList } from '@mui/lab';
import { Grid, Tab, TableContainer, TablePagination } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RatesTable } from './Table';
import DatePicker from '@Components/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

const SearchFilterList = [{ label: 'Services', value: 'services' }];
type TabValues = 'Outbound' | 'Inbound' | string;
const TABS: { label: string; value: string }[] = [
  {
    value: 'outbound',
    label: 'Outbound',
  },
  {
    value: 'inbound',
    label: 'Inbound',
  },
];

const RateTypeOptions: DropdownOptionType<string>[] = [
  { value: 'parcel', display: 'Parcel' },
  { value: 'document', display: 'Document' },
];

type GetOptionsArr<keys extends string> = {
  [displayKey in keys]: string;
};

function getOptionFromList<D extends string, I extends string>(
  arr: GetOptionsArr<D | I>[],
  displayKey: D,
  idKey: I,
): DropdownOptionType[] {
  const optionsList = arr.map(item => ({ display: item[displayKey], value: item[idKey] }));
  return optionsList;
}

export default function MyRates() {
  const [filterSelectValue, setFilterSelectValue] = useState('services');
  const dispatch = useDispatch<AppDispatch>();
  const [tabValue, setTabValue] = useState<TabValues>('outbound');
  const [systemValues, setSystemValues] = useState<string[]>([]);
  const [rateType, setRateType] = useState<string[]>([]);
  const [systemOptions, setSystemOptions] = useState<DropdownOptionType[]>([]);
  const SystemList: SystemObj[] = useSelector<RootStateType>(state => state.System.activeSystemList) as SystemObj[];
  const [cspValues, setCspValues] = useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const { profile, loading: accountLoader } = useSelector((state: RootStateType) => state.loginUser);
  const isDefaultAccount = profile?._accountId?.isDefaultAccount;
  const accountNumber = profile?.accountNumber;
  // const { token, loading: accountLoader } = useSelector((state: RootStateType) => state.loginUser);
  const RateState = useSelector((state: RootStateType) => state.RateSummary);
  const { currentPage, totalCount, perPage } = RateState;
  const [cspOptions, setCspOptions] = useState<DropdownOptionType[]>([]);
  const CspList: ServiceObj[] = useSelector<RootStateType>(
    state => state.carrierAndService.serviceList,
  ) as ServiceObj[];

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    setTabValue(currentTab);
  };

  useEffect(function () {
    dispatch(getServiceList({}));
    dispatch(getActiveSystemList());
    return () => {
      dispatch(resetRateSummary());
    };
  }, []);

  useEffect(
    function () {
      dispatch(updateFilters({ csp: cspValues, system: systemValues, rateType: rateType[0] }));
    },
    [cspValues, systemValues, rateType],
  );

  useEffect(
    function () {
      setSystemOptions(getOptionFromList(SystemList, 'name', '_id'));
    },
    [SystemList, setSystemOptions],
  );

  useEffect(
    function () {
      setCspOptions(getOptionFromList(CspList, 'name', '_id'));
    },
    [CspList, setCspOptions],
  );

  useEffect(
    function () {
      dispatch(
        getRatesSheet({
          navigatedDate: startDate.toDate().toISOString(),
          pageType: isDefaultAccount ? 'tariff' : 'contract',
          accountNumber: accountNumber || '',
          isImport: tabValue === 'inbound',
          system: [],
          csp: [],
          rateType: [],
        }),
      );
    },
    [accountNumber, tabValue, isDefaultAccount, startDate],
  );

  const onClearFilter = () => {
    setSystemValues([]);
    setRateType([]);
    setCspValues([]);
    setFilterValue('');
    setStartDate(dayjs());
  };

  return (
    <Loader loading={RateState.loading || accountLoader} overly>
      <>
        <Title title="Rate Sheets" removeBorder subTitle="Download Rate Sheets" />
        <TabContext value={tabValue}>
          <Grid container sx={{ borderBottom: '1px solid #E4E4E4', marginBottom: '50px' }}>
            <TabList
              onChange={onTabChange}
              indicatorColor="primary"
              TabIndicatorProps={{ sx: { height: '4px' } }}
              scrollButtons="auto"
              variant="scrollable"
              sx={{
                minHeight: '38px',
              }}
            >
              {TABS.map(cTab => (
                <Tab
                  sx={{ textTransform: 'none', padding: '0px 8px', minHeight: 'auto' }}
                  className="md-text font-medium sb-text-black"
                  value={cTab.value}
                  label={cTab.label}
                  key={cTab.value}
                />
              ))}
            </TabList>
          </Grid>
        </TabContext>
        <TableActionContainer>
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={SearchFilterList}
            selectValue={filterSelectValue}
            textValue={filterValue}
            onTextChange={e => {
              setFilterValue(e.target.value);
            }}
            onTextSearch={() => {
              if (filterValue?.length > 0) {
                if (
                  cspOptions?.filter(option => option?.display?.toLowerCase()?.startsWith(filterValue?.toLowerCase()))
                    ?.length
                ) {
                  setCspValues(
                    cspOptions
                      ?.filter(option => option?.display?.toLowerCase()?.startsWith(filterValue?.toLowerCase()))
                      ?.map(item => item?.value),
                  );
                } else {
                  setCspValues([filterValue]);
                }
              }
            }}
            onTextClear={() => {
              setCspValues([]);
              setFilterValue('');
            }}
          />
          <DatePicker
            label=""
            value={startDate}
            handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
              setStartDate(date);
            }}
          />
          <div></div>

          <FilterSelect
            id="shipment-type"
            value={rateType}
            options={RateTypeOptions}
            label="Shipment Type "
            onChange={selected => setRateType(selected)}
          />
          <FilterSelect
            id="system-filter"
            value={systemValues}
            options={systemOptions}
            label="System"
            onChange={selected => setSystemValues(selected)}
          />

          <SbButton
            className="xs-text"
            variant="outlined"
            sx={{ borderRadius: '8px !important', padding: '8px !important' }}
            onClick={onClearFilter}
          >
            Clear Filters
          </SbButton>
        </TableActionContainer>
        <TableContainer id="fadedScroll">
          <RatesTable />
        </TableContainer>
        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                dispatch(onChangePagination(page));
              }}
              rowsPerPageOptions={[]}
            />
          </>
        )}
      </>
    </Loader>
  );
}

const TableActionContainer = styled('div')(({ theme }) => ({
  // padding: '32px 16px',
  margin: '2rem 0',
  display: 'grid',
  // gridTemplateColumns: '480px 1fr fit-content(180px) fit-content(150px) fit-content(150px) fit-content(150px) 100px',
  gridTemplateColumns: '380px 200px 1fr fit-content(150px) fit-content(150px) fit-content(150px)',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '0',
    columnGap: '8px',
    gridTemplateColumns: '380px 200px 1fr fit-content(150px) fit-content(150px) fit-content(150px)',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));
