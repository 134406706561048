import { PayloadAction } from '@reduxjs/toolkit';
import {
  AccessGroupByIdObj,
  AccessGroupCreatedType,
  AccessGroupListResponse,
  AccessGroupState,
  ActionIdsType,
  ActionsDataEntity,
  ActionsListResponse,
  ErrorType,
  PagesDataEntity,
  PagesDataSideNavbar,
  PagesListResponse,
  actionsList,
  pagesList,
  sideNavTypes,
} from './Type';

function handlePending(state: AccessGroupState) {
  state.loading = true;
  state.success = '';
  state.error = {
    msg: '',
    errorDetails: '',
    isError: false,
  };
}

function handleRejected(state: AccessGroupState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = { msg: action.payload?.msg || action.payload?.errorDetails || 'Error', isError: true };
}

function getAccessGroupFulfilled(state: AccessGroupState, action: { payload: AccessGroupListResponse }) {
  const { data } = action.payload;
  state.data.list = data;
  state.data.totalCount = data.length;
  state.loading = false;
}

function getAccessGroupByIdFulfilled(state: AccessGroupState, action: { payload: AccessGroupByIdObj }) {
  state.accessGroupById = action.payload;
  state.loading = false;
  state.pageIds = action.payload.pagesId ?? [];
  state.actionsIds = (action.payload.actions ?? [])?.map(item => ({
    selectedActions: item?.selectedActions,
    _id: item?._id?._id,
  })) as ActionIdsType[];
}

function getSideNavbarPagesFulfilled(state: AccessGroupState, action: { payload: AccessGroupByIdObj }) {
  state.menuAcccessGroupById = action.payload;
  state.loading = false;
  state.SideNavbarPagesIds = action.payload?.pagesId ?? [];
  state.SideNavbarActionsIds = (action.payload?.actions ?? [])?.map(item => ({
    selectedActions: item?.selectedActions || [],
    _id: item?._id?._id || '',
    details: item?._id,
  })) as unknown as sideNavTypes[]; // Convert to 'unknown' first
}
function updateAccessGroupFullfilled(state: AccessGroupState, action: { payload: AccessGroupByIdObj }) {
  state.accessGroupById = action.payload;
  state.loading = false;
}

function createAccessGroupFulfilled(state: AccessGroupState, action: PayloadAction<AccessGroupCreatedType>) {
  state.loading = false;
  state.accessGroupCreated = action.payload;
}

function createAccountRejected(state: AccessGroupState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.accessGroupCreated = {
    isError: true,
    msg: action.payload?.msg || action.payload?.errorDetails || 'Unable to create access group',
  };
}

function getPagesFulfilled(state: AccessGroupState, action: PayloadAction<PagesListResponse>) {
  const res = action.payload.data?.reduce((acc: pagesList, page: PagesDataEntity) => {
    if (page?.tag && page?.tag?.length > 0) {
      const { tag } = page;
      const tagName = tag[0];
      acc[tagName] = acc[tagName] || [];
      acc[tagName].push(page);
    }
    return acc;
  }, {});
  state.pagesList = res;
  state.loading = false;
  state.PagesData = action.payload.data?.map((item: PagesDataSideNavbar) => ({
    _id: item._id,
    name: item.name,
    tag: item.tag,
    route: item.route,
  }));
}

function getActionsFulfilled(state: AccessGroupState, action: PayloadAction<ActionsListResponse>) {
  const res = action.payload.data.reduce((acc: actionsList, page: ActionsDataEntity) => {
    const { _id } = page;
    acc[_id] = { ...page, ...checkAllowedActions(page.allowedActions) };
    return acc;
  }, {});
  state.actionsList = res;
  state.loading = false;
}

export function checkAllowedActions(actions: string[]) {
  let isReadOnly = false,
    isWriteOnly = false;
  if (actions?.length) {
    if (actions[0] === 'r') {
      isReadOnly = true;
    }
    if (actions[0] === 'w' || actions[1] === 'w') {
      isWriteOnly = true;
    }
  }
  return { isReadOnly, isWriteOnly };
}

export {
  handlePending,
  handleRejected,
  getAccessGroupFulfilled,
  getAccessGroupByIdFulfilled,
  createAccessGroupFulfilled,
  createAccountRejected,
  getPagesFulfilled,
  getActionsFulfilled,
  updateAccessGroupFullfilled,
  getSideNavbarPagesFulfilled,
};
