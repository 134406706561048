import * as Yup from 'yup';

export const chargesSchemaObject = {
  chargeName: Yup.string().required('Charge name is required'),
  value: Yup.number().required().min(0.01),
  chargeType: Yup.string().required('Charge type is required'),
  selectedApplyOn: Yup.array(Yup.string()).when('chargeType', (chargeType, schema) => {
    if (chargeType[0] === 'percentage') return schema.min(1, 'At least one apply on is required');
    return schema;
  }),
};

export const chargesSchema = Yup.object().shape(chargesSchemaObject);

const globalChargeForm = {
  fromDate: Yup.string().trim().required('Start date is required'),
  toDate: Yup.string().trim().required('End date is required'),
  _systemId: Yup.string().trim().required('System is required'),
  _courierId: Yup.string().trim().required('Service is required'),
};

const copyRatesForm = {
  fromDate: Yup.string().trim().required('Start date is required'),
  toDate: Yup.string().trim().required('End date is required'),
  _systemId: Yup.array(Yup.string()).required('System is required'),
  _courierId: Yup.string().trim().required('Service is required'),
};

export const createGlobalChargeSchema = Yup.object().shape({
  ...globalChargeForm,
  // charges: Yup.array(Yup.object({ ...chargesSchemaObject })).min(1, 'At least one charge is required'),
  charges: Yup.array(Yup.object({ ...chargesSchemaObject })),
  copyRates: Yup.array(Yup.object({ ...copyRatesForm })),
});

export const copyRatesSchema = Yup.object().shape({
  ...copyRatesForm,
});
