import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { SingleDocumentType } from './Type';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@Store';
import { viewSignaleDocument } from '@Reducers/kyc/actions';

const Wrapper = styled(Grid)(() => ({
  borderBottom: '1px solid',
  padding: '20px 0 20px 15px',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const StyleIconButton = styled(IconButton)(({ theme: _ }) => ({
  border: '1px solid #b2b2b2',
  padding: 8,
  borderRadius: 8,
  color: '#000',
  fontSize: '24px',
}));

function SingleDocument(props: SingleDocumentType) {
  const {
    label,
    required,
    documentData,
    onDocumentUploadModalOpen,
    // onDocumentDeleteModalOpen,
    modalView = false,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const onViewDocument = () => {
    if (documentData?._document) {
      dispatch(viewSignaleDocument({ documentId: documentData?._document, url: 'user' }));
    }
  };

  return (
    // <Grid container>
    <Wrapper
      container
      item
      alignItems={modalView ? 'baseline' : 'flex-end'}
      className={`border-grey`}
      // spacing={2.5}
    >
      <Grid container item xs={12} md={8} lg={7} alignItems="center" spacing={0.5}>
        <Grid item xs={12}>
          <Typography className="md-text font-semibold">
            {label} {required ? '*' : ''}
          </Typography>
        </Grid>
        {!modalView && (
          <Grid item xs={12}>
            <Typography className="sm-text">{documentData?.value || '-'}</Typography>
          </Grid>
        )}
        {/* <SbTextField label={label} value={value} required={required} disabled className="w-full" /> */}
      </Grid>
      <Grid container item xs={12} md={4} lg={5} gap={2}>
        {!documentData && (
          <StyleIconButton onClick={() => onDocumentUploadModalOpen(label, documentData)}>
            <AddIcon fontSize="inherit" />
          </StyleIconButton>
        )}
        {documentData && (
          <>
            {/* <Link to={`${CONFIG.API_URI}/user/file/get-url/${documentData?._document}`} target="_blank"> */}
            <StyleIconButton onClick={onViewDocument}>
              <VisibilityIcon fontSize="inherit" />
            </StyleIconButton>
            {/* </Link> */}
            {/* <StyleIconButton onClick={() => onDocumentDeleteModalOpen(documentData._id)}>
              <DeleteIcon fontSize="inherit" />
            </StyleIconButton> */}
            <StyleIconButton onClick={() => onDocumentUploadModalOpen(label, documentData)}>
              <ModeEditIcon fontSize="inherit" />
            </StyleIconButton>
          </>
        )}
      </Grid>
    </Wrapper>
    // </Grid>
  );
}

export default SingleDocument;
