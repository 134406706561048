import FilterSelect from '@Components/Filter/Dropdown';
import { RootStateType } from '@Store';
import DateRangePicker from '../../components/DateRangePicker';

import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import DownloadButton from './DownlaodButton';
import TrackingGraph from './TrackingGraph';
import TrackingTable, { Shipment } from './TrackingTable';
import API from '@Services/apiAxios';
import { ActionPending, ApiResponseTracking } from './types/type';

const TrackingStatus = () => {
  const [system, setSystem] = useState<string[]>([]);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [trackingStatus, setTrackingStatus] = useState<ApiResponseTracking | null>(null);
  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
  };
  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
  };

  const fetchDataForTrackingStatus = async () => {
    try {
      const resp = await API.get('/analytics/shipment/general', {
        params: {
          'system[]': system,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        },
      });
      setTrackingStatus(resp.data);
    } catch (error) {
      console.error('Error in fetching data for customers', error);
    }
  };

  useEffect(() => {
    fetchDataForTrackingStatus();
  }, [system, dateRange]);

  // console.info('trackingStatus', trackingStatus?.data);
  const mapToShipments = (actionPending: ActionPending[]): Shipment[] => {
    return actionPending?.map(item => ({
      id: item?.shipmentNumber,
      status: item?.status,
      statusLabel: item?.statusLabel,
      account: item?._userId.companyName || item?._userId.username, // Use companyName or username if companyName is empty
      accountId: item?._userId.accountNumber || item?._userId.accountNumber, // Use companyName or username if companyName is empty
    }));
  };

  // const mapToTrackingData = (trackingData: TrackingData[]): TrackingData => {
  //   return {
  //     labels: trackingData.labels,
  //     data: trackingData.data,
  //     backgroundColor: trackingData.backgroundColor,
  //   };
  // };
  return (
    <Box
      sx={{
        borderRadius: '9px',
        border: '1px solid #F1F1F1',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        // height: '678px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        padding: '40px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column-reverse', sm: 'row-reverse' },
          gap: '10px',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <DateRangePicker onChange={onDateChange} value={dateRange} />
        </Box>
        <Box
          sx={{
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          <FilterSelect
            id="system"
            label="Select system"
            options={loggedInUserSystemDropdownList}
            value={system}
            onChange={onSystemFilter}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          '@media (min-width: 600px)': { flexDirection: 'row', justifyContent: 'space-between' },
        }}
      >
        <Box sx={{ flex: 1 }}>
          <TrackingGraph
            shipmentPercentage={trackingStatus?.data?.shipmentPercentage}
            trackingData={trackingStatus?.data?.trackingData}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            borderLeft: '1px dashed rgba(189, 189, 189, 1)',
            paddingLeft: '40px',
            '@media (max-width: 600px)': {
              borderLeft: 'none',
              paddingLeft: 0,
            },
          }}
        >
          <TrackingTable
            shipments={trackingStatus?.data.actionPending && mapToShipments(trackingStatus?.data.actionPending)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TrackingStatus;
