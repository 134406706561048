import { GROUPS } from '@Constants/user';
import { rootStore } from '@Store';

export function hasAdminAccess() {
  const accessGroup = rootStore.getState().loginUser.profile?.accessGroup?.toLowerCase();
  return accessGroup && (GROUPS[accessGroup]?.admin || accessGroup === 'superadmin') ? true : false;
}

export function userLogin() {
  const accessGroup = rootStore.getState().loginUser.profile?.accessGroup;
  return accessGroup === GROUPS.pu_all.code;
}
