import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { DragHandleRounded } from '@mui/icons-material';
import { Chip, Stack, TableBody, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { RouteOptimizerDetailsProps, Place, Stop } from './Types';
import { manualOptimize } from '@Reducers/Squad/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@Store';
import Loader from '@Components/Loader';
import { DropResult } from 'react-beautiful-dnd';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

export function RouteOptimizerDetailsTable(props: RouteOptimizerDetailsProps) {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setLoading] = useState(false);
  const { setAllStops, AllStops, setIsOptimized, SetIsManual } = props;

  const onChangeOrder = async (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (source.index === destination.index) {
      const sourceItem = AllStops[source.index];
      const destinationItem = AllStops[destination.index];
      if (sourceItem === destinationItem) {
        return;
      }
    }

    const updatedStops: Stop[] = Array.from(AllStops);

    const [movedItem] = updatedStops.splice(source.index, 1);

    updatedStops.splice(destination.index, 0, movedItem);

    const places = [] as Place[];
    updatedStops.forEach((stop: Stop, index) => {
      if (index === updatedStops.length - 1) {
        return;
      }
      places.push({
        index,
        source: stop.query,
        destination: updatedStops[index + 1].query,
      });
    });

    const postData = {
      places: places,
      vehicleType: props.SquadMembervehicle,
    };
    try {
      setLoading(true);
      const pickedUp: Record<string, boolean> = {};
      updatedStops.forEach(stop => {
        stop.activities.forEach(activity => {
          if (activity.shipmentId === 'arrival' || activity.shipmentId === 'departure') {
            return;
          }
          if (activity.isPickup) {
            pickedUp[activity.shipmentId] = true;
          } else if (!activity.isPickup && !pickedUp[activity.shipmentId]) {
            throw { msg: 'delivery' };
          }
        });
      });
      const optimizeDistance = await dispatch(manualOptimize({ postData })).unwrap();

      const newStops = updatedStops.map((stop, index) => {
        if (index === 0) {
          return { ...stop, distance: 0 };
        } else {
          return {
            ...stop,
            distance: optimizeDistance[index - 1]?.distance || stop.distance,
          };
        }
      });

      setAllStops(newStops);
      SetIsManual(true);
      setIsOptimized(true);
    } catch (error) {
      if (error.msg === 'delivery') {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Delivery cannot be above pickup',
            subTitle: '',
          }),
        );
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Something went wrong',
            subTitle: '',
          }),
        );
      }
      console.error('Error during optimization:', error);
    }
    setLoading(false);
  };

  const shipmentActivity = (activity: any) => {
    let pickupCount = 0;
    let deliveryCount = 0;
    const filteredArr = activity.filter(
      (item: any) => item.shipmentNumber !== 'departure' && item.shipmentNumber !== 'arrival',
    );

    filteredArr.forEach((item: any) => {
      if (item.isPickup) {
        pickupCount += 1;
      } else {
        deliveryCount += 1;
      }
    });

    return {
      pickupCount,
      deliveryCount,
    };
  };

  return (
    <>
      <Loader loading={isLoading} overly />
      <DragDropContext onDragEnd={onChangeOrder}>
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell width="100px">Sr no.</TableHeadCell>
              <TableHeadCell>Shipment</TableHeadCell>
              <TableHeadCell>Distance</TableHeadCell>
              <TableHeadCell>Type</TableHeadCell>
              <TableHeadCell width={'20%'}>Address</TableHeadCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="droppable-1" type="routes">
            {provided => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {AllStops?.map((data: any, index: number) => {
                  const { activities, addressId, distance, stopName } = data;
                  const typeObject = shipmentActivity(activities);
                  const distanceInKm = distance / 1000;

                  if (activities.length > 1 && data.type === 'shipment') {
                    return (
                      <Draggable key={`group-${data._id}`} draggableId={`group-${data._id}`} index={index}>
                        {provided => (
                          <>
                            {activities.map((activity: any, activityIndex: number) => (
                              <SBTableRow
                                key={`activity-${activity._id}`}
                                ref={activityIndex === 0 ? provided.innerRef : null}
                                {...(activityIndex === 0 ? provided.draggableProps : {})}
                                {...(activityIndex === 0 ? provided.dragHandleProps : {})}
                              >
                                {/* Shared data for multiple rows */}
                                <TableRowCell align="center" className="md-text">
                                  {activityIndex == 0 ? (
                                    <Stack direction="row" gap={1}>
                                      <DragHandleRounded /> {index}
                                    </Stack>
                                  ) : (
                                    index
                                  )}
                                </TableRowCell>
                                <TableRowCell align="center" className="sm-text font-medium">
                                  {activity.shipmentNumber || 'Warehouse'}
                                </TableRowCell>

                                <TableRowCell align="center" className="sm-text font-medium">
                                  {distanceInKm} Km
                                </TableRowCell>
                                <TableRowCell align="center" className="sm-text font-medium">
                                  <Chip
                                    label={`${activity.isPickup ? '1 Pickup' : 'Delivery'}`}
                                    sx={{
                                      bgcolor: activity.isPickup ? 'success.light' : 'error.light',
                                      color: activity.isPickup ? 'success.main' : 'error.main',
                                      fontWeight: 500,
                                    }}
                                  />
                                </TableRowCell>
                                <TableRowCell align="left" className="sm-text font-medium">
                                  {addressId.line1},<br />
                                  {addressId.line2 && `${addressId.line2},`}
                                  <br />
                                  {addressId.city} - {addressId.pincode}
                                  <br />
                                  {addressId.state}, {addressId.country}
                                </TableRowCell>
                              </SBTableRow>
                            ))}
                          </>
                        )}
                      </Draggable>
                    );
                  }

                  if (activities.length != 0) {
                    return (
                      <Draggable key={data._id} draggableId={`${data._id}`} index={index}>
                        {provided => (
                          <SBTableRow
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <TableRowCell align="center" className="md-text">
                              <Stack direction="row" gap={1}>
                                <DragHandleRounded /> {index}
                              </Stack>
                            </TableRowCell>
                            <TableRowCell align="center" className="sm-text font-medium">
                              {stopName}
                            </TableRowCell>

                            <TableRowCell align="center" className="sm-text font-medium">
                              {distanceInKm} Km
                            </TableRowCell>
                            <TableRowCell align="center" className="sm-text font-medium">
                              {typeObject.pickupCount > 0 && (
                                <Chip
                                  label={`${typeObject.pickupCount} pickup`}
                                  sx={{
                                    bgcolor: 'success.light',
                                    color: 'success.main',
                                    fontWeight: 500,
                                  }}
                                />
                              )}
                              {typeObject.deliveryCount > 0 && (
                                <Chip
                                  label={`${typeObject.deliveryCount} Delivery`}
                                  sx={{
                                    bgcolor: 'success.light',
                                    color: 'success.main',
                                    fontWeight: 500,
                                  }}
                                />
                              )}
                            </TableRowCell>
                            <TableRowCell align="left" className="sm-text font-medium">
                              {addressId.line1},<br />
                              {addressId.line2 && `${addressId.line2},`}
                              <br />
                              {addressId.city} - {addressId.pincode}
                              <br />
                              {addressId.state}, {addressId.country}
                            </TableRowCell>
                          </SBTableRow>
                        )}
                      </Draggable>
                    );
                  }
                })}
                {provided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </SBTable>
      </DragDropContext>
    </>
  );
}
