import SbButton from '@Components/Button';
import { Paper, alpha, styled } from '@mui/material';

export const TableActionContainer = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
  margin: '32px 0',
  display: 'grid',
  // gridTemplateColumns: '510px 1fr 184px 184px 200px 136px 113px',
  gridTemplateColumns: '510px 1fr 184px 200px 136px 113px',

  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: '10px',
    // gridTemplateColumns: '490px 1fr 184px 184px 200px 136px 113px',
    gridTemplateColumns: '510px 1fr 184px 200px 136px 113px',
  },
}));

export const SystemBox = styled(Paper)(({ theme }) => ({
  maxWidth: '126px',
  minWidth: '110px',
  margin: 'auto',
  maxHeight: '100px',
  overflow: 'hidden',
  //   border: `0.5px solid ${alpha(theme.palette.border.grey, 0.5)}`,
  boxShadow: `0px 12px 16px -4px ${alpha(theme.palette.border.grey, 0.8)}`,
  '&:hover': {
    overflow: 'auto',
  },
}));

export const AddNewContractButton = styled(SbButton)(({ theme }) => ({
  borderRadius: '8px !important',
  borderStyle: 'dashed',
  borderColor: theme.palette.border.grey,
  color: theme.palette.text.light,
  '&:hover': {
    borderStyle: 'dashed',
    borderColor: theme.palette.border.grey,
  },
  marginTop: '30px',
}));
