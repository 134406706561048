import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import CreateSquadForm, { initCreateSquad } from './Form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { SquadType } from './type';
// import { createSystem } from '@Store/reducers/Systems';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { CreateSquadSchema, UpdateSquadSchema, CreateWarehouseAgentSchema, UpdateWarehouseAgentSchema } from './schema';
import SbButton from '@Components/Button';
import Loader from '@Components/Loader';
import SuccessModal from './SuccessModal';
import API from '@Services/apiAxios';
import { getLoggedInUserSystemList } from '@Reducers/Systems';
import { getAdminListForTag } from '@Reducers/Admin/actions';
import { createSquad, updateSquad } from '@Reducers/Squad/actions';

function CreateSquad() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { squadId } = useParams<{ squadId: string }>();
  // const [system, _setSystem] = useState<SquadType>({ ...initCreateSquad });
  // const SystemState = useSelector((state: RootStateType) => state.System);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<SquadType>(initCreateSquad);
  const [currentSquad, setCurrentSquad] = useState<string>('');
  const [editLoading, setEditLoading] = useState(false);
  const [squadType, setQuadType] = useState<string>('');
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const { adminListForTag: adminList } = useSelector((state: RootStateType) => state.admin);
  const loading = useSelector((state: RootStateType) => state.squadManagement.loading);

  useEffect(() => {
    if (squadId) {
      setEditLoading(true);
      const fetchSquadData = async () => {
        try {
          const response = await API.get(`squad/admin/squads/${squadId}`);
          const responseIntitals = {
            ...response.data.data.squad,
            system: response.data.data.squad.system._id,
          };
          setInitialValues(responseIntitals);
          setEditLoading(false);
        } catch (error) {
          console.error('Error fetching squad data:', error);
        }
      };

      fetchSquadData();
    }
    dispatch(getLoggedInUserSystemList());
    dispatch(getAdminListForTag());
  }, [squadId, dispatch]);

  const onCreate = async (params: SquadType) => {
    try {
      const payload = {
        ...params,
        isAvailable: true,
        isOnline: false,
        zones: params.zones,
      };

      if (params.password && params.password.length > 0) {
        payload.password = params.password;
      }

      if (!squadId) {
        let result;
        if (squadType === 'squad_driver') {
          result = await dispatch(createSquad({ postData: payload }));
        } else {
          const { phoneNumber, vehicle, ...restFields } = payload;

          const updatedPayload = {
            ...restFields,
            phoneNumber: restFields.reportToPhoneNumber,
            telephoneCode: restFields.reportToTelephoneCode,
          };
          result = await dispatch(createSquad({ postData: updatedPayload }));
        }
        setCurrentSquad(result.payload?.id);
        result.payload && setIsModalOpen(true);
      } else {
        // Update squad
        const { document, ...UpdatedPayload } = payload;
        UpdatedPayload.zones = UpdatedPayload.zones.filter((zone: string) => zone.length === 1);
        await dispatch(
          updateSquad({ payload: document?.length !== 0 ? payload : UpdatedPayload, squadId, navigate: '/squad' }),
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true, // Disable validation on change
    validateOnBlur: true, // Validate only on blur (losing focus)
    initialValues: initialValues || initCreateSquad,
    validationSchema: squadId
      ? squadType === 'warehouse_agent'
        ? UpdateWarehouseAgentSchema
        : UpdateSquadSchema
      : squadType === 'warehouse_agent'
      ? CreateWarehouseAgentSchema
      : CreateSquadSchema,
    onSubmit(values) {
      onCreate(values);
    },
  });

  return (
    <Loader loading={loading || editLoading} overly>
      <>
        <Grid container>
          <Grid item xs={12}>
            <CreateSquadForm
              formType={squadId ? 'edit' : 'create'}
              showBackgroundColor={false}
              touched={formik.touched}
              errors={Object.keys(formik.touched).length ? formik.errors : {}}
              values={formik.values}
              handleChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
              systemOptions={loggedInUserSystemDropdownList}
              adminOptions={adminList?.map(admin => ({ display: admin.name, value: admin._id })) || []}
              squadId={squadId}
              setQuadType={setQuadType}
            />
          </Grid>
          <Grid container item mt={4} spacing={2} justifyContent="flex-end">
            <Grid item>
              <SbButton
                fullWidth
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </SbButton>
            </Grid>
            <Grid item>
              <SbButton
                fullWidth
                variant="contained"
                onClick={_event => {
                  formik.handleSubmit();
                  // onCreate(formik.values);
                }}
              >
                Save
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
        {isModalOpen && (
          <SuccessModal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
              navigate('/squad');
            }}
            copyObj={{
              username: formik.values.username,
              password: formik.values.password || '',
            }}
            squadId={currentSquad || ''}
          />
        )}
      </>
    </Loader>
  );
}

export default CreateSquad;
