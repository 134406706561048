import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { AppDispatch, RootStateType } from '../../store';

import ShipmentSettingForm from './Form';
import { SHIPMENT_STETTING_FORM } from './type';
import { useFormik } from 'formik';
import { ShipmentSettingSchema } from './schema';

import {
  getShipmentSettings,
  saveShipmentSettings,
  resetShipmentSettings,
} from '../../store/reducers/OrganizationSetup';
import SbButton from '../../components/Button';
import Loader from '../../components/Loader';
import { Grid } from '@mui/material';
import { DEFAULT_DOC_LIST, DEFAULT_SHIPMENT_CHARGES_LIST } from '../OrganizationSetup/constants';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

export const initForm: SHIPMENT_STETTING_FORM = {
  shipmentCharges: [],
  personalShipmentId: [],
  exportShipmentId: [],
  pickupTimeSlots: {
    slots: [],
    slotGroup: '',
  },
  packagingCharge: {
    charge: '',
    chargeType: 'fixed',
  },
  insuranceCharge: {
    charge: '',
    chargeType: 'fixed',
  },
  enablePackagingCharge: false,
  enableInsuranceCharge: false,
};

function ShipmentSetting() {
  const dispatch = useDispatch<AppDispatch>();
  const [shipmentSettingState, setShipmentSettingState] = useState<SHIPMENT_STETTING_FORM>({ ...initForm });
  const { shipmentSettingSuccess, shipmentSetting, loading } = useSelector((state: RootStateType) => state?.orgSetup);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentSettingEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Settings.edit_shipment,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    dispatch(getShipmentSettings());
  }, []);

  useEffect(() => {
    const shipmentCharges = shipmentSetting?.shipmentCharges.length
      ? [...(shipmentSetting?.shipmentCharges || [])]
      : DEFAULT_SHIPMENT_CHARGES_LIST;
    // shipmentSetting?.shipmentCharges.forEach((charge, ind) => {
    //   if (defaultChargesValues.includes(charge.value)) {
    //     shipmentCharges[ind] = { ...shipmentCharges[ind], isActive: true };
    //   }
    // });
    const personalShipmentId = shipmentSetting?.personalShipmentId
      ? [...(shipmentSetting?.personalShipmentId || [])]
      : DEFAULT_DOC_LIST;
    // shipmentSetting?.personalShipmentId.forEach((charge, ind) => {
    //   if (defaultDocValues.includes(charge.value)) {
    //     personalShipmentId[ind] = { ...personalShipmentId[ind], isActive: true };
    //   }
    // });
    const obj = {
      shipmentCharges,
      personalShipmentId,
      enablePackagingCharge: !!shipmentSetting?.packagingCharge.charge,
      enableInsuranceCharge: !!shipmentSetting?.insuranceCharge.charge,
    };

    if (shipmentSetting) setShipmentSettingState({ ...shipmentSetting, ...obj });
    else setShipmentSettingState(state => ({ ...state, ...obj }));
  }, [shipmentSetting]);

  useEffect(() => {
    if (shipmentSettingSuccess) {
      dispatch(resetShipmentSettings());
    }
  }, [shipmentSettingSuccess]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...shipmentSettingState },
    validationSchema: ShipmentSettingSchema,
    onSubmit(values) {
      dispatch(saveShipmentSettings({ payload: values, showModal: true }));
    },
  });

  return (
    <>
      <Loader overly loading={loading} />
      <ShipmentSettingForm
        title="Shipment Setting"
        subTitle="Please Update Shipment Setting"
        setFieldValue={formik.setFieldValue}
        handleChange={formik.handleChange}
        values={formik.values}
        errors={formik.errors}
        disabled={!isShipmentSettingEditAllowed}
      />
      <Grid container item mt={4} spacing={2} justifyContent="flex-end">
        <Grid item xs={6} sm={3} md={2}>
          <SbButton
            fullWidth
            variant="contained"
            onClick={_event => {
              formik.handleSubmit();
            }}
            disabled={!isShipmentSettingEditAllowed}
          >
            {shipmentSetting?._id ? 'Update' : 'Create'}
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

export default ShipmentSetting;
