import { DropdownOptionType } from '@Components/Dropdown/Type';
import { ShipmentByIdType } from '@Reducers/Shipment/Type';
import { ShipmentObj } from '@Reducers/ShipmentHistory/Type';
import { userLogin } from '@Services/hasAdminAccess';

export interface ShipmentStatusDetailsType {
  value: string;
  label: string;
  msg: string;
  bgColor: string;
  textColor: string;
  canUpdateShipperAddress?: boolean;
  canUpdateConsigneeAddress?: boolean;
  canUpdatePickupDropoff?: boolean;
  canUpdateShipmentDetails?: boolean;
  canUpdatemanifest?: boolean;
  canUpdateTracking?: boolean;
  isOther?: boolean;
  hide?: boolean;
  canConnectToWarehouse?: boolean;
  canAddTransitHub?: boolean;
  canUpdateBuyingDetails?: boolean;
  canUpdateSellingDetails?: boolean;
  canUpdateNotes?: boolean;
  canUpdateReferenceNo?: boolean;
  canUpdateFinalFwdAWB?: boolean;
  shipmentDelivered?: boolean;
}
export interface ShipmentStatusType {
  [key: string]: ShipmentStatusDetailsType;
}
const SHIPMENT_TYPE: DropdownOptionType[] = [
  { display: 'GIFT', value: 'GIFT' },
  { display: 'SAMPLE', value: 'SAMPLE' },
  { display: 'FOR SALE', value: 'FOR_SALE' },
];

const SHIPMENT_STATUS: ShipmentStatusType = {
  placed: {
    value: 'placed',
    label: 'Placed',
    msg: 'Shipment placed',
    bgColor: '#FFF9E9',
    textColor: '#755F27',
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
    canUpdatePickupDropoff: true,
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    canConnectToWarehouse: true,
    canUpdateFinalFwdAWB: true,
    canUpdateBuyingDetails: true,
    canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    canAddTransitHub: true,
  },
  assigned: {
    value: 'assigned',
    label: 'Assigned',
    msg: 'Shipment assigned for pickup/delivery.',
    bgColor: '#EAE9FF',
    textColor: '#2E2775',
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
    canUpdatePickupDropoff: true,
    canUpdateTracking: true,
    canUpdateBuyingDetails: true,
    canUpdateSellingDetails: true,
    canUpdateShipmentDetails: true,
    // canConnectToWarehouse: true,
    canUpdateFinalFwdAWB: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    canAddTransitHub: true,
  },
  // pickup_cancelled: {
  //   value: 'pickup_cancelled',
  //   label: 'Pickup cancelled',
  //   msg: 'Shipment pickup cancelled.',
  //   bgColor: '#FFEFE9',
  //   textColor: '#DA424E',
  //   canUpdateTracking: true,
  // },
  // delivery_assigned: {
  //   value: 'delivery_assigned',
  //   label: 'Assigned for Delivery',
  //   msg: 'Delivery assigned.',
  //   bgColor: '#EAE9FF',
  //   textColor: '#2E2775',
  //   canUpdateTracking: true,
  // },
  // delivery_cancelled: {
  //   value: 'delivery_cancelled',
  //   label: 'Delivery cancelled',
  //   msg: 'Shipment delivery cancelled.',
  //   bgColor: '#FFEFE9',
  //   textColor: '#DA424E',
  //   canUpdateTracking: true,
  // },
  picked_up: {
    value: 'picked_up',
    label: 'Picked Up',
    msg: 'Shipment picked up',
    bgColor: '#F1FFE9',
    textColor: '#457527',
    // canConnectToWarehouse: true,
    canUpdateFinalFwdAWB: true,
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    canUpdateBuyingDetails: true,
    canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    canAddTransitHub: true,
  },
  at_warehouse: {
    value: 'at_warehouse',
    label: 'At Warehouse',
    msg: 'Shipment at warehouse',
    bgColor: '#F4E9FF',
    textColor: '#4E2775',
    // canConnectToWarehouse: true,
    canUpdateFinalFwdAWB: true,
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    canUpdateBuyingDetails: true,
    canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    canAddTransitHub: true,
  },
  forwarded: {
    value: 'forwarded',
    label: 'Forwarded',
    msg: 'Shipment Forwarded to Carrier',
    bgColor: '#E9FFFA',
    textColor: '#277563',
    canUpdateTracking: true,
    canConnectToWarehouse: true,
    // canUpdateFinalFwdAWB: true,
    // canUpdateBuyingDetails: true,
    // canUpdateSellingDetails: true,
    canUpdateShipmentDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    // canAddTransitHub: true,
  },
  in_transit: {
    value: 'in_transit',
    label: 'In Transit',
    msg: 'Shipment in transit',
    bgColor: '#FFE9F9',
    textColor: '#75275F',
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    // canUpdateBuyingDetails: true,
    // canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    // canUpdateFinalFwdAWB: true,
  },
  delivered: {
    value: 'delivered',
    label: 'Delivered',
    msg: 'Shipment delivered',
    bgColor: '#4AB56D',
    textColor: '#fff',
    canUpdateShipmentDetails: true,
    canUpdateNotes: true,
    shipmentDelivered: true,
    canUpdateReferenceNo: true,
  },
  // in_scan: {
  //   value: 'in_scan',
  //   label: 'In Scanned',
  //   msg: 'Shipment In Scanned at Hub',
  //   bgColor: '#E9F6FF',
  //   textColor: '##275475',
  //   canUpdateShipmentDetails: true,
  //   canUpdateTracking: true,
  // },
  // out_scan: {
  //   value: 'out_scan',
  //   label: 'Out Scanned',
  //   msg: 'Shipment Departed from Hub',
  //   bgColor: '#E9F6FF',
  //   textColor: '##275475',
  //   canUpdateTracking: true,
  // },
  // on_hold: {
  //   value: 'on_hold',
  //   label: 'On hold',
  //   msg: 'Shipment on hold',
  //   bgColor: '#FFEFE9',
  //   textColor: '#753F27',
  //   canUpdateTracking: true,
  // },
  rto: {
    value: 'rto',
    label: 'RTO',
    msg: 'Shipment in RTO',
    bgColor: '#FFF9E9',
    textColor: '#755F27',
    isOther: true,
    // canUpdateBuyingDetails: true,
    // canUpdateSellingDetails: true,
    canUpdateShipmentDetails: true,
    canUpdateNotes: true,
    shipmentDelivered: true,
  },
  out_for_delivery: {
    value: 'out_for_delivery',
    label: 'Out for delivery',
    msg: 'Shipment out for Delivery',
    bgColor: '#FFF9E9',
    textColor: '#755F27',
    isOther: true,
    // canUpdateBuyingDetails: true,
    // canUpdateSellingDetails: true,
    canUpdateShipmentDetails: true,
    canUpdateNotes: true,
    shipmentDelivered: true,
  },
  shipment_exception: {
    value: 'shipment_exception',
    label: 'Shipment Exception',
    msg: 'Shipment has shipment exception',
    bgColor: '#FFFFE9',
    textColor: '#737527',
    isOther: true,
    canUpdateShipmentDetails: true,
    canUpdateNotes: true,
    canUpdateTracking: true,
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
  },
  unsuccessful_delivery: {
    value: 'unsuccessful_delivery',
    label: 'Unsuccessful Delivery',
    msg: 'Shipment - Unsuccessful Delivery',
    bgColor: '#FFEFE9',
    textColor: '#DA424E',
    isOther: true,
    canUpdateShipmentDetails: true,
    canUpdateNotes: true,
    shipmentDelivered: true,
  },
  cancelled: {
    value: 'cancelled',
    label: 'Cancelled',
    msg: 'Shipment cancelled',
    bgColor: '#DA424E',
    textColor: '#ffffff',
    isOther: true,
    canUpdateShipmentDetails: true,
    canUpdateNotes: true,
    shipmentDelivered: true,
  },
  unprocessed: {
    value: 'unprocessed',
    label: 'Unprocessed',
    msg: 'Shipment is in Unprocessed State',
    bgColor: '#FFEFE9',
    textColor: '#753F27',
    canUpdateShipmentDetails: true,
    shipmentDelivered: true,
  },
  draft: {
    value: 'draft',
    label: 'Draft',
    msg: 'Shipment store in draft',
    bgColor: '#FFEFE9',
    textColor: '#753F27',
    canUpdateShipmentDetails: true,
  },
  // successful_delivery: {
  //   value: 'successful_delivery',
  //   label: 'Successful Delivery',
  //   msg: 'Shipment - Successful Delivery',
  //   bgColor: '#E9FFEB',
  //   textColor: '#27752F',
  // },
};

type SHIPMENT_ROUTE_TYPE_CONSTANTS = {
  [key: string]: {
    value: string;
    label: string;
    bgColor?: string;
    textColor?: string;
  };
};

const SHIPMENT_DROPDOWN_STATUS: ShipmentStatusType = {
  placed: {
    value: 'placed',
    label: 'Placed',
    msg: 'Shipment placed',
    bgColor: '#FFF9E9',
    textColor: '#755F27',
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
    canUpdatePickupDropoff: true,
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    canConnectToWarehouse: true,
    canUpdateFinalFwdAWB: true,
    canUpdateBuyingDetails: true,
    canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    canAddTransitHub: true,
  },
  assigned: {
    value: 'assigned',
    label: 'Assigned',
    msg: 'Shipment assigned for pickup/delivery.',
    bgColor: '#EAE9FF',
    textColor: '#2E2775',
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
    canUpdatePickupDropoff: true,
    canUpdateTracking: true,
    canUpdateBuyingDetails: true,
    canUpdateSellingDetails: true,
    canUpdateShipmentDetails: true,
    // canConnectToWarehouse: true,
    canUpdateFinalFwdAWB: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    canAddTransitHub: true,
  },
  picked_up: {
    value: 'picked_up',
    label: 'Picked Up',
    msg: 'Shipment picked up',
    bgColor: '#F1FFE9',
    textColor: '#457527',
    // canConnectToWarehouse: true,
    canUpdateFinalFwdAWB: true,
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    canUpdateBuyingDetails: true,
    canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    canAddTransitHub: true,
  },
  at_warehouse: {
    value: 'at_warehouse',
    label: 'At Warehouse',
    msg: 'Shipment at warehouse',
    bgColor: '#F4E9FF',
    textColor: '#4E2775',
    // canConnectToWarehouse: true,
    canUpdateFinalFwdAWB: true,
    canUpdateShipperAddress: true,
    canUpdateConsigneeAddress: true,
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    canUpdateBuyingDetails: true,
    canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    canAddTransitHub: true,
  },
  forwarded: {
    value: 'forwarded',
    label: 'Forwarded',
    msg: 'Shipment Forwarded to Carrier',
    bgColor: '#E9FFFA',
    textColor: '#277563',
    canUpdateTracking: true,
    canConnectToWarehouse: true,
    // canUpdateFinalFwdAWB: true,
    // canUpdateBuyingDetails: true,
    // canUpdateSellingDetails: true,
    canUpdateShipmentDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    // canAddTransitHub: true,
  },
  in_transit: {
    value: 'in_transit',
    label: 'In Transit',
    msg: 'Shipment in transit',
    bgColor: '#FFE9F9',
    textColor: '#75275F',
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    // canUpdateBuyingDetails: true,
    // canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    // canUpdateFinalFwdAWB: true,
  },
  delivered: {
    value: 'delivered',
    label: 'Delivered',
    msg: 'Delivered',
    bgColor: '#FFE9F9',
    textColor: '#75275F',
    canUpdateShipmentDetails: true,
    canUpdateTracking: true,
    // canUpdateBuyingDetails: true,
    // canUpdateSellingDetails: true,
    canUpdateNotes: true,
    canUpdateReferenceNo: true,
    // canUpdateFinalFwdAWB: true,
  },
};
const SHIPMENT_ROUTE_TYPE: SHIPMENT_ROUTE_TYPE_CONSTANTS = {
  import: {
    value: 'import',
    label: 'Import',
    bgColor: '#F1FFE9',
    textColor: '#00A12D',
  },
  hyperlocal: {
    value: 'hyperlocal',
    label: 'Hyperlocal',
    bgColor: '',
    textColor: '',
  },
  domestic: {
    value: 'domestic',
    label: 'Domestic',
    bgColor: '',
    textColor: '',
  },
  export: {
    value: 'export',
    label: 'Export',
    bgColor: '#fef3f2',
    textColor: '#b42318',
  },
  // out: {
  //   value: 'out',
  //   label: 'Out',
  //   bgColor: '',
  //   textColor: '',
  // },
};
const INVOICE_ROUTE_TYPE: SHIPMENT_ROUTE_TYPE_CONSTANTS = {
  credit: {
    value: 'credit',
    label: 'Credit',
    bgColor: '',
    textColor: '',
  },
  debit: {
    value: 'debit',
    label: 'Debit',
    bgColor: '',
    textColor: '',
  },
  invoice: {
    value: 'invoice',
    label: 'Invoice',
    bgColor: '',
    textColor: '',
  },
};

const SHIPMENT_ROUTE_TYPE_DROPDOWN_LIST = Object.keys(SHIPMENT_ROUTE_TYPE).map(key => ({
  display: SHIPMENT_ROUTE_TYPE[key]?.label,
  value: SHIPMENT_ROUTE_TYPE[key]?.value,
}));

const INVOICE_ROUTE_TYPE_DROPDOWN_LIST = Object.keys(INVOICE_ROUTE_TYPE).map(key => ({
  display: INVOICE_ROUTE_TYPE[key]?.label,
  value: INVOICE_ROUTE_TYPE[key]?.value,
}));

const DIM_WT_DIVISOR = { kg: 5000, pound: 306 };

const MAX_ZONEWISE_WT = 9999;

const hideDownloadCarrierAWBAction = (shipment: ShipmentObj | ShipmentByIdType | null) => {
  const isAdmin = !userLogin();
  // checking for the admin
  return isAdmin
    ? false
    : // checking for the mask selection
    shipment?.buyingPricing?._courierId !== shipment?.sellingPricing?._courierId
    ? true
    : // checking for the forwarding of shipment
    shipment?.carrierDetails
    ? // checking for the display AWB selection
      !shipment?.carrierDetails?.awbShowToUser
    : false;
};

type StatusItem = {
  display: string;
  value: string;
};

type Status = {
  [key: string]: StatusItem[];
};

const STATUS: Status = {
  placed: [
    {
      display: 'Assigned',
      value: 'assigned',
    },
    {
      display: 'Picked Up',
      value: 'picked_up',
    },
    {
      display: 'At Warehouse',
      value: 'at_warehouse',
    },
    {
      display: 'Forwarded',
      value: 'forwarded',
    },
    {
      display: 'Cancelled',
      value: 'cancelled',
    },
  ],
  assigned: [
    {
      display: 'Picked Up',
      value: 'picked_up',
    },
    {
      display: 'Delivered',
      value: 'delivered',
    },
  ],
  picked_up: [
    {
      display: 'At Warehouse',
      value: 'at_warehouse',
    },
    {
      display: 'Cancelled',
      value: 'cancelled',
    },
  ],
  at_warehouse: [
    {
      display: 'Forwarded',
      value: 'forwarded',
    },
    {
      display: 'RTO',
      value: 'rto',
    },
    {
      display: 'Shipment Exception',
      value: 'shipment_exception',
    },
    {
      display: 'Cancelled',
      value: 'cancelled',
    },
    {
      display: 'In Transit',
      value: 'in_transit',
    },
  ],
  forwarded: [
    {
      display: 'In Transit',
      value: 'in_transit',
    },
    {
      display: 'Shipment Exception',
      value: 'shipment_exception',
    },
    {
      display: 'Cancelled',
      value: 'cancelled',
    },
  ],
  in_transit: [
    {
      display: 'At Warehouse',
      value: 'at_warehouse',
    },
    {
      display: 'In Transit',
      value: 'in_transit',
    },
    {
      display: 'Delivered',
      value: 'delivered',
    },
    {
      display: 'RTO',
      value: 'rto',
    },
    {
      display: 'Shipment Exception',
      value: 'shipment_exception',
    },
    {
      display: 'Unsuccessful Delivery',
      value: 'unsuccessful_delivery',
    },
    {
      display: 'Cancelled',
      value: 'cancelled',
    },
  ],
  shipment_exception: [
    // {
    //   display: 'Delivered',
    //   value: 'delivered',
    // },
    {
      display: 'RTO',
      value: 'rto',
    },
    {
      display: 'Unsuccessful Delivery',
      value: 'unsuccessful_delivery',
    },
    // {
    //   display: 'In Transit',
    //   value: 'in_transit',
    // },
    {
      display: 'Cancelled',
      value: 'cancelled',
    },
  ],
};

const WHEN_REMOVE_FROM_SHIPMENT_EXCEPTION: Status = {
  at_warehouse: [
    {
      display: 'At Warehouse',
      value: 'at_warehouse',
    },
    {
      display: 'Forwarded',
      value: 'forwarded',
    },
  ],
  forwarded: [
    {
      display: 'Forwarded',
      value: 'forwarded',
    },
    {
      display: 'In Transit',
      value: 'in_transit',
    },
  ],
  in_transit: [
    {
      display: 'In Transit',
      value: 'in_transit',
    },
    {
      display: 'Delivered',
      value: 'delivered',
    },
  ],
};

export {
  DIM_WT_DIVISOR,
  MAX_ZONEWISE_WT,
  SHIPMENT_ROUTE_TYPE,
  INVOICE_ROUTE_TYPE,
  SHIPMENT_ROUTE_TYPE_DROPDOWN_LIST,
  INVOICE_ROUTE_TYPE_DROPDOWN_LIST,
  SHIPMENT_STATUS,
  SHIPMENT_TYPE,
  STATUS,
  WHEN_REMOVE_FROM_SHIPMENT_EXCEPTION,
  hideDownloadCarrierAWBAction,
  SHIPMENT_DROPDOWN_STATUS,
};

export type CarrierAccessType = 'fedex' | 'dhl' | 'dhlIndia' | 'aramex' | 'stage';
export type newCarrierAccessType = 'fedex' | 'dhl';

export const CSP_TYPES: Record<CarrierAccessType, { display: string; value: string }> = {
  fedex: {
    display: 'FedEx',
    value: 'fedex',
  },
  dhl: {
    display: 'Dhl',
    value: 'dhl',
  },
  dhlIndia: {
    display: 'DHL India',
    value: 'dhl_india',
  },
  aramex: {
    display: 'Aramex',
    value: 'aramex',
  },
  stage: {
    display: 'Stage',
    value: 'stage',
  },
};
