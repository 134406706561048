import { createSlice } from '@reduxjs/toolkit';
import { getTransactionList } from './actions';
import { getTransactionListFulfilled, pending, rejected } from './case';
import { TransactionHistoryState } from './Type';

const initialState = <TransactionHistoryState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },
  selectedTransactions: {},
};

const shipmentHistorySlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getTransactionList.pending, pending);
    addCase(getTransactionList.fulfilled, getTransactionListFulfilled);
    addCase(getTransactionList.rejected, rejected);
  },
});

export { getTransactionList };
export default shipmentHistorySlice.reducer;
