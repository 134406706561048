import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Checkbox,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import API from '@Services/apiAxios';
import Loader from '@Components/Loader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@Store';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

interface Notification {
  module: string;
  triggers: string[];
}

interface DeliveryMethod {
  sms: boolean;
  email: boolean;
  dashboard: boolean;
}

interface Recipient {
  customer: boolean;
  shipper: boolean;
  consignee: boolean;
  admin: boolean;
}

interface NotificationSettings {
  trigger: string;
  deliveryMethods: DeliveryMethod;
  recipients: Recipient;
}

const modules: Notification[] = [
  {
    module: 'User Status Notifications',
    triggers: ['Invitation Sent', 'Kyc Pending', 'Active', 'Kyc Verification', 'Suspended', 'Deactivated'],
  },
  {
    module: 'Shipment Status Notifications',
    triggers: [
      'Placed',
      'At Warehouse',
      'Forwarded',
      'In Transit',
      'Shipment Exception',
      'Rto',
      'Cancelled',
      'Delivered',
    ],
  },
  {
    module: 'Shipment Changes Notifications',
    triggers: ['Weight Change', 'Address Change', 'Price Change', 'Amount Refunded'],
  },
  {
    module: 'Invoice Notifications',
    triggers: [
      'Invoice Created',
      'Credit Note Created',
      'Debit Note Created',
      'Invoice Due Date Reached',
      'Wallet Updated',
      'Credit Limit Updated',
    ],
  },
  {
    module: 'Pricing Notifications',
    triggers: ['Change Base Price', 'Change Tariff Price', 'Change Contract Price', 'Oda Updation'],
  },
];

const prefilledSettings: Record<string, NotificationSettings> = {
  'Invitation Sent': {
    trigger: 'Invitation Sent',
    deliveryMethods: { sms: false, email: false, dashboard: false },
    recipients: {
      customer: false,
      shipper: false,
      consignee: false,
      admin: false,
    },
  },
  Placed: {
    trigger: 'Placed',
    deliveryMethods: { sms: false, email: false, dashboard: false },
    recipients: {
      customer: false,
      shipper: false,
      consignee: false,
      admin: false,
    },
  },
};

const transformApiResponse = (data: any[]) => {
  const transformed: Record<string, NotificationSettings> = {};

  data.forEach(item => {
    const [, triggerName] = item.code.split('.');
    const formattedTrigger = triggerName.replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase());

    const formattedKey = `${formattedTrigger}`;

    transformed[formattedKey] = {
      trigger: formattedKey,
      deliveryMethods: {
        sms: item.sms,
        email: item.email,
        dashboard: item.dashboard,
      },
      recipients: {
        customer: item.toCustomer,
        shipper: item.toShipper,
        consignee: item.toConsignee,
        admin: item.toAdmin,
      },
    };
  });

  return transformed;
};

const NotificationSettingsUI: React.FC = () => {
  const [settings, setSettings] = useState<Record<string, NotificationSettings>>(prefilledSettings);
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //   const orgCountryObj = useSelector((state: RootStateType) => state?.orgSetup?.orgCountryObj);

  //   const disableSms = orgCountryObj?.name?.toLowerCase() !== 'india';
  const disableSms = true;

  // Function to check if a recipient checkbox should be disabled based on module
  const isRecipientDisabled = (moduleName: string, recipient: keyof Recipient) => {
    switch (moduleName) {
      case 'User Status Notifications':
        return ['shipper', 'admin', 'consignee'].includes(recipient);
      case 'Shipment Status Notifications':
        return recipient === 'admin';
      case 'Shipment Changes Notifications':
        return recipient === 'admin';
      case 'Invoice Notifications':
        return ['shipper', 'admin', 'consignee'].includes(recipient);
      case 'Pricing Notifications':
        return ['shipper', 'admin', 'consignee'].includes(recipient);
      default:
        return false;
    }
  };

  // Function to check if dashboard checkbox should be disabled based on module
  const isDashboardDisabled = (moduleName: string) => {
    switch (moduleName) {
      case 'User Status Notifications':
      case 'Shipment Changes Notifications':
      case 'Pricing Notifications':
        return true;
      default:
        return false;
    }
  };

  const fetchConfigs = async () => {
    try {
      setIsLoading(true);
      const response = await API.get('/notification/getAllConfig');
      if (response?.status === 200) {
        const transformedSettings = transformApiResponse(response.data.data);
        setSettings(transformedSettings);
      }
    } catch (error) {
      console.error('Error fetching configs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  // Function to check if email should be disabled for specific pricing triggers
  const isEmailDisabled = (moduleName: string, trigger: string) => {
    if (moduleName === 'Pricing Notifications') {
      return ['Change Base Price', 'Change Tariff Price', 'Oda Updation'].includes(trigger);
    }
    return false;
  };

  // Function to check if customer checkbox should be disabled
  const isCustomerDisabled = (moduleName: string, trigger: string) => {
    if (moduleName === 'Pricing Notifications') {
      return ['Change Base Price', 'Change Tariff Price', 'Oda Updation'].includes(trigger);
    }
    return isRecipientDisabled(moduleName, 'customer');
  };

  const handleCheckboxChange = (
    trigger: string,
    type: 'deliveryMethods' | 'recipients',
    key: keyof DeliveryMethod | keyof Recipient,
    checked: boolean,
    moduleName: string,
  ) => {
    if (key === 'sms' && disableSms) return;
    if (key === 'dashboard' && isDashboardDisabled(moduleName)) return;
    if (key === 'email' && isEmailDisabled(moduleName, trigger)) return;
    if (type === 'recipients' && key === 'customer' && isCustomerDisabled(moduleName, trigger)) return;
    if (type === 'recipients' && isRecipientDisabled(moduleName, key as keyof Recipient)) return;

    setSettings(prev => ({
      ...prev,
      [trigger]: {
        ...prev[trigger],
        [type]: {
          ...prev[trigger]?.[type],
          [key]: checked,
        },
      },
    }));
  };
  const getCompleteSettings = () => {
    const configs: any[] = [];

    modules.forEach(module => {
      const moduleName = module.module.toLowerCase().replace(/\s+/g, '_');

      module.triggers.forEach(trigger => {
        const triggerName = trigger.toLowerCase().replace(/\s+/g, '_');

        const existing = settings[trigger] || {
          trigger,
          deliveryMethods: { sms: false, email: false, dashboard: false },
          recipients: { customer: false, shipper: false, consignee: false, admin: false },
        };

        configs.push({
          code: `${moduleName}.${triggerName}`,
          sms: existing.deliveryMethods.sms || false,
          email: existing.deliveryMethods.email || false,
          dashboard: existing.deliveryMethods.dashboard || false,
          toCustomer: existing?.recipients?.customer || false,
          toShipper: existing?.recipients?.shipper || false,
          toConsignee: existing?.recipients?.consignee || false,
          toAdmin: existing?.recipients?.admin || false,
        });
      });
    });

    return { configs };
  };

  const handleSubmit = async () => {
    const completeSettings = getCompleteSettings();
    try {
      setIsLoading(true);
      const response = await API.post('/notification/updateConfig', { configs: completeSettings?.configs });
      if (response?.status === 200) {
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: response?.data?.msg,
          }),
        );
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Loader overly loading={isLoading}>
      <Box>
        <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 600 }} gutterBottom>
          Notification Configuration
        </Typography>
        {modules.map(module => (
          <Accordion key={module.module} sx={{ mb: 1, boxShadow: 'none', border: 'none' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6" sx={{ fontSize: '18px' }}>
                {module.module}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider />
              <Table sx={{ backgroundColor: '#cccccc' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: '16px' }}>Trigger</TableCell>
                    {/* <TableCell sx={{ fontSize: '16px', textAlign: 'center' }}>SMS</TableCell> */}
                    <TableCell sx={{ fontSize: '16px', textAlign: 'center' }}>Email</TableCell>
                    <TableCell sx={{ fontSize: '16px', textAlign: 'center' }}>Dashboard Notification</TableCell>
                    <TableCell sx={{ fontSize: '16px', textAlign: 'center' }}>Customer</TableCell>
                    <TableCell sx={{ fontSize: '16px', textAlign: 'center' }}>Shipper</TableCell>
                    <TableCell sx={{ fontSize: '16px', textAlign: 'center' }}>Consignee</TableCell>
                    <TableCell sx={{ fontSize: '16px', textAlign: 'center' }}>Admin</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {module.triggers.map(trigger => (
                    <TableRow key={trigger} sx={{ backgroundColor: '#fff' }}>
                      <TableCell sx={{ width: '15rem', fontSize: '16px' }}>{trigger}</TableCell>
                      {/* <TableCell sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={settings[trigger]?.deliveryMethods?.sms || false}
                          onChange={e =>
                            handleCheckboxChange(trigger, 'deliveryMethods', 'sms', e.target.checked, module.module)
                          }
                          disabled={disableSms}
                        />
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={settings[trigger]?.deliveryMethods?.email || false}
                          onChange={e =>
                            handleCheckboxChange(trigger, 'deliveryMethods', 'email', e.target.checked, module.module)
                          }
                          disabled={isEmailDisabled(module.module, trigger)}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={settings[trigger]?.deliveryMethods?.dashboard || false}
                          onChange={e =>
                            handleCheckboxChange(
                              trigger,
                              'deliveryMethods',
                              'dashboard',
                              e.target.checked,
                              module.module,
                            )
                          }
                          disabled={isDashboardDisabled(module.module)}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={settings[trigger]?.recipients?.customer || false}
                          onChange={e =>
                            handleCheckboxChange(trigger, 'recipients', 'customer', e.target.checked, module.module)
                          }
                          disabled={isCustomerDisabled(module.module, trigger)}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={settings[trigger]?.recipients?.shipper || false}
                          onChange={e =>
                            handleCheckboxChange(trigger, 'recipients', 'shipper', e.target.checked, module.module)
                          }
                          disabled={isRecipientDisabled(module.module, 'shipper')}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={settings[trigger]?.recipients?.consignee || false}
                          onChange={e =>
                            handleCheckboxChange(trigger, 'recipients', 'consignee', e.target.checked, module.module)
                          }
                          disabled={isRecipientDisabled(module.module, 'consignee')}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Checkbox
                          checked={settings[trigger]?.recipients?.admin || false}
                          onChange={e =>
                            handleCheckboxChange(trigger, 'recipients', 'admin', e.target.checked, module.module)
                          }
                          disabled={isRecipientDisabled(module.module, 'admin')}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
        <Box mt={3}>
          <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mr: 2, fontSize: '16px' }}>
            Save Settings
          </Button>
        </Box>
      </Box>
    </Loader>
  );
};

export default NotificationSettingsUI;
