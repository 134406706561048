import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';

import { Typography, Tabs, Tab, Box } from '@mui/material';

// import { AppDispatch, RootStateType } from '../../store';

import Title from '../../components/Title';
import UnprocessedList from './Unprocessed';
import SavedList from './Saved';
import { useNavigate } from 'react-router-dom';
import { useSearchQueryParams } from '@Hook/useSearchQueryParams';

export default function CarrierAndService() {
  // const dispatch = useDispatch<AppDispatch>();
  const [tabValue, setTabValue] = React.useState(0);
  const navigation = useNavigate();
  const searchParams = useSearchQueryParams();

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const currentTab = newValue === 0 ? 'unprocessed' : 'saved';
    navigation(`?tab=${currentTab}`);
    setTabValue(newValue);
  };

  useEffect(() => {
    if (searchParams.tab) {
      if (searchParams.tab === 'saved') {
        setTabValue(1);
      } else {
        setTabValue(0);
      }
    }
  }, [searchParams.tab]);

  const subTitleText = 'View and update unprocessed shipments.';
  const draftSubTitleText = 'View and update draft shipments.';

  return (
    <>
      <Title
        title={tabValue === 0 ? `Unprocessed Shipment` : `Saved Shipment`}
        subTitle={tabValue === 0 ? subTitleText : draftSubTitleText}
        removeBorder
      />

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Unprocessed" {...a11yProps(0)} />
            <Tab label="Saved" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <UnprocessedList />
          {/* Tab 1 */}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <SavedList />
        </TabPanel>
      </Box>
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { fontSize: '1rem', fontWeight: '600' },
  };
}
