import * as Yup from 'yup';
export const CreateWareHouseSchema = Yup.object().shape({
  name: Yup.string().trim().required('Warehouse name is required'),
  attendanceName: Yup.string().trim().required('C/O Attn is required'),
  systemId: Yup.string().trim().required('System is required'),
  email: Yup.string()
    .trim()
    .matches(/^[^\s@]{3,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
  countryCode: Yup.string().trim().required('Country Code is required'),
  number: Yup.string().trim().required('Contact/Phone number is required'),
  addressLine1: Yup.string().trim().required('Address Line 1 is required'),
  addressLine2: Yup.string().trim(),
  city: Yup.string().trim().required('City is required'),
  state: Yup.string().trim().required('State is required'),
  pincode: Yup.string().trim().required('Pincode is required'),
});
