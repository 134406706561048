import { ShipmentObj } from '@Reducers/Invoice/Type';
import { formatNumber, useLocale } from '../../utils/formatNumber';

export const calculateTotalAmount = (s: ShipmentObj, type?: string) => {
  const extraChargesNotes = s?.extraChargesNotes;
  const additionKey = type === 'debit' ? 'add' : 'discount';
  const locale = useLocale();

  if (extraChargesNotes) {
    const sellingCharges =
      extraChargesNotes.selling && extraChargesNotes.selling[additionKey]
        ? Object.values(extraChargesNotes.selling[additionKey]).reduce((acc, section) => {
            if (section) {
              return acc + section.value;
            } else {
              return acc;
            }
          }, 0)
        : 0;

    const pricingCharges =
      extraChargesNotes.pricing && extraChargesNotes.pricing[additionKey]
        ? Object.values(extraChargesNotes.pricing[additionKey]).reduce((acc, section) => {
            if (section) {
              return (
                acc +
                Object.values(section)?.reduce((acc2, value) => {
                  if (value) {
                    return Math.abs(value) + acc2;
                  } else {
                    return acc2;
                  }
                }, 0)
              );
            } else {
              return acc;
            }
          }, 0)
        : 0;
    return formatNumber(sellingCharges + pricingCharges, locale);
  } else {
    return 0;
  }
};
