import React, { useEffect, useState } from 'react';
import GlobalChargeForm, { initCreateGlobalCharge } from './Form';
import { Grid } from '@mui/material';
import SbButton from '../../../components/Button';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { createGlobalChargeSchema } from '../schema';
import { createGlobalCharge } from '@Reducers/GlobalCharge';
import { GlobalCharge, GlobalChargeFormType } from './type';
import { GlobalChargePostData } from '@Reducers/GlobalCharge/Type';
import { useSearchQueryParams } from '@Hook/useSearchQueryParams';
import { CopyRateFormType } from '../CopyRate/type';

function getCopyRatesData(copyData: CopyRateFormType[]): GlobalCharge[] {
  const data: GlobalCharge[] = [];
  copyData.forEach((rates: CopyRateFormType) => {
    rates._systemId.forEach(system => {
      data.push({
        _courierId: rates._courierId,
        _systemId: system,
        fromDate: rates.fromDate.toString(),
        toDate: rates.toDate.toString(),
      });
    });
  });
  return data;
}

function CreateGlobalCharge() {
  const dispatch = useDispatch<AppDispatch>();
  const searchParams = useSearchQueryParams();
  const navigate = useNavigate();

  const [globalCharge, _setGlobalCharge] = useState<GlobalChargeFormType>({ ...initCreateGlobalCharge });

  useEffect(
    function () {
      if (searchParams?.system) {
        formik.setFieldValue('_systemId', searchParams?.system);
      }
      if (searchParams?.service) {
        formik.setFieldValue('_courierId', searchParams?.service);
      }
    },
    [searchParams?.system, searchParams?.service],
  );

  const onCreate = (params: GlobalChargeFormType) => {
    const payload: GlobalChargePostData = {
      fromDate: params.fromDate,
      toDate: params.toDate,
      _systemId: params._systemId,
      _courierId: params._courierId,
      charges: params.charges.map(c => ({
        chargeName: c.chargeName,
        ...(c.chargeType === 'fixed' ? { fixed: c.value } : { percent: c.value }),
        applyOn: c.selectedApplyOn,
      })),
    };
    if (params.taxes) {
      payload.taxes = params.taxes instanceof Array ? params.taxes : [params.taxes];
    }
    if (params.copyRates) {
      payload.copyRates = getCopyRatesData(params.copyRates);
    }
    dispatch(createGlobalCharge(payload));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...globalCharge },
    validationSchema: createGlobalChargeSchema,
    onSubmit(values) {
      onCreate(values);
    },
  });

  return (
    <>
      <GlobalChargeForm
        showBackgroundColor
        formType="create"
        errors={formik.errors}
        values={formik.values}
        setFieldError={formik.setFieldError}
        handleChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
        validateForm={formik.validateForm}
      />
      <Grid container item mt={4} spacing={2} justifyContent="flex-end">
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          {/* <Link to="/global-charges"> */}
          <SbButton fullWidth variant="outlined" onClick={() => navigate(-1)}>
            Back
          </SbButton>
          {/* </Link> */}
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          <SbButton
            fullWidth
            variant="contained"
            disabled={!!formik.errors.toDate}
            onClick={_event => {
              formik.handleSubmit();
            }}
          >
            Save
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateGlobalCharge;
