import { Grid, TableBody, TableHead, TablePagination, TableRow, Typography, alpha, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SbTooltip from '../../../components/Tooltip';
import Loader from '../../../components/Loader';
import Divider from '../../../components/Divider';
import SbTextField from '../../../components/Textfield';
import Dropdown from '../../../components/Dropdown';

import { AppDispatch, RootStateType } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deletePincode, getPincodeBySystemId, resetPincde, updatePincode } from '../../../store/reducers/pincode';

import { SBTable } from '../../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../../components/Table/TableCell';
import { SBTableRow } from '../../../components/Table/TableRow';
import SbButton from '../../../components/Button';
import Image from '../../../components/Image';
import { SinglePincode } from '../../../store/reducers/pincode/Type';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useFormik } from 'formik';

import { downloadPincodes } from '../helper';
import FilterSearch from '@Components/Filter/Search';
import DeleteModal from '@Components/Modal/Delete';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { isActionAllowed } from '../../../utils/allowedActions';

const SearchFilterList = [{ label: 'Pincode', value: 'pincode' }];
interface PincodeEditProps {
  showBackgroundColor?: boolean;
}

function PincodeEdit(props: PincodeEditProps) {
  const { showBackgroundColor } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useTheme();
  const pincodeState = useSelector((state: RootStateType) => state.pincode);
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isDownloadPincodeAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.System.create_system,
    PERFORM_ACTION.write,
  );

  const [filterSelectValue, setFilterSelectValue] = useState('pincode');
  const [filterText, setFilterText] = useState('');
  const [filterPincode, setFilterPincode] = useState<SinglePincode[]>([]);
  const { id: systemId } = useParams() as { id: string };
  const { currentPage, perPage, totalCount } = useSelector((state: RootStateType) => state.pincode);

  useEffect(() => {
    if (systemId) {
      dispatch(getPincodeBySystemId({ id: systemId, queryParams: { currentPage: 1, perPage: 10 } }));
    }
    return () => {
      dispatch(resetPincde());
    };
  }, []);

  useEffect(() => {
    if (pincodeState.systemPincode?.pincodes) {
      setFilterPincode(pincodeState.systemPincode?.pincodes || []);
    }
  }, [pincodeState.systemPincode?.pincodes]);

  const onPincodeDownload = () => {
    if (pincodeState.systemPincode && isDownloadPincodeAllowed)
      downloadPincodes(pincodeState.systemPincode?.pincodes, pincodeState.systemPincode?.system.name);
  };

  const onClear = () => {
    setFilterText('');

    dispatch(getPincodeBySystemId({ id: systemId, queryParams: { currentPage: 1, perPage } }));
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Loader loading={pincodeState.loading} overly>
      <>
        <Grid
          container
          className="p-4"
          style={{
            borderRadius: '12px',
            backgroundColor: showBackgroundColor ? alpha(theme.palette.primary.light, 0.4) : '',
            overflow: 'hidden',
            width: '100%',
          }}
        >
          <Grid item xs={12}>
            <Typography className="org-text underline">Pincode</Typography>
            <Typography className="md-text flex items-center">
              System : <span className="ml-1 font-semibold">{pincodeState.systemPincode?.system.name}</span>
              {/* <SbTooltip title="Please create one system to start setting up your account. You will be able to create more systems at later stage.">
            <InfoIcon className="ml-1" sx={{ width: '20px', height: '20px' }} />
          </SbTooltip> */}
            </Typography>
            <Divider className="mt-3 mb-3" />
          </Grid>

          <Grid container mb={3} justifyContent="space-between">
            <Grid container item xs={12} md={6}>
              <FilterSearch
                onSelectChange={(value: string) => {
                  setFilterSelectValue(value);
                }}
                list={SearchFilterList}
                selectValue={filterSelectValue}
                textValue={filterText}
                onTextChange={e => {
                  setFilterText(e.target.value);
                }}
                onTextSearch={() => {
                  dispatch(
                    getPincodeBySystemId({
                      id: systemId,
                      queryParams: { currentPage: 1, perPage, pincode: filterText },
                    }),
                  );
                }}
                onTextClear={onClear}
              />
            </Grid>
            <Grid container item xs={12} sm={4} md={5} lg={4} xl={2.5} justifyContent="flex-end">
              <SbButton
                onClick={onPincodeDownload}
                // fullWidth
                variant="outlined"
                sx={{
                  borderRadius: '4px !important',
                  backgroundColor: theme.palette.common.white,
                  width: { xs: '100%', sm: '80%' },
                  marginTop: { xs: '20px', sm: '0px' },
                }}
                startIcon={<Image src={'/images/file_download.svg'} alt={'Speedbox'} width={20} height={20} />}
                disabled={!isDownloadPincodeAllowed}
              >
                Download Pincodes
              </SbButton>
            </Grid>
          </Grid>
          <Grid container sx={{ maxHeight: 400, overflowY: 'scroll' }}>
            <SBTable sx={{ background: theme.palette.common.white }} stickyHeader>
              <PincodeTableHead />
              <TableBody>
                {/* pincodeState.systemPincode?.pincodes */}
                {filterPincode.map((pincode, index) => (
                  <PincodeRow key={pincode._id} count={index + 1} {...pincode} isLast={filterPincode.length === 1} />
                ))}
              </TableBody>
            </SBTable>
          </Grid>
          <Divider className="mt-3 mb-3" />

          <Grid container justifyContent="flex-end">
            {!!totalCount && !!perPage && (
              <>
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={currentPage - 1}
                  rowsPerPage={perPage}
                  onPageChange={(_, page) => {
                    dispatch(
                      getPincodeBySystemId({
                        id: systemId,
                        queryParams: { currentPage: page + 1, perPage },
                      }),
                    );
                  }}
                  onRowsPerPageChange={event => {
                    const newPerPage = parseInt(event.target.value, 10);

                    dispatch(
                      getPincodeBySystemId({
                        id: systemId,
                        queryParams: { currentPage: 1, perPage: newPerPage },
                      }),
                    );
                  }}
                />
              </>
            )}
            <SbButton onClick={onCancel} variant="outlined">
              Cancel
            </SbButton>
          </Grid>
        </Grid>
      </>
    </Loader>
  );
}

function PincodeTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableHeadCell width="100px">Sr no.</TableHeadCell>
        <TableHeadCell width="30%">Pincode</TableHeadCell>
        <TableHeadCell>Zone</TableHeadCell>
        <TableHeadCell width="20%">Pickup serviceability</TableHeadCell>
        <TableHeadCell>Actions</TableHeadCell>
      </TableRow>
    </TableHead>
  );
}

interface PincodeRowProps extends SinglePincode {
  count: number;
  isLast: boolean;
}
const PickupServiceabilityOptions = [
  { display: 'Yes', value: 'yes' },
  { display: 'No', value: 'no' },
];

const pincodeUpdateSchema = Yup.object().shape({
  zoneName: Yup.string(),
  isPickupServiceable: Yup.string().required(),
  pincode: Yup.string().required(),
  isEdit: Yup.boolean(),
});
interface PincodeEditState {
  zoneName: string;
  isPickupServiceable: 'yes' | 'no';
  pincode: string;
  isEdit: boolean;
}
const initPincodeEditState: PincodeEditState = {
  zoneName: '',
  isPickupServiceable: 'no',
  pincode: '',
  isEdit: false,
};

function PincodeRow(props: PincodeRowProps) {
  const pickupServiceable = props.isPickupServiceable ? 'yes' : 'no';
  const [state, setState] = useState<PincodeEditState>({ ...initPincodeEditState });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const { currentPage, perPage } = useSelector((state: RootStateType) => state.pincode);
  const isDownloadPincodeAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.System.create_system,
    PERFORM_ACTION.write,
  );

  const onEdit = () => {
    setState({
      zoneName: props.zoneName || '',
      isPickupServiceable: pickupServiceable,
      pincode: props.pincode,
      isEdit: true,
    });
  };

  const onCancel = () => {
    setState({ ...initPincodeEditState });
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...state },
    validationSchema: pincodeUpdateSchema,
    onSubmit(values) {
      dispatch(
        updatePincode({
          pincode: props.pincode,
          payload: {
            zoneName: values.zoneName,
            isPickupServiceable: values.isPickupServiceable === 'yes',
            pincode: values.pincode,
          },
          showModal: true,
        }),
      );
      onCancel();
    },
  });

  return (
    <>
      <DeleteModal
        icon="/images/icons/DeleteAction.svg"
        open={isOpen}
        title="Are You Sure?"
        subTitle={`Do you want to delete this pincode?`}
        onClose={() => setIsOpen(false)}
        onConfirm={() => {
          setIsOpen(false);
          dispatch(deletePincode({ pincode: props.pincode, showModal: true }));
        }}
      />
      <SBTableRow>
        <TableRowCell align="center" className="md-text">
          {/* {props.count} */}

          <div>{props.count + (currentPage - 1) * perPage}</div>
        </TableRowCell>
        <TableRowCell align="center" className="md-text">
          {state.isEdit ? (
            <SbTextField
              name="pincode"
              value={formik.values.pincode}
              onChange={formik.handleChange}
              error={formik.errors.pincode}
              disabled
            />
          ) : (
            props.pincode
          )}
        </TableRowCell>
        <TableRowCell align="center" className="md-text">
          {state.isEdit ? (
            <SbTextField
              name="zoneName"
              value={formik.values.zoneName}
              onChange={formik.handleChange}
              error={formik.errors.zoneName}
            />
          ) : (
            props.zoneName
          )}
        </TableRowCell>
        <TableRowCell align="center" className="md-text capitalize">
          {state.isEdit ? (
            <Dropdown
              id={`pincode-${props._id}`}
              onChange={([value]) => {
                formik.setFieldValue('isPickupServiceable', value);
              }}
              value={[formik.values.isPickupServiceable]}
              options={PickupServiceabilityOptions}
              error={formik.errors.isPickupServiceable}
            />
          ) : (
            pickupServiceable
          )}
        </TableRowCell>
        <TableRowCell>
          {isDownloadPincodeAllowed && (
            <Grid container item justifyContent="center" gap={2} alignItems="center">
              {state.isEdit ? (
                <>
                  <Grid
                    textAlign="center"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    <SbTooltip title="Update Pincode">
                      <div>
                        <CheckIcon />
                      </div>
                    </SbTooltip>
                  </Grid>
                  <Grid textAlign="center" onClick={onCancel}>
                    <SbTooltip title="Cancel Pincode">
                      <div>
                        <ClearIcon />
                      </div>
                    </SbTooltip>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid textAlign="center" onClick={onEdit}>
                    <SbTooltip title="Edit Pincode">
                      <div>
                        <Image src="/images/icons/EditAction.svg" alt="Edit" />
                      </div>
                    </SbTooltip>
                  </Grid>

                  {!props.isLast && (
                    <Grid textAlign="center" onClick={() => setIsOpen(true)}>
                      <SbTooltip title="Delete Pincode">
                        <div>
                          <Image src="/images/icons/DeleteAction.svg" alt="Delete" />
                        </div>
                      </SbTooltip>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
        </TableRowCell>
      </SBTableRow>
    </>
  );
}

export default PincodeEdit;
