import React, { useMemo } from 'react';
import { Grid, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

type TabValues =
  | 'shipper_consignee'
  | 'shipment_details'
  | 'shipment_processing'
  | 'tracking_detail'
  | 'shipment_charges';

function ShipmentTabs() {
  const navigation = useNavigate();
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipperConsigneeDetailsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipper_or_consignee_details,
    PERFORM_ACTION.read,
  );

  const isShipmentDetailsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_details,
    PERFORM_ACTION.read,
  );

  const isShipmentProcessingAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_processing,
    PERFORM_ACTION.read,
  );

  const isTrackingDetailsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.tracking_details,
    PERFORM_ACTION.read,
  );

  const isShipmentChargesAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_charges,
    PERFORM_ACTION.read,
  );

  const isUnprocessedShipment = useMemo(
    () => shipmentById?.status === SHIPMENT_STATUS.unprocessed.value,
    [shipmentById?.status],
  );

  const TABS: { label: string; value: TabValues }[] = useMemo(() => {
    let tabs: { label: string; value: TabValues }[] = [
      { label: 'Shipper/Consignee', value: 'shipper_consignee' },
      { label: 'Shipment Details', value: 'shipment_details' },
      { label: 'Shipment Processing', value: 'shipment_processing' },
      { label: 'Tracking Details', value: 'tracking_detail' },
      { label: 'Shipment Charges', value: 'shipment_charges' },
    ];
    if (isUnprocessedShipment)
      tabs = tabs.filter(
        tab => !['shipment_processing', 'tracking_detail', 'shipment_charges'].some(i => i === tab.value),
      );

    return tabs;
  }, [isUnprocessedShipment]);

  const [currentTap, setCurrentTap] = useState<TabValues>(TABS[0].value);

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    navigation(`?tab=${currentTab}`);
    setCurrentTap(currentTab);
  };

  return (
    <TabContext value={currentTap}>
      <Grid container sx={{ marginTop: 2, borderBottom: '2px solid #E4E4E4', borderRadius: 1 }}>
        <TabList
          onChange={onTabChange}
          indicatorColor="primary"
          TabIndicatorProps={{ sx: { height: '4px' } }}
          scrollButtons="auto"
          variant="scrollable"
        >
          {TABS.filter(
            cTab =>
              (cTab.value === 'shipper_consignee' && isShipperConsigneeDetailsAllowed) ||
              (cTab.value === 'shipment_details' && isShipmentDetailsAllowed) ||
              (cTab.value === 'shipment_processing' && isShipmentProcessingAllowed) ||
              (cTab.value === 'tracking_detail' && isTrackingDetailsAllowed) ||
              (cTab.value === 'shipment_charges' && isShipmentChargesAllowed),
          ).map(cTab => (
            <Tab
              sx={{ textTransform: 'none', padding: '16px 16px' }}
              className="md-text font-medium sb-text-black"
              value={cTab.value}
              label={cTab.label}
              key={cTab.value}
            />
          ))}
        </TabList>
      </Grid>
    </TabContext>
  );
}

export default ShipmentTabs;
