import { togglePaymentModal } from '@Reducers/InvoiceList';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';

export function usePaymentInvoice() {
  const dispatch = useDispatch<AppDispatch>();
  const { profile } = useSelector((state: RootStateType) => state.loginUser);
  const { paymentGetwayList } = useSelector((state: RootStateType) => state.Wallet);

  const isPrepaid = profile?._accountId.isPrepaid || false;
  const isAccountHolder = profile?._accountId?.isAccountHolder;
  const accountNumber = profile?.accountNumber || '';
  const isPrepaidUser = isAccountHolder ? isPrepaid : true;

  const setOpenPaymentModal = (open: string) => {
    dispatch(togglePaymentModal(open));
  };
  return {
    setOpenPaymentModal,
    isPrepaidUser,
    accountNumber,
    profile,
    hasPaymentAccess:
      paymentGetwayList && paymentGetwayList.length && paymentGetwayList.some(({ gateway }) => gateway === 'razorpay')
        ? true
        : false,
  };
}
