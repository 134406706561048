/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { CreateKycPostPayload, UpdateKycPutPayload } from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

async function DownloadFile(url: string) {
  const res = await API.get(url);

  window.open(res.data.data, '_blank');
}
async function GetDownloadFileUrl(url: string) {
  const res = await API.get(url);
  return res.data;
}

const getLoggedInUserKyc = createAsyncThunk('kyc/getLoggedInUserKyc', async (_, { dispatch }) => {
  try {
    const profileData = await API.get(`/user/kyc`);
    return profileData.data.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const uploadLoggedInUserKyc = createAsyncThunk(
  'kyc/uploadLoggedInUserKyc',
  async (postData: CreateKycPostPayload, { dispatch }) => {
    try {
      const profileData = await API.post(`/user/kyc`, postData);
      return profileData.data.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const updateLoggedInUserKyc = createAsyncThunk(
  'kyc/updateLoggedInUserKyc',
  async (postData: UpdateKycPutPayload, { dispatch }) => {
    try {
      const profileData = await API.put('/user/kyc/' + postData._id, postData);
      return profileData.data.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getUserKyc = createAsyncThunk('kyc/getUserKyc', async (userId: string, { dispatch }) => {
  try {
    const profileData = await API.get(`/user/kyc${`/${userId}`}`);
    return { list: profileData.data.data, userId };
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const uploadUserKyc = createAsyncThunk(
  'kyc/uploadUserKyc',
  async (postData: CreateKycPostPayload & { _userId: string }, { dispatch }) => {
    try {
      const profileData = await API.post(`/user/kyc/${postData._userId}`, postData);
      return profileData.data.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const verifyKyc = createAsyncThunk(
  'kyc/verifyKyc',
  async ({ userId, kycId }: { userId: string; kycId: string }, { dispatch }) => {
    try {
      const result = await API.get(`/user/kyc/verify/${userId}/${kycId}`);
      return result.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const viewSignaleDocument = createAsyncThunk(
  'CarrierLabelDownload',
  async ({ documentId, url }: { documentId: string; url: string }) => {
    // return await DownloadFile(`/shipment/file/get-url/${documentId}`);
    return await DownloadFile(`/${url}/file/get-url/${documentId}`);
  },
);

export {
  getLoggedInUserKyc,
  uploadLoggedInUserKyc,
  updateLoggedInUserKyc,
  verifyKyc,
  uploadUserKyc,
  getUserKyc,
  viewSignaleDocument,
  GetDownloadFileUrl,
};
