import SbButton from '@Components/Button';
import Modal from '@Components/Modal';
import SbTextField from '@Components/Textfield';
import { HighlightOffRounded } from '@mui/icons-material';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { addCreditLimit } from '@Reducers/Credit';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { AppDispatch, RootStateType } from '@Store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface PropsType {
  onClose: () => void;
  availableLimit: number;
  selectedUser?: UserObj | null;
}

function AddCreditModal(props: PropsType) {
  const { availableLimit, onClose, selectedUser } = props;
  const [limit, setLimit] = useState<string>();
  const [comment, setComment] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name || '');

  const { success } = useSelector((state: RootStateType) => state.Credit);
  // const selectedUser = useSelector((state: RootStateType) => state.accountLookup?.creditAndWallet?.selectedUser);

  const onAddCreditLimit = () => {
    if (limit && selectedUser?._accountId) {
      const { _id: _accountId, accountNumber } = selectedUser._accountId;
      dispatch(
        addCreditLimit({
          _accountId,
          accountNumber,
          _userId: selectedUser?._id,
          amount: Number(limit),
        }),
      );
    }
  };
  const theme = useTheme();

  useEffect(() => {
    if (success) {
      onClose();
    }
  }, [success]);
  return (
    <Modal open onClose={props.onClose}>
      <Grid
        sx={{
          width: '380px',
          maxWidth: '100%',
          padding: '16px',
          [theme.breakpoints.down('sm')]: {
            padding: '12px',
            width: '100%',
          },
        }}
      >
        <Grid container justifyContent={'space-between'}>
          <Typography className="font-semibold l-text">Add Credit Limit</Typography>
          <IconButton onClick={props.onClose}>
            <HighlightOffRounded />
          </IconButton>
        </Grid>
        <Grid container p={2} border={'1px solid'} borderColor={'background.grey'} borderRadius={2} my={2}>
          <Grid container className="mt-1" gap={6}>
            <Typography className="font-medium md-text">Current Credit Limit</Typography>
            <Typography className="font-semibold md-text ml-1">
              {orgCountryCurrency} {availableLimit}
            </Typography>
          </Grid>
          <Grid container className="mt-3" sx={{ flexDirection: 'column', gap: 2 }}>
            <SbTextField
              className="w-full"
              label="Enter Amount (INR)"
              required
              type="number"
              value={limit}
              onChange={e => setLimit(e.target.value)}
              sx={{
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                },
              }}
            />
            <SbTextField
              className="w-full"
              label="Comment"
              type="string"
              value={comment}
              onChange={e => setComment(e.target.value)}
              sx={{
                width: '100%',
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container className="mt-1" spacing={2}>
          {/* <Grid item xs={6}>
            <SbButton variant="outlined" fullWidth onClick={props.onClose}>
              Cancel
            </SbButton>
          </Grid> */}
          <Grid item xs={12}>
            <SbButton variant="contained" fullWidth onClick={onAddCreditLimit}>
              Update Credits
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default AddCreditModal;
