import React from 'react';
import CreateAdminForm from '../Create/Form';

function EditAdmin() {
  return (
    <>
      <CreateAdminForm formType="edit" />
    </>
  );
}

export default EditAdmin;
