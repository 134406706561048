import React, { useEffect, useState } from 'react';

import { FormHelperText, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Divider from '../../../components/Divider';

import SBDropdown from '../../../components/Dropdown';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';

import { getActiveSystemList } from '../../../store/reducers/Systems';

import DatePicker from '@Components/DatePicker';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import { getServiceList } from '@Reducers/CarrierAndService';
import { TaxObject } from '@Reducers/GlobalCharge/Type';
import { GlobalChargeFormProps, GlobalChargeFormType } from './type';

import SbButton from '@Components/Button';
import SbTooltip from '@Components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import { resetGlobalChargeRedirect } from '@Reducers/GlobalCharge';
import { getTaxInfoList } from '@Reducers/OrganizationSetup';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import CopyRate from '../CopyRate';
import CopyRateTable from '../CopyRate/Table';
import { CopyRateFormType } from '../CopyRate/type';
import { FormContent, FormLabel } from '../styles';
import ChargeTable from './ChargeTable';

export const initCreateGlobalCharge: GlobalChargeFormType = {
  fromDate: '',
  toDate: '',
  _systemId: '',
  _courierId: '',
  charges: [],
};

function GlobalChargeForm(props: GlobalChargeFormProps) {
  const { formType = 'create', values, errors, setFieldValue, validateForm, setFieldError } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isEdit = formType === 'edit';

  const { activeSystemDropdownList: SystemList, activeSystemListHashById } = useSelector(
    (state: RootStateType) => state.System,
  );
  const { serviceDropdownList: ServiceList, serviceListHashById } = useSelector(
    (state: RootStateType) => state.carrierAndService,
  );
  const TaxInfoList = useSelector((state: RootStateType) => state.orgSetup.dropdownTaxList);
  const { isRedirect } = useSelector((state: RootStateType) => state.globalCharge);
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
  const [copyRateEdit, setCopyRateEdit] = useState<CopyRateFormType | null>(null);
  const [copyRates, setCopyRates] = useState<CopyRateFormType[]>([]);

  useEffect(function () {
    dispatch(getServiceList({}));
    dispatch(getActiveSystemList());
    dispatch(getTaxInfoList());
  }, []);

  useEffect(() => {
    if (isRedirect) {
      if (formType === 'create') {
        dispatch(resetGlobalChargeRedirect());
        navigate('/global-charges');
      }
    }
  }, [isRedirect]);

  // ----------------------copy rates----------------------
  const onAddAndUpdateCopyRate = (rate: CopyRateFormType) => {
    const _copyRatesTemp = [...copyRates];
    if (rate.id || rate.id === 0) {
      const updateObjIndex = _copyRatesTemp.findIndex(x => x.id === rate.id);
      _copyRatesTemp[updateObjIndex] = rate;
    } else {
      rate.id = _copyRatesTemp.length;
      _copyRatesTemp.push({ ...rate });
    }
    setFieldValue?.('copyRates', _copyRatesTemp);
    // if(rate.accountNumber === "default") rate.accountNumber = null;
    setCopyRates([..._copyRatesTemp]);
  };

  const onDeleteCopyRates = (index: number) => {
    const _tempAcc = [...copyRates];
    _tempAcc.splice(index, 1);
    setFieldValue?.('copyRates', _tempAcc);
    setCopyRates([..._tempAcc]);
  };

  const onCopyRatesEdit = (index: number) => {
    setCopyRateEdit({ ...copyRates[index] });
    setCopyModalOpen(true);
  };

  const onClickCopyRates = async () => {
    const validateData = await validateForm();
    if (!Object.keys(validateData).length) setCopyModalOpen(true);
  };

  const onCloseModal = () => {
    setCopyModalOpen(false);
    if (copyRateEdit) setCopyRateEdit(null);
  };
  // --------------------------------------------

  return (
    <>
      {copyModalOpen && (
        <CopyRate
          modalOpen={copyModalOpen}
          onCloseModal={onCloseModal}
          onAdd={onAddAndUpdateCopyRate}
          edit={copyRateEdit}
          serviceName={values._courierId ? serviceListHashById[values._courierId]?.name || '' : ''}
          systemName={values._systemId ? activeSystemListHashById[values._systemId]?.name || '' : ''}
        />
      )}
      <Grid container sx={{ p: 4 }}>
        <Grid item xs={12}>
          <Typography className="org-text underline mb-4">
            {(formType === 'edit' && 'Edit') || (formType === 'create' && 'Add')} Global Charges
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="baseline" spacing={2}>
          <Grid container item xs={12} rowSpacing={2}>
            <Grid container item columnSpacing={{ xs: 5, lg: 20 }} rowSpacing={{ xs: 0, md: 20 }}>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={12} md={6} mb={{ xs: 1 }}>
                  <FormLabel className="md-text font-medium">System*</FormLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormContent sx={{ backgroundColor: theme.palette.background.white }}>
                    <SBDropdown
                      id="System"
                      required
                      options={SystemList}
                      onChange={([value]) => setFieldValue?.('_systemId', value, false)}
                      name="_systemId"
                      placeholder="Select"
                      value={[values._systemId]}
                      error={errors?._systemId}
                      disabled={isEdit}
                    />
                  </FormContent>
                </Grid>
                {!isMobile && <Divider className="mt-3 mb-2" />}
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={12} md={6} mb={{ xs: 1 }}>
                  <FormLabel className="md-text font-medium">Start Date *</FormLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormContent sx={{ backgroundColor: theme.palette.background.white }}>
                    {/* <SbTextField name="email" value={values.email} onChange={handleChange} error={errors?.email} /> */}
                    <DatePicker
                      error={errors?.fromDate}
                      value={values.fromDate ? dayjs(values.fromDate) : null}
                      inputFormat="DD/MM/YYYY"
                      minDate={dayjs()}
                      handleChange={date => {
                        setFieldError?.('fromDate', '');
                        if (date) setFieldValue?.('fromDate', date.format(), false);
                        else return setFieldValue?.('fromDate', null, false);

                        if (values.toDate && date.valueOf() > new Date(values.toDate).getTime()) {
                          if (setFieldError) console.log('Setting error');
                          setFieldError?.('toDate', 'To date should be greater than from date');
                        }
                      }}
                      disabled={isEdit}
                    />
                  </FormContent>
                </Grid>
                {!isMobile && <Divider className="mt-3 mb-2" />}
              </Grid>
            </Grid>
            <Grid container item columnSpacing={{ xs: 5, lg: 20 }} rowSpacing={{ xs: 0, md: 20 }}>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={12} md={6} mb={{ xs: 1 }}>
                  <FormLabel className="md-text font-medium">Service*</FormLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormContent sx={{ backgroundColor: theme.palette.background.white }}>
                    <SBDropdown
                      id="Service"
                      required
                      options={ServiceList}
                      onChange={([value]) => setFieldValue?.('_courierId', value, false)}
                      name="_courierId"
                      placeholder="Select"
                      value={[values._courierId]}
                      error={errors?._courierId}
                      disabled={isEdit}
                    />
                  </FormContent>
                </Grid>
                {!isMobile && <Divider className="mt-3 mb-2" />}
              </Grid>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={12} md={6} mb={{ xs: 1 }}>
                  <FormLabel className="md-text font-medium">End Date *</FormLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormContent sx={{ backgroundColor: theme.palette.background.white }}>
                    {/* <SbTextField name="email" value={values.email} onChange={handleChange} error={errors?.email} /> */}
                    <DatePicker
                      error={errors?.toDate}
                      value={values.toDate ? dayjs(values.toDate) : null}
                      inputFormat="DD/MM/YYYY"
                      handleChange={date => {
                        // if (!date) return setFieldValue?.('toDate', '', false);
                        // if (values.toDate && values.fromDate && dayjs(values.fromDate).isAfter(date)) return;
                        // setFieldValue?.('toDate', date.format());

                        setFieldError?.('toDate', '');
                        // console.log({ date });
                        if (date) setFieldValue?.('toDate', date.format(), false);
                        else setFieldValue?.('toDate', null, false);
                      }}
                      minDate={values.fromDate}
                      // disabled={isEdit}
                    />
                  </FormContent>
                </Grid>
                {!isMobile && <Divider className="mt-3 mb-2" sx={{ borderColor: 'transparent' }} />}
              </Grid>
            </Grid>
            <Grid container item columnSpacing={{ xs: 5, lg: 20 }} rowSpacing={{ xs: 0, md: 20 }}>
              <Grid item container xs={12} md={6} alignItems="center">
                <Grid item xs={12} md={6} mb={{ xs: 1 }}>
                  <FormLabel className="md-text font-medium">Apply Tax</FormLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormContent sx={{ backgroundColor: theme.palette.background.white }}>
                    <SBDropdown
                      id="Tax"
                      required
                      options={TaxInfoList || []}
                      onChange={([value], [selectedObj]: DropdownOptionType<TaxObject>[]) => {
                        setFieldValue?.('selectedTax', value, false);
                        setFieldValue?.('taxes', selectedObj?.metadata, false);
                      }}
                      name="selectedTax"
                      placeholder="Select"
                      value={[values.selectedTax]}
                      error={errors?.selectedTax}
                    />
                  </FormContent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="mt-3 mb-4" />
        </Grid>

        {errors?.charges && <FormHelperText error>{errors?.charges as string}</FormHelperText>}
        <ChargeTable
          charges={values?.charges}
          updateCharges={value => {
            setFieldError?.('charges', '');
            setFieldValue?.('charges', value);
          }}
        />
        {!isEdit ? (
          <>
            {copyRates.length ? (
              <>
                <Typography className="md-text font-medium">Rates copied to below services:</Typography>
                <CopyRateTable lists={copyRates} onDelete={onDeleteCopyRates} onEdit={onCopyRatesEdit} />
                <Grid container mt={2} justifyContent="flex-end">
                  <SbButton variant="contained" onClick={onClickCopyRates}>
                    Add More
                  </SbButton>
                </Grid>
              </>
            ) : (
              <Grid container>
                <Grid item xs={6} sm={3} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
                  <SbButton fullWidth variant="contained" onClick={onClickCopyRates} className="mr-2">
                    Copy Rates
                  </SbButton>
                  <SbTooltip placement="right" title="Copy this charges to other services and systems">
                    <InfoRounded />
                  </SbTooltip>
                </Grid>
              </Grid>
            )}
          </>
        ) : null}
      </Grid>
    </>
  );
}

export default GlobalChargeForm;
