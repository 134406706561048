import { RootStateType } from '@Store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

interface PropsType {
  shipperCountry?: string;
}

export function usePickupDropoffAccess({ shipperCountry }: PropsType) {
  const pincodeServiceabilityForShipment = useSelector(
    (state: RootStateType) => state?.pincode.pincodeServiceabilityForShipment,
  );
  const orgCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.name);

  const disablePickup: boolean | null = useMemo(() => {
    return pincodeServiceabilityForShipment
      ? pincodeServiceabilityForShipment?.isPickupServiceable === false
        ? true
        : false
      : null;
  }, [pincodeServiceabilityForShipment]);

  const disableDropoff: boolean = useMemo(() => {
    return shipperCountry !== orgCountry;
  }, [shipperCountry]);

  return { disablePickup, disableDropoff };
}
