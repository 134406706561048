import { TabPanel } from '@mui/lab';
import { FormLabel, Grid, SelectChangeEvent, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SbTextField from '../../../../components/Textfield';
import { SbSwitch } from '../../../../components/Switch';
import { OrganizationSetupContextType } from '../../../../types/organization';
import { RootStateType } from '../../../../store';
import { useSelector } from 'react-redux';
import SBDropdown from '../../../../components/Dropdown';
import { DropdownOptionType } from '../../../../components/Dropdown/Type';
import SbButton from '@Components/Button';
import { bankDetailsDefaultData } from '../../constants';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  gridGap: '24px',
}));

interface PropsType {
  data: OrganizationSetupContextType['bankDetails'][];
  onChange: (data: OrganizationSetupContextType['bankDetails'][]) => void;
  // setApplyToAllSystem: (data: ApplyToAllSystemType) => void;
  // applyToAllSystem: ApplyToAllSystemType;
  selectedBank?: OrganizationSetupContextType['bankDetails'];
  setSelectedBank?: (data: OrganizationSetupContextType['bankDetails']) => void;
}

// const schema = Yup.object().shape({
//   systemId: Yup.string().required(),
//   accountNumber: Yup.string().required(),
//   accountName: Yup.string().required(),
//   bankName: Yup.string().required('bank name / branch name required'),
//   accountType: Yup.string().required(),
//   ifscCode: Yup.string(),
//   upiId: Yup.string(),
//   gstNo: Yup.string(),
//   getwayIntegrationRequired: Yup.boolean(),
// });

function BankDetails(props: PropsType) {
  const { bankAccountTypeList } = useSelector((state: RootStateType) => state?.global);
  const activeSystemDropdownList = useSelector((state: RootStateType) => state?.System.activeSystemDropdownList);
  const { invoiceSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const { onChange: setBankDetails } = props;

  const [bankDetailsArr, setBankDetailsArr] = useState<OrganizationSetupContextType['bankDetails'][]>([]);
  // const [selectedBank, setSelectedBank] = useState<OrganizationSetupContextType['bankDetails']>();
  const { selectedBank, setSelectedBank } = props;

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isBankDetailsWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.bank_details,
    PERFORM_ACTION.write,
  );

  // useEffect(() => {
  //   if (bankDetails.length) {
  //     setSelectedBank?.(bankDetails[0]);
  //   }
  // }, [bankDetails.length]);

  useEffect(() => {
    const arr: OrganizationSetupContextType['bankDetails'][] = [];
    activeSystemDropdownList.forEach(system => {
      const selectedBank = invoiceSetting?.bankDetails.find(bank => bank.systemId === system.value);
      arr.push(
        selectedBank?._id
          ? {
              ...selectedBank,
            }
          : {
              ...bankDetailsDefaultData,
              systemId: system.value,
              system: {
                name: system.display,
                _id: system.value,
              },
            },
      );
    });
    setBankDetailsArr([...arr]);
  }, [invoiceSetting?.bankDetails, activeSystemDropdownList]);

  // useEffect(() => {
  //   const arr: OrganizationSetupContextType['bankDetails'][] = [];
  //   if (props?.applyToAllSystem?.apply && props?.applyToAllSystem?.defaultSystemBank) {
  //     activeSystemDropdownList.forEach(system => {
  //       const selectedBank = {
  //         ...props?.applyToAllSystem.defaultSystemBank,
  //       };
  //       arr.push({
  //         accountName: selectedBank.accountName || '',
  //         accountNumber: selectedBank.accountNumber || '',
  //         bankName: selectedBank.bankName || '',
  //         accountType: selectedBank.accountType || '',
  //         ifscCode: selectedBank.ifscCode || '',
  //         upiId: selectedBank.upiId || '',
  //         gstNo: selectedBank.gstNo || '',
  //         getwayIntegrationRequired: !!selectedBank.getwayIntegrationRequired,
  //         systemId: system.value,
  //         system: {
  //           name: system.display,
  //           _id: system.value,
  //         },
  //       });
  //     });
  //     setBankDetailsArr([...arr]);
  //   }
  // }, [props?.applyToAllSystem?.apply]);

  useEffect(() => {
    setBankDetails([...bankDetailsArr]);
  }, [bankDetailsArr]);

  // const formik = useFormik({
  //   enableReinitialize: true,
  //   validateOnChange: false,
  //   initialValues: { ...selectedBank },
  //   validationSchema: schema,
  //   onSubmit(values) {
  //     // onCreate(values);
  //   },
  // });
  // const { setFieldValue, values: formkikValues, errors } = formik;

  const { accountName, accountNumber, bankName, accountType, ifscCode, upiId, gstNo, getwayIntegrationRequired } =
    selectedBank || {};
  const { systemId } = selectedBank || {};

  // useEffect(() => {
  //   if (props?.applyToAllSystem?.apply) {
  //     let data = [...bankDetailsArr];
  //     data = data.map(bank => {
  //       return { ...props?.applyToAllSystem.defaultSystemBank, systemId: bank.systemId, system: bank.system };
  //     });
  //     setBankDetailsArr([...data]);
  //     setBankDetails([...data]);
  //   }
  // }, [props?.applyToAllSystem?.apply]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const currentBankInd = bankDetailsArr.findIndex(bank => selectedBank?.systemId === bank.systemId);
    const currentBankObj = { ...bankDetailsArr[currentBankInd] };
    const data = [...bankDetailsArr];
    if (
      name === 'accountName' ||
      name === 'accountNumber' ||
      name === 'bankName' ||
      name === 'ifscCode' ||
      name === 'upiId' ||
      name === 'gstNo'
    ) {
      currentBankObj[name] = value;
      // setFieldValue(name, value);
    }
    data[currentBankInd] = currentBankObj;
    setSelectedBank?.({ ...selectedBank, ...currentBankObj });
    setBankDetailsArr([...data]);
    // setBankDetails(data);
  }

  function handleDropdownChange(
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
    _child: React.ReactNode,
  ) {
    const { name, value } = event.target;
    const currentBankInd = bankDetailsArr.findIndex(bank => selectedBank?.systemId === bank.systemId);
    const currentBankObj = { ...bankDetailsArr[currentBankInd] };
    const data = [...bankDetailsArr];
    if (name === 'accountType') currentBankObj[name] = value;
    if (name === 'systemId') currentBankObj[name] = value;
    data[currentBankInd] = currentBankObj;
    // setFieldValue(name, value);
    setSelectedBank?.({ ...selectedBank, ...currentBankObj });
    setBankDetailsArr([...data]);
    // setBankDetails(data);
  }

  function handleSwitchChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    const { name } = event.target;
    const currentBankInd = bankDetailsArr.findIndex(bank => selectedBank?.systemId === bank.systemId);
    const currentBankObj = { ...bankDetailsArr[currentBankInd] };
    const data = [...bankDetailsArr];
    if (name === 'getwayIntegrationRequired') {
      currentBankObj[name] = checked;
      // setFieldValue(name, checked);
    }
    data[currentBankInd] = currentBankObj;
    setSelectedBank?.({ ...selectedBank, ...currentBankObj });
    setBankDetailsArr([...data]);
    // setBankDetails(data);
  }

  // const handleApplyToAllSystem = () => {
  //   if (props.applyToAllSystem?.apply) {
  //     props.setApplyToAllSystem({ apply: false, defaultSystemBank: null });
  //   } else if (selectedBank?.systemId) {
  //     props.setApplyToAllSystem({ apply: true, defaultSystemBank: selectedBank });
  //     let data = [...bankDetailsArr];
  //     data = data.map(bank => {
  //       return { ...selectedBank, systemId: bank.systemId, system: bank.system };
  //     });
  //     setBankDetailsArr([...data]);
  //     setBankDetails([...data]);
  //   }
  // };

  return (
    <TabPanel value="bank_details" className="p-0 w-full">
      <Grid className="mb-2 overflow-auto bg-grey rounded" sx={{ whiteSpace: 'nowrap', padding: '12px' }}>
        {bankDetailsArr.length ? (
          bankDetailsArr.map((bank, ind) => (
            <SbButton
              key={`system-${bank.system.name}-${ind}`}
              variant={systemId === bank.system?._id ? 'contained' : 'outlined'}
              className="mr-2"
              sx={{ borderRadius: '8px !important', height: '42px' }}
              onClick={() => setSelectedBank?.(bank)}
              disabled={!isBankDetailsWriteAllowed}
            >
              {bank.system.name}
            </SbButton>
          ))
        ) : (
          <></>
        )}
      </Grid>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel className="md-text font-medium sb-text-black">Account Name</FormLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <SbTextField
              placeholder="Enter Account Name"
              label=""
              name="accountName"
              value={accountName}
              onChange={handleChange}
              required
              // error={errors.accountName}
              disabled={!isBankDetailsWriteAllowed}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel className="md-text font-medium sb-text-black">Account Number</FormLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <SbTextField
              placeholder="Enter Account Number"
              label=""
              name="accountNumber"
              value={accountNumber}
              onChange={handleChange}
              required
              disabled={!isBankDetailsWriteAllowed}
              // error={errors.accountNumber}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel className="md-text font-medium sb-text-black">Name & Branch</FormLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <SbTextField
              placeholder="Enter Name & Branch"
              label=""
              name="bankName"
              value={bankName}
              onChange={handleChange}
              disabled={!isBankDetailsWriteAllowed}
              required
              // error={errors.bankName}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel className="md-text font-medium sb-text-black">Account Type</FormLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <SBDropdown
              id="bank-acc-type"
              label=""
              required
              name="accountType"
              options={bankAccountTypeList}
              value={accountType ? [accountType] : []}
              onChange={handleDropdownChange}
              disabled={!isBankDetailsWriteAllowed}
              // error={errors.accountType}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel className="md-text font-medium sb-text-black">Ifsc Code</FormLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <SbTextField
              placeholder="Enter Ifsc Code"
              label=""
              name="ifscCode"
              value={ifscCode}
              onChange={handleChange}
              required
              disabled={!isBankDetailsWriteAllowed}
              // error={errors.ifscCode}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel className="md-text font-medium sb-text-black">UPI ID </FormLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <SbTextField
              label=""
              placeholder="Enter UPI ID"
              name="upiId"
              value={upiId}
              onChange={handleChange}
              required
              disabled={!isBankDetailsWriteAllowed}
              // error={errors.upiId}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel className="md-text font-medium sb-text-black">GST No</FormLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <SbTextField
              placeholder="Enter GST No"
              label=""
              name="gstNo"
              value={gstNo}
              onChange={handleChange}
              required
              disabled={!isBankDetailsWriteAllowed}
              //  error={errors.gstNo}
            />
          </Grid>
        </Grid>
        {/* <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <FormLabel className="md-text font-medium sb-text-black">Systems</FormLabel>
          </Grid>
          <Grid item xs={12} md={8}>
            <SBDropdown
              id="systemId"
              label=""
              required
              name="systemId"
              options={activeSystemDropdownList}
              value={systemId ? [systemId] : []}
              onChange={handleDropdownChange}
            />
          </Grid>
        </Grid> */}
        <Grid container alignItems="center" />
        <Grid container alignItems="center">
          <Grid item xs={10} md={4} sm={6}>
            <FormLabel className="md-text font-medium sb-text-black">
              Do you want a Payment Gateway integration?*
            </FormLabel>
          </Grid>
          <Grid item xs={2} md={8} sm={6}>
            <SbSwitch
              label=""
              onChange={handleSwitchChange}
              name="getwayIntegrationRequired"
              value={getwayIntegrationRequired}
              defaultChecked={getwayIntegrationRequired}
              disabled={!isBankDetailsWriteAllowed}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" />
        {/* <Grid container item md="auto" alignItems={'center'} className="cursor-pointer">
          <Checkbox
            name="insurance"
            id="insurance"
            className="mr-1"
            checked={
              props.applyToAllSystem?.defaultSystemBank?.systemId === selectedBank?.systemId &&
              props.applyToAllSystem.apply
            }
            onChange={handleApplyToAllSystem}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <Typography component={'label'} htmlFor="insurance" className="md-text font-light ml-1" variant="caption">
            Apply to All System
          </Typography>
        </Grid> */}
      </Container>
    </TabPanel>
  );
}

export default BankDetails;
