import React from 'react';
import ErrorModal from '@Components/Modal/error';
import { Grid, Typography, styled } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import SbButton from '@Components/Button';

interface PropsType {
  open: boolean;
  onClose: () => void;
}

const P = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: { fontSize: theme.spacing(4) },
  [theme.breakpoints.up('md')]: { fontSize: theme.spacing(3) },
}));

export default function IndividualAccountHolderModal({ open, onClose }: PropsType) {
  return (
    <ErrorModal icon={'/images/paid.svg'} open={open} onClose={onClose} onConfirm={onClose} btnText="Close">
      <Grid textAlign={'left'} className="p-2">
        <P className="font-semibold" sx={{ mt: '50px', mb: '30px' }}>
          This User is now an <span className="underline font-bold">Account Holder</span>{' '}
        </P>
        <P>By making them an account holder they have access to :</P>
        <Grid container>
          {['Contract pricing', 'Invoices', 'Credits'].map(i => (
            <Grid container item xs={12} alignItems={'center'}>
              <CircleIcon className="mr-2" sx={{ fontSize: '12px' }} /> <P>{i}</P>
            </Grid>
          ))}
        </Grid>

        <Grid container justifyContent={'flex-end'}>
          <SbButton className="mt-1" variant="contained" sx={{ width: '150px' }} onClick={onClose}>
            Ok
          </SbButton>
        </Grid>
      </Grid>
    </ErrorModal>
  );
}
