import { DispatchSuccess } from '@Reducers/SuccessErrorModal';
import { PayloadAction } from '@reduxjs/toolkit';
import { SuccessPayload } from '../../../types';
import {
  AddShipmentNotesType,
  AramexInformationType,
  ConnectOnWarehouse,
  DHLInformationType,
  ErrorType,
  FedXInformationType,
  PlaceRTOShipmentSuccessResType,
  ShipmentByIdType,
  ShipmentError,
  ShipmentNotesType,
  ShipmentPricing,
  ShipmentStateType,
  ShipmentpaymentType,
  UpdateShipmentRes,
  locationsResponse,
  vendorAccountType,
} from './Type';

import { initFedXInformation } from '../../../pages/ViewSingleShipmentAdmin/components/ShipmentProcessingV2/components/Fedex/constants';
import { CSP_TYPES } from '@Constants/Shipment';
import { initDHLInformation } from '../../../pages/ViewSingleShipment/TrackingDetails/UserDHL/constants';
import { initAramexInformation } from '../../../pages/ViewSingleShipmentAdmin/components/ShipmentProcessingV2/components/Aramex/constants';

function pending(state: ShipmentStateType) {
  state.loading = true;
  // state.error = {};
  state.carrierNumberUpdated = false;
}

function getShipmentByIdFulfilled(state: ShipmentStateType, action: PayloadAction<{ data: ShipmentByIdType }>) {
  state.loading = false;
  const data = action.payload.data;
  // const boxData =
  data._packageId.box.forEach((singleBox, ind) => (singleBox.id = ind.toString()));
  console.log({ data });
  // data._packageId.box = boxData;
  state.shipmentById = data;
  // state.RTOShipment = null;
}

function getShipmentPricingFulfilled(
  state: ShipmentStateType,
  action: PayloadAction<SuccessPayload<ShipmentPricing[]>>,
) {
  state.loading = false;
  if (action.payload.data) state.shipmentPricing = action.payload.data;
}

function getShipmentErrorFulfilled(state: ShipmentStateType, action: PayloadAction<{ data: ShipmentError }>) {
  state.loading = false;
  state.shipmentError = action.payload.data;
}

function getShipmentNotesFulfilled(
  state: ShipmentStateType,
  action: PayloadAction<SuccessPayload<ShipmentNotesType[]>>,
) {
  state.loading = false;
  if (action.payload.data) state.shipmentNotes = action.payload.data;
}

function addShipmentNotesFulfilled(state: ShipmentStateType, _action: PayloadAction<AddShipmentNotesType>) {
  state.loading = false;
  state.shipmentNotesAdded = true;
}

function updateShipmentFulfilled(state: ShipmentStateType, _action: PayloadAction<UpdateShipmentRes>) {
  state.loading = false;
  state.shipmentUpdated = true;
}
function updateCarrierAndCustomerRefNumberFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
}

function updatePickUpAndDropOffFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
}

function getShipmentPaymentBySNFulfilled(
  state: ShipmentStateType,
  action: PayloadAction<SuccessPayload<ShipmentpaymentType>>,
) {
  state.loading = false;
  if (action.payload.data) state.shipmentpayment = action.payload.data;
}

function rejected(state: ShipmentStateType) {
  state.loading = false;
  state.error = {
    msg: 'Something went wrong.',
    errorDetails: '',
    isError: true,
  };
}

function updateShipmentRejected(state: ShipmentStateType, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.shipmentUpdateError = false;
  state.error = {
    msg: action?.payload?.msg || 'Something went wrong.',
    errorDetails: '',
    isError: true,
  };
}

function postFedExDetailsRejected(state: ShipmentStateType) {
  state.loading = false;
}

function postFedExDetailsFulfilled(state: ShipmentStateType, _action: PayloadAction<string | undefined>) {
  state.loading = false;
  state.subCharges = _action?.payload;
}

function updateGlobalChargeFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
}

function shipmentLockRateFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
}

function updateExtraChargeFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
}

function connectOnWarehouseFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
  state.shipmentConnectOnWarehouse = [];
}

function getConnectOnWarehouseFulfilled(
  state: ShipmentStateType,
  action: PayloadAction<SuccessPayload<ConnectOnWarehouse[]>>,
) {
  state.loading = false;
  if (action.payload.data) state.shipmentConnectOnWarehouse = action.payload.data;
}

function placeRTOShipmentFulfilled(state: ShipmentStateType, action: PayloadAction<PlaceRTOShipmentSuccessResType>) {
  const { rtoShipmentNumber } = action.payload || {};
  state.loading = false;

  if (rtoShipmentNumber) {
    state.RTOShipment = rtoShipmentNumber;
    // DispatchSuccess('Success', `RTO shipment has been booked with shipment number : ${rtoShipmentNumber}`);
  }
}

function updateSingleBoxCarrierNumberFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
  state.carrierNumberUpdated = true;
}

function updateFirstConnectingWarehouseFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
}

function updateLastConnectionFulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
  DispatchSuccess('Success', `Shipment connect on details updated successfully!`);
}

function getFedXDetailsFulfilled(
  state: ShipmentStateType,
  _action: PayloadAction<SuccessPayload<FedXInformationType> | SuccessPayload<DHLInformationType>>,
) {
  state.loading = false;
  if (isFedXInformation(_action.payload.data)) {
    state.fedExDetails = _action.payload.data || initFedXInformation;
    state.fedExDetails.pickupDate = _action.payload.data?.pickup
      ? _action.payload.data?.pickup.readyDateTimestamp
      : null;
    if (_action.payload.data?.pickup && _action.payload.data?.pickup?.readyDateTimestamp && state.shipmentById) {
      state.shipmentById.customerReferenceNumber = [
        ...JSON.parse(JSON.stringify(state.shipmentById?.customerReferenceNumber)),
        {
          label: 'Fedex Pickup ID',
          value: _action.payload.data?.pickup.location + _action.payload.data?.pickup.pickupConfirmationCode,
        },
      ];
    }
  } else if (isDhlInformation(_action.payload.data)) {
    state.dhlDetails = _action.payload.data;
  } else if (isAramexInformation(_action.payload.data)) {
    state.aramexDetails = _action.payload.data;
  } else if (isStageInformation(_action.payload.data)) {
    state.stageDetails = _action.payload.data;
  } else {
    state.fedExDetails = initFedXInformation;
    state.dhlDetails = initDHLInformation;
    state.aramexDetails = initAramexInformation;
  }
}

function postDhlOtpfulfilled(
  state: ShipmentStateType,
  _action: PayloadAction<SuccessPayload<{ Postshipment_Payload: string }>>,
) {
  state.loading = false;
  state.dhlOtpPostShipmentPayload = _action.payload?.data?.Postshipment_Payload || '';
}

function postDhlVerifyOtpfulfilled(state: ShipmentStateType, _action: PayloadAction<SuccessPayload>) {
  state.loading = false;
}

function getVendorsAccountFulfilled(
  state: ShipmentStateType,
  action: PayloadAction<SuccessPayload<vendorAccountType[]>>,
) {
  state.loading = false;
  state.vendersAccount = action.payload.data || [];
}

function getLocationsFulfilled(state: ShipmentStateType, action: PayloadAction<SuccessPayload<locationsResponse[]>>) {
  state.loading = false;
  state.locations = action.payload.data || [];
}

export {
  addShipmentNotesFulfilled,
  connectOnWarehouseFulfilled,
  getConnectOnWarehouseFulfilled,
  getFedXDetailsFulfilled,
  getLocationsFulfilled,
  getShipmentByIdFulfilled,
  getShipmentErrorFulfilled,
  getShipmentNotesFulfilled,
  getShipmentPaymentBySNFulfilled,
  getShipmentPricingFulfilled,
  getVendorsAccountFulfilled,
  pending,
  placeRTOShipmentFulfilled,
  postDhlOtpfulfilled,
  postDhlVerifyOtpfulfilled,
  postFedExDetailsRejected,
  rejected,
  shipmentLockRateFulfilled,
  updateCarrierAndCustomerRefNumberFulfilled,
  updateExtraChargeFulfilled,
  updateFirstConnectingWarehouseFulfilled,
  updateGlobalChargeFulfilled,
  updateLastConnectionFulfilled,
  updatePickUpAndDropOffFulfilled,
  updateShipmentFulfilled,
  updateShipmentRejected,
  updateSingleBoxCarrierNumberFulfilled,
  postFedExDetailsFulfilled,
};

function isFedXInformation(data: any): data is FedXInformationType {
  return (data as FedXInformationType)?.csp === CSP_TYPES.fedex.value;
}
function isAramexInformation(data: any): data is AramexInformationType {
  return (data as AramexInformationType)?.csp === CSP_TYPES.aramex.value;
}
function isStageInformation(data: any): data is any {
  return (data as any)?.csp === CSP_TYPES.stage.value;
}

function isDhlInformation(data: any): data is DHLInformationType {
  return (
    (data as DHLInformationType)?.csp === CSP_TYPES.dhlIndia.value ||
    (data as DHLInformationType)?.csp === CSP_TYPES.dhl.value
  );
}
