import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  cancelShipmentFulfilled,
  getShipmentListFulfilled,
  UpdateShipmentStatusFulfilled,
  pending,
  rejected,
  success,
  bulkStatusUpdateFulfilled,
  bulkPaymentStatusUpdateFulfilled,
  // resetShipmentSummary,
} from './case';
import { ShipmentHistoryState } from './Type';
import {
  BulkExportOrder,
  BulkStatusUpdate,
  BulkPaymentUpdate,
  cancelShipment,
  downloadBulkCarrierLabel,
  downloadBulkProformaInvoice,
  downloadBulkShippingInvoice,
  downloadBulkShippingLabel,
  downloadCSPLabel,
  downloadManifest,
  downloadProformaInvoice,
  downloadShippingInvoice,
  downloadShippingLabel,
  getShipmentList,
  UpdateShipmentStatus,
  updateUserPickUpAndDropOff,
} from './actions';

const initialState = <ShipmentHistoryState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 0,
  },
  success: '',
  bulkStatusUploaded: false,
  selectedShipments: {},
  shipmentCancelled: false,
  isBulkPaymentStatusUpdate: false,
};

const shipmentHistorySlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    setSelectedShipments: function (state, action: PayloadAction<ShipmentHistoryState['selectedShipments']>) {
      const newSelectedShipments = action.payload;
      state.selectedShipments = newSelectedShipments;
    },
    // resetShipmentSummary: resetShipmentSummary,
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getShipmentList.pending, pending);
    addCase(getShipmentList.fulfilled, getShipmentListFulfilled);
    addCase(getShipmentList.rejected, rejected);

    addCase(cancelShipment.pending, pending);
    addCase(cancelShipment.fulfilled, cancelShipmentFulfilled);
    addCase(cancelShipment.rejected, rejected);

    addCase(UpdateShipmentStatus.pending, pending);
    addCase(UpdateShipmentStatus.fulfilled, UpdateShipmentStatusFulfilled);
    addCase(UpdateShipmentStatus.rejected, rejected);

    addCase(BulkStatusUpdate.pending, pending);
    addCase(BulkStatusUpdate.fulfilled, bulkStatusUpdateFulfilled);
    addCase(BulkStatusUpdate.rejected, rejected);

    addCase(downloadShippingLabel.pending, pending);
    addCase(downloadShippingLabel.fulfilled, success);
    addCase(downloadShippingLabel.rejected, rejected);

    addCase(downloadCSPLabel.pending, pending);
    addCase(downloadCSPLabel.fulfilled, success);
    addCase(downloadCSPLabel.rejected, rejected);

    addCase(downloadShippingInvoice.pending, pending);
    addCase(downloadShippingInvoice.fulfilled, success);
    addCase(downloadShippingInvoice.rejected, rejected);

    addCase(downloadProformaInvoice.pending, pending);
    addCase(downloadProformaInvoice.fulfilled, success);
    addCase(downloadProformaInvoice.rejected, rejected);

    addCase(downloadBulkProformaInvoice.pending, pending);
    addCase(downloadBulkProformaInvoice.fulfilled, success);
    addCase(downloadBulkProformaInvoice.rejected, rejected);

    addCase(downloadBulkShippingLabel.pending, pending);
    addCase(downloadBulkShippingLabel.fulfilled, success);
    addCase(downloadBulkShippingLabel.rejected, rejected);

    addCase(downloadBulkShippingInvoice.pending, pending);
    addCase(downloadBulkShippingInvoice.fulfilled, success);
    addCase(downloadBulkShippingInvoice.rejected, rejected);

    addCase(downloadBulkCarrierLabel.pending, pending);
    addCase(downloadBulkCarrierLabel.fulfilled, success);
    addCase(downloadBulkCarrierLabel.rejected, rejected);

    addCase(downloadManifest.pending, pending);
    addCase(downloadManifest.fulfilled, success);
    addCase(downloadManifest.rejected, rejected);

    addCase(BulkExportOrder.pending, pending);
    addCase(BulkExportOrder.fulfilled, success);
    addCase(BulkExportOrder.rejected, rejected);

    addCase(updateUserPickUpAndDropOff.pending, pending);
    addCase(updateUserPickUpAndDropOff.fulfilled, success);
    addCase(updateUserPickUpAndDropOff.rejected, rejected);

    addCase(BulkPaymentUpdate.pending, pending);
    addCase(BulkPaymentUpdate.fulfilled, bulkPaymentStatusUpdateFulfilled);
    addCase(BulkPaymentUpdate.rejected, rejected);
  },
});

// const { setUser } = shipmentHistorySlice.actions;

export { getShipmentList, cancelShipment, UpdateShipmentStatus };
export const { setSelectedShipments } = shipmentHistorySlice.actions;
export default shipmentHistorySlice.reducer;
