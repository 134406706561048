import React from 'react';
import { Typography } from '@mui/material';
import Image from '../../Image';
import { VerticalTrackingProps } from './type';
import { SHIPMENT_STATUS } from '../../../constants/Shipment';
import {
  SBTimeline,
  SBTimelineItem,
  SBTimelineOppositeContent,
  SBTimelineSeparator,
  SBTimelineDot,
  SBTimelineConnector,
  SBTimelineContent,
  ShipmentStatus,
  ShipmentLocation,
  VerticalTrackingWrapper,
} from './styles';
import { hasAdminAccess } from '@Services/hasAdminAccess';
import formatDate from '../../../utils/formatDate';

const VerticalTracking = (props: VerticalTrackingProps) => {
  return (
    <VerticalTrackingWrapper>
      {props?.data?.length ? (
        <>
          {props.data.map((outer, outerInd) => (
            <SBTimeline
              key={'outer-' + outerInd}
              className="mt-4"
              is_last={props.data.length === outerInd + 1 ? '1' : '0'}
            >
              {/* align="alternate" */}
              <SBTimelineItem key={outerInd}>
                <SBTimelineOppositeContent className="pt-0 pb-0">
                  <Typography className="md-text font-normal">{outer?.formattedDate || ''}</Typography>
                </SBTimelineOppositeContent>

                <SBTimelineSeparator>
                  <SBTimelineDot />
                  <SBTimelineConnector />
                </SBTimelineSeparator>

                <SBTimelineContent className="pt-0 pb-0" />
              </SBTimelineItem>
              {outer.details?.length > 0 &&
                outer.details
                  ?.filter(el => el.shipmentNumber === props?.shipmentNumber)
                  ?.map((outerItem, outerInd) => {
                    return outerItem.statusDetails?.map((item, statusIndex) => {
                      return (
                        <SBTimelineItem key={'inner-' + statusIndex}>
                          <SBTimelineOppositeContent className="sm-text font-normal pt-0 pb-0">
                            {formatDate(item?.timeStamp) || ''}
                          </SBTimelineOppositeContent>

                          <SBTimelineSeparator
                            sx={{
                              marginLeft:
                                outerInd === 0 && statusIndex === 0
                                  ? { sm: '-3px', md: '-4px', lg: '-3px', xl: '-0.5px' }
                                  : '0px',
                            }}
                          >
                            {outerInd === 0 && statusIndex === 0 ? (
                              <Image
                                src="/images/shipment/BoxIcon.svg"
                                alt="Box"
                                height={24}
                                style={{ maxWidth: '24px' }}
                              />
                            ) : (
                              <SBTimelineDot />
                            )}
                            <SBTimelineConnector />
                          </SBTimelineSeparator>

                          <SBTimelineContent className="pt-0 pb-0">
                            <div className="flex">
                              <ShipmentStatus className="sm-text font-normal flex flex-col">
                                <p>{SHIPMENT_STATUS[item.status]?.label || ''}</p>
                                <Typography className="xs-text font-normal mb-2" sx={{ color: '#AFAFAF' }}>
                                  {item.msg}
                                </Typography>
                              </ShipmentStatus>
                              <ShipmentLocation className="sm-text font-normal flex">
                                {item.location
                                  ? `${item.location.charAt(0).toUpperCase()}${item.location.slice(1)}`
                                  : ''}
                              </ShipmentLocation>
                            </div>
                            {item.status === SHIPMENT_STATUS.rto?.value && props.rtoShipmentNumber && (
                              <Typography className="xs-text flex items-center" sx={{ color: '#AFAFAF' }}>
                                <img src={'/images/paid.svg'} alt="logistics" className="mr-1" />
                                RTO shipment has been booked with shipment number:{' '}
                                <a
                                  href={`${hasAdminAccess() ? `/shipment/admin/edit/` : '/shipment/history/'}${
                                    props.rtoShipmentNumber
                                  }`}
                                  target="_blank"
                                  className="link ml-1/2"
                                >
                                  {props.rtoShipmentNumber}
                                </a>
                              </Typography>
                            )}
                          </SBTimelineContent>
                        </SBTimelineItem>
                      );
                    });
                  })}
            </SBTimeline>
          ))}
        </>
      ) : (
        <></>
      )}
    </VerticalTrackingWrapper>
  );
};

export default VerticalTracking;
