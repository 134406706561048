import * as Yup from 'yup';

export const dhlInformationSchema = Yup.object().shape({
  accountNo: Yup.string().trim().required('DHL Account is required'),
  countryOfManufacture: Yup.string().trim().required('Country of manufacture is required'),
  paymentMode: Yup.string().trim().required('Payor is required'),
  ShipperType: Yup.string().trim().required('GSTIN is required'),
  GSTINNumber: Yup.string()
    .trim()
    .when('$ShipperType', ([ShipperType], schema) => {
      return ShipperType === 'MSME' ? schema.required('Booking confirmation number is required') : schema;
    }),
  GSTINType: Yup.string().trim().required('gstinType is required'),
  gstInvNo: Yup.mixed()
    .nullable()
    .when('ShipperType', {
      is: 'MSME',
      then: schema => schema.required('Invoice Date is required'),
      otherwise: schema => schema.notRequired().nullable(),
    }),

  gstInvNoDate: Yup.mixed()
    .nullable()
    .when('ShipperType', {
      is: 'MSME',
      then: schema => schema.required('Invoice Date is required'),
      otherwise: schema => schema.notRequired().nullable(),
    }),
});
