import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@Services/apiAxios';
import { ErrorType } from '../../../types';
import { TrackShipmentResponseType } from './type';

function getTrackingURl(id: string) {
  return API.get('/tracking/track/single', {
    params: {
      shipmentNumber: id,
    },
  });
}

const trackMultipleShipments = createAsyncThunk<TrackShipmentResponseType[], string[], { rejectValue: ErrorType }>(
  'shipment/track-multiple',
  async (
    shipmentIds,
    // {
    //   /*dispatch*/
    // },
  ) => {
    console.log('trackShipment shipmentIds : ', shipmentIds);
    try {
      const res = await Promise.allSettled(shipmentIds.map(id => getTrackingURl(id))).then(data =>
        data
          .map(data => {
            if (data?.status === 'fulfilled') {
              return data.value.data;
            } else {
              return null;
            }
          })
          .filter(Boolean),
      );
      return res;
    } catch (error) {
      console.log({ error });
      // const errorRes = error.response.data;
      // dispatch(
      //   SuccessErrorModalOpen({
      //     type: 'error',
      //     title: 'Oops!',
      //     subTitle: errorRes?.msg || 'Something went to wrong.',
      //   }),
      // );
      throw error;
    }
    // const response: TrackShipmentResponseType = {
    //   data: [
    //     {
    //       courierServiceName: 'Fedex Priority - Hub Connect',
    //       customerReferenceNumber: [''],
    //       shipmentNumber: '2794140603356',
    //       carrierAirWayBillNumber: '782126458443',
    //       statusDetails: [
    //         {
    //           msg: 'At local FedEx facility',
    //           status: 'delivered',
    //           location: 'DUBAI',
    //           timeStamp: '2023-08-13T12:10:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'At local FedEx facility',
    //           status: 'in_transit',
    //           location: 'DUBAI',
    //           timeStamp: '2023-08-12T11:40:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'In transit',
    //           status: 'in_transit',
    //           location: 'BANGALORE',
    //           timeStamp: '2023-08-12T04:38:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'At local FedEx facility',
    //           status: 'in_transit',
    //           location: 'BANGALORE',
    //           timeStamp: '2023-08-11T03:39:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'In transit',
    //           status: 'in_transit',
    //           location: 'HYDERABAD',
    //           timeStamp: '2023-08-10T16:31:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'Picked up',
    //           status: 'in_transit',
    //           location: 'HYDERABAD',
    //           timeStamp: '2023-08-10T13:47:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           position: {
    //             type: 'Point',
    //             coordinates: [],
    //           },
    //           _id: '64d08f8a72822a00089e82d4',
    //           msg: 'Mps Shipment Has Been Forwarded.',
    //           status: 'forwarded',
    //           location: 'Hyderabad',
    //           timeStamp: '2023-08-07T06:30:34.827Z',
    //           modifiedBy: '64b0fd14eff5a700089d4476',
    //           airwayBill: '782126458490',
    //         },
    //         {
    //           position: {
    //             type: 'Point',
    //             coordinates: [],
    //           },
    //           _id: '64d08eeac62c0d00084f4fae',
    //           msg: 'Order Placed',
    //           status: 'placed',
    //           location: 'Hyderabad',
    //           timeStamp: '2023-08-07T06:27:54.053Z',
    //           modifiedBy: '60d1a5d5a7459c000977fd19',
    //           airwayBill: '782126458124',
    //         },
    //       ],
    //       origin: 'HYDERABAD',
    //       status: 'in_transit',
    //       // createdAt: '2023-08-07T06:27:54.015Z',
    //       // timezone: '+0530',
    //       destination: 'Surabaya',
    //       weight: 25,
    //       unitOfMeasurement: 'kg-cm',
    //       // pickupDate: null,
    //       // systemName: 'Hyderabad',
    //       childAWBs: {
    //         orderNumbers: ['782126458443', '782126460395'],
    //         trackURL: 'https://www.fedex.com/apps/fedextrack/?action=track&locale=en_IN&cntry_code=in&tracknumbers=',
    //         limit: 20,
    //       },
    //     },
    //   ],
    //   msg: 'Tracking details found',
    // };
    // return response;
  },
);

const trackShipment = createAsyncThunk<TrackShipmentResponseType, string, { rejectValue: ErrorType }>(
  'shipment/track',
  async (shipmentId, { dispatch: _dispatch }) => {
    console.log('trackShipment shipmentId : ', shipmentId);
    try {
      const res = await getTrackingURl(shipmentId);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      console.error('errorRes', errorRes);
      // dispatch(
      //   SuccessErrorModalOpen({
      //     type: 'error',
      //     title: 'Oops!',
      //     subTitle: errorRes?.msg || 'Something went to wrong.',
      //   }),
      // );
      throw error;
    }
    // const response: TrackShipmentResponseType = {
    //   data: [
    //     {
    //       courierServiceName: 'Fedex Priority - Hub Connect',
    //       customerReferenceNumber: [''],
    //       shipmentNumber: '2794140603356',
    //       carrierAirWayBillNumber: '782126458443',
    //       statusDetails: [
    //         {
    //           msg: 'At local FedEx facility',
    //           status: 'delivered',
    //           location: 'DUBAI',
    //           timeStamp: '2023-08-13T12:10:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'At local FedEx facility',
    //           status: 'in_transit',
    //           location: 'DUBAI',
    //           timeStamp: '2023-08-12T11:40:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'In transit',
    //           status: 'in_transit',
    //           location: 'BANGALORE',
    //           timeStamp: '2023-08-12T04:38:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'At local FedEx facility',
    //           status: 'in_transit',
    //           location: 'BANGALORE',
    //           timeStamp: '2023-08-11T03:39:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'In transit',
    //           status: 'in_transit',
    //           location: 'HYDERABAD',
    //           timeStamp: '2023-08-10T16:31:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           msg: 'Picked up',
    //           status: 'in_transit',
    //           location: 'HYDERABAD',
    //           timeStamp: '2023-08-10T13:47:00.000Z',
    //           airwayBill: '782126458443',
    //         },
    //         {
    //           position: {
    //             type: 'Point',
    //             coordinates: [],
    //           },
    //           _id: '64d08f8a72822a00089e82d4',
    //           msg: 'Mps Shipment Has Been Forwarded.',
    //           status: 'forwarded',
    //           location: 'Hyderabad',
    //           timeStamp: '2023-08-07T06:30:34.827Z',
    //           modifiedBy: '64b0fd14eff5a700089d4476',
    //           airwayBill: '782126458490',
    //         },
    //         {
    //           position: {
    //             type: 'Point',
    //             coordinates: [],
    //           },
    //           _id: '64d08eeac62c0d00084f4fae',
    //           msg: 'Order Placed',
    //           status: 'placed',
    //           location: 'Hyderabad',
    //           timeStamp: '2023-08-07T06:27:54.053Z',
    //           modifiedBy: '60d1a5d5a7459c000977fd19',
    //           airwayBill: '782126458124',
    //         },
    //       ],
    //       origin: 'HYDERABAD',
    //       status: 'in_transit',
    //       // createdAt: '2023-08-07T06:27:54.015Z',
    //       // timezone: '+0530',
    //       destination: 'Surabaya',
    //       weight: 25,
    //       unitOfMeasurement: 'kg-cm',
    //       // pickupDate: null,
    //       // systemName: 'Hyderabad',
    //       childAWBs: {
    //         orderNumbers: ['782126458443', '782126460395'],
    //         trackURL: 'https://www.fedex.com/apps/fedextrack/?action=track&locale=en_IN&cntry_code=in&tracknumbers=',
    //         limit: 20,
    //       },
    //     },
    //   ],
    //   msg: 'Tracking details found',
    // };
    // return response;
  },
);

export { trackMultipleShipments, trackShipment };
