import React from 'react';
import SbButton from './index';
import Image from '../Image';

interface ExportBtnProps {
  iconSrc: string;
  alt: string;
  height?: number;
  width?: number;
  label: string;
  // onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void | undefined;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

function ExportBtn({ iconSrc, alt, height, width, label, onClick, disabled }: ExportBtnProps) {
  return (
    <SbButton
      variant="outlined"
      className=" border-light "
      sx={{ borderRadius: '8px !important', minWidth: '145px' }}
      startIcon={<Image src={iconSrc} alt={alt} height={height || 28} width={width || 28} />}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </SbButton>
  );
}

export default ExportBtn;
