import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import API from '../../../services/apiAxios';
import { SuccessPayload } from '../../../types';
import {
  CreateServicePayload,
  ErrorType,
  ListQuery,
  ServiceCreateResponse,
  ServiceResponse,
  SingleService,
  UpdateServicePayload,
} from './Type';

const getServiceList = createAsyncThunk<ServiceResponse, ListQuery, { rejectValue: ErrorType }>(
  'getServiceList',
  async (queryParams, { rejectWithValue }) => {
    try {
      const res = await API.get(`/organization/service-provider/list?`, { params: queryParams });
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const getServiceById = createAsyncThunk<SingleService, string, { rejectValue: ErrorType }>(
  'getServiceById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await API.get(`/organization/service-provider/${id}`);
      return res.data.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const createService = createAsyncThunk<ServiceCreateResponse, CreateServicePayload, { rejectValue: ErrorType }>(
  'createService',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await API.post(`/organization/service-provider`, payload);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const updateServiceById = createAsyncThunk<SuccessPayload, [string, UpdateServicePayload], { rejectValue: ErrorType }>(
  'updateServiceById',
  async ([id, payload], { rejectWithValue }) => {
    try {
      const res = await API.put(`/organization/service-provider/${id}`, payload);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const deleteServiceById = createAsyncThunk<SuccessPayload, string, { rejectValue: ErrorType }>(
  'deleteServiceById',
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await API.delete(`/organization/service-provider/${id}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

export { getServiceList, createService, updateServiceById, getServiceById, deleteServiceById };
