export function getFullAddress(params: {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}) {
  const { line1, line2, city, state, country, pincode } = params;
  let address = '';
  if (line1) address += line1 + ', ';
  if (line2) address += line2 + ', ';
  if (city) address += city + ', ';
  if (state) address += state + ', ';
  if (pincode) address += pincode + ', ';
  if (country) address += country + '.';
  return address;
}
