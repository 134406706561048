import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { GoogleMapsTypes } from './Type';
import { GOOGLE_MAPS_API_KEY, loadScript } from './helper';
import { InputLabel } from '@mui/material';
import SbTextField from '../Textfield';
import SearchIcon from '@mui/icons-material/Search';

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.

export default function GoogleMaps(props: GoogleMapsTypes) {
  const autocompleteService = React.useRef();
  const loaded = React.useRef(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = React.useState(props?.value || '');

  const { reinitialize, label, placeholder, required, onSelect, disabled } = props;

  useEffect(() => {
    if (reinitialize) setInputValue('');
  }, [reinitialize]);

  const initMap = function () {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    autocompleteService.current = new (window as any).google.maps.places.Autocomplete(inputRef.current);
    (window as any).google.maps.event.addListener(autocompleteService.current, 'place_changed', () => {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const place = (autocompleteService.current as any).getPlace();
      const { formatted_address } = place;
      setInputValue(formatted_address);
      onSelect(place);
    });
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      if (!document.querySelector('#google-maps')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          document.querySelector('head'),
          'google-maps',
        );
      }

      loaded.current = true;
    }
  }, []);

  React.useEffect(() => {
    if (!autocompleteService.current && inputRef.current && (window as any).google) initMap();
  }, [inputValue]);

  return (
    <Grid className="w-full">
      {label && (
        <InputLabel className="mb-1 font-medium sm-text" id={`google-map-demo-label`} required={required}>
          {label}
        </InputLabel>
      )}
      <SbTextField
        value={inputValue}
        onChange={event => setInputValue(event.target.value)}
        inputRef={inputRef}
        placeholder={placeholder}
        endAdornment={<SearchIcon />}
        disabled={disabled}
      />
    </Grid>
  );
}
