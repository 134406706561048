import { Grid, Typography } from '@mui/material';
import React from 'react';
import Divider from '../../../../components/Divider';
import { PricingType } from '../../../../store/reducers/GetRates/Type';

interface PropsType {
  pricing: PricingType[];
  totalPricing: number;
  taxPricing: PricingType[];
  currency: string;
}

export default function ChargesBreakup({ pricing, totalPricing, taxPricing, currency }: PropsType) {
  return (
    <Grid container rowSpacing={1}>
      <Grid container item justifyContent={'center'}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Breakup
        </Typography>
      </Grid>
      <Grid item xs={12} rowSpacing={1}>
        {pricing.map((price, ind) => (
          <Grid key={`charges-breakup-${ind}`} container item xs={12} className="mb-1">
            <Grid item xs={8}>
              {price.displayKey}
            </Grid>
            <Grid container item xs={4} justifyContent={'end'}>
              {`${currency} ${parseFloat(price?.value?.toString())?.toFixed(2)}`}
            </Grid>
          </Grid>
        ))}
        {taxPricing.map((price, ind) => (
          <Grid key={`charges-breakup-${ind}`} container item xs={12} className="mb-1">
            <Grid item xs={8}>
              {price.displayKey}
            </Grid>
            <Grid container item xs={4} justifyContent={'end'}>
              {`${currency} ${parseFloat(price?.value?.toString())?.toFixed(2)}`}
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Divider style={{ borderBottomStyle: 'dashed' }} />
      <Grid container item>
        <Grid item xs={8}>
          Total Charges
        </Grid>
        <Grid container item xs={4} justifyContent={'end'}>
          {totalPricing ? `${currency} ${parseFloat(totalPricing?.toString())?.toFixed(2)}` : ''}
        </Grid>
      </Grid>
    </Grid>
  );
}
