import { createSlice } from '@reduxjs/toolkit';
import { Rejected, Fulfilled, Pending } from './case';
import { D365State } from './Type';
import {
  sendNonAccountDataTo365,
  sendAccountDataTo365,
  sendAccountCreditDataTo365,
  sendAccountDebitDataTo365,
  sendNonAccountCreditDataTo365,
  sendNonAccountDebitDataTo365,
} from './actions';

const initialState = <D365State>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
};

const userSlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: _ => {
    const addCase = _.addCase;

    addCase(sendAccountDataTo365.pending, Pending);
    addCase(sendAccountDataTo365.fulfilled, Fulfilled);
    addCase(sendAccountDataTo365.rejected, Rejected);

    addCase(sendAccountDebitDataTo365.pending, Pending);
    addCase(sendAccountDebitDataTo365.fulfilled, Fulfilled);
    addCase(sendAccountDebitDataTo365.rejected, Rejected);

    addCase(sendAccountCreditDataTo365.pending, Pending);
    addCase(sendAccountCreditDataTo365.fulfilled, Fulfilled);
    addCase(sendAccountCreditDataTo365.rejected, Rejected);

    addCase(sendNonAccountDataTo365.pending, Pending);
    addCase(sendNonAccountDataTo365.fulfilled, Fulfilled);
    addCase(sendNonAccountDataTo365.rejected, Rejected);

    addCase(sendNonAccountDebitDataTo365.pending, Pending);
    addCase(sendNonAccountDebitDataTo365.fulfilled, Fulfilled);
    addCase(sendNonAccountDebitDataTo365.rejected, Rejected);

    addCase(sendNonAccountCreditDataTo365.pending, Pending);
    addCase(sendNonAccountCreditDataTo365.fulfilled, Fulfilled);
    addCase(sendNonAccountCreditDataTo365.rejected, Rejected);
  },
});

export {
  sendNonAccountDataTo365,
  sendAccountDataTo365,
  sendAccountCreditDataTo365,
  sendAccountDebitDataTo365,
  sendNonAccountCreditDataTo365,
  sendNonAccountDebitDataTo365,
};
export default userSlice.reducer;
