import React from 'react';
import { SnackbarProvider } from 'notistack';
import MenuContextWrapper from '../context/Menu';
import { Grow } from '@mui/material';
import { LayoutProps } from '../types';
const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      TransitionComponent={Grow}
    >
      <MenuContextWrapper>
        <div>{children}</div>
      </MenuContextWrapper>
    </SnackbarProvider>
  );
};
// https://codesandbox.io/s/vigorous-tdd-xrwxix?file=/src/ReportComplete.tsx

export default Layout;
