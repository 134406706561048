import { Grid, SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from '../../../components/DatePicker';
import { SBDropdown } from '../../../components/Dropdown';
import { DropdownOptionType } from '../../../components/Dropdown/Type';
import RadioButton from '../../../components/RadioButton';
import CreateShipmentContext from '../../../context/CreateShipment';
import {
  PickupDataErrorType,
  PickupType,
  PickupTypeListType,
  createShipmentContextType,
} from '../../../types/shipment';
// import getActiveTimeSlot from '../../../services/getActivetimeLots';
import WarehouseDropdownList from '@Components/Dropdown/Warehouse';
import useDebounce from '@Hook/useDebounce';
import { usePickupDropoffAccess } from '@Hook/usePickupDropoffAccess';
import { getWarehouseListForShipment } from '@Reducers/Warehouse';
import { checkPincodeServiceabilityForShipment } from '@Reducers/pincode/actions';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchQueryParams } from '@Hook/useSearchQueryParams';

function ShipmentPickupType(props: PickupDataErrorType) {
  const { warehouse: warehouseErr, date: dateErr, timeSlot: timeSlotErr, pincode, selectedCountry } = props;
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pickupType, setPickupType, packageData, setPackageData } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;
  const { ref } = useSearchQueryParams();

  console.info('pickupType:', pickupType);

  const { warehouseListForShipmentOptionsHashById } = useSelector((state: RootStateType) => state.Warehouse);
  const countryHashById = useSelector((state: RootStateType) => state.global.countriesObj);

  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);

  const { disablePickup, disableDropoff } = usePickupDropoffAccess({ shipperCountry: selectedCountry });

  const { pickupTimeSlots } = shipmentSetting || {};

  const { type, date, warehouse, timeSlot } = pickupType;

  const [timeSlotsOption, setTimeSlotsOption] = useState<DropdownOptionType[]>([]);

  // useEffect(() => {
  //   if (date) {
  //     setTimeSlotsOption(getActiveTimeSlot(date));
  //   }
  // }, [date]);

  useEffect(() => {
    const slots = pickupTimeSlots?.slots;
    if (slots) {
      setTimeSlotsOption(
        slots.map(({ from, to }) => {
          const isToday = dayjs(pickupType.date).isSame(dayjs(), 'day');
          const checkPastTime = dayjs(to, 'hh a').isBefore(dayjs(dayjs(), 'hh:mm a'));
          const str = `${from} - ${to}`;
          return { display: str, value: str, disable: isToday && checkPastTime };
        }),
      );
    }
  }, [pickupTimeSlots, pickupType.date]);

  function handlePickupTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setPickupType({ ...pickupType, type: value as PickupType });
  }

  function handleTimeSlotChange(
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
    _child: React.ReactNode,
  ) {
    const value = event.target.value;
    setPickupType({ ...pickupType, timeSlot: value });
  }

  function handleWarehouseChange(value: string[]) {
    const [selectedWearhouse] = value;
    if (selectedWearhouse) {
      const selectedWarehouse = warehouseListForShipmentOptionsHashById[selectedWearhouse];
      const coAttention = selectedWarehouse.coAttention;
      const [telephoneCode, number] = coAttention.contact;
      const countryId = selectedWarehouse._addressId.country;
      const warehouseAddress = {
        ...selectedWarehouse._addressId,
        country: countryHashById?.[countryId]?.name,
        name: selectedWarehouse.name,
        _countryId: countryHashById?.[countryId]?._id,
        phone: { telephoneCode, number, name: coAttention.name },
        email: coAttention.email,
      };
      setPackageData({ ...packageData, isDropOff: true });
      setPickupType({ ...pickupType, selectedWearhouseAddress: warehouseAddress, warehouse: selectedWearhouse });
    }
  }
  // const debouncedSend = useDebounce((pincode: string) => {
  //   if (pincode) dispatch(checkPincodeServiceabilityForShipment({ pincode }));
  // }, 1000);

  const debouncedSend = useDebounce(({ pincode, pickupType }: { pincode: string; pickupType: string }) => {
    if (pincode) dispatch(checkPincodeServiceabilityForShipment({ pincode }));
    if (pickupType !== 'pickUp') dispatch(getWarehouseListForShipment(pincode ? { pincode } : {}));
  }, 1000);

  useEffect(() => {
    debouncedSend({ pincode: pincode || '', pickupType: pickupType.type });
  }, [pincode, pickupType.type]);

  //TODO: Enabling the drop-off if pickup serviceability not available for this pincode
  useEffect(() => {
    if (disablePickup === true) {
      setPickupType({ ...pickupType, type: 'dropOff' as PickupType, date: ref ? dayjs() : pickupType.date });
    } else {
      setPickupType({ ...pickupType, type: 'pickUp' as PickupType, date: ref ? dayjs() : pickupType.date });
    }
  }, [disablePickup, ref]);

  const PickupTypeList: PickupTypeListType[] = useMemo(
    () => [
      { value: 'pickUp', label: 'Pickup', disabled: disablePickup === true },
      { value: 'dropOff', label: 'Drop-off', disabled: disablePickup === null },
    ],
    [disablePickup],
  );
  return (
    <>
      <Grid item md={6} xs={12}>
        <RadioButton
          containerClass="w-full"
          name="type"
          defaultValue={type}
          value={pickupType.type}
          onChange={handlePickupTypeChange}
          list={PickupTypeList}
          sx={{ display: 'flex', columnGap: 10 }}
        />
      </Grid>
      {!isMobile && <Grid item xs={0} md={6} />}
      {type === 'dropOff' && (
        <Grid item md={6} xs={12}>
          {/* <SBDropdown
            label={'Warehouse List'}
            id="warehouse"
            required
            placeholder=""
            options={[]}
            onChange={console.log}
            error={warehouseErr}
          /> */}
          <WarehouseDropdownList
            error={warehouseErr}
            value={warehouse || ''}
            disabled={disableDropoff}
            onChange={handleWarehouseChange}
            pincode={pincode}
            userId={props.userId}
            isSortingAllowed={false}
          />
        </Grid>
      )}
      {type === 'pickUp' && (
        <>
          <Grid item md={timeSlotsOption?.length ? 3 : 6} xs={6}>
            <DatePicker
              label="Date"
              value={date}
              required
              handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) =>
                setPickupType({ ...pickupType, date: date })
              }
              error={dateErr}
              minDate={dayjs()}
              disabled={disablePickup === true || !pincode}
            />
          </Grid>
          {timeSlotsOption?.length ? (
            <Grid item md={3} xs={6}>
              <SBDropdown
                label={'Time Slot'}
                id="timeSlot"
                name="timeSlot"
                isSortingAllowed={false}
                required
                placeholder="Select Time Slot"
                options={timeSlotsOption}
                value={[timeSlot]}
                onChange={handleTimeSlotChange}
                error={timeSlotErr}
                disabled={disablePickup === true || !pincode}
              />
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default ShipmentPickupType;
