import { createSlice } from '@reduxjs/toolkit';

import { getLoggedInUserKyc, updateLoggedInUserKyc, uploadLoggedInUserKyc, getUserKyc, uploadUserKyc } from './actions';
import {
  docUploadSuccessfullyModalClose,
  getLoggedInUserKycFulfilled,
  getLoggedInUserKycPending,
  getLoggedInUserKycRejected,
  getUserKycFulfilled,
  getUserKycPending,
  getUserKycRejected,
  uploadLoggedInUserKycFulfilled,
  uploadLoggedInUserKycPending,
  uploadLoggedInUserKycRejected,
} from './case';
import { StateType } from './Type';

const initialState = <StateType>{
  loading: false,
  isError: false,
  isSuccess: false,
  message: '',
  documentUploadSuccessfullyModal: false,
  kyc: {},
  adminKyc: null,
};

const slice = createSlice({
  name: 'kyc',
  initialState: { ...initialState },
  reducers: {
    docUploadSucModalClose: docUploadSuccessfullyModalClose,
  },
  extraReducers: builder => {
    const { addCase } = builder;

    addCase(getLoggedInUserKyc.fulfilled, getLoggedInUserKycFulfilled);
    addCase(getLoggedInUserKyc.rejected, getLoggedInUserKycRejected);
    addCase(getLoggedInUserKyc.pending, getLoggedInUserKycPending);

    addCase(uploadLoggedInUserKyc.fulfilled, uploadLoggedInUserKycFulfilled);
    addCase(uploadLoggedInUserKyc.rejected, uploadLoggedInUserKycRejected);
    addCase(uploadLoggedInUserKyc.pending, uploadLoggedInUserKycPending);

    addCase(uploadUserKyc.fulfilled, uploadLoggedInUserKycFulfilled);
    addCase(uploadUserKyc.rejected, uploadLoggedInUserKycRejected);
    addCase(uploadUserKyc.pending, uploadLoggedInUserKycPending);

    addCase(updateLoggedInUserKyc.fulfilled, uploadLoggedInUserKycFulfilled);
    addCase(updateLoggedInUserKyc.rejected, uploadLoggedInUserKycRejected);
    addCase(updateLoggedInUserKyc.pending, uploadLoggedInUserKycPending);

    // ----------------------------------------------------------------------------
    addCase(getUserKyc.fulfilled, getUserKycFulfilled);
    addCase(getUserKyc.rejected, getUserKycRejected);
    addCase(getUserKyc.pending, getUserKycPending);
  },
});

const { docUploadSucModalClose } = slice.actions;

export {
  docUploadSucModalClose,
  getLoggedInUserKyc,
  uploadLoggedInUserKyc,
  updateLoggedInUserKyc,
  getUserKyc,
  uploadUserKyc,
};
export default slice.reducer;
