import AccountLookup from '@Components/AccountLookup';
import Loader from '@Components/Loader';
import Title from '@Components/Title';
import useAccountLookup from '@Hook/useAccountLookup';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { userLogin } from '@Services/hasAdminAccess';
import { RootStateType } from '@Store';
import { Divider, Grid, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserDetailCard from '../../PricingList/UserDetailCard';
import TransactionCreditHistory from '../Credit';
import TransactionWalletHistory from '../Wallet';

export default function CreditsWalletHistory() {
  const modalId = 'creditAndWallet';
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  const [accountUser, setAccountUser] = useState<UserObj | null>(null);

  const {
    loading: accountLookupLoader,
    onOpen: openAccountLookupModal,
    resetSelectedUser,
    handleSetUserQuery,
  } = useAccountLookup(modalId, true);

  const getSelectedUser = (user: UserObj) => {
    setAccountUser(user);
  };

  const loading = useSelector(
    (state: RootStateType) => state.transactionHistory.loading || state.Wallet.loading || state.Credit.loading,
  );
  useEffect(() => {
    resetSelectedUser();
    openAccountLookupModal();
    handleSetUserQuery({ pageType: 'wallet-credit' });
  }, [isAdmin]);

  return (
    <Loader loading={accountLookupLoader || loading} overly>
      <>
        <Title
          title="Credit and Wallet"
          removeBorder
          subTitle="View and edit credit and wallet details for all users"
        />
        {isAdmin && (
          <>
            <Paper variant="outlined" className="rounded-md">
              <Grid container className=" p-4  mt-3 mb-4">
                <Grid item xs={12} md={6} lg={4} xl={3} className="mb-3">
                  <AccountLookup modalId={modalId} showSearchInput={true} getSelectedUser={getSelectedUser}>
                    <Title
                      title="Credit and Wallet"
                      removeBorder
                      subTitle="Select company or individual account to access credit and wallet transactions"
                    />
                  </AccountLookup>
                </Grid>
                <Grid xs={12}>
                  <Divider
                    className="mt-2 mb-4 "
                    sx={{
                      borderColor: '#EAECF0',
                    }}
                  />
                </Grid>
                {accountUser && <UserDetailCard user={accountUser} />}
              </Grid>
            </Paper>
            <Divider className="my-5" />
          </>
        )}
        {accountUser ? (
          !accountUser?._accountId?.isPrepaid ? (
            <TransactionCreditHistory user={accountUser} />
          ) : (
            <TransactionWalletHistory user={accountUser} />
          )
        ) : (
          <></>
        )}
      </>
    </Loader>
  );
}
