import * as Yup from 'yup';

const phoneSO = Yup.object().shape({
  number: Yup.string().trim().required('Phone number code is required.'),
  countryCode: Yup.string().required().trim(),
  label: Yup.string().trim().default(''),
});

// const taxInfoSO = Yup.object({
//   key: Yup.string().trim(),
//   value: Yup.string().trim(),
// });

export const CompanyTabchema = Yup.object().shape({
  _systemId: Yup.string().required('System is required').trim(),
  name: Yup.string().required().trim().min(3, 'Need to provide at least 3 character'),
  email: Yup.string().email('Invalid email address.').trim().required('Email is required'),
  contact: phoneSO,
  address: Yup.object({
    line1: Yup.string().trim().required('line 1 address is required'),
    line2: Yup.string().trim(),
    city: Yup.string().trim().required('city is required'),
    state: Yup.string().trim().default(''),
    country: Yup.string().trim().required('Country is required'),
    pincode: Yup.string().trim().required('Pincode is required'),
    _country: Yup.string().trim().required(),
  }),
  // taxInfo: Yup.array(taxInfoSO).min(1, 'at least 1 tax information').required(),
});
