import { createSlice } from '@reduxjs/toolkit';
import {
  deactivateAdminAccount,
  getAdminList,
  createAdmin,
  getAdminDetailsById,
  restoreAdminAccount,
  getAdminListForTag,
  editAdmin,
  shipmentPayByCash,
  checkUsername,
  shipmentAmtRefund,
  adminSendInvite,
} from './actions';
import { AdminState } from './Type';
import {
  checkUsernameFulfilled,
  createAdminFulfilled,
  deactivateAdminAccountFulfilled,
  editAdminFulfilled,
  getAdminDetailsByIdFulfilled,
  getAdminListForTagFulfilled,
  getAdminListFulfilled,
  pending,
  rejected,
  restoreAdminAccountFulfilled,
  shipmentAmtRefundFulfilled,
  shipmentPayByCashFulfilled,
} from './case';

const initialState = <AdminState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 0,
  },
  adminListForTag: [],
  success: '',
  adminDataById: null,
  adminCreated: false,
  adminUpdated: false,
  isUsernameTaken: null,
};

const adminReducer = createSlice({
  name: 'admin',
  initialState: { ...initialState },
  reducers: {
    resetSuccess: (state: AdminState) => {
      state.success = '';
    },
    resetAdminCreated: function (state) {
      state.adminCreated = false;
      state.error = initialState.error;
    },
    resetAdminUpdated: function (state) {
      state.adminUpdated = false;
    },
    resetUsername: function (state) {
      state.isUsernameTaken = null;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getAdminList.pending, pending);
    addCase(getAdminList.fulfilled, getAdminListFulfilled);
    addCase(getAdminList.rejected, rejected);

    addCase(getAdminListForTag.pending, pending);
    addCase(getAdminListForTag.fulfilled, getAdminListForTagFulfilled);
    addCase(getAdminListForTag.rejected, rejected);

    addCase(deactivateAdminAccount.pending, pending);
    addCase(deactivateAdminAccount.fulfilled, deactivateAdminAccountFulfilled);
    addCase(deactivateAdminAccount.rejected, rejected);

    addCase(restoreAdminAccount.pending, pending);
    addCase(restoreAdminAccount.fulfilled, restoreAdminAccountFulfilled);
    addCase(restoreAdminAccount.rejected, rejected);

    addCase(createAdmin.pending, pending);
    addCase(createAdmin.fulfilled, createAdminFulfilled);
    addCase(createAdmin.rejected, rejected);

    addCase(editAdmin.pending, pending);
    addCase(editAdmin.fulfilled, editAdminFulfilled);
    addCase(editAdmin.rejected, rejected);

    addCase(getAdminDetailsById.pending, pending);
    addCase(getAdminDetailsById.fulfilled, getAdminDetailsByIdFulfilled);
    addCase(getAdminDetailsById.rejected, rejected);

    addCase(shipmentPayByCash.pending, pending);
    addCase(shipmentPayByCash.fulfilled, shipmentPayByCashFulfilled);
    addCase(shipmentPayByCash.rejected, rejected);

    addCase(checkUsername.pending, pending);
    addCase(checkUsername.fulfilled, checkUsernameFulfilled);
    addCase(checkUsername.rejected, rejected);

    addCase(shipmentAmtRefund.pending, pending);
    addCase(shipmentAmtRefund.fulfilled, shipmentAmtRefundFulfilled);
    addCase(shipmentAmtRefund.rejected, rejected);

    addCase(adminSendInvite.pending, pending);
    addCase(adminSendInvite.fulfilled, rejected);
    addCase(adminSendInvite.rejected, rejected);
  },
});
const { resetSuccess: adminResetSuccess, resetAdminCreated, resetAdminUpdated, resetUsername } = adminReducer.actions;
export { adminResetSuccess, resetAdminCreated, resetAdminUpdated, checkUsername, shipmentAmtRefund, resetUsername };
export default adminReducer.reducer;
