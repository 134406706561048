import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormHelperText, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import VisibilityIcon from '@mui/icons-material/Visibility';

import SbTextField, { TextFieldProps } from '@Components/Textfield';
import SbButton from '@Components/Button';
import Loader from '@Components/Loader';
import SuccessError from '@Components/Modal/SuccessError';

import { AppDispatch, RootStateType } from '@Store';
import { updatePassword } from '@Reducers/loginUser/actions';

const ButtonContainer = styled(SbButton)({
  margin: '6px 0px',
  height: '48px',
  fontSize: '16px',
  letterSpacing: '0.46px',
  textTransform: 'capitalize',
});

interface resetPasswordProps {
  username: string;
}

interface ResetPasswordStateFields {
  username: string;
  oldpassword: string;
  password: string;
  repeatPassword: string;
}

const AdminResetPassword = (props: resetPasswordProps) => {
  const { username } = props;

  const dispatch = useDispatch<AppDispatch>();
  const loginUser = useSelector((state: RootStateType) => state?.loginUser);

  const [state, setState] = useState<ResetPasswordStateFields>(() => {
    return { username: username || '', oldpassword: '', password: '', repeatPassword: '' };
  });
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [viewOldPassword, setViewOldPassword] = useState<boolean>(false);
  const [viewRepeadPassword, setViewRepeadPassword] = useState<boolean>(false);
  const [passwordCheck, setPasswordCheck] = useState<boolean>(false);
  const { loading = false } = loginUser.password;
  const { oldpassword, password, repeatPassword } = state;

  const togglePasswordView = () => {
    setViewPassword(!viewPassword);
  };

  const toggleOldPasswordView = () => {
    setViewOldPassword(!viewOldPassword);
  };

  const toggleRepeatPasswordView = () => {
    setViewRepeadPassword(!viewRepeadPassword);
  };

  const confirmPassword = () => {
    const { password } = state;

    dispatch(updatePassword({ oldPassword: oldpassword, newPassword: password })).then((res: { type: string }) => {
      if (res?.type === 'loginUser/updatePassword/fulfilled') {
        window.location.reload();
      }
    });
  };

  const isValidPassword = (password: string) => {
    const newPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return newPasswordRegex.test(password);
  };

  const handleInputChange =
    (name: keyof ResetPasswordStateFields): TextFieldProps['onChange'] =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newState = { ...state };
      newState[name] = value;
      if (name === 'password') {
        setPasswordCheck(isValidPassword(value));
      }
      setState({ ...newState });
    };

  const btnDisable =
    !username || !password || !repeatPassword || password !== repeatPassword || !passwordCheck || false;

  return (
    <>
      <SuccessError />
      <Loader loading={loading}></Loader>
      <Grid item xs={10} style={{ border: '1px solid rgba(0, 0, 0, 0.1)', padding: '40px 40px 32px' }}>
        <div style={{ textAlign: 'center' }}>
          <h1 style={{ fontSize: 'revert' }}>Reset Password</h1>
          <p>Reset your temporary password by creating a new password.</p>
        </div>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <form action="">
            <SbTextField
              placeholder="Email ID/Phone no"
              label="Email ID/Phone no"
              name="username"
              value={username || ''}
              style={{ marginBottom: '10px' }}
              disabled
            />
            <SbTextField
              type={viewOldPassword ? 'text' : 'password'}
              placeholder="Enter old password"
              label="Old Password"
              name="oldpassword"
              value={oldpassword || ''}
              onChange={handleInputChange('oldpassword')}
              style={{ marginBottom: '10px' }}
              endAdornment={
                <VisibilityIcon
                  onClick={toggleOldPasswordView}
                  style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
                />
              }
            />
            <SbTextField
              type={viewPassword ? 'text' : 'password'}
              placeholder="Enter new password"
              label="New Password"
              name="password"
              value={password || ''}
              onChange={handleInputChange('password')}
              style={{ marginBottom: '10px' }}
              endAdornment={
                <VisibilityIcon
                  onClick={togglePasswordView}
                  style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
                />
              }
            />
            <FormHelperText className="xs-text" sx={{ mt: '6px', marginBottom: '0.5rem' }}>
              Your new password must be more than 8 characters, must have atleast one uppercase, one number and one
              special character
            </FormHelperText>
            <SbTextField
              // type="password"
              type={viewRepeadPassword ? 'text' : 'password'}
              placeholder="Re-enter new password"
              label="Confirm New Password"
              name="repeatPassword"
              value={repeatPassword || ''}
              onChange={handleInputChange('repeatPassword')}
              style={{ marginBottom: '10px' }}
              endAdornment={
                <VisibilityIcon
                  onClick={toggleRepeatPasswordView}
                  style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
                />
              }
            />
            {password !== repeatPassword ? <p>Repeat password does not match.</p> : ''}
            <ButtonContainer fullWidth disabled={btnDisable} variant="contained" onClick={confirmPassword}>
              Reset password
            </ButtonContainer>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminResetPassword;
