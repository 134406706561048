import React from 'react';
import ErrorModal from '@Components/Modal/error';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';

const imageType = {
  error: '/images/Boxes/NoRatesFound.svg',
  success: '/images/paid.svg',
};

function SuccessError() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const successErrorModal = useSelector((state: RootStateType) => state.successErrorModal);

  const modalMsg = successErrorModal.title || '';
  const modalSubMsg = successErrorModal.subTitle;
  const onConfirm = successErrorModal.onConfirm;
  const closeIcon = successErrorModal.closeIcon;
  const secondaryBtnText = successErrorModal.secondaryBtnText;
  const btnText = successErrorModal.btnText;

  const onClose = () => {
    dispatch(SuccessErrorModalReset());

    if (successErrorModal.navigateTo) navigate(successErrorModal.navigateTo);
  };

  return (
    <ErrorModal
      icon={imageType[successErrorModal.type] || ''}
      open={successErrorModal.open}
      title={modalMsg}
      subTitle={modalSubMsg}
      onClose={onClose}
      onConfirm={onConfirm || onClose}
      btnText={btnText || 'Close'}
      secondaryBtnText={secondaryBtnText}
      closeIcon={closeIcon}
    />
  );
}

export default SuccessError;
