import { StateType, KycType } from './Type';

// ------------------------------------------------------------------------- USER KYC DOCUMENT UPLOAD
export function docUploadSuccessfullyModalClose(state: StateType) {
  state.loading = false;
  state.documentUploadSuccessfullyModal = false;
}

export function getLoggedInUserKycFulfilled(state: StateType, data: { payload: KycType[] }) {
  state.loading = false;
  state.kyc = data.payload.reduce((op: StateType['kyc'], kyc: KycType) => ((op[kyc.name] = kyc), op), {});
}

export function getLoggedInUserKycRejected(state: StateType) {
  Object.assign(state, {
    loading: false,
    isError: true,
    isSuccess: false,
  });
}

export function getLoggedInUserKycPending(state: StateType) {
  Object.assign(state, {
    loading: true,
    isError: false,
    isSuccess: false,
  });
}

export function uploadLoggedInUserKycFulfilled(state: StateType) {
  state.loading = false;
  state.documentUploadSuccessfullyModal = true;
}

export function uploadLoggedInUserKycRejected(state: StateType) {
  Object.assign(state, {
    loading: false,
    isError: true,
    isSuccess: false,
  });
}

export function uploadLoggedInUserKycPending(state: StateType) {
  Object.assign(state, {
    loading: true,
    isError: false,
    isSuccess: false,
  });
}

// ----------------------------------------
export function getUserKycFulfilled(state: StateType, data: { payload: { userId: string; list: KycType[] } }) {
  state.loading = false;
  if (!state.adminKyc) state.adminKyc = {};
  const { userId, list } = data.payload;
  state.adminKyc[userId] = list.reduce((op: StateType['kyc'], kyc: KycType) => ((op[kyc.name] = kyc), op), {});
}

export function getUserKycRejected(state: StateType) {
  Object.assign(state, {
    loading: false,
    isError: true,
    isSuccess: false,
  });
}

export function getUserKycPending(state: StateType) {
  Object.assign(state, {
    loading: true,
    isError: false,
    isSuccess: false,
  });
}
