import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { AvailableCreditResponse, AddCreditsPayload, CreditHistoryQuery } from './Type';
import { SuccessPayload, ErrorType } from '../../../types';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const addCreditLimit = createAsyncThunk<
  SuccessPayload<AvailableCreditResponse>,
  AddCreditsPayload,
  { rejectValue: ErrorType }
>('addCreditLimit', async (body, { dispatch }) => {
  try {
    const res = await API.post(`/payment/credit`, body);
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: res.data.msg,
      }),
    );
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const getCreditLimit = createAsyncThunk('credit/current-balance', async (accountId?: string) => {
  const res = await API.get(`/payment/credit/current-balance${accountId ? `/${accountId}` : ''}`);
  return res.data;
});

const getOutstandingBalanceForPostpaid = createAsyncThunk('credit/outstanding-balance', async (accountId?: string) => {
  const res = await API.get(`/shipment/outstanding/balance/account${accountId ? `/${accountId}` : ''}`);
  return res.data;
});

const getOutstandingBalanceInvoiceForPostpaid = createAsyncThunk(
  'credit/outstanding-balance/invoice',
  async (accountId?: string) => {
    const res = await API.get(`/invoice/outstanding/balance/account${accountId ? `/${accountId}` : ''}`);
    return res.data;
  },
);

const getCreditTransactionList = createAsyncThunk(
  'getCreditTransactionList',
  async ([accountId, queryParams]: [string | null, CreditHistoryQuery]) => {
    console.log('getCreditTransactionList');
    const res = await API.get(`/payment/credit/all${accountId ? `/${accountId}` : ''}`, { params: queryParams });
    return res.data;
  },
);

export {
  addCreditLimit,
  getCreditLimit,
  getOutstandingBalanceForPostpaid,
  getCreditTransactionList,
  getOutstandingBalanceInvoiceForPostpaid,
};
