import { UnprocessedShipmentCreateResponse, UnprocessedShipmentListResponse, UnprocessedShipmentState } from './Type';

function getUnprocessedShipmentListPending(state: UnprocessedShipmentState) {
  state.loading = true;
}

function getUnprocessedShipmentListFulfilled(
  state: UnprocessedShipmentState,
  action: { payload: UnprocessedShipmentListResponse },
) {
  state.loading = false;
  state.lists = action.payload.data;
  state.totalCount = action.payload.totalCount;
  state.currentPage = action.payload.currentPage - 1;
  state.perPage = action.payload.perPage;
}

function getUnprocessedShipmentListRejected(state: UnprocessedShipmentState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function createUnprocessedShipmentPending(state: UnprocessedShipmentState) {
  state.loading = true;
}

function createUnprocessedShipmentFulfilled(
  state: UnprocessedShipmentState,
  _data: { payload: UnprocessedShipmentCreateResponse },
) {
  // state.isRedirect = true;
  state.loading = false;
}

function updateUnprocessedShipmentFulfilled(
  state: UnprocessedShipmentState,
  data: { payload: UnprocessedShipmentCreateResponse },
) {
  state.singeUnprocessedShipment = data.payload.data;
  state.loading = false;
}

function createUnprocessedShipmentRejected(state: UnprocessedShipmentState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function getUnprocessedShipmentByIdPending(state: UnprocessedShipmentState) {
  state.loading = true;
}

function getUnprocessedShipmentByIdFulfilled(
  state: UnprocessedShipmentState,
  data: { payload: UnprocessedShipmentCreateResponse },
) {
  state.singeUnprocessedShipment = data.payload.data;
  state.loading = false;
}

function getUnprocessedShipmentByIdRejected(state: UnprocessedShipmentState) {
  state.loading = false;
  state.error = { msg: 'The desired UnprocessedShipment does not exist.', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function deleteUnprocessedShipmentPending(state: UnprocessedShipmentState) {
  state.loading = true;
}

function deleteUnprocessedShipmentFulfilled(
  state: UnprocessedShipmentState,
  data: { payload: UnprocessedShipmentCreateResponse },
) {
  state.singeUnprocessedShipment = data.payload.data;
  state.loading = false;
}

function deleteUnprocessedShipmentRejected(state: UnprocessedShipmentState) {
  state.loading = false;
  state.error = { msg: 'The desired UnprocessedShipment does not exist.', isError: true };
}

export {
  getUnprocessedShipmentListPending,
  getUnprocessedShipmentListFulfilled,
  getUnprocessedShipmentListRejected,
  //
  createUnprocessedShipmentPending,
  createUnprocessedShipmentFulfilled,
  createUnprocessedShipmentRejected,
  //
  getUnprocessedShipmentByIdPending,
  getUnprocessedShipmentByIdFulfilled,
  getUnprocessedShipmentByIdRejected,
  //
  updateUnprocessedShipmentFulfilled,

  //
  deleteUnprocessedShipmentPending,
  deleteUnprocessedShipmentFulfilled,
  deleteUnprocessedShipmentRejected,
};
