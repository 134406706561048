import SbButton from '@Components/Button';
import Divider from '@Components/Divider';
import SBDropdown from '@Components/Dropdown';
import SBCountryDropdown from '@Components/Dropdown/Country';
import SbTextField from '@Components/Textfield';
import { AramexUserInformationType } from '@Reducers/AdminShipment/Type';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { OptionType } from '../../../../../CreateAccount/CarrierAccess/Type';
// import OtpModal from './OtpModal';
import { onForward } from './constants';
import { aramexInformationSchema } from './schema';
import { downloadCarrierLabel } from '@Reducers/ShipmentHistory/actions';
import DatePicker from '@Components/DatePicker';
import { getCarrierSettings } from '@Reducers/User/actions';
interface propsTypes {
  onClickForward: () => void;
  onClose?: () => void;
  initialStates: any;
  isEditable?: boolean;
  showFedExDetails: boolean;
  btnText: string;
}

const UserAramexInformation = (props: propsTypes) => {
  const { onClickForward, onClose, initialStates, isEditable, showFedExDetails, btnText } = props;
  const dispatch = useDispatch<AppDispatch>();
  // const { dhlIndia: dhlSettings } = useSelector((state: RootStateType) => state.user.carrierSetting);
  // const { shipmentById, vendersAccount, dhlOtpPostShipmentPayload } = useSelector(
  //   (state: RootStateType) => state.adminShipment,
  // );
  const { aramexDetails: adminAramexDetails } = useSelector((state: RootStateType) => state.adminShipment);
  const { shipmentById, userAramexInformationDetails: aramexSettings } = useSelector(
    (state: RootStateType) => state.shipment,
  );
  const invoiceNo = useSelector((state: RootStateType) => state.shipment.shipmentById?._packageId.invoiceNumber);
  const { values, setFieldValue, errors, handleSubmit, handleChange } = useFormik<AramexUserInformationType>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ...initialStates,
      invoiceNumber: invoiceNo || '',
    },
    validationSchema: aramexInformationSchema,
    onSubmit(values) {
      onForward({
        values,
        shipmentNumber: shipmentById?.shipmentNumber || '',
        dispatch,
        nextFunc: onClickForward,
      });
    },
  });

  useEffect(() => {
    if (shipmentById?._accountId.accountNumber) {
      dispatch(
        getCarrierSettings({
          csp: 'aramex',
          accountNumber: shipmentById?._accountId.accountNumber,
        }),
      );
    }
  }, [shipmentById?._accountId.accountNumber]);
  return (
    <>
      {showFedExDetails && (
        <Paper
          variant="outlined"
          sx={{
            my: '2rem',
            p: '2rem',
          }}
        >
          <Grid container item xs={12}>
            <Grid container item mb={3}>
              <Grid item xs={12} md={6}>
                <Typography className="font-medium l-text pb-3">Aramex Information</Typography>{' '}
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="accountNo"
                    label={`Aramex Account`}
                    required
                    placeholder="Select"
                    name="accountNo"
                    value={[values.accountNo]}
                    options={aramexSettings?.accountNo ?? []}
                    onChange={value => setFieldValue('accountNo', value[0])}
                    error={errors?.accountNo as string}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBCountryDropdown
                    disabled={!isEditable}
                    required
                    label="Country of Manufacture"
                    value={values?.countryOfManufacture}
                    options={undefined}
                    onChange={value => {
                      setFieldValue('countryOfManufacture', value[0]);
                    }}
                    error={errors?.countryOfManufacture as string}
                  />
                </Grid>
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SbTextField
                    disabled={!isEditable}
                    label={`Invoice Number`}
                    required
                    value={invoiceNo ? invoiceNo : values.invoiceNumber}
                    placeholder={`12345678`}
                    onChange={handleChange}
                    name="invoiceNumber"
                    error={errors.invoiceNumber as string}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SbTextField
                    disabled={!isEditable}
                    label={`Shipper Tax Id`}
                    required
                    placeholder="Add Shipper Tax Id"
                    onChange={handleChange}
                    name="shipperTaxId"
                    value={values.shipperTaxId}
                    error={errors.shipperTaxId as string}
                  />
                </Grid>
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    required
                    label="Invoice Date"
                    value={values?.invoiceDate || null}
                    handleChange={value => {
                      setFieldValue('invoiceDate', value);
                    }}
                    // minDate={dayjs()}
                    disabled={!isEditable}
                    error={errors.invoiceDate as string}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="productType"
                    label={`Product Type`}
                    required
                    placeholder="Select "
                    name="productType"
                    value={[values.productType]}
                    options={aramexSettings?.productType ?? []}
                    onChange={value => {
                      setFieldValue('productType', value);
                    }}
                    error={errors.productType as string}
                  />
                </Grid>
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="paymentType"
                    label={`Payment Type`}
                    required
                    placeholder="Select "
                    name="paymentType"
                    value={[values.paymentType]}
                    options={aramexSettings?.paymentType ?? []}
                    onChange={value => {
                      setFieldValue('paymentType', value);
                    }}
                    error={errors.paymentType as string}
                  />
                </Grid>
              </Grid>
              <Divider className="mt-3 mb-3" />
              <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
                <Grid container item sx={{ gap: '1rem' }}>
                  <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                    <SbButton
                      disabled={!isEditable}
                      variant="contained"
                      sx={{ borderRadius: `4px !important` }}
                      className="w-full"
                      onClick={_event => {
                        handleSubmit();
                      }}
                    >
                      {btnText}
                    </SbButton>
                  </Grid>
                  {onClose && (
                    <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                      <SbButton
                        sx={{ borderRadius: `4px !important` }}
                        className="w-full"
                        variant="outlined"
                        onClick={onClose}
                      >
                        Cancel
                      </SbButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}

      {/* {initialStates?.response && ( */}
      {adminAramexDetails?.response && (
        <Grid container item>
          <Grid
            item
            xs={'auto'}
            border={'1px solid'}
            className={`border-grey font-medium md-text p-2`}
            borderRadius={1}
          >
            This shipment has been forwarded.
            <Box
              component={'span'}
              className="font-medium md-text ml-1"
              sx={{
                color: '#485fc7',
                textDecoration: 'underline',
              }}
              onClick={() => {
                if (shipmentById?.shipmentNumber) {
                  dispatch(downloadCarrierLabel(shipmentById?.shipmentNumber));
                }
              }}
            >
              Download the carrier AWB
            </Box>
            {/* <Link
              className="font-medium md-text ml-1"
              to={`${CONFIG.API_URI}/shipment/label/getLabel?shipmentNumber=${shipmentById?.shipmentNumber}`}
              target="_blank"
              download={`${Date.now()}.pdf`}
            >
              Download the carrier AWB
            </Link> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserAramexInformation;
