import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { styled } from '@mui/system';
import { Grid, IconButton, TableBody, TableHead, TablePagination, TableRow } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import FilterSearch from '@Components/Filter/Search';
import SbButton from '@Components/Button';
import Loader from '@Components/Loader';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import DeleteModal from '@Components/Modal/Delete';

import { AppDispatch, RootStateType } from '@Store';
import { getServiceList, onChangePagination, resetCurrentPage } from '@Reducers/CarrierAndService';
import { ListQuery, ServiceObjList } from '@Reducers/CarrierAndService/Type';
import { deleteServiceById } from '@Reducers/CarrierAndService';
import Image from '@Components/Image';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const TableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 0px',
  display: 'grid',
  gridTemplateColumns: 'auto auto 145px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 0,
    gridTemplateColumns: 'auto auto 145px',
  },
}));

const SearchFilterList = [
  { label: 'Name', value: 'name' },
  { label: 'Carrier', value: 'carrier' },
];

interface ServiceTableProps {
  list: ServiceObjList[];
}

interface FilterObj {
  currentPage?: number;
  perPage?: number;
  searchKey?: string;
  searchValue?: string;
}

type DeleteServiceProviderType = null | { id: string; name: string };

function ServiceTable(props: ServiceTableProps) {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedService, _setSelectedService] = useState<{ [x: string]: boolean }>({});
  const [deleteServiceProviderId, setDeleteServiceProviderId] = useState<DeleteServiceProviderType>();
  const onDeleteRequest = (id: string, name: string) => setDeleteServiceProviderId({ id, name });

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Services.edit_service,
    PERFORM_ACTION.write,
  );

  const isDeleteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Services.delete_service,
    PERFORM_ACTION.write,
  );

  const onCloseModal = () => setDeleteServiceProviderId(null);

  const onDelete = () => {
    if (deleteServiceProviderId) dispatch(deleteServiceById(deleteServiceProviderId.id));
    setDeleteServiceProviderId(null);
  };
  return (
    <>
      <div style={{ overflowX: 'auto', width: '100%' }}>
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell width={'30%'}>Name</TableHeadCell>
              <TableHeadCell width={'30%'}>Carrier</TableHeadCell>
              <TableHeadCell width={'15%'}>Type</TableHeadCell>
              <TableHeadCell width={'15%'}>Dimensional Divisor</TableHeadCell>
              <TableHeadCell width={'10%'}>Action</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.list?.map((service, _index) => (
              <SBTableRow key={service.name} className={selectedService[service._id] ? 'selected' : ''}>
                <TableRowCell align="center" className="md-text">
                  <CarrierAndLogo logo={service.logo} name={service.name} />
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  <CarrierAndLogo logo={service?.carrierId?.logo} name={service?.carrierId?.name} />{' '}
                </TableRowCell>
                <TableRowCell align="center" className="md-text uppercase">
                  <div>{service?.service?.legs?.join(', ')}</div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  <Grid container flexDirection="column">
                    <div>{service?.dimWtDivisor?.kg} - kg </div>
                    {/* <div>{service?.dimWtDivisor?.pound} - lb</div> */}
                  </Grid>
                </TableRowCell>
                <TableRowCell align="center" className="md-text uppercase">
                  <div className="flex sb-gap-1 justify-center">
                    {isDeleteAllowed && (
                      <IconButton>
                        <DeleteIcon
                          className="sb-text-black"
                          onClick={_ => onDeleteRequest(service._id, service.name)}
                        />
                      </IconButton>
                    )}
                    {isEditAllowed && (
                      <Link to={`/carrier-and-services/edit/service/${service._id}`}>
                        <IconButton>
                          <CreateOutlinedIcon className="sb-text-black" />
                        </IconButton>
                      </Link>
                    )}
                  </div>
                </TableRowCell>
              </SBTableRow>
            ))}
          </TableBody>
        </SBTable>
      </div>
      {deleteServiceProviderId && (
        <DeleteModal
          title={`Do you want to delete ${deleteServiceProviderId.name}`}
          open={true}
          onClose={onCloseModal}
          onConfirm={onDelete}
        />
      )}
    </>
  );
}

export default function ServiceList() {
  const dispatch = useDispatch<AppDispatch>();
  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const [filterText, setFilterText] = useState('');

  const carrierAndService = useSelector((state: RootStateType) => state.carrierAndService);
  const { currentPage, totalCount, perPage } = carrierAndService;
  const serviceList = carrierAndService.serviceList;
  const loading = carrierAndService.loading;
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isCreateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Services.create_service,
    PERFORM_ACTION.write,
  );

  const navigate = useNavigate();
  // console.log('filterText ', filterText);
  const onCreate = () => {
    if (isCreateAllowed) {
      navigate('/carrier-and-services/create/service');
    }
  };

  useEffect(() => {
    dispatch(getServiceList({}));
  }, []);

  useEffect(
    function () {
      if (carrierAndService.refreshList) {
        dispatch(getServiceList({}));
      }
      dispatch(resetCurrentPage());
    },
    [carrierAndService.refreshList],
  );

  const getFilterObj = (options: FilterObj): ListQuery => {
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;

    const filterQuery: ListQuery = {
      // currentPage: options.currentPage || currentPage,
      // perPage: options.perPage || perPage,

      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    return filterQuery;
  };

  return (
    <Loader loading={loading} overly>
      <>
        <TableActionContainer>
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={SearchFilterList}
            selectValue={filterSelectValue}
            textValue={filterText}
            onTextChange={e => {
              setFilterText(e.target.value);
            }}
            onTextSearch={() => {
              dispatch(getServiceList(getFilterObj({})));
              dispatch(resetCurrentPage());
            }}
            onTextClear={() => {
              setFilterText('');
              dispatch(getServiceList(getFilterObj({ searchValue: undefined })));
              dispatch(resetCurrentPage());
            }}
          />
          <div></div>
          <SbButton
            className="xs-text ml-1"
            variant="contained"
            sx={{ borderRadius: '8px !important', padding: '8px !important' }}
            startIcon={<AddCircleIcon fontSize="small" />}
            onClick={onCreate}
            disabled={!isCreateAllowed}
          >
            Create New
          </SbButton>
        </TableActionContainer>
        <ServiceTable list={serviceList} />
        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                dispatch(onChangePagination(page));
              }}
              onRowsPerPageChange={event => {
                dispatch(getServiceList(getFilterObj({ perPage: parseInt(event.target.value) })));
              }}
            />
          </>
        )}
      </>
    </Loader>
  );
}

function CarrierAndLogo({ logo, name }: { logo: string; name: string }) {
  return (
    <Grid container display="grid" gridTemplateColumns="120px 1fr" alignItems="center" gap={2} pl={1}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        p={1}
        className="border border-1px rounded border-light bg-white"
      >
        <Image src={logo} alt={name} width={100} style={{ maxHeight: '200px' }} />
      </Grid>
      <Grid className="md-text text-left">{name}</Grid>
    </Grid>
  );
}
