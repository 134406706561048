import dayjs from 'dayjs';
import {
  BoxType,
  BulkShipmentType as BulkShipmentPayloadType,
  ProductType,
  ShipmentByIdType,
  ShipperConsigneeIdType,
  UserType,
} from '../store/reducers/Shipment/Type';

import {
  BulkShipmentType,
  PackageType,
  PickupDataType,
  ShipmentDataType,
  userDetailsType,
  GetShipmentPayloadType,
  BoxType as BoxTypePayload,
} from '../types/shipment';

export function getAddressObj(address: userDetailsType) {
  const { altContactName, altContactNo, altContactTelephoneCode } = address;
  const obj: UserType = {
    companyName: address.companyName,
    line1: address.line1,
    line2: address.line2,
    city: address.city,
    state: address.state,
    country: address.country,
    pincode: address.pincode,
    name: address.name,
    email: address.email,
    phone: {
      telephoneCode: address.telephoneCode,
      number: address.phoneNumber,
    },
  };
  if (altContactName && altContactNo && altContactTelephoneCode) {
    obj.alternatePhone = {
      telephoneCode: address.altContactTelephoneCode || '',
      number: address.altContactNo || '',
      name: address.altContactName || '',
    };
  }
  return obj;
}

export function getSingleShipmentPackagePayload({
  boxes,
  packageData,
  shipmentType,
  pickupType,
}: {
  boxes: BoxType[];
  packageData: PackageType;
  shipmentType: ShipmentDataType;
  pickupType: PickupDataType;
}): PackageType {
  const boxesTemp: BoxType[] = [...boxes];
  const packageObj: PackageType = {
    ...packageData,
    unitOfMeasurement: shipmentType.unit,
    currency: shipmentType.currency,
    invoiceNumber: shipmentType.invoiceNumber || '',
    userReferenceNumber: shipmentType.referenceNumber || '',
    isDocument: shipmentType.type === 'document',
  };
  if (pickupType?.type === 'pickUp') {
    packageObj.timeSlot = pickupType.timeSlot;
    packageObj.pickupDate = pickupType.date ? `${pickupType.date}` : null;
    packageObj.isDropOff = false;
  }
  const packageBoxes = boxesTemp.map(item => {
    const products = item.products.map((product: ProductType) => {
      const copyProduct: ProductType = { ...product };
      delete copyProduct.actions;
      delete copyProduct.id;
      delete copyProduct.totalValue;
      // product.value = product.unitValue;
      return product;
    });
    // delete item.id;
    delete item.selected;
    delete item.startCount;
    return <BoxTypePayload>{
      ...item,
      products,
    };
  });
  packageObj.box = packageBoxes;
  return packageObj;
}

export function getShipmentPayload({
  shipments,
  isDropOff,
  pickupDate,
  timeSlot,
  warehouseId,
  warehouseAddress,
}: GetShipmentPayloadType) {
  const payload: BulkShipmentPayloadType[] = [];
  shipments.map((shipment: BulkShipmentType) => {
    const boxesTemp: BoxType[] = [...shipment.package.box];
    shipment.package.box = boxesTemp.map(item => {
      const products = item.products.map((product: ProductType) => {
        delete product.actions;
        delete product.id;
        delete product.totalValue;
        product.value = product.unitValue;
        return product;
      });
      // delete item.id;
      delete item.selected;
      delete item.startCount;
      return <BoxTypePayload>{
        ...item,
        products,
      };
    });
    if (pickupDate && timeSlot) {
      shipment.package = { ...shipment.package, isDropOff, pickupDate, timeSlot };
    } else {
      shipment.package = { ...shipment.package, isDropOff };
    }
    payload.push({
      ...shipment,
      shipper: getAddressObj(shipment.shipper),
      consignee: getAddressObj(shipment.consignee),
      warehouseId,
      warehouseAddress,
    });
  });
  return payload;
}

export function getUserDetailsStateObj(data: ShipperConsigneeIdType) {
  const { phone, alternatePhone, name, companyName, line1, line2, city, state, country, pincode, email } = data;
  const temp: userDetailsType = {
    name,
    companyName: companyName ? companyName : '',
    line1,
    line2: line2 ? line2 : '',
    city,
    state,
    country,
    pincode,
    phoneNumber: phone.number,
    telephoneCode: phone.telephoneCode,
    altContactTelephoneCode: alternatePhone?.telephoneCode ?? '',
    altContactNo: alternatePhone?.number ?? '',
    email,
    altContactName: alternatePhone?.name ?? '',
  };
  return temp;
}

export function getBoxStateObj(box: BoxType[]) {
  return box.length
    ? box.map(({ length, width, height, weight, products, boxCopyCount }, ind) => ({
        length,
        width,
        height,
        weight,
        id: ind.toString(),
        products,
        selected: ind == 0,
        boxCopyCount,
      }))
    : [];
}

export function getPackageStateObj(data: ShipmentByIdType['_packageId']) {
  const {
    unitOfMeasurement,
    packaging,
    insurance,
    isDocument,
    isCod,
    isDropOff,
    pickupDate,
    timeSlot,
    codAmount,
    currency,
    box,
    userReferenceNumber,
    invoiceNumber,
  } = data;
  const temp: PackageType = {
    unitOfMeasurement,
    packaging,
    insurance,
    isDocument,
    isCod,
    isDropOff,
    pickupDate: pickupDate ? dayjs(pickupDate) : null,
    timeSlot,
    codAmount,
    currency,
    box: [...getBoxStateObj(box)],
    totalBoxWeight: 'Need calc',
    invoiceNumber: invoiceNumber || '',
    userReferenceNumber: userReferenceNumber || '',
  };
  return temp;
}

export function getPickupStateObj(data: ShipmentByIdType['_packageId']) {
  const { isDropOff, pickupDate, timeSlot } = data;
  const temp: PickupDataType = {
    type: isDropOff ? 'dropOff' : 'pickUp',
    date: isDropOff ? undefined : dayjs(pickupDate),
    timeSlot: isDropOff ? '' : timeSlot,
  };
  return temp;
}

export function getShipmentTypeStateObj(data: ShipmentByIdType) {
  const temp: ShipmentDataType = {
    numberOfBoxes: data._packageId.box.length.toString(),
    shipmentMode: data.shipmentMode,
    currency: data._packageId.currency,
    type: data._packageId.isDocument ? 'document' : 'parcel',
    unit: data._packageId.unitOfMeasurement,
    invoiceNumber: '',
    referenceNumber: '',
  };
  return temp;
}
