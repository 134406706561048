import * as Yup from 'yup';

const addressSO = {
  line1: Yup.string().trim().required('line 1 address is required'),
  line2: Yup.string().trim(),
  city: Yup.string().trim().required('city is required'),
  state: Yup.string().trim().required('state is required'),
  country: Yup.string().trim().required('Country is required'),
  pincode: Yup.string().trim().required('Pincode is required'),
};

const taxInformation = {
  name: Yup.string().trim().required(),
  value: Yup.string().trim().required(),
};

const passwordRegEx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
const passwordValidate = Yup.string()
  .required()
  .trim()
  .min(8, 'Password is too short - should be 8 chars minimum.')
  .matches(passwordRegEx, 'Password must have at least 1 Uppercase, 1 lowercase, and 1 number');

const urlRegex =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

export const OrgUpdateSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  contactInfo: Yup.object({
    address: Yup.object(addressSO).required(),
    contactNumber: Yup.string().trim().required('Contact number is required'),
    telephoneCode: Yup.string().trim().required('Telephone Code is required'),
    email: Yup.string()
      .trim()
      .required('Email is required')
      .matches(/^[^\s@]{1,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
    supportEmail: Yup.string()
      .email()
      .trim()
      .required('Support email is required')
      .matches(/^[^\s@]{1,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
  }).default({ telephoneCode: '' }),
  websiteLink: Yup.string().trim().matches(urlRegex, 'URL is not valid').default(''),
  logoFile: Yup.object({
    fileName: Yup.string().trim().required(),
    fileExtension: Yup.string().trim().required(),
    fileData: Yup.string().trim().required(),
  })
    .nullable()
    .default(null),
  taxInformation: Yup.array(Yup.object(taxInformation)),
  termsAndCondition: Yup.string().trim().required('Terms And Condition is required.'),
  privacyPolicy: Yup.string().trim().required('Privacy Policy is required.'),
});

export const OrgOnboardSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required'),
  contactInfo: Yup.object({
    address: Yup.object(addressSO).required(),
    contactNumber: Yup.string().trim().required('Contact number is required'),
    telephoneCode: Yup.string().trim().required('Telephone Code is required'),
    email: Yup.string()
      .email()
      .trim()
      .required('Email is required')
      .matches(/^[^\s@]{1,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
    supportEmail: Yup.string()
      .email()
      .trim()
      .required('Support email is required')
      .matches(/^[^\s@]{1,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
  }).default({ telephoneCode: '' }),
  websiteLink: Yup.string().trim().matches(urlRegex, 'URL is not valid').default(''),
  logo: Yup.object({
    fileName: Yup.string().trim().required(),
    fileExtension: Yup.string().trim().required(),
    fileData: Yup.string().trim().required(),
  }).required(),
  // username: Yup.string().required('User name is required'),
  email: Yup.string()
    .email()
    .trim()
    .required('Email is required')
    .matches(/^[^\s@]{1,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
  password: passwordValidate,
  taxInformation: Yup.array(Yup.object(taxInformation)),
});
