import { createSlice } from '@reduxjs/toolkit';

import {
  setLoginForm,
  doLoginPending,
  doLoginFulfilled,
  doLoginRejected,
  doLoginWithOtpPending,
  doLoginWithOtpFulfilled,
  doLoginWithOtpRejected,
  // resetLoginForm,
  doLogoutPending,
  doLogoutFulfilled,
  doLogoutRejected,
  setResetRequired,
  closeResetRequired,
} from './case';
import { doLogin, doLoginWithOtp, verifyLoginOtp, doLogout } from './actions';
import { loginFormStateType } from './type';

const initialState = <loginFormStateType>{
  value: { userId: null, email: null, phone: null, password: null },
  user: { user: { token: '', name: '' } },
  error: {},
  loading: false,
  resetRequired: false,
};
const loginFormSlice = createSlice({
  name: 'loginform',
  initialState: { ...initialState },
  reducers: {
    setLoginState: setLoginForm,
    resetLoginState: function (state) {
      state.value = { userId: null, email: null, phone: null, password: null };
      state.user = null;
      state.error = {
        msg: '',
        isError: false,
      };
      state.loading = false;
      state.resetRequired = false;
    },
    setToResetRequired: setResetRequired,
    unsetResetRequired: closeResetRequired,
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(doLogin.pending, doLoginPending);
    addCase(doLogin.fulfilled, doLoginFulfilled);
    addCase(doLogin.rejected, doLoginRejected);
    addCase(doLogout.pending, doLogoutPending);
    addCase(doLogout.fulfilled, doLogoutFulfilled);
    addCase(doLogout.rejected, doLogoutRejected);

    addCase(doLoginWithOtp.pending, doLoginWithOtpPending);
    addCase(doLoginWithOtp.fulfilled, doLoginWithOtpFulfilled);
    addCase(doLoginWithOtp.rejected, doLoginWithOtpRejected);
  },
});

export const { setLoginState, resetLoginState, setToResetRequired, unsetResetRequired } = loginFormSlice.actions;

export { doLogin, doLoginWithOtp, verifyLoginOtp, doLogout };
export default loginFormSlice.reducer;
