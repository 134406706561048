import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
  style?: React.CSSProperties;
  width?: number;
  height?: number;
  className?: string;
}

function Image(props: ImageProps): JSX.Element {
  const { src, alt, width, height, style, ...otherProps } = props;
  return <img src={src} alt={alt} width={width} height={height} style={style} {...otherProps} />;
}

export default Image;
