import React from 'react';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import SortIcon from '../../../public/images/icons/AdminHome/sortIcon.svg';
import Image from '@Components/Image';
import { formatNumber, useLocale } from '../../utils/formatNumber';

interface Destination {
  name: string;
  flagURI: string;
  totalShipment: number;
  totalRevenue: number;
  totalWeight: number;
  shipmentPercentage: number;
  weightPercentage: number;
  revenuePercentage: number;
  _id: string;
}

interface TableStyle {
  fontFamily: string;
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
  color: string;
}

const tableStyle: TableStyle = {
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: `16px`,
  lineHeight: `20px`,
  color: '#181D25',
};

interface Props {
  data: Destination[];
  setDestinationShipmentSort?: any;
  destinationRevenueSort: boolean;
  destinationWeightSort: boolean;
  destinationShipmentSort: boolean;
  setDestinationWeightSort?: any;
  setDestinationRevenueSort?: any;
}

const TopTenDestinationsTable: React.FC<Props> = ({
  data,
  setDestinationShipmentSort,
  setDestinationWeightSort,
  setDestinationRevenueSort,
  destinationShipmentSort,
  destinationWeightSort,
  destinationRevenueSort,
}) => {
  const handleShipmentSort = () => {
    setDestinationShipmentSort((prev: any) => !prev);
    setDestinationWeightSort(false);
    setDestinationRevenueSort(false);
  };
  const handleWeightSort = () => {
    setDestinationWeightSort((prev: any) => !prev);
    setDestinationShipmentSort(false);
    setDestinationRevenueSort(false);
  };
  const handleRevenueSort = () => {
    setDestinationRevenueSort((prev: any) => !prev);
    setDestinationWeightSort(false);
    setDestinationShipmentSort(false);
  };
  const locale = useLocale();

  return (
    <Box sx={{ padding: 0 }}>
      <TableContainer sx={{ border: 'none', width: '100%', boxShadow: 'none' }} component={Paper}>
        <Table sx={{ border: 'none' }}>
          <TableHead>
            <TableRow sx={{ borderRight: 'none' }}>
              <TableCell sx={{ ...tableStyle }}>Name</TableCell>
              <TableCell
                onClick={handleShipmentSort}
                sx={{
                  ...tableStyle,
                  cursor: 'pointer',
                  color: destinationShipmentSort ? '#03A01C' : '',
                  fontWeight: destinationShipmentSort ? 600 : 400,
                  textAlign: 'center',
                }}
              >
                Shipments
                {/* <Image src={SortIcon} alt="Sort Icon" /> */}
              </TableCell>

              <TableCell
                onClick={handleWeightSort}
                sx={{
                  ...tableStyle,
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  color: destinationWeightSort ? '#03A01C' : '',
                  fontWeight: destinationWeightSort ? 600 : 400,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Weight(Kg)
                  {/* <Image src={SortIcon} alt="Sort Icon" /> */}
                </Box>
              </TableCell>
              <TableCell
                onClick={handleRevenueSort}
                sx={{
                  ...tableStyle,
                  textAlign: 'center',
                  cursor: 'pointer',
                  color: destinationRevenueSort ? '#03A01C' : '',
                  fontWeight: destinationRevenueSort ? 600 : 400,
                }}
              >
                Revenue(INR)
                {/* <Image src={SortIcon} alt="Sort Icon" /> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((destination: Destination) => (
              <TableRow key={destination._id}>
                <TableCell sx={{ ...tableStyle, color: '#929FB1' }}>
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Box>
                      <img src={destination.flagURI} alt={`${destination.name} flag`} />
                    </Box>
                    <Box>{destination.name}</Box>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', color: '#929FB1' }}>
                  <Box>
                    <Box>
                      <Typography sx={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>
                        {destination.totalShipment}{' '}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',
                          backgroundColor: destination.shipmentPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: destination.shipmentPercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            destination.shipmentPercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(destination.shipmentPercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', color: '#929FB1' }}>
                  <Box>
                    <Box sx={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>
                      <Typography sx={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>
                        {formatNumber(destination.totalWeight, locale)}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',
                          backgroundColor: destination.weightPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: destination.weightPercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            destination.weightPercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(destination.weightPercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', color: '#929FB1' }}>
                  <Box sx={{ marginLeft: '' }}>
                    <Box>
                      <Typography sx={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>
                        {formatNumber(destination.totalRevenue, locale)}{' '}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',
                          backgroundColor: destination.revenuePercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: destination.revenuePercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            destination.revenuePercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(destination.revenuePercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TopTenDestinationsTable;
