import { TableRowCell } from '@Components/Table/TableCell';
import { Grid, styled, Typography } from '@mui/material';

export const FormLabel = styled(Typography)`
  min-width: 280px;
`;

export const FormContent = styled('div')`
  max-width: 512px;
`;

export const SBTableRowCell = styled(TableRowCell)`
  font-size: 16px;
  text-align: center;
  width: 20%;
`;

export const CalendarContainer = styled(Grid)(({ theme }) => ({
  minHeight: '500px',
  paddingBottom: '10px',
  '& .rbc-month-view': {
    height: 'auto',
  },
  '& .rbc-header': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    fontSize: '95%',
    // backgroundColor: theme.palette.background.,
    textTransform: 'uppercase',
  },
  '& .rbc-toolbar': {
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    '& button': {
      cursor: 'pointer',
    },
    '& .rbc-toolbar-label': {
      padding: '0 10px 15px',
      textTransform: 'uppercase',
      fontWeight: '550',
    },
  },
  '& .rbc-event-content': {
    minHeight: '20px',
    fontSize: '15px',
  },
  '& .rbc-row-content > .rbc-row:nth-child(1)': {
    position: 'absolute',
    left: '0',
    right: '0',
    zIndex: '1',
    top: '40%',
    justifyContent: 'space-around',
    height: '0',
  },
  '& .rbc-row-content > .rbc-row:nth-child(2)': {
    height: '100%',
    width: '100%',
  },
  '& .rbc-row-content': {
    position: 'relative',
    zIndex: '4',
    height: '100%',
  },
  '& .rbc-date-cell': {
    textAlign: 'center',
  },
  '& .rbc-event': {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    opacity: '0.4',
    height: '100%',
    borderRadius: 'unset',
  },
  '& .rbc-off-range': {
    color: theme.palette.background.black,
    filter: 'contrast(0.3)',
  },
  '& .rbc-today': {
    backgroundColor: 'unset',
    borderColor: `${theme.palette.primary.main} !important`,
    boxSizing: 'border-box',
    border: '2px solid',
    flex: '0.99',
    '&::before': {
      content: "''",
      position: 'absolute',
      border: `10px solid ${theme.palette.primary.main}`,
      borderRightColor: 'transparent',
      borderBottomColor: 'transparent',
    },
  },
  '& .rbc-selected': {
    opacity: '0.6',
  },
}));
