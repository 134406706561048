import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Checkbox, Grid, Stack, TableBody, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import Accord from '@Components/Accordion';
import SbButton from '@Components/Button';
// import ExportBtn from '@Components/Button/Export';
import DateRangePicker from '@Components/DateRangePicker';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import SbTextField from '@Components/Textfield';
import Title from '@Components/Title';
import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { getSystemList } from '@Reducers/Systems';
import { AppDispatch, RootStateType } from '@Store';
import { AccountHolderInvoiceTableProps, AccountHolderUnbilledData, FilterObj } from '../Type';
// ICONS
import DatePicker from '@Components/DatePicker';
import Image from '@Components/Image';
import Loader from '@Components/Loader';
import { ShipmentObj } from '@Reducers/Invoice/Type';
import { InvoiceCreditDebitPayload } from '@Reducers/InvoiceList/Type';
import { getAccountUnbilledDebitList } from '@Reducers/InvoiceList/actions';
import { sendAccountDebitDataTo365 } from '@Reducers/d365';
import dayjs, { Dayjs } from 'dayjs';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues, useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { calculateTotalAmount } from '../temp';
import API from '@Services/apiAxios';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { getShipmentWebsocketStream } from '@Reducers/AdminShipment/actions';
import { formatNumber, useLocale } from '../../../utils/formatNumber';

//Types
type selectedParamType = {
  accountNumber: string;
  _systemId: string;
  systemName: string;
  shipments: ShipmentObj[];
  totalAmount: number;
  invoiceNumber: number;
  _userId?: string;
};

type selectedInvoices = { [x: string]: boolean };

type StepThreeProps = {
  selectedAccountRows: selectedParamType[];
  selectedInvoices: selectedInvoices;
  list: AccountHolderUnbilledData[];
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<InvoiceCreditDebitPayload>['setFieldValue'];
  errors?: FormikErrors<InvoiceCreditDebitPayload>;
  onInvoiceDateChange: (date: Dayjs) => void;
  onDueDateChange: (date: Dayjs) => void;
};

type StepTwoProps = {
  selectedInvoices: selectedInvoices;
  setSelectedUnbilledShipment: (obj: selectedParamType) => void;
  list: AccountHolderUnbilledData[];
  selectedUnbilledRow: selectedParamType[];
};

type AccordionTableProps = {
  data: AccountHolderUnbilledData;
  setSelectedUnbilledShipment: (obj: selectedParamType) => void;
  selectedUnbilledRow: selectedParamType[];
};

// Define the Invoice schema
const InvoiceSchema = yup.object().shape({
  invoiceNo: yup.string().required('Invoice number is required'),
  shipmentIds: yup.array().of(yup.string()).required('Shipment IDs are required'),
});

// Define the InvoiceData schema
const InvoiceDataSchema = yup.object().shape({
  invoice: yup.array().of(InvoiceSchema).required('Invoice details are required'),
  dueDate: yup.string().required('Due date is required'),
  invoiceDate: yup.string().required('Invoice date is required'),
  remarks: yup.string(),
});

const initialState: InvoiceCreditDebitPayload = {
  invoice: [],
  dueDate: '',
  invoiceDate: '',
  remarks: '',
};

const SearchFilterList = [
  { label: 'Account No.', value: 'account_no' },
  { label: 'Invoice Number', value: 'invoice_no' },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 0px',
  display: 'grid',
  gridTemplateColumns: '390px 320px 1fr 140px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: '10px',
    gridTemplateColumns: '390px 320px 1fr 140px',
  },

  [theme.breakpoints.down('lg')]: {
    columnGap: '10px',
    gridTemplateColumns: '320px 1fr',
  },

  [theme.breakpoints.down('md')]: {
    columnGap: '5px',
    gridTemplateColumns: '1fr',
    rowGap: '15px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '16px 0px',
    gridTemplateColumns: '1fr',
    rowGap: '10px',
  },
}));

// const StepTwoTableActionContainer = styled('div')(({ theme }) => ({
//   padding: '32px 16px',
//   display: 'grid',
//   gridTemplateColumns: '510px 140px',
//   columnGap: '15px',
//   [theme.breakpoints.down('xl')]: {
//     padding: '32px 0px',
//     columnGap: 0,
//     gridTemplateColumns: '490px 140px',
//   },
// }));

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date', width: 130, cellClassName: 'md-text', headerClassName: 'md-text' },
  {
    field: 'shipmentNo',
    headerName: 'Shipment No',
    width: 130,
    renderCell: (params: GridValueGetterParams) => (
      <Link to={`/shipment/admin/edit/${params?.value}`} className="sb-text-black underline">
        {params?.value}
      </Link>
    ),
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'status',
    headerName: 'Status',
    // type: 'number',
    width: 160,
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'shipper',
    headerName: 'Shipper',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 180,
    renderCell: (params: GridValueGetterParams) => (
      <Box>
        <p className="font-medium capitalize">{params?.value?.name}</p>
        <p>
          {params?.value?.city}-{params?.value?.pincode}
        </p>
        <p>{params?.value?.country}</p>
      </Box>
    ),
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'consignee',
    headerName: 'Consignee',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 180,
    renderCell: (params: GridValueGetterParams) => (
      <Box>
        <p className="font-medium capitalize">{params?.value?.name}</p>
        <p>
          {params?.value?.city}-{params?.value?.pincode}
        </p>
        <p>{params?.value?.country}</p>
      </Box>
    ),
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'weight',
    headerName: 'Weight',
    width: 160,
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'packages',
    headerName: 'Packages',
    width: 160,
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'totalAmount',
    headerName: 'Total Amount (INR)',
    width: 160,
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
];

interface FilterType {
  currentPage: number;
  perPage: number;
  fromDate: string;
  toDate: string;
  systems: string[];
  searchKey?: string;
  searchValue?: string;
}

interface Invoice {
  invoiceNo: string;
  shipmentIds: string[];
  // shipmentNumbers: string[];
}

interface Data {
  invoice: Invoice[];
  dueDate?: string;
  invoiceDate: string;
  remarks: string;
}

function InvoiceTable(props: AccountHolderInvoiceTableProps) {
  const { list = [], selectedInvoices, checkAllInvoices, onSelectInvoice } = props;
  const locale = useLocale();

  return (
    <SBTable>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            <Checkbox
              style={{ color: 'inherit' }}
              checked={
                list?.length > 0 &&
                (selectedInvoices ? Object.keys(selectedInvoices).length : 0) === (list ? list.length : 0)
              }
              onChange={checkAllInvoices}
            />
          </TableHeadCell>
          <TableHeadCell>Invoice No.</TableHeadCell>
          <TableHeadCell>Account No.</TableHeadCell>
          <TableHeadCell>Bill To</TableHeadCell>
          <TableHeadCell>No of Shipments</TableHeadCell>
          <TableHeadCell>System</TableHeadCell>
          <TableHeadCell>Total Amount</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.length ? (
          list.map((data, i) => (
            <SBTableRow key={i} className={selectedInvoices[i] ? 'selected' : ''}>
              <TableRowCell align="center">
                <div>
                  <Checkbox
                    style={{ color: 'inherit' }}
                    onChange={e => onSelectInvoice(i, e.target.checked)}
                    checked={!!selectedInvoices[i]}
                  />
                </div>
              </TableRowCell>
              <TableRowCell align="center" className="md-text uppercase">
                {data.invoiceNumber}
              </TableRowCell>
              <TableRowCell align="center" className="md-text uppercase">
                {data.account?.accountNumber}
              </TableRowCell>
              <TableRowCell align="center" className="md-text capitalize">
                <p>{data?.company ? data?.company?.name : data?.user ? data?.user?.name : '-'}</p>{' '}
                <p className="text-[#AFAFAF]">
                  {data?.company ? data?.company?.email : data?.user ? data?.user?.email : '-'}
                </p>
              </TableRowCell>
              <TableRowCell
                sx={{
                  div: {
                    textAlign: 'center',
                  },
                }}
                align="center"
                className="md-text"
              >
                {data.shipments.length}
              </TableRowCell>
              <TableRowCell align="center" className="md-text">
                {data?.system?.name}
              </TableRowCell>
              <TableRowCell
                sx={{
                  div: {
                    textAlign: 'center',
                  },
                }}
                align="center"
                className="md-text"
              >
                {data?.totalInvoiceAmount && formatNumber(data?.totalInvoiceAmount, locale)}
              </TableRowCell>
            </SBTableRow>
          ))
        ) : (
          <SBTableRow>
            <TableRowCell colSpan={7}>
              <Typography className="sb-text-gray font-medium text-center">No Data Found.</Typography>
            </TableRowCell>
          </SBTableRow>
        )}
      </TableBody>
    </SBTable>
  );
}

export default function InvoiceList() {
  // console.log('Debit Creates');
  const [filterSelectValue, setFilterSelectValue] = useState('account_no');
  const [filterText, setFilterText] = useState('');
  const [step, setStep] = useState(1);
  const [system, setSystem] = useState<string[]>([]);
  const [invoiceDate, setInvoiceDate] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [selectedInvoices, setSelectedInvoices] = useState<selectedInvoices>({});
  const [selectedAccountRows, setSelectedAccountRows] = useState<selectedParamType[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const SystemList = useSelector((state: RootStateType) => state.System.data.selectedList).map(system => ({
    display: system.name,
    value: system._id,
  }));
  const _systems = useSelector((state: RootStateType) => state.loginUser?.profile?._systems);
  const activeSystemList = useMemo(() => {
    if (_systems?.length) return SystemList.filter(system => _systems.includes(system.value));
    else return [];
  }, [SystemList, _systems]);
  const {
    unBilledList: { list, currentPage = 1, perPage, totalCount },
    loading,
  } = useSelector((state: RootStateType) => state.InvoiceList);

  useEffect(() => {
    const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
    const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
    dispatch(getAccountUnbilledDebitList({ currentPage: 1, perPage: 10, fromDate, toDate }));
    dispatch(getSystemList());
  }, []);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(getAccountUnbilledDebitList(getFilterObj({ dateRange })));
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    dispatch(getAccountUnbilledDebitList(getFilterObj({ system: selected })));
  };

  const getFilterObj = (options: FilterObj) => {
    setSelectedInvoices({});
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterSystem = options.system || system;
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filter: FilterType = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      systems: filterSystem,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };

    // Remove searchKey and searchValue if searchValue is falsy
    if (!filter.searchValue) {
      delete filter.searchKey;
      delete filter.searchValue;
    }

    return filter;
  };

  const onSelectInvoice = function (invoiceId: number, isChecked: boolean) {
    const newSelectedInvoice = { ...selectedInvoices };
    if (isChecked) newSelectedInvoice[invoiceId] = true;
    else delete newSelectedInvoice[invoiceId];
    setSelectedInvoices(newSelectedInvoice);
  };

  const checkAllInvoices = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newInvoiceCheckedList = list.reduce((op: { [k: string]: boolean }, _, index) => {
        op[index] = true;
        return op;
      }, {});
      setSelectedInvoices(newInvoiceCheckedList);
    } else {
      setSelectedInvoices({});
    }
  };

  const setSelectedUnbilledShipment = (_params: selectedParamType) => {
    const { accountNumber, _systemId, shipments } = _params;
    let newObj = [...selectedAccountRows];
    newObj = newObj.filter(
      item =>
        item.accountNumber !== accountNumber ||
        item._systemId !== _systemId ||
        item.invoiceNumber !== _params.invoiceNumber,
    );
    if (shipments?.length) {
      newObj.push(_params);
    }
    setSelectedAccountRows(newObj);
  };

  // const isCreateDisabled = () => {
  //   if (!Object.values(selectedInvoices)?.length) return true;
  //   return false;
  // };

  const nextStep = () => {
    if (step === 3) return;
    setStep(step + 1);
  };
  const preStep = () => {
    if (step <= 0) return;
    setStep(step - 1);
    if (step === 2) {
      setSelectedAccountRows([]);
    }
  };
  const isPrevDisabled = () => {
    if (step <= 1) return true;
    return false;
  };

  function convertDateFormat(dateStr: string): string {
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month}-${day}`;
  }

  const { handleSubmit, ...formik } = useFormik<InvoiceCreditDebitPayload>({
    initialValues: {
      ...initialState,
      invoice: selectedAccountRows?.map(row => ({
        invoiceNo: row?.invoiceNumber,
        shipmentIds: row?.shipments?.map((item: ShipmentObj) => item?._id),
      })),
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      console.log(values);
      const result: Invoice[] = selectedAccountRows.map(
        (item: any): Invoice => ({
          invoiceNo: item.invoiceNumber,
          shipmentIds: item.shipments.map((shipment: any) => shipment._id),
        }),
      );

      const customPayload: Data = {
        invoice: result,
        invoiceDate: convertDateFormat(invoiceDate),
        dueDate: convertDateFormat(dueDate),
        remarks: formik?.values?.remarks || '',
      };
      API.post('/invoice/debitnote', { ...customPayload })
        .then(res => {
          // console.info(res.data.msg);
          // Dispatch success modal
          dispatch(
            SuccessErrorModalOpen({
              type: 'success',
              title: 'Success',
              subTitle: res.data.msg,
            }),
          );
          // Navigate to the invoice view
          navigate('/invoice/view');
        })
        .catch(error => {
          // Dispatch error modal
          dispatch(
            SuccessErrorModalOpen({
              type: 'error',
              title: 'Oops!',
              subTitle: 'Something went wrong.',
            }),
          );
          // Throw the error to handle it further if needed
          throw error;
        });

      const accountData = (selectedAccountRows as any).map((item: selectedParamType) => ({
        _shipmentNumbers: item.shipments.map(s => s.shipmentNumber),
      }));

      const dataForStream = accountData.map((el: any) => el._shipmentNumbers).flat();
      dispatch(getShipmentWebsocketStream(['shipment-update-' + dataForStream]));

      // console.log('resp--->', resp);
      // dispatch(
      //   SuccessErrorModalOpen({
      //     type: 'success',
      //     title: 'Success',
      //     subTitle: 'DebitNote created successfully.',
      //   }),
      // );
      // navigate('/invoice/view');

      // const payload = {
      //   ...values,
      //   dueDate: values?.dueDate,
      //   invoiceDate: values?.invoiceDate,
      //   comment: values?.remarks,
      // };
      // dispatch(createInvoiceCreditDebitNote(payload)).then(res => {
      //   if (res.type === 'createInvoiceCreditDebitNote/fulfilled') {
      //     navigate('/invoice/view');
      //   }
      // });
    },
    validationSchema: InvoiceDataSchema,
  });

  useEffect(() => {
    console.log(selectedInvoices, list, 'Hello', selectedAccountRows);
  });
  const handleInvoiceDateChange = (e: Dayjs) => {
    const date = e.toDate();
    console.info(moment(date).format('DD/MM/YYYY'), 'date');
    setInvoiceDate(moment(date).format('DD/MM/YYYY'));
  };

  const handleDueDateChange = (e: Dayjs) => {
    const date = e.toDate();
    setDueDate(moment(date).format('DD/MM/YYYY'));
  };

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Box
          onClick={() => {
            if (step === 1) {
              navigate('/invoice/view');
            } else if (step === 2) {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
          sx={{ zIndex: '1', cursor: 'pointer' }}
        >
          <Image src="/images/backbutton.svg" alt="Back Button" style={{ width: '1.5rem', marginTop: '0.2rem' }} />
        </Box>
        <Title title="Invoices" subTitle="View and create invoices" />
      </Box>
      <div className="flex" style={{ marginTop: '52px', marginBottom: '25px' }}>
        <Title removeBorder title="Create Debit Note" titleClass="underline px_20-text" />
        {step === 1 &&
          // <ExportBtn
          //   onClick={() => {
          //     console.log('Export Unbilled Debit Clicked');
          //   }}
          //   iconSrc="/images/file_download.svg"
          //   alt="Export"
          //   label="Export"
          // />
          null}
      </div>
      {step === 1 && (
        <>
          <TableActionContainer>
            <FilterSearch
              onSelectChange={(value: string) => {
                setFilterSelectValue(value);
              }}
              list={SearchFilterList}
              selectValue={filterSelectValue}
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={() => {
                dispatch(getAccountUnbilledDebitList(getFilterObj({})));
              }}
              onTextClear={() => {
                setFilterText('');
                dispatch(getAccountUnbilledDebitList(getFilterObj({ searchValue: undefined })));
              }}
            />
            <DateRangePicker onChange={onDateChange} value={dateRange} />
            <div></div>
            <FilterSelect
              id="system"
              label="System"
              value={system}
              options={activeSystemList}
              onChange={onSystemFilter}
            />
          </TableActionContainer>
          <div
            style={{
              display: 'block',
              width: '100%',
              overflowX: 'auto',
              overflowY: 'hidden',
              maxWidth: '100%',
            }}
          >
            <div
              style={{
                minWidth: '600px',
              }}
            >
              <InvoiceTable
                checkAllInvoices={checkAllInvoices}
                onSelectInvoice={onSelectInvoice}
                selectedInvoices={selectedInvoices}
                list={list}
              />
            </div>
            {totalCount ? (
              <>
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={currentPage - 1}
                  rowsPerPage={perPage}
                  onPageChange={(_, page) => {
                    dispatch(getAccountUnbilledDebitList(getFilterObj({ currentPage: page + 1 })));
                  }}
                  onRowsPerPageChange={event => {
                    dispatch(getAccountUnbilledDebitList(getFilterObj({ perPage: parseInt(event.target.value) })));
                  }}
                />
              </>
            ) : (
              ''
            )}
          </div>
        </>
      )}
      {step === 2 && (
        <StepTwo
          selectedInvoices={selectedInvoices}
          setSelectedUnbilledShipment={setSelectedUnbilledShipment}
          list={list}
          selectedUnbilledRow={selectedAccountRows}
        />
      )}
      {step === 3 && (
        <StepThree
          selectedInvoices={selectedInvoices}
          list={list}
          selectedAccountRows={selectedAccountRows}
          {...formik}
          {...formik}
          onInvoiceDateChange={handleInvoiceDateChange}
          onDueDateChange={handleDueDateChange}
        />
      )}

      <Grid container item mt={2} justifyContent="flex-end" spacing={4}>
        <Grid item xs={12} sm={3} md={2}>
          <SbButton variant="outlined" fullWidth className="rounded-full" onClick={preStep} disabled={isPrevDisabled()}>
            Back
          </SbButton>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <SbButton
            variant="contained"
            fullWidth
            className="rounded-full"
            onClick={
              step === 3
                ? () => {
                    handleSubmit();
                  }
                : nextStep
            }
            // disabled={isCreateDisabled() || (step === 2 && !selectedAccountRows?.length) || step === 3}
            disabled={false}
          >
            {step === 3 ? 'Create' : 'Next'}
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

function StepTwo(props: StepTwoProps) {
  const { selectedInvoices, setSelectedUnbilledShipment, selectedUnbilledRow, list } = props;
  const selectedShipmentsData = Object.keys(selectedInvoices).map((i: string) => list[Number(i)]);

  return (
    <>
      {selectedShipmentsData?.map(s => (
        <AccordionTable
          data={s}
          setSelectedUnbilledShipment={setSelectedUnbilledShipment}
          selectedUnbilledRow={selectedUnbilledRow?.filter(item => item?.invoiceNumber === s?.invoiceNumber)}
        />
      ))}
    </>
  );
}

function StepThree(props: StepThreeProps) {
  const { handleChange, errors, values, selectedAccountRows, setFieldValue, onInvoiceDateChange, onDueDateChange } =
    props;
  const { loading: loadingD365 } = useSelector((state: RootStateType) => state?.D365);
  const dispatch = useDispatch<AppDispatch>();
  const [isWyngsMy, setIsWyngsMy] = useState<boolean>(false);

  const sendTo365 = () => {
    const accountData = (selectedAccountRows as any).map((item: selectedParamType) => ({
      accountNo: item.accountNumber,
      _systemId: item._systemId,
      _shipmentIds: item.shipments.map(s => s._id),
      _userId: item?._userId,
      _shipmentNumbers: item.shipments.map(s => s.shipmentNumber),
    }));
    dispatch(
      sendAccountDebitDataTo365({
        accountData,
        // dueDate: values.dueDate,
        // invoiceDate: values.invoiceDate,
        dueDate: dayjs(values.dueDate).format('YYYY-MM-DD'),
        invoiceDate: dayjs(values.invoiceDate).format('YYYY-MM-DD'),
        comment: values?.remarks,
      }),
    );
  };

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    // setOrganisationNameRedirect(subdomain);
    // console.info('subdomain:', subdomain);
    if (subdomain === 'wyngs-my') {
      setIsWyngsMy(true);
    } else {
      setIsWyngsMy(false);
    }
  }, []);

  // console.info('isWyngsMy', isWyngsMy);

  return (
    <>
      <Loader loading={loadingD365} />
      <Grid container gap={2}>
        <Grid item md={5} mt={'3.8rem'}>
          <Grid container gap={3}>
            <Grid item xs={5}>
              <DatePicker
                label="Invoice Date"
                value={values.invoiceDate}
                handleChange={(date: Dayjs) => {
                  onInvoiceDateChange(date);
                  setFieldValue('invoiceDate', date);
                  setFieldValue('dueDate', null);
                }}
                required
                error={errors?.invoiceDate}
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                label="Due Date"
                required
                value={values.dueDate}
                handleChange={(date: Dayjs) => {
                  onDueDateChange(date);
                  setFieldValue('dueDate', date);
                }}
                minDate={dayjs(values?.dueDate)}
                error={errors?.dueDate}
              />
            </Grid>
          </Grid>
          <Grid container mt={3}>
            <Grid item md={11}>
              <SbTextField
                // placeholder="01/01/2023"
                label="Remark"
                name="remarks"
                multiline
                rows={4}
                value={values.remarks}
                onChange={handleChange}
                error={errors?.remarks}
              />
            </Grid>
            <Grid item md={11} mt={3}>
              <div style={{ display: 'flex', marginTop: '10px' }}>
                {!isWyngsMy && (
                  <SbButton
                    className="rounded-full"
                    variant="contained"
                    sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                    onClick={() => {
                      sendTo365();
                    }}
                    disabled={!(values.invoiceDate && values.dueDate)}
                  >
                    Send to D365
                  </SbButton>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6.8}>
          <Grid container justifyContent={'space-between'} alignItems={'center'} my={'1rem'}>
            <Grid item xs="auto">
              <Box pb={'0.5rem'} borderBottom={'4px solid #080808'} width={'170px'}>
                <Title removePadding removeBorder title="Preview Debit Notes" titleClass=" px_16-text" />
              </Box>
            </Grid>
            <Grid item xs="auto" justifyContent={'flex-end'} container>
              <Title
                removePadding
                removeBorder
                title={'No. of Debit Notes : ' + selectedAccountRows?.length}
                titleClass=" px_16-text"
              />
            </Grid>
          </Grid>
          <SBTable>
            <TableHead>
              <TableRow>
                <TableHeadCell>Sr. No.</TableHeadCell>
                <TableHeadCell>Invoice No.</TableHeadCell>
                <TableHeadCell>System</TableHeadCell>
                <TableHeadCell>No of Shipments</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedAccountRows.map((data, index) => (
                <SBTableRow key={index}>
                  <TableRowCell
                    sx={{
                      div: {
                        textAlign: 'center',
                      },
                    }}
                    align="center"
                  >
                    {index + 1}
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text uppercase">
                    {data.invoiceNumber}
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text capitalize">
                    <p>{data.systemName}</p>
                  </TableRowCell>
                  <TableRowCell
                    sx={{
                      div: {
                        textAlign: 'center',
                      },
                    }}
                    align="center"
                    className="md-text"
                  >
                    {data.shipments?.length}
                  </TableRowCell>
                </SBTableRow>
              ))}
            </TableBody>
          </SBTable>
        </Grid>
      </Grid>
    </>
  );
}

function AccordionTable(props: AccordionTableProps) {
  const {
    data: { shipments, system, totalInvoiceAmount = 0, account, user, invoiceNumber = 0, company },
    setSelectedUnbilledShipment,
    selectedUnbilledRow,
  } = props;
  const locale = useLocale();

  const rows = shipments.map((s, i: number) => {
    return {
      id: i + 1,
      date: moment(s.createdAt).format('DD/MM/YYYY'),
      shipmentNo: s.shipmentNumber,
      status: SHIPMENT_STATUS[s.status]?.label,
      shipper: s._shipperId,
      consignee: s._consigneeId,
      weight: s?.sellingPricing?.displayChargeableWeight + 'kg',
      packages: s?._packageId?.box?.length,
      totalAmount: calculateTotalAmount(s, 'debit'),
      isDisabled: s.carrierDetails && s.carrierDetails?.awb,
    };
  });

  const summary = () => {
    return (
      <Stack direction={'row'} justifyContent={'space-between'} pr={'1rem'}>
        <Stack gap={'10px'}>
          <Stack direction={'row'} gap={'2rem'}>
            <Box
              sx={{
                minWidth: '5rem',
              }}
              className="md-text"
            >
              {company ? company?.name : user ? user?.name : '-'}
            </Box>
            <p className="md-text  has-text-grey-light">{company ? company?.email : user ? user?.email : '-'}</p>
          </Stack>
          <Stack direction={'row'} gap={'2rem'}>
            <p className="md-text font-semibold">{account?.accountNumber}</p>
            <p className="md-text">{system?.name}</p>
            <p className="md-text">Invoice No:{invoiceNumber}</p>
            <p className="md-text">Shipments({shipments?.length})</p>
          </Stack>
        </Stack>
        <Box
          sx={{
            justifySelf: 'flex-end',
            alignSelf: 'center',
            fontWeight: 600,
            color: 'primary.main',
          }}
          className="md-text"
        >
          Total Amount: INR {formatNumber(totalInvoiceAmount, locale)}
        </Box>
      </Stack>
    );
  };
  const selectShipment = (selectedRows: Array<number>) => {
    // console.log('selected ', selectedRows);
    const shipmentRow = shipments.filter((_, i) => selectedRows.includes(i + 1));
    const obj = {
      accountNumber: account?.accountNumber || '',
      _systemId: system?._id || '',
      shipments: shipmentRow,
      totalAmount: totalInvoiceAmount,
      systemName: system?.name || '',
      invoiceNumber,
      _userId: user?._id,
    };

    setSelectedUnbilledShipment(obj);
  };

  const details = () => {
    return (
      <>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          rowHeight={90}
          rowSelectionModel={selectedUnbilledRow?.flatMap((item: selectedParamType) =>
            item?.shipments?.map(ship => {
              const index = shipments.findIndex(s => s?._id === ship?._id);
              return index !== -1 ? index + 1 : -1;
            }),
          )}
          checkboxSelection
          onRowSelectionModelChange={selectShipment}
        />
      </>
    );
  };
  return (
    <>
      <Accord summary={summary()} details={details()} />
    </>
  );
}
