import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Checkbox, Grid, Stack, TableBody, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import Accord from '@Components/Accordion';
import SbButton from '@Components/Button';
// import ExportBtn from '@Components/Button/Export';
import DatePicker from '@Components/DatePicker';
import DateRangePicker from '@Components/DateRangePicker';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import SbTextField from '@Components/Textfield';
import Title from '@Components/Title';

import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { createAccountInvoice } from '@Reducers/Invoice';
import { getInvoiceList } from '@Reducers/InvoiceList';
import { getSystemList } from '@Reducers/Systems';
import { sendAccountDataTo365 } from '@Reducers/d365';
import { AppDispatch, RootStateType } from '@Store';
// import { sendAccountDataTo365 } from '@Reducers/Invoice';
import { getInvoiceSettings } from '@Reducers/OrganizationSetup';

import { AccountHolderInvoiceTableProps, AccountHolderUnbilledData, FilterObj } from '../Type';

function convertToBoolean(input: string[]): boolean | null {
  if (input.length === 1) {
    const value = input[0].toLowerCase();
    if (value === 'true') return true;
    if (value === 'false') return false;
  }
  return null; // Return null if input is invalid
}

// ICONS

import SBDropdown from '@Components/Dropdown';
import Image from '@Components/Image';
import { Loader } from '@Components/Loader';
import { ShipmentObj } from '@Reducers/Invoice/Type';
import formatDate from '../../../utils/formatDate';
import { formatNumber, useLocale } from '../../../utils/formatNumber';

const SearchFilterList = [
  { label: 'Account No.', value: 'account_no' },
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
];
// const StepTwoSearchFilterList = [{ label: 'Shipment No.', value: 'shipment_no' }];
const billToOptions = [
  { display: 'Customer', value: 'customer' },
  { display: 'Third Party', value: 'third_party' },
];

const ShipmentTypeList = [
  {
    value: 'import',
    display: 'Import',
  },
  {
    value: 'export',
    display: 'Export',
  },
  {
    value: 'domestic',
    display: 'Domestic',
  },
  // {
  //   value: 'hyperlocal',
  //   display: 'Hyperlocal',
  // },
  // {
  //   value: 'out-import',
  //   display: 'Out Import',
  // },
  // {
  //   value: 'out-export',
  //   display: 'Out Export',
  // },
  // {
  //   value: 'out-international',
  //   display: 'Out International',
  // },
  // {
  //   value: 'out-domestic',
  //   display: 'Out Domestic',
  // },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  margin: '2rem 0',
  display: 'grid',
  gridTemplateColumns: '380px 1fr  fit-content(150px) fit-content(150px) fit-content(150px) 150px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    columnGap: '8px',
    gridTemplateColumns: '380px 1fr  fit-content(150px) fit-content(150px) fit-content(150px) 120px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

function InvoiceTable(props: AccountHolderInvoiceTableProps) {
  const { list, selectedInvoices, checkAllInvoices, onSelectInvoice } = props;
  const [organisationName, setOrganisationName] = useState<string>();
  const locale = useLocale();

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    setOrganisationName(subdomain);
  }, []);

  console.log(organisationName);

  return (
    <SBTable>
      <TableHead>
        <TableRow
          sx={{
            th: {
              textAlign: 'center !important',
            },
          }}
        >
          <TableHeadCell align="center">
            <Checkbox
              style={{ color: 'inherit' }}
              checked={
                list?.length > 0 &&
                (selectedInvoices ? Object.keys(selectedInvoices).length : 0) ===
                  (list ? list.filter(item => !item?.canGenerateInvoice).length : 0)
              }
              onChange={checkAllInvoices}
            />
          </TableHeadCell>
          <TableHeadCell align="center">Account No.</TableHeadCell>
          <TableHeadCell align="center">Bill To</TableHeadCell>
          <TableHeadCell align="center">No of Shipments</TableHeadCell>
          <TableHeadCell align="center">System</TableHeadCell>
          <TableHeadCell align="center">Total Amount</TableHeadCell>
          <TableHeadCell align="center">Shipment Type</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((data, i) => (
          <SBTableRow key={i} className={selectedInvoices[i] ? 'selected' : ''}>
            <TableRowCell align="center">
              <Checkbox
                // disabled={organisationName === 'wyngs-my' ? false : !data?.canGenerateInvoice}
                style={{ color: 'inherit' }}
                sx={{
                  '&.MuiCheckbox-colorPrimary.Mui-disabled': {
                    color: 'grey !important',
                  },
                }}
                onChange={e => onSelectInvoice(i, e.target.checked)}
                checked={!!selectedInvoices[i]}
              />
            </TableRowCell>
            <TableRowCell
              sx={{
                div: {
                  textAlign: 'center',
                },
              }}
              align="center"
              className="md-text uppercase"
            >
              {data.accountNumber}
            </TableRowCell>
            <TableRowCell
              sx={{
                div: {
                  textAlign: 'start',
                  ml: 2,
                  // justifyContent: 'center',
                },
              }}
              align="center"
              className="md-text capitalize"
            >
              <p>{data?.company ? data?.company?.name : data?.user ? data?.user?.name : '-'}</p>{' '}
              <p>{data?.company ? data?.company?.email : data?.user ? data?.user?.email : '-'}</p>
            </TableRowCell>
            <TableRowCell
              sx={{
                div: {
                  textAlign: 'center',
                },
              }}
              align="center"
              className="md-text"
            >
              {data.shipments?.length}
            </TableRowCell>
            <TableRowCell
              sx={{
                div: {
                  textAlign: 'center',
                },
              }}
              align="center"
              className="md-text"
            >
              {data._systemId?.name}
            </TableRowCell>
            <TableRowCell
              sx={{
                div: {
                  textAlign: 'center',
                },
              }}
              align="center"
              className="md-text"
            >
              {formatNumber(parseFloat(data.totalAmount), locale)}
            </TableRowCell>
            <TableRowCell
              sx={{
                div: {
                  textAlign: 'center',
                },
              }}
              className="md-text"
              align="center"
            >
              {data.shipmentRouteType}
            </TableRowCell>
          </SBTableRow>
        ))}
      </TableBody>
    </SBTable>
  );
}

interface FilterType {
  currentPage: number;
  perPage: number;
  fromDate: string;
  toDate: string;
  searchKey?: string;
  searchValue: string | undefined;
  systems: string[];
  type: string[];
}
type selectedInvoices = { [x: string]: boolean };
type invConfig = {
  invoiceDate?: Dayjs | null;
  dueDate?: Dayjs | null;
  taxBreakdown?: string[];
  splitTax?: string[];
  sacCode?: string[];
  billTo?: string[];
  billname?: string;
  billaddress?: string;
  taxname?: string;
  taxno?: string;
};
type selectedParamType = {
  accountNumber: string;
  shipmentRouteType: string;
  _systemId: string;
  systemName: string;
  shipments: ShipmentObj[];
  uniqueKey?: string;
  _userId?: string;
};
const initialDateRange = {
  startDate: moment().subtract(3, 'months').startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
};
export default function InvoiceList() {
  // console.log('Invoice Creates');
  const [filterSelectValue, setFilterSelectValue] = useState('account_no');
  const [filterText, setFilterText] = useState('');
  const [step, setStep] = useState(1);
  const [isClear, setClear] = useState(false);
  const [system, setSystem] = useState<string[]>([]);
  const [shipmentType, setShipmentType] = useState<string[]>([]);
  const [invConfig, setInvConfig] = useState<invConfig>({
    invoiceDate: null,
    dueDate: null,
    billTo: [],
  });
  const [dateRange, setDateRange] = useState(initialDateRange);

  const [selectedInvoices, setSelectedInvoices] = useState<selectedInvoices>({});
  const [selectedUnbilledRow, setSelectedUnbilledRow] = useState<AccountHolderUnbilledData[]>([]);
  const [selectedAccountRows, setSelectedAccountRows] = useState<selectedParamType[]>([]);
  const [organisationName, setOrganisationName] = useState<string>();
  console.log(organisationName);

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    setOrganisationName(subdomain);
  }, []);

  const [isWyngsMy, setIsWyngsMy] = useState<boolean>(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    // setOrganisationNameRedirect(subdomain);
    // console.info('subdomain:', subdomain);
    if (subdomain === 'wyngs-my') {
      setIsWyngsMy(true);
    } else {
      setIsWyngsMy(false);
    }
  }, []);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const SystemList = useSelector((state: RootStateType) => state.System.data.selectedList).map(system => ({
    display: system.name,
    value: system._id,
  }));
  const _systems = useSelector((state: RootStateType) => state.loginUser?.profile?._systems);
  const activeSystemList = useMemo(() => {
    if (_systems?.length) return SystemList.filter(system => _systems.includes(system.value));
    else return [];
  }, [SystemList, _systems]);
  const {
    unBilledList: { list, currentPage = 1, perPage, totalCount },
    loading,
  } = useSelector((state: RootStateType) => state.InvoiceList);
  const { invoiceSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const { taxInformation = [] } = invoiceSetting || {};
  const taxInformationOptions = taxInformation.map(s => ({ display: s.display, value: s.value, key: s.key }));

  function getTaxBreakDownObject(keys: any, data: any[]) {
    for (const key of keys) {
      const matchedItem = data.find((item: any) => item.key === key);

      if (matchedItem) {
        return {
          display: matchedItem.display,
          value: matchedItem.value,
          key: matchedItem.key,
        };
      }
    }
  }

  useEffect(() => {
    const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
    const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
    dispatch(getInvoiceList({ currentPage: 1, perPage: 10, fromDate, toDate }));
    dispatch(getSystemList());
  }, []);

  useEffect(() => {
    if (isClear) dispatch(getInvoiceList(getFilterObj({})));
    return () => {
      setClear(false);
    };
  }, [isClear]);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(getInvoiceList(getFilterObj({ dateRange })));
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    dispatch(getInvoiceList(getFilterObj({ system: selected })));
  };

  const onTypeFilter = (selected: Array<string>) => {
    setShipmentType(selected);
    dispatch(getInvoiceList(getFilterObj({ shipmentType: selected })));
  };

  const getFilterObj = (options: FilterObj) => {
    setSelectedInvoices({});
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterSystem = options.system || system;
    const filterType = options.shipmentType || shipmentType;
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filter: FilterType = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
      systems: filterSystem,
      type: filterType,
    };

    if (!filterSearchValue) {
      delete filter.searchKey;
      delete filter.searchValue;
    }

    return filter;
  };

  const onClear = () => {
    setSystem([]);
    setShipmentType([]);
    setFilterText('');
    setDateRange(initialDateRange);
    setClear(true);
  };

  // console.info('isWyngsMy', isWyngsMy);

  const onSelectInvoice = function (invoiceId: number, isChecked: boolean) {
    const newSelectedInvoice = { ...selectedInvoices };
    if (isChecked) newSelectedInvoice[invoiceId] = true;
    else delete newSelectedInvoice[invoiceId];
    const markedRow = [...list][invoiceId];
    let newSelectedRow = [...selectedUnbilledRow];
    if (isChecked) {
      newSelectedRow.push({ ...markedRow, shipments: [] });
    } else {
      newSelectedRow = newSelectedRow.filter(
        item =>
          item.accountNumber !== markedRow.accountNumber ||
          item.shipmentRouteType !== markedRow.shipmentRouteType ||
          item._systemId?._id !== markedRow._systemId?._id,
      );
    }
    setSelectedUnbilledRow(newSelectedRow);
    setSelectedInvoices(newSelectedInvoice);
  };
  const checkAllInvoices = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newInvoiceCheckedList = list.reduce((op: { [k: string]: boolean }, item, index) => {
        if (item?.canGenerateInvoice) {
          op[index] = true;
        }
        return op;
      }, {});
      setSelectedInvoices(newInvoiceCheckedList);
    } else {
      setSelectedInvoices({});
      setSelectedUnbilledRow([]);
    }
  };
  const setSelectedUnbilledShipment = (_params: selectedParamType) => {
    const { accountNumber, shipmentRouteType, _systemId, shipments } = _params;

    let newObj = [...selectedAccountRows];
    newObj = newObj.filter(
      item =>
        item.accountNumber !== accountNumber ||
        item.shipmentRouteType !== shipmentRouteType ||
        item._systemId !== _systemId,
    );

    if (shipments?.length) {
      newObj.push(_params);
    }

    setSelectedAccountRows(newObj);
  };

  const createUnbilledInvoice = () => {
    const { sacCode, taxBreakdown, dueDate, invoiceDate, billTo, billaddress, billname, taxname, taxno, splitTax } =
      invConfig;
    console.info('splitTax:', splitTax);

    if (isWyngsMy) {
      if (!invoiceDate || !dueDate) {
        return;
      }
    } else {
      if (
        !sacCode ||
        !taxBreakdown ||
        // !splitTax ||
        !dueDate ||
        !invoiceDate ||
        !selectedAccountRows ||
        (billTo?.[0] === 'third_party' ? !(billaddress && taxname) : false)
      ) {
        return;
      }
    }

    const accountData = (selectedAccountRows as any).map((item: selectedParamType) => ({
      accountNo: item.accountNumber,
      shipmentRouteType: item.shipmentRouteType,
      _systemId: item._systemId,
      _shipmentIds: item.shipments.map(s => s._id),
      _shipmentNumbers: item.shipments.map(s => s.shipmentNumber),
    }));

    const payload = {
      dueDate: formatDate(dueDate),
      invoiceDate: formatDate(invoiceDate),
      tax: getTaxBreakDownObject(taxBreakdown, taxInformationOptions),
      sacCode: sacCode?.[0],
      accountData,
      splitTax: convertToBoolean(splitTax as string[]),
      billToType: '',
      thirdPartyInfo: {},
    };
    if (billTo?.[0] === 'third_party') {
      payload.billToType = billTo[0];
      payload.thirdPartyInfo = {
        name: billname,
        address: billaddress,
        taxInfoList: [{ key: taxname, value: taxno }],
      };
    }

    dispatch(createAccountInvoice(payload)).then(res => {
      if (res.type === 'createAccountInvoice/fulfilled') {
        navigate('/invoice/view');
      }
    });
  };

  // const isCreateDisabled = () => {
  //   if (step !== 3) return false;
  //   const { sacCode, taxBreakdown, dueDate, invoiceDate, billTo, billaddress, billname } = invConfig;
  //   if (
  //     !sacCode ||
  //     !taxBreakdown ||
  //     !dueDate ||
  //     !invoiceDate ||
  //     (billTo?.[0] === 'third_party' ? !(billaddress && billname) : false)
  //   )
  //     return true;
  //   return false;
  // };

  const nextStep = () => {
    if (step === 3) return;
    setStep(step + 1);
  };
  const preStep = () => {
    if (step <= 0) return;
    setStep(step - 1);
    if (step === 2) {
      setSelectedAccountRows([]);
    }
  };
  const isPrevDisabled = () => {
    if (step <= 1) return true;
    return false;
  };

  const isNextDisabled = () => {
    if (step === 1 && Object.keys(selectedInvoices).length === 0) return true;
    return false;
  };
  // console.log('selectedInvoice ', selectedInvoices);
  // console.log('selectedAccountRows ', selectedAccountRows);
  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
        <Box
          onClick={() => {
            if (step === 1) {
              navigate('/invoice/view');
            } else if (step === 2) {
              setStep(1);
            } else {
              setStep(2);
            }
          }}
          sx={{ zIndex: '1', cursor: 'pointer' }}
        >
          <Image src="/images/backbutton.svg" alt="Back Button" style={{ width: '1.5rem', marginTop: '0.2rem' }} />
        </Box>
        <Title title="Invoices" subTitle="View and create invoices" />
      </Box>
      <div className="flex" style={{ marginTop: '52px', marginBottom: '25px' }}>
        <Title title="Create Invoices" removeBorder removePadding titleClass="underline px_20-text" />
        {step === 1 &&
          // <ExportBtn
          //   onClick={() => {
          //     console.log('Export Unbilled Invoice Clicked');
          //   }}
          //   iconSrc="/images/file_download.svg"
          //   alt="Export"
          //   label="Export"
          // />
          null}
      </div>
      {step === 1 && (
        <>
          <TableActionContainer>
            <FilterSearch
              onSelectChange={(value: string) => {
                setFilterSelectValue(value);
              }}
              list={SearchFilterList}
              selectValue={filterSelectValue}
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={() => {
                dispatch(getInvoiceList(getFilterObj({})));
              }}
              onTextClear={() => {
                setFilterText('');
                dispatch(getInvoiceList(getFilterObj({ searchValue: undefined })));
              }}
            />
            <DateRangePicker onChange={onDateChange} value={dateRange} />
            <div></div>
            <FilterSelect
              id="system"
              label="System"
              value={system}
              options={activeSystemList}
              onChange={onSystemFilter}
            />
            <FilterSelect
              id="shipmenttype"
              label="Shipment Type"
              value={shipmentType}
              options={ShipmentTypeList}
              onChange={onTypeFilter}
            />
            <SbButton
              className="xs-text ml-1"
              variant="outlined"
              sx={{ borderRadius: '8px !important', padding: '8px !important' }}
              onClick={onClear}
            >
              Clear Filters
            </SbButton>
          </TableActionContainer>
          <Box my={'2rem'} pb={'0.5rem'} borderBottom={'4px solid #080808'} width={'220px'}>
            <Typography className="md-text" fontWeight={700}>
              Select Unbilled Shipments
            </Typography>
          </Box>
          <div
            style={{
              display: 'block',
              width: '100%',
              overflowX: 'auto',
              overflowY: 'hidden',
              maxWidth: '100%',
            }}
          >
            <div
              style={{
                minWidth: '600px',
              }}
            >
              <InvoiceTable
                checkAllInvoices={checkAllInvoices}
                onSelectInvoice={onSelectInvoice}
                selectedInvoices={selectedInvoices}
                list={list}
              />
            </div>
            {totalCount ? (
              <>
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={currentPage - 1}
                  rowsPerPage={perPage}
                  onPageChange={(_, page) => {
                    dispatch(getInvoiceList(getFilterObj({ currentPage: page + 1 })));
                  }}
                  onRowsPerPageChange={event => {
                    dispatch(getInvoiceList(getFilterObj({ perPage: parseInt(event.target.value) })));
                  }}
                />
              </>
            ) : (
              ''
            )}
          </div>
        </>
      )}
      {step === 2 && (
        <StepTwo
          selectedInvoices={selectedInvoices}
          setSelectedUnbilledShipment={setSelectedUnbilledShipment}
          list={list}
          selectedUnbilledRow={selectedAccountRows}
        />
      )}
      {step === 3 && (
        <StepThree
          selectedAccountRows={selectedAccountRows}
          selectedUnbilledRow={selectedUnbilledRow}
          list={list}
          invConfig={invConfig}
          setInvConfig={setInvConfig}
          selectedInvoices={selectedInvoices}
        />
      )}

      <Grid container item mt={2} justifyContent="flex-end" spacing={4}>
        <Grid item xs={12} sm={3} md={2}>
          <SbButton variant="outlined" fullWidth className="rounded-full" onClick={preStep} disabled={isPrevDisabled()}>
            Back
          </SbButton>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <SbButton
            variant="contained"
            fullWidth
            // disabled={
            //   isCreateDisabled() ||
            //   (step === 1 && !Object.values(selectedInvoices)?.length) ||
            //   (step === 2 && !selectedAccountRows?.length)
            // }
            // disabled={!(invConfig.invoiceDate && invConfig.dueDate)}
            className="rounded-full"
            onClick={step === 3 ? createUnbilledInvoice : nextStep}
            disabled={isNextDisabled()}
          >
            {step === 3 ? 'Create' : 'Next'}
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

type StepTwoProps = {
  selectedInvoices: selectedInvoices;
  setSelectedUnbilledShipment: (obj: selectedParamType) => void;
  list: AccountHolderUnbilledData[];
  selectedUnbilledRow: selectedParamType[];
};

function StepTwo(props: StepTwoProps) {
  const { selectedInvoices, list, setSelectedUnbilledShipment, selectedUnbilledRow } = props;
  const selectedShipmentsData = Object.keys(selectedInvoices).map((i: string) => list[Number(i)]);
  return (
    <>
      <Box my={'2rem'} pb={'0.5rem'} borderBottom={'4px solid #080808'} width={'220px'}>
        <Typography className="md-text" fontWeight={700}>
          Select Unbilled Shipments
        </Typography>
      </Box>
      {selectedShipmentsData?.map(s => (
        <AccordionTable
          data={s}
          setSelectedUnbilledShipment={setSelectedUnbilledShipment}
          selectedUnbilledRow={selectedUnbilledRow?.filter(item => item?.uniqueKey === s?.uniqueKey)}
        />
      ))}
    </>
  );
}

type StepThreeProps = {
  selectedAccountRows: selectedParamType[];
  selectedUnbilledRow: AccountHolderUnbilledData[];
  selectedInvoices: selectedInvoices;
  list: AccountHolderUnbilledData[];
  invConfig: invConfig;
  setInvConfig: React.Dispatch<React.SetStateAction<invConfig>>;
};
function StepThree(props: StepThreeProps) {
  const { selectedAccountRows, invConfig, setInvConfig } = props;
  const { invoiceSetting, loading } = useSelector((state: RootStateType) => state?.orgSetup);
  const { loading: loadingD365 } = useSelector((state: RootStateType) => state?.D365);
  const dispatch = useDispatch<AppDispatch>();
  const [isWyngsMy, setIsWyngsMy] = useState<boolean>(false);

  const { sacCode = [], taxInformation = [] } = invoiceSetting || {};
  const sacCodeOptions = sacCode.map(s => ({ display: s.display, value: s.key }));
  const taxInformationOptions = taxInformation.map(s => ({ display: s.display, value: s.key }));
  const noOfInvoices = selectedAccountRows?.length || 0;

  useEffect(() => {
    dispatch(getInvoiceSettings());
  }, []);

  const sendTo365 = () => {
    const accountData = (selectedAccountRows as any).map((item: selectedParamType) => ({
      _userId: item?._userId,
      accountNo: item.accountNumber,
      _systemId: item._systemId,
      _shipmentIds: item.shipments.map(s => s._id),
      _shipmentNumbers: item.shipments.map(s => s.shipmentNumber),
    }));
    dispatch(
      sendAccountDataTo365({
        accountData,
        dueDate: dayjs(invConfig.dueDate).format('YYYY-MM-DD'),
        invoiceDate: dayjs(invConfig.invoiceDate).format('YYYY-MM-DD'),
      }),
    );
  };

  // console.log(selectedAccountRows);
  // console.log({ selectedUnbilledRow });

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    // setOrganisationNameRedirect(subdomain);
    // console.info('subdomain:', subdomain);
    if (subdomain === 'wyngs-my') {
      setIsWyngsMy(true);
    } else {
      setIsWyngsMy(false);
    }
  }, []);

  const splitTaxOptions = [
    {
      display: 'Split Tax',
      value: 'true',
    },
    {
      display: 'Full Tax',
      value: 'false',
    },
  ];

  return (
    <>
      <Loader loading={loading || loadingD365} />

      <Grid container>
        <Grid item md={5} container gap={2} mt={'3.8rem'}>
          <Grid item md={9} container columnSpacing={4}>
            <Grid item md={6}>
              <DatePicker
                // placeholder="01/01/2023"
                label="Invoice Date"
                // name="invoiceDate"
                value={invConfig.invoiceDate}
                handleChange={(date: Dayjs) => setInvConfig({ ...invConfig, invoiceDate: date, dueDate: null })}
                // minDate={dayjs()}
                required
                // disabled={pincode ? disablePickup : false}
              />
            </Grid>
            <Grid item md={6}>
              <DatePicker
                // placeholder="01/01/2023"
                label="Due Date"
                // name="dueDate"
                required
                value={invConfig.dueDate}
                handleChange={(date: Dayjs) => setInvConfig({ ...invConfig, dueDate: date })}
                minDate={dayjs(invConfig.invoiceDate)}
                // disabled={pincode ? disablePickup : false}
              />
            </Grid>
          </Grid>
          <Grid item md={9}>
            <SBDropdown
              label="Select Taxation"
              id="taxBreakdown"
              required={isWyngsMy ? false : true}
              placeholder="Select"
              options={taxInformationOptions || []}
              onChange={value => {
                setInvConfig({ ...invConfig, taxBreakdown: value });
              }}
              name="taxBreakdown"
              value={invConfig.taxBreakdown || []}
            />
          </Grid>
          <Grid item md={9}>
            <SBDropdown
              label="SAC Code"
              id="sacCode"
              required={isWyngsMy ? false : true}
              placeholder="Select"
              options={sacCodeOptions || []}
              onChange={value => {
                setInvConfig({ ...invConfig, sacCode: value });
              }}
              name="sacCode"
              value={invConfig.sacCode || []}
            />
          </Grid>
          <Grid item md={9}>
            <SBDropdown
              label="Tax Breakdown"
              id="splitTax"
              required={isWyngsMy ? false : true}
              placeholder="Select"
              options={splitTaxOptions || []}
              onChange={value => {
                setInvConfig({ ...invConfig, splitTax: value });
              }}
              name="splitTax"
              value={invConfig.splitTax || []}
            />
          </Grid>
          <Grid item md={9}>
            <SBDropdown
              label="Billing To"
              id="billTo"
              required={!isWyngsMy ? false : true}
              placeholder="Select"
              options={billToOptions || []}
              onChange={value => {
                console.log('value ', value);
                setInvConfig({ ...invConfig, billTo: value });
              }}
              name="billTo"
              value={invConfig.billTo || []}
            />
          </Grid>
          {/* Third party */}
          {invConfig?.billTo && invConfig?.billTo[0] === 'third_party' && (
            <>
              <Grid item md={9}>
                <SbTextField
                  placeholder="Bill Name"
                  label="Bill Name"
                  name="billname"
                  value={invConfig.billname}
                  onChange={e => {
                    const { value } = e.target;
                    setInvConfig({ ...invConfig, billname: value });
                  }}
                  required
                  // error={errors.bankName}
                />
              </Grid>
              <Grid item md={9}>
                <SbTextField
                  placeholder="Bill Address"
                  label="Bill Address"
                  name="billaddres"
                  value={invConfig.billaddress}
                  onChange={e => {
                    const { value } = e.target;
                    setInvConfig({ ...invConfig, billaddress: value });
                  }}
                  required
                  // error={errors.bankName}
                />
              </Grid>
              <Grid item md={9}>
                <Grid container columnSpacing={4}>
                  <Grid item md={6} gap={4}>
                    <SbTextField
                      placeholder="Tax Name"
                      label="Tax Name"
                      name="taxname"
                      value={invConfig.taxname}
                      onChange={e => {
                        const { value } = e.target;
                        setInvConfig({ ...invConfig, taxname: value });
                      }}
                      // error={errors.bankName}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <SbTextField
                      placeholder="Tax No"
                      label="Tax No"
                      name="taxno"
                      value={invConfig.taxno}
                      onChange={e => {
                        const { value } = e.target;
                        setInvConfig({ ...invConfig, taxno: value });
                      }}
                      // error={errors.bankName}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item md={9}>
            <div style={{ display: 'flex', marginTop: '10px' }}>
              {!isWyngsMy && (
                <SbButton
                  className="rounded-full"
                  variant="contained"
                  sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                  onClick={() => {
                    sendTo365();
                  }}
                  disabled={!(invConfig.invoiceDate && invConfig.dueDate)}
                >
                  Send to D365
                </SbButton>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6.8}>
          <Grid container justifyContent={'space-between'} alignItems={'center'} my={'1rem'}>
            <Grid item xs="auto">
              <Box pb={'0.5rem'} borderBottom={'4px solid #080808'} width={'140px'}>
                <Title removePadding removeBorder title="Preview Invoices" titleClass=" px_16-text" />
              </Box>
            </Grid>
            <Grid item xs="auto" justifyContent={'flex-end'} container>
              <Title removePadding removeBorder title={`No. of Invoices : ${noOfInvoices}`} titleClass="px_16-text" />
            </Grid>
          </Grid>
          <Box sx={{ overflowX: 'auto' }}>
            <SBTable>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Sr. No.</TableHeadCell>
                  <TableHeadCell>Account No.</TableHeadCell>
                  <TableHeadCell>System</TableHeadCell>
                  <TableHeadCell>No of Shipments</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedAccountRows.map((data, index) => (
                  <SBTableRow key={index}>
                    <TableRowCell
                      sx={{
                        div: {
                          textAlign: 'center',
                        },
                      }}
                      align="center"
                    >
                      {index + 1}
                    </TableRowCell>
                    <TableRowCell align="center" className="md-text uppercase">
                      {data.accountNumber}
                    </TableRowCell>
                    <TableRowCell align="center" className="md-text capitalize">
                      <p>{data.systemName}</p>
                    </TableRowCell>
                    <TableRowCell
                      sx={{
                        div: {
                          textAlign: 'center',
                        },
                      }}
                      align="center"
                      className="md-text"
                    >
                      {data.shipments.length}
                    </TableRowCell>
                  </SBTableRow>
                ))}
              </TableBody>
            </SBTable>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width: 70 },
  { field: 'date', headerName: 'Date', width: 130, cellClassName: 'md-text', headerClassName: 'md-text' },
  {
    field: 'shipmentNo',
    headerName: 'Shipment No',
    width: 150,
    renderCell: (params: GridValueGetterParams) => (
      <Link to={`/shipment/admin/edit/${params?.value}`} className="sb-text-black underline">
        {params?.value}
      </Link>
    ),
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'status',
    headerName: 'Status',
    // type: 'number',
    width: 160,
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'shipper',
    headerName: 'Shipper',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 180,
    renderCell: (params: GridValueGetterParams) => (
      <Box>
        <p className="font-medium capitalize">{params?.value?.name}</p>
        <p className="sb-text-gray">
          {params?.value?.city}-{params?.value?.pincode}
        </p>
        <p className="sb-text-gray">{params?.value?.country}</p>
      </Box>
    ),
    cellClassName: 'md-text h-min',
    headerClassName: 'md-text',
  },
  {
    field: 'consignee',
    headerName: 'Consignee',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 180,
    renderCell: (params: GridValueGetterParams) => (
      <Box>
        <p className="font-medium capitalize">{params?.value?.name}</p>
        <p className="sb-text-gray">
          {params?.value?.city}-{params?.value?.pincode}
        </p>
        <p className="sb-text-gray">{params?.value?.country}</p>
      </Box>
    ),
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'weight',
    headerName: 'Weight',
    width: 160,
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'packages',
    headerName: 'Packages',
    width: 160,
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
  {
    field: 'totalAmount',
    headerName: 'Total Amount (INR)',
    width: 180,
    cellClassName: 'md-text',
    headerClassName: 'md-text',
  },
];

type AccordionTableProps = {
  data: AccountHolderUnbilledData;
  setSelectedUnbilledShipment: (obj: selectedParamType) => void;
  selectedUnbilledRow: selectedParamType[];
};

function AccordionTable(props: AccordionTableProps) {
  const { data, setSelectedUnbilledShipment, selectedUnbilledRow } = props;
  const locale = useLocale();

  const {
    shipments,
    shipmentRouteType,
    accountNumber,
    count,
    _systemId,
    totalAmount,
    accountId,
    user,
    company,
    uniqueKey,
  } = data;

  const rows = shipments.map((s, i: number) => {
    return {
      id: i + 1,
      date: moment(s.createdAt).format('DD/MM/YYYY'),
      shipmentNo: s.shipmentNumber,
      status: SHIPMENT_STATUS[s.status]?.label,
      shipper: s._shipperId,
      consignee: s._consigneeId,
      weight: s?.sellingPricing?.displayChargeableWeight + 'kg',
      packages: s?._packageId?.box?.length,
      totalAmount: formatNumber(s?.sellingPricing?.totalPricing || 0, locale),
      isDisabled: s.carrierDetails && s.carrierDetails?.awb,
    };
  });

  console.log(totalAmount);

  // Calculate the sum of totalPricing for all shipments
  const totalPricingSum = selectedUnbilledRow?.reduce((sum, entry) => {
    const shipments = entry?.shipments || [];
    const total = shipments?.reduce((acc, shipment) => acc + (shipment?.sellingPricing?.totalPricing || 0), 0);
    return sum + total;
  }, 0);

  const summary = () => {
    const locale = useLocale();

    return (
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        pr={'1rem'}
        sx={{
          '@media (max-width: 600px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
        }}
      >
        <Stack gap={'10px'} sx={{ width: '100%' }}>
          <Stack direction={'row'} gap={'2rem'} sx={{ '@media (max-width: 600px)': { flexDirection: 'column' } }}>
            <Box
              sx={{
                minWidth: '5rem',
                fontSize: '1rem',
                '@media (max-width: 600px)': {
                  fontSize: '0.875rem',
                  width: '100%',
                },
              }}
              className="md-text"
            >
              {company ? company?.name : user ? user?.name : '-'}
            </Box>
            <Typography
              className="md-text has-text-grey-light"
              sx={{
                fontSize: '0.875rem',
                '@media (max-width: 600px)': {
                  fontSize: '0.75rem',
                },
              }}
            >
              {company ? company?.email : user ? user?.email : '-'}
            </Typography>
          </Stack>

          <Stack direction={'row'} gap={'2rem'} sx={{ '@media (max-width: 600px)': { flexDirection: 'column' } }}>
            <Typography
              className="md-text font-semibold"
              sx={{
                fontSize: '0.875rem',
                '@media (max-width: 600px)': { fontSize: '0.75rem' },
              }}
            >
              {accountId?.accountNumber}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.875rem',
                '@media (max-width: 600px)': { fontSize: '0.75rem' },
              }}
            >
              {_systemId?.name}
            </Typography>
            <Typography
              className="md-text capitalize"
              sx={{
                fontSize: '0.875rem',
                '@media (max-width: 600px)': { fontSize: '0.75rem' },
              }}
            >
              {shipmentRouteType}
            </Typography>
            <Typography
              className="md-text"
              sx={{
                fontSize: '0.875rem',
                '@media (max-width: 600px)': { fontSize: '0.75rem' },
              }}
            >
              Shipments({count})
            </Typography>
          </Stack>
        </Stack>
        <Box
          sx={{
            justifySelf: 'flex-end',
            alignSelf: 'center',
            fontWeight: 600,
            color: 'primary.main',
            fontSize: '1rem',
            '@media (max-width: 600px)': {
              fontSize: '0.875rem',
              width: '100%',
              textAlign: 'left',
            },
          }}
          className="md-text"
        >
          Total Amount: INR {formatNumber(totalPricingSum, locale)}
        </Box>
      </Stack>
    );
  };
  const selectShipment = (_data: AccountHolderUnbilledData) => (selectedRows: Array<number>) => {
    const shipmentRow = shipments.filter((_, i) => selectedRows.includes(i + 1));
    const obj = {
      _userId: user?._id,
      accountNumber,
      shipmentRouteType,
      _systemId: _systemId._id,
      systemName: _systemId.name,
      shipments: shipmentRow,
      uniqueKey,
    };
    setSelectedUnbilledShipment(obj);
  };
  const details = () => {
    const [organisationName, setOrganisationName] = useState<string>();

    useEffect(() => {
      const hostname = window.location.hostname;
      const subdomain = hostname.split('.')[0];
      setOrganisationName(subdomain);
    }, []);
    console.log(organisationName);
    return (
      <>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          rowHeight={90}
          rowSelectionModel={selectedUnbilledRow?.flatMap((item: selectedParamType) =>
            item?.shipments?.map(ship => {
              const index = shipments.findIndex(s => s?._id === ship?._id);
              return index !== -1 ? index + 1 : -1;
            }),
          )}
          // isRowSelectable={(params: GridRowParams) => {
          //   const res = organisationName === 'wyngs-my' ? true : !!params.row?.isDisabled;
          //   // const res = organisationName === 'localhost' ? true : !!params.row?.isDisabled;
          //   // const res = true;
          //   return res;
          // }}
          checkboxSelection
          onRowSelectionModelChange={selectShipment(data)}
        />
      </>
    );
  };
  return (
    <>
      <Accord summary={summary()} details={details()} />
    </>
  );
}
