import { styled } from '@mui/material';
import React from 'react';

const WrapperStyled = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: `repeat(100, 300px)`,
  columnGap: `16px`,
}));

function Row(props: { children: React.ReactElement }) {
  return <WrapperStyled>{props.children}</WrapperStyled>;
}

export default Row;
