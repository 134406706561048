import React, { useEffect, useMemo, useState } from 'react';

import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';
import CreateSysForm, { initCreateSystem } from '../Create/Form';
import { SystemType } from '../Create/type';

import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import SbButton from '../../../components/Button';
import { getSystemById, resetSingleSystem, updateSystemById } from '../../../store/reducers/Systems';
import { UpdateSystemSchema } from '../Create/schema';
import { ZonesEntity } from '@Reducers/Systems/Type';

function EditSystem() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id: systemId } = useParams() as { id: string };
  const SystemState = useSelector((state: RootStateType) => state.System);
  const [zones, setZonesPricing] = useState<ZonesEntity[]>([]);

  useEffect(() => {
    if (systemId) dispatch(getSystemById({ id: systemId, showModal: true, navigate: '/system' }));
    return () => {
      dispatch(resetSingleSystem());
    };
  }, []);

  const initialFormValues = useMemo(() => {
    if (SystemState.singleSystem) {
      // formik.se
      const _SystemState = SystemState.singleSystem;

      return {
        name: _SystemState.name,
        email: _SystemState.contact.email,
        supportEmail: _SystemState.contact.supportEmail,
        countryCode: _SystemState.contact.phone.telCode,
        number: _SystemState.contact.phone.number,
        pincodes: [],
        addressLine1: _SystemState?.address?.line1,
        addressLine2: _SystemState?.address?.line2,
        city: _SystemState?.address?.city,
        state: _SystemState?.address?.state,
        country: _SystemState?.address?.country,
        pincode: _SystemState?.address?.pincode,
      };
    } else return initCreateSystem;
  }, [SystemState.singleSystem]);

  const onCreate = (params: SystemType) => {
    const payload = {
      name: params.name,
      contact: {
        phone: {
          number: params.number,
          telCode: params.countryCode,
        },
        supportEmail: params.supportEmail,
        email: params.email,
      },
      address: {
        line1: params.addressLine1,
        line2: params.addressLine2,
        city: params.city,
        state: params.state,
        country: params.country,
        pincode: params.pincode,
      },
      pincode: params.pincodes,
      squadzones: { zones },
    };
    dispatch(updateSystemById({ id: systemId, payload, showModal: true, navigate: '/system', zones }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: initialFormValues,
    validationSchema: UpdateSystemSchema,
    onSubmit(values) {
      onCreate(values);
    },
  });

  return (
    <>
      {/* <Grid container> */}
      <Grid item xs={12}>
        <CreateSysForm
          formType="edit"
          showBackgroundColor={false}
          errors={formik.errors}
          values={formik.values}
          handleChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
          setZonesPricing={setZonesPricing}
        />
      </Grid>
      <Grid container item mt={4} spacing={2} justifyContent="flex-end">
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          <SbButton
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </SbButton>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          <SbButton
            fullWidth
            variant="contained"
            onClick={_event => {
              formik.handleSubmit();
            }}
          >
            Save
          </SbButton>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </>
  );
}

export default EditSystem;
