import { PayloadAction } from '@reduxjs/toolkit';
import {
  CreditState,
  AvailableCreditResponse,
  AvailableCreditLimit,
  OutstandingBalanceResponse,
  CreditHistoryResponse,
} from './Type';
import { SuccessPayload } from '../../../types';
import { formatNumber } from '../../../utils/numberUtils';

function pending(state: CreditState) {
  state.loading = true;
  state.success = false;
}

function addCreditLimitFulfilled(
  state: CreditState,
  { payload }: PayloadAction<SuccessPayload<AvailableCreditResponse>>,
) {
  state.availableLimit = payload.data?.limit || 0;
  state.loading = false;
  state.success = true;
}

function getCreditLimitFulfilled(state: CreditState, { payload }: PayloadAction<SuccessPayload<AvailableCreditLimit>>) {
  state.availableLimit = formatNumber(payload.data?.availableLimit || 0);
  state.creditLimit = formatNumber(payload.data?.creditLimit || 0);
  state.loading = false;
}

function getOutstandingBalanceForPostpaidFulfilled(
  state: CreditState,
  { payload }: PayloadAction<SuccessPayload<OutstandingBalanceResponse>>,
) {
  state.outstandingBalance = formatNumber(payload.data?.outstandingBalance || 0);
  state.totalShipmentAmtPaid = formatNumber(payload.data?.totalShipmentAmtPaid || 0);
  state.totalShipmentAmt = formatNumber(payload.data?.totalShipmentAmt || 0);
  state.loading = false;
}

function getOutstandingBalanceInvoiceForPostpaidFulfilled(
  state: CreditState,
  { payload }: PayloadAction<SuccessPayload<OutstandingBalanceResponse>>,
) {
  state.totalInvoiceAmtPaid = formatNumber(payload.data?.totalInvoiceAmtPaid || 0);
  state.totalInvoiceAmt = formatNumber(payload.data?.totalInvoiceAmt || 0);
  state.loading = false;
}

function getCreditTransactionListFulfilled(state: CreditState, action: { payload: CreditHistoryResponse }) {
  state.data = action.payload.data;
  state.loading = false;
}

function rejected(state: CreditState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

export {
  pending,
  addCreditLimitFulfilled,
  rejected,
  getCreditLimitFulfilled,
  getOutstandingBalanceForPostpaidFulfilled,
  getCreditTransactionListFulfilled,
  getOutstandingBalanceInvoiceForPostpaidFulfilled,
};
