import { Grid, styled } from '@mui/material';

export const Container = styled(Grid)(() => ({
  marginTop: '32px',
}));
export const Wrapper = styled(Grid)(({ theme }) => ({
  border: '1px solid var(--mui-palette-border-light)',
  padding: '60px',
  [theme.breakpoints.down('md')]: {
    padding: '20px',
  },
  marginBottom: '32px',
  borderRadius: 'calc(var(--mui-palette-spacing) * 2)',
  '.title': {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
  },
}));
export const ContainerStyle = styled(Grid)(({ theme: _ }) => ({
  padding: '20px 0',
  borderTop: '1px solid #EAECF0',
}));
