import * as XLSX from 'xlsx';

import { FileResultType } from '../../Helper/file';
import { ODAType, ChargeType } from './Create/type';

export function downloadOdaCharges(pincodes: Array<ChargeType>, fileName: string) {
  /* generate worksheet and workbook */
  const rows = pincodes.map(p => [p.pincode, String(p.charge), p.applyOn.join(','), (p.canDeliver && 'Yes') || 'No']);
  const worksheet = XLSX.utils.aoa_to_sheet([]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');

  XLSX.utils.sheet_add_aoa(worksheet, [
    ['Pincode', 'Charge Amount', 'Global Charges', 'Deliver Serviceability'],
    ...rows,
  ]);

  /* calculate column width */
  const max_width = rows.reduce((w, r) => Math.max(w, r[0].length), 10);
  worksheet['!cols'] = [{ wch: max_width }, { wch: 12 }];
  /* create an XLSX file and try to save to Presidents.xlsx */
  XLSX.writeFile(workbook, `${fileName} - ODA.xlsx`, { compression: true });
}

export function readXslAndGetObject(data: FileResultType[]) {
  const [{ fileData }] = data;
  if (!(fileData instanceof Array)) return;

  const {
    data: [headers, ...body],
  } = fileData[0] as { data: string[][] };
  const pincodeIndex = headers.findIndex(x => x.match(/pincode/i));
  const chargeIndex = headers.findIndex(x => x.match(/charge amount/i));
  const globalChargesIndex = headers.findIndex(x => x.match(/global charges/i));
  const deliveryServiceIndex = headers.findIndex(x => x.toLowerCase().match(/delivery serviceability/i));

  if (pincodeIndex === -1) throw { msg: 'Pincode columns not found in xlsx' };
  else if (!body.length) throw { msg: 'At least one pincode required' };
  else if (deliveryServiceIndex === -1) throw { msg: 'Delivery serviceability column not found in xlsx' };

  const invalidDeliveryServiceValue = body.filter(x => {
    return !x[deliveryServiceIndex].toLowerCase().match(/^(Y|N|yes|no)$/i);
  });

  if (invalidDeliveryServiceValue?.length) {
    const pincodes = invalidDeliveryServiceValue.map(i => i[pincodeIndex]);
    let formattedPincodeError = '';
    if (pincodes.length === 1) {
      formattedPincodeError = pincodes[0] + ' contains invalid entry of delivery serviceability.';
    } else {
      formattedPincodeError = 'Given pincode contains invalid entry of delivery serviceability: ' + pincodes.join(', ');
    }
    throw {
      msg: formattedPincodeError,
    };
  }

  const deliveryCharges: ODAType['charges'] = body.map(arr => ({
    pincode: arr[pincodeIndex],
    charge: arr[chargeIndex] ? Number(arr[chargeIndex]) : 0,
    applyOn: arr[globalChargesIndex]?.split(',') || [],
    canDeliver: !!new RegExp(/yes|Y/, 'i').test(arr[deliveryServiceIndex]),
  }));
  return deliveryCharges;
}

export default { downloadOdaCharges, readXslAndGetObject };
