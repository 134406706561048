import React, { ReactNode } from 'react';
import { Dialog, Grid, Typography, styled } from '@mui/material';
import SbButton from '../Button';

interface PropsType {
  icon?: string;
  title?: string;
  subTitle?: string | ReactNode;
  children?: ReactNode;
  open: boolean;
  btnText?: string;
  onClose: () => void;
  onConfirm?: () => void;
}

const Title = styled(Typography)(({ theme }) => ({
  // fontSize: '32px',
  maxWidth: '500px',
  marginTop: theme.spacing(4),
}));

const DialogContainer = styled(Grid)({
  maxWidth: '540px',
});

const DialogContent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
}));

const Header = styled(Grid)`
  background: var(--mui-palette-border-main);
  height: 89px;

  div {
    width: 130px;
    height: 130px;
    background: var(--mui-palette-border-main);
    border-radius: 50%;
    margin-top: 10px;
    color: var(--mui-palette-primary-contrastText);
  }
  img {
    height: 45px;
    width: 45px;
  }
`;

function Modal({ open, onConfirm, onClose, title, subTitle, children, icon }: PropsType) {
  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose} scroll={'paper'}>
      <DialogContainer container justifyContent="flex-end">
        {/* <HighlightOffIcon
          sx={{ cursor: 'pointer', position: 'absolute', right: '12px', top: '15px', height: '24px', width: '24px' }}
          color="action"
          onClick={onClose}
        /> */}
        <Header container item justifyContent="center">
          <Grid container item justifyContent="center" alignItems="center">
            <img src={icon ? icon : '/images/icons/DeleteAction.svg'} alt="logistics" />
          </Grid>
        </Header>
        <DialogContent
          container
          item
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          {children ? (
            children
          ) : (
            <>
              {title && <Title className="font-medium xl-text">{title}</Title>}
              {subTitle && (
                <Typography variant="h6" className="mt-1 font-normal">
                  {subTitle}
                </Typography>
              )}

              <Grid container gap={1} className="mt-4" justifyContent="center">
                <SbButton
                  onClick={onClose}
                  variant="outlined"
                  className="rounded-full"
                  style={{ height: '44px', width: '148px' }}
                >
                  No
                </SbButton>
                <SbButton
                  onClick={onConfirm}
                  variant="contained"
                  className="rounded-full"
                  style={{ height: '44px', width: '148px' }}
                >
                  Yes
                </SbButton>
              </Grid>
            </>
          )}
        </DialogContent>
      </DialogContainer>
    </Dialog>
  );
}
export default Modal;
