import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  UpdateMarkupRate,
  UpdateZoneRate,
  UploadRateMarkup,
  UploadRateZone,
  ErrorType,
  SuccessResponseType,
  GetSingleRatesParams,
} from './Type';
import API from '../../../services/apiAxios';
import { AxiosError } from 'axios';

const UploadZone = createAsyncThunk<SuccessResponseType, UploadRateZone, { rejectValue: ErrorType }>(
  'UploadZone',
  async (params: UploadRateZone, { rejectWithValue }) => {
    try {
      const res = await API.post(`/shipment/rate/${params.accountNumber}`, params);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);
const UpdateZone = createAsyncThunk<SuccessResponseType, UpdateZoneRate, { rejectValue: ErrorType }>(
  'UpdateZone',
  async (params: UpdateZoneRate, { rejectWithValue }) => {
    try {
      const res = await API.put(`/shipment/rate/${params.accountNumber}`, params);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const UploadMarkup = createAsyncThunk<SuccessResponseType, UploadRateMarkup, { rejectValue: ErrorType }>(
  'UploadMarkup',
  async (params: UploadRateMarkup, { rejectWithValue }) => {
    try {
      const res = await API.post(`/shipment/rate/${params.accountNumber}`, params);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);
const UpdateMarkup = createAsyncThunk<SuccessResponseType, UpdateMarkupRate, { rejectValue: ErrorType }>(
  'UpdateMarkup',
  async (params: UpdateMarkupRate, { rejectWithValue }) => {
    try {
      const res = await API.put(`/shipment/rate/${params.accountNumber}`, params);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const GetSingleRateObj = createAsyncThunk<SuccessResponseType, GetSingleRatesParams, { rejectValue: ErrorType }>(
  'GetRate',
  async (params, { rejectWithValue }) => {
    try {
      const res = await API.get(`/shipment/rate/single/${params.rateUploadType}`, { params });
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

export { UploadZone, UpdateZone, UploadMarkup, UpdateMarkup, GetSingleRateObj };
