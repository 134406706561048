import { constantType } from './Type';

const constant: constantType = {
  DOMAIN_OWNER: 'speedbox',
  protocol: 'http',
  DOMAIN: 'https://d3jhzv3gzqmxuo.cloudfront.net',
  API_DOMAIN: 'https://d3jhzv3gzqmxuo.cloudfront.net/prod',
  API_URI: '/prod/api/v2',
  // API_URI: 'https://d3jhzv3gzqmxuo.cloudfront.net/staging/api/v2',
  WEBSOCKET_URI: 'ws://2.speedboxapp.com/development/speedbox/api/v1',
  API_URI_PATHNAME: '/prod/api/v2',
  razorKey: 'rzp_test_LIOk4AYGgVRnDY',
  trackingUrl: 'https://speedboxapp.com/track?orderNumber=',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBFD_fSwbp29dcajB97ReaHX2SB9V1VMvI',
};

export default constant;
