import SbButton from '@Components/Button';
import SBDropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import SbTextField from '@Components/Textfield';
import Title from '@Components/Title';
import {
  editFinalForward,
  getAdminShipmentPricing,
  updateCarrierAndCustomerRefNumber,
  updateShipment,
  updateShipmentSelectedCharges,
} from '@Reducers/AdminShipment';
import { CustomerReferenceNumberType, ShipmentPricing } from '@Reducers/AdminShipment/Type';
import { AppDispatch, RootStateType } from '@Store';
import { Timeline, TimelineContent, TimelineItem, TimelineSeparator, timelineItemClasses } from '@mui/lab';
import { Alert, Checkbox, FormControlLabel, Grid, Typography, Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useScroll from '../../useScroll';
// import Actions from '../Actions';
import Image from '@Components/Image';
import { CSP_TYPES } from '@Constants/Shipment';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import useConnectionTitle from '@Hook/useConnectionTitle';
import { getFedExDetails, getVendorsAccount } from '@Reducers/AdminShipment/actions';
import { getWarehouseListForShipment, setIsWarehouseSelectedINFedX } from '@Reducers/Warehouse';
// import API from '@Services/apiAxios';
import { ShipmentControl } from '@Services/shipmentActionControl';
import { ExpandLessRounded } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isActionAllowed } from '../../../../utils/allowedActions';
import FirstConnectingWarehouse from './FirstConnectingWarehouse';
import NewConnectingWarehouse from './NewConnectingWarehouse';
import DHLInformation from './components/DHL/DHLInformation';
import FedXInformation from './components/Fedex/FedXInformation';
import SideTimelineSeparator from './components/SideTimelineSeparator';
import ViewConnection from './components/ViewConnection';
import { HorizontalDash, SingleConnectionContainer, TimelineConnectorStyled } from './style';
import AramexInformation from './components/Aramex/AramexInformation';
import { fetchCarrierData } from '@Reducers/integration';
import { postCirroDetails } from '@Reducers/AdminShipment/actions';
import Loader from '@Components/Loader';
import { downloadCarrierLabel } from '@Reducers/ShipmentHistory/actions';
import StageInformation from './components/Stage/StageInformation';
// import { it } from 'node:test';

interface BuyingSellingChargesUpdate {
  buying: string;
  selling: string;
}

function ShipmentProcessingV2() {
  const { shipmentProcessingRef } = useScroll();
  const dispatch = useDispatch<AppDispatch>();
  // const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const isFinalForwardEditable = useSelector((state: RootStateType) => state.adminShipment.editFinalForward);
  const {
    lastTransitWarehouse,
    shipmentConnectOnWarehouse,
    fedExDetails,
    addTransitHub,
    shipmentById,
    dhlDetails,
    aramexDetails,
    stageDetails,
  } = useSelector((state: RootStateType) => state.adminShipment);
  // const addTransitHub = useSelector((state: RootStateType) => state.adminShipment.addTransitHub);
  const shipmentActionControl = new ShipmentControl(shipmentById);

  const [selectedBuyingPricing, setSelectedBuyingPricing] = useState<string>('');
  const [selectedSellingPricing, setSelectedSellingPricing] = useState<string>('');
  const [carrierAwb, setCarrierAwb] = useState<string>('');
  const [carrierAwbShowToUser, setCarrierAwbShowToUser] = useState<boolean>(true);
  const [customerReferenceNumber, setCustomerReferenceNumber] = useState<CustomerReferenceNumberType[]>([]);
  const [error, setError] = useState<{ carrierAwb: string }>({ carrierAwb: '' });
  const [showAWB, setShowAWB] = useState<string>('');
  const shipmentpayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);
  const { isWarehouseSelectedINFedX } = useSelector((state: RootStateType) => state.Warehouse);
  const [isCodeMatched, setIsCodeMatched] = useState<boolean>(false);
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const carrierIntegration = useSelector((state: RootStateType) => state.carrierIntegration.carriers);
  const [isloading, setLoading] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const isShipmentProcessingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_processing,
    PERFORM_ACTION.write,
  );

  const connectionCountInWord = useConnectionTitle({
    position: addTransitHub ? (shipmentConnectOnWarehouse?.length || 0) + 2 : -1,
    addressObj: addTransitHub ? lastTransitWarehouse?.metadata : shipmentById?._consigneeId,
  });

  useEffect(() => {
    dispatch(getWarehouseListForShipment());
  }, []);

  useEffect(() => {
    if (shipmentById) {
      const { buyingPricing, sellingPricing } = shipmentById;
      if (buyingPricing) setSelectedBuyingPricing(`${buyingPricing?._courierId}-${buyingPricing?._systemId}`);
      if (sellingPricing) setSelectedSellingPricing(`${sellingPricing?._courierId}-${sellingPricing?._systemId}`);
      // if (shipmentNumber) dispatch(getFedExDetails(shipmentNumber));
    }

    // if (shipmentById?.customerReferenceNumber?.length)
    setCustomerReferenceNumber(shipmentById?.customerReferenceNumber || []);

    // if (shipmentById?.carrierDetails?.awb)
    setCarrierAwb(shipmentById?.carrierDetails?.awb ?? '');
  }, [shipmentById]);

  const addNewCustomerReferenceNumber = (data: CustomerReferenceNumberType) => {
    // setCustomerReferenceNumber(state => [...state, data]);
    onSaveCarrierAndRefNo({ customerReferenceNumber: [...customerReferenceNumber, data] });
  };

  const delNewCustomerReferenceNumber = (index: number) => {
    // setCustomerReferenceNumber(state => state.filter((_, i) => i !== index));
    onSaveCarrierAndRefNo({ customerReferenceNumber: customerReferenceNumber.filter((_, i) => i !== index) });
  };

  const onSavePricing = ({ buying, selling }: BuyingSellingChargesUpdate) => {
    if (!shipmentById?.shipmentNumber) return;
    setShowAWB('');
    const [buyingCourierId, buyingSystemId] = buying.split('-');
    const [sellingCourierId, sellingSystemId] = selling.split('-');
    dispatch(
      updateShipmentSelectedCharges({
        shipmentNumber: shipmentById?.shipmentNumber,
        buyingCourierId,
        buyingSystemId,
        sellingCourierId,
        sellingSystemId,
      }),
    );
  };

  const onSaveCarrierAndRefNo = ({
    customerReferenceNumber,
    carrierAwb,
  }: {
    carrierAwb?: string;
    customerReferenceNumber?: CustomerReferenceNumberType[];
  }) => {
    if (!shipmentById?.shipmentNumber) return;
    dispatch(
      updateCarrierAndCustomerRefNumber({
        shipmentNumber: shipmentById?.shipmentNumber,
        customerReferenceNumber,
        carrierAWB: carrierAwb,
      }),
    );
  };

  const onClickForward = () => {
    // handleSubmit();
    // if (isFinalForwardEditable && !carrierAwb) {
    //   setError({ carrierAwb: 'Carrier AWB is required' });
    //   return;
    // }
    if (!shipmentById?.shipmentNumber) return;

    if (shipmentById?.shipmentRouteType !== 'import') {
      if (isWarehouseSelectedINFedX === '' || isWarehouseSelectedINFedX === 'false') {
        dispatch(setIsWarehouseSelectedINFedX('false'));
        return;
      }
    }
    setError({ carrierAwb: '' });
    dispatch(
      updateShipment({
        carrierDetails: { awb: carrierAwb, awbShowToUser: carrierAwbShowToUser },
        shipmentNumber: shipmentById?.shipmentNumber,
        isFinalForward: true,
      }),
    );
    dispatch(editFinalForward(false));
    setShowAWB('');
  };

  console.log({ shipmentActionControl, isFinalForwardEditable });

  // TODO: Add more carrier awb services here
  const showGenerateAWB =
    shipmentById?.buyingPricing?.code == CSP_TYPES.fedex.value ||
    shipmentById?.buyingPricing?.code == CSP_TYPES.dhlIndia.value ||
    shipmentById?.buyingPricing?.code == CSP_TYPES.dhl.value ||
    shipmentById?.buyingPricing?.code == CSP_TYPES.stage.value ||
    shipmentById?.buyingPricing?.code == CSP_TYPES.aramex.value ||
    shipmentById?.buyingPricing?.code == CSP_TYPES.stage.value ||
    shipmentById?.buyingPricing?.code == 'cirro';

  useEffect(() => {
    //TODO: Replace shipmentById?.buyingPricing?.code === "fedex" to shipmentById?.buyingPricing?.code, when other AWB integrated.
    if (
      shipmentById?.shipmentNumber &&
      shipmentById?.buyingPricing?.code &&
      (shipmentById?.buyingPricing?.code === CSP_TYPES.fedex.value ||
        shipmentById?.buyingPricing?.code === CSP_TYPES.dhlIndia.value ||
        shipmentById?.buyingPricing?.code === CSP_TYPES.dhl.value ||
        shipmentById?.buyingPricing?.code === CSP_TYPES.stage.value ||
        shipmentById?.buyingPricing?.code === CSP_TYPES.aramex.value)
    ) {
      if (isCodeMatched) {
        dispatch(getVendorsAccount(shipmentById?.buyingPricing?.code));
      }
      if (shipmentConnectOnWarehouse?.length === 0 && carrierIntegration.length > 0 && isCodeMatched) {
        dispatch(
          getFedExDetails({
            shipmentNumber: shipmentById?.shipmentNumber,
            type: 'D-D',
            csp: shipmentById?.buyingPricing?.code,
          }),
        );
      }
      if (
        shipmentConnectOnWarehouse &&
        shipmentConnectOnWarehouse?.length > 0 &&
        carrierIntegration.length > 0 &&
        isCodeMatched
      ) {
        dispatch(
          getFedExDetails({
            shipmentNumber: shipmentById?.shipmentNumber,
            type: 'W-D',
            csp: shipmentById?.buyingPricing?.code,
          }),
        );
      }
    }
  }, [
    shipmentById?.shipmentNumber,
    shipmentConnectOnWarehouse?.length,
    shipmentById?.buyingPricing?.code,
    isCodeMatched,
  ]);

  useEffect(() => {
    if (carrierIntegration.length > 0 && shipmentById) {
      // Convert all carrier names to lowercase
      const filterData = carrierIntegration.map(item => item.code.toLowerCase());
      const { buyingPricing } = shipmentById || {};
      // Check if the code matches
      const checkCodeMatched = filterData.includes(buyingPricing?.code?.toLowerCase() || '');
      setIsCodeMatched(checkCodeMatched);
    }
  }, [carrierIntegration, shipmentById]);

  useEffect(() => {
    dispatch(fetchCarrierData());
  }, [dispatch]);
  useEffect(() => {
    if (shipmentById?.isFinalForward) {
      setDownload(true);
    }
  }, [shipmentById]);

  // console.info('isCodeMatched:', isCodeMatched);
  const PostCirro = async () => {
    if (shipmentById?.shipmentNumber) {
      setLoading(true);
      dispatch(
        postCirroDetails({
          shipmentNumber: shipmentById?.shipmentNumber,
          body: {
            type: 'D-D',
            csp: 'cirro',
            accountNo: '00018',
          },
        }),
      )
        .unwrap()
        .then(() => {
          setTimeout(() => {
            dispatch(
              updateShipment({
                carrierDetails: { awbShowToUser: carrierAwbShowToUser },
                shipmentNumber: shipmentById?.shipmentNumber,
                isFinalForward: true,
              }),
            );
            dispatch(editFinalForward(false));
            dispatch(getFedExDetails({ shipmentNumber: shipmentById?.shipmentNumber, type: 'D-D', csp: 'cirro' }));
            setLoading(false);
            setDownload(true);
          }, 2500);
        })
        .catch(error => {
          console.error('postCirroDetails failed:', error);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Loader loading={isloading} overly />
      <Grid container mb={2} ref={shipmentProcessingRef}>
        <Title title="Shipment Processing" removeBorder removePadding />
      </Grid>

      <Grid position={'relative'} container item className="p-4 mb-3 border border-solid border-light rounded pr-0 ">
        <Grid container spacing={2}>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <ShowConnectOnWarehouse />

            {!addTransitHub && (
              <>
                <BuyingSellingCharges
                  buying={selectedBuyingPricing}
                  selling={selectedSellingPricing}
                  onChangeSelling={value => setSelectedSellingPricing(value)}
                  onChangeBuying={value => setSelectedBuyingPricing(value)}
                  onSave={onSavePricing}
                />

                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnectorStyled
                      className={`${
                        shipmentActionControl.doesShipmentDelivered ||
                        // shipmentById?.carrierDetails?.awb ||
                        shipmentById?.isFinalForward
                          ? 'selected'
                          : ''
                      } img`}
                      sx={{ mx: '11px' }}
                    />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Grid
                      className={!isFinalForwardEditable && shipmentById?.isFinalForward ? 'disable-form' : ''}
                      container
                      my={2}
                      alignItems="flex-start"
                      display="grid"
                      sx={{
                        gridTemplateColumns: {
                          xs: '1fr',
                          sm: '1fr',
                          md: '1.5fr 0fr 2fr',
                        },
                        gap: {
                          xs: 2,
                          sm: 2,
                          md: 7,
                        },
                      }}
                    >
                      <Grid container item>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            marginRight: { xs: '-10px', sm: '-10px', md: '0', lg: '0' },
                          }}
                          className={!isFinalForwardEditable && shipmentById?.isFinalForward ? 'disable-form' : ''}
                        >
                          <SbTextField
                            label="Carrier AWB"
                            // required
                            value={carrierAwb}
                            onChange={e => setCarrierAwb(e.target.value)}
                            error={error.carrierAwb}
                            disabled={
                              isShipmentProcessingWriteAllowed
                                ? !shipmentActionControl.canUpdateFinalFwdAWB ||
                                  shipmentActionControl.doesShipmentDelivered ||
                                  (!isFinalForwardEditable && !!shipmentById?.isFinalForward)
                                : !isShipmentProcessingWriteAllowed
                            }
                          />
                          {/* </Grid>
                    <Grid item xs={12} mt={'-15px'}> */}
                          <FormControlLabel
                            sx={{ my: '6px', color: '#000', ml: 0 }}
                            className={'sm-text font-medium'}
                            control={
                              <Checkbox
                                sx={{ mr: 0.5, p: 0 }}
                                checked={carrierAwbShowToUser}
                                onChange={() => setCarrierAwbShowToUser(!carrierAwbShowToUser)}
                              />
                            }
                            disabled={
                              !isFinalForwardEditable && shipmentById?.isFinalForward
                                ? true
                                : isShipmentProcessingWriteAllowed
                                ? !shipmentActionControl.canUpdateFinalFwdAWB ||
                                  shipmentActionControl.doesShipmentDelivered
                                : !isShipmentProcessingWriteAllowed
                              // || !!shipmentById?.carrierDetails?.awb
                            }
                            label="Display Carrier AWB"
                          />
                        </Grid>
                      </Grid>
                      <HorizontalDash
                        sx={{
                          height: '90px',
                          '@media (max-width: 600px)': {
                            height: '0px',
                          },
                        }}
                      />
                      <Grid container item xs={12} sm={7}>
                        <ReferenceNoAddForm
                          onAdd={addNewCustomerReferenceNumber}
                          disable={
                            isShipmentProcessingWriteAllowed
                              ? !shipmentActionControl.canUpdateReferenceNo ||
                                (shipmentById?.isFinalForward && !isFinalForwardEditable) ||
                                ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')
                              : !isShipmentProcessingWriteAllowed
                            // || !!shipmentById?.carrierDetails?.awb
                          }
                        />
                        <Grid
                          container
                          spacing={2}
                          // className="mt-1"
                          className={`mt-1 ${
                            !shipmentActionControl.canUpdateReferenceNo ||
                            (shipmentById?.isFinalForward && !isFinalForwardEditable)
                              ? // || shipmentById?.carrierDetails?.awb
                                'disabled-form'
                              : ''
                          }`}
                        >
                          {customerReferenceNumber.map((currData, index) => (
                            <SingleReferenceNo
                              key={index}
                              {...currData}
                              index={index}
                              onDelete={delNewCustomerReferenceNumber}
                              disable={
                                !shipmentActionControl.canUpdateReferenceNo ||
                                (shipmentById?.isFinalForward && !isFinalForwardEditable)
                                // ||!!shipmentById?.carrierDetails?.awb
                              }
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* {shipmentActionControl.canUpdateFinalFwdAWB && ( */}
                    <Grid container item xs={12} spacing={2} my={3}>
                      {/* {showGenerateAWB && ( */}
                      <Grid item xs={12} lg={3.5}>
                        <SbButton
                          variant="outlined"
                          sx={{ borderRadius: `4px !important`, padding: '0 !important' }}
                          className="w-full border-grey sb-text-black"
                          startIcon={<Image src="/images/icons/GenerateAwb.svg" alt="Edit" />}
                          endIcon={
                            showAWB ? (
                              <ExpandLessRounded />
                            ) : shipmentById?.buyingPricing?.code == 'cirro' ? (
                              <></>
                            ) : (
                              <ExpandMoreIcon />
                            )
                          }
                          onClick={() => {
                            if (showAWB) {
                              setShowAWB('');
                            } else {
                              if (shipmentById?.buyingPricing?.code == 'cirro') PostCirro();
                              else setShowAWB(shipmentById?.buyingPricing?.code || '');
                            }
                          }}
                          disabled={
                            !isShipmentProcessingWriteAllowed
                              ? true
                              : ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')
                              ? false
                              : !shipmentActionControl.canUpdateFinalFwdAWB ||
                                (!(shipmentById?.isFinalForward && isFinalForwardEditable) &&
                                  !!shipmentById?.isFinalForward) ||
                                !showGenerateAWB ||
                                !isCodeMatched // Additional disable conditions
                          }
                        >
                          {/* Generate DHL AWB */}
                          Generate AWB
                        </SbButton>
                      </Grid>
                      {/* )} */}
                      {!showAWB && (
                        <Grid item xs={12} lg={3.5}>
                          <SbButton
                            variant="contained"
                            sx={{ borderRadius: `4px !important` }}
                            className="w-full"
                            disabled={
                              isShipmentProcessingWriteAllowed
                                ? (!(shipmentById?.isFinalForward && isFinalForwardEditable) &&
                                    !!shipmentById?.isFinalForward) ||
                                  ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')
                                : !isShipmentProcessingWriteAllowed
                            }
                            onClick={onClickForward}
                          >
                            {shipmentById?.isFinalForward ? 'Update' : 'Forward'}
                          </SbButton>
                        </Grid>
                      )}
                      {shipmentById?.isFinalForward && isFinalForwardEditable && !showAWB && (
                        <Grid item xs={12} lg={3.5}>
                          <SbButton
                            variant="outlined"
                            sx={{ borderRadius: `4px !important` }}
                            className="w-full"
                            // disabled={!!(shipmentById?.carrierDetails?.awb || shipmentById?.isFinalForward)}
                            onClick={() => {
                              // setFianlAWBEditable(false);
                              dispatch(editFinalForward(false));
                              setError({ carrierAwb: '' });
                            }}
                            disabled={!isShipmentProcessingWriteAllowed}
                          >
                            Cancel
                          </SbButton>
                        </Grid>
                      )}
                    </Grid>
                    {/* )} */}
                    {/* 
                    Show FedEx details when the generate AWB button is clicked and the selected shipment service belongs to FedEx.
                    Additionally, allow editing FedEx details if:
                    - User has permission to view only FedEx details, and
                    - The selected shipment service belongs to FedEx.
                   */}

                    <FedXInformation
                      onClickForward={() => {
                        if (!shipmentById?.shipmentNumber) return;
                        dispatch(
                          updateShipment({
                            carrierDetails: { awbShowToUser: carrierAwbShowToUser },
                            shipmentNumber: shipmentById?.shipmentNumber,
                            isFinalForward: true,
                          }),
                        );
                        dispatch(editFinalForward(false));
                        setShowAWB('');
                      }}
                      onClose={() => {
                        dispatch(editFinalForward(false));
                        setError({ carrierAwb: '' });
                        setShowAWB('');
                      }}
                      fedExDetails={{ ...fedExDetails, type: shipmentConnectOnWarehouse?.length ? 'W-D' : 'D-D' }}
                      isEditable={
                        ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')
                          ? false
                          : shipmentById?.isFinalForward
                          ? isFinalForwardEditable
                          : true
                      }
                      showFedExDetails={showAWB === CSP_TYPES.fedex.value}
                      btnText={isFinalForwardEditable ? 'Update' : 'Forward'}
                    />
                    <AramexInformation
                      onClickForward={() => {
                        if (!shipmentById?.shipmentNumber) return;
                        dispatch(
                          updateShipment({
                            carrierDetails: { awbShowToUser: carrierAwbShowToUser },
                            shipmentNumber: shipmentById?.shipmentNumber,
                            isFinalForward: true,
                          }),
                        );
                        dispatch(editFinalForward(false));
                        setShowAWB('');
                      }}
                      onClose={() => {
                        dispatch(editFinalForward(false));
                        setError({ carrierAwb: '' });
                        setShowAWB('');
                      }}
                      initialStates={{ type: shipmentConnectOnWarehouse?.length ? 'W-D' : 'D-D' }}
                      isEditable={
                        ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')
                          ? false
                          : shipmentById?.isFinalForward
                          ? isFinalForwardEditable
                          : true
                      }
                      showFedExDetails={showAWB === CSP_TYPES.aramex.value}
                      btnText={isFinalForwardEditable ? 'Update' : 'Forward'}
                    />
                    <StageInformation
                      onClickForward={() => {
                        if (!shipmentById?.shipmentNumber) return;
                        dispatch(
                          updateShipment({
                            carrierDetails: { awbShowToUser: carrierAwbShowToUser },
                            shipmentNumber: shipmentById?.shipmentNumber,
                            isFinalForward: true,
                          }),
                        );
                        dispatch(editFinalForward(false));
                        setShowAWB('');
                      }}
                      onClose={() => {
                        dispatch(editFinalForward(false));
                        setError({ carrierAwb: '' });
                        setShowAWB('');
                      }}
                      initialStates={{ type: shipmentConnectOnWarehouse?.length ? 'W-D' : 'D-D' }}
                      isEditable={
                        ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')
                          ? false
                          : shipmentById?.isFinalForward
                          ? isFinalForwardEditable
                          : true
                      }
                      showFedExDetails={showAWB === CSP_TYPES.stage.value}
                      btnText={isFinalForwardEditable ? 'Update' : 'Forward'}
                    />
                    <DHLInformation
                      onClickForward={() => {
                        if (!shipmentById?.shipmentNumber) return;
                        dispatch(
                          updateShipment({
                            carrierDetails: { awbShowToUser: carrierAwbShowToUser },
                            shipmentNumber: shipmentById?.shipmentNumber,
                            isFinalForward: true,
                          }),
                        );
                        dispatch(editFinalForward(false));
                        setShowAWB('');
                      }}
                      onClose={() => {
                        dispatch(editFinalForward(false));
                        setError({ carrierAwb: '' });
                        setShowAWB('');
                      }}
                      initialStates={{ ...dhlDetails, type: shipmentConnectOnWarehouse?.length ? 'W-D' : 'D-D' }}
                      isEditable={
                        ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')
                          ? false
                          : shipmentById?.isFinalForward
                          ? isFinalForwardEditable
                          : true
                      }
                      showFedExDetails={showAWB === CSP_TYPES.dhlIndia.value || showAWB === CSP_TYPES.dhl.value}
                      btnText={isFinalForwardEditable ? 'Update' : 'Forward'}
                      isCodeMatched={isCodeMatched}
                    />

                    {shipmentById?.isFinalForward &&
                      !fedExDetails?.response &&
                      !dhlDetails?.response &&
                      !dhlDetails?.response &&
                      !stageDetails?.response &&
                      !aramexDetails?.response && (
                        <Typography
                          className="md-text font-medium border-grey w-fit my-2 px-3 py-1/2 rounded-xs"
                          sx={{ border: '1px solid' }}
                        >
                          {isDownload ? (
                            <>
                              This shipment has been forwarded
                              <Box
                                component={'span'}
                                className="font-medium md-text ml-1"
                                sx={{
                                  color: '#485fc7',
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (shipmentById?.shipmentNumber) {
                                    dispatch(downloadCarrierLabel(shipmentById?.shipmentNumber));
                                  }
                                }}
                              >
                                Download the carrier AWB
                              </Box>
                            </>
                          ) : (
                            'This shipment has been forwarded.'
                          )}
                        </Typography>
                      )}

                    {/* <Grid container className="mt-3">
                  <Actions onSave={onSaveCarrierAndRefNo} />
                </Grid> */}
                  </TimelineContent>
                </TimelineItem>
              </>
            )}
            <TimelineItem>
              <TimelineSeparator>
                {/* <TimelineConnectorStyled className="img" />
                <TimelineDot sx={{ width: '10px', height: '10px', mx: '6px', mt: '-3px' }} /> */}
                <SideTimelineSeparator
                  inTransit={shipmentActionControl.doesShipmentDelivered || !!shipmentById?.isFinalForward}
                  isDestination
                />
              </TimelineSeparator>
              <TimelineContent>
                <SingleConnectionContainer container item justifyContent="center" className="selected">
                  <Grid item xs={12}>
                    <Grid container item>
                      <Typography className="md-text font-semibold capitalize">{connectionCountInWord}</Typography>
                    </Grid>
                  </Grid>
                </SingleConnectionContainer>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
        {shipmentById?.status !== 'forwarded' &&
          shipmentById?._accountId?.isPrepaid &&
          Number((shipmentpayment?.payableAmount ?? 0) - (shipmentpayment?.tdsAmount ?? 0)) > 0 && (
            <Grid
              position={'absolute'}
              sx={{
                inset: '0',
                cursor: 'not-allowed',
                bgcolor: 'rgba(0, 0, 0, 0.2)',
              }}
              container
              width={'100%'}
              justifyItems={'center'}
              alignItems={'center'}
              xs={12}
              item
              zIndex={2}
            >
              <Grid item xs={12} container justifyContent={'center'}>
                <Alert
                  variant="standard"
                  severity="error"
                  className="w-fit border-grey sb-text-error sm-text"
                  sx={{ py: '1rem' }}
                >
                  Payment for this pre-paid shipment is outstanding. <br />
                  Please collect the payment in order to proceed with processing the shipment.
                </Alert>
              </Grid>
            </Grid>
          )}
      </Grid>
    </>
  );
}

function ShowConnectOnWarehouse() {
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const shipmentActionControl = new ShipmentControl(shipmentById);
  console.log('canConnectToWareshouse -', { shipmentActionControl });
  const shipmentConnectedWarehouse = useSelector(
    (state: RootStateType) => state.adminShipment.shipmentConnectOnWarehouse,
  );

  // if (shipmentById?.buyingPricing?.outbound === null) return <></>;
  console.log({ shipmentConnectedWarehouse });
  return (
    <>
      {!shipmentConnectedWarehouse?.length ? <FirstConnectingWarehouse /> : <></>}

      {shipmentConnectedWarehouse?.map((warehouse, index, allConnections) => {
        const isFristConnection = index === 0;
        const warehouseId = isFristConnection
          ? shipmentById?.firstConnectingWarehouse?._warehouseId || ''
          : allConnections[index - 1].warehouseId;

        return (
          <ViewConnection
            data={warehouse}
            warehouseId={warehouseId}
            position={index + 1}
            isFristConnection={isFristConnection}
          />
        );
      })}
      {
        // shipmentActionControl.canConnectToWareshouse &&
        shipmentConnectedWarehouse?.length ? (
          <NewConnectingWarehouse
            fromWarehouseId={shipmentConnectedWarehouse.at(-1)?.warehouseId || ''}
            position={shipmentConnectedWarehouse?.length + 1}
          />
        ) : (
          <></>
        )
      }
    </>
  );
}

interface BuyingSellingChargesProps {
  buying: string;
  selling: string;
  onChangeBuying: (value: string) => void;
  onChangeSelling: (value: string) => void;
  onSave: (params: BuyingSellingChargesUpdate) => void;
}

function BuyingSellingCharges(props: BuyingSellingChargesProps) {
  const [isMasked, setMasked] = React.useState<boolean>(false);
  const [isChangeService, setChangeService] = React.useState<boolean>(false);
  const { buying, selling } = props;
  // onChangeBuying, onChangeSelling
  const { sn: shipmentNumber } = useParams();
  const id = React.useId();
  const dispatch = useDispatch<AppDispatch>();
  const shipmentPricingState = useSelector((state: RootStateType) => state.adminShipment.shipmentPricing);
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const isFinalForwardEditable = useSelector((state: RootStateType) => state.adminShipment.editFinalForward);

  const shipmentActionControl = new ShipmentControl(shipmentById);
  // const isForwarded = useMemo(() => !!shipmentById?.carrierDetails?.awb, [shipmentById?.carrierDetails?.awb]);
  const isForwarded = useMemo(
    () => !!shipmentById?.isFinalForward,
    [shipmentById?.isFinalForward, shipmentActionControl.canConnectToWareshouse],
  );

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentProcessingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_processing,
    PERFORM_ACTION.write,
  );

  console.log({ isForwarded, shipmentActionControl });
  const { buyingOptions, sellingOptions } = useMemo(() => {
    const buyingOptions: DropdownOptionType[] = [];
    const sellingOptions: DropdownOptionType[] = [];
    if (shipmentPricingState?.length) {
      shipmentPricingState.forEach(charge => {
        const option: DropdownOptionType<ShipmentPricing> = {
          display: charge.serviceName,
          value: `${charge._courierId}-${charge._systemId}`,
          metadata: charge,
        };
        if (charge.masked)
          buyingOptions.push({
            ...option,
            secondary: `Total charge: ${organizationCountry?.currency?.name || ''} ${charge.totalPricing}`,
          });
        else
          sellingOptions.push({
            ...option,
            secondary: `Total charge: ${organizationCountry?.currency?.name || ''} ${charge.totalPricing}`,
          });
      });
    }
    return { buyingOptions, sellingOptions };
  }, [shipmentPricingState]);

  useEffect(() => {
    if (buying && selling) {
      setMasked(buying !== selling);
    }
  }, [buying, selling]);

  useEffect(() => {
    if (shipmentNumber) dispatch(getAdminShipmentPricing(shipmentNumber));
  }, [shipmentNumber]);

  useEffect(() => {
    if (buying && selling) {
      setChangeService(
        shipmentById?.firstSelectedServiceName?.toLowerCase() !==
          shipmentById?.sellingPricing?.serviceName.toLowerCase(),
      );
    }
  }, [shipmentById?.firstSelectedServiceName, shipmentById?.sellingPricing?.serviceName, buying && selling]);

  return (
    <TimelineItem>
      <TimelineSeparator>
        {/* <TimelineConnectorStyled className="img" /> */}
        {/* <TimelineDot sx={{ width: '24px', height: '24px' }} /> */}
        {/* {shipmentById?.firstConnectingWarehouse?._warehouseId &&
          !shipmentActionControl.canConnectToWareshouse &&
          !shipmentById?.carrierDetails?.awb && <SideTimelineSeparator inTransit={true} />} */}
        <TimelineConnectorStyled
          className={`${
            shipmentActionControl.doesShipmentDelivered ||
            // shipmentById?.carrierDetails?.awb ||
            shipmentById?.isFinalForward
              ? 'selected'
              : ''
          } img`}
          sx={{ mx: '11px' }}
        />
      </TimelineSeparator>
      <TimelineContent>
        <Grid
          // className={shipmentById?.carrierDetails?.awb || shipmentById?.isFinalForward ? 'disabled-form' : ''}
          container
          spacing={2}
          justifyContent="start"
        >
          {/* <Grid container item spacing={2}>
            <Grid item xs={6}>
              <SbTextField
                label="Original Service Selected "
                value={shipmentById?.firstSelectedServiceName || '-'}
                disabled
              />
            </Grid>
          </Grid> */}
          <Grid
            container
            item
            xs={6}
            gap={8}
            // className={shipmentById?.carrierDetails?.awb || shipmentById?.isFinalForward ? 'disabled-form' : ''}
          >
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                sx={{ mb: '6px', color: '#000', ml: 0 }}
                className={'sm-text font-medium'}
                control={
                  <Checkbox
                    sx={{ mr: 0.5, p: 0 }}
                    color="primary"
                    checked={isChangeService}
                    onChange={() => {
                      setChangeService(!isChangeService);
                      // setMasked(false);
                      if (isChangeService) {
                        const sellingObj = sellingOptions?.find(
                          item => item?.display.toLowerCase() === shipmentById?.firstSelectedServiceName?.toLowerCase(),
                        );
                        if (sellingObj?.value !== selling) {
                          props.onSave({ buying: sellingObj?.value || '', selling: sellingObj?.value || '' });
                        }
                      }
                    }}
                  />
                }
                label="Change Service"
                // disabled={(!isFinalForwardEditable) || isForwarded || !shipmentActionControl.canUpdateSellingDetails}
                disabled={
                  isShipmentProcessingWriteAllowed
                    ? !shipmentActionControl.canUpdateSellingDetails ||
                      (shipmentById?.isFinalForward && !isFinalForwardEditable)
                    : !isShipmentProcessingWriteAllowed
                }
              />
              {isChangeService && (
                <SBDropdown
                  id={id}
                  disabled={!isChangeService || !shipmentActionControl.canUpdateSellingDetails}
                  // label="Final Service Booked"
                  required
                  options={sellingOptions}
                  value={selling ? [selling] : []}
                  onChange={([value]) => {
                    if (value && value !== selling) props.onSave({ buying: value, selling: value });
                  }}
                  // onChange={([value]) => onChangeSelling(value)}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                sx={{ mb: '6px', color: '#000', ml: 0 }}
                className={'sm-text font-medium'}
                control={
                  <Checkbox
                    sx={{ mr: 0.5, p: 0 }}
                    color="primary"
                    checked={isMasked}
                    onChange={() => {
                      setMasked(!isMasked);
                      if (isMasked && selling !== buying) {
                        props.onSave({ buying: selling, selling: selling });
                      }
                      // setChangeService(false);
                    }}
                  />
                }
                label="Mask Service"
                disabled={
                  isShipmentProcessingWriteAllowed
                    ? !shipmentActionControl.canUpdateSellingDetails ||
                      (shipmentById?.isFinalForward && !isFinalForwardEditable)
                    : !isShipmentProcessingWriteAllowed
                }
              />
              {isMasked && (
                <SBDropdown
                  required
                  disabled={!shipmentActionControl.canUpdateBuyingDetails}
                  id={id}
                  label=""
                  options={buyingOptions}
                  value={buying ? [buying] : []}
                  onChange={([value]) => {
                    props.onSave({ buying: value, selling });
                  }}
                  // onChange={([value]) => onChangeBuying(value)}
                />
              )}
            </Grid>
          </Grid>
          {/* {!shipmentActionControl.doesShipmentDelivered &&
            !shipmentActionControl.canConnectToWareshouse &&
            // !shipmentById?.carrierDetails?.awb &&
            shipmentById?.isFinalForward && (
              <Grid container xs={6} justifyContent={'end'}>
                <SbButton
                  sx={{ borderRadius: `4px !important`, zIndex: 1 }}
                  variant="contained"
                  // className="absolute"
                  // disabled={!state.value.trim() || !state.label.trim()}
                  onClick={() => {
                    console.log('isFianlAWBEditable onEdit--');
                    dispatch(editFinalForward(true));
                  }}
                >
                  Edit
                </SbButton>
              </Grid>
            )} */}
          {/* <Grid container className="mt-3">
            <Actions onSave={props.onSave} />
          </Grid> */}
          {/* <Grid item xs={12}>
            <Divider className="mt-4" />
          </Grid> */}
        </Grid>
      </TimelineContent>
    </TimelineItem>
  );
}

const initReferenceNoAddForm: CustomerReferenceNumberType = {
  value: '',
  label: '',
  showToUser: true,
};

interface ReferenceNoAddFormProps {
  onAdd: (data: CustomerReferenceNumberType) => void;
  disable?: boolean;
}

function ReferenceNoAddForm(props: ReferenceNoAddFormProps) {
  const [state, setState] = React.useState<CustomerReferenceNumberType>({ ...initReferenceNoAddForm });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const { value, label } = state;

  const onAdd = () => {
    props.onAdd(state);
    setState({ ...initReferenceNoAddForm });
  };

  return (
    <Grid container className={`${props.disable ? 'disable-form' : ''}`}>
      <Grid container item spacing={2} alignItems="flex-end">
        <Grid item xs={12} sm={5}>
          <SbTextField
            label="Reference No"
            placeholder="Reference No"
            value={value}
            name="value"
            onChange={handleChange}
            disabled={props.disable}
            // required
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <SbTextField
            placeholder="Remark"
            value={label}
            name="label"
            onChange={handleChange}
            disabled={props.disable}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <SbButton
            sx={{ borderRadius: `4px !important` }}
            variant="contained"
            disabled={props.disable || !state.value.trim() || !state.label.trim()}
            onClick={onAdd}
          >
            Add
          </SbButton>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Checkbox checked={showToUser} onChange={() => setState({ ...state, showToUser: !state.showToUser })} />
          }
          label="Show To User"
        />
      </Grid> */}
    </Grid>
  );
}

interface SingleReferenceNoProps extends CustomerReferenceNumberType {
  index: number;
  onDelete: (index: number) => void;
  disable?: boolean;
}

function SingleReferenceNo(props: SingleReferenceNoProps) {
  return (
    <Grid container item xs={12} sm={6}>
      <Grid item xs={12} className="bg-grey rounded shadow-xs" py={2} px={1.5}>
        <Typography display="block" className="md-text">
          {props.value}
        </Typography>
        <Typography display="block" className="sm-text">
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="right" className="mt-1">
        <Typography
          display="inline-block"
          className="underline cursor-pointer"
          color="primary"
          onClick={() => {
            if (!props.disable) props.onDelete(props.index);
          }}
        >
          Delete
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ShipmentProcessingV2;
