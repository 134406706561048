import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import {
  resetViewAccessGroup,
  resetWriteAccessGroup,
  setViewAccessGroup,
  setWriteAccessGroup,
} from '@Reducers/AccessGroup';
import { AppDispatch, RootStateType } from '@Store';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Checkbox, Collapse, Grid, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from './Actions';
import { checkAllowedActions } from '@Reducers/AccessGroup/case';
import { ActionIdsType, PagesDataEntity } from '@Reducers/AccessGroup/Type';

export function Pages({ moduleName, data }: { moduleName: string; data: PagesDataEntity[] }) {
  return (
    <Grid container item className="mt-3">
      <Typography className=" font-semibold" sx={{ fontSize: '20px' }}>
        {moduleName}
      </Typography>
      <Grid container className="mt-3">
        <Collapse in={true} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
          <SBTable>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell align="left">Pages</StyledTableHeadCell>
                <StyledTableHeadCell sx={{ textAlign: 'center !important' }}>View</StyledTableHeadCell>
                <StyledTableHeadCell sx={{ textAlign: 'center !important' }}>Edit</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item: PagesDataEntity) => (
                  <Row
                    name={item?.name}
                    pageId={item._id}
                    key={item._id}
                    actionIdsArr={item?.actionIds?.length ? item?.actionIds : []}
                  />
                ))}
            </TableBody>
          </SBTable>
        </Collapse>
      </Grid>
    </Grid>
  );
}

function Row({ name, pageId, actionIdsArr }: Row) {
  const dispatch = useDispatch<AppDispatch>();
  const { actionsList, actionsIds, pageIds } = useSelector((state: RootStateType) => state.accessGroup);
  const [isCollapse, setIsCollapse] = useState<boolean>(false);

  const actionsByPage = useMemo(() => {
    if (actionIdsArr && actionIdsArr?.length) {
      return actionIdsArr?.map(id => actionsList[id]);
    }
    return [];
  }, [actionsList, actionIdsArr]);

  // Checking edit access for all actions for page
  const isAllEditTrue = useMemo(() => {
    return (
      actionIdsArr?.length > 0 && // Checking for the actions of the page
      actionsIds?.length > 0 && // Checking for the selected actions of the page
      actionsIds?.filter((item: ActionIdsType) => {
        const { isWriteOnly } = checkAllowedActions(item?.selectedActions);
        return isWriteOnly && JSON.stringify(actionIdsArr)?.includes(item._id); // Checking action should have write and also be the part actions of this page
      })?.length ===
        actionsByPage?.filter(data => {
          return data?.isWriteOnly;
        })?.length
    );
  }, [actionsByPage, actionIdsArr, actionsIds]);

  // Checking view access for all actions for page
  const isAllViewTrue = useMemo(() => {
    return JSON.stringify(pageIds)?.includes(pageId);
  }, [actionsByPage, actionIdsArr, actionsIds, pageIds, pageId]);

  const handleViewCheckBoxChange = () => {
    if (!isAllViewTrue) {
      dispatch(
        setViewAccessGroup({
          pageId: pageId,
          actionIds: actionIdsArr,
        }),
      );
    } else {
      dispatch(
        resetViewAccessGroup({
          pageId: pageId,
          actionIds: actionIdsArr,
        }),
      );
    }
  };

  const handleEditCheckBoxChange = () => {
    if (!isAllEditTrue) {
      if (actionsIds?.length !== 0) {
        dispatch(
          resetWriteAccessGroup({
            actionIds: actionIdsArr,
          }),
        );
      }
      dispatch(
        setWriteAccessGroup({
          actionIds: actionIdsArr,
        }),
      );
    } else {
      dispatch(
        resetWriteAccessGroup({
          actionIds: actionIdsArr,
        }),
      );
    }
  };

  return (
    <>
      {/* <SBTableRow sx={{ backgroundColor: isAllViewTrue ? 'border.tableRow' : 'none' }}> */}
      <SBTableRow sx={{ backgroundColor: isCollapse || isAllViewTrue ? 'border.tableRow' : 'none' }}>
        <StyledTableRowCell
          align="left"
          className="md-text"
          width={'62%'}
          onClick={() => {
            if (actionIdsArr.length > 0) {
              setIsCollapse(!isCollapse);
            }
          }}
        >
          <Typography className="md-text  flex">
            {name}
            <span>
              {/* {actionIdsArr.length > 0 ? <>{!isAllViewTrue ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</> : null} */}
              {actionIdsArr.length > 0 ? (
                <>
                  {/* {!isAllViewTrue ? ( */}
                  {/* {!isCollapse || !isAllViewTrue ? ( */}
                  {!isCollapse ? (
                    <span>
                      <ArrowDropDownIcon />
                    </span>
                  ) : (
                    <span>
                      <ArrowDropUpIcon />
                    </span>
                  )}
                </>
              ) : null}
            </span>
          </Typography>
        </StyledTableRowCell>
        <StyledTableRowCell className="md-text">
          <Grid container justifyContent="center">
            <Checkbox
              checked={isAllViewTrue}
              value={pageId}
              onChange={handleViewCheckBoxChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
        </StyledTableRowCell>
        <StyledTableRowCell className="md-text">
          <Grid container justifyContent="center">
            <Checkbox
              disabled={!isAllViewTrue}
              checked={isAllEditTrue}
              onChange={handleEditCheckBoxChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
        </StyledTableRowCell>
      </SBTableRow>
      <SBTableRow sx={{ p: '0 !important' }}>
        <TableCell style={{ padding: 0 }} colSpan={3}>
          {/* <Collapse in={isAllViewTrue} timeout="auto" unmountOnExit> */}
          {/* <Collapse in={isCollapse || isAllViewTrue} timeout="auto" unmountOnExit> */}
          <Collapse in={isCollapse} timeout="auto" unmountOnExit>
            <>
              {actionIdsArr.length > 0 ? (
                <Typography
                  className="md-text font-semibold"
                  sx={{
                    paddingLeft: '60px',
                    height: '4rem',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'border.tableRow',
                  }}
                >
                  Actions
                </Typography>
              ) : null}
              {actionsByPage?.length > 0 &&
                actionsByPage?.map(actionData => (
                  <>
                    <Actions isAllViewTrue={isAllViewTrue} key={actionData?._id} data={actionData} pageId={pageId} />{' '}
                  </>
                ))}
            </>
          </Collapse>
        </TableCell>
      </SBTableRow>
    </>
  );
}

const StyledTableHeadCell = styled(TableHeadCell)<{ align?: string }>(({ align }) => ({
  textAlign: align || 'center',
  backgroundColor: '#ccc',
  color: '#344054',
  // paddingLeft: theme.spacing(6),
  // textAlign: 'center',
}));

const StyledTableRowCell = styled(TableRowCell)<{ align?: string }>(({ theme, align }) => ({
  textAlign: align || 'center',
  // backgroundColor: '#ccc',
  // color: '#344054',
  paddingLeft: theme.spacing(2),
  '& div': {
    padding: '0 !important',
  },
}));

interface Row {
  name: string;
  pageId: string;
  actionIdsArr: string[] | [];
}
