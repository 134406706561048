import { DeleteRatesParams, RateObj, RateState } from '@Reducers/RateSummary/Type';

function getEditURI(rate: RateObj, RateConfig: RateState['config']) {
  const redirectURI = new URL(`/pricing/${RateConfig.pageType}/edit`, window.location.origin);
  redirectURI.searchParams.append('accountNumber', rate.accountNumber || 'default');
  redirectURI.searchParams.append('documentRates', rate.documentRates + '');
  redirectURI.searchParams.append('startDate', rate.startDate);
  redirectURI.searchParams.append('endDate', rate.endDate);
  redirectURI.searchParams.append('csp', rate.csp);
  redirectURI.searchParams.append('system', rate.system);
  redirectURI.searchParams.append('isImport', RateConfig.isImport + '');
  redirectURI.searchParams.append('isMarkup', rate.isMarkup + '');
  const rateUploadType = 'data' in rate ? 'markup' : 'sheet';
  redirectURI.searchParams.append('rateUploadType', rateUploadType);
  return redirectURI;
}

function getDeleteParams(rate: RateObj, RateConfig: RateState['config']) {
  const params: DeleteRatesParams = {
    _courierId: rate.csp,
    _systemId: rate.system,
    accountNumber: rate.accountNumber,
    documentRates: rate.documentRates,
    endDate: rate.endDate,
    startDate: rate.startDate,
    isImport: RateConfig.isImport,
    isMarkup: 'data' in rate,
  };
  return params;
}

export { getEditURI, getDeleteParams };
