import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import { AppDispatch, RootStateType } from '@Store';

import Title from '@Components/Title';
// import x from '@Components/Ta';

import Overview from './components/Overview';

import CancelAndTrackShipment from '@Components/CancelAndTrackShipment';
import Loader from '@Components/Loader';
import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { resetShipmentError } from '@Reducers/AdminShipment';
import {
  getAdminShipmentById,
  getAdminShipmentError,
  getConnectOnWarehouse,
  getShipmentPaymentBySN,
} from '@Reducers/AdminShipment/actions';
import { getShipmentSettings } from '@Reducers/OrganizationSetup';
import PickupDropoff from './components/PickupDropoff';
import ShipmentCharges from './components/ShipmentCharges';
import ShipmentDetails from './components/ShipmentDetails';
import ShipmentError from './components/ShipmentError';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import ShipmentNotes from './components/ShipmentNotes';
import ShipmentPayment from './components/ShipmentPayments';
import ShipmentProcessingV2 from './components/ShipmentProcessingV2';
import ShipperConsignee from './components/ShipperConsignee';
import SuccessError from './components/SuccessError';
import TabsList from './components/Tabs';
import TrackingDetails from './components/TrackingDetails';
import { getCreditLimit } from '@Reducers/Credit';
import BackButton from '@Components/BackButton';
import { useWebsocket } from '@Hook/useWebsocket';
import { fetchCarrierData } from '@Reducers/integration';

export function AdminSingleShipment() {
  const dispatch = useDispatch<AppDispatch>();
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipperConsigneeDetailsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipper_or_consignee_details,
    PERFORM_ACTION.read,
  );

  const isPickupDropoffAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.pick_up_or_drop_off,
    PERFORM_ACTION.read,
  );

  const isShipmentDetailsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_details,
    PERFORM_ACTION.read,
  );

  const isShipmentProcessingAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_processing,
    PERFORM_ACTION.read,
  );

  const isTrackingDetailsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.tracking_details,
    PERFORM_ACTION.read,
  );

  const isShipmentChargesAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_charges,
    PERFORM_ACTION.read,
  );

  const isShipmentNotesAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_notes,
    PERFORM_ACTION.read,
  );

  const isPaymentAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.payment,
    PERFORM_ACTION.read,
  );

  const { sn: shipmentNumber } = useParams();
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const loading = useSelector(
    (state: RootStateType) =>
      state.adminShipment.loading ||
      state.admin.loading ||
      state.Warehouse.loading ||
      state.trackShipment.loading ||
      state.shipmentHistory.loading ||
      state.getRates.loading ||
      state.shipment.loading,
  );
  const isUnprocessedShipment = shipmentById?.status === SHIPMENT_STATUS.unprocessed.value;

  useEffect(() => {
    if (shipmentNumber) {
      dispatch(fetchCarrierData());
      dispatch(getShipmentSettings());
      dispatch(getAdminShipmentById(shipmentNumber));
      dispatch(getShipmentPaymentBySN(shipmentNumber));
      dispatch(getConnectOnWarehouse(shipmentNumber));
    }
    () => {
      dispatch(resetShipmentError());
    };
  }, [shipmentNumber]);

  useEffect(() => {
    if (isUnprocessedShipment && shipmentNumber) {
      dispatch(getAdminShipmentError(shipmentNumber));
    }
  }, [shipmentById?.status]);

  useEffect(() => {
    if (shipmentById?._accountId?._id) dispatch(getCreditLimit(shipmentById._accountId._id));
  }, [shipmentById?._accountId]);

  useWebsocket({
    query: {
      channel: 'shipment-update-' + shipmentNumber,
    },
  });

  return (
    <Grid container>
      <Loader loading={loading} overly>
        <>
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <BackButton link="/shipment/history" />
            <Title title="Shipment Details " removeBorder />
          </Box>
          <SuccessError />
          <Overview />
          <Grid container justifyContent={'space-between'}>
            <ShipmentError sellingChargeMsgShow={false} />
            {shipmentById?.status && (
              <CancelAndTrackShipment shipmentStatus={shipmentById.status} accountId={shipmentById?._userId._id} />
            )}
          </Grid>
          <TabsList />
          {isShipperConsigneeDetailsAllowed && <ShipperConsignee />}
          {isPickupDropoffAllowed && <PickupDropoff />}
          {isShipmentDetailsAllowed && <ShipmentDetails />}
          {!isUnprocessedShipment && (
            <>
              {/*   {isShipmentProcessingAllowed && <ShipmentProcessing />} */}
              {isShipmentProcessingAllowed && <ShipmentProcessingV2 />}
              {isShipmentNotesAllowed && <ShipmentNotes />}
              {isTrackingDetailsAllowed && <TrackingDetails />}
              {isShipmentChargesAllowed && <ShipmentCharges />}
              {isPaymentAllowed && <ShipmentPayment />}
            </>
          )}
        </>
      </Loader>
    </Grid>
  );
}

export default AdminSingleShipment;

// Below files are not used anytwhere, thus remove :
// src/pages/ViewSingleShipmentAdmin/components/BoxDimensionsInfo
// src/pages/ViewSingleShipmentAdmin/components/BoxesDetails
