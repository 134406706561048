import { Box } from '@mui/material';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import { userLogin } from '@Services/hasAdminAccess';

export interface Shipment {
  id: string;
  status: string;
  account: string;
  accountId: string;
  statusLabel: string;
}

interface TrackingTableProps {
  shipments: Shipment[] | undefined;
}

const TrackingTable: React.FC<TrackingTableProps> = ({ shipments }) => {
  const isUserLogin = userLogin();

  return (
    <Box>
      <Box sx={{ fontWeight: '500', fontSize: '24px', lineHeight: '20px' }}>
        <p>Action Pending </p>
      </Box>
      <TableContainer sx={{ marginTop: '30px', border: 'none', boxShadow: 'none', height: '500px' }} component={Paper}>
        <Table sx={{ border: 'none' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '16px', lineHeight: '20px', fontWeight: 400, color: '#181D25' }}>
                Shipment No
              </TableCell>
              <TableCell sx={{ fontSize: '16px', lineHeight: '20px', fontWeight: 400, color: '#181D25' }}>
                Status
              </TableCell>
              <TableCell sx={{ fontSize: '16px', lineHeight: '20px', fontWeight: 400, color: '#181D25' }}>
                Account
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shipments?.map((shipment: Shipment) => (
              <TableRow key={shipment.id}>
                <TableCell
                  sx={{
                    color: 'rgba(13, 124, 255, 1)',
                    textDecoration: 'underline',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: 400,
                  }}
                >
                  <Link
                    to={isUserLogin ? `/shipment/history/${shipment.id}` : `/shipment/admin/edit/${shipment.id}`}
                    style={{
                      textDecoration: 'none',
                      color: 'rgba(13, 124, 255, 1)', // Same color for unvisited and visited states
                    }}
                  >
                    {shipment.id}
                  </Link>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    color:
                      shipment.statusLabel === 'At Warehouse'
                        ? '#020597'
                        : shipment.statusLabel === 'Shipment Exception'
                        ? '#CB0000'
                        : shipment.statusLabel === 'RTO'
                        ? '#FFE5E5'
                        : 'rgba(255, 122, 122, 1)', // Default color
                  }}
                >
                  {shipment.statusLabel}
                </TableCell>
                <TableCell sx={{ fontSize: '16px', lineHeight: '20px', fontWeight: 400 }}>
                  <Box sx={{ width: '12rem', wordBreak: 'break-word' }}>{shipment?.accountId}</Box>
                  <Box sx={{ color: '#03A01C' }}>{shipment.account}</Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TrackingTable;
