// src/redux/slices/exportSlice.ts
import API from '@Services/apiAxios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// types.ts
export interface DownloadTransactionResponse {
  msg: string;
  data: {
    url: string;
  };
}

export interface ExportState {
  loading: boolean;
  error: string | null;
  url: string | null;
}

export const fetchTransactionExport = createAsyncThunk<
  DownloadTransactionResponse,
  { fromDate: string; toDate: string },
  { rejectValue: string }
>('export/fetchTransactionExport', async ({ fromDate, toDate }, { rejectWithValue }) => {
  try {
    const response = await API.get<DownloadTransactionResponse>('/payment/transaction/download-all', {
      params: { fromDate, toDate },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || 'Failed to fetch export URL');
  }
});

const initialState: ExportState = {
  loading: false,
  error: null,
  url: null,
};

const exportSlice = createSlice({
  name: 'downloadTransactionReport',
  initialState,
  reducers: {
    resetExportState: () => initialState, // Reset action to reset state to initialState
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTransactionExport.pending, state => {
        state.loading = true;
        state.error = null;
        state.url = null;
      })
      .addCase(fetchTransactionExport.fulfilled, (state, action) => {
        state.loading = false;
        state.url = action.payload.data.url;
      })
      .addCase(fetchTransactionExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Unknown error occurred';
      });
  },
});

export const { resetExportState } = exportSlice.actions; // Export the reset action

export default exportSlice.reducer;
