import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RangeKeyDict, Range } from 'react-date-range';
import { Grid } from '@mui/material';

// COMPONENTS
import DateRangeCalendar from '@Components/DateRangeCalendar';

import { AppDispatch, RootStateType } from '@Store';
import { changeNavigatedDate } from '@Reducers/ODA';

function OdaDateRange() {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedRange, setSelectedRange] = useState<Range[]>([
    { startDate: new Date(), endDate: new Date(), key: 'selection' },
  ]);
  const OdaState = useSelector((state: RootStateType) => state.oda);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(
    function () {
      dispatch(changeNavigatedDate({ selectedDate: selectedDate.toString() }));
    },
    [selectedDate],
  );

  useEffect(
    function () {
      const selectedRange = OdaState.mergedChargesArr.map(({ startDate, endDate }) => ({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
      }));
      selectedRange.unshift({ startDate: selectedDate, endDate: selectedDate, key: 'selection' });
      selectedRange.push({ startDate: selectedDate, endDate: selectedDate, key: 'selection' });
      setSelectedRange(selectedRange);
    },
    [OdaState.data.filteredList, selectedDate],
  );

  const onDateChange = (range: RangeKeyDict) => {
    return setSelectedDate(range?.selection?.startDate || new Date());
  };

  return (
    <>
      <Grid container>
        <Grid item>
          <DateRangeCalendar date={selectedDate} ranges={selectedRange} onChange={onDateChange} />
        </Grid>
      </Grid>
    </>
  );
}

export default OdaDateRange;
