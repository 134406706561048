import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UploadDocument from './UploadDocument';
import DocumentUploadSuccessfully from './DocumentUploadSuccessfully';
import DocumentDelete from './DocumentDelete';
import { Grid } from '@mui/material';
import SingleDocument from './SingleDocument';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';

import { DocumentUploadPropsType } from './Type';
import { getKycSetting } from '@Reducers/Global';
import { KycType } from '@Reducers/kyc/Type';
import { docUploadSucModalClose, getLoggedInUserKyc } from '@Reducers/kyc';
import { getAccountType } from '@Services/user';

function DocumentUpload(_props: DocumentUploadPropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const kycSetting = useSelector((state: RootStateType) => state.global.kycSetting);
  const profile = useSelector((state: RootStateType) => state.loginUser.profile);
  const documentUploadSuccessfullyModal = useSelector(
    (state: RootStateType) => state.kyc.documentUploadSuccessfullyModal,
  );
  const _kyc = useSelector((state: RootStateType) => state.kyc.kyc);

  const [uploadDocumentModal, setUploadDocumentModal] = useState<boolean>(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState<boolean>(false);
  const [successfullyModal, setSuccessfullyModal] = useState<boolean>(false);
  const [documentLabel, setDocumentLabel] = useState<string>('');
  const [documentEdit, setDocumentEdit] = useState<KycType | null>(null);
  const [deleteDocId, setDeleteDocId] = useState<string>('');

  useEffect(() => {
    dispatch(getKycSetting());
    dispatch(getLoggedInUserKyc());
  }, []);

  const individualDocumentLists = useMemo(() => {
    if (kycSetting && profile && profile._accountId) {
      const type = getAccountType(profile._accountId);
      switch (type) {
        case 'account-individual':
          return kycSetting.individualAccountHolder.list;
        case 'account-individual-company':
          return kycSetting.individualAccountHolder.list;
        case 'individual':
          return kycSetting.nonAccountHolder.list;
        default:
          return [];
      }
    }
    return [];
  }, [kycSetting]);

  useEffect(() => {
    if (documentUploadSuccessfullyModal) setSuccessfullyModal(true);
  }, [documentUploadSuccessfullyModal]);

  const uploadDocumentModalClose = () => setUploadDocumentModal(false);
  const uploadDocumentModalOpen = () => setUploadDocumentModal(true);

  const successModalClose = () => {
    dispatch(docUploadSucModalClose());
    uploadDocumentModalClose();
    setSuccessfullyModal(false);
    dispatch(getLoggedInUserKyc());
  };

  const onDocumentUploadModalOpen = useCallback((documentLabel: string, documentData: KycType | null): void => {
    setDocumentLabel(documentLabel);
    setDocumentEdit(documentData);

    uploadDocumentModalOpen();
  }, []);

  const deleteDocumentModalClose = (refetch: boolean) => {
    setDeleteDocId('');
    setDeleteDocumentModal(false);
    if (refetch) dispatch(getLoggedInUserKyc());
  };

  const onDocumentDeleteModalOpen = useCallback((id: string): void => {
    setDeleteDocId(id);
    setDeleteDocumentModal(true);
  }, []);
  // NEED TO CHECKED TODO:NAVIN

  return (
    <>
      <UploadDocument
        open={uploadDocumentModal}
        onClose={uploadDocumentModalClose}
        documentLabel={documentLabel}
        documentData={documentEdit}
        userId={profile?._id || ''}
      />
      <DocumentUploadSuccessfully open={successfullyModal} onClose={successModalClose} />
      <DocumentDelete
        userId={profile?._id || ''}
        id={deleteDocId}
        open={deleteDocumentModal}
        onClose={deleteDocumentModalClose}
      />
      <Grid container item xs={12} md={12} lg={5}>
        {individualDocumentLists.map((kyc, index) => (
          <SingleDocument
            key={`${kyc.label}-${index}`}
            label={kyc.label}
            required={kyc.isRequired}
            documentData={_kyc?.[kyc.label] || null}
            onDocumentUploadModalOpen={onDocumentUploadModalOpen}
            onDocumentDeleteModalOpen={onDocumentDeleteModalOpen}
          />
        ))}
      </Grid>
    </>
  );
}

export default DocumentUpload;
