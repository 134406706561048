import { DraftShipmentCreateResponse, DraftShipmentListResponse, DraftShipmentState } from './Type';

function getDraftShipmentListPending(state: DraftShipmentState) {
  state.loading = true;
}

function getDraftShipmentListFulfilled(state: DraftShipmentState, action: { payload: DraftShipmentListResponse }) {
  state.loading = false;
  state.lists = action.payload.data;
}

function getDraftShipmentListRejected(state: DraftShipmentState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function createDraftShipmentPending(state: DraftShipmentState) {
  state.loading = true;
}

function createDraftShipmentFulfilled(state: DraftShipmentState, _data: { payload: DraftShipmentCreateResponse }) {
  // state.isRedirect = true;
  state.loading = false;
}

function updateDraftShipmentFulfilled(state: DraftShipmentState, data: { payload: DraftShipmentCreateResponse }) {
  state.singeDraftShipment = data.payload.data;
  state.loading = false;
}

function createDraftShipmentRejected(state: DraftShipmentState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function getDraftShipmentByIdPending(state: DraftShipmentState) {
  state.loading = true;
}

function getDraftShipmentByIdFulfilled(state: DraftShipmentState, data: { payload: DraftShipmentCreateResponse }) {
  state.singeDraftShipment = data.payload.data;
  state.loading = false;
}

function getDraftShipmentByIdRejected(state: DraftShipmentState) {
  state.loading = false;
  state.error = { msg: 'The desired DraftShipment does not exist.', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function deleteDraftShipmentPending(state: DraftShipmentState) {
  state.loading = true;
}

function deleteDraftShipmentFulfilled(state: DraftShipmentState, data: { payload: DraftShipmentCreateResponse }) {
  state.singeDraftShipment = data.payload.data;
  state.loading = false;
}

function deleteDraftShipmentRejected(state: DraftShipmentState) {
  state.loading = false;
  state.error = { msg: 'The desired DraftShipment does not exist.', isError: true };
}

export {
  getDraftShipmentListPending,
  getDraftShipmentListFulfilled,
  getDraftShipmentListRejected,
  //
  createDraftShipmentPending,
  createDraftShipmentFulfilled,
  createDraftShipmentRejected,
  //
  getDraftShipmentByIdPending,
  getDraftShipmentByIdFulfilled,
  getDraftShipmentByIdRejected,
  //
  updateDraftShipmentFulfilled,

  //
  deleteDraftShipmentPending,
  deleteDraftShipmentFulfilled,
  deleteDraftShipmentRejected,
};
