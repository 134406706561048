import React, { useState } from 'react';
import { Tabs, Tab, Box, useMediaQuery, useTheme } from '@mui/material';

interface CustomTabsProps {
  tabs: string[];
  tabContent: Record<string, React.ReactNode>; // An object mapping tab labels to content
}

const CustomTabs: React.FC<CustomTabsProps> = ({ tabs, tabContent }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  // Use media query to adjust the layout on smaller screens
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Tabs
        sx={{
          maxWidth: isSmallScreen ? '100%' : '50%', // Full width on small screens
          width: '100%',
          flexDirection: isSmallScreen ? 'column' : 'row', // Stack tabs vertically on small screens
        }}
        value={activeTab}
        onChange={handleTabChange}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab}
            sx={{
              backgroundColor: activeTab === index ? theme.palette.primary.main : 'rgba(243, 243, 243, 1)',
              borderRadius: activeTab === index ? '10px' : 0,
              color: activeTab === index ? 'white' : 'black',
              marginBottom: isSmallScreen ? '10px' : '0', // Add margin for vertical tabs on small screens
              '&.Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                borderBottom: 'none',
                // border: '2px solid red',
              }, // Text color
            }}
          />
        ))}
      </Tabs>
      <Box sx={{ border: 'none' }}>
        {/* Content for each tab */}
        {tabContent[tabs[activeTab]]}
      </Box>
    </Box>
  );
};

export default CustomTabs;
