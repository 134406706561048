import SBDropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import SbTextField from '@Components/Textfield';
import { shipmentAmtRefund } from '@Reducers/Admin';
import { TransactionsIdType } from '@Reducers/AdminShipment/Type';
import { getShipmentPaymentBySN } from '@Reducers/Shipment/actions';
import { AppDispatch, RootStateType } from '@Store';
import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Actions from '../Actions';

// const PAYMENT_OPTIONS: DropdownOptionType[] = [{ display: 'Wallet', value: 'wallet' }];
const modeOptionsMap: { [key: string]: DropdownOptionType } = {
  wallet: { display: 'Wallet', value: 'wallet' },
  gateway: { display: 'Gateway', value: 'gateway' },
  cash: { display: 'Cash', value: 'cash' },
};

interface Props {
  onCancel: () => void;
}

function Refund(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const shipmentPayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);
  // const { shipmentNumber, payableAmount } = shipmentPayment || {};
  const { shipmentNumber, payableAmount, amount } = shipmentPayment || {};
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const { orgCountryObj } = useSelector((state: RootStateType) => state?.orgSetup);

  // const PAYMENT_OPTIONS: DropdownOptionType[] = useMemo(() => {
  //   if (!shipmentPayment?._transactionsId?.length) return [];
  //   return Object.values(
  //     shipmentPayment?._transactionsId.reduce((op, t) => {
  //       if (!op[t.mode] && t.paymentTransactionId && t.type !== 'refund')
  //         op[t.mode] = { ...modeOptionsMap[t.mode], metadata: t };
  //       return op;
  //     }, {} as { [key: string]: DropdownOptionType<TransactionsIdType> }),
  //   );
  // }, [shipmentPayment?._transactionsId]);
  const getLastSuccessfulTransaction = (transactions: TransactionsIdType[] = []): TransactionsIdType | undefined => {
    const successfulTransactions = transactions.filter(transaction => transaction.status === 'successful');
    return successfulTransactions[successfulTransactions.length - 1];
  };
  const PAYMENT_OPTIONS: DropdownOptionType[] = useMemo(() => {
    if (!shipmentPayment?._transactionsId?.length) return [];

    const optionsMap = shipmentPayment?._transactionsId.reduce((op, t) => {
      if (!op[t.mode] && t.paymentTransactionId && t.type !== 'refund') {
        op[t.mode] = { ...modeOptionsMap[t.mode], metadata: t };
      }
      return op;
    }, {} as { [key: string]: DropdownOptionType<TransactionsIdType> });

    // Add 'Cash' option if it doesn't exist
    if (!optionsMap['cash']) {
      const lastSuccessfulTransaction = getLastSuccessfulTransaction(shipmentPayment?._transactionsId);
      optionsMap['cash'] = {
        display: 'Cash',
        value: 'cash',
        metadata: lastSuccessfulTransaction,
      };
    }

    // Add 'Wallet' option if it doesn't exist
    if (!optionsMap['wallet']) {
      const lastSuccessfulTransaction = getLastSuccessfulTransaction(shipmentPayment?._transactionsId);
      optionsMap['wallet'] = {
        display: 'Wallet',
        value: 'wallet',
        metadata: lastSuccessfulTransaction,
      };
    }

    return Object.values(optionsMap);
  }, [shipmentPayment?._transactionsId]);

  // console.info('PAYMENT_OPTIONS', PAYMENT_OPTIONS);
  // console.info({ shipmentPayment, shipmentById });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { mode: '', amount: Math.abs(payableAmount || 0), comment: '' },
    validationSchema: Yup.object().shape({
      mode: Yup.string().required(),
      amount: Yup.number()
        .min(0)
        // .max(Math.abs(payableAmount || 0))
        .max(Math.abs(shipmentById && shipmentById?.status === 'cancelled' ? amount || 0 : payableAmount || 0))
        .required('Amount is required.'),
      comment: Yup.string().trim().min(5).required('Comment is required.'),
    }),
    onSubmit(values) {
      // handleOnSubmit(values);

      if (shipmentNumber) {
        const selectedDropDownOption: DropdownOptionType | undefined = PAYMENT_OPTIONS.find(
          x => x.value === values.mode,
        );
        const metadata = selectedDropDownOption?.metadata as TransactionsIdType;

        if (!metadata?.paymentTransactionId) return;

        dispatch(
          shipmentAmtRefund({
            amount: values.amount?.toString() || '',
            comment: values.comment,
            shipmentNumber: shipmentNumber,
            transactionId: metadata.paymentTransactionId,
            currency: orgCountryObj?.currency?.name || 'INR',
            mode: values.mode || '',
          }),
        ).then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            setTimeout(() => {
              dispatch(getShipmentPaymentBySN(shipmentNumber));
            }, 2000);
            props.onCancel();
          }
        });
      }
    },
  });

  return (
    <Grid container p={4} boxShadow="0px 2px 4px 0px rgba(156, 156, 156, 0.25)">
      <Grid container item mb={1}>
        <Typography className="md-text">Refund</Typography>
      </Grid>
      <Grid container item spacing={2} mb={2}>
        <Grid item xs={12}>
          <SBDropdown
            id="paymentMode"
            options={PAYMENT_OPTIONS}
            label="Mode"
            name="mode"
            required
            value={formik.values.mode ? [formik.values.mode] : []}
            onChange={([value]) => {
              formik.setFieldValue('mode', value);
            }}
            disabled={!PAYMENT_OPTIONS.length}
            error={formik?.errors?.mode || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <SbTextField
            label="Amount"
            required
            name="amount"
            value={formik.values.amount}
            type="number"
            number
            // disabled={!!formik.values.amount}
            error={formik?.errors?.amount || ''}
            onChange={formik.handleChange}
            disabled={!PAYMENT_OPTIONS.length}
          />
        </Grid>
        <Grid item xs={12}>
          <SbTextField
            label="Comment"
            name="comment"
            value={formik.values.comment}
            error={formik?.errors?.comment || ''}
            onChange={formik.handleChange}
            disabled={!PAYMENT_OPTIONS.length}
          />
        </Grid>
      </Grid>
      {PAYMENT_OPTIONS.length ? <Actions onCancel={props.onCancel} onSave={formik.handleSubmit} /> : <></>}
    </Grid>
  );
}
export default Refund;
