/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { setDocumentUploadModalOpen, toggleTransitHub } from '.';
import API from '../../../services/apiAxios';
import { SuccessPayload } from '../../../types';
import {
  AddShipmentNotesType,
  CarrierNumberUpdatePayload,
  ConnectOnWarehouse,
  ErrorType,
  FedXInformationPayloadType,
  FedXInformationType,
  FirstConnectOnParamsType,
  LastConnectOnParamsType,
  NotesPayloadType,
  PlaceRTOShipmentParamsType,
  PlaceRTOShipmentSuccessResType,
  ShipmentByIdType,
  ShipmentCarrierAndCustomerRefNumber,
  ShipmentConnectOn,
  ShipmentError,
  ShipmentNotesType,
  ShipmentPricing,
  ShipmentSelectedCharges,
  ShipmentpaymentType,
  UpdatePickUpAndDropOff,
  UpdateShipmentPayload,
  UpdateShipmentRes,
  getFedExDetailsType,
  locationsPayload,
  locationsResponse,
  postPickupDetailsType,
  updateExtraChargePayloadType,
  updateGlobalChargePayloadType,
  uploadFedExDocumentPayloadType,
  uploadFedExImagePayloadType,
  vendorAccountType,
  FedexErrorResponse,
  postDHLPayload,
  postDhlVerifyOtpPayload,
  CirroInformationPayloadType,
  CirroErrorResponse,
} from './Type';
import { RootStateType } from '@Store';

const getAdminShipmentById = createAsyncThunk<{ data: ShipmentByIdType }, string, { rejectValue: ErrorType }>(
  'getAdminShipmentById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await API.get(`/shipment/${id}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const placeRTOShipment = createAsyncThunk<
  PlaceRTOShipmentSuccessResType,
  PlaceRTOShipmentParamsType,
  { rejectValue: ErrorType }
>('placeRTOShipment', async (params, { rejectWithValue }) => {
  try {
    const res = await API.post(`/shipment/place/rto`, params);
    return res.data.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const getAdminShipmentPricing = createAsyncThunk<SuccessPayload<ShipmentPricing[]>, string, { rejectValue: ErrorType }>(
  'getAdminShipmentPricing',
  async (id, { rejectWithValue }) => {
    try {
      const res = await API.get(`/shipment/admin/pricing/${id}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const getAdminShipmentError = createAsyncThunk<{ data: ShipmentError }, string, { rejectValue: ErrorType }>(
  'getAdminShipmentError',
  async (id, { rejectWithValue }) => {
    try {
      const res = await API.get(`/shipment/admin/error/${id}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const getAdminShipmentNotes = createAsyncThunk<SuccessPayload<ShipmentNotesType[]>, string, { rejectValue: ErrorType }>(
  'getAdminShipmentNotes',
  async (id, { rejectWithValue }) => {
    try {
      const res = await API.get(`/shipment/admin/notes/${id}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const addAdminShipmentNotes = createAsyncThunk<AddShipmentNotesType, NotesPayloadType, { rejectValue: ErrorType }>(
  'addAdminShipmentNotes',
  async ({ id, message, _taggedUserId }, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.post(`/shipment/admin/notes/${id}`, { message, _taggedUserId });
      dispatch(getAdminShipmentNotes(id));
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const updateShipment = createAsyncThunk<UpdateShipmentRes, UpdateShipmentPayload, { rejectValue: ErrorType }>(
  'updateAdminShipment',
  async (
    { shipmentNumber, shipper, consignee, package: _package, extraDetails, carrierDetails, isFinalForward },
    { rejectWithValue, dispatch },
  ) => {
    const postData: UpdateShipmentPayload = { shipmentNumber, extraDetails, carrierDetails, isFinalForward };
    if (shipper) {
      postData['shipper'] = shipper;
    }
    if (consignee) {
      postData['consignee'] = consignee;
    }
    if (_package) {
      postData['package'] = _package;
    }
    try {
      const res = await API.put(`/shipment/admin/${shipmentNumber}`, postData);
      setTimeout(function () {
        dispatch(getAdminShipmentById(shipmentNumber));
        dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
        // dispatch(trackShipment(shipmentNumber));
      }, 2000);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const getShipmentPaymentBySN = createAsyncThunk<
  SuccessPayload<ShipmentpaymentType>,
  string,
  { rejectValue: ErrorType }
>('getShipmentPaymentBySN', async (id, { rejectWithValue }) => {
  try {
    const res = await API.get(`/shipment/transaction/${id}`);
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const updateShipmentSelectedCharges = createAsyncThunk<
  SuccessPayload,
  ShipmentSelectedCharges,
  { rejectValue: ErrorType }
>('updateShipmentSelectedCharges', async (params, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.put(`/shipment/admin/selected-charge/${params.shipmentNumber}`, params);
    dispatch(getAdminShipmentById(params.shipmentNumber));
    dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const updateCarrierAndCustomerRefNumber = createAsyncThunk<
  SuccessPayload,
  ShipmentCarrierAndCustomerRefNumber,
  { rejectValue: ErrorType }
>('updateCarrierAndCustomerRefNumber', async (params, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.put(`/shipment/admin/carrier-customer-ref-number/${params.shipmentNumber}`, params);
    dispatch(getAdminShipmentById(params.shipmentNumber));
    dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));

    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const updatePickUpAndDropOff = createAsyncThunk<SuccessPayload, UpdatePickUpAndDropOff, { rejectValue: ErrorType }>(
  'updatePickUpAndDropOff',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.put(`/shipment/admin/pickup-dropOff/${params.shipmentNumber}`, params);
      dispatch(getAdminShipmentById(params.shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      console.error('Error in updatePickUpAndDropOff:', error);
      if (error.response?.data?.msg) {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Error',
            subTitle: error.response?.data?.msg,
          }),
        );
      }
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const updateGlobalCharge = createAsyncThunk<SuccessPayload, updateGlobalChargePayloadType, { rejectValue: ErrorType }>(
  'updateGlobalCharge',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.put(`/shipment/admin/global-charge/${params.shipmentNumber}`, params);
      dispatch(getAdminShipmentById(params.shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));
      dispatch(getShipmentPaymentBySN(params.shipmentNumber));
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const shipmentLockRate = createAsyncThunk<SuccessPayload, string, { rejectValue: ErrorType }>(
  'shipmentLockRate',
  async (shipmentNumber, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.put(`/shipment/admin/lock-rate/${shipmentNumber}`);
      dispatch(getAdminShipmentById(shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));

      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const shipmentUnlockRate = createAsyncThunk<SuccessPayload, string, { rejectValue: ErrorType }>(
  'shipmentLockRate',
  async (shipmentNumber, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.put(`/shipment/admin/unlock-rate/${shipmentNumber}`);
      dispatch(getAdminShipmentById(shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));

      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const updateExtraCharge = createAsyncThunk<SuccessPayload, updateExtraChargePayloadType, { rejectValue: ErrorType }>(
  'updateExtraCharge',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.put(`/shipment/admin/extra-charge/${params.type}/${params.shipmentNumber}`, params);
      setTimeout(() => {
        dispatch(getAdminShipmentById(params.shipmentNumber));
        dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));
        dispatch(getShipmentPaymentBySN(params.shipmentNumber));
      }, 2000);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const connectOnWarehouse = createAsyncThunk<SuccessPayload, ShipmentConnectOn, { rejectValue: ErrorType }>(
  'connectOnWarehouse',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.put(`/shipment/admin/connect-on/${params.shipmentNumber}`, params);
      dispatch(getConnectOnWarehouse(params.shipmentNumber));
      dispatch(toggleTransitHub(false));
      setTimeout(() => {
        dispatch(getAdminShipmentById(params.shipmentNumber));
        dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));
        // dispatch(trackShipment(params.shipmentNumber));
      }, 2000);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const getConnectOnWarehouse = createAsyncThunk<
  SuccessPayload<ConnectOnWarehouse[]>,
  string,
  { rejectValue: ErrorType }
>('getConnectOnWarehouse', async (shipmentNumber, { rejectWithValue }) => {
  try {
    const res = await API.get(`/shipment/admin/connect-on/${shipmentNumber}`);
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const updateSingleBoxCarrierNumber = createAsyncThunk<
  SuccessPayload,
  CarrierNumberUpdatePayload,
  { rejectValue: ErrorType }
>('updateSingleBoxCarrierNumber', async (postData, { rejectWithValue, dispatch }) => {
  const { shipmentNumber, boxShipmentNumber } = postData;
  try {
    const res = await API.post(`/shipment/admin/box-carrier/${shipmentNumber}/${boxShipmentNumber}`, postData);
    dispatch(getAdminShipmentById(shipmentNumber));
    dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const updateFirstConnectingWarehouse = createAsyncThunk<
  SuccessPayload,
  FirstConnectOnParamsType,
  { rejectValue: ErrorType }
>('updateFirstConnectingWarehouse', async (params, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.put(`/shipment/admin/first-connecting-warehouse/${params.shipmentNumber}`, params);
    dispatch(getAdminShipmentById(params.shipmentNumber));
    dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const updateLastConnection = createAsyncThunk<SuccessPayload, LastConnectOnParamsType, { rejectValue: ErrorType }>(
  'updateLastConnection',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.put(
        `/shipment/admin/connect-on-details/${params.shipmentNumber}/${params.connectOnId}`,
        params,
      );
      dispatch(getAdminShipmentById(params.shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

export const postCirroDetails = createAsyncThunk<
  string,
  CirroInformationPayloadType,
  { rejectValue: CirroErrorResponse }
>('postCirroDetails/post', async ({ shipmentNumber, body, boxSize }, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.post(`/shipment/vendor/placeShipment/${shipmentNumber}`, body);

    dispatch(getShipmentWebsocketStream([`shipment-update-${shipmentNumber}`]));
    dispatch(getFedExDetails({ shipmentNumber, type: body.type, csp: body.csp }));

    if (boxSize && boxSize < 40) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Upload documents',
          subTitle: '',
          onConfirm: () => {
            dispatch(setDocumentUploadModalOpen(true));
            dispatch(SuccessErrorModalReset());
          },
          closeIcon: true,
          btnText: 'Now',
          secondaryBtnText: 'Later',
        }),
      );
    }

    return res.data?.data.surcharges || '';
  } catch (e) {
    const error: AxiosError<ErrorType | CirroErrorResponse> = e;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title:
          typeof error?.response?.data.msg !== 'object'
            ? error?.response?.data.msg || 'Please upload the following document to generate the AWB'
            : error?.response?.data?.msg?.message || 'Something went wrong!',
        subTitle:
          typeof error?.response?.data.msg === 'object'
            ? error?.response?.data?.msg?.code === 'STREETLINES.TOO.LONG'
              ? error?.response?.data?.msg?.code
              : ''
            : '',
      }),
    );

    if (!error.response) throw error;
    const errorData = error.response.data;

    if ('msg' in errorData && typeof errorData.msg === 'object') {
      return rejectWithValue(errorData as CirroErrorResponse);
    }

    return rejectWithValue({
      msg:
        typeof errorData?.msg === 'object'
          ? errorData.msg
          : { code: 'UNKNOWN_ERROR', message: errorData?.msg || 'An unknown error occurred' },
      isError: errorData?.isError ?? true,
    });
  }
});
const postFedExDetails = createAsyncThunk<
  string,
  FedXInformationPayloadType,
  { rejectValue: ErrorType | FedexErrorResponse }
>('postFedExDetails/post', async ({ shipmentNumber, body, date, nextFunc, boxSize }, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.post(`/shipment/vendor/placeShipment/${shipmentNumber}`, body);
    if (date) {
      dispatch(
        postPickupDetails({
          shipmentNumber,
          date,
          type: body.type,
          csp: body.csp,
        }),
      );
    } else {
      dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
      dispatch(getFedExDetails({ shipmentNumber, type: body.type, csp: body.csp }));
    }
    nextFunc();

    if (boxSize && boxSize < 40)
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Upload documents',
          subTitle: '',
          onConfirm: () => {
            dispatch(setDocumentUploadModalOpen(true));
            dispatch(SuccessErrorModalReset());
          },
          closeIcon: true,
          btnText: 'Now',
          secondaryBtnText: 'Later',
        }),
      );
    return res.data?.data.surcharges || '';
  } catch (e) {
    const error: AxiosError<ErrorType | FedexErrorResponse> = e;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title:
          typeof error?.response?.data.msg !== 'object'
            ? error?.response?.data.msg || 'Please upload the following document to generate the AWB'
            : error?.response?.data?.msg?.message || 'Some thing went wrong!',
        subTitle:
          typeof error?.response?.data.msg === 'object'
            ? error?.response?.data?.msg?.code === 'STREETLINES.TOO.LONG'
              ? error?.response?.data?.msg?.code
              : ''
            : '',
        // btnText: 'Upload',
        // onConfirm: () => {
        //   dispatch(setDocumentUploadModalOpen(true));
        //   dispatch(SuccessErrorModalReset());
        // },
      }),
    );

    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const postDHLDetails = createAsyncThunk<SuccessPayload, postDHLPayload, { rejectValue: ErrorType }>(
  'postDhlDetails',
  async ({ shipmentNumber, body }, { dispatch }) => {
    try {
      const res = await API.post(`/shipment/vendor/msme/${shipmentNumber}`, body);
      dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
      dispatch(getFedExDetails({ shipmentNumber, type: body.type, csp: body.csp }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      // dispatch(getDHLDetails({ shipmentNumber, type: body.type, csp: body.csp }));
      return res.data;
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title:
            typeof error?.response?.data.msg !== 'object'
              ? error?.response?.data.msg || ''
              : error?.response?.data?.msg?.msg || 'Some thing went wrong!',
          subTitle: '',
        }),
      );
      throw error;
    }
  },
);

const postDhlSendOtp = createAsyncThunk<
  SuccessPayload<{ Postshipment_Payload: string }>,
  postDHLPayload,
  { rejectValue: ErrorType }
>('postDhlSendOtp', async ({ shipmentNumber, body }, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.post(`/shipment/vendor/sendOtp/${shipmentNumber}`, body);
    dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
    return res.data;
  } catch (error) {
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title:
          typeof error?.response?.data.msg !== 'object'
            ? error?.response?.data.msg || ''
            : error?.response?.data?.msg?.msg || 'Some thing went wrong!',
        subTitle: '',
      }),
    );
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const postDhlVerifyOtp = createAsyncThunk<SuccessPayload, postDhlVerifyOtpPayload, { rejectValue: ErrorType }>(
  'postDhlVerifyOtp',
  async ({ shipmentNumber, body }, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.post(`/shipment/vendor/verifyOtp/${shipmentNumber}`, body);
      dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
      dispatch(getFedExDetails({ shipmentNumber, type: body.payload?.type, csp: body.payload?.csp }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: res?.data?.msg,
          subTitle: '',
        }),
      );
      return res.data;
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title:
            typeof error?.response?.data.msg !== 'object'
              ? error?.response?.data.msg || ''
              : error?.response?.data?.msg?.msg || 'Some thing went wrong!',
          subTitle: '',
        }),
      );
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const getVendorsAccount = createAsyncThunk<SuccessPayload<vendorAccountType[]>, string, { rejectValue: ErrorType }>(
  'getVendorsAccount',
  async (csp, { rejectWithValue }) => {
    try {
      const res = await API.get(`shipment/vendor/vendorAccounts?csp=${csp}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;

      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const postFedExDocument = createAsyncThunk<
  SuccessPayload,
  uploadFedExDocumentPayloadType,
  { rejectValue: ErrorType | FedexErrorResponse }
>('postFedExDocument', async (body, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.post(`shipment/vendor/uploadDocument`, body);
    dispatch(getFedExDetails({ shipmentNumber: body?.trackingNumber, csp: body.csp, type: body.type }));
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Uploaded Successfully',
        subTitle: '',
      }),
    );
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType | FedexErrorResponse> = e;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title:
          typeof error?.response?.data.msg !== 'object'
            ? error?.response?.data.msg || ''
            : error?.response?.data?.msg?.message || 'Some thing went wrong!',
        subTitle: '',
      }),
    );
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const postFedExDocumentImagesType = createAsyncThunk<
  SuccessPayload,
  uploadFedExImagePayloadType,
  { rejectValue: ErrorType | FedexErrorResponse }
>('postFedExDocumentImagesType', async (body, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.post(`shipment/vendor/uploadImage`, body);
    dispatch(getFedExDetails({ shipmentNumber: body?.referenceId, csp: body.csp, type: body.type }));
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Uploaded Successfully',
        subTitle: '',
      }),
    );
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType | FedexErrorResponse> = e;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title:
          typeof error?.response?.data.msg !== 'object'
            ? error?.response?.data.msg || ''
            : error?.response?.data?.msg?.message || 'Some thing went wrong!',
        subTitle: '',
      }),
    );
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const getFedExDetails = createAsyncThunk<
  SuccessPayload<FedXInformationType>,
  getFedExDetailsType,
  { rejectValue: ErrorType }
>('getFedExDetails', async ({ shipmentNumber, csp, type }, { rejectWithValue }) => {
  try {
    if (shipmentNumber && csp && type) {
      const res = await API.get(`shipment/vendor/${shipmentNumber}?csp=${csp}&type=${type}`);
      return res.data;
    }
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const getLocations = createAsyncThunk<
  SuccessPayload<locationsResponse[]>,
  locationsPayload,
  { rejectValue: ErrorType | FedexErrorResponse }
>('getLocations', async ({ shipmentNumber, csp }, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.post(`shipment/vendor/location/${shipmentNumber}?csp=${csp}`);
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType | FedexErrorResponse> = e;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title:
          typeof error?.response?.data.msg !== 'object'
            ? error?.response?.data.msg || ''
            : error?.response?.data?.msg?.message || 'Some thing went wrong!',
        subTitle: '',
      }),
    );
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const postPickupDetails = createAsyncThunk<
  SuccessPayload,
  postPickupDetailsType,
  { rejectValue: ErrorType | FedexErrorResponse }
>('postPickupDetails', async ({ date, shipmentNumber, csp, type }, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.post(`shipment/vendor/requestPickup/${shipmentNumber}`, {
      pickupDate: date,
      csp,
      type,
    });
    dispatch(getFedExDetails({ shipmentNumber, type, csp }));
    dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType | FedexErrorResponse> = e;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title:
          typeof error?.response?.data.msg !== 'object'
            ? error?.response?.data.msg || ''
            : error?.response?.data?.msg?.message || 'Some thing went wrong!',
        subTitle: '',
      }),
    );
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const getShipmentWebsocketStream = createAsyncThunk<{ data: ShipmentByIdType }, string[], { rejectValue: ErrorType }>(
  'getShipmentWebsocketStream',
  async (channels, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootStateType;
      const res = await API.get(`shipment/websocket/stream`, {
        params: {
          channels,
          connectionId: state?.adminShipment?.connectionId,
        },
      });
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

export {
  addAdminShipmentNotes,
  connectOnWarehouse,
  getAdminShipmentById,
  getAdminShipmentError,
  getAdminShipmentNotes,
  getAdminShipmentPricing,
  getConnectOnWarehouse,
  getFedExDetails,
  getLocations,
  getShipmentPaymentBySN,
  getVendorsAccount,
  placeRTOShipment,
  postFedExDetails,
  postFedExDocument,
  postFedExDocumentImagesType,
  postPickupDetails,
  shipmentLockRate,
  shipmentUnlockRate,
  updateCarrierAndCustomerRefNumber,
  updateExtraCharge,
  updateFirstConnectingWarehouse,
  updateGlobalCharge,
  updateLastConnection,
  updatePickUpAndDropOff,
  updateShipment,
  updateShipmentSelectedCharges,
  updateSingleBoxCarrierNumber,
  getShipmentWebsocketStream,
  postDHLDetails,
  postDhlSendOtp,
  postDhlVerifyOtp,
};
