import * as Yup from 'yup';

export const FedXInformationSchema = Yup.object().shape({
  // csp: Yup.string().nullable().trim().required('CSP is required'),
  accountNo: Yup.string().trim().required('Account number is required'),
  packagingType: Yup.string().trim().required('Packaging Type is required'),
  pickupType: Yup.string().trim().required('Pickup type is required'),
  payor: Yup.string().trim().required('Payor is required'),
  printer: Yup.string().trim().required('Laser for import shipments is required'),
  countryOfManufacture: Yup.string().trim().required('Country of manufacture is required'),
  bookingConfirmationNumber: Yup.string()
    .trim()
    .when('$serviceType', ([serviceType], schema) => {
      return serviceType === 'INTERNATIONAL_PRIORITY_FREIGHT'
        ? schema.required('Booking confirmation number is required')
        : schema;
    }),
  shipmentPurpose: Yup.string().trim().required('Shipment purpose is required'),
  dryIceShipments: Yup.array().when('$shipmentType', ([shipmentType], schema) => {
    return shipmentType && shipmentType.includes('DRY_ICE')
      ? schema
          .of(Yup.number().positive('Dry ice shipment must be a positive number'))
          .min(1, 'Dry ice shipment must be a positive number')
      : schema;
  }),
  battery: Yup.array()
    .of(Yup.string())
    .when('$shipmentType', ([shipmentType], schema) => {
      return shipmentType && shipmentType.includes('Lithium Batteries')
        ? schema.min(1, 'Lithium Batteries must be selected atleast one')
        : schema;
    }),
  specialHandlingOptions: Yup.array().when('$shipmentType', ([shipmentType], schema) => {
    return shipmentType && shipmentType.includes('Others')
      ? schema.of(Yup.string()).min(1, 'Select at least one special type')
      : schema;
  }),
  locationId: Yup.string()
    .trim()
    .when('$specialHandlingOptions', ([specialHandlingOptions], schema) => {
      return specialHandlingOptions && specialHandlingOptions.includes('HOLD_AT_LOCATION')
        ? schema.required('Location id is required')
        : schema;
    }),
  thirdPartyPayor: Yup.string()
    .trim()
    .when('$payor', ([payor], schema) => {
      return payor === 'THIRD_PARTY' ? schema.required('Third party Payor is required') : schema;
    }),
  shippersLoadAndCount: Yup.number(),
  dangerousGoodsType: Yup.string()
    .trim()
    .when('$shipmentType', ([shipmentType], schema) => {
      return shipmentType && shipmentType.includes('DG shipments')
        ? schema.required('Dangerous Goods Type is required')
        : schema;
    }),
  attachedDocuments: Yup.array(),
  invoiceNo: Yup.string().required('Invoice No is required'),
  billDutiesAndTaxesTo: Yup.string().trim().required('Bill Duties and Taxes to is required'),
  billDutiesAccountNo: Yup.string()
    .trim()
    .when('$billDutiesAndTaxesTo', ([billDutiesAndTaxesTo], schema) => {
      return billDutiesAndTaxesTo === 'THIRD_PARTY' ? schema.required('Bill Duties Account No is required') : schema;
    }),
  poNo: Yup.string().trim().required('PO No is required'),
  customerReferenceNo: Yup.number(),
  csDetails: Yup.string().trim().required('Cs details is required'),
  serviceType: Yup.string().trim().required('Service Type is required'),
  shipperShipmentType: Yup.string().trim().required('Shipper shipment type is required'),
  shipmentIdType: Yup.string().trim().required('Shipper ID Type is required'),
  shipmentId: Yup.string().trim().required('Shipper ID  is required'),
  shipmentType: Yup.array().of(Yup.string()),
  b13AFilingOption: Yup.string().trim(),
  // harmonizedCode: Yup.string().trim(),
  broker: Yup.object()
    .shape({
      accountNo: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Account No. is required')
            : schema;
        }),
      shipmentType: Yup.string().trim(),
      tinNo: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Tino No is required')
            : schema;
        }),
      phoneNumber: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Phone Number is required')
            : schema;
        }),
      telephoneCode: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Telephone Code is required')
            : schema;
        }),
      contactId: Yup.string().trim(),
      companyName: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Company Name is required')
            : schema;
        }),
      name: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Name is required')
            : schema;
        }),
      title: Yup.string().trim(),
      faxNo: Yup.string().trim(),
      email: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Email is required').email('Invalid email address')
            : schema;
        }),
      line1: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Address Line1 is required')
            : schema;
        }),
      line2: Yup.string().trim(),
      city: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('City is required')
            : schema;
        }),
      state: Yup.string().trim(),
      country: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Country is required')
            : schema;
        }),
      pincode: Yup.string()
        .trim()
        .when('$shipmentType', ([shipmentType], schema) => {
          return shipmentType && shipmentType.includes('BROKER_SELECT_OPTION')
            ? schema.required('Pincode is required')
            : schema;
        }),
    })
    .nullable(),
  commercialRoutingNo: Yup.string()
    .trim()
    .when('$shipmentType', ([shipmentType], schema) => {
      return shipmentType && shipmentType.includes('Commercial')
        ? schema.required('Commercial Routing Number is required')
        : schema;
    }),
  exportComplianceStatement: Yup.string().trim(),
  pickupDate: Yup.string()
    .trim()
    .when('$pickupType', ([pickupType], schema) => {
      return pickupType === 'USE_SCHEDULED_PICKUP' ? schema.required('Pickup date is required') : schema.nullable();
    }),
});
