import { Grid } from '@mui/material';
import React from 'react';
import SbButton from '../../../components/Button';
import GetRatesContext from '../../../context/GetRates';
import { GetRatesContextType } from '../Type';
import { AppDispatch, RootStateType } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getRates } from '../../../store/reducers/GetRates';
import { SHIPMENT_TYPES } from '../Constant';
import { useNavigate } from 'react-router';
import { RatesType } from '@Reducers/GetRates/Type';
import { getDefaultRates } from '@Reducers/GetRates/actions';
// import ShipmentWeightValidationModal from '@Components/ShipmentWeightValidationModal/ShipmentWeightValidationModal';

// let postObj = {
//   destination: { city: 'Mumbai', state: 'Maharashtra', country: 'India', pincode: '400008' },
//   source: { city: 'Mumbai', state: 'Maharashtra', country: 'India', pincode: '400001' },
//   shipmentType: 'parcel',
//   userId: '63bd4bf3abcd13bf437829ba',
//   weight: '200',
//   package: {
//     box: [
//       { weight: '0', length: 0, width: 0, height: 0 },
//       { weight: '0', length: 0, width: 0, height: 0 },
//     ],
//     unitOfMeasurement: 'kg',
//   },
//   document: false,
// };

interface ActionsProps {
  onNext?: (event: React.SyntheticEvent) => void;
  isTarrifPricing: boolean;
}

function GetRatesAction({ isTarrifPricing }: ActionsProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { token } = useSelector((state: RootStateType) => state.loginUser);

  const { ratesInputHasError, ratesInput, boxes, chargeableWeight, userId, setReduxState } = React.useContext(
    GetRatesContext,
  ) as GetRatesContextType;

  // const [weightValidation, setWeightValidation] = useState<boolean>(false);

  function handleGetRates() {
    const { destination, destinationPinCode, pickup, shipmentType, weight, weightUnit, pickupPinCode } = ratesInput;
    // if (!weightValidation && shipmentType === 'parcel' && weight !== chargeableWeight) {
    //   setWeightValidation(true);
    //   return;
    // }
    // setWeightValidation(false);
    const finalWeight = chargeableWeight > Number(weight) ? chargeableWeight : Number(weight);
    const obj: RatesType = {
      destination: { ...destination, pincode: destinationPinCode?.toString() },
      source: { ...pickup, pincode: pickupPinCode.toString() },
      shipmentType,
      weight: Number(weight),
      package: {
        box: boxes.map((box, ind) => ({
          weight: Number(box.weight) < 1 && ind === 0 ? finalWeight.toString() : box.weight.toString(),
          length: typeof box.length === 'string' ? parseInt(box.length) : box.length,
          width: typeof box.width === 'string' ? parseInt(box.width) : box.width,
          height: typeof box.height === 'string' ? parseInt(box.height) : box.height,
        })),
        unitOfMeasurement: weightUnit === 'kg' ? 'kg-cm' : 'lb-in',
      },
      document: shipmentType === SHIPMENT_TYPES[1].value,
      dropOff: true,
    };

    setReduxState();

    if (token?._id) {
      if (isTarrifPricing) {
        dispatch(getRates([{ ...obj, rateFor: 'get-rates' }, true]));
      } else {
        dispatch(getRates([{ ...obj, userId, rateFor: 'get-rates' }, false]));
      }
    } else {
      dispatch(getDefaultRates([{ ...obj, rateFor: 'get-rates' }, true]));
    }
    // navigate('/get-rates', { state: { pickup, destination, boxes, weight, weightUnit } });
    // navigate('/get-rates');
  }
  const handleBack = () => {
    navigate('/login');
  };

  // console.info('ratesInputHasError:', ratesInputHasError);

  return (
    <>
      <Grid
        container
        justifyContent={{ xs: 'center', md: 'end' }}
        spacing={2}
        className="bg-white p-2 pt-0"
        // sx={{
        //   position: 'sticky',
        //   bottom: '-1px',
        // }}
      >
        <Grid container item xs={12} md={2.5} lg={2}>
          <SbButton
            onClick={handleGetRates}
            variant="contained"
            className="rounded-full w-full"
            style={{ height: '44px', zIndex: '2' }}
            disabled={ratesInputHasError}
          >
            Get Rates
          </SbButton>
        </Grid>
        <Grid item xs={12} md={2.5} lg={2}>
          <SbButton
            onClick={handleBack}
            variant="outlined"
            className="rounded-full w-full"
            style={{ height: '44px', zIndex: '2' }}
          >
            Back
          </SbButton>
        </Grid>
      </Grid>
      {/* <ShipmentWeightValidationModal
        open={weightValidation}
        onClose={() => setWeightValidation(false)}
        onConfirm={handleGetRates}
        weight={ratesInput.weight}
        weightUnit={ratesInput.weightUnit}
        chargeableWeight={chargeableWeight}
      /> */}
    </>
  );
}

export default GetRatesAction;
