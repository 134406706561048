import API from '@Services/apiAxios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface DownloadState {
  loading: boolean;
  error: string | null;
  downloadUrl: string | null;
}

const initialState: DownloadState = {
  loading: false,
  error: null,
  downloadUrl: null,
};

// Async thunk for downloading the report
export const downloadReport = createAsyncThunk(
  'reports/downloadReport',
  async (params: { pageSize: number; page: number; name: string; startDate?: string; endDate?: string }, thunkAPI) => {
    try {
      const response = await API.get('/squad/admin/download-report', {
        params,
      });
      return response.data.data.url;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.msg || 'Failed to download report');
    }
  },
);

// Create slice
const reportSlice = createSlice({
  name: 'squadReports',
  initialState,
  reducers: {
    clearState: state => {
      state.loading = false;
      state.error = null;
      state.downloadUrl = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(downloadReport.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.loading = false;
        state.downloadUrl = action.payload; // Set the download URL
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearState } = reportSlice.actions;
export default reportSlice.reducer;
