import FilterSearch from '@Components/Filter/Search';
import { getUserList } from '@Reducers/AccountLookup';
import { AppDispatch, RootStateType } from '@Store';
import { FormHelperText, InputLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterObj } from '../../pages/UserList/Types';
import { UserQuery } from '@Reducers/AccountLookup/Type';
import useAccountLookup from '@Hook/useAccountLookup';

const SearchFilterList = [
  { label: 'Email', value: 'email' },
  { label: 'Account no.', value: 'accountNumber' },
  { label: 'Name', value: 'name' },
];

function SearchInput(props: { modalId: string; searchDefault?: boolean }) {
  const dispatch = useDispatch<AppDispatch>();

  const { userQuery } = useAccountLookup(props.modalId);
  // const { searchValue } = userQuery;
  const [filterSelectValue, setFilterSelectValue] = useState<string>('email');
  const [filterText, setFilterText] = useState<string>('');
  const [error, setError] = useState<string>('');
  const { currentPage, perPage } = useSelector((state: RootStateType) => state.accountLookup[props.modalId] || {});
  const errorMsg = useSelector((state: RootStateType) => state.accountLookup[props.modalId] || {});

  useEffect(() => {
    if (props.searchDefault && !userQuery.searchKey && !userQuery.searchValue) {
      dispatch(getUserList([props.modalId, getFilterObj({ searchValue: undefined })]));
    }
  }, [props.searchDefault]);

  useEffect(() => {
    const { searchKey, searchValue } = userQuery;
    if (searchKey && searchValue) {
      setFilterSelectValue(searchKey);
      setFilterText(searchValue);
    }
  }, [userQuery]);

  const getFilterObj = (options: FilterObj): UserQuery => {
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filterQuery: UserQuery = {
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    if (userQuery.accountType) {
      filterQuery.accountType = userQuery.accountType;
    }
    if (userQuery.pageType) {
      filterQuery.pageType = userQuery.pageType;
    }
    return filterQuery;
  };

  const onTextSearch = () => {
    if (filterText.length < 3) {
      return setError('Need to provide at least 3 characters.');
    }

    const updatedQuery = getFilterObj({ searchValue: filterText });
    dispatch(getUserList([props.modalId, updatedQuery]));
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) setError('');
    setFilterText(event.target.value);
  };

  const onTextClear = () => {
    if (error) setError('');
    setFilterText('');
    dispatch(getUserList([props.modalId, getFilterObj({ searchValue: undefined })]));
  };

  useEffect(() => {
    if (errorMsg?.error?.isError) {
      setError('No results found for your query. Please try again.');
    }
  }, [errorMsg?.error]);

  return (
    <>
      <InputLabel sx={{ mb: 1 }} className="sb-text-gray">
        Enter email or select account no.
      </InputLabel>
      <FilterSearch
        inputSx={{ flex: 1 }}
        onSelectChange={(value: string) => {
          setFilterSelectValue(value);
        }}
        list={SearchFilterList}
        selectValue={filterSelectValue}
        textValue={filterText}
        onTextChange={onTextChange}
        onTextSearch={onTextSearch}
        onTextClear={onTextClear}
      />
      {error && (
        <FormHelperText error className="mt-1">
          {error}
        </FormHelperText>
      )}
    </>
  );
}

export default SearchInput;
