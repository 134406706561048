import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { FormHelperText, Grid } from '@mui/material';

import SbTextField from '../../../components/Textfield';
import SbButton from '../../../components/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { PasswordValue, PasswordVisibility, PwdVisibilityKeys } from './Type';
import { useDispatch, useSelector } from 'react-redux';
import { updatePassword } from '../../../store/reducers/loginUser/actions';
import { AppDispatch, RootStateType } from '../../../store';
import { TabPanel } from '@mui/lab';
import Title from '../../../components/Title';
import { resetPasswordState } from '@Reducers/loginUser';

const initPwdVisibility: PasswordVisibility = {
  oldPassword: false,
  newPassword: false,
  reEnterNewPassword: false,
};

const initPwdValue: PasswordValue = {
  oldPassword: '',
  newPassword: '',
  reEnterNewPassword: '',
};

function PasswordChange() {
  const dispatch = useDispatch<AppDispatch>();
  const [pwdVisibility, setPwdVisibility] = useState<PasswordVisibility>({ ...initPwdVisibility });
  const [pwdValue, setPwdValue] = useState<PasswordValue>({ ...initPwdValue });
  const passwordState = useSelector((state: RootStateType) => state.loginUser.password);

  useEffect(() => {
    if (passwordState.isUpdated) {
      setPwdVisibility({ ...initPwdVisibility });
      setPwdValue({ ...initPwdValue });
      dispatch(resetPasswordState());
    }
  }, [passwordState.isUpdated]);

  const onChangePwdVisibility = (type: PwdVisibilityKeys) =>
    setPwdVisibility({ ...pwdVisibility, [type]: !pwdVisibility[type] });

  const onChangePwdValue =
    (type: PwdVisibilityKeys): ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =>
    event =>
      setPwdValue({ ...pwdValue, [type]: event.target.value });

  const getCommonProps = (
    type: PwdVisibilityKeys,
  ): {
    required: boolean;
    endAdornment: React.ReactNode;
    type: 'text' | 'password';
    value: string;
    onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  } => {
    const isVisible = pwdVisibility[type];
    return {
      required: true,
      value: pwdValue[type],
      onChange: onChangePwdValue(type),
      type: isVisible ? 'text' : 'password',
      endAdornment: isVisible ? (
        <VisibilityOffIcon sx={{ cursor: 'pointer' }} onClick={() => onChangePwdVisibility(type)} />
      ) : (
        <VisibilityIcon sx={{ cursor: 'pointer' }} onClick={() => onChangePwdVisibility(type)} />
      ),
    };
  };

  const onCancel = () => {
    setPwdVisibility({ ...initPwdVisibility });
    setPwdValue({ ...initPwdValue });
  };

  const onPasswordUpdate = () => {
    const payload = { oldPassword: pwdValue.oldPassword, newPassword: pwdValue.newPassword };
    dispatch(updatePassword(payload));
  };

  // const disableBtn =
  //   pwdValue.newPassword &&
  //   pwdValue.reEnterNewPassword &&
  //   pwdValue.oldPassword &&
  //   pwdValue.newPassword === pwdValue.reEnterNewPassword
  //     ? false
  //     : true;

  const isValidPassword = () => {
    const newPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
    return (
      pwdValue.newPassword &&
      pwdValue.reEnterNewPassword &&
      pwdValue.oldPassword &&
      pwdValue.newPassword === pwdValue.reEnterNewPassword &&
      newPasswordRegex.test(pwdValue.newPassword)
    );
  };

  const disableBtn = !isValidPassword();

  return (
    <TabPanel value="change_password" className="p-0 w-full">
      <Grid container p={{ md: '0 32px' }}>
        <Title title="Password" subTitle="Please enter details to change your password." />
        <Grid container item xs={12} md={7} lg={6} mt={'20px'} rowGap={'24px'}>
          <Grid container item className="mt-2" rowGap={'20px'}>
            <SbTextField label="Old Password" className="w-full" {...getCommonProps('oldPassword')} />
            <Grid item xs={12}>
              <SbTextField label="New Password" className="w-full" {...getCommonProps('newPassword')} />
              <FormHelperText className="xs-text" sx={{ mt: '6px' }}>
                Your new password must be more than 8 characters, must have atleast one uppercase, one number and one
                special character
              </FormHelperText>
            </Grid>
            <SbTextField label="Re-enter New Password" className="w-full" {...getCommonProps('reEnterNewPassword')} />
          </Grid>
          {/* ---------------------------------------------------------------------------------------------------------- BTN */}
          <Grid container item justifyContent={{ xs: 'space-evenly', md: 'flex-end' }} gap={{ md: '12px' }}>
            <Grid item xs={5} md={2}>
              <SbButton variant="contained" className="rounded-full" fullWidth onClick={onCancel} disabled={disableBtn}>
                Cancel
              </SbButton>
            </Grid>
            <Grid item>
              <SbButton
                variant="outlined"
                className="rounded-full"
                fullWidth
                disabled={disableBtn}
                onClick={onPasswordUpdate}
              >
                Update password
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabPanel>
  );
}

export default PasswordChange;
