import React, { useCallback, useState } from 'react';

import { FormHelperText, Grid } from '@mui/material';

import Divider from '@Components/Divider';
import { FormLabel, FormContent } from '@Components/Form';
import DragDrop from '@Components/File/DragDrop';
import { FileResultType } from '../../../Helper/file';

import { Zone } from '@Reducers/RateUpload/Type';

import { SheetUploadType } from '../type';
import { transformExcelToRateObj } from '@Reducers/RateUpload/util';
import SbButton from '@Components/Button';
import Image from '@Components/Image';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';

function SheetUpload(props: SheetUploadType) {
  const [error, setError] = useState('');
  const [fileName, setFileName] = useState('');
  const alternateCountryNameObj = useSelector((state: RootStateType) => state.global.alternateCountryNameObj);
  const onFileChange = useCallback(
    (_data: FileResultType[], event: React.ChangeEvent<HTMLInputElement>) => {
      setError('');
      setFileName(_data[0].fileName);

      transformExcelToRateObj(event, props.rateApplyFor, alternateCountryNameObj, props.maxWeight)
        .then(data => {
          if (data?.zone) {
            // console.log({ propsRateApplyFor: props.rateApplyFor, data: data?.zone });
            const sheetData: Zone[] = data?.zone;
            // console.info({ sheetData });
            props.setSheet(sheetData);
            props.handleChange(sheetData);
            setError('');
          }
        })
        .catch(e => {
          console.log(e);
          setError(e.errorMsg);
        });
    },
    [props.rateApplyFor, props.handleChange, setError, setFileName],
  );

  const onDownloadSampleSheet = () => {
    let link = undefined;
    if (props.rateApplyFor === 'rate-sheet') {
      link = 'https://s3.ap-south-1.amazonaws.com/speedbox-static-file-2.0/Sample+Base+Rate+Sheet.xlsx';
    } else if (props.rateApplyFor === 'zone-wise') {
      link = 'https://s3.ap-south-1.amazonaws.com/speedbox-static-file-2.0/Sample+Zone+Wise+Rate+Sheet.xlsx';
    }
    if (link) window.open(link, '_blank');
  };

  return (
    <Grid item container xs={12} alignItems="center">
      <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
        <FormLabel>Rate Sheet*</FormLabel>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <FormContent>
          <DragDrop
            readType="xls"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            subText={fileName || 'only xls and xlsx file allowed'}
            onChange={onFileChange}
          />
          {(props.error || error) && (
            <FormHelperText error className="mt-1">
              {props.error || error}
            </FormHelperText>
          )}
        </FormContent>
        <SbButton
          variant="text"
          className="mt-1"
          onClick={onDownloadSampleSheet}
          startIcon={
            <Image
              src={'/images/upload_document.svg'}
              alt="Download"
              width={35}
              height={35}
              style={{ transform: 'rotate(180deg)' }}
            />
          }
        >
          Download Sample File
        </SbButton>
      </Grid>
      <Divider className="my-2" />
    </Grid>
  );
}

export default SheetUpload;
