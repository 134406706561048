import { Grid, alpha, styled } from '@mui/material';

interface BoxContainerProps {
  isselected: string;
}

const BoxContainer = styled(Grid)<BoxContainerProps>(({ theme, isselected }) => ({
  border: isselected === 'true' ? alpha(theme.palette.primary.light, 1) : `1px solid var(--mui-palette-primary-light)`,
  width: '100%',
  height: '189px',
  padding: '15px',
  cursor: 'pointer',
  '.isSelected': {
    background: 'var(--mui-palette-background-white) !important',
  },
  background: isselected === 'true' ? alpha(theme.palette.primary.light, 1) : 'unset',
}));

const ScrollableBox = styled(Grid)`
  overflow-x: auto;
  max-height: 426px;
`;

export { BoxContainer, ScrollableBox };
