import SbButton from '@Components/Button';
import ExportBtn from '@Components/Button/Export';
// import DatePicker from '@Components/DatePicker';
import DoughnutChart from '@Components/DoughnutChart/DoughnutChart';
import Image from '@Components/Image';
import Title from '@Components/Title';
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  Paper,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import FilterSelect from '../../components/Filter/Dropdown';
import FilterSearch from '../../components/Filter/Search';
import { SBTable } from '../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../components/Table/TableCell';
import { SBTableRow } from '../../components/Table/TableRow';
import BoxImageModal from './BoxImageModal';
import BackButton from '@Components/BackButton';
import { getSquadReportsDetails } from '@Reducers/Squad/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { squadReportsDetailsPayload, MemberDetailSquadReports, SummarySquadMembers } from '@Reducers/Squad/type';
import { useParams } from 'react-router-dom';
import { SquadReportDetailsProps } from './Types';
import Loader from '@Components/Loader';
import DisplaySquadVehicle from '../../utils/displaySquadVehicle';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { downloadSquadShipmentReport } from '@Reducers/downloadSquadShipmentReport/downloadSquadShipmentReport';
import DateRangePicker from '../../components/DateRangePicker';

import moment from 'moment';
import API from '@Services/apiAxios';
import { formatNumber, useLocale } from '../../utils/formatNumber';
const SearchFilterList = [{ label: 'Shipment No.', value: 'shipment_no' }];

const shipmentTypeList = [
  {
    value: 'pickup',
    display: 'Pickup',
  },
  {
    value: 'delivery',
    display: 'Delivery',
  },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  overflowX: 'auto', // Ensure horizontal scrolling
  margin: '32px 0',
  padding: '4px 0',
  display: 'grid',
  gridTemplateColumns:
    '510px 1fr 174px fit-content(160px) fit-content(160px) fit-content(160px) fit-content(160px) 110px',
  columnGap: '15px',
  whiteSpace: 'nowrap', // Prevent wrapping of content
  [theme.breakpoints.down('lg')]: {
    display: 'grid', // Retain grid layout
    gridAutoFlow: 'column', // Ensure grid flows horizontally
  },
  [theme.breakpoints.down('xl')]: {
    display: 'grid', // Retain grid layout
    gridAutoFlow: 'column', // Ensure grid flows horizontally
  },
}));

function SquadReportTable(props: SquadReportDetailsProps) {
  // const [selectedTasks, setSelectedTasks] = useState<{ [x: string]: boolean }>({});
  const [openBoxImageModal, setOpenBoxImageModal] = useState<boolean>(false);
  const [openProofDoc, setOpenProofDoc] = useState<boolean>(false);
  const [isloadingDoc, setIsLoadingDoc] = useState<boolean>(false);
  const [shipmentNumber, setShipmentNumber] = useState<string>('');
  const [boxImages, setBoxImages] = useState({
    startImage: '',
    endImage: '',
    type: 'Box',
  });
  const [proofImages, setProofImages] = useState({
    startImage: '',
    endImage: '',
    type: 'Proof',
  });
  const updateImages = async (startDocumentId: string | undefined, endDocumentId: string | undefined, type: string) => {
    setIsLoadingDoc(true);
    if (startDocumentId) {
      try {
        const start = await API.get(`/squad/file/get-url/${startDocumentId}`);
        if (type === 'Box') {
          setBoxImages(prev => ({
            ...prev,
            startImage: start.data.data,
          }));
        } else {
          setProofImages({
            startImage: start.data.data,
            endImage: '',
            type: 'Proof',
          });
        }
      } catch (err) {
        console.error(err);
        setIsLoadingDoc(false);
      }
    }

    if (endDocumentId) {
      try {
        const end = await API.get(`/squad/file/get-url/${endDocumentId}`);
        startDocumentId
          ? setProofImages(prev => ({
              ...prev,
              endImage: end.data.data,
            }))
          : setProofImages({
              type: 'Proof',
              startImage: '',
              endImage: end.data.data,
            });
      } catch (err) {
        console.error(err);
        setIsLoadingDoc(false);
      }
    }
    setIsLoadingDoc(false);
    if (type === 'Box') setOpenBoxImageModal(true);
    else setOpenProofDoc(true);
  };

  const { reportDetailsList } = props;
  const { currentPage, pageSize, loading } = useSelector((state: RootStateType) => state.squadManagement);

  const handleCloseBoxImageModal = () => {
    setOpenBoxImageModal(false);
    setOpenProofDoc(false);
  };
  const renderProofLink = (
    shipmentType: string,
    signatureDocument: string | undefined,
    confirmationDocument: string | undefined,
    shipmentNumber: string,
  ) => {
    const isPickup = shipmentType === 'pickup';
    const isDeliveryOrHyperlocal = shipmentType === 'delivery' || shipmentType === 'hyperlocal';

    if (
      (isPickup && (signatureDocument || confirmationDocument)) ||
      (isDeliveryOrHyperlocal && (signatureDocument || confirmationDocument))
    ) {
      return (
        <Typography
          className="underline cursor-pointer"
          sx={{ color: '#4047FF' }}
          onClick={() => {
            updateImages(signatureDocument, confirmationDocument, 'Proof');
            setShipmentNumber(shipmentNumber);
            setOpenProofDoc(true);
          }}
        >
          Proof of {shipmentType === 'hyperlocal' ? 'Delivery' : shipmentType}
        </Typography>
      );
    }
    return null;
  };

  return (
    <>
      <Loader loading={loading || isloadingDoc} overly />
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Checkbox style={{ color: 'inherit' }} />
            </TableHeadCell>
            <TableHeadCell>Shipment No.</TableHeadCell>
            <TableHeadCell>Type</TableHeadCell>
            <TableHeadCell>Distance</TableHeadCell>
            <TableHeadCell>Total Weight</TableHeadCell>
            <TableHeadCell>Amount Collected </TableHeadCell>
            <TableHeadCell>No.of Boxes</TableHeadCell>
            <TableHeadCell> Address</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportDetailsList.map((item: any, index: number) => {
            const {
              line1: pickupLine1,
              line2: pickupLine2,
              city: pickupCity,
              state: pickupState,
              country: pickupCountry,
              pincode: pickupPincode,
            } = item.pickupAddress;

            const {
              line1: deliveryLine1,
              line2: deliveryLine2,
              city: deliveryCity,
              state: deliveryState,
              country: deliveryCountry,
              pincode: deliveryPincode,
            } = item.deliveryAddress;
            return (
              <SBTableRow key={index}>
                <TableRowCell align="center">
                  <div className="flex sb-gap-1 items-center">
                    <Checkbox style={{ color: 'inherit' }} />
                    <Typography>{index + 1 + (currentPage - 1) * pageSize}</Typography>
                  </div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  {item.shipmentNumber}
                </TableRowCell>
                <TableRowCell align="center" className="md-text ">
                  {/* <Typography sx={{ background: '#C1F8C0', color: '#429E16', borderRadius: '20px', padding: '0 1rem' }}>
                {item.type}
              </Typography> */}
                  <Chip
                    label={item.shipmentType}
                    sx={{
                      bgcolor: 'success.light',
                      color: 'success.main',
                      fontWeight: 500,
                    }}
                  />
                  {renderProofLink(
                    item.shipmentType,
                    item.shipmentType === 'pickup' ? item.pickupSignatureDocument : item.deliverySignatureDocument,
                    item.shipmentType === 'pickup' ? item.proofOfPickup : item.deliveryConfirmationDocument,
                    item.shipmentNumber,
                  )}
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  {item.totalDistance != 0 ? item.totalDistance / 1000 : 0} km
                </TableRowCell>
                <TableRowCell align="center" className="md-text ">
                  {item.totalWeight}
                </TableRowCell>
                <TableRowCell align="center" className="md-text ">
                  {item.totalAmount}
                </TableRowCell>
                <TableRowCell align="center" className="md-text ">
                  <Box>
                    {item.totalBoxes}
                    {(((item.shipmentType === 'delivery' || item.shipmentType === 'hyperlocal') &&
                      item.deliveryConfirmationDocument) ||
                      (item.shipmentType === 'pickup' && item.proofOfPickup)) && (
                      <Typography
                        className="underline cursor-pointer"
                        sx={{ color: '#4047FF' }}
                        onClick={() => {
                          const documentToUse =
                            item.shipmentType === 'delivery' || item.shipmentType === 'hyperlocal'
                              ? item.deliveryConfirmationDocument
                              : item.proofOfPickup;

                          updateImages(documentToUse, undefined, 'Box');
                          setShipmentNumber(item.shipmentNumber);
                        }}
                      >
                        Box Image
                      </Typography>
                    )}
                  </Box>
                </TableRowCell>
                <TableRowCell align="center" className="md-text ">
                  {Object.keys(item.pickupAddress).length ? (
                    <Box>
                      {pickupLine1}, {pickupLine2}, {pickupCity}, {pickupState}, {pickupCountry}, {pickupPincode}
                    </Box>
                  ) : (
                    <Box>
                      {deliveryLine1}, {deliveryLine2}, {deliveryCity}, {deliveryState}, {deliveryCountry},{' '}
                      {deliveryPincode}
                    </Box>
                  )}
                </TableRowCell>
              </SBTableRow>
            );
          })}
        </TableBody>
      </SBTable>
      {(openBoxImageModal || openProofDoc) && (
        <BoxImageModal
          shipmentNumber={shipmentNumber}
          imageData={openBoxImageModal ? boxImages : proofImages}
          open={openBoxImageModal || openProofDoc}
          onClose={handleCloseBoxImageModal}
        />
      )}
    </>
  );
}

const SquadReportDetail = () => {
  const [filterSelectValue, setFilterSelectValue] = useState('shipment_no');
  const [filterText, setFilterText] = useState('');
  // const [squadReportDelivery, setSquadReportDelivery] = useState<string[]>([]);
  const [shipmentType, setShipmentType] = useState<string[]>([]);
  const [shipmentSystem, setShipmentSystem] = useState<string[]>([]);
  const [reportDetailsList, setReportDetailsList] = useState([]);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [summary, setSummary] = useState<SummarySquadMembers>({
    totalAmount: 0,
    totalDistance: 0,
    totalTasksCompleted: 0,
    totalTasksIncomplete: 0,
  });
  const theme = useTheme();
  const { limit } = useSelector((state: RootStateType) => state.notifications);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    fetchSquadReportsDetails({
      currentPage: 1,
      pageSize: 10,
      squadId,
      shipmentNumber: filterText,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      shipmentType,
    });
  };

  const [squadMemberDetails, setSquadMemberDetails] = useState<MemberDetailSquadReports>({});
  const dispatch = useDispatch<AppDispatch>();
  const { squadId } = useParams();
  const formatSelectedDate = selectedDate ? selectedDate.format('YYYY-MM-DD') : undefined;
  const { currentPage, pageSize, totalCount } = useSelector((state: RootStateType) => state.squadManagement);
  const locale = useLocale();

  const fetchSquadReportsDetails = async (props: SquadReportDetailsProps) => {
    const { currentPage, pageSize, squadId, shipmentNumber, shipmentType, startDate, endDate } = props;
    const queryParams = {
      currentPage: currentPage,
      pageSize: pageSize,
      squadId,
      shipmentNumber,
      // date,
      shipmentType: JSON.stringify(shipmentType),
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };
    try {
      const res = await dispatch(getSquadReportsDetails(queryParams));
      const { data } = res.payload as squadReportsDetailsPayload;
      setReportDetailsList(data.data);
      const { summary, squadMemberdetails } = data;
      setSummary(summary);
      setSquadMemberDetails(squadMemberdetails);
    } catch (error) {
      setReportDetailsList([]);
      console.error(error);
    }
  };

  console.log(setSelectedDate);

  useEffect(() => {
    fetchSquadReportsDetails({
      currentPage: 1,
      pageSize: 10,
      squadId,
      shipmentNumber: filterText,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      shipmentType,
    });
  }, [squadId]);

  const chartData1 = {
    datasets: [
      {
        label: `${summary.totalTasksCompleted + summary.totalTasksIncomplete}`,
        data: [summary.totalTasksIncomplete, summary.totalTasksCompleted],
        backgroundColor: [theme.palette.primary.main, theme.palette.secondary.main],
      },
    ],
  };

  const chartData2 = {
    datasets: [
      {
        label: `${summary.totalTasksCompleted + summary.totalTasksIncomplete}`,
        data: [summary.totalTasksCompleted, summary.totalTasksIncomplete],
        backgroundColor: ['#8A8F9D', theme.palette.secondary.main],
      },
    ],
  };

  const handleShipmentTypeFilter = (selected: Array<string>) => {
    setShipmentType(selected);
    fetchSquadReportsDetails({
      currentPage: 1,
      pageSize,
      squadId,
      shipmentNumber: filterText,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      shipmentType: selected,
    });
  };

  // const handleDateFilter = (date: Dayjs | null) => {
  //   setSelectedDate(date);
  //   fetchSquadReportsDetails({
  //     currentPage: 1,
  //     pageSize,
  //     squadId,
  //     shipmentNumber: filterText,
  //     date: date ? date.format('YYYY-MM-DD') : undefined,
  //     shipmentType,
  //   });
  // };

  const handleClearFilters = () => {
    setFilterText('');
    setShipmentType([]);
    setShipmentSystem([]);
    setDateRange({
      startDate: moment().subtract(1, 'months').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    });
    fetchSquadReportsDetails({
      currentPage: 1,
      pageSize: 10,
      squadId,
      shipmentNumber: '',
      startDate: moment().subtract(1, 'months').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
      shipmentType: [],
    });
  };
  const handleExport = async () => {
    const queryParams = {
      currentPage,
      pageSize: totalCount,
      squadId,
      shipmentType: shipmentType,
      shipmentSystem: shipmentSystem,
      shipmentNumber: filterText,
      date: formatSelectedDate,
    };
    try {
      dispatch(downloadSquadShipmentReport(queryParams));
      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit,
        }),
      );
      dispatch(getAllReadNotifications({ page: 1, limit }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Download in Progress',
          subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
          btnText: 'Close',
          onConfirm: () => {
            dispatch(toggleBoolean());
            dispatch(SuccessErrorModalReset());
          },
        }),
      );
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <BackButton link="/squad-reports" />
        <Title title="Squad Reports" removeBorder />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs>
          <Box
            sx={{
              background: '#F1F1FF',
              border: '1px solid #DCDCDC',
              borderRadius: '0.5rem',
              height: '12rem',
              gap: '0.8rem',
            }}
            className="flex justify-center items-center  px-2"
          >
            <Paper
              elevation={3}
              //   className={`text-center p-1 m-auto ${inInvitationSend ? 'cursor-not-allowed' : 'cursor-pointer'}`}
              className={`text-center p-1`}
              sx={{ width: '70px', height: '74px' }}
            >
              <Image src="/images/icons/user/UserKycDoc.svg" alt="UserKycDoc" />
              <Typography className="sb-text-gray xs-text flex items-center justify-center font-medium">KYC</Typography>
            </Paper>
            <Box>
              <Typography className="px_20-text font-medium">{squadMemberDetails.name}</Typography>
              <Typography>{squadMemberDetails.username}</Typography>
              <Typography>{squadMemberDetails.phoneNumber}</Typography>
              <Typography>Vehicle: {DisplaySquadVehicle(squadMemberDetails.vehicle)}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{ background: '#F1F1FF', border: '1px solid #DCDCDC', borderRadius: '0.5rem', height: '12rem' }}
            className="flex flex-col justify-center items-center text-center px-2"
          >
            <Typography className={`md-text font-medium `}>Total Tasks Completed</Typography>
            <Box className="flex item items-center">
              <DoughnutChart chartData={chartData1} />
              <Typography className="l-text font-medium">{summary.totalTasksCompleted}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{ background: '#F1F1FF', border: '1px solid #DCDCDC', borderRadius: '0.5rem', height: '12rem' }}
            className="flex flex-col justify-center items-center text-center px-2"
          >
            <Typography className={`md-text font-medium`}>Tasks Incomplete</Typography>
            <Box className="flex item items-center">
              <DoughnutChart chartData={chartData2} />
              <Typography className="l-text font-medium">{summary.totalTasksIncomplete}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{ background: '#F1F1FF', border: '1px solid #DCDCDC', borderRadius: '0.5rem', height: '12rem' }}
            className="flex flex-col justify-center items-center text-center px-2"
          >
            <Typography className={`md-text font-medium`}>Total Distance Covered</Typography>
            <Typography className={`l-text font-medium`}>{summary?.totalDistance / 1000} km</Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{ background: '#F1F1FF', border: '1px solid #DCDCDC', borderRadius: '0.5rem', height: '12rem' }}
            className="flex flex-col justify-center items-center text-center px-2"
          >
            <Typography className={`md-text font-medium`}>Total Amount Collected</Typography>
            <Typography className={`l-text font-medium`}>INR {formatNumber(summary.totalAmount, locale)}</Typography>
          </Box>
        </Grid>
      </Grid>

      <TableActionContainer className="scroll-h-1">
        <FilterSearch
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={filterText}
          onTextChange={e => {
            setFilterText(e.target.value);
          }}
          onTextSearch={() => {
            fetchSquadReportsDetails({
              currentPage: 1,
              pageSize,
              squadId,
              shipmentNumber: filterText,
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
              shipmentType,
            });
          }}
          onTextClear={() => {
            setFilterText('');
            fetchSquadReportsDetails({
              currentPage: 1,
              pageSize,
              squadId,
              shipmentNumber: '',
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
            });
          }}
        />

        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          {/* <div></div> */}
          <DateRangePicker onChange={onDateChange} value={dateRange} />

          <ExportBtn
            iconSrc="/images/upload_document.svg"
            alt="Export Reports"
            label="Export "
            onClick={handleExport}
          />
          <FilterSelect
            id="type"
            label="Type"
            value={shipmentType}
            options={shipmentTypeList}
            onChange={handleShipmentTypeFilter}
          />
          <SbButton
            className="xs-text"
            variant="outlined"
            sx={{ borderRadius: '8px !important', padding: '8px !important', width: '240px' }}
            onClick={handleClearFilters}
          >
            Clear Filters
          </SbButton>
        </Box>
      </TableActionContainer>

      <SquadReportTable
        reportDetailsList={reportDetailsList}
        currentPage={1}
        pageSize={0}
        squadId={''}
        shipmentNumber={''}
      />

      {!!totalCount && !!pageSize && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage - 1}
            rowsPerPage={pageSize}
            onPageChange={(_, page) => {
              fetchSquadReportsDetails({
                currentPage: page + 1,
                pageSize,
                squadId,
                shipmentNumber: filterText,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                shipmentType,
              });
            }}
            onRowsPerPageChange={event => {
              const newPerPage = parseInt(event.target.value, 10);
              fetchSquadReportsDetails({
                currentPage: 1,
                pageSize: newPerPage,
                squadId,
                shipmentNumber: filterText,
                startDate: dateRange.startDate,
                endDate: dateRange.endDate,
                shipmentType,
              });
            }}
          />
        </>
      )}
    </>
  );
};

export default SquadReportDetail;
