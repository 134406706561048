import { createSlice } from '@reduxjs/toolkit';
import { getPPAgreement, getTnCAgreement, getTnC, getPP } from './actions';
import {
  getPPAgreementFulfilled,
  getTnCAgreementFulFilled,
  getTnCFulFilled,
  getPPFulfilled,
  pending,
  rejected,
} from './case';
import { AgreementStateType } from './Type';

const initialState: AgreementStateType = {
  loading: false,
  PP: [],
  TnC: [],
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
};

const userSlice = createSlice({
  name: 'agreement',
  initialState: {
    ...initialState,
  },
  reducers: {
    // setUser: setCountryHandler,
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getPPAgreement.pending, pending);
    addCase(getPPAgreement.fulfilled, getPPAgreementFulfilled);
    addCase(getPPAgreement.rejected, rejected);

    addCase(getTnCAgreement.pending, pending);
    addCase(getTnCAgreement.fulfilled, getTnCAgreementFulFilled);
    addCase(getTnCAgreement.rejected, rejected);

    addCase(getTnC.pending, pending);
    addCase(getTnC.fulfilled, getTnCFulFilled);
    addCase(getTnC.rejected, rejected);

    addCase(getPP.pending, pending);
    addCase(getPP.fulfilled, getPPFulfilled);
    addCase(getPP.rejected, rejected);
  },
});

// const { setUser } = userSlice.actions;

export { getPPAgreement, getTnCAgreement, getTnC, getPPFulfilled };
export default userSlice.reducer;
