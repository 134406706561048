import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { FormControl, OutlinedInput } from '@mui/material';

type InputEvent = React.ChangeEvent<HTMLInputElement>;
type KeyDownEvent = React.KeyboardEvent<HTMLInputElement>;

function TrackShipment() {
  const [shipmentNumber, setShipmentNumber] = useState('');

  const handleKeyDown = (e: KeyDownEvent) => {
    if (e.keyCode === 13) {
      window.open(`/track-shipment?trackingNumber=${shipmentNumber}`);
    }
  };

  const handleChange = (e: InputEvent) => {
    setShipmentNumber(e.target.value);
  };

  return (
    <FormControl fullWidth>
      <OutlinedInput
        type="text"
        onKeyDown={handleKeyDown}
        value={shipmentNumber}
        onChange={handleChange}
        placeholder={'Track Shipment'}
        startAdornment={<SearchIcon sx={{ mr: 2 }} />}
        sx={{ width: { xl: '482px', md: '350px' }, height: '44px', borderRadius: '20px' }}
        className="bg-grey"
      />
    </FormControl>
  );
}

export default TrackShipment;
