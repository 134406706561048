import { ContentCopyOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const CopyCredentials = (props: { textToCopy: string; label?: string }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.textToCopy);
    setIsCopied(true);
  };

  useEffect(() => {
    return () => {
      setIsCopied(false);
    };
  }, []);

  return (
    <Grid
      textAlign="center"
      onClick={copyToClipboard}
      style={{
        cursor: 'pointer',
      }}
    >
      <ContentCopyOutlined fontSize="large" />
      <Typography className="xs-text mt-1\/2">{isCopied ? 'Copied' : props.label || 'Copy Credentials'}</Typography>
    </Grid>
  );
};

export default CopyCredentials;
