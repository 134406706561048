import React from 'react';
import { Grid, styled } from '@mui/material';
import Divider from '../../components/Divider';
import Title from '../../components/Title';
import PackageDetails from './PackageDetails';
import ShipmentCharges from './ShipmentCharges';
import ShipmentDetails from './ShipmentDetails';
import ShipmentPickupTime from './ShipmentPickupTime';
import { FormikType } from './type';

interface SHIPMENT_STETTING_FORM_PROPS extends FormikType {
  title?: string;
  subTitle?: string;
  disabled?: boolean;
}

const ProfileContainerStyle = styled(Grid)(({ theme: _ }) => ({
  background: '#fff',
  width: '100%',
  // maxHeight: '100vh',
}));

const TitleContentStyle = styled(Grid)(({ theme: _ }) => ({
  marginBottom: '24px',
}));

function ShipmentSettingForm(props: SHIPMENT_STETTING_FORM_PROPS) {
  return (
    <ProfileContainerStyle container alignContent="flex-start">
      <TitleContentStyle>
        <Title
          title={props.title || 'Organization setting'}
          subTitle={props.subTitle || 'Please update organization setting'}
          removeBorder
        />
      </TitleContentStyle>

      <ShipmentCharges
        setFieldValue={props.setFieldValue}
        handleChange={props.handleChange}
        values={props.values.shipmentCharges}
        errors={props?.errors}
        disabled={props.disabled}
      />
      <Divider className="pt-1 pb-1 mb-4 mt-4" />
      <ShipmentDetails
        setFieldValue={props.setFieldValue}
        handleChange={props.handleChange}
        values={{
          personalShipmentId: [...props.values.personalShipmentId],
          exportShipmentId: [...props.values.exportShipmentId],
        }}
        errors={props?.errors}
        disabled={props.disabled}
      />
      <Divider className="pt-1 pb-1 mb-4 mt-4" />
      <ShipmentPickupTime
        setFieldValue={props.setFieldValue}
        handleChange={props.handleChange}
        values={props.values.pickupTimeSlots}
        errors={props?.errors}
        disabled={props.disabled}
      />
      <Divider className="pt-1 pb-1 mb-4 mt-4" />
      <PackageDetails
        setFieldValue={props.setFieldValue}
        handleChange={props.handleChange}
        values={{
          packagingCharge: props.values.packagingCharge,
          insuranceCharge: props.values.insuranceCharge,
          enableInsuranceCharge: props.values.enableInsuranceCharge,
          enablePackagingCharge: props.values.enablePackagingCharge,
        }}
        errors={props?.errors}
        disabled={props.disabled}
      />
      <Divider className="pt-1 pb-1 mb-4 mt-4" />
    </ProfileContainerStyle>
  );
}

export default ShipmentSettingForm;
