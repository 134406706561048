import { Grid } from '@mui/material';
import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { userDetailsType } from '../../types/shipment';
import { getFormattedContact } from '../../services/getFormattedContact';
import { Details, Name, Pincode, SubHeading } from '../CreateShipment/ShipmentSummary/styles';

interface DetailsPropsType extends userDetailsType {
  label: string;
}

export function ShipperConsigneeSummaryDetails({
  companyName,
  label,
  name,
  line1,
  line2,
  city,
  state,
  country,
  pincode,
  email,
  phoneNumber,
  telephoneCode,
  IdType,
  IdNumber,
  altContactName,
  altContactNo,
  altContactTelephoneCode,
}: DetailsPropsType) {
  return (
    <Grid item xs={12}>
      <SubHeading className="md-text font-semibold">{label}</SubHeading>
      <Name mt={3} className="sm-text" color={'#080808'}>
        {name}
      </Name>
      <Name className="mb-3 sm-text" color={'#080808'}>
        {companyName}
      </Name>
      {email && (
        <Details mt="2" mb="1">
          <MailOutlineIcon className="mr-1 md-text" />
          {email}
        </Details>
      )}
      <Details>
        <CallOutlinedIcon className="mr-1 md-text" />
        {getFormattedContact(`${telephoneCode}${phoneNumber}`)}
      </Details>
      <Details mt={4}>{`${line1}, ${line2 ? `${line2}, ` : ''} ${city}, ${state}, ${country}`}</Details>
      <Pincode>Pincode : {pincode}</Pincode>
      {IdType && (
        <div className="mt-2">
          <Details>{`${label} ID Type: ${IdType}`}</Details>
          <Details>{`${label} ID No: ${IdNumber}`}</Details>
        </div>
      )}
      {altContactName && (
        <>
          <Details mt={3}>Alternate Contact </Details>
          <Details>{altContactName}</Details>
          <Details>
            <CallOutlinedIcon className="mr-1 md-text" />
            {getFormattedContact(`${altContactTelephoneCode}${altContactNo}`)}
          </Details>
        </>
      )}
    </Grid>
  );
}
