import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { CreateManifestPostData, ManifestQuery, UpdateManifest } from './Type';
// const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const createManifest = createAsyncThunk('manifest/create', async (postData: CreateManifestPostData, { dispatch }) => {
  try {
    const res = await API.post('/shipment/manifest', postData);
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went wrong.',
      }),
    );
    throw error;
  }
});

const getManifest = createAsyncThunk('manifest/get-list', async (params: ManifestQuery, { dispatch }) => {
  try {
    const res = await API.get('/shipment/manifest', { params });
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.errorDetails || 'Something went wrong.',
      }),
    );
    throw { msg: 'No such shipment.' };
  }
});

const updateManifest = createAsyncThunk('manifest/update', async (payload: UpdateManifest, { dispatch }) => {
  try {
    const res = await API.put(`/shipment/manifest/${payload._id}`, payload);
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: res.data?.msg || 'Manifest updated successfully.',
      }),
    );
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went wrong.',
      }),
    );
    throw error;
  }
});

const deleteManifest = createAsyncThunk('manifest/delete', async ([id, params]: [string, ManifestQuery], thunkApi) => {
  const res = await API.delete(`/shipment/manifest/${id}`);
  thunkApi.dispatch(getManifest(params));
  return res.data;
});

export { createManifest, getManifest, updateManifest, deleteManifest };
