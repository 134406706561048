import React from 'react';
import OrganizationSettingTabs from './OrganizationSettingTabs';
import OrganizationSetupContext from '../../../context/OrganizationSetup';
import { OrganizationSetupContextType } from '../../../types/organization';

function OrganizationSetting() {
  const { activeStep, handleNext, handleBack } = React.useContext(
    OrganizationSetupContext,
  ) as OrganizationSetupContextType;
  return (
    <>
      <OrganizationSettingTabs
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        viewFor="org-setup"
      />
    </>
  );
}

export default OrganizationSetting;
