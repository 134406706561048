import React from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CloseIcon from '@mui/icons-material/Close';
import { DocumentUploadSuccessfullyType } from './Type';
import Modal from '@Components/Modal';

function DocumentUploadSuccessfully(props: DocumentUploadSuccessfullyType) {
  return (
    <Modal open={props.open}>
      <Grid container className="mb-3" gap={3} justifyContent="flex-end">
        <IconButton onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
        <Grid container item justifyContent="center">
          <TaskAltIcon sx={{ width: '100px', height: '100px' }} color="success" />
        </Grid>
        <Grid container item justifyContent="center" flexDirection="column" textAlign="center">
          <Typography fontWeight="bold" variant="h5">
            Upload Successful
          </Typography>
          <Typography variant="h6" mt={0.5}>
            Document uploaded successfully.
          </Typography>
        </Grid>
      </Grid>
    </Modal>
  );
}
export default DocumentUploadSuccessfully;
