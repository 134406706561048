import { createSlice } from '@reduxjs/toolkit';
import {
  getActiveSystemListFulfilled,
  getActiveSystemListRejected,
  getLoggedInUserSystemListFulfilled,
  getLoggedInUserSystemListRejected,
  getSystemByIdFulfilled,
  getSystemByIdRejected,
  getSystemListFulfilled,
  getSystemListRejected,
  pending,
  updateSystemFulfilled,
  createSystemFulfilled,
  createSystemRejected,
} from './case';
import { SystemState } from './Type';
import {
  getSystemList,
  createSystem,
  getSystemById,
  updateSystemById,
  getActiveSystemList,
  getLoggedInUserSystemList,
} from './actions';

const initialState: SystemState = {
  loading: false,
  singleSystem: null,
  activeSystemList: [],
  activeSystemDropdownList: [],
  systemListHashById: {},
  activeSystemListHashById: {},
  loggedInUserSystemDropdownList: [],
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    selectedList: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 10,
  },
};

const reduxStateSlice = createSlice({
  name: 'global',
  initialState: { ...initialState },
  reducers: {
    resetSystemRedirect: function (state) {
      state.isRedirect = false;
    },
    resetSystemError: function (state) {
      state.error = {
        msg: '',
        isError: false,
      };
    },
    resetSingleSystem: function (state) {
      state.singleSystem = null;
    },
    resetCurrentPage: function (state) {
      state.data.currentPage = 0;
    },
    onChangePagination: function (state, action) {
      state.data.currentPage = action.payload;
      const startIndex = state.data.currentPage * state.data.perPage;
      const endIndex = startIndex + state.data.perPage;
      state.data.list = state.data.selectedList?.slice(startIndex, endIndex);
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getSystemList.pending, pending);
    addCase(getSystemList.fulfilled, getSystemListFulfilled);
    addCase(getSystemList.rejected, getSystemListRejected);

    addCase(createSystem.pending, pending);
    addCase(createSystem.fulfilled, createSystemFulfilled);
    addCase(createSystem.rejected, createSystemRejected);

    addCase(getSystemById.pending, pending);
    addCase(getSystemById.fulfilled, getSystemByIdFulfilled);
    addCase(getSystemById.rejected, getSystemByIdRejected);

    addCase(updateSystemById.pending, pending);
    addCase(updateSystemById.fulfilled, updateSystemFulfilled);
    addCase(updateSystemById.rejected, createSystemRejected);

    addCase(getActiveSystemList.pending, pending);
    addCase(getActiveSystemList.fulfilled, getActiveSystemListFulfilled);
    addCase(getActiveSystemList.rejected, getActiveSystemListRejected);

    addCase(getLoggedInUserSystemList.pending, pending);
    addCase(getLoggedInUserSystemList.fulfilled, getLoggedInUserSystemListFulfilled);
    addCase(getLoggedInUserSystemList.rejected, getLoggedInUserSystemListRejected);
  },
});

export { getSystemList, createSystem, getSystemById, updateSystemById, getActiveSystemList, getLoggedInUserSystemList };
export const { resetSystemRedirect, resetSystemError, resetSingleSystem, onChangePagination, resetCurrentPage } =
  reduxStateSlice.actions;
export default reduxStateSlice.reducer;
