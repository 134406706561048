import React from 'react';
import InvoiceSettingTabs from '../OrganizationSetup/InvoiceSettings/InvoiceSettingTabs';

function InvoiceSettings() {
  return (
    <>
      <InvoiceSettingTabs viewFor="settings" />
    </>
  );
}

export default InvoiceSettings;
