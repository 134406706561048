import SbButton from '@Components/Button';
import Contact from '@Components/Contact';
import Divider from '@Components/Divider';
import SBDropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import GoogleMaps from '@Components/Location';
import SbTextField from '@Components/Textfield';
import Title from '@Components/Title';
import { getShipmentSettings } from '@Reducers/OrganizationSetup';
import { addAddress } from '@Reducers/SavedAddress';
import { AddressType } from '@Reducers/SavedAddress/Type';
import { updateAddress } from '@Reducers/SavedAddress/actions';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Grid, InputLabel, SelectChangeEvent, TextField } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFormattedLocation } from '../../services/formateLocation';
import { userDetailsType } from '../../types/shipment';
import { defaultUserState } from '../CreateShipment/constants';
import ShipmentUserSchema from './Schema';
import BackButton from '@Components/BackButton';

function CreateShipperAddress(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const {
    state: { formType, address },
  } = useLocation();
  const { countryList } = useSelector((state: RootStateType) => state?.global);
  // const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  // const { exportShipmentId, personalShipmentId } = shipmentSetting || {};
  const [reinitialize, setReinitialize] = useState<boolean>(true);
  const navigate = useNavigate();
  // const [saveAs, setSaveAs] = useState<string>('');

  function saveOrUpdateSaveAs(values: userDetailsType) {
    const {
      telephoneCode,
      phoneNumber,
      altContactTelephoneCode,
      altContactNo,
      name,
      companyName,
      line1,
      line2,
      city,
      state,
      country,
      pincode,
      email,
      label,
      altContactName,
      countryId,
    } = values;
    const addressObj: AddressType = {
      phone: {
        telephoneCode: telephoneCode,
        number: phoneNumber,
      },
      name: name,
      email: email,
      companyName: companyName,
      line1: line1,
      line2: line2,
      city: city,
      state: state,
      country: country,
      _countryId: countryId,
      pincode: pincode,
      type: formType?.toLocaleLowerCase(),
      label,
      _id: address?._id,
    };
    if (altContactNo || altContactName)
      addressObj.alternatePhone = {
        telephoneCode: altContactTelephoneCode,
        number: altContactNo,
        name: altContactName,
      };

    if (address && address?._id) {
      dispatch(
        updateAddress({
          payload: addressObj,
          next: () => {
            navigate('/shipment/addresses', {
              state: formType,
            });
          },
        }),
      );
    } else {
      dispatch(
        addAddress({
          payload: addressObj,
          next: () => {
            navigate('/shipment/addresses', {
              state: formType,
            });
          },
        }),
      );
    }
  }

  const { values, handleChange, setFieldValue, errors, handleSubmit } = useFormik<userDetailsType>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: address || defaultUserState,
    validationSchema: ShipmentUserSchema,
    // validate: validateForm,
    onSubmit: values => {
      saveOrUpdateSaveAs(values);
    },
  });

  function handleDropdownChange(
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
    _child: React.ReactNode,
  ) {
    const { name, value } = event.target;
    setFieldValue(name, value);
    setFieldValue('countryId', _selectedObj[0]?.countryId);
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function handleSelectedAddress(event: any) {
    const address = getFormattedLocation(event.address_components);
    const countryId = countryList?.find(item => item?.value === address['country'])?.countryId;
    setFieldValue('countryId', countryId);

    for (const key in address) {
      setFieldValue(key, address[key]);
    }
    setReinitialize(false);
  }

  // const typeOption = useMemo(() => {
  //   const options =
  //     values.type || personalShipmentId || exportShipmentId
  //       ? values.type === 'personalShipments'
  //         ? personalShipmentId
  //         : exportShipmentId
  //       : [];
  //   return options?.map(({ label, value }) => ({ display: label, value }));
  // }, [values.type, exportShipmentId, personalShipmentId]);

  useEffect(() => {
    dispatch(getShipmentSettings());
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', gap: '0.5rem' }}>
        <BackButton link="/shipment/addresses" />
        <Title title="Saved Address" removeBorder removePadding />
      </Box>
      <Grid container alignItems={'center'} mt={'1.5rem'}>
        <Grid item lg={2} md={2} xs={12}>
          <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }} required>
            {`${formType} name`}
          </InputLabel>
        </Grid>
        <Grid item lg={10} md={10} xs={12}>
          <SbTextField
            onChange={handleChange}
            placeholder="Enter Name"
            name="name"
            required
            value={values.name}
            error={errors.name}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: { md: 4, xs: 2 } }} />
      <Grid container alignItems={'center'}>
        <Grid item lg={2} md={2} xs={12}>
          <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
            Company Name
          </InputLabel>
        </Grid>
        <Grid item lg={10} md={10} xs={12}>
          <SbTextField
            placeholder="Enter Company Name"
            value={values.companyName}
            onChange={handleChange}
            name="companyName"
            error={errors.companyName}
          />
        </Grid>
      </Grid>
      <Divider sx={{ my: { md: 4, xs: 2 } }} />
      <Grid container alignItems={'center'}>
        <Grid item lg={2} md={2} xs={12}>
          <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
            Contact
          </InputLabel>
        </Grid>
        <Grid container item lg={10} md={10} xs={12} columnSpacing={3}>
          <Grid item md={6} xs={12} sx={{ mb: { md: 0, xs: 2 } }}>
            <Contact
              label="Mobile No. *"
              phoneName="phoneNumber"
              phoneNumber={values.phoneNumber || ''}
              onChangePhoneNumber={handleChange}
              telCountryCodeName="telephoneCode"
              telCountryCode={values.telephoneCode || ''}
              onChangeTelCountryCode={handleChange}
              setDefaultTelephone={(value: number) => setFieldValue('telephoneCode', value.toString())}
              error={errors.phoneNumber || errors.telephoneCode}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SbTextField
              placeholder="Enter Email Id"
              label="Email Address"
              onChange={handleChange}
              name="email"
              value={values.email}
              error={errors.email}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ my: { md: 4, xs: 2 } }} />
      <Grid container>
        <Grid item lg={2} md={2} xs={12}>
          <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
            Address
          </InputLabel>
        </Grid>
        <Grid container item lg={10} md={10} xs={12} spacing={3}>
          <Grid item md={12} xs={12}>
            <GoogleMaps
              onSelect={handleSelectedAddress}
              label={'Location'}
              placeholder="Start typing to search for location"
              reinitialize={reinitialize}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SbTextField
              placeholder="Line 1"
              label="Address Line 1"
              required
              value={values.line1}
              onChange={handleChange}
              name="line1"
              error={errors.line1}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SbTextField
              placeholder="Line 2"
              label="Address Line 2"
              // required
              value={values.line2}
              onChange={handleChange}
              name="line2"
              error={errors.line2}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SbTextField
              placeholder="City"
              label="City"
              required
              value={values.city}
              onChange={handleChange}
              name="city"
              error={errors.city}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SbTextField
              placeholder="State"
              label="State"
              required
              value={values.state}
              onChange={handleChange}
              name="state"
              error={errors.state}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SBDropdown
              id="company-country-id"
              label={'Country'}
              required
              name="country"
              placeholder="Country"
              options={countryList}
              value={values.country ? [values.country] : []}
              onChange={handleDropdownChange}
              error={errors.country}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SbTextField
              placeholder="Pincode"
              label="Pincode"
              required
              value={values.pincode}
              onChange={handleChange}
              name="pincode"
              error={errors.pincode}
            />
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ my: { md: 4, xs: 2 } }} />
      <Grid container alignItems={'center'}>
        <Grid item lg={2} md={2} xs={12}>
          <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
            Alternate contact
          </InputLabel>
        </Grid>
        <Grid container item lg={10} md={10} xs={12} spacing={3}>
          <Grid item md={6} xs={12}>
            <SbTextField
              placeholder="Enter name"
              label="Name"
              name="altContactName"
              onChange={handleChange}
              value={values.altContactName}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Contact
              label={values.altContactName ? 'Mobile No. *' : 'Mobile No.'}
              phoneName="altContactNo"
              phoneNumber={values.altContactNo || ''}
              onChangePhoneNumber={handleChange}
              telCountryCodeName="altContactTelephoneCode"
              telCountryCode={values.altContactTelephoneCode || ''}
              onChangeTelCountryCode={handleChange}
              setDefaultTelephone={(value: number) => setFieldValue('altContactTelephoneCode', value.toString())}
              error={errors.altContactNo || errors.altContactTelephoneCode}
            />
          </Grid>
        </Grid>
      </Grid>
      {/* <Divider sx={{ my: { md: 4, xs: 2 } }} />
      <Grid container className="mt-1">
        <Grid item lg={2} md={2} xs={12}>
          <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>{`${formType} ID`}</InputLabel>
        </Grid>
        <Grid container item lg={10} md={10} xs={12} spacing={3}>
          <Grid item md={6} xs={12}>
            <SBDropdown
              id="company-country-id"
              label={`${formType} Type`}
              placeholder="Personal Shipments"
              name="type"
              value={values.type ? [values.type] : []}
              options={shipmentIdTypeList}
              onChange={handleDropdownChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SBDropdown
              label={`${formType} ID Type`}
              id="IdType"
              placeholder="Select ID ype"
              name="IdType"
              value={values.IdType ? [values.IdType] : []}
              options={typeOption || []}
              onChange={handleDropdownChange}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <SbTextField
              label={`${formType} ID No.`}
              placeholder="ID Number"
              name="IdNumber"
              value={values.IdNumber}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid> */}
      <Divider sx={{ my: { md: 4, xs: 2 } }} />
      <Grid container alignItems={'baseline'}>
        <Grid item lg={2} md={2} xs={12}>
          <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
            Save as
          </InputLabel>
        </Grid>
        <Grid container item lg={10} md={10} xs={12} spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              placeholder="Save as"
              value={values?.label}
              name="label"
              variant="standard"
              onChange={handleChange}
              sx={{ fontSize: '14px', height: '44px', width: '100%' }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item my={4} spacing={2} justifyContent="flex-end">
        <Grid item xs={12} sm={4} md={2} lg={1.5}>
          <SbButton
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </SbButton>
        </Grid>
        <Grid item xs={12} sm={4} md={2} lg={1.3}>
          <SbButton
            fullWidth
            variant="contained"
            onClick={_event => {
              handleSubmit();
            }}
          >
            Save
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateShipperAddress;
