import { displayRazorpay } from './helper';
import API from '../../services/apiAxios';
import { DispatchError, DispatchSuccess } from '@Reducers/SuccessErrorModal';

export enum PAYMENT_MODE {
  gateway = 'gateway',
  wallet = 'wallet',
  gatewayWallet = 'wallet+gateway',
  WalletAndCash = 'wallet+cash',
}

export type PaymentModes = (typeof PAYMENT_MODE)[keyof typeof PAYMENT_MODE];

export interface BeforePaymentPostData {
  shipmentNumber: string;
  accountNumber: string;
  paymentMode: PaymentModes;
  walletAmount?: number;
  totalAmount?: number;
  currency?: string;
  tdsAmount?: number;
  amount: number;
}
export interface cashPaymentPostData {
  shipmentNumber: string;
  tdsAmount: number;
}

export interface PayloadBeforePaymentResponse {
  payload: {
    shipmentNumber: string;
    amount: number;
    paymentMode: PaymentModes;
    _userId: string;
    _accountId: string;
  };
  type: string;
  hash: string;
  paymentRequestId: string;
}

export interface RazorPayOrderPayload {
  orderId: string;
  currency: string;
  amount: number;
  keyId: string;
  description?: string;
}

const paymentModeUrl: { [key in PaymentModes]: string } = {
  gateway: 'razorpay/order',
  wallet: 'wallet',
  'wallet+gateway': 'razorpay/wallet-gateway/order',
  'wallet+cash': 'wallet',
};

export const getShipmentAmount = async (shipmentNumber: string) => {
  try {
    const res = await API.get(`/shipment/payment/payable-amount/shipment/${shipmentNumber}`);
    const payload = (await res)?.data;
    console.log({ payload });
    return payload;
  } catch (err) {
    console.log('error while (getWalletBalance) -', err);
    return err.response?.data;
  }
};

export const getWalletBalance = async () => {
  try {
    const res = await API.get('/payment/wallet/current-balance');
    const payload = (await res)?.data;
    console.log({ payload });
    return payload;
  } catch (err) {
    console.log('error while (getWalletBalance) -', err);
    return err.response?.data;
  }
};

export const createPaymentPayload = async ({
  postData,
  handlePaymentSuccess,
}: {
  postData: BeforePaymentPostData;
  handlePaymentSuccess: () => void;
}) => {
  try {
    const res = await API.post('/shipment/payment/create', postData);
    const payload = (await res).data;

    const orderDetails: RazorPayOrderPayload = await createOrderIdForShipment(
      payload as PayloadBeforePaymentResponse,
      postData.paymentMode,
    );
    if (postData.paymentMode === PAYMENT_MODE.wallet || postData.paymentMode === PAYMENT_MODE.WalletAndCash) {
      handlePaymentSuccess();
    } else {
      console.log({ orderDetails });
      return displayRazorpay(orderDetails, handlePaymentSuccess);
    }
  } catch (err) {
    console.log('error while verify (createPaymentPayload) -', err.response);
    return err.response.data;
  }
};
export const createCashPayment = async ({ postData }: { postData: cashPaymentPostData }) => {
  try {
    await API.post('/shipment/payment/add-tds', postData);
    return;
  } catch (err) {
    console.log('error while verify (createPaymentPayload) -', err.response);
    return err.response.data;
  }
};

export const createOrderIdForShipment = async (
  postData: PayloadBeforePaymentResponse,
  selectedPaymentMode: PaymentModes,
) => {
  try {
    //getway -> payment/pay/shipment/razorpay/order
    //wallet -> payment/pay/shipment/wallet
    //wallet + getway -> payment/pay/shipment/razorpay/wallet-gateway/order

    const res = await API.post(`/payment/pay/shipment/${paymentModeUrl[selectedPaymentMode]}`, postData);
    const payload = (await res).data.data;
    if (selectedPaymentMode === PAYMENT_MODE.WalletAndCash) {
      DispatchSuccess('Success', res.data?.msg || '', '');
    }
    return payload;
  } catch (err) {
    console.log('error while (createOrderIdForShipment) -', err);
    DispatchError('Error', err.response.data?.msg || '', '');
    return err.response.data;
  }
};
