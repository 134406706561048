import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AppDispatch, RootStateType } from '../../../store';
import Title from '../../../components/Title';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { OutletBox } from '../Style';
import { SettingsTabsPropsType } from '../../../types/organization';
import { useFormik } from 'formik';
import { FileResultType } from '../../../Helper/file';

import { OrgUpdateSchema } from '../../OrganizationOnboard/OrganizationForm/schema';
import { getFormattedLocation } from '../../../services/formateLocation';

import OrganizationForm from './OrgForm';
import Actions from '../Actions';
import { OrganizationType } from './type';
import TermsAndConditions from './TermsAndConditionsForm';
import Loader from '@Components/Loader';
import { getOrganizationDetails, getOrganizationPublic, updateOrganizationDetails } from '@Reducers/OrganizationSetup';
import { domainToURL } from '../../../utils/domainToURL';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

type TabValues = 'general' | 't_n_c' | string;
const primaryColor = {
  main: '',
  dark: '',
  light: '',
  contrastText: '',
};
const secondaryColor = {
  light: '',
  main: '',
};
const organizationInitials = {
  contactInfo: {
    address: { line1: '', line2: '', city: '', state: '', country: '', pincode: '' },
    telephoneCode: '',
    contactNumber: '',
    email: '',
    supportEmail: '',
  },
  name: '',
  websiteLink: '',
  logo: '',
  logoFile: null,
  firstUserEmail: '',
  taxInformation: [],
  termsAndCondition: '',
  theme: { primary: primaryColor, secondary: secondaryColor },
  privacyPolicy: '',
  currency: { name: '', symbol: '', fullname: '' },
};

const TABS: { label: string; value: TabValues }[] = [
  { label: 'Terms and conditions', value: 't_n_c' },
  { label: 'General', value: 'general' },
];

const TAB_KEYS: TabValues[] = TABS.map(x => x.value);

const ProfileContainerStyle = styled(Grid)(({ theme: _ }) => ({
  background: '#fff',
  width: '100%',
  // maxHeight: '100vh',
}));

const TitleContentStyle = styled(Grid)(({ theme: _ }) => ({
  marginBottom: '24px',
}));

function OrganizationSettingTabs({ handleBack, activeStep, viewFor, handleNext }: SettingsTabsPropsType) {
  const navigation = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const { loading, organizationData } = useSelector((state: RootStateType) => state.orgSetup);

  const [currentTap, setCurrentTap] = useState<TabValues>(TABS[0].value);
  const [organizationState, setOrganizationState] = useState<OrganizationType>({ ...organizationInitials });
  const { countries } = useSelector((state: RootStateType) => state.global);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isGeneralReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Organization_Settings.General,
    PERFORM_ACTION.read,
  );

  const isTCReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Organization_Settings.t_and_c,
    PERFORM_ACTION.read,
  );

  const isGeneralWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Organization_Settings.General,
    PERFORM_ACTION.write,
  );

  const isTCWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Organization_Settings.t_and_c,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    dispatch(getOrganizationDetails());
    const currentActiveTab: TabValues = searchParams.get('tab') || 'account_holder';
    if (TAB_KEYS.includes(currentActiveTab)) setCurrentTap(currentActiveTab);
    // dispatch(getCountryList());
  }, []);

  useEffect(() => {
    if (organizationData) {
      setOrganizationState({
        contactInfo: {
          address: {
            line1: organizationData.contactInfo.address.line1,
            line2: organizationData.contactInfo.address.line2,
            city: organizationData.contactInfo.address.city,
            state: organizationData.contactInfo.address.state,
            country: organizationData.contactInfo.address.country,
            pincode: organizationData.contactInfo.address.pincode,
          },
          telephoneCode: organizationData.contactInfo.telephoneCode,
          contactNumber: organizationData.contactInfo.contactNumber,
          email: organizationData.contactInfo.email,
          supportEmail: organizationData.contactInfo.supportEmail,
        },
        name: organizationData.name,
        websiteLink: organizationData.websiteLink,
        logo: organizationData.logo,
        logoFile: null,
        firstUserEmail: '',
        taxInformation: organizationData.taxInformation?.map(x => ({
          name: x.display || x.key || x.name || '',
          value: x.value,
        })),
        termsAndCondition: organizationData.termsAndCondition?.content || '',
        theme: organizationData.theme,
        currency: organizationData?.currency?.name,
        privacyPolicy: organizationData.privacyPolicy?.content || '',
      });
    }
  }, [organizationData]);
  const handleFormSubmit = (values: OrganizationType) => {
    let selectedCurrencyObj = countries.find(item => item.currency.name === values?.currency)?.currency;
    if (selectedCurrencyObj) {
      selectedCurrencyObj = {
        ...selectedCurrencyObj,
        fullForm:
          selectedCurrencyObj.fullForm || countries.find(item => item.currency.name === values?.currency)?.name || '',
      };
    }

    values.currency = selectedCurrencyObj;
    dispatch(
      updateOrganizationDetails({
        payload: { ...values, websiteLink: domainToURL(values.websiteLink) },
        showModal: !handleNext,
      }),
    ).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        if (activeStep && handleNext) handleNext?.(activeStep);
        dispatch(getOrganizationDetails());
        dispatch(getOrganizationPublic());
      }
    });
  };

  const organizationFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...organizationState },
    validationSchema: OrgUpdateSchema,
    onSubmit(values) {
      handleFormSubmit(values);
    },
  });

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function handleSelectedAddress(event: any) {
    const address = getFormattedLocation(event.address_components);
    organizationFormik.setFieldValue('contactInfo.address', {
      ...organizationFormik.values.contactInfo.address,
      city: address.city,
      state: address.state,
      pincode: address.pincode,
    });
    // setInitials({ ...tempObj });
  }

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    navigation(`?tab=${currentTab}`);
    setCurrentTap(currentTab);
  };

  const onFileChange = (fileData: FileResultType[]) => {
    organizationFormik.setFieldValue('logoFile', fileData[0]);
  };

  return (
    <Loader loading={loading} overly>
      <>
        <OutletBox className="border-grey rounded-md" removeBorder={viewFor === 'settings'}>
          <ProfileContainerStyle container alignContent="flex-start">
            <TitleContentStyle>
              <Title title="Organization setting" subTitle="Please update organization setting" removeBorder />
            </TitleContentStyle>
            <TabContext value={currentTap}>
              <Grid container sx={{ borderBottom: '1px solid #E4E4E4', marginBottom: '50px' }}>
                <TabList
                  onChange={onTabChange}
                  indicatorColor="primary"
                  TabIndicatorProps={{ sx: { height: '4px' } }}
                  scrollButtons="auto"
                  variant="scrollable"
                >
                  {TABS.filter(
                    ctab =>
                      (ctab.value === 'general' && isGeneralReadAllowed) || (ctab.value === 't_n_c' && isTCReadAllowed),
                  ).map(cTab => (
                    <Tab
                      sx={{ textTransform: 'none', padding: '16px 16px' }}
                      className="md-text font-medium sb-text-black"
                      value={cTab.value}
                      label={cTab.label}
                      key={cTab.value}
                    />
                  ))}
                </TabList>
              </Grid>
              <TabPanel value="general" className="p-0 w-full">
                {isGeneralReadAllowed && (
                  <OrganizationForm
                    onFileChange={onFileChange}
                    handleSelectedAddress={handleSelectedAddress}
                    handleChange={organizationFormik.handleChange}
                    setFieldValue={organizationFormik.setFieldValue}
                    values={organizationFormik.values}
                    errors={organizationFormik.errors}
                  />
                )}
              </TabPanel>
              <TabPanel value="t_n_c" className="p-0 w-full">
                {isTCReadAllowed && (
                  <TermsAndConditions
                    handleChange={organizationFormik.handleChange}
                    setFieldValue={organizationFormik.setFieldValue}
                    values={organizationFormik.values}
                    errors={organizationFormik.errors}
                  />
                )}
              </TabPanel>
            </TabContext>
          </ProfileContainerStyle>
        </OutletBox>
        {(isGeneralReadAllowed || isTCReadAllowed) && (
          <Actions
            onNext={() => {
              organizationFormik.handleSubmit();
              // handleNext(activeStep);
            }}
            onBack={() => (handleBack ? handleBack(activeStep || 0) : {})}
            disableNext={false}
            viewFor={viewFor}
            activeStep={activeStep}
            disabled={
              (currentTap === 'general' && !isGeneralWriteAllowed) || (currentTap === 't_n_c' && !isTCWriteAllowed)
            }
          />
        )}
      </>
    </Loader>
  );
}

export default OrganizationSettingTabs;
