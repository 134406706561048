import API from '@Services/apiAxios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define the API response type
interface ExportResponse {
  url: string;
}

// Define the slice state type
interface ExportState {
  isLoading: boolean;
  fileUrl: string | null;
  error: string | null;
}

// Initial state
const initialState: ExportState = {
  isLoading: false,
  fileUrl: null,
  error: null,
};

// Async thunk for API call
export const exportUserList = createAsyncThunk<
  ExportResponse, // Returned data type
  {
    currentPage: number;
    perPage: number;
    system?: string[];
    status?: string;
    accessGroup?: string[];
    onBoardedStatus?: string[];
  }, // Params type
  { rejectValue: string } // Error type
>('export/exportUserList', async (params, { rejectWithValue }) => {
  try {
    const response = await API.get<ExportResponse>('/user/list/download-admin', { params });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || 'Failed to export data');
  }
});

const exportSlice = createSlice({
  name: 'adminExportSlice',
  initialState,
  reducers: {
    resetAdminExportState(state) {
      state.isLoading = false;
      state.fileUrl = null;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(exportUserList.pending, state => {
        state.isLoading = true;
        state.fileUrl = null;
        state.error = null;
      })
      .addCase(exportUserList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fileUrl = action.payload.url;
        state.error = null;
      })
      .addCase(exportUserList.rejected, (state, action) => {
        state.isLoading = false;
        state.fileUrl = null;
        state.error = action.payload || 'Unknown error occurred';
      });
  },
});

export const { resetAdminExportState } = exportSlice.actions;

export default exportSlice.reducer;
