import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { SyntheticEvent, useMemo } from 'react';
import SbButton from '../../../components/Button';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CreateShipmentContext from '../../../context/CreateShipment';
import { createShipmentContextType, userDetailsProps, userDetailsType } from '../../../types/shipment';
import { getAddressObj, getSingleShipmentPackagePayload } from '@Services/getShipmentPayload';
import { DraftShipmentCreatePayload } from '@Reducers/DraftShipment/Type';
import { createDraftShipment, updateDraftShipmentById } from '@Reducers/DraftShipment/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';

interface ActionsProps {
  onNext?: (event: React.SyntheticEvent) => void;
  disableNext?: boolean;
  details?: userDetailsType;
  detailsFor?: userDetailsProps['detailsFor'];
  userId?: string;
  accountNumber?: string;
  accountId?: string;
}

function Actions(props: ActionsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch<AppDispatch>();
  const { selectedDraftShipmentId: draftId } = useSelector((state: RootStateType) => state.DraftShipment);
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const {
    activeStep,
    setActiveStep,
    shipper,
    consignee,
    shipmentType,
    packageData,
    notes,
    boxes,
    pickupType,
    extraDetails,
  } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const { detailsFor } = props;
  function handleBack() {
    setActiveStep(activeStep - 1);
  }
  function handleNext(e: SyntheticEvent<HTMLButtonElement>) {
    if (props.onNext && !props.disableNext) {
      props.onNext(e);
      return;
    }
    setActiveStep(activeStep + 1);
  }

  const enableSaveAsDraft = useMemo(
    () => (detailsFor === 'Shipper' ? props.details?.name || props.details?.phoneNumber : true),
    [detailsFor, props.details?.name, props.details?.phoneNumber],
  );

  const onSaveDraft = () => {
    if (!enableSaveAsDraft) return;
    const { warehouse } = pickupType;
    const packageObj = getSingleShipmentPackagePayload({
      boxes,
      packageData,
      shipmentType,
      pickupType,
    });
    const details = { ...props.details };
    if (!details.telephoneCode) details.telephoneCode = organizationCountry?.telephoneCode?.toString() || '';
    if (!details.altContactTelephoneCode)
      details.altContactTelephoneCode = organizationCountry?.telephoneCode?.toString() || '';

    const postObj: DraftShipmentCreatePayload = {
      package: packageObj,
      shipper: getAddressObj(detailsFor === 'Shipper' ? (details as userDetailsType) : shipper),
      consignee: getAddressObj(detailsFor === 'Consignee' ? (details as userDetailsType) : consignee),
      shipmentNotes: notes,
      shipmentMode: shipmentType.shipmentMode,
      extraDetails: {
        shipper: {
          type: shipper.type,
          IdType: shipper.IdType,
          IdNumber: shipper.IdNumber,
        },
        consignee: {
          type: consignee.type,
          IdType: consignee.IdType,
          IdNumber: consignee.IdNumber,
        },
        other: extraDetails,
      },
      _onbehalf: props?.userId ? props?.userId : null,
      _accountId: props.accountId,
      accountNumber: props.accountNumber,
    };
    if (pickupType.type === 'dropOff') {
      postObj.warehouseId = warehouse;
    }
    if (draftId) {
      dispatch(
        updateDraftShipmentById({
          id: draftId,
          payload: postObj,
          navigate: '/shipment/draft?tab=saved',
          showModal: true,
        }),
      );
    } else {
      dispatch(createDraftShipment({ payload: postObj, navigate: '/shipment/draft?tab=saved', showModal: true }));
    }
    console.log('postObj', postObj);
  };

  return (
    <Grid container justifyContent={isMobile ? 'space-between' : 'end'} spacing={2} className="my-1">
      {/* {activeStep !== 0 && ( */}
      <Grid item xs={12} md={6}>
        <SbButton onClick={onSaveDraft} variant="outlined" disabled={!enableSaveAsDraft}>
          Save as draft
        </SbButton>
      </Grid>
      {/* )} */}
      <Grid container item xs={12} md={6} justifyContent={isMobile ? 'space-between' : 'end'} columnSpacing={2}>
        <Grid item width={'150px'}>
          {activeStep !== 0 && (
            <SbButton fullWidth onClick={handleBack} variant="outlined">
              Back
            </SbButton>
          )}
        </Grid>
        <Grid item width={'150px'}>
          <SbButton fullWidth onClick={handleNext} variant="contained" disabled={props.disableNext}>
            Next
            {/* <KeyboardArrowRightIcon className="" /> */}
          </SbButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Actions;
