import React, { useEffect, useState } from 'react';
import { Grid, TableBody, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootStateType } from '@Store';
import { getOdaChargeById, resetSingleOdaCharge } from '@Reducers/ODA';
import { SingleOdaCharge } from '@Reducers/ODA/Type';

// import SbTooltip from '../../../components/Tooltip';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import SbButton from '@Components/Button';
import Image from '@Components/Image';
import Loader from '@Components/Loader';
import Divider from '@Components/Divider';
import SbTextField from '@Components/Textfield';
import Dropdown from '@Components/Dropdown';

// import CheckIcon from '@mui/icons-material/Check';
// import ClearIcon from '@mui/icons-material/Clear';

import { downloadOdaCharges } from '../helper';
import FilterSearch from '@Components/Filter/Search';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

interface Props {
  showBackgroundColor: boolean;
}

function ODACharges(_props: Props) {
  const [filterText, setFilterText] = useState('');
  const [filterChargesList, setFilterChargesList] = useState<SingleOdaCharge[]>([]);
  // const { showBackgroundColor } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useTheme();
  const ODAState = useSelector((state: RootStateType) => state.oda);
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isODAChargesEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Oda_Charges.edit_ODA,
    PERFORM_ACTION.write,
  );

  const { id: odaId } = useParams() as { id: string };

  useEffect(() => {
    if (odaId) dispatch(getOdaChargeById(odaId));
    return () => {
      dispatch(resetSingleOdaCharge());
    };
  }, []);

  useEffect(() => {
    if (ODAState.singleOdaCharge?.charges) setFilterChargesList(ODAState.singleOdaCharge?.charges || []);
  }, [ODAState.singleOdaCharge?.charges]);

  const onSearchEnter = () => {
    let filterList: SingleOdaCharge[] = ODAState.singleOdaCharge?.charges || [];

    if (filterList && filterText.trim())
      filterList = filterList.filter(x => {
        return new RegExp(filterText.trim(), 'ig').test(x.pincode);
      });

    setFilterChargesList(filterList);
  };

  const onDownload = () => {
    if (ODAState.singleOdaCharge)
      downloadOdaCharges(ODAState.singleOdaCharge?.charges, ODAState.singleOdaCharge?._serviceProviderId.name);
  };

  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Loader loading={ODAState.loading} overly>
      <>
        <Grid
          container
          // className="p-4"
          // style={{
          //   borderRadius: '12px',
          //   backgroundColor: showBackgroundColor ? alpha(theme.palette.primary.light, 0.4) : '',
          // }}
        >
          <Grid item xs={12}>
            <Typography className="org-text underline">View ODA Charges</Typography>
            <Typography className="md-text flex items-center mt-2">
              Service : <span className="ml-1 font-semibold">{ODAState.singleOdaCharge?._serviceProviderId.name}</span>
              {/* <SbTooltip title="Please create one system to start setting up your account. You will be able to create more systems at later stage.">
            <InfoIcon className="ml-1" sx={{ width: '20px', height: '20px' }} />
          </SbTooltip> */}
            </Typography>
            <Divider className="mt-3 mb-3" />
          </Grid>

          <Grid container mb={3} justifyContent="space-between">
            <FilterSearch
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={onSearchEnter}
              onTextClear={() => {
                setFilterText('');
                if (ODAState.singleOdaCharge?.charges) setFilterChargesList(ODAState.singleOdaCharge?.charges || []);
              }}
            />
            <Grid item xs={12} sm={2} md={3} lg={2} xl={1.5}>
              <SbButton
                onClick={onDownload}
                fullWidth
                variant="outlined"
                sx={{ borderRadius: '4px !important', backgroundColor: theme.palette.common.white }}
                startIcon={
                  <Image
                    src={'/images/upload_document.svg'}
                    alt={'Speedbox'}
                    width={35}
                    height={35}
                    style={{ transform: 'rotate(180deg)' }}
                  />
                }
                disabled={!isODAChargesEditAllowed}
              >
                Download
              </SbButton>
            </Grid>
          </Grid>
          <Grid container sx={{ maxHeight: 400, overflowY: 'scroll' }}>
            <SBTable sx={{ background: theme.palette.common.white }} stickyHeader>
              <PincodeTableHead />
              <TableBody>
                {filterChargesList?.map((charge, index) => (
                  <PincodeRow key={charge._id} count={index + 1} {...charge} />
                ))}
              </TableBody>
            </SBTable>
          </Grid>
          <Divider className="mt-3 mb-3" />
          <Grid container justifyContent="flex-end">
            <SbButton onClick={onCancel} variant="outlined">
              Cancel
            </SbButton>
          </Grid>
        </Grid>
      </>
    </Loader>
  );
}

function PincodeTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableHeadCell width="100px">Sr no.</TableHeadCell>
        <TableHeadCell width="30%">Pincode</TableHeadCell>
        <TableHeadCell>ODA Charge</TableHeadCell>
        <TableHeadCell>Global Charges Applied </TableHeadCell>
        <TableHeadCell width="20%">Delivery Serviceability</TableHeadCell>
      </TableRow>
    </TableHead>
  );
}

interface PincodeRowProps extends SingleOdaCharge {
  count: number;
}
const PickupServiceabilityOptions = [
  { display: 'Yes', value: 'yes' },
  { display: 'No', value: 'no' },
];

const pincodeUpdateSchema = Yup.object().shape({
  zoneName: Yup.string().required(),
  isPickupServiceable: Yup.string().required(),
  pincode: Yup.string().required(),
  isEdit: Yup.boolean(),
});
interface ODAChargesState {
  zoneName: string;
  isPickupServiceable: 'yes' | 'no';
  pincode: string;
  isEdit: boolean;
}
const initODAChargesState: ODAChargesState = {
  zoneName: '',
  isPickupServiceable: 'no',
  pincode: '',
  isEdit: false,
};

function PincodeRow(props: PincodeRowProps) {
  const serviceable = props.canDeliver ? 'yes' : 'no';
  const [state, _setState] = useState<ODAChargesState>({ ...initODAChargesState });
  // const dispatch = useDispatch<AppDispatch>();

  // const onEdit = () => {
  //   setState({
  //     zoneName: props.zoneName,
  //     isPickupServiceable: serviceable,
  //     pincode: props.pincode,
  //     isEdit: true,
  //   });
  // };

  // const onCancel = () => {
  //   setState({ ...initODAChargesState });
  // };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...state },
    validationSchema: pincodeUpdateSchema,
    onSubmit(_values) {
      // dispatch(
      //   updatePincode([
      //     props.pincode,
      //     {
      //       zoneName: values.zoneName,
      //       isPickupServiceable: values.isPickupServiceable === 'yes',
      //       pincode: values.pincode,
      //     },
      //   ]),
      // );
      // onCancel();
    },
  });

  // const onDelete = () => {
  //   dispatch(deletePincode(props.pincode));
  // };

  return (
    <SBTableRow>
      <TableRowCell align="center" className="md-text">
        {props.count}
      </TableRowCell>
      <TableRowCell align="center" className="md-text">
        {state.isEdit ? (
          <SbTextField
            name="pincode"
            value={formik.values.pincode}
            onChange={formik.handleChange}
            error={formik.errors.pincode}
          />
        ) : (
          props.pincode
        )}
      </TableRowCell>
      <TableRowCell align="center" className="md-text">
        {state.isEdit ? (
          <SbTextField
            name="zoneName"
            value={formik.values.zoneName}
            onChange={formik.handleChange}
            error={formik.errors.zoneName}
          />
        ) : (
          props.charge
        )}
      </TableRowCell>
      <TableRowCell align="center" className="md-text">
        {props.applyOn?.join(', ')}
      </TableRowCell>
      <TableRowCell align="center" className="md-text capitalize">
        {state.isEdit ? (
          <Dropdown
            id={`pincode-${props._id}`}
            onChange={([value]) => {
              formik.setFieldValue('isPickupServiceable', value);
            }}
            value={[formik.values.isPickupServiceable]}
            options={PickupServiceabilityOptions}
            error={formik.errors.isPickupServiceable}
          />
        ) : (
          serviceable
        )}
      </TableRowCell>
      {/* <TableRowCell>
        <Grid container item justifyContent="center" gap={2} alignItems="center">
          {state.isEdit ? (
            <>
              <Grid
                textAlign="center"
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                <SbTooltip title="Update Pincode">
                  <div>
                    <CheckIcon />
                  </div>
                </SbTooltip>
              </Grid>
              <Grid textAlign="center" onClick={onCancel}>
                <SbTooltip title="Cancel Pincode">
                  <ClearIcon />
                </SbTooltip>
              </Grid>
            </>
          ) : (
            <>
              <Grid textAlign="center" onClick={onEdit}>
                <SbTooltip title="Edit Pincode">
                  <div>
                    <Image src="/images/icons/EditAction.svg" alt="Edit" />
                  </div>
                </SbTooltip>
              </Grid>

              <Grid textAlign="center" onClick={onDelete}>
                <SbTooltip title="Delete Pincode">
                  <div>
                    <Image src="/images/icons/DeleteAction.svg" alt="Delete" />
                  </div>
                </SbTooltip>
              </Grid>
            </>
          )}
        </Grid>
      </TableRowCell> */}
    </SBTableRow>
  );
}

export default ODACharges;
