/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { AccountListResponse, ErrorType, UserQuery } from './Type';
import { AxiosError } from 'axios';

const getUserList = createAsyncThunk<AccountListResponse, [string, UserQuery], { rejectValue: ErrorType }>(
  'getAccountlookupUserList',
  async ([modalId, queryParams], { rejectWithValue }) => {
    try {
      const res = await API.get(`/user/list/search-user`, { params: queryParams });
      return { ...res.data, params: queryParams, modalId };
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data, modalId });
    }
  },
);

export { getUserList };
