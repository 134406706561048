import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';

import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import GlobeIcon from '@mui/icons-material/LanguageOutlined';
import PhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import TermsIcon from '@mui/icons-material/GradingOutlined';
import PolicyIcon from '@mui/icons-material/PolicyOutlined';
import { Box, SvgIconProps, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getOrganizationDetails } from '@Reducers/OrganizationSetup';
import { Link } from 'react-router-dom';
import MuiLink from '@mui/material/Link';

const DetailsContainer = styled('div')(({ theme }) => ({
  // width: '190px',
  // height: '272px',
  backgroundColor: 'var(--mui-palette-background-hoverMenu)',
  padding: '23px',
  animation: 'append-animate 0.3s linear',
  // [theme.breakpoints.only('xl')]: {
  //   width: '282px',
  // },
  margin: '0.5rem',
  marginTop: theme.spacing(2),
  overflow: 'hidden',
  borderRadius: '0.4rem',
}));

function OrganizationContainer() {
  const dispatch = useDispatch<AppDispatch>();
  const organizationData = useSelector((state: RootStateType) => state.orgSetup.organizationData);
  const { name = '', contactInfo, websiteLink } = organizationData || {};

  useEffect(() => {
    dispatch(getOrganizationDetails());
  }, []);

  return (
    <DetailsContainer>
      <Box color={'primary.main'} className="org-text ">
        {name}
      </Box>
      <OrganizationDetail title="Website" Icon={SettingsIcon}>
        {websiteLink && (
          <MuiLink href={websiteLink} underline="always" target="_blank" color={'inherit'}>
            {websiteLink}
          </MuiLink>
        )}
      </OrganizationDetail>
      <OrganizationDetail title="Email" Icon={GlobeIcon}>
        {contactInfo?.supportEmail}
      </OrganizationDetail>
      <OrganizationDetail title="Phone" Icon={PhoneIcon}>
        {contactInfo?.telephoneCode.charAt(0) === '+' ? contactInfo?.telephoneCode : `+${contactInfo?.telephoneCode}`}{' '}
        {contactInfo?.contactNumber}
      </OrganizationDetail>
      <div className="mt-5  bg-black" style={{ width: '100%', height: '1px' }}></div>
      <Link to="/terms-and-conditions" className="text-decoration-none" style={{ color: 'black' }}>
        <OrganizationDetail title="Terms and Condition" Icon={TermsIcon}>
          Terms and Conditions
        </OrganizationDetail>
      </Link>
      <Link to="/privacy-policy" className="text-decoration-none" style={{ color: 'black' }}>
        <OrganizationDetail title="Privacy Policy" Icon={PolicyIcon}>
          Privacy Policy
        </OrganizationDetail>
      </Link>
    </DetailsContainer>
  );
}

interface OrgDetailsProps {
  Icon: React.FC<SvgIconProps>;
  children: React.ReactNode;
  title: string;
}

function OrganizationDetail({ Icon, children, title }: OrgDetailsProps) {
  return (
    <div style={{ marginTop: '12px' }} className="flex xs-text">
      <Tooltip title={title}>
        <Icon style={{ marginRight: '12px' }} />
      </Tooltip>
      <Tooltip title={children}>
        <span
          className="truncate"
          style={{
            fontWeight: '500',
          }}
        >
          {children}
        </span>
      </Tooltip>
    </div>
  );
}

export default OrganizationContainer;
