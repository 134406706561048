import * as Yup from 'yup';
import { USERNAME_REGEXP } from '../../../constants/regEx';
import { getSubdomain } from '../../../utils/getSubdomain';

const subdomain = getSubdomain();

export const CreateAdminSchema = Yup.object().shape({
  name: Yup.string().trim().required('Admin name is required.'),

  email: subdomain
    ? Yup.string().trim().email('Invalid email address') // Not required but must be a valid email if provided
    : Yup.string().trim().email('Invalid email address').required('Email is required.'),

  username: subdomain
    ? Yup.string()
        .trim()
        .required('Username is required.')
        .matches(USERNAME_REGEXP, 'Must be characters, numbers, or special characters')
    : Yup.string().trim().matches(USERNAME_REGEXP, 'Must be characters, numbers, or special characters'), // Not required

  password: Yup.string()
    .trim()
    .required('Password is required.')
    .min(8, 'Must be 8 characters or more. One Uppercase, One Lowercase, One Number, and One Special Case Character')
    .matches(/[A-Z]+/, 'One uppercase character')
    .matches(/[a-z]+/, 'One lowercase character')
    .matches(/\d+/, 'One number')
    .matches(/[@$!%*#?&]+/, 'One special character'),

  systems: Yup.array(Yup.string()).of(Yup.string().trim()).min(1, 'At least 1 system is required.'),
  accessGroup: Yup.string().required('Access group is required.'),
});

export const EditAdminSchema = Yup.object().shape({
  name: Yup.string().trim().required('Admin name is required.'),

  email: subdomain
    ? Yup.string().trim().email('Invalid email address')
    : Yup.string().trim().email('Invalid email address').required('Email is required.'),

  username: subdomain
    ? Yup.string()
        .trim()
        .required('Username is required.')
        .matches(USERNAME_REGEXP, 'Must be characters, numbers, or special characters')
    : Yup.string().trim().matches(USERNAME_REGEXP, 'Must be characters, numbers, or special characters'),

  password: Yup.string()
    .trim()
    .min(8, 'Must be 8 characters or more. One Uppercase, One Lowercase, One Number, and One Special Case Character')
    .matches(/[A-Z]+/, 'One uppercase character')
    .matches(/[a-z]+/, 'One lowercase character')
    .matches(/\d+/, 'One number')
    .matches(/[@$!%*#?&]+/, 'One special character'),

  systems: Yup.array(Yup.string()).of(Yup.string().trim()).min(1, 'At least 1 system is required.'),
  accessGroup: Yup.string().required('Access group is required.'),
});

export const InviteAdminSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address.').trim().required('Email is required.'),
});
