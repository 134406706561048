import Modal from '@Components/Modal/error';
import React from 'react';
import { useNavigate } from 'react-router';

interface BackToLoginModalProps {
  open: boolean;
  onClose: () => void;
}

export function BackToLoginModal({ open, onClose }: BackToLoginModalProps) {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate('/login');
  };

  return (
    <Modal
      icon={'/images/icons/user/Login_user_Icon.svg'}
      open={open}
      onClose={onClose}
      title={'Please login to book shipments'}
      btnText="Okay"
      onConfirm={handleConfirm}
    ></Modal>
  );
}
