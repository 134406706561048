import { createSlice } from '@reduxjs/toolkit';
import {
  // getInvoiceListFulfilled,
  getInvoiceListPending,
  getInvoiceListRejected,
  getNonAccountUnbilledListFulfilled,
  fetchInvoiceListPending,
  // fetchInvoiceListFulfilled,
  fetchInvoiceListRejected,
  createNonAccountInvoiceFulfilled,
  voidInvoiceFullFilled,
} from './case';
import { InvoiceListState } from './Type';
import {
  // getInvoiceList,
  getNonAccountUnbilledList,
  // fetchInvoiceList,
  sendAccountDataTo365,
  createNonAccountInvoice,
  createAccountInvoice,
  sendNonAccountDataTo365,
  voidInvoice,
  downloadInvoiceDoc,
} from './actions';

const initialState = <InvoiceListState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },
  nonAccountData: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },
};

const userSlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetInvoiceState: function (state) {
      state = { ...state, ...initialState };
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    // addCase(getInvoiceList.pending, getInvoiceListPending);
    // addCase(getInvoiceList.fulfilled, getInvoiceListFulfilled);
    // addCase(getInvoiceList.rejected, getInvoiceListRejected);

    addCase(getNonAccountUnbilledList.pending, getInvoiceListPending);
    addCase(getNonAccountUnbilledList.fulfilled, getNonAccountUnbilledListFulfilled);
    addCase(getNonAccountUnbilledList.rejected, getInvoiceListRejected);

    addCase(createNonAccountInvoice.pending, fetchInvoiceListPending);
    addCase(createNonAccountInvoice.fulfilled, createNonAccountInvoiceFulfilled);
    addCase(createNonAccountInvoice.rejected, fetchInvoiceListRejected);

    addCase(voidInvoice.pending, fetchInvoiceListPending);
    addCase(voidInvoice.fulfilled, voidInvoiceFullFilled);
    addCase(voidInvoice.rejected, fetchInvoiceListRejected);

    addCase(downloadInvoiceDoc.pending, fetchInvoiceListPending);
    addCase(downloadInvoiceDoc.fulfilled, voidInvoiceFullFilled);
    addCase(downloadInvoiceDoc.rejected, fetchInvoiceListRejected);
  },
});

const { resetInvoiceState } = userSlice.actions;

export {
  // getInvoiceList,
  getNonAccountUnbilledList,
  // fetchInvoiceList,
  sendAccountDataTo365,
  sendNonAccountDataTo365,
  createNonAccountInvoice,
  createAccountInvoice,
  resetInvoiceState,
  voidInvoice,
  downloadInvoiceDoc,
};
export default userSlice.reducer;
