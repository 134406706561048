import { BoxType } from '../types/shipment';

function twoDecimalPlace(str: string | number) {
  if (!str) return str;
  if (typeof str === 'string') return Number(Number(str).toFixed(2));
  else if (typeof str === 'number') return Number(str.toFixed(2));
  return str;
}

function inToCm(inches: number) {
  if (typeof inches !== 'number' || isNaN(inches)) return 1;
  return inches * 2.54;
}

function lbToKg(lbs: number) {
  if (typeof lbs !== 'number' || isNaN(lbs)) return 0;
  return lbs * 0.45359237;
}

function kgToLb(kg: number, isLbs?: boolean) {
  if (typeof kg !== 'number' || isNaN(kg)) return 0;
  if (!isLbs) return kg;
  return kg * 2.20462;
}

function calculateDimWt(length: number, width: number, height: number, dimWtDivisor: number) {
  return (length * width * height) / dimWtDivisor;
}

function calculateChargeableWt(weight: number, boxes: BoxType[], DIM_WT_DIVISOR: number, isWtinLbs: boolean) {
  weight = isWtinLbs ? lbToKg(weight) : weight;
  if (!(boxes instanceof Array)) return { chargeableWeight: weight };
  let boxesChargeableWt = 0;
  boxes.forEach(box => {
    if (!box.length || !box.width || !box.height) {
      boxesChargeableWt += Number(box.weight) || 0;
      return;
    }
    const boxChargeableWeight = getBoxChargeableWt(box, DIM_WT_DIVISOR, isWtinLbs);
    boxesChargeableWt += Number(boxChargeableWeight);
  });
  return { chargeableWeight: twoDecimalPlace(Math.max(boxesChargeableWt, weight)), weight: twoDecimalPlace(weight) };
}

function calculateBoxWiseChargeableWt(boxes: BoxType[], DIM_WT_DIVISOR: number, isWtinLbs: boolean) {
  return boxes.map(function (box) {
    let chargeableWeight = getBoxChargeableWt(box, DIM_WT_DIVISOR, isWtinLbs);
    const weight = isWtinLbs ? lbToKg(parseFloat(box.weight + '')) : box.weight;
    if (weight && Number(weight) > chargeableWeight) chargeableWeight = Number(weight);
    return {
      chargeableWeight,
      weight,
    };
  });
}

function getBoxChargeableWt(box: BoxType, DIM_WT_DIVISOR: number, isWtinLbs: boolean) {
  const weight = isWtinLbs ? lbToKg(parseFloat(box.weight + '')) : parseFloat(box.weight + '');
  if (!box.length || !box.width || !box.height) return weight;
  const length = isWtinLbs ? inToCm(parseFloat(box.length + '')) : parseFloat(box.length + '');
  const width = isWtinLbs ? inToCm(parseFloat(box.width + '')) : parseFloat(box.width + '');
  const height = isWtinLbs ? inToCm(parseFloat(box.height + '')) : parseFloat(box.height + '');
  const DIM_WT = calculateDimWt(length, width, height, DIM_WT_DIVISOR);
  if (!box.weight) return DIM_WT;
  return Number(twoDecimalPlace(Math.max(DIM_WT, weight)));
}

export { twoDecimalPlace, inToCm, kgToLb, lbToKg, calculateDimWt, calculateChargeableWt, calculateBoxWiseChargeableWt };
