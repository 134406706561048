import { Box, Grid, Typography } from '@mui/material';
import { UserObj } from '@Reducers/AccountLookup/Type';
import React from 'react';

interface CardProps {
  title: string;
  value: string;
}

function Card(props: CardProps) {
  return (
    <>
      <Box
        className="p-3 shadow rounded"
        minHeight={'5rem'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography className="font-medium">{props.title}</Typography>
        <Typography>{props.value}</Typography>
      </Box>
    </>
  );
}

interface UserDetailProps {
  user: UserObj | null;
}

function UserDetailCard({ user }: UserDetailProps) {
  if (!user) return <></>;
  const { _accountId, email, _belongToAdminId, name } = user || {};
  const isIndividualAccount = _accountId?.accountType === 'individual';
  const companyName = isIndividualAccount ? name : _accountId?._company?.[0]?.name;

  return (
    <Grid container item spacing={2.5}>
      <Grid item xs={12} sm={6} md={3}>
        <Card title="Account Number" value={_accountId?.accountNumber || ''} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card title={isIndividualAccount ? 'Name' : 'Company name'} value={companyName || 'N/A'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card title="Email" value={email || 'N/A'} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Card title="Belongs To" value={_belongToAdminId?.name || 'N/A'} />
      </Grid>
    </Grid>
  );
}

export default UserDetailCard;
