import { createSlice } from '@reduxjs/toolkit';
import { addAddress, getAllSavedAddress, getSavedAddressById } from './actions';
import {
  addAddressFulfilled,
  addAddressPending,
  addAddressRejected,
  getAddressByIdFulFilled,
  getAllAddressFulfilled,
} from './case';
import { SavedAddressStateType } from './Type';

const initialState = <SavedAddressStateType>{
  savedAddressList: [],
  savedAddressById: {
    type: '',
    phone: {
      telephoneCode: '',
      number: '',
    },
    alternatePhone: undefined,
    name: '',
    companyName: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    label: '',
  },
  shipperAddresses: [],
  consigneeAddresses: [],
  loading: false,
  success: { msg: '' },
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
};

const userSlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    // setUser: setCountryHandler,
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getAllSavedAddress.pending, addAddressPending);
    addCase(getAllSavedAddress.fulfilled, getAllAddressFulfilled);
    addCase(getAllSavedAddress.rejected, addAddressRejected);

    addCase(getSavedAddressById.pending, addAddressPending);
    addCase(getSavedAddressById.fulfilled, getAddressByIdFulFilled);
    addCase(getSavedAddressById.rejected, addAddressRejected);

    addCase(addAddress.pending, addAddressPending);
    addCase(addAddress.fulfilled, addAddressFulfilled);
    addCase(addAddress.rejected, addAddressRejected);
  },
});

// const { setUser } = userSlice.actions;

export { addAddress };
export default userSlice.reducer;
