import { Grid } from '@mui/material';
import React from 'react';
import { BoxButton } from '../../StyleComponent';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

interface PropsType {
  onCancel: () => void;
  onConfirm: () => void;
  disabled?: boolean;
}

export default function Actions({ onCancel, onConfirm, disabled }: PropsType) {
  return (
    <Grid container justifyContent="space-between" alignItems="center" columnSpacing={2} mt={1}>
      <Grid item xs={6} title="Cancel">
        <BoxButton onClick={onCancel} className="truncate">
          <CloseIcon />
          Cancel
        </BoxButton>
      </Grid>
      <Grid item xs={6} title="Confirm">
        <BoxButton onClick={onConfirm} className="truncate" disabled={disabled}>
          <CheckIcon />
          Confirm
        </BoxButton>
      </Grid>
    </Grid>
  );
}
