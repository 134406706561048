import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import GetRatesWithoutLogin from '../pages/GetRatesWithoutLogin';
import Login from '../pages/Login';
import Register from '../pages/Register';
import ForgotPassword from '../pages/ForgotPassword';
import OrganizationOnboard from '../pages/OrganizationOnboard';
import TrackShipment from '../pages/TrackShipment';
import TermsAndConditionsBeforeAuth from '../pages/TermsAndConditionsBeforeAuth';
import PrivacyPolicyBeforeAuth from '../pages/PrivacyPolicyBeforeAuth';

export default (
  <>
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<Register />} />
    <Route path="/forgotpassword" element={<ForgotPassword />} />
    <Route path="/organization-onboard/:orgName" element={<OrganizationOnboard />} />
    <Route path="/login/get-rates" element={<GetRatesWithoutLogin />} />
    <Route path="/login/track-shipment" element={<TrackShipment />} />
    <Route path="/track-shipment" element={<TrackShipment />} />
    <Route path="*" element={<Navigate to="/login" replace />} />
    <Route path="/terms-and-conditions-agreement" element={<TermsAndConditionsBeforeAuth />} />
    <Route path="/privacy-policy-agreement" element={<PrivacyPolicyBeforeAuth />} />
  </>
);
