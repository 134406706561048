import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import SbButton from '../../../components/Button';
import { OrgViewFor } from '../../../types/organization';
import { useNavigate } from 'react-router';

interface ActionsProps {
  onNext?: () => void;
  onBack?: (event: React.SyntheticEvent) => void;
  setApplyToAllSystem?: () => void;
  disableNext?: boolean;
  viewFor: OrgViewFor;
  activeStep?: number;
  disabled?: boolean;
  currentTap?: string;
  setCurrentTap?: React.Dispatch<React.SetStateAction<string>>;
}

function Actions(props: ActionsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigation = useNavigate();
  // const { activeStep } = React.useContext(OrganizationSetupContext) as OrganizationSetupContextType;

  const tabMappings: { [key: string]: string } = {
    billing: 'terms_and_conditions',
    terms_and_conditions: 'bank_details',
  };

  function handleNext(_e: SyntheticEvent<HTMLButtonElement>) {
    if (props.onNext && !props.disableNext) {
      props.onNext();
      if (
        props.currentTap &&
        (props.currentTap === 'billing' ||
          props.currentTap === 'terms_and_conditions' ||
          props.currentTap === 'bank_details')
      ) {
        const nextTab = tabMappings[props.currentTap];
        if (nextTab && props.setCurrentTap) {
          navigation(`?tab=${nextTab}`);
          props.setCurrentTap(nextTab);
        }
      }
      return;
    }
  }
  return (
    <Grid
      container
      justifyContent={props.viewFor === 'org-setup' ? (isMobile ? 'space-between' : 'center') : 'end'}
      columnSpacing={2}
      className="my-1"
    >
      {props.viewFor === 'org-setup' ? (
        <>
          {props?.activeStep !== 0 && (
            <Grid item xs={6} sm={3} md={2}>
              <SbButton fullWidth onClick={props.onBack} variant="outlined">
                Back
              </SbButton>
            </Grid>
          )}
          <Grid item xs={6} sm={3} md={2}>
            <SbButton fullWidth onClick={handleNext} variant="contained" disabled={props.disableNext}>
              Next
              {/* <KeyboardArrowRightIcon className="" /> */}
            </SbButton>
          </Grid>
        </>
      ) : (
        <>
          {props?.setApplyToAllSystem && (
            <Grid item xs={6} sm={3} md={3}>
              <SbButton fullWidth onClick={props.setApplyToAllSystem} variant="outlined" disabled={props.disabled}>
                Apply to All Systems
              </SbButton>
            </Grid>
          )}
          <Grid item xs={6} sm={3} md={2}>
            <SbButton fullWidth onClick={handleNext} variant="contained" disabled={props.disabled || props.disableNext}>
              Save and Next
            </SbButton>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Actions;
