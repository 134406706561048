import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../Modal';
import { Checkbox, Grid, IconButton, Radio, Typography, styled, useTheme, Box, Stack } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SbButton from '../Button';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SbTextField from '@Components/Textfield';
import {
  BeforePaymentPostData,
  PAYMENT_MODE,
  PaymentModes,
  createPaymentPayload,
  createCashPayment,
  getWalletBalance,
} from './PaymentService';
import { PaymentDetails } from '.';
import { useSnackbar } from 'notistack';
import Loader from '@Components/Loader';
import { usePayment } from '@Hook/usePayment';
import { Payments } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';

interface PropsType {
  isOpen: boolean;
  handleClose: () => void;
  paymentDetails: PaymentDetails;
}

interface PaymentOptionType {
  title: string;
  subTitle: string;
  icon: React.ReactNode;
  onSelect: ({ isSelected, amount }: SelectedAccountType) => void;
  selectedAmount: SelectedAccountType;
  amount: number;
  isDisabled: boolean;
  isRadio: boolean;
}

interface SelectedAccountType {
  isSelected: boolean;
  amount: number;
}

const PaymentOptionWrapper = styled(Grid)<{ isDisabled: boolean }>(({ isDisabled }) => ({
  border: '1px solid',
  marginBottom: '30px',
  opacity: isDisabled ? 0.6 : 1,
}));

function PaymentOption({
  title,
  subTitle,
  icon,
  selectedAmount,
  isDisabled,
  onSelect,
  amount,
  isRadio,
}: PaymentOptionType) {
  const { orgCountryObj } = useSelector((state: RootStateType) => state.orgSetup);

  return (
    <PaymentOptionWrapper container className="p-3 border-grey rounded" alignItems={'center'} isDisabled={isDisabled}>
      <Grid item xs={1} mr={4}>
        {icon}
      </Grid>
      <Grid item xs={7}>
        <Typography className="md-text font-semibold">{title}</Typography>
        <Typography>{subTitle}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className="md-text font-semibold">
          {amount} {orgCountryObj?.currency?.name}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        {isRadio ? (
          <Radio
            className="ml-3"
            style={{ color: selectedAmount.isSelected ? 'green' : 'inherit' }}
            disabled={isDisabled}
            onChange={() => {
              onSelect({ isSelected: !selectedAmount.isSelected, amount: selectedAmount.amount });
            }}
            checked={selectedAmount.isSelected}
          />
        ) : (
          <Checkbox
            className="ml-3"
            style={{ color: isDisabled ? 'grey' : selectedAmount.isSelected ? 'green' : 'inherit' }}
            disabled={isDisabled}
            onChange={() => {
              onSelect({ isSelected: !selectedAmount.isSelected, amount: selectedAmount.amount });
            }}
            checked={selectedAmount.isSelected}
          />
        )}
      </Grid>
    </PaymentOptionWrapper>
  );
}

function PaymentModal({ isOpen, handleClose, paymentDetails }: PropsType) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const iconStyles = { color: theme.palette.primary.main, height: '40px', width: '40px' };
  const { isPrepaidUser, accountNumber, setOpenPaymentModal, isTaxAllowed } = usePayment();
  const { organizationPublicData, orgCountryObj } = useSelector((state: RootStateType) => state?.orgSetup);
  const { shipmentNumber, payableAmount, handlePaymentSuccess } = paymentDetails;
  const { shipmentPayment } = useSelector((state: RootStateType) => state.shipment);
  const { paymentGetwayList } = useSelector((state: RootStateType) => state.Wallet);

  const { tdsAmount = 0 } = shipmentPayment || {};

  const dispatch = useDispatch<AppDispatch>();
  const [isNetBanking, setIsNetBanking] = useState<SelectedAccountType>({
    isSelected: false,
    amount: 0,
  });
  const [isCashPay, setIsCashPay] = useState<SelectedAccountType>({
    isSelected: false,
    amount: 0,
  });
  const [enablePay, setEnablePay] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [walletAmount, setWalletAmount] = useState<number>(0);
  const [isWallet, setIsWallet] = useState<SelectedAccountType>({ isSelected: true, amount: 0 });
  const [tdsMoney, setTdsMoney] = useState<string>('');
  const [netPayable, setNetPayable] = useState<number>(payableAmount);
  useEffect(() => {
    setNetPayable(payableAmount);
  }, [payableAmount]);
  useEffect(() => {
    const tds = parseFloat(tdsMoney) || 0;
    const netAmount = payableAmount - tds;
    const roundedNetAmount = parseFloat(netAmount.toFixed(2));
    setNetPayable(roundedNetAmount > 0 ? roundedNetAmount : 0);
  }, [tdsMoney]);

  const walletAmountToBePaid = useMemo(() => {
    return netPayable < walletAmount ? netPayable : walletAmount;
  }, [netPayable, walletAmount]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getWalletBalance();
      setLoading(false);
      if (res?.data?.balance) {
        setWalletAmount(res?.data?.balance);
      } else if (res?.isError) {
        enqueueSnackbar(res.msg, { variant: 'error', key: new Date().getTime() });
        // setOpenPaymentModal(false);
      }
    })();
  }, []);

  useEffect(() => {
    setIsWallet(prevState => ({
      ...prevState,
      amount: walletAmountToBePaid,
    }));

    setIsNetBanking(prevState => ({
      ...prevState,
      amount: netPayable,
    }));

    setIsCashPay(prevState => ({
      ...prevState,
      amount: netPayable,
    }));
  }, [netPayable, walletAmount, walletAmountToBePaid]);

  useEffect(() => {
    const amount = Number(
      Number(
        (isWallet.isSelected ? isWallet.amount : 0) +
          ((isNetBanking.isSelected ? isNetBanking.amount : 0) || (isCashPay.isSelected ? isCashPay.amount : 0)),
      ).toFixed(2),
    );
    setEnablePay(amount === netPayable);
  }, [isWallet, isNetBanking, netPayable, isCashPay]);

  useEffect(() => {
    const amount = isWallet.isSelected ? Number(Number(netPayable - isWallet.amount)?.toFixed(2)) : netPayable;
    setIsNetBanking(({ isSelected }) => ({
      isSelected: amount > 0 ? isSelected : false,
      amount: amount,
    }));
    setIsCashPay(({ isSelected }) => ({
      isSelected: amount > 0 ? isSelected : false,
      amount: amount,
    }));
  }, [isWallet, netPayable]);

  const onPaymentSuccess = () => {
    handlePaymentSuccess();
    setOpenPaymentModal(false);
  };
  useEffect(() => {
    if (isWallet.isSelected && walletAmount >= payableAmount) {
      setTdsMoney('');
    }
  }, [isWallet.isSelected]);
  useEffect(() => {
    if (walletAmount == 0) {
      setIsWallet({
        amount: 0,
        isSelected: false,
      });
    }
    if (walletAmount > 0) {
      setIsWallet(prevState => ({
        ...prevState,
        isSelected: true,
      }));
    }
  }, [walletAmount]);
  const handlePayment = async () => {
    try {
      setLoading(true);
      const tdsToUse = tdsAmount > 0 ? tdsAmount : Number(tdsMoney);
      if (!isWallet.isSelected && isCashPay.isSelected) {
        const res = await createCashPayment({ postData: { shipmentNumber, tdsAmount: tdsToUse } });
        if (res?.isError) throw res;
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            subTitle: `Your shipment has been placed Successfully. Please pay cash to ${organizationPublicData?.name} to process the shipment. Thank you!`,
            title: 'Success',
            onConfirm: () => {
              onPaymentSuccess();
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
        setLoading(false);
        return;
      }
      const paymentMode: PaymentModes =
        isWallet.isSelected && isNetBanking.isSelected
          ? PAYMENT_MODE.gatewayWallet
          : isWallet.isSelected && isCashPay.isSelected
          ? PAYMENT_MODE.WalletAndCash
          : isWallet.isSelected
          ? PAYMENT_MODE.wallet
          : PAYMENT_MODE.gateway;
      const postData: BeforePaymentPostData = {
        shipmentNumber: shipmentNumber,
        accountNumber,
        paymentMode,
        currency: orgCountryObj?.currency?.name || 'INR',
        tdsAmount: Number(tdsMoney),
        amount: Number(
          (isWallet.isSelected ? payableAmount - walletAmountToBePaid - tdsToUse : payableAmount - tdsToUse).toFixed(2),
        ),
      };
      postData.totalAmount = payableAmount;
      if (paymentMode === PAYMENT_MODE.gatewayWallet) {
        postData.walletAmount = walletAmount;
      }
      if (isWallet.isSelected) {
        postData.walletAmount = walletAmount;
      }
      const res = await createPaymentPayload({ postData, handlePaymentSuccess: onPaymentSuccess });
      if (res?.isError) throw res;
    } catch (error) {
      if (error?.isPaid) return onPaymentSuccess();
      if (error?.isError) {
        error?.msg && enqueueSnackbar(error?.msg, { variant: 'error', key: new Date().getTime() });
        // handleClose();
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      dialogContainerSx={{
        paddingTop: 1,
        paddingRight: 1,
        paddingLeft: 3,
      }}
      // onClose={handleClose}
    >
      <Loader loading={loading} overly>
        <div className="pr-2 pt-1" style={{ width: '580px' }}>
          <Grid container justifyContent={'space-between'}>
            <Typography className="md-text font-bold pt-2">Payment Options</Typography>
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent={'center'}>
            <Typography className="l-text font-bold my-4">
              Total: {payableAmount} {orgCountryObj?.currency?.name}
            </Typography>
          </Grid>
          {isPrepaidUser && (
            <PaymentOption
              isRadio={false}
              title={'Speedbox wallet'}
              subTitle={`Available balance: ₹ ${walletAmount}`}
              icon={<AccountBalanceWalletIcon sx={iconStyles} />}
              selectedAmount={isWallet}
              onSelect={setIsWallet}
              amount={walletAmountToBePaid}
              isDisabled={!walletAmountToBePaid}
            />
          )}
          <PaymentOption
            isRadio={true}
            title={'Card/ Netbanking/ UPI'}
            subTitle={'other payment mode'}
            icon={<CreditCardIcon sx={iconStyles} />}
            selectedAmount={isNetBanking}
            onSelect={data => {
              setIsNetBanking(data);
              setIsCashPay(prev => ({ ...prev, isSelected: false }));
            }}
            // amount={payableAmount < walletAmount ? 0 : isWallet.isSelected ? payableAmount - walletAmount : payableAmount}
            amount={isNetBanking.amount}
            isDisabled={
              !!(
                !isNetBanking.amount ||
                (paymentGetwayList && !paymentGetwayList.some(({ gateway }) => gateway === 'razorpay'))
              )
            }
          />
          <PaymentOption
            isRadio={true}
            title={'Pay by cash'}
            subTitle={'other payment mode'}
            icon={<Payments sx={iconStyles} />}
            selectedAmount={isCashPay}
            onSelect={data => {
              setIsCashPay(data);
              setIsNetBanking(prev => ({ ...prev, isSelected: false }));
            }}
            // amount={payableAmount < walletAmount ? 0 : isWallet.isSelected ? payableAmount - walletAmount : payableAmount}
            amount={isCashPay.amount}
            isDisabled={!isCashPay.amount}
          />
          <Box className="mb-4">
            <SbTextField
              label={`Less TDS`}
              value={tdsMoney}
              placeholder={tdsAmount > 0 ? tdsAmount.toString() : '2000'}
              onChange={e => {
                if (Number(e.target.value) < payableAmount) {
                  setTdsMoney(e.target.value);
                }
              }}
              type="string"
              name="tds"
              error={''}
              disabled={!isTaxAllowed || tdsAmount > 0}
              startAdornment={
                <Stack direction={'row'} gap={'10px'} paddingX={'10px'}>
                  <span>{orgCountryObj?.currency?.name} </span>
                  <span>|</span>
                </Stack>
              }
            />
          </Box>
          <SbButton
            variant="contained"
            className="w-full"
            disabled={!enablePay}
            onClick={_e => {
              handlePayment();
            }}
          >
            Pay {netPayable} {orgCountryObj?.currency?.name}
          </SbButton>
        </div>
      </Loader>
    </Modal>
  );
}

export default PaymentModal;
