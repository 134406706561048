import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { TabPanel } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import Title from '../../../components/Title';
import SbTextField from '../../../components/Textfield';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SbButton from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';
import { updateUserProfile } from '../../../store/reducers/loginUser/actions';
import { userLogin } from '@Services/hasAdminAccess';
import SbTooltip from '@Components/Tooltip';

const ProfileInfoStyle = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '12px',
}));

function TabProfile() {
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const profileState = useSelector((state: RootStateType) => state.loginUser.profile);
  const organizationData = useSelector((state: RootStateType) => state.loginUser.profile?._accountId?._company?.[0]);
  const countriesState = useSelector((state: RootStateType) => state.global.countries);
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();

  const Address = useMemo(() => {
    const { city, state, pincode, line1, line2 } = organizationData?._address || {};
    return { line1, line2, city, state, country, pincode };
  }, [organizationData, country]);

  useEffect(() => {
    if (profileState) setName(profileState?.name);
  }, [profileState]);

  useEffect(() => {
    if (profileState && countriesState.length) {
      const userCountry = countriesState.find(country => country._id === profileState?._countryId);
      if (userCountry) setCountry(userCountry.name);
    }
  }, [countriesState, profileState?._countryId]);

  const contactNumber = `+ ${profileState?.telephoneCode || ''} ${profileState?.phoneNumber || ''}`;

  const onCancel = () => {
    if (profileState) setName(profileState?.name);
  };

  const onUserInfoUpdate = () => {
    if (name?.trim() && name !== profileState?.name) dispatch(updateUserProfile({ name }));
  };

  const btnDisable: boolean = name?.trim() ? profileState?.name === name : true;

  return (
    <TabPanel value="profile" className="p-0 w-full">
      <Grid container gap={'60px'}>
        <ProfileInfoStyle item xs={12} md={4} lg={3} alignSelf="flex-start">
          <Grid container bgcolor={'primary.main'} p="32px 25px" borderRadius="12px 12px 0 0">
            <Grid item xs={12}>
              <Typography color="#fff" className="l-text font-bold truncate" title={profileState?.name || ''}>
                {profileState?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isAdmin ? (
                <Typography color="#fff" className="md-text font-medium">
                  Username. : {profileState?.username}
                </Typography>
              ) : (
                <Typography color="#fff" className="md-text font-medium">
                  Account No. : {profileState?._accountId?.accountNumber}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container p="34px" gap={'32px'}>
            {/* ---------------------------------------------------------------------------------------------------------Company Info  */}
            {!profileState?._accountId?.isDefaultAccount && !isAdmin && (
              <ProfileUserInfo
                title="Company:"
                subTitle1={organizationData?.name || '--'}
                subTitle2={Object.values(Address).join(' ')}
              />
            )}
            <ProfileUserInfo
              title="Created by:"
              subTitle1={profileState?.createdBy?.name || '--'}
              subTitle2={profileState?.createdBy?.email || ''}
            />
            <ProfileUserInfo
              title="Belongs to:"
              subTitle1={profileState?._belongToAdminId?.name || '--'}
              subTitle2={profileState?._belongToAdminId?.email || ''}
            />
          </Grid>
        </ProfileInfoStyle>
        <Grid item xs={12} md={7} lg={8} p={{ md: '0 32px' }}>
          <Title title="Profile" subTitle="Update your personal details here." />
          {/* ---------------------------------------------------------------------------------------------------------- USER NAME */}
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} md={3}>
              <Typography className="font-medium md-text">Name*</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={7} pr={{ md: '48px' }}>
              <Grid item xs={12}>
                <SbTextField
                  value={name}
                  onChange={e => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 80) {
                      setName(e.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </ContainerStyle>

          {/* ---------------------------------------------------------------------------------------------------------- EMAIL ADDRESS */}
          {!isAdmin && (
            <ContainerStyle container alignItems="center">
              <Grid item xs={12} md={3}>
                <Typography className="font-medium md-text">Email address</Typography>
              </Grid>
              <Grid container item xs={12} md={6} lg={7} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={10} md={11}>
                  <SbTextField
                    startAdornment={<EmailOutlinedIcon sx={{ mr: '6px' }} />}
                    value={profileState?.email || ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={1} textAlign="center">
                  <SbTooltip placement="right" title={`Please contact the admin to update this details`}>
                    <LockOutlinedIcon />
                  </SbTooltip>
                </Grid>
              </Grid>
            </ContainerStyle>
          )}

          {/* ---------------------------------------------------------------------------------------------------------- CONTACT */}
          {!isAdmin && (
            <ContainerStyle container alignItems="center">
              <Grid item xs={12} md={3}>
                <Typography className="font-medium md-text">Contact</Typography>
              </Grid>
              <Grid container item xs={12} md={6} lg={7} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={10} md={11}>
                  <SbTextField value={contactNumber} disabled />
                </Grid>
                <Grid item xs={1} textAlign="center">
                  <SbTooltip placement="right" title={`Please contact the admin to update this details`}>
                    <LockOutlinedIcon />
                  </SbTooltip>
                </Grid>
              </Grid>
            </ContainerStyle>
          )}

          {/* ---------------------------------------------------------------------------------------------------------- COUNTRY */}
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} md={3}>
              <Typography className="font-medium md-text">Country</Typography>
            </Grid>
            <Grid container item xs={12} md={6} lg={7} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={10} md={11}>
                <SbTextField value={country} disabled />
              </Grid>
              <Grid item xs={1} textAlign="center">
                <SbTooltip placement="right" title={`Please contact the admin to update this details`}>
                  <LockOutlinedIcon />
                </SbTooltip>
              </Grid>
            </Grid>
          </ContainerStyle>

          {/* ---------------------------------------------------------------------------------------------------------- ORGANIZATION NAME
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} md={3}>
              <Typography className="font-medium md-text">Organization</Typography>
            </Grid>
            <Grid container item xs={12} md={6} lg={7} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={10} md={11}>
                <SbTextField value="Speedbox" disabled />
              </Grid>
              <Grid item xs={1} textAlign="center">
                <LockOutlinedIcon />
              </Grid>
            </Grid>
          </ContainerStyle> */}

          {/* ---------------------------------------------------------------------------------------------------------- BTN */}
          <Grid
            container
            justifyContent={{ xs: 'space-evenly', md: 'flex-end' }}
            mt={'32px'}
            gap={{ md: '12px' }}
            pr={{ md: '120px' }}
          >
            <Grid item xs={5} md={3} lg={2}>
              <SbButton variant="contained" className="rounded-full" fullWidth onClick={onCancel} disabled={btnDisable}>
                Cancel
              </SbButton>
            </Grid>
            <Grid item xs={5} md={3} lg={2}>
              <SbButton
                variant="outlined"
                className="rounded-full"
                fullWidth
                disabled={btnDisable}
                onClick={onUserInfoUpdate}
              >
                Save
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabPanel>
  );
}

const ContainerStyle = styled(Grid)(({ theme: _ }) => ({
  padding: '20px 0',
  borderBottom: '1px solid #EAECF0',
}));

function ProfileUserInfo(props: { title: string; subTitle1: string; subTitle2: string }) {
  return (
    <Grid container item xs={12} gap={'4px'}>
      <Grid item xs={12}>
        <Typography color="primary.main" className="sm-text font-semibold">
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary.main" className="sm-text">
          {props.subTitle1}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary.main" className="sm-text break-words">
          {props.subTitle2}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TabProfile;
