import React from 'react';
import { DocumentUploadSuccessfullyType } from './Type';
import UploadSuccessModal from '../../components/Modal/error';

function DocumentUploadSuccessfully(props: DocumentUploadSuccessfullyType) {
  return (
    // <Modal open={props.open}>
    //   <Grid container className="mb-3" gap={3} justifyContent="flex-end">
    //     <IconButton onClick={props.onClose}>
    //       <CloseIcon />
    //     </IconButton>
    //     <Grid container item justifyContent="center">
    //       <TaskAltIcon sx={{ width: '100px', height: '100px' }} color="success" />
    //     </Grid>
    //     <Grid container item justifyContent="center" flexDirection="column" textAlign="center">
    //       <Typography fontWeight="bold" variant="h5">
    //         Upload Successful
    //       </Typography>
    //       <Typography variant="h6" mt={0.5}>
    //         Document uploaded successfully.
    //       </Typography>
    //     </Grid>
    //   </Grid>
    // </Modal>
    <UploadSuccessModal
      icon="/images/icons/SuccessIcon.svg"
      open={props.open}
      title="Upload Successful"
      subTitle={' Document uploaded successfully.'}
      onClose={props.onClose}
      closeIcon={true}
      btnText="Close"
      onConfirm={props.onClose}
    />
  );
}
export default DocumentUploadSuccessfully;
