import * as Yup from 'yup';

const SquadSchema = Yup.object({
  username: Yup.string()
    .matches(
      /^[A-Za-z]+[A-Za-z0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]*$/,
      'Username must start with a letter and can contain numbers or special characters after it, but no spaces',
    )
    .min(3, 'Username must be at least 3 characters')
    .required('Username is required'),
  name: Yup.string()
    .matches(/^[A-Za-z][A-Za-z ]*$/, 'Name is invalid')
    .min(3, 'Name must be at least 3 characters')
    .required('Name is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  phoneNumber: Yup.string().required('Contact is required'),
  system: Yup.string().required('System is required'),
  zones: Yup.array()
    .of(Yup.string().required('Zone ID is required'))
    .min(1, 'At least one zone is required')
    .required('Zones are required'),
  reportTo: Yup.string().required('Report To name is required'),
  reportToPhoneNumber: Yup.string().required('Report To phone number is required'),
  vehicle: Yup.string().required('Vehicle is required'),
  kyc: Yup.mixed()
    .required('KYC document is required')
    .test('fileSize', 'File size should be 2MB or smaller', value => {
      if (!value || !(value instanceof File)) return true;
      return value.size <= 2 * 1024 * 1024;
    })
    .test('fileType', 'Only SVG, PNG, JPG, or GIF files are allowed', value => {
      if (!value || !(value instanceof File)) return true;
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
      return allowedTypes.includes(value.type);
    }),
});

const updateSchema = {
  username: Yup.string()
    .matches(
      /^[A-Za-z]+[A-Za-z0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]*$/,
      'Username must start with a letter and can contain numbers or special characters after it, but no spaces',
    )
    .min(3, 'Username must be at least 3 characters')
    .required('Username is required'),
  name: Yup.string()
    .matches(/^[A-Za-z][A-Za-z ]*$/, 'Name is invalid')
    .min(3, 'Name must be at least 3 characters')
    .required('Name is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  phoneNumber: Yup.string().required('Contact is required'),
  system: Yup.string().required('System is required'),
  zones: Yup.array()
    .of(Yup.string().required('Zone ID is required'))
    .min(1, 'At least one zone is required')
    .required('Zones are required'),
  reportTo: Yup.string().required('Report To name is required'),
  reportToPhoneNumber: Yup.string().required('Report To phone number is required'),
  vehicle: Yup.string().required('Vehicle is required'),
  kyc: Yup.mixed()
    .test('documentOrKyc', 'KYC document is required', function (value) {
      const { document } = this.parent;
      if (document) return true;
      return !!value;
    })
    .test('fileSize', 'File size should be 2MB or smaller', value => {
      if (!value || !(value instanceof File)) return true;
      return value.size <= 2 * 1024 * 1024;
    })
    .test('fileType', 'Only SVG, PNG, JPG, or GIF files are allowed', value => {
      if (!value || !(value instanceof File)) return true;
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];
      return allowedTypes.includes(value.type);
    }),
};

export const CreateSquadSchema = Yup.object().concat(SquadSchema);

export const UpdateSquadSchema = Yup.object().shape({
  ...updateSchema,
});

export const CreateWarehouseAgentSchema = Yup.object().shape({
  username: Yup.string()
    .matches(
      /^[A-Za-z]+[A-Za-z0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]*$/,
      'Username must start with a letter and can contain numbers or special characters after it, but no spaces',
    )
    .min(3, 'Username must be at least 3 characters')
    .required('Username is required'),

  name: Yup.string()
    .matches(/^[A-Za-z][A-Za-z ]*$/, 'Name is invalid')
    .min(3, 'Name must be at least 3 characters')
    .required('Name is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  system: Yup.string().required('System is required'),
  reportTo: Yup.string().required('Report To name is required'),
  reportToPhoneNumber: Yup.string().required('Report To phone number is required'),
  warehouse: Yup.array()
    .of(Yup.string().required('Warehouse ID is required'))
    .min(1, 'At least one warehouse is required')
    .required('Warehouse is required'),
});
export const UpdateWarehouseAgentSchema = Yup.object().shape({
  username: Yup.string()
    .matches(
      /^[A-Za-z]+[A-Za-z0-9!@#$%^&*()_+=[\]{};':"\\|,.<>/?-]*$/,
      'Username must start with a letter and can contain numbers or special characters after it, but no spaces',
    )
    .min(3, 'Username must be at least 3 characters')
    .required('Username is required'),

  name: Yup.string()
    .matches(/^[A-Za-z][A-Za-z ]*$/, 'Name is invalid')
    .min(3, 'Name must be at least 3 characters')
    .required('Name is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain at least one special character')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  system: Yup.string().required('System is required'),
  reportTo: Yup.string().required('Report To name is required'),
  reportToPhoneNumber: Yup.string().required('Report To phone number is required'),
  warehouse: Yup.array()
    .of(Yup.string().required('Warehouse ID is required'))
    .min(1, 'At least one warehouse is required')
    .required('Warehouse is required'),
});
