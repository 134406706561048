import { DropdownOptionType } from '@Components/Dropdown/Type';
import { CSP_TYPES } from '@Constants/Shipment';
import { FedXInformationType } from '@Reducers/AdminShipment/Type';
import { postFedExDetails } from '@Reducers/AdminShipment/actions';
import { AppDispatch } from '@Store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const initFedXInformation: FedXInformationType = {
  _id: '',
  csp: CSP_TYPES.fedex.value,
  accountNo: '',
  packagingType: '',
  pickupType: '',
  payor: '',
  printer: '',
  countryOfManufacture: '',
  bookingConfirmationNumber: '',
  shipmentPurpose: '',
  dryIceShipments: [],
  battery: [],
  specialHandlingOptions: [],
  locationId: '',
  thirdPartyPayor: '',
  dangerousGoodsType: '',
  invoiceNo: '',
  billDutiesAndTaxesTo: '',
  billDutiesAccountNo: '',
  poNo: '',
  customerReferenceNo: '',
  csDetails: '',
  serviceType: '',
  shipmentIdType: '',
  shipmentId: '',
  shipmentType: [],
  shipperShipmentType: '',
  broker: {
    accountNo: '',
    shipmentType: '',
    tinNo: '',
    phoneNumber: '',
    telephoneCode: '',
    contactId: '',
    companyName: '',
    name: '',
    title: '',
    faxNo: '',
    email: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  },
  uplodedDocuments: [],
  uplodedImages: [],
  createdAt: '',
  updatedAt: '',
  shipperTinNo: '',
  commercialRoutingNo: '',
  exportComplianceStatement: '',
  isRequiredComplianceStatement: false,
  pickupDate: null,
  warehouseId1: '',
  warehouseId2: '',
  type: 'D-D',
  connectOn: {},
  pickup: {
    location: '',
    _requestedBy: '',
    pickupConfirmationCode: '',
    readyDateTimestamp: null,
  },
  b13AFilingOption: '',
  // harmonizedCode: '',
};

export const onForward = async ({
  values,
  shipmentNumber,
  dispatch,
  nextFunc,
  setIsLoading,
}: {
  values: FedXInformationType;
  shipmentNumber: string;
  dispatch: AppDispatch;
  nextFunc: () => void;
  setIsLoading?: any;
}) => {
  setIsLoading(true);
  // const body = { ...JSON.parse(JSON.stringify(values)), shipperTinNo: `${values.shipmentIdType}${values.shipmentId}` };
  const body = { ...JSON.parse(JSON.stringify(values)), shipperTinNo: `${values.shipmentId}` };

  //\\ Remove unnecessary properties based on conditions //\\
  for (const key in body) {
    if (
      key === '_id' ||
      key === '_placed' ||
      key === 'isRequiredComplianceStatement' ||
      key === 'pickupDate' ||
      key === 'pickup'
    ) {
      // key === 'battery'
      delete body[key];
    }
    if (body[key]) {
      if (key === 'accountNo' || key === 'thirdPartyPayor' || key === 'billDutiesAccountNo') {
        // if (key === 'thirdPartyPayor' || key === 'billDutiesAccountNo') {
        body[key] = body[key]?.split('-')?.[0];
      }
      if (JSON.stringify(body[key]) === '{}' || JSON.stringify(body[key]) === '[]') {
        delete body[key];
      }
    } else {
      delete body[key];
    }
  }
  if (!(body?.shipmentType as string[])?.includes('BROKER_SELECT_OPTION')) {
    delete body?.broker;
  } else {
    body.broker.contactNo = body?.broker?.telephoneCode + body?.broker?.phoneNumber;
    delete body?.broker.telephoneCode;
    delete body?.broker.phoneNumber;
  }
  //\\ Remove unnecessary properties based on conditions \\//

  if (body.shipmentIdType === 'GSTIN') {
    body.shipmentIdType = '';
  }

  await dispatch(
    postFedExDetails({
      shipmentNumber: `${shipmentNumber}`,
      body,
      date: values?.pickupDate ? dayjs(values?.pickupDate).format() : '',
      nextFunc,
    }),
  );
  setIsLoading(false);
};

export const checkImageType = (imageType: string) => /image*/.test(imageType);

export const ShipmentPurpose: DropdownOptionType[] = [
  { value: 'NOT_SOLD', display: 'NOT SOLD' },
  { value: 'GIFT', display: 'GIFT' },
  { value: 'PERSONAL_EFFECTS', display: 'PERSONAL EFFECTS' },
  { value: 'REPAIR_AND_RETURN', display: 'REPAIR AND RETURN' },
  { value: 'SAMPLE', display: 'SAMPLE' },
  { value: 'SOLD', display: 'SOLD' },
];

export const Payor: DropdownOptionType[] = [
  { value: 'SENDER', display: 'Sender' },
  { value: 'RECIPIENT', display: 'Recipient' },
  { value: 'THIRD_PARTY', display: 'Third Party' },
  // {
  //   value: 'COLLECT',
  //   display: 'Collect',
  // },
];
export const ThirdPartyPayorAccount: DropdownOptionType[] = [
  { value: '267966060', display: 'MH GTI' },
  { value: '495075885', display: 'MH IMPORT' },
  { value: '987394471', display: 'MH LWP' },
  { value: '233728900', display: 'MH MASTER' },
];

export const BillDuties: DropdownOptionType[] = [
  { value: 'SENDER', display: 'Sender' },
  { value: 'RECIPIENT', display: 'Recipient' },
  { value: 'THIRD_PARTY', display: 'Third Party' },
  // {
  //   value: 'COLLECT',
  //   display: 'Collect',
  // },
];

export const PoNo: DropdownOptionType[] = [
  { value: 'B2B', display: 'B2B' },
  { value: 'B2C', display: 'B2C' },
];
export const Packaging_Type: DropdownOptionType[] = [
  { value: 'FEDEX_10KG_BOX', display: 'FEDEX 10KG BOX' },
  { value: 'FEDEX_25KG_BOX', display: 'FEDEX 25KG BOX' },
  { value: 'FEDEX_BOX', display: 'FEDEX BOX' },
  { value: 'FEDEX_ENVELOPE', display: 'FEDEX ENVELOPE' },
  { value: 'FEDEX_EXTRA_LARGE_BOX', display: 'FEDEX EXTRA LARGE BOX' },
  { value: 'FEDEX_LARGE_BOX', display: 'FEDEX LARGE BOX' },
  { value: 'FEDEX_MEDIUM_BOX', display: 'FEDEX MEDIUM BOX' },
  { value: 'FEDEX_PAK', display: 'FEDEX PAK' },
  { value: 'FEDEX_SMALL_BOX', display: 'FEDEX SMALL BOX' },
  { value: 'FEDEX_TUBE', display: 'FEDEX TUBE' },
  { value: 'YOUR_PACKAGING', display: 'YOUR PACKAGING' },
];

export const ServiceType: DropdownOptionType[] = [
  { value: 'PRIORITY_OVERNIGHT', display: 'PRIORITY OVERNIGHT' },
  { value: 'STANDARD_OVERNIGHT', display: 'STANDARD OVERNIGHT' },
  { value: 'FEDEX_INTERNATIONAL_PRIORITY', display: 'FEDEX INTERNATIONAL PRIORITY' },
  { value: 'INTERNATIONAL_ECONOMY', display: 'INTERNATIONAL ECONOMY' },
  { value: 'EUROPE_FIRST_INTERNATIONAL_PRIORITY', display: 'EUROPE FIRST INTERNATIONAL PRIORITY' },
  { value: 'FEDEX_1_DAY_FREIGHT', display: 'FEDEX 1 DAY FREIGHT' },
  { value: 'FEDEX_2_DAY', display: 'FEDEX 2 DAY' },
  { value: 'FEDEX_2_DAY_AM', display: 'FEDEX 2 DAY AM' },
  { value: 'FEDEX_2_DAY_FREIGHT', display: 'FEDEX 2 DAY FREIGHT' },
  { value: 'FEDEX_DISTANCE_DEFERRED', display: 'FEDEX DISTANCE DEFERRED' },
  { value: 'FEDEX_EXPRESS_SAVER', display: 'FEDEX EXPRESS SAVER' },
  { value: 'FEDEX_FIRST_FREIGHT', display: 'FEDEX FIRST FREIGHT' },
  { value: 'FEDEX_FREIGHT_ECONOMY', display: 'FEDEX FREIGHT ECONOMY' },
  { value: 'FEDEX_FREIGHT_PRIORITY', display: 'FEDEX FREIGHT PRIORITY' },
  { value: 'FEDEX_GROUND', display: 'FEDEX GROUND' },
  { value: 'FEDEX_NEXT_DAY_AFTERNOON', display: 'FEDEX NEXT DAY AFTERNOON' },
  { value: 'FEDEX_NEXT_DAY_EARLY_MORNING', display: 'FEDEX NEXT DAY EARLY MORNING' },
  { value: 'FEDEX_NEXT_DAY_END_OF_DAY', display: 'FEDEX NEXT DAY END OF DAY' },
  { value: 'FEDEX_NEXT_DAY_FREIGHT', display: 'FEDEX NEXT DAY FREIGHT' },
  { value: 'FEDEX_NEXT_DAY_MID_MORNING', display: 'FEDEX NEXT DAY MID MORNING' },
  { value: 'FIRST_OVERNIGHT', display: 'FIRST OVERNIGHT' },
  { value: 'GROUND_HOME_DELIVERY', display: 'GROUND HOME DELIVERY' },
  { value: 'INTERNATIONAL_ECONOMY_FREIGHT', display: 'INTERNATIONAL ECONOMY FREIGHT' },
  { value: 'INTERNATIONAL_FIRST', display: 'INTERNATIONAL FIRST' },
  { value: 'INTERNATIONAL_PRIORITY_EXPRESS', display: 'INTERNATIONAL PRIORITY EXPRESS' },
  { value: 'INTERNATIONAL_PRIORITY_FREIGHT', display: 'INTERNATIONAL PRIORITY FREIGHT' },
  { value: 'SAME_DAY', display: 'SAME DAY' },
  { value: 'SAME_DAY_CITY', display: 'SAME DAY CITY' },
  { value: 'SMART_POST', display: 'SMART POST' },
  { value: 'SELECT_SERVICE_TYPE', display: 'SELECT SERVICE TYPE' },
];

export const ShipmentTypeForShipperId: DropdownOptionType[] = [
  { value: 'PERSONAL_SHIPMENTS', display: 'PERSONAL SHIPMENTS' },
  { value: 'EXPORT_SHIPMENTS', display: 'EXPORT SHIPMENTS' },
];

export const ShipperIdForPersonal: DropdownOptionType[] = [
  { value: 'PAN', display: 'PAN Number' },
  { value: 'TAN', display: 'TAN Number' },
  { value: 'PSP', display: 'Passport Number' },
  { value: 'ADH', display: 'Aadhaar Number' },
  { value: 'VID', display: 'Voter ID Number' },
];

export const ShipperIdForExport: DropdownOptionType[] = [
  // { value: '', display: 'GSTIN (Normal)' },
  { value: 'GSTIN', display: 'GSTIN (Normal)' },
  { value: 'GSD', display: 'GSTIN of Diplomats' },
  { value: 'GSG', display: 'GSTIN of Govt. Entities' },
];

export const DangerousGoodsType: DropdownOptionType[] = [
  { value: 'ACCESSIBLE', display: 'Accessible' },
  { value: 'INACCESSIBLE', display: 'InAccessible' },
];

export const ShipmentType: DropdownOptionType[] = [
  { value: 'Commercial', display: 'Commercial' },
  { value: 'DG shipments', display: 'DG shipments' },
  { value: 'BROKER_SELECT_OPTION', display: 'BSO shipments' },
  { value: 'DRY_ICE', display: 'Dry Ice Shipments' },
  { value: 'Lithium Batteries', display: 'Lithium Batteries' },
  { value: 'Others', display: 'Others' },
];
export const PickupType: DropdownOptionType[] = [
  // { value: 'CONTACT_FEDEX_TO_SCHEDULE', display: 'CONTACT_FEDEX_TO_SCHEDULE' },
  { value: 'DROPOFF_AT_FEDEX_LOCATION', display: 'DROP-OFF AT FEDEX LOCATION' },
  { value: 'USE_SCHEDULED_PICKUP', display: 'USE SCHEDULED PICKUP' },
];

export const SpecialType: DropdownOptionType[] = [
  { value: 'SATURDAY_DELIVERY', display: 'SATURDAY DELIVERY' },
  { value: 'SATURDAY_PICKUP', display: 'SATURDAY PICKUP' },
  { value: 'HOLD_AT_LOCATION', display: 'HOLD AT LOCATION' },
];

export const DocumentOptions: DropdownOptionType[] = [
  { value: 'CERTIFICATE_OF_ORIGIN', display: 'Certificate of Origin' },
  { value: 'COMMERCIAL_INVOICE', display: 'Commercial Invoice' },
  { value: 'ETD_LABEL', display: 'ETD Label' },
  { value: 'USMCA_CERTIFICATION_OF_ORIGIN', display: 'USMCA Certification of Origin' },
  {
    value: 'USMCA_COMMERCIAL_INVOICE_CERTIFICATION_OF_ORIGIN',
    display: 'USMCA Commercial Invoice Certification of Origin',
  },
  { value: 'PRO_FORMA_INVOICE', display: 'Pro Forma Invoice' },
  { value: 'OTHER', display: 'Other' },
];

export const ImageOptions: DropdownOptionType[] = [
  { value: 'SIGNATURE', display: 'Signature' },
  { value: 'LETTERHEAD', display: 'Letterhead' },
];

export const b13AFilingOption = [
  { display: 'Not Required', value: 'NOT_REQUIRED' },
  { display: 'Manually Attached', value: 'MANUALLY_ATTACHED' },
  { display: 'Filed Electronically', value: 'FILED_ELECTRONICALLY' },
  { display: 'Summary Reporting', value: 'SUMMARY_REPORTING' },
  // { display: 'FedEx to Stamp', value: 'FEDEX_TO_STAMP' },
];

export const LithiumBatteriesOptions: DropdownOptionType[] = [
  { value: 'Ion Packed', display: 'Ion Packed with Equipment (UN3481,PI966) ' },
  { value: 'Ion Contained', display: 'Ion Contained in Equipment (UN3481,PI967)' },
  { value: 'Metal Packed', display: 'Metal Packed with Equipment (UN3091,P1969)' },
  { value: 'Metal Contained', display: 'Metal Contained in Equipment (UN3091,PI970)' },
];
