// Creditnote - non account
import BackButton from '@Components/BackButton';
import SbButton from '@Components/Button';
import ExportBtn from '@Components/Button/Export';
import DatePicker from '@Components/DatePicker';
import DateRangePicker from '@Components/DateRangePicker';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import Loader from '@Components/Loader';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import SbTextField from '@Components/Textfield';
import Title from '@Components/Title';
import { ShipmentObj } from '@Reducers/Invoice/Type';
import { InvoiceCreditDebitPayload } from '@Reducers/InvoiceList/Type';
import { getNonAccountUnbilledCreditList } from '@Reducers/InvoiceList/actions';
import { getSystemList } from '@Reducers/Systems';
import { sendNonAccountCreditDataTo365 } from '@Reducers/d365';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Checkbox, Grid, TableBody, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues, useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AccountHolderInvoiceTableProps, AccountHolderUnbilledData, FilterObj } from '../Type';
import { calculateTotalAmount } from '../temp';
import API from '@Services/apiAxios';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import formatDate from '../../../utils/formatDate';
import { getShipmentWebsocketStream } from '@Reducers/AdminShipment/actions';

type selectedInvoices = { [x: string]: boolean };

type StepTwoProps = {
  selectedInvoices: selectedInvoices;
  list: AccountHolderUnbilledData[];
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<InvoiceCreditDebitPayload>['setFieldValue'];
  errors?: FormikErrors<InvoiceCreditDebitPayload>;
};

// Define the Invoice schema
const InvoiceSchema = yup.object().shape({
  invoiceNo: yup.string().required('Invoice number is required'),
  shipmentIds: yup.array().of(yup.string()).required('Shipment IDs are required'),
});

// Define the InvoiceData schema
const InvoiceDataSchema = yup.object().shape({
  invoice: yup.array().of(InvoiceSchema).required('Invoice details are required'),
  // dueDate: yup.string().required('Due date is required'),
  invoiceDate: yup.string().required('Invoice date is required'),
  remarks: yup.string(),
});

const initialState: InvoiceCreditDebitPayload = {
  invoice: [],
  dueDate: '',
  invoiceDate: '',
  remarks: '',
};

const SearchFilterList = [
  { label: 'Shipment No.', value: 'shipment_no' },
  { label: 'Invoice Number', value: 'invoice_no' },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 0px',
  display: 'grid',
  gridTemplateColumns: '390px 320px 1fr 140px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: '10px',
    gridTemplateColumns: '390px 320px 1fr 140px',
  },

  [theme.breakpoints.down('lg')]: {
    columnGap: '10px',
    gridTemplateColumns: '320px 1fr',
  },

  [theme.breakpoints.down('md')]: {
    columnGap: '5px',
    gridTemplateColumns: '1fr',
    rowGap: '15px',
  },

  [theme.breakpoints.down('sm')]: {
    padding: '16px 0px',
    gridTemplateColumns: '1fr',
    rowGap: '10px',
  },
}));

interface FilterType {
  currentPage: number;
  perPage: number;
  fromDate: string;
  toDate: string;
  system: string[];
  searchKey?: string;
  searchValue?: string;
}

function InvoiceTable(props: AccountHolderInvoiceTableProps) {
  const { list = [], selectedInvoices, checkAllInvoices, onSelectInvoice } = props;
  return (
    <SBTable>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            <Checkbox
              style={{ color: 'inherit' }}
              checked={Object.keys(selectedInvoices).length > 0 && Object.keys(selectedInvoices).length === list.length}
              onChange={checkAllInvoices}
            />
          </TableHeadCell>
          <TableHeadCell>Invoice No</TableHeadCell>
          <TableHeadCell>Shipment No</TableHeadCell>
          <TableHeadCell>Bill To</TableHeadCell>
          <TableHeadCell>System</TableHeadCell>
          <TableHeadCell>Total Amount</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.length ? (
          list.map((data, i) => (
            <SBTableRow key={i} className={selectedInvoices[i] ? 'selected' : ''}>
              <TableRowCell align="center">
                <div>
                  <Checkbox
                    style={{ color: 'inherit' }}
                    onChange={e => onSelectInvoice(i, e.target.checked)}
                    checked={!!selectedInvoices[i]}
                  />
                </div>
              </TableRowCell>
              <TableRowCell align="center" className="md-text uppercase">
                {data.invoiceNumber}
              </TableRowCell>
              <TableRowCell
                sx={{
                  div: {
                    textAlign: 'center',
                  },
                }}
                align="center"
                className="md-text uppercase"
              >
                {data.shipments?.[0]?.shipmentNumber}
              </TableRowCell>
              <TableRowCell align="center" className="md-text capitalize">
                <p>{data.user?.name || '-'}</p>
                <p className="text-[#AFAFAF]">{data.user?.email}</p>
              </TableRowCell>
              <TableRowCell align="center" className="md-text">
                {data?.system?.name}
              </TableRowCell>
              <TableRowCell
                sx={{
                  div: {
                    textAlign: 'center',
                  },
                }}
                align="center"
                className="md-text"
              >
                {calculateTotalAmount(data?.shipments?.[0], 'credit')}
              </TableRowCell>
            </SBTableRow>
          ))
        ) : (
          <SBTableRow>
            <TableRowCell colSpan={6}>
              <Typography className="sb-text-gray font-medium text-center">No Data Found.</Typography>
            </TableRowCell>
          </SBTableRow>
        )}
      </TableBody>
    </SBTable>
  );
}

export default function UnbilledList() {
  // console.log('Non-Account Unbilled Create credit');
  const [filterSelectValue, setFilterSelectValue] = useState('shipment_no');
  const [filterText, setFilterText] = useState('');
  const [step, setStep] = useState(1);
  const [system, setSystem] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [selectedInvoices, setSelectedInvoices] = useState<selectedInvoices>({});
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const SystemList = useSelector((state: RootStateType) => state.System.data.selectedList).map(system => ({
    display: system.name,
    value: system._id,
  }));

  const _systems = useSelector((state: RootStateType) => state.loginUser?.profile?._systems);
  const activeSystemList = useMemo(() => {
    if (_systems?.length) return SystemList.filter(system => _systems.includes(system.value));
    else return [];
  }, [SystemList, _systems]);
  const {
    unBilledList: { list, currentPage = 1, perPage, totalCount },
  } = useSelector((state: RootStateType) => state.InvoiceList);

  useEffect(() => {
    const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
    const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
    dispatch(getNonAccountUnbilledCreditList({ currentPage: 1, perPage: 10, fromDate, toDate }));
    dispatch(getSystemList());
  }, []);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(getNonAccountUnbilledCreditList(getFilterObj({ dateRange })));
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    dispatch(getNonAccountUnbilledCreditList(getFilterObj({ system: selected })));
  };

  const getFilterObj = (options: FilterObj) => {
    setSelectedInvoices({});
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterSystem = options.system || system;
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filter: FilterType = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      system: filterSystem,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    // Remove searchKey and searchValue if searchValue is falsy
    if (!filter.searchValue) {
      delete filter.searchKey;
      delete filter.searchValue;
    }
    return filter;
  };

  const onSelectInvoice = function (invoiceId: number, isChecked: boolean) {
    const newSelectedInvoice = { ...selectedInvoices };
    if (isChecked) newSelectedInvoice[invoiceId] = true;
    else delete newSelectedInvoice[invoiceId];
    setSelectedInvoices(newSelectedInvoice);
  };

  const checkAllInvoices = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newInvoiceCheckedList = list.reduce((op: { [k: string]: boolean }, _, index) => {
        op[index] = true;
        return op;
      }, {});
      setSelectedInvoices(newInvoiceCheckedList);
    } else {
      setSelectedInvoices({});
    }
  };
  // const setSelectedUnbilledShipment = (_params: selectedParamType) => {
  //   // console.log('here ', _params);
  //   const { accountNumber, _systemId, shipments } = _params;
  //   let newObj = [...selectedAccountRows];
  //   newObj = newObj.filter(item => item.accountNumber !== accountNumber || item._systemId !== _systemId);
  //   if (shipments?.length) {
  //     newObj.push(_params);
  //   }
  //   setSelectedAccountRows(newObj);
  // };

  // const isCreateDisabled = () => {
  //   if (!Object.values(selectedInvoices)?.length) return true;
  //   return false;
  // };

  const nextStep = () => {
    if (step === 2) return;
    setStep(step + 1);
  };
  const preStep = () => {
    if (step <= 0) return;
    setStep(step - 1);
  };
  const isPrevDisabled = () => {
    if (step <= 1) return true;
    return false;
  };

  const { handleSubmit, ...formik } = useFormik<InvoiceCreditDebitPayload>({
    initialValues: {
      ...initialState,
      invoice: Object.keys(selectedInvoices)
        .map((_, i) => list[i])
        ?.map((row: AccountHolderUnbilledData) => ({
          invoiceNo: row?.invoiceNumber || 0,
          shipmentIds: row?.shipments?.map((item: ShipmentObj) => item?._id),
          shipmentNumbers: row?.shipments.map(s => s.shipmentNumber),
        })),
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      const payload = {
        ...values,
        dueDate: values?.invoiceDate && formatDate(values?.invoiceDate),
        invoiceDate: values?.invoiceDate && formatDate(values?.invoiceDate),
        comment: values?.remarks,
      };
      API.post('/invoice/creditnote', { ...payload })
        .then(res => {
          // console.info(res.data.msg);
          // Dispatch success modal
          dispatch(
            SuccessErrorModalOpen({
              type: 'success',
              title: 'Success',
              subTitle: res.data.msg,
            }),
          );
          // Navigate to the invoice view
          navigate('/invoice/view');
        })
        .catch(error => {
          // Dispatch error modal
          dispatch(
            SuccessErrorModalOpen({
              type: 'error',
              title: 'Oops!',
              subTitle: 'Something went wrong.',
            }),
          );
          // Throw the error to handle it further if needed
          throw error;
        });
      // dispatch(createInvoiceCreditDebitNote(payload)).then(res => {
      //   if (res.type === 'createInvoiceCreditDebitNote/fulfilled') {
      //     navigate('/invoice/view');
      //   }
      // });
      const accountData = (values?.invoice as any).map((item: any) => ({
        _shipmentNumbers: item.shipmentNumbers,
      }));
      const dataForStream = accountData.map((el: any) => el._shipmentNumbers).flat();
      // console.info('accountData:', dataForStream);
      dispatch(getShipmentWebsocketStream(['shipment-update-' + dataForStream]));
    },
    validationSchema: InvoiceDataSchema,
  });

  return (
    <>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <BackButton link="/invoice/view?tab=non-account" />
        <Title title="Invoices" subTitle="View and create invoices" />
      </Box>
      <div className="flex" style={{ marginTop: '52px', marginBottom: '25px' }}>
        <Title title="Create Credit Note" titleClass="underline px_20-text" removeBorder />
        {step === 1 && (
          <ExportBtn
            onClick={() => {
              console.log('Export Unbilled Credit Clicked');
            }}
            iconSrc="/images/file_download.svg"
            alt="Export"
            label="Export"
          />
        )}
      </div>
      {step === 1 && (
        <>
          <TableActionContainer>
            <FilterSearch
              onSelectChange={(value: string) => {
                setFilterSelectValue(value);
              }}
              list={SearchFilterList}
              selectValue={filterSelectValue}
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={() => {
                dispatch(getNonAccountUnbilledCreditList(getFilterObj({})));
              }}
              onTextClear={() => {
                setFilterText('');
                dispatch(getNonAccountUnbilledCreditList(getFilterObj({ searchValue: undefined })));
              }}
            />
            <DateRangePicker onChange={onDateChange} value={dateRange} />
            <div></div>
            <FilterSelect
              id="system"
              label="System"
              value={system}
              options={activeSystemList}
              onChange={onSystemFilter}
            />
          </TableActionContainer>
          <div
            style={{
              display: 'block',
              width: '100%',
              overflowX: 'auto',
              overflowY: 'hidden',
              maxWidth: '100%',
            }}
          >
            <div
              style={{
                minWidth: '600px',
              }}
            >
              <InvoiceTable
                checkAllInvoices={checkAllInvoices}
                onSelectInvoice={onSelectInvoice}
                selectedInvoices={selectedInvoices}
                list={list}
              />
            </div>
            {totalCount ? (
              <>
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={currentPage - 1}
                  rowsPerPage={perPage}
                  onPageChange={(_, page) => {
                    dispatch(getNonAccountUnbilledCreditList(getFilterObj({ currentPage: page + 1 })));
                  }}
                  onRowsPerPageChange={event => {
                    dispatch(getNonAccountUnbilledCreditList(getFilterObj({ perPage: parseInt(event.target.value) })));
                  }}
                />
              </>
            ) : (
              ''
            )}
          </div>
        </>
      )}

      {step === 2 && <StepTwo list={list} selectedInvoices={selectedInvoices} {...formik} />}

      <Grid container item mt={2} justifyContent="flex-end" spacing={4}>
        <Grid item xs={12} sm={3} md={2}>
          <SbButton variant="outlined" fullWidth className="rounded-full" onClick={preStep} disabled={isPrevDisabled()}>
            Back
          </SbButton>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <SbButton
            variant="contained"
            fullWidth
            className="rounded-full"
            onClick={
              step === 2
                ? () => {
                    handleSubmit();
                  }
                : nextStep
            }
            // disabled={isCreateDisabled() || step === 2}
          >
            {step === 2 ? 'Create' : 'Next'}
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

function StepTwo(props: StepTwoProps) {
  const { list, selectedInvoices, handleChange, errors, values, setFieldValue } = props;
  const selectedList = Object.keys(selectedInvoices).map(i => list[Number(i)]);
  const dispatch = useDispatch<AppDispatch>();
  const [isWyngsMy, setIsWyngsMy] = useState<boolean>(false);

  // const { invoiceSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  // const { sacCode = [], taxInformation = [] } = invoiceSetting || {};
  // const sacCodeOptions = sacCode.map(s => ({ display: s.display, value: s.key }));
  // const taxInformationOptions = taxInformation.map(s => ({ display: s.display, value: s.key }));
  // console.log({ sacCode, sacCodeOptions });

  // useEffect(() => {
  //   dispatch(getInvoiceSettings());
  // }, []);

  const sendTo365 = () => {
    const shipmentIds = selectedList.flatMap(s => s.shipments.map(ship => ship._id));
    const shipmentNumbers = selectedList.flatMap(s => s.shipments.map(ship => ship.shipmentNumber));
    const payload = {
      shipmentIds: shipmentIds,
      shipmentNumbers,
      // dueDate: values.invoiceDate,
      // invoiceDate: values.invoiceDate,
      dueDate: dayjs(values.invoiceDate).format('YYYY-MM-DD'),
      invoiceDate: dayjs(values.invoiceDate).format('YYYY-MM-DD'),
      comment: values?.remarks,
    };
    dispatch(sendNonAccountCreditDataTo365(payload));
  };
  const { loading: loadingD365 } = useSelector((state: RootStateType) => state?.D365);
  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    // setOrganisationNameRedirect(subdomain);
    // console.info('subdomain:', subdomain);
    if (subdomain === 'wyngs-my') {
      setIsWyngsMy(true);
    } else {
      setIsWyngsMy(false);
    }
  }, []);
  return (
    <>
      <Loader loading={loadingD365} />
      <Grid container gap={2}>
        <Grid item md={5} mt={'3.8rem'}>
          <Grid container gap={3}>
            <Grid item xs={11}>
              <DatePicker
                // placeholder="01/01/2023"
                label="Invoice Date"
                // name="invoiceDate"
                value={values.invoiceDate}
                handleChange={(date: Dayjs) => setFieldValue('invoiceDate', date)}
                // minDate={dayjs()}
                required
                error={errors?.invoiceDate}
              />
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item md={11}>
              <SbTextField
                // placeholder="01/01/2023"
                label="Remark"
                name="remarks"
                multiline
                rows={4}
                value={values.remarks}
                onChange={handleChange}
                error={errors?.remarks}
              />
            </Grid>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
              {!isWyngsMy && (
                <SbButton
                  className="rounded-full"
                  variant="contained"
                  sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                  onClick={() => {
                    sendTo365();
                  }}
                  disabled={!values.invoiceDate}
                >
                  Send to D365
                </SbButton>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item md={6.8}>
          <Grid container justifyContent={'space-between'} alignItems={'center'} my={'1rem'}>
            <Grid item xs="auto">
              <Box pb={'0.5rem'} borderBottom={'4px solid #080808'} width={'170px'}>
                <Title removePadding removeBorder title="Preview Credit Notes" titleClass=" px_16-text" />
              </Box>
            </Grid>
            <Grid item xs="auto" justifyContent={'flex-end'} container>
              <Title
                removePadding
                removeBorder
                title={'No. of Credit Notes : ' + selectedList?.length}
                titleClass=" px_16-text"
              />
            </Grid>
          </Grid>
          <SBTable>
            <TableHead>
              <TableRow>
                <TableHeadCell>Sr. No.</TableHeadCell>
                <TableHeadCell>Invoice No.</TableHeadCell>
                <TableHeadCell>System</TableHeadCell>
                <TableHeadCell>Bill To</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedList.map((data, index) => (
                <SBTableRow key={index}>
                  <TableRowCell align="center">{index + 1}</TableRowCell>
                  <TableRowCell align="center" className="md-text uppercase">
                    {data.invoiceNumber}
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text capitalize">
                    <p>{data.system?.name}</p>
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text capitalize">
                    <p className="md-text font-medium">{data.user?.name || '-'}</p>
                    <p className="text-[#AFAFAF] md-text">{data.user?.email}</p>
                  </TableRowCell>
                </SBTableRow>
              ))}
            </TableBody>
          </SBTable>
        </Grid>
      </Grid>
    </>
  );
}
