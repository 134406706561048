import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, MenuItem, Select } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { PropsType } from './Type';
import SbTextField from '../Textfield';
import { DropdownOptionType } from '../Dropdown/Type';
import { RootStateType } from '../../store';
import { getFormattedContact } from '../../services/getFormattedContact';

const SelectOptions = styled(Select)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  height: '44px',
  // width: '90px',
  width: 'fit-content',
  textAlign: 'center',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  '& > .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

function Contact(props: PropsType) {
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const { countryCodeList } = useSelector((state: RootStateType) => state.global);

  const { phoneName, phoneNumber, onChangePhoneNumber, label, error, disabled = false } = props;
  const { telCountryCodeName, onChangeTelCountryCode, telCountryCodeDisable = false, setDefaultTelephone } = props;

  useEffect(() => {
    if (!props.telCountryCode && setDefaultTelephone && organizationCountry?.telephoneCode) {
      setDefaultTelephone(Number(organizationCountry?.telephoneCode));
    }
  }, [organizationCountry?.telephoneCode]);

  const defaultTelephoneCode = organizationCountry?.telephoneCode || '';
  const telCountryCode = props.telCountryCode || defaultTelephoneCode;

  return (
    <SbTextField
      type="integer"
      label={label || ''}
      name={phoneName || 'phone_number'}
      value={phoneNumber}
      onChange={onChangePhoneNumber}
      style={{ paddingLeft: 0 }}
      sx={{ paddingLeft: 0 }}
      error={error}
      disabled={disabled}
      placeholder="Enter Contact Number"
      startAdornment={
        <FormControl sx={{ mr: '24px' }}>
          <SelectOptions
            className="font-semibold sm-text"
            renderValue={p => <>{getFormattedContact(`${p}`)}</>}
            name={telCountryCodeName}
            value={telCountryCode ? `${telCountryCode}` : ''}
            onChange={onChangeTelCountryCode}
            defaultValue={`${defaultTelephoneCode}`}
            disabled={disabled || telCountryCodeDisable}
          >
            <MenuItem disabled value="">
              Select
            </MenuItem>
            {countryCodeList?.map(({ display, value }: DropdownOptionType, index: number) => (
              <MenuItem key={`telephone-Code-${value}-${index}`} value={value}>
                {display}
              </MenuItem>
            ))}
          </SelectOptions>
        </FormControl>
      }
    />
  );
}

export default memo(Contact);
