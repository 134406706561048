import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { Box } from '@mui/material';

Chart.register(...registerables);

interface MonthlyData {
  month: string;
  year: string;
  totalWeight: number;
  totalShipment: number;
  exportShipments: number;
  importShipments: number;
}

interface ShipmentsChartProps {
  data: MonthlyData[];
}

const ShipmentsChart: React.FC<ShipmentsChartProps> = ({ data }) => {
  // Extracting the labels for the x-axis
  const labels = data?.map(item => `${item.month} ${item.year}`);

  // Extracting the total shipment data
  const shipmentData = data?.map(item => item.totalShipment);

  // Defining the bar chart data
  const barChartData = {
    labels,
    datasets: [
      {
        label: 'Total Shipments',
        data: shipmentData,
        backgroundColor: 'rgba(217, 217, 217, 1)',
      },
    ],
  };

  // Configuring the chart options
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'No. of Shipments', // Label for the y-axis
        },
      },
      x: {
        title: {
          display: true,
          text: 'Months', // Label for the x-axis
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Show legend to identify the dataset
      },
    },
  };

  return (
    <Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', gap: '100px', alignItems: 'center', marginBottom: '10px' }}
      >
        <p style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <span
            style={{
              borderRadius: '50%',
              backgroundColor: 'rgba(217, 217, 217, 1)',
              display: 'inline-block',
              width: '15px',
              height: '15px',
              textAlign: 'center',
            }}
          ></span>
          <span>Shipment</span>
        </p>
      </Box>
      <Bar data={barChartData} options={options} />
    </Box>
  );
};

export default ShipmentsChart;
