import { RatesQuery } from '@Reducers/RateSummary/Type';
import API from '@Services/apiAxios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface queryParams extends Omit<RatesQuery, 'pageType'> {
  system: string[];
  csp: string[];
  rateType: string[];
  pageType: string | null;
}

export const exportRates = createAsyncThunk('ShipmentExport', async (params: RatesQuery) => {
  const queryParams: queryParams = Object.assign({}, params);
  if (params.pageType === 'base' || params.pageType === 'tariff') {
    if (params.pageType === 'tariff') queryParams.pageType = 'tariff';
    else queryParams.pageType = 'base'; // backend manages accountNumber based on page type
    params.accountNumber = 'default';
  } else queryParams.pageType = 'custom';

  const res = await API.get(`shipment/export/ratelist/${params.accountNumber}?`, { params: queryParams });
  // window.open(res.data.data, '_blank');
  return res.data.data;
});

const ratesSlice = createSlice({
  name: 'rates',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },
  reducers: {
    resetBasePriceState: state => {
      state.isLoading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(exportRates.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(exportRates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload; // Handle success response if needed
      })
      .addCase(exportRates.rejected, state => {
        state.isLoading = false;
      });
  },
});

export const { resetBasePriceState } = ratesSlice.actions; // Export the resetState action
export default ratesSlice.reducer;
