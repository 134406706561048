import FilterSearch from '@Components/Filter/Search';
import Image from '@Components/Image';
import Title from '@Components/Title';
import { AddressType, SavedAddressListQuery } from '@Reducers/SavedAddress/Type';
import { deleteAddress, getAllSavedAddress } from '@Reducers/SavedAddress/actions';
import { AppDispatch, RootStateType } from '@Store';
import { AddRounded } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Divider, IconButton, Paper, Stack, Tab, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { defaultUserState } from '../CreateShipment/constants';
import Loader from '@Components/Loader';
import { FilterObj } from './Types';

const SearchFilterList = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Label', value: 'label' },
  { label: 'Pincode', value: 'pincode' },
];

function ShipmentAddress() {
  const { state } = useLocation();
  const [tabValue, setTabValue] = useState<string>('shipperAddress');
  const [filterSelectValue, setFilterSelectValue] = useState('email');
  const [filterText, setFilterText] = useState('');

  const { shipperAddresses, consigneeAddresses, loading } = useSelector((state: RootStateType) => state?.savedAddress);
  const dispatch = useDispatch<AppDispatch>();

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    setTabValue(currentTab);
  };

  useEffect(() => {
    dispatch(getAllSavedAddress());
  }, []);

  useEffect(() => {
    if (state) {
      setTabValue(state === 'Shipper' ? 'shipperAddress' : 'consigneeAddress');
    }
  }, [state]);

  const getFilterObj = (options: FilterObj): SavedAddressListQuery => {
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    // if (
    //   filterSearchKey.toLowerCase() !== prevSearchObj.searchKey.toLowerCase() ||
    //   filterSearchValue?.toLowerCase() !== prevSearchObj.searchValue.toLowerCase()
    // ) {
    //   options.currentPage = 0;
    //   setPrevSearchObj({
    //     searchKey: filterSearchKey,
    //     searchValue: filterSearchValue || '',
    //   });
    // }
    const filterQuery: SavedAddressListQuery = {
      // currentPage: options.currentPage || currentPage,
      // perPage: options.perPage || perPage,

      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    return filterQuery;
  };

  return (
    <Loader loading={loading} overly>
      <Box
        sx={{
          p: { xs: '0rem', sm: '1rem' },
          mt: { xs: '20px', sm: '0px' },
        }}
      >
        <Title title="Saved Address" removeBorder removePadding />
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          mb={'1rem'}
          sx={{
            mt: { xs: '20px', sm: '0px' },
          }}
        >
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={SearchFilterList}
            // inputClassName="rounded"
            inputSx={{
              width: '18rem',
              bgcolor: '#F7F6FF',
            }}
            placeholder="Search Saved Address"
            selectValue={filterSelectValue}
            textValue={filterText}
            onTextChange={e => {
              setFilterText(e.target.value);
            }}
            onTextSearch={() => {
              dispatch(getAllSavedAddress(getFilterObj({})));
            }}
            onTextClear={() => {
              setFilterText('');
              dispatch(getAllSavedAddress(getFilterObj({ searchValue: undefined })));
            }}
          />
        </Stack>
        <TabContext value={tabValue}>
          <TabList
            onChange={onTabChange}
            indicatorColor="primary"
            TabIndicatorProps={{ sx: { height: '4px' } }}
            scrollButtons="auto"
            variant="scrollable"
          >
            {TABS.map(cTab => (
              <Tab
                sx={{ textTransform: 'none', padding: '16px 16px' }}
                className="md-text font-medium sb-text-black"
                value={cTab.value}
                label={cTab.label}
                key={cTab.value}
              />
            ))}
          </TabList>
          <Divider className="mt-3 mb-3" />

          <TabPanel value="shipperAddress" className="p-0 w-full">
            <Stack direction={'row'} gap={'2rem'} flexWrap={'wrap'}>
              <AddAddress addressType="shipperAddress" />
              {shipperAddresses?.map(address => (
                <AddressCard key={address._id} address={address} addressType="shipperAddress" />
              ))}
            </Stack>
          </TabPanel>
          <TabPanel value="consigneeAddress" className="p-0 w-full">
            <Stack direction={'row'} gap={'2rem'} flexWrap={'wrap'}>
              <AddAddress addressType="consigneeAddress" />
              {consigneeAddresses?.map(address => (
                <AddressCard key={address._id} address={address} addressType="consigneeAddress" />
              ))}
            </Stack>
          </TabPanel>
        </TabContext>
      </Box>
    </Loader>
  );
}

export default ShipmentAddress;

const AddressCard: React.FC<AddressCardProps> = ({ address, addressType }) => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Paper
      variant="outlined"
      sx={{
        width: '17rem',
        height: '15rem',
        borderRadius: '10px',
        p: '1rem',
        position: 'relative',
      }}
    >
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography component={'p'} className="md-text font-medium">
          {address.label}
        </Typography>
        <Link
          to={'/shipment/create-shipment-address'}
          state={{
            address: {
              ...address,
              telephoneCode: address?.phone?.telephoneCode,
              phoneNumber: address?.phone?.number,
              altContactNo: address?.alternatePhone?.number,
              altContactTelephoneCode: address?.alternatePhone?.telephoneCode,
              altContactName: address?.alternatePhone?.name,
            },
            formType: addressType === 'shipperAddress' ? 'Shipper' : 'Consignee',
          }}
          style={{ textDecoration: 'none' }}
        >
          <IconButton
            onClick={() => {
              console.log('');
            }}
            sx={{ fontSize: 24 }}
            className="sb-text-black "
          >
            <Image src="/images/icons/EditSquare.svg" alt="Edit" />
          </IconButton>
        </Link>
      </Stack>

      <Stack gap={'0.5rem'} my="1rem">
        <Typography component="p" className=" sm-text font-normal">
          {address?.name},
        </Typography>
        <Typography component="p" className=" sm-text font-normal truncate">
          {`${address.line1} ${address.line2}`}
        </Typography>
        <Typography component="p" className=" sm-text font-normal">
          {`${address.city}, ${address.state}-${address.pincode}, ${address.country}`}
        </Typography>
      </Stack>

      <Stack direction={'row'} justifyContent={'flex-end'} position={'absolute'} bottom={'1rem'} right={'1rem'}>
        <IconButton
          onClick={() => {
            if (address._id) {
              dispatch(
                deleteAddress({
                  addressId: address._id,
                }),
              );
            }
          }}
          sx={{ fontSize: 24 }}
          className="sb-text-black "
        >
          <Image src="/images/icons/DeleteAction.svg" alt="Delete" />
        </IconButton>
      </Stack>
    </Paper>
  );
};

const AddAddress: React.FC<AddAddressProps> = ({ addressType }) => {
  return (
    <Link
      to={'/shipment/create-shipment-address'}
      style={{ textDecoration: 'none' }}
      state={{
        address: defaultUserState,
        formType: addressType === 'shipperAddress' ? 'Shipper' : 'Consignee',
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          width: '17rem',
          height: '15rem',
          borderRadius: '10px',
          display: 'grid',
          placeItems: 'center',
          // cursor: 'pointer',
        }}
      >
        <Box textAlign={'center'}>
          <AddRounded className="l-text" />
          <Typography component={'p'} className="md-text font-medium">
            Add new address
          </Typography>
        </Box>
      </Paper>
    </Link>
  );
};

const TABS: { label: string; value: string }[] = [
  {
    value: 'shipperAddress',
    label: 'Shipper Address',
  },
  {
    value: 'consigneeAddress',
    label: 'Consignee Address',
  },
];

type TabValues = 'shipperAddress' | 'consigneeAddress' | string;

interface AddressCardProps {
  address: AddressType;
  addressType: TabValues;
}

interface AddAddressProps {
  addressType: TabValues;
}
