import API from '@Services/apiAxios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

// Define the type for your API response
interface Carrier {
  _id: string; // ID as a string
  name: string; // Carrier name
  code: string; // Carrier code
}

// Define the initial state interface
interface CarrierState {
  carriers: Carrier[];
  loading: boolean;
  error: string | null;
}

// Async thunk to fetch data
export const fetchCarrierData = createAsyncThunk<Carrier[]>('carrier/fetchCarrierData', async () => {
  const response = await API.get('/organization/carrier/integrated');
  return response?.data?.data;
});

const initialState: CarrierState = {
  carriers: [],
  loading: false,
  error: null,
};

const carrierSlice = createSlice({
  name: 'carrierIntegration',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCarrierData.pending, state => {
        state.loading = true;
      })
      .addCase(fetchCarrierData.fulfilled, (state, action: PayloadAction<Carrier[]>) => {
        state.carriers = action.payload;
        state.loading = false;
      })
      .addCase(fetchCarrierData.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch data';
        state.loading = false;
      });
  },
});

export default carrierSlice.reducer;
