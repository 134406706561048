import React, { useState } from 'react';

import { Grid, Typography, useTheme, FormHelperText } from '@mui/material';
import { styled, alpha } from '@mui/system';
import Divider from '../../../components/Divider';
import SbTextField from '../../../components/Textfield';
import Contact from '../../../components/Contact';
import SbButton from '../../../components/Button';

// ICONS
import InfoIcon from '@mui/icons-material/Info';
import DragDrop from '../../../components/File/DragDrop';
import { SystemType, PincodeType } from './type';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues } from 'formik';

import SbTooltip from '../../../components/Tooltip';
import { FileResultType } from '../../../Helper/file';
import Image from '../../../components/Image';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';
import { ZonesEntity } from '@Reducers/Systems/Type';

interface CreateSysFormProps {
  showBackgroundColor: boolean;
  formType?: 'create' | 'edit';
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<SystemType>['setFieldValue'];
  errors?: FormikErrors<SystemType>;
  setZonesPricing?: React.Dispatch<React.SetStateAction<ZonesEntity[]>>;
}

interface PincodeCheckType {
  [index: string]: boolean;
}

export const initCreateSystem: SystemType = {
  name: '',
  email: '',
  supportEmail: '',
  countryCode: '',
  number: '',
  pincodes: [],
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
};

function CreateSysForm(props: CreateSysFormProps) {
  const { showBackgroundColor, formType, values, errors, handleChange, setFieldValue, setZonesPricing } = props;
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);

  const theme = useTheme();

  const [fileError, setFileError] = useState<string>('');

  const onFileChange = (data: FileResultType[]) => {
    if (fileError) setFileError('');

    if (!data.length) return setFileError('Please upload pincode list.');
    const [{ fileData }] = data;
    if (!(fileData instanceof Array)) return;

    const {
      data: [headers, ...body],
    } = fileData[0] as { data: string[][] };
    const pincodeIndex = headers.findIndex(x => x.match(/pincode/i));
    const zoneIndex = headers.findIndex(x => x.match(/zone/i));
    const pickupIndex = headers.findIndex(x => x.match(/pickup serviceability/i));

    if (pincodeIndex === -1) return setFileError('Pincode columns not found in xlsx');
    else if (!body.length) return setFileError('At least one pincode required');

    const pincodeCheck: PincodeCheckType = {};
    const pincodes = [];
    let hasDuplicates = false;

    for (let ind = 0; ind < body.length; ind++) {
      const arr = body[ind];
      if (pincodeCheck[arr[pincodeIndex]]) {
        hasDuplicates = true;
        break;
      }
      if (arr[pincodeIndex] === undefined || arr[pincodeIndex] === null) {
        setFileError(`Pincode is missing in row ${ind + 2}`);
        break;
      }
      pincodeCheck[arr[pincodeIndex]] = true;
      pincodes.push({
        pincode: arr[pincodeIndex],
        zoneName: arr[zoneIndex],
        isPickupServiceable: !!new RegExp(/yes|Y/, 'i').test(arr[pickupIndex]),
      });
    }

    if (hasDuplicates) {
      setFileError('uploaded file has duplicate pincodes');
      return;
    }

    setFieldValue('pincodes', pincodes);

    // -----------------> setting the zones Pricing <--------------------
    const sheet2Data = fileData[1];

    if (!sheet2Data) {
      console.error('Sheet2 data not found');
      return;
    }

    const zones: { name: string; priceList: { weight: number; price: number }[] }[] = [];
    const { data: sheetData } = sheet2Data;

    if (!sheetData || sheetData.length === 0) {
      console.error('No data found in Sheet2');
      return;
    }

    const zoneNames = sheetData[0].slice(1).map((zoneName: string) => zoneName.toString());

    const weightNames = sheetData.slice(1).map((row: (string | number)[]) => row[0].toString());

    zoneNames.forEach((zoneName: string, colIndex: number) => {
      const priceList: { weight: number; price: number }[] = [];

      weightNames.forEach((weight: string, rowIndex: number) => {
        const price = Number(sheetData[rowIndex + 1][colIndex + 1]) || 0;
        if (price !== 0) {
          priceList.push({ weight: Number(weight.replace('kg', '')), price });
        }
      });

      if (priceList.length > 0) {
        zones.push({ name: zoneName, priceList });
      }
    });
    const pincodeZoneNames = Array.from(new Set(pincodes.map(pincode => pincode.zoneName)));
    const zoneMap = new Map(zones.map(zone => [zone.name, zone]));

    pincodeZoneNames.forEach(zoneName => {
      if (!zoneMap.has(zoneName)) {
        const newZone = { name: zoneName, priceList: [] };
        zones.push(newZone);
        zoneMap.set(zoneName, newZone);
      } else {
        const existingZone = zoneMap.get(zoneName);
        if (existingZone) {
          existingZone.priceList = existingZone.priceList || [];
        }
      }
    });

    const filteredZones = zones.filter(zone => zone.name.trim() !== '' || zone.priceList.length > 0);

    if (setZonesPricing) {
      setZonesPricing(filteredZones);
    }
  };

  const onDownloadSampleSheet = () => {
    window.open(
      'https://s3.ap-south-1.amazonaws.com/speedbox-static-file-2.0/Sample%2BSystems%2Bpincode.xlsx',
      '_blank',
    );
  };

  return (
    <>
      <Grid
        container
        className="p-4"
        style={{
          borderRadius: '12px',
          backgroundColor: showBackgroundColor ? alpha(theme.palette.primary.light, 0.4) : '',
        }}
      >
        <Grid item xs={12}>
          <Typography className="org-text underline">
            {(formType === 'edit' && 'Edit') || (formType === 'create' && 'Create')} System
          </Typography>
          {formType === 'create' && (
            <Typography className="md-text flex items-center">
              System is a set of unique serviceable pincodes{' '}
              <SbTooltip title="Please create one system to start setting up your account. You will be able to create more systems at later stage.">
                <InfoIcon className="ml-1" sx={{ width: '20px', height: '20px' }} />
              </SbTooltip>
            </Typography>
          )}
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">System Name *</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <FormContent>
              <SbTextField
                required
                placeholder="Enter System Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={errors?.name}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">System Contact Email *</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <FormContent>
              <SbTextField
                name="email"
                placeholder="Enter System Contact Email"
                value={values.email}
                onChange={handleChange}
                error={errors?.email}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">System Support Email *</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <FormContent>
              <SbTextField
                name="supportEmail"
                placeholder="Enter System Support Email"
                value={values.supportEmail}
                onChange={handleChange}
                error={errors?.supportEmail}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">System Contact *</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <FormContent>
              <Contact
                phoneName="number"
                phoneNumber={values.number}
                onChangePhoneNumber={handleChange}
                telCountryCodeName="countryCode"
                telCountryCode={values.countryCode}
                telCountryCodeDisable
                onChangeTelCountryCode={handleChange}
                setDefaultTelephone={(value: number) => setFieldValue('countryCode', value)}
                error={errors?.number || errors?.countryCode}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
        <Grid item container xs={12} alignItems="baseline" spacing={2}>
          <Grid item xs={12} md={2.5} lg={2}>
            <FormLabel className="md-text font-medium">Address</FormLabel>
          </Grid>
          <Grid container item xs={12} md={9.5} lg={10} columnSpacing={4} rowSpacing={2}>
            {/* <Grid container item mb={2}>
              <Grid item xs={12} md={6}>
                <SbTextField label="Enter Location" required />
              </Grid>
            </Grid> */}
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="Address Line 1"
                  placeholder="Enter Address Line 1"
                  name="addressLine1"
                  value={values.addressLine1}
                  error={errors?.addressLine1}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SbTextField
                  label="Address Line 2"
                  placeholder="Enter Address Line 2"
                  name="addressLine2"
                  value={values.addressLine2}
                  error={errors?.addressLine2}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="Pincode"
                  placeholder="Enter Pincode"
                  name="pincode"
                  value={values.pincode}
                  error={errors?.pincode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="City"
                  placeholder="Enter City"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  error={errors?.city}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="State"
                  placeholder="Enter State"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  error={errors?.state}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SbTextField label="Country" placeholder="Enter Country" value={organizationCountry?.name} disabled />
              </Grid>
            </Grid>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">Add Pincodes *</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <FormContent className="w-full">
              <DragDrop
                readType="xls"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                subText={
                  values?.pincodes?.length
                    ? `${values.pincodes.length} Pincodes uploaded`
                    : 'only xls and xlsx file allowed'
                }
                onChange={onFileChange}
              />
              {(fileError || errors?.pincodes) && (
                <FormHelperText error className="mt-1">
                  {fileError || Array.isArray(errors?.pincodes)
                    ? fileError || (errors?.pincodes as PincodeType[])?.map?.(pincode => pincode.pincode).join(', ')
                    : errors?.pincodes}
                </FormHelperText>
              )}

              <SbButton
                variant="text"
                className="mt-1"
                onClick={onDownloadSampleSheet}
                startIcon={
                  <Image
                    src={'/images/upload_document.svg'}
                    alt="Download"
                    width={35}
                    height={35}
                    style={{ transform: 'rotate(180deg)' }}
                  />
                }
              >
                Download Sample File
              </SbButton>
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
      </Grid>
    </>
  );
}

const FormLabel = styled(Typography)`
  min-width: 280px;
`;

const FormContent = styled('div')`
  max-width: 512px;
`;

export default CreateSysForm;
