import * as Yup from 'yup';

export const aramexInformationSchema = Yup.object().shape({
  type: Yup.string().trim().required('Type is required'),
  accountNo: Yup.string().required('Aramex Account is required'),
  countryOfManufacture: Yup.string().trim().required('Country of manufacture is required'),
  shipperTaxId: Yup.string().trim().required('Shipper Tax ID is required'),
  invoiceDate: Yup.date().required('Invoice Date is required').nullable(),
  productType: Yup.array().required('Product type is required'),
  paymentType: Yup.array().required('Payment type is required'),
  // invoiceNumber: Yup.string().trim().required('Invoice number is required'),
});
