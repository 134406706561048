import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { GlobalChargeFormType } from '../../../pages/GlobalCharge/Create/type';
import { GlobalChargeSingleResponse, GlobalChargeListResponse, GlobalChargeReducerState } from './Type';

function formatSingleGlobalCharge(params: GlobalChargeSingleResponse['data']): GlobalChargeFormType {
  const { fromDate, toDate, _courierId, _systemId, charges, taxes } = params;
  return {
    fromDate,
    toDate,
    _courierId,
    _systemId,
    charges: charges?.length
      ? charges.map(i => ({
          _id: i._id,
          chargeName: i.chargeName,
          chargeType: i.fixed ? 'fixed' : 'percentage',
          value: i.fixed || i.percent || 0,
          selectedApplyOn: i.applyOn,
        }))
      : [],
    taxes: taxes?.length ? taxes : undefined,
    selectedTax: taxes?.length ? taxes[0]._id : '',
  };
}

function getGlobalChargeListPending(state: GlobalChargeReducerState) {
  state.loading = true;
}

function getGlobalChargeListFulfilled(
  state: GlobalChargeReducerState,
  action: PayloadAction<GlobalChargeListResponse>,
) {
  const { data } = action.payload;
  const format = 'MMMM D, YYYY';
  state.data = data?.length
    ? data
        .sort(c1 => new Date(c1.fromDate || c1.createdAt).getTime() - new Date(c1.fromDate || c1.createdAt).getTime())
        .map(elem => ({
          ...elem,
          // start: moment(elem.fromDate || elem.createdAt)
          //   .startOf('day')
          //   .toDate(),
          // end: elem.toDate ? moment(new Date(elem.toDate)).endOf('day').toDate() : new Date(),
          title: `${moment(elem.fromDate || elem.createdAt).format(format)} - ${
            elem.toDate ? moment(elem.toDate).format(format) : 'NOW'
          }`,
        }))
    : undefined;
  state.loading = false;
}

function getGlobalChargeListRejected(state: GlobalChargeReducerState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function createGlobalChargePending(state: GlobalChargeReducerState) {
  state.loading = true;
}

function createGlobalChargeFulfilled(state: GlobalChargeReducerState) {
  state.isRedirect = true;
  state.loading = false;
}

function updateGlobalChargeFulfilled(state: GlobalChargeReducerState) {
  state.isRedirect = true;
  state.loading = false;
}

function createGlobalChargeRejected(state: GlobalChargeReducerState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function getGlobalChargeByIdPending(state: GlobalChargeReducerState) {
  state.loading = true;
}

function getGlobalChargeByIdFulfilled(
  state: GlobalChargeReducerState,
  action: PayloadAction<GlobalChargeSingleResponse>,
) {
  state.loading = false;
  state.singeGlobalCharge = formatSingleGlobalCharge(action.payload.data);
}

function getGlobalChargeByIdRejected(state: GlobalChargeReducerState) {
  state.loading = false;
  state.error = { msg: 'The desired GlobalCharge does not exist.', isError: true };
}

export {
  getGlobalChargeListPending,
  getGlobalChargeListFulfilled,
  getGlobalChargeListRejected,
  //
  createGlobalChargePending,
  createGlobalChargeFulfilled,
  createGlobalChargeRejected,
  //
  getGlobalChargeByIdPending,
  getGlobalChargeByIdFulfilled,
  getGlobalChargeByIdRejected,
  //
  updateGlobalChargeFulfilled,
};
