import { createSlice } from '@reduxjs/toolkit';
import {
  addCreditLimit,
  getCreditLimit,
  getCreditTransactionList,
  getOutstandingBalanceForPostpaid,
  getOutstandingBalanceInvoiceForPostpaid,
} from './actions';
import {
  addCreditLimitFulfilled,
  getCreditLimitFulfilled,
  getCreditTransactionListFulfilled,
  getOutstandingBalanceForPostpaidFulfilled,
  getOutstandingBalanceInvoiceForPostpaidFulfilled,
  pending,
  rejected,
} from './case';
import { CreditState } from './Type';

const initialState = <CreditState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  outstandingBalance: 0,
  totalInvoiceAmtPaid: 0,
  totalShipmentAmt: 0,
  totalShipmentAmtPaid: 0,
  creditLimit: 0,
  availableLimit: 0,
  totalInvoiceAmt: 0,
  success: false,
  data: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },
};

const creditSlice = createSlice({
  name: 'Credit',
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(addCreditLimit.pending, pending);
    addCase(addCreditLimit.fulfilled, addCreditLimitFulfilled);
    addCase(addCreditLimit.rejected, rejected);

    addCase(getCreditLimit.pending, pending);
    addCase(getCreditLimit.fulfilled, getCreditLimitFulfilled);
    addCase(getCreditLimit.rejected, rejected);

    addCase(getOutstandingBalanceForPostpaid.pending, pending);
    addCase(getOutstandingBalanceForPostpaid.fulfilled, getOutstandingBalanceForPostpaidFulfilled);
    addCase(getOutstandingBalanceForPostpaid.rejected, rejected);

    addCase(getOutstandingBalanceInvoiceForPostpaid.pending, pending);
    addCase(getOutstandingBalanceInvoiceForPostpaid.fulfilled, getOutstandingBalanceInvoiceForPostpaidFulfilled);
    addCase(getOutstandingBalanceInvoiceForPostpaid.rejected, rejected);

    addCase(getCreditTransactionList.pending, pending);
    addCase(getCreditTransactionList.fulfilled, getCreditTransactionListFulfilled);
    addCase(getCreditTransactionList.rejected, rejected);
  },
});

export { addCreditLimit, getCreditLimit, getOutstandingBalanceForPostpaid, getCreditTransactionList };
export default creditSlice.reducer;
