import * as XLSX from 'xlsx';
import { SinglePincode } from '../../store/reducers/pincode/Type';

export function downloadPincodes(pincodes: Array<SinglePincode>, systemName: string) {
  /* generate worksheet and workbook */
  const rows = pincodes.map(p => [p.pincode, p.zoneName, (p.isPickupServiceable && 'Yes') || 'No']);
  const worksheet = XLSX.utils.aoa_to_sheet([]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Pincodes');

  XLSX.utils.sheet_add_aoa(worksheet, [['Pincode', 'Zones Name', 'Pickup Serviceability'], ...rows]);

  /* calculate column width */
  const max_width = rows.reduce((w, r) => Math.max(w, r[0].length), 10);
  worksheet['!cols'] = [{ wch: max_width }, { wch: 12 }];
  /* create an XLSX file and try to save to Presidents.xlsx */
  XLSX.writeFile(workbook, `${systemName} -Pincodes.xlsx`, { compression: true });
}

export default { downloadPincodes };
