import { Grid, Typography, styled } from '@mui/material';
import React from 'react';
import SbImage from '../../../components/Image';
import SbButton from '../../../components/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../store';
// import { Steps } from '../constants';

const Root = styled(Grid)(({ theme }) => ({
  background: "url('/images/login/Group.png')",
  backgroundPositionX: '220%',
  backgroundSize: '72%',
  backgroundRepeatX: 'no-repeat',
  flex: 1,
  backgroundRepeat: 'no-repeat',
  height: '100%',
  borderRadius: '12pxx',
  padding: '5%',
  [theme.breakpoints.down('md')]: {
    background: 'transparent',
  },
}));

function OrganizationCompletion() {
  const { organizationData } = useSelector((state: RootStateType) => state?.orgSetup);

  return (
    <Root>
      <Grid container alignItems={'center'} className="h-full">
        <Grid item>
          <SbImage src={organizationData?.logo || ''} alt="logo" style={{ aspectRatio: '17 16' }} height={120} />
          <Typography sx={{ fontSize: { md: '28px', xs: '20px' }, color: '#2C2B6D' }} className="font-semibold mb-2">
            Welcome to Allied Express
          </Typography>

          <Typography
            sx={{ fontSize: { md: '30px', xs: '18px' }, maxWidth: { md: '70%', xs: '90%' } }}
            className="font-light my-4"
          >
            Your organization setup is now <span className="font-bold mt-2">Complete!</span>
          </Typography>

          <Typography
            sx={{ fontSize: { md: '20px', xs: '12px' }, marginBottom: { md: '30px', xs: '20px' } }}
            className="font-semibold mt-2"
          >
            Proceed to{' '}
            <Link to="/carrier-and-services" className="color-p-main" reloadDocument>
              create carriers and services
            </Link>
          </Typography>
          <Link to="/system" reloadDocument>
            <SbButton variant="outlined" className="mr-1 mb-1 rounded-xs" endIcon={<KeyboardArrowRightIcon />}>
              Systems
            </SbButton>
          </Link>
          <Link to="/warehouse" reloadDocument>
            <SbButton variant="outlined" className="mr-1 mb-1 rounded-xs" endIcon={<KeyboardArrowRightIcon />}>
              Warehouses
            </SbButton>
          </Link>
          <Link to="/org-settings" reloadDocument>
            <SbButton variant="outlined" className="mr-1 mb-1 rounded-xs" endIcon={<KeyboardArrowRightIcon />}>
              Settings
            </SbButton>
          </Link>
          <Link to="/" reloadDocument>
            <SbButton className="mr-1 mb-1" variant="contained" endIcon={<KeyboardArrowRightIcon />}>
              Home
            </SbButton>
          </Link>
        </Grid>
      </Grid>
    </Root>
  );
}

export default OrganizationCompletion;
