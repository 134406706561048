import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorType, UserLoginRejectType, UserLoginRes, loginFormStateType } from './type';

function setLoginForm(state: loginFormStateType, data: { payload: loginFormStateType['value'] }) {
  const { payload } = data;
  state.value = payload;
}

function resetLoginForm(state: loginFormStateType) {
  state = {
    ...state,
    value: { userId: null, email: null, phone: null, password: null },
    user: null,
    isOtp: false,
    error: null,
    loading: false,
  };
}

function doLoginPending(state: loginFormStateType) {
  state.loading = true;
  state.error = null;
  state.user = null;
  state.isOtp = false;
}

function doLoginWithOtpPending(state: loginFormStateType) {
  state.loading = true;
  state.error = null;
  state.user = null;
  state.isOtp = false;
}

function doLoginFulfilled(state: loginFormStateType, action: PayloadAction<UserLoginRes>) {
  const { payload } = action;
  state.loading = false;
  state.error = null;
  state.user = payload;
}
function doLoginWithOtpFulfilled(state: loginFormStateType, action: PayloadAction<null>) {
  const { payload } = action;
  state.loading = false;
  state.error = null;
  state.isOtp = true;
  state.user = payload;
}

function doLoginRejected(state: loginFormStateType, action: PayloadAction<UserLoginRejectType | undefined>) {
  const { payload } = action;
  state.loading = false;
  state.user = null;
  state.error = {
    msg: payload?.errorObj?.msg || 'error',
    isError: true,
    code: payload?.errorObj.code,
    verificationPending: payload?.errorObj?.verificationPending,
  };
}

function doLoginWithOtpRejected(state: loginFormStateType, action: PayloadAction<{ errorObj: ErrorType } | undefined>) {
  const { payload } = action;
  state.loading = false;
  state.user = null;
  state.error = { msg: payload?.errorObj?.msg || 'error', isError: true };
}

function doLogoutPending(state: loginFormStateType) {
  state.loading = true;
  state.error = null;
}

function doLogoutRejected(state: loginFormStateType, action: PayloadAction<{ errorObj: ErrorType } | undefined>) {
  const { payload } = action;
  state.loading = false;
  // state.user = null;
  state.error = { msg: payload?.errorObj?.msg || 'error', isError: true };
}

function doLogoutFulfilled() {
  location.reload();
  // state.loading = false;
  // state.user = null;
  // state.error = null;
}

function setResetRequired(state: loginFormStateType) {
  state.resetRequired = true;
}

function closeResetRequired(state: loginFormStateType) {
  state.resetRequired = false;
}

export {
  setLoginForm,
  doLoginPending,
  doLoginFulfilled,
  doLoginRejected,
  doLoginWithOtpPending,
  doLoginWithOtpRejected,
  doLoginWithOtpFulfilled,
  doLogoutPending,
  doLogoutRejected,
  doLogoutFulfilled,
  resetLoginForm,
  setResetRequired,
  closeResetRequired,
};
