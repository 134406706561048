import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import Title from '@Components/Title';
import TopPerformanceView from '@Components/AdminHome/TopPerformanceView';
import TrackingStatusAdmin from '@Components/AdminHome/TrackingStatus';
import TrackingStatusUser from '@Components/UserHome/TrackingStatus';
import GeneralOverview from '@Components/AdminHome/GeneralOverview';
import TopTenView from '@Components/AdminHome/TopTenView';
import { userLogin } from '@Services/hasAdminAccess';
import { Navigation } from './Transactions/Credit';
import { Navigation as NavigationWallet } from './Transactions/Wallet';
import { isActionAllowed } from '../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { UserObj } from '@Reducers/AccountLookup/Type';

import NavigationButtons from './ShipmentHistory/Navigation';

import { getCreditLimit, getOutstandingBalanceForPostpaid } from '@Reducers/Credit';
import { getOutstandingBalanceInvoiceForPostpaid } from '@Reducers/Credit/actions';
import { getOutstandingBalanceForPrepaid, getWalletBalance } from '@Reducers/Wallet';
import TodayShipment from '@Components/UserHome/TodayShipment';
import UserGeneralOverview from '@Components/UserHome/UserGeneralOverview';
import UserDestinations from '@Components/UserHome/UserDestinations';
function HomeRoutes() {
  const isUserLogin = userLogin();
  const dispatch = useDispatch<AppDispatch>();
  const [organizationNameRedirect, setOrganizationNameRedirect] = useState<string>('');

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const isPrepaid = useSelector((state: RootStateType) => state.loginUser.profile?._accountId.isPrepaid);
  const [accountUser, setAccountUser] = useState<UserObj | null>(null);
  const [organisationName, setOrganisationName] = useState<string>();
  const organizationData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { name = '' } = organizationData || {};

  const isUpdateLimitEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Credits_And_Wallet.update_limit,
    PERFORM_ACTION.write,
  );

  const isAddMoneyEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Credits_And_Wallet.add_money,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    setOrganizationNameRedirect(subdomain);
    if (isPrepaid) {
      dispatch(getOutstandingBalanceForPrepaid());
      if (isUserLogin) dispatch(getWalletBalance());
    } else {
      dispatch(getCreditLimit());
      dispatch(getOutstandingBalanceForPostpaid());
      dispatch(getOutstandingBalanceInvoiceForPostpaid());
    }
  }, [isUserLogin]);

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    setOrganisationName(subdomain);
  }, []);

  console.log('isUserLogin', setAccountUser, organizationNameRedirect);

  const allowedOrganisations = ['ae', 'uat-speedbox', 'localhost', 'preprod'];
  return (
    <>
      <Box>
        <Grid color={'primary.main'} item xs={12}>
          {/* <Title title={`Welcome to ${organizationNameRedirect?.toUpperCase()}!`} removeBorder /> */}
          <Title title={`Welcome to ${name}!`} removeBorder />
        </Grid>
        {allowedOrganisations.includes(organisationName!) && (
          <>
            {!isUserLogin ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <TopPerformanceView />
                <TrackingStatusAdmin />
                <GeneralOverview />
                <TopTenView />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Box>
                  <Box sx={{ width: '100%' }}>
                    <NavigationButtons />
                  </Box>
                  <Box
                    sx={{
                      padding: '30px',
                      border: '2px solid #f6f6f6',
                      borderRadius: '10px',
                      boxShadow: '0px 1px 4px 0px #00000040',
                      marginTop: '10px',
                    }}
                  >
                    {isPrepaid ? (
                      <>
                        <p style={{ fontSize: '24px', fontWeight: '500', marginBottom: '32px' }}>Wallet Details</p>
                        <NavigationWallet
                          selectedUser={accountUser}
                          refetch={() => {
                            console.log('check');
                          }}
                          disabled={!isAddMoneyEditAllowed}
                        />
                      </>
                    ) : (
                      <>
                        <p style={{ fontSize: '24px', fontWeight: '500', marginBottom: '32px' }}>Credit Details</p>
                        <Navigation selectedUser={accountUser} disabled={!isUpdateLimitEditAllowed} />
                      </>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    padding: '10px',
                    border: '2px solid #f6f6f6',
                    borderRadius: '10px',
                    boxShadow: '0px 1px 4px 0px #00000040',
                  }}
                >
                  <TodayShipment />
                  <TrackingStatusUser />
                </Box>
                <UserGeneralOverview />
                <UserDestinations />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default HomeRoutes;
