import React, { useMemo, useState } from 'react';
import { MenuItem, OutlinedInput, Select, SxProps } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

interface FilterSearchProps {
  onSelectChange?: (value: string) => void;
  list?: { label: string; value: string }[];
  selectValue?: string;
  textValue: string;
  onTextChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onTextSearch: () => void;
  onTextClear?: React.MouseEventHandler;
  onTextBlur?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  inputRef?: React.Ref<HTMLInputElement>;
  placeholder?: string;
  disabled?: boolean;
  inputSx?: SxProps;
  inputClassName?: string;
}

function FilterSearch(props: FilterSearchProps) {
  const { onSelectChange, list, inputSx = {}, selectValue, textValue } = props;
  const [open, setOpen] = useState(false);

  const handleSelectChange = (e: SelectChangeEvent) => {
    onSelectChange && onSelectChange(e.target.value);
  };

  const handleOpen = () => {
    if (list && list?.length > 1) {
      // Disabled click when list item contains only one filter
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sortedList = useMemo(() => {
    return list?.slice().sort((a, b) => a.label.localeCompare(b.label));
  }, [list]);

  return (
    <div className="flex">
      {sortedList?.length ? (
        <Select
          style={{
            backgroundColor: 'var(--mui-palette-background-hoverMenu)',
            color: 'black',
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          }}
          value={selectValue}
          onChange={handleSelectChange}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            //   transformOrigin: {
            //     vertical: 'top',
            //     horizontal: 'left',
            //   },
          }}
        >
          {sortedList.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <></>
      )}

      <OutlinedInput
        className={props.inputClassName || ''}
        placeholder={props.placeholder || ''}
        onKeyUp={event => {
          if (event.code === 'Enter') props.onTextSearch();
        }}
        endAdornment={
          <>
            <SearchIcon
              className="cursor-pointer"
              onClick={() => {
                props.onTextSearch();
              }}
            />
            <ClearIcon className="cursor-pointer" onClick={props.onTextClear} />
          </>
        }
        sx={list?.length ? { ...inputSx, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : inputSx}
        disabled={!!props.disabled}
        value={textValue || ''}
        onChange={props.onTextChange}
        inputRef={props.inputRef}
        onBlur={props.onTextBlur}
      />
    </div>
  );
}

export default FilterSearch;
