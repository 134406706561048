import React, { useEffect, useState } from 'react';
import Title from '../../components/Title';
import { BorderedWrapper } from './style';
import { Grid, SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import PickupDrop from '../../components/Shipment/PickupDrop';
// ICONS

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../store';
import { defaultPickupState } from '../CreateShipment/constants';
import { PickupDataType, PickupType } from '../../types/shipment';
import { DropdownOptionType } from '../../components/Dropdown/Type';
import dayjs, { Dayjs } from 'dayjs';
import { Details } from '../CreateShipment/ShipmentSummary/styles';
import { SHIPMENT_TYPES } from '../GetRatesV2/Constant';
import SbButton from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { UpdatePickUpAndDropOff } from '@Reducers/AdminShipment/Type';
import { getWarehouseListForShipment } from '@Reducers/Warehouse';
import { updateUserPickUpAndDropOff } from '@Reducers/ShipmentHistory/actions';

export function ShipmentDetails() {
  // const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [error, setError] = useState<string>('');
  const [pickupType, setPickupType] = useState<PickupDataType>({ ...defaultPickupState });
  const { warehouseListForShipmentOptionsHashById } = useSelector((state: RootStateType) => state.Warehouse);
  const countryHashById = useSelector((state: RootStateType) => state.global.countriesObj);
  const { shipmentById } = useSelector((state: RootStateType) => state.shipment);
  // const { _packageId, shipmentMode, customerReferenceNumber } = shipmentById;
  const _packageId = shipmentById?._packageId;
  const shipmentMode = shipmentById?.shipmentMode;
  const shipper = shipmentById?._shipperId;
  const shipmentType = _packageId?.isDocument ? SHIPMENT_TYPES[1].display : SHIPMENT_TYPES[0].display;
  // const shipmentType = _packageId?.isDropOff ? SHIPMENT_TYPES[0].display
  const insurance = _packageId?.insurance ? 'Yes' : 'No';
  const packaging = _packageId?.packaging ? 'Yes' : 'No';
  const referenceNumber = shipmentById?.userReferenceNumber;

  const handlePickupTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPickupType({ ...defaultPickupState, type: value as PickupType });
  };

  const handleTimeSlotChange = (
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
  ) => {
    const value = event.target.value;
    setPickupType({ ...pickupType, timeSlot: value });
  };

  const handlePickupDateChange = (date: Dayjs) => {
    setPickupType({ ...pickupType, date: date });
  };

  const handleWarehouseChange = (
    [_selected]: Array<string>,
    _selectedObj: DropdownOptionType[],
    _event: SelectChangeEvent<string>,
  ) => {
    const value = _selected;
    setPickupType({ ...pickupType, warehouse: value });
  };

  const onSava = () => {
    const isDropOff = pickupType.type === 'dropOff';
    if (!isDropOff && !(pickupType.date && pickupType.timeSlot)) {
      return setError('Pickup date and time is required.');
    } else if (isDropOff && !pickupType.warehouse) {
      return setError('Drop of warehouse is required.');
    }
    if (!shipmentById) return;
    const payload: UpdatePickUpAndDropOff = { shipmentNumber: shipmentById.shipmentNumber, isDropOff: isDropOff };
    if (isDropOff && pickupType.warehouse) {
      const selectedWarehouse = warehouseListForShipmentOptionsHashById[pickupType.warehouse];
      const coAttention = selectedWarehouse.coAttention;
      const [telephoneCode, number] = coAttention.contact;
      payload.warehouseId = pickupType.warehouse;
      const countryId = selectedWarehouse._addressId.country;
      payload.warehouseAddress = {
        ...selectedWarehouse._addressId,
        country: countryHashById?.[countryId]?.name,
        name: selectedWarehouse.name,
        phone: { telephoneCode, number, name: coAttention.name },
        email: coAttention.email,
      };
    } else if (pickupType.date && pickupType.timeSlot) {
      payload.pickupDate = pickupType.date.toISOString();
      payload.timeSlot = pickupType.timeSlot;
    }

    dispatch(updateUserPickUpAndDropOff(payload));
  };

  const handleOnBack = () => {
    navigate('/shipment/history');
  };

  useEffect(() => {
    if (shipper?.pincode) {
      dispatch(getWarehouseListForShipment(shipper?.pincode ? { pincode: shipper?.pincode } : {}));
    }
  }, [shipper?.pincode]);

  useEffect(() => {
    if (shipmentById?._id) {
      setPickupType({
        type: shipmentById?._packageId.isDropOff ? 'dropOff' : 'pickUp',
        date: shipmentById?._packageId.isDropOff ? undefined : dayjs(shipmentById?._packageId.pickupDate),
        timeSlot: shipmentById?._packageId.isDropOff ? '' : shipmentById?._packageId.timeSlot,
        warehouse: shipmentById._warehouseId,
      });
    }
  }, [JSON.stringify(shipmentById)]);

  return (
    <>
      <Title title="Shipment Details" removeBorder />
      <BorderedWrapper marginBottom="10px">
        <Grid container justifyContent="space-between">
          <Grid
            item
            justifyContent="space-around"
            md={6}
            xs={12}
            sx={{
              borderRight: { md: '1px solid var(--mui-palette-grey-300)', sm: '0px' },
              paddingRight: { md: 3, sm: 0 },
            }}
            // className={isMobile ? '' : 'border-right pr-3'}
          >
            <PickupDrop
              error={error}
              handlePickupTypeChange={handlePickupTypeChange}
              pickupType={pickupType.type}
              handleTimeSlotChange={handleTimeSlotChange}
              timeSlot={pickupType.timeSlot}
              handlePickupDateChange={handlePickupDateChange}
              pickupDate={pickupType.date}
              warehouse={pickupType.warehouse}
              handleWarehouseChange={handleWarehouseChange}
              pincode={shipper?.pincode}
              selectedCountry={shipper?.country}
            />
          </Grid>

          <Grid container item justifyContent="space-around" md={6} xs={12} mt={isMobile ? 2 : 0}>
            <Grid item md={6} xs={12} pl={!isMobile ? 2 : 0}>
              <Details mt="2">{`Shipment Type: ${shipmentType}`}</Details>
              <Details mt="2">{`Shipment Mode: ${shipmentMode}`}</Details>
              <Details mt="2">{`Insured: ${insurance}`}</Details>
              <Details mt="2">{`Packaging: ${packaging}`}</Details>
            </Grid>
            <Grid item md={6} xs={12}>
              <Details mt="2">{`Payment Mode: ${shipmentById?._packageId?.isCod ? 'Cash' : 'Online'}`}</Details>
              <Details mt="2">{`Reference No: ${referenceNumber}`}</Details>
              <Details mt="2">{`Purpose of Shipment: ${shipmentById?.extraDetails?.other?.purposeOfShipment}`}</Details>
              <Details mt="2">{`Note: ${shipmentById?.shipmentNotes}`}</Details>
            </Grid>
          </Grid>
        </Grid>
      </BorderedWrapper>
      <Grid container justifyContent="flex-end" gap={2}>
        <SbButton variant="outlined" onClick={handleOnBack}>
          Back
        </SbButton>
        <SbButton variant="contained" onClick={onSava}>
          Save
        </SbButton>
      </Grid>
    </>
  );
}

export default ShipmentDetails;
