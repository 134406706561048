import * as Yup from 'yup';

const PhoneSchema = {
  telephoneCode: Yup.string().trim().required('Telephone code is required.'),
  number: Yup.string().trim().required('Phone number code is required.'),
};

const alternatePhoneSchema = {
  telephoneCode: Yup.string()
    .trim()
    .when('$number', ([number], schema) => {
      return number ? schema.required('Telephone code is required.') : schema;
    }),
  number: Yup.string().trim(),
};

const ExtraDetails = Yup.object({
  type: Yup.string().trim().default(''),
  IdType: Yup.string().trim().default(''),
  IdNumber: Yup.string().trim().default(''),
});

const ShipperConsignee = Yup.object().shape({
  phone: Yup.object(PhoneSchema),
  alternatePhone: Yup.object({
    name: Yup.string().trim(),
    ...alternatePhoneSchema,
  }).nullable(),
  name: Yup.string().trim().required('Name is required.'),
  email: Yup.string().email('Invalid email address.').trim(),
  companyName: Yup.string().trim(),
  line1: Yup.string().trim().required('Line 1 is required.'),
  line2: Yup.string().trim(),
  city: Yup.string().trim().required('City is required.'),
  // state: Yup.string().trim().required('State is required.'),
  state: Yup.string().trim(),
  country: Yup.string().trim().required('Country is required.'),
  pincode: Yup.string().trim().required('Pincode is required.'),
  extraDetails: ExtraDetails,
});
// .required('Email is required.')
// .required('Company is required.')

const PackageShipment = Yup.object().shape({
  shipmentMode: Yup.string().required(),
});

const PackageInfo = Yup.object().shape({
  isDocument: Yup.boolean(),
  unitOfMeasurement: Yup.string(),
  packaging: Yup.boolean(),
  insurance: Yup.boolean(),
  isCod: Yup.boolean(),
  currency: Yup.string(),
});

const UpdateTrackingStatus = Yup.object().shape({
  status: Yup.string().required('Status is required.'),
  location: Yup.string(),
  message: Yup.string(),
  boxesToUpdate: Yup.array().of(Yup.string().trim()).min(1, 'Select atleast one box.'),
});

export default { ShipperConsignee, PackageInfo, PackageShipment, UpdateTrackingStatus };
