import React, { useEffect, useState } from 'react';
import CreateSysForm, { initCreateWarehouse } from './Form';
import { Grid } from '@mui/material';
import SbButton from '../../../components/Button';
import { AppDispatch, RootStateType } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createWarehouse } from '../../../store/reducers/Warehouse';
import { useFormik } from 'formik';
import { CreateWareHouseSchema } from './schema';
import { WarehouseType } from './type';
import Divider from '../../../components/Divider';
import Loader from '@Components/Loader';
import { useSearchQueryParams } from '@Hook/useSearchQueryParams';

function CreateWarehouse() {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const [warehouse, _setWarehouse] = useState<WarehouseType>({ ...initCreateWarehouse });
  const WarehouseState = useSelector((state: RootStateType) => state.Warehouse);
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const { systemState } = useSearchQueryParams();
  const [disabledSystemdropdown, setDisabledSystemDropdown] = useState<boolean>(false);

  // useEffect(() => {
  //   if (WarehouseState.isRedirect) {
  //     dispatch(resetWarehouseRedirect());
  //     navigate('/warehouse');
  //   }
  // }, [WarehouseState.isRedirect]);

  const onCreate = (params: WarehouseType) => {
    const payload = {
      name: params.name,
      _systemId: [params.systemId],
      coAttention: {
        name: params.attendanceName,
        contact: `${params.countryCode} ${params.number}`,
        email: params.email,
      },
      address: {
        line1: params.addressLine1,
        line2: params.addressLine2,
        city: params.city,
        state: params.state,
        country: params.country,
        pincode: params.pincode,
      },
    };
    if (systemState) {
      dispatch(createWarehouse({ payload, navigate: '/system', showModal: true }));
    } else {
      dispatch(createWarehouse({ payload, navigate: '/warehouse', showModal: true }));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...warehouse, state: systemState ? systemState : warehouse.state },
    validationSchema: CreateWareHouseSchema,
    onSubmit(values) {
      onCreate(values);
    },
  });

  useEffect(() => {
    const systemId = searchParams.get('systemId');
    if (systemId) {
      formik.setFieldValue('systemId', systemId);
      setDisabledSystemDropdown(true);
    }
  }, []);

  useEffect(() => {
    if (organizationCountry) formik.setFieldValue('country', organizationCountry._id);
  }, [organizationCountry]);

  return (
    <Loader loading={WarehouseState.loading} overly>
      <>
        <CreateSysForm
          showBackgroundColor={false}
          errors={formik.errors}
          values={formik.values}
          handleChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
          disabledSystemdropdown={disabledSystemdropdown}
        />
        <Divider className="mt-3 mb-3" />
        <Grid container item mt={4} spacing={2} justifyContent="flex-end">
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton
              fullWidth
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton
              fullWidth
              variant="contained"
              onClick={_event => {
                formik.handleSubmit();
              }}
            >
              Create
            </SbButton>
          </Grid>
        </Grid>
      </>
    </Loader>
  );
}

export default CreateWarehouse;
