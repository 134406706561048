import React, { useState } from 'react';
import { SBTable, SBTableWrapper } from '../../components/Table/Table';
import {
  Checkbox,
  FormGroup,
  Grid,
  IconButton,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableHeadCell, TableRowCell } from '../../components/Table/TableCell';
import { SBTableRow } from '../../components/Table/TableRow';
import SbButton from '../../components/Button';
import SbTextField from '../../components/Textfield';
import DeleteIcon from '@mui/icons-material/Delete';

import SbTooltip from '../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import { FormikType, SHIPMENT_STETTING_FORM } from './type';
import { DEFAULT_DOC_LIST, defaultDocValues } from '../OrganizationSetup/constants';

// const TABLE_HEADER_COLS = ['KYC list', 'Mandatory'];

type PROPS_TYPE = FormikType & {
  values: {
    personalShipmentId: SHIPMENT_STETTING_FORM['personalShipmentId'];
    exportShipmentId: SHIPMENT_STETTING_FORM['exportShipmentId'];
  };
  disabled?: boolean;
};

function ShipmentDetails(props: PROPS_TYPE) {
  return (
    <Grid container>
      <Grid item container mb={2}>
        <Grid container item xs={6}>
          <Typography className="md-text font-semibold mr-1">Shipper & Consignee Id Details</Typography>
          <SbTooltip
            placement="right"
            title="Shipment and Consignee ID Details will be visible to shipper at the time of Shipment Booking "
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <Grid item xs={6}>
          {/* <Browse /> */}
        </Grid>
      </Grid>

      {/*  */}
      <ShipmentTable
        disabled={props.disabled}
        type={'personal'}
        values={props.values.personalShipmentId}
        setFieldValue={props.setFieldValue}
      />
      <Grid container className="mt-3">
        <ShipmentTable
          disabled={props.disabled}
          type={'export'}
          values={props.values.exportShipmentId}
          setFieldValue={props.setFieldValue}
        />
      </Grid>
    </Grid>
  );
}

function ShipmentTable(props: {
  type: 'personal' | 'export';
  values: SHIPMENT_STETTING_FORM['personalShipmentId'] | SHIPMENT_STETTING_FORM['exportShipmentId'];
  setFieldValue: FormikType['setFieldValue'];
  disabled?: boolean;
}) {
  const [docName, setDocName] = useState<string>('');
  const [error, setError] = useState<string>('');

  function handleAddMore() {
    if ([...props.values, ...DEFAULT_DOC_LIST].some(doc => docName.toLocaleLowerCase() === doc.value)) {
      if (props.type === 'personal') setError('Personal shipment ID type already exists.');
      else if (props.type === 'export') setError('Export shipment ID type already exists.');

      return;
    }
    if (error) setError('');
    const tempData = [...props.values];
    tempData.push({ label: docName.trim(), value: docName.trim().toLocaleLowerCase(), isActive: true });
    if (props.setFieldValue) {
      if (props.type === 'personal') props.setFieldValue('personalShipmentId', tempData);
      else if (props.type === 'export') props.setFieldValue('exportShipmentId', tempData);
    }
    setDocName('');
  }

  function handleDelete(ind: number) {
    const tempData = [...props.values];
    tempData.splice(ind, 1);
    if (props.setFieldValue) {
      if (props.type === 'personal') props.setFieldValue('personalShipmentId', tempData);
      else if (props.type === 'export') props.setFieldValue('exportShipmentId', tempData);
    }
  }

  function handleCheckboxChange(chargeName: string, checked: boolean) {
    const tempData = [...props.values];
    const index = tempData.findIndex(x => x.value === chargeName.toLocaleLowerCase());

    if (index === -1) {
      tempData.push({ value: chargeName.toLocaleLowerCase(), label: chargeName, isActive: true });
    } else {
      tempData[index] = { ...tempData[index], isActive: !checked };
      // tempData.splice(index, 1);
    }
    if (props.setFieldValue) {
      if (props.type === 'personal') props.setFieldValue('personalShipmentId', tempData);
      else if (props.type === 'export') props.setFieldValue('exportShipmentId', tempData);
    }
  }

  return (
    <SBTableWrapper>
      <SBTable className="rounded-md">
        <TableHead>
          <TableRow>
            <TableHeadCell className="text-left">
              {(props.type === 'personal' && 'Personal shipment ID type') ||
                (props.type === 'export' && 'Export shipment ID type')}
            </TableHeadCell>
            <TableHeadCell>Mandatory</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* {DEFAULT_DOC_LIST.map((doc, ind) => (
            <TableRowCharge
              key={`default-${ind}`}
              label={doc.label}
              handleCheckboxChange={handleCheckboxChange}
              index={ind}
              checked={extraChargesValues?.includes(doc.value)}
            />
          ))} */}
          {props.values?.map(
            (doc, ind) => (
              // defaultChargesValues?.includes(doc.value) ? (
              //   <React.Fragment key={`extra-${ind}`}></React.Fragment>
              // ) : (
              <TableRowCharge
                key={`extra-${ind}`}
                label={doc.label}
                handleDelete={handleDelete}
                index={ind}
                showDelete={!defaultDocValues.includes(doc.value)}
                handleCheckboxChange={handleCheckboxChange}
                checked={!!doc.isActive}
                disabled={props.disabled}
              />
            ),
            // ),
          )}
        </TableBody>
        <TableFooter>
          <SBTableRow>
            <TableRowCell>
              <SbTextField
                placeholder="Enter"
                value={docName}
                onChange={e => setDocName(e.target.value)}
                error={error}
                disabled={props.disabled}
              />
            </TableRowCell>
            <TableRowCell align="center">
              <SbButton variant="contained" disabled={!docName} onClick={handleAddMore}>
                Add more
              </SbButton>
            </TableRowCell>
          </SBTableRow>
        </TableFooter>
      </SBTable>
    </SBTableWrapper>
  );
}

// ------------------------------------------------------------------------------------------------------------------
interface ROW_TYPE_PROPS {
  label: string;
  handleCheckboxChange?: (chargeName: string, checked: boolean) => void;
  handleDelete?: (ind: number) => void;
  index: number;
  showDelete?: boolean;
  checked?: boolean;
  disabled?: boolean;
}
function TableRowCharge(props: ROW_TYPE_PROPS) {
  const { checked, label, handleCheckboxChange, index, showDelete, handleDelete } = props;
  return (
    <SBTableRow>
      <TableRowCell width={'75%'} align="left" className="md-text font-medium">
        {label}
      </TableRowCell>
      <TableRowCell align="center" sx={{ px: 0 }}>
        <Grid container justifyContent="center">
          <FormGroup classes={{ root: 'flex' }} style={{ width: '15px' }}>
            <Checkbox
              onChange={_ => handleCheckboxChange?.(label, !!checked)}
              checked={!!checked}
              disabled={props.disabled}
            />
          </FormGroup>
          {props.disabled && showDelete && (
            <IconButton className="ml-2" onClick={() => handleDelete?.(index)}>
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </TableRowCell>
    </SBTableRow>
  );
}

export default ShipmentDetails;
