import React from 'react';
import { GridWrapper, SubHeading } from '../styles';
import { Grid, Typography } from '@mui/material';
import Divider from '../../../../components/Divider';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { createShipmentContextType } from '../../../../types/shipment';
import { PricingType } from '../../../../store/reducers/GetRates/Type';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';

function Charges() {
  const { selectedCSP } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name);
  const { totalPricing, pricing, taxPricing } = selectedCSP ?? {};
  return (
    <GridWrapper>
      <SubHeading>Charges</SubHeading>
      <Grid container justifyContent={'end'}>
        <Typography className="md-text font-semibold">{orgCountryCurrency}</Typography>
      </Grid>
      <Divider className="my-1" />
      {pricing?.length ? (
        pricing.map(({ displayKey, value, key }: PricingType) => (
          <Grid key={key} container justifyContent={'space-between'} className="mb-1">
            <Grid item xs={10} lg={9}>
              <Typography className="md-text">{displayKey}</Typography>
            </Grid>
            <Grid item xs={2} lg={3}>
              <Typography className="md-text text-right">{value}</Typography>
            </Grid>
          </Grid>
        ))
      ) : (
        <></>
      )}
      {taxPricing?.length ? (
        taxPricing.map(({ displayKey, value, key }: PricingType) => (
          <Grid key={key} container justifyContent={'space-between'} className="mb-1">
            <Grid item xs={10} lg={9}>
              <Typography className="md-text">{displayKey}</Typography>
            </Grid>
            <Grid item xs={2} lg={3}>
              <Typography className="md-text text-right">{value}</Typography>
            </Grid>
          </Grid>
        ))
      ) : (
        <></>
      )}
      <Divider className="my-1" />
      <Grid container justifyContent={'space-between'}>
        <SubHeading className="font-medium">Total</SubHeading>
        <SubHeading className="font-semibold">
          {orgCountryCurrency} {totalPricing}
        </SubHeading>
      </Grid>
    </GridWrapper>
  );
}

export default Charges;
