import SbButton from '@Components/Button';
import SBDropdown from '@Components/Dropdown';
import SbTextField from '@Components/Textfield';
import { getAdminShipmentById, getShipmentWebsocketStream, placeRTOShipment } from '@Reducers/AdminShipment/actions';
import { getServiceList } from '@Reducers/CarrierAndService';
import { getRates, resetCharges } from '@Reducers/GetRates';
import { AddressType, CSPChargeType, RatesType } from '@Reducers/GetRates/Type';
import { AppDispatch, RootStateType } from '@Store';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SingleCSP from '../../../GetRatesV2/CSPList/SingleCSP';
import SingleCSPMobile from '../../../GetRatesV2/CSPList/SingleCSPMobile';
// import { RTOSuccessModal } from './RTOSuccessModal';

interface ManualRatesType {
  service: string;
  amount: string;
}

const defaultManualRates = { service: '', amount: '' };

function getAddressObj({ pincode, city, country, state }: AddressType) {
  return { city, state, country, pincode };
}

export default function CreateRTO() {
  const dispatch = useDispatch<AppDispatch>();
  const { sn: shipmentNumber } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const { serviceDropdownList } = useSelector((state: RootStateType) => state.carrierAndService);
  const CSPChargesData = useSelector((state: RootStateType) => state?.getRates?.CSPChargesData);
  const { shipmentById, RTOShipment } = useSelector((state: RootStateType) => state.adminShipment);
  const { _warehouseAddressId, _shipperId, _consigneeId, _packageId, _userId, sellingPricing } = shipmentById || {};

  const [show, setShow] = useState<{ rto: boolean; manualRates: boolean }>({ rto: false, manualRates: false });
  const [selectedCSP, setSelectedCSP] = useState<CSPChargeType | null>();
  const [manualRates, setManualRates] = useState<ManualRatesType>(defaultManualRates);
  // const [showModal, setShowModal] = useState(false);

  const source = useMemo(() => {
    return _consigneeId;
  }, [_consigneeId]);
  const destination = useMemo(() => {
    return _warehouseAddressId ? _warehouseAddressId : _shipperId;
  }, [_warehouseAddressId, _shipperId]);

  useEffect(() => {
    if (RTOShipment && shipmentNumber) {
      dispatch(getAdminShipmentById(shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
      // setShowModal(true);
    }
  }, [RTOShipment, shipmentNumber]);

  function handleBookCSP(CSPCharge: CSPChargeType | null) {
    setSelectedCSP(CSPCharge);
    if (show.manualRates) {
      setShow(show => ({ ...show, manualRates: !show.manualRates }));
      setManualRates(defaultManualRates);
    }
  }

  function handleGetRates() {
    if (_shipperId) {
      dispatch(resetCharges());
      const box = _packageId?.box.map(({ length, width, height, weight }) => ({ length, width, height, weight })) || [];
      const obj: RatesType = {
        source: { ...getAddressObj(source as AddressType) },
        destination: { ...getAddressObj(destination as AddressType) },
        shipmentType: _packageId?.isDocument ? 'document' : 'parcel',
        document: !!_packageId?.isDocument,
        userId: _userId?._id,
        weight: sellingPricing?.chargeableWeight || 0,
        package: {
          unitOfMeasurement: _packageId?.unitOfMeasurement || 'kg-cm',
          box: [...box],
        },
        insurance: !!_packageId?.insurance,
        packaging: !!_packageId?.packaging,
      };
      dispatch(getRates([{ ...obj, rateFor: 'create-shipment' }, false]));
      dispatch(getServiceList({}));
    }
  }

  function handleCreateRTO() {
    if (!show.rto) handleGetRates();
    setShow(show => ({ ...show, rto: !show.rto }));
  }

  function handleManualRatesAdd() {
    if (!show.manualRates) {
      setSelectedCSP(null);
    }
    setShow(show => ({ ...show, manualRates: !show.manualRates }));
  }

  function handlePlaceShipment() {
    if (manualRates.service) {
      const payload = {
        shipmentNumber: shipmentById?.shipmentNumber,
        systemId: selectedCSP?._systemId,
        userId: _userId?._id || '',
        courierId: manualRates.service,
        amount: manualRates.amount,
        isManual: true,
      };
      dispatch(placeRTOShipment(payload));
    } else if (selectedCSP) {
      const payload = {
        shipmentNumber: shipmentById?.shipmentNumber,
        systemId: selectedCSP?._systemId || '',
        userId: _userId?._id || '',
        courierId: selectedCSP?._courierId,
      };
      dispatch(placeRTOShipment(payload));
    }
  }

  return (
    <Grid container rowGap={0}>
      <Grid container justifyContent="end">
        <SbButton variant="outlined" containerClass="rounded-xs capitalize" onClick={handleCreateRTO}>
          Create RTO Shipment
        </SbButton>
      </Grid>
      {show.rto && (
        <>
          {CSPChargesData?.charges.length ? (
            <>
              <div>
                <Typography className="org-text">Select Service</Typography>
              </div>
              <Grid container className="mt-2 mb-4" sx={{ maxHeight: 380, overflowY: 'auto' }}>
                {CSPChargesData?.charges?.map((charge, ind) =>
                  isMobile ? (
                    <SingleCSPMobile
                      key={ind}
                      CSPCharge={charge}
                      handleBookCSP={handleBookCSP}
                      isSelected={selectedCSP?.serviceName === charge.serviceName}
                    />
                  ) : (
                    <SingleCSP
                      key={ind}
                      CSPCharge={charge}
                      handleBookCSP={handleBookCSP}
                      renderedFrom="admin-shipment-history"
                      isSelected={selectedCSP?.serviceName === charge.serviceName}
                    />
                  ),
                )}
              </Grid>
            </>
          ) : (
            <></>
          )}
          <SbButton variant="outlined" containerClass="rounded-xs capitalize" onClick={handleManualRatesAdd}>
            Add manual rates
          </SbButton>
          {show.manualRates && (
            <Grid container sx={{ py: 3 }} rowSpacing={2} direction="column" whiteSpace={'nowrap'}>
              <Grid container item>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    id="Service"
                    label="Service"
                    options={serviceDropdownList}
                    onChange={([value]) => {
                      setManualRates(rates => ({ ...rates, service: value }));
                      console.log('service onchange : ', value);
                    }}
                    name="_courierId"
                    placeholder="Select"
                    value={[manualRates.service]}
                    // error={errors?._courierId}
                  />
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs={12} md={6}>
                  <SbTextField
                    label="Enter Amount"
                    placeholder="Type here"
                    onChange={e => {
                      setManualRates(rates => ({ ...rates, amount: e.target.value }));
                    }}
                    name="length"
                    value={manualRates.amount}
                    type="number"
                    // error={errors.length}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid container item xs={12} md={12} justifyContent="end">
              <SbButton
                variant="contained"
                containerClass="rounded-xs capitalize"
                sx={{ mr: 2 }}
                onClick={handlePlaceShipment}
              >
                Place RTO Shipment
              </SbButton>
              <SbButton variant="outlined" containerClass="rounded-xs capitalize">
                Cancel
              </SbButton>
            </Grid>
          </Grid>
        </>
      )}

      {/* {RTOShipment && (
        <RTOSuccessModal open={showModal} shipmentNumber={RTOShipment} handleClose={() => setShowModal(false)} />
      )} */}
    </Grid>
  );
}
