import React from 'react';
import { Grid } from '@mui/material';
import Title from '@Components/Title';
import { Outlet } from 'react-router-dom';

function AccessGroup() {
  return (
    <Grid container>
      <Title title="Access Control" subTitle="Assign access to admins " removeBorder />
      <Outlet />
    </Grid>
  );
}

export default AccessGroup;
