import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import GoogleMaps from '../../../components/Location';
import RadioButton from '../../../components/RadioButton';
import SbTextField from '../../../components/Textfield';
import GetRatesContext from '../../../context/GetRates';
import { getFormattedLocation } from '../../../services/formateLocation';
import { GetRatesContextType, RatesInputErrorType, RatesInputType } from '../Type';
import { Root } from './styles';
// import AddressView from './AddressView';
import { RootStateType } from '@Store';
import { ValidationResult } from 'joi';
import { useSelector } from 'react-redux';
import { DropdownOptionType } from '../../../components/Dropdown/Type';
import { DefaultRatesInput, DefaultRatesInputError, WEIGHT_UNITS } from '../Constant';
import { SelectOptions } from '../StyleComponent';
import { documentWeight, inputRatesSchema } from './Schema';

const ShipmentTypeList = [
  { value: 'parcel', label: 'Parcel' },
  { value: 'document', label: 'Document' },
];

function RatesInput() {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { formData } = useSelector((state: RootStateType) => state.getRates);
  const {
    hideBoxes,
    setHideBoxes,
    setRateInput,
    setBoxes,
    setRatesInputHasError,
    boxes,
    pickup,
    destination,
    setPickup,
    setDestination,
    setBoxCount,
  } = React.useContext(GetRatesContext) as GetRatesContextType;

  const [initials, setInitials] = useState<RatesInputType>({ ...DefaultRatesInput });
  // const [pickup, setPickup] = useState<AddressType>({ ...DefaultAddress });
  // const [destination, setDestination] = useState<AddressType>({ ...DefaultAddress });
  const [pickupPinCode, setPickupPinCode] = useState<string>('');
  const [destinationPinCode, setDestinationPinCode] = useState<string>('');
  const [errors, setErrors] = useState<RatesInputErrorType>({ ...DefaultRatesInputError });

  useEffect(() => {
    if (formData?.boxes) {
      const { boxes, weight, weightUnit } = formData;
      setInitials(initials => ({ ...initials, weight, weightUnit }));
      setBoxCount(boxes.length);
    }
  }, [formData]);

  useEffect(() => {
    setBoxCount(boxes.length);
  }, [boxes.length]);

  useEffect(() => {
    setInitials(initials => ({ ...initials, pickup, destination, pickupPinCode, destinationPinCode }));
    setPickupPinCode(pickup.pincode);
    setDestinationPinCode(destination.pincode);
  }, [pickup, destination, pickupPinCode, destinationPinCode]);

  useEffect(() => {
    setRateInput({ ...initials });
    const result: ValidationResult = inputRatesSchema.validate(initials, { abortEarly: false });
    // console.info('result:', result);
    const { error } = result;
    if (error?.details.length) setRatesInputHasError(true);
    else setRatesInputHasError(false);
  }, [initials]);

  function validateProperty(event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) {
    const { name, value } = event.target;
    const obj = { [name]: value };
    const result = inputRatesSchema.validate(obj);
    const { error } = result;
    const errorData = { ...errors };
    const errorMessage = error && error.details[0].context?.key === name ? error.details[0].message : null;
    if (errorMessage && name in DefaultRatesInputError) {
      errorData[name as keyof RatesInputErrorType] = errorMessage;
    } else {
      errorData[name as keyof RatesInputErrorType] = '';
    }
    setErrors(errorData);

    // const { name, value } = event.target;
    // const obj = { [name]: value };
    // const result = schema.validate(obj);
    // const { error } = result;
    // return error && error.details[0].context?.key === name ? error.details[0].message : null;
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    validateProperty(event);
    const { name, value } = event.target;
    const data = { ...initials };
    if (name === 'pickupPinCode') {
      setPickupPinCode(value);
      setPickup({ ...pickup, pincode: value });
    } else if (name === 'destinationPinCode') {
      setDestinationPinCode(value);
      setDestination({ ...destination, pincode: value });
    } else if (name === 'shipmentType') {
      data[name] = value;
    } else if (name === 'weightUnit') {
      data[name] = value === 'kg' ? 'kg' : 'lb';
    } else if (name === 'weight') data[name] = value;
    setInitials(data);
  }

  const handleAddressChange = useCallback(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    (event: any, key: string) => {
      const address = getFormattedLocation(event.address_components);
      if (key === 'pickup') {
        setPickup({ ...address });
      } else if (key === 'destination') {
        setDestination({ ...address });
      }
      if (key === 'pickup') {
        setPickupPinCode(address.pincode);
      } else if (key === 'destination') {
        setDestinationPinCode(address.pincode);
      }
    },
    [initials],
  );

  const handleShipmentTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setInitials(initials => ({
      ...initials,
      weight: DefaultRatesInput.weight,
      weightUnit: DefaultRatesInput.weightUnit,
    }));
    setBoxes([]);
  };
  useEffect(() => {
    const sumOfBoxWeights = boxes.reduce((total, box) => total + Number(box.weight), 0.0);
    setInitials(initials => ({ ...initials, weight: sumOfBoxWeights }));
  }, [boxes]);
  return (
    <Root container item className="w-full">
      <Grid container xs={12} item className={`rates-row`}>
        <Grid container item xs={12} md={6}>
          <InputLabel className="mb-1" required>
            Pickup
          </InputLabel>
          <Grid container item gap={2}>
            <Grid item xs={12} md={5}>
              <GoogleMaps
                onSelect={event => handleAddressChange(event, 'pickup')}
                placeholder="Pick up Location"
                required
                value={Object.values(pickup).join(' ')}
              />
              <p>{Object.values(pickup).join(' ')}</p>
              {/* {isMobile && <AddressView details={pickup} />} */}
            </Grid>
            <Grid item xs={12} md={5} className={'mb-input'}>
              <SbTextField
                placeholder="Pick up Pincode"
                required
                value={pickupPinCode}
                onChange={handleChange}
                name="pickupPinCode"
                error={errors.pickupPinCode}
              />
            </Grid>
          </Grid>
          {/* <AddressView details={pickup} /> */}
        </Grid>
        <Grid container item xs={12} md={6}>
          <InputLabel className="mb-1" required>
            Destination
          </InputLabel>
          <Grid container item gap={2}>
            <Grid item xs={12} md={5}>
              <GoogleMaps
                onSelect={event => handleAddressChange(event, 'destination')}
                placeholder="Destination Location"
                required
                value={Object.values(destination).join(' ')}
              />
              <p>{Object.values(destination).join(' ')}</p>
              {/* {isMobile && <AddressView details={destination} />} */}
            </Grid>
            <Grid item xs={12} md={5} className={'mb-input'}>
              <SbTextField
                placeholder="Destination Pincode"
                value={destinationPinCode}
                onChange={handleChange}
                name="destinationPinCode"
                error={errors.destinationPinCode}
              />
            </Grid>
          </Grid>
          {/* <AddressView details={destination} /> */}
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <RadioButton
          label="Shipment Type"
          required
          containerClass="w-full pl-1/2"
          name="shipmentType"
          onChange={handleShipmentTypeChange}
          defaultValue={initials.shipmentType}
          value={initials.shipmentType}
          list={ShipmentTypeList}
          sx={{ display: 'flex', columnGap: 4 }}
        />
      </Grid>
      <Grid item xs={12} md={2.5}>
        <SbTextField
          type="number"
          placeholder="Weight"
          label="Weight"
          required
          value={initials.weight ? initials.weight.toString() : ''}
          onChange={handleChange}
          name="weight"
          error={errors.weight}
          endAdornment={
            <FormControl style={{ width: '50%' }}>
              <SelectOptions
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={initials.weightUnit}
                name="weightUnit"
                renderValue={value => <>{value}</>}
                onChange={handleChange}
              >
                {WEIGHT_UNITS?.map(({ display, value }: DropdownOptionType) => (
                  <MenuItem key={value} value={value}>
                    {display}
                  </MenuItem>
                ))}
              </SelectOptions>
            </FormControl>
          }
        />
        {initials.shipmentType === 'document' && (
          <FormHelperText className="m-0">{`For shipment type document weight should be less than 
                ${documentWeight[initials.weightUnit]} ${initials.weightUnit}`}</FormHelperText>
        )}
      </Grid>
      {/* <Grid container item columnSpacing={{ xs: 0, md: 12 }} className={`rates-row`}>
        <Grid container item xs={12} md={10} lg={8}>
          <Grid container item columnSpacing={{ xs: 0, md: 5 }} rowSpacing={{ sm: 2 }}>
          </Grid>
        </Grid>
      </Grid> */}
      {initials.shipmentType === 'parcel' && (
        <Grid container item alignItems={'flex-start'}>
          <Checkbox
            name="insurance"
            checked={!hideBoxes}
            onChange={() => setHideBoxes(!hideBoxes)}
            inputProps={{ 'aria-label': 'controlled' }}
            style={{ paddingLeft: 0 }}
          />
          <Grid item className="mt-1 mb-2">
            <InputLabel>Add Box-wise Details</InputLabel>
            <Typography className="xs-text">For volumetric weight</Typography>
          </Grid>
        </Grid>
      )}
    </Root>
  );
}

export default RatesInput;
