import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  ReverseDateWiseType,
  TrackingResponseObject,
  TrackShipmentDataType,
  TrackShipmentReducerState,
  TrackShipmentResponseType,
} from './type';

export function pending(state: TrackShipmentReducerState) {
  state.data = null;
  state.loading = true;
  state.error = '';
}

export function rejected(state: TrackShipmentReducerState) {
  state.loading = false;
  // const response = JSON.parse(action.payload.error.message);
  // if (response?.errorObj) {
  //   state.error = response?.errorObj?.msg;
  // } else if (response?.msg) {
  //   state.error = response?.msg;
  // } else if (response?.message) {
  //   state.error = response?.message;
  // } else {
  //   state.error = response || 'Oops! Something went wrong.';
  // }
}

export function reset(state: TrackShipmentReducerState) {
  state.loading = true;
  state.error = '';
}

function formatSingleTrackingObject(trackingData: TrackingResponseObject) {
  const statusArray: string[] = [];
  trackingData?.statusDetails?.length
    ? Array.from(trackingData?.statusDetails).map((i, ind) => {
        if (ind === 0 || trackingData?.statusDetails[ind - 1]?.status !== i.status) {
          statusArray.push(i.status);
        }
      })
    : [];
  return {
    ...trackingData,
    statusArray: statusArray.reverse(),
    reverseDateWise: trackingData?.statusDetails
      ? trackingData?.statusDetails?.reduce((result, statusDetail) => {
          const timeStamp = new Date(statusDetail.timeStamp).toLocaleDateString();
          const existingEntry: ReverseDateWiseType | undefined = result.find(entry => entry?.date === timeStamp);

          const details = { ...statusDetail, time: moment(statusDetail.timeStamp).format('hh:mm A') };
          if (existingEntry && existingEntry.details) {
            existingEntry.details.push(details);
          } else {
            result.push({
              date: timeStamp,
              formattedDate: moment(statusDetail.timeStamp).format('DD MMMM YYYY, dddd'),
              details: [details],
            });
          }
          return result;
        }, [] as ReverseDateWiseType[])
      : null,
  };
}

export function trackShipmentFulfilled(
  state: TrackShipmentReducerState,
  action: PayloadAction<TrackShipmentResponseType>,
) {
  const trackingData = action.payload.data?.length ? action.payload.data[0] : null;
  state.mutlipleShipmentsData = action.payload.data as TrackShipmentDataType[];
  state.loading = false;
  state.isSuccess = true;
  if (trackingData) {
    state.data = formatSingleTrackingObject(structuredClone(trackingData));
  }
}
export function getTrackShipmentFulfilled(
  state: TrackShipmentReducerState,
  action: PayloadAction<TrackShipmentResponseType>,
) {
  const trackingData = action.payload.data?.length ? action.payload.data[0] : null;
  state.getMutlipleShipmentsData = action.payload.data as TrackShipmentDataType[];
  state.loading = false;
  state.isSuccess = true;
  if (trackingData) {
    state.data = formatSingleTrackingObject(structuredClone(trackingData));
  }
}

export function trackMultipleShipmentsFulfilled(
  state: TrackShipmentReducerState,
  action: PayloadAction<TrackShipmentResponseType[]>,
) {
  state.loading = false;
  state.isSuccess = true;

  const response = action.payload?.length ? action.payload.filter(data => data.data?.length) : [];

  const obj: TrackShipmentReducerState['trackingDataByShipmentId'] = {};

  if (response.length) {
    response.forEach(data => {
      const trackingData = data.data[0];
      //Adding index for child boxes
      obj[Number(trackingData.shipmentNumber)] = formatSingleTrackingObject(structuredClone(trackingData));
    });
  }
  state.trackingDataByShipmentId = obj;
}
export function getTrackMultipleShipmentsFulfilled(
  state: TrackShipmentReducerState,
  action: PayloadAction<TrackShipmentResponseType[]>,
) {
  state.loading = false;
  state.isSuccess = true;

  const response = action.payload?.length ? action.payload.filter(data => data.data?.length) : [];

  const obj: TrackShipmentReducerState['getTrackingDataByShipmentId'] = {};

  if (response.length) {
    response.forEach(data => {
      const trackingData = data.data[0];
      //Adding index for child boxes
      obj[Number(trackingData.shipmentNumber)] = formatSingleTrackingObject(structuredClone(trackingData));
    });
  }
  state.trackingDataByShipmentId = obj;
}
