import React, { useEffect, useState } from 'react';
import { SBTable, SBTableWrapper } from '../../../../components/Table/Table';
import { Checkbox, FormGroup, Grid, IconButton, TableBody, TableFooter, TableHead, TableRow } from '@mui/material';
import { TableHeadCell, TableRowCell } from '../../../../components/Table/TableCell';
import { SBTableRow } from '../../../../components/Table/TableRow';
import SbButton from '../../../../components/Button';
import SbTextField from '../../../../components/Textfield';
import DeleteIcon from '@mui/icons-material/Delete';
import { KycSettingsType } from '../../../../types/organization';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../../store';
// import { isActionAllowed } from '../../../../utils/allowedActions';
// import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

// const TABLE_HEADER_COLS = ['KYC list', 'Mandatory'];

interface PropsType {
  settings: KycSettingsType;
  setList: (data: KycSettingsType) => void;
  disabled?: boolean;
}

function DocTable({ settings, setList, disabled }: PropsType) {
  const { documentList } = useSelector((state: RootStateType) => state.orgSetup);

  // const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  // const isAccountHolderWriteAllowed: boolean = isActionAllowed(
  //   accessGroupById?.actions,
  //   ACTION_NAMES.KYC_Settings.account_holder,
  //   PERFORM_ACTION.write,
  // );

  const { requiredDocExist, list } = settings;
  const [docName, setDocName] = useState<string>('');

  useEffect(() => {
    if (!requiredDocExist) {
      const tempData = list.map(data => ({ ...data, isRequired: false }));
      setList({ ...settings, list: [...tempData] });
    }
  }, [requiredDocExist]);

  function handleAddMore() {
    const tempData = [...list];
    if (!tempData.some(doc => docName === doc.label)) {
      tempData.push({ label: docName, isRequired: false });
      setList({ ...settings, list: [...tempData] });
      setDocName('');
    }
  }

  const handleDelete = (ind: number) => {
    const tempData = [...list];
    tempData.splice(ind, 1);
    setList({ ...settings, list: [...tempData] });
  };

  const handleCheckboxChange = (val: boolean, ind: number) => {
    const tempData = [...list];
    const tempObj = { ...tempData[ind] };
    tempObj.isRequired = val;
    tempData[ind] = tempObj;
    setList({ ...settings, list: [...tempData] });
  };

  return (
    <SBTableWrapper>
      <SBTable className="rounded-md">
        <TableHead>
          <TableRow>
            {/* {TABLE_HEADER_COLS.map(x => (
              <TableHeadCell key={x} align="left">
                {x}
              </TableHeadCell>
            ))} */}

            <TableHeadCell className="text-left">KYC List</TableHeadCell>
            <TableHeadCell>Mandatory</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((doc, ind) => (
            <SBTableRow key={ind}>
              <TableRowCell width={'62%'} align="left" className="md-text font-medium">
                {doc.label}
              </TableRowCell>
              <TableRowCell align="center" sx={{ px: 0 }}>
                <Grid container justifyContent="center">
                  <FormGroup classes={{ root: 'flex' }} style={{ width: '15px' }}>
                    <Checkbox
                      onChange={e => handleCheckboxChange(e.target.checked, ind)}
                      checked={doc.isRequired}
                      disabled={disabled || !requiredDocExist}
                    />
                  </FormGroup>
                  {!disabled && !documentList.some(docName => docName === doc.label) && (
                    <IconButton className="ml-2" onClick={() => handleDelete(ind)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Grid>
              </TableRowCell>
            </SBTableRow>
          ))}
        </TableBody>
        <TableFooter>
          <SBTableRow>
            <TableRowCell>
              <SbTextField
                className="px-2"
                placeholder="Enter"
                value={docName}
                onChange={e => setDocName(e.target.value)}
                style={{ minWidth: '230px' }}
                disabled={disabled}
              />
            </TableRowCell>
            <TableRowCell align="center">
              <SbButton variant="contained" disabled={!docName} onClick={handleAddMore} style={{ minWidth: '130px' }}>
                Add more
              </SbButton>
            </TableRowCell>
          </SBTableRow>
        </TableFooter>
      </SBTable>
    </SBTableWrapper>
  );
}

export default DocTable;
