import { createSlice } from '@reduxjs/toolkit';

import { OpenModal, initState, resetModal } from './case';
import { rootStore } from '@Store';

const slice = createSlice({
  name: 'successError',
  initialState: { ...initState },
  reducers: {
    SuccessErrorModalOpen: OpenModal,
    SuccessErrorModalReset: resetModal,
  },
  extraReducers: {},
});

const { SuccessErrorModalOpen, SuccessErrorModalReset } = slice.actions;

function DispatchSuccess(title: string, subTitle: string, navigateTo?: string) {
  setTimeout(function () {
    rootStore.dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title,
        subTitle,
        navigateTo,
      }),
    );
  });
}

function DispatchError(title: string, subTitle: string, navigateTo?: string) {
  setTimeout(function () {
    rootStore.dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title,
        subTitle,
        navigateTo,
      }),
    );
  });
}

export { SuccessErrorModalOpen, SuccessErrorModalReset, DispatchSuccess, DispatchError };
export default slice.reducer;
