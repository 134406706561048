export const ACTION_NAMES = {
  System: {
    create_system: 'Create System',
    view_pincode: 'View Pincode',
    download_pincode: 'Download Pincode',
    edit_system: 'Edit System',
    delete_System: 'Delete System',
  },
  Warehouse: {
    create_warehouse: 'Create Warehouse',
    edit_warehouse: 'Edit Warehouse',
    delete_warehouse: 'Delete Warehouse',
  },
  Carriers: {
    create_carriers: 'Create Carriers',
  },
  Services: {
    create_service: 'Create Service',
    edit_service: 'Edit Service',
    delete_service: 'Delete Service',
  },
  KYC_Settings: {
    account_holder: 'kyc settings - account holder',
    non_account_holder: 'kyc settings - non-account holder',
  },
  Invoice_Settings: {
    billing: 'Tax information',
    t_and_c: 'T&C',
    bank_details: 'Bank Details',
  },
  Organization_Settings: {
    General: 'General',
    t_and_c: 'T&C',
  },
  Shipment_Settings: {
    edit_shipment: 'edit shipment',
  },
  Notification_Settings: 'Notification Settings',
  User_Settings: {
    Saved_Address: 'Saved Address',
  },
  User_Details: {
    Create_Account: 'Create Account',
    edit_user: 'Edit User',
    deactivate_user: 'Deactivate User',
    suspend_user: 'Suspend User',
    restore_account: 'Restore Account',
    view_contract: 'View Contract',
    delete_contract: 'Delete Contract',
    service_settings: 'Service Settings',
    view_kyc: 'View KYC',
    delete_kyc: 'Delete KYC',
    upload_kyc: 'Upload KYC',
    verify_kyc: 'Verify KYC',
    create_account_holder: 'create account holder',
    download_report: 'download user report',
  },
  Admin_Details: {
    create_admin: 'Create Admin',
    edit_admin: 'Edit Admin',
    deactive_admin: 'Deactive Admin',
    suspend_admin: 'Suspend Admin',
    restore_admin: 'Restore Admin',
    download_report: 'download admin report',
  },
  Shipment_Details: {
    create_single_shipment: 'Create Single Shipment',
    create_bulk_shipments: 'Create Bulk Shipments',
    get_rates: 'Get Rates',
    bulk_status_upload: 'Bulk Status Upload',
    export_reports: 'Export Reports',
    log_history: 'Log History',
    change_status: 'Change Status',
    download_carrier_awb: 'Download carrier AWB',
    download_shipment_label: 'Download Shipment label',
    download_proforma_invoice: 'Download Proforma Invoice',
    view_docs: 'View Docs',
    repeat_order: 'Repeat Order',
    shipment_report_detailed: 'shipment report - detailed',
    shipment_report_summary: 'shipment report - summary',
    sales_report_detailed: 'sales report - detailed',
    sales_report_summary: 'sales report - summary',
    operation_report_detailed: 'operation report - detailed',
    operation_report_summary: 'operation report - summary',
    customer_service_report_detailed: 'customer service report - detailed',
    customer_service_report_summary: 'customer service report - summary',
    accounts_report_detailed: 'accounts report - detailed',
    accounts_report_summary: 'accounts report - summary',
  },
  Draft_Shipments: {
    unprocessed_shipments: 'unprocessed shipments',
    saved_shipments: 'saved shipments',
  },
  Manifest: {
    create_manifest: 'create manifest',
    edit_manifest: 'edit manifest',
    delete_manifest: 'delete manifest',
    download_manifest: 'download manifest',
    download_shipping_label: 'download shipping label',
  },
  Single_Shipment_View_OR_Processing: {
    cancel_shipment: 'Cancel Shipment',
    repeat_shipment: 'Repeat Shipment',
    shipper_or_consignee_details: 'Shipper/ Consignee Details',
    pick_up_or_drop_off: 'Pick up / Drop off',
    shipment_details: 'Shipment Details',
    shipment_processing: 'Shipment Processing',
    assign_pick_up_or_delivery: 'Assign Pick up/ Delivery',
    shipment_notes: 'Shipment Notes',
    tracking_details: 'Tracking Details',
    shipment_charges: 'Shipment Charges',
    selling_charges: 'Selling Charges',
    buying_charges: 'Buying Charges',
    lock_rates: 'Lock rates',
    unlock_rates: 'Unlock rates',
    global_charges: 'global charges',
    payment: 'Payment',
  },
  Base_Pricing: {
    add_rates: 'Add base rates',
    download_report: 'download base  report',
    view_rate_sheet: 'view base rate sheet',
    delete_pricing: 'Delete base Pricing',
    edit_pricing: 'Edit base Pricing',
    download_pricing: 'Download Pricing',
  },
  Tariff_Pricing: {
    add_rates: 'add tariff rates',
    download_report: 'download tariff report',
    view_rate_sheet: 'View tariff Rate Sheet',
    delete_pricing: 'Delete tariff Pricing',
    edit_pricing: 'Edit tariff Pricing',
  },
  Contract_Pricing: {
    add_rates: 'Add contract rates',
    download_report: 'download contract report',
    view_rate_sheet: 'View contract Rate Sheet',
    delete_pricing: 'Delete contract Pricing',
    edit_pricing: 'Edit contract Pricing',
  },
  Oda_Charges: {
    edit_ODA: 'edit ODA',
  },
  Global_charges: {
    edit_global_charges: 'edit global charges',
  },
  View_Invoices_Account_holder: {
    upload_invoices: 'Upload Invoices',
    download_report: 'Download Report',
    create_invoices: 'Create Invoices',
    create_debit_note: 'Create Debit Note',
    create_credit_note: 'Create Credit Note',
    upload_payment: 'Upload Payment',
    download_invoices: 'Download Invoices',
    void_invoices: 'Void Invoices',
    download_report_detailed: 'download report detailed',
    download_report_summary: 'download report summary',
  },
  View_Invoices_Non_Account_holder: {
    upload_invoices: 'upload non account holder invoices',
    download_report: 'Download non account holder Report',
    create_invoices: 'Create non account holder Invoices',
    create_debit_note: 'Create non account holder Debit Note',
    create_credit_note: 'Create non account holder Credit Note',
    upload_payment: 'Upload non account holder Payment',
    download_invoices: 'Download non account holder Invoices',
    void_invoices: 'Void non account holder Invoices',
    download_non_account_holder_report_detailed: 'download non account holder report detailed',
    download_non_account_holder_report_summary: 'download non account holder report summary',
  },
  Transactions: {
    export_transactions: 'export transactions',
  },
  Credits_And_Wallet: {
    export_data: 'export data',
    add_money: 'add money',
    update_limit: 'update limit',
  },
  Squad_Management: {},
  Payments_And_Transactions: {},
  Access_Groups: {
    Save_Template: 'Saved Template',
    Create_Template: 'Create Template',
    Draft_Template: 'Default Template',
  },
  home: {
    view_rates: 'view rates',
    edit_profile: 'edit profile',
  },
};

// User Management

// User
// Download Report - Edit

// Admin
// Download Report - Edit

// Shipment

// Shipment Reports - Detailed
// Shipment Reports -  Summary
// Sales Reports - Detailed
// Sales Reports -  Summary
// Operation Reports - Detailed
// Operations Reports -  Summary
// Customer Service Reports - Detailed
// Customer Service Reports -  Summary
// Accounts Reports - Detailed
// Account  Reports -  Summary

// Invoices

// Invoice Reports -  Summary
// Invoice Reports -  Detailed

export const PERFORM_ACTION = {
  read: 'r',
  write: 'w',
};

export const ActionsPathLists = [
  {
    action_name: 'Create System',
    path: '/system/create',
  },
  {
    action_name: 'Edit System',
    path: '/system/edit',
  },
  {
    action_name: 'View Pincode',
    path: '/system/pincode/edit',
  },
  {
    action_name: 'Create Warehouse',
    path: '/warehouse/create',
  },
  {
    action_name: 'Edit Warehouse',
    path: '/warehouse/edit/',
  },
  {
    action_name: 'Create Carriers',
    path: '/carrier-and-services/create/carrier',
  },
  {
    action_name: 'Create Service',
    path: '/carrier-and-services/create/service',
  },
  {
    action_name: 'Edit Service',
    path: '/carrier-and-services/edit/service',
  },
  // -----------------------------------
  {
    action_name: 'Add tariff rates',
    path: '/pricing/tariff/create',
  },
  {
    action_name: 'Edit tariff Pricing',
    path: '/pricing/tariff/edit',
  },
  {
    action_name: 'Add base rates',
    path: '/pricing/base/create',
  },
  {
    action_name: 'Edit base Pricing',
    path: '/pricing/base/edit',
  },
  {
    action_name: 'Add contract rates',
    path: '/pricing/contract/create',
  },
  {
    action_name: 'Edit contract Pricing',
    path: '/pricing/contract/edit',
  },
  // -----------------------------------------
  {
    action_name: 'Create Account',
    path: '/user/create-account',
  },
  {
    action_name: 'Edit User',
    path: '/user/edit',
  },
  {
    action_name: 'Create Admin',
    path: '/admin/create',
  },
  {
    action_name: 'Edit Admin',
    path: '/admin/edit',
  },
  {
    action_name: 'Edit Admin',
    path: '/admin/edit',
  },
];
