import { DropdownOptionType } from '@Components/Dropdown/Type';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ChargeFormType, GlobalChargeFormType } from '../pages/GlobalCharge/Create/type';
import { chargesSchema } from '../pages/GlobalCharge/schema';
const defaultAvailableApplyOn = [{ display: 'Base', value: 'base' }];
const defaultSelectedChargeState: ChargeFormType = {
  chargeName: '',
  selectedApplyOn: [],
};

interface PropsType {
  charges: GlobalChargeFormType['charges'];
  updateCharges: (i: GlobalChargeFormType['charges']) => void;
}

export function useChargesTable(props: PropsType) {
  const { charges, updateCharges } = props;

  const [showDependencyModal, setShowDependencyModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<{ index: number; open: boolean } | null>(null);
  const [availableApplyOn, setAvailableApplyOn] = useState<DropdownOptionType[]>([...defaultAvailableApplyOn]);

  const chargeFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...defaultSelectedChargeState },
    validationSchema: chargesSchema,
    onSubmit(values) {
      onSelectedChargeSubmit(values);
    },
  });

  useEffect(() => {
    if (charges) {
      const _availableApplyOn = getPreviousCharges(charges.length);
      setAvailableApplyOn(_availableApplyOn);
    }
  }, [charges]);

  const onSelectedChargeSubmit = (params: ChargeFormType) => {
    const temp: ChargeFormType[] = JSON.parse(JSON.stringify(charges));
    const selectedCharge: ChargeFormType = { ...params };
    const selectedIndex = temp.findIndex(i => i?._id === params?._id);
    if (selectedIndex >= 0) {
      temp[selectedIndex] = selectedCharge;
    } else {
      temp.push({ ...selectedCharge, _id: Date.now().toString() });
    }
    updateCharges(temp);
    chargeFormik.resetForm();
  };

  function getPreviousCharges(lastIndex: number): DropdownOptionType[] {
    const previousCharges = charges.slice(0, lastIndex).map(c => ({ display: c.chargeName, value: c.chargeName }));
    return [...defaultAvailableApplyOn, ...previousCharges];
  }

  function handleAvailableApplyOn(index: number) {
    const _availableApplyOn = getPreviousCharges(index);
    console.log({ _availableApplyOn });
    setAvailableApplyOn(_availableApplyOn);
  }

  function handleDeleteCharge(index: number, chargeName: string) {
    const hasDependency =
      charges?.length && charges.some((i: ChargeFormType) => i.selectedApplyOn.includes(chargeName));
    console.log({ hasDependency });

    if (hasDependency) {
      setShowDependencyModal(true);
    } else {
      setDeleteModal({ index: index, open: true });
    }
  }

  const onCloseDependencyModal = () => {
    setShowDependencyModal(false);
  };

  const onCloseDeleteModal = () => {
    setDeleteModal(null);
  };

  const onConfirmDeleteCharge = () => {
    if (deleteModal && deleteModal?.index > -1) {
      const temp: ChargeFormType[] = JSON.parse(JSON.stringify(charges));
      temp.splice(deleteModal.index, 1);
      updateCharges(temp);
      setDeleteModal(null);
    }
  };

  return {
    showDependencyModal,
    onCloseDependencyModal,
    deleteModal,
    handleDeleteCharge,
    onCloseDeleteModal,
    onConfirmDeleteCharge,
    chargeFormik,
    availableApplyOn,
    handleAvailableApplyOn,
  };
}
