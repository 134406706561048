import { Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import SbImage from '../../../components/Image';
import SbButton from '../../../components/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { Steps } from '../constants';

const Root = styled(Grid)(({ theme }) => ({
  background: "url('/images/login/Group.png')",
  backgroundPositionX: '220%',
  backgroundSize: '72%',
  backgroundRepeatX: 'no-repeat',
  flex: 1,
  backgroundRepeat: 'no-repeat',
  height: '100%',
  borderRadius: '12pxx',
  padding: '5%',
  [theme.breakpoints.down('md')]: {
    background: 'transparent',
  },
}));

function OrganizationWelcome() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(Steps.System);
  };

  return (
    <Root>
      <Grid container alignItems={'center'} className="h-full">
        <Grid item>
          <Typography sx={{ fontSize: { md: '30px', xs: '20px' } }} className="font-semibold mb-2">
            Welcome to
          </Typography>
          <SbImage
            src="/images/speedbox_logo.png"
            alt="logo"
            style={{ marginBottom: '20px' }}
            height={isMobile ? 40 : 50}
          />
          <Typography sx={{ fontSize: { md: '30px', xs: '18px' }, maxWidth: '70%' }} className="font-light">
            Your Journey to get your business online
          </Typography>
          <Typography
            sx={{ fontSize: { md: '30px', xs: '18px' }, maxWidth: '70%', marginBottom: { md: '30px', xs: '20px' } }}
            className="font-bold mt-2"
          >
            Begins now!
          </Typography>
          <SbButton variant="contained" endIcon={<KeyboardArrowRightIcon />} onClick={handleClick}>
            Lets start!
          </SbButton>
        </Grid>
      </Grid>
    </Root>
  );
}

export default OrganizationWelcome;
