import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { FileResultType } from '../../../Helper/file';
import { BulkPaymentType, BulkStatusType } from './types';

function formatStatus(status: string) {
  const s = status.toLowerCase().split(' ');
  return s.length > 1 ? s.join('_') : s[0];
}

// Function to convert Excel date serial number to MM/DD/YYYY format
function excelDateToJSDate(serial: number) {
  const utc_days = Math.floor(serial - 25569);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  const year = date_info.getFullYear();
  const month = ('0' + (date_info.getMonth() + 1)).slice(-2);
  const day = ('0' + date_info.getDate()).slice(-2);

  return `${day}/${month}/${year}`;
}

export function readXslAndGetBulkStatusUploadObject(data: FileResultType[]) {
  const [{ fileData }] = data;
  if (!(fileData instanceof Array)) return;

  const {
    data: [headers, ...body],
  } = fileData[0] as { data: string[][] };
  const shipmentNumberIndex = headers.findIndex(x => x && x.match(/shipment number/i));
  const statusIndex = headers.findIndex(x => x && x.match(/status/i));
  const messageIndex = headers.findIndex(x => x && x.match(/message/i));
  const locationIndex = headers.findIndex(x => x && x.match(/location/i));

  if (shipmentNumberIndex === -1) throw { msg: 'Shipment number column not found in xlsx' };
  else if (!body.length) throw { msg: 'At least one shipment is required' };
  else if (statusIndex === -1) throw { msg: 'Status column not found in xlsx' };
  else if (messageIndex === -1) throw { msg: 'Message column not found in xlsx' };

  const validShipmentStatus = Object.keys(SHIPMENT_STATUS).filter(x => !['placed', 'cancelled'].includes(x));

  const formattedBody: string[][] = body
    .filter(data => data[shipmentNumberIndex])
    .map(data => {
      if (!data[statusIndex]) throw { msg: `Status not found for shipment ${data[shipmentNumberIndex]}` };
      // else if (!data[messageIndex]) throw { msg: `Message not found for shipment ${data[shipmentNumberIndex]}` };

      const status = formatStatus(data[statusIndex]);
      if (!validShipmentStatus.includes(status)) {
        throw {
          msg: `Please choose another status to update for shipment: ${String(
            data[shipmentNumberIndex],
          )}, valid status are: ${validShipmentStatus.join(', ')}`,
        };
      }
      data[statusIndex] = status;
      return data;
    });

  const bulkData: BulkStatusType[] = formattedBody.map(arr => ({
    shipmentNumber: String(arr[shipmentNumberIndex]),
    status: arr[statusIndex],
    msg: arr[messageIndex] || SHIPMENT_STATUS[arr[statusIndex]].msg,
    location: arr[locationIndex],
  }));
  return bulkData;
}

export function readXslAndGetBulkPaymentUploadObject(data: FileResultType[]) {
  const [{ fileData }] = data;
  if (!(fileData instanceof Array)) return;

  const {
    data: [headers, ...body],
  } = fileData[0] as { data: string[][] };

  // Indexes of required columns
  const invoiceNumberIndex = headers.findIndex(x => x && x.match(/invoice number/i));
  const amountPaidIndex = headers.findIndex(x => x && x.match(/amount paid/i));
  const tdsIndex = headers.findIndex(x => x && x.match(/tds/i));
  const modeOfPaymentIndex = headers.findIndex(x => x && x.match(/mode of payment/i));
  const commentIndex = headers.findIndex(x => x && x.match(/comment/i));
  const paymentDateIndex = headers.findIndex(x => x && x.match(/payment date/i));
  // const creditNoteAmountIndex = headers.findIndex(x => x && x.match(/credit note amount/i));
  // const accountNumberIndex = headers.findIndex(x => x && x.match(/account number/i));

  // Validation checks
  if (invoiceNumberIndex === -1) throw { msg: 'Invoice number column not found in xlsx' };
  else if (!body.length) throw { msg: 'At least one invoice is required' };
  else if (amountPaidIndex === -1) throw { msg: 'Amount paid column not found in xlsx' };
  else if (tdsIndex === -1) throw { msg: 'TDS column not found in xlsx' };
  else if (modeOfPaymentIndex === -1) throw { msg: 'Mode of payment column not found in xlsx' };
  else if (commentIndex === -1) throw { msg: 'Comment column not found in xlsx' };
  else if (paymentDateIndex === -1) throw { msg: 'Payment date column not found in xlsx' };
  // else if (creditNoteAmountIndex === -1) throw { msg: 'Credit note amount column not found in xlsx' };
  // else if (accountNumberIndex === -1) throw { msg: 'Account number column not found in xlsx' };

  // Formatting payment data
  const formattedBody: string[][] = body
    .filter(data => data[invoiceNumberIndex])
    .map(data => {
      if (!data[amountPaidIndex]) throw { msg: `Amount paid not found for invoice ${data[invoiceNumberIndex]}` };
      // if (!data[tdsIndex]) throw { msg: `TDS not found for invoice ${data[invoiceNumberIndex]}` };
      if (!data[modeOfPaymentIndex]) throw { msg: `Mode of payment not found for invoice ${data[invoiceNumberIndex]}` };
      if (!data[commentIndex]) throw { msg: `Comment not found for invoice ${data[invoiceNumberIndex]}` };
      if (!data[paymentDateIndex]) throw { msg: `Payment date not found for invoice ${data[invoiceNumberIndex]}` };
      // if (!data[creditNoteAmountIndex])
      //   throw { msg: `Credit note amount not found for invoice ${data[invoiceNumberIndex]}` };
      // if (!data[accountNumberIndex]) throw { msg: `Account number not found for invoice ${data[invoiceNumberIndex]}` };

      // Convert the Excel serial number to date string if necessary
      const paymentDate =
        typeof data[paymentDateIndex] === 'number'
          ? excelDateToJSDate(data[paymentDateIndex] as any)
          : data[paymentDateIndex];

      // Normalize the mode of payment value
      const paymentMode = data[modeOfPaymentIndex].toLowerCase().replace(/\s+/g, '');

      return {
        ...data,
        [paymentDateIndex]: paymentDate,
        [modeOfPaymentIndex]: paymentMode,
      };
    });

  const bulkData: BulkPaymentType[] = formattedBody.map(arr => ({
    invoiceNumber: String(arr[invoiceNumberIndex]),
    amount: parseFloat(arr[amountPaidIndex]),
    tdsAmount: parseFloat(arr[tdsIndex]) || 0,
    paymentMode: arr[modeOfPaymentIndex].toLowerCase(),
    comment: arr[commentIndex],
    paymentDate: arr[paymentDateIndex],
    // creditNoteAmount: parseFloat(arr[creditNoteAmountIndex]),
    // accountNumber: arr[accountNumberIndex],
  }));

  return bulkData;
}
