import SbTextField from '@Components/Textfield';
import Title from '@Components/Title';
import { FormLabel, Grid, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { addAdminShipmentNotes, getAdminShipmentNotes } from '@Reducers/AdminShipment';
import { ShipmentNotesType } from '@Reducers/AdminShipment/Type';

import { useParams } from 'react-router-dom';
import moment from 'moment';
import SearchDropdown from '@Components/SearchDropdown';
import { getAdminListForTag } from '@Reducers/Admin/actions';
import { OptionType } from '@Components/SearchDropdown/Type';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { ShipmentControl } from '@Services/shipmentActionControl';

function ShipmentNote() {
  const { sn: shipmentNumber } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const AdminList = useSelector((state: RootStateType) => state.admin.adminListForTag).map(admin => ({
    display: admin.username,
    value: admin._id,
  }));
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const shipmentNotes = useSelector((state: RootStateType) => state.adminShipment.shipmentNotes);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentNotesWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_notes,
    PERFORM_ACTION.write,
  );

  const [taggedUsers, setTaggedUsers] = useState<OptionType[]>([]);

  useEffect(() => {
    dispatch(getAdminListForTag());
  }, []);
  useEffect(() => {
    if (shipmentNumber) dispatch(getAdminShipmentNotes(shipmentNumber));
  }, [shipmentNumber]);
  return (
    <>
      <Grid container mb={2}>
        <Title title="Shipment Notes" removeBorder removePadding />
      </Grid>
      <Grid container rowGap={3} className="p-4 mb-3 border border-solid border-light rounded">
        <Grid container item xs={12} alignItems="baseline">
          <Typography className="md-text sb-text-black">User Notes :</Typography>
          <Typography className="sm-text sb-text-gray ml-1">{shipmentById?.shipmentNotes || '-'}</Typography>
        </Grid>
        <Grid container>
          <Grid container item md={12} lg={5} mb={2}>
            <FormLabel>Admin Notes</FormLabel>
            <SearchDropdown
              label=""
              multiple
              id="company-country-id"
              placeholder="Tag Admin"
              options={AdminList}
              value={taggedUsers}
              hideStartAdornment
              onChange={value => {
                if (value) setTaggedUsers(value);
              }}
              disabled={!isShipmentNotesWriteAllowed}
            />
          </Grid>
          <Grid container item justifyContent="space-between" rowGap={3}>
            <Grid container item md={12} lg={5}>
              <AddNote
                taggedUsers={taggedUsers}
                resetUsers={() => setTaggedUsers([])}
                disabled={!isShipmentNotesWriteAllowed}
              />
            </Grid>
            <Grid container item md={12} lg={6.8} rowGap={2} overflow="auto" height={200} alignItems="baseline">
              {shipmentNotes?.map(note => (
                <SingleNote data={note} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const SendNoteBtn = styled(SendIcon)(() => ({
  position: 'absolute',
  bottom: '16px',
  right: '16px',
}));

function AddNote({
  taggedUsers,
  resetUsers,
  disabled,
}: {
  taggedUsers: OptionType[];
  resetUsers: () => void;
  disabled?: boolean;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const { sn: shipmentNumber } = useParams();
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const shipmentActionControl = new ShipmentControl(shipmentById);
  const [note, setNote] = useState<string>('');
  const onAddNote = async () => {
    if (note.trim() && shipmentNumber) {
      await dispatch(
        addAdminShipmentNotes({
          id: shipmentNumber,
          message: note.trim(),
          _taggedUserId: taggedUsers.map(user => user.value),
        }),
      );
      setNote('');
      resetUsers();
    }
  };
  return (
    <Grid container>
      <SbTextField
        className="w-full"
        // label="Admin Notes"
        value={note}
        onChange={e => setNote(e.target.value)}
        multiline
        rows={8}
        endAdornment={
          shipmentActionControl.canUpdateNotes ? <SendNoteBtn className="cursor-pointer" onClick={onAddNote} /> : <></>
        }
        disabled={disabled}
      />
    </Grid>
  );
}

const SingleNoteContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.note,
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

interface SingleNoteProps {
  data: ShipmentNotesType;
}

function SingleNote(props: SingleNoteProps) {
  const { message, _userId, createdAt, _taggedUserId } = props.data;
  const date = moment(createdAt).format('DD MMM YYYY');
  const time = moment(createdAt).format('hh:mm');
  return (
    <SingleNoteContainer container className="rounded">
      <Grid xs={12}>
        <Typography className="font-medium sm-text sb-text-gray">
          {_userId.name} <time className="font-normal xs-text ">{time}</time>
        </Typography>
      </Grid>
      <Grid xs={12}>
        <Typography display="block" my={0.5} className="xs-text" sx={{ color: '#7D878C', whiteSpace: 'pre-line' }}>
          {message}
          {_taggedUserId?.map(user => (
            <span className="color-p-main">{` @${user.username}`}</span>
          ))}
        </Typography>
      </Grid>

      <Grid container justifyContent="space-between">
        <Typography className="font-medium sm-text sb-text-gray">{_userId.email}</Typography>
        <time className="font-medium xs-text sb-text-gray">{date}</time>
      </Grid>
    </SingleNoteContainer>
  );
}

export default ShipmentNote;
