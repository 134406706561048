import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid, Tab, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppDispatch, RootStateType } from '../../../store';
import { TabContext, TabList } from '@mui/lab';
import TaxInformationMain from './TaxInformationTab';
import BankDetails from './BankDetails';
import TermsAndConditions from './TermsAndConditions';
import Actions from '../Actions';
import {
  ApplyToAllSystemType,
  OrganizationSetupContextType,
  SacCodeType,
  SettingsTabsPropsType,
  TaxInformationType,
  TermsAndConditionsType,
} from '../../../types/organization';
import { saveInvoiceSettings, getInvoiceSettings, resetSaveInvoiceSettings } from '@Reducers/OrganizationSetup';
import { getActiveSystemList } from '@Reducers/Systems';
import { termsAndConditionsDefaultData } from '../constants';
import { OutletBox } from '../Style';
import { getFormattedKey } from '../../../services/getFormattedKey';
import Loader from '@Components/Loader';
import { InvoiceSettingsPayloadType } from '../../../types/organization';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';
import ErrorModal from '@Components/Modal/error';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

type TabValues = 'tax_information' | 'terms_and_conditions' | 'bank_details' | string;

interface TabAccessKeyType {
  isCompanyInfoShow: boolean;
}

type TabAccessKey = keyof TabAccessKeyType;

const TABS: { label: string; value: TabValues; accessKey?: TabAccessKey }[] = [
  // { label: 'Tax information', value: 'tax_information' },
  { label: 'Billing', value: 'billing' },
  { label: 'Terms and conditions', value: 'terms_and_conditions' },
  { label: 'Bank Details', value: 'bank_details' },
];

const TAB_KEYS: TabValues[] = TABS.map(x => x.value);

const ProfileContainerStyle = styled(Grid)(({ theme: _ }) => ({
  background: '#fff',
  width: '100%',
  // maxHeight: '100vh',
}));

const TitleContentStyle = styled(Grid)(({ theme: _ }) => ({
  marginBottom: '24px',
}));

function InvoiceSettingTabs({ handleNext, handleBack, activeStep, viewFor }: SettingsTabsPropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigate();
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const { saveInvoiceSuccess, invoiceSetting, taxList, loading } = useSelector(
    (state: RootStateType) => state?.orgSetup,
  );

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isBillingReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.billing,
    PERFORM_ACTION.read,
  );
  const isTCReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.t_and_c,
    PERFORM_ACTION.read,
  );
  const isBankDetailsReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.bank_details,
    PERFORM_ACTION.read,
  );

  const isBillingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.billing,
    PERFORM_ACTION.write,
  );

  const isTCWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.t_and_c,
    PERFORM_ACTION.write,
  );
  const isBankDetailsWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.bank_details,
    PERFORM_ACTION.write,
  );

  // const [currentTap, setCurrentTap] = useState<TabValues>('tax_information');
  const [currentTap, setCurrentTap] = useState<TabValues>('billing');
  const accountState = useSelector((state: RootStateType) => state.loginUser.profile?._accountId);

  const [taxInformation, setTaxInformation] = useState<TaxInformationType[]>([]);
  const [sacCode, setSacCode] = useState<SacCodeType[]>([]);
  const [generateInvoices, setGenerateInvoices] = useState<string[]>([]);
  const [bankDetails, setBankDetails] = useState<OrganizationSetupContextType['bankDetails'][]>([]);
  const [selectedBank, setSelectedBank] = useState<OrganizationSetupContextType['bankDetails']>();
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditionsType>(termsAndConditionsDefaultData);
  const [_applyToAllSystem, setApplyToAllSystem] = useState<ApplyToAllSystemType>({
    apply: false,
    defaultSystemBank: null,
  });
  const [error, showError] = useState<string | null>();

  useEffect(() => {
    const list: TaxInformationType[] = [];
    taxList.map(i => {
      list.push({
        display: i,
        key: getFormattedKey(i),
        value: '',
      });
    });
    setTaxInformation([...taxInformation, ...list]);
  }, [taxList]);

  useEffect(() => {
    // const currentActiveTab: TabValues = searchParams.get('tab') || 'tax_information';
    const currentActiveTab: TabValues = searchParams.get('tab') || 'billing';
    if (TAB_KEYS.includes(currentActiveTab)) setCurrentTap(currentActiveTab);
    dispatch(getInvoiceSettings());
    dispatch(getActiveSystemList());
  }, []);

  useEffect(() => {
    if (saveInvoiceSuccess) {
      if (viewFor === 'org-setup' && handleNext && activeStep) handleNext(activeStep);
      else if (viewFor === 'settings') dispatch(getInvoiceSettings());
      dispatch(resetSaveInvoiceSettings());
    }
  }, [saveInvoiceSuccess]);

  useEffect(() => {
    if (invoiceSetting) {
      if (invoiceSetting.bankDetails?.length === 1 && invoiceSetting.bankDetails[0].applyToAllSystem) {
        setApplyToAllSystem({ apply: true, defaultSystemBank: invoiceSetting.bankDetails[0] });
      }
      if (invoiceSetting.bankDetails) {
        // const bankInfo = invoiceSetting.bankDetails[0];
        setBankDetails(invoiceSetting.bankDetails);
      }
      if (invoiceSetting.taxInformation) setTaxInformation(invoiceSetting.taxInformation);
      if (invoiceSetting.sacCode) setSacCode(invoiceSetting.sacCode);
      if (invoiceSetting.termAndCondition) setTermsAndConditions(invoiceSetting.termAndCondition);
      if (invoiceSetting.status) setGenerateInvoices(invoiceSetting.status);
    }
  }, [invoiceSetting]);

  useEffect(() => {
    if (invoiceSetting?.bankDetails?.length && !selectedBank) setSelectedBank(invoiceSetting.bankDetails[0]);
  }, [invoiceSetting?.bankDetails, selectedBank]);

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    navigation(`?tab=${currentTab}`);
    setCurrentTap(currentTab);
    showError(null);
  };
  function handleSubmit(applyToAllSystem?: ApplyToAllSystemType) {
    showError(null);
    const generateInvoicesValue = generateInvoices?.map(str => str.toUpperCase());
    const payloadObj = {
      showModal: !handleNext,
      payload: {
        taxInformation: { taxInformation, sacCode },
        termAndCondition: termsAndConditions,
        bankDetails: {},
        // status: generateInvoices,
        status: generateInvoicesValue,
      },
    };
    if (applyToAllSystem?.apply) {
      payloadObj.payload.bankDetails = { data: [applyToAllSystem.defaultSystemBank], applyToAllSystem: true };
    } else {
      const bankDetailsError = bankDetails.find(data => {
        const { accountName, accountNumber, bankName, accountType, systemId } = data;
        return !systemId || !accountNumber || !accountName || !bankName || !accountType;
      });

      const ssytemerror = bankDetails
        .filter(detail => detail.accountNumber === '' || detail.accountName === '' || detail.bankName === '')
        .map(detail => detail.system.name)
        .join(', ');

      if (bankDetailsError) {
        setSelectedBank(bankDetailsError);

        if (currentTap === 'bank_details') {
          showError(
            // `Please enter Account Number, Account Name, Bank Name, Account Type details. `,
            `Please enter Account Number, Account Name, Bank Name, Account Type details in system ${ssytemerror} . `,
          );
        }
        return;
      }
      const { proformaInvoice, shipmentInvoice, taxInvoice } = termsAndConditions;
      if (!proformaInvoice || !shipmentInvoice || !taxInvoice) {
        showError('Please enter Proforma Invoice, Shipment Invoice and Tax Invoice details.');
        return;
      }
      showError(null);
      payloadObj.payload.bankDetails = { data: bankDetails, applyToAllSystem: false };
    }
    if (currentTap === 'bank_details') {
      dispatch(
        saveInvoiceSettings(payloadObj as { payload: InvoiceSettingsPayloadType } & SuccessErrorModalParamsType),
      );
    }
  }
  const handleApplyToAllSystem = () => {
    showError(null);
    if (selectedBank) {
      const { accountName, accountNumber, bankName, accountType, systemId } = selectedBank;
      const bankDetailsError = !systemId || !accountNumber || !accountName || !bankName || !accountType;
      if (bankDetailsError) {
        setSelectedBank(selectedBank);
        showError('Please enter Account Number, Account Name, Bank Name, Account Type details.');
        return;
      }
      showError(null);
      setApplyToAllSystem({ apply: true, defaultSystemBank: selectedBank });
      handleSubmit({ apply: true, defaultSystemBank: selectedBank });
    }
  };
  const tabAccessKey: TabAccessKeyType = { isCompanyInfoShow: !accountState?.isDefaultAccount };

  const closeErrorModal = () => showError(null);

  return (
    <Loader loading={loading} overly>
      <>
        <OutletBox className="border-grey rounded-md" removeBorder={viewFor === 'settings'}>
          <ProfileContainerStyle container alignContent="flex-start">
            <TitleContentStyle>
              <Typography className="font-semibold l-text mb-1">Invoice settings</Typography>
              <Typography className="d-block md-text">Please update below details required for invoicing.</Typography>
            </TitleContentStyle>
            <TabContext value={currentTap}>
              <Grid
                container
                sx={{ borderBottom: `1px solid ${theme.palette.border.grey}`, marginBottom: theme.spacing(6) }}
              >
                <TabList
                  onChange={onTabChange}
                  indicatorColor="primary"
                  TabIndicatorProps={{ sx: { height: '4px' } }}
                  scrollButtons="auto"
                  variant="scrollable"
                >
                  {TABS.filter(
                    cTab =>
                      (cTab.accessKey ? tabAccessKey[cTab.accessKey] : true) &&
                      ((cTab.value === 'billing' && isBillingReadAllowed) ||
                        (cTab.value === 'terms_and_conditions' && isTCReadAllowed) ||
                        (cTab.value === 'bank_details' && isBankDetailsReadAllowed)),
                  ).map(cTab => (
                    // <Link to={`/${cTab.value}`}  >
                    <Tab
                      sx={{ textTransform: 'none', padding: '16px 16px' }}
                      className="md-text font-medium sb-text-black"
                      value={cTab.value}
                      label={cTab.label}
                      key={cTab.value}
                    />
                    // </Link>
                  ))}
                </TabList>
              </Grid>
              {isBillingReadAllowed && (
                <TaxInformationMain
                  taxInformation={taxInformation}
                  onChangeTaxInformation={setTaxInformation}
                  sacCode={sacCode}
                  onChangeSacCode={setSacCode}
                  generateInvoices={generateInvoices}
                  onchangeGenerateInvoices={setGenerateInvoices}
                />
              )}
              {isBankDetailsReadAllowed && (
                <BankDetails
                  data={bankDetails}
                  onChange={setBankDetails}
                  // applyToAllSystem={applyToAllSystem}
                  // setApplyToAllSystem={setApplyToAllSystem}
                  selectedBank={selectedBank}
                  setSelectedBank={setSelectedBank}
                />
              )}
              {isTCReadAllowed && <TermsAndConditions data={termsAndConditions} onChange={setTermsAndConditions} />}
            </TabContext>
            {/* {error && <FormHelperText error>{error}</FormHelperText>} */}
            <ErrorModal
              icon="/images/Boxes/WarningContrast.svg"
              open={!!error}
              title="Oops!"
              subTitle={error}
              onClose={closeErrorModal}
              onConfirm={closeErrorModal}
              btnText="Close"
            />
          </ProfileContainerStyle>
        </OutletBox>
        {(isBillingReadAllowed || isTCReadAllowed || isBankDetailsReadAllowed) && (
          <Actions
            onNext={handleSubmit}
            onBack={() => (handleBack ? handleBack(activeStep || 0) : {})}
            setApplyToAllSystem={currentTap === 'bank_details' ? handleApplyToAllSystem : undefined}
            // applyToAllSystem={applyToAllSystem}
            disableNext={false}
            viewFor={viewFor}
            activeStep={activeStep}
            currentTap={currentTap}
            setCurrentTap={setCurrentTap}
            disabled={
              (currentTap === 'billing' && !isBillingWriteAllowed) ||
              (currentTap === 'terms_and_conditions' && !isTCWriteAllowed) ||
              (currentTap === 'bank_details' && !isBankDetailsWriteAllowed)
            }
          />
        )}
      </>
    </Loader>
  );
}

export default InvoiceSettingTabs;
