import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

function useScroll() {
  const shipperConsigneeRef = useRef<HTMLDivElement | null>(null);
  const shipmentProcessingRef = useRef<HTMLDivElement | null>(null);
  const shipmentDetailsRef = useRef<HTMLDivElement | null>(null);
  const trackingRef = useRef<HTMLDivElement | null>(null);
  const shipmentChargesRef = useRef<HTMLDivElement | null>(null);

  const [searchParam] = useSearchParams();
  const tabKey = searchParam?.get('tab');
  useEffect(() => {
    switch (tabKey) {
      case 'shipper_consignee':
        shipperConsigneeRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'shipment_details':
        shipmentDetailsRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'shipment_processing':
        shipmentProcessingRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'tracking_detail':
        trackingRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'shipment_charges':
        shipmentChargesRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;

      default:
        shipperConsigneeRef?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
    }
  }, [tabKey]);
  return { shipperConsigneeRef, shipmentProcessingRef, shipmentDetailsRef, trackingRef, shipmentChargesRef };
}
export default useScroll;
