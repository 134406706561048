import React, { useEffect, useState } from 'react';
import Title from '../../components/Title';
import { BorderedWrapper, SelectedWrapper } from './style';
import { Grid, Paper, Typography } from '@mui/material';
import { ScrollableBox } from '../CreateShipment/styles';
import ProductTable from '../CreateShipment/ShipmentDetails/ProductTable';
import SingleBoxView from './SingleBoxView';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';
import { BoxType } from '../../store/reducers/Shipment/Type';
import { UNIT, calculateWeights } from '../../services/calculateVolume';
import ViewAction from './ViewAction';

function PackagingData({ label, value }: { label: string; value: string }) {
  return (
    <Grid
      container
      justifyContent={'space-between'}
      px={{ xs: 4, sm: 3, md: 3, lg: 2 }}
      py={{ xs: 4, sm: 3, md: 3, lg: 4 }}
      sx={{
        background: 'rgba(217, 217, 217, 0.2)',
        borderRadius: '8px',
      }}
    >
      <Typography className="md-text">{label}</Typography>
      <Typography className="md-text">{value}</Typography>
    </Grid>
  );
}

export function PackagingDetails() {
  const { shipmentById } = useSelector((state: RootStateType) => state.shipment);
  const [selectedBox, setSelectedBox] = useState<BoxType>();
  const [totalShipmentValue, setTotalShipmentValue] = useState<number>(0);
  const [actualWeight, setActualWeight] = useState<number>(0);
  const [chargeableWeight, setChargeableWeight] = useState<number>(0);
  const [viewMore, setViewMore] = useState<boolean>(true);

  const boxes = shipmentById?._packageId?.box;
  const lengthUnit: UNIT = shipmentById?._packageId?.unitOfMeasurement.split('-')[1] === UNIT.cm ? UNIT.cm : UNIT.in;
  const weightUnit = shipmentById?._packageId?.unitOfMeasurement.split('-')[0];

  useEffect(() => {
    if (boxes?.length) {
      setSelectedBox({ ...boxes[0], id: '0' });
      const { totalValue, totalChargeableWeight, totalActualWeight } = calculateWeights<BoxType>({
        boxes,
        unit: lengthUnit,
      });
      setTotalShipmentValue(totalValue);
      setChargeableWeight(totalChargeableWeight);
      setActualWeight(totalActualWeight);
    }
  }, [boxes]);

  return (
    <>
      <Title title="Packaging Details" removeBorder />
      <BorderedWrapper>
        <Paper
          elevation={0}
          sx={{
            display: 'grid',
            gridTemplateColumns: { lg: '1fr 1fr 1fr 1fr', md: '2fr 2fr', XS: '1fr' },
            columnGap: { lg: '35px', md: '35px', sx: 0, xs: 0 },
            rowGap: { lg: 0, md: '35px', sm: '35px', xs: '35px' },
          }}
        >
          <PackagingData label="No of Boxes" value={`${boxes?.length}`} />
          <PackagingData label="Chargeable weight" value={`${chargeableWeight} ${weightUnit}`} />
          <PackagingData label="Actual weight" value={`${actualWeight} ${weightUnit}`} />
          <PackagingData label="Shipment value" value={`${totalShipmentValue}`} />
        </Paper>
        <Grid container justifyContent={'flex-end'} className="my-3">
          <ViewAction setViewMore={setViewMore} viewMore={viewMore} />
        </Grid>
        {viewMore && (
          <Grid container className="my-3" spacing={2}>
            <Grid item lg={4} xl={3} md={4} sm={12} xs={12}>
              <ScrollableBox container id={'fadedScroll'} sx={{ pr: { xs: 1, md: 2 } }} rowGap={2}>
                {boxes?.map((box: BoxType, i: number) => (
                  <SingleBoxView
                    key={`single-box-${i}`}
                    box={{ ...box, id: String(i) }}
                    setSelectedBox={setSelectedBox}
                    isSelected={selectedBox?.id === String(i)}
                  />
                ))}
              </ScrollableBox>
            </Grid>
            <Grid item lg={8} xl={9} md={8} sm={12} xs={12}>
              {selectedBox?.products && (
                <SelectedWrapper sx={{ p: 2 }}>
                  <BorderedWrapper sx={{ py: 3, px: 2.5, mb: 0 }}>
                    <ProductTable title={'Product Description'} enableActions={false} products={selectedBox.products} />
                  </BorderedWrapper>
                </SelectedWrapper>
              )}
            </Grid>
          </Grid>
        )}
      </BorderedWrapper>
    </>
  );
}

export default PackagingDetails;
