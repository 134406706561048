import React from 'react';
import { ReverseDateWiseType } from '@Reducers/Tracking/type';
import { SingleBoxProps } from './type';
import moment from 'moment';
import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { Grid, Typography, alpha, styled } from '@mui/material';
import { TrackingStatus } from '../ViewSingleShipment/TrackingDetails/style';
import { Link } from 'react-router-dom';
import { transformString } from '../../utils/transformString';

interface BoxContainerProps {
  isselected: string;
}

const TrackingBoxContainer = styled(Grid)<BoxContainerProps>(({ theme, isselected }) => ({
  border: isselected === 'true' ? alpha(theme.palette.primary.light, 1) : `1px solid var(--mui-palette-primary-light)`,
  width: '100%',
  minHeight: '133px',
  padding: '15px',
  // paddingRight: '5px',
  cursor: 'pointer',
  '.isSelected': {
    background: 'var(--mui-palette-background-white) !important',
  },
  background: isselected === 'true' ? alpha(theme.palette.primary.light, 1) : 'unset',
}));

export default function SingleBox({
  singleShipmentData,
  setSelectedBox,
  setVerticalTrackingData,
  // index,
  selectedBox,
}: SingleBoxProps) {
  const verticalTrackingData = singleShipmentData?.statusDetails?.reduce((result, statusDetail) => {
    const timeStamp = new Date(statusDetail.timeStamp).toLocaleDateString();
    const existingEntry: ReverseDateWiseType | undefined = result.find(entry => entry?.date === timeStamp);

    const details = { ...statusDetail, time: moment(statusDetail.timeStamp).format('hh:mm A') };
    if (existingEntry && existingEntry.details) {
      existingEntry.details.push(details);
    } else {
      result.push({
        date: timeStamp,
        formattedDate: moment(statusDetail.timeStamp).format('DD MMMM YYYY, dddd'),
        details: [details],
      });
    }
    return result;
  }, [] as ReverseDateWiseType[]);

  const status = SHIPMENT_STATUS[singleShipmentData?.status as keyof typeof SHIPMENT_STATUS]?.label;

  const filteredData = singleShipmentData?.statusDetails.map(ShipmentData => {
    const lastTrackingDetails = ShipmentData.statusDetails[ShipmentData.statusDetails.length - 1];
    const lastTrackingLocation =
      ShipmentData.carrierAirWayBillNumber?.trim().length > 0
        ? ShipmentData.carrierAirWayBillNumber
        : lastTrackingDetails?.timeStamp || '-';

    return {
      shipmentNumber: ShipmentData.shipmentNumber,
      awbNumber: ShipmentData.carrierAirWayBillNumber ? ShipmentData.carrierAirWayBillNumber : ' -',
      lastTrackingLocation,
      status: ShipmentData.status,
    };
  });

  return (
    <>
      {filteredData && filteredData.length > 0 ? (
        filteredData.map((shipment, index) => (
          <div
            key={index}
            onClick={() => {
              setSelectedBox(shipment?.shipmentNumber);
              setVerticalTrackingData(verticalTrackingData);
            }}
          >
            <TrackingBoxContainer
              container
              flexDirection={'column'}
              className={`rounded-lg  `}
              isselected={selectedBox === shipment?.shipmentNumber ? 'true' : 'false'}
              key={index}
            >
              <Typography variant="subtitle1" className="sm-text font-bold">
                {/* Tracking Status */}
                Box {index + 1}
              </Typography>

              <Typography variant="subtitle1" className="sm-text ">
                <Link to={`/shipment/admin/edit/${shipment?.shipmentNumber}`}>{shipment?.shipmentNumber}</Link>
              </Typography>
              <Typography variant="subtitle1" className="sm-text">
                Carrier AWB:
                {singleShipmentData && shipment.awbNumber}
              </Typography>

              <TrackingStatus
                bgcolor={SHIPMENT_STATUS[singleShipmentData?.status]?.bgColor}
                color={SHIPMENT_STATUS[status]?.textColor}
              >
                {transformString(shipment.status)}
              </TrackingStatus>
            </TrackingBoxContainer>
          </div>
        ))
      ) : (
        <Typography variant="subtitle1" className="md-text sb-text-gray">
          No Shipments Found
        </Typography>
      )}
    </>
  );
}
