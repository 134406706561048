import { constantType } from './Type';

const constant: constantType = {
  DOMAIN_OWNER: 'speedbox',
  protocol: 'http',
  DOMAIN: 'http://localhost:3004/',
  API_DOMAIN: 'http://localhost:3003/development',
  // API_URI: 'http://localhost:3003/development/speedbox/api/v1',
  // https://uat-speedbox.speedboxapp.com
  // API_URI: 'https://uat-speedbox.speedboxapp.com/staging/api/v2',
  API_URI: '/development/api/v2',
  WEBSOCKET_URI: 'ws://localhost:3001/development/speedbox/api/v1',
  API_URI_PATHNAME: '/development/speedbox/api/v1',
  razorKey: 'rzp_test_LIOk4AYGgVRnDY',
  trackingUrl: 'https://speedboxapp.com/track?orderNumber=',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBFD_fSwbp29dcajB97ReaHX2SB9V1VMvI',
};

export default constant;
