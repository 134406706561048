/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import API from '../../../services/apiAxios';
import {
  AccountCreatedType,
  AccountListResponse,
  CarrierSettingsList,
  ErrorType,
  ForgotPasswordPayload,
  ForgotPasswordSuccess,
  UpdateAccountPayload,
  UserListActionQuery,
  UserListResponse,
  UserObj,
  UserQuery,
  UserSendInvitePayload,
  UserSendInviteResponse,
  dhlCarrierPayload,
  fedexCarrierPayload,
  aramexCarrierPayload,
  updateParams,
} from './Type';

import { resetLoginState } from '@Reducers/LoginForm';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { SuccessPayload } from '../../../types';
// const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const getUserList = createAsyncThunk<UserListResponse, UserQuery, { rejectValue: ErrorType }>(
  'getUserList',
  async (queryParams, { dispatch }) => {
    try {
      const res = await API.get(`/user/list/user`, { params: queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getAccountList = createAsyncThunk<AccountListResponse, undefined, { rejectValue: ErrorType }>(
  'getAccountList',
  async (_, { rejectWithValue }) => {
    try {
      const res = await API.get(`/user/account/list/company`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const createAccount = createAsyncThunk<
  AccountCreatedType,
  { body: UserObj; nextActionBody: fedexCarrierPayload },
  { rejectValue: ErrorType }
>('createAccount', async ({ body, nextActionBody }, { dispatch }) => {
  try {
    const res = await API.post(`/user/invitation/signup`, body);
    if (nextActionBody?.shipmentPurpose?.length) {
      if (nextActionBody._id) {
        dispatch(updateCarrierSettings(nextActionBody));
      } else {
        dispatch(addCarrierSettings(nextActionBody));
      }
    }
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const resendInvite = createAsyncThunk<AccountCreatedType, UserListActionQuery, { rejectValue: ErrorType }>(
  'resendInvite',
  async (queryParams, { dispatch }) => {
    try {
      const res = await API.get(`/user/invitation/signup/mail/${queryParams.id}`);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const userSendInvite = createAsyncThunk<UserSendInviteResponse, UserSendInvitePayload, { rejectValue: ErrorType }>(
  'adminSendInvite',
  async (body, { dispatch }) => {
    try {
      const res = await API.post(`/user/invitation/send-invite`, body);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg || 'Something went to wrong.',
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const viewContract = createAsyncThunk<SuccessPayload, UserListActionQuery, { rejectValue: ErrorType }>(
  'viewContract',
  async (queryParams, { dispatch }) => {
    try {
      const res = await API.get(`/user/contract/${queryParams.id}`);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const deactivateUserAccount = createAsyncThunk<SuccessPayload, UserListActionQuery, { rejectValue: ErrorType }>(
  'deactivateUserAccount',
  async (queryParams, { dispatch }) => {
    const { id, showModal } = queryParams;
    try {
      const res = await API.post(`/user/on-board-status/deactivate/${id}`);
      if (showModal)
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: res.data.msg,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const suspendUserAccount = createAsyncThunk<SuccessPayload, UserListActionQuery, { rejectValue: ErrorType }>(
  'suspendUserAccount',
  async (queryParams, { dispatch }) => {
    const { id, showModal } = queryParams;
    try {
      const res = await API.post(`/user/on-board-status/suspend/${id}`);
      if (showModal)
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: res.data.msg,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const restoreUserAccount = createAsyncThunk<SuccessPayload, UserListActionQuery, { rejectValue: ErrorType }>(
  'restoreUserAccount',
  async (queryParams, { dispatch }) => {
    const { id, showModal } = queryParams;
    try {
      const res = await API.post(`/user/on-board-status/restore/${id}`);
      if (showModal)
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: res.data.msg,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getUserById = createAsyncThunk<UserObj, string, { rejectValue: ErrorType }>(
  'getUserById',
  async (id, { dispatch }) => {
    try {
      const res = await API.get(`/user/${id}`);
      return res.data.data;
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: 'An error occurred while retrieving user details.',
        }),
      );
      throw error;
    }
  },
);

const updateAccount = createAsyncThunk<
  UserObj,
  { payload: UpdateAccountPayload } & updateParams,
  { rejectValue: ErrorType }
>('updateAccount', async ({ payload, invitationSend }, { dispatch }) => {
  try {
    if (invitationSend) {
      const res = await API.put(`/user/invitation/signup/${payload._id}`, payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: 'User successfully updated.',
          navigateTo: '/user',
        }),
      );

      return res.data;
    } else {
      const res = await API.put(`/user/user-account/${payload._id}`, payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: 'User successfully updated.',
          navigateTo: '/user',
        }),
      );

      return res.data;
    }
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const forgotPassword = createAsyncThunk<ForgotPasswordSuccess, ForgotPasswordPayload, { rejectValue: ErrorType }>(
  'forgotPassword',
  async (payload, { dispatch }) => {
    try {
      const res = await API.post(`/app/forget-password`, payload);

      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data?.msg || 'Reset password OTP sent successfully.',
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.errorObj?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const confirmForgotPassword = createAsyncThunk<SuccessPayload, ForgotPasswordPayload, { rejectValue: ErrorType }>(
  'confirmForgotPassword',
  async (payload, { dispatch }) => {
    try {
      const res = await API.post(`/app/forget-password/confirm`, payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data?.msg || 'Password reset successfully.',
          navigateTo: '/login',
        }),
      );
      dispatch(resetLoginState());
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      const msg = errorRes?.errorObj?.msg || errorRes?.msg;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const addCarrierSettings = createAsyncThunk<
  SuccessPayload,
  fedexCarrierPayload | dhlCarrierPayload | aramexCarrierPayload | any,
  { rejectValue: ErrorType }
>('addCarrierSettings', async (payload, { dispatch }) => {
  try {
    const res = await API.post(`/shipment/vendor/setting`, payload);
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: res.data?.msg || 'Carrier settings added successfully.',
      }),
    );
    dispatch(getCarrierSettingsList(payload?.accountNumber || ''));
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    const msg = errorRes?.errorObj?.msg || errorRes?.msg;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const updateCarrierSettings = createAsyncThunk<
  SuccessPayload,
  fedexCarrierPayload | dhlCarrierPayload | aramexCarrierPayload | any,
  { rejectValue: ErrorType }
>('updateCarrierSettings', async (payload, { dispatch }) => {
  try {
    const res = await API.put(`/shipment/vendor/setting`, payload);
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: res.data?.msg || 'Carrier settings added successfully.',
      }),
    );
    dispatch(getCarrierSettingsList(payload?.accountNumber || ''));
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    const msg = errorRes?.errorObj?.msg || errorRes?.msg;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const getCarrierSettings = createAsyncThunk<
  SuccessPayload<fedexCarrierPayload>,
  {
    csp: string;
    accountNumber: string;
  },
  { rejectValue: ErrorType }
>('getCarrierSettings', async ({ csp, accountNumber }, { dispatch }) => {
  try {
    const res = await API.get(`shipment/vendor/setting?csp=${csp}&accountNumber=${accountNumber}`);
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    const msg = errorRes?.errorObj?.msg || errorRes?.msg;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const getCarrierSettingsList = createAsyncThunk<
  SuccessPayload<CarrierSettingsList>,
  string,
  { rejectValue: ErrorType }
>('getCarrierSettingsList', async (payload, { dispatch }) => {
  try {
    const res = await API.get(`shipment/vendor/settingList?accountNumber=${payload}`);
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    const msg = errorRes?.errorObj?.msg || errorRes?.msg;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const deleteCarrierSetting = createAsyncThunk<
  SuccessPayload,
  {
    csp: string;
    accountNumber: string;
  },
  { rejectValue: ErrorType }
>('deleteCarrierSetting', async ({ csp, accountNumber }, { dispatch }) => {
  try {
    const res = await API.delete(`shipment/vendor/setting?csp=${csp}&accountNumber=${accountNumber}`);
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: res.data?.msg || 'Carrier settings deleted successfully.',
      }),
    );
    dispatch(getCarrierSettingsList(accountNumber));
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    const msg = errorRes?.errorObj?.msg || errorRes?.msg;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

export {
  addCarrierSettings,
  confirmForgotPassword,
  createAccount,
  deactivateUserAccount,
  deleteCarrierSetting,
  forgotPassword,
  getAccountList,
  getCarrierSettings,
  getCarrierSettingsList,
  getUserById,
  getUserList,
  resendInvite,
  restoreUserAccount,
  suspendUserAccount,
  updateAccount,
  updateCarrierSettings,
  userSendInvite,
  viewContract,
};
