import { Grid, Typography } from '@mui/material';
import React from 'react';
// import { CopyCount } from '../GetRatesV2/StyleComponent';
import { BoxContainer } from '../CreateShipment/styles';
import { BoxType } from '../../store/reducers/Shipment/Type';
import { RootStateType } from '../../store';
import { useSelector } from 'react-redux';
import { UNIT, calculateVolume } from '../../services/calculateVolume';

interface PropsType {
  box: BoxType;
  setSelectedBox: (box: BoxType) => void;
  isSelected: boolean;
}

function BoxInfo({ value, label }: { label: string; value: string }) {
  return (
    <Grid container className="mb-1/2" display="grid" gridTemplateColumns="2fr 1fr" gap={1}>
      <Grid item>
        <Typography className="sm-text">{label}</Typography>
      </Grid>
      <Grid item textAlign="left">
        <Typography className="sm-text">{value}</Typography>
      </Grid>
    </Grid>
  );
}

function SingleBoxView({ box, setSelectedBox, isSelected }: PropsType) {
  const { shipmentById } = useSelector((state: RootStateType) => state.shipment);
  const selectedUnit = shipmentById?._packageId.unitOfMeasurement.split('-')[0];
  const selectedLengthUnit: UNIT =
    shipmentById?._packageId.unitOfMeasurement.split('-')[1] === UNIT.cm ? UNIT.cm : UNIT.in;
  // const { boxCopyCount, id, weight, length, width, height, startCount = 0 } = box;
  const { id, weight, length, width, height } = box;
  const volumetricWeight = calculateVolume(length, width, height, selectedLengthUnit);
  return (
    <>
      <BoxContainer
        container
        alignItems={'center'}
        className={`rounded-lg`}
        isselected={String(isSelected)}
        onClick={() => {
          setSelectedBox(box);
          // setSelectedProduct({ ...defaultProductState });
        }}
      >
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid container justifyContent="space-between">
            <Grid container item xs={11} className="mb-1">
              <Typography className="md-text font-bold">{`Box ${Number(id) + 1}`}</Typography>
              {/* <Typography className="md-text font-bold">Box 1-3</Typography> */}
              {/* {boxCopyCount && boxCopyCount > 0 ? (
                <CopyCount className="sm-text ml-1 px-1 rounded-xs">x {boxCopyCount}</CopyCount>
              ) : (
                <></>
              )} */}
            </Grid>
          </Grid>
          <BoxInfo label="Actual Weight" value={`${weight || 0} ${selectedUnit}`} />
          <BoxInfo label="Volumetric Weight" value={`${volumetricWeight || 0} ${selectedUnit}`} />
          <BoxInfo
            label="Chargeable Weight"
            value={`${
              weight && volumetricWeight && volumetricWeight > Number(weight) ? volumetricWeight || 0 : weight || 0
            } ${selectedUnit}`}
          />
          <BoxInfo label={`Dimentions(L*W*H)${selectedLengthUnit}`} value={`${length}*${width}*${height}`} />
        </Grid>
      </BoxContainer>
    </>
  );
}

export default SingleBoxView;
