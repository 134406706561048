import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import SBDropdown from '@Components/Dropdown';
import { SHIPMENT_STATUS, STATUS, WHEN_REMOVE_FROM_SHIPMENT_EXCEPTION } from '@Constants/Shipment';
import SbTextField from '@Components/Textfield';
import Actions from '../Actions';
import { useFormik } from 'formik';
import Schema from '../../Schema';
import { UpdateShipmentStatusPostData } from '@Reducers/ShipmentHistory/Type';
import { UpdateShipmentStatus } from '@Reducers/ShipmentHistory';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { BoxType } from '@Reducers/Shipment/Type';
import { trackShipment } from '@Reducers/Tracking/actions';
import { getAdminShipmentById, getShipmentWebsocketStream } from '@Reducers/AdminShipment/actions';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

// const StatusList = Object.values(SHIPMENT_STATUS)
//   .filter(s => ![SHIPMENT_STATUS.placed.value, SHIPMENT_STATUS.unprocessed.value].includes(s.value))
//   .map(s => ({ display: s.label, value: s.value, grouped: true }));
const OtherStatusList = Object.values(SHIPMENT_STATUS).filter(x => x.isOther);
const StatusList = [
  ...Object.values(SHIPMENT_STATUS)
    .filter(x => !x.isOther)
    .map(s => ({ display: s.label, value: s.value })),
  { display: 'Others', value: 'Others', grouped: true },
  ...OtherStatusList.map(s => ({ display: s.label, value: s.value })),
];
const DefaultBoxesList = [{ display: 'All Boxes', value: 'all' }];

interface UpdateStatusFormProps {
  shipmentDetails: {
    shipmentNumber: string;
    userId: string;
    boxes: BoxType[];
  };
  disableActions: boolean;
  latestStatus?: string;
  previousStatus: string;
}

interface UpdateStatusFormType {
  location: string;
  status: string;
  message: string;
  boxesToUpdate: string[];
}

const initialStatusForm: UpdateStatusFormType = {
  location: '',
  status: '',
  message: '',
  boxesToUpdate: [],
};

const UpdateStatusForm = (props: UpdateStatusFormProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isTrackingDetailsWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.tracking_details,
    PERFORM_ACTION.write,
  );

  function handleSave(params: UpdateStatusFormType) {
    const { userId, shipmentNumber = '' } = props.shipmentDetails;

    let shipmentNumbers: string[] = [shipmentNumber];
    if (props.shipmentDetails.boxes?.length && params.boxesToUpdate?.length) {
      if (params.boxesToUpdate.includes('all')) {
        shipmentNumbers = props.shipmentDetails.boxes
          .filter(i => Boolean(i.childAWBNumber))
          .map(i => i.childAWBNumber || '');
      } else {
        shipmentNumbers = params.boxesToUpdate;
      }
    }

    const postData: UpdateShipmentStatusPostData = {
      _userId: userId,
      location: params.location,
      msg: params.message || SHIPMENT_STATUS[params.status as keyof typeof SHIPMENT_STATUS]?.msg,
      status: params.status,
      shipmentNumbers,
    };

    dispatch(UpdateShipmentStatus(postData)).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        handleCancel();
        dispatch(trackShipment(shipmentNumber));
        dispatch(getAdminShipmentById(shipmentNumber));
        dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
      }
    });
  }

  function handleCancel() {
    formik.resetForm();
  }

  function handleBoxSelection(selected: string[]) {
    // handle on All Boxes selected, other boxes selection should be disabled
    formik.setFieldValue('boxesToUpdate', selected);
  }

  const boxesList = useMemo(() => {
    // console.log('Boxes list memo called : ', props.shipmentDetails.boxes.length);
    if (!props.shipmentDetails.boxes.length) return DefaultBoxesList;
    return [
      // ...DefaultBoxesList,
      ...props.shipmentDetails.boxes.map((x, i) => ({
        display: `Box ${i + 1}`,
        value: (x.childAWBNumber || i + 1).toString(),
      })),
    ];
  }, [props.shipmentDetails.boxes]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialStatusForm,
      boxesToUpdate: boxesList?.length === 1 ? [boxesList?.[0]?.value] : [],
    },
    validationSchema: Schema.UpdateTrackingStatus,
    validateOnChange: false,
    onSubmit(values) {
      handleSave(values);
    },
  });

  const { location, status, message, boxesToUpdate } = formik.values;
  const filteredOptions = props.latestStatus
    ? props?.latestStatus === 'shipment_exception'
      ? [...WHEN_REMOVE_FROM_SHIPMENT_EXCEPTION[props.previousStatus], ...STATUS[props.latestStatus]]
      : STATUS[props.latestStatus]
    : StatusList;

  return (
    <>
      <Grid container rowSpacing={{ md: 3, lg: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 14, lg: 14 }}>
        <Grid item xs={12} md={6}>
          <SBDropdown
            id="company-country-id"
            required
            label="Status"
            placeholder="Select"
            value={[status]}
            options={filteredOptions?.filter(status => status.value !== 'forwarded') || []}
            // options={filteredOptions || []}
            onChange={([value]) => formik.setFieldValue('status', value)}
            error={formik.errors.status}
            disabled={!isTrackingDetailsWriteAllowed}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SbTextField
            label="Enter Location"
            placeholder="Type here"
            name="location"
            className="w-full"
            onChange={formik.handleChange}
            value={location}
            disabled={!isTrackingDetailsWriteAllowed}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SbTextField
            label="Enter Message"
            placeholder="Type here"
            name="message"
            className="w-full"
            onChange={formik.handleChange}
            value={message}
            disabled={!isTrackingDetailsWriteAllowed}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SBDropdown
            required
            id="company-country-id"
            label="Select Box"
            placeholder="Select"
            multiple={boxesList?.length > 1}
            value={boxesToUpdate}
            options={boxesList}
            onChange={handleBoxSelection}
            error={formik.errors.boxesToUpdate as string}
            disabled={!isTrackingDetailsWriteAllowed}
          />
        </Grid>
      </Grid>
      {props.disableActions && (
        <Actions
          // onCancel={handleCancel}
          disabled={!isTrackingDetailsWriteAllowed}
          onSave={formik.handleSubmit}
        />
      )}
    </>
  );
};

export default UpdateStatusForm;
