import SbButton from '@Components/Button';
import CopyCredentials from '@Components/CopyCredentials';
import Image from '@Components/Image';
import Modal from '@Components/Modal';
import SbTextField from '@Components/Textfield';

import { CloseRounded } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { sendInvite } from '@Reducers/Squad/actions';
import { AppDispatch } from '@Store';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function SuccessModal(props: ModalProps) {
  const { open, onClose, copyObj, squadId } = props;
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState<string>('');
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  const sendInviteClick = async () => {
    await dispatch(sendInvite({ email: inputValue, squadId }));
    onClose();
    navigate('/squad');
  };

  return (
    <Modal open={open} onClose={onClose} maxWidth="md">
      <Box>
        <Stack>
          <IconButton onClick={onClose} sx={{ width: 36, ml: 'auto', fontSize: 36 }}>
            <CloseRounded />
          </IconButton>
        </Stack>

        <Typography className="font-medium l-text">You’ve successfully created an account!</Typography>
        <Typography variant="h6" className="mt-1 md-text font-normal">
          Share account credentials to give access to the squad member
        </Typography>
        <Stack className="my-4" direction={'row'} gap={2}>
          <SbTextField
            sx={{ width: 300 }}
            name="password"
            placeholder="contact@email.com"
            value={inputValue}
            onChange={handleInput}
            endAdornment={
              <IconButton
                onClick={() => {
                  setInputValue('');
                }}
                sx={{ width: 36, ml: 'auto', fontSize: 36 }}
              >
                <CloseRounded />
              </IconButton>
            }
          />
          <SbButton
            variant="contained"
            onClick={sendInviteClick}
            sx={{
              borderRadius: '10px !important',
            }}
          >
            Send Invite
          </SbButton>
        </Stack>
        <Stack className="py-2" direction={'row'} gap={4}>
          <Stack justifyContent={'center'} alignItems={'center'}>
            {/* <ContentCopyRounded
              sx={{
                fontSize: 32,
              }}
            />
            <Typography
              variant="subtitle1"
              className="mt-1 sm-text font-normal"
              sx={{
                lineHeight: 1.3,
              }}
            >
              Copy Admin <br />
              Credentials
            </Typography> */}
            <CopyCredentials
              label="Copy Admin Credentials"
              textToCopy={`Username: ${copyObj.username}, Password: ${copyObj.password}`}
            ></CopyCredentials>
          </Stack>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Image alt="whatsapp" src="/images/icons/social_media/WhatsApp.svg" width={32} height={32} />
            <Typography variant="subtitle1" className="mt-1 sm-text font-normal">
              WhatsApp
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default SuccessModal;

interface ModalProps {
  open: boolean;
  onClose: () => void;
  copyObj: {
    username: string;
    password: string;
  };
  squadId: string;
}
