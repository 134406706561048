import SBDropdown from '@Components/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import Modal from '@Components/Modal';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import {
  assignTaskToSquad,
  getAllTaskShipments,
  getSquadForTaskAssignment,
  UnassignTaskToSquad,
} from '@Reducers/Squad/actions';
import { AppDispatch, RootStateType } from '@Store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignClickProps, SquadMemberTableProps } from './types';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import DisplaySquadVehicle from '../../utils/displaySquadVehicle';
import Loader from '@Components/Loader';
import { SquadMembersPayload } from '../../store/reducers/Squad/type';
import { Dayjs } from 'dayjs';
interface EditModalProps {
  openEdit: boolean;
  onClose: () => void;
  shipmentId?: string;
  date: Dayjs;
  selectedZones: string[];
}
const SearchFilterList = [{ label: 'Name', value: 'name' }];

function SquadMemberTable(props: SquadMemberTableProps) {
  const { shipmentId, close, allSquads, date, setLoading } = props;
  const [isUnassigned, setIsUnassigned] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const onAssignClick = async (props: assignClickProps) => {
    const { _shipmentId, assignedTo } = props;
    const postData = {
      _shipmentId,
      assignedTo,
      date,
    };

    try {
      setLoading(true);
      const data = await dispatch(assignTaskToSquad(postData)).unwrap();
      if (data.warningMessage) {
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Shipment is successfully assigned!',
            subTitle: `${data.warningMessage}`,
          }),
        );
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Shipment is successfully assigned!',
            subTitle: '',
          }),
        );
      }
      setLoading(false);
      await dispatch(getAllTaskShipments({ page: 1, pageSize: 10, shipmentNumber: '' }));
    } catch (err) {
      setLoading(false);
    } finally {
      close();
    }
  };

  const onUnassignClick = async (shipmentId: string) => {
    const payload = {
      _shipmentId: shipmentId,
    };
    try {
      setLoading(true);
      await dispatch(UnassignTaskToSquad(payload)).unwrap();
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Shipment is successfully Unassigned!',
          subTitle: '',
        }),
      );
      setLoading(false);
      setIsUnassigned(true);
      // await dispatch(getAllTaskShipments({ page: 1, pageSize: 10, shipmentNumber: '' }));
    } catch (err) {
      setLoading(false);
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Unable to Unassign Shipment',
          subTitle: '',
        }),
      );
      props.close();
    }
  };

  return (
    <SBTable>
      <TableHead>
        <TableRow>
          <TableHeadCell is_light="1" className="bg-grey">
            Name
          </TableHeadCell>
          <TableHeadCell is_light="1" className="bg-grey">
            UserName
          </TableHeadCell>
          <TableHeadCell is_light="1" className="bg-grey">
            Vehicle
          </TableHeadCell>
          <TableHeadCell is_light="1" className="bg-grey">
            Actions
          </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allSquads.map((data: any) => {
          const { name, phoneNumber, username, vehicle, vehicleCapacity, isAlreadyAssigned, id } = data;
          return (
            <SBTableRow key={Number(id)}>
              <TableRowCell align="center">
                <div>
                  <Typography>
                    {name} {phoneNumber}
                  </Typography>
                </div>
              </TableRowCell>
              <TableRowCell align="center" className="md-text">
                <Typography>{username}</Typography>
              </TableRowCell>
              <TableRowCell align="center" className="md-text ">
                <Box className="flex flex-col sb-gap-1">
                  <Typography>{DisplaySquadVehicle(vehicle)} </Typography>
                  <Typography> Capacity : {vehicleCapacity} kg</Typography>
                </Box>
              </TableRowCell>
              <TableRowCell align="center" className="md-text ">
                {isAlreadyAssigned && !isUnassigned ? (
                  <Button variant="contained" className="rounded-xs" onClick={() => onUnassignClick(shipmentId)}>
                    Unassign
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className="rounded-xs"
                    disabled={!isAlreadyAssigned && !isUnassigned}
                    onClick={() =>
                      onAssignClick({
                        _shipmentId: shipmentId as string,
                        assignedTo: id,
                      })
                    }
                  >
                    Assign
                  </Button>
                )}
              </TableRowCell>
            </SBTableRow>
          );
        })}
      </TableBody>
    </SBTable>
  );
}

const EditModal = (props: EditModalProps) => {
  const [value, setValue] = useState('1');
  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const [filterText, setFilterText] = useState('');
  const [allSquads, setAllSquads] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { currentPage, perPage, loading } = useSelector((state: RootStateType) => state.squadManagement);
  const handleClose = () => {
    props.onClose();
  };

  const fetchSquadForAssignment = async (name: string, currentPage: number, perPage: number) => {
    const queryParams = {
      name,
      shipmentId: props.shipmentId || '',
      currentPage,
      perPage,
      zoneName: props.selectedZones,
    };
    const result = await dispatch(getSquadForTaskAssignment(queryParams));
    const { data, totalCount } = result.payload as SquadMembersPayload;
    setAllSquads(data);
    setTotalCount(totalCount);
  };

  useEffect(() => {
    fetchSquadForAssignment('', currentPage, perPage);
  }, []);
  return (
    <>
      <Loader loading={isLoading || loading} overly />
      <Modal open={props.openEdit} onClose={handleClose} maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ width: '46rem', backgroundColor: 'white' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Squad Member" value="1" className="md-text font-medium" />
                  <Tab label="Third-party Integration" className="md-text font-medium" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ height: '55vh', overflow: 'auto' }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InputLabel className="sb-text-gray">Enter name or username</InputLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                      <FilterSearch
                        onSelectChange={(value: string) => {
                          setFilterSelectValue(value);
                        }}
                        list={SearchFilterList}
                        selectValue={filterSelectValue}
                        textValue={filterText}
                        placeholder="sbps@gmail.com"
                        onTextChange={e => {
                          setFilterText(e.target.value);
                        }}
                        onTextSearch={() => {
                          fetchSquadForAssignment(filterText, currentPage, perPage);
                        }}
                        onTextClear={async () => {
                          setFilterText('');
                          fetchSquadForAssignment('', currentPage, perPage);
                        }}
                      />
                    </Grid>
                  </Grid>

                  {props.shipmentId && (
                    <SquadMemberTable
                      allSquads={allSquads}
                      shipmentId={props.shipmentId}
                      close={handleClose}
                      date={props.date}
                      setLoading={setLoading}
                    />
                  )}
                </Box>
              </TabPanel>
              {!!totalCount && !!perPage && (
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={currentPage - 1}
                  rowsPerPage={perPage}
                  onPageChange={async (_, page) => {
                    fetchSquadForAssignment(filterText, page + 1, perPage);
                  }}
                  onRowsPerPageChange={async event => {
                    const newPerPage = parseInt(event.target.value, 10);
                    fetchSquadForAssignment(filterText, currentPage, newPerPage);
                  }}
                />
              )}
              <TabPanel value="2" sx={{ height: '55vh', overflow: 'auto' }}>
                <Grid container direction="column" spacing={2} sx={{ width: '45%' }}>
                  <Grid item xs={6}>
                    <SBDropdown
                      id="IdType-csp"
                      label={`Select CSP*`}
                      placeholder="FedEx - Priority "
                      name="IdType"
                      value={[]}
                      options={[]}
                      onChange={console.log}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SBDropdown
                      id="IdType-csp"
                      label={`Select Warehouse Address `}
                      placeholder="Allied Warehouse Mumbai"
                      name="IdType"
                      value={[]}
                      options={[]}
                      onChange={console.log}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
          <Box>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditModal;
