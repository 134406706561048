import React from 'react';

import OrganizationSetupContext from '../../../context/OrganizationSetup';
import { OrganizationSetupContextType } from '../../../types/organization';
import InvoiceSettingTabs from './InvoiceSettingTabs';

function OrgInvoiceSettings() {
  const { activeStep, handleNext, handleBack } = React.useContext(
    OrganizationSetupContext,
  ) as OrganizationSetupContextType;
  // const { saveInvoiceSuccess } = useSelector((state: RootStateType) => state?.orgSetup);

  // useEffect(() => {
  //   if (saveInvoiceSuccess) {
  //     handleNext(activeStep);
  //   }
  // }, [saveInvoiceSuccess]);

  // useEffect(() => {
  //   if (invoiceSetting) {
  //     if (invoiceSetting.bankDetails[0]) {
  //       const bankInfo = invoiceSetting.bankDetails[0];
  //       setBankDetails({
  //         systemId: bankInfo.systemId,
  //         accountName: bankInfo.accountName,
  //         bankName: bankInfo.bankName,
  //         accountNumber: bankInfo.accountNumber,
  //         accountType: bankInfo.accountType,
  //         ifscCode: bankInfo.ifscCode,
  //         upiId: bankInfo.upiId,
  //         gstNo: bankInfo.gstNo,
  //         getwayIntegrationRequired: bankInfo.getwayIntegrationRequired,
  //       });
  //     }
  //     if (invoiceSetting.taxInformation) setTaxInformation(invoiceSetting.taxInformation);
  //     if (invoiceSetting.sacCode) setSacCode(invoiceSetting.sacCode);
  //     if (invoiceSetting.termAndCondition) setTermsAndConditions(invoiceSetting.termAndCondition);
  //   }
  // }, [invoiceSetting]);

  // function handleSubmit() {
  //   dispatch(
  //     saveInvoiceSettings({
  //       taxInformation: { taxInformation, sacCode },
  //       termAndCondition: termsAndConditions,
  //       bankDetails: { data: [bankDetails], applyToAllSystem: true },
  //     }),
  //   );
  //   // handleNext(activeStep);
  // }

  return (
    <>
      {/* <Loader loading={false} /> */}
      {/* <OutletBox className="border-grey rounded-md my-3"> */}
      <InvoiceSettingTabs
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        viewFor={'org-setup'}
      />
      {/* </OutletBox> */}
    </>
  );
}

export default OrgInvoiceSettings;
