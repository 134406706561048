import React, { createContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MenuWidth } from './type';

type DefaultContextStateType = {
  isMenuOpen: boolean;
  setIsMenuOpen?: (param: boolean | ((param: boolean) => boolean)) => void;
  menuWidth?: MenuWidth;
  showMobileMenu: boolean;
  setShowMobileMenu?: (param: boolean | ((param: boolean) => boolean)) => void;
};
const defaultContextState: DefaultContextStateType = {
  menuWidth: 104,
  isMenuOpen: true,
  showMobileMenu: false,
};

export const MenuContext = createContext(defaultContextState);

interface MenuContextWrapperType {
  children: React.ReactNode;
}

function MenuContextWrapper(props: MenuContextWrapperType) {
  const [isMenuOpen, setIsMenuOpen] = useState(defaultContextState.isMenuOpen);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [menuWidth, setMenuWidth] = useState<MenuWidth>(104);
  const theme = useTheme();
  const large_screen_matches = useMediaQuery(theme.breakpoints.only('xl'));
  const medium_screen_matches = useMediaQuery(theme.breakpoints.down('xl'));
  useEffect(
    function () {
      if (isMenuOpen && large_screen_matches) setMenuWidth(260);
      else if (isMenuOpen && medium_screen_matches) setMenuWidth(250);
      else setMenuWidth(104);
    },
    [isMenuOpen],
  );
  return (
    <>
      <MenuContext.Provider value={{ isMenuOpen, menuWidth, setIsMenuOpen, showMobileMenu, setShowMobileMenu }}>
        {props.children}
      </MenuContext.Provider>
    </>
  );
}

export default MenuContextWrapper;
