type UserStatusColor = {
  textColor: string;
  bgColor: string;
  text: string;
};

interface UserStatusColorMap {
  [key: string]: UserStatusColor;
}

export const USER_STATUS_COLOR: UserStatusColorMap = {
  active: {
    textColor: '#1F9C3A',
    bgColor: '#D0FFDB',
    text: 'Active',
  },
  deactivated: {
    textColor: '#E98C00',
    bgColor: '#FFE8C6',
    text: 'Deactivated',
  },
  successful: {
    text: 'Successful',
    textColor: '#1F9C3A',
    bgColor: '#D0FFDB',
  },
  pending: {
    text: 'Pending',
    textColor: '#E98C00',
    bgColor: '#FFE8C6',
  },
  failed: {
    text: 'Failed',
    textColor: '#CE202E',
    bgColor: '#FFDADB',
  },
  cancelled: {
    text: 'Cancelled',
    textColor: '#CE202E',
    bgColor: '#FFDADB',
  },
};
