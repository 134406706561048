import React, { useEffect } from 'react';

import { Grid, Typography, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/system';
import Contact from '../../../components/Contact';
import Divider from '../../../components/Divider';
import SbTextField from '../../../components/Textfield';

import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues } from 'formik';
import SBDropdown from '../../../components/Dropdown';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';

import { Info } from '@mui/icons-material';
import SbTooltip from '../../../components/Tooltip';
import { getSystemList } from '../../../store/reducers/Systems';
import { WarehouseType } from './type';

interface CreateWarehouseFormProps {
  showBackgroundColor?: boolean;
  formType?: 'create' | 'edit';
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<WarehouseType>['setFieldValue'];
  errors?: FormikErrors<WarehouseType>;
  disabledSystemdropdown?: boolean;
}

export const initCreateWarehouse: WarehouseType = {
  name: '',
  attendanceName: '',
  email: '',
  countryCode: '',
  number: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  // TODO; REMOVED TO
  pincode: '',
  systemId: '',
};

function CreateWarehouseForm(props: CreateWarehouseFormProps) {
  const {
    showBackgroundColor,
    formType = 'create',
    values,
    errors,
    handleChange,
    setFieldValue,
    disabledSystemdropdown,
  } = props;
  const theme = useTheme();

  const dispatch = useDispatch<AppDispatch>();

  const SystemState = useSelector((state: RootStateType) => state.System);
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);

  useEffect(() => {
    dispatch(getSystemList());
  }, []);

  const systemOptions = SystemState?.data?.selectedList?.map(system => ({ display: system.name, value: system._id }));

  return (
    <>
      <Grid
        container
        sx={{ p: 4 }}
        style={{
          borderRadius: '12px',
          backgroundColor: showBackgroundColor ? alpha(theme.palette.primary.light, 0.4) : '',
        }}
      >
        <Grid item xs={12}>
          <Typography className="org-text ">
            {(formType === 'edit' && 'Edit') || (formType === 'create' && 'Create')} warehouse
          </Typography>
          <Grid container>
            <Typography className="sm-text flex" sx={{ mt: '1' }}>
              Link a warehouse to your system
            </Typography>
            <SbTooltip title="Please create at-least one warehouse for this system">
              <Info className="ml-1" />
            </SbTooltip>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        <Grid item container xs={12} alignItems="baseline" spacing={2}>
          <Grid item xs={12} md={2.5} lg={2}>
            <FormLabel className="md-text font-medium">Basic Details</FormLabel>
          </Grid>
          <Grid container item xs={12} md={9.5} lg={10} columnSpacing={4} rowSpacing={2}>
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="Warehouse Name"
                  placeholder="Enter Warehouse Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors?.name}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="C/O Attn Name"
                  placeholder="Enter C/O Attn Name"
                  name="attendanceName"
                  value={values.attendanceName}
                  onChange={handleChange}
                  error={errors?.attendanceName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SBDropdown
                  id="System"
                  required
                  label="System linked to this warehouse"
                  options={systemOptions}
                  onChange={([value]) => setFieldValue?.('systemId', value, false)}
                  name="systemId"
                  value={[values.systemId]}
                  error={errors?.systemId}
                  disabled={disabledSystemdropdown ? disabledSystemdropdown : formType === 'edit'}
                />
                {/* <SbTextField
                  required
                  label="System linked  to this warehouse"
                  name="systemId"
                  value={values.systemId}
                  onChange={handleChange}
                  error={errors?.systemId}
                /> */}
              </Grid>
            </Grid>
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  label="Email"
                  placeholder="Enter Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors?.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Contact
                  phoneName="number"
                  phoneNumber={values.number}
                  onChangePhoneNumber={handleChange}
                  label="Warehouse Contact No *"
                  telCountryCodeName="countryCode"
                  telCountryCode={values.countryCode}
                  telCountryCodeDisable
                  onChangeTelCountryCode={handleChange}
                  setDefaultTelephone={(value: number) => setFieldValue('countryCode', value)}
                  error={errors?.number || errors?.countryCode}
                />
              </Grid>
            </Grid>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        <Grid item container xs={12} alignItems="baseline" spacing={2}>
          <Grid item xs={12} md={2.5} lg={2}>
            <FormLabel className="md-text font-medium">Address</FormLabel>
          </Grid>
          <Grid container item xs={12} md={9.5} lg={10} columnSpacing={4} rowSpacing={2}>
            {/* <Grid container item mb={2}>
              <Grid item xs={12} md={6}>
                <SbTextField label="Enter Location" required />
              </Grid>
            </Grid> */}
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="Address Line 1"
                  placeholder="Enter Address Line 1"
                  name="addressLine1"
                  value={values.addressLine1}
                  error={errors?.addressLine1}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SbTextField
                  label="Address Line 2"
                  placeholder="Enter Address Line 2"
                  name="addressLine2"
                  value={values.addressLine2}
                  error={errors?.addressLine2}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="Pincode"
                  placeholder="Enter Pincode"
                  name="pincode"
                  value={values.pincode}
                  error={errors?.pincode}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="City"
                  placeholder="Enter City"
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  error={errors?.city}
                />
              </Grid>
            </Grid>
            <Grid container item columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                <SbTextField
                  required
                  label="State"
                  placeholder="Enter State"
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  error={errors?.state}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SbTextField label="Country" placeholder="Enter Country" value={organizationCountry?.name} disabled />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const FormLabel = styled(Typography)`
  min-width: 280px;
`;

export default CreateWarehouseForm;
