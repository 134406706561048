import FilterSelect from '@Components/Filter/Dropdown';
import { RootStateType } from '@Store';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import DateRangePicker from '@Components/DateRangePicker';
import API from '@Services/apiAxios';
import { Destination } from '@Components/AdminHome/types/type';

// import DownloadButton from '@Components/AdminHome/DownlaodButton';
import CustomTabs from '@Components/AdminHome/CustomTabs';
import UserDestinationExportTable from './UserDestinationTable';
import UserDestinationsImportTable from './UserDestinationImportTable';

export interface Shipment {
  id: string;
  status: string;
  account: string;
}

const UserDestinations = () => {
  const [system, setSystem] = useState<string[]>([]);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [destinationsImport, setDestinationsImport] = useState<Destination[]>([]);
  const [destinationsExport, setDestinationsExport] = useState<Destination[]>([]);

  const [destinationShipmentSort, setDestinationShipmentSort] = useState<boolean>(true);
  const [destinationWeightSort, setDestinationWeightSort] = useState<boolean>(false);
  const [destinationRevenueSort, setDestinationRevenueSort] = useState<boolean>(false);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
  };

  const fetchDataForDestinationsImport = async () => {
    try {
      let sortBy = '';
      if (destinationShipmentSort) {
        sortBy = 'shipments';
      } else if (destinationWeightSort) {
        sortBy = 'weight';
      }

      const resp = await API.get('/analytics/shipment/topten/destinations', {
        params: {
          'system[]': system,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          sortBy: sortBy || 'shipments',
          isImport: true,
        },
      });
      setDestinationsImport(resp.data.data);
    } catch (error) {
      console.error('Error in fetching data for customers', error);
    }
  };

  const fetchDataForDestinationsExport = async () => {
    try {
      let sortBy = '';
      if (destinationShipmentSort) {
        sortBy = 'shipments';
      } else if (destinationWeightSort) {
        sortBy = 'weight';
      }

      const resp = await API.get('/analytics/shipment/topten/destinations', {
        params: {
          'system[]': system,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          sortBy: sortBy || 'shipments',
          isImport: false,
        },
      });

      setDestinationsExport(resp.data.data);
    } catch (error) {
      console.error('Error in fetching data for customers', error);
    }
  };

  useEffect(() => {
    fetchDataForDestinationsImport();
    fetchDataForDestinationsExport();
  }, [
    system,
    dateRange.endDate,
    dateRange.startDate,
    destinationShipmentSort,
    destinationWeightSort,
    destinationRevenueSort,
  ]);

  const tabLabels = ['Export', 'Import'];

  const tabContent = {
    Export: (
      <UserDestinationsImportTable
        data={destinationsExport}
        setDestinationShipmentSort={setDestinationShipmentSort}
        setDestinationWeightSort={setDestinationWeightSort}
        setDestinationRevenueSort={setDestinationRevenueSort}
        destinationShipmentSort={destinationShipmentSort}
        destinationWeightSort={destinationWeightSort}
        destinationRevenueSort={destinationRevenueSort}
      />
    ),
    Import: (
      <UserDestinationExportTable
        data={destinationsImport}
        setDestinationShipmentSort={setDestinationShipmentSort}
        setDestinationWeightSort={setDestinationWeightSort}
        setDestinationRevenueSort={setDestinationRevenueSort}
        destinationShipmentSort={destinationShipmentSort}
        destinationWeightSort={destinationWeightSort}
        destinationRevenueSort={destinationRevenueSort}
      />
    ),
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
  };
  return (
    <Box
      sx={{
        borderRadius: '9px',
        border: '1px solid #F1F1F1',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 40px',
      }}
    >
      <Box sx={{ color: 'rgba(24, 29, 37, 1)', fontSize: '24px', fontWeight: 500, lineHeight: '32px' }}>
        Top Destinations
      </Box>

      <Box sx={{}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row-reverse' },
            gap: '10px',
            height: 'fit-content',
            position: 'relative',
            top: '45px',
          }}
        >
          {/* <Box>
            <DownloadButton
              name={'Download'}
              onClick={() => {
                console.info('click');
              }}
            />
          </Box> */}
          <Box>
            <DateRangePicker onChange={onDateChange} value={dateRange} />
          </Box>
          <Box>
            <FilterSelect
              id="system"
              label="Select system"
              options={loggedInUserSystemDropdownList}
              value={system}
              onChange={onSystemFilter}
            />
          </Box>
        </Box>
        <Box sx={{ marginTop: { xs: '70px', sm: '0px' }, marginBottom: { xs: '70px', sm: '0px' } }}>
          <CustomTabs tabs={tabLabels} tabContent={tabContent} />
        </Box>
      </Box>
    </Box>
  );
};

export default UserDestinations;
