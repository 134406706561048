import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import {
  ErrorType,
  GlobalChargeListResponse,
  GlobalChargePostData,
  GlobalChargeQueryParam,
  GlobalChargeSingleResponse,
} from './Type';

const createGlobalCharge = createAsyncThunk<GlobalChargeSingleResponse, GlobalChargePostData>(
  'global-charge/create',
  async (payload, { dispatch }) => {
    try {
      const res = await API.post('/shipment/global-charge', payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
          navigateTo: `/global-charges?system=${payload._systemId}&service=${payload._courierId}`,
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const updateGlobalChargeById = createAsyncThunk<GlobalChargeSingleResponse, [string, GlobalChargePostData]>(
  'global-charge/edit',
  async ([id, payload], { dispatch }) => {
    try {
      const res = await API.put(`/shipment/global-charge/${id}`, payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
          navigateTo: `/global-charges?system=${payload._systemId}&service=${payload._courierId}`,
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getGlobalChargeList = createAsyncThunk<
  GlobalChargeListResponse,
  GlobalChargeQueryParam,
  { rejectValue: ErrorType }
>('global-charge/list', async queryParams => {
  const res = await API.get('/shipment/global-charge', { params: queryParams });
  return res.data;
});

const getGlobalChargeById = createAsyncThunk('global-charge', async (id: string) => {
  const res = await API.get(`/shipment/global-charge/${id}`);
  return res.data;
});

export { createGlobalCharge, updateGlobalChargeById, getGlobalChargeList, getGlobalChargeById };
