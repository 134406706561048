import { FormHelperText, Grid, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { Details, Name, Pincode, SubHeading } from './Styles';
import { createShipmentContextType, userDetailsType } from '../../../types/shipment';
import CreateShipmentContext from '../../../context/CreateShipment';
import { getFormattedContact } from '../../../services/getFormattedContact';

interface DetailsPropsType extends userDetailsType {
  label: string;
  activeStep?: number;
  handleChange?: (data: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, label: string) => void;
  editShipment?: boolean;
  error?: string;
}

interface EditActionPropsType {
  activeStep: number;
}

export function EditAction({ activeStep }: EditActionPropsType) {
  const { setActiveStep } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  return (
    <img
      src="/images/Boxes/Edit.svg"
      alt="edit"
      className="m-1"
      style={{ cursor: 'pointer' }}
      onClick={() => setActiveStep(activeStep)}
    />
  );
}

export function ShipperConsigneeSummaryDetails({
  label,
  name,
  line1,
  line2,
  city,
  state,
  country,
  pincode,
  email,
  phoneNumber,
  telephoneCode,
  activeStep,
  handleChange,
  editShipment,
  error,
  IdNumber,
  IdType,
  type,
}: DetailsPropsType) {
  return (
    <Grid item xs={12}>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <SubHeading>{label}</SubHeading>
        </Grid>
        {editShipment && typeof activeStep === 'number' && (
          <Grid item>
            <EditAction activeStep={activeStep} />
          </Grid>
        )}
      </Grid>
      {handleChange ? (
        <div className="mb-1">
          <TextField
            variant="standard"
            name="name"
            value={name}
            onChange={e => handleChange(e, label)}
            error={!!error}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </div>
      ) : (
        <Name>{name}</Name>
      )}
      <Details>{`${line1}, ${line2 ? `${line2}, ` : ''} ${city}, ${state}, ${country}`}</Details>
      <Pincode>Pincode : {pincode}</Pincode>
      {type && <Details>{` ${label} type : ${type}`}</Details>}
      {IdNumber && <Details>{` ${label} Id : ${IdNumber}`}</Details>}
      {IdType && <Details>{` ${label} ID Type : ${IdType}`}</Details>}
      {email && (
        <Details>
          <MailOutlineIcon className="mr-1" />
          {email}
        </Details>
      )}
      <Details>
        <CallOutlinedIcon className="mr-1" />
        {getFormattedContact(`${telephoneCode}${phoneNumber}`)}
      </Details>
    </Grid>
  );
}
