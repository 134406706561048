import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { styled } from '@mui/system';

import { Grid, TableBody, TableHead, TablePagination, TableRow, useTheme } from '@mui/material';

import FilterSearch from '@Components/Filter/Search';

import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import Tooltip from '@Components/Tooltip';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// ICONS

import { AppDispatch, RootStateType } from '@Store';

import { getSystemList, onChangePagination, resetCurrentPage } from '@Reducers/Systems';
import { FilterObj } from './Types';
import { SystemListQuery } from '@Reducers/Systems/Type';
import { Link } from 'react-router-dom';
import { SystemObj } from '@Reducers/Systems/Type';
import Image from '@Components/Image';
import { downloadPincode } from '@Reducers/Systems/actions';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const SearchFilterList = [
  { label: 'Name', value: 'name' },
  { label: 'Warehouse', value: 'warehouseName' },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  // padding: '32px 16px',
  margin: '32px 0',
  display: 'grid',
  gridTemplateColumns: '510px 1fr fit-content(140px) fit-content(140px) 140px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 0,
    gridTemplateColumns: '490px 1fr fit-content(140px) fit-content(140px) 140px',
  },
}));

// const actionIconStyle = { width: '20px', height: '20px', cursor: 'pointer' };

function LabelValue({ label, value }: { label: string; value: string }) {
  return (
    <>
      <Grid container>
        <Grid item xs={4} className="font-medium">
          {label}
        </Grid>
        <Grid item xs={0.5} className="font-medium">
          :
        </Grid>
        <Grid className="sm-text">{value}</Grid>
      </Grid>
    </>
  );
}

function Actions(props: { system: SystemObj }) {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const isDownloadPincodeAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.System.download_pincode,
    PERFORM_ACTION.write,
  );

  const isEditPincodeAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.System.edit_system,
    PERFORM_ACTION.write,
  );

  const isViewPincodeAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.System.view_pincode,
    PERFORM_ACTION.write,
  );

  return (
    <Grid container item justifyContent="center" gap={2} alignItems="center">
      <Grid item textAlign="center">
        {isViewPincodeAllowed && (
          <Tooltip title="View Pincode">
            <Link to={`/system/pincode/edit/${props.system._id}`} style={{ color: theme.palette.primary.main }}>
              <RemoveRedEyeIcon style={{ color: '#000000' }} />
            </Link>
          </Tooltip>
        )}
      </Grid>
      {isDownloadPincodeAllowed && (
        <Tooltip title="Download Pincode">
          <Grid
            item
            textAlign="center"
            className="cursor-pointer"
            onClick={() => {
              dispatch(downloadPincode(props.system._id));
            }}
          >
            <Image src="/images/file_download.svg" alt="Edit" />
          </Grid>
        </Tooltip>
      )}
      {isEditPincodeAllowed && (
        <Grid item textAlign="center">
          <Tooltip title="Edit System">
            <Link to={`/system/edit/${props.system._id}`} style={{ color: theme.palette.primary.main }}>
              <Image src="/images/icons/EditAction.svg" alt="Edit" />
            </Link>
          </Tooltip>
        </Grid>
      )}
      {/* <Grid xs={6} textAlign="center">
      <Tooltip title="View">
        <Link to={`/warehouse/edit/${props.warehouse._id}`} style={{ color: theme.palette.primary.main }}>
          <Image src="/images/icons/DeleteAction.svg" alt="Delete" />
        </Link>
      </Tooltip>
    </Grid> */}

      {/* <Tooltip title="Shipping Label">
        <ShippingLabelIcon style={actionIconStyle} />
      </Tooltip> */}
    </Grid>
  );
}

function SystemTable() {
  const {
    data: { list, currentPage = 1, perPage = 100 },
  } = useSelector((state: RootStateType) => state.System);

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell width="100px">Sr no.</TableHeadCell>
            <TableHeadCell width="30%">System Name</TableHeadCell>
            <TableHeadCell>System Contact</TableHeadCell>
            <TableHeadCell width="20%">Warehouse</TableHeadCell>
            <TableHeadCell>Action</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map((data, index) => (
            <SBTableRow key={data._id}>
              <TableRowCell align="center" className="md-text">
                <div>{index + currentPage * perPage + 1}</div>
                {/* index + 1 + (currentPage - 1) * perPage */}
              </TableRowCell>
              <TableRowCell align="left" className="md-text">
                <Grid container>
                  <Grid item xs={12} className="font-semibold">
                    {data.name}
                  </Grid>
                </Grid>
                <LabelValue label="Created On:" value={moment(data.createdAt).format('DD/MM/YYYY')} />
                <LabelValue label="Updated On:" value={moment(data.updatedAt).format('DD/MM/YYYY')} />
              </TableRowCell>
              <TableRowCell className="md-text">
                <LabelValue
                  label="Number"
                  value={data?.contact ? `+${data?.contact?.phone?.telCode} ${data?.contact?.phone?.number}` : 'N/A'}
                />
                <LabelValue label="Contact Email" value={data?.contact ? data?.contact?.email : 'N/A'} />
                <LabelValue label="Support Email" value={data?.contact ? data?.contact?.supportEmail : 'N/A'} />
              </TableRowCell>
              <TableRowCell align="center" className="md-text font-medium">
                {data?.warehouses?.map(x => x.name).join(', ') || 'N/A'}
              </TableRowCell>
              <TableRowCell>
                <Actions system={data} />
              </TableRowCell>
            </SBTableRow>
          ))}
        </TableBody>
      </SBTable>
    </div>
  );
}

export default function SystemList() {
  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const [filterText, setFilterText] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const {
    data: { currentPage, perPage, totalCount },
  } = useSelector((state: RootStateType) => state.System);

  useEffect(() => {
    dispatch(getSystemList({ currentPage: 1, perPage: 10 }));
    dispatch(resetCurrentPage());
  }, []);

  const getFilterObj = (options: FilterObj): SystemListQuery => {
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filterQuery: SystemListQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,

      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    return filterQuery;
  };

  return (
    <div style={{ width: '100%', overflow: 'hidden' }}>
      <TableActionContainer>
        <FilterSearch
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={filterText}
          onTextChange={e => {
            setFilterText(e.target.value);
          }}
          onTextSearch={() => {
            dispatch(getSystemList(getFilterObj({})));
            dispatch(resetCurrentPage());
          }}
          onTextClear={() => {
            setFilterText('');
            dispatch(getSystemList(getFilterObj({ searchValue: undefined })));
            dispatch(resetCurrentPage());
          }}
        />
      </TableActionContainer>

      <SystemTable />
      {/* {!!totalCount && !!perPage && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage - 1}
            rowsPerPage={perPage}
            onPageChange={(_, page) => {
              dispatch(getSystemList(getFilterObj({ currentPage: page + 1 })));
            }}
            onRowsPerPageChange={event => {
              dispatch(getSystemList(getFilterObj({ perPage: parseInt(event.target.value) })));
            }}
          />
        </>
      )} */}
      {!!totalCount && !!perPage && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage}
            rowsPerPage={perPage}
            onPageChange={(_, page) => {
              dispatch(onChangePagination(page));
            }}
            onRowsPerPageChange={event => {
              dispatch(getSystemList(getFilterObj({ perPage: parseInt(event.target.value) })));
            }}
          />
        </>
      )}
    </div>
  );
}
