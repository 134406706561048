import Divider from '@Components/Divider';
import SBDropdown from '@Components/Dropdown';
import { DropdownPropsType } from '@Components/Dropdown/Type';
import { FormContent, FormLabel } from '@Components/Form';
import { RootStateType } from '@Store';
import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

interface PropsType {
  value: string[];
  onChange: DropdownPropsType['onChange'];
  multiple?: boolean;
  error?: string;
  disabled?: boolean;
}
function System(props: PropsType) {
  const systemOptions = useSelector((state: RootStateType) => state.System.activeSystemDropdownList);
  // const systemOptions = SystemState?.data?.list?.map(system => ({ display: system.name, value: system._id }));

  return (
    <Grid item container xs={12} alignItems="center">
      <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
        <FormLabel>System*</FormLabel>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <FormContent>
          <SBDropdown
            id="System"
            required
            label=""
            options={systemOptions}
            onChange={props.onChange}
            name="system"
            value={props.value}
            error={props.error}
            multiple={!!props.multiple}
            disabled={props.disabled}
            placeholder="Select System"
          />
        </FormContent>
      </Grid>
      <Divider className="my-2" />
    </Grid>
  );
}

export default System;
