import React from 'react';
import { Button, alpha, styled } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ViewBtn = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.border.grey,
  color: alpha(`${theme.palette.primary.contrastText}`, 1),
  // color: alpha(`${theme.palette.primary.main}`, 0.6),
  // ':hover': {
  //   background: 'transparent',
  //   borderColor: theme.palette.border.grey,
  // },
  fontSize: '16px',
}));

interface PropsType {
  setViewMore: (view: boolean) => void;
  viewMore: boolean;
}

function ViewAction({ setViewMore, viewMore }: PropsType) {
  return (
    <ViewBtn
      onClick={() => setViewMore(!viewMore)}
      variant="contained"
      endIcon={
        viewMore ? (
          <KeyboardArrowUpIcon sx={{ color: 'white' }} />
        ) : (
          <KeyboardArrowDownIcon color="disabled" sx={{ color: 'white' }} />
        )
      }
      className="normal-case font-normal"
    >
      {`View ${viewMore ? 'less' : 'more'}`}
    </ViewBtn>
    // <ViewBtn
    //   onClick={() => setViewMore(!viewMore)}
    //   variant="contained"
    //   endIcon={viewMore ? <KeyboardArrowUpIcon color="disabled" /> : <KeyboardArrowDownIcon color="disabled" />}
    //   className="normal-case font-normal"
    // >
    //   {`View ${viewMore ? 'less' : 'more'}`}
    // </ViewBtn>
  );
}

export default ViewAction;
