import React from 'react';
import { Grid, TableBody, TableHead, TableRow } from '@mui/material';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { RateRowService } from '../../PricingList/RateRow';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';
import { SystemState } from '@Reducers/Systems/Type';
import { CopyRate as CopyRateType } from '../type';
import SbButton from '@Components/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';

interface PropsType {
  lists: CopyRateType[];
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
  showAccountNumber: boolean;
}

function CopyRateTable(props: PropsType) {
  return (
    <>
      <SBTableWrapper className="mt-2">
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ minWidth: '300px' }}>Services</TableHeadCell>
              <TableHeadCell sx={{ minWidth: '150px' }}>System</TableHeadCell>
              {props.showAccountNumber && <TableHeadCell>Account No.</TableHeadCell>}
              <TableHeadCell>Min Weight</TableHeadCell>
              <TableHeadCell>Max Weight</TableHeadCell>
              <TableHeadCell>Validity</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.lists?.map((rate, index) => (
              <CopyRateRow
                showAccountNumber={props.showAccountNumber}
                key={index}
                index={index}
                {...rate}
                onDelete={props.onDelete}
                onEdit={props.onEdit}
              />
            ))}
            {/* rate.system + rate.csp + rate.documentRates */}
          </TableBody>
        </SBTable>
      </SBTableWrapper>
    </>
  );
}

interface CopyRateRowType extends CopyRateType {
  onDelete: PropsType['onDelete'];
  onEdit: PropsType['onEdit'];
  index: number;
  showAccountNumber: boolean;
}

function CopyRateRow(props: CopyRateRowType) {
  const startFormattedDate = moment(props.startDate).format('DD MMM YYYY');
  const endFormattedDate = moment(props.endDate).format('DD MMM YYYY');
  return (
    <TableRow>
      <TableRowCell>
        <RateRowService cspId={props.csp} />
      </TableRowCell>
      <TableRowCell>
        <SystemName systemIds={props.system} />
      </TableRowCell>
      {props.showAccountNumber && (
        <TableRowCell>
          <Grid container className="md-text">
            {props.accountNumber?.join(', ')}
          </Grid>
        </TableRowCell>
      )}
      <TableRowCell>
        <Grid container className="md-text">
          {props.minWeight === 0 ? 0 : props.minWeight || 'N/A'}
        </Grid>
      </TableRowCell>
      <TableRowCell>
        <Grid container className="md-text">
          {props.maxWeight === 0 ? 0 : props.maxWeight || 'N/A'}
        </Grid>
      </TableRowCell>
      <TableRowCell>
        <Grid container className="md-text">{`${startFormattedDate} to ${endFormattedDate}`}</Grid>
      </TableRowCell>
      <TableRowCell>
        <Actions index={props.index} onDelete={props.onDelete} onEdit={props.onEdit} />
      </TableRowCell>
    </TableRow>
  );
}

function SystemName({ systemIds }: { systemIds: string[] }) {
  const systemHashById = useSelector<RootStateType>(
    state => state.System.activeSystemListHashById,
  ) as SystemState['activeSystemListHashById'];

  const systemNames = systemIds
    .map(x => systemHashById?.[x]?.name)
    .filter(x => x)
    .join(', ');
  return (
    <Grid container className="md-text">
      {systemNames}
    </Grid>
  );
}

interface ActionType {
  onDelete: PropsType['onDelete'];
  onEdit: PropsType['onEdit'];
  index: number;
}

function Actions({ onEdit, onDelete, index }: ActionType) {
  return (
    <section className="px-1 py-1 w-full md-text underline cursor-pointer rounded text-center bg-p-light">
      <SbButton>
        <EditIcon onClick={() => onEdit(index)} />
      </SbButton>
      <SbButton className="ml-1" onClick={() => onDelete(index)}>
        <DeleteIcon />
      </SbButton>
    </section>
  );
}

export default CopyRateTable;
