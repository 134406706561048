import { globalStateType } from '@Reducers/Global/Type';
import {
  WarehouseCreateResponse,
  WarehouseListForShipmentResponse,
  WarehouseListResponse,
  WarehouseState,
} from './Type';
import { getFullAddress } from './helper';

function getWarehouseListPending(state: WarehouseState) {
  state.loading = true;
}

function getWarehouseListFulfilled(state: WarehouseState, data: { payload: WarehouseListResponse }) {
  const { list } = data.payload.data;
  state.data.selectedList = list;
  state.data.totalCount = list?.length;
  // state.data.currentPage = currentPage;
  // state.data.perPage = perPage;
  const startIndex = state.data.currentPage * state.data.perPage;
  const endIndex = startIndex + state.data.perPage;
  state.data.list = list?.slice(startIndex, endIndex);
  // state.data.list = list;
  state.loading = false;
}

function getWarehouseListForShipmentFulfilled(
  state: WarehouseState,
  data: { payload: WarehouseListForShipmentResponse & { countriesObj: globalStateType['countriesObj'] } },
) {
  const { countriesObj, data: resData } = data.payload;
  const { list } = resData;

  state.warehouseListForShipmentOptions = list.map(({ name, _id, _addressId }) => ({
    display: name,
    value: _id,
    secondary: getFullAddress({ ..._addressId, country: countriesObj?.[_addressId?.country]?.name || '' }),
    metadata: { ..._addressId, country: countriesObj?.[_addressId?.country]?.name || '' },
  }));
  const hashObj: WarehouseState['warehouseListForShipmentOptionsHashById'] = {};
  state.warehouseListForShipmentOptionsHashById = list.reduce((op, system) => ((op[system._id] = system), op), hashObj);
  state.warehouseListForShipent.list = list;
  state.loading = false;
}

function getWarehouseListRejected(state: WarehouseState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function createWarehousePending(state: WarehouseState) {
  state.loading = true;
}

function createWarehouseFulfilled(state: WarehouseState, _data: { payload: WarehouseCreateResponse }) {
  // state.isRedirect = true;
  state.loading = false;
}

function updateWarehouseFulfilled(state: WarehouseState, data: { payload: WarehouseCreateResponse }) {
  state.singeWarehouse = data.payload.data;
  state.loading = false;
}

function createWarehouseRejected(state: WarehouseState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function getWarehouseByIdPending(state: WarehouseState) {
  state.loading = true;
}

function getWarehouseByIdFulfilled(state: WarehouseState, data: { payload: WarehouseCreateResponse }) {
  state.singeWarehouse = data.payload.data;
  state.loading = false;
}

function getWarehouseByIdRejected(state: WarehouseState) {
  state.loading = false;
  state.error = { msg: 'The desired Warehouse does not exist.', isError: true };
}

export {
  getWarehouseListPending,
  getWarehouseListFulfilled,
  getWarehouseListRejected,
  //
  createWarehousePending,
  createWarehouseFulfilled,
  createWarehouseRejected,
  //
  getWarehouseByIdPending,
  getWarehouseByIdFulfilled,
  getWarehouseByIdRejected,
  //
  updateWarehouseFulfilled,

  //
  getWarehouseListForShipmentFulfilled,
};
