import React, { KeyboardEvent } from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FormHelperText, InputLabel } from '@mui/material';
import { PickerStateProps } from '@mui/x-date-pickers/internals';

export interface DatePickerProps {
  label?: string;
  inputFormat?: string;
  value?: Dayjs | null;
  handleChange: PickerStateProps<Dayjs, Dayjs>['onChange'];
  required?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  error?: string;
  disabled?: boolean;
}

function DatePicker(props: DatePickerProps): JSX.Element {
  const { label, inputFormat, value, handleChange, minDate, maxDate, error } = props;

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Prevent all keyboard input including Backspace
    e.preventDefault();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label && (
        <InputLabel className={'font-medium'} sx={props.label ? { mb: '6px' } : {}} required={props.required}>
          {label || 'Date'}
        </InputLabel>
      )}
      <DesktopDatePicker
        className="w-full date-picker"
        inputFormat={inputFormat || 'DD/MM/YYYY'}
        disableMaskedInput={true} // Disable masked input
        value={value}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            error={!!error}
            onKeyDown={onKeyDown} // Prevent all keyboard input
            inputProps={{
              ...params.inputProps,
              readOnly: true,
              style: {
                cursor: 'not-allowed',
              },
              // Make the input field readonly
            }}
          />
        )}
        minDate={minDate}
        maxDate={maxDate}
        disabled={props.disabled}
      />
      {error && (
        <FormHelperText error className="m-0">
          {error}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
}

export default DatePicker;
