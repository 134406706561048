import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Image from '../../Image';
import { VerticalTrackingProps } from './type';
import { SHIPMENT_STATUS } from '../../../constants/Shipment';
import {
  SBTimeline,
  SBTimelineItem,
  SBTimelineOppositeContent,
  SBTimelineSeparator,
  SBTimelineDot,
  SBTimelineConnector,
  SBTimelineContent,
  ShipmentStatus,
  ShipmentLocation,
  VerticalTrackingWrapper,
  SBTimelineOppositeContentDate,
} from './styles';
import { hasAdminAccess } from '@Services/hasAdminAccess';

const VerticalTracking = (props: VerticalTrackingProps) => {
  function convertToDDMMYY(formattedDate: string) {
    const datePart = formattedDate.split(',')[0];
    const dateObject = new Date(datePart);
    const dd = String(dateObject.getDate()).padStart(2, '0');
    const mm = String(dateObject.getMonth() + 1).padStart(2, '0');
    const yy = String(dateObject.getFullYear());

    return `${dd}/${mm}/${yy}`;
  }
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <VerticalTrackingWrapper>
      {props?.data?.length ? (
        <>
          {props.data.map((outer, outerInd) => (
            <SBTimeline
              key={'outer-' + outerInd}
              className="mt-4"
              is_last={props.data.length === outerInd + 1 ? '1' : '0'}
            >
              {/* align="alternate" */}
              <SBTimelineItem key={outerInd}>
                <SBTimelineOppositeContentDate className="pt-0 pb-0">
                  <Typography className="md-text font-normal">
                    {isSmallScreen ? convertToDDMMYY(outer.formattedDate) : outer.formattedDate}
                  </Typography>
                </SBTimelineOppositeContentDate>

                <SBTimelineSeparator>
                  <SBTimelineDot />
                  <SBTimelineConnector />
                </SBTimelineSeparator>

                <SBTimelineContent className="pt-0 pb-0" />
              </SBTimelineItem>
              {outer.details?.length ? (
                outer.details.map((item, innerInd) => (
                  <SBTimelineItem key={'innder-' + innerInd}>
                    <SBTimelineOppositeContent
                      className="sm-text font-normal pt-0 pb-0"
                      // sx={{
                      //   padding: isSmallScreen && innerInd === outer.details.length - 1 ? '5px !important' : '0px',
                      // }}
                    >
                      {item?.time || ''}
                    </SBTimelineOppositeContent>

                    <SBTimelineSeparator
                      sx={{
                        marginLeft:
                          outerInd === 0 && innerInd === 0
                            ? { sm: '-3px', md: '-4px', lg: '-3px', xl: '-0.5px' }
                            : '0px',
                      }}
                    >
                      {outerInd === 0 && innerInd === 0 ? (
                        <Image
                          src="/images/shipment/BoxIcon.svg"
                          alt="Box"
                          style={{ maxWidth: isSmallScreen ? '20px' : '24px' }}
                        />
                      ) : (
                        <SBTimelineDot />
                      )}
                      <SBTimelineConnector />
                    </SBTimelineSeparator>

                    <SBTimelineContent className="pt-0 pb-0">
                      <div className="flex">
                        <ShipmentStatus className="sm-text font-normal flex flex-col">
                          <p>{SHIPMENT_STATUS[item.status as keyof typeof SHIPMENT_STATUS]?.label || ''}</p>
                          <Typography className="xs-text font-normal mb-2" sx={{ color: '#AFAFAF' }}>
                            {item.msg}
                          </Typography>
                        </ShipmentStatus>
                        <ShipmentLocation className="sm-text font-normal flex">
                          {item.location && (item.location !== '-' || !isSmallScreen)
                            ? `${item.location.charAt(0).toUpperCase()}${item.location.slice(1).toLowerCase()}`
                            : ''}
                        </ShipmentLocation>
                      </div>
                      <Typography className="xs-text font-normal mb-2" sx={{ color: '#AFAFAF' }}>
                        {/* {item.msg} */}
                        {item.status === SHIPMENT_STATUS.rto?.value && props.rtoShipmentNumber && (
                          <>
                            <Typography className="xs-text flex items-center">
                              <img src={'/images/paid.svg'} alt="logistics" className="mr-1" />
                              RTO shipment has been booked with shipment number :{' '}
                              <a
                                href={`${hasAdminAccess() ? `/shipment/admin/edit/` : '/shipment/history/'}${
                                  props.rtoShipmentNumber
                                }`}
                                target="_blank"
                                className="link ml-1/2"
                              >
                                {props.rtoShipmentNumber}
                              </a>
                            </Typography>
                          </>
                        )}
                      </Typography>
                    </SBTimelineContent>
                  </SBTimelineItem>
                ))
              ) : (
                <></>
              )}
            </SBTimeline>
          ))}
        </>
      ) : (
        <></>
      )}
    </VerticalTrackingWrapper>
  );
};

export default VerticalTracking;
