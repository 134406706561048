import SBDropdown from '@Components/Dropdown';

import { RootStateType } from '@Store';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropdownOptionType, DropdownPropsType } from './Type';

interface Props {
  label?: string;
  placeholder?: string;
  value: string | string[];
  onChange: DropdownPropsType['onChange'];
  required?: boolean;
  error?: string;
  setDefaultCountry?: (country: string, countryId: string) => void;
  // setDefaultCountry?: (country: string) => void;
  disabled?: boolean;
  multiple?: boolean;
  options?: Array<DropdownOptionType>;
}

function Country(props: Props) {
  const id = React.useId();
  const {
    error,
    required,
    value,
    label = 'Country',
    placeholder = 'Select Country',
    setDefaultCountry,
    disabled = false,
    multiple,
    options,
  } = props;
  const { countryList } = useSelector((state: RootStateType) => state?.global);
  const countryId = useSelector((state: RootStateType) => state.orgSetup.organizationData?._countryId);
  const countriesObj = useSelector((state: RootStateType) => state.global.countriesObj);

  useEffect(() => {
    if (setDefaultCountry && countryId && countriesObj[countryId]) {
      const selectedCountry = countriesObj[countryId].name;
      setDefaultCountry(countryId, selectedCountry);
      // setDefaultCountry(selectedCountry);
    }
  }, [countryId, JSON.stringify(countriesObj)]);

  return (
    <SBDropdown
      multiple={multiple}
      required={required}
      label={label}
      id={id}
      placeholder={placeholder}
      options={options || countryList}
      value={value ? (Array.isArray(value) ? value : [value]) : []}
      onChange={props.onChange}
      error={error}
      disabled={disabled}
    />
  );
}

export default Country;
