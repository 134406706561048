import React from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

import { Box, useTheme } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Tooltip, Legend);

interface MonthlyData {
  month: string;
  year: string;
  result: {
    totalShipment: number;
    totalWeight: number;
    totalRevenue: number;
    totalGrossProfit: number;
  };
}

interface MixedChartShipmentProps {
  data: MonthlyData[] | undefined;
}

const MixedChartShipment: React.FC<MixedChartShipmentProps> = ({ data }) => {
  const months = data?.map(item => item.month);
  const weights = data?.map(item => item.result.totalShipment);
  const revenues = data?.map(item => item.result.totalRevenue);
  const amounts = data?.map(item => item.result.totalGrossProfit);
  const theme = useTheme();

  const chartData: any = {
    labels: months,
    datasets: [
      {
        type: 'line' as const,
        label: 'Revenue',
        data: revenues,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        yAxisID: 'y1',
      },
      {
        type: 'line' as const,
        label: 'Gross Profit',
        data: amounts,
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        yAxisID: 'y1',
      },
      {
        type: 'bar' as const,
        label: 'Weight',
        data: weights,
        backgroundColor: '#D9D9D9',
        yAxisID: 'y',
      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: 'linear' as const,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Shipment',
        },
        beginAtZero: true,
      },
      y1: {
        type: 'linear' as const,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Revenue/Grossprofit (INR)', // Updated title
        },
        grid: {
          drawOnChartArea: false,
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Show legends for clarity
      },
    },
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: {
            xs: '10px', // Smaller gap on small screens
            sm: '50px', // Larger gap on medium and above
          },
          flexDirection: {
            xs: 'column', // Stack items vertically on small screens
            sm: 'row', // Align items horizontally on medium and above
          },
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <p style={{ display: 'flex', gap: '10px', alignItems: 'center', fontSize: '0.9rem' }}>
          <span
            style={{
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.main,
              display: 'inline-block',
              width: '15px',
              height: '15px',
            }}
          ></span>
          <span>Revenue/Kg</span>
        </p>
        <p style={{ display: 'flex', gap: '10px', alignItems: 'center', fontSize: '0.9rem' }}>
          <span
            style={{
              borderRadius: '50%',
              backgroundColor: theme.palette.secondary.main,
              display: 'inline-block',
              width: '15px',
              height: '15px',
            }}
          ></span>
          <span>Grossprofit/Kg</span>
        </p>
        <p style={{ display: 'flex', gap: '10px', alignItems: 'center', fontSize: '0.9rem' }}>
          <span
            style={{
              borderRadius: '50%',
              backgroundColor: '#D9D9D9',
              display: 'inline-block',
              width: '15px',
              height: '15px',
            }}
          ></span>
          <span>Shipment</span>
        </p>
      </Box>
      <Bar data={chartData} options={options} />
    </Box>
  );
};

export default MixedChartShipment;
