/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { ErrorType, RatesType, chareableWeightObject, chareableWeightPayload } from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const getRates = createAsyncThunk('getRates', async ([postObj, isTarrifPricing]: [RatesType, boolean]) => {
  const rateFetchFor = postObj.rateFor;
  delete postObj.rateFor;
  // const res = await API.post(`/shipment/rate${isTarrifPricing ? '/default' : ''}`, postObj);
  if (isTarrifPricing) postObj.isDefaultSearch = true;

  /***
   * INSURANCE PACKAGING GET RATES
   */
  // postObj.insurance = true;
  // postObj.packaging = true;

  const res = await API.post(`/shipment/rate`, postObj);

  res.data.rateFetchFor = rateFetchFor;
  return res.data;
});

// const getChargeableWeight = createAsyncThunk(<> 'getRates', async (postObj: chareableWeightPayload) => {
//   const res = await API.post(`/shipment/rate/chargeableweight`, postObj);

//   // res.data.rateFetchFor = rateFetchFor;
//   return res.data;
// });

const getChargeableWeight = createAsyncThunk<chareableWeightObject, chareableWeightPayload, { rejectValue: ErrorType }>(
  'getChargeableWeight',
  async (postObj, { dispatch }) => {
    try {
      // const res = await API.post('/user/admin/on-board', postData);
      const res = await API.post(`/shipment/rate/chargeableweight`, postObj);

      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getDefaultRates = createAsyncThunk(
  'getDefaultRates',
  async ([postObj, isTarrifPricing]: [RatesType, boolean]) => {
    const rateFetchFor = postObj.rateFor;
    delete postObj.rateFor;
    // const res = await API.post(`/shipment/rate${isTarrifPricing ? '/default' : ''}`, postObj);
    if (isTarrifPricing) postObj.isDefaultSearch = true;

    /***
     * INSURANCE PACKAGING GET RATES
     */
    // postObj.insurance = true;
    // postObj.packaging = true;

    const res = await API.post(`/shipment/default-rate`, postObj);

    res.data.rateFetchFor = rateFetchFor;
    return res.data;
  },
);

const getBuyingRates = createAsyncThunk('getBuyingRates', async (postObj: RatesType) => {
  const rateFetchFor = postObj.rateFor;
  delete postObj.rateFor;

  /***
   * INSURANCE PACKAGING GET RATES
   */
  // postObj.insurance = true;
  // postObj.packaging = true;

  const res = await API.post(`/shipment/rate`, postObj);

  res.data.rateFetchFor = rateFetchFor;
  return res.data;
});

export { getRates, getBuyingRates, getDefaultRates, getChargeableWeight };
