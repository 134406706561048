import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  deleteDraftShipmentFulfilled,
  deleteDraftShipmentPending,
  deleteDraftShipmentRejected,
  getDraftShipmentListFulfilled,
  getDraftShipmentListPending,
  getDraftShipmentListRejected,
  updateDraftShipmentFulfilled,
} from './case';
import { createDraftShipmentFulfilled, createDraftShipmentPending, createDraftShipmentRejected } from './case';
import { getDraftShipmentByIdFulfilled, getDraftShipmentByIdPending, getDraftShipmentByIdRejected } from './case';
import { DraftShipmentState } from './Type';
import {
  getDraftShipmentList,
  createDraftShipment,
  getDraftShipmentById,
  updateDraftShipmentById,
  deleteDraftShipment,
} from './actions';

const initialState = <DraftShipmentState>{
  singeDraftShipment: null,
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  lists: [],
  selectedDraftShipmentId: null,
};

const reduxStateSlice = createSlice({
  name: 'global',
  initialState: { ...initialState },
  reducers: {
    resetDraftShipmentRedirect: function (state) {
      state.isRedirect = false;
    },
    resetDraftShipmentError: function (state) {
      state.error = {
        msg: '',
        isError: false,
      };
    },
    resetSingleDraftShipment: function (state) {
      state.singeDraftShipment = null;
    },
    setDraftShipmentId: function (state, action: PayloadAction<{ id: string | null }>) {
      state.selectedDraftShipmentId = action.payload.id;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getDraftShipmentList.pending, getDraftShipmentListPending);
    addCase(getDraftShipmentList.fulfilled, getDraftShipmentListFulfilled);
    addCase(getDraftShipmentList.rejected, getDraftShipmentListRejected);

    addCase(getDraftShipmentById.pending, getDraftShipmentByIdPending);
    addCase(getDraftShipmentById.fulfilled, getDraftShipmentByIdFulfilled);
    addCase(getDraftShipmentById.rejected, getDraftShipmentByIdRejected);

    addCase(createDraftShipment.pending, createDraftShipmentPending);
    addCase(createDraftShipment.fulfilled, createDraftShipmentFulfilled);
    addCase(createDraftShipment.rejected, createDraftShipmentRejected);

    addCase(updateDraftShipmentById.pending, createDraftShipmentPending);
    addCase(updateDraftShipmentById.fulfilled, updateDraftShipmentFulfilled);
    addCase(updateDraftShipmentById.rejected, createDraftShipmentRejected);

    addCase(deleteDraftShipment.pending, deleteDraftShipmentPending);
    addCase(deleteDraftShipment.fulfilled, deleteDraftShipmentFulfilled);
    addCase(deleteDraftShipment.rejected, deleteDraftShipmentRejected);
  },
});

export {
  getDraftShipmentList,
  createDraftShipment,
  updateDraftShipmentById,
  getDraftShipmentById,
  deleteDraftShipment,
};
export const { resetDraftShipmentRedirect, resetDraftShipmentError, resetSingleDraftShipment, setDraftShipmentId } =
  reduxStateSlice.actions;
export default reduxStateSlice.reducer;
