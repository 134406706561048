import Contact from '@Components/Contact';
import Divider from '@Components/Divider';
import SbTextField from '@Components/Textfield';
import { FormHelperText, Grid, Typography, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/system';
import React, { useEffect, useState } from 'react';

// ICONS
import SBDropdown from '@Components/Dropdown';
import FilePreview from '@Components/File/FilePreview';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched, FormikValues } from 'formik';
import { FileResultType } from './type';
import DragDrop from './DragDropSquad';
import { DropdownOption, signedUrlResponseType, SquadType, Zone, Item, WarehouseOption } from './type';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getLoggedInUserSystemList } from '@Reducers/Systems';
import { SQUAD_VEHICLES } from './constants';
import { getAdminListForTag } from '@Reducers/Admin/actions';
import API from '@Services/apiAxios';
import axios from 'axios';
import { getZonesFromSystem, getAdminWareHouse } from '@Reducers/Squad/actions';
import SquadKycModal from '../List/SquadKycModal';
import Loader from '@Components/Loader';
import RadioButton from '@Components/RadioButton';
interface CreateSquadFormProps {
  showBackgroundColor: boolean;
  formType?: 'create' | 'edit';
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<SquadType>['setFieldValue'];
  errors?: FormikErrors<SquadType>;
  systemOptions: { display: string; value: string }[];
  adminOptions: { display: string; value: string }[];
  squadId?: string;
  touched: FormikTouched<SquadType>;
  setQuadType: (value: string) => void;
}

export const initCreateSquad: SquadType = {
  username: '',
  name: '',
  password: '',
  telephoneCode: '91',
  phoneNumber: '',
  system: '',
  zones: [],
  reportTo: '',
  reportToPhoneNumber: '',
  vehicle: '',
  kyc: '',
  reportToTelephoneCode: '91',
  document: '',
  squadType: 'squad_driver',
};
const SquadTypeList = [
  { value: 'squad_driver', label: 'Squad Driver' },
  { value: 'warehouse_agent', label: 'Warehouse Agent' },
];

function CreateSquadForm(props: CreateSquadFormProps) {
  const {
    showBackgroundColor,
    values,
    errors,
    handleChange,
    setFieldValue,
    systemOptions,
    adminOptions,
    squadId,
    setQuadType,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const [fileError, setFileError] = useState<string>('');
  const [filteredZones, setFilteredZones] = useState<DropdownOption[]>([]);
  const [openKycModal, setOpenKycModal] = useState<boolean>(false);
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [warehouseOptions, setWarehouseOptions] = useState<WarehouseOption[]>([]);
  const loading = useSelector((state: RootStateType) => state.squadManagement.loading);
  const [kycLoading, setKycLoading] = useState(false);
  const getSignedUrl = async (fileType: string) => {
    const result = await API.post(`squad/file/upload-url/squad-document-UserDocument`, { mimeType: fileType });
    return result.data.data;
  };

  const isSquadDriver = values.squadType === 'squad_driver';

  const updateKycDocument = async (props: any) => {
    setKycLoading(true);
    const { fileData, fileExtension, fileName, fileSize, fileType } = props;
    const fileSizeNumber = parseInt(fileSize.split('')[0]);
    const uploadPayload = { value: fileName, fileSize: fileSizeNumber, fileType: fileExtension };

    let signedUrlResponse: signedUrlResponseType | null = null;

    try {
      signedUrlResponse = await getSignedUrl(fileExtension);
      await axios.put(signedUrlResponse?.signedUrl as string, fileData, {
        headers: { 'Content-Type': fileType, 'Content-Encoding': 'base64' },
      });
      const res = await API.post(`squad/admin/upload-document`, {
        ...uploadPayload,
        fileName: signedUrlResponse?.fileName,
      });
      setKycLoading(false);
      return res.data.documentData._id;
    } catch (error) {
      console.error('Error during upload', error);
    }
  };

  const onFileChange = async (data: FileResultType[]) => {
    if (fileError) setFileError('');
    if (!data.length) return setFileError('Please upload kyc file');
    try {
      const documentId = await updateKycDocument(data[0]);
      setFieldValue('document', documentId);
      setFieldValue('kyc', {
        fileName: data[0].fileName,
        fileSize: data[0].fileSize,
        fileType: data[0].fileType,
        fileExtension: data[0].fileExtension,
      });
    } catch (error) {
      setFileError('Error uploading file');
    }
  };

  useEffect(() => {
    dispatch(getLoggedInUserSystemList());
    dispatch(getAdminListForTag());
  }, []);
  useEffect(() => {
    if (values.squadType) {
      setQuadType(values.squadType);
    }
  }, [values.squadType]);
  useEffect(() => {
    if (values.document && errors?.kyc) {
      delete errors.kyc;
    }
  }, [values.document, errors]);
  useEffect(() => {
    if (values.system) {
      (async () => {
        try {
          const systemId = values?.system?._id ? values?.system?._id : values?.system;
          const response = await dispatch(getZonesFromSystem({ systemId: systemId }));
          const warehouse = await dispatch(getAdminWareHouse({ systemId }));
          const formattedOptions = (warehouse.payload as Item[])
            .filter((item): item is Exclude<Item, null> => item !== null)
            .flatMap(item =>
              item.warehouses.map(warehouse => ({
                display: warehouse.name,
                value: warehouse._id,
              })),
            );
          setWarehouseOptions(formattedOptions);
          const zonesForDropdown: DropdownOption[] = (response.payload as Zone[]).map(zone => ({
            display: zone.name,
            value: zone.name,
          }));

          setFilteredZones(zonesForDropdown);
        } catch (error) {
          console.error('Failed to fetch zones:', error);
        }
      })();
    }
  }, [values.system, dispatch, squadId]);

  const openKycDocument = async (id: string) => {
    try {
      setKycLoading(true);
      const res = await API.get(`/squad/file/get-url/${id}`);
      setDocumentUrl(res.data.data);
      setKycLoading(false);
    } catch (error) {
      console.error('Error fetching document URL:', error);
    }
    setOpenKycModal(true);
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };
  return (
    <>
      <Loader loading={loading || kycLoading} overly />
      <Grid
        container
        className="mt-3"
        style={{
          borderRadius: '12px',
          backgroundColor: showBackgroundColor ? alpha(theme.palette.primary.light, 0.4) : '',
        }}
      >
        {/* <Grid item xs={12}>
          <Typography className="org-text mb-4">Squad Onboarding</Typography>
        </Grid> */}
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">Username*</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={4.4}>
            <FormContent>
              <SbTextField
                required
                placeholder="Enter The Username"
                name="username"
                value={values.username}
                onChange={handleChange}
                error={errors?.username}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">Name*</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={4.4}>
            <FormContent>
              <SbTextField
                required
                placeholder="Enter your name"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={errors?.name}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">Squad type*</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={4.4}>
            <FormContent>
              <RadioButton
                containerClass="w-full"
                name="squadType"
                defaultValue={values.squadType}
                value={values.squadType}
                onChange={handleRadioChange}
                list={SquadTypeList.map(item => ({
                  ...item,
                  disabled: squadId ? item.value !== values.squadType : false,
                }))}
                sx={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'start' }}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">Password*</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={4.4}>
            <FormContent>
              <SbTextField
                name="password"
                placeholder="Enter The Password"
                value={values.password}
                onChange={handleChange}
                error={errors?.password}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>

        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
        {isSquadDriver && (
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel className="md-text font-medium">Contact*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={4.4}>
              <FormContent>
                <Contact
                  label="Mobile no.*"
                  phoneName="phoneNumber"
                  phoneNumber={values.phoneNumber}
                  onChangePhoneNumber={handleChange}
                  telCountryCodeName="telephoneCode"
                  telCountryCode={values.telephoneCode || '91'}
                  onChangeTelCountryCode={handleChange}
                  setDefaultTelephone={(value: number) => setFieldValue('telephoneCode', value)}
                  error={errors?.phoneNumber}
                />
              </FormContent>
            </Grid>
            <Divider className="mt-3 mb-3" />
          </Grid>
        )}

        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">System*</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={4.4}>
            <FormContent>
              <SBDropdown
                id="system"
                placeholder="Select"
                // options={[{ display: 'Option', value: 'value' }]}
                options={systemOptions}
                name="system"
                onChange={([value]) => setFieldValue('system', value)}
                value={values?.system ? [values?.system] : []}
                required
                error={errors?.system}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>

        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">{isSquadDriver ? 'Zone*' : 'Warehouse*'}</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={4.4}>
            <FormContent>
              {isSquadDriver ? (
                <SBDropdown
                  id="zones"
                  placeholder="Select"
                  multiple={true}
                  disabled={!values?.system}
                  options={filteredZones}
                  onChange={value => setFieldValue('zones', value)}
                  name="zones"
                  value={values?.zones ? values?.zones?.map((zone: string) => zone) : []}
                  error={Array.isArray(errors?.zones) ? errors?.zones[0] : errors?.zones}
                />
              ) : (
                <SBDropdown
                  id="warehouse"
                  // required
                  placeholder="Select"
                  disabled={!values?.system}
                  options={warehouseOptions}
                  value={
                    values?.warehouse ? values?.warehouse?.map((wareHouse: string) => wareHouse.toLowerCase()) : []
                  }
                  onChange={option => setFieldValue('warehouse', option)}
                  name="warehouse"
                  error={errors?.warehouse}
                />
              )}
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>

        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">Report To*</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              sx={{
                mr: {
                  md: 2,
                },
                marginBottom: '1rem',
              }}
            >
              <FormContent>
                <SBDropdown
                  id="reportTo"
                  required
                  placeholder="Select"
                  options={adminOptions}
                  onChange={([value]) => setFieldValue('reportTo', value)}
                  name="reportTo"
                  value={values?.reportTo ? [values?.reportTo] : []}
                  error={errors?.reportTo}
                />
              </FormContent>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={11.6}
              lg={6}
              sx={{
                mt: {
                  xs: 2,
                  md: 0,
                },
              }}
            >
              <FormContent>
                <Contact
                  phoneName="reportToPhoneNumber"
                  phoneNumber={values.reportToPhoneNumber}
                  onChangePhoneNumber={handleChange}
                  telCountryCodeName="reportToTelephoneCode"
                  telCountryCode={values.reportToTelephoneCode || '91'}
                  onChangeTelCountryCode={handleChange}
                  setDefaultTelephone={(value: number) => setFieldValue('reportToTelephoneCode', value)}
                  error={errors?.reportToPhoneNumber}
                />
              </FormContent>
            </Grid>
          </Grid>

          <Divider className="mt-3 mb-3" />
        </Grid>

        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        {isSquadDriver && (
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel className="md-text font-medium">Vehicle*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={4.4}>
              <FormContent>
                <SBDropdown
                  id="vehicle"
                  placeholder="Select"
                  options={SQUAD_VEHICLES}
                  onChange={([value]) => setFieldValue('vehicle', value)}
                  name="vehicle"
                  value={values?.vehicle ? [values?.vehicle] : []}
                  required
                  error={errors?.vehicle}
                />
              </FormContent>
            </Grid>
            <Divider className="mt-3 mb-3" />
          </Grid>
        )}

        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        {isSquadDriver && (
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel className="md-text font-medium">Squad KYC*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={4.4}>
              <FormContent className="w-full" sx={{ backgroundColor: 'primary.light' }}>
                {!values.document && !values.kyc ? (
                  <DragDrop accept="image/*" onChange={onFileChange} subText={'SVG, PNG, JPG or GIF (max 2MB)'} />
                ) : (
                  <FilePreview
                    filename={values?.kyc ? values.kyc.fileName : 'Squad Document'}
                    fileSize={values?.kyc ? values.kyc.fileSize : '10'}
                    onFileRemove={() => {
                      setFieldValue('document', '');
                      setFieldValue('kyc', '');
                    }}
                    onClick={() => openKycDocument(values?.document)}
                  />
                )}
              </FormContent>
              {(fileError || (errors?.kyc && Touch)) && (
                <FormHelperText error>{fileError || errors?.kyc}</FormHelperText>
              )}
            </Grid>
            <Divider className="mt-3 mb-3" />
          </Grid>
        )}
      </Grid>
      {/* <SuccessModal open={true} onClose={() => {}} /> */}
      {openKycModal && (
        <SquadKycModal
          open={openKycModal}
          onClose={() => setOpenKycModal(false)}
          document={documentUrl}
          type="KYC Document"
        />
      )}
    </>
  );
}

const FormLabel = styled(Typography)`
  // min-width: 280px;
`;

const FormContent = styled('div')`
  // max-width: 512px;
`;

export default CreateSquadForm;
