import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs';

import { styled } from '@mui/system';
import { Checkbox, TableBody, TableHead, TablePagination, TableRow, Grid, Typography, Box } from '@mui/material';

import Title from '@Components/Title';
import ExportBtn from '@Components/Button/Export';
import FilterSearch from '@Components/Filter/Search';
import FilterSelect from '@Components/Filter/Dropdown';
import DateRangePicker from '@Components/DateRangePicker';
import SBDropdown from '@Components/Dropdown';
import SbButton from '@Components/Button';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import DatePicker from '@Components/DatePicker';
// import SbTextField from '@Components/Textfield';

import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { AppDispatch, RootStateType } from '@Store';
import { getNonAccountUnbilledList, createNonAccountInvoice } from '@Reducers/Invoice';
import { sendNonAccountDataTo365 } from '@Reducers/d365';
import { ShipmentObj } from '@Reducers/Invoice/Type';
import { getInvoiceSettings } from '@Reducers/OrganizationSetup';

// import { FilterObj } from './Types';
// import { InvoiceListQuery } from '@Reducers/InvoiceList/Type';
import { InvoiceTableProps, FilterObj } from '../Type';

// ICONS
import Loader from '@Components/Loader';
import { getSystemList } from '@Reducers/Systems';
import BackButton from '@Components/BackButton';
import formatDate from '../../../utils/formatDate';

const SearchFilterList = [
  { label: 'Shipment No.', value: 'shipment_no' },
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
];

const ShipmentTypeList = [
  {
    value: 'import',
    display: 'Import',
  },
  {
    value: 'export',
    display: 'Export',
  },
  {
    value: 'domestic',
    display: 'Domestic',
  },
];

interface FilterType {
  currentPage: number;
  perPage: number;
  fromDate: string;
  toDate: string;
  searchKey?: string;
  searchValue: string | undefined;
  systems: string[];
  type: string[];
}

const TableActionContainer = styled('div')(({ theme }) => ({
  margin: '2rem 0',
  display: 'grid',
  gridTemplateColumns: '380px 1fr  fit-content(150px) fit-content(150px) fit-content(150px) 150px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    columnGap: '8px',
    gridTemplateColumns: '380px 1fr  fit-content(150px) fit-content(150px) fit-content(150px) 120px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

function InvoiceTable(props: InvoiceTableProps) {
  const { list, selectedInvoices, checkAllInvoices, onSelectInvoice } = props;

  // const list = InvoiceArr;

  return (
    <SBTableWrapper>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Checkbox
                style={{ color: 'inherit' }}
                checked={
                  list?.length > 0 && selectedInvoices?.length > 0
                    ? Object.keys(selectedInvoices).length ===
                      list.filter(item => item?.carrierDetails && item.carrierDetails?.awb).length
                    : false
                }
                onChange={checkAllInvoices}
              />
            </TableHeadCell>
            <TableHeadCell>Bill To</TableHeadCell>
            <TableHeadCell>Date</TableHeadCell>
            <TableHeadCell>Shipment No</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell>Shipment type</TableHeadCell>
            <TableHeadCell>Shipper</TableHeadCell>
            <TableHeadCell>Consignee</TableHeadCell>
            <TableHeadCell>Weight</TableHeadCell>
            <TableHeadCell>Packages</TableHeadCell>
            <TableHeadCell>Total Amount</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.length ? (
            list.map(data => (
              <SBTableRow key={data._id} className={selectedInvoices.includes(data._id) ? 'selected' : ''}>
                <TableRowCell align="center">
                  <div>
                    <Checkbox
                      disabled={!(data?.carrierDetails && data.carrierDetails?.awb)}
                      style={{ color: 'inherit' }}
                      sx={{
                        '&.MuiCheckbox-colorPrimary.Mui-disabled': {
                          color: 'grey !important',
                        },
                      }}
                      onChange={e => onSelectInvoice(data._id, e.target.checked)}
                      checked={!!selectedInvoices.includes(data._id)}
                    />
                  </div>
                </TableRowCell>
                <TableRowCell align="center" className="sm-text">
                  <p>{data._userId?.name || '-'}</p>
                  <p className="sb-text-gray">{data._userId?.email || '-'}</p>
                </TableRowCell>
                <TableRowCell
                  sx={{
                    div: {
                      textAlign: 'center',
                    },
                  }}
                  align="center"
                  className="sm-text capitalize"
                >
                  <p>{moment(data.createdAt).format('DD/MM/YYYY')}</p>
                </TableRowCell>
                <TableRowCell
                  sx={{
                    div: {
                      textAlign: 'center',
                    },
                  }}
                  align="center"
                  className="sm-text"
                >
                  {data.shipmentNumber}
                </TableRowCell>
                <TableRowCell align="center" className="sm-text">
                  {SHIPMENT_STATUS[data.status]?.label}
                </TableRowCell>
                <TableRowCell className="sm-text capitalize" align="center">
                  {data.shipmentRouteType}
                </TableRowCell>
                <TableRowCell className="sm-text" align="center">
                  <Box
                    sx={{
                      minWidth: 150,
                    }}
                  >
                    <p>{data._shipperId?.name}</p>
                    <p>{data._shipperId?.city}</p>
                    <p>{data._shipperId?.country}</p>
                  </Box>
                </TableRowCell>
                <TableRowCell className="sm-text" align="center">
                  <Box
                    sx={{
                      minWidth: 150,
                    }}
                  >
                    <p>{data._consigneeId?.name}</p>
                    <p>{data._consigneeId?.city}</p>
                    <p>{data._consigneeId?.country}</p>
                  </Box>
                </TableRowCell>
                <TableRowCell
                  sx={{
                    div: {
                      textAlign: 'center',
                    },
                  }}
                  className="sm-text"
                  align="center"
                >
                  {data?.sellingPricing?.chargeableWeight}
                </TableRowCell>
                <TableRowCell
                  sx={{
                    div: {
                      textAlign: 'center',
                    },
                  }}
                  className="sm-text"
                  align="center"
                >
                  {data?.totalPackages}
                </TableRowCell>
                <TableRowCell
                  sx={{
                    div: {
                      textAlign: 'center',
                    },
                  }}
                  align="center"
                  className="sm-text"
                >
                  {Number(data?.totalAmount || 0)?.toFixed(2)}
                </TableRowCell>
              </SBTableRow>
            ))
          ) : (
            <SBTableRow>
              <TableRowCell colSpan={11}>
                <Typography className="sb-text-gray font-medium text-center">No Data Found.</Typography>
              </TableRowCell>
            </SBTableRow>
          )}
        </TableBody>
      </SBTable>
    </SBTableWrapper>
  );
}

type invConfig = { invoiceDate?: null | Dayjs; dueDate?: null | Dayjs; taxBreakdown?: string[]; sacCode?: string };
const initialDateRange = {
  startDate: moment().subtract(3, 'months').startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
};

export default function UnbilledList() {
  // console.log('Non-Account Unbilled Create Invoice');
  const [filterSelectValue, setFilterSelectValue] = useState('shipment_no');
  const [filterText, setFilterText] = useState('');
  const [step, setStep] = useState(1);
  const [isClear, setClear] = useState(false);
  const [system, setSystem] = useState<string[]>([]);
  const [shipmentType, setShipmentType] = useState<string[]>([]);
  const [invConfig, setInvConfig] = useState<invConfig>({
    invoiceDate: null,
    dueDate: null,
  });
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [isWyngsMy, setIsWyngsMy] = useState<boolean>(false);
  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    // setOrganisationNameRedirect(subdomain);
    // console.info('subdomain:', subdomain);
    if (subdomain === 'wyngs-my' || subdomain === 'localhost') {
      setIsWyngsMy(true);
    } else {
      setIsWyngsMy(false);
    }
  }, []);

  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  // const [selectedAccountRows, setSelectedAccountRows] = useState<string[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const {
    nonAccountData: { list, currentPage = 1, perPage, totalCount },
    loading,
  } = useSelector((state: RootStateType) => state.Invoice);

  const SystemList = useSelector((state: RootStateType) => state.System.data.selectedList).map(system => ({
    display: system.name,
    value: system._id,
  }));

  useEffect(() => {
    const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
    const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
    dispatch(getNonAccountUnbilledList({ currentPage: 1, perPage: 10, fromDate, toDate }));
    dispatch(getSystemList());
  }, []);

  useEffect(() => {
    if (isClear) dispatch(getNonAccountUnbilledList(getFilterObj({})));
    return () => {
      setClear(false);
    };
  }, [isClear]);

  const onClear = () => {
    setSystem([]);
    setShipmentType([]);
    setFilterText('');
    setDateRange(initialDateRange);
    setClear(true);
  };

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(getNonAccountUnbilledList(getFilterObj({ dateRange })));
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    dispatch(getNonAccountUnbilledList(getFilterObj({ system: selected })));
  };

  const onTypeFilter = (selected: Array<string>) => {
    setShipmentType(selected);
    dispatch(getNonAccountUnbilledList(getFilterObj({ shipmentType: selected })));
  };

  const getFilterObj = (options: FilterObj) => {
    setSelectedInvoices([]);
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterSystem = options.system || system;
    const filterType = options.shipmentType || shipmentType;
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filter: FilterType = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
      systems: filterSystem,
      type: filterType,
    };
    if (!filterSearchValue) {
      delete filter.searchKey;
      delete filter?.searchValue;
    }
    return filter;
  };

  const onSelectInvoice = function (invoiceId: string, isChecked: boolean) {
    let newSelectedInvoice = [...selectedInvoices];
    if (isChecked) newSelectedInvoice.push(invoiceId);
    else {
      newSelectedInvoice = newSelectedInvoice.filter(i => i !== invoiceId);
    }
    // console.log('invoiceId ', invoiceId, isChecked, newSelectedInvoice);

    setSelectedInvoices([...newSelectedInvoice]);
  };
  const checkAllInvoices = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newCheckedList = list?.filter(data => data?.carrierDetails && data.carrierDetails?.awb).map(i => i._id);
      setSelectedInvoices(newCheckedList);
    } else {
      setSelectedInvoices([]);
    }
  };

  const { invoiceSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const { taxInformation = [] } = invoiceSetting || {};
  const taxInformationOptions = taxInformation.map(s => ({ display: s.display, value: s.value, key: s.key }));

  const createUnbilledInvoice = () => {
    const { sacCode, taxBreakdown, dueDate, invoiceDate } = invConfig;
    const shipment = list.filter(s => selectedInvoices.includes(s._id));
    const shipmentNumbers = shipment.map(s => s.shipmentNumber);
    function getTaxBreakDownObject(keys: any, data: any[]) {
      for (const key of keys) {
        const matchedItem = data.find((item: any) => item.key === key);

        if (matchedItem) {
          return [
            {
              display: matchedItem.display,
              value: matchedItem.value,
              key: matchedItem.key,
            },
          ];
        }
      }
    }

    const params = {
      ...invConfig,
      shipmentIds: selectedInvoices,
      shipmentNumbers,
      invoiceDate: formatDate(invoiceDate),
      dueDate: formatDate(dueDate),
      taxBreakdown: getTaxBreakDownObject(taxBreakdown, taxInformationOptions),
    };
    // if (!sacCode || !taxBreakdown || !dueDate || !invoiceDate) return;
    if (isWyngsMy) {
      if (!dueDate || !invoiceDate) return;
    } else {
      if (!sacCode || !taxBreakdown || !dueDate || !invoiceDate) return;
    }
    dispatch(createNonAccountInvoice(params)).then(res => {
      if (res.type === 'createNonAccountInvoice/fulfilled') {
        navigate('/invoice/view');
      }
    });
  };
  // const isCreateDisabled = () => {
  //   if (step !== 2) return false;
  //   const { sacCode, taxBreakdown, dueDate, invoiceDate } = invConfig;
  //   if (!sacCode || !taxBreakdown || !dueDate || !invoiceDate) return true;
  //   return false;
  // };

  const nextStep = () => {
    if (step === 2) return;
    setStep(step + 1);
  };
  const preStep = () => {
    if (step <= 1) return;
    setStep(step - 1);
  };
  const isPrevDisabled = () => {
    if (step <= 1) return true;
    return false;
  };
  // const isNextdisabled = () => {};
  // console.log('selectedAccountRows ', selectedAccountRows);

  return (
    <>
      <Loader loading={loading} />
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <BackButton link="/invoice/view?tab=non-account" />
        <Title title="Invoices" subTitle="View and create invoices" />
      </Box>
      <div className="flex" style={{ marginTop: '52px', marginBottom: '25px' }}>
        <Title title="Create Invoices" titleClass="underline px_20-text" removeBorder />
        {step === 1 && (
          <ExportBtn
            onClick={() => {
              console.log('Export Unbilled Invoice Clicked');
            }}
            iconSrc="/images/file_download.svg"
            alt="Export"
            label="Export"
          />
        )}
      </div>
      {step === 1 && (
        <>
          <TableActionContainer>
            <FilterSearch
              onSelectChange={(value: string) => {
                setFilterSelectValue(value);
              }}
              list={SearchFilterList}
              selectValue={filterSelectValue}
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={() => {
                dispatch(getNonAccountUnbilledList(getFilterObj({})));
              }}
              onTextClear={() => {
                setFilterText('');
                dispatch(getNonAccountUnbilledList(getFilterObj({ searchValue: undefined })));
              }}
            />
            <DateRangePicker onChange={onDateChange} value={dateRange} />
            <div></div>
            <FilterSelect id="system" label="System" value={system} options={SystemList} onChange={onSystemFilter} />
            <FilterSelect
              id="shipmenttype"
              label="Shipment Type"
              value={shipmentType}
              options={ShipmentTypeList}
              onChange={onTypeFilter}
            />
            <SbButton
              className="xs-text ml-1"
              variant="outlined"
              sx={{ borderRadius: '8px !important', padding: '8px !important' }}
              onClick={onClear}
            >
              Clear Filters
            </SbButton>
          </TableActionContainer>
          <Box my={'2rem'} pb={'0.5rem'} borderBottom={'4px solid #080808'} width={'220px'}>
            <Typography className="md-text" fontWeight={700}>
              Select Unbilled Shipments
            </Typography>
          </Box>

          <InvoiceTable
            checkAllInvoices={checkAllInvoices}
            onSelectInvoice={onSelectInvoice}
            selectedInvoices={selectedInvoices}
            list={list}
          />
          {totalCount ? (
            <>
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage - 1}
                rowsPerPage={perPage}
                onPageChange={(_, page) => {
                  dispatch(getNonAccountUnbilledList(getFilterObj({ currentPage: page + 1 })));
                }}
                onRowsPerPageChange={event => {
                  dispatch(getNonAccountUnbilledList(getFilterObj({ perPage: parseInt(event.target.value) })));
                }}
              />
            </>
          ) : (
            ''
          )}
        </>
      )}

      {step === 2 && (
        <StepTwo list={list} invConfig={invConfig} setInvConfig={setInvConfig} selectedInvoices={selectedInvoices} />
      )}

      <Grid container item mt={2} justifyContent="flex-end" spacing={4}>
        <Grid item xs={12} sm={3} md={2}>
          <SbButton variant="outlined" fullWidth className="rounded-full" onClick={preStep} disabled={isPrevDisabled()}>
            Back
          </SbButton>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <SbButton
            variant="contained"
            // disabled={!selectedInvoices?.length || isCreateDisabled()}
            fullWidth
            className="rounded-full"
            onClick={step === 2 ? createUnbilledInvoice : nextStep}
          >
            {step === 2 ? 'Create' : 'Next'}
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

type StepTwoProps = {
  list: ShipmentObj[];
  selectedInvoices: string[];
  invConfig: invConfig;
  setInvConfig: React.Dispatch<React.SetStateAction<invConfig>>;
};

function StepTwo(props: StepTwoProps) {
  const { list, selectedInvoices, invConfig, setInvConfig } = props;
  // console.log('list ', list);
  const { loading: loadingD365 } = useSelector((state: RootStateType) => state?.D365);

  const selectedList = list?.filter(shipment => selectedInvoices.includes(shipment._id));
  const dispatch = useDispatch<AppDispatch>();

  const { invoiceSetting, loading } = useSelector((state: RootStateType) => state?.orgSetup);
  const { loading: d365Loading } = useSelector((state: RootStateType) => state?.D365);
  const { sacCode = [], taxInformation = [] } = invoiceSetting || {};
  const sacCodeOptions = sacCode.map(s => ({ display: s.display, value: s.key }));
  const taxInformationOptions = taxInformation.map(s => ({ display: s.display, value: s.key }));
  const noOfInvoices = selectedList?.length || 0;
  const loadingState = loading || d365Loading || false;
  const [isWyngsMy, setIsWyngsMy] = useState<boolean>(false);
  // console.log({ sacCode, sacCodeOptions });

  useEffect(() => {
    dispatch(getInvoiceSettings());
  }, []);

  // const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setInvConfig({ [name]: value });
  // };

  const sendTo365 = () => {
    const shipmentIds = selectedList.map(s => s._id);
    const shipmentNumbers = selectedList.map(s => s.shipmentNumber);
    const payload = {
      shipmentIds: shipmentIds,
      shipmentNumbers,
      dueDate: dayjs(invConfig.dueDate).format('YYYY-MM-DD'),
      invoiceDate: dayjs(invConfig.invoiceDate).format('YYYY-MM-DD'),
    };
    dispatch(sendNonAccountDataTo365(payload));
  };

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    // setOrganisationNameRedirect(subdomain);
    // console.info('subdomain:', subdomain);
    if (subdomain === 'wyngs-my') {
      setIsWyngsMy(true);
    } else {
      setIsWyngsMy(false);
    }
  }, []);

  return (
    <>
      <Loader loading={loadingState || loadingD365} />

      <Grid container>
        <Grid item md={5} mt={'3.8rem'}>
          <Grid container flex={1}>
            <Grid item md={10}>
              <Grid container columnSpacing={4}>
                <Grid item md={6} gap={4}>
                  <DatePicker
                    required
                    // placeholder="01/01/2023"
                    label="Invoice Date"
                    // name="invoiceDate"
                    value={invConfig.invoiceDate}
                    handleChange={(date: Dayjs) => setInvConfig({ ...invConfig, invoiceDate: date, dueDate: null })}
                    // minDate={dayjs()}
                    // disabled={pincode ? disablePickup : false}
                  />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    required
                    // placeholder="01/01/2023"
                    label="Due Date"
                    // name="dueDate"
                    value={invConfig.dueDate}
                    handleChange={(date: Dayjs) => setInvConfig({ ...invConfig, dueDate: date })}
                    minDate={dayjs(invConfig.invoiceDate)}
                    // disabled={pincode ? disablePickup : false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container gap={2} mt={2}>
            <Grid item md={10}>
              <SBDropdown
                label="Tax Breakdown"
                id="taxBreakdown"
                placeholder="Select"
                required={isWyngsMy ? false : true}
                options={taxInformationOptions || []}
                onChange={value => {
                  setInvConfig({ ...invConfig, taxBreakdown: value });
                }}
                name="taxBreakdown"
                value={invConfig.taxBreakdown || []}
              />
            </Grid>
            <Grid item md={10}>
              <SBDropdown
                label="SAC Code"
                id="sacCode"
                placeholder="Select"
                required={isWyngsMy ? false : true}
                options={sacCodeOptions || []}
                onChange={value => {
                  setInvConfig({ ...invConfig, sacCode: value?.[0] });
                }}
                name="sacCode"
                value={[invConfig.sacCode || ''] || []}
              />
            </Grid>
            <Grid item md={10}>
              <div style={{ display: 'flex' }}>
                {!isWyngsMy && (
                  <SbButton
                    className="rounded-full"
                    variant="contained"
                    sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                    onClick={() => {
                      sendTo365();
                    }}
                    disabled={!(invConfig.invoiceDate && invConfig.dueDate)}
                  >
                    Send to D365
                  </SbButton>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={7}>
          <Grid container justifyContent={'space-between'} alignItems={'center'} my={'1rem'}>
            <Grid item xs="auto">
              <Box pb={'0.5rem'} borderBottom={'4px solid #080808'} width={'140px'}>
                <Title removePadding removeBorder title="Preview Invoices" titleClass=" px_16-text" />
              </Box>
            </Grid>
            <Grid item xs="auto" justifyContent={'flex-end'} container>
              <Title removePadding removeBorder title={`No. Of Invoices : ${noOfInvoices}`} titleClass="px_16-text" />
            </Grid>
          </Grid>
          <SBTable style={{ height: '250px', overflowY: 'scroll' }}>
            <TableHead>
              <TableRow>
                <TableHeadCell>Sr. No.</TableHeadCell>
                <TableHeadCell>Shipment No</TableHeadCell>
                <TableHeadCell>System</TableHeadCell>
                <TableHeadCell>Bill To</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedList.map((data, index) => (
                <SBTableRow key={data._id}>
                  <TableRowCell
                    sx={{
                      div: {
                        textAlign: 'center',
                      },
                    }}
                    align="center"
                  >
                    {index + 1}
                  </TableRowCell>
                  <TableRowCell
                    sx={{
                      div: {
                        textAlign: 'center',
                      },
                    }}
                    align="center"
                    className="md-text uppercase"
                  >
                    {data.shipmentNumber}
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text capitalize">
                    <p>{data._chargingSystemId?.name}</p>
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text">
                    <p>{data._userId.name}</p>
                    <p className="sb-text-gray">{data._userId.email}</p>
                  </TableRowCell>
                </SBTableRow>
              ))}
            </TableBody>
          </SBTable>
        </Grid>
      </Grid>
    </>
  );
}
