import { PayloadAction } from '@reduxjs/toolkit';
import {
  OutstandingBalanceResponse,
  RechargeWalletByAdminPayloadResponse,
  RechargeWalletResponse,
  WalletBalanceResponse,
  WalletHistoryResponse,
  WalletState,
} from './Type';
import { SuccessPayload } from '../../../types';
import { formatNumber } from '../../../utils/numberUtils';

function pending(state: WalletState) {
  state.loading = true;
}

function getWalletBalanceFulfilled(state: WalletState, data: PayloadAction<SuccessPayload<WalletBalanceResponse>>) {
  state.currentBalance = formatNumber(data.payload.data?.balance || 0);
  state.loading = false;
}

function rechargeWalletRazorpayPayloadFulfilled(
  state: WalletState,
  data: PayloadAction<SuccessPayload<RechargeWalletResponse>>,
) {
  state.addWalletRazorpay = data.payload.data || null;
  state.loading = false;
}

function getAllPaymentGetWayFulfilled(
  state: WalletState,
  data: PayloadAction<SuccessPayload<WalletState['paymentGetwayList']>>,
) {
  state.paymentGetwayList = data.payload.data || null;
  state.loading = false;
}

function getWalletTransactionListFulfilled(state: WalletState, action: { payload: WalletHistoryResponse }) {
  state.data = action.payload.data;
  state.loading = false;
}

function getOutstandingBalanceForPrepaidFulfilled(
  state: WalletState,
  { payload }: PayloadAction<SuccessPayload<OutstandingBalanceResponse>>,
) {
  state.outstandingBalance = formatNumber(payload.data?.outstandingBalance || 0);
  state.totalShipmentAmtPaid = formatNumber(payload.data?.totalShipmentAmtPaid || 0);
  state.totalShipmentAmt = formatNumber(payload.data?.totalShipmentAmt || 0);
  state.loading = false;
}

function getOutstandingBalanceInvoiceForPrepaidFulfilled(
  state: WalletState,
  { payload }: PayloadAction<SuccessPayload<OutstandingBalanceResponse>>,
) {
  state.totalInvoiceAmtPaid = formatNumber(payload.data?.totalInvoiceAmtPaid || 0);
  state.loading = false;
}
function addMoneyToWalletByAdminFulfilled(
  state: WalletState,
  { payload }: PayloadAction<SuccessPayload<RechargeWalletByAdminPayloadResponse>>,
) {
  state.currentBalance = formatNumber(payload.data?.balance || 0);
  state.loading = false;
}

function rejected(state: WalletState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

export {
  pending,
  getWalletBalanceFulfilled,
  rejected,
  rechargeWalletRazorpayPayloadFulfilled,
  getAllPaymentGetWayFulfilled,
  getWalletTransactionListFulfilled,
  getOutstandingBalanceForPrepaidFulfilled,
  addMoneyToWalletByAdminFulfilled,
  getOutstandingBalanceInvoiceForPrepaidFulfilled,
};
