import React, { ReactNode } from 'react';
import { Dialog, Grid, Stack, Typography, styled } from '@mui/material';
import SbButton from '../Button';
import { HighlightOff } from '@mui/icons-material';

interface PropsType {
  icon?: string | ReactNode;
  closeIcon?: boolean;
  title?: string;
  subTitle?: string | ReactNode;
  children?: ReactNode;
  open: boolean;
  btnText?: string;
  onClose: () => void;
  onConfirm?: () => void;
  secondaryBtnText?: string;
  format?: boolean;
}

const Title = styled(Typography)(({ theme }) => ({
  // fontSize: '32px',
  maxWidth: '500px',
  marginTop: theme.spacing(4),
}));

const DialogContainer = styled(Grid)({
  maxWidth: '640px',
});

const DialogContent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
}));

const Header = styled(Grid)`
  background: var(--mui-palette-border-main);
  height: 89px;

  div {
    width: 167px;
    height: 146px;
    background: var(--mui-palette-border-main);
    border-radius: 50%;
    margin-top: 10px;
    color: var(--mui-palette-primary-contrastText);
  }
  img {
    height: 85px;
    width: 85px;
  }
`;

function Modal({
  open,
  btnText,
  onConfirm,
  onClose,
  icon,
  title,
  subTitle,
  children,
  closeIcon,
  secondaryBtnText,
  format,
}: PropsType) {
  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose} scroll={'paper'}>
      <DialogContainer container justifyContent="flex-end">
        {closeIcon && (
          <HighlightOff
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '12px',
              top: '15px',
              height: '28px',
              width: '28px',
              color: 'common.white',
            }}
            color="action"
            onClick={onClose}
          />
        )}
        <Header container item justifyContent="center">
          <Grid container item justifyContent="center" alignItems="center">
            {icon && typeof icon === 'string' ? <img src={icon} alt="logistics" /> : icon}
          </Grid>
        </Header>
        <DialogContent
          container
          item
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          {children ? (
            children
          ) : (
            <>
              {title && <Title className="font-medium xl-text">{title}</Title>}
              {subTitle && (
                <Typography variant="h6" className="mt-1 font-normal">
                  {/* {subTitle} */}
                  {format ? <span style={{ whiteSpace: 'pre-line' }}>{subTitle} </span> : subTitle}
                </Typography>
              )}
              <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} gap={2}>
                {btnText && (
                  <SbButton
                    onClick={onConfirm}
                    variant="contained"
                    className="mt-4 rounded-full"
                    style={{ height: '44px', width: '148px' }}
                  >
                    {btnText}
                  </SbButton>
                )}
                {secondaryBtnText && (
                  <SbButton
                    onClick={onClose}
                    variant="contained"
                    className="mt-4 rounded-full"
                    sx={{
                      height: '44px',
                      width: '148px',
                      bgcolor: 'background.menuResizeBtn',
                      ':hover': {
                        bgcolor: 'background.menuResizeBtn',
                      },
                    }}
                  >
                    {secondaryBtnText}
                  </SbButton>
                )}
              </Stack>
            </>
          )}
        </DialogContent>
      </DialogContainer>
    </Dialog>
  );
}
export default Modal;
