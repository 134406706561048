import SBDropdown from '@Components/Dropdown';
import { getWarehouseListForShipment } from '@Reducers/Warehouse';
import { AppDispatch, RootStateType } from '@Store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownPropsType } from './Type';
// import useDebounce from '@Hook/useDebounce';

interface Props {
  label?: string;
  placeholder?: string;
  value: string;
  onChange: DropdownPropsType['onChange'];
  error?: string;
  disabled?: boolean;
  pincode?: string;
  userId?: string;
  isSortingAllowed?: boolean;
  warehouses?: any;
}

function WarehouseDropdownList(props: Props) {
  const {
    error,
    onChange,
    value,
    label = 'Warehouse List',
    placeholder = 'Select Warehouse',
    isSortingAllowed = false,
    pincode,
    warehouses,
  } = props;
  // const { pincode } = props;
  const id = React.useId();
  const dispatch = useDispatch<AppDispatch>();
  const { warehouseListForShipmentOptions } = useSelector((state: RootStateType) => state.Warehouse);

  console.info('warehouseListForShipmentOptions:', warehouseListForShipmentOptions);

  // const debouncedSend = useDebounce(pincode => {
  //   dispatch(getWarehouseListForShipment({ pincode }));
  // }, 1000);

  // useEffect(() => {
  //   dispatch(getWarehouseListForShipment({ userId: props.userId }));
  // }, []);

  useEffect(() => {
    if (pincode) {
      const queryParams = { pincode, filter: true };
      dispatch(getWarehouseListForShipment(queryParams));
    } else {
      dispatch(getWarehouseListForShipment());
    }
  }, [dispatch, pincode]);

  console.info('warehouses-->', warehouses);

  return (
    <SBDropdown
      required
      label={label}
      id={id}
      placeholder={placeholder}
      options={warehouseListForShipmentOptions}
      value={value ? [value] : []}
      onChange={onChange}
      error={error}
      disabled={props.disabled}
      isSortingAllowed={isSortingAllowed}
    />
  );
}

export default WarehouseDropdownList;
