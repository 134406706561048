import React, { useEffect, useMemo, useState } from 'react';
import { vendorAccountType } from '@Reducers/AdminShipment/Type';
import {
  Box,
  FormHelperText,
  Grid,
  Paper,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SBDropdown from '@Components/Dropdown';
import { useFormik } from 'formik';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsWarehouseSelectedINFedX } from '@Reducers/Warehouse';

import dayjs, { Dayjs } from 'dayjs';
import { downloadCarrierLabel } from '@Reducers/ShipmentHistory/actions';
import { onForward } from './constants';
import SbButton from '@Components/Button';
import Divider from '@Components/Divider';
import * as Yup from 'yup';
import RadioButton from '@Components/RadioButton';
import { PickupDataType, PickupType } from '../../../../../../types/shipment';
import { PickupTypeList, defaultPickupState } from '../../../../../CreateShipment/constants';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import { isActionAllowed } from '../../../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import DatePicker from '@Components/DatePicker';
import { usePickupDropoffAccess } from '@Hook/usePickupDropoffAccess';
import API from '@Services/apiAxios';
import WarehouseSelect from './WarehouseSelect';
import Loader from '@Components/Loader';

interface propsTypes {
  onClickForward: () => void;
  onClose?: () => void;
  initialStates: any;
  isEditable?: boolean;
  showFedExDetails: boolean;
  btnText: string;
}

const stageInformationSchema = Yup.object().shape({
  accountNo: Yup.string().trim().required('Account is required'),
  service: Yup.string().trim().required('Account is required'),
});

export interface StageInformation {
  service: string;
  type: string;
  accountNo: string;
  countryOfManufacture: string;
  shipperTaxId: string;
  invoiceDate: Dayjs | null;
  productType: string;
  paymentType: string;
  invoiceNumber: string;
}

interface Warehouse {
  _id: string;
  name: string;
  [key: string]: any; // For additional fields
}

const StageInformation = (props: propsTypes) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [pickupType, setPickupType] = useState<PickupDataType>({ ...defaultPickupState });
  const [error, setError] = useState<string>('');
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const { onClickForward, onClose, initialStates, isEditable, showFedExDetails, btnText } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { isWarehouseSelectedINFedX } = useSelector((state: RootStateType) => state.Warehouse);
  const [timeSlotsOption, setTimeSlotsOption] = useState<DropdownOptionType[]>([]);
  const [serviceData, setServiceData] = useState<any>('');
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse | null>(null);
  const [loading, setLoading] = useState(false);

  const { shipmentById, vendersAccount, stageDetails } = useSelector((state: RootStateType) => state.adminShipment);
  const shipperPincode = shipmentById?._shipperId.pincode;
  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const countryHashById = useSelector((state: RootStateType) => state.global.countriesObj);
  const { pickupTimeSlots } = shipmentSetting || {};
  const { values, setFieldValue, errors, handleSubmit } = useFormik<StageInformation>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ...initialStates,
    },
    validationSchema: stageInformationSchema,
    onSubmit(values) {
      if (isWarehouseSelectedINFedX === '' || isWarehouseSelectedINFedX === 'false') {
        dispatch(setIsWarehouseSelectedINFedX('false'));
      } else {
        onForward({
          values,
          selectedWarehouse,
          countryHashById,
          shipmentNumber: shipmentById?.shipmentNumber || '',
          dispatch,
          nextFunc: onClickForward,
        });
      }
    },
  });

  useEffect(() => {
    const slots = pickupTimeSlots?.slots;
    if (slots) {
      setTimeSlotsOption(
        slots.map(({ from, to }) => {
          const isToday = dayjs(pickupType.date).isSame(dayjs(), 'day');
          const checkPastTime = dayjs(to, 'hh a').isBefore(dayjs(dayjs(), 'hh:mm a'));
          const str = `${from} - ${to}`;
          return { display: str, value: str, disable: isToday && checkPastTime };
        }),
      );
    }
  }, [pickupTimeSlots, pickupType.date]);

  const fetchWarehouses = async (accountNo: string) => {
    try {
      const response = await API.get('/shipment/vendor/warehouses', {
        params: {
          perPage: 10,
          csp: 'stage',
          accountNo,
          currentPage: 1,
        },
      });
      setWarehouses(response.data.data);
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchShipmentRate = async (accountNo: string) => {
    const apiUrl = `/shipment/vendor/services?csp=stage&accountNo=${accountNo}`;

    const shipmentDetails = {
      destination: {
        city: shipmentById?._consigneeId?.city || '',
        state: shipmentById?._consigneeId?.state || '',
        country: shipmentById?._consigneeId?.country || '',
        pincode: shipmentById?._consigneeId?.pincode || '',
      },
      source: {
        city: shipmentById?._shipperId?.city || '',
        state: shipmentById?._shipperId?.state || '',
        country: shipmentById?._shipperId?.country || '',
        pincode: shipmentById?._shipperId?.pincode || '',
      },
      shipmentType: shipmentById?._packageId?.isDocument ? 'document' : 'parcel',
      weight: shipmentById?.sellingPricing?.chargeableWeight || '',
      value: '',
      package: {
        box: shipmentById?._packageId?.box?.map(box => ({
          weight: box.weight,
          length: box.length,
          width: box.width,
          height: box.height,
        })),
        unitOfMeasurement: shipmentById?._packageId?.unitOfMeasurement,
      },
      document: shipmentById?._packageId?.isDocument,
      insurance: shipmentById?._packageId?.insurance,
      packaging: shipmentById?._packageId?.packaging,
      dropOff: shipmentById?._packageId?.isDropOff,
    };

    try {
      setLoading(true);
      const response = await API.post(apiUrl, shipmentDetails);
      setServiceData(response.data.data);
    } catch (err) {
      console.error('API call failed:', err);
      setError(err.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const accountOptions = useMemo(
    () =>
      vendersAccount?.map((item: vendorAccountType) => ({
        value: `${item.accountNo}-${item._id}`,
        display: item.name,
      })),
    [vendersAccount],
  );

  const serviceOptions = useMemo(
    () =>
      Array.isArray(serviceData) // Check if serviceData is an array
        ? serviceData.map((item: any) => ({
            value: `${item._courierId}-${item._systemId}`,
            display: item.serviceName,
          }))
        : [], // Return an empty array if serviceData is not an array
    [serviceData],
  );

  const handlePickupDateChange = (date: Dayjs) => {
    setPickupType({ ...pickupType, date: date });
  };

  const handlePickupTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === 'dropOff') {
      setPickupType({ ...defaultPickupState, type: value as PickupType, warehouse: shipmentById?._warehouseId || '' });
    } else {
      setSelectedWarehouse(null);
      setPickupType({ ...defaultPickupState, type: value as PickupType });
    }
    setError('');
  };

  const isPickupDropoffWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.pick_up_or_drop_off,
    PERFORM_ACTION.write,
  );

  const { disablePickup } = usePickupDropoffAccess({
    shipperCountry: shipmentById?._shipperId.country,
  });

  const handleTimeSlotChange = (
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
  ) => {
    const value = event.target.value;
    setPickupType({ ...pickupType, timeSlot: value });
  };

  const handleWarehouseSelect = (warehouse: Warehouse) => {
    setSelectedWarehouse(warehouse);
  };

  return (
    <div>
      <Loader loading={loading}></Loader>

      {showFedExDetails && (
        <>
          <Paper
            variant="outlined"
            sx={{
              my: '2rem',
              p: '2rem',
            }}
          >
            <Grid container item xs={12}>
              <Grid container item mb={3}>
                <Grid item xs={12} md={6}>
                  <Typography className="font-medium l-text pb-3">Stage Information</Typography>{' '}
                </Grid>
              </Grid>
              <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
                <Grid container item columnSpacing={10} rowSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <SBDropdown
                      disabled={!isEditable}
                      id="accountNo"
                      label={`Stage Account`}
                      required
                      placeholder="Select"
                      name="accountNo"
                      value={[values.accountNo]}
                      options={accountOptions}
                      onChange={async value => {
                        const newAccountNo = value[0];
                        setFieldValue('accountNo', newAccountNo);
                        const newStageAccount = newAccountNo.split('-')[0];
                        await fetchShipmentRate(newStageAccount);
                        await fetchWarehouses(newStageAccount);
                      }}
                      error={errors?.accountNo as string}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SBDropdown
                      disabled={!isEditable}
                      id="service"
                      label={`Service`}
                      required
                      placeholder="Select"
                      name="service"
                      value={[values.service]}
                      options={serviceOptions}
                      onChange={value => setFieldValue('service', value[0])}
                      error={errors?.accountNo as string}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={10} rowSpacing={2}>
                  <Grid item xs={12} md={12}>
                    <Box className="font-medium md-text">Pickup or Drop-off?</Box>

                    <Grid container item md={7} sx={{ py: 6 }}>
                      <Grid
                        container
                        item
                        md={6}
                        xs={12}
                        alignItems={'center'}
                        className={isMobile ? '' : 'border-right pr-3'}
                      >
                        <RadioButton
                          containerClass="w-full"
                          name="type"
                          value={pickupType.type}
                          onChange={handlePickupTypeChange}
                          list={PickupTypeList}
                          sx={{ display: 'flex', columnGap: 10 }}
                        />
                      </Grid>
                      {pickupType.type === 'dropOff' && (
                        <Grid item md={6} xs={12} sx={{ pl: { lg: 3, xs: 0 } }}>
                          {/* <SBWarehouseDropdown
                            value={pickupType.warehouse || ''}
                            onChange={handleWarehouseChange}
                            disabled={!isPickupDropoffWriteAllowed}
                            warehouses={warehouses}
                          /> */}
                          <WarehouseSelect warehouses={warehouses} onSelect={handleWarehouseSelect} />
                          {error && <FormHelperText error>{error}</FormHelperText>}
                        </Grid>
                      )}
                      {pickupType.type === 'pickUp' && (
                        <>
                          <Grid item md={timeSlotsOption?.length ? 3 : 6} xs={6} sx={{ pl: { lg: 3, xs: 0 } }}>
                            <DatePicker
                              minDate={shipmentById?.createdAt ? dayjs(shipmentById?.createdAt) : dayjs()}
                              label="Date"
                              value={pickupType.date}
                              handleChange={handlePickupDateChange}
                              disabled={
                                !isPickupDropoffWriteAllowed
                                  ? !isPickupDropoffWriteAllowed
                                  : shipperPincode
                                  ? !!disablePickup
                                  : false
                              }
                            />
                          </Grid>
                          {timeSlotsOption?.length ? (
                            <Grid item md={3} xs={6} className="pl-3">
                              <SBDropdown
                                label={'Time Slot'}
                                id="timeSlot"
                                name="timeSlot"
                                isSortingAllowed={false}
                                placeholder="Select time slot"
                                options={timeSlotsOption}
                                value={pickupType.timeSlot ? [pickupType.timeSlot] : []}
                                onChange={handleTimeSlotChange}
                                disabled={
                                  !isPickupDropoffWriteAllowed
                                    ? !isPickupDropoffWriteAllowed
                                    : shipperPincode
                                    ? !!disablePickup
                                    : false
                                }
                              />
                              {error && <FormHelperText error>{error}</FormHelperText>}
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Divider className="mt-3 mb-3" />
                <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
                  <Grid container item sx={{ gap: '1rem' }}>
                    <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                      <SbButton
                        disabled={!isEditable}
                        variant="contained"
                        sx={{ borderRadius: `4px !important` }}
                        className="w-full"
                        onClick={_event => {
                          handleSubmit();
                        }}
                      >
                        {btnText}
                      </SbButton>
                    </Grid>
                    {onClose && (
                      <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                        <SbButton
                          sx={{ borderRadius: `4px !important` }}
                          className="w-full"
                          variant="outlined"
                          onClick={onClose}
                        >
                          Cancel
                        </SbButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
      {stageDetails?.response && (
        <Grid container item>
          <Grid
            item
            xs={'auto'}
            border={'1px solid'}
            className={`border-grey font-medium md-text p-2`}
            borderRadius={1}
          >
            This shipment has been forwarded.
            <Box
              component={'span'}
              className="font-medium md-text ml-1"
              sx={{
                color: '#485fc7',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (shipmentById?.shipmentNumber) {
                  dispatch(downloadCarrierLabel(shipmentById?.shipmentNumber));
                }
              }}
            >
              Download the carrier AWB
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default StageInformation;
