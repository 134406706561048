/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';

const getCountryList = createAsyncThunk('getCountryList', async () => {
  const res = await API.get('/organization/country/sb/all');
  return res.data;
});

const getKycSetting = createAsyncThunk('global/getKycSetting', async () => {
  const result = await API.get('/user/setting/kyc');
  return result.data;
});

export { getCountryList, getKycSetting };
