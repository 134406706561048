import React from 'react';
import KYCSettingTabs from '../OrganizationSetup/KYCSettings/KYCSettingsTabs';

function KYCSettings() {
  return (
    <>
      <KYCSettingTabs viewFor="settings" />
    </>
  );
}

export default KYCSettings;
