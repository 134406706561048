import { Typography, alpha, styled } from '@mui/material';

export const OutletWrapperStyle = styled('div')(({ theme }) => ({
  flex: 1,
  height: '100vh',
  overflowY: 'auto',
  backgroundColor: alpha(theme.palette.primary.light, 1),
}));

export const OutletContainerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'start',
  height: '100vh',
  overflowY: 'auto',
  backgroundColor: alpha(theme.palette.primary.light, 1),
  padding: theme.spacing(2),
}));

export const ComponentWrapper = styled('div')(({ theme }) => ({
  width: '90%',
  background: theme.palette.background.default,
  borderRadius: '12px',
  // marginTop: theme.spacing(3),
  margin: 'auto',
}));

export const OutletBox = styled('div')<{ removeBorder?: boolean }>(({ theme, removeBorder }) =>
  removeBorder
    ? {}
    : {
        padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
        border: '1px solid',
        [theme.breakpoints.up('md')]: {
          padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
        },
        [theme.breakpoints.down('md')]: {
          padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
        },
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
);
export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2.2),
  color: theme.palette.primary.dark,
}));
export const SectionWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 6),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 6),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 2),
  },
}));
export const SectionSubText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.8),
}));
export const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 500,
}));
