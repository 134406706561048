import { ActionsPathLists } from '@Constants/actionsNames';
import { ValidationActionsData } from '@Reducers/AccessGroup/Type';
import { GROUPS } from '@Constants/user';
import { rootStore } from '@Store';

// export function isActionAllowed(actions: ValidationActionsData[] | [], action_name: string, perform_action: string) {
export function isActionAllowed(
  actions: ValidationActionsData[] | undefined | [],
  action_name: string,
  perform_action: string,
) {
  // export function isActionAllowed(actions, action_name, perform_action) {

  const accessGroup = rootStore.getState().loginUser.profile?.accessGroup?.toLowerCase();
  if (accessGroup === GROUPS.pu_all.code || accessGroup === GROUPS.pa_all.code) {
    return true;
  }

  const foundAction = actions?.find(
    (action: ValidationActionsData) => action._id.name.toLowerCase() === action_name.toLowerCase(),
  );

  if (foundAction) {
    return foundAction.selectedActions?.includes(perform_action);
  }

  return false;
}

export function isRouteAllowed(path: string, actions: ValidationActionsData[] | []) {
  // export function isRouteAllowed(path: string, actions) {
  const matchedAction = ActionsPathLists.find(action => path.startsWith(action.path));

  const foundAction = actions?.find((action: ValidationActionsData) => {
    return action._id.name.toLowerCase() === matchedAction?.action_name.toLocaleLowerCase();
  });

  if (foundAction) {
    return true;
  }
  return false;
}

export function CheckCondition(path: string) {
  const matchedAction = ActionsPathLists.find(action => path.startsWith(action.path));
  if (matchedAction) {
    return true;
  }
  return false;
}
