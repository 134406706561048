import { TabPanel } from '@mui/lab';
import { Grid } from '@mui/material';
import React from 'react';
import NonAccountHolderKYC from './NonAccountHolderKYC';
import { OrganizationSetupContextType } from '../../../../types/organization';

interface PropsType {
  nonAccountHolder: OrganizationSetupContextType['nonAccountHolder'];
  onChangeNonAccountHolder: OrganizationSetupContextType['setNonAccountHolder'];
}

function NonAccountHolder(props: PropsType) {
  const { nonAccountHolder, onChangeNonAccountHolder } = props;

  return (
    <TabPanel value="non_account_holder" className="p-0 w-full">
      <Grid container>
        <Grid container item xs={12} md={6}>
          <NonAccountHolderKYC data={nonAccountHolder} handleChange={onChangeNonAccountHolder} />
        </Grid>
        <Grid container item xs={12} md={6}></Grid>
      </Grid>
    </TabPanel>
  );
}

export default NonAccountHolder;
