import SbButton from '@Components/Button';
import SBDropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import SbTextField from '@Components/Textfield';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import React, { useMemo, useState } from 'react';
// import Actions from '../Actions';

import { connectOnWarehouse, setLastTransactionWarehouse } from '@Reducers/AdminShipment';
import { getBuyingRates } from '@Reducers/GetRates';
import { CSPChargeType, RatesType } from '@Reducers/GetRates/Type';
import { getFullAddress } from '@Reducers/Warehouse/helper';
import { AddressType } from '@Reducers/Warehouse/Type';
import { AppDispatch, RootStateType } from '@Store';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { defaultBoxes } from '../../../../GetRatesV2/Constant';
import { ConnectWarehouseFormSchema } from '../schema';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '@Components/Image';
import { ShipmentControl } from '@Services/shipmentActionControl';
import FedXInformation from './Fedex/FedXInformation';
import { initFedXInformation } from './Fedex/constants';
import { CSP_TYPES } from '@Constants/Shipment';

function getAddressObj({ pincode, city, country, state }: AddressType) {
  return { city, state, country, pincode };
}

interface ConnectWarehouseFormType {
  fromWarehouseId: string;
  onSelectRate: (selectedCharge: CSPChargeType) => void;
  children?: React.ReactNode;
}

interface InitConnectWarehouse {
  warehouseId: string;
  buyigRate: string;
  awb: string;
}
const initConnectWarehouse: InitConnectWarehouse = {
  warehouseId: '',
  buyigRate: '',
  awb: '',
};

function ConnectWarehouseForm(props: ConnectWarehouseFormType) {
  const id = React.useId();
  const warehouseDropDownId = React.useId();
  const dispatch = useDispatch<AppDispatch>();
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment?.shipmentById);
  const lastTransitWarehouse = useSelector((state: RootStateType) => state.adminShipment?.lastTransitWarehouse);
  const countriesObj = useSelector((state: RootStateType) => state.global.countriesObj);
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const BuyingRates = useSelector((state: RootStateType) => state.getRates.buyingRates);
  const [showAWB, setShowAWB] = useState<string>('');
  const [buyingCode, setBuyingCode] = useState<string>('');
  const connectOnWarehouseList = useSelector(
    (state: RootStateType) => state.adminShipment.shipmentConnectOnWarehouse?.map(x => x.warehouseId) || [],
  );
  const shipmentActionControl = new ShipmentControl(shipmentById);

  const {
    warehouseListForShipent: { list: warehouseList },
    warehouseListForShipmentOptionsHashById,
  } = useSelector((state: RootStateType) => state.Warehouse);

  const warehouseListOptions = useMemo(() => {
    if (!props.fromWarehouseId) return [];

    const _warehouseList: DropdownOptionType<AddressType>[] = warehouseList
      .filter(wh => wh._id !== props.fromWarehouseId)
      .map(({ name, _id, _addressId }) => ({
        display: name,
        value: _id,
        secondary: getFullAddress({ ..._addressId, country: countriesObj?.[_addressId?.country]?.name || '' }),
        metadata: { ..._addressId, country: countriesObj?.[_addressId?.country]?.name || '' },
      }));

    return _warehouseList;
  }, [props.fromWarehouseId, warehouseList]);

  const BuyingChargesList: DropdownOptionType<CSPChargeType>[] = useMemo(() => {
    if (!BuyingRates) return [];
    return BuyingRates.charges.map(charge => ({
      display: charge.serviceName,
      value: `${charge._systemId}-${charge._courierId}`,
      secondary: `Total charge: ${organizationCountry?.currency?.name || ''} ${charge.totalPricing}`,
      metadata: charge,
    }));
  }, [BuyingRates]);

  const ConnectWarehouseFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...initConnectWarehouse },
    validationSchema: ConnectWarehouseFormSchema,
    onSubmit(values) {
      if (shipmentById?.shipmentNumber) {
        const [_systemId, _courierId] = values.buyigRate.split('-');
        dispatch(
          connectOnWarehouse({
            shipmentNumber: shipmentById?.shipmentNumber,
            connectOn: [...connectOnWarehouseList, values.warehouseId],
            awb: values.awb,
            selectedCourierId: _courierId,
            selectedSystemId: _systemId,
            countryId: warehouseList?.find(item => item?._id === values.warehouseId)?._addressId?.country || '',
          }),
        );
      }
    },
  });

  const fetchConnectOnRateRates = (selectedObj: AddressType) => {
    const payload: RatesType | null = getRatePayload(selectedObj);
    if (payload) dispatch(getBuyingRates(payload));
  };

  const getRatePayload = (selectedObj: AddressType) => {
    const { _packageId, buyingPricing, createdAt } = shipmentById || {};
    const isDocument = shipmentById?._packageId.isDocument || false;
    const shipmentType = isDocument ? 'document' : 'parcel';

    const source = warehouseListForShipmentOptionsHashById?.[props.fromWarehouseId]?._addressId;
    const destination = selectedObj;
    if (!source || !destination) return null;

    const payload: RatesType = {
      onlyBuying: true,
      source: getAddressObj({ ...source, country: countriesObj?.[source.country]?.name || '' }),
      destination: getAddressObj(destination),
      document: isDocument,
      weight: buyingPricing?.chargeableWeight || 0,
      shipmentType: shipmentType,
      package: {
        box: _packageId?.box.length
          ? _packageId?.box.map(box => ({
              weight: box.weight.toString(),
              length: box.length,
              width: box.width,
              height: box.height,
            }))
          : defaultBoxes,
        unitOfMeasurement: _packageId?.unitOfMeasurement || 'kg-cm',
      },
      rateFor: 'connect-on',
      date: createdAt ? new Date(createdAt) : undefined,
      // userId: _userId?._id,
    };
    return payload;
  };

  const { values, handleChange, setFieldValue, errors, handleSubmit } = ConnectWarehouseFormik;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container item>
      <Grid
        container
        item
        alignItems="flex-start"
        display="grid"
        gridTemplateColumns={isSmallScreen ? '1fr' : '1.5fr 2fr'}
        gap={14}
        mt={4}
      >
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6}>
            <SBDropdown
              id={warehouseDropDownId}
              options={warehouseListOptions}
              required
              label="Select Transit Warehouse"
              value={values.warehouseId ? [values.warehouseId] : []}
              onChange={([value], [selectedObj]: DropdownOptionType<AddressType>[]) => {
                setFieldValue('warehouseId', value);
                setFieldValue('buyigRate', '');
                dispatch(setLastTransactionWarehouse(selectedObj));
                if (selectedObj.metadata) fetchConnectOnRateRates(selectedObj.metadata);
              }}
              error={errors.warehouseId}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SBDropdown
              id={id}
              required
              label="Transit Service"
              error={errors.buyigRate}
              options={BuyingChargesList}
              value={values.buyigRate ? [values.buyigRate] : []}
              onChange={([value], [selectedObj]: [DropdownOptionType<CSPChargeType>]) => {
                setShowAWB('');
                setFieldValue('buyigRate', value);
                setBuyingCode(selectedObj?.metadata?.code || '');
                if (selectedObj.metadata) props.onSelectRate(selectedObj.metadata as CSPChargeType);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SbTextField value={values.awb} name="awb" label="Carrier AWB" onChange={handleChange} />
          </Grid>
        </Grid>
        {props?.children}
      </Grid>
      {shipmentActionControl.canAddTransitHub && (
        <Grid container item mt={3} gap={2}>
          {!shipmentById?.carrierDetails?.awb && (
            <Grid item xs={12} lg={3.5}>
              <SbButton
                variant="outlined"
                sx={{ borderRadius: `4px !important`, padding: '0 !important' }}
                className="w-full border-grey sb-text-black"
                startIcon={<Image src="/images/icons/GenerateAwb.svg" alt="Edit" />}
                endIcon={<ExpandMoreIcon />}
                disabled={buyingCode !== CSP_TYPES.fedex.value}
                onClick={() => {
                  if (showAWB) {
                    setShowAWB('');
                  } else {
                    setShowAWB(CSP_TYPES.fedex.value || '');
                  }
                }}
              >
                {/* Generate DHL AWB */}
                Generate AWB
              </SbButton>
            </Grid>
          )}
          {!showAWB && (
            <Grid item xs={12} lg={3.5}>
              <SbButton
                variant="contained"
                sx={{ borderRadius: `4px !important` }}
                className="w-full"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Forward
              </SbButton>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container item xs={12}>
        <FedXInformation
          onClickForward={() => {
            //TODO: Handle when other carrier AWB added
          }}
          fedExDetails={{
            ...initFedXInformation,
            warehouseId1: shipmentById?._warehouseAddressId?._id || '',
            warehouseId2: lastTransitWarehouse?.metadata?._id,
            type: 'W-W',
            connectOn: {
              shipmentNumber: shipmentById?.shipmentNumber,
              connectOn: [...connectOnWarehouseList, values.warehouseId],
              awb: values.awb,
              selectedCourierId: values?.buyigRate?.split('-')?.[0],
              selectedSystemId: values?.buyigRate?.split('-')?.[1],
            },
          }}
          onClose={() => {
            setShowAWB('');
          }}
          isEditable={true}
          showFedExDetails={showAWB === CSP_TYPES.fedex.value}
          btnText={'Forward'}
        />
      </Grid>
    </Grid>
  );
}

export default ConnectWarehouseForm;
