import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
// import Actions from '../Actions';

import { CSPChargeType } from '@Reducers/GetRates/Type';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import Charges from '../../ShipmentCharges/Charges';
import ConnectWarehouseForm from './ConnectWarehouseForm';
import { toggleTransitHub } from '@Reducers/AdminShipment';
import { ShipmentControl } from '@Services/shipmentActionControl';
import { isActionAllowed } from '../../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

function ConnectedWarehouseInfo({ fromWarehouseId }: { fromWarehouseId: string }) {
  const dispatch = useDispatch<AppDispatch>();
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const isFinalForwardEditable = useSelector((state: RootStateType) => state.adminShipment.editFinalForward);

  const shipmentActionControl = new ShipmentControl(shipmentById);

  const [showConnectOnOption, setShowConnectOnOption] = useState<boolean>(false);
  const [selectedChargeObj, setSelectedChargeObj] = useState<CSPChargeType | null>(null);

  const onSelectCharge = (selectedCharge: CSPChargeType) => {
    setSelectedChargeObj(selectedCharge);
  };
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentProcessingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_processing,
    PERFORM_ACTION.write,
  );

  const addConnectionDisable = shipmentActionControl.canAddTransitHub
    ? shipmentById?.isFinalForward
      ? !isFinalForwardEditable
      : !shipmentById?.firstConnectingWarehouse?._warehouseId
    : true;

  return (
    <Grid container>
      {/* {!shipmentById?.carrierDetails?.awb && ( */}
      <Grid container item xs={12}>
        <FormControlLabel
          disabled={addConnectionDisable}
          sx={{ my: '6px', color: '#000', ml: 0 }}
          className={'sm-text font-medium'}
          control={
            <Checkbox
              sx={{ mr: 0.5, p: 0 }}
              checked={showConnectOnOption}
              onChange={ev => {
                setShowConnectOnOption(ev.target.checked);
                dispatch(toggleTransitHub(ev.target.checked));
              }}
              id="w-d"
              // value={!showConnectOnOption}
              name="service.legs"
              disabled={!isShipmentProcessingWriteAllowed}
            />
          }
          label="Add Transit Hub"
        />
        {/* {!showConnectOnOption ? (
            <SbButton
              variant="contained"
              sx={{ borderRadius: `4px !important` }}
              onClick={() => setShowConnectOnOption(true)}
            >
              Add Connection
            </SbButton>
          ) : (
            <SbButton
              variant="contained"
              sx={{ borderRadius: `4px !important` }}
              onClick={() => setShowConnectOnOption(false)}
            >
              Removed Connection
            </SbButton>
          )} */}
      </Grid>
      {/* )} */}
      {showConnectOnOption ? (
        <Grid container item mt={2}>
          <ConnectWarehouseForm fromWarehouseId={fromWarehouseId} onSelectRate={onSelectCharge}>
            {selectedChargeObj && (
              <>
                {/* <HorizontalDash /> */}
                {/* <div></div>{' '} */}
                <Grid container item>
                  <Grid item xs={12} md={10} xl={8}>
                    <Charges
                      pricing={selectedChargeObj.pricing}
                      taxPricing={selectedChargeObj.taxPricing}
                      totalPricing={selectedChargeObj.totalPricing}
                      currency="INR"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </ConnectWarehouseForm>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}

export default ConnectedWarehouseInfo;
