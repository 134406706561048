export const ONBOARD_STATUS_LIST = {
  incomplete_onboarding: {
    label: 'Incomplete Onboarding',
    value: 'incomplete_onboarding',
  },
  email_verification_pending: {
    label: 'Email Verification Pending',
    value: 'email_verification_pending',
  },
  on_boarded: { label: 'On-boarded', value: 'on_boarded' },
  invitation_send: {
    label: 'Invitation Send',
    value: 'invitation_send',
  },
  verification_pending: {
    label: 'Verification Pending',
    value: 'verification_pending',
  },
  email_verified: {
    label: 'Email Verified',
    value: 'email_verified',
  },
  suspended: { label: 'Suspended', value: 'suspended' },
  kyc_verified: { label: 'Kyc Verified', value: 'kyc_verified' },
  archive: { label: 'Deactivated', value: 'archive' },
  closed: { label: 'Closed', value: 'closed' },
  kyc_pending: { label: 'Kyc Pending', value: 'kyc_pending' },
  kyc_verification_pending: {
    label: 'Kyc Verification Pending',
    value: 'kyc_verification_pending',
  },
};

export const USER_STATUS_LIST = {
  on_boarded: { label: 'Active', value: 'on_boarded', filterValue: ['on_boarded'] },
  kyc_pending: { label: 'Kyc Pending', value: 'kyc_pending', filterValue: ['kyc_pending'] },
  kyc_verification_pending: {
    label: 'KYC Verification Pending',
    value: 'kyc_verification_pending',
    filterValue: ['kyc_verification_pending'],
  },
  verification_pending: {
    label: 'OTP Verification Pending',
    value: 'verification_pending',
    filterValue: ['verification_pending'],
  },
  invitation_send: {
    label: 'Invitation Send',
    value: 'invitation_send',
    filterValue: ['invitation_send'],
  },
  suspended: { label: 'Suspended', value: 'suspended', filterValue: ['suspended'] },
  archive: { label: 'Deactivated', value: 'archive', filterValue: ['archive'] },
};

export const USER_STATUS_COLOR: {
  [key in keyof typeof USER_STATUS_LIST]: { label: string; bgColor: string; textColor: string };
} = {
  on_boarded: { label: 'Active', bgColor: '#E9FFFA', textColor: '#277563' },
  invitation_send: { label: 'Invitation Send', bgColor: '#FFFFE9', textColor: '#BE9C8E' },
  verification_pending: { label: 'OTP Verification Pending', bgColor: '#FFFFE9', textColor: '#BE9C8E' },
  suspended: { label: 'Suspended', bgColor: '#FFEFE9', textColor: '#976B58' },
  archive: { label: 'Deactivated', bgColor: '#FFE9F9', textColor: '#975886' },
  kyc_pending: { label: 'Kyc Pending', bgColor: '#FFFFE9', textColor: '#BE9C8E' },
  kyc_verification_pending: { label: 'Kyc Verification Pending', bgColor: '#FFECE0', textColor: '#FF9900' },
};

export enum USER_STATUS {
  'suspended' = 'suspended',
  'archive' = 'archive',
  'on_boarded' = 'on_boarded',
  'invitation_send' = 'invitation_send',
  'kyc_pending' = 'kyc_pending',
}

export const ACCOUNT_TYPE = {
  individual: {
    label: 'Individual',
    value: 'individual',
  },
  company: {
    label: 'Company',
    value: 'company',
  },
  prepaid: {
    label: 'Prepaid',
    value: 'prepaid',
  },
  postpaid: {
    label: 'Postpaid',
    value: 'postpaid',
  },
  carrierAccess: {
    label: 'Carrier Access',
    value: 'carrier_access',
  },
  accountHolder: {
    label: 'Account holder',
    // value: 'true',
    value: 'account_holder',
  },
  nonAccountHolder: {
    label: 'Non Account holder',
    // value: 'false',
    value: 'non_account_holder',
  },
};

export const GROUPS: { [key: string]: { [key: string]: boolean | string[] | string } } = {
  pa_all: {
    code: 'pa_all',
    name: 'Primary Admin',
    multi_system: true,
    update_authority: ['pu_all', 'pa_all', 'aa_all'],
    creation_authority: ['pu_all', 'pa_all', 'aa_all'],
    same_acc_number: ['pa_all', 'aa_all'],
    admin_password_reset_access: true,
    admin: true,
    hub_admin: true,
    place_shipment_on_behalf: true,
    update_shipment: true,
    shipment_notes_view: true,
    shipment_notes_add: true,
    upload_custom_selling_rates: true,
    upload_buying_rates: true,
    upload_base_selling_rates: true,
    // Admin only has access to one action.
    user_view_all_systems: true,
    // shipment_view_all_systems: true,
    // shipment_view_only_systems_access: true,
    // shipment_view_only_account_number: true,
  },
  pu_all: {
    code: 'pu_all',
    name: 'Primary User',
    update_authority: ['pu_all', 'au_all'],
    creation_authority: ['pu_all', 'au_all'],
    same_acc_number: ['pu_all', 'au_all'],
    place_shipment: true,
  },
};
