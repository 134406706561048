import React from 'react';
import { Box, Grid, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { styled } from '@mui/system';
import { TableData } from './type';
// import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';

const StyledTableRowCell = styled(TableRowCell)(({ theme }) => ({
  textAlign: 'left',
  paddingLeft: theme.spacing(6),
  '& div': {
    padding: '0 !important',
  },
}));
function Table({
  header,
  data,
  EditPermission,
}: {
  header: string;
  data?: TableData[] | [];
  EditPermission: boolean | undefined;
}) {
  const { token } = useSelector((state: RootStateType) => state.loginUser);

  return (
    <Grid container item className="mt-3" sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography className="org-text font-semibold mb-2 ">{header}</Typography>
      <Box sx={{ height: '38rem', overflow: 'auto' }}>
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell>Sr.No</TableHeadCell>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Template Details</TableHeadCell>
              <TableHeadCell>Action</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.filter((item: { code: string }) => {
                if (header === 'Saved Template') {
                  return item.code !== token?.accessGroup;
                }
                return true;
              })
              .map((row, index) => (
                <>
                  <SingleRow
                    data={row}
                    index={index}
                    EditPermission={
                      EditPermission
                        ? header === 'Default Template'
                          ? token?.accessGroup === row?.code || token?.accessGroup === 'pa_all'
                          : true
                        : EditPermission
                    }
                  />
                </>
              ))}
          </TableBody>
        </SBTable>
      </Box>
    </Grid>
  );
}

function SingleRow({
  data,
  index,
  EditPermission,
}: {
  data: TableData;
  index: number;
  EditPermission: boolean | undefined;
}) {
  const navigate = useNavigate();

  // function dateFormatter(date: string) {
  //   const parsedDate = dayjs(date);

  //   // Format the date as dd/mm/yyyy
  //   const formattedDate = parsedDate.format('DD/MM/YYYY');

  //   return formattedDate;
  // }

  const handleEdit = (code: string) => {
    navigate('edit', { state: { code } });
  };
  return (
    <SBTableRow>
      <StyledTableRowCell className="md-text">{index + 1}</StyledTableRowCell>
      <StyledTableRowCell className="md-text font-medium">{data?.name}</StyledTableRowCell>
      <StyledTableRowCell className="md-text">Created by : {data?.createdBy}</StyledTableRowCell>
      {/* <StyledTableRowCell className="md-text">
        Date : {data?.createdAt && dateFormatter(data?.createdAt)} <br /> Created by : {data?.createdBy}
        <br /> Updated On :{data?.updatedAt && dateFormatter(data?.updatedAt)}
      </StyledTableRowCell> */}
      <StyledTableRowCell className="md-text">
        {EditPermission && (
          <Box>
            <img
              src="/images/icons/EditAction.svg"
              alt="Edit"
              style={{ width: '20px', height: '20px', cursor: 'pointer' }}
              onClick={() => handleEdit(data?.code)}
            />
          </Box>
        )}
      </StyledTableRowCell>
    </SBTableRow>
  );
}

export default Table;
