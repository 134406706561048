import React, { useEffect, useState } from 'react';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';

import { BrowserRouter, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType, rootStore } from '../store';

import getTheme from '../theme/theme';
import Layout from './Layout';
import routes from '../routes';
import ErrorBoundary from './ErrorBoundary';
import unprotected from '../routes/unprotected';
import { getCountryList } from '@Reducers/Global';
import { getOrganizationPublic } from '@Reducers/OrganizationSetup';
import { getLoggedInUser } from '@Reducers/loginUser/actions';
import Loader from './Loader';
import OrganizationOnboardPending from './OrganizationOnboardPending';
import { setPrevRoute } from '@Reducers/GetRates';
import { ONBOARD_STATUS_LIST } from '@Constants/user';
import { getAllPaymentGetWay } from '@Reducers/Wallet/actions';
import { getPagesList, getSideNavbarPages } from '@Reducers/AccessGroup/actions';
import AdminResetPassword from './ResetPassword/AdminResetPassword';
import Modal from '@Components/Modal';
import { getAccountType } from '@Services/user';
import DynamicHead from './DynamicHead';
import { ThemesColor } from '../pages/OrganizationSetup/OrganizationSetting/type';

const App = (): JSX.Element => {
  const [mode] = React.useState<PaletteMode>('light');
  const [themeColor, setThemeColor] = useState<ThemesColor[]>([]);
  const theme = React.useMemo(
    () => extendTheme(getTheme(mode, themeColor, setThemeColor)),
    [mode, themeColor, setThemeColor],
  );

  // return <>Hello Everyone</>;

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <Provider store={rootStore}>
          {/* <PersistGate persistor={persistor}> */}
          <CssVarsProvider theme={theme}>
            {/* <ThemeProvider theme={theme}> */}
            <CssBaseline />
            <StyledEngineProvider injectFirst>
              <RouteLayout />
            </StyledEngineProvider>
            {/* </ThemeProvider> */}
          </CssVarsProvider>
          {/* </PersistGate> */}
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;

function RouteLayout() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const OrgSetup = useSelector((state: RootStateType) => state.orgSetup);
  const loginUser = useSelector((state: RootStateType) => state.loginUser);
  const accountState = useSelector((state: RootStateType) => state.loginUser.profile?._accountId);

  const companyUser = getAccountType(accountState) === 'account-individual-company';

  const { pathname, search } = useLocation();
  const profileData = loginUser.profile;
  const orgPublicData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { logo = '', name = '' } = orgPublicData || {};

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getOrganizationPublic());
    dispatch(getLoggedInUser());
  }, []);

  useEffect(() => {
    if (loginUser?.token?.accessGroup) {
      dispatch(getSideNavbarPages(loginUser?.token?.accessGroup));
      dispatch(getPagesList());
      dispatch(getAllPaymentGetWay());
    }
  }, [loginUser?.token?.accessGroup]);

  useEffect(() => {
    dispatch(setPrevRoute(`${pathname}${search || ''}`));
  }, [pathname, search]);

  useEffect(() => {
    if (
      ['pu_all'].includes(profileData?.accessGroup || '') &&
      profileData?.onBoardedStatus === ONBOARD_STATUS_LIST.kyc_pending.value
    ) {
      if (companyUser) {
        navigate('/profile?tab=company_details');
      } else {
        navigate('/profile?tab=kyc');
      }
    }
  }, [profileData]);

  if (!loginUser.pageLoaded || (OrgSetup.loading && !OrgSetup.organizationPublicData) || loginUser.loading) {
    return (
      <Loader loading={true}>
        <></>
      </Loader>
    );
  } else if (loginUser.pageLoaded && !OrgSetup.organizationPublicData && !pathname.startsWith('/organization-onboard'))
    return <OrganizationOnboardPending />;

  if (profileData?._accountId?.isAdminAccount && profileData?.adminResetPassword) {
    return (
      <Modal open={true} maxWidth={'xl'}>
        <AdminResetPassword username={profileData?.email || profileData?.username} />
      </Modal>
    );
  }

  return (
    <Layout>
      <>
        <DynamicHead faviconUrl={logo} title={name} />
        <Routes>{loginUser.profile ? routes : unprotected}</Routes>
      </>
    </Layout>
  );
}

/**
 * ref for theme toggle (dark to light)
 * https://stackoverflow.com/questions/71092293/dark-mode-in-react-using-mui-v5
 */
