import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { styled } from '@mui/system';
import { Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import FilterSearch from '@Components/Filter/Search';
import SbButton from '@Components/Button';

import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootStateType } from '@Store';
import Image from '@Components/Image';
import { getCarrierList } from '@Reducers/Carrier';
import { CarrierListQuery } from '@Reducers/Carrier/Type';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const TableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 0px',
  display: 'grid',
  gridTemplateColumns: 'auto auto 145px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 0,
    gridTemplateColumns: 'auto auto 145px',
  },
}));

const SearchFilterList = [{ label: 'Name', value: 'name' }];

function CarrierSection() {
  const dispatch = useDispatch<AppDispatch>();
  const carrier = useSelector((state: RootStateType) => state.carrier);

  useEffect(() => {
    dispatch(getCarrierList({}));
  }, []);

  return (
    <Grid
      container
      display="grid"
      gridTemplateColumns={{ xs: '1fr', sm: 'repeat(auto-fill, minmax(250px, 32%))' }}
      justifyContent="center"
      gap={'20px'}
    >
      {carrier?.carrierList?.map((carrier, index) => (
        <Grid
          container
          key={index}
          className="shadow rounded"
          px={2}
          py={3}
          spacing={1}
          sx={{
            width: '100%',
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Grid
            item
            p={1}
            xs={3}
            container
            alignItems="center"
            justifyContent="center"
            className="border-1px border-light rounded"
            // style={{ overflow: 'hidden' }}
          >
            <Image
              src={carrier.logo}
              alt={carrier?.name}
              style={{ maxHeight: '200px', maxWidth: '100%', height: 'auto', width: 'auto' }}
            />
          </Grid>

          <Grid
            xs={9}
            p={1}
            container
            alignItems="center"
            flexGrow={1}
            item
            sx={{
              '@media (max-width: 600px)': {
                xs: 12,
                textAlign: 'center',
                justifyContent: 'center',
              },
            }}
          >
            <p className="truncate md-text">{carrier?.name}</p>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

export default function CarrierList() {
  const dispatch = useDispatch<AppDispatch>();
  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const [filterText, setFilterText] = useState('');
  const navigate = useNavigate();
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isCreateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Carriers.create_carriers,
    PERFORM_ACTION.write,
  );

  const onCreate = () => {
    if (isCreateAllowed) {
      navigate('/carrier-and-services/create/carrier');
    }
  };

  interface FilterObj {
    currentPage?: number;
    perPage?: number;
    searchKey?: string;
    searchValue?: string;
  }

  const getFilterObj = (options: FilterObj): CarrierListQuery => {
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filterQuery: CarrierListQuery = {
      // currentPage: options.currentPage || currentPage,
      // perPage: options.perPage || perPage,

      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    return filterQuery;
  };

  return (
    <>
      <TableActionContainer>
        <FilterSearch
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={filterText}
          onTextChange={e => {
            setFilterText(e.target.value);
          }}
          onTextSearch={() => {
            dispatch(getCarrierList(getFilterObj({})));
          }}
          onTextClear={() => {
            setFilterText('');
            dispatch(getCarrierList(getFilterObj({ searchValue: undefined })));
          }}
        />
        <div></div>

        <SbButton
          className="xs-text ml-1"
          variant="contained"
          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
          startIcon={<AddCircleIcon fontSize="small" />}
          onClick={onCreate}
          disabled={!isCreateAllowed}
        >
          Create New
        </SbButton>
      </TableActionContainer>
      <CarrierSection />
    </>
  );
}
