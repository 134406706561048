import SbButton from '@Components/Button';
import { getAccessGroupListById, getActionsList, getPagesList, updateAccessGroup } from '@Reducers/AccessGroup/actions';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pages } from './Pages';
import InputModal from './InputModal';
import { useLocation, useNavigate } from 'react-router';
import DeleteModal from '@Components/Modal/Delete';
import Loader from '@Components/Loader';

function AccessGroupCreate() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [openInputModal, setInputModal] = useState<boolean>(false);
  const [openConfirmSaveModal, setConfirmSaveModal] = useState<boolean>(false);
  const { pageIds, actionsIds, pagesList, accessGroupById, loading } = useSelector(
    (state: RootStateType) => state.accessGroup,
  );
  // const { token } = useSelector((state: RootStateType) => state.loginUser);
  const { state, pathname } = useLocation();

  const handleCloseInputModal = () => {
    setInputModal(false);
  };

  const handleCloseConfirmSaveModal = () => {
    setConfirmSaveModal(false);
  };

  useEffect(() => {
    dispatch(getPagesList());
    dispatch(getActionsList());
    if (pathname === '/access-group/edit') {
      dispatch(getAccessGroupListById(state.code));
    }
  }, []);

  const handleSaveAsNew = () => {
    setInputModal(true);
  };

  const handleUpdateAccessGroup = () => {
    const actionsIdsArr = actionsIds.map(({ _id, selectedActions }) => ({ _id, selectedActions }));
    const payload = {
      _id: accessGroupById?._id,
      data: {
        pagesId: pageIds,
        actions: actionsIdsArr,
      },
    };
    dispatch(
      updateAccessGroup({
        payload: {
          body: payload,
          code: accessGroupById?.code || '',
        },
        next: () => {
          navigate('/access-group');
        },
      }),
    );
  };

  const handleSave = () => {
    setConfirmSaveModal(true);
  };
  const handleCreate = () => {
    setInputModal(true);
  };

  const handleBack = (link: string): void => {
    navigate(link);
  };

  const onConfirmSaveModal = () => {
    handleUpdateAccessGroup();
    setConfirmSaveModal(false);
  };

  return (
    <Loader loading={loading} overly>
      <>
        <Grid container>
          <Grid item>
            <Grid container item>
              {pagesList &&
                Object?.keys(pagesList)?.map(moduleKey => <Pages moduleName={moduleKey} data={pagesList[moduleKey]} />)}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '1.5rem' }}>
            <Box className="flex justify-between">
              <Box>
                {pathname === '/access-group/edit' ? (
                  <SbButton variant="outlined" onClick={handleSaveAsNew}>
                    Save As New
                  </SbButton>
                ) : null}
              </Box>
              <Box className="flex " sx={{ gap: '1.5rem' }}>
                <SbButton onClick={() => handleBack('/access-group')} variant="outlined">
                  Back
                </SbButton>
                {pathname === '/access-group/create' ? (
                  <SbButton onClick={handleCreate} variant="contained">
                    Create
                  </SbButton>
                ) : (
                  <SbButton onClick={handleSave} variant="contained" disabled={accessGroupById?.isDefault}>
                    Save
                  </SbButton>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
        <InputModal open={openInputModal} onClose={handleCloseInputModal} />
        <DeleteModal
          icon="/images/icons/SuccessIcon.svg"
          open={openConfirmSaveModal}
          title="Do you want to save changes to this template?"
          subTitle=""
          onClose={handleCloseConfirmSaveModal}
          onConfirm={onConfirmSaveModal}
        />
      </>
    </Loader>
  );
}

export default AccessGroupCreate;
