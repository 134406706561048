import React from 'react';
import { Chip } from '@mui/material';

function SelectedAccountNumber({
  accountNumber,
  onRemove,
}: {
  accountNumber: string;
  onRemove: (accountNumber: string) => void;
}) {
  return <Chip label={accountNumber} variant="outlined" onDelete={() => onRemove(accountNumber)} />;
}

export default SelectedAccountNumber;
