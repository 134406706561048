import * as Yup from 'yup';
import { APPLY_FOR_OPTIONS } from '../constant';
export const CreateRateSchema = Yup.object().shape({
  accountNumber: Yup.string().trim().nullable(),
  csp: Yup.string().trim().required(),
  system: Yup.string().trim().required(),
  document: Yup.boolean().default(false).required(),
  endDate: Yup.string().trim().required(),
  startDate: Yup.string().trim().required(),
  maxWeight: Yup.string().trim().nullable(),
  minWeight: Yup.string().trim().nullable(),
  isImport: Yup.boolean().default(false).required(),
  // sheet: Yup.object({ zone: Yup.array() }),
  // markup: Yup.string().trim().required(),
  rateApplyFor: Yup.string()
    .oneOf(APPLY_FOR_OPTIONS.map(x => x.value))
    .required(),
});

export const editRateSchema = Yup.object().shape({
  accountNumber: Yup.string().trim().nullable(),
  _courierId: Yup.string().trim().required('CSP is required/'),
  _systemId: Yup.string().trim().required('system is required.'),
  documentRates: Yup.boolean().default(false).required('Document type is required'),
  endDate: Yup.string().trim().required(),
  startDate: Yup.string().trim().required(),
  maxWeight: Yup.string().trim().nullable(),
  minWeight: Yup.string().trim().nullable(),
  isImport: Yup.boolean().default(false).required(),
  // sheet: Yup.object({ zone: Yup.array() }),
  // markup: Yup.string().trim().required(),
  rateApplyFor: Yup.string()
    .oneOf(APPLY_FOR_OPTIONS.map(x => x.value))
    .required(),
});

export const CopyRateSchema = Yup.object().shape({
  csp: Yup.string().trim().required(),
  system: Yup.array(Yup.string().trim()).min(1, 'At least one system is required.'),
  endDate: Yup.string().trim().required(),
  startDate: Yup.string().trim().required(),
  maxWeight: Yup.string().trim().nullable(),
  minWeight: Yup.string().trim().nullable(),
});
