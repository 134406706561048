import React, { useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { BoxDetailsType, BoxType, GetRatesContextType } from '../../Type';
import { BoxButton } from '../../StyleComponent';
import GetRatesContext from '../../../../context/GetRates';
import { calculateVolume } from '../../../../services/calculateVolume';
import BoxDelete from '../BoxDelete/BoxDelete';
import { LENGTH_UNITS, defaultBox } from '../../Constant';
import updateBoxId from '@Services/updateBoxId';

function WeightDesc({ label, value }: { label: string; value: string }) {
  return (
    <Grid container className="mb-1/2" justifyContent="space-between">
      <Grid item xs={6}>
        <Typography className="xs-text">{label}</Typography>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <Typography className="xs-text">{value}</Typography>
      </Grid>
    </Grid>
  );
}

function BoxDetails({ setBoxState, data }: BoxDetailsType) {
  const { boxes, setBoxes, ratesInput } = React.useContext(GetRatesContext) as GetRatesContextType;
  const [openDeleteBox, setOpenDeleteBox] = useState({ open: false });
  const { id = '0', length, height, width, weight } = data;
  const { weightUnit } = ratesInput;

  const volumetricWeight = useMemo(
    () => calculateVolume(length, width, height, LENGTH_UNITS[weightUnit].value),
    [length, width, height, weightUnit],
  );

  function deleteConfirm() {
    let boxesTemp = [...boxes];
    if (id) {
      boxesTemp.splice(parseInt(id), 1);
      boxesTemp = boxesTemp.map((box, ind) => ({ ...box, id: ind.toString() }));
    }
    const arr = updateBoxId<BoxType>(boxesTemp, defaultBox, 0);
    setBoxes([...arr]);
    handleCloseModal();
  }

  function onDelete() {
    if (boxes.length > 1) setOpenDeleteBox({ open: true });
  }

  const handleCloseModal = () => {
    setOpenDeleteBox({ open: false });
  };

  const actualWeight = typeof weight === 'string' ? parseFloat(weight || '0') : weight || 0;
  const chargeableWeight = volumetricWeight > actualWeight ? volumetricWeight : actualWeight;

  return (
    <>
      <Grid container item justifyContent="space-between" alignItems="center">
        <Grid container justifyContent="space-between">
          <Grid container item xs={11} className="mb-1/2">
            <Typography className="md-text font-bold">{`Box ${parseInt(id) + 1} of ${boxes.length}`}</Typography>
            {/* <Typography className="md-text font-bold">Box 1-3</Typography> */}
            {/* {boxCopyCount && boxCopyCount > 0 ? (
              <CopyCount className="xs-text ml-1 px-1 rounded-xs">x {boxCopyCount}</CopyCount>
            ) : (
              <></>
            )} */}
          </Grid>
          <Grid item xs={1}>
            <img
              src={`/images/Boxes/${boxes.length <= 1 ? 'DeleteDisabled' : 'Delete'}.svg`}
              alt="logistics"
              className="mr-1"
              style={{ cursor: 'pointer' }}
              onClick={onDelete}
            />
          </Grid>
        </Grid>
        <WeightDesc label="Actual Weight" value={`${actualWeight || 0} ${weightUnit}`} />
        <WeightDesc label="Volumetric Weight" value={`${volumetricWeight || 0} ${weightUnit}`} />
        <WeightDesc label="Chargeable Weight" value={`${chargeableWeight} ${weightUnit}`} />
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" columnSpacing={1} mt={1}>
        <Grid item xs={6} title="Edit Box">
          <BoxButton
            onClick={() => setBoxState('edit')}
            className="truncate"
            startIcon={<img src="/images/Boxes/Edit.svg" alt="logistics" className="mr-1 sm-text" />}
          >
            Edit
          </BoxButton>
        </Grid>
        <Grid item xs={6} title="Duplicate">
          <BoxButton
            onClick={() => setBoxState('copy')}
            className="truncate"
            startIcon={<img src="/images/Boxes/Copy.svg" alt="logistics" className="mr-1 sm-text" />}
          >
            Duplicate
          </BoxButton>
        </Grid>
      </Grid>
      <BoxDelete open={openDeleteBox.open} onClose={handleCloseModal} onConfirm={deleteConfirm} />
    </>
  );
}

export default BoxDetails;
