import React from 'react';
import { TimelineDot, TimelineSeparator } from '@mui/lab';
import Image from '@Components/Image';
import { TimelineConnectorStyled } from '../style';

function SideTimelineSeparator({
  inTransit = false,
  isDestination = false,
}: {
  inTransit?: boolean;
  isDestination?: boolean;
}) {
  const classname = `img ${inTransit ? '' : 'selected'}`;
  return (
    <TimelineSeparator>
      {/* <TimelineConnectorStyled className="selected img" /> */}
      {inTransit ? (
        <Image src="/images/shipment/airplane.svg" alt="Box" height={24} style={{ maxWidth: '24px' }} />
      ) : (
        <TimelineDot
          sx={{
            width: '10px',
            height: '10px',
            mx: '6px',
            my: isDestination ? '11px' : 0,
            backgroundColor: theme => (isDestination ? '' : theme.palette.primary.main),
          }}
        />
      )}
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
      <TimelineConnectorStyled className={classname} />
    </TimelineSeparator>
  );
}

export default SideTimelineSeparator;
