import {
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
// import {
//   Checkbox,
//   Grid,
//   Menu,
//   MenuItem,
//   TableBody,
//   TableHead,
//   TablePagination,
//   TableRow,
//   Typography,
// } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SbButton from '../../components/Button';
import ExportBtn from '../../components/Button/Export';
import DateRangePicker from '../../components/DateRangePicker';
import FilterSelect from '../../components/Filter/Dropdown';
import FilterSearch from '../../components/Filter/Search';
import { SBTable, SBTableWrapper } from '../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../components/Table/TableCell';
import { SBTableRow } from '../../components/Table/TableRow';
import Title from '../../components/Title';
import Navigation from './Navigation';

// ICONS
import ClearIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CspLabelIcon from '@mui/icons-material/HandshakeOutlined';
import ShippingLabelIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import ShipmentInvoiceIcon from '@mui/icons-material/SaveOutlined';
import ProformaInvoiceIcon from '@mui/icons-material/SummarizeOutlined';
import { resetManifest, resetManifestSuccess } from '@Reducers/Manifest';
// import { useSnackbar } from 'notistack';
import DownloadShipmentLabelAndInvoice from '@Components/DownloadShipmentLabelAndInvoice';
import Loader from '@Components/Loader';
import ShipmentKycUpload from '@Components/ShipmentKycUpload';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { getLoggedInUserSystemList } from '@Reducers/Systems';
import { useNavigate } from 'react-router-dom';
import { DropdownOptionType } from '../../components/Dropdown/Type';
import Image from '../../components/Image';
// import CONFIG from '../../config';
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import API from '@Services/apiAxios';
import {
  CSP_TYPES,
  hideDownloadCarrierAWBAction,
  SHIPMENT_ROUTE_TYPE,
  SHIPMENT_STATUS,
} from '../../constants/Shipment';
import { hasAdminAccess, userLogin } from '../../services/hasAdminAccess';
import { AppDispatch, RootStateType } from '../../store';
import { UserType } from '../../store/reducers/Shipment/Type';
import { getShipmentList, setSelectedShipments as setSelectedShips } from '../../store/reducers/ShipmentHistory';
import {
  // downloadBulkCarrierLabel,
  downloadBulkProformaInvoice,
  downloadBulkShippingInvoice,
  downloadBulkShippingLabel,
  downloadCarrierLabel,
} from '../../store/reducers/ShipmentHistory/actions';
import { ShipmentHistoryQuery, ShipmentObj } from '../../store/reducers/ShipmentHistory/Type';
import { isActionAllowed } from '../../utils/allowedActions';
import { UNIT_OF_MEASUREMENT } from '../GetRatesV2/Constant';
import CreateManifestSuccessModal from '../Manifest/SuccessModal';
import BulkStatusUpload from './BulkStatusUpload';
import CreateManifest from './CreateManifest';
import DownloadOptions from './DonloadOptions';
import { FileDownloadOptionPropsType, FilterObj } from './Types';
import UpdateStatus from './UpdateStatus';
import { fetchShipmentReports } from '@Reducers/shipmentReportsDownloadSlice/shipmentReportsDownloadSlice';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';

const SearchFilterList = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Account no.', value: 'accountNumber' },
  { label: 'Company Name', value: 'companyName' },
  { label: 'Shipment Number', value: 'shipmentNumber' },
  { label: 'AWB Number', value: 'awb' },
  { label: 'Invoice Number', value: 'invoiceNumber' },
  { label: 'Reference Number', value: 'referenceNumber' },
];
const AdminSearchFilterList = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Account no.', value: 'accountNumber' },
  { label: 'Company Name', value: 'companyName' },
  { label: 'Manifest Number', value: 'manifest_number' },
  { label: 'Shipment Number', value: 'shipmentNumber' },
  { label: 'AWB Number', value: 'awb' },
  { label: 'Invoice Number', value: 'invoiceNumber' },
  { label: 'Reference Number', value: 'referenceNumber' },
];

const StatusList = Object.values(SHIPMENT_STATUS)
  .filter(item => item.value !== 'unprocessed' && item.value !== 'draft')
  .map(s => ({ display: s.label, value: s.value }));

const TableActionContainer = styled('div')<{ isAdmin?: '1' | '0' }>(({ theme, isAdmin = false }) => ({
  padding: '32px 0px',
  display: 'grid',
  gridTemplateColumns:
    isAdmin === '1'
      ? '510px 1fr fit-content(140px) fit-content(140px) 140px 140px 140px'
      : '510px 1fr fit-content(140px) fit-content(140px) 140px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 0,
    gridTemplateColumns:
      isAdmin === '1'
        ? '490px 1fr fit-content(140px) fit-content(140px) 140px 140px 140px'
        : '490px 1fr fit-content(140px) fit-content(140px) 140px',
  },
}));

function UserInfo(props: { userObj: UserType }) {
  const { userObj } = props;
  return (
    <>
      <div className="p-3">
        <p className="font-semibold md-text">{userObj.name}</p>
        <p className="sm-text">
          {userObj.city} - {userObj.pincode}
        </p>
        <p className="sm-text w-max ">{userObj.country}</p>
      </div>
    </>
  );
}

function TrackingInfo(props: { shipment: ShipmentObj; isAdmin: boolean }) {
  const { shipment } = props;
  const refNo = shipment?.userReferenceNumber;
  return (
    <div className="p-3">
      <p className="font-semibold md-text">{shipment.shipmentNumber}</p>
      <p className="sm-text">
        <span className="font-semibold">Reference:</span> {refNo}
      </p>
      <p className="sm-text">
        <span className="font-semibold">AWB:</span> {shipment?.carrierDetails?.awb || '-'}
      </p>
    </div>
  );
}

function ShipmentActions(props: { shipment: ShipmentObj }) {
  const { shipment } = props;
  const navigate = useNavigate();
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
  const shipmentNumber = shipment?.shipmentNumber || '';
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isRepeatOrderAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.repeat_order,
    PERFORM_ACTION.write,
  );

  return (
    <>
      {documentUploadModalOpen && (
        <ShipmentKycUpload
          shipmentNumber={shipmentNumber}
          open={documentUploadModalOpen}
          handleClose={() => setDocumentUploadModalOpen(false)}
          dragDropWrapperOnClickRegister
        />
      )}
      <div className="p-2 flex flex-col items-center justify-center">
        <DownloadShipmentLabelAndInvoice
          handleView={() => {
            setDocumentUploadModalOpen(true);
          }}
          carrierAWBIcon={
            // TODO: Add More carrier AWB
            shipment?.vendorShipmentModel &&
            (shipment?.buyingPricing?.code === CSP_TYPES.fedex.value ||
              shipment?.buyingPricing?.code === CSP_TYPES.aramex.value ||
              shipment?.buyingPricing?.code === 'cirro' ||
              shipment?.buyingPricing?.code === CSP_TYPES.dhlIndia.value ||
              shipment?.buyingPricing?.code === CSP_TYPES.dhlIndia.value ||
              shipment?.buyingPricing?.code === CSP_TYPES.stage.value ||
              shipment?.buyingPricing?.code === CSP_TYPES.dhl.value)
              ? shipment?.buyingPricing?.serviceImage
              : ''
          }
          hideCarrierAWB={hideDownloadCarrierAWBAction(shipment)}
          shipmentNumber={shipmentNumber}
          // carrierAWBType={shipment?.buyingPricing?.code}
          // vendorShipmentId={shipment?._vendorShipmentId}
        />
        <Grid container item justifyContent="center" className="mt-2">
          <SbButton
            containerClass="py-1 px-3 normal-case rounded-full xs-text"
            sx={{ height: '26px' }}
            variant="outlined"
            startIcon={<ReplayIcon />}
            onClick={() => {
              if (isRepeatOrderAllowed) {
                navigate(
                  `/shipment/create-single?ref=${shipment.shipmentNumber}${
                    isAdmin ? `&accountId=${shipment._userId._id}` : ''
                  }`,
                );
              }
            }}
            disabled={isAdmin && !shipment?._accountId?.isAccountHolder ? true : !isRepeatOrderAllowed}
          >
            Repeat Order
          </SbButton>
        </Grid>
      </div>
    </>
  );
}

function ShipmentHistoryTable(props: { isAdmin: boolean }) {
  const {
    data: { list, currentPage, perPage },
    selectedShipments,
  } = useSelector((state: RootStateType) => state.shipmentHistory);
  const serviceList = useSelector((state: RootStateType) => state?.carrierAndService?.serviceList);
  const navigate = useNavigate();
  const isAdmin = hasAdminAccess();
  const isUser = userLogin();
  const dispatch = useDispatch<AppDispatch>();
  const onSelectShipment = function (shipmentId: string, isChecked: boolean) {
    const newSelectedShipment = { ...selectedShipments };
    if (isChecked) newSelectedShipment[shipmentId] = true;
    else delete newSelectedShipment[shipmentId];

    dispatch(setSelectedShips(newSelectedShipment));
  };

  interface FirstResponseItem {
    selectedCourierId: string;
    [key: string]: any; // Additional properties
  }

  interface CarrierId {
    _id: string;
    name: string;
    lowerName: string;
    logo: string;
    isAccountHolder: boolean;
    code: string;
    isActive: boolean;
    __v: number;
  }

  interface Service {
    legs: string[];
  }

  interface DimWtDivisor {
    pound: number;
    kg: number;
  }

  interface SecondResponseItem {
    service: Service;
    dimWtDivisor: DimWtDivisor;
    _id: string;
    carrierId: CarrierId;
    name: string;
    logo: string;
    isApiIntegrated: boolean;
    integrationDetails: any[];
    boxWiseRates: boolean;
    isActive: boolean;
    __v: number;
  }

  const getCourierName = (
    firstResponse: FirstResponseItem[],
    secondResponse: SecondResponseItem[],
  ): (string | null)[] => {
    return firstResponse.map(item => {
      const courierId = item.selectedCourierId;
      const matchingService = secondResponse.find(service => service._id === courierId);
      return matchingService ? matchingService.name : null;
    });
  };

  const courierNames = getCourierName(list as any, serviceList as any);

  const checkAllShipments = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newShipmentCheckedList = list.reduce((op: { [k: string]: boolean }, shipment: ShipmentObj) => {
        op[shipment.shipmentNumber] = true;
        return op;
      }, {});
      dispatch(setSelectedShips(newShipmentCheckedList));
    } else {
      dispatch(setSelectedShips({}));
    }
  };

  // console.info('list:', list);

  return (
    <SBTableWrapper>
      <SBTable className="rounded-md border-none" stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Checkbox
                style={{ color: 'inherit' }}
                checked={Object.keys(selectedShipments).length === list.length}
                onChange={checkAllShipments}
              />
            </TableHeadCell>
            <TableHeadCell style={{ minWidth: '80px' }}>Sr no.</TableHeadCell>
            <TableHeadCell>Date</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell style={{ minWidth: '250px' }}>Shipper</TableHeadCell>
            <TableHeadCell style={{ minWidth: '250px' }}>Consignee</TableHeadCell>
            {props.isAdmin && <TableHeadCell style={{ minWidth: '220px' }}>Service Name</TableHeadCell>}
            <TableHeadCell>Tracking</TableHeadCell>
            <TableHeadCell style={{ minWidth: '120px' }}>Status</TableHeadCell>
            <TableHeadCell>Char.Weight</TableHeadCell>
            {props.isAdmin && <TableHeadCell style={{ minWidth: '120px' }}>Type</TableHeadCell>}
            <TableHeadCell>Actions</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((shipment, index) => {
            const routeTypeObj = SHIPMENT_ROUTE_TYPE[shipment.shipmentRouteType] || {};
            const statusObj = SHIPMENT_STATUS[shipment.status] || {};
            const isUnprocessed = shipment.status === SHIPMENT_STATUS?.unprocessed?.value;
            return (
              <SBTableRow
                key={shipment.shipmentNumber}
                className={selectedShipments[shipment.shipmentNumber] ? 'selected cursor-pointer' : 'cursor-pointer'}
                onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
                  const { target } = e;
                  if ((target as HTMLInputElement).getAttribute('type') === 'checkbox') {
                    return;
                  }
                  return navigate(
                    `${
                      isAdmin || !isUser
                        ? `/shipment/admin${isUnprocessed ? '/unprocessed' : ''}/edit`
                        : '/shipment/history'
                    }/${shipment.shipmentNumber}`,
                  );
                }}
              >
                <TableRowCell
                  align="center"
                  onClick={event => {
                    // event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <div>
                    <Checkbox
                      style={{ color: 'inherit' }}
                      onChange={e => {
                        onSelectShipment(shipment.shipmentNumber, e.target.checked);
                      }}
                      checked={!!selectedShipments[shipment.shipmentNumber]}
                    />
                  </div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  <div>{index + 1 + (currentPage - 1) * perPage}</div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  {moment(shipment.createdAt).format('DD/MM/YYYY')}
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  {/* {moment(shipment.createdAt).format('DD/MM/YYYY')} */}
                  {shipment?.company?.name ? shipment?.company?.name : shipment?._userId?.name || 'N/A'}
                </TableRowCell>
                <TableRowCell>
                  <UserInfo userObj={shipment._shipperId} />
                </TableRowCell>
                <TableRowCell>
                  <UserInfo userObj={shipment._consigneeId} />
                </TableRowCell>
                {props.isAdmin && (
                  <TableRowCell align="center" className="sm-text">
                    {courierNames[index] ? courierNames[index] : shipment?.sellingPricing?.serviceName}
                  </TableRowCell>
                )}
                <TableRowCell
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <TrackingInfo shipment={shipment} isAdmin={props.isAdmin} />
                </TableRowCell>
                <TableRowCell align="center">
                  <Typography
                    className="p-1 rounded-xs sm-text font-medium capitalize w-max flex justify-self-center"
                    sx={{
                      color: statusObj.textColor,
                      background: statusObj.bgColor,
                      px: 1,
                      py: 0.5,
                      justifySelf: 'center',
                    }}
                  >
                    {statusObj.label}
                  </Typography>
                </TableRowCell>
                <TableRowCell className="md-text font-semibold" align="center">
                  {shipment.sellingPricing?.displayChargeableWeight
                    ? `${shipment.sellingPricing?.displayChargeableWeight} ${
                        UNIT_OF_MEASUREMENT[shipment._packageId.unitOfMeasurement as keyof typeof UNIT_OF_MEASUREMENT]
                          ?.weightUnit || '-'
                      }`
                    : '-'}
                </TableRowCell>
                {props.isAdmin && (
                  <TableRowCell align="center">
                    <Typography
                      className="p-1 rounded-xs capitalize"
                      sx={{ color: routeTypeObj.textColor, background: routeTypeObj.bgColor, px: 1, py: 0.5 }}
                    >
                      {routeTypeObj.label || shipment.status}
                    </Typography>
                  </TableRowCell>
                )}
                <TableRowCell
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <ShipmentActions shipment={shipment} />
                </TableRowCell>
              </SBTableRow>
            );
          })}
        </TableBody>
      </SBTable>
    </SBTableWrapper>
  );
}

function ImportExportActions(props: { isAdmin: boolean; getFilterObj: (options: FilterObj) => ShipmentHistoryQuery }) {
  // console.info(props.getFilterObj({}), '>>>>>>', 'export');
  // console.info(getFilterObj, 'filter');
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const accessGroup = useSelector((state: RootStateType) => state?.loginUser?.token?.accessGroup?.toLocaleLowerCase());
  const { limit } = useSelector((state: RootStateType) => state.notifications);
  // const accessGroup = 'accounts';

  const options = [
    { value: 'ACCOUNTS', label: 'Account Detailed' },
    { value: 'ACCOUNTS_SUMMARY', label: 'Accounts Summary' },
    { value: 'CS', label: 'Customer Service Detailed' },
    { value: 'OP', label: 'Operation Detailed' },
    { value: 'SALES', label: 'Sales Detailed' },
    { value: 'ALL', label: 'Shipment Report Detailed' },
    { value: 'CS_SUMMARY', label: 'Customer Service Summary' },
    { value: 'OP_SUMMARY', label: 'Operation Summary' },
    { value: 'SALES_SUMMARY', label: 'Sales Summary' },
    { value: 'ALL_SUMMARY', label: 'Shipment Report Summary' },
  ];
  options.sort((a, b) => a.label.localeCompare(b.label));
  const getFilteredOptions = () => {
    switch (accessGroup) {
      case 'superadmin':
      case 'pa_all':
        return options;
      case 'sales':
        return options.filter(option => ['SALES', 'SALES_SUMMARY'].includes(option.value));
      case 'operation':
        return options.filter(option => ['OP', 'OP_SUMMARY'].includes(option.value));
      case 'accounts':
        return options.filter(option => ['ACCOUNTS', 'ACCOUNTS_SUMMARY'].includes(option.value));
      case 'sales_manager':
        return options.filter(option => ['SALES_SUMMARY'].includes(option.value));
      case 'operations_manager':
        return options.filter(option => ['OP', 'OP_SUMMARY'].includes(option.value));
      case 'accounts_manager':
      case 'accounts_executive':
        return options.filter(option => ['ACCOUNTS_SUMMARY'].includes(option.value));
      case 'complaince':
      case 'station_manager':
        return options.filter(option => ['ALL_SUMMARY'].includes(option.value));
      case 'customer_service':
        return options.filter(option => ['CS', 'CS_SUMMARY'].includes(option.value));
      default:
        return [];
    }
  };
  const filteredOptions = getFilteredOptions();

  const closeMenu = () => {
    setAnchor(null);
  };
  // Function to handle export action
  const onMenuItemClick = async (page: string) => {
    // const { fromDate, toDate, status, systems, type } = props.getFilterObj({});
    try {
      setLoading(true);
      const response = await API.get('/shipment/download-list', {
        params: {
          downloadType: page,
          ...props.getFilterObj({}),
        },
      });

      if (response?.data) {
        dispatch(fetchShipmentReports());
        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Download in Progress',
            subTitle: response?.data?.msg,
            btnText: 'Close',
            onConfirm: () => {
              dispatch(toggleBoolean());
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    } finally {
      setLoading(false);
    }
    setAnchor(null);
  };

  const handleUserShipmentsExport = async () => {
    // const { fromDate, toDate, status, systems, type } = props.getFilterObj({});
    try {
      setLoading(true);
      const response = await API.get('/shipment/download-list', {
        params: {
          downloadType: 'USER_SUMMARY',
          ...props.getFilterObj({}),
        },
      });

      if (response?.data) {
        dispatch(fetchShipmentReports());
        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Download in Progress',
            subTitle: response?.data?.msg,
            btnText: 'Close',
            onConfirm: () => {
              dispatch(toggleBoolean());
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
      }
    } catch (error) {
      // console.error(error);
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const [showBulkStatusModal, setShowBulkStatusModal] = useState<boolean>(false);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isBulkStatusUploadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.bulk_status_upload,
    PERFORM_ACTION.write,
  );

  const isExportReportsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.export_reports,
    PERFORM_ACTION.write,
  );

  return (
    <>
      <div className="overflow-auto" id="fadedScroll">
        <Grid
          container
          justifyContent={{ xs: props.isAdmin ? '' : 'flex-end', md: 'flex-end' }}
          flexWrap={{ xs: 'nowrap', md: 'wrap' }}
          style={{ marginTop: '52px', marginBottom: '25px' }}
        >
          {props.isAdmin ? (
            <>
              <SbButton
                startIcon={<Image src={'/images/file_upload.svg'} alt="upload" />}
                className="border-1px border-light  mr-2"
                sx={{ borderRadius: '8px !important', minWidth: '225px' }}
                onClick={() => {
                  if (isBulkStatusUploadAllowed) {
                    setShowBulkStatusModal(true);
                  }
                }}
                variant="outlined"
                disabled={!isBulkStatusUploadAllowed}
              >
                Bulk Status Upload
              </SbButton>
              <SbButton
                // className="xs-text"
                variant="contained"
                sx={{ borderRadius: '8px !important', padding: '8px !important', width: '120px' }}
                onClick={openMenu}
                endIcon={<ArrowDropDownCircleOutlined />}
                disabled={!isExportReportsAllowed}
              >
                Export
              </SbButton>
              <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                keepMounted
                PaperProps={{
                  sx: {
                    // maxHeight: 40 * 6,
                    width: '250px',
                  },
                }}
                // style={{ width: '120px' }}
              >
                <Loader loading={loading} />

                {filteredOptions.map(option => (
                  <MenuItem onClick={_ => onMenuItemClick(option.value)} key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          ) : (
            <>
              <ExportBtn
                iconSrc="/images/upload_document.svg"
                alt="Export orders"
                label="Export Orders"
                onClick={() => {
                  // dispatch(ExportOrder(props.getFilterObj({})));
                  handleUserShipmentsExport();
                }}
              />
            </>
          )}
        </Grid>
      </div>
      <BulkStatusUpload open={showBulkStatusModal} handleClose={() => setShowBulkStatusModal(false)} />
    </>
  );
}

const fileDownloadDefaultOption: FileDownloadOptionPropsType = {
  isOpen: false,
  title: '',
  downloadOption: 'fileFormat',
  handleDownload: () => {
    console.log('handle File Download');
  },
};

export default function ShipmentHistory() {
  const [filterSelectValue, setFilterSelectValue] = useState('email');
  const [filterText, setFilterText] = useState('');
  const [status, setStatus] = useState<string[]>([]);
  const [system, setSystem] = useState<string[]>([]);
  const [type, setType] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [updateStatus, setUpdateStatus] = useState<{ isOpen: boolean }>({ isOpen: false });
  const [createManifest, setCreatemanifest] = useState<{ isOpen: boolean }>({ isOpen: false });
  const [createManifestSuccessModal, setCreateManifestSuccessModal] = useState<{ isOpen: boolean }>({ isOpen: false });
  const [fileDownloadOption, setFileDownloadOption] = useState<FileDownloadOptionPropsType>(fileDownloadDefaultOption);

  const dispatch = useDispatch<AppDispatch>();
  // const { enqueueSnackbar } = useSnackbar();

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isBulkStatusUploadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.change_status,
    PERFORM_ACTION.write,
  );

  const isDownloadCarrierAWBAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.download_carrier_awb,
    PERFORM_ACTION.write,
  );

  const isDownloadShipmentlabelAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.download_shipment_label,
    PERFORM_ACTION.write,
  );

  const isDownloadProformaInvoiceAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.download_proforma_invoice,
    PERFORM_ACTION.write,
  );

  const isCreateManifestAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Manifest.create_manifest,
    PERFORM_ACTION.write,
  );

  const { success: manifestSuccess } = useSelector((state: RootStateType) => state.manifest);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const { success: statusUpdated, bulkStatusUploaded } = useSelector((state: RootStateType) => state.shipmentHistory);
  const loading = useSelector((state: RootStateType) => state.System.loading || state.shipmentHistory.loading);
  const {
    data: { currentPage, perPage, totalCount, list },
    selectedShipments,
  } = useSelector((state: RootStateType) => state.shipmentHistory);
  // const SystemList = useSelector((state: RootStateType) => state.System.data.list).map(system => ({
  //   display: system.name,
  //   value: system._id,
  // }));

  // HYPERLOCAL: 'hyperlocal',
  // DOMESTIC: 'domestic',
  // IMPORT: 'import',
  // EXPORT: 'export',
  // OUT: 'out',
  const TypeList: Array<DropdownOptionType> = [
    { display: 'HYPERLOCAL', value: 'hyperlocal' },
    { display: 'DOMESTIC', value: 'domestic' },
    { display: 'IMPORT', value: 'import' },
    { display: 'EXPORT', value: 'export' },
    // { display: 'OUT', value: 'out' },
  ];

  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  const isUser = userLogin();

  useEffect(() => {
    const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
    const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
    dispatch(getShipmentList({ currentPage: 1, perPage: 10, fromDate, toDate }));
    dispatch(getLoggedInUserSystemList());
  }, []);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(getShipmentList(getFilterObj({ dateRange, currentPage: 1, perPage: 10 })));
  };

  const onStatusFilter = (selected: Array<string>) => {
    setStatus(selected);
    dispatch(getShipmentList(getFilterObj({ status: selected, currentPage: 1, perPage: 10 })));
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    dispatch(getShipmentList(getFilterObj({ systems: selected, currentPage: 1, perPage: 10 })));
  };

  const onTypeFilter = (selected: Array<string>) => {
    setType(selected);
    dispatch(getShipmentList(getFilterObj({ type: selected, currentPage: 1, perPage: 10 })));
  };

  const getFilterObj = (options: FilterObj): ShipmentHistoryQuery => {
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterStatus = options.status || status;
    const filterSystem = options.systems || system;
    const filterType = options.type || type;
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filterQuery: ShipmentHistoryQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      status: filterStatus,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (isAdmin) {
      filterQuery['systems'] = filterSystem;
      filterQuery['type'] = filterType;
    }
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    return filterQuery;
  };

  useEffect(() => {
    if (manifestSuccess) {
      setCreatemanifest({ isOpen: false });
      setCreateManifestSuccessModal({ isOpen: true });
      dispatch(resetManifestSuccess());
      dispatch(setSelectedShips({}));
    }
  }, [manifestSuccess]);

  function handleManifestSuccessModalClose() {
    setCreateManifestSuccessModal({ isOpen: false });
    dispatch(resetManifest());
  }

  useEffect(() => {
    if (statusUpdated) {
      setUpdateStatus({ isOpen: false });
      dispatch(getShipmentList(getFilterObj({ currentPage: 1, perPage: 10 })));
      // enqueueSnackbar(statusUpdated, { variant: 'success', key: new Date().getTime() });
    }
  }, [statusUpdated]);

  useEffect(() => {
    if (bulkStatusUploaded) {
      setTimeout(() => {
        dispatch(getShipmentList(getFilterObj({ currentPage: 1, perPage: 10 })));
      }, 2000);
    }
  }, [bulkStatusUploaded]);

  const ButtonSx = {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '8px !important',
    backgroundColor: 'var(--mui-palette-primary-light)',
    whiteSpace: 'nowrap',
  };

  return (
    <Loader loading={loading} overly>
      <>
        <DownloadOptions {...fileDownloadOption} handleClose={() => setFileDownloadOption(fileDownloadDefaultOption)} />
        <UpdateStatus
          isOpen={updateStatus.isOpen}
          handleClose={() => {
            setUpdateStatus({ isOpen: false });
            setTimeout(() => {
              dispatch(getShipmentList(getFilterObj({})));
            }, 1000);
          }}
          selectedShipments={Object.keys(selectedShipments)}
        />
        <CreateManifest
          isOpen={createManifest.isOpen}
          handleClose={() => setCreatemanifest({ isOpen: false })}
          selectedShipments={Object.keys(selectedShipments)}
        />
        <CreateManifestSuccessModal
          isOpen={createManifestSuccessModal.isOpen}
          handleClose={handleManifestSuccessModalClose}
        />

        <Title title={isAdmin ? 'Shipment Details' : 'Shipment History'} removeBorder />
        <Navigation />
        <ImportExportActions getFilterObj={getFilterObj} isAdmin={Boolean(isAdmin)} />
        {Object.keys(selectedShipments).length === 0 && (
          <div className="overflow-auto" id="fadedScroll">
            <TableActionContainer isAdmin={isAdmin ? '1' : '0'}>
              <FilterSearch
                onSelectChange={(value: string) => {
                  setFilterSelectValue(value);
                }}
                list={isAdmin ? AdminSearchFilterList : SearchFilterList}
                selectValue={filterSelectValue}
                textValue={filterText}
                onTextChange={e => {
                  setFilterText(e.target.value);
                }}
                onTextSearch={() => {
                  dispatch(getShipmentList(getFilterObj({ currentPage: 1, perPage: 10 })));
                }}
                onTextClear={() => {
                  setFilterText('');
                  dispatch(getShipmentList(getFilterObj({ searchValue: undefined, currentPage: 1, perPage: 10 })));
                }}
              />
              <div></div>
              <DateRangePicker onChange={onDateChange} value={dateRange} />
              <FilterSelect id="status" label="Status" options={StatusList} value={status} onChange={onStatusFilter} />
              {isAdmin ? (
                <>
                  <FilterSelect
                    id="system"
                    label="System"
                    options={loggedInUserSystemDropdownList}
                    value={system}
                    onChange={onSystemFilter}
                  />
                  <FilterSelect id="type" label="Type" options={TypeList} value={type} onChange={onTypeFilter} />
                </>
              ) : (
                <></>
              )}
              <SbButton
                className="xs-text ml-1"
                variant="outlined"
                sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                onClick={() => {
                  setStatus([]);
                  setSystem([]);
                  setType([]);
                  setFilterText('');
                  setDateRange({
                    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                    endDate: moment().endOf('day').toDate(),
                  });
                  dispatch(
                    getShipmentList(
                      getFilterObj({
                        status: [],
                        searchValue: '',
                        dateRange: {
                          startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                          endDate: moment().endOf('day').toDate(),
                        },
                        currentPage: 1,
                        perPage: 10,
                      }),
                    ),
                  );
                }}
              >
                Clear Filters
              </SbButton>
            </TableActionContainer>
          </div>
        )}
        {Object.keys(selectedShipments).length > 0 && (
          <div className="overflow-auto" id="fadedScroll">
            <TableActionContainer
              style={{ zIndex: 1, gridTemplateColumns: 'repeat(7, min-content)', columnGap: '15px', rowGap: '15px' }}
            >
              <SbButton
                sx={ButtonSx}
                endIcon={<ClearIcon />}
                onClick={() => {
                  dispatch(setSelectedShips({}));
                }}
              >
                Selected ({Object.keys(selectedShipments).length})
              </SbButton>
              {isUser ? (
                <></>
              ) : (
                <SbButton
                  variant="outlined"
                  startIcon={<ShippingLabelIcon />}
                  sx={ButtonSx}
                  className="sm-text"
                  onClick={() => {
                    if (isBulkStatusUploadAllowed) {
                      setUpdateStatus({ isOpen: true });
                    }
                  }}
                  disabled={!isBulkStatusUploadAllowed}
                >
                  Change Status
                </SbButton>
              )}
              <SbButton
                variant="outlined"
                startIcon={<CspLabelIcon />}
                endIcon={<FileDownloadIcon />}
                sx={ButtonSx}
                className="sm-text"
                disabled={!isDownloadCarrierAWBAllowed}
                onClick={() => {
                  Object.keys(selectedShipments).forEach((item, index) => {
                    setTimeout(() => {
                      const shipmentDetails = list?.find(obj => obj?.shipmentNumber === item);
                      // Filtering results which have AWB(W-D, D-D)
                      if (
                        (!isUser || shipmentDetails?.carrierDetails?.awbShowToUser) &&
                        shipmentDetails?.carrierDetails?.awb
                      ) {
                        // Forming the link to download the carrier AWB
                        // const awbLink = `${CONFIG.API_URI}/shipment/label/getLabel?shipmentNumber=${item}`;
                        // const link = document.createElement('a');
                        // link.href = awbLink;
                        // link.download;
                        // document.body.appendChild(link);
                        // link.click();
                        // document.body.removeChild(link);
                        dispatch(downloadCarrierLabel(item));
                      }
                    }, index * 3000); // Adjust the delay as needed (in milliseconds)
                  });
                }}
              >
                Carrier Label
              </SbButton>
              <SbButton
                variant="outlined"
                startIcon={<ShippingLabelIcon />}
                endIcon={<FileDownloadIcon />}
                sx={ButtonSx}
                className="sm-text"
                onClick={() => {
                  if (isDownloadShipmentlabelAllowed) {
                    setFileDownloadOption({
                      isOpen: true,
                      downloadOption: 'fileDimention',
                      title: 'Download Shipping Label',
                      handleDownload: fileType =>
                        dispatch(
                          downloadBulkShippingLabel({ fileType, shipmentNumber: Object.keys(selectedShipments) }),
                        ),
                    });
                  }
                }}
                disabled={!isDownloadShipmentlabelAllowed}
              >
                Shipping Label
              </SbButton>
              <SbButton
                variant="outlined"
                startIcon={<ShipmentInvoiceIcon />}
                endIcon={<FileDownloadIcon />}
                sx={ButtonSx}
                className="sm-text"
                onClick={() => {
                  setFileDownloadOption({
                    isOpen: true,
                    downloadOption: 'fileFormat',
                    title: 'Download Invoice',
                    handleDownload: fileType =>
                      dispatch(
                        downloadBulkShippingInvoice({ fileType, shipmentNumber: Object.keys(selectedShipments) }),
                      ),
                  });
                }}
              >
                Shipping Invoice
              </SbButton>
              <SbButton
                variant="outlined"
                startIcon={<ProformaInvoiceIcon />}
                endIcon={<FileDownloadIcon />}
                sx={ButtonSx}
                className="sm-text"
                onClick={() => {
                  if (isDownloadProformaInvoiceAllowed) {
                    dispatch(downloadBulkProformaInvoice({ shipmentNumbers: Object.keys(selectedShipments) }));
                  }
                }}
                disabled={!isDownloadProformaInvoiceAllowed}
              >
                Proforma Invoice
              </SbButton>
              {isUser ? (
                <></>
              ) : (
                <SbButton
                  variant="outlined"
                  startIcon={<ProformaInvoiceIcon />}
                  sx={ButtonSx}
                  className="sm-text"
                  onClick={() => {
                    if (isCreateManifestAllowed) {
                      setCreatemanifest({ isOpen: true });
                    }
                  }}
                  disabled={!isCreateManifestAllowed}
                >
                  Create Manifest
                </SbButton>
              )}
            </TableActionContainer>

            {/* {isAdmin || isUser ? (
              <></>
            ) : (
              <TableActionContainer
                style={{ zIndex: 1, gridTemplateColumns: 'repeat(5, min-content)', columnGap: '15px', rowGap: '15px' }}
              >
                <SbButton
                  sx={ButtonSx}
                  endIcon={<ClearIcon />}
                  onClick={() => {
                    dispatch(setSelectedShips({}));
                  }}
                >
                  Selected {Object.keys(selectedShipments).length}
                </SbButton>
                <SbButton variant="outlined" startIcon={<ShippingLabelIcon />} sx={ButtonSx}>
                  Shipping Label
                </SbButton>
                <SbButton variant="outlined" startIcon={<CspLabelIcon />} sx={ButtonSx}>
                  Carrier Label
                </SbButton>
                <SbButton variant="outlined" startIcon={<ShipmentInvoiceIcon />} sx={ButtonSx}>
                  Shipping Invoice
                </SbButton>
                <SbButton variant="outlined" startIcon={<ProformaInvoiceIcon />} sx={ButtonSx}>
                  Proforma Invoice
                </SbButton>
              </TableActionContainer>
            )} */}
          </div>
        )}

        <ShipmentHistoryTable isAdmin={Boolean(isAdmin)} />
        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage - 1}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                dispatch(getShipmentList(getFilterObj({ currentPage: page + 1 })));
              }}
              onRowsPerPageChange={event => {
                dispatch(getShipmentList(getFilterObj({ perPage: parseInt(event.target.value) })));
              }}
            />
          </>
        )}
      </>
    </Loader>
  );
}
