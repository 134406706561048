import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { InvoiceCreditDebitPayload, InvoiceListQuery } from './Type';
import { hashPaymentPayloadType, initialValuesTypes } from '../../../pages/Invoice/Payment/Types';
import { DispatchError, DispatchSuccess, SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { InvoiceListQuery as InvoiceIndexPageQuery } from '@Reducers/Invoice/Type';

const getInvoiceList = createAsyncThunk('getInvoiceList', async (_queryParams: InvoiceListQuery, { dispatch }) => {
  try {
    const url = '/invoice/account/unbilled';
    const res = await API.get(`${url}?`, { params: _queryParams });
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

// const getNonAccountUnbilledList = createAsyncThunk(
//   'getNonAccountUnbilledList',
//   async (_queryParams: InvoiceListQuery, { dispatch }) => {
//     try {
//       const url = '/invoice/non-account/unbilled';
//       const res = await API.get(`${url}?`, { params: _queryParams });
//       return res.data;
//     } catch (error) {
//       const errorRes = error.response.data;
//       dispatch(
//         SuccessErrorModalOpen({
//           type: 'error',
//           title: 'Oops!',
//           subTitle: errorRes?.msg || 'Something went to wrong.',
//         }),
//       );
//       throw error;
//     }
//   },
// );

const fetchUserInvoiceList = createAsyncThunk(
  'fetchUserInvoiceList',
  async (_queryParams: InvoiceListQuery, { dispatch }) => {
    try {
      const url = '/invoice/list';
      const res = await API.get(`${url}?`, { params: { ..._queryParams } });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const fetchInvoiceList = createAsyncThunk(
  'fetchInvoiceList',
  async (_queryParams: InvoiceIndexPageQuery, { dispatch }) => {
    try {
      const url = '/invoice/account/list';
      const res = await API.get(`${url}?`, { params: { ..._queryParams, isAccountHolder: true } });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const fetchNonAccountInvoiceList = createAsyncThunk(
  'fetchNonAccountInvoiceList',
  // async (_queryParams: InvoiceListQuery , { dispatch }) => {
  async (_queryParams: InvoiceIndexPageQuery, { dispatch }) => {
    try {
      const url = '/invoice/non-account/list';
      const res = await API.get(`${url}?`, { params: { ..._queryParams, isAccountHolder: false } });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const createPaymentByCash = createAsyncThunk(
  'payment/createPaymentByCash',
  async (
    {
      postData,
      handleSuccess,
    }: {
      postData: initialValuesTypes;
      handleSuccess: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const res = await API.post('/invoice/payment/create/cash', postData);
      const payload = await res.data;
      // Assuming createOrderIdForShipment is also an async function
      // and you want to wait for it to complete
      dispatch(completePaymentByCashByHash({ postData: payload, handleSuccess }));
      return payload;
    } catch (err) {
      DispatchError('Error', err.response.data?.msg || ''); // Assuming DispatchError is properly defined
      // Use rejectWithValue to send error data along with action
      return rejectWithValue(err.response.data?.msg || 'An error occurred');
    }
  },
);

const completePaymentByCashByHash = createAsyncThunk(
  'shipment/completePaymentByCashByHash',
  async (
    {
      postData,
      handleSuccess,
    }: {
      postData: hashPaymentPayloadType;
      handleSuccess: () => void;
    },
    { rejectWithValue },
  ) => {
    try {
      const res = await API.post('/payment/pay/invoice/admin/cash', postData);
      setTimeout(() => {
        const payload = res.data.data;
        handleSuccess(); // Call handleSuccess function upon successful API call
        DispatchSuccess('Success', res.data?.msg || ''); // Assuming DispatchSuccess is properly defined
        return payload;
      }, 3000);
    } catch (err) {
      DispatchError('Error', err.response.data?.msg || ''); // Assuming DispatchError is properly defined
      return rejectWithValue(err.response.data);
    }
  },
);

const getAccountUnbilledCreditList = createAsyncThunk(
  'getAccountUnbilledCreditList',
  async (_queryParams: InvoiceListQuery, { dispatch }) => {
    try {
      const url = '/invoice/account/unbilled_credit';
      const res = await API.get(`${url}?`, { params: _queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getAccountUnbilledDebitList = createAsyncThunk(
  'getAccountUnbilledDebitList',
  async (_queryParams: InvoiceListQuery, { dispatch }) => {
    try {
      const url = '/invoice/account/unbilled_debit';
      const res = await API.get(`${url}?`, { params: _queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);
const getNonAccountUnbilledCreditList = createAsyncThunk(
  'getNonAccountUnbilledCreditList',
  async (_queryParams: InvoiceListQuery, { dispatch }) => {
    try {
      const url = '/invoice/non-account/unbilled_credit';
      const res = await API.get(`${url}?`, { params: _queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getNonAccountUnbilledDebitList = createAsyncThunk(
  'getNonAccountUnbilledDebitList',
  async (_queryParams: InvoiceListQuery, { dispatch }) => {
    try {
      const url = '/invoice/non-account/unbilled_debit';
      const res = await API.get(`${url}?`, { params: _queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);
const createInvoiceCreditDebitNote = createAsyncThunk(
  'createInvoiceCreditDebitNote',
  async (payload: InvoiceCreditDebitPayload, { dispatch }) => {
    try {
      const res = await API.post('/invoice/creditdebitnote', payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);
export {
  getAccountUnbilledCreditList,
  getAccountUnbilledDebitList,
  getNonAccountUnbilledCreditList,
  getNonAccountUnbilledDebitList,
  getInvoiceList,
  // getNonAccountUnbilledList,
  fetchUserInvoiceList,
  fetchInvoiceList,
  fetchNonAccountInvoiceList,
  createPaymentByCash,
  completePaymentByCashByHash,
  createInvoiceCreditDebitNote,
};
