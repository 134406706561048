import { RootStateType } from '@Store';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import DownloadShipmentLabelAndInvoice from '@Components/DownloadShipmentLabelAndInvoice';
import ShipmentKycUpload from '@Components/ShipmentKycUpload';
import { CSP_TYPES } from '@Constants/Shipment';

function Overview({ EditPermissionAllowed }: { EditPermissionAllowed?: boolean }) {
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment?.shipmentById);
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);
  if (!shipmentById) return <></>;
  const shipmentNumber = shipmentById?.shipmentNumber || '';
  const WritePermission = EditPermissionAllowed ?? true;
  return (
    <Grid container item className="p-2 border border-solid border-light rounded mb-2">
      <Grid container item alignItems="center" gap={1}>
        <ShipmentNumberAndManifest />
        <ShipmentPlaceDetails />
        <PlaceDate />
        <Grid container item gap={0.5} sm={'auto'}>
          {WritePermission && (
            <DownloadShipmentLabelAndInvoice
              handleView={() => setDocumentUploadModalOpen(true)}
              shipmentNumber={shipmentById?.shipmentNumber}
              carrierAWBIcon={
                // TODO: Add More carrier AWB
                shipmentById?.vendorShipmentModel &&
                (shipmentById?.buyingPricing?.code === CSP_TYPES.fedex.value ||
                  shipmentById?.buyingPricing?.code === CSP_TYPES.dhlIndia.value ||
                  shipmentById?.buyingPricing?.code === CSP_TYPES.dhl.value ||
                  shipmentById?.buyingPricing?.code === CSP_TYPES.stage.value ||
                  shipmentById?.buyingPricing?.code === 'cirro' ||
                  shipmentById?.buyingPricing?.code === CSP_TYPES.aramex.value)
                  ? shipmentById?.buyingPricing?.serviceImage
                  : ''
              }
              // carrierAWBType={shipmentById?.buyingPricing?.code}
              // vendorShipmentId={shipmentById?._vendorShipmentId}
            />
          )}
        </Grid>
        {documentUploadModalOpen && (
          <ShipmentKycUpload
            shipmentNumber={shipmentNumber}
            open={documentUploadModalOpen}
            handleClose={() => setDocumentUploadModalOpen(false)}
          />
        )}
      </Grid>
    </Grid>
  );
}

function ShipmentNumberAndManifest() {
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  return (
    <Grid container item gap={0.5} sm={4} lg={2.5} xl={2}>
      <Grid item xs={12}>
        <Typography variant="h5" className="font-medium">
          {shipmentById?.shipmentNumber}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Manifest No: {shipmentById?.manifestNumber || '-'}</Typography>
      </Grid>
    </Grid>
  );
}

function ShipmentPlaceDetails() {
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const placedBy = shipmentById?._placedById;
  const onBehalfBy = shipmentById?._userId;
  // const placedByName = placedBy?._id !== onBehalfBy?._id ? placedBy?.name : '';
  return (
    <Grid container item sm={7} lg={5} xl={4.5}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" className="capitalize">
          Placed By: {placedBy?.name}
          {placedBy?.accessGroup === 'pu_all' ? `(${placedBy?.email})` : `(${placedBy?.username})`}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {placedBy?.accessGroup === 'pu_all' ? null : (
          <Typography variant="subtitle1" className="capitalize">
            On behalf of : {onBehalfBy?.name}
            {` (${onBehalfBy?.email}) `}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

function PlaceDate() {
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const date = shipmentById?.createdAt ? moment(shipmentById?.createdAt).format('DD MMM YYYY, hh:mm a') : '';
  return (
    <Grid container item sm={4} lg={2} xl={3.5}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">{date}</Typography>
      </Grid>
    </Grid>
  );
}

export default Overview;
