import React, { useEffect, useState } from 'react';

// import { styled } from '@mui/system';
import { Box, Grid } from '@mui/material';

import Title from '@Components/Title';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getCarrierList } from '@Reducers/Carrier';
import Loader from '@Components/Loader';
import Divider from '@Components/Divider';
import BackButton from '@Components/BackButton';

export default function CarrierAndService() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [_subtitle, setSubtitle] = useState<string>();
  const loading = useSelector((state: RootStateType) => state.carrierAndService.loading);
  const isCarrierLoading = useSelector((state: RootStateType) => state.carrier.loading);
  // const showCreateBtn = location.pathname === '/carrier-and-service';
  const showBackButton =
    location.pathname.startsWith('/carrier-and-services/create/carrier') ||
    location.pathname.startsWith('/carrier-and-services/create/service') ||
    location.pathname.startsWith('/carrier-and-services/edit/service');

  const link = location.pathname.startsWith('/carrier-and-services/create/carrier')
    ? '/carrier-and-services?tab=carrier'
    : '/carrier-and-services';

  useEffect(function () {
    dispatch(getCarrierList({}));
  }, []);

  useEffect(
    function () {
      switch (location.pathname) {
        case '/carrier-and-service/carrier':
          setSubtitle('View and edit your carrier');
          break;
        case '/carrier-and-service/service':
          setSubtitle('View and edit your service');
          break;
        // case ""
      }
    },
    [location.pathname, setSubtitle],
  );

  return (
    <Loader loading={loading || isCarrierLoading} overly={true}>
      <>
        <Grid container alignItems="center">
          <Grid item xs={6} sm={10} md={10} className="mb-3" sx={{ display: 'flex', gap: '0.5rem' }}>
            {showBackButton ? (
              <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                <BackButton link={link} />
                <Title
                  title="Carriers and Services"
                  subTitle="View and  edit your carrier and service"
                  removeBorder
                  removePadding
                />
              </Box>
            ) : (
              <Title
                title="Carriers and Services"
                subTitle="View and  edit your carrier and service"
                removeBorder
                removePadding
              />
            )}

            <Divider className="mt-3 mb-3" />
          </Grid>
        </Grid>
        <Outlet />
      </>
    </Loader>
  );
}
