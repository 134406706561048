import SbButton from '@Components/Button';
import SbTextField from '@Components/Textfield';
import useConnectionTitle from '@Hook/useConnectionTitle';
import { editFinalForward } from '@Reducers/AdminShipment';
import { ShipmentControl } from '@Services/shipmentActionControl';
import { AppDispatch, RootStateType } from '@Store';
import { TimelineContent, TimelineItem } from '@mui/lab';
import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FirstConnectiongWearhouseDropdown from './FirstConnectiongWearhouseDropdown';
import CreateConnection from './components/CreateConnection';
import SideTimelineSeparator from './components/SideTimelineSeparator';
import { SingleConnectionContainer } from './style';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

function FirstConnectingWarehouse() {
  const dispatch = useDispatch<AppDispatch>();
  const firstConnectingWarehouseId = useSelector(
    (state: RootStateType) => state.adminShipment?.shipmentById?.firstConnectingWarehouse?._warehouseId,
  );
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const shipmentActionControl = new ShipmentControl(shipmentById);

  const connectionCountInWord = useConnectionTitle({ position: 1 });

  const shipmentConnectOnWarehouse = useSelector(
    (state: RootStateType) => state.adminShipment?.shipmentConnectOnWarehouse,
  );

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentProcessingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_processing,
    PERFORM_ACTION.write,
  );
  console.log('FirstConnectingWarehouse');
  return (
    <TimelineItem>
      <SideTimelineSeparator
        inTransit={
          shipmentActionControl.doesShipmentDelivered
            ? false
            : !shipmentConnectOnWarehouse?.length &&
              !(shipmentById?.carrierDetails?.awb || shipmentById?.isFinalForward)
        }
      />
      {/* <TimelineConnectorStyled className="img" />
      <TimelineDot sx={{ width: '24px', height: '24px' }} />
      <TimelineConnectorStyled className="img" /> */}
      <TimelineContent>
        <SingleConnectionContainer container item mb={1} justifyContent="center" className="selected">
          <Grid item xs={12}>
            <Grid container item mb={1} justifyContent={'space-between'}>
              <Typography
                className=" font-semibold capitalize"
                sx={{
                  fontSize: '16px',
                  '@media (max-width: 600px)': {
                    fontSize: '14px',
                  },
                }}
              >
                {connectionCountInWord}{' '}
              </Typography>
              {!shipmentActionControl.doesShipmentDelivered &&
                // !shipmentById?.carrierDetails?.awb &&
                shipmentById?.isFinalForward && (
                  <Grid
                    container
                    xs={6}
                    sx={{
                      justifyContent: { xs: 'start', sm: 'start', md: 'end', lg: 'end' },
                    }}
                  >
                    <SbButton
                      sx={{
                        borderRadius: '4px !important',
                        zIndex: 1,
                        '@media (max-width: 600px)': {
                          marginTop: '10px',
                          marginBottom: '10px',
                        },
                      }}
                      variant="contained"
                      // className="absolute"
                      // disabled={!state.value.trim() || !state.label.trim()}
                      onClick={() => {
                        console.log('isFianlAWBEditable onEdit--');
                        dispatch(editFinalForward(true));
                      }}
                      disabled={!isShipmentProcessingWriteAllowed || shipmentById?.status === 'shipment_exception'}
                    >
                      Edit
                    </SbButton>
                  </Grid>
                )}
            </Grid>
            <Grid
              item
              alignItems="flex-start"
              display="grid"
              gridTemplateColumns={{
                xs: '1fr',
                sm: '1fr',
                md: '1.5fr 2fr',
              }}
              gap={4}
            >
              <>
                {shipmentById?.shipmentRouteType !== 'import' ? <FirstConnectiongWearhouseDropdown /> : null}
                <Grid item xs={6}>
                  <SbTextField
                    label="Original Service"
                    value={shipmentById?.firstSelectedServiceName || '-'}
                    disabled
                  />
                </Grid>
              </>
            </Grid>
            {shipmentById?.shipmentRouteType !== 'import' ? (
              <CreateConnection fromWarehouseId={firstConnectingWarehouseId || ''} />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Divider className="mt-2" />
          </Grid>
        </SingleConnectionContainer>
      </TimelineContent>
    </TimelineItem>
  );
}

export default FirstConnectingWarehouse;
