import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid, Tab, styled } from '@mui/material';
import RatesInput from './RatesInput';
import Divider from '../../components/Divider';
import GetRatesAction from './GetRatesAction';
import GetRatesWrapper from '../../context/GetRates/Provider';
import { GetRatesContextType } from './Type';
import GetRatesContext from '../../context/GetRates';
import CSPList from './CSPList';
import NoCSPFound from './CSPList/NoCSPFound/NoCSPFound';
import BoxWise from './BoxWise';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../store';
import Loader from '../../components/Loader';
// import { CSPChargeType } from '../../store/reducers/GetRates/Type';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RatesInputType } from './Type';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AccountLookup from '@Components/AccountLookup';
import SelectedUserInfoCard from '@Components/AccountLookup/SelectedUserInfoCard';
import Title from '@Components/Title';
import Image from '@Components/Image';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { userLogin } from '../../services/hasAdminAccess';
import useAccountLookup from '@Hook/useAccountLookup';
import { getUserList } from '@Reducers/AccountLookup';

type TabValues = 'tarrif_pricing' | 'contract_pricing' | string;

interface TabAccessKeyType {
  isCompanyInfoShow: boolean;
}

interface GetRatesContentType {
  hideBoxes: boolean;
  ratesInput: RatesInputType;
  isTarrifPricing?: boolean;
}

interface ContractPricing {
  hideBoxes: boolean;
  ratesInput: RatesInputType;
  isTarrifPricing?: boolean;
  modalId: string;
}

type TabAccessKey = keyof TabAccessKeyType;

const TABS: { label: string; value: TabValues; accessKey?: TabAccessKey }[] = [
  { label: 'Tarrif Pricing', value: 'tarrif_pricing' },
  { label: 'Contract Pricing ', value: 'contract_pricing' },
];

const TAB_KEYS: TabValues[] = TABS.map(x => x.value);

const Root = styled(Grid)(({ theme }) => ({
  // padding: theme.spacing(7),
  scrollMargin: '95px',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

export function GetRatesContent({ hideBoxes, ratesInput, isTarrifPricing = false }: GetRatesContentType) {
  return (
    <>
      <RatesInput />
      {!hideBoxes && ratesInput?.shipmentType === 'parcel' && <BoxWise />}
      <GetRatesAction isTarrifPricing={isTarrifPricing} />
    </>
  );
}

function ContractPricing({ hideBoxes, ratesInput, modalId }: ContractPricing) {
  const { selectedUser } = useAccountLookup(modalId);
  const { setUserId } = React.useContext(GetRatesContext) as GetRatesContextType;
  const dispatch = useDispatch<AppDispatch>();

  function getSelectedUser(user: UserObj) {
    setUserId(user._id);
  }

  useEffect(() => {
    if (modalId) {
      dispatch(getUserList([modalId, {}]));
    }
  }, [modalId]);

  return (
    <>
      <div className="border-1px border-light rounded-md mb-3 mr-3" style={{ padding: '1.25rem' }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={5}>
            <AccountLookup modalId={modalId} getSelectedUser={getSelectedUser} showSearchInput disableUserReset>
              <Title
                titleIconLeft={<Image src="/images/icons/contract_pricing.svg" alt="contract_pricing" />}
                title="Contract Pricing "
                removeBorder
              />
            </AccountLookup>
          </Grid>
        </Grid>
        {selectedUser?._id && (
          <>
            <Divider sx={{ my: 3 }} />
            <SelectedUserInfoCard user={selectedUser} />
          </>
        )}
      </div>
      {selectedUser?._id && <GetRatesContent hideBoxes={hideBoxes} ratesInput={ratesInput} />}
    </>
  );
}

function GetRatesV2Main() {
  const modalId = 'GetRatesV2Main';

  const navigate = useNavigate();
  const { rateFor, loading, CSPChargesData } = useSelector((state: RootStateType) => state.getRates);
  const loggedInUserId = useSelector((state: RootStateType) => state.loginUser.profile?._id);
  const { selectedUser, loading: accountLookupLoading } = useAccountLookup(modalId, true);
  const { hideBoxes, showRates, ratesInput, setShowRates, setUserId, resetReduxState } = React.useContext(
    GetRatesContext,
  ) as GetRatesContextType;

  const ref: React.RefObject<HTMLDivElement> = useRef(null);
  const [searchParams] = useSearchParams();

  const [currentTap, setCurrentTap] = useState<TabValues>('tarrif_pricing');

  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();

  useEffect(() => {
    if (!isAdmin && loggedInUserId) {
      setUserId(loggedInUserId);
    }
  }, [isAdmin, loggedInUserId]);

  useEffect(() => {
    const currentActiveTab: TabValues = searchParams.get('tab') || 'tarrif_pricing';
    if (TAB_KEYS.includes(currentActiveTab)) setCurrentTap(currentActiveTab);
  }, []);

  useEffect(() => {
    ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [showRates]);

  function handleBookCSP() {
    if (rateFor === 'get-rates') {
      // navigate('/shipment/create-single', { state: { pickup, destination, boxes } });
      const url = selectedUser?._id
        ? `/shipment/create-single?accountId=${selectedUser?._id}&redirectedFrom=GetRates`
        : '/shipment/create-single?redirectedFrom=GetRates';
      console.log({ url });
      navigate(url);
    }
  }

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    navigate(`?tab=${currentTab}`);
    setCurrentTap(currentTab);
    resetReduxState();
  };

  return (
    <Loader loading={loading || accountLookupLoading} overly>
      <Root ref={ref} container className="bg-white rounded">
        <Grid
          item
          xs={12}
          className="mb-1"
          sx={{ padding: isAdmin ? 'none' : { xs: '3rem 3rem 0 1.5rem', sm: '3rem 3rem 0 3rem' } }}
        >
          <Title title="Get Rates" removeBorder></Title>
        </Grid>
        {CSPChargesData?.charges?.length && rateFor === 'get-rates' ? (
          <>
            {selectedUser?._id && currentTap != 'tarrif_pricing' && (
              <SelectedUserInfoCard user={selectedUser} classname="mb-2" />
            )}
            <CSPList weightUnit={ratesInput.weightUnit} handleBookCSP={handleBookCSP} />
          </>
        ) : (
          <Grid container item>
            {isAdmin && loggedInUserId ? (
              <TabContext value={currentTap}>
                <Grid container sx={{ borderBottom: '1px solid #E4E4E4', marginBottom: '50px' }}>
                  <TabList
                    onChange={onTabChange}
                    indicatorColor="primary"
                    TabIndicatorProps={{ sx: { height: '4px' } }}
                    scrollButtons="auto"
                    variant="scrollable"
                  >
                    {TABS.map(cTab => (
                      <Tab
                        sx={{ textTransform: 'none', padding: '16px 16px' }}
                        className="md-text font-medium sb-text-black"
                        value={cTab.value}
                        label={cTab.label}
                        key={cTab.value}
                      />
                    ))}
                  </TabList>
                </Grid>
                <TabPanel value="tarrif_pricing" className="p-0 w-full">
                  <GetRatesContent hideBoxes={hideBoxes} ratesInput={ratesInput} isTarrifPricing={true} />
                </TabPanel>
                <TabPanel value="contract_pricing" className="p-0 w-full">
                  <ContractPricing modalId={modalId} hideBoxes={hideBoxes} ratesInput={ratesInput} />
                </TabPanel>
              </TabContext>
            ) : (
              <Box sx={{ padding: { xs: '0 1.5rem', sm: '0 3rem' } }}>
                {CSPChargesData?.charges?.length && rateFor === 'get-rates' ? (
                  <>
                    {/* {selectedUser?._id && <SelectedUserInfoCard user={selectedUser} classname="mb-2" />} */}
                    <CSPList weightUnit={ratesInput.weightUnit} handleBookCSP={handleBookCSP} />
                  </>
                ) : (
                  <GetRatesContent hideBoxes={hideBoxes} ratesInput={ratesInput} />
                )}
              </Box>
            )}
          </Grid>
        )}
        <NoCSPFound setShowRates={setShowRates} />
      </Root>
    </Loader>
  );
}

const GetRatesV2: React.FC = (): JSX.Element => {
  return (
    <GetRatesWrapper>
      <GetRatesV2Main />
    </GetRatesWrapper>
  );
};

export default GetRatesV2;
