import { Box, Grid } from '@mui/material';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import Title from '../../components/Title';
import BackButton from '@Components/BackButton';

const RouteOptimizer: React.FC = () => {
  const { pathname } = useLocation();
  const showBackButton = pathname.includes('/route-optimizer/details');

  return (
    <Loader loading={false} overly>
      <Box p={2}>
        <Grid container alignItems="center">
          <Grid item xs={12} sx={{ display: 'flex', gap: '0.5rem' }}>
            {showBackButton && <BackButton link="" />}
            <Title
              title={'Route Optimizer'}
              subTitle={'View and edit optimized routes '}
              removeBorder
              removePadding
              subTextSize="px_20-text"
            />
          </Grid>
        </Grid>
        <Outlet />
      </Box>
    </Loader>
  );
};

export default RouteOptimizer;
