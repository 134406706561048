import React, { useMemo } from 'react';
import { vendorAccountType } from '@Reducers/AdminShipment/Type';
import { Grid, Paper, Typography } from '@mui/material';
import SBDropdown from '@Components/Dropdown';
import { useFormik } from 'formik';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import SBCountryDropdown from '@Components/Dropdown/Country';
// import SbTextField from '@Components/Textfield';
// import DatePicker from '@Components/DatePicker';
import { addCarrierSettings, updateCarrierSettings } from '@Reducers/User/actions';

import {
  PaymentTypeOptions,
  ProductTypeOptions,
} from '../../ViewSingleShipmentAdmin/components/ShipmentProcessingV2/components/Aramex/constants';
import SbButton from '@Components/Button';
import Divider from '@Components/Divider';
import * as Yup from 'yup';
import { AramexCarrier } from './Type';
import { setCarrierSettings } from '@Reducers/User';

interface propsTypes {
  onClose: () => void;
  isEditable: boolean;
}
const validationSchema = Yup.object({
  accountNo: Yup.array().required('Account number is required'),
  countryOfManufacture: Yup.string().required('Country of manufacture is required'),

  productType: Yup.array().min(1, 'At least one product type is required').required('Product type is required'),
  paymentType: Yup.array().min(1, 'At least one payment type is required').required('Payment type is required'),
});
const AramexInformation = (props: propsTypes) => {
  const { onClose, isEditable } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { vendersAccount } = useSelector((state: RootStateType) => state.adminShipment);
  const { userDataById, carrierSetting } = useSelector((state: RootStateType) => state.user);
  const { values, setFieldValue, errors, handleSubmit } = useFormik<AramexCarrier>({
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    initialValues: {
      ...carrierSetting.aramex,
    },
    onSubmit(values) {
      if (userDataById?._id) {
        if (carrierSetting.aramex._id) {
          dispatch(updateCarrierSettings({ ...carrierSetting.aramex, ...values }));
        } else {
          dispatch(addCarrierSettings({ ...carrierSetting.aramex, ...values }));
        }
      } else {
        dispatch(setCarrierSettings({ values, carrierType: 'aramex' }));
      }

      onClose();
    },
  });

  const accountOptions = useMemo(
    () =>
      vendersAccount?.map((item: vendorAccountType) => ({
        value: `${item.accountNo}-${item._id}`,
        display: item.name,
      })),
    [vendersAccount],
  );

  return (
    <div>
      <>
        <Paper
          variant="outlined"
          sx={{
            my: '2rem',
            p: '2rem',
          }}
        >
          <Grid container item xs={12}>
            <Grid container item mb={3}>
              <Grid item xs={12} md={6}>
                <Typography className="font-medium l-text pb-3">Aramex Information</Typography>{' '}
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="accountNo"
                    label={`Aramex Account`}
                    required
                    multiple={true}
                    placeholder="Select"
                    name="accountNo"
                    value={values?.accountNo.map(item => item.value)}
                    options={accountOptions}
                    onChange={(_, selectedObj) => {
                      setFieldValue('accountNo', selectedObj);
                    }}
                    error={Array.isArray(errors.accountNo) ? errors.accountNo.join(', ') : errors.accountNo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBCountryDropdown
                    disabled={!isEditable}
                    required
                    label="Country of Manufacture"
                    value={values?.countryOfManufacture}
                    options={undefined}
                    onChange={value => {
                      setFieldValue('countryOfManufacture', value[0]);
                    }}
                    error={errors?.countryOfManufacture as string}
                  />
                </Grid>
              </Grid>

              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="paymentType"
                    label={`Payment Type`}
                    required
                    placeholder="Select "
                    name="paymentType"
                    value={values?.paymentType.map(item => item.value)}
                    options={PaymentTypeOptions.map(item => ({
                      value: item.value,
                      display: item.display,
                    }))}
                    onChange={(_, selectedObj) => {
                      setFieldValue('paymentType', selectedObj);
                    }}
                    error={errors.paymentType as string}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="productType"
                    label={`Product Type`}
                    required
                    multiple={true}
                    placeholder="Select "
                    name="productType"
                    value={values?.productType.map(item => item.value)}
                    options={ProductTypeOptions.map(item => ({
                      value: item.value,
                      display: item.display,
                    }))}
                    onChange={(_, selectedObj) => {
                      setFieldValue('productType', selectedObj);
                    }}
                    error={errors.productType as string}
                  />
                </Grid>
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}></Grid>
              </Grid>
              <Divider className="mt-3 mb-3" />
              <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
                <Grid container item sx={{ gap: '1rem' }}>
                  <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                    <SbButton
                      type="submit"
                      disabled={!isEditable}
                      variant="contained"
                      sx={{ borderRadius: `4px !important` }}
                      className="w-full"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Save
                    </SbButton>
                  </Grid>
                  {onClose && (
                    <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                      <SbButton
                        type="button"
                        sx={{ borderRadius: `4px !important` }}
                        className="w-full"
                        variant="outlined"
                        onClick={onClose}
                      >
                        Cancel
                      </SbButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    </div>
  );
};

export default AramexInformation;
