// Import the functions you need from the SDKs you need
import { setNotificationCount } from '@Reducers/Notifications';
import { putNotificationToken } from '@Reducers/Notifications/actions';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { AppDispatch } from '@Store';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//PLEASE ALSO ChANGE THESE CONFIG IN => firebase-messaging-sw.js
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export function requestPermission(dispatch: ThunkDispatch<unknown, unknown, AnyAction>) {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      getToken(messaging, {
        vapidKey: 'BFtZC19NSUOEApTw790BdyP9kw0ihk6AEj1RXJg0RasQrwvmSvq-XszvdFDNuhk1ZqKbbGHyeOA_8RYWXz3diaM',
      })
        .then(async currentToken => {
          if (currentToken) {
            localStorage.setItem('fcmToken', currentToken);
            dispatch(
              putNotificationToken({
                fcmWebToken: currentToken,
              }),
            );
          }
        })
        .catch(() => {
          dispatch(
            SuccessErrorModalOpen({
              type: 'error',
              title: 'Oops!',
              subTitle: 'An error occurred while retrieving token.',
            }),
          );
        });
    } else {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: 'Please enable browser notifications and login again to receive notifications',
        }),
      );
    }
  });
}

export const onMessageListener = (dispatch: AppDispatch) =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      dispatch(
        setNotificationCount({
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          data: {
            type: payload?.data?.type || '',
            url: payload?.data?.url || '',
            userId: payload?.data?.userId || '',
          },
          message: payload?.notification?.body || '',
          title: payload?.notification?.title || '',
          user: payload?.data?.userId || '',
          _id: payload?.data?.notificationId || '',
          isRead: false,
        }),
      );
      resolve(payload);
    });
  });

export { messaging };
