import { Grid, styled } from '@mui/material';

export const Root = styled(Grid)(({ theme }) => ({
  background: "url('/images/login/Group.png')",
  backgroundPositionX: '220%',
  backgroundSize: '72%',
  backgroundRepeatX: 'no-repeat',
  flex: 1,
  backgroundRepeat: 'no-repeat',
  height: '100vh',
  borderRadius: '12px',
  padding: '4%',
  paddingRight: '5%',
  [theme.breakpoints.down('md')]: {
    background: 'transparent',
  },
}));

export const ContactLink = styled('a')({
  color: '#2C2B6D',
});

export const ContactCardWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '274px',
  height: ' 248px',
  borderRadius: '8px',
  boxShadow: '1px 1px 4px 0px rgba(0, 0, 0, 0.25)',
  padding: '28px',
  marginRight: '25px',
}));

export const ContactCardHeader = styled('div')(() => ({
  // padding: '16px',
}));

export const ContactCardBody = styled('div')(() => ({
  // padding: '22px',
}));
