import { createSlice } from '@reduxjs/toolkit';
import {
  deleteUnprocessedShipmentFulfilled,
  deleteUnprocessedShipmentPending,
  deleteUnprocessedShipmentRejected,
  getUnprocessedShipmentListFulfilled,
  getUnprocessedShipmentListPending,
  getUnprocessedShipmentListRejected,
  updateUnprocessedShipmentFulfilled,
} from './case';
import {
  createUnprocessedShipmentFulfilled,
  createUnprocessedShipmentPending,
  createUnprocessedShipmentRejected,
} from './case';
import {
  getUnprocessedShipmentByIdFulfilled,
  getUnprocessedShipmentByIdPending,
  getUnprocessedShipmentByIdRejected,
} from './case';
import { UnprocessedShipmentState } from './Type';
import {
  getUnprocessedShipmentList,
  createUnprocessedShipment,
  getUnprocessedShipmentById,
  updateUnprocessedShipmentById,
  deleteUnprocessedShipment,
} from './actions';

const initialState = <UnprocessedShipmentState>{
  singeUnprocessedShipment: null,
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  lists: [],
  totalCount: 0,
  currentPage: 0,
  perPage: 10,
};

const reduxStateSlice = createSlice({
  name: 'global',
  initialState: { ...initialState },
  reducers: {
    resetUnprocessedShipmentRedirect: function (state) {
      state.isRedirect = false;
    },
    resetUnprocessedShipmentError: function (state) {
      state.error = {
        msg: '',
        isError: false,
      };
    },
    resetSingleUnprocessedShipment: function (state) {
      state.singeUnprocessedShipment = null;
    },
    resetSingleUnprocessedShipmentList: function (state) {
      state.lists = [];
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getUnprocessedShipmentList.pending, getUnprocessedShipmentListPending);
    addCase(getUnprocessedShipmentList.fulfilled, getUnprocessedShipmentListFulfilled);
    addCase(getUnprocessedShipmentList.rejected, getUnprocessedShipmentListRejected);

    addCase(getUnprocessedShipmentById.pending, getUnprocessedShipmentByIdPending);
    addCase(getUnprocessedShipmentById.fulfilled, getUnprocessedShipmentByIdFulfilled);
    addCase(getUnprocessedShipmentById.rejected, getUnprocessedShipmentByIdRejected);

    addCase(createUnprocessedShipment.pending, createUnprocessedShipmentPending);
    addCase(createUnprocessedShipment.fulfilled, createUnprocessedShipmentFulfilled);
    addCase(createUnprocessedShipment.rejected, createUnprocessedShipmentRejected);

    addCase(updateUnprocessedShipmentById.pending, createUnprocessedShipmentPending);
    addCase(updateUnprocessedShipmentById.fulfilled, updateUnprocessedShipmentFulfilled);
    addCase(updateUnprocessedShipmentById.rejected, createUnprocessedShipmentRejected);

    addCase(deleteUnprocessedShipment.pending, deleteUnprocessedShipmentPending);
    addCase(deleteUnprocessedShipment.fulfilled, deleteUnprocessedShipmentFulfilled);
    addCase(deleteUnprocessedShipment.rejected, deleteUnprocessedShipmentRejected);
  },
});

export {
  getUnprocessedShipmentList,
  createUnprocessedShipment,
  updateUnprocessedShipmentById,
  getUnprocessedShipmentById,
  deleteUnprocessedShipment,
};
export const {
  resetUnprocessedShipmentRedirect,
  resetUnprocessedShipmentError,
  resetSingleUnprocessedShipment,
  resetSingleUnprocessedShipmentList,
} = reduxStateSlice.actions;
export default reduxStateSlice.reducer;
