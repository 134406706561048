import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { styled, Typography } from '@mui/material';
import { SBTimelineType } from './type';

export const SBTimeline = styled(Timeline)<SBTimelineType>(({ theme, is_last }) => ({
  borderBottom: is_last === '1' ? 'unset' : `3px solid ${theme.palette.border.tableRow}`,
}));

export const SBTimelineItem = styled(TimelineItem)(() => ({
  minHeight: '53px',
}));

export const SBTimelineOppositeContent = styled(TimelineOppositeContent)(() => ({
  flexBasis: '32%',
}));

export const SBTimelineSeparator = styled(TimelineSeparator)(() => ({
  flexBasis: '5%',
  flex: 1,
}));

export const SBTimelineDot = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  marginBlock: 0,
  marginTop: 4,
}));

export const SBTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  marginBottom: -5,
}));

export const SBTimelineContent = styled(TimelineContent)(() => ({
  flexBasis: '63%',
}));

export const ShipmentStatus = styled(Typography)(() => ({
  flexBasis: '70%',
}));

export const ShipmentLocation = styled(Typography)(() => ({
  flexBasis: '30%',
}));

export const VerticalTrackingWrapper = styled('div')(() => ({
  // minWidth: '500px',
}));
