import { PayloadAction } from '@reduxjs/toolkit';
import { RatePayloadResponse, RateState, pageType, MergedRatesArr, RateObj, knownError } from './Type';

function getRatePending(state: RateState) {
  state.loading = true;
}

function getRateFulfilled(state: RateState, data: { payload: RatePayloadResponse }) {
  const selectedDate = data.payload.queryParams.navigatedDate;
  state.config.selectedDate = selectedDate;
  state.config.pageType = data.payload.queryParams.pageType;
  state.config.isImport = data.payload.queryParams.isImport;
  const {
    mergedRatesArr,
    selectedDateRates,
    data: rateDataByAccountNumber,
  } = mergeDates(data.payload.data, data.payload.queryParams.pageType, new Date(selectedDate));
  state.mergedRatesArr = mergedRatesArr;
  state.allSelectedDateRate = selectedDateRates;
  state.totalCount = data.payload?.data?.totalCount;
  state.selectedDateRate = data.payload.data?.list;
  state.currentPage = data.payload.data?.currentPage;
  state.perPage = data.payload.data?.perPage;
  state.sheetCount = data.payload.data?.sheetCount;

  // state.totalCount = selectedDateRates?.length;
  // const startIndex = state.currentPage * state.perPage;
  // const endIndex = startIndex + state.perPage;
  // state.selectedDateRate = selectedDateRates?.slice(startIndex, endIndex);
  // state.selectedDateRate = selectedDateRates;
  const accountNumber = data.payload.data.accountUser?.accountNumber;
  if (state.config.pageType === 'base') state.config.selectedAccountNumber = null;
  else state.config.selectedAccountNumber = accountNumber;
  state.rateDataByAccountNumber[state.config.selectedAccountNumber || 'default'] = rateDataByAccountNumber;
  state.loading = false;
}

function getRateSheetFulfilled(state: RateState, data: { payload: RatePayloadResponse }) {
  const selectedDate = data.payload.queryParams.navigatedDate;
  const selectedDateTime = new Date(selectedDate).getTime();
  state.config.selectedDate = selectedDate;
  state.config.pageType = data.payload.queryParams.pageType;
  state.config.isImport = data.payload.queryParams.isImport;
  const {
    markup,
    sheet,
    default: { sheet: defaultSheet },
  } = data.payload.data;

  const filteredData = [
    // Filter and process markup data
    ...markup.filter(rateObj => {
      const startDateTime = new Date(rateObj.startDate).getTime();
      const endDateTime = new Date(rateObj.endDate).getTime();

      // Check if the selectedDateTime is within the range of the rateObj's start and end dates
      if (startDateTime <= selectedDateTime && endDateTime >= selectedDateTime) {
        // Check if there's a matching item in the defaultSheet
        return (defaultSheet || []).some(item => {
          if (
            item?.csp === rateObj?.csp &&
            item?.system === rateObj?.system &&
            rateObj?.documentRates === item?.documentRates
          ) {
            // Update state.sheets with the matching item
            state.sheets = { ...state.sheets, [`${rateObj?.csp}${rateObj?.system}`]: item };
            return true;
          } else {
            return false;
          }
        });
      } else {
        return false;
      }
    }),

    // Filter and process sheet data
    ...sheet.filter(rateObj => {
      const startDateTime = new Date(rateObj.startDate).getTime();
      const endDateTime = new Date(rateObj.endDate).getTime();

      if (rateObj?.isMarkup) {
        if (
          startDateTime !== null &&
          endDateTime !== null &&
          selectedDateTime !== null &&
          startDateTime <= selectedDateTime &&
          endDateTime >= selectedDateTime
        ) {
          // Check if there's a matching item in the defaultSheet
          return (defaultSheet || []).some(item => {
            if (
              item?.csp === rateObj?.csp &&
              item?.system === rateObj?.system &&
              rateObj?.documentRates === item?.documentRates
            ) {
              // Update state.sheets with the matching item
              state.sheets = { ...state.sheets, [`${rateObj?.csp}${rateObj?.system}`]: item };
              return true;
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      } else {
        // If rateObj is not markup, directly update state.sheets and return true
        state.sheets = { ...state.sheets, [`${rateObj?.csp}${rateObj?.system}`]: rateObj };
        return true;
      }
    }),
  ];

  state.allSelectedDateRate = filteredData;
  state.totalCount = filteredData?.length;
  const startIndex = state.currentPage * state.perPage;
  const endIndex = startIndex + state.perPage;
  state.selectedDateRate = filteredData?.slice(startIndex, endIndex);
  const accountNumber = data.payload.data.accountUser?.accountNumber;
  state.config.selectedAccountNumber = accountNumber;
  state.rateDataByAccountNumber[accountNumber || 'default'] = filteredData;
  state.loading = false;
}

function getRateRejected(state: RateState, _action: PayloadAction<knownError | undefined>) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getSelectedDateRate(data: RateObj[], selectedDate: Date): RateObj[] {
  const selectedDateRates: RateObj[] = [];
  const selectedDateTime = selectedDate.getTime();
  data.forEach(rateObj => {
    const startDateTime = new Date(rateObj.startDate).getTime();
    const endDateTime = new Date(rateObj.endDate).getTime();
    if (startDateTime <= selectedDateTime && endDateTime >= selectedDateTime) {
      selectedDateRates.push(rateObj);
    }
  });
  return selectedDateRates;
}

function deleteRatesPending(state: RateState) {
  state.loading = true;
}

function deleteRatesFulfilled(state: RateState) {
  state.loading = false;
}

function deleteRatesRejected(state: RateState, action: PayloadAction<knownError | undefined>) {
  state.loading = false;
  state.error = { msg: action.payload?.msg, isError: true };
}

export {
  getRatePending,
  getRateFulfilled,
  getRateRejected,
  getSelectedDateRate,
  deleteRatesPending,
  deleteRatesFulfilled,
  deleteRatesRejected,
  getRateSheetFulfilled,
};

function mergeDates(data: RatePayloadResponse['data'], pageType: pageType, selectedDate: Date) {
  if (pageType === 'contract') {
    const ratesArr = [...data.sheet, ...data.markup];
    const sortedRatesArr = ratesArr.sort(
      (r1, r2) => new Date(r1.startDate).getTime() - new Date(r2.startDate).getTime(),
    );
    return mergeRatesArr(sortedRatesArr, selectedDate);
  } else if (pageType === 'tariff') {
    const ratesArr = [...data.sheet, ...data.markup];
    const sortedRatesArr = ratesArr.sort(
      (r1, r2) => new Date(r1.startDate).getTime() - new Date(r2.startDate).getTime(),
    );
    return mergeRatesArr(sortedRatesArr, selectedDate);
  } else {
    return mergeRatesArr(data.default.sheet, selectedDate);
  }
}

function mergeRatesArr(data: RateObj[], selectedDate: Date) {
  const mergedRatesArr: MergedRatesArr[] = [];
  const selectedDateRates: RateObj[] = [];
  const selectedDateTime = selectedDate.getTime();

  data.forEach(rateObj => {
    const startDateTime = new Date(rateObj.startDate).getTime();
    const endDateTime = new Date(rateObj.endDate).getTime();

    if (startDateTime <= selectedDateTime && endDateTime >= selectedDateTime) {
      selectedDateRates.push(rateObj);
    }

    const activeRange = mergedRatesArr.at(-1);
    if (!activeRange) return mergedRatesArr.push({ startDate: startDateTime, endDate: endDateTime });

    if (startDateTime <= activeRange.endDate) {
      if (endDateTime > activeRange.endDate) activeRange.endDate = endDateTime;
    } else if (startDateTime > activeRange.endDate) {
      mergedRatesArr.push({ startDate: startDateTime, endDate: endDateTime });
    }
  });
  return { mergedRatesArr, selectedDateRates, data };
}
