import React from 'react';
import { Checkbox, Grid, Radio, Typography, styled } from '@mui/material';

const PaymentOptionWrapper = styled(Grid)<{ isDisabled: boolean }>(({ isDisabled, theme }) => ({
  border: '1px solid',
  opacity: isDisabled ? 0.6 : 1,
  background: theme.palette.primary.light,
}));

interface SelectedAccountType {
  isSelected: boolean;
  amount: number;
}

interface PaymentOptionType {
  title: string;
  subTitle: string;
  icon: React.ReactNode;
  onSelect: ({ isSelected, amount }: SelectedAccountType) => void;
  selectedAmount: SelectedAccountType;
  amount: number;
  isDisabled: boolean;
  isRadio: boolean;
}
export function PaymentOption({
  title,
  subTitle,
  icon,
  selectedAmount,
  isDisabled,
  onSelect,
  amount,
  isRadio,
}: PaymentOptionType) {
  return (
    <PaymentOptionWrapper container className="p-3 border-grey rounded" alignItems={'center'} isDisabled={isDisabled}>
      <Grid item xs={1.7}>
        {icon}
      </Grid>
      <Grid item xs={7}>
        <Typography className="md-text font-semibold">{title}</Typography>
        <Typography>{subTitle}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className="md-text font-semibold">{amount} INR</Typography>
      </Grid>
      <Grid item xs={1.3}>
        {isRadio ? (
          <Radio
            className="ml-3"
            style={{ color: selectedAmount.isSelected ? 'green' : 'inherit' }}
            disabled={isDisabled}
            onChange={() => {
              onSelect({ isSelected: !selectedAmount.isSelected, amount: selectedAmount.amount });
            }}
            checked={selectedAmount.isSelected}
          />
        ) : (
          <Checkbox
            className="ml-3"
            style={{ color: isDisabled ? 'grey' : selectedAmount.isSelected ? 'green' : 'inherit' }}
            disabled={isDisabled}
            onChange={() => {
              onSelect({ isSelected: !selectedAmount.isSelected, amount: selectedAmount.amount });
            }}
            checked={selectedAmount.isSelected}
          />
        )}
      </Grid>
    </PaymentOptionWrapper>
  );
}
