import { Box, Typography } from '@mui/material';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';

interface TrackingData {
  totalCount: number;
  placed: number;
  at_warehouse: number;
  in_transit: number;
  forwarded: number;
  rto: number;
  cancelled: number;
  delivered: number;
  shipment_exception: number;
  unsuccessful_delivery: number;
}

interface TrackingGraphProps {
  trackingData: TrackingData | undefined;
  shipmentPercentage: number | undefined;
}

const TrackingGraph: React.FC<TrackingGraphProps> = ({ trackingData, shipmentPercentage }) => {
  // console.info('trackingData', trackingData);

  const data = {
    labels: [
      'Placed',
      'At Warehouse',
      'In Transit',
      'Forwarded',
      'RTO',
      'Cancelled',
      'Delivered',
      'Shipment Exception',
      'Unsuccessful Delivery',
    ],
    datasets: [
      {
        label: '# of Shipments',
        data: [
          trackingData?.placed,
          trackingData?.at_warehouse,
          trackingData?.in_transit,
          trackingData?.forwarded,
          trackingData?.rto,
          trackingData?.cancelled,
          trackingData?.delivered,
          trackingData?.shipment_exception,
          trackingData?.unsuccessful_delivery,
        ],
        backgroundColor: [
          '#E6E6FF', // Placed
          '#020597', // At Warehouse
          '#00CC3D', // In Transit
          '#B2FFC9', // Forwarded
          '#FFE5E5', // RTO
          '#CB0000', // Cancelled
          '#00992E', // Delivered
          '#FE6666', // Shipment Exception
          '#FE0000', // Unsuccessful Delivery
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: '70%',
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            const dataset = data.datasets && data.datasets[0];
            if (dataset) {
              const total: any = dataset.data.reduce(
                (accumulator: number, currentValue: number) => accumulator + currentValue,
                0,
              );
              const currentValue: any = dataset.data[tooltipItem.dataIndex];
              const percentage = Math.round((currentValue / total) * 100);
              return `${currentValue} (${percentage}%)`;
            }
            return '';
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  // const total = data.datasets[0].data.reduce((acc: any, curr: any) => acc + curr, 0);

  const labelArrayList = [
    { status: 'Placed', count: trackingData?.placed, color: '#E6E6FF' },
    { status: 'At Warehouse', count: trackingData?.at_warehouse, color: '#020597' },
    { status: 'In Transit', count: trackingData?.in_transit, color: '#00CC3D' },
    { status: 'Forwarded', count: trackingData?.forwarded, color: '#B2FFC9' },
    { status: 'RTO', count: trackingData?.rto, color: '#FFE5E5' },
    { status: 'Cancelled', count: trackingData?.cancelled, color: '#CB0000' },
    { status: 'Delivered', count: trackingData?.delivered, color: '#00992E' },
    { status: 'Shipment Exception', count: trackingData?.shipment_exception, color: '#FE6666' },
    { status: 'Unsuccessful Delivery', count: trackingData?.unsuccessful_delivery, color: '#FE0000' },
  ];

  return (
    <Box>
      <Box sx={{ fontWeight: '500', fontSize: '24px' }}>
        <p>Tracking Status </p>
        <p style={{ fontSize: '12px' }}>
          <span
            style={{
              color: shipmentPercentage && shipmentPercentage > 0 ? 'rgba(3, 160, 28, 1)' : 'red',
              fontWeight: '700',
            }}
          >
            {shipmentPercentage}%{' '}
          </span>
          <span style={{ color: 'rgba(73, 69, 79, 1)', fontWeight: '400' }}>since last month</span>
        </p>
      </Box>
      <Box sx={{ display: 'flex', gap: '10px', marginTop: '20px', flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box
          sx={{
            flexBasis: { xs: '100%', sm: '30%' },
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            marginBottom: { xs: '20px', sm: '0' },
          }}
        >
          {labelArrayList.map((el, index) => (
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }} key={index}>
              <Box>
                <p
                  style={{
                    borderRadius: '50%',
                    backgroundColor: el.color,
                    display: 'inline-block',
                    width: '15px',
                    height: '15px',
                    textAlign: 'center',
                  }}
                ></p>
              </Box>
              <Box sx={{ fontSize: '16px', lineHeight: '22.5px' }}>{`${el.count ? el.count : 0} ${el.status}`}</Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            flexBasis: { xs: '100%', sm: 'fit-content' },
            alignSelf: 'center',
            position: 'relative',
            width: { xs: 250, sm: 300 },
            height: { xs: 250, sm: 300 },
          }}
        >
          <Doughnut data={data} options={options} />

          <Box
            display="flex"
            width={'100%'}
            justifyContent="center"
            alignItems="center"
            position="absolute"
            flexDirection="column"
            top={0}
            bottom={0}
            left={0}
            right={50}
          >
            <Typography
              sx={{
                fontSize: { xs: '28px', sm: '32px' },
                fontWeight: '600',
                lineHeight: '48px',
                color: '#181D25',
              }}
              component="div"
              color="textSecondary"
            >
              {trackingData?.totalCount}
            </Typography>
            <Typography
              sx={{
                fontSize: '13px',
                fontWeight: '400',
                color: 'rgba(96, 110, 128, 1)',
                lineHeight: '19.5px',
                textAlign: 'center',
              }}
              component="div"
              color="textSecondary"
            >
              Total Shipments
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TrackingGraph;
