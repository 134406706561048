import { Typography } from '@mui/material';
import React from 'react';
import { BoxType } from '../../../../store/reducers/Shipment/Type';
import { SHIPMENT_STATUS } from '../../../../constants/Shipment';
import { EditButton, SaveButton, TrackingBoxContainer, TrackingStatus } from './style';
import { TrackShipmentDataType } from '@Reducers/Tracking/type';
import EditIcon from '@mui/icons-material/Edit';
import { EditableAWB } from './type';
import SbTextField from '@Components/Textfield';
import { updateSingleBoxCarrierNumber } from '@Reducers/AdminShipment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { hasAdminAccess } from '@Services/hasAdminAccess';

interface PropsType {
  key: string;
  box: BoxType;
  setSelectedBox: (box: BoxType) => void;
  isSelected: boolean;
  childAWBNumber: string;
  carrierNumber: string;
  trackingData: TrackShipmentDataType | null;
  editableAWB: EditableAWB;
  setEditableAWB: (param: EditableAWB) => void;
  shipmentNumber: string;
  disabled?: boolean;
}

function SingleBox({
  key,
  box,
  setSelectedBox,
  isSelected,
  childAWBNumber,
  trackingData,
  editableAWB,
  setEditableAWB,
  shipmentNumber,
  carrierNumber,
  disabled,
}: PropsType) {
  const { id } = box;
  const { trackingDataByShipmentId } = useSelector((state: RootStateType) => state.trackShipment);
  // const carrierAirWayBillNumber = trackingData?.carrierAirWayBillNumber;
  const status = trackingData?.status || trackingDataByShipmentId[shipmentNumber]?.status || 'placed';
  const dispatch = useDispatch<AppDispatch>();
  const isAdmin = hasAdminAccess();

  function updateCarrierAWB(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    dispatch(
      updateSingleBoxCarrierNumber({
        shipmentNumber,
        boxShipmentNumber: childAWBNumber,
        carrierNumber: editableAWB.state,
      }),
    );
  }

  console.info('trackingData:', trackingData);

  return (
    <>
      <TrackingBoxContainer
        container
        flexDirection={'column'}
        className={`rounded-lg ${disabled ? 'cursor-default' : ''} `}
        isselected={String(isSelected)}
        onClick={() => {
          setEditableAWB({ state: '', boxId: '' });
          if (!isSelected) {
            setSelectedBox(box);
          }
        }}
        key={key}
      >
        <Typography className="md-text font-bold" sx={{ marginBottom: '5px' }}>{`Box ${Number(id) + 1}`}</Typography>
        <Typography className="sm-text" sx={{ marginBottom: '3px' }}>
          {childAWBNumber || '-'}
        </Typography>
        <div className="flex items-center" style={{ marginBottom: '10px' }}>
          <Typography className="sm-text">Carrier AWB: </Typography>
          {editableAWB.boxId === id ? (
            <SbTextField
              placeholder="Enter your AWB"
              label=""
              style={{ width: '140px', marginLeft: '5px' }}
              sx={{ width: '140px', height: '30px', paddingInline: '10px' }}
              value={editableAWB.state}
              onClick={e => e.stopPropagation()}
              onChange={e => setEditableAWB({ boxId: id, state: e.target.value })}
            />
          ) : (
            <Typography className="sm-text" sx={{ marginLeft: '5px' }}>
              {carrierNumber || '--'}
            </Typography>
          )}
        </div>

        <div className="flex justify-between">
          <TrackingStatus bgcolor={SHIPMENT_STATUS[status]?.bgColor} color={SHIPMENT_STATUS[status]?.textColor}>
            {SHIPMENT_STATUS[status]?.label}
          </TrackingStatus>
          {isAdmin && editableAWB.boxId === id ? (
            <SaveButton
              variant="contained"
              containerClass="px-2"
              onClick={updateCarrierAWB}
              loading={false}
              disabled={disabled}
            >
              Save
            </SaveButton>
          ) : (
            <EditButton
              onClick={e => {
                e.stopPropagation();
                setEditableAWB({ boxId: String(id), state: carrierNumber || '' });
                if (!isSelected && id !== editableAWB.boxId) {
                  setSelectedBox(box);
                }
              }}
              endIcon={<EditIcon style={{ height: 15, width: 15 }} />}
              disabled={disabled}
            >
              Edit
            </EditButton>
          )}
        </div>
      </TrackingBoxContainer>
    </>
  );
}

export default SingleBox;
