import React, { useMemo } from 'react';
import { vendorAccountType } from '@Reducers/AdminShipment/Type';
import { Box, Grid, Paper, Typography } from '@mui/material';
import SBDropdown from '@Components/Dropdown';
import { useFormik } from 'formik';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { setIsWarehouseSelectedINFedX } from '@Reducers/Warehouse';
import SBCountryDropdown from '@Components/Dropdown/Country';
import SbTextField from '@Components/Textfield';
import DatePicker from '@Components/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { downloadCarrierLabel } from '@Reducers/ShipmentHistory/actions';
import { PaymentTypeOptions, ProductTypeOptions, onForward } from './constants';
import SbButton from '@Components/Button';
import Divider from '@Components/Divider';
import * as Yup from 'yup';

interface propsTypes {
  onClickForward: () => void;
  onClose?: () => void;
  initialStates: any;
  isEditable?: boolean;
  showFedExDetails: boolean;
  btnText: string;
}

const aramexInformationSchema = Yup.object().shape({
  type: Yup.string().trim().required('Type is required'),
  accountNo: Yup.string().trim().required('DHL Account is required'),
  countryOfManufacture: Yup.string().trim().required('Country of manufacture is required'),
  shipperTaxId: Yup.string().trim().required('Shipper Tax ID is required'),
  invoiceDate: Yup.date().required('Invoice Date is required').nullable(),
  productType: Yup.array().required('Product type is required'),
  paymentType: Yup.array().required('Payment type is required'),
  invoiceNumber: Yup.string().trim().required('Invoice number is required'),
});

export interface AramexInformation {
  type: string;
  accountNo: string;
  countryOfManufacture: string;
  shipperTaxId: string;
  invoiceDate: Dayjs | null;
  productType: string;
  paymentType: string;
  invoiceNumber: string;
}

const AramexInformation = (props: propsTypes) => {
  const { onClickForward, onClose, initialStates, isEditable, showFedExDetails, btnText } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { isWarehouseSelectedINFedX } = useSelector((state: RootStateType) => state.Warehouse);
  const { shipmentById, vendersAccount, aramexDetails } = useSelector((state: RootStateType) => state.adminShipment);

  const { values, handleChange, setFieldValue, errors, handleSubmit } = useFormik<AramexInformation>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ...initialStates,
    },
    validationSchema: aramexInformationSchema,
    onSubmit(values) {
      console.info('values', values);
      if (isWarehouseSelectedINFedX === '' || isWarehouseSelectedINFedX === 'false') {
        dispatch(setIsWarehouseSelectedINFedX('false'));
      } else {
        onForward({
          values,
          shipmentNumber: shipmentById?.shipmentNumber || '',
          dispatch,
          nextFunc: onClickForward,
        });
      }
    },
  });

  const accountOptions = useMemo(
    () =>
      vendersAccount?.map((item: vendorAccountType) => ({
        value: `${item.accountNo}-${item._id}`,
        display: item.name,
      })),
    [vendersAccount],
  );

  return (
    <div>
      {showFedExDetails && (
        <>
          <Paper
            variant="outlined"
            sx={{
              my: '2rem',
              p: '2rem',
            }}
          >
            <Grid container item xs={12}>
              <Grid container item mb={3}>
                <Grid item xs={12} md={6}>
                  <Typography className="font-medium l-text pb-3">Aramex Information</Typography>{' '}
                </Grid>
              </Grid>
              <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
                <Grid container item columnSpacing={10} rowSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <SBDropdown
                      disabled={!isEditable}
                      id="accountNo"
                      label={`Aramex Account`}
                      required
                      placeholder="Select"
                      name="accountNo"
                      value={[values.accountNo]}
                      options={accountOptions}
                      onChange={value => setFieldValue('accountNo', value[0])}
                      error={errors?.accountNo as string}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SBCountryDropdown
                      disabled={!isEditable}
                      required
                      label="Country of Manufacture"
                      value={values?.countryOfManufacture}
                      options={undefined}
                      onChange={value => {
                        console.info('value', value);
                        setFieldValue('countryOfManufacture', value[0]);
                      }}
                      error={errors?.countryOfManufacture as string}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={10} rowSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <SbTextField
                      disabled={!isEditable}
                      label={`Invoice Number`}
                      required
                      value={values.invoiceNumber || shipmentById?._packageId.invoiceNumber}
                      placeholder={`12345678`}
                      onChange={handleChange}
                      name="invoiceNumber"
                      error={errors.invoiceNumber as string}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SbTextField
                      disabled={!isEditable}
                      label={`Shipper Tax Id`}
                      required
                      placeholder=""
                      onChange={handleChange}
                      name="shipperTaxId"
                      value={values.shipperTaxId}
                      error={errors.shipperTaxId as string}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={10} rowSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      required
                      label="Invoice Date"
                      value={values?.invoiceDate || null}
                      handleChange={value => {
                        setFieldValue('invoiceDate', value);
                      }}
                      minDate={dayjs()}
                      disabled={!isEditable}
                      error={errors.invoiceDate as string}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SBDropdown
                      disabled={!isEditable}
                      id="productType"
                      label={`Product Type`}
                      required
                      placeholder="Select "
                      name="productType"
                      value={[values.productType]}
                      options={ProductTypeOptions}
                      onChange={value => {
                        setFieldValue('productType', value);
                      }}
                      error={errors.productType as string}
                    />
                  </Grid>
                </Grid>
                <Grid container item columnSpacing={10} rowSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <SBDropdown
                      disabled={!isEditable}
                      id="paymentType"
                      label={`Payment Type`}
                      required
                      placeholder="Select "
                      name="paymentType"
                      value={[values.paymentType]}
                      options={PaymentTypeOptions}
                      onChange={value => {
                        setFieldValue('paymentType', value);
                      }}
                      error={errors.paymentType as string}
                    />
                  </Grid>
                </Grid>
                <Divider className="mt-3 mb-3" />
                <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
                  <Grid container item sx={{ gap: '1rem' }}>
                    <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                      <SbButton
                        disabled={!isEditable}
                        variant="contained"
                        sx={{ borderRadius: `4px !important` }}
                        className="w-full"
                        onClick={_event => {
                          handleSubmit();
                        }}
                      >
                        {btnText}
                      </SbButton>
                    </Grid>
                    {onClose && (
                      <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                        <SbButton
                          sx={{ borderRadius: `4px !important` }}
                          className="w-full"
                          variant="outlined"
                          onClick={onClose}
                        >
                          Cancel
                        </SbButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </>
      )}
      {aramexDetails?.response && (
        <Grid container item>
          <Grid
            item
            xs={'auto'}
            border={'1px solid'}
            className={`border-grey font-medium md-text p-2`}
            borderRadius={1}
          >
            This shipment has been forwarded.
            <Box
              component={'span'}
              className="font-medium md-text ml-1"
              sx={{
                color: '#485fc7',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (shipmentById?.shipmentNumber) {
                  dispatch(downloadCarrierLabel(shipmentById?.shipmentNumber));
                }
              }}
            >
              Download the carrier AWB
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default AramexInformation;
