import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@Services/apiAxios';
import { ErrorType, ResendVerificationRes, SignupRes, signupState, verifyEmailRes } from './type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const doSignup = createAsyncThunk<SignupRes, signupState, { rejectValue: ErrorType }>(
  'doSignup',
  async (postData: signupState, { dispatch, rejectWithValue }) => {
    try {
      const result = await API.post('/app/signup', postData);

      return result.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      return rejectWithValue(errorRes);
    }
  },
);

const verifySignupEmail = createAsyncThunk<verifyEmailRes, { userId: string; otp: string }, { rejectValue: ErrorType }>(
  'verifySignupEmail',
  async (postData: { userId: string; otp: string }, { dispatch }) => {
    try {
      const result = await API.post('/app/signup/verify', postData);
      return result.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.errorObj?.msg || 'Something went wrong.',
        }),
      );
      throw { msg: errorRes };
    }
  },
);

const resendVerification = createAsyncThunk<ResendVerificationRes, { username: string }, { rejectValue: ErrorType }>(
  'resendVerification',
  async (postData: { username: string }, { rejectWithValue }) => {
    try {
      const result = await API.post('/app/signup/resend/verification', postData);
      return result.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export { doSignup, verifySignupEmail, resendVerification };
