import React from 'react';
// import SbButton from '../../../../components/Button';
import Modal from '../../../../components/Modal/error';
// import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../../store';
import { setNoRatesFund } from '../../../../store/reducers/GetRates';

interface PropsType {
  setShowRates: (show: boolean) => void;
}

function NoCSPFound({ setShowRates: _ }: PropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const ratesNotFund = useSelector((state: RootStateType) => state?.getRates?.ratesNotFund);

  // useEffect(() => {
  //   if (error?.isError) setOpen(true);
  //   else setShowRates(false);
  // }, [error]);

  // useEffect(() => {
  //   if (CSPChargesData) setShowRates(true);
  //   else onClose();
  // }, [CSPChargesData]);

  function onClose() {
    // setOpen(false);
    dispatch(setNoRatesFund(false));
  }

  return (
    <Modal
      icon={'/images/Boxes/NoRatesFound.svg'}
      open={ratesNotFund}
      onClose={onClose}
      onConfirm={onClose}
      title="Try Again!"
      subTitle="No rates found for this query!"
      btnText="Go Back"
    >
      {/* <Typography className="mt-1" style={{ fontSize: '36px', fontWeight: '700' }}>
        Try Again!
      </Typography>
      <Typography variant="h6" className="mt-1">
        
      </Typography>
      <SbButton
        onClick={onClose}
        variant="contained"
        className="mt-4 rounded-full"
        style={{ height: '44px', width: '148px' }}
      >
        Go Back
      </SbButton> */}
    </Modal>
  );
}
export default NoCSPFound;
