import SBDropdown from '@Components/Dropdown';
import { ShipmentKycType } from '@Reducers/Shipment/Type';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormHelperText, Grid, IconButton, Radio, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import React from 'react';
import { DocumentOptions, ImageOptions, checkImageType } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { uploadFedExDocumentResponseType } from '@Reducers/AdminShipment/Type';
import { viewSignaleDocument } from '@Reducers/kyc/actions';

const Wrapper = styled(Grid)`
  border-bottom: 1px solid;
  padding: 20px;
  &:last-child {
    border-bottom: none;
  }
`;

const StyleIconButton = styled(IconButton)(({ theme: _ }) => ({
  border: '1px solid #b2b2b2',
  padding: 8,
  borderRadius: 8,
  color: '#000',
  fontSize: '24px',
}));

interface SingleDocumentType {
  label: string;
  documentData: ShipmentKycType;
  required: boolean;
  modalView?: boolean;
  handleFileChecked: (data: ShipmentKycType) => void;
  selectedDocument: ShipmentKycType | null;
  setDocumentType: React.Dispatch<React.SetStateAction<string>>;
  documentType: string;
}

function SingleDocument(props: SingleDocumentType) {
  const { label, required, documentData, handleFileChecked, selectedDocument, setDocumentType, documentType } = props;
  const { fedExDetails } = useSelector((state: RootStateType) => state.adminShipment);
  const { uplodedDocuments, uplodedImages } = fedExDetails;

  const dispatch = useDispatch<AppDispatch>();

  const onViewDocument = () => {
    // window.open(`${CONFIG.API_URI}/shipment/file/get-url/${documentData?._id}`, '_blank');
    dispatch(viewSignaleDocument({ documentId: documentData?._id, url: 'shipment' }));
  };
  const isUploadedDate: string =
    uplodedDocuments.filter((item: uploadFedExDocumentResponseType) => item?._documentId === documentData?._id)[0]
      ?.updatedAt ||
    uplodedImages.filter((item: uploadFedExDocumentResponseType) => item?._documentId === documentData?._id)[0]
      ?.updatedAt;
  const isUploadedDocument: string =
    uplodedDocuments.filter((item: uploadFedExDocumentResponseType) => item?._documentId === documentData?._id)[0]
      ?._uploadedBy?.name ||
    uplodedImages.filter((item: uploadFedExDocumentResponseType) => item?._documentId === documentData?._id)[0]
      ?._uploadedBy?.name;
  return (
    <Wrapper
      container
      item
      alignItems="flex-center"
      className={`border-grey`}
      gap={2}
      sx={{
        ':hover': {
          bgcolor: 'primary.light',
          '#documentType': {
            bgcolor: 'primary.light',
          },
        },
      }}
    >
      <Grid container item xs={12} md={4.5} gap={1} alignItems="center">
        <Radio
          checked={Boolean(selectedDocument?._id === documentData._id)}
          onChange={() => handleFileChecked(documentData)}
        />
        <Typography className="md-text font-semibold" sx={{ mb: { xs: 1, md: 0 } }}>
          {label} {required ? '*' : ''}
        </Typography>
      </Grid>{' '}
      <Grid container item xs={12} md={3} alignItems="center" gap={1}>
        <SBDropdown
          disabled={Boolean(selectedDocument?._id !== documentData._id)}
          id="documentType"
          required
          placeholder="Select "
          value={selectedDocument?._id === documentData._id ? [documentType] : []}
          options={checkImageType(documentData?.contentType || '') ? ImageOptions : DocumentOptions}
          onChange={(value: string[]) => {
            if (value?.length) {
              setDocumentType(value[0]);
            }
          }}
        />
        {checkImageType(documentData?.contentType || '') &&
        selectedDocument?._id === documentData._id &&
        documentType ? (
          documentType === 'SIGNATURE' ? (
            <FormHelperText sx={{ color: 'info.main' }} className="m-0">
              File size should be 240x25 pixels
            </FormHelperText>
          ) : (
            <FormHelperText error className="m-0">
              File size should be 700x50 pixels
            </FormHelperText>
          )
        ) : (
          <></>
        )}
      </Grid>
      <Grid container item xs={12} md={2.5} alignItems="center">
        {isUploadedDate && dayjs(isUploadedDate).format('DD/MM/YY')}
        <br />
        {isUploadedDocument && <span className="capitalize">By: {isUploadedDocument}</span>}
      </Grid>
      <Grid container item xs={12} md={1} alignSelf="flex-end" justifyContent="flex-end">
        {documentData && (
          <StyleIconButton onClick={onViewDocument}>
            <VisibilityIcon fontSize="inherit" />
          </StyleIconButton>
        )}
      </Grid>
    </Wrapper>
  );
}

export default SingleDocument;
