import DoughnutChart from '@Components/DoughnutChart/DoughnutChart';
import Image from '@Components/Image';
import Title from '@Components/Title';
import {
  Box,
  Chip,
  Grid,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Checkbox,
  Tooltip,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import SbButton from '../../components/Button';
import FilterSelect from '../../components/Filter/Dropdown';
import FilterSearch from '../../components/Filter/Search';
import { SBTable } from '../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../components/Table/TableCell';
import { SBTableRow } from '../../components/Table/TableRow';
import AssignModal from './AssignModal';
import EditModal from './EditModal';

import DatePicker from '@Components/DatePicker';
import { SbMenu, SbMenuItem } from '@Components/Menu';
import { CalendarMonthRounded, FiberManualRecord } from '@mui/icons-material';
import { styled } from '@mui/system';
import dayjs, { Dayjs } from 'dayjs';
import RescheduleModal from './RescheduleModal';
import { fetchTaskShipmentsProps, TaskData, TaskList, locationData, BulkAssignArray } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getAllTaskShipments, squadOnOffCount } from '@Reducers/Squad/actions';
import { TaskManagementPayload, SquadOnOffData, BulkAssignShipments } from '@Reducers/Squad/type';
import Loader from '@Components/Loader';
import formatDate from '../../utils/formatDate';
import { Link } from 'react-router-dom';
import { getSystemList } from '@Reducers/Systems';
import { getWarehouseListForShipment } from '@Reducers/Warehouse';
import { updateFirstConnectingWarehouse } from '@Reducers/AdminShipment/actions';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Menu, MenuItem } from '@mui/material';
import { toCamelCase } from '../../utils/ToCamelCase';
import { setSelectedTasks, setBulkShipmentIdArray } from '@Reducers/Squad';
import { getPincodeZones } from '@Reducers/pincode/actions';
interface taskTableProps {
  onOpen: () => void;
  onOpenEdit: () => void;
  openReschedule: () => void;
  allTaskList: TaskList[];
  setSelectedId: React.Dispatch<React.SetStateAction<string>>;
  setPincode: React.Dispatch<React.SetStateAction<string>>;
  setShipmentId: (shipmentId: string) => void;
  setDeliveryChange: (isChanged: boolean) => void;
  isDeliveryChanged: boolean;
  checkAllTasks: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectTask: (shipmentId: string, isChecked: boolean) => void;
  setSelectedZones: (pincode: string[]) => void;
}

const SearchFilterList = [{ label: 'Shipment No.', value: 'shipment_no' }];

const TaskManagementTypeList = [
  {
    value: 'Pickup',
    display: 'Pickup',
  },
  {
    value: 'Delivery',
    display: 'Delivery',
  },
  {
    value: 'Hyperlocal',
    display: 'Hyperlocal',
  },
];

const taskManagementAssignList = [
  {
    value: 'true',
    display: 'Assign',
  },
  {
    value: 'false',
    display: 'Unassign',
  },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  // width: '100%',
  // overflow: 'auto',
  margin: '32px 0',
  padding: '4px 0',
  display: 'grid',
  gridTemplateColumns: '410px 250px 1fr  164px  fit-content(140px)  136px 113px',
  columnGap: '12px',
  // [theme.breakpoints.down('xl')]: {
  //   columnGap: '10px',
  //   gridTemplateColumns: '490px 1fr 184px 200px 136px 113px',
  // },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

function TaskManagementTable(props: taskTableProps) {
  const {
    allTaskList,
    setShipmentId,
    setSelectedId,
    setPincode,
    setDeliveryChange,
    isDeliveryChanged,
    checkAllTasks,
    onSelectTask,
    setSelectedZones,
  } = props;
  let loading = useSelector((state: RootStateType) => state.squadManagement.loading);
  const { page, pageSize, selectedTasks } = useSelector((state: RootStateType) => state.squadManagement);
  const { warehouseListForShipmentOptions } = useSelector((state: RootStateType) => state.Warehouse);
  const dispatch = useDispatch<AppDispatch>();
  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [deliveryShipmentNumber, setDeliveryShipmentNumber] = useState('');
  // dispatch(getWarehouseListForShipment(queryParams));
  // const [checkedAll, setCheckedAll] = useState(false);

  // const [selectedIds, SetSelectedIds] = useState([]);

  const handleDropDown = (event: React.MouseEvent<SVGSVGElement>, pincode: string, shipmentNumber: string) => {
    setAnchorEl(event.currentTarget as unknown as HTMLElement);
    setDropDownOpen(!isDropDownOpen);
    const queryParams = { pincode, filter: true };
    dispatch(getWarehouseListForShipment(queryParams));
    setDeliveryShipmentNumber(shipmentNumber);
  };

  const handleClose = () => {
    setDropDownOpen(false);
    setAnchorEl(null);
  };
  const handleWarehouseChange = (index: number) => {
    try {
      dispatch(
        updateFirstConnectingWarehouse({
          shipmentNumber: deliveryShipmentNumber,
          _warehouseId: warehouseListForShipmentOptions[index].value,
          _warehouseAddressId: warehouseListForShipmentOptions[index].metadata?._id ?? '',
        }),
      );
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Delivery Location is updated',
          subTitle: '',
        }),
      );
      setDeliveryChange(!isDeliveryChanged);
    } catch (err) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: '',
        }),
      );
    }
    setDropDownOpen(false);
    setAnchorEl(null);
  };
  const handleAssignTo = async (shipmentId: string, pincode: string) => {
    setShipmentId(shipmentId);
    loading = true;
    const location = await dispatch(getPincodeZones({ pincodes: [pincode] }));
    const { payload } = location as { payload: locationData[] };
    setSelectedZones(payload.map(item => item.zoneName));
    loading = false;
    props.onOpen();
  };
  const handleEditAssign = async (shipmentId: string, pincode: string) => {
    setShipmentId(shipmentId);
    loading = true;
    const location = await dispatch(getPincodeZones({ pincodes: [pincode] }));
    const { payload } = location as { payload: locationData[] };
    setSelectedZones(payload.map(item => item.zoneName));
    loading = false;
    props.onOpenEdit();
  };

  const handleReschedule = () => {
    props.openReschedule();
  };

  return (
    <>
      <Loader loading={loading} overly />

      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Checkbox
                style={{ color: 'inherit' }}
                checked={allTaskList.filter(task => !task.isAssigned).every(task => selectedTasks[task.shipmentNumber])}
                onChange={checkAllTasks}
              />
            </TableHeadCell>
            <TableHeadCell>Shipment No.</TableHeadCell>
            <TableHeadCell>Type</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '150px' }}>Pickup Date</TableHeadCell>
            <TableHeadCell>Pickup Address</TableHeadCell>
            <TableHeadCell> Delivery Address</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '150px' }}>Assign To </TableHeadCell>
            <TableHeadCell>Actions</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allTaskList.map((data: any, index: number) => {
            const {
              line1: pickupLine1,
              line2: pickupLine2,
              city: pickupCity,
              state: pickupState,
              country: pickupCountry,
              pincode: pickupPincode,
            } = data.pickupAddress;

            const {
              line1: deliveryLine1,
              line2: deliveryLine2,
              city: deliveryCity,
              state: deliveryState,
              country: deliveryCountry,
              pincode: deliveryPincode,
            } = data.deliveryAddress;
            return (
              <SBTableRow key={data._id}>
                <TableRowCell align="center">
                  <div className="items-center md-text">
                    <Typography>
                      <Checkbox
                        style={data.isAssigned ? { cursor: 'not-allowed', color: 'grey' } : { color: 'inherit' }}
                        disabled={data.isAssigned}
                        checked={!!selectedTasks[data.shipmentNumber]}
                        onChange={e => {
                          onSelectTask(data.shipmentNumber, e.target.checked);
                        }}
                      />
                      {index + 1 + (page - 1) * pageSize}
                    </Typography>
                  </div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  <Link to={`/shipment/admin/edit/${data.shipmentNumber}`}> {data.shipmentNumber}</Link>
                </TableRowCell>
                <TableRowCell align="center" className="md-text ">
                  {/* <Typography sx={{ background: '#C1F8C0', color: '#429E16', borderRadius: '20px', padding: '0 1rem' }}>
                  {data.shipmentType}
                </Typography> */}
                  <Chip
                    label={toCamelCase(data.shipmentType)}
                    sx={{
                      bgcolor: '#C1F8C0',
                      color: '#429E16',
                      fontWeight: 500,
                      fontSize: '16px',
                    }}
                  />
                  {data.squadTag && (
                    <Tooltip title={data.squadTag === 'Rejected' ? data.rejectionMessage : ''} arrow>
                      <Chip
                        label={toCamelCase(data.squadTag)}
                        sx={{
                          bgcolor: '#FDCECE',
                          color: '#A00303',
                          fontWeight: 500,
                          fontSize: '16px',
                        }}
                      />
                    </Tooltip>
                  )}
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  {formatDate(data.pickupDate)}
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  {pickupLine1}, {pickupLine2}, {pickupCity}, {pickupState} <br /> {pickupCountry}- {pickupPincode}
                </TableRowCell>
                <TableRowCell align="center" className="md-text ">
                  {data.shipmentType === 'pickup' && data.warehouseCount > 1 && (
                    <Box sx={{ textAlign: 'end', cursor: 'pointer' }}>
                      <ExpandMoreIcon
                        sx={{
                          color: 'grey',
                          transform: isDropDownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.3s ease-in-out',
                        }}
                        onClick={event => handleDropDown(event, pickupPincode, data.shipmentNumber)}
                      />
                      <Menu
                        anchorEl={anchorEl}
                        open={isDropDownOpen}
                        onClose={handleClose}
                        sx={{ position: 'absolute' }}
                      >
                        {warehouseListForShipmentOptions.map((warehouse, index) => (
                          <MenuItem key={index} onClick={() => handleWarehouseChange(index)}>
                            <div>
                              <strong>{warehouse.display}</strong>
                              <div>{warehouse.secondary}</div>
                            </div>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  )}
                  <div>
                    {deliveryLine1}, {deliveryLine2}, {deliveryCity}, {deliveryState} <br />
                    {deliveryCountry}- {deliveryPincode}
                  </div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  {data.isAssigned ? (
                    data.assignedSquadMemberName
                  ) : (
                    <SbButton
                      variant="contained"
                      onClick={() => {
                        if (data.shipmentType === 'pickup' || data.shipmentType === 'hyperlocal') {
                          handleAssignTo(data._id, pickupPincode);
                        } else {
                          handleAssignTo(data._id, deliveryPincode);
                        }
                      }}
                    >
                      Assign
                    </SbButton>
                  )}
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  <Grid container item justifyContent="center" gap={2} alignItems="center">
                    <Grid item textAlign="center">
                      <SbMenu>
                        <SbMenuItem
                          onClick={
                            data.isAssigned
                              ? () => {
                                  if (data.shipmentType === 'pickup' || data.shipmentType === 'hyperlocal') {
                                    handleEditAssign(data._id, pickupPincode);
                                  } else {
                                    handleEditAssign(data._id, deliveryPincode);
                                  }
                                }
                              : () => undefined
                          }
                          icon={
                            <Image
                              src="/images/icons/EditAction.svg"
                              alt="Edit"
                              style={{
                                opacity: data.isAssigned ? 1 : 0.2,
                                pointerEvents: data.isAssigned ? 'auto' : 'none',
                              }}
                            />
                          }
                          name="Edit"
                        />
                        <SbMenuItem
                          onClick={() => {
                            setSelectedId(data.shipmentNumber);
                            setPincode(pickupPincode);
                            handleReschedule();
                          }}
                          icon={<CalendarMonthRounded className="sb-text-black" />}
                          name="Reschedule"
                        />
                      </SbMenu>
                    </Grid>
                  </Grid>
                </TableRowCell>
              </SBTableRow>
            );
          })}
        </TableBody>
      </SBTable>
    </>
  );
}

const TaskManagement = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedId, setSelectedId] = useState('');
  const [pincode, setPincode] = useState('');
  const [allTaskList, setAllTaskList] = useState<TaskList[]>([]);
  const [filterSelectValue, setFilterSelectValue] = useState('shipment_no');
  const [shipmentNumber, setShipmentNumber] = useState('');
  const [shipmentType, setShipmentType] = useState<string[]>([]);
  const [assign, setAssign] = useState<string[]>([]);
  const [system, setSystem] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs());
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedZones, setSelectedZones] = useState<string[]>([]);
  const [squadOnlineOfflineCount, setSquadOnlineOfflineCount] = useState<SquadOnOffData>({
    onlineCount: 0,
    offlineCount: 0,
  });
  const [isBulkAssignClick, setBulkAssignClick] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setBulkAssignClick(false);
  };
  const handleCloseEdit = () => setOpenEdit(false);
  const handleopenEdit = () => setOpenEdit(true);
  const handleOpen = () => setOpen(true);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const handleCloseRescheduleModal = () => setOpenRescheduleModal(false);
  const handleOpenRescheduleModal = () => setOpenRescheduleModal(true);
  const [tasks, setTasks] = useState<TaskData | null>(null);
  const [isDeliveryChanged, setDeliveryChange] = useState(false);
  const [shipmentId, setShipmentId] = useState('');
  const { assignedShipments = 0, unassignedShipments = 0 } = tasks || {};
  const theme = useTheme();
  const { selectedTasks, bulkShipmentIdArray } = useSelector((state: RootStateType) => state.squadManagement);
  const checkAllTasks = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newShipmentCheckedList = allTaskList.reduce((op: { [k: string]: boolean }, task: TaskList) => {
        if (!task.isAssigned) {
          op[task.shipmentNumber] = true;
        }
        return op;
      }, {});
      dispatch(setSelectedTasks(newShipmentCheckedList));
    } else {
      dispatch(setSelectedTasks({}));
    }
  };

  const onSelectTask = function (shipmentId: string, isChecked: boolean) {
    const newSelectedTask = { ...selectedTasks };
    if (isChecked) newSelectedTask[shipmentId] = true;
    else delete newSelectedTask[shipmentId];

    dispatch(setSelectedTasks(newSelectedTask));
  };

  const { totalCount, page, pageSize } = useSelector((state: RootStateType) => state.squadManagement);

  const onShipmentTypeFilter = (selected: Array<string>) => {
    setShipmentType(selected);
    fetchTaskShipments({
      page: 1,
      pageSize,
      shipmentType: selected,
      shipmentNumber,
      assign,
      startDate,
      system,
    });
  };

  const onAssignFilter = (selected: Array<string>) => {
    setAssign(selected);
    fetchTaskShipments({
      page: 1,
      pageSize,
      shipmentNumber,
      shipmentType,
      assign: selected,
      startDate,
      system,
    });
  };
  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    fetchTaskShipments({
      page: 1,
      pageSize,
      shipmentNumber,
      shipmentType,
      assign,
      startDate,
      system: selected,
    });
  };

  const chartData1 = {
    datasets: [
      {
        label: `${assignedShipments + unassignedShipments}`,
        data: [unassignedShipments, assignedShipments],
        backgroundColor: [theme.palette.primary.main, theme.palette.secondary.main],
      },
    ],
  };

  const chartData2 = {
    datasets: [
      {
        label: `${assignedShipments + unassignedShipments}`,
        data: [assignedShipments, unassignedShipments],
        backgroundColor: ['#8A8F9D', theme.palette.secondary.main],
      },
    ],
  };

  const fetchTaskShipments = async (props: fetchTaskShipmentsProps) => {
    try {
      const { page = 1, pageSize = 10, shipmentNumber = '', shipmentType = [], assign = [], startDate, system } = props;
      const formattedStartDate =
        typeof startDate === 'string' ? startDate : dayjs(startDate).toISOString().slice(0, 10);
      const queryParams = {
        page,
        pageSize,
        shipmentNumber,
        shipmentType: shipmentType.length ? JSON.stringify(shipmentType.map(type => type.toLowerCase())) : undefined,
        isAssigned: JSON.stringify(assign),
        startDate: formattedStartDate,
        system,
      };
      const response = await dispatch(getAllTaskShipments(queryParams));
      const { data } = response.payload as TaskManagementPayload;
      setTasks({ assignedShipments: data.assignedShipments, unassignedShipments: data.unassignedShipments });
      setAllTaskList(data.shipments);
    } catch (error) {
      console.error('Error during dispatch:', error);
    }
  };
  const fetchSquadOnOff = async () => {
    try {
      const resultAction = await dispatch(squadOnOffCount());
      setSquadOnlineOfflineCount(resultAction.payload);
    } catch (error) {
      console.error('Error during dispatch:', error);
    }
  };
  useEffect(() => {
    fetchSquadOnOff();
    dispatch(getSystemList());
  }, []);

  useEffect(() => {
    fetchTaskShipments({
      page,
      pageSize,
      shipmentNumber,
      shipmentType,
      assign,
      startDate,
      system,
    });
  }, [open, openEdit, openRescheduleModal, startDate]);
  useEffect(() => {
    setTimeout(() => {
      fetchTaskShipments({
        page,
        pageSize,
        shipmentNumber,
        shipmentType,
        assign,
        startDate,
        system,
      });
    }, 3000);
  }, [isDeliveryChanged]);
  const SystemList = useSelector((state: RootStateType) => state.System.data.selectedList).map(system => ({
    display: system.name,
    value: system._id,
  }));
  const _systems = useSelector((state: RootStateType) => state.loginUser?.profile?._systems);
  const activeSystemList = useMemo(() => {
    if (_systems?.length) return SystemList.filter(system => _systems.includes(system.value));
    else return [];
  }, [SystemList, _systems]);

  useEffect(() => {
    const updatedShipmentArray: BulkAssignShipments[] = [];
    allTaskList.forEach(task => {
      if (selectedTasks[task.shipmentNumber]) {
        updatedShipmentArray.push({
          shipmentType: task.shipmentType,
          outboundSystem: task.outboundSystem,
          inboundSystem: task.inboundSystem,
          _shipmentId: task._id,
        });
      }
    });

    dispatch(setBulkShipmentIdArray(updatedShipmentArray));
  }, [selectedTasks]);
  const getZones = async (bulkShipmentIdArray: BulkAssignArray[], allTaskList: TaskList[]) => {
    const filteredPincodes = bulkShipmentIdArray
      .map(bulkShipment => {
        const task = allTaskList.find(task => task._id === bulkShipment._shipmentId);
        if (task) {
          if (task.shipmentType === 'pickup' || task.shipmentType === 'hyperlocal') {
            return task.pickupAddress.pincode;
          } else if (task.shipmentType === 'delivery') {
            return task.deliveryAddress.pincode;
          }
        }
        return null;
      })
      .filter(pincode => pincode !== null) as string[];
    const location = await dispatch(getPincodeZones({ pincodes: filteredPincodes }));
    const { payload } = location as { payload: locationData[] };
    return payload.map(item => item.zoneName);
  };
  const handleBulkAssign = async () => {
    const deliveryShipments = bulkShipmentIdArray.filter(s => s.shipmentType === 'delivery');
    const pickupShipments = bulkShipmentIdArray.filter(s => s.shipmentType === 'pickup');
    const hyperlocalShipments = bulkShipmentIdArray.filter(s => s.shipmentType === 'hyperlocal');

    const deliveryInboundSystems = deliveryShipments.map(s => s.inboundSystem);
    const pickupOutboundSystems = pickupShipments.map(s => s.outboundSystem);
    const hyperlocalOutboundSystems = hyperlocalShipments.map(s => s.outboundSystem);

    if (deliveryShipments.length && pickupShipments.length && hyperlocalShipments.length) {
      const allDeliveryInboundSame = new Set(deliveryInboundSystems).size === 1;
      const deliveryInboundMatchesPickupOutbound = pickupShipments.every(p =>
        deliveryInboundSystems.includes(p.outboundSystem),
      );
      const deliveryInboundMatchesHyperlocalOutbound = hyperlocalShipments.every(h =>
        deliveryInboundSystems.includes(h.outboundSystem),
      );

      if (allDeliveryInboundSame && deliveryInboundMatchesPickupOutbound && deliveryInboundMatchesHyperlocalOutbound) {
        const allZones = await getZones(bulkShipmentIdArray, allTaskList);
        setSelectedZones(allZones);
        setShipmentId(bulkShipmentIdArray[0]._shipmentId);
        handleOpen();
        setBulkAssignClick(true);
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Mismatch in Shipment Systems',
            subTitle: 'Please ensure all shipments belong to the same system.',
          }),
        );
      }
    } else if (!deliveryShipments.length && pickupShipments.length && hyperlocalShipments.length) {
      const pickupOutboundMatchesHyperlocalOutbound = pickupShipments.every(p =>
        hyperlocalOutboundSystems.includes(p.outboundSystem),
      );

      if (pickupOutboundMatchesHyperlocalOutbound) {
        const allZones = await getZones(bulkShipmentIdArray, allTaskList);
        setSelectedZones(allZones);
        setShipmentId(bulkShipmentIdArray[0]._shipmentId);
        handleOpen();
        setBulkAssignClick(true);
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Mismatch in Shipment Systems',
            subTitle: 'Please ensure all pickup and hyperlocal shipments belong to the same system.',
          }),
        );
      }
    } else if (deliveryShipments.length && !pickupShipments.length && hyperlocalShipments.length) {
      const deliveryInboundMatchesHyperlocalOutbound = hyperlocalShipments.every(h =>
        deliveryInboundSystems.includes(h.outboundSystem),
      );

      if (deliveryInboundMatchesHyperlocalOutbound) {
        const allZones = await getZones(bulkShipmentIdArray, allTaskList);
        setSelectedZones(allZones);
        setShipmentId(bulkShipmentIdArray[0]._shipmentId);
        handleOpen();
        setBulkAssignClick(true);
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Mismatch in Shipment Systems',
            subTitle: 'Please ensure all delivery and hyperlocal shipments belong to the same system.',
          }),
        );
      }
    } else if (deliveryShipments.length && pickupShipments.length && !hyperlocalShipments.length) {
      const deliveryInboundMatchesPickupOutbound = pickupShipments.every(p =>
        deliveryInboundSystems.includes(p.outboundSystem),
      );

      if (deliveryInboundMatchesPickupOutbound) {
        const allZones = await getZones(bulkShipmentIdArray, allTaskList);
        setSelectedZones(allZones);
        setShipmentId(bulkShipmentIdArray[0]._shipmentId);
        handleOpen();
        setBulkAssignClick(true);
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Mismatch in Shipment Systems',
            subTitle: 'Please ensure all delivery and pickup shipments belong to the same system.',
          }),
        );
      }
    } else if (pickupShipments.length && !deliveryShipments.length && !hyperlocalShipments.length) {
      const allPickupOutboundSame = new Set(pickupOutboundSystems).size === 1;

      if (allPickupOutboundSame) {
        const allZones = await getZones(bulkShipmentIdArray, allTaskList);
        setSelectedZones(allZones);
        setShipmentId(bulkShipmentIdArray[0]._shipmentId);
        handleOpen();
        setBulkAssignClick(true);
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Mismatch in Pickup Shipments',
            subTitle: 'Please ensure all pickup shipments belong to the same system.',
          }),
        );
      }
    } else if (hyperlocalShipments.length && !deliveryShipments.length && !pickupShipments.length) {
      const allHyperlocalOutboundSame = new Set(hyperlocalOutboundSystems).size === 1;

      if (allHyperlocalOutboundSame) {
        const allZones = await getZones(bulkShipmentIdArray, allTaskList);
        setSelectedZones(allZones);
        setShipmentId(bulkShipmentIdArray[0]._shipmentId);
        handleOpen();
        setBulkAssignClick(true);
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Mismatch in Hyperlocal Shipments',
            subTitle: 'Please ensure all hyperlocal shipments belong to the same system.',
          }),
        );
      }
    } else if (deliveryShipments.length && !pickupShipments.length && !hyperlocalShipments.length) {
      const allDeliveryInboundSame = new Set(deliveryInboundSystems).size === 1;

      if (allDeliveryInboundSame) {
        const allZones = await getZones(bulkShipmentIdArray, allTaskList);
        setSelectedZones(allZones);
        setShipmentId(bulkShipmentIdArray[0]._shipmentId);
        handleOpen();
        setBulkAssignClick(true);
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Mismatch in Delivery Shipments',
            subTitle: 'Please ensure all delivery shipments belong to the same system.',
          }),
        );
      }
    } else {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Mismatch in Shipment Systems',
          subTitle: 'Please ensure all selected shipments belong to the same system.',
        }),
      );
    }
  };

  useEffect(() => {
    const allShipmentNumbers = new Set(allTaskList.map(task => task.shipmentNumber));
    const updatedSelectedTasks = Object.keys(selectedTasks).reduce<{
      [key: string]: boolean;
    }>((acc, shipmentNumber) => {
      if (allShipmentNumbers.has(shipmentNumber)) {
        acc[shipmentNumber] = selectedTasks[shipmentNumber];
      }
      return acc;
    }, {});
    dispatch(setSelectedTasks(updatedSelectedTasks));
  }, [allTaskList]);
  return (
    <>
      <Title
        title="Task Management"
        subTitle="View and assign tasks to squad members"
        removeBorder
        subTextSize="px_20-text"
      />
      <Grid container spacing={2}>
        <Grid item xs>
          <Box
            sx={{ background: '#F1F1FF', border: '1px solid #DCDCDC', borderRadius: '0.5rem', height: '12rem' }}
            className="flex flex-col justify-center items-center text-center px-2"
          >
            <Typography className={`md-text font-medium`}>Tasks Assigned</Typography>
            <Box className="flex item items-center">
              <DoughnutChart chartData={chartData1} />
              <Typography className="l-text font-medium">{assignedShipments}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{ background: '#F1F1FF', border: '1px solid #DCDCDC', borderRadius: '0.5rem', height: '12rem' }}
            className="flex flex-col justify-center items-center text-center px-2"
          >
            <Typography className={`md-text font-medium`}>Not Assigned</Typography>
            <Box className="flex item items-center">
              <DoughnutChart chartData={chartData2} />
              <Typography className="l-text font-medium">{unassignedShipments}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs>
          <Box
            sx={{
              background: '#F1F1FF',
              border: '1px solid #DCDCDC',
              borderRadius: '0.5rem',
              height: '12rem',
              gap: '4vh',
            }}
            className="flex flex-col justify-center items-center px-2 "
          >
            <Box className="flex items-center sb-gap-1" sx={{ width: '7rem' }}>
              {/* <img src="/images/taskManagement/greenDot.svg" alt="online greenDot" className="" /> */}
              <FiberManualRecord sx={{ color: 'success.main', width: '0.8rem' }} />
              <Typography className={`md-text font-medium`}> Online : {squadOnlineOfflineCount.onlineCount}</Typography>
            </Box>
            <Box className="flex items-center  sb-gap-1" sx={{ width: '7rem' }}>
              {/* <img src="/images/taskManagement/redDot.svg" alt="online redDot" className="" /> */}
              <FiberManualRecord sx={{ color: 'text.red', width: '0.8rem' }} />
              <Typography className={`md-text font-medium`}>
                Offline : {squadOnlineOfflineCount.offlineCount}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <TableActionContainer className="">
        <FilterSearch
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={shipmentNumber}
          onTextChange={e => {
            setShipmentNumber(e.target.value);
          }}
          onTextSearch={() => {
            fetchTaskShipments({
              shipmentNumber,
              page: 1,
              pageSize,
              shipmentType,
              assign,
              startDate,
              system,
            });
          }}
          onTextClear={() => {
            setShipmentNumber('');
            fetchTaskShipments({
              shipmentNumber: '',
              page: 1,
              pageSize,
              shipmentType,
              assign,
              startDate,
              system,
            });
          }}
        />
        <DatePicker
          label=""
          value={startDate}
          minDate={dayjs()}
          handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
            setStartDate(date);
          }}
        />
        <div style={{ minWidth: '100px' }}>
          {bulkShipmentIdArray.length > 0 && (
            <SbButton variant="contained" onClick={handleBulkAssign}>
              Bulk Assign
            </SbButton>
          )}
        </div>

        <FilterSelect
          id="type"
          label="Type"
          value={shipmentType}
          options={TaskManagementTypeList}
          onChange={onShipmentTypeFilter}
        />
        <FilterSelect id="type" label="System " value={system} options={activeSystemList} onChange={onSystemFilter} />
        <FilterSelect
          id="assign"
          label="Assign"
          value={assign}
          options={taskManagementAssignList}
          onChange={onAssignFilter}
        />

        <SbButton
          className="xs-text ml-1"
          variant="outlined"
          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
          onClick={() => {
            setAssign([]);
            setShipmentType([]);
            setSystem([]);
            setShipmentNumber('');
            setStartDate(dayjs());
            fetchTaskShipments({
              page: 1,
              pageSize,
              shipmentNumber: '',
              shipmentType: [],
              assign: [],
              startDate: new Date().toISOString().slice(0, 10),
              system: [],
            });
          }}
        >
          Clear Filters
        </SbButton>
      </TableActionContainer>

      <TaskManagementTable
        onOpen={handleOpen}
        openReschedule={handleOpenRescheduleModal}
        allTaskList={allTaskList}
        setShipmentId={setShipmentId}
        onOpenEdit={handleopenEdit}
        setSelectedId={setSelectedId}
        setPincode={setPincode}
        setDeliveryChange={setDeliveryChange}
        isDeliveryChanged={isDeliveryChanged}
        // handleSelectAllRow={handleSelectAllRow}
        // handleRowSelect={handleRowSelect}
        // selectAllRow={selectAllRow}
        checkAllTasks={checkAllTasks}
        onSelectTask={onSelectTask}
        setSelectedZones={setSelectedZones}
      />
      {!!totalCount && !!pageSize && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={page - 1}
            rowsPerPage={pageSize}
            onPageChange={(_, page) => {
              fetchTaskShipments({
                page: page + 1,
                pageSize,
                shipmentNumber,
                shipmentType,
                assign,
                startDate,
                system,
              });
            }}
            onRowsPerPageChange={event => {
              const newPerPage = parseInt(event.target.value, 10);
              fetchTaskShipments({
                page: 1,
                pageSize: newPerPage,
                shipmentNumber,
                shipmentType,
                assign,
                startDate,
                system,
              });
            }}
          />
        </>
      )}

      {open && (
        <AssignModal
          isBulkAssign={isBulkAssignClick}
          open={open}
          onClose={() => handleClose()}
          shipmentId={shipmentId}
          date={startDate}
          selectedZones={selectedZones}
        />
      )}
      {openEdit && (
        <EditModal
          openEdit={openEdit}
          onClose={() => handleCloseEdit()}
          shipmentId={shipmentId}
          date={startDate}
          selectedZones={selectedZones}
        />
      )}
      <RescheduleModal
        open={openRescheduleModal}
        pincode={pincode}
        selectedId={selectedId}
        onClose={handleCloseRescheduleModal}
      />
    </>
  );
};

export default TaskManagement;
