import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import BoxDetails from './BoxDetails';
import BoxEdit from './BoxEdit';
import { BoxState, BoxType, GetRatesContextType, SingleBoxPropsType } from '../Type';
import CopyBox from './CopyBox';
import DashedBtn from './DashedBtn';
import GetRatesContext from '../../../context/GetRates';
import { defaultBox } from '../Constant';
import Divider from '@Components/Divider';
import SbTextField from '@Components/Textfield';
import BoxCountValidation from '../RatesInput/BoxCountValidation/BoxDelete';
import { boxCountSchema } from './Schema';
import updateBoxId from '@Services/updateBoxId';

const BoxContainer = styled(Grid)`
  border: 1px solid;
  border-color: var(--mui-palette-grey-400);
  // width: 380px;
  // height: 170px;
  padding: 15px;
`;

const DefaultBoxState = {
  weight: '',
  length: '',
  width: '',
  height: '',
  boxCopyCount: 0,
};

function SingleBoxView({ data }: SingleBoxPropsType) {
  const [boxState, setBoxState] = useState<BoxState>('details');

  return (
    <Grid item xs={12} md={6} lg={4} xl={3}>
      <BoxContainer container className="rounded" justifyContent="space-between" alignItems="center">
        {boxState === 'edit' ? (
          <BoxEdit setBoxState={setBoxState} data={data} />
        ) : boxState === 'copy' ? (
          <CopyBox setBoxState={setBoxState} data={data} />
        ) : (
          <BoxDetails setBoxState={setBoxState} data={data} />
        )}
      </BoxContainer>
    </Grid>
  );
}

function BoxWise() {
  const { boxes, setBoxes, boxCount, setBoxCount } = React.useContext(GetRatesContext) as GetRatesContextType;
  const [boxCountValidation, setBoxCountValidation] = useState<boolean>(false);
  const [boxCountError, setBoxCountError] = useState<string>('');

  function handleAddBox() {
    const boxesTemp = structuredClone(boxes);
    boxesTemp.push({ ...DefaultBoxState, id: boxesTemp.length.toString() });
    const arr = updateBoxId<BoxType>(boxesTemp, defaultBox, 0);
    setBoxes([...arr]);
  }

  function validateBoxCount(value: number) {
    const result = boxCountSchema.validate(value);
    const errorMessage = result.error ? result.error.details[0].message : '';
    setBoxCountError(errorMessage);
  }

  function handleOnBoxCountChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const _boxCount = parseInt(value);
    validateBoxCount(_boxCount);
    setBoxCount(_boxCount);
    if (!_boxCount) return;
    if (_boxCount < boxes.length) {
      setBoxCountValidation(true);
    } else {
      setBoxes([
        ...boxes,
        ...Array(_boxCount - boxes.length)
          .fill(0)
          .map((_x, ind) => ({ ...defaultBox, id: (boxes.length + ind).toString() })),
      ]);
    }
  }

  function handleOnBoxCountBlur() {
    setBoxCountError('');
    if (boxes.length !== boxCount) {
      setBoxCount(boxes.length);
    }
  }

  return (
    <>
      <Grid item xs={12} className="my-3">
        <Divider />
      </Grid>
      <Typography className="org-text mb-2">Box-wise Details</Typography>

      <Grid container spacing={3} className="mr-1 mb-3">
        <Grid item xs={12} md={3}>
          <SbTextField
            placeholder="No. of Boxes"
            label="No. of Boxes"
            type="number"
            value={boxCount ? boxCount.toString() : ''}
            onChange={handleOnBoxCountChange}
            onBlur={handleOnBoxCountBlur}
            name="boxCount"
            error={boxCountError}
          />
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          {boxes.map(box => (
            <SingleBoxView key={box.id} data={box} />
          ))}
        </Grid>
        <Grid container className="mb-2" marginTop={'2rem'} justifyContent={'center'}>
          <Grid item xs={6} md={12}>
            <DashedBtn label="Add a Box" onClick={handleAddBox} />
          </Grid>
        </Grid>
      </Box>
      <BoxCountValidation
        open={boxCountValidation}
        onClose={() => setBoxCountValidation(false)}
        onConfirm={() => setBoxCountValidation(false)}
      />
    </>
  );
}

export default BoxWise;
