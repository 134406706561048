import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import {
  getOdaByIdFulfilled,
  getOdaByIdPending,
  getOdaByIdRejected,
  getOdaListFulfilled,
  getOdaListPending,
  getOdaListRejected,
  updateOdaFulfilled,
  getOdaChargeByIdFulfilled,
  deleteOdaFulfilled,
  //
  updateFilters as updateFiltersCase,
  clearFilters as clearFiltersCase,
  changeNavigatedDate as changeNavigatedDateCase,
} from './case';
import { createOdaFulfilled, createOdaPending } from './case';
import { OdaState, RejectPayload } from './Type';
import { getOdaList, createOda, getOdaById, updateOdaById, getOdaChargeById, deleteOdaById } from './actions';

const initialState = <OdaState>{
  loading: false,
  singleOda: null,
  singleOdaCharge: null,
  filters: {
    selectedDate: new Date().toString(),
    csp: [],
  },
  mergedChargesArr: [],
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    filteredList: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 0,
  },
};

const reduxStateSlice = createSlice({
  name: 'global',
  initialState: { ...initialState },
  reducers: {
    updateFilters: updateFiltersCase,
    clearFilters: clearFiltersCase,
    changeNavigatedDate: changeNavigatedDateCase,
    resetOdaRedirect: function (state) {
      state.isRedirect = false;
    },
    resetOdaError: function (state) {
      state.error = {
        msg: '',
        isError: false,
      };
    },
    resetSingleOda: function (state) {
      state.singleOda = null;
    },
    resetSingleOdaCharge: function (state) {
      state.singleOdaCharge = null;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getOdaList.pending, getOdaListPending);
    addCase(getOdaList.fulfilled, getOdaListFulfilled);
    addCase(getOdaList.rejected, getOdaListRejected);

    addCase(createOda.pending, createOdaPending);
    addCase(createOda.fulfilled, createOdaFulfilled);

    addCase(getOdaById.pending, getOdaByIdPending);
    addCase(getOdaById.fulfilled, getOdaByIdFulfilled);
    addCase(getOdaById.rejected, getOdaByIdRejected);

    addCase(getOdaChargeById.pending, getOdaByIdPending);
    addCase(getOdaChargeById.fulfilled, getOdaChargeByIdFulfilled);
    addCase(getOdaChargeById.rejected, getOdaByIdRejected);

    addCase(updateOdaById.pending, createOdaPending);
    addCase(updateOdaById.fulfilled, updateOdaFulfilled);

    addCase(deleteOdaById.pending, getOdaListPending);
    addCase(deleteOdaById.fulfilled, deleteOdaFulfilled);
    addCase(deleteOdaById.rejected, getOdaByIdRejected);

    addCase(updateOdaById.rejected, function (state: OdaState, { payload }) {
      const collidingError = getCollidingError(payload?.colliding);
      state.loading = false;
      state.error = { msg: payload?.msg + '\n ' + collidingError, isError: true };
    });

    addCase(createOda.rejected, function (state: OdaState, { payload }) {
      const collidingError = getCollidingError(payload?.colliding);
      state.loading = false;
      state.error = { msg: payload?.msg + '\n ' + collidingError, isError: true };
    });
  },
});

function getCollidingError(data: RejectPayload['colliding'] | undefined) {
  if (!data?.length) return '';
  const collidingError = data?.reduce(
    (op, col) =>
      op +
      `Start Date: ${moment(col.startDate).format('DD MMM YYYY')} - End Date: ${moment(col.endDate).format(
        'DD MMM YYYY',
      )}, `,
    '',
  );
  return collidingError?.slice(0, collidingError.length - 2);
}

export { getOdaList, createOda, getOdaById, updateOdaById, getOdaChargeById, deleteOdaById };
export const {
  resetOdaRedirect,
  resetOdaError,
  resetSingleOda,
  resetSingleOdaCharge,
  changeNavigatedDate,
  updateFilters,
  clearFilters,
} = reduxStateSlice.actions;
export default reduxStateSlice.reducer;
