import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@Services/apiAxios';
import {
  AccessGroupByIdObj,
  AccessGroupCreatedType,
  AccessGroupListResponse,
  AccessGroupObj,
  AccessGroupQueryParams,
  AccessGroupUpdateObj,
  ActionsListResponse,
  ErrorType,
  PagesListResponse,
} from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const getAccessGroupList = createAsyncThunk<
  AccessGroupListResponse,
  AccessGroupQueryParams,
  { rejectValue: ErrorType }
>('getAccessGroupList', async (queryParams, { dispatch }) => {
  try {
    const res = await API.get(`organization/accessgroup/list`, { params: queryParams });
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const getAccessGroupListById = createAsyncThunk<AccessGroupByIdObj, string, { rejectValue: ErrorType }>(
  'getAccessGroupListById',
  async (code, { dispatch }) => {
    try {
      const res = await API.get(`/organization/accessgroup/code/${code}`);
      return res.data.data;
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: 'An error occurred while retrieving Access Group details.',
        }),
      );
      throw error;
    }
  },
);
const getSideNavbarPages = createAsyncThunk<AccessGroupByIdObj, string, { rejectValue: ErrorType }>(
  'getSideNavbarPages',
  async (code, { dispatch }) => {
    try {
      const res = await API.get(`/organization/accessgroup/code/${code}`);
      return res.data.data;
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: 'An error occurred while retrieving Access Group details.',
        }),
      );
      throw error;
    }
  },
);

const createAccessGroup = createAsyncThunk<AccessGroupCreatedType, AccessGroupObj, { rejectValue: ErrorType }>(
  'createAccessGroup',
  async ({ payload, next }, { dispatch }) => {
    try {
      const res = await API.post(`organization/accessgroup`, payload);
      next();
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'You’re template has been successfully created',
          subTitle: '',
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getPagesList = createAsyncThunk<PagesListResponse, undefined, { rejectValue: ErrorType }>(
  'getPagesList',
  async (_, { dispatch }) => {
    try {
      const res = await API.get(`organization/page/list`);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getActionsList = createAsyncThunk<ActionsListResponse, undefined, { rejectValue: ErrorType }>(
  'getActionsList',
  async (_, { dispatch }) => {
    try {
      const res = await API.get(`organization/action/list`);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const updateAccessGroup = createAsyncThunk<AccessGroupByIdObj, AccessGroupUpdateObj, { rejectValue: ErrorType }>(
  'updateAccessGroup',
  async ({ payload: { body, code }, next }, { dispatch }) => {
    try {
      const res = await API.put(`organization/accessgroup/${code || ''}`, body);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'You’re template has been successfully updated',
          subTitle: '',
        }),
      );
      next();
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

export {
  getAccessGroupList,
  getAccessGroupListById,
  createAccessGroup,
  getPagesList,
  getActionsList,
  updateAccessGroup,
  getSideNavbarPages,
};
