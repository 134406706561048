import React from 'react';
import OrganizationSettingTabs from '../OrganizationSetup/OrganizationSetting/OrganizationSettingTabs';

function OrganizationSettings() {
  return (
    <>
      <OrganizationSettingTabs viewFor="settings" />
    </>
  );
}

export default OrganizationSettings;
