import Image from '@Components/Image';
import { Box } from '@mui/material';
// import PDFViewer from 'pdf-viewer-reactjs';
import React from 'react';

function ViewDocument(props: ViewDocumentType) {
  const mimeTypes = /image\/png|image\/jpeg|image\/jpg|image\/svg\+xml|image\/gif|image\/svg\+xml/;
  const isFileTpeImage = mimeTypes.test(props.fileType);
  return (
    <>
      {isFileTpeImage ? (
        <Image src={props.src} alt="preview kyc" style={{ width: '100%' }} />
      ) : (
        <Box
          sx={{
            p: '1rem',
            div: {
              height: '100% !important',
            },
          }}
        >
          {/* <PDFViewer
            document={{
              url: props.src,
            }}
            hideNavbar={true}
          /> */}
          <iframe
            src={props.src}
            style={{
              width: '100%',
              height: '14rem',
              border: 'none',
            }}
            title="PDF Viewer"
          />
        </Box>
      )}
    </>
  );
}

export default ViewDocument;

interface ViewDocumentType {
  fileType: string;
  src: string;
}
