import React from 'react';
import { Grid, Typography } from '@mui/material';
import SbTextField from '../../components/Textfield';
import Divider from '../../components/Divider';
import SbTooltip from '../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import { FormikType, SHIPMENT_STETTING_FORM } from './type';
import RadioButton from '@Components/RadioButton';

const RadioList = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

interface PROPS_TYPE extends FormikType {
  values: {
    packagingCharge: SHIPMENT_STETTING_FORM['packagingCharge'];
    insuranceCharge: SHIPMENT_STETTING_FORM['insuranceCharge'];
    enablePackagingCharge?: SHIPMENT_STETTING_FORM['enablePackagingCharge'];
    enableInsuranceCharge?: SHIPMENT_STETTING_FORM['enableInsuranceCharge'];
  };
  disabled?: boolean;
}

function PackageDetails(props: PROPS_TYPE) {
  const onChangeEnablePackaging = () => {
    props.setFieldValue?.('enablePackagingCharge', !props.values.enablePackagingCharge);
    props.setFieldValue?.('packagingCharge.charge', '');
  };
  const onChangeEnableInsurance = () => {
    props.setFieldValue?.('enableInsuranceCharge', !props.values.enableInsuranceCharge);
    props.setFieldValue?.('insuranceCharge.charge', '');
  };

  const onChangePackaging = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue?.('packagingCharge.charge', event.target.value);
  };
  const onChangeInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue?.('insuranceCharge.charge', event.target.value);
  };
  console.log(props.errors, props.values);
  return (
    <>
      <Grid item container xs={12} alignItems="baseline" spacing={2}>
        <Grid container item xs={12} mb={4}>
          <Typography className="md-text font-semibold mr-1">Value Added Services</Typography>
        </Grid>
        <Grid container item xs={12} md={2.5} lg={2.5} xl={2} alignItems="center">
          <Typography className="md-text font-semibold mr-1">Offer packaging?</Typography>
          <SbTooltip placement="right" title="User can opt for packaging while shipment booking.">
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <Grid container alignItems={'center'} item xs={12} md={9.5} lg={9.5} xl={10}>
          <RadioButton
            containerClass="w-full"
            name="accountType"
            value={!!props.values.enablePackagingCharge}
            onChange={onChangeEnablePackaging}
            list={RadioList}
            disabled={props.disabled}
          />
        </Grid>
        {props.values.enablePackagingCharge && (
          <Grid container alignItems={'end'} item xs={12} md={9.5} lg={9.5} xl={10} rowGap={2}>
            <Grid item xs={12} sm={6}>
              <SbTextField
                type="number"
                label="Add Packaging Charge"
                value={props.values.packagingCharge.charge || 0}
                disabled={props.disabled || !props.values.enablePackagingCharge}
                onChange={onChangePackaging}
                error={props.values.enablePackagingCharge ? props.errors?.packagingCharge?.charge || '' : ''}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider className="pt-1 pb-1 mb-4 mt-4" />
      <Grid item container xs={12} alignItems="baseline" spacing={2}>
        <Grid container item xs={12} md={2.5} lg={2.5} xl={2} alignItems="center">
          <Typography className="md-text font-semibold mr-1">Insurance </Typography>
          <SbTooltip
            placement="right"
            title="User can opt for Insurance while shipment booking. The given percent will be applicable on Total shipment value."
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <Grid container alignItems={'center'} item xs={12} md={9.5} lg={9.5} xl={10}>
          <RadioButton
            containerClass="w-full"
            name="accountType"
            value={!!props.values.enableInsuranceCharge}
            onChange={onChangeEnableInsurance}
            list={RadioList}
            disabled={props.disabled}
          />
        </Grid>
        {props.values.enableInsuranceCharge && (
          <Grid container alignItems={'end'} item xs={12} md={9.5} lg={9.5} xl={10} rowGap={2}>
            <Grid item xs={12} sm={6}>
              <SbTextField
                type="number"
                label="Add Insurance Percentage"
                value={props.values.insuranceCharge.charge || 0}
                disabled={props.disabled || !props.values.enableInsuranceCharge}
                onChange={onChangeInsurance}
                error={props.values.enableInsuranceCharge ? props.errors?.insuranceCharge?.charge || '' : ''}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default PackageDetails;
