import SBDropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import SbTextField from '@Components/Textfield';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
// import Actions from '../Actions';

import { ConnectOnWarehouse } from '@Reducers/AdminShipment/Type';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import SbButton from '@Components/Button';
import { AddressType } from '@Reducers/Warehouse/Type';
import { connectOnWarehouse, setLastTransactionWarehouse } from '@Reducers/AdminShipment';
import { CSPChargeType, RatesType } from '@Reducers/GetRates/Type';
import { defaultBoxes } from '../../../../GetRatesV2/Constant';
import { getBuyingRates } from '@Reducers/GetRates';
import { ShipmentControl } from '@Services/shipmentActionControl';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Image from '@Components/Image';
import { updateLastConnection } from '@Reducers/AdminShipment/actions';
import FedXInformation from './Fedex/FedXInformation';
import { ExpandLessRounded } from '@mui/icons-material';
import { initFedXInformation } from './Fedex/constants';
import { CSP_TYPES } from '@Constants/Shipment';

interface ConnectWarehouseFormType {
  fromWarehouseId: string;
  data: ConnectOnWarehouse;
  disableForm?: boolean;
  isEditable?: boolean;
  handleCancel?: () => void;
  onSelectRate?: (data: CSPChargeType) => void;
  children?: React.ReactNode;
}

interface InitConnectWarehouse {
  warehouseId: string;
  buyigRate: string;
  awb: string;
}
const initConnectWarehouse: InitConnectWarehouse = {
  warehouseId: '',
  buyigRate: '',
  awb: '',
};

// const disableStyles = { opacity: '0.5' };

function getAddressObj({ pincode, city, country, state }: AddressType) {
  return { city, state, country, pincode };
}

function ViewConnectWarehouse(props: ConnectWarehouseFormType) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    // fromWarehouseId: warehouseId,
    data,
  } = props;

  const { buyingPricing, carrierDetails } = data || {};
  const dropdownId = React.useId();
  const warehouseDropDownId = React.useId();
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment?.shipmentById);
  const lastTransitWarehouse = useSelector((state: RootStateType) => state.adminShipment?.lastTransitWarehouse);
  const shipmentActionControl = new ShipmentControl(shipmentById);
  const [showAWB, setShowAWB] = useState<string>('');

  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const { warehouseListForShipmentOptions } = useSelector((state: RootStateType) => state.Warehouse);
  const connectOnWarehouseList = useSelector(
    (state: RootStateType) => state.adminShipment.shipmentConnectOnWarehouse?.map(x => x.warehouseId) || [],
  );

  // const warehouseList = warehouseListForShipmentOptions.filter(wh => wh.value !== warehouseId);

  const userData = useSelector((state: RootStateType) => state.loginUser.profile);
  const {
    // warehouseListForShipent: { list: warehouseList },
    warehouseListForShipmentOptionsHashById,
  } = useSelector((state: RootStateType) => state.Warehouse);
  const countriesObj = useSelector((state: RootStateType) => state.global?.countriesObj);

  // const editLastConnection = useSelector((state: RootStateType) => state.adminShipment?.shipmentById);

  const [initials, setInitials] = useState({ ...initConnectWarehouse });

  const BuyingChargesList: DropdownOptionType[] = useMemo(() => {
    if (!buyingPricing?.charges) return [];
    return buyingPricing.charges.map(charge => ({
      display: charge.serviceName,
      value: `${charge._systemId}-${charge._courierId}`,
      secondary: `Total charge: ${organizationCountry?.currency?.name || ''} ${charge.totalPricing}`,
      metadata: charge,
    }));
  }, [buyingPricing?.charges]);

  const _selectedBuyigRate = buyingPricing?.selectedCharge
    ? `${buyingPricing?.selectedCharge._systemId}-${buyingPricing?.selectedCharge._courierId}`
    : '';

  useEffect(() => {
    setInitials({ awb: carrierDetails?.awb || '', warehouseId: data.warehouseId, buyigRate: _selectedBuyigRate });
  }, [carrierDetails?.awb, data.warehouseId, buyingPricing]);

  const hasSystemAccess = useMemo(() => {
    return userData?._systems.includes(data.buyingPricing.chargingSystemId);
  }, [data.buyingPricing.chargingSystemId, userData?._systems]);

  const handleForwardShipment = async () => {
    console.log('initials->', initials);
    const [_systemId, _courierId] = initials.buyigRate.split('-');
    const connectOnWarehouseListCopy = [...connectOnWarehouseList];
    connectOnWarehouseListCopy.pop();
    if (data._id) {
      await dispatch(
        updateLastConnection({
          shipmentNumber: data.shipmentNumber,
          awb: initials.awb,
          connectOnId: data._id,
        }),
      );
    } else {
      dispatch(
        connectOnWarehouse({
          shipmentNumber: data.shipmentNumber,
          connectOn: [...connectOnWarehouseListCopy, initials.warehouseId],
          awb: initials.awb,
          selectedCourierId: _courierId,
          selectedSystemId: _systemId,
          countryId:
            warehouseListForShipmentOptions?.find(item => item?.value === initials.warehouseId)?.countryId || '',
        }),
      );
    }
    props?.handleCancel?.();
  };

  const fetchConnectOnRateRates = (selectedObj: AddressType) => {
    const payload: RatesType | null = getRatePayload(selectedObj);
    console.log(payload);
    if (payload) dispatch(getBuyingRates(payload));
  };

  const getRatePayload = (selectedObj: AddressType) => {
    const { _packageId, buyingPricing, createdAt } = shipmentById || {};
    const isDocument = shipmentById?._packageId.isDocument || false;
    const shipmentType = isDocument ? 'document' : 'parcel';

    const source = warehouseListForShipmentOptionsHashById?.[props.fromWarehouseId]?._addressId;
    const destination = selectedObj;
    if (!source || !destination) return null;

    const payload: RatesType = {
      onlyBuying: true,
      source: getAddressObj({ ...source, country: countriesObj?.[source.country]?.name || '' }),
      destination: getAddressObj(destination),
      document: isDocument,
      weight: buyingPricing?.chargeableWeight || 0,
      shipmentType: shipmentType,
      package: {
        box: _packageId?.box.length
          ? _packageId?.box.map(box => ({
              weight: box.weight.toString(),
              length: box.length,
              width: box.width,
              height: box.height,
            }))
          : defaultBoxes,
        unitOfMeasurement: _packageId?.unitOfMeasurement || 'kg-cm',
      },
      rateFor: 'connect-on',
      date: createdAt ? new Date(createdAt) : undefined,
      // userId: _userId?._id,
    };
    return payload;
  };

  const disabled = props.isEditable ? !shipmentActionControl.canConnectToWareshouse : !props.isEditable;
  const enableGenerateAWB = buyingPricing?.selectedCharge.code === CSP_TYPES.fedex.value;
  return (
    <Grid container item>
      <Grid
        container
        item
        alignItems="flex-start"
        display="grid"
        gridTemplateColumns={{
          xs: '1fr', // Single-column layout for extra-small screens
          sm: '1fr 1fr', // Two columns for small screens
          md: '1.5fr 2fr', // Original layout for medium screens and larger
        }}
        gap={{
          xs: 4, // Smaller gap for extra-small screens
          sm: 6, // Moderate gap for small screens
          md: 14, // Original gap for medium screens
        }}
        mt={{
          xs: 2, // Reduced margin for small screens
          sm: 3,
          md: 4,
        }}
      >
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6} className={disabled ? 'disabled-form' : ''}>
            <SBDropdown
              id={warehouseDropDownId}
              options={warehouseListForShipmentOptions}
              required
              label="Select Transit Warehouse"
              value={initials.warehouseId ? [initials.warehouseId] : []}
              onChange={([value], [selectedObj]: DropdownOptionType<AddressType>[]) => {
                // return;
                // handleChange(value);
                setInitials(initials => ({ ...initials, warehouseId: value, buyigRate: '' }));
                dispatch(setLastTransactionWarehouse(selectedObj));
                if (selectedObj.metadata) fetchConnectOnRateRates(selectedObj.metadata);
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={disabled ? 'disabled-form' : ''}>
            <SBDropdown
              id={dropdownId}
              required
              label="Booked Service"
              options={BuyingChargesList}
              value={initials.buyigRate ? [initials.buyigRate] : []}
              onChange={([value], [selectedObj]) => {
                setInitials(initials => ({ ...initials, buyigRate: value }));
                setShowAWB('');
                if (selectedObj.metadata && props?.onSelectRate)
                  props.onSelectRate(selectedObj.metadata as CSPChargeType);
              }}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} className={props.disableForm && !props.isEditable ? 'disabled-form' : ''}>
            <SbTextField
              value={initials.awb}
              name="awb"
              label="Carrier AWB"
              disabled={!props.isEditable || ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')}
              onChange={e => setInitials(initials => ({ ...initials, awb: e.target.value }))}
            />
          </Grid>
        </Grid>
        {props?.children}
      </Grid>

      {(hasSystemAccess || props.isEditable) && (
        <Grid container item xs={12} gap={2} mt={3}>
          <Grid item xs={12} sm={6} md={3.5}>
            <SbButton
              variant="outlined"
              sx={{ borderRadius: `4px !important`, padding: '0 !important' }}
              className="w-full border-grey sb-text-black md-text"
              startIcon={<Image src="/images/icons/GenerateAwb.svg" alt="Edit" />}
              endIcon={showAWB ? <ExpandLessRounded /> : <ExpandMoreIcon />}
              disabled={enableGenerateAWB ? (props.isEditable ? false : true) : true}
              onClick={() => {
                if (showAWB) {
                  setShowAWB('');
                } else {
                  setShowAWB(buyingPricing?.selectedCharge.code || '');
                }
              }}
            >
              {/* Generate DHL AWB */}
              Generate AWB
            </SbButton>
          </Grid>
          {!showAWB && (
            <Grid item xs={12} sm={6} md={3.5}>
              <SbButton
                variant="contained"
                sx={{ borderRadius: `4px !important` }}
                className="w-full"
                disabled={!props.isEditable || ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')}
                onClick={handleForwardShipment}
              >
                Update
              </SbButton>
            </Grid>
          )}
          {props.isEditable && !showAWB && (
            <Grid item xs={12} sm={6} md={3.5}>
              <SbButton
                variant="outlined"
                sx={{ borderRadius: `4px !important` }}
                className="w-full"
                onClick={() => props?.handleCancel?.()}
                disabled={['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')}
              >
                Cancel
              </SbButton>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container item xs={12}>
        <FedXInformation
          onClickForward={() => {
            //TODO: Handle when other carrier AWB added
          }}
          onClose={() => {
            props?.handleCancel?.();
            setShowAWB('');
          }}
          showFedExDetails={showAWB === CSP_TYPES.fedex.value}
          fedExDetails={{
            ...initFedXInformation,
            warehouseId1: shipmentById?._warehouseAddressId?._id || '',
            warehouseId2: lastTransitWarehouse?.metadata?._id,
            type: 'W-W',
            connectOn: {
              shipmentNumber: data.shipmentNumber,
              awb: initials.awb,
              connectOnId: data._id,
            },
          }}
          isEditable={props.isEditable && !['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')}
          btnText={'Update'}
        />
      </Grid>

      {hasSystemAccess && (
        <Grid item xs={12} mt={showAWB ? 0 : 3}>
          <Typography
            className="md-text font-medium border-grey w-fit px-3 py-1/2 rounded-xs"
            sx={{ border: '1px solid' }}
          >
            This shipment has been forwarded.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default ViewConnectWarehouse;
