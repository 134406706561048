import React from 'react';
import { Grid, TableBody, TableHead, TableRow } from '@mui/material';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { RateRowService } from '../../PricingList/RateRow';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';
import { SystemState } from '@Reducers/Systems/Type';
import { CopyRateFormType } from './type';
import SbButton from '@Components/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import moment from 'moment';

interface PropsType {
  lists: CopyRateFormType[];
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
}

function CopyRateTable(props: PropsType) {
  return (
    <>
      <SBTableWrapper className="mt-2">
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ minWidth: '300px' }}>Service</TableHeadCell>
              <TableHeadCell sx={{ minWidth: '150px' }}>System</TableHeadCell>
              <TableHeadCell>Validity</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.lists?.map((rate, index) => (
              <CopyRateRow key={index} index={index} {...rate} onDelete={props.onDelete} onEdit={props.onEdit} />
            ))}
          </TableBody>
        </SBTable>
      </SBTableWrapper>
    </>
  );
}

interface CopyRateRowType extends CopyRateFormType {
  onDelete: PropsType['onDelete'];
  onEdit: PropsType['onEdit'];
  index: number;
}

function CopyRateRow(props: CopyRateRowType) {
  const startFormattedDate = moment(props?.fromDate?.toString()).format('DD/MM/YYYY');
  const endFormattedDate = moment(props?.toDate?.toString()).format('DD/MM/YYYY');
  return (
    <TableRow>
      <TableRowCell>
        <RateRowService cspId={props._courierId} />
      </TableRowCell>
      <TableRowCell>
        <SystemName systemIds={props._systemId} />
      </TableRowCell>
      <TableRowCell>
        <Grid container className="md-text justify-center">{`${startFormattedDate} - ${endFormattedDate}`}</Grid>
      </TableRowCell>
      <TableRowCell>
        <Actions index={props.index} onDelete={props.onDelete} onEdit={props.onEdit} />
      </TableRowCell>
    </TableRow>
  );
}

function SystemName({ systemIds }: { systemIds: string[] }) {
  const systemHashById = useSelector<RootStateType>(
    state => state.System.activeSystemListHashById,
  ) as SystemState['activeSystemListHashById'];

  const systemNames = systemIds
    .map(x => systemHashById?.[x]?.name)
    .filter(x => x)
    .join(', ');
  return (
    <Grid container className="md-text justify-center">
      {systemNames}
    </Grid>
  );
}

interface ActionType {
  onDelete: PropsType['onDelete'];
  onEdit: PropsType['onEdit'];
  index: number;
}

function Actions({ onEdit, onDelete, index }: ActionType) {
  return (
    <section className="px-1 py-1 w-full md-text underline cursor-pointer rounded text-center bg-p-light">
      <SbButton>
        <EditIcon onClick={() => onEdit(index)} />
      </SbButton>
      <SbButton className="ml-1" onClick={() => onDelete(index)}>
        <DeleteIcon />
      </SbButton>
    </section>
  );
}

export default CopyRateTable;
