import React from 'react';
import SbButton from '@Components/Button';
import Divider from '@Components/Divider';
import { Delete } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { PricingDataType } from '@Reducers/AdminShipment/Type';
import { PricingType } from '@Reducers/GetRates/Type';
import { formatNumber, useLocale } from '../../../../utils/formatNumber';

export default function Charges(props: {
  totalPricing: number;
  pricing: PricingDataType[] | PricingType[];
  taxPricing: PricingDataType[] | PricingType[];
  extraCharge?: PricingDataType[];
  currency: string;
  disable?: boolean;
  writeAccess?: boolean;
  label?: string;
  onDeleteExtraCharge?: ({ chargeName, value }: { chargeName: string; value: number }) => void;
}) {
  const locale = useLocale();

  return (
    <>
      <Grid container justifyContent={'space-between'}>
        <Typography className="md-text font-semibold">{props.label ? props.label : 'Charges'}</Typography>
        <Typography className="md-text font-semibold">Amount</Typography>
      </Grid>
      <Divider className="my-1" />
      {props.pricing?.length ? (
        props.pricing.map(({ displayKey, value, rateType, invoiced }: PricingDataType, index) => (
          <SingleCharge
            key={`pricing-${index}`}
            displayKey={displayKey}
            value={formatNumber(value, locale)}
            isDelete={props.disable ? false : rateType === 'extra'}
            onDeleteExtraCharge={
              rateType === 'extra' ? () => props?.onDeleteExtraCharge?.({ chargeName: displayKey, value }) : undefined
            }
            invoiced={invoiced}
            writeAccess={props.writeAccess}
          />
        ))
      ) : (
        <></>
      )}
      {props.extraCharge?.length ? (
        props.pricing.map(({ displayKey, value }, index) => (
          <SingleCharge
            key={`extra-charge-${index}`}
            displayKey={displayKey}
            value={value}
            writeAccess={props.writeAccess}
          />
        ))
      ) : (
        <></>
      )}
      {props.taxPricing?.length ? (
        props.taxPricing.map(({ displayKey, value, rateType }, index) => (
          <SingleCharge
            key={`tax-${index}`}
            displayKey={displayKey}
            value={formatNumber(value, locale)}
            isDelete={props.disable ? false : rateType === 'extra'}
            onDeleteExtraCharge={
              rateType === 'extra' ? () => props?.onDeleteExtraCharge?.({ chargeName: displayKey, value }) : undefined
            }
            writeAccess={props.writeAccess}
          />
        ))
      ) : (
        <></>
      )}
      <Divider className="my-1" />
      <Grid container justifyContent={'space-between'}>
        <Typography className="font-medium md-text">Total</Typography>
        <Typography className="font-semibold md-text">
          {props.currency} {formatNumber(props.totalPricing, locale)}
        </Typography>
      </Grid>
      <Divider className="my-1" />
    </>
  );
}

function SingleCharge(props: {
  displayKey: string;
  value: number | string;
  isDelete?: boolean;
  writeAccess?: boolean;
  onDeleteExtraCharge?: () => void;
  invoiced?: boolean;
}) {
  const { displayKey, value, isDelete, invoiced, writeAccess } = props;
  return (
    <Grid container justifyContent={'space-between'} className="mb-1">
      <Grid item xs={10} lg={9}>
        <Typography className="md-text">
          {displayKey}
          {!invoiced && isDelete && (
            <SbButton
              size="small"
              startIcon={<Delete fontSize="small" />}
              className="ml-2 sb-text-gray xs-text"
              sx={{ height: 24, borderRadius: '4px !important' }}
              onClick={props.onDeleteExtraCharge}
              disabled={!writeAccess}
            >
              Delete
            </SbButton>
          )}
        </Typography>
      </Grid>
      <Grid item xs={2} lg={3}>
        <Typography className="md-text text-right">{value}</Typography>
      </Grid>
    </Grid>
  );
}
