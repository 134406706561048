import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormHelperText, Grid, Typography } from '@mui/material';
import dayJs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';

import Title from '@Components/Title';
import Divider from '@Components/Divider';
import { FormLabel, FormContent } from '@Components/Form';

import SbTextField from '@Components/Textfield';

import AccountLookup from '@Components/AccountLookup';
import RadioButton from '@Components/RadioButton';
import DatePicker from '@Components/DatePicker';

import { AppDispatch, RootStateType } from '@Store';
import { getActiveSystemList } from '@Reducers/Systems';
import { UploadMarkup, UploadZone } from '@Reducers/RateUpload';
import { getServiceList } from '@Reducers/CarrierAndService';
import { CopyRatesType, UploadRate, Zone } from '@Reducers/RateUpload/Type';

import { CreateRateSchema } from './schema';
import { CopyRate as CopyRateType, CreateRatePropsType } from '../type';
import SbButton from '@Components/Button';
import { RatesTypeList, initCreateRate, pageConfig } from '../constant';

import SuccessError from '../SucessError';
import Loader from '@Components/Loader';
import { UserObj } from '@Reducers/AccountLookup/Type';
import SelectedAccountNumber from '../SelectedAccountNumber';
import CopyRate from './CopyRates';
import CSP from './csp';
import System from './System';
import useAccountLookup from '@Hook/useAccountLookup';
import CopyRateTable from './CopyRateTable';
import MarkupUpload from './MarkupUpload';
import SheetUpload from './SheetUpload';
import ApplyForRates from './ApplyForRates';
import { useLocation, useNavigate } from 'react-router-dom';
import SbTooltip from '@Components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import BackButton from '@Components/BackButton';

function getCopyRatesDate(copyData: CopyRateType[]): CopyRatesType[] {
  const data: CopyRatesType[] = [];
  copyData.forEach((rates: CopyRateType) => {
    const accountNumber = rates.accountNumber ? rates.accountNumber : ['default'];
    accountNumber.forEach(acc => {
      rates.system.forEach(sys => {
        data.push({
          accountNumber: acc,
          system: sys,
          csp: rates.csp,
          startDate: rates.startDate,
          endDate: rates.endDate,
          minWeight: Number(rates.minWeight),
          maxWeight: Number(rates.maxWeight),
        });
      });
    });
  });
  return data;
}

function CreateRate(props: CreateRatePropsType) {
  const navigate = useNavigate();
  const location = useLocation();
  const modalId = React.useId();
  const copyRatesModalId = React.useId();

  const _pageConfig = pageConfig[props.rateFor];
  const showAccountNumber = _pageConfig.showAccountNumber;
  const { loading: AccountLookupLoading } = useAccountLookup(modalId, true);
  const { loading: CopyAccountLookupLoading } = useAccountLookup(copyRatesModalId, true);

  const dispatch = useDispatch<AppDispatch>();
  const RateUpload = useSelector((state: RootStateType) => state.RateUpload);
  // const [rateObj, setRateObj] = useState<UploadRate>({ ...initCreateRate });

  const [serviceName, setServiceName] = useState<string>('');
  const [systemName, setSystemName] = useState<string>('');

  const [markup, setMarkup] = useState<number>(0);
  const [sheet, setSheet] = useState<Zone[]>([]);
  const [selectedAccNum, setSelectedAccNum] = useState<string>('default');
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
  const [copyRateEdit, setCopyRateEdit] = useState<CopyRateType | null>(null);
  const [copyRates, setCopyRates] = useState<CopyRateType[]>([]);

  const [markupError, setMarkupError] = useState<string>('');
  const [sheetError, setSheetError] = useState<string>('');
  const [accountNumberError, setAccountNumberError] = useState<string>('');

  const showBackButton = location.pathname.includes('/create') || location.pathname.includes('/edit');

  const link = location.pathname.startsWith('/pricing/base')
    ? '/pricing/base'
    : location.pathname.startsWith('/pricing/tariff')
    ? '/pricing/tariff'
    : '/pricing/contract';

  useEffect(() => {
    dispatch(getActiveSystemList());
    dispatch(getServiceList({}));
    if (_pageConfig.onlySheet) formik.setFieldValue('rateApplyFor', 'rate-sheet');
  }, []);

  const onSelectUser = (user: UserObj) => {
    setAccountNumberError('');
    setSelectedAccNum(user._accountId?.accountNumber || '');
  };

  const onFormClear = () => {
    formik.handleReset({ ...initCreateRate });
    setCopyRates([]);
    setMarkup(0);
    setSheet([]);
  };

  const onCloseModal = () => {
    setCopyModalOpen(false);
    if (copyRateEdit) setCopyRateEdit(null);
  };

  useEffect(
    function () {
      if (location.search) {
        const searchParams = new URLSearchParams(location.search);
        const ratesFor = searchParams.get('ratesFor');
        if (ratesFor === 'inbound') setFieldValue('isImport', true);
        else if (ratesFor === 'outbound') setFieldValue('isImport', false);

        const accountNumber = searchParams.get('accountNumber');
        if (props.pageType === 'contract' && !accountNumber) navigate('/pricing/contract');
        else if (accountNumber) setSelectedAccNum(accountNumber);
      }
    },
    [location.search],
  );

  const onAddAndUpdateCopyRate = (rate: CopyRateType) => {
    const _copyRatesTemp = [...copyRates];
    // Convert minWeight to a number
    if (rate.minWeight) {
      rate.minWeight = Number(rate.minWeight);
    }
    if (rate.id || rate.id === 0) {
      const updateObjIndex = _copyRatesTemp.findIndex(x => x.id === rate.id);
      _copyRatesTemp[updateObjIndex] = rate;
    } else {
      const accountNumber = rate.accountNumber?.map(x => (props.pageType === 'base' ? null : x));
      rate.id = _copyRatesTemp.length;
      // if(rate.accountNumber[0] === "default") rate.accountNumber[0] = null;
      _copyRatesTemp.push({ ...rate, accountNumber });
    }
    // if(rate.accountNumber === "default") rate.accountNumber = null;
    setCopyRates([..._copyRatesTemp]);
  };

  const onDeleteCopyRates = (index: number) => {
    const _tempAcc = [...copyRates];
    _tempAcc.splice(index, 1);
    setCopyRates([..._tempAcc]);
  };

  const onCopyRatesEdit = (index: number) => {
    setCopyRateEdit({ ...copyRates[index] });
    setCopyModalOpen(true);
  };

  const onClickCopyRates = async () => {
    const validateData = await formik.validateForm();
    if (!Object.keys(validateData).length) setCopyModalOpen(true);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initCreateRate },
    validationSchema: CreateRateSchema,

    onSubmit(values) {
      onCreate(values);
    },
    validateOnChange: false,
  });
  const { handleChange, setFieldValue, values: formikValues, errors } = formik;
  const showMarkup = _pageConfig.onlySheet ? false : formikValues.rateApplyFor === 'markup';

  const onCreate = (params: UploadRate) => {
    let isError = false;
    if (showMarkup && !markup) (isError = true), setMarkupError('Markup value is required.');
    if (!showMarkup && !sheet.length) (isError = true), setSheetError('Sheet is required.');
    if (showAccountNumber && (!selectedAccNum || selectedAccNum === 'default'))
      (isError = true), setAccountNumberError('Please Select Account Number.');
    if (isError) return;
    const payload = {
      isImport: params.isImport,
      document: params.document,
      system: params.system,
      csp: params.csp,
      minWeight: Number(params.minWeight),
      maxWeight: params.maxWeight,
      accountNumber: selectedAccNum ? selectedAccNum : null,
      startDate: params.startDate,
      endDate: params.endDate,
      copyRates: getCopyRatesDate(copyRates),
      isMarkup: params.rateApplyFor === 'zone-wise',
    };

    if (showMarkup) {
      dispatch(UploadMarkup({ ...payload, markup }));
    } else {
      // console.log({ payload, sheet, selectedAccNum });
      if (selectedAccNum === 'default' && !payload.isMarkup) payload.accountNumber = null;
      if (props.pageType === 'tariff') payload.accountNumber = 'default';
      dispatch(UploadZone({ ...payload, sheet: { zone: sheet } }));
    }
  };
  return (
    <Loader overly loading={RateUpload.loading || AccountLookupLoading || CopyAccountLookupLoading}>
      <>
        {copyModalOpen && (
          <CopyRate
            modalOpen={copyModalOpen}
            onCloseModal={onCloseModal}
            onAdd={onAddAndUpdateCopyRate}
            rateFor={props.rateFor}
            modalId={copyRatesModalId}
            edit={copyRateEdit}
            serviceName={serviceName}
            systemName={systemName}
            selectedAccNum={selectedAccNum}
            startDate={formikValues.startDate} // Pass startDate
            endDate={formikValues.endDate} // Pass endDate
            minWeight={formikValues.minWeight} // Pass minWeight
            maxWeight={formikValues.maxWeight} // Pass maxWeight
            defaultCspId={formikValues.csp} // Pass the CSP ID
            defaultSystemIds={formikValues.system} // Pass the System IDs array
          />
        )}
        <SuccessError pageType={props.pageType} onFormClear={onFormClear} />
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          {showBackButton && <BackButton link={link} />}
          <Title title={_pageConfig.title} subTitle={_pageConfig.subTitle} />
        </Box>
        <Grid container className="p-3">
          <Typography variant="h6" className="underline">
            {_pageConfig.createTitle || 'Add rates'}
          </Typography>
          <Divider className="my-2" />
          {/****************************** Account Lookup **************************************/}
          {false && showAccountNumber && (
            <Grid item container xs={12} alignItems="start">
              <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
                <FormLabel>Account Number*</FormLabel>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <FormContent>
                  <AccountLookup modalId={modalId} showSearchInput getSelectedUser={onSelectUser} />
                </FormContent>
                {accountNumberError && (
                  <FormHelperText error className="mt-1">
                    {accountNumberError}
                  </FormHelperText>
                )}
                {selectedAccNum && selectedAccNum !== 'default' && (
                  <Grid item className="mt-2">
                    <SelectedAccountNumber accountNumber={selectedAccNum} onRemove={() => setSelectedAccNum('')} />
                  </Grid>
                )}
              </Grid>
              <Divider className="my-2" />
            </Grid>
          )}
          {/****************************** Service **************************************/}
          <CSP
            onChange={([value], [selected]) => {
              setFieldValue('csp', value, false);
              setServiceName(selected?.display || '');
            }}
            value={[formikValues.csp]}
            error={errors.csp}
          />
          {/****************************** System **************************************/}
          <System
            onChange={([value], [selected]) => {
              setFieldValue('system', value, false);
              setSystemName(selected?.display || '');
            }}
            value={[formikValues.system]}
            error={Array.isArray(errors.system) ? errors.system.join(',') : errors.system}
          />
          {/****************************** Rates For **************************************/}
          {/* <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Rates For*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <RadioButton
                  containerClass="w-full"
                  name="isImport"
                  defaultValue={true}
                  value={formikValues.isImport}
                  onChange={evt => {
                    setFieldValue('isImport', evt.target.value === 'true');
                  }}
                  list={RatesForTypeList}
                  sx={{ display: 'flex', columnGap: 10 }}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid> */}
          {/****************************** Rates Type **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Rates Type*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <RadioButton
                  containerClass="w-full"
                  name="type"
                  defaultValue={false}
                  value={formikValues.document}
                  onChange={evt => {
                    setFieldValue('document', evt.target.value === 'true');
                  }}
                  list={RatesTypeList}
                  sx={{ display: 'flex', columnGap: 10 }}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Apply For **************************************/}
          {(props.rateFor === 'contract' || props.rateFor === 'tariff') && (
            <ApplyForRates
              values={formikValues}
              setFieldValue={setFieldValue}
              errors={errors}
              rateFor={props.rateFor}
            />
          )}
          {/****************************** Start Date **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Start Date*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <DatePicker
                  inputFormat="DD MMM YYYY"
                  label=""
                  value={formikValues.startDate ? dayJs(formikValues.startDate) : null}
                  required
                  minDate={dayJs()}
                  // maxDate={dayJs(new Date(formikValues.endDate))}
                  handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
                    if (date) setFieldValue('startDate', date.format(), false);
                    else return setFieldValue('startDate', null, false);
                    if (formikValues.endDate && date.valueOf() > new Date(formikValues.endDate).getTime()) {
                      formik.setFieldError('endDate', 'End date should be greater than start date');
                    }
                  }}
                  error={errors.startDate}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** End Date **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>End Date*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <DatePicker
                  inputFormat="DD MMM YYYY"
                  label=""
                  value={formikValues.endDate ? dayJs(formikValues.endDate) : null}
                  required
                  handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
                    formik.setFieldError('endDate', '');
                    // console.log({ date });
                    if (date) setFieldValue('endDate', date.format(), false);
                    else setFieldValue('endDate', null, false);
                  }}
                  error={errors.endDate}
                  minDate={formikValues.startDate ? dayJs(formikValues.startDate) : undefined}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Min Weight **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Min Weight</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <SbTextField
                  type="string"
                  required
                  name="minWeight"
                  placeholder="Min Weight (in Kg)"
                  value={formikValues.minWeight ?? undefined}
                  onChange={e => {
                    const { value } = e.target;
                    if (!value || /^\d*\.?\d{0,2}$/.test(value)) {
                      setFieldValue('minWeight', value);
                    }
                    // handleChange();
                  }}
                  error={errors?.minWeight}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Max Weight **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Max Weight</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <SbTextField
                  required
                  type="number"
                  name="maxWeight"
                  placeholder="Max Weight (in Kg)"
                  value={formikValues.maxWeight ?? undefined}
                  onChange={handleChange}
                  error={errors?.maxWeight}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Markup **************************************/}
          {showMarkup && <MarkupUpload value={markup} handleChange={setMarkup} error={markupError} />}
          {/****************************** Rate Sheet **************************************/}
          {!showMarkup && (
            <SheetUpload
              handleChange={setSheet}
              setSheet={setSheet}
              error={sheetError}
              rateApplyFor={formikValues.rateApplyFor}
              maxWeight={formikValues.maxWeight}
            />
          )}

          {/****************************** Actions **************************************/}

          {copyRates.length ? (
            <>
              <Typography className="md-text font-medium">Rates copied to below services:</Typography>
              <CopyRateTable
                showAccountNumber={showAccountNumber}
                lists={copyRates}
                onDelete={onDeleteCopyRates}
                onEdit={onCopyRatesEdit}
              />
              <Grid container mt={2} justifyContent="flex-end">
                <SbButton variant="contained" onClick={onClickCopyRates}>
                  Add More
                </SbButton>
              </Grid>
            </>
          ) : (
            <Grid container>
              <Grid item xs={6} sm={3} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <SbButton fullWidth variant="contained" onClick={onClickCopyRates} className="mr-2">
                  Copy Rates
                </SbButton>
                {props.copyRateText && (
                  <SbTooltip placement="right" title={props.copyRateText}>
                    <InfoRounded />
                  </SbTooltip>
                )}
              </Grid>
            </Grid>
          )}

          <Grid container item mt={4} spacing={2} justifyContent="flex-end">
            <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
              <SbButton fullWidth variant="outlined" onClick={() => navigate(_pageConfig.redirectBack)}>
                Cancel
              </SbButton>
            </Grid>
            <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
              <SbButton
                fullWidth
                variant="contained"
                disabled={!!formik.errors.endDate}
                onClick={_event => {
                  formik.handleSubmit();
                }}
              >
                Create
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Loader>
  );
}

export default CreateRate;
