import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@Services/apiAxios';
import { ErrorType } from '../../../types';
import { TrackShipmentResponseType } from './type';

function getMultipleTrackingURl(id: string | string[]) {
  return API.get('/tracking/track', {
    params: {
      shipmentNumbers: Array.isArray(id) ? id : [id],
    },
  });
}

const getTrackMultipleShipments = createAsyncThunk<TrackShipmentResponseType[], string[], { rejectValue: ErrorType }>(
  'getShipment/track-multiple',
  async shipmentIds => {
    try {
      const res = await Promise.allSettled(shipmentIds.map(id => getMultipleTrackingURl(id))).then(data =>
        data
          .map(data => {
            if (data?.status === 'fulfilled') {
              return data.value.data;
            } else {
              return null;
            }
          })
          .filter(Boolean),
      );
      return res;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  },
);

const getTrackShipment = createAsyncThunk<TrackShipmentResponseType, string, { rejectValue: ErrorType }>(
  'getShipment/track',
  async (shipmentId, { dispatch: _dispatch }) => {
    try {
      const res = await getMultipleTrackingURl(shipmentId);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      console.error('errorRes', errorRes);
      throw error;
    }
  },
);

export { getTrackMultipleShipments, getTrackShipment };
