import { PayloadAction } from '@reduxjs/toolkit';
import { ShipmentHistoryResponse, ShipmentHistoryState } from './Type';
import { SuccessPayload } from '../../../types';

function pending(state: ShipmentHistoryState) {
  state.loading = true;
  state.shipmentCancelled = false;
  state.bulkStatusUploaded = false;
}

function success(state: ShipmentHistoryState) {
  state.loading = false;
}

function getShipmentListFulfilled(state: ShipmentHistoryState, data: { payload: ShipmentHistoryResponse }) {
  const { shipmentResult, currentPage, perPage, totalCount } = data.payload.data;
  state.loading = false;
  state.data.list = shipmentResult;
  state.data.totalCount = totalCount;
  state.data.currentPage = currentPage;
  state.data.perPage = perPage;
}

function cancelShipmentFulfilled(state: ShipmentHistoryState, action: PayloadAction<SuccessPayload>) {
  state.loading = false;
  state.success = action?.payload?.msg;
  state.shipmentCancelled = true;
}

function UpdateShipmentStatusFulfilled(state: ShipmentHistoryState, action: PayloadAction<SuccessPayload>) {
  state.loading = false;
  state.success = action?.payload?.msg;
}

function bulkStatusUpdateFulfilled(state: ShipmentHistoryState) {
  state.loading = false;
  state.bulkStatusUploaded = true;
}
function bulkPaymentStatusUpdateFulfilled(state: ShipmentHistoryState) {
  state.loading = false;
  state.isBulkPaymentStatusUpdate = true;
}

function rejected(state: ShipmentHistoryState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

export {
  pending,
  success,
  getShipmentListFulfilled,
  cancelShipmentFulfilled,
  UpdateShipmentStatusFulfilled,
  rejected,
  bulkStatusUpdateFulfilled,
  bulkPaymentStatusUpdateFulfilled,
};
