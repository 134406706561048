import {
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SbTextField from '../../../components/Textfield';
import SbTooltip from '../../../components/Tooltip';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { SelectOptions } from '../../GetRatesV2/StyleComponent';
import { AppDispatch, RootStateType } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownOptionType } from '../../../components/Dropdown/Type';
import GoogleMaps from '../../../components/Location';
import SBDropdown from '../../../components/Dropdown';
import SbButton from '../../../components/Button';
import LanguageIcon from '@mui/icons-material/Language';
import { InfoRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import { Container, ContainerStyle, Wrapper } from '../Styles';
import DragDrop from '../../../components/File/DragDrop';
import { SaveOrganization, resetOrgError } from '../../../store/reducers/OrganizationSetup';
import ColorPicker from '../../../components/ColorPicker';
import { OrganizationOnboardType } from '../../../types/organization';
import { useFormik } from 'formik';
import { getFormattedLocation } from '../../../services/formateLocation';
import { getCountryList } from '../../../store/reducers/Global';
import { FileResultType } from '../../../Helper/file';
import { OrgOnboardSchema } from './schema';
import { useParams, useSearchParams } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import ErrorModal from '../../../components/Modal/error';
import { domainToURL } from '../../../utils/domainToURL';
import { lighten, darken } from 'polished';
// const ButtonContained = styled(SbButton)(({ theme }) => ({
//   background: theme.palette.background.speedbox.dark,
//   '&:hover': {
//     background: theme.palette.background.speedbox.dark,
//   },
// }));
const ButtonContained = styled(SbButton)(({ theme }) => {
  const backgroundColor = theme?.palette?.primary?.main;

  if (!backgroundColor) {
    console.error('The theme object is missing required properties.');
    return {};
  }

  return {
    background: backgroundColor,
    '&:hover': {
      background: backgroundColor,
    },
  };
});
// const ButtonOutlined = styled(SbButton)(({ theme }) => ({
//   borderColor: theme.palette.background.speedbox.dark,
//   color: theme.palette.background.speedbox.dark,
//   '&:hover': {
//     borderColor: theme.palette.background.speedbox.dark,
//     color: theme.palette.background.speedbox.dark,
//   },
// }));

const defaultValue = {
  contactInfo: {
    address: { line1: '', line2: '', city: '', state: '', country: '', pincode: '' },
    telephoneCode: '',
    contactNumber: '',
    email: '',
    supportEmail: '',
  },
  name: '',
  websiteLink: '',
  logo: '',
  // username: '',
  email: '',
  password: '',
  taxInformation: [],
  termsAndCondition: '',
  privacyPolicy: '',
  taxName: '',
  taxNumber: '',
  currency: { name: '', symbol: '', fullname: '' },
};

function OrganizationForm() {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const { countryCodeList, countryList, countriesNameObj, countriesObj, countries } = useSelector(
    (state: RootStateType) => state?.global,
  );
  const {
    error: { isError, msg, errorDetails },
  } = useSelector((state: RootStateType) => state?.orgSetup);
  const [initials, setInitials] = useState<OrganizationOnboardType>({ ...defaultValue });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [primaryColor, setPrimary] = useState('#ffffff');
  const [secondaryColor, secondaryPrimary] = useState('#ffffff');
  function generateThemeColors(primaryMain: string, secondaryMain: string) {
    return {
      primary: {
        main: primaryMain,
        dark: darken(0.2, primaryMain),
        light: lighten(0.2, primaryMain),
        contrastText: '#fff',
      },
      secondary: {
        main: secondaryMain,
        dark: darken(0.2, secondaryMain),
        light: lighten(0.2, secondaryMain),
      },
    };
  }
  const handleFormSubmit = (values: OrganizationOnboardType) => {
    const token = searchParams.get('token');
    const _countryId = countriesNameObj[values?.contactInfo?.address?.country]._id;
    const postObj = JSON.parse(JSON.stringify({ ...values, _countryId }));
    postObj.contactInfo.address.country = _countryId;
    postObj.websiteLink = domainToURL(postObj.websiteLink);
    delete postObj.taxName;
    delete postObj.taxNumber;
    const themeColors = generateThemeColors(primaryColor, secondaryColor);
    postObj.theme = themeColors;
    dispatch(SaveOrganization([postObj, token || '']));
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initials },
    validationSchema: OrgOnboardSchema,
    onSubmit(values) {
      let selectedCurrencyObj = countries.find(item => item.currency.name === values?.currency)?.currency;
      if (selectedCurrencyObj) {
        selectedCurrencyObj = {
          ...selectedCurrencyObj,
          fullForm:
            selectedCurrencyObj.fullForm || countries.find(item => item.currency.name === values?.currency)?.name || '',
        };
      }
      values.currency = selectedCurrencyObj;
      handleFormSubmit(values);
    },
  });

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = formik;
  useEffect(() => {
    setFieldValue('name', params.orgName);
    dispatch(getCountryList());
  }, []);

  // console.info('errors:', errors);

  useEffect(
    function () {
      if (countryCodeList.length) {
        const countryObj = countriesObj[searchParams.get('_countryId') || ''];
        if (countryObj) setFieldValue('contactInfo.telephoneCode', countryObj.telephoneCode);
      }
    },
    [countryCodeList],
  );

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function handleSelectedAddress(event: any) {
    const address = getFormattedLocation(event.address_components);

    const tempObj = { ...values };
    tempObj['contactInfo']['address'] = address;
    setInitials({ ...tempObj });
  }

  const onFileChange = (fileData: FileResultType[]) => {
    setFieldValue('logo', fileData[0]);
  };

  const handleAddTax = () => {
    const { taxInformation, taxName, taxNumber } = values;
    const arr = [...taxInformation];
    if (taxName && taxNumber) arr.push({ name: taxName, value: taxNumber });
    setFieldValue('taxInformation', arr);
    setFieldValue('taxName', '');
    setFieldValue('taxNumber', '');
  };

  const ohandleErrorModalClose = () => {
    dispatch(resetOrgError());
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  return (
    <Container>
      <Grid container className="baseForm">
        <Wrapper container>
          <Grid item xs={12}>
            <Typography variant="h5" className="font-semibold mb-1">
              Organization Setup
            </Typography>
          </Grid>
          <Grid item xs={12} className="mb-3">
            <Typography variant="subtitle1" className="sb-text-gray">
              System is a set of unique serviceable pincodes
            </Typography>
          </Grid>
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Organization name*</Typography>
                <SbTooltip
                  placement="right"
                  title={`Your organization name visible to your customers & staff while using dashboard`}
                >
                  <InfoRounded />
                </SbTooltip>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={12} md={11}>
                <SbTextField
                  name="name"
                  placeholder="Enter Organization Name"
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name && touched.name ? errors.name : ''}
                />
              </Grid>
            </Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Organization Email*</Typography>
                <SbTooltip placement="right" title={`This email will be visible on your dashboard & Log in Page`}>
                  <InfoRounded />
                </SbTooltip>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={12} md={11}>
                <SbTextField
                  value={values.contactInfo.email}
                  name={'contactInfo.email'}
                  startAdornment={<MailOutlineIcon sx={{ mr: 1 }} />}
                  placeholder="Enter Email id"
                  error={errors?.contactInfo?.email && touched?.contactInfo?.email ? errors?.contactInfo?.email : ''}
                  onChange={handleChange('contactInfo.email')}
                />
              </Grid>
            </Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Organization support email*</Typography>
                <SbTooltip placement="right" title={`This email will be used to send user onboarding invitation`}>
                  <InfoRounded />
                </SbTooltip>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={12} md={11}>
                <SbTextField
                  name="contactInfo.supportEmail"
                  value={values.contactInfo.supportEmail}
                  startAdornment={<MailOutlineIcon sx={{ mr: 1 }} />}
                  placeholder="Enter Email id"
                  error={
                    errors?.contactInfo?.supportEmail && touched?.contactInfo?.supportEmail
                      ? errors?.contactInfo?.supportEmail
                      : ''
                  }
                  onChange={handleChange('contactInfo.supportEmail')}
                />
              </Grid>
            </Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Organization Contact*</Typography>
                <SbTooltip placement="right" title={`This email will be used to send user onboarding invitation`}>
                  <InfoRounded />
                </SbTooltip>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={12} md={11}>
                <SbTextField
                  placeholder="Enter Contact number"
                  onChange={handleChange('contactInfo.contactNumber')}
                  type={'number'}
                  // required
                  value={values.contactInfo.contactNumber}
                  // error={errors.phoneNumber}
                  name="contactInfo.contactNumber"
                  startAdornment={
                    <FormControl style={{ width: '30%' }}>
                      <SelectOptions
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.contactInfo.telephoneCode}
                        name="contactInfo.telephoneCode"
                        renderValue={p => <>{`+${p}`}</>}
                        onChange={handleChange}
                      >
                        {countryCodeList?.map(({ display, value }: DropdownOptionType) => (
                          <MenuItem key={value} value={value}>
                            {display}
                          </MenuItem>
                        ))}
                      </SelectOptions>
                    </FormControl>
                  }
                  error={
                    (errors?.contactInfo?.contactNumber && touched?.contactInfo?.contactNumber) ||
                    (errors?.contactInfo?.telephoneCode && touched?.contactInfo?.telephoneCode)
                      ? errors?.contactInfo?.contactNumber || errors?.contactInfo?.telephoneCode
                      : ''
                  }
                />
              </Grid>
            </Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="start">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Organization address</Typography>
                <SbTooltip placement="right" title={`This address will be visible on your dashboard & Log in Page`}>
                  <InfoRounded />
                </SbTooltip>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} lg={8} rowSpacing={3}>
              <Grid container item xs={12} md={12} lg={12} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={12} md={11.5}>
                  <GoogleMaps
                    onSelect={handleSelectedAddress}
                    label={'Location'}
                    placeholder="Enter location"
                    // reinitialize={reinitialize}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={12} md={11}>
                  <SbTextField
                    placeholder="Enter Line 1"
                    required
                    label="Address line 1"
                    value={values.contactInfo.address.line1}
                    onChange={handleChange('contactInfo.address.line1')}
                    name="contactInfo.address.line1"
                    error={
                      errors?.contactInfo?.address?.line1 && touched?.contactInfo?.address?.line1
                        ? errors?.contactInfo?.address.line1
                        : ''
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={12} md={11}>
                  <SbTextField
                    placeholder="Enter line 2"
                    label="Address line 2"
                    value={values.contactInfo.address.line2}
                    onChange={handleChange('contactInfo.address.line2')}
                    name="contactInfo.address.line2"
                    error={
                      errors?.contactInfo?.address?.line2 && touched?.contactInfo?.address?.line2
                        ? errors?.contactInfo?.address.line2
                        : ''
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={12} md={11}>
                  <SbTextField
                    required
                    placeholder="Enter City"
                    label="City"
                    value={values.contactInfo.address.city}
                    onChange={handleChange('contactInfo.address.city')}
                    name="contactInfo.address.city"
                    error={
                      errors?.contactInfo?.address?.city && touched?.contactInfo?.address?.city
                        ? errors?.contactInfo?.address.city
                        : ''
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={12} md={11}>
                  <SbTextField
                    required
                    placeholder="Enter State"
                    label="State"
                    value={values.contactInfo.address.state}
                    onChange={handleChange('contactInfo.address.state')}
                    name="contactInfo.address.state"
                    error={
                      errors?.contactInfo?.address?.state && touched?.contactInfo?.address?.state
                        ? errors?.contactInfo?.address.state
                        : ''
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={12} md={11}>
                  <SBDropdown
                    id="company-country-id"
                    label={'Country'}
                    required
                    name="contactInfo.address.country"
                    placeholder="Select Country"
                    options={countryList}
                    value={values.contactInfo.address.country ? [values.contactInfo.address.country] : []}
                    onChange={([value]) => formik.setFieldValue('contactInfo.address.country', value)}
                    error={
                      errors?.contactInfo?.address?.country && touched?.contactInfo?.address?.country
                        ? errors?.contactInfo?.address.country
                        : ''
                    }
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6} lg={6} alignItems="center" justifyContent={{ xs: 'space-between' }}>
                <Grid item xs={12} md={11}>
                  <SbTextField
                    required
                    placeholder="Enter Pincode"
                    label="Pincode"
                    value={values.contactInfo.address.pincode}
                    onChange={handleChange('contactInfo.address.pincode')}
                    name="contactInfo.address.pincode"
                    error={
                      errors?.contactInfo?.address?.pincode && touched?.contactInfo?.address?.pincode
                        ? errors?.contactInfo?.address.pincode
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="start">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Tax Information</Typography>
                <SbTooltip placement="right" title={`Add your company tax information like GSTIN & PAN`}>
                  <InfoRounded />
                </SbTooltip>
              </Grid>
            </Grid>
            <Grid container item xs={12} lg={8} xl={9} rowSpacing={3}>
              {values.taxInformation.map((tax, ind) => (
                <React.Fragment key={ind}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={5.35}
                    lg={5.35}
                    alignItems="center"
                    justifyContent={{ xs: 'space-between' }}
                  >
                    <Grid item xs={12} md={11}>
                      <CircleIcon sx={{ mr: 2, fontSize: '8px' }} />
                      {tax.name}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={5.35}
                    lg={5.35}
                    alignItems="center"
                    justifyContent={{ xs: 'space-between' }}
                  >
                    <Grid item xs={12} md={11}>
                      <CircleIcon sx={{ mr: 2, fontSize: '8px' }} /> {tax.value}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
              <Grid
                container
                item
                xs={12}
                md={5.35}
                lg={5.35}
                alignItems="center"
                justifyContent={{ xs: 'space-between' }}
              >
                <Grid item xs={12} md={11}>
                  <SbTextField
                    placeholder="PAN"
                    label="Tax name"
                    value={values.taxName}
                    onChange={handleChange}
                    name="taxName"
                    error={errors.taxName}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={5.35}
                lg={5.35}
                alignItems="center"
                justifyContent={{ xs: 'space-between' }}
              >
                <Grid item xs={12} md={11}>
                  <SbTextField
                    placeholder="ABCD12345678"
                    label="Tax number"
                    value={values.taxNumber}
                    onChange={handleChange}
                    name="taxNumber"
                    error={errors.taxNumber}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={1} alignItems="end" justifyContent={{ xs: 'space-between' }}>
                <ButtonContained
                  variant="contained"
                  onClick={handleAddTax}
                  disabled={!values.taxName || !values.taxNumber}
                >
                  Add
                </ButtonContained>
              </Grid>
            </Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">First user*</Typography>
                <SbTooltip placement="right" title={`The first user - Admin on the Speedbox Dashboard`}>
                  <InfoRounded />
                </SbTooltip>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              lg={4}
              gap={2}
              alignItems="center"
              justifyContent={{ xs: 'space-between' }}
            >
              {/* <Grid item xs={12} md={11}>
                <SbTextField
                  name="username"
                  value={values.username}
                  label="User Name"
                  placeholder="Enter username"
                  error={errors.username && touched.username ? errors.username : ''}
                  onChange={handleChange}
                />
              </Grid> */}
              <Grid item xs={12} md={11}>
                <SbTextField
                  name="email"
                  label="Email"
                  required
                  value={values.email}
                  placeholder="Enter email"
                  error={errors.email && touched.email ? errors.email : ''}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={11}>
                <SbTextField
                  label="Password"
                  name="password"
                  value={values.password}
                  placeholder="Enter password"
                  error={errors.password && touched.password ? errors.password : ''}
                  onChange={handleChange}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              lg={4}
              alignItems="center"
              justifyContent={{ xs: 'space-between' }}
            ></Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Website link</Typography>
                <SbTooltip placement="right" title={`Website link of the organization if any`}>
                  <InfoRounded />
                </SbTooltip>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={12} md={11}>
                <SbTextField
                  name="websiteLink"
                  value={values.websiteLink}
                  startAdornment={<LanguageIcon sx={{ mr: 1 }} />}
                  placeholder="Enter Website Link"
                  onChange={handleChange}
                  error={errors.websiteLink && touched.websiteLink ? errors.websiteLink : ''}
                />
              </Grid>
            </Grid>
          </ContainerStyle>

          <ContainerStyle container alignItems="center">
            <Grid item xs={12} lg={4} xl={3}>
              <SBLabel label="Select Currency" tooltipText="Select currency for the organization" />
            </Grid>
            <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={12} md={11}>
                <Select
                  sx={{ width: '100%' }}
                  name="currency"
                  value={values.currency || ''}
                  displayEmpty
                  onChange={handleChange}
                >
                  <MenuItem value="" disabled>
                    Select a currency
                  </MenuItem>
                  {countries.map((item, ind) => (
                    <MenuItem key={ind} value={item.currency.name}>
                      {item.currency.name} ({item.currency.symbol})
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="start">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Add logo*</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} lg={8} xl={9} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={12} md={11}>
                <DragDrop
                  accept="image/*"
                  onChange={onFileChange}
                  subText={
                    values.logo && typeof values.logo === 'object'
                      ? `${values.logo.fileName} (${values.logo.fileSize})`
                      : 'Supported formats: JPEG, PNG (450x225px, File upto 5 mb)'
                  }
                  readType="base64"
                />
                {errors.logo && touched.logo && (
                  <FormHelperText error className="m-0">
                    Logo is required
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </ContainerStyle>
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} lg={4} xl={3}>
              <Grid container alignItems={'center'} className="title">
                <Typography className="font-medium md-text mr-1">Theme color</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} lg={8} xl={9} alignItems="center" justifyContent={{ xs: 'space-between' }}>
              <Grid item xs={12} md={11}>
                <ColorPicker
                  sx={{ mr: { md: 3, xs: 0 }, mb: { md: 0, xs: 3 } }}
                  value={primaryColor}
                  setValue={setPrimary}
                />
                <ColorPicker value={secondaryColor} setValue={secondaryPrimary} />
              </Grid>
            </Grid>
          </ContainerStyle>
        </Wrapper>
        <Grid container justifyContent={'center'}>
          {/* <ButtonOutlined variant="outlined" type="submit" className="mr-1" onClick={() => handleSubmit()}>
            Save changes
          </ButtonOutlined> */}
          <ButtonContained variant="contained" onClick={() => handleSubmit()}>
            Submit
          </ButtonContained>
        </Grid>
      </Grid>
      <ErrorModal
        icon={'/images/Boxes/WarningContrast.svg'}
        open={isError || false}
        title={'Oops!!'}
        subTitle={errorDetails || msg}
        onClose={ohandleErrorModalClose}
        onConfirm={ohandleErrorModalClose}
        btnText="Go back"
      />
    </Container>
  );
}

function SBLabel({ label, tooltipText, required }: { label: string; tooltipText?: string; required?: boolean }) {
  return (
    <Grid container alignItems={'center'} className="title">
      <FormLabel required={required} className="font-medium md-text mr-1">
        {label}
      </FormLabel>
      {tooltipText && (
        <SbTooltip placement="right" title={tooltipText}>
          <InfoRounded />
        </SbTooltip>
      )}
    </Grid>
  );
}

export default OrganizationForm;
