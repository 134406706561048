import updateBoxId from '@Services/updateBoxId';
import { Box, Grid, Typography, alpha } from '@mui/material';
import { styled } from '@mui/system';
import { ValidationResult } from 'joi';
import React, { useEffect, useState } from 'react';
import SbButton from '../../../../components/Button';
import Divider from '../../../../components/Divider';
import SbTextField from '../../../../components/Textfield';
import CreateShipmentContext from '../../../../context/CreateShipment';
import {
  AddProductType,
  BoxType,
  ProductErrorType,
  ProductType,
  createShipmentContextType,
} from '../../../../types/shipment';
import { defaultBoxState, defaultProductErrorState, defaultProductState } from '../../constants';
import BoxDimensions from '../BoxDimensions';
import ProductTable from '../ProductTable';
import { productDescriptionSchema } from './schema';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { SHIPMENT_TYPES } from '../../../GetRatesV2/Constant';
import { getChargeableWeight } from '@Reducers/GetRates/actions';
import { AddressType } from '@Reducers/GetRates/Type';
import { setMaximumCharegableWeight } from '@Reducers/GetRates';

const Container = styled(Box)(({ theme }) => ({
  padding: '20px 12px',
  borderRadius: '8px',
  background: alpha(theme.palette.primary.light, 1),
}));

const Wrapper = styled(Box)(({ theme }) => ({
  padding: '20px',
  border: '1px solid var(--mui-palette-grey-300)',
  borderRadius: '8px',
  background: alpha(theme.palette.background.white, 1),
}));

interface ProductDescriptionProps {
  writeAccess?: boolean;
}

function getAddressObj({ pincode, city, country, state }: AddressType) {
  return { city, state, country, pincode };
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({ writeAccess }): JSX.Element => {
  const {
    selectedBox,
    setSelectedBox,
    boxes,
    setBoxes,
    selectedProduct,
    setSelectedProduct,
    shipmentType,
    consignee,
    shipper,
    setChargeableWeight,
    pickupType: { selectedWearhouseAddress },
  } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const [productDetails, setProductDetails] = useState<AddProductType>({ ...defaultProductState });
  const [errors, setErrors] = useState<ProductErrorType>({ ...defaultProductErrorState });

  const rates = useSelector((state: RootStateType) => state.getRates);
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);

  const { id, description, quantity, unitValue, HSNName, HSNCode } = productDetails;
  const writePermission = writeAccess ?? true;

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (selectedProduct) {
      setProductDetails({ ...selectedProduct });
    } else {
      setProductDetails({ ...defaultProductState });
    }
  }, [selectedProduct]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    validateProperty(event);
    const name = event.target.name as keyof AddProductType;
    const { value } = event.target;
    const productDataTemp = { ...productDetails };
    if (name === 'description' || name === 'HSNCode' || name === 'HSNName') {
      productDataTemp[name] = value;
    } else if (name === 'quantity') {
      productDataTemp[name] = parseInt(value);
    } else if (name === 'unitValue') {
      productDataTemp[name] = value;
    }
    setProductDetails({ ...productDataTemp });
  }

  function finalCharegableWTByID() {
    if (shipmentById?.shipmentNumber) {
      const obj = {
        destination: { ...getAddressObj(shipmentById?._consigneeId) },
        // source: { ...getAddressObj(selectedWearhouseAddress ? selectedWearhouseAddress : shipper) },
        source: { ...getAddressObj(shipmentById?._shipperId) },
        package: {
          box: shipmentById?._packageId?.box?.map(box => ({
            weight: box.weight.toString(),
            length: Number(box.length),
            width: Number(box.width),
            height: Number(box.height),
          })),
          unitOfMeasurement: shipmentType.unit,
        },
        document: shipmentType.type === SHIPMENT_TYPES[1].value,
        _courierId: shipmentById?.sellingPricing?._courierId,
        shipmentEdit: true,
        inbound: shipmentById?.sellingPricing?.inbound,
        outbound: shipmentById?.sellingPricing?.outbound,
        shipmentNumber: shipmentById?.shipmentNumber,
      };
      dispatch(getChargeableWeight({ ...obj }));
    }
  }

  function updateFinalChargableWeight() {
    if (shipmentById?.shipmentNumber) {
      const obj = {
        destination: { ...getAddressObj(shipmentById?._consigneeId) },
        // source: { ...getAddressObj(selectedWearhouseAddress ? selectedWearhouseAddress : shipper) },
        source: { ...getAddressObj(shipmentById?._shipperId) },
        package: {
          box: boxes.map(box => ({
            weight: box.weight.toString(),
            length: Number(box.length),
            width: Number(box.width),
            height: Number(box.height),
          })),
          unitOfMeasurement: shipmentType.unit,
        },
        document: shipmentType.type === SHIPMENT_TYPES[1].value,
        _courierId: shipmentById?.sellingPricing?._courierId,
        shipmentEdit: true,
        inbound: shipmentById?.sellingPricing?.inbound,
        outbound: shipmentById?.sellingPricing?.outbound,
      };
      dispatch(getChargeableWeight({ ...obj }));
    } else {
      const obj = {
        destination: { ...getAddressObj(consignee) },
        source: { ...getAddressObj(selectedWearhouseAddress ? selectedWearhouseAddress : shipper) },
        package: {
          box: boxes.map(box => ({
            weight: box.weight.toString(),
            length: Number(box.length),
            width: Number(box.width),
            height: Number(box.height),
          })),
          unitOfMeasurement: shipmentType.unit,
        },
        document: shipmentType.type === SHIPMENT_TYPES[1].value,
      };
      dispatch(getChargeableWeight({ ...obj }));
    }

    const updateChargeableWeight = rates?.chareableWeight?.maxChargeableWt;

    if (updateChargeableWeight) {
      setChargeableWeight(updateChargeableWeight);
    }
  }

  useEffect(() => {
    if (shipmentById?.sellingPricing?.chargeableWeight) {
      finalCharegableWTByID();
      // setChargeableWeight(shipmentById?.sellingPricing?.chargeableWeight);
      dispatch(setMaximumCharegableWeight(shipmentById?.sellingPricing?.chargeableWeight));
    }
  }, [shipmentById?.shipmentNumber, shipmentById?._packageId?.box]);

  // useEffect(() => {
  //   // console.info('test:-->');
  //   dispatch(resetChareableWeight());
  // }, []);

  function addProduct() {
    const boxesTemp = structuredClone(boxes);
    const productsTemp = structuredClone(selectedBox.products);
    const boxIndex = boxesTemp.findIndex(box => box.id === selectedBox.id);
    const productInd = id ? productsTemp.findIndex(product => product.id === id) : -1;

    // set product index
    const _id = productInd > -1 ? productInd.toString() : boxesTemp[boxIndex].products.length.toString();
    console.log({ boxIndex, productInd, _id });

    // productsTemp[productInd] = { ...productDetails };
    if (boxesTemp[boxIndex].products.length && productInd > -1) {
      // update selected index
      boxesTemp[boxIndex].products[productInd] = {
        ...productDetails,
        id: _id,
        unitValue:
          typeof productDetails.unitValue === 'string' ? Number(productDetails.unitValue) : productDetails.unitValue,
      };
    } else {
      // add new product
      boxesTemp[boxIndex].products = [...boxesTemp[boxIndex].products, { ...productDetails, id: _id }].map(x => ({
        ...x,
        unitValue: typeof x.unitValue === 'string' ? Number(x.unitValue) : x.unitValue,
      }));
    }

    setBoxes([...boxesTemp]);
    setSelectedBox({
      ...selectedBox,
      products: [...boxesTemp[boxIndex].products],
    });
    // setSelectedProduct(null);
    // setSelectedProduct({ ...defaultProductState });
    setSelectedProduct({ ...defaultProductState, id: `${boxesTemp[boxIndex].products.length}` });

    updateFinalChargableWeight();
  }

  function updateProduct(): void {
    // Ensure selectedBox and products are defined
    if (!selectedBox || !selectedBox.products) {
      return;
    }

    // Cast unitValue to number if it's a string
    const updatedProductDetails = {
      ...productDetails,
      unitValue:
        typeof productDetails.unitValue === 'string' ? Number(productDetails.unitValue) : productDetails.unitValue,
    };

    // Find the index of the item with matching id in the products array
    const indexToUpdate = selectedBox.products.findIndex(item => item._id === updatedProductDetails._id);

    // If a matching item is found, update it with the data
    if (indexToUpdate !== -1) {
      // Create a new array with the updated item
      const updatedProducts = [
        ...selectedBox.products.slice(0, indexToUpdate), // Items before the updated item
        updatedProductDetails, // Updated item
        ...selectedBox.products.slice(indexToUpdate + 1), // Items after the updated item
      ];

      // Assign the updated array back to selectedBox
      selectedBox.products = updatedProducts;

      const updateBoxes = boxes?.map(box => (box.id === selectedBox.id ? selectedBox : box));
      setBoxes(updateBoxes);

      setSelectedBox({
        ...selectedBox,
        products: updatedProducts,
      });
      setSelectedProduct({ ...defaultProductState });
      // setSelectedProduct({ ...selectedProduct });
    } else {
      // If no matching item is found, simply push the data to the products array
      // selectedBox.products.push(updatedProductDetails);

      setSelectedBox({
        ...selectedBox,
        products: selectedBox.products,
      });
      // setSelectedProduct({ ...defaultProductState });
    }
    updateFinalChargableWeight();
  }

  function cancelProduct() {
    const boxesTemp = Array.from(boxes);
    const boxIndex = boxesTemp.findIndex(box => box.id === selectedBox.id);
    setBoxes([...boxesTemp]);
    setSelectedBox({
      ...selectedBox,
      products: [...boxesTemp[boxIndex].products],
    });
    setSelectedProduct(null);
  }

  function validateProductData() {
    const _productDetails = { ...productDetails };
    delete _productDetails.isUpdate;
    const result: ValidationResult = productDescriptionSchema.validate(_productDetails, {
      abortEarly: false,
      stripUnknown: true,
    });
    const { error } = result;
    if (error) {
      const errorData: ProductErrorType = {};
      for (const item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name as keyof ProductErrorType] = message;
      }
      setErrors(errorData);
      return;
    }

    if (productDetails.isUpdate) {
      updateProduct();
    } else {
      addProduct();
    }
  }

  const validateProperty = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const obj = { [name]: value };
    const result = productDescriptionSchema.validate(obj);
    const { error } = result;
    const errorData = { ...errors };
    const errorMessage = error && error.details[0].context?.key === name ? error.details[0].message : null;
    if (errorMessage && name in defaultProductErrorState) {
      errorData[name as keyof ProductErrorType] = errorMessage;
    } else {
      errorData[name as keyof ProductErrorType] = '';
    }
    setErrors(errorData);
  };

  function calculateTotalValue() {
    if (quantity && unitValue)
      setProductDetails({
        ...productDetails,
        totalValue: quantity * (typeof unitValue === 'string' ? Number(unitValue) : unitValue),
      });
  }

  function handleEditProduct(data: ProductType) {
    setSelectedProduct({ ...data, isUpdate: true });
  }

  function handleDeleteProduct(ind: number) {
    let productsTemp = structuredClone(selectedBox.products);
    productsTemp.splice(ind, 1);
    const boxesTemp = structuredClone(boxes);
    const boxInd = boxesTemp.findIndex(box => box.id === selectedBox.id);
    productsTemp = productsTemp.map((p, ind) => ({ ...p, id: ind.toString() }));
    boxesTemp[boxInd].products = productsTemp;
    setSelectedBox({ ...selectedBox, products: productsTemp });

    const arr = updateBoxId<BoxType>(boxesTemp, defaultBoxState, 0);
    setBoxes([...arr]);
  }
  useEffect(() => {
    const isShipperValid = shipper.city && shipper.country && shipper.pincode && shipper.state;
    const isConsigneeValid = consignee.city && consignee.country && consignee.pincode && consignee.state;
    if (boxes.length >= 1 && isShipperValid && isConsigneeValid) {
      updateFinalChargableWeight();
    }
  }, [boxes.length]);
  return (
    <Container>
      <Wrapper>
        <Typography className="md-text mb-1" fontWeight={'600'}>
          Box Dimensions
        </Typography>
        <BoxDimensions writePermission={writePermission} />
        <Divider className="my-4" />
        <Box className="px-2 py-0 rounded">
          <Typography className="md-text mb-2" fontWeight={'600'}>
            Add product
          </Typography>
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item md={12} xs={12}>
              <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item md={4} xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={1}>
                    <Grid item md={12} xs={12}>
                      <SbTextField
                        required
                        multiline
                        rows={5}
                        placeholder="Enter Description"
                        label="Description"
                        onChange={handleChange}
                        name="description"
                        value={description}
                        error={errors.description}
                        style={{ height: '100%' }}
                        disabled={!writePermission}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={8} xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={1.5}>
                    <Grid item md={6} xs={12}>
                      <SbTextField
                        placeholder="Enter Quantity"
                        label="Quantity"
                        type="integer"
                        onChange={handleChange}
                        name="quantity"
                        value={quantity ? JSON.stringify(quantity) : ''}
                        onBlur={calculateTotalValue}
                        required
                        error={errors.quantity}
                        disabled={!writePermission}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <SbTextField
                        placeholder={`Unit value in ${shipmentType.currency}`}
                        label="Unit Value"
                        onChange={handleChange}
                        name="unitValue"
                        value={unitValue ? unitValue : ''}
                        onBlur={calculateTotalValue}
                        type="number"
                        required
                        error={errors.unitValue}
                        disabled={!writePermission}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <SbTextField
                        placeholder="Enter HSN Name"
                        label="HSN Name"
                        onChange={handleChange}
                        name="HSNName"
                        value={HSNName}
                        error={errors.HSNName}
                        disabled={!writePermission}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <SbTextField
                        placeholder="Enter HSN Code"
                        label="HSN Code"
                        onChange={handleChange}
                        name="HSNCode"
                        value={HSNCode}
                        error={errors.HSNCode}
                        disabled={!writePermission}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} columnSpacing={1} justifyContent={'end'} className="mt-1">
                  {productDetails.isUpdate && (
                    <Grid item>
                      <SbButton fullWidth variant="outlined" onClick={cancelProduct}>
                        Cancel
                      </SbButton>
                    </Grid>
                  )}
                  <Grid item>
                    <SbButton fullWidth variant="contained" onClick={validateProductData} disabled={!writePermission}>
                      {productDetails.isUpdate ? 'Update' : 'Add'}
                    </SbButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {selectedBox?.products?.length ? (
          <>
            <Divider className="my-4" />
            <ProductTable
              title={'Product Description'}
              enableActions
              products={selectedBox.products}
              handleEdit={handleEditProduct}
              handleDelete={handleDeleteProduct}
              selectedProduct={selectedProduct}
            />
          </>
        ) : (
          <></>
        )}
      </Wrapper>
    </Container>
  );
};

export default ProductDescription;
