import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import API from '../../../services/apiAxios';
import { DeleteRatesParams, RatePayloadResponse, RatesQuery, knownError } from './Type';
import { RootStateType } from '@Store';

interface queryParams extends Omit<RatesQuery, 'pageType'> {
  system: string[];
  csp: string[];
  rateType: string[];
  pageType: string | null;
}

const getRates = createAsyncThunk<RatePayloadResponse, RatesQuery, { rejectValue: knownError }>(
  'getRateList',
  async (params: RatesQuery, { rejectWithValue }) => {
    try {
      const queryParams: queryParams = Object.assign({}, params);
      if (params.pageType === 'base' || params.pageType === 'tariff') {
        if (params.pageType === 'tariff') queryParams.pageType = 'tariff';
        else queryParams.pageType = 'base'; // backend manages accountNumber based on page type
        params.accountNumber = 'default';
      } else queryParams.pageType = 'custom';

      queryParams.system = params.system || [];
      queryParams.csp = params.csp || [];
      queryParams.rateType = params.rateType || [];

      const res = await API.get(`/shipment/rate/${params.accountNumber}?`, { params: queryParams });

      return { queryParams: { ...params }, data: res.data };
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error: AxiosError<knownError> = e as any;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const exportRates = createAsyncThunk('ShipmentExport', async (params: RatesQuery) => {
  const queryParams: queryParams = Object.assign({}, params);
  if (params.pageType === 'base' || params.pageType === 'tariff') {
    if (params.pageType === 'tariff') queryParams.pageType = 'tariff';
    else queryParams.pageType = 'base'; // backend manages accountNumber based on page type
    params.accountNumber = 'default';
  } else queryParams.pageType = 'custom';

  const res = await API.get(`shipment/export/ratelist/${params.accountNumber}?`, { params: queryParams });
  console.info(res.data);
  // window.open(res.data.data, '_blank');
  return {};
});

const getRatesSheet = createAsyncThunk<RatePayloadResponse, RatesQuery, { rejectValue: knownError }>(
  'getRatesSheet',
  async (params: RatesQuery, { rejectWithValue }) => {
    try {
      const queryParams: queryParams = Object.assign({}, params);
      if (params.pageType === 'base' || params.pageType === 'tariff') {
        if (params.pageType === 'tariff') queryParams.pageType = 'tariff';
        else queryParams.pageType = 'base'; // backend manages accountNumber based on page type
        params.accountNumber = 'default';
      } else queryParams.pageType = 'custom';
      const res = await API.get(`/shipment/rate/${params.accountNumber}?`, { params: queryParams });
      return { queryParams: { ...params }, data: res.data };
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error: AxiosError<knownError> = e as any;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

type deleteResponse = { msg: string };
const deleteRates = createAsyncThunk<deleteResponse, DeleteRatesParams, { rejectValue: knownError }>(
  'deleteRates',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const res = (await API.delete(`/shipment/rate/${params.accountNumber}?`, { data: params })) as deleteResponse;
      const state = getState() as RootStateType;
      const RateSummaryConfig = state.RateSummary.config;
      dispatch(
        getRates({
          accountNumber: RateSummaryConfig.selectedAccountNumber,
          isImport: RateSummaryConfig.isImport,
          navigatedDate: RateSummaryConfig.selectedDate,
          pageType: RateSummaryConfig.pageType,
          system: RateSummaryConfig.system,
          csp: RateSummaryConfig.csp,
          rateType: RateSummaryConfig.rateType,
        }),
      );
      return res;
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error: AxiosError<knownError> = e as any;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

export { getRates, deleteRates, getRatesSheet, exportRates };
