// import DownloadButton from './DownlaodButton';
import FilterSelect from '@Components/Filter/Dropdown';
import { RootStateType } from '@Store';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import CustomTabs from './CustomTabs';
import TopTenViewTable from './TopTenViewTable';
import DateRangePicker from '@Components/DateRangePicker';
import API from '@Services/apiAxios';
import { ApiResponseCustomer, Customer, Destination, Carrier } from './types/type';
import TopTenDestinationsTable from './TopTenDestinationsTable';
import TopTenCarriersTable from './TopTenCarriersTable';

export interface Shipment {
  id: string;
  status: string;
  account: string;
}

const TopTenView = () => {
  const [system, setSystem] = useState<string[]>([]);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [customerShipmentSort, setCustomerShipmentSort] = useState<boolean>(true);
  const [customerWeightSort, setCustomerWeightSort] = useState<boolean>(false);
  const [customerRevenueSort, setCustomerRevenueSort] = useState<boolean>(false);
  const [destinationShipmentSort, setDestinationShipmentSort] = useState<boolean>(true);
  const [destinationWeightSort, setDestinationWeightSort] = useState<boolean>(false);
  const [destinationRevenueSort, setDestinationRevenueSort] = useState<boolean>(false);
  const [carrierShipmentSort, setCarrierShipmentSort] = useState<boolean>(true);
  const [carrierWeightSort, setCarrierWeightSort] = useState<boolean>(false);
  const [carrierRevenueSort, setCarrierRevenueSort] = useState<boolean>(false);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
  };

  const fetchDataForCustomers = async () => {
    try {
      let sortBy = '';
      if (customerShipmentSort) {
        sortBy = 'shipments';
      } else if (customerWeightSort) {
        sortBy = 'weight';
      } else if (customerRevenueSort) {
        sortBy = 'revenue';
      }

      const resp = await API.get<ApiResponseCustomer>('/analytics/shipment/topten/customers', {
        params: {
          'system[]': system,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          sortBy: sortBy || 'shipments',
        },
      });
      setCustomers(resp.data.data);
    } catch (error) {
      console.error('Error in fetching data for customers', error);
    }
  };
  const fetchDataForDestinations = async () => {
    try {
      let sortBy = '';
      if (destinationShipmentSort) {
        sortBy = 'shipments';
      } else if (destinationWeightSort) {
        sortBy = 'weight';
      } else if (destinationRevenueSort) {
        sortBy = 'revenue';
      }

      const resp = await API.get('/analytics/shipment/topten/destinations', {
        params: {
          'system[]': system,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          sortBy: sortBy || 'shipments',
        },
      });
      setDestinations(resp.data.data);
    } catch (error) {
      console.error('Error in fetching data for customers', error);
    }
  };
  const fetchDataForCarriers = async () => {
    try {
      let sortBy = '';
      if (carrierShipmentSort) {
        sortBy = 'shipments';
      } else if (carrierWeightSort) {
        sortBy = 'weight';
      } else if (carrierRevenueSort) {
        sortBy = 'revenue';
      }

      const resp = await API.get('/analytics/shipment/topten/carriers', {
        params: {
          'system[]': system,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          sortBy: sortBy || 'shipments',
        },
      });
      setCarriers(resp.data.data);
    } catch (error) {
      console.error('Error in fetching data for customers', error);
    }
  };

  useEffect(() => {
    fetchDataForCustomers();
  }, [system, dateRange.endDate, dateRange.startDate, customerShipmentSort, customerWeightSort, customerRevenueSort]);
  useEffect(() => {
    fetchDataForDestinations();
  }, [
    system,
    dateRange.endDate,
    dateRange.startDate,
    destinationShipmentSort,
    destinationWeightSort,
    destinationRevenueSort,
  ]);
  useEffect(() => {
    fetchDataForCarriers();
  }, [system, dateRange.endDate, dateRange.startDate, carrierShipmentSort, carrierWeightSort, carrierRevenueSort]);

  const tabLabels = ['Customers', 'Destinations', 'Carriers'];

  const tabContent = {
    Customers: (
      <TopTenViewTable
        data={customers}
        setCustomerShipmentSort={setCustomerShipmentSort}
        setCustomerWeightSort={setCustomerWeightSort}
        setCustomerRevenueSort={setCustomerRevenueSort}
        customerShipmentSort={customerShipmentSort}
        customerWeightSort={customerWeightSort}
        customerRevenueSort={customerRevenueSort}
      />
    ),
    Destinations: (
      <TopTenDestinationsTable
        data={destinations}
        setDestinationShipmentSort={setDestinationShipmentSort}
        setDestinationWeightSort={setDestinationWeightSort}
        setDestinationRevenueSort={setDestinationRevenueSort}
        destinationShipmentSort={destinationShipmentSort}
        destinationWeightSort={destinationWeightSort}
        destinationRevenueSort={destinationRevenueSort}
      />
    ),
    Carriers: (
      <TopTenCarriersTable
        data={carriers}
        setCarrierWeightSort={setCarrierWeightSort}
        setCarrierShipmentSort={setCarrierShipmentSort}
        setCarrierRevenueSort={setCarrierRevenueSort}
        carrierShipmentSort={carrierShipmentSort}
        carrierWeightSort={carrierWeightSort}
        carrierRevenueSort={carrierRevenueSort}
      />
    ),
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
  };
  return (
    <Box
      sx={{
        borderRadius: '9px',
        border: '1px solid #F1F1F1',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 40px',
      }}
    >
      <Box sx={{ color: 'rgba(24, 29, 37, 1)', fontSize: '24px', fontWeight: 500, lineHeight: '32px' }}>Top Ten's</Box>

      <Box sx={{}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row-reverse' },
            gap: '10px',
            height: 'fit-content',
            position: 'relative',
            top: '45px',
          }}
        >
          {/* <Box>
            <DownloadButton
              name={'Download'}
              onClick={() => {
                console.info('click');
              }}
            />
          </Box> */}
          <Box>
            <DateRangePicker onChange={onDateChange} value={dateRange} />
          </Box>
          <Box>
            <FilterSelect
              id="system"
              label="Select system"
              options={loggedInUserSystemDropdownList}
              value={system}
              onChange={onSystemFilter}
            />
          </Box>
        </Box>
        <Box sx={{ marginTop: { xs: '70px', sm: '0px' }, marginBottom: { xs: '70px', sm: '0px' } }}>
          <CustomTabs tabs={tabLabels} tabContent={tabContent} />
        </Box>
      </Box>
    </Box>
  );
};

export default TopTenView;
