import Title from '@Components/Title';
import { Grid } from '@mui/material';
import React from 'react';

import PaymentCompletedDetails from './PaymentCompletedDetails';
import PaymentOptionSelections from './PaymentOptionSelections';

function ShipmentPayment() {
  return (
    <>
      <Grid container mb={2}>
        <Title title="Payment" removeBorder removePadding />
      </Grid>
      <Grid container className="p-4 mb-3 border border-solid border-light rounded">
        <Grid container item columnSpacing={12} rowSpacing={5}>
          <PaymentOptionSelections />
          <PaymentCompletedDetails />
        </Grid>
      </Grid>
    </>
  );
}

export default ShipmentPayment;
