import React, { useMemo, useState } from 'react';

import { Grid, Typography, useTheme, FormHelperText } from '@mui/material';
import { styled } from '@mui/system';
import Divider from '../../../components/Divider';
import SbButton from '../../../components/Button';

import DragDrop from '../../../components/File/DragDrop';
import { ODAType } from './type';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues } from 'formik';

import { FileResultType } from '../../../Helper/file';
import Image from '../../../components/Image';
import DatePicker from '../../../components/DatePicker';
import Dropdown from '../../../components/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';
import { DropdownOptionType } from '../../../components/Dropdown/Type';
import dayjs from 'dayjs';
import { readXslAndGetObject } from '../helper';
import ErrorModal from '../../../components/Modal/error';
import { resetOdaError, resetOdaRedirect } from '../../../store/reducers/ODA';
import { useNavigate } from 'react-router-dom';

interface CreateFormProps {
  showBackgroundColor: boolean;
  formType?: 'create' | 'edit';
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<ODAType>['setFieldValue'];
  errors?: FormikErrors<ODAType>;
}

export const initCreate: ODAType = {
  _serviceProviderId: '',
  startDate: '',
  endDate: '',
  charges: [],
};

interface FileErrorType {
  fileError: string;
  chargesError?: FormikErrors<ODAType['charges']> | string | string[];
}

function FileError({ fileError, chargesError }: FileErrorType) {
  let showError = '';
  if (fileError) showError = fileError;
  else if (Array.isArray(chargesError)) {
    showError = chargesError
      ?.map?.(pincode => (typeof pincode === 'string' ? pincode : pincode?.pincode))
      .filter(x => x)
      .join(', ');
  } else if (chargesError) showError = chargesError;
  if (!showError.trim()) return <></>;
  return (
    <FormHelperText error className="mt-1">
      {showError}
    </FormHelperText>
  );
}

function CreateForm(props: CreateFormProps) {
  const { formType, values, errors, setFieldValue } = props;
  const isEdit = formType === 'edit';
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [fileError, setFileError] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const { serviceList } = useSelector((state: RootStateType) => state.carrierAndService);
  const OdaState = useSelector((state: RootStateType) => state.oda);

  const dropDownOption = useMemo(() => {
    if (!serviceList) return [];
    const arr: Array<DropdownOptionType> = serviceList.map(x => ({ display: x.name, value: x._id }));

    return arr;
  }, [serviceList]);

  const onFileChange = (data: FileResultType[]) => {
    if (fileError) {
      setFileError('');
      setFileName('');
    }
    if (data?.[0]?.fileName) setFileName(data[0].fileName);
    try {
      if (!data.length) throw { msg: 'Please upload pincode list.' };
      const deliveryCharges = readXslAndGetObject(data);
      setFieldValue('charges', deliveryCharges);
    } catch (error) {
      if (error?.msg) setFileError(error.msg);
    }
  };

  const onDownloadSampleSheet = () => {
    window.open(
      'https://s3.ap-south-1.amazonaws.com/speedbox-static-file-2.0/Sample%2BODA%2BPincode+(1).xlsx',
      '_blank',
    );
  };

  const onClose = () => {
    dispatch(resetOdaError());
    if (OdaState.isRedirect) {
      dispatch(resetOdaRedirect());
      navigate(`/oda`);
    }
  };
  const isPastDate = isEdit ? dayjs().isAfter(values.endDate) : false;
  return (
    <>
      <ErrorModal
        icon="/images/Boxes/NoRatesFound.svg"
        open={!!OdaState.error?.isError}
        title="Oops!"
        subTitle={OdaState.error?.msg}
        onClose={onClose}
        onConfirm={onClose}
        btnText="Close"
      />
      <Grid container>
        <Grid item xs={12}>
          <Typography className="org-text underline">
            {(formType === 'edit' && 'Edit') || (formType === 'create' && 'Add')} ODA Charges
          </Typography>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">Service*</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <FormContent>
              {/* <SbTextField required name="name" value={values.name} onChange={handleChange} error={errors?.name} /> */}
              <Dropdown
                id="oda-service-list"
                value={[values._serviceProviderId]}
                onChange={([value]) => {
                  setFieldValue('_serviceProviderId', value);
                }}
                options={dropDownOption}
                error={errors?._serviceProviderId}
                placeholder="Select Service"
                disabled={isEdit || isPastDate}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">Start Date *</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <FormContent sx={{ backgroundColor: theme.palette.background.white }}>
              {/* <SbTextField name="email" value={values.email} onChange={handleChange} error={errors?.email} /> */}
              <DatePicker
                error={errors?.startDate}
                value={values.startDate}
                handleChange={date => {
                  if (values.endDate && date.isAfter(values.endDate)) {
                    setFieldValue('endDate', date?.toString() || '');
                  }
                  setFieldValue('startDate', date?.toString() || '');
                }}
                disabled={isEdit}
                minDate={dayjs()}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>
        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
            <FormLabel className="md-text font-medium">End Date *</FormLabel>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <FormContent sx={{ backgroundColor: theme.palette.background.white }}>
              {/* <SbTextField name="email" value={values.email} onChange={handleChange} error={errors?.email} /> */}
              <DatePicker
                error={errors?.endDate}
                value={values.endDate}
                handleChange={date => {
                  if (values.endDate && values.startDate && dayjs(values.startDate).isAfter(date)) return;
                  setFieldValue('endDate', date?.toString() || '');
                }}
                minDate={values.startDate ? values.startDate : dayjs()}
                disabled={isPastDate}
              />
            </FormContent>
          </Grid>
          <Divider className="mt-3 mb-3" />
        </Grid>

        {/* ----------------------------------------------------------------------------------------------------------------------------------------- */}

        {!isPastDate && (
          <Grid item container xs={12} alignItems="start">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel className="md-text font-medium">ODA File *</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent className="w-full">
                <DragDrop
                  readType="xls"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  subText={fileName ? fileName : 'only xls and xlsx file allowed'}
                  onChange={onFileChange}
                />
                <FileError fileError={fileError} chargesError={errors?.charges} />

                <SbButton
                  variant="text"
                  className="mt-1"
                  onClick={onDownloadSampleSheet}
                  startIcon={
                    <Image
                      src={'/images/upload_document.svg'}
                      alt="Download"
                      width={35}
                      height={35}
                      style={{ transform: 'rotate(180deg)' }}
                    />
                  }
                >
                  Download Sample File
                </SbButton>
              </FormContent>
            </Grid>
            <Divider className="mt-3 mb-3" />
          </Grid>
        )}
      </Grid>
    </>
  );
}

const FormLabel = styled(Typography)`
  min-width: 280px;
`;

const FormContent = styled('div')`
  max-width: 512px;
`;

export default CreateForm;
