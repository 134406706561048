import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { styled } from '@mui/system';
import {
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  IconButton,
  Stack,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import CloseIcon from '@mui/icons-material/Close';

import Loader from '@Components/Loader';
import FilterSearch from '@Components/Filter/Search';
// import FilterSelect from '@Components/Filter/Dropdown';
import SbButton from '@Components/Button';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
// import ModalError from '@Components/Modal/error';
import FilterSelect from '@Components/Filter/Dropdown';
import DateRangePicker from '@Components/DateRangePicker';
import Modal from '@Components/Modal';
import DeleteModal from '@Components/Modal/Delete';

import { AppDispatch, RootStateType } from '@Store';
import { getSystemList } from '@Reducers/Systems';
import { fetchNonAccountInvoiceList, resetInvoiceState } from '@Reducers/InvoiceList';
import { voidInvoice, downloadInvoiceDoc } from '@Reducers/Invoice';
import { FilterObj } from '../Type';
import { INVOICE_ROUTE_TYPE_DROPDOWN_LIST, SHIPMENT_ROUTE_TYPE_DROPDOWN_LIST } from '@Constants/Shipment';
import { Status } from '../Status';
import PaymentModal from '../Payment';
import { HighlightOffRounded } from '@mui/icons-material';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { resetInvoiceNumber } from '@Reducers/AdminShipment';
import { InvoiceListQuery } from '@Reducers/Invoice/Type';
import { formatNumber, useLocale } from '../../../utils/formatNumber';
const TableActionContainer = styled('div')(({ theme }) => ({
  margin: '2rem 0',
  display: 'grid',
  gridTemplateColumns: '380px 1fr  fit-content(150px) fit-content(150px) fit-content(150px) fit-content(150px) 150px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    columnGap: '8px',
    gridTemplateColumns: '380px 1fr  fit-content(150px) fit-content(150px) fit-content(150px) fit-content(150px) 120px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

const SearchFilterList = [
  { label: 'Invoice No.', value: 'invoiceNumber' },
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
];

const StatusList = [
  { display: 'Paid', value: 'paid' },
  { display: 'Unpaid', value: 'unpaid' },
  { display: 'Void', value: 'void' },
];

const initialDateRange = {
  startDate: moment().subtract(3, 'months').startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
};
const options = [
  { value: 'invoice', label: 'Invoice' },
  { value: 'annexure', label: 'Annexure' },
];
interface DateRange {
  startDate: string;
  endDate: string;
}

interface FilteredOptionsComponentProps {
  setNonAccountDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
  setNonAccountSystem: React.Dispatch<React.SetStateAction<string[]>>;
  setNonAccountType: React.Dispatch<React.SetStateAction<string[]>>;
  setNonAccountStatus: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function AccountHolders({
  setNonAccountDateRange,
  setNonAccountSystem,
  setNonAccountType,
  setNonAccountStatus,
}: FilteredOptionsComponentProps) {
  const { isAccountHolder, invoiceNumber } = useSelector((state: RootStateType) => state.adminShipment);
  const [filterSelectValue, setFilterSelectValue] = useState('invoiceNumber');
  const [filterText, setFilterText] = useState('');
  const [system, setSystem] = useState<string[]>([]);
  const [invoiceType, setInvoiceType] = useState<string[]>([]);
  const [shipmentType, setShipmentType] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [isClear, setClear] = useState(false);

  // const [selectedShipment, setSelectedShipment] = useState<string[]>([]);

  const [dateRange, setDateRange] = useState(initialDateRange);

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: RootStateType) => state.System?.loading);

  const {
    nonAccountData: { currentPage = 1, perPage, totalCount = 0 },
    loading: invoiceLoding,
  } = useSelector((state: RootStateType) => state.InvoiceList);

  const { loading: downloading } = useSelector((state: RootStateType) => state.Invoice);

  const SystemList = useSelector((state: RootStateType) => state.System.data.selectedList).map(system => ({
    display: system.name,
    value: system._id,
  }));
  const _systems = useSelector((state: RootStateType) => state.loginUser?.profile?._systems);

  const activeSystemList = useMemo(() => {
    if (_systems?.length) return SystemList.filter(system => _systems.includes(system.value));
    else return [];
  }, [SystemList, _systems]);

  useEffect(() => {
    dispatch(getSystemList());
    dispatch(fetchNonAccountInvoiceList(getFilterObj({})));

    return () => {
      dispatch(resetInvoiceState());
    };
  }, []);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      setNonAccountDateRange({
        startDate: moment(dateRange.startDate).format('DD/MM/YYYY'),
        endDate: moment(dateRange.endDate).format('DD/MM/YYYY'),
      });
    }
    if (system) {
      setNonAccountSystem([...system]);
    }
    if (status) {
      setNonAccountStatus([...status]);
    }
    if (invoiceType) {
      setNonAccountType([...invoiceType]);
    }
  }, [dateRange.startDate, dateRange.endDate, system, status, invoiceType, shipmentType]);

  const getFilterObj = (options: FilterObj) => {
    const filterDate = options.dateRange || dateRange;
    const filterSystem = options.system || system;
    const filterTypeInvoice = options.invoiceType || invoiceType;
    const filterTypeShipment = options.shipmentType || shipmentType;
    const filterStatus = options.status || status;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;

    const filterQuery: InvoiceListQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      systems: filterSystem,
      invoice_type: filterTypeInvoice,
      type: filterTypeShipment,
      status: filterStatus,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };

    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }

    return filterQuery;

    // return {
    //   currentPage: options.currentPage || currentPage,
    //   perPage: options.perPage || perPage,
    //   fromDate,
    //   toDate,
    //   system: filterSystem,
    //   shipmentType: filterType,
    //   status: filterStatus,
    //   searchKey: filterSearchKey,
    //   searchValue: filterSearchValue,
    // };
  };

  useEffect(() => {
    if (!isAccountHolder && invoiceNumber) {
      setFilterText(`${invoiceNumber}`);
      dispatch(fetchNonAccountInvoiceList(getFilterObj({ searchValue: invoiceNumber?.toString() })));
    } else {
      dispatch(fetchNonAccountInvoiceList(getFilterObj({ searchValue: '' })));
    }
    return () => {
      setClear(false);
    };
  }, [isClear]);

  const onClear = () => {
    setSystem([]);
    setInvoiceType([]);
    setShipmentType([]);
    setStatus([]);
    setFilterText('');
    setDateRange(initialDateRange);
    setClear(true);
  };

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(fetchNonAccountInvoiceList(getFilterObj({ dateRange })));
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    dispatch(fetchNonAccountInvoiceList(getFilterObj({ system: selected })));
  };

  const onInvoiceTypeFilter = (selected: Array<string>) => {
    setInvoiceType(selected);
    dispatch(fetchNonAccountInvoiceList(getFilterObj({ invoiceType: selected })));
  };
  const onShipmentTypeFilter = (selected: Array<string>) => {
    setShipmentType(selected);
    dispatch(fetchNonAccountInvoiceList(getFilterObj({ shipmentType: selected })));
  };

  const onStatusFilter = (selected: Array<string>) => {
    setStatus(selected);
    dispatch(fetchNonAccountInvoiceList(getFilterObj({ status: selected })));
  };

  return (
    <>
      <Loader loading={loading || invoiceLoding || downloading} />
      <TableActionContainer>
        <FilterSearch
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={filterText}
          onTextChange={e => {
            setFilterText(e.target.value);
          }}
          onTextSearch={() => {
            dispatch(
              fetchNonAccountInvoiceList(
                getFilterObj({
                  currentPage: 1,
                  perPage: 30,
                  searchKey: filterSelectValue,
                  searchValue: filterText || undefined,
                }),
              ),
            );
          }}
          onTextClear={() => {
            setFilterText('');
            dispatch(
              fetchNonAccountInvoiceList(
                getFilterObj({
                  currentPage: 1,
                  perPage: 30,
                  searchValue: undefined,
                }),
              ),
            );
            dispatch(resetInvoiceNumber());
          }}
        />
        {/* <div></div> */}
        <DateRangePicker onChange={onDateChange} value={dateRange} />
        <FilterSelect id="system" label="System" options={activeSystemList} value={system} onChange={onSystemFilter} />
        <FilterSelect
          id="type"
          label="Invoice Type"
          options={INVOICE_ROUTE_TYPE_DROPDOWN_LIST}
          value={invoiceType}
          onChange={onInvoiceTypeFilter}
        />
        <FilterSelect
          id="type"
          label="Shipment Type"
          options={SHIPMENT_ROUTE_TYPE_DROPDOWN_LIST}
          value={shipmentType}
          onChange={onShipmentTypeFilter}
        />
        <FilterSelect id="status" label="Status" options={StatusList} value={status} onChange={onStatusFilter} />
        <SbButton
          className="xs-text ml-1"
          variant="outlined"
          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
          onClick={onClear}
        >
          Clear Filters
        </SbButton>
      </TableActionContainer>

      <NonAccountHolderInvoiceTable
        handlePaymentSuccess={() => {
          dispatch(fetchNonAccountInvoiceList(getFilterObj({})));
        }}
      />

      {!!totalCount && !!perPage && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage - 1}
            rowsPerPage={perPage}
            onPageChange={(_, page) => {
              dispatch(fetchNonAccountInvoiceList(getFilterObj({ currentPage: page + 1 })));
            }}
            onRowsPerPageChange={event => {
              dispatch(fetchNonAccountInvoiceList(getFilterObj({ perPage: parseInt(event.target.value) })));
            }}
          />
        </>
      )}
    </>
  );
}

// const invList = [
//   {
//     _id: 1,
//     _user: { name: 'Pinak Deshpande', address: 'Mumbai - 400101' },
//     invoiceNo: '12345',
//     invoiceType: 'invoice',
//     refInvoiceno: '',
//     billDate: moment().format('DD/MM/YY'),
//     dueDate: moment().format('DD/MM/YY'),
//     invoiceAmount: '1000',
//     amountPaid: '0',
//     deductions: '',
//     pending: '1000',
//     status: 'unpaid',
//   },
// ];

function NonAccountHolderInvoiceTable({ handlePaymentSuccess }: { handlePaymentSuccess: () => void }) {
  const {
    nonAccountData: { list },
  } = useSelector((state: RootStateType) => state.InvoiceList);

  const dispatch = useDispatch<AppDispatch>();
  const [showCommentsModal, setShowCommentsModal] = useState<{ open: boolean; invId?: string }>({ open: false });
  const [showConfirmationModal, setShowConfirmationModal] = useState<{
    open: boolean;
    handleConfirm?: () => void;
    msg?: string;
  }>({
    open: false,
  });
  const locale = useLocale();

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isVoidInvoiceAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.View_Invoices_Non_Account_holder.void_invoices,
    PERFORM_ACTION.write,
  );
  const isDownloadInvoiceAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.View_Invoices_Non_Account_holder.download_invoices,
    PERFORM_ACTION.write,
  );
  const isPaymentAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.View_Invoices_Non_Account_holder.upload_payment,
    PERFORM_ACTION.write,
  );

  const handleConfirm = () => {
    showConfirmationModal?.handleConfirm?.();
    setShowConfirmationModal({ open: false });
  };

  const deleteSelected = (_id: string) => {
    setShowConfirmationModal({
      open: true,
      msg: 'Do you want to void this invoice?',
      handleConfirm: () => {
        dispatch(voidInvoice({ _id }));
      },
    });
  };

  const selectedComment = list.find(item => item._id === showCommentsModal.invId);
  let comments;
  if (selectedComment) {
    comments = selectedComment?.comments;
  }
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDocId, setInvoiceDocId] = useState(false);
  const [exportAnchor, setExportAnchor] = useState(null);
  const handleExportButtonClick = (event: any) => {
    setExportAnchor(event.currentTarget);
  };
  // Handle closing the menu
  const closeExportMenu = () => {
    setExportAnchor(null);
  };
  return (
    <>
      <DeleteModal
        icon="/images/Boxes/DeleteContrast.svg"
        open={!!showConfirmationModal.open}
        title="Are You Sure?"
        subTitle={showConfirmationModal.msg}
        onClose={() => setShowConfirmationModal({ open: false })}
        onConfirm={handleConfirm}
      />
      <Modal open={showCommentsModal.open} onClose={() => setShowCommentsModal({ invId: '', open: false })}>
        <Grid container style={{ minWidth: 430 }}>
          <Grid item container justifyContent={'flex-end'}>
            <IconButton
              onClick={() => {
                setShowCommentsModal({ open: false, invId: '' });
              }}
            >
              <HighlightOffRounded
                sx={{
                  fontSize: '2rem',
                  color: 'rgba(0,0,0,0.7)',
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12} padding={2}>
            <Typography className="md-text font-medium my-3">Comments</Typography>
            <Stack gap={2} maxHeight={400} overflow={'auto'} id="fadedScroll" pr={1}>
              {comments?.map((c, i) => (
                <div
                  key={i}
                  className="rounded"
                  style={{ background: '#F1F1F1', padding: '20px', paddingBottom: '5px' }}
                >
                  <Typography>
                    {selectedComment?._accountRefUserId?.name} <span>{moment(c?.createdAt).format('HH:MM A')}</span>
                  </Typography>
                  <Typography>
                    {moment(c.createdAt).format('DD/MM/YYYY')} - Paid {selectedComment?.currency?.name} {c.paidAmount}
                  </Typography>
                  <Typography>Comment: {c.comment}</Typography>
                </div>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Modal>

      <SBTableWrapper>
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Invoice No.</TableHeadCell>
              <TableHeadCell>Ref. Invoice No.</TableHeadCell>
              <TableHeadCell>Bill Date</TableHeadCell>
              <TableHeadCell>Due Date</TableHeadCell>
              <TableHeadCell>Invoice Amount</TableHeadCell>
              <TableHeadCell>Amount Paid</TableHeadCell>
              <TableHeadCell>Pending</TableHeadCell>
              <TableHeadCell>Status</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length ? (
              list.map(inv => (
                <SBTableRow key={inv._id}>
                  <TableRowCell className="md-text">
                    <p>{inv._accountRefUserId?.name}</p>
                    <p>{inv._accountRefUserId?.email}</p>
                    <Typography color={'#667085'} className="capitalize">
                      {inv._systemId?.name}
                    </Typography>
                  </TableRowCell>
                  <TableRowCell className="md-text" sx={{ display: 'block', width: '190px', border: 'none' }}>
                    <p>{inv.invoiceNo}</p>
                    <Typography color={'#667085'} className="capitalize">
                      {inv.invoiceType === 'debit'
                        ? 'Debit Note'
                        : inv.invoiceType === 'credit'
                        ? 'Credit Note'
                        : inv.invoiceType}
                    </Typography>
                  </TableRowCell>

                  <TableRowCell
                    className="md-text"
                    align="center"
                    sx={{
                      div: {
                        width: '180px',
                      },
                    }}
                  >
                    {inv.refInvoiceno || '-'}
                  </TableRowCell>
                  <TableRowCell className="md-text" align="center">
                    {moment(inv.billDate ? inv.billDate : inv.invoicingDate).format('DD/MM/YYYY')}
                  </TableRowCell>
                  <TableRowCell className="md-text">{moment(inv.dueDate).format('DD/MM/YYYY')}</TableRowCell>
                  <TableRowCell className="md-text">{formatNumber(inv.invoiceAmount, locale)}</TableRowCell>
                  <TableRowCell className="md-text">{formatNumber(inv.paidAmount, locale)}</TableRowCell>
                  <TableRowCell className="md-text">{formatNumber(inv.dueAmount, locale)}</TableRowCell>
                  <TableRowCell className="md-text">{inv.status ? <Status status={inv.status} /> : ''}</TableRowCell>
                  <TableRowCell
                    className="md-text"
                    sx={{ display: 'block', width: '350px', border: 'none !important' }}
                  >
                    <Grid container columnSpacing={2}>
                      {isPaymentAllowed && (
                        <Grid item md={3}>
                          <PaymentModal invoiceObj={inv} handleSuccess={handlePaymentSuccess} isAccountHolder={false} />
                        </Grid>
                      )}
                      <Grid item md={3}>
                        <SbButton
                          variant="outlined"
                          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                          onClick={e => {
                            setInvoiceNumber(inv.invoiceNo);
                            setInvoiceDocId(inv.docId);
                            handleExportButtonClick(e);
                          }}
                        >
                          <DownloadIcon />
                        </SbButton>
                        <Menu
                          open={Boolean(exportAnchor)}
                          anchorEl={exportAnchor}
                          onClose={closeExportMenu}
                          keepMounted
                          PaperProps={{
                            sx: {
                              width: '150px',
                              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1)',
                            },
                          }}
                        >
                          {options.map(option => {
                            const isInvoice = option.value === 'invoice';
                            const showTooltip = !(isDownloadInvoiceAllowed && invoiceDocId) && isInvoice;

                            return (
                              <Tooltip
                                key={option.value}
                                title={showTooltip ? 'Invoice is still being processed. Please try again later.' : ''}
                                disableHoverListener={!showTooltip}
                                arrow
                              >
                                <span>
                                  <MenuItem
                                    onClick={() => {
                                      if (isInvoice) {
                                        if (showTooltip) {
                                          return;
                                        }
                                        dispatch(downloadInvoiceDoc({ invoiceNo: invoiceNumber, annexure: false }));
                                      } else {
                                        dispatch(downloadInvoiceDoc({ invoiceNo: invoiceNumber, annexure: true }));
                                      }
                                      closeExportMenu();
                                    }}
                                    disabled={showTooltip}
                                  >
                                    {option.label}
                                  </MenuItem>
                                </span>
                              </Tooltip>
                            );
                          })}
                        </Menu>
                      </Grid>
                      <Grid item md={3}>
                        <SbButton
                          variant="contained"
                          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                          onClick={() => {
                            setShowCommentsModal({ open: true, invId: inv._id });
                          }}
                        >
                          <ModeCommentOutlinedIcon />
                        </SbButton>
                      </Grid>
                      <Grid item md={3}>
                        <SbButton
                          variant="contained"
                          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                          onClick={() => {
                            deleteSelected(inv._id);
                          }}
                          disabled={!isVoidInvoiceAllowed}
                        >
                          <CloseIcon />
                        </SbButton>
                      </Grid>
                    </Grid>
                  </TableRowCell>
                </SBTableRow>
              ))
            ) : (
              <SBTableRow>
                <TableRowCell colSpan={11}>
                  <Typography className="sb-text-gray font-medium text-center">No Data Found.</Typography>
                </TableRowCell>
              </SBTableRow>
            )}
          </TableBody>
        </SBTable>
      </SBTableWrapper>
    </>
  );
}
