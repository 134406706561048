import { createSlice } from '@reduxjs/toolkit';
import { pending, reject, rateUploadFulfilled, getSingleRateObjFulfilled } from './case';
import { UploadRateState } from './Type';
import { UploadZone, UpdateZone, UploadMarkup, UpdateMarkup, GetSingleRateObj } from './action';

const initialState = <UploadRateState>{
  loading: false,
  rateSuccessfullyUpload: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  isActiveRate: false,
  data: {},
  singleRate: null,
};

const RateUpload = createSlice({
  name: 'ratesUpload',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetRateUploadState: (state: UploadRateState) => {
      state.rateSuccessfullyUpload = false;
      state.copyErrorList = [];
    },
    resetError: function (state) {
      state.error = {
        msg: '',
        isError: false,
        errorDetails: '',
        collidingMarkup: [],
        collidingZone: [],
      };
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(UploadZone.pending, pending);
    addCase(UploadZone.fulfilled, rateUploadFulfilled);
    addCase(UploadZone.rejected, reject);

    addCase(UpdateZone.pending, pending);
    addCase(UpdateZone.fulfilled, rateUploadFulfilled);
    addCase(UpdateZone.rejected, reject);

    addCase(UploadMarkup.pending, pending);
    addCase(UploadMarkup.fulfilled, rateUploadFulfilled);
    addCase(UploadMarkup.rejected, reject);

    addCase(UpdateMarkup.pending, pending);
    addCase(UpdateMarkup.fulfilled, rateUploadFulfilled);
    addCase(UpdateMarkup.rejected, reject);

    addCase(GetSingleRateObj.pending, pending);
    addCase(GetSingleRateObj.fulfilled, getSingleRateObjFulfilled);
    addCase(GetSingleRateObj.rejected, reject);
  },
});

export { GetSingleRateObj, UploadZone, UpdateZone, UploadMarkup, UpdateMarkup };
export const { resetRateUploadState, resetError } = RateUpload.actions;
export default RateUpload.reducer;
