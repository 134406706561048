import { DropdownOptionType } from '@Components/Dropdown/Type';
import { CSP_TYPES } from '@Constants/Shipment';
import { DHLInformationType } from '@Reducers/AdminShipment/Type';
import { postDHLDetails, postDhlSendOtp } from '@Reducers/AdminShipment/actions';
import { AppDispatch } from '@Store';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const initDHLInformation: DHLInformationType = {
  _id: '',
  csp: CSP_TYPES.dhlIndia.value,
  accountNo: '530993773',
  countryOfManufacture: '',
  GSTINNumber: '',
  paymentMode: '',
  ShipperType: '',
  type: '',
  GSTINType: '',
  ShipmentType: '',
  dutyPaymentMode: '',
  gstInvNo: '',
  gstInvNoDate: undefined,
};

export const onForward = ({
  values,
  shipmentNumber,
  dispatch,
  nextFunc,
  handleOtpModalOpen,
}: {
  values: DHLInformationType;
  shipmentNumber: string;
  dispatch: AppDispatch;
  nextFunc: () => void;
  handleOtpModalOpen: () => void;
}) => {
  const body = { ...JSON.parse(JSON.stringify(values)) };

  //\\ Remove unnecessary properties based on conditions //\\
  for (const key in body) {
    if (body[key]) {
      if (key === 'accountNo') {
        body[key] = body[key]?.split('-')?.[0];
      }
      if (JSON.stringify(body[key]) === '{}' || JSON.stringify(body[key]) === '[]') {
        delete body[key];
      }
    } else {
      delete body[key];
    }
  }
  //\\ Remove unnecessary properties based on conditions \\//
  if (!('documentId' in body)) {
    body.documentId = '';
  }

  if (values.ShipperType === 'INDL') {
    dispatch(
      postDhlSendOtp({
        shipmentNumber: `${shipmentNumber}`,
        body,
      }),
    ).then((val: { type: string }) => {
      if (val.type === 'postDhlSendOtp/fulfilled') {
        handleOtpModalOpen();
      }
    });
  } else {
    dispatch(
      postDHLDetails({
        shipmentNumber: `${shipmentNumber}`,
        body,
      }),
    ).then((val: { type: string }) => {
      if (val.type === 'postDhlDetails/fulfilled') {
        nextFunc();
      }
    });
  }
};

export const Payor: DropdownOptionType[] = [
  { value: 'S', display: 'Sender' },
  { value: 'R', display: 'Recipient' },
];

export const GSTType: DropdownOptionType[] = [
  { value: 'GSTIN', display: 'GSTIN' },
  { value: 'PAN', display: 'PAN' },
  { value: 'AADHAR', display: 'AADHAR' },
];

export const ShipperType: DropdownOptionType[] = [
  { value: 'MSME', display: 'MSME' },
  { value: 'INDL', display: 'INDL' },
];
export const ShipmentType: DropdownOptionType[] = [
  { value: 'CSBIV', display: 'CSBIV' },
  { value: 'CARGO', display: 'CARGO' },
];
export const DutyPaymentMode: DropdownOptionType[] = [
  { value: 'DD', display: 'DDP – Delivered Duty Paid' },
  { value: 'DS', display: 'DDU – Delivered Duty Unpaid' },
];
