import API from '@Services/apiAxios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ExportState {
  url: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: ExportState = {
  url: null,
  loading: false,
  error: null,
};

export const fetchExportUrl = createAsyncThunk(
  'export/fetchExportUrl',
  async (params: { id: string | null; filters: Record<string, any> }, thunkAPI) => {
    try {
      const { id, filters } = params;
      const response = await API.get(`payment/credit/download-all/${id}`, { params: filters });
      return response.data.data.url;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.msg || 'Failed to fetch the export URL.');
    }
  },
);

const exportSlice = createSlice({
  name: 'postpaidUserReport',
  initialState,
  reducers: {
    resetPostpaidExportState(state) {
      state.url = initialState.url;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchExportUrl.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchExportUrl.fulfilled, (state, action: PayloadAction<string>) => {
        state.url = action.payload;
        state.loading = false;
      })
      .addCase(fetchExportUrl.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export const { resetPostpaidExportState } = exportSlice.actions;

export default exportSlice.reducer;
