import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorType, ResendVerificationRes, SignupRes, signupStateForm, verifyEmailRes } from './type';

function resetSignupState(state: signupStateForm) {
  state.loading = true;
  state.error = null;
  state.user = null;
  state.verifyEmail = null;
  state.isOtp = false;
  state.resendVerification = null;
}

function doSignupPending(state: signupStateForm) {
  state.loading = true;
  state.error = null;
  state.verifyEmail = null;
  state.isOtp = false;
  state.resendVerification = null;
}
function verifySignupEmailPending(state: signupStateForm) {
  state.loading = true;
  state.error = null;
}

function resendVerificationPending(state: signupStateForm) {
  state.loading = true;
  state.error = null;
  state.isOtp = false;
  state.resendVerification = null;
  state.verifyEmail = null;
}

function doSignupFulfilled(state: signupStateForm, action: PayloadAction<SignupRes>) {
  const { payload } = action;

  state.loading = false;
  state.error = null;
  state.user = payload;
  state.isOtp = true;
}

function verifySignupEmailFulfilled(state: signupStateForm, action: PayloadAction<verifyEmailRes>) {
  const { payload } = action;

  state.loading = false;
  state.error = null;
  state.verifyEmail = payload;
}

function resendVerificationFulfilled(state: signupStateForm, action: PayloadAction<ResendVerificationRes>) {
  const { payload } = action;

  state.loading = false;
  state.error = null;
  state.resendVerification = payload;
  state.isOtp = true;
}

// function doSignupRejected(state: signupStateForm, action: PayloadAction<ErrorType | undefined>) {
function doSignupRejected(state: signupStateForm) {
  // const { payload } = action;

  // console.log({ payload });
  state.loading = false;
  state.user = null;
  state.verifyEmail = null;
  // state.error = { msg: payload?.msg || 'error', isError: true };
}
function resendVerificationRejected(state: signupStateForm, action: PayloadAction<ErrorType | undefined>) {
  const { payload } = action;
  state.loading = false;
  state.resendVerification = null;
  state.error = { msg: payload?.msg || 'error', isError: true };
}

function verifySignupEmailRejected(state: signupStateForm, action: PayloadAction<ErrorType | undefined>) {
  const { payload } = action;
  // console.log({ payload });
  state.loading = false;
  state.verifyEmail = null;
  state.error = { msg: payload?.msg || 'error', isError: true };
}

export {
  doSignupPending,
  doSignupFulfilled,
  doSignupRejected,
  verifySignupEmailPending,
  verifySignupEmailFulfilled,
  verifySignupEmailRejected,
  resendVerificationPending,
  resendVerificationFulfilled,
  resendVerificationRejected,
  resetSignupState,
};
