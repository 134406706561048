import CancelAndTrackShipment from '@Components/CancelAndTrackShipment';
import Title from '@Components/Title';
import { getAdminShipmentById, getAdminShipmentError, updateShipment } from '@Reducers/AdminShipment/actions';
import { resetShipmentError } from '@Reducers/Shipment';
import { getSingleShipmentPackagePayload } from '@Services/getShipmentPayload';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CreateShipmentContext from '../../../context/CreateShipment';
import CreateShipmentWrapper from '../../../context/CreateShipment/Provider';
import { LengthType, createShipmentContextType } from '../../../types/shipment';
import Actions from '../components/Actions';
import Overview from '../components/Overview';
import ShipmentError from '../components/ShipmentError';
import ShipmentDetails from './ShipmentDetails';
import ShipperConsignee from './ShipperConsignee';
import TabsList from './Tabs';
import { FormikValues } from 'formik';
import { UpdateShipmentPayload } from '@Reducers/AdminShipment/Type';
import { UserType } from '@Reducers/AdminShipment/Type';
import SuccessError from './SuccessError';
import { setError } from '@Reducers/AdminShipment';
import Loader from '@Components/Loader';
import { getShipmentSettings } from '@Reducers/OrganizationSetup';
import PickupDropoff from '../components/PickupDropoff';
import BackButton from '@Components/BackButton';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

function UnprocessedShipment() {
  const dispatch = useDispatch<AppDispatch>();
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const { sn: shipmentNumber } = useParams();

  const loading = useSelector(
    (state: RootStateType) =>
      state.adminShipment.loading || state.Warehouse.loading || state.getRates.loading || state.shipment.loading,
  );

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isUnprocessedShipmentEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Draft_Shipments.unprocessed_shipments,
    PERFORM_ACTION.write,
  );

  const shipperFormikRef = useRef<FormikValues>(null);
  const consigneeFormikRef = useRef<FormikValues>(null);
  useEffect(() => {
    if (shipmentNumber) {
      dispatch(getShipmentSettings());
      dispatch(getAdminShipmentById(shipmentNumber));
      dispatch(getAdminShipmentError(shipmentNumber));
    }
    () => {
      dispatch(resetShipmentError());
    };
  }, [shipmentNumber]);

  const {
    boxes,
    packageData,
    shipmentType,
    pickupType,
    boxValidation,
    extraDetails: packageExtradetails,
  } = useContext(CreateShipmentContext) as createShipmentContextType;

  const getShipmentDetailsObj = () => {
    const packageObj = getSingleShipmentPackagePayload({
      boxes,
      packageData,
      shipmentType: { ...shipmentType, unit: packageData.unitOfMeasurement as LengthType },
      pickupType,
    });

    return { details: packageObj, extraDetails: packageExtradetails };
  };

  const getShipperConsigee = (values: FormikValues) => {
    return {
      values,
      extraDetails: { type: values.type, IdType: values?.IdType || '', IdNumber: values?.IdNumber || '' },
    };
  };

  const onSave = () => {
    const { details: packageObj, extraDetails: packageExtradetails } = getShipmentDetailsObj();

    if (shipperFormikRef.current?.errors && Object.keys(shipperFormikRef.current?.errors).length) {
      return;
    }
    console.log('SHIPPER ERRORS ', shipperFormikRef.current);
    // const { details: shipper, extraDetails: shipperExtraDetail } = shipperFormikRef.current?.onSubmit();
    // const { details: consignee, extraDetails: consigneeExtraDetail } = consigneeFormikRef.current?.onSubmit();

    if (shipmentById?.shipmentNumber && boxValidation) {
      const payload: UpdateShipmentPayload = {
        shipmentNumber: shipmentById.shipmentNumber,
        package: packageObj,
        shipper: getShipperConsigee(shipperFormikRef.current?.values).values as UserType,
        consignee: getShipperConsigee(consigneeFormikRef.current?.values).values as UserType,
        extraDetails: {
          shipper: getShipperConsigee(shipperFormikRef.current?.values).extraDetails,
          consignee: getShipperConsigee(consigneeFormikRef.current?.values).extraDetails,
          other: packageExtradetails,
        },
      };
      console.log({ payload });
      dispatch(updateShipment(payload));
    } else {
      dispatch(setError({ errorDetails: 'Invali data', msg: 'Please add valid shipment/Box details', isError: false }));
    }
  };

  return (
    <Loader loading={loading} overly>
      <>
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          <BackButton link="/shipment/draft" />
          <Title title="Unprocessed Shipments " removeBorder />
        </Box>
        <SuccessError />
        <Overview EditPermissionAllowed={isUnprocessedShipmentEditAllowed} />
        <Grid container justifyContent={'space-between'}>
          <ShipmentError sellingChargeMsgShow={true} />

          {isUnprocessedShipmentEditAllowed && shipmentById?.status && (
            <CancelAndTrackShipment shipmentStatus={shipmentById.status} />
          )}
        </Grid>
        <TabsList />
        <ShipperConsignee shipperFormikRef={shipperFormikRef} consigneeFormikRef={consigneeFormikRef} />
        <PickupDropoff />

        <ShipmentDetails />
        <Actions saveButtonName="Place Order" onSave={onSave} disabled={!isUnprocessedShipmentEditAllowed} />
      </>
    </Loader>
  );
}

// export default UnprocessedShipment;

function ContextWrapper() {
  return (
    <CreateShipmentWrapper>
      <UnprocessedShipment />
    </CreateShipmentWrapper>
  );
}

export default ContextWrapper;
