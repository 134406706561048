// import DownloadButton from './DownlaodButton';
import FilterSelect from '@Components/Filter/Dropdown';
import Image from '@Components/Image';
import { RootStateType, AppDispatch } from '@Store';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import CustomTabs from './CustomTabs';
import BarChart from './ BarChart';
import API from '@Services/apiAxios';
import { getLoggedInUserSystemList } from '../../store/reducers/Systems';
import { StatsResponse } from './types/type';
import MixedChart from './MIxedChart';
import MixedChartShipment from './MixedChartShipment';
import { formatNumber, useLocale } from '../../utils/formatNumber';

const generateMonthOptions = (): DropdownOptionType[] => {
  const currentMonth = moment().month(); // Current month as a 0-based index
  const currentYear = moment().year();
  const options: DropdownOptionType[] = [];

  for (let i = 0; i <= currentMonth; i++) {
    const date = moment().month(i).year(currentYear);
    options.push({
      value: date.format('MM'),
      display: date.format('MMMM YYYY'), // Full month name with year
    });
  }

  return options;
};

const monthOptions = generateMonthOptions();

const GeneralOverview = () => {
  const [system, setSystem] = useState<string[]>([]);
  const locale = useLocale();
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const [selectedValues, setSelectedValues] = useState<string[]>([moment().format('MM')]);
  const dispatch = useDispatch<AppDispatch>();
  const [stats, setStats] = useState<StatsResponse | null>(null);

  const tabLabels = ['Revenue', 'Weight', 'Shipment'];

  const getMonthlyStats = async () => {
    try {
      const response = await API.get(`/analytics/stats/monthly`, {
        params: { month: selectedValues, 'system[]': system },
      });
      setStats(response.data.data);
    } catch (error) {
      console.error('Error fetching monthly stats:', error);
      throw error;
    }
  };
  useEffect(() => {
    dispatch(getLoggedInUserSystemList());
  }, []);

  useEffect(() => {
    getMonthlyStats();
  }, [selectedValues, system]);

  const tabContent = {
    Type: (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: 'rgba(31, 30, 97, 1)',
            gap: '17px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : `/images/icons/AdminHome/downArrow.svg`
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.shipmentPercentage &&
                    formatNumber(stats?.currentMonth?.shipmentPercentage, locale)}
                  %
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalShipment && formatNumber(stats?.currentMonth?.totalShipment, locale)}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Shipment</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/weight.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.weightPercentage && formatNumber(stats?.currentMonth?.weightPercentage, locale)}
                  %
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalWeight && formatNumber(stats?.currentMonth?.totalWeight, locale)}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Weight (KG)</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/revenue.png"
              alt="revenue"
            />

            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.revenuePercentage &&
                    formatNumber(stats?.currentMonth?.revenuePercentage, locale)}
                  %
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalRevenue && formatNumber(stats?.currentMonth?.totalRevenue, locale)}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Revenue (INR)</p>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <BarChart data={stats?.graphData} />
        </Box>
      </Box>
    ),
    Weight: (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: 'rgba(31, 30, 97, 1)',
            gap: '17px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : `/images/icons/AdminHome/downArrow.svg`
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.shipmentPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalShipment}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Shipment</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/weight.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.weightPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalWeight}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Weight (KG)</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/revenue.png"
              alt="revenue"
            />

            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.revenuePercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalRevenue}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Revenue (INR)</p>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <MixedChart data={stats?.graphData} />
        </Box>
      </Box>
    ),
    Shipment: (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: 'rgba(31, 30, 97, 1)',
            gap: '17px',
            flexWrap: 'wrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : `/images/icons/AdminHome/downArrow.svg`
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.shipmentPercentage && stats?.currentMonth?.shipmentPercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.shipmentPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalShipment}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Shipment</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/weight.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.weightPercentage && stats?.currentMonth?.weightPercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.weightPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalWeight}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Weight (KG)</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
              minWidth: '250px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/revenue.png"
              alt="revenue"
            />

            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage >= 0
                      ? 'rgba(206, 253, 222, 1)'
                      : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.currentMonth?.revenuePercentage && stats?.currentMonth?.revenuePercentage > 0
                        ? 'rgba(3, 160, 28, 1)'
                        : '#A00303',
                  }}
                >
                  {stats?.currentMonth?.revenuePercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.currentMonth?.totalRevenue}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Revenue (INR)</p>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '30px' }}>
          <MixedChartShipment data={stats?.graphData} />
        </Box>
      </Box>
    ),
  };

  const handleChange = (selected: any) => {
    setSelectedValues(selected);
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
  };
  return (
    <Box
      sx={{
        borderRadius: '9px',
        border: '1px solid #F1F1F1',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: { xs: '15px 20px', sm: '20px 40px' },
      }}
    >
      <Box
        sx={{
          color: 'rgba(24, 29, 37, 1)',
          fontSize: { xs: '20px', sm: '24px' },
          fontWeight: 600,
          lineHeight: '33.6px',
        }}
      >
        General Overview
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row-reverse' },
            gap: '10px',
            height: 'fit-content',
            position: 'relative',
            top: { xs: '0', sm: '45px' },
          }}
        >
          {/* <Box>
            <DownloadButton
              name={'Download'}
              onClick={() => {
                console.info('click');
              }}
            />
          </Box> */}
          <Box>
            <FilterSelect
              id="month"
              label="Select month"
              options={monthOptions}
              value={selectedValues}
              onChange={handleChange}
              isSorted={true}
            />
            {/* <SBDropdown
              id="month"
              placeholder="Select month"
              name="IdType"
              value={selectedValues ? [selectedValues] : []}
              options={monthOptions}
              onChange={handleChange}
              isSortingAllowed={false}
            /> */}
          </Box>
          <Box>
            <FilterSelect
              id="system"
              label="Select system"
              options={loggedInUserSystemDropdownList}
              value={system}
              onChange={onSystemFilter}
            />
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: {
              xs: '20px',
              sm: '0px',
            },
          }}
        >
          <CustomTabs tabs={tabLabels} tabContent={tabContent} />
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralOverview;
