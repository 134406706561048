import CONFIG from '../../config';
const GOOGLE_MAPS_API_KEY = CONFIG.GOOGLE_MAPS_API_KEY;

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export { loadScript, GOOGLE_MAPS_API_KEY };
