import Image from '@Components/Image';
import { SbMenu, SbMenuItem } from '@Components/Menu';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { Chip, Grid, TableBody, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MapComponent from '../Map/index';
import { CustomModal } from '@Components/Modal/BasicModal';
import { routeOptimizerProps } from './Types';
import { getSquadRouteOptimizer } from '@Reducers/Squad/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import DisplaySquadVehicle from '../../../utils/displaySquadVehicle';
export function RouteOptimizerTable(props: routeOptimizerProps) {
  const { allSquadDetails, currentPage, perPage } = props;
  return (
    <SBTable>
      <TableHead>
        <TableRow>
          <TableHeadCell width="100px">Sr no.</TableHeadCell>
          <TableHeadCell>Squad Details</TableHeadCell>
          <TableHeadCell>Tasks </TableHeadCell>
          <TableHeadCell>No. of Shipments </TableHeadCell>
          <TableHeadCell>Weight Assigned </TableHeadCell>
          <TableHeadCell>Vehicle </TableHeadCell>
          <TableHeadCell>Distance</TableHeadCell>
          <TableHeadCell>Action</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {allSquadDetails?.tripDetails?.map((data: any, index: number) => (
          <SBTableRow key={index}>
            <TableRowCell align="center" className="md-text">
              <div>{index + 1 + (currentPage - 1) * perPage}</div>
            </TableRowCell>
            <TableRowCell align="center" className="sm-text">
              <div>
                {data.name}
                <br />
                {data.phoneNumber}
              </div>
            </TableRowCell>
            <TableRowCell align="center" className="sm-text font-medium">
              {data.totalTasks}
            </TableRowCell>
            <TableRowCell align="center" className="sm-text font-medium">
              <Chip
                label={`${data.totalPickups} Pickup`}
                sx={{
                  bgcolor: 'success.light',
                  color: 'GrayText',
                  fontWeight: 500,
                }}
              />
              <Chip
                label={`${data.totalDeliveries} Deliveries`}
                sx={{
                  bgcolor: 'success.light',
                  color: 'GrayText',
                  fontWeight: 500,
                }}
              />
            </TableRowCell>
            <TableRowCell align="center" className="sm-text font-medium">
              {data.totalWeight} Kg
            </TableRowCell>
            <TableRowCell align="center" className="md-text font-medium">
              {DisplaySquadVehicle(data.vehicle)}
            </TableRowCell>
            <TableRowCell align="left" className="md-text font-medium ">
              {data.totalDistance ? data.totalDistance / 1000 : 0} km
            </TableRowCell>
            <TableRowCell>
              <Actions routeId={data.routeId} tripId={data.tripId} />
              {/* <Actions /> */}
            </TableRowCell>
          </SBTableRow>
        ))}
      </TableBody>
    </SBTable>
  );
}

// function Actions({ tripId }: { tripId: string }) {
function Actions({ routeId, tripId }: { routeId: string; tripId: string }) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { routeOptimizerTrips } = useSelector((state: RootStateType) => state.squadManagement);
  const [open, setOpen] = React.useState(false);
  const [locations, setLocations] = useState([]);

  const handleClose = () => setOpen(false);

  const fetchLocations = () => {
    const locationList = routeOptimizerTrips.stops.map((item: any) => item.location);
    setLocations(locationList);
  };

  const onViewMapClick = async () => {
    await dispatch(getSquadRouteOptimizer(routeId));
    setOpen(true);
    fetchLocations();
  };

  const onEditClick = async () => {
    // await dispatch(getSquadTripDetails({ tripId }));
    navigate(`/route-optimizer/details/${routeId}/${tripId}`);
  };

  return (
    <>
      <Grid container item justifyContent="center" gap={2} alignItems="center">
        <Grid item textAlign="center">
          <SbMenu>
            <SbMenuItem
              onClick={() => onEditClick()}
              icon={<Image src="/images/icons/EditAction.svg" alt="Edit" />}
              name="Edit"
            />
            <SbMenuItem
              onClick={() => onViewMapClick()}
              icon={<Image src="/images/icons/map.svg" alt="Edit" />}
              name="View Map"
            />
          </SbMenu>
        </Grid>
      </Grid>
      <CustomModal open={open} onClose={handleClose} title="Coordinates">
        <MapComponent locations={locations} />
      </CustomModal>
    </>
  );
}
