import { createSlice } from '@reduxjs/toolkit';
import {
  SquadListState,
  TaskListState,
  SquadRouteDetailState,
  SquadRouteOptimizerState,
  squadTripDetailsState,
  SquadList,
  SquadOnOffState,
} from './type';
import {
  createSquad,
  getAllSquadMember,
  getAllTaskShipments,
  getSquadReports,
  getSquadReportsDetails,
  getSquadRouteDetails,
  getSquadRouteOptimizer,
  getSquadTripDetails,
  updateSquad,
  uploadKycDocument,
  getSquadForTaskAssignment,
  squadOnOffCount,
} from './actions';
import {
  getAllSquadMemberFulfilled,
  getAllSquadMemberPending,
  getAllSquadMemberRejected,
  getAllTaskShipmentsFulfilled,
  getAllTaskShipmentsPending,
  getAllTaskShipmentsRejected,
  getCreateSquadFulfilled,
  getCreateSquadPending,
  getCreateSquadRejected,
  getSquadReportsDetailsFulfilled,
  getSquadReportsDetailsPending,
  getSquadReportsDetailsRejected,
  getSquadReportsFulfilled,
  getSquadReportsPending,
  getSquadReportsRejected,
  getSquadRouteDetailsFulfilled,
  getSquadRouteDetailsPending,
  getSquadRouteDetailsRejected,
  getSquadRouteOptimizerFulfilled,
  getSquadRouteOptimizerPending,
  getSquadRouteOptimizerRejected,
  getSquadTripDetailsFulfilled,
  getSquadTripDetailsPending,
  getSquadtripDetailsRejected,
  getSquadMemberTaskPending,
  getSquadMemberTaskRejected,
  getSquadMemberTaskFulfilled,
  getSquadOnOffCountPending,
  getSquadOnOffCountRejected,
  getSquadOnOffCountFulfilled,
  updateSquadFulfilled,
  updateSquadPending,
  updateSquadRejected,
  uploadKycDocumentFulfilled,
  uploadKycDocumentPending,
  uploadKycDocumentRejected,
} from './case';

const initialState = <
  SquadListState &
    SquadRouteDetailState &
    TaskListState &
    SquadRouteOptimizerState &
    squadTripDetailsState &
    SquadList &
    SquadOnOffState
>(<unknown>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
    system: '',
  },
  routeOptimizerTrips: [],
  squadTripDetails: [],
  selectedTasks: {},
  bulkShipmentIdArray: [],
});

const squadSlice = createSlice({
  name: 'squad',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetSquadState: function (state) {
      state = { ...state, ...initialState };
    },
    setRouteOptimizerTrips(state, action) {
      state.routeOptimizerTrips = action.payload;
    },
    setSelectedTasks: function (state, action) {
      const newSelectedShipments = action.payload;
      state.selectedTasks = newSelectedShipments;
    },
    setBulkShipmentIdArray: function (state, action) {
      const newIdArray = action.payload;
      state.bulkShipmentIdArray = newIdArray;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;

    addCase(createSquad.pending, getCreateSquadPending);
    addCase(createSquad.fulfilled, getCreateSquadFulfilled);
    addCase(createSquad.rejected, getCreateSquadRejected);

    addCase(getAllSquadMember.pending, getAllSquadMemberPending);
    addCase(getAllSquadMember.fulfilled, getAllSquadMemberFulfilled);
    addCase(getAllSquadMember.rejected, getAllSquadMemberRejected);

    addCase(uploadKycDocument.pending, uploadKycDocumentPending);
    addCase(uploadKycDocument.fulfilled, uploadKycDocumentFulfilled);
    addCase(uploadKycDocument.rejected, uploadKycDocumentRejected);

    addCase(updateSquad.pending, updateSquadPending);
    addCase(updateSquad.fulfilled, updateSquadFulfilled);
    addCase(updateSquad.rejected, updateSquadRejected);

    addCase(getAllTaskShipments.pending, getAllTaskShipmentsPending);
    addCase(getAllTaskShipments.fulfilled, getAllTaskShipmentsFulfilled);
    addCase(getAllTaskShipments.rejected, getAllTaskShipmentsRejected);
    addCase(getSquadReports.pending, getSquadReportsPending);
    addCase(getSquadReports.fulfilled, getSquadReportsFulfilled);
    addCase(getSquadReports.rejected, getSquadReportsRejected);
    addCase(getSquadReportsDetails.pending, getSquadReportsDetailsPending);
    addCase(getSquadReportsDetails.fulfilled, getSquadReportsDetailsFulfilled);
    addCase(getSquadReportsDetails.rejected, getSquadReportsDetailsRejected);

    addCase(getSquadRouteDetails.pending, getSquadRouteDetailsPending);
    addCase(getSquadRouteDetails.fulfilled, getSquadRouteDetailsFulfilled);
    addCase(getSquadRouteDetails.rejected, getSquadRouteDetailsRejected);

    addCase(getSquadRouteOptimizer.pending, getSquadRouteOptimizerPending);
    addCase(getSquadRouteOptimizer.fulfilled, getSquadRouteOptimizerFulfilled);
    addCase(getSquadRouteOptimizer.rejected, getSquadRouteOptimizerRejected);

    addCase(getSquadTripDetails.pending, getSquadTripDetailsPending);
    addCase(getSquadTripDetails.fulfilled, getSquadTripDetailsFulfilled);
    addCase(getSquadTripDetails.rejected, getSquadtripDetailsRejected);

    addCase(getSquadForTaskAssignment.pending, getSquadMemberTaskPending);
    addCase(getSquadForTaskAssignment.fulfilled, getSquadMemberTaskFulfilled);
    addCase(getSquadForTaskAssignment.rejected, getSquadMemberTaskRejected);
    addCase(squadOnOffCount.pending, getSquadOnOffCountPending);
    addCase(squadOnOffCount.fulfilled, getSquadOnOffCountFulfilled);
    addCase(squadOnOffCount.rejected, getSquadOnOffCountRejected);
  },
});
export const { resetSquadState, setRouteOptimizerTrips } = squadSlice.actions;
export const { setSelectedTasks, setBulkShipmentIdArray } = squadSlice.actions;
export default squadSlice.reducer;
