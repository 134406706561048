import { InvoiceListState, NonAccountUnbilledListResponse } from './Type';

function getInvoiceListPending(state: InvoiceListState) {
  state.loading = true;
}

// function getInvoiceListFulfilled(state: InvoiceListState, data: { payload: AccountUnbilledListResponse }) {
//   const { invoiceResult, currentPage, perPage, totalCount } = data.payload.data;
//   state.data.list = invoiceResult;
//   state.loading = false;
//   state.data.totalCount = totalCount;
//   state.data.currentPage = currentPage;
//   state.data.perPage = perPage;
// }

function fetchInvoiceListPending(state: InvoiceListState) {
  state.loading = true;
}

// function fetchInvoiceListFulfilled(state: InvoiceListState, data: { payload: AccountUnbilledListResponse }) {
//   const { invoiceResult, currentPage, perPage, totalCount } = data.payload.data;
//   state.loading = false;
//   state.data.list = invoiceResult;
//   state.data.totalCount = totalCount;
//   state.data.currentPage = currentPage;
//   state.data.perPage = perPage;
// }

function voidInvoiceFullFilled(state: InvoiceListState) {
  state.loading = false;
}

function createNonAccountInvoiceFulfilled(state: InvoiceListState) {
  state.loading = false;
}

function fetchInvoiceListRejected(state: InvoiceListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getNonAccountUnbilledListFulfilled(
  state: InvoiceListState,
  data: { payload: NonAccountUnbilledListResponse },
) {
  const { invoiceResult, currentPage, perPage, totalCount } = data.payload.data;
  state.loading = false;
  state.nonAccountData.list = invoiceResult;
  state.nonAccountData.totalCount = totalCount;
  state.nonAccountData.currentPage = currentPage;
  state.nonAccountData.perPage = perPage;
}

function getInvoiceListRejected(state: InvoiceListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

export {
  createNonAccountInvoiceFulfilled,
  fetchInvoiceListPending,
  // fetchInvoiceListFulfilled,
  fetchInvoiceListRejected,
  getInvoiceListPending,
  // getInvoiceListFulfilled,
  getInvoiceListRejected,
  getNonAccountUnbilledListFulfilled,
  voidInvoiceFullFilled,
};
