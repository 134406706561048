import React, { useEffect } from 'react';
import { Typography, Tabs, Tab, Box } from '@mui/material';
import ServiceList from './Service';
import CarrierList from './Carrier';
import { useLocation, useNavigate } from 'react-router-dom';

const TABS = {
  service: 0,
  carrier: 1,
};

type TAB_KEYS = keyof typeof TABS;
const TAB_KEYS_ARR = Object.keys(TABS) as TAB_KEYS[];

export default function CarrierAndServiceList() {
  const location = useLocation();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState<TAB_KEYS>('service');

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const newTabValue = TAB_KEYS_ARR.find(x => TABS[x] === newValue);
    // setTabValue(newTabValue || 'service');
    const newTab = newTabValue || 'service';
    navigate(location.pathname + '?tab=' + newTab);
  };

  useEffect(
    function () {
      const searchParams = new URLSearchParams(location.search);
      const tabValue = searchParams.get('tab') as TAB_KEYS;
      if (tabValue) setTabValue(tabValue);
      else navigate(location.search + '?tab=service');
    },
    [location.search],
  );

  // let subTitleText = 'View and edit your ';
  // if (tabValue === 'service') subTitleText += 'services.';
  // else subTitleText += 'carrier.';

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={TABS[tabValue]} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Service" {...a11yProps('service')} className="normal-case" />
            <Tab label="Carrier" {...a11yProps('carrier')} className="normal-case" />
          </Tabs>
        </Box>
        <TabPanel value={'service'} selectedValue={tabValue}>
          <ServiceList />
        </TabPanel>
        <TabPanel value={'carrier'} selectedValue={tabValue}>
          <CarrierList />
        </TabPanel>
      </Box>
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  value: string;
  selectedValue: string;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, selectedValue, ...other } = props;
  const selectedIndex = TABS[selectedValue as keyof typeof TABS];
  const index = TABS[value as keyof typeof TABS];
  return (
    <div
      role="tabpanel"
      hidden={index !== selectedIndex}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {index === selectedIndex && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(value: keyof typeof TABS) {
  return {
    id: `simple-tab-${TABS[value]}`,
    'aria-controls': `simple-tabpanel-${TABS[value]}`,
    sx: { fontSize: '1rem', fontWeight: '600' },
  };
}
