import { PayloadAction } from '@reduxjs/toolkit';
import { ManifestListResponse, ManifestState } from './Type';
import { SuccessPayload } from '../../../types';

function pending(state: ManifestState) {
  state.loading = true;
}

function createManifestFulfilled(state: ManifestState, action: PayloadAction<SuccessPayload>) {
  state.loading = false;
  state.success = action.payload.msg;
}

function rejected(state: ManifestState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function updateManifestFulfilled(state: ManifestState, action: PayloadAction<SuccessPayload>) {
  state.loading = false;
  state.success = action.payload.msg;
  state.singleData = null;
}

function getManifestFulfilled(state: ManifestState, action: PayloadAction<{ data: ManifestListResponse }>) {
  const { lists, currentPage, perPage, totalCount } = action.payload.data?.data || {};
  // console.log('action.payload.data ', action.payload.data);
  state.loading = false;
  state.data.list = lists;
  state.data.totalCount = totalCount;
  state.data.currentPage = currentPage;
  state.data.perPage = perPage;
}

// function getSingleManifestFulfilled(state: ManifestState, action: PayloadAction<{ data: UpdateManifest }>) {
//   state.loading = false;
//   state.singleData = action.payload.data;
// }

function deleteManifestFulfilled(state: ManifestState) {
  state.loading = false;
  state.successDelete = true;
}

export {
  pending,
  createManifestFulfilled,
  rejected,
  updateManifestFulfilled,
  getManifestFulfilled,
  deleteManifestFulfilled,
  // getSingleManifestFulfilled,
};
