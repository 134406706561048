import SBDropdown from '@Components/Dropdown';
import SbTextField from '@Components/Textfield';
import useConnectionTitle from '@Hook/useConnectionTitle';
import { ConnectOnWarehouse } from '@Reducers/AdminShipment/Type';
import { RootStateType } from '@Store';
import { TimelineContent, TimelineItem } from '@mui/lab';
import { Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Charges from '../../ShipmentCharges/Charges';
import { SingleConnectionContainer } from '../style';
import SideTimelineSeparator from './SideTimelineSeparator';
import ViewConnectWarehouse from './ViewConnectWarehouse';
import SbButton from '@Components/Button';
import { ShipmentControl } from '@Services/shipmentActionControl';
import { CSPChargeType } from '@Reducers/GetRates/Type';
import FirstConnectiongWearhouseDropdown from '../FirstConnectiongWearhouseDropdown';

interface ViewConnectionProps {
  warehouseId: string;
  data: ConnectOnWarehouse;
  position: number;
  isFristConnection: boolean;
}

function ViewConnection(props: ViewConnectionProps) {
  const { warehouseId, data, position, isFristConnection } = props;
  const { warehouseListForShipmentOptions, warehouseListForShipmentOptionsHashById } = useSelector(
    (state: RootStateType) => state.Warehouse,
  );
  const connectionCountInWord = useConnectionTitle({
    position,
    addressObj: warehouseListForShipmentOptionsHashById[data.warehouseId]?._addressId,
  });
  // const isFinalForwardEditable = useSelector((state: RootStateType) => state.adminShipment.editFinalForward);
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment?.shipmentById);
  const shipmentActionControl = new ShipmentControl(shipmentById);
  const shipmentConnectOnWarehouse = useSelector(
    (state: RootStateType) => state.adminShipment.shipmentConnectOnWarehouse,
  );
  const [isEditable, setEditable] = useState<boolean>(false);
  const [selectedChargeObj, setSelectedChargeObj] = useState<CSPChargeType | null>(null);

  const onSelectCharge = (selectedCharge: CSPChargeType) => {
    setSelectedChargeObj(selectedCharge);
  };

  console.log('ViewConnection');
  return (
    <TimelineItem>
      <SideTimelineSeparator />
      <TimelineContent sx={{ pt: 0 }}>
        <SingleConnectionContainer container item mb={1} justifyContent="center" className="selected">
          <Grid item xs={12}>
            <Grid container item mb={1} justifyContent="space-between">
              <Typography className="md-text font-semibold capitalize">{connectionCountInWord}</Typography>

              {!shipmentActionControl.doesShipmentDelivered && position === shipmentConnectOnWarehouse?.length && (
                <SbButton
                  sx={{ borderRadius: `4px !important`, zIndex: 1 }}
                  variant="contained"
                  // className="absolute"
                  // disabled={!state.value.trim() || !state.label.trim()}
                  onClick={() => {
                    setEditable(true);
                  }}
                >
                  Edit
                </SbButton>
              )}
            </Grid>
            <Grid
              item
              alignItems="flex-start"
              display="grid"
              gridTemplateColumns={{
                xs: '1fr', // Single-column layout for extra-small screens
                sm: '1fr 1fr', // Two equal columns for small screens
                md: '1.5fr 2fr', // Original layout for medium and larger screens
              }}
              gap={{
                xs: 2, // Compact gaps for smaller screens
                sm: 6, // Moderate gaps for small screens
                md: 14, // Original gaps for larger screens
              }}
              className={
                (isEditable ? !shipmentActionControl.canConnectToWareshouse : !isEditable) ? 'disabled-form' : ''
              }
            >
              {/* <Grid item xs={12}> */}
              {isFristConnection && isEditable && shipmentActionControl.canConnectToWareshouse ? (
                <FirstConnectiongWearhouseDropdown selectedWarehouse={data.warehouseId} />
              ) : (
                <SBDropdown
                  // required
                  label="Select Origin Warehouse"
                  id="Select-Connecting-Warehouse"
                  placeholder="Warehouse"
                  options={warehouseListForShipmentOptions}
                  value={warehouseId ? [warehouseId] : []}
                  onChange={console.log}
                  disabled={true}
                />
              )}
              <Grid item xs={12} sm={6}>
                <SbTextField label="Original Service" value={shipmentById?.firstSelectedServiceName || '-'} disabled />
              </Grid>
            </Grid>
            <Grid container item>
              <ViewConnectWarehouse
                fromWarehouseId={warehouseId || ''}
                data={data}
                disableForm={!isEditable}
                isEditable={isEditable || ['rto', 'delivered', 'cancelled'].includes(shipmentById?.status ?? '')}
                handleCancel={() => setEditable(false)}
                onSelectRate={onSelectCharge}
              >
                <Grid container item sx={{ opacity: '0.4' }}>
                  <Grid item xs={12} md={10} xl={8}>
                    <Charges
                      pricing={
                        selectedChargeObj ? selectedChargeObj.pricing : data.buyingPricing.selectedCharge.pricing
                      }
                      taxPricing={
                        selectedChargeObj ? selectedChargeObj.taxPricing : data.buyingPricing.selectedCharge.taxPricing
                      }
                      totalPricing={
                        selectedChargeObj
                          ? selectedChargeObj.totalPricing
                          : data.buyingPricing.selectedCharge.totalPricing
                      }
                      currency="INR"
                    />
                  </Grid>
                </Grid>
              </ViewConnectWarehouse>
              {/* <ConnectWarehouseForm fromWarehouseId={data.warehouseId} onSelectRate={onSelectCharge} /> */}

              {/* <HorizontalDash /> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className="mt-4" />
          </Grid>
        </SingleConnectionContainer>
      </TimelineContent>
    </TimelineItem>
  );
}

export default ViewConnection;
