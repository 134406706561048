import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { styled, Typography } from '@mui/material';
import { SBTimelineType } from './type';

export const SBTimeline = styled(Timeline)<SBTimelineType>(({ theme, is_last }) => ({
  borderBottom: is_last === '1' ? 'unset' : `3px solid ${theme.palette.border.tableRow}`,
}));

export const SBTimelineItem = styled(TimelineItem)(({ theme }) => ({
  minHeight: '52px',
  '&:before': {
    content: '""',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
}));

export const SBTimelineOppositeContent = styled(TimelineOppositeContent)(({ theme }) => ({
  flexBasis: '32%',
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    marginLeft: '5px',
    padding: '0',
    flexBasis: '40%',
    minWidth: '100px',
  },
}));
export const SBTimelineOppositeContentDate = styled(TimelineOppositeContent)(({ theme }) => ({
  flexBasis: '32%',
  textAlign: 'right',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    marginLeft: '5px',
    padding: '0',
    minWidth: '100px',
  },
}));

export const SBTimelineSeparator = styled(TimelineSeparator)(({ theme }) => ({
  flexBasis: '5%',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    flexBasis: '8%',
  },
}));

export const SBTimelineDot = styled(TimelineDot)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  marginBlock: 0,
  height: '1.01rem',
  width: '1.01rem',
  [theme.breakpoints.down('sm')]: {
    height: '1rem',
    width: '1rem',
  },
}));

export const SBTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.down('sm')]: {},
}));

export const SBTimelineContent = styled(TimelineContent)(({ theme }) => ({
  flexBasis: '63%',
  [theme.breakpoints.down('sm')]: {
    flexBasis: '75%',
    padding: '0.5rem',
  },
}));

export const ShipmentStatus = styled(Typography)(() => ({
  flexBasis: '77%',
}));

export const ShipmentLocation = styled(Typography)(() => ({
  flexBasis: '30%',
}));

export const VerticalTrackingWrapper = styled('div')(() => ({
  // minWidth: '500px',
}));
