import { DropdownOptionType } from '@Components/Dropdown/Type';
import { TransactionModes, TransactionStatus } from './Types';

export const SearchFilterList = [
  { label: 'Shipment No', value: 'shipmentNumber' },
  { label: 'Account No', value: 'accountNumber' },
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  // { label: 'Company Name', value: 'companyName' },
  { label: 'Invoice No', value: 'invoiceNumber' },
  { label: 'Transaction Id', value: 'transactionId' },
];

export const CreditWalletSearchFilterList = [
  { label: 'Shipment No.', value: 'shipmentNumber' },
  { label: 'Invoice No.', value: 'invoiceNumber' },
  { label: 'Transaction Id', value: 'transactionId' },
];

export const PaymentModeList: { [key in TransactionModes]: DropdownOptionType } = {
  wallet: {
    value: 'wallet',
    display: 'Wallet',
  },
  gateway: {
    value: 'gateway',
    display: 'Card / Netbanking / UPI',
  },
  cash: {
    value: 'cash',
    display: 'Cash',
  },
  // credit: {
  //   value: 'credit',
  //   display: 'Credit',
  // },
  creditnote: {
    value: 'creditnote',
    display: 'Creditnote',
  },
};

export const StatusList: { [key in TransactionStatus]: DropdownOptionType } = {
  successful: {
    display: 'Successful',
    value: 'successful',
  },
  pending: {
    display: 'Pending',
    value: 'pending',
  },
  failed: {
    display: 'Failed',
    value: 'failed',
  },
  cancelled: {
    display: 'Cancelled',
    value: 'cancelled',
  },
};
