import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { Box, useTheme } from '@mui/material';

Chart.register(...registerables);

interface MonthlyData {
  month: string;
  year: string;
  result: {
    totalShipment: number;
    totalWeight: number;
    totalRevenue: number;
    totalGrossProfit: number;
  };
}

interface BarChartProps {
  data: MonthlyData[] | undefined;
}

const BarChart: React.FC<BarChartProps> = ({ data }) => {
  const months = data?.map(item => item.month);
  const revenueData = data?.map(item => item.result.totalRevenue);
  const grossProfitData = data?.map(item => item.result.totalGrossProfit);
  const theme = useTheme();

  const barChartData = {
    labels: months,
    datasets: [
      {
        label: 'Revenue',
        data: revenueData,
        backgroundColor: theme.palette.primary.main,
      },
      {
        label: 'Gross Profit',
        data: grossProfitData,
        backgroundColor: theme.palette.secondary.main,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
  };
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'flex-end' },
          gap: { xs: '10px', sm: '100px' },
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <p style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <span
            style={{
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.main,
              display: 'inline-block',
              width: '15px',
              height: '15px',
            }}
          ></span>
          <span>Revenue</span>
        </p>
        <p style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <span
            style={{
              borderRadius: '50%',
              backgroundColor: theme.palette.secondary.main,
              display: 'inline-block',
              width: '15px',
              height: '15px',
            }}
          ></span>
          <span>Gross Profit</span>
        </p>
      </Box>
      <Box sx={{ position: 'relative', height: { xs: '300px', sm: '500px' }, width: '100%' }}>
        <Bar data={barChartData} options={options} />
      </Box>
    </Box>
  );
};

export default BarChart;
