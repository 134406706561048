import SbButton from '@Components/Button';
import SBDropdown from '@Components/Dropdown';
import SBCountryDropdown from '@Components/Dropdown/Country';
import { FormLabel } from '@Components/Form';
import { vendorAccountType } from '@Reducers/AdminShipment/Type';
import { setCarrierSettings } from '@Reducers/User';
import { addCarrierSettings, updateCarrierSettings } from '@Reducers/User/actions';
import { AppDispatch, RootStateType } from '@Store';
import { Checkbox, FormControlLabel, FormHelperText, Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { importShipments } from '../../CreateShipment/constants';
import {
  BillDuties,
  Packaging_Type,
  Payor,
  PickupType,
  PoNo,
  ServiceType,
  ShipmentPurpose,
  ShipmentType,
  ShipmentTypeForShipperId,
  ShipperIdForExport,
  ShipperIdForPersonal,
} from '../../ViewSingleShipmentAdmin/components/ShipmentProcessingV2/components/Fedex/constants';
import { OptionType, fedexCarrier } from './Type';
import { fedExSchema } from './constant';

const FedEx = ({ onCancel }: { onCancel: () => void }) => {
  const [idAttached, setIdAttached] = useState<boolean>(false);
  const { vendersAccount } = useSelector((state: RootStateType) => state.adminShipment);
  const { userDataById, carrierSetting } = useSelector((state: RootStateType) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const { values, setFieldValue, errors, handleSubmit } = useFormik<fedexCarrier>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: carrierSetting.fedex,
    validationSchema: fedExSchema,
    onSubmit(values) {
      if (userDataById?._id) {
        if (carrierSetting.fedex._id) {
          dispatch(updateCarrierSettings({ ...carrierSetting.fedex, ...values }));
        } else {
          dispatch(addCarrierSettings({ ...carrierSetting.fedex, ...values }));
        }
      } else {
        dispatch(setCarrierSettings({ values, carrierType: 'fedex' }));
      }
      onCancel();
    },
  });

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    if (subdomain === 'uat-speedbox' || subdomain === 'localhost') {
      setIdAttached(true);
    }
  }, []);

  // useEffect(() => {
  //   if (carrierSetting.fedex.accountNumber) {
  //     dispatch(
  //       getCarrierSettings({
  //         csp: CSP_TYPES.fedex.value,
  //         accountNumber: carrierSetting.fedex.accountNumber,
  //       }),
  //     );
  //   }
  // }, [carrierSetting.fedex.accountNumber, userDataById?._id]);

  return (
    <Paper elevation={0}>
      <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="shipmentPurpose"
              label={`Purpose of Shipment`}
              required
              placeholder="Select "
              name="shipmentPurpose"
              options={ShipmentPurpose}
              value={values.shipmentPurpose?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('shipmentPurpose', selectedObj);
              }}
              error={Array.isArray(errors.shipmentPurpose) ? errors.shipmentPurpose.join(', ') : errors.shipmentPurpose}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBCountryDropdown
              // multiple
              // required
              label="Country of Manufacture"
              // value={values.countryOfManufacture?.map(item => item.value)}
              value={values.countryOfManufacture}
              // onChange={(_, selectedObj) => {
              //   setFieldValue('countryOfManufacture', selectedObj);
              // }}
              onChange={value => {
                setFieldValue('countryOfManufacture', value[0]);
              }}
              error={errors.countryOfManufacture}
              // error={
              //   Array.isArray(errors.countryOfManufacture)
              //     ? errors.countryOfManufacture.join(', ')
              //     : errors.countryOfManufacture
              // }
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="account_no"
              label={`Account No`}
              required
              placeholder="Select"
              name="accountNo"
              // options={vendersAccount?.map((item: vendorAccountType) => ({
              //   value: `${item.accountNo}-${item._id}`,
              //   display: item.name,
              // }))}
              options={vendersAccount?.map((item: vendorAccountType) => ({
                value: idAttached ? `${item.accountNo}-${item._id}` : `${item.accountNo}`,
                display: item.name,
              }))}
              value={values.accountNo?.map(item => item?.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('accountNo', selectedObj);
              }}
              error={Array.isArray(errors.accountNo) ? errors.accountNo.join(', ') : errors.accountNo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="payor"
              required
              label={`Payor`}
              options={Payor}
              placeholder="Select"
              name="payor"
              value={values.payor?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('payor', selectedObj);
                setFieldValue('thirdPartyPayor', []);
              }}
              error={Array.isArray(errors.payor) ? errors.payor.join(', ') : errors.payor}
            />
          </Grid>
        </Grid>

        {JSON.stringify(values?.payor)?.includes('THIRD_PARTY') && (
          <Grid container item columnSpacing={10} rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <SBDropdown
                multiple
                id="thirdPartyPayor"
                label={`Third party Payor`}
                required
                placeholder="1254621561661"
                name="thirdPartyPayor"
                value={values.thirdPartyPayor?.map(item => item.value)}
                onChange={(_, selectedObj) => {
                  setFieldValue('thirdPartyPayor', selectedObj);
                }}
                error={
                  Array.isArray(errors.thirdPartyPayor) ? errors.thirdPartyPayor.join(', ') : errors.thirdPartyPayor
                }
                options={vendersAccount?.map((item: vendorAccountType) => ({
                  value: `${item.accountNo}-${item._id}`,
                  display: item.name,
                }))}
              />
            </Grid>
          </Grid>
        )}
        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="po_no"
              label={`PO No`}
              required
              placeholder="Select"
              name="poNo"
              value={values.poNo?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('poNo', selectedObj);
              }}
              error={Array.isArray(errors.poNo) ? errors.poNo.join(', ') : errors.poNo}
              options={PoNo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="bill"
              required
              label={`Bill Duties and Taxes to`}
              placeholder="Select"
              name="billDutiesAndTaxesTo"
              value={values.billDutiesAndTaxesTo?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('billDutiesAndTaxesTo', selectedObj);
                setFieldValue('billDutiesAccountNo', []);
              }}
              error={
                Array.isArray(errors.billDutiesAndTaxesTo)
                  ? errors.billDutiesAndTaxesTo.join(', ')
                  : errors.billDutiesAndTaxesTo
              }
              options={BillDuties}
            />
          </Grid>
        </Grid>
        {(JSON.stringify(values?.billDutiesAndTaxesTo)?.includes('THIRD_PARTY') ||
          JSON.stringify(values?.billDutiesAndTaxesTo)?.includes('RECIPIENT')) && (
          <Grid container item columnSpacing={10} rowSpacing={2}>
            <Grid item xs={12} md={6}>
              <SBDropdown
                multiple
                id="billDutiesAccountNo"
                label={`Bill Duties Account No`}
                required
                placeholder="1254621561661"
                name="billDutiesAccountNo"
                value={values.billDutiesAccountNo?.map(item => item.value)}
                onChange={(_, selectedObj) => {
                  setFieldValue('billDutiesAccountNo', selectedObj);
                }}
                error={
                  Array.isArray(errors.billDutiesAccountNo)
                    ? errors.billDutiesAccountNo.join(', ')
                    : errors.billDutiesAccountNo
                }
                options={vendersAccount?.map((item: vendorAccountType) => ({
                  value: `${item.accountNo}-${item._id}`,
                  display: item.name,
                }))}
              />
            </Grid>
          </Grid>
        )}

        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="packagingType"
              label={`Packaging Type`}
              placeholder="Your packaging "
              required
              name="packagingType"
              options={Packaging_Type}
              value={values.packagingType?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('packagingType', selectedObj);
              }}
              error={Array.isArray(errors.packagingType) ? errors.packagingType.join(', ') : errors.packagingType}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="serviceType"
              label={`Service Type`}
              required
              placeholder="Select"
              name="serviceType"
              options={ServiceType}
              value={values.serviceType?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('serviceType', selectedObj);
              }}
              error={Array.isArray(errors.serviceType) ? errors.serviceType.join(', ') : errors.serviceType}
            />
          </Grid>
        </Grid>

        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="shipperId"
              label={`Shipment Type for Shipper Id`}
              required
              placeholder="Select"
              name="shipperShipmentType"
              options={ShipmentTypeForShipperId}
              value={values.shipperShipmentType?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('shipperShipmentType', selectedObj);
                setFieldValue('shipmentIdType', []);
              }}
              error={
                Array.isArray(errors.shipperShipmentType)
                  ? errors.shipperShipmentType.join(', ')
                  : errors.shipperShipmentType
              }
            />
          </Grid>
          {values.shipperShipmentType?.length > 0 && (
            <Grid item xs={12} md={6}>
              <SBDropdown
                multiple
                id="shipperId"
                label={`Shipper ID Type`}
                required
                placeholder="Select"
                name="shipmentIdType"
                options={
                  JSON.stringify(values.shipperShipmentType)?.includes('PERSONAL_SHIPMENTS') &&
                  JSON.stringify(values.shipperShipmentType)?.includes('EXPORT_SHIPMENTS')
                    ? [...ShipperIdForPersonal, ...ShipperIdForExport]
                    : JSON.stringify(values.shipperShipmentType)?.includes('PERSONAL_SHIPMENTS')
                    ? ShipperIdForPersonal
                    : ShipperIdForExport
                }
                value={values.shipmentIdType?.map(item => item.value)}
                onChange={(_, selectedObj) => {
                  setFieldValue('shipmentIdType', selectedObj);
                }}
                error={Array.isArray(errors.shipmentIdType) ? errors.shipmentIdType.join(', ') : errors.shipmentIdType}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="pickupType"
              label={`Pickup Type`}
              required
              placeholder="Select "
              name="pickupType"
              options={PickupType}
              value={values.pickupType?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('pickupType', selectedObj);
              }}
              error={Array.isArray(errors.pickupType) ? errors.pickupType.join(', ') : errors.pickupType}
            />
          </Grid>
        </Grid>
        <Grid container item columnSpacing={10} rowSpacing={1}>
          <Grid item xs={12}>
            <FormLabel fontWeight={500} fontSize={14}>
              Select laser for import shipments*
            </FormLabel>
          </Grid>
          {importShipments?.map(obj => (
            <Grid item xs={12} md={3.5} key={obj.value}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="printer"
                    checked={values.printer?.map(item => item.value).includes(obj.value)}
                    onChange={() => {
                      setFieldValue(
                        'printer',
                        values?.printer?.length > 0 && JSON.stringify(values?.printer)?.includes(obj.value)
                          ? values.printer.filter((item: OptionType) => item.value !== obj.value)
                          : [...values.printer, { value: obj.value, display: obj.label }],
                      );
                    }}
                  />
                }
                label={obj.label}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            {errors.printer && (
              <FormHelperText error>
                {Array.isArray(errors.printer) ? errors.printer.join(', ') : errors.printer}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              required
              multiple
              id="shipmentType"
              label="Shipment Type"
              placeholder="Shipment Type"
              name="shipmentType"
              options={ShipmentType}
              value={values.shipmentType?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('shipmentType', selectedObj);
              }}
              error={Array.isArray(errors.shipmentType) ? errors.shipmentType.join(', ') : errors.shipmentType}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <SBDropdown
              multiple
              id="b13AFilingOption"
              label={`B13A Filing Option`}
              required
              placeholder="Select"
              name="b13AFilingOption"
              value={values.b13AFilingOption?.map(item => item.value)}
              options={b13AFilingOption}
              onChange={(_, selectedObj) => {
                setFieldValue('b13AFilingOption', selectedObj);
              }}
              error={
                Array.isArray(errors.b13AFilingOption) ? errors.b13AFilingOption.join(', ') : errors.b13AFilingOption
              }
            /> */}
          </Grid>
        </Grid>

        <Grid container item sx={{ gap: '1rem' }} justifyContent={'flex-end'}>
          <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
            <SbButton sx={{ borderRadius: `4px !important` }} className="w-full" variant="outlined" onClick={onCancel}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
            <SbButton
              variant="contained"
              sx={{ borderRadius: `4px !important` }}
              className="w-full"
              onClick={_event => {
                handleSubmit();
              }}
            >
              Save
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FedEx;
