import { setWsConnectionId } from '@Reducers/AdminShipment';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { AppDispatch, RootStateType } from '@Store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useWebsocket = ({ query }: { query: Record<string, string> }) => {
  const user_id = useSelector((state: RootStateType) => state.loginUser.token?._id);
  const [websocket, setWebsocket] = useState<WebSocket>();
  const [connecting, setConnection] = useState<boolean>(true);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const hostname = window.location.hostname;
    const organisationName = hostname.split('.')[0];
    let socketStr = '';
    if (organisationName) {
      if (organisationName === 'ae') {
        socketStr = `wss://s0panimq60.execute-api.ap-south-1.amazonaws.com/prod?channel=${query?.channel}&org_code=allied&user_id=${user_id}`;
      } else if (organisationName === 'uat-speedbox' || organisationName === 'localhost') {
        socketStr = `wss://s0panimq60.execute-api.ap-south-1.amazonaws.com/staging?channel=${query?.channel}&org_code=stage&user_id=${user_id}`;
      } else {
        socketStr = `wss://s0panimq60.execute-api.ap-south-1.amazonaws.com/prod?channel=${query?.channel}&org_code=${organisationName}&user_id=${user_id}`;
      }
    }

    const websocket = new WebSocket(socketStr);
    setWebsocket(websocket);
    websocket.onopen = function () {
      setConnection(false);
      websocket.send('connection added');
    };

    websocket.onerror = function (error) {
      setConnection(false);
      console.info(error);
    };
    return () => {
      if (websocket) {
        websocket.close();
        websocket.onclose = function (event) {
          if (event.wasClean) {
            console.info(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
          } else {
            console.info('[close] Connection died');
          }
        };
      }
    };
  }, []);

  if (websocket) {
    websocket.onmessage = function (event) {
      if (JSON.parse(event?.data)?.isUpdated) {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: event?.data?.message || 'This shipment has been updated, please refresh',
            subTitle: '',
            btnText: 'Refresh',
            onConfirm: () => {
              window.location.reload();
            },
          }),
        );
      } else {
        dispatch(setWsConnectionId(JSON.parse(event?.data)?.connectionId));
      }
    };
  }

  return {
    connecting,
    websocket,
  };
};
