import TrackShipment from '@Components/TrackShipment';
import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import Notification from './Notification';
import DownloadNotification from './DownloadNotification';
import DownloadIcon from '@mui/icons-material/Download';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UserData from './UserData';
import { MenuContext } from '../../context/Menu';
import MenuIcon from '@mui/icons-material/Menu';

const HeaderWrapper = styled('div')`
  display: flex;
  gap: 5rem;
  align-items: center;
  padding: 24px;
`;
// const HeaderWrapper = styled('div')`
//   display: grid;
//   grid-template-columns: 2fr 1fr;
//   align-items: center;
//   padding: 24px;
// `;

function Header() {
  const theme = useTheme();
  const renderMobileMenu = useMediaQuery(theme.breakpoints.down('md'));
  if (renderMobileMenu) return <MobileHeader />;
  return (
    <HeaderWrapper>
      <div>
        <TrackShipment />
      </div>
      <Grid
        container
        display={'flex'}
        justifyContent={'end'}
        // gridTemplateColumns={' max-content 60px 60px max-content'}
        gridTemplateColumns={' max-content  60px max-content'}
      >
        <DownloadNotification icon={<DownloadIcon />} />
        <Notification icon={<NotificationsIcon />} />
        <UserData />
      </Grid>
    </HeaderWrapper>
  );
}

const MobileHeaderWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  margin-bottom: 16px;
`;

function MobileHeader() {
  const { setShowMobileMenu } = useContext(MenuContext);
  return (
    <MobileHeaderWrapper className="w-full">
      <MenuIcon
        className="cursor-pointer"
        onClick={() => {
          setShowMobileMenu?.(true);
        }}
      />

      <Grid container justifyContent={'end'} alignItems={'center'} gap={1}>
        <Grid item xs="auto">
          <DownloadNotification icon={<DownloadIcon />} />
        </Grid>
        <Grid item xs="auto">
          <Notification icon={<NotificationsIcon />} />
        </Grid>
        <Grid item xs="auto">
          <UserData />
        </Grid>
      </Grid>
    </MobileHeaderWrapper>
  );
}

export default Header;
