import React, { useState } from 'react';

import { Grid } from '@mui/material';
import CreateCarrierForm, { initCreateCarrier } from './Form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { createCarrier } from '../../../../store/reducers/Carrier';
import { useFormik } from 'formik';
import SbButton from '../../../../components/Button';
import { CreateCarrierPayload } from '@Reducers/Carrier/Type';
import { CreateCarrierSchema } from './schema';
import { useNavigate } from 'react-router-dom';

function CreateCarrier() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [system, _setSystem] = useState<CreateCarrierPayload>({ ...initCreateCarrier });

  const onCreate = (params: CreateCarrierPayload) => {
    const payload = {
      name: params.name,
      logo: params.logo,
      isAccountHolder: params.isAccountHolder,
    };
    dispatch(createCarrier(payload));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...system },
    validationSchema: CreateCarrierSchema,
    onSubmit(values) {
      // console.log({ values });
      onCreate(values);
    },
  });

  const onCancel = () => {
    navigate('/carrier-and-services?tab=carrier');
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="p-4">
          <CreateCarrierForm
            formType="create"
            errors={formik.errors}
            values={formik.values}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
          />
        </Grid>
        <Grid container item mt={4} spacing={2} justifyContent="flex-end">
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton fullWidth variant="outlined" onClick={onCancel}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton
              fullWidth
              variant="contained"
              onClick={_event => {
                formik.handleSubmit();
              }}
            >
              Create
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateCarrier;
