import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import SbButton from '../../../../components/Button';

interface DashedBtnProps {
  label: string;
  classes?: string;
  onClick?: () => void;
}

const btnStyles = {
  border: '1px #D0D5DD',
  borderStyle: 'dashed',
  justifyContent: 'center',
  cursor: 'pointer',
  color: '#667085',
};

function DashedBtn(props: DashedBtnProps): JSX.Element {
  return (
    <SbButton className={`rounded flex p-2 ${props.classes}`} style={btnStyles} onClick={props.onClick}>
      <AddIcon />
      <Typography>{props.label}</Typography>
    </SbButton>
  );
}

export default DashedBtn;
