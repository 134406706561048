import { createSlice } from '@reduxjs/toolkit';
import {
  getCarrierListPending,
  getCarrierListFulfilled,
  getCarrierListRejected,
  createCarrierPending,
  createCarrierFulfilled,
  createCarrierRejected,
  getCarrierByIdPending,
  getCarrierByIdFulfilled,
  getCarrierByIdRejected,
  updateCarrierPending,
  updateCarrierFulfilled,
  updateCarrierRejected,
} from './case';
import { CarrierState } from './Type';
import { getCarrierList, createCarrier, getCarrierById, updateCarrierById } from './actions';

const initialState: CarrierState = {
  loading: false,
  singleCarrier: null,
  carrierList: [],
  activeCarrierList: [],
  filter: {
    searchKey: 'name',
    searchValue: '',
  },
  activeCarrierListHashById: {},
  activeCarrierDropdownList: [],
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
};

const reduxStateSlice = createSlice({
  name: 'global',
  initialState: { ...initialState },
  reducers: {
    resetCarrierError: function (state) {
      state.error = {
        msg: '',
        isError: false,
      };
    },
    resetSingleCarrier: function (state) {
      state.singleCarrier = null;
    },
    // filterCarrierList: function (state) {},
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getCarrierList.pending, getCarrierListPending);
    addCase(getCarrierList.fulfilled, getCarrierListFulfilled);
    addCase(getCarrierList.rejected, getCarrierListRejected);

    addCase(createCarrier.pending, createCarrierPending);
    addCase(createCarrier.fulfilled, createCarrierFulfilled);
    addCase(createCarrier.rejected, createCarrierRejected);

    addCase(getCarrierById.pending, getCarrierByIdPending);
    addCase(getCarrierById.fulfilled, getCarrierByIdFulfilled);
    addCase(getCarrierById.rejected, getCarrierByIdRejected);

    addCase(updateCarrierById.pending, updateCarrierPending);
    addCase(updateCarrierById.fulfilled, updateCarrierFulfilled);
    addCase(updateCarrierById.rejected, updateCarrierRejected);

    // addCase(getActiveSystemList.pending, getActiveSystemListPending);
    // addCase(getActiveSystemList.fulfilled, getActiveSystemListFulfilled);
    // addCase(getActiveSystemList.rejected, getActiveSystemListRejected);
  },
});

export { getCarrierList, createCarrier, getCarrierById, updateCarrierById };
export const { resetCarrierError, resetSingleCarrier } = reduxStateSlice.actions;
export default reduxStateSlice.reducer;
