import { styled, Typography } from '@mui/material';
import { TransactionType } from './Types';

const TableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 16px',
  display: 'grid',
  gridTemplateColumns: '400px 1fr 1fr 1fr 1fr 140px ',
  columnGap: '15px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 10,
    gridTemplateColumns: '380px 0.5fr 1.5fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '32px 0px',
    gap: 15,
    gridTemplateColumns: '340px 1fr 1.5fr',
    gridTemplateRows: 'repeat(2,1fr)',
    '.whiteSpace': {
      display: 'none',
    },
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    marginBottom: '60px',
    marginTop: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

const CreditWalletTableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 0',
  display: 'grid',
  gridTemplateColumns: '400px 1fr 1fr 1fr 1fr 140px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 10,
    gridTemplateColumns: '380px 0.5fr 1.5fr 1fr 1fr 1fr',
  },
  [theme.breakpoints.down('lg')]: {
    padding: '32px 0px',
    gap: 15,
    gridTemplateColumns: '340px 1fr 1.5fr',
    gridTemplateRows: 'repeat(2,1fr)',
    '.whiteSpace': {
      display: 'none',
    },
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

const NavigationBtnContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridGap: '15px',
  [theme.breakpoints.down('xl')]: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const NavigationBtn = styled('div')(({ theme }) => ({
  // height: '92px',
  padding: '20px',
  display: 'grid',
  gridTemplateColumns: '32px 1fr',
  alignItems: 'center',
  border: ' 1px solid',
  borderRadius: ' 8px',
  borderColor: ' var(--mui-palette-border-light)',
  [theme.breakpoints.down('xl')]: {
    width: 340,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const FilterSectionContainer = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr;
  grid-gap: 24px;
  margin-bottom: 30px;
`;

const FilterSection = styled('div')<{ hasTwoDivisions?: boolean }>(({ theme, hasTwoDivisions }) => ({
  height: '130px',
  padding: '20px',
  display: 'grid',
  gridTemplateColumns: hasTwoDivisions ? '1fr 1fr' : '1fr 1fr 2fr',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.border.grey}`,
  backgroundColor: theme.palette.primary.light,
}));

const FilterLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.purple,
  fontSize: '20px',
  fontWeight: 500,
}));

const DebitedIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.dark,
  width: 28,
  height: 28,
  borderRadius: 20,
  marginRight: 10,
}));

const CreditedIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.dark,
  width: 28,
  height: 28,
  borderRadius: 20,
  marginRight: 10,
}));

const IconStyle = { width: '33px', height: '33px' };

const TransactionStatus = styled(Typography)(() => ({
  // backgroundColor: theme.palette.background.grey,
  paddingBlock: 5,
  width: 120,
  marginInline: 'auto',
  textAlign: 'center',
  fontWeight: 500,
  borderRadius: 2,
}));

const AmountTypo = styled(Typography)<{ type: TransactionType }>(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 600,
}));

export {
  TableActionContainer,
  CreditWalletTableActionContainer,
  NavigationBtnContainer,
  NavigationBtn,
  FilterSectionContainer,
  FilterSection,
  FilterLabel,
  IconStyle,
  DebitedIcon,
  CreditedIcon,
  TransactionStatus,
  AmountTypo,
};
