import React from 'react';
import Dialog, { DialogClasses } from '@mui/material/Dialog';
import { styled, SxProps, Breakpoint } from '@mui/system';
import { Grid } from '@mui/material';
import { Theme } from '@emotion/react';

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  fullScreen?: boolean;
  sx?: SxProps<Theme>;
  classes?: Partial<DialogClasses>;
  dialogContainerClass?: string;
  dialogContainerSx?: SxProps<Theme>;
  maxWidth?: Breakpoint;
}

const DialogContainer = styled(Grid)(({ theme }) => ({
  // minWidth: '540px',
  // maxWidth: '640px',
  padding: theme.spacing(3),
}));

function Modal(props: ModalProps) {
  return (
    <Dialog
      maxWidth={props.maxWidth || 'lg'}
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={props.onClose}
      classes={props.classes}
      scroll={'paper'}
      sx={{ ...props.sx }}
    >
      <DialogContainer sx={props.dialogContainerSx} className={props.dialogContainerClass}>
        {props.children}
      </DialogContainer>
    </Dialog>
  );
}

export default Modal;
