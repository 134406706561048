import { SbLoadingButton } from '@Components/Button';
import { SingleDocumentType } from '@Components/DocumentUpload/Type';
import { getLoggedInUserKyc, getUserKyc } from '@Reducers/kyc';
import API from '@Services/apiAxios';
import { AppDispatch, RootStateType } from '@Store';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VerifyModal from './VerifyModal';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { GetDownloadFileUrl } from '@Reducers/kyc/actions';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const Wrapper = styled(Grid)`
  border-bottom: 1px solid;
  padding: 20px;
  &:last-child {
    border-bottom: none;
  }
`;

const StyleIconButton = styled(IconButton)(({ theme: _ }) => ({
  border: '1px solid #b2b2b2',
  padding: 8,
  borderRadius: 8,
  color: '#000',
  fontSize: '24px',
}));

function SingleDocument(props: SingleDocumentType) {
  // const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const {
    label,
    required,
    documentData,
    onDocumentUploadModalOpen,
    onDocumentDeleteModalOpen,
    accountNumber,
    kycVerified,
  } = props;

  const profileUserId = useSelector((state: RootStateType) => state.loginUser.profile?._id);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const [kycImage, setKycImage] = useState<string>('');

  const isViewKYCAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.view_kyc,
    PERFORM_ACTION.read,
  );

  const isDeleteKYCAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.delete_kyc,
    PERFORM_ACTION.write,
  );

  const isUploadKYCAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.upload_kyc,
    PERFORM_ACTION.write,
  );

  // const isViewKYCAllowed = true;

  // const isDeleteKYCAllowed = true;

  // const isUploadKYCAllowed = true;

  const [openVerfifyModal, setOpenVerifyModal] = useState<boolean>(false);

  const [loadingVerifying, setLoadingVerifying] = useState<boolean>(false);

  const verifyKyc = async () => {
    if (!profileUserId || !documentData) return;

    setLoadingVerifying(true);
    try {
      await API.post(`user/kyc/verify/${documentData._user}/${documentData._id}`);
      dispatch(getUserKyc(documentData._user));
      if (props.fetchList) props.fetchList();
      if (profileUserId === documentData._user) {
        dispatch(getLoggedInUserKyc());
      }
      setLoadingVerifying(false);
    } catch (error) {
      if (error.response?.data?.msg) {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Error',
            subTitle: error.response?.data?.msg,
          }),
        );
      }
      setLoadingVerifying(false);
    }
  };

  const handleVerifyModal = async () => {
    if (documentData?._document) {
      try {
        const res = await GetDownloadFileUrl(`/user/file/get-url/${documentData?._document}`);
        setKycImage(res?.data);
      } catch (error) {
        console.error(error);
      }
    }
    setOpenVerifyModal(true);
  };

  const handleCloseVerifyModal = () => {
    setOpenVerifyModal(false);
  };

  const isKycVerified = documentData?.status === 'verified';
  return (
    // <Grid container>
    <>
      <Wrapper
        container
        item
        alignItems="flex-center"
        // justifyContent="space-between"
        className={`border-grey`}
        //   spacing={2.5}
      >
        <Grid container item xs={12} md={4} lg={6} alignItems="center" spacing={0.5}>
          <Grid item xs={12}>
            <Typography className="md-text font-semibold" sx={{ mb: { xs: 1, md: 0 } }}>
              {label} {required ? '*' : ''}
            </Typography>
            <Typography className="md-text " sx={{ mb: { xs: 1, md: 0 } }}>
              {documentData?.value && documentData?.value}
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
          <Typography className="sm-text">{documentData?.value || '-'}</Typography>
        </Grid> */}
          {/* <SbTextField label={label} value={value} required={required} disabled className="w-full" /> */}
        </Grid>
        <Grid container item xs={12} md={8} lg={6} gap={1} justifyContent={{ lg: 'flex-end', xs: 'space-between' }}>
          {!documentData && isUploadKYCAllowed && (
            <StyleIconButton onClick={() => onDocumentUploadModalOpen(label, documentData)}>
              <AddIcon fontSize="inherit" />
            </StyleIconButton>
          )}
          {documentData && (
            <>
              <SbLoadingButton
                variant="outlined"
                className={isKycVerified ? 'sb-text-success' : 'sb-text-error'}
                sx={{
                  borderRadius: '8px !important',
                  border: '1px solid #b2b2b2',
                  color: '#000',
                  ':hover': {
                    border: '1px solid #b2b2b2',
                  },
                  cursor: isKycVerified ? 'unset' : 'cursor',
                  pointerEvents: 'none',
                }}
                // onClick={() => {
                //   !isKycVerified && verifyKyc();
                // }}
                loading={loadingVerifying}
                disabled={profileUserId === documentData._user}
                // disabled
              >
                {/* {isKycVerified ? 'Verified' : 'Verify'} */}
                {isKycVerified ? (
                  <>
                    Verified
                    <span className="flex">
                      <CheckCircleIcon className="ml-1 md-text sb-text-success pt-0" />
                    </span>
                  </>
                ) : (
                  <>
                    Verify
                    <span className="flex">
                      <ErrorOutlineIcon className="ml-1 md-text sb-text-error" />
                    </span>
                  </>
                )}
              </SbLoadingButton>
              {/* <Link to={`${CONFIG.API_URI}/user/file/get-url/${documentData?._document}`} target="_blank"> */}
              {isViewKYCAllowed && (
                <StyleIconButton onClick={handleVerifyModal}>
                  <VisibilityIcon fontSize="inherit" />
                </StyleIconButton>
              )}

              {/* </Link> */}
              {isDeleteKYCAllowed && (
                <StyleIconButton onClick={() => onDocumentDeleteModalOpen(documentData._id)}>
                  <DeleteIcon fontSize="inherit" />
                </StyleIconButton>
              )}
            </>
          )}
        </Grid>
      </Wrapper>
      <VerifyModal
        open={openVerfifyModal}
        onClose={handleCloseVerifyModal}
        verifyKyc={verifyKyc}
        isKycVerified={isKycVerified}
        // previewImage={`${CONFIG.API_URI}/user/file/get-url/${documentData?._document}`}
        previewImage={kycImage ? kycImage : ''}
        fileType={documentData?.documentObj.contentType || ''}
        accountNumber={accountNumber}
        kycVerified={kycVerified}
      />
    </>
    // </Grid>
  );
}

export default SingleDocument;
