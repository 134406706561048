import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import {
  RechargeWalletByAdminPayload,
  RechargeWalletByAdminPayloadResponse,
  RechargeWalletPayload,
  RechargeWalletResponse,
  WalletHistoryQuery,
} from './Type';
import { SuccessPayload, ErrorType } from '../../../types';
import { AxiosError } from 'axios';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const getWalletBalance = createAsyncThunk('wallet/current-balance', async (userId?: string) => {
  const res = await API.get(`/payment/wallet/current-balance${userId ? `/${userId}` : ''}`);
  return res.data;
});

const rechargeWalletRazorpayPayload = createAsyncThunk<
  SuccessPayload<RechargeWalletResponse>,
  RechargeWalletPayload,
  { rejectValue: ErrorType }
>('wallet/recharge-wallet', async (body, { rejectWithValue, dispatch }) => {
  try {
    const res = await API.post(`/payment/pay/recharge-wallet`, body);
    return res.data;
  } catch (e) {
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: e?.response?.data?.msg || 'Something went to wrong.',
      }),
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: AxiosError<ErrorType> = e as any;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const addMoneyToWalletByAdmin = createAsyncThunk<
  SuccessPayload<RechargeWalletByAdminPayloadResponse>,
  RechargeWalletByAdminPayload,
  { rejectValue: ErrorType }
>('wallet/recharge-wallet-by-admin', async (body, { dispatch }) => {
  try {
    const res = await API.post(`/payment/wallet/cash`, body);
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: 'Successfully money added to wallet.',
      }),
    );
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error.response.data;
  }
});

const getAllPaymentGetWay = createAsyncThunk('getAllPaymentGetway', async () => {
  const res = await API.get(`/payment/gateway-credential/all`);
  return res.data;
});

const getWalletTransactionList = createAsyncThunk(
  'getWalletTransactionList',
  async ([userId, queryParams]: [string | null, WalletHistoryQuery]) => {
    console.log('getWalletTransactionList');
    const res = await API.get(`/payment/wallet/all${userId ? `/${userId}` : ''}`, { params: queryParams });
    return res.data;
  },
);

const getUserTransactionList = createAsyncThunk(
  'getUserTransactionList',
  async (queryParams: WalletHistoryQuery, { dispatch }) => {
    try {
      const res = await API.get(`payment/transaction/user/all`, { params: queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error.response.data;
    }
  },
);

const getOutstandingBalanceForPrepaid = createAsyncThunk('wallet/outstanding-balance', async (userId?: string) => {
  const res = await API.get(`/shipment/outstanding/balance/user${userId ? `/${userId}` : ''}`);
  return res.data;
});

const getOutstandingBalanceInvoiceForPrepaid = createAsyncThunk(
  'wallet/outstanding-balance/invoice',
  async (userId?: string) => {
    const res = await API.get(`/invoice/outstanding/balance/user${userId ? `/${userId}` : ''}`);
    return res.data;
  },
);

export {
  getWalletBalance,
  rechargeWalletRazorpayPayload,
  getAllPaymentGetWay,
  getWalletTransactionList,
  getOutstandingBalanceForPrepaid,
  addMoneyToWalletByAdmin,
  getOutstandingBalanceInvoiceForPrepaid,
  getUserTransactionList,
};
