import { PayloadType, GetRatesStateType, CSPChargeType, chareableWeightObject } from './Type';

function getRatesPending(state: GetRatesStateType) {
  state.loading = true;
}

function getRatesFulfilled(state: GetRatesStateType, data: PayloadType) {
  const { payload } = data;

  state.loading = false;
  if (payload?.isError) {
    state.error = payload;
    state.ratesNotFund = true;
  } else {
    const charges = payload?.data?.charges.filter(rate => !rate.masked);
    fastestAndCheapest(charges);

    if (!charges.length) state.ratesNotFund = true;
    if (payload.rateFetchFor === 'create-shipment') state.redirectToCreateShipmentCSP = true;
    state.CSPChargesData = { ...payload.data, charges };
    state.rateFor = payload.rateFetchFor;
  }
}

function getRatesBuyingFulfilled(state: GetRatesStateType, data: PayloadType) {
  const { payload } = data;

  state.loading = false;
  if (payload?.isError) {
    state.error = payload;
    state.ratesNotFund = true;
  } else {
    const charges = payload?.data?.charges;

    fastestAndCheapest(charges);
    if (!charges.length) state.ratesNotFund = true;
    state.buyingRates = { ...payload.data, charges };
  }
}

// function getChargeableWeightFulfilled(state: GetRatesStateType, data: chareableWeightObject) {
function getChargeableWeightFulfilled(state: GetRatesStateType, action: { payload: chareableWeightObject }) {
  state.loading = false;
  state.chareableWeight = action.payload;
  state.MaximumCharegableWeight = action?.payload?.maxChargeableWt;
}

function fastestAndCheapest(charges: CSPChargeType[]) {
  const rateObj: {
    fastestIndex: number;
    cheapestIndex: number;
    fastestTransitTimeIndexes: number[];
    cheapestAmountIndexes: number[];
  } = {
    fastestIndex: 0,
    cheapestIndex: 0,
    fastestTransitTimeIndexes: [],
    cheapestAmountIndexes: [],
  };

  for (const currRateIndex in charges) {
    if (charges[currRateIndex].totalPricing < charges[rateObj.cheapestIndex].totalPricing) {
      rateObj.cheapestIndex = Number(currRateIndex);
      rateObj.cheapestAmountIndexes = [Number(currRateIndex)];
    } else if (charges[currRateIndex].totalPricing === charges[rateObj.cheapestIndex].totalPricing) {
      rateObj.cheapestAmountIndexes.push(Number(currRateIndex));
    }

    if (charges[currRateIndex].transitTime < charges[rateObj.fastestIndex].transitTime) {
      rateObj.fastestIndex = Number(currRateIndex);
      rateObj.fastestTransitTimeIndexes = [Number(currRateIndex)];
    } else if (charges[currRateIndex].transitTime === charges[rateObj.fastestIndex].transitTime) {
      rateObj.fastestTransitTimeIndexes.push(Number(currRateIndex));
    }
  }
  if (charges.length) {
    // charges[rateObj.cheapestIndex].cheapest = true;
    if (rateObj.fastestTransitTimeIndexes.length !== charges.length) {
      rateObj.fastestTransitTimeIndexes.forEach(fastestIndex => {
        charges[fastestIndex].fastest = true;
      });
    }
    if (rateObj.cheapestAmountIndexes.length !== charges.length) {
      rateObj.cheapestAmountIndexes.forEach(cheapestIndex => {
        charges[cheapestIndex].cheapest = true;
      });
    }
  }
  return charges;
}

function getRatesRejected(state: GetRatesStateType) {
  state.loading = false;
  state.error = { msg: 'No rates found.', isError: true };
  state.ratesNotFund = true;
}

export { getRatesPending, getRatesFulfilled, getRatesRejected, getRatesBuyingFulfilled, getChargeableWeightFulfilled };
