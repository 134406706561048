/* eslint-disable @typescript-eslint/no-explicit-any */
import development from './development';
import staging from './staging';
import { appEnvType, configJsonType, constantType } from './Type';
import production from './production';
import preprod from './preprod';

const configJson: configJsonType = {
  production,
  development,
  staging,
  preprod,
};

declare global {
  interface Window {
    __DEV__: boolean;
    __PROD__: boolean;
    Razorpay: any;
  }
}

const APP_ENV: appEnvType = import.meta.env.VITE_NODE_ENV?.toLowerCase().trim() || 'development';

if (APP_ENV === 'development') window.__DEV__ = true;
else if (APP_ENV === 'production') window.__PROD__ = true;

const logger = console.log;
const CONSOLE_DEBUG = '';

console.log = function (...params) {
  if (!window.__DEV__) return;
  if (CONSOLE_DEBUG) {
    if (params[0] === CONSOLE_DEBUG) logger(...params);
    return;
  }
  logger(...params);
};

const CONSTANT: constantType = configJson[APP_ENV];

export default CONSTANT;
