import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import SBDropdown from '../../components/Dropdown';
import SbButton from '../../components/Button';
import { Grid } from '@mui/material';
import { FileDimentionOptions, FileFormatOptions } from '../../constants/file';
import { FileDownloadOptionPropsType } from './Types';

interface PropsType extends FileDownloadOptionPropsType {
  handleClose: () => void;
}

export default function DownloadOptions({ title, downloadOption, isOpen, handleClose, handleDownload }: PropsType) {
  const [selectedFileType, setSelectedFileType] = useState<string>('');

  useEffect(() => {
    return () => setSelectedFileType('');
  }, []);

  return (
    <Modal
      open={isOpen}
      dialogContainerSx={{
        paddingTop: 1,
        paddingRight: 1,
        paddingLeft: 3,
      }}
      // onClose={handleClose}
    >
      <div className="pr-2 pt-1" style={{ width: '580px' }}>
        <div style={{ textAlign: 'center' }}>
          <Title title={title} removeBorder />
        </div>
        <Grid container mb={2}>
          <SBDropdown
            id="file-type"
            label={`Select File ${downloadOption === 'fileFormat' ? 'Format' : 'Dimention'}`}
            // placeholder="Select Admin Username"
            name="status"
            value={[selectedFileType || '']}
            options={downloadOption === 'fileFormat' ? FileFormatOptions : FileDimentionOptions}
            onChange={([value]) => setSelectedFileType(value)}
          />
        </Grid>
        <div className="flex mt-3" style={{ justifyContent: 'center' }}>
          <SbButton variant="outlined" sx={{ borderRadius: '8px !important', mr: 1 }} onClick={handleClose}>
            Cancel
          </SbButton>
          <SbButton
            variant="contained"
            sx={{ borderRadius: '8px !important', ml: 1 }}
            onClick={() => {
              if (selectedFileType) {
                handleDownload(selectedFileType);
                handleClose();
              }
            }}
          >
            Download
          </SbButton>
        </div>
      </div>
    </Modal>
  );
}
