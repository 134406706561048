import { Grid, Typography, styled } from '@mui/material';
import React from 'react';
import Image from '../../../components/Image';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { doLogout } from '@Reducers/LoginForm';

const Wrapper = styled(Grid)(({ theme }) => ({
  boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  border: '1px solid #EAECF0',
  borderRadius: '8px',
  padding: theme.spacing(1, 5),
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(1, 5),
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    padding: '0px',
    marginBottom: theme.spacing(2),
  },
}));
const LargeTypo = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.5),
  },
}));
const Typo = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  [theme.breakpoints.down('xl')]: {
    fontSize: theme.spacing(1.4),
  },
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.2),
  },
}));

function Header() {
  const profileState = useSelector((state: RootStateType) => state.loginUser.profile);
  const orgPublicData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { name = '', logo = '' } = orgPublicData || {};
  const dispatch = useDispatch<AppDispatch>();
  const handleLogout = () => {
    // @prem to look at this
    // alert('user is logged out');
    dispatch(doLogout(null));
  };

  return (
    <>
      <Wrapper container justifyContent={'space-between'} alignItems={'center'}>
        <Grid container item xs={12} md={6} justifyContent={'start'} alignItems={'center'}>
          <Image src={String(logo || '')} alt={name} height={70} style={{ aspectRatio: '17 16' }} />
          <LargeTypo sx={{ marginLeft: { md: 3, xs: 1 } }}>{name}</LargeTypo>
        </Grid>
        <Grid container item xs={12} md={6} justifyContent={'end'} alignItems={'center'}>
          <Typo className="mr-2">{profileState?.name}</Typo>
          <span onClick={handleLogout} className="cursor-pointer">
            <Image src={'/images/logout.svg'} alt={'Speedbox'} width={24} height={24} />
          </span>
        </Grid>
      </Wrapper>
      <LargeTypo sx={{ marginBottom: { md: 2, xs: 1 } }}>Orgainzation setup</LargeTypo>
    </>
  );
}

export default Header;
