import React from 'react';
import { styled } from '@mui/system';
import Image from '../../components/Image';
import { Typography } from '@mui/material';
import RightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { userLogin } from '@Services/hasAdminAccess';

const NavigationBtnContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: '24px',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));

const NavigationBtn = styled(Link)`
  text-decoration: none;
  color: unset;
  height: 92px;
  padding: 20px;
  display: grid;
  grid-template-columns: 32px 1fr 10px;
  align-items: center;
  border: 1px solid;
  border-radius: 7px;
  border-color: var(--mui-palette-border-light);
  cursor: pointer;
  &:hover {
    background-color: var(--mui-palette-primary-light);
  }
`;

function Navigation() {
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const isUserLogin = userLogin();

  const isGetRatesAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.get_rates,
    PERFORM_ACTION.write,
  );

  const isCreateBulkShipmentsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.create_bulk_shipments,
    PERFORM_ACTION.write,
  );

  const isCreateSingleShipmentAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.create_single_shipment,
    PERFORM_ACTION.write,
  );

  return (
    <NavigationBtnContainer>
      <NavigationBtn
        className={`${!isUserLogin ? (isGetRatesAllowed ? '' : 'cursor-not-allowed') : ''}`}
        to={!isUserLogin ? (isGetRatesAllowed ? '/get-rates' : '') : '/get-rates'}
      >
        <TroubleshootOutlinedIcon fontSize="large" />
        <Typography className="md-text" style={{ marginLeft: '16px' }}>
          Get Rates
        </Typography>
        <RightIcon width={'17px'} height="17px" style={{ justifySelf: 'flex-end' }} />
      </NavigationBtn>
      <NavigationBtn
        className={`${!isUserLogin ? (isCreateSingleShipmentAllowed ? '' : 'cursor-not-allowed') : ''}`}
        to={!isUserLogin ? (isCreateSingleShipmentAllowed ? '/shipment/create-single' : '') : '/shipment/create-single'}
      >
        <Image src="/images/icons/SingleShipment.svg" alt="Search Rate" />
        <Typography className="md-text" style={{ marginLeft: '16px' }}>
          Place a Single Shipment
        </Typography>
        <RightIcon width={'17px'} height="17px" style={{ justifySelf: 'flex-end' }} />
      </NavigationBtn>
      <NavigationBtn
        className={`${!isUserLogin ? (isCreateBulkShipmentsAllowed ? '' : 'cursor-not-allowed') : ''}`}
        to={!isUserLogin ? (isCreateBulkShipmentsAllowed ? '/shipment/create-bulk' : '') : '/shipment/create-bulk'}
      >
        <Image src="/images/icons/BulkShipment.svg" alt="Search Rate" />
        <Typography className="md-text" style={{ marginLeft: '16px' }}>
          Place a bulk Shipment
        </Typography>
        <RightIcon width={'17px'} height="17px" style={{ justifySelf: 'flex-end' }} />
      </NavigationBtn>
    </NavigationBtnContainer>
  );
}

export default Navigation;
