import { Grid, IconButton, TableBody, TableFooter, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { InfoRounded } from '@mui/icons-material';
import SbTooltip from '../../../../components/Tooltip';
import { SectionSubText, SectionTitle } from '../../Style';
import { getFormattedKey } from '../../../../services/getFormattedKey';
import { OrganizationSetupContextType } from '../../../../types/organization';
import { SBTable, SBTableWrapper } from '../../../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../../../components/Table/TableCell';
import { SBTableRow } from '../../../../components/Table/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import SbTextField from '../../../../components/Textfield';
import SbButton from '../../../../components/Button';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

interface PropsType {
  data: OrganizationSetupContextType['sacCode'];
  onChange: OrganizationSetupContextType['setSacCode'];
}

const TABLE_HEADER_COLS = ['Sr. No.', 'SAC code', 'Action'];

function SACCode(props: PropsType) {
  const { data: sacCode, onChange: setSacCode } = props;

  const [code, setCode] = useState<string>('');

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isBillingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.billing,
    PERFORM_ACTION.write,
  );

  function handleAddMore() {
    const tempData = [...sacCode];
    if (!tempData.some(c => c.display === code)) {
      tempData.push({ display: code, key: getFormattedKey(code) });
      setSacCode([...tempData]);
      setCode('');
    }
  }

  function handleDelete(ind: number) {
    const tempData = [...sacCode];
    tempData.splice(ind, 1);
    setSacCode([...tempData]);
  }

  return (
    <>
      <Grid container className="mb-3">
        <Grid container justifyContent={'start'} alignItems={'center'} className="mb-1">
          <SectionTitle className="mr-2">SAC code</SectionTitle>
          <SbTooltip
            placement="right"
            title={`The SAC code will be visible on Invoice. You can add multiple SAC codes`}
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <SectionSubText width={{ xs: '100%', md: '60%' }}>
          Individual users will be able to upload their KYC from below options. You can select the ones you want to make
          mandatory by clicking the checkbox.
        </SectionSubText>
      </Grid>
      <Grid container item xs={12} md={12} lg={7} className="mb-3">
        <SBTableWrapper>
          <SBTable className="rounded-md">
            <TableHead>
              <TableRow>
                {TABLE_HEADER_COLS.map(x => (
                  <TableHeadCell key={x}>{x}</TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sacCode.map((code, ind) => (
                <SBTableRow key={ind}>
                  <TableRowCell width={'15%'} align="center" className="md-text font-medium">
                    {ind + 1}
                  </TableRowCell>
                  <TableRowCell width={'55%'} align="left" className="md-text font-medium">
                    {code.display}
                  </TableRowCell>
                  <TableRowCell align="center">
                    {isBillingWriteAllowed && (
                      <Grid container justifyContent="center">
                        <IconButton onClick={() => handleDelete(ind)}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </TableRowCell>
                </SBTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <SBTableRow>
                <TableRowCell>
                  <></>
                </TableRowCell>
                <TableRowCell>
                  <SbTextField
                    className="px-2"
                    type="number"
                    placeholder="Enter"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    style={{ minWidth: '230px' }}
                    disabled={!isBillingWriteAllowed}
                  />
                </TableRowCell>
                <TableRowCell align="center">
                  <SbButton variant="contained" disabled={!code} onClick={handleAddMore} style={{ minWidth: '130px' }}>
                    Add more
                  </SbButton>
                </TableRowCell>
              </SBTableRow>
            </TableFooter>
          </SBTable>
        </SBTableWrapper>
      </Grid>
    </>
  );
}

export default SACCode;
