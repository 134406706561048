import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GridWrapper, Heading, SubHeading } from './styles';
import CSPDetails from './CSPDetails';
import ShipmentSummaryPickupType from './ShipmentSummaryPickupType';
import SbTextField from '../../../components/Textfield';
import Charges from './Charges';
import CreateShipmentContext from '../../../context/CreateShipment';
import { createShipmentContextType } from '../../../types/shipment';
import { AppDispatch, RootStateType } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { placeShipment, redirectToSuccessPage, resetEditShipment } from '../../../store/reducers/Shipment';
import Divider from '../../../components/Divider';
import {
  ShipperConsigneeSummaryDetails,
  EditAction,
} from '../../../components/Shipment/Summary/ShipperConsigneeSummaryDetails';
import ShipmentSummaryDetails from '../../../components/Shipment/Summary/ShipmentSummaryDetails';
import { ShipmentType } from '../../../store/reducers/Shipment/Type';
import { PayNow } from '@Components/PayNow';
import { getAddressObj, getSingleShipmentPackagePayload } from '@Services/getShipmentPayload';
import { usePayment } from '@Hook/usePayment';
import SbButton from '@Components/Button';
import { ErrorType } from './Type';

function ShipmentSummary() {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { setOpenPaymentModal, hasPaymentAccess } = usePayment();
  const {
    shipper,
    consignee,
    shipmentType,
    extraDetails,
    packageData,
    notes,
    boxes,
    pickupType,
    // chargeableWeight,
    setNotes,
    setShipper,
    setConsignee,
    userId,
    selectedCSP,
  } = React.useContext(CreateShipmentContext) as createShipmentContextType;

  const { shipmentSummary, editShipment, finalChargeableWeight } = useSelector(
    (state: RootStateType) => state.shipment,
  );
  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);

  const { pickupTimeSlots } = shipmentSetting || {};
  const [errors, setErrors] = useState<ErrorType>();

  const shipmentNumber = shipmentSummary?.shipmentNumber || '';
  const sellingPricing = selectedCSP?.totalPricing;

  const { warehouse, selectedWearhouseAddress } = pickupType;
  const { shipmentMode, referenceNumber } = shipmentType;
  const { insurance, packaging, unitOfMeasurement } = packageData;

  useEffect(() => {
    return () => {
      dispatch(resetEditShipment());
    };
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    setNotes(value);
  }

  function handleDetailsChange(event: React.ChangeEvent<HTMLInputElement>, label: string) {
    const { value } = event.target;
    if (label === 'Shipper') {
      setShipper({ ...shipper, name: value });
    } else if (label === 'Consignee') {
      setConsignee({ ...consignee, name: value });
    }
  }

  function handlePlaceOrder() {
    const errorData: ErrorType = {};
    if (!shipper.name) {
      errorData['shipperName'] = 'Please add name.';
      setErrors(errorData);
    }
    if (!consignee.name) {
      errorData['consigneeName'] = 'Please add name.';
      setErrors(errorData);
    }
    if (pickupType.type === 'dropOff' && !pickupType.warehouse) {
      errorData['warehouse'] = 'Please choose the drop-off warehouse.';
      setErrors(errorData);
      return;
    } else if (pickupType.type === 'pickUp') {
      if (!pickupType.date) {
        errorData['date'] = 'Please choose a pickup date ';
        setErrors(errorData);
        return;
      }
      if (pickupTimeSlots?.slots?.length && !pickupType.timeSlot) {
        errorData['timeSlot'] = 'Please choose a  time slot.';
        setErrors(errorData);
        return;
      }
    }

    if ((!errorData || (errorData && !Object.keys(errorData).length)) && editShipment) {
      setErrors(undefined);
      const packageObj = getSingleShipmentPackagePayload({ boxes, packageData, shipmentType, pickupType });
      const postObj: ShipmentType = {
        package: packageObj,
        shipper: getAddressObj(shipper),
        consignee: getAddressObj(consignee),
        shipmentNotes: notes,
        shipmentMode: shipmentType.shipmentMode,
        selectedCourierId: selectedCSP?._courierId,
        selectedSystemId: selectedCSP?._systemId,
        extraDetails: {
          shipper: {
            type: shipper.type,
            IdType: shipper.IdType,
            IdNumber: shipper.IdNumber,
          },
          consignee: {
            type: consignee.type,
            IdType: consignee.IdType,
            IdNumber: consignee.IdNumber,
          },
          other: extraDetails,
        },
      };
      if (pickupType.type === 'dropOff') {
        postObj.warehouseAddress = selectedWearhouseAddress;
        postObj.warehouseId = warehouse;
      }
      dispatch(placeShipment({ postObj: { ...postObj }, userId, showPaymentModal: hasPaymentAccess }));
    } else {
      setOpenPaymentModal(true);
    }
  }

  function handlePaymentSuccess() {
    dispatch(redirectToSuccessPage(true));
  }

  return (
    <Grid>
      <Heading className="mb-3">Summary</Heading>
      <Grid container sx={{ p: { xs: 0, lg: 3 } }} spacing={3}>
        <Grid item lg={12} xs={12}>
          <GridWrapper className="b-0" hasBackground>
            <Grid container justifyContent={'space-between'}>
              <SubHeading>Service</SubHeading>
              {editShipment && isMobile && (
                <Grid item>
                  <EditAction activeStep={3} />
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent={'space-between'}>
              <Grid item lg={11}>
                <CSPDetails />
              </Grid>
              {editShipment && !isMobile && (
                <Grid item>
                  <EditAction activeStep={3} />
                </Grid>
              )}
            </Grid>
          </GridWrapper>
        </Grid>
        <Grid container item lg={8} xs={12} spacing={3}>
          <Grid item lg={12}>
            <GridWrapper container>
              <Grid container item lg={6} className={isMobile ? '' : 'border-right pr-3'}>
                <ShipperConsigneeSummaryDetails
                  label="Shipper"
                  {...shipper}
                  activeStep={0}
                  handleChange={handleDetailsChange}
                  editShipment={editShipment}
                  error={errors?.shipperName || ''}
                />
              </Grid>
              {isMobile && <Divider className="my-2" />}
              <Grid container item lg={6} sx={{ pl: { lg: 3, xs: 0 } }}>
                <ShipperConsigneeSummaryDetails
                  label="Consignee"
                  {...consignee}
                  activeStep={1}
                  handleChange={handleDetailsChange}
                  editShipment={editShipment}
                  error={errors?.consigneeName || ''}
                />
              </Grid>
            </GridWrapper>
          </Grid>
          <Grid item lg={12}>
            <GridWrapper container>
              <ShipmentSummaryPickupType errorData={errors} />
            </GridWrapper>
          </Grid>
          <Grid item lg={12}>
            <GridWrapper container>
              <ShipmentSummaryDetails
                boxes={boxes.length}
                // chargeableWeight={chargeableWeight}
                chargeableWeight={finalChargeableWeight}
                shipmentType={shipmentType.type}
                shipmentMode={shipmentMode}
                insurance={insurance}
                packaging={packaging}
                unitOfMeasurement={unitOfMeasurement}
                activeStep={2}
                editShipment={editShipment}
                referenceNumber={referenceNumber}
                purposeOfShipment={extraDetails?.purposeOfShipment}
                ior={extraDetails?.ior}
              />
              {isMobile && <Divider className="my-2" />}
              <Grid container item xs={12} lg={6} sx={{ pl: { lg: 3, xs: 0 } }}>
                <Grid item lg={12} xs={12}>
                  <SubHeading>Add a Note</SubHeading>
                  <SbTextField
                    placeholder="Enter a description..."
                    // label="Description"
                    onChange={handleChange}
                    name="description"
                    value={notes}
                    required
                    // error={errors.description}
                    rows={8}
                    multiline
                    className="w-full"
                  />
                </Grid>
              </Grid>
            </GridWrapper>
          </Grid>
        </Grid>
        <Grid container item lg={4} xs={12}>
          <Grid item xs={12} sm={12} lg={12}>
            <Charges />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
        {!hasPaymentAccess ? (
          <SbButton
            onClick={handlePlaceOrder}
            variant="contained"
            style={{ height: '44px' }}
            sx={{ my: { lg: 0, xs: 3 } }}
          >
            Place Shipment
          </SbButton>
        ) : (
          <Grid item xs={2}>
            <PayNow
              handlePayNow={handlePlaceOrder}
              paymentDetails={{
                shipmentNumber,
                payableAmount: Number(sellingPricing),
                handlePaymentSuccess,
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ShipmentSummary;
