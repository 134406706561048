import { Grid } from '@mui/material';
import React from 'react';
import ChargeTitle from '../Charges/ChargeTitle';
import SingleCharge from '../Charges/SingleCharge';
import Divider from '@Components/Divider';
import Comments from './Comment';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';
import { formatNumber, useLocale } from '../../../../utils/formatNumber';

function PaymentCompletedDetails() {
  const shipmentpayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);
  const { amount, payableAmount = 0, totalPaidAmt = 0, tdsAmount = 0, _transactionsId } = shipmentpayment || {};
  const successfulTransactions = _transactionsId?.filter(payment => payment.status === 'successful') || [];

  const totalAmount = successfulTransactions.reduce((sum, payment) => sum + payment.amount, 0);
  const totalTdsAmount = successfulTransactions.reduce((sum, payment) => sum + payment.tdsAmount, 0);
  const locale = useLocale();

  return (
    <Grid item xs={12} sm={12} lg={6} xl={4} px={2}>
      <ChargeTitle />
      <Divider className="my-1" />
      <SingleCharge displayKey="Total Amount" value={(amount && formatNumber(amount, locale)) || 0} />
      <SingleCharge
        displayKey="Paid"
        value={tdsAmount == 0 ? formatNumber(totalPaidAmt, locale) : formatNumber(totalAmount, locale)}
      />
      <SingleCharge
        displayKey="Deductions"
        value={tdsAmount != 0 ? formatNumber(tdsAmount, locale) : formatNumber(totalTdsAmount, locale)}
      />
      <SingleCharge
        displayKey="Amount Due"
        value={formatNumber(payableAmount === 0 ? 0 : payableAmount - tdsAmount, locale)}
      />

      <Divider className="my-1" />
      <Comments />
    </Grid>
  );
}

export default PaymentCompletedDetails;
