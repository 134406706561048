import React from 'react';

import SbButton from '@Components/Button';
import { Grid, TableBody, TableHead, TableRow } from '@mui/material';

import DeleteModal from '@Components/Modal/Delete';
import ErrorModal from '@Components/Modal/error';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { useChargesTable } from '@Hook/useChargesTable';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SBDropdown from '../../../components/Dropdown';
import SbTextField from '../../../components/Textfield';
import { ChargeFormType, ChargeTypeObject, GlobalChargeFormType, SelectedChargeFormProps } from './type';

const ChargeTypeList: ChargeTypeObject[] = [
  { display: 'Fixed', value: 'fixed' },
  { display: 'Percentage', value: 'percentage' },
];

interface ChargeTableProps {
  charges: GlobalChargeFormType['charges'];
  updateCharges: (i: GlobalChargeFormType['charges']) => void;
}

interface ChargeRowProps {
  charge: ChargeFormType;
  index: number;
  setSelectedCharge: (values: ChargeFormType) => void;
  setAvailableApplyOn: (index: number) => void;
  onDeleteCharge: (index: number, chargeName: string) => void;
}
function Actions({ charge, index, setSelectedCharge, setAvailableApplyOn, onDeleteCharge }: ChargeRowProps) {
  const onEdit = () => {
    setSelectedCharge({ ...charge, isEdit: true });
    setAvailableApplyOn(index);
  };

  const onDelete = () => {
    onDeleteCharge(index, charge?.chargeName);
  };

  return (
    <>
      <div className="flex justify-center">
        <SbButton onClick={onEdit}>
          <EditIcon />
        </SbButton>
        <SbButton className="ml-2" onClick={onDelete}>
          <DeleteIcon />
        </SbButton>
      </div>
      {/* {showModal && <DeleteModal title="Delete Charge" open={true} onClose={onCloseModal} onConfirm={onDelete} />} */}
    </>
  );
}

function ChargeTable(props: ChargeTableProps) {
  const {
    showDependencyModal,
    deleteModal,
    handleDeleteCharge,
    onCloseDependencyModal,
    onCloseDeleteModal,
    onConfirmDeleteCharge,
    chargeFormik,
    availableApplyOn,
    handleAvailableApplyOn,
  } = useChargesTable({ ...props });

  return (
    <>
      <ErrorModal
        icon="/images/Boxes/NoRatesFound.svg"
        open={showDependencyModal}
        // title="Oops!"
        subTitle="Delete the dependency prior to removing the charge."
        onClose={onCloseDependencyModal}
        onConfirm={onCloseDependencyModal}
        btnText="Close"
      />

      <DeleteModal
        icon="/images/Boxes/WarningContrast.svg"
        open={Boolean(deleteModal?.open)}
        title="Are You Sure?"
        subTitle="Do you want to delete this charge?"
        onClose={onCloseDeleteModal}
        onConfirm={onConfirmDeleteCharge}
      />
      <AddChargeForm {...props} />
      <SBTableWrapper className="mb-3">
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell className="uppercase">Charge Name</TableHeadCell>
              <TableHeadCell className="uppercase">Percentage</TableHeadCell>
              <TableHeadCell className="uppercase">Fixed</TableHeadCell>
              <TableHeadCell className="uppercase">Apply On</TableHeadCell>
              <TableHeadCell className="uppercase">Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.charges?.length ? (
              props.charges.map((charge, index) =>
                chargeFormik.values?._id === charge._id ? (
                  <EditChargeForm
                    showBackgroundColor
                    formType={'edit'}
                    values={chargeFormik.values}
                    errors={chargeFormik.errors}
                    handleChange={chargeFormik.handleChange}
                    setFieldValue={chargeFormik.setFieldValue}
                    handleSubmit={chargeFormik.handleSubmit}
                    availableApplyOn={availableApplyOn}
                    key={index}
                  />
                ) : (
                  <TableRow key={index}>
                    <TableRowCell className="md-text font-medium text-center" sx={{ width: '25%' }}>
                      {charge.chargeName}
                    </TableRowCell>
                    <TableRowCell className="md-text font-medium text-center" sx={{ width: '20%' }}>
                      {charge?.chargeType === 'percentage' ? charge.value + '%' : '--'}
                    </TableRowCell>
                    <TableRowCell className="md-text font-medium text-center" sx={{ width: '20%' }}>
                      {charge?.chargeType === 'fixed' ? charge.value : '--'}
                    </TableRowCell>
                    <TableRowCell className="md-text font-medium text-center" sx={{ width: '30%' }}>
                      {charge?.selectedApplyOn?.length ? charge?.selectedApplyOn.join(', ') : '--'}
                    </TableRowCell>
                    <TableRowCell sx={{ width: '5%' }}>
                      <Actions
                        charge={charge}
                        index={index}
                        setSelectedCharge={chargeFormik.setValues}
                        setAvailableApplyOn={handleAvailableApplyOn}
                        onDeleteCharge={handleDeleteCharge}
                      />
                    </TableRowCell>
                  </TableRow>
                ),
              )
            ) : (
              <TableRow>
                <TableRowCell colSpan={5} className="text-center">
                  No added charges.
                </TableRowCell>
              </TableRow>
            )}
          </TableBody>
        </SBTable>
      </SBTableWrapper>
    </>
  );
}

function AddChargeForm(props: ChargeTableProps) {
  // const { values, errors, setFieldValue, handleChange, handleSubmit, availableApplyOn } = props;

  const { chargeFormik, availableApplyOn } = useChargesTable({ ...props });

  const { values, errors, handleChange, setFieldValue, handleSubmit } = chargeFormik;
  console.log({ errors });
  return (
    <>
      <Grid item container xs={12} alignItems="baseline" spacing={2} className="mb-4">
        <Grid container item columnSpacing={{ xs: 2, md: 4 }} sx={{ alignItems: 'flex-start' }}>
          <Grid item xs={12} md={2.75}>
            <SbTextField
              // required
              label="Charge Name"
              name="chargeName"
              value={values?.chargeName}
              error={errors?.chargeName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={2.75}>
            <SBDropdown
              id="ChargeType"
              required
              label="Charge Type"
              placeholder="Select"
              options={ChargeTypeList}
              onChange={([value]) => setFieldValue?.('chargeType', value)}
              value={values?.chargeType ? [values?.chargeType] : []}
              error={errors?.chargeType}
            />
          </Grid>
          <Grid item xs={12} md={2.75}>
            <SbTextField
              required
              label="Value"
              name="value"
              type="number"
              value={values?.value}
              error={errors?.value}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={2.75}>
            <SBDropdown
              id="ApplyOn"
              label="Apply On"
              options={availableApplyOn}
              multiple
              disabled={values?.chargeType === 'fixed'}
              placeholder="Select"
              onChange={value => {
                console.log(' selected apply on ', value);
                setFieldValue?.('selectedApplyOn', value);
              }}
              value={values?.selectedApplyOn || []}
              error={errors?.selectedApplyOn as string}
            />
          </Grid>
          <Grid item xs={12} md={1} sx={{ marginTop: '24px' }}>
            <SbButton
              fullWidth
              variant="contained"
              containerClass="rounded"
              onClick={_event => {
                handleSubmit?.();
              }}
            >
              Add
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function EditChargeForm(props: SelectedChargeFormProps) {
  const { values, errors, setFieldValue, handleChange, handleSubmit, availableApplyOn, key } = props;

  return (
    <>
      <TableRow sx={{ alignItems: 'end' }} key={key}>
        <TableRowCell sx={{ width: '25%' }}>
          <SbTextField
            required
            name="chargeName"
            value={values?.chargeName}
            error={errors?.chargeName}
            onChange={handleChange}
          />
        </TableRowCell>
        <TableRowCell sx={{ width: '20%' }}>
          {values?.chargeType === 'fixed' ? (
            '--'
          ) : (
            <SbTextField
              required
              name="value"
              type="number"
              value={values?.value}
              error={errors?.value}
              onChange={handleChange}
            />
          )}
        </TableRowCell>
        <TableRowCell sx={{ width: '20%' }}>
          {values?.chargeType === 'percentage' ? (
            '--'
          ) : (
            <SbTextField
              required
              name="value"
              type="number"
              value={values?.value}
              error={errors?.value}
              onChange={handleChange}
            />
          )}
        </TableRowCell>
        <TableRowCell sx={{ width: '30%' }}>
          <SBDropdown
            id="ApplyOn"
            options={availableApplyOn}
            multiple
            disabled={values?.chargeType === 'fixed'}
            placeholder="Select"
            onChange={value => {
              console.log(' selected apply on ', value);
              setFieldValue?.('selectedApplyOn', value);
            }}
            value={values?.selectedApplyOn || []}
            error={errors?.selectedApplyOn as string}
          />
        </TableRowCell>
        <TableRowCell sx={{ width: '5%' }}>
          <SbButton
            fullWidth
            variant="contained"
            containerClass="rounded"
            onClick={_event => {
              handleSubmit?.();
            }}
          >
            Save
          </SbButton>
        </TableRowCell>
      </TableRow>
    </>
  );
}

export default ChargeTable;
