import React, { useEffect, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  InputBaseComponentProps,
  InputLabel,
  OutlinedInput,
  SxProps,
} from '@mui/material';
import { INTEGER_REGEXP, NUMBER_REGEXP } from '../../constants/regEx';

export interface TextFieldProps {
  placeholder?: string;
  label?: string;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  className?: string;
  inputClassName?: string;
  type?: 'number' | 'integer' | 'string' | 'password' | 'text';
  style?: React.CSSProperties;
  disabled?: boolean;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onPaste?: React.ClipboardEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  error?: string;
  name?: string;
  multiline?: boolean;
  required?: boolean;
  number?: boolean;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  inputRef?: React.Ref<any>;
  sx?: SxProps;
  rows?: number;
  showInputProps?: boolean;
  maxLength?: number;
  helperText?: string;
  showCharCount?: boolean;
}

function SbTextField(props: TextFieldProps) {
  const { type, showInputProps = true, maxLength, value = '', showCharCount } = props;
  const [charCount, setCharCount] = useState<number | undefined>(value ? String(value).length : undefined);

  useEffect(() => {
    if (value) {
      setCharCount(String(value).length);
    } else {
      setCharCount(undefined);
    }
  }, [value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (props.onChange) {
      if (type === 'number' || type === 'integer') {
        const regExp = type === 'number' ? NUMBER_REGEXP : INTEGER_REGEXP;
        if (regExp.test(event.target.value) || event.target.value === '') {
          props.onChange(event);
          setCharCount(value.length);
        }
      } else {
        props.onChange(event);
        setCharCount(value.length);
      }
    }
  };

  const inputProps: InputBaseComponentProps = {
    ...(showInputProps && type === 'number' ? { type: 'number', step: '0.01', min: '1' } : {}),
    ...(maxLength ? { maxLength: maxLength } : {}),
  };
  return (
    <div style={props.style || {}} className={props.className || ''}>
      {props.label && (
        <InputLabel className={props.label ? ' mb-1' : ''} required={props.required}>
          {props.label}
        </InputLabel>
      )}
      <FormControl className={`bg-white ${props.inputClassName}`} fullWidth>
        <OutlinedInput
          multiline={props.multiline}
          rows={props.rows}
          error={props.error ? true : false}
          name={props.name}
          type={props.type === 'number' ? 'string' : props.type}
          className={props.inputClassName}
          placeholder={props.placeholder || ''}
          endAdornment={props.endAdornment || <></>}
          startAdornment={props.startAdornment || <></>}
          sx={{ ...props.sx }}
          disabled={!!props.disabled}
          value={props.value || ''}
          onChange={onChange}
          inputRef={props.inputRef}
          onBlur={props.onBlur}
          required={props.required}
          onPaste={props.onPaste}
          // inputProps={props.type === 'number' ? { type: 'number', step: '0.01', min: '1' } : {}}
          // inputProps={showInputProps && props.type === 'number' ? { type: 'number', step: '0.01', min: '1' } : {}}
          inputProps={inputProps}
          onClick={props.onClick}
        />
        {props.error && (
          <FormHelperText error className="m-0">
            {typeof props.error === 'string' ? props.error : 'Invalid Data'}
          </FormHelperText>
        )}
        {showCharCount && charCount !== undefined && (
          <div style={{ textAlign: 'right', marginTop: 4, fontSize: '0.875rem', color: '#888' }}>{charCount}</div>
        )}
      </FormControl>
    </div>
  );
}

export default SbTextField;
