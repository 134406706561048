import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const FormContent = styled('div')`
  max-width: 512px;
`;

const FormLabel = styled(Typography)`
  min-width: 280px;
`;

export { FormContent, FormLabel };
