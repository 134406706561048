import { createSlice } from '@reduxjs/toolkit';
import { trackMultipleShipments, trackShipment } from './actions';
import { TrackShipmentReducerState } from './type';
import {
  pending,
  rejected,
  trackMultipleShipmentsFulfilled,
  trackShipmentFulfilled,
  getTrackMultipleShipmentsFulfilled,
  getTrackShipmentFulfilled,
} from './case';
import { getTrackMultipleShipments, getTrackShipment } from './multipleActions';

const initialState: TrackShipmentReducerState = {
  data: null,
  loading: false,
  error: '',
  isSuccess: false,
  isCancelled: false,
  message: '',
  trackingDataByShipmentId: {},
  mutlipleShipmentsData: [],
  getTrackingDataByShipmentId: {},
  getMutlipleShipmentsData: [],
};

const trackShipmentSlice = createSlice({
  name: 'TrackShipment',
  initialState,
  reducers: {
    resetTrackShipmentError: (state: TrackShipmentReducerState) => {
      state.error = '';
    },
    resetTrackShipmentReducer: (state: TrackShipmentReducerState) => {
      state.error = '';
      state.data = null;
      state.isSuccess = false;
      state.isCancelled = false;
      state.message = '';
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(trackShipment.pending, pending);

    addCase(trackShipment.fulfilled, trackShipmentFulfilled);

    addCase(trackShipment.rejected, rejected);

    addCase(getTrackShipment.pending, pending);

    addCase(getTrackShipment.fulfilled, getTrackShipmentFulfilled);

    addCase(getTrackShipment.rejected, rejected);

    addCase(trackMultipleShipments.pending, pending);

    addCase(trackMultipleShipments.fulfilled, trackMultipleShipmentsFulfilled);

    addCase(trackMultipleShipments.rejected, rejected);

    addCase(getTrackMultipleShipments.pending, pending);

    addCase(getTrackMultipleShipments.fulfilled, getTrackMultipleShipmentsFulfilled);

    addCase(getTrackMultipleShipments.rejected, rejected);
  },
});

const { resetTrackShipmentError, resetTrackShipmentReducer } = trackShipmentSlice.actions;

export {
  trackShipment,
  trackMultipleShipments,
  resetTrackShipmentError,
  resetTrackShipmentReducer,
  getTrackShipment,
  getTrackMultipleShipments,
};

export default trackShipmentSlice.reducer;
