import { PayloadAction } from '@reduxjs/toolkit';
import { SuccessType, AgreementStateType } from './Type';

function pending(state: AgreementStateType) {
  state.loading = true;
}

function rejected(state: AgreementStateType) {
  state.loading = false;
  // state.error = data?.payload ? data.payload : { msg: 'error' };
}

function getTnCAgreementFulFilled(state: AgreementStateType, action: PayloadAction<SuccessType>) {
  state.loading = false;
  state.TnC = action.payload.data || [];
}

function getPPAgreementFulfilled(state: AgreementStateType, action: PayloadAction<SuccessType>) {
  state.loading = false;
  state.PP = action.payload.data || [];
}
function getTnCFulFilled(state: AgreementStateType, action: PayloadAction<SuccessType>) {
  state.loading = false;
  state.TnC = action.payload.data || [];
}
function getPPFulfilled(state: AgreementStateType, action: PayloadAction<SuccessType>) {
  state.loading = false;
  state.PP = action.payload.data || [];
}
export { pending, rejected, getTnCAgreementFulFilled, getPPAgreementFulfilled, getTnCFulFilled, getPPFulfilled };
