import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initDHLInformation } from '../../../pages/ViewSingleShipment/TrackingDetails/UserDHL/constants';
import { initFedXInformation } from '../../../pages/ViewSingleShipmentAdmin/components/ShipmentProcessingV2/components/Fedex/constants';
import { BilledShipmentType, BoxType, ErrorType, ProductType, ShipmentStateType } from './Type';
import {
  addAdminShipmentNotes,
  connectOnWarehouse,
  getAdminShipmentById,
  getAdminShipmentError,
  getAdminShipmentNotes,
  getAdminShipmentPricing,
  getConnectOnWarehouse,
  getFedExDetails,
  getLocations,
  getShipmentPaymentBySN,
  getVendorsAccount,
  placeRTOShipment,
  postDHLDetails,
  postDhlSendOtp,
  postDhlVerifyOtp,
  postFedExDetails,
  postFedExDocument,
  postFedExDocumentImagesType,
  postPickupDetails,
  shipmentLockRate,
  updateCarrierAndCustomerRefNumber,
  updateExtraCharge,
  updateFirstConnectingWarehouse,
  updateGlobalCharge,
  updateLastConnection,
  updatePickUpAndDropOff,
  updateShipment,
  updateShipmentSelectedCharges,
  updateSingleBoxCarrierNumber,
} from './actions';
import {
  addShipmentNotesFulfilled,
  connectOnWarehouseFulfilled,
  getConnectOnWarehouseFulfilled,
  getFedXDetailsFulfilled,
  getLocationsFulfilled,
  getShipmentByIdFulfilled,
  getShipmentErrorFulfilled,
  getShipmentNotesFulfilled,
  getShipmentPaymentBySNFulfilled,
  getShipmentPricingFulfilled,
  getVendorsAccountFulfilled,
  pending,
  placeRTOShipmentFulfilled,
  postDhlOtpfulfilled,
  postFedExDetailsFulfilled,
  postFedExDetailsRejected,
  rejected,
  shipmentLockRateFulfilled,
  updateCarrierAndCustomerRefNumberFulfilled,
  updateExtraChargeFulfilled,
  updateFirstConnectingWarehouseFulfilled,
  updateGlobalChargeFulfilled,
  updateLastConnectionFulfilled,
  updatePickUpAndDropOffFulfilled,
  updateShipmentFulfilled,
  updateShipmentRejected,
  updateSingleBoxCarrierNumberFulfilled,
} from './case';

const initialState = <ShipmentStateType>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  shipmentById: null,
  shipmentPricing: null,
  shipmentConnectOnWarehouse: null,
  shipmentError: null,
  shipmentNotes: null,
  shipmentNotesAdded: false,
  selectedBox: null,
  selectedProduct: null,
  shipmentpayment: null,
  shipmentUpdated: false,
  shipmentUpdateError: false,
  RTOShipment: null,
  addTransitHub: false,
  lastTransitWarehouse: null,
  vendersAccount: [],
  fedExDetails: initFedXInformation,
  dhlDetails: initDHLInformation,
  isViewOnlyFedExDetails: false,
  documentUploadModalOpen: false,
  locations: [],
  editFinalForward: false,
  insureShipment: false,
  isAccountHolder: true,
  invoiceNumber: null,
  connectionId: '',
  dhlOtpPostShipmentPayload: '',
  subCharges: '',
};

const shipmentSlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetShipmentError: function (state) {
      state.error = initialState.error;
      state.shipmentUpdateError = false;
    },
    resetShipmentNotesAdded: function (state) {
      state.shipmentNotesAdded = false;
    },
    setSelectedBox: function (state, actions: PayloadAction<BoxType>) {
      state.selectedBox = actions.payload;
    },
    setSelectedProduct: function (state, actions: PayloadAction<ProductType>) {
      state.selectedProduct = actions.payload;
    },
    resetShipmentUploadState: state => {
      state.shipmentUpdated = false;
    },
    resetRTOShipment: state => {
      state.RTOShipment = null;
    },
    setError: function (state, action: PayloadAction<ErrorType>) {
      state.error = action.payload;
      state.shipmentUpdateError = true;
    },
    toggleTransitHub: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        state.lastTransitWarehouse = null;
      }
      state.addTransitHub = action.payload;
    },
    setLastTransactionWarehouse: (state, action: PayloadAction<ShipmentStateType['lastTransitWarehouse']>) => {
      state.lastTransitWarehouse = action.payload;
    },
    setDocumentUploadModalOpen: (state, action: PayloadAction<boolean>) => {
      state.documentUploadModalOpen = action.payload;
    },
    editFinalForward: (state, action: PayloadAction<boolean>) => {
      state.editFinalForward = action.payload;
    },
    setInsureShipment: (state, action: PayloadAction<boolean>) => {
      state.insureShipment = action.payload;
    },
    setInvoiceNumber: (state, action: PayloadAction<BilledShipmentType>) => {
      const { isAccountHolder, invoiceNumber } = action.payload;
      if (isAccountHolder !== undefined) {
        state.isAccountHolder = isAccountHolder;
      }
      state.invoiceNumber = invoiceNumber;
    },
    resetInvoiceNumber: state => {
      state.isAccountHolder = true;
      state.invoiceNumber = null;
    },
    setWsConnectionId: (state, action: PayloadAction<string>) => {
      state.connectionId = action.payload;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;

    addCase(getAdminShipmentById.pending, pending);
    addCase(getAdminShipmentById.fulfilled, getShipmentByIdFulfilled);
    addCase(getAdminShipmentById.rejected, rejected);

    addCase(getAdminShipmentPricing.pending, pending);
    addCase(getAdminShipmentPricing.fulfilled, getShipmentPricingFulfilled);
    addCase(getAdminShipmentPricing.rejected, rejected);

    addCase(getAdminShipmentError.pending, pending);
    addCase(getAdminShipmentError.fulfilled, getShipmentErrorFulfilled);
    addCase(getAdminShipmentError.rejected, rejected);

    addCase(getAdminShipmentNotes.pending, pending);
    addCase(getAdminShipmentNotes.fulfilled, getShipmentNotesFulfilled);
    addCase(getAdminShipmentNotes.rejected, rejected);

    addCase(addAdminShipmentNotes.pending, pending);
    addCase(addAdminShipmentNotes.fulfilled, addShipmentNotesFulfilled);
    addCase(addAdminShipmentNotes.rejected, rejected);

    addCase(updateShipment.pending, pending);
    addCase(updateShipment.fulfilled, updateShipmentFulfilled);
    addCase(updateShipment.rejected, updateShipmentRejected);

    addCase(getShipmentPaymentBySN.pending, pending);
    addCase(getShipmentPaymentBySN.fulfilled, getShipmentPaymentBySNFulfilled);
    addCase(getShipmentPaymentBySN.rejected, rejected);

    addCase(updateShipmentSelectedCharges.pending, pending);
    addCase(updateShipmentSelectedCharges.fulfilled, updateShipmentFulfilled);
    addCase(updateShipmentSelectedCharges.rejected, updateShipmentRejected);

    addCase(updateCarrierAndCustomerRefNumber.pending, pending);
    addCase(updateCarrierAndCustomerRefNumber.fulfilled, updateCarrierAndCustomerRefNumberFulfilled);
    addCase(updateCarrierAndCustomerRefNumber.rejected, rejected);

    addCase(updatePickUpAndDropOff.pending, pending);
    addCase(updatePickUpAndDropOff.fulfilled, updatePickUpAndDropOffFulfilled);
    addCase(updatePickUpAndDropOff.rejected, updateShipmentRejected);

    addCase(updateGlobalCharge.pending, pending);
    addCase(updateGlobalCharge.fulfilled, updateGlobalChargeFulfilled);
    addCase(updateGlobalCharge.rejected, updateShipmentRejected);

    addCase(shipmentLockRate.pending, pending);
    addCase(shipmentLockRate.fulfilled, shipmentLockRateFulfilled);
    addCase(shipmentLockRate.rejected, updateShipmentRejected);

    addCase(updateExtraCharge.pending, pending);
    addCase(updateExtraCharge.fulfilled, updateExtraChargeFulfilled);
    addCase(updateExtraCharge.rejected, updateShipmentRejected);

    addCase(connectOnWarehouse.pending, pending);
    addCase(connectOnWarehouse.fulfilled, connectOnWarehouseFulfilled);
    addCase(connectOnWarehouse.rejected, updateShipmentRejected);

    addCase(getConnectOnWarehouse.pending, pending);
    addCase(getConnectOnWarehouse.fulfilled, getConnectOnWarehouseFulfilled);
    addCase(getConnectOnWarehouse.rejected, updateShipmentRejected);

    addCase(placeRTOShipment.pending, pending);
    addCase(placeRTOShipment.fulfilled, placeRTOShipmentFulfilled);
    addCase(placeRTOShipment.rejected, rejected);

    addCase(updateSingleBoxCarrierNumber.pending, pending);
    addCase(updateSingleBoxCarrierNumber.fulfilled, updateSingleBoxCarrierNumberFulfilled);
    addCase(updateSingleBoxCarrierNumber.rejected, updateShipmentRejected);

    addCase(updateFirstConnectingWarehouse.pending, pending);
    addCase(updateFirstConnectingWarehouse.fulfilled, updateFirstConnectingWarehouseFulfilled);
    addCase(updateFirstConnectingWarehouse.rejected, updateShipmentRejected);

    addCase(updateLastConnection.pending, pending);
    addCase(updateLastConnection.fulfilled, updateLastConnectionFulfilled);
    addCase(updateLastConnection.rejected, updateShipmentRejected);

    addCase(getVendorsAccount.pending, pending);
    addCase(getVendorsAccount.fulfilled, getVendorsAccountFulfilled);
    addCase(getVendorsAccount.rejected, updateShipmentRejected);

    addCase(getFedExDetails.pending, pending);
    addCase(getFedExDetails.fulfilled, getFedXDetailsFulfilled);
    addCase(getFedExDetails.rejected, updateShipmentRejected);

    addCase(postFedExDocument.pending, pending);
    addCase(postFedExDocument.rejected, postFedExDetailsRejected);

    addCase(postFedExDocumentImagesType.pending, pending);
    addCase(postFedExDocumentImagesType.rejected, postFedExDetailsRejected);

    addCase(postFedExDetails.pending, pending);
    addCase(postFedExDetails.fulfilled, postFedExDetailsFulfilled);
    addCase(postFedExDetails.rejected, postFedExDetailsRejected);

    addCase(getLocations.pending, pending);
    addCase(getLocations.fulfilled, getLocationsFulfilled);
    addCase(getLocations.rejected, postFedExDetailsRejected);

    addCase(postPickupDetails.pending, pending);
    addCase(postPickupDetails.rejected, postFedExDetailsRejected);

    addCase(postDHLDetails.pending, pending);
    addCase(postDHLDetails.fulfilled, rejected);
    addCase(postDHLDetails.rejected, rejected);

    addCase(postDhlSendOtp.pending, pending);
    addCase(postDhlSendOtp.fulfilled, postDhlOtpfulfilled);
    addCase(postDhlSendOtp.rejected, rejected);

    addCase(postDhlVerifyOtp.pending, pending);
    addCase(postDhlVerifyOtp.fulfilled, rejected);
    addCase(postDhlVerifyOtp.rejected, rejected);
  },
});

export const {
  resetShipmentError,
  setSelectedBox,
  setSelectedProduct,
  resetShipmentUploadState,
  resetRTOShipment,
  setError,
  toggleTransitHub,
  setLastTransactionWarehouse,
  setDocumentUploadModalOpen,
  editFinalForward,
  setInsureShipment,
  setInvoiceNumber,
  resetInvoiceNumber,
  setWsConnectionId,
} = shipmentSlice.actions;
export {
  addAdminShipmentNotes,
  connectOnWarehouse,
  getAdminShipmentError,
  getAdminShipmentNotes,
  getAdminShipmentPricing,
  getConnectOnWarehouse,
  shipmentLockRate,
  updateCarrierAndCustomerRefNumber,
  updateExtraCharge,
  updateGlobalCharge,
  updatePickUpAndDropOff,
  updateShipment,
  updateShipmentSelectedCharges,
  updateSingleBoxCarrierNumber,
};
export default shipmentSlice.reducer;
