import { AddressType } from '@Reducers/Warehouse/Type';
import { RootStateType } from '@Store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useConnectionTitle({ position, addressObj }: { position: number; addressObj?: AddressType }) {
  const { warehouseListForShipmentOptionsHashById } = useSelector((state: RootStateType) => state.Warehouse);
  const firstConnectingWarehouseId = useSelector(
    (state: RootStateType) => state.adminShipment?.shipmentById?.firstConnectingWarehouse?._warehouseId,
  );
  const { countriesObj } = useSelector((state: RootStateType) => state.global);
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const isOrigin = useMemo(() => position === 1, [position]);
  const isDestination = useMemo(() => position === -1, [position]);
  const originSource =
    firstConnectingWarehouseId && warehouseListForShipmentOptionsHashById?.[firstConnectingWarehouseId]
      ? warehouseListForShipmentOptionsHashById?.[firstConnectingWarehouseId]._addressId
      : shipmentById?._shipperId;
  let source =
    firstConnectingWarehouseId && warehouseListForShipmentOptionsHashById?.[firstConnectingWarehouseId]
      ? warehouseListForShipmentOptionsHashById?.[firstConnectingWarehouseId]._addressId
      : shipmentById?._shipperId;
  const destination = shipmentById?._consigneeId;
  const isDomestic = source?.country === destination?.country;
  source = addressObj ? addressObj : isOrigin || isDestination ? source : undefined;
  const address = `${source?.city ? `${source?.city}, ` : ''} ${
    isDomestic ? source?.state || '' : source?.country ? countriesObj[source.country]?.name || source.country : ''
  }`;
  // console.info({ shipmentById, source, firstConnectingWarehouseId, warehouseListForShipmentOptionsHashById });

  return isOrigin
    ? `Origin -  ${originSource?.city}, ${originSource?.state}, ${
        source?.country && countriesObj[source.country]?.name
      }`
    : isDestination
    ? `Destination - ${address}`
    : `Transit Hub - ${address}`;
}
