import SbButton from '@Components/Button';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import Image from '@Components/Image';
import { SbMenu, SbMenuItem } from '@Components/Menu';
import Modal from '@Components/Modal/error';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { RemoveCircleRounded, WarningAmberRounded } from '@mui/icons-material';
import { AppDispatch, RootStateType } from '@Store';
import {
  Grid,
  Paper,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Status from './Status';
import { getAllSquadMember } from '@Reducers/Squad/actions';
import moment from 'moment';
import { ActionType, squadListProps } from './Types';
import { SquadMemberPayload } from '@Reducers/Squad/type';
import SquadKycModal from './SquadKycModal';
import API from '@Services/apiAxios';
import { resetSquadState } from '@Reducers/Squad';
import { getSystemList } from '@Reducers/Systems';
import Loader from '@Components/Loader';
import { getAdminListForTag } from '@Reducers/Admin/actions';
import DisplaySquadVehicle from '../../../utils/displaySquadVehicle';
import { toCamelCase } from '../../../utils/ToCamelCase';
import { Link } from 'react-router-dom';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { fetchSquadListUrl } from '@Reducers/SquadManagementReport/SquadManagementReport';
const SearchFilterList = [{ label: 'Name', value: 'name' }];

export const TableActionContainer = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '16px 0',
  overflow: 'auto',
  margin: '1rem 0',
  display: 'grid',
  gridTemplateColumns: '490px 1fr fit-content(160px) fit-content(160px) 100px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    columnGap: '10px',
    gridTemplateColumns: '490px 1fr fit-content(160px) fit-content(160px) 100px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

function Actions(props: ActionType) {
  const { squadId, isActive, onStatusChange } = props;
  const navigate = useNavigate();
  const [isActiveModal, setIsActiveModal] = useState<boolean>(false);
  const activeToggle = isActive ? 'Deactivate' : 'Activate';

  const onConfirmClick = async () => {
    await API.put(`squad/admin/active-inactive-squad/${squadId}`);
    onStatusChange();
    setIsActiveModal(false);
  };

  return (
    <>
      <Grid container item justifyContent="center" gap={2} alignItems="center">
        <Grid item textAlign="center">
          <SbMenu>
            <SbMenuItem
              onClick={() => {
                navigate(`/squad/edit/${squadId}`);
              }}
              icon={<Image src="/images/icons/EditAction.svg" alt="Edit" />}
              name="Edit"
            />
            <SbMenuItem
              onClick={() => {
                setIsActiveModal(true);
              }}
              icon={<RemoveCircleRounded className="sb-text-black" />}
              name={activeToggle}
            />
          </SbMenu>
        </Grid>
      </Grid>
      {isActiveModal && (
        <Modal
          closeIcon={true}
          icon={<WarningAmberRounded sx={{ fontSize: 60 }} />}
          title={`${activeToggle} Squad Member`}
          subTitle={`Are you sure you wanna ${activeToggle} this squad member ? `}
          btnText="Yes"
          open={true}
          onClose={() => {
            setIsActiveModal(false);
          }}
          onConfirm={onConfirmClick}
        />
      )}
    </>
  );
}

function SquadTable(props: any) {
  const dispatch = useDispatch<AppDispatch>();

  const { allSquadList, fetchSquadMembers } = props;
  const [openKycModal, setOpenKycModal] = useState<boolean>(false);
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [kycLoading, setKycLoading] = useState(false);
  const { adminListForTag: adminList } = useSelector((state: RootStateType) => state.admin);
  adminList?.map(admin => ({ display: admin.name, value: admin._id }));

  const { perPage, currentPage } = useSelector((state: RootStateType) => state.squadManagement);
  const loading = useSelector((state: RootStateType) => state.squadManagement.loading);
  const onKycClick = async (documentId: string) => {
    if (documentId) {
      try {
        setKycLoading(true);
        const res = await API.get(`/squad/file/get-url/${documentId}`);
        setDocumentUrl(res.data.data);
        setKycLoading(false);
      } catch (error) {
        console.error('Error fetching document URL:', error);
      }
    }
    setOpenKycModal(true);
  };

  const getReportTo = (reportToId: string): string => {
    const admin = adminList.find(item => item._id === reportToId);
    return admin ? admin.name : '';
  };

  useEffect(() => {
    dispatch(getAdminListForTag());
  }, []);

  return (
    <>
      <Loader loading={loading || kycLoading} overly />
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell width="100px">Sr no.</TableHeadCell>
            <TableHeadCell>Account</TableHeadCell>
            <TableHeadCell>System</TableHeadCell>
            <TableHeadCell>Vehicle </TableHeadCell>
            <TableHeadCell>Capacity </TableHeadCell>
            <TableHeadCell width={'10%'}>KYC</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell>User Details</TableHeadCell>
            <TableHeadCell>Type</TableHeadCell>
            <TableHeadCell>Action</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allSquadList?.map((data: any, index: number) => (
            <SBTableRow key={data._id}>
              <TableRowCell align="center" className="md-text">
                <div>{index + 1 + (currentPage - 1) * perPage}</div>
              </TableRowCell>
              <TableRowCell align="center" className="sm-text">
                <div>
                  <Link to={`/squad-reports/details/${data._id}`}>{data.name}</Link>
                  <br />
                  {data.phoneNumber}
                </div>
              </TableRowCell>
              <TableRowCell align="center" className="sm-text">
                {data.system.name}
              </TableRowCell>
              <TableRowCell align="center" className="sm-text font-medium">
                {DisplaySquadVehicle(data.vehicle)}
              </TableRowCell>
              <TableRowCell align="center" className="sm-text font-medium">
                {data.vehicleCapacity ?? '-'}
              </TableRowCell>
              <TableRowCell align="center" className="md-text font-medium">
                <Paper
                  sx={{ width: '100px' }}
                  className="py-2 px-5 rounded mx-auto cursor-pointer"
                  onClick={() => onKycClick(data?.document)}
                >
                  <Image src={'/images/icons/user/UserKycDocBlue.svg'} alt="KYC doc" width={35} height={35} />
                  <p className="sb-text-info sm-text">KYC</p>
                </Paper>
              </TableRowCell>
              <TableRowCell align="center" className="md-text font-medium">
                {data.isActive ? <Status status="Active" /> : <Status status="Deactivated" />}
              </TableRowCell>
              <TableRowCell align="left" className="md-text">
                <div>
                  {/* <Typography className="sm-text">
                  {data?._createdBy?.name && <span>Created by: {data?._createdBy?.name}</span>}
                </Typography> */}
                  <Typography className="sm-text">
                    {data.reportTo && <span>Report to: {getReportTo(data?.reportTo)}</span>}
                  </Typography>
                  <Typography className="sm-text">
                    {data?.createdAt && (
                      <>
                        <span>Date:</span> <span>{moment(data?.createdAt).format('DD/MM/YYYY')}</span>
                      </>
                    )}
                  </Typography>
                </div>
              </TableRowCell>
              <TableRowCell align="center" className="sm-text">
                {data.squadType ? toCamelCase(data.squadType) : ' '}
              </TableRowCell>
              <TableRowCell>
                <Actions squadId={data?._id} isActive={data?.isActive} onStatusChange={fetchSquadMembers} />
              </TableRowCell>
            </SBTableRow>
          ))}
        </TableBody>
        {openKycModal && (
          <SquadKycModal
            open={openKycModal}
            onClose={() => setOpenKycModal(false)}
            document={documentUrl}
            type="KYC Document"
          />
        )}
      </SBTable>
    </>
  );
}
export default function SquadList(props: squadListProps) {
  const [status, setStatus] = useState<string[]>([]);
  const [system, setSystem] = useState<string[]>([]);
  const [allSquadList, setAllSquadList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const dispatch = useDispatch<AppDispatch>();
  const _systems = useSelector((state: RootStateType) => state.loginUser?.profile?._systems);
  const SystemList = useSelector((state: RootStateType) => state.System.data.selectedList).map(system => ({
    display: system.name,
    value: system._id,
  }));
  const { limit } = useSelector((state: RootStateType) => state.notifications);

  const activeSystemList = useMemo(() => {
    if (_systems?.length) return SystemList.filter(system => _systems.includes(system.value));
    else return [];
  }, [SystemList, _systems]);
  const { totalCount, currentPage, perPage } = useSelector((state: RootStateType) => state.squadManagement);

  const fetchSquadMembers = async (
    selectedSystemIds: string[] = [],
    keyword = '',
    page: number,
    status: string[],
    perPage?: number,
  ) => {
    try {
      const isActiveFilter = status.length === 1 ? status[0] === 'active' : undefined;

      const actionResult = await dispatch(
        getAllSquadMember({
          currentPage: page,
          perPage,
          isActive: isActiveFilter,
          system: selectedSystemIds,
          keyword,
        }),
      );
      const { data } = actionResult.payload as SquadMemberPayload;
      setAllSquadList(data);
    } catch (error) {
      console.error('Error during dispatch:', error);
    }
  };
  const handleExport = async () => {
    const queryParams = {
      currentPage,
      perPage,
      keyword,
      system,
      isActive: status.length > 1 || status.length === 0 ? undefined : status[0] == 'active' ? true : false,
    };
    props.setExport(false);
    try {
      const res = await API.get(`/squad/admin/downloadSquadList`, { params: queryParams });
      console.info(res.data);

      dispatch(fetchSquadListUrl(queryParams));
      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit,
        }),
      );
      dispatch(getAllReadNotifications({ page: 1, limit }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Download in Progress',
          subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
          btnText: 'Close',
          onConfirm: () => {
            dispatch(toggleBoolean());
            dispatch(SuccessErrorModalReset());
          },
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    }
  };
  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    fetchSquadMembers(selected, keyword, 1, status, perPage);
  };

  const onStatusFilter = (status: Array<string>) => {
    const selectedValues = status as string[];
    setStatus(selectedValues);
    fetchSquadMembers(system, keyword, 1, status, perPage);
  };
  useEffect(() => {
    if (props.isExport) {
      handleExport();
    }
  }, [props.isExport]);
  useEffect(() => {
    dispatch(getSystemList());
    return () => {
      dispatch(resetSquadState());
    };
  }, []);

  useEffect(() => {
    fetchSquadMembers(system, keyword, 1, status, perPage);
  }, [dispatch]);

  return (
    <>
      <TableActionContainer className="scroll-h-1">
        <FilterSearch
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={keyword}
          onTextChange={e => {
            setKeyword(e.target.value);
          }}
          onTextSearch={() => {
            fetchSquadMembers(system, keyword, 1, status, perPage);
          }}
          onTextClear={() => {
            setKeyword('');
            fetchSquadMembers(system, '', 1, status, perPage);
          }}
        />
        <div></div>
        <FilterSelect
          id="status"
          label="Status"
          options={[
            { display: 'Active', value: 'active' },
            { display: 'Deactivated', value: 'deactivated' },
          ]}
          value={status}
          onChange={onStatusFilter}
        />
        <FilterSelect id="system" label="System" options={activeSystemList} value={system} onChange={onSystemFilter} />

        <SbButton
          className="xs-text"
          variant="outlined"
          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
          onClick={() => {
            setSystem([]);
            setKeyword('');
            setStatus([]);
            fetchSquadMembers([], '', 1, [], 10);
          }}
        >
          Clear Filters
        </SbButton>
      </TableActionContainer>

      <TableContainer id="fadedScroll">
        <SquadTable
          allSquadList={allSquadList}
          fetchSquadMembers={() => fetchSquadMembers(system, keyword, currentPage, status, perPage)}
        />
      </TableContainer>

      {!!totalCount && !!perPage && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage - 1}
            rowsPerPage={perPage}
            onPageChange={(_, newPage) => fetchSquadMembers(system, keyword, newPage + 1, status, perPage)}
            onRowsPerPageChange={event => {
              const newPerPage = parseInt(event.target.value, 10);
              fetchSquadMembers(system, keyword, 1, status, newPerPage);
            }}
          />
        </>
      )}
    </>
  );
}
