import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import Modal from '@Components/Modal';
import DragDrop from '@Components/File/DragDrop';
import SbButton, { SbLoadingButton } from '@Components/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import SbTextField from '@Components/Textfield';
import { alpha, styled, useTheme } from '@mui/material/styles';

import { FileResultType } from '../../../Helper/file';
import Image from '@Components/Image';
import API from '../../../services/apiAxios';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@Store';
import { ContractObject } from '@Reducers/Contract/Type';
import { addNewContract } from '@Reducers/Contract';

const initFileData: FileResultType = {
  fileName: '',
  fileType: '',
  fileExtension: '',
  fileData: '',
  fileSize: '',
};

interface signedUrlResponseType {
  fileName: string;
  fileType: string;
  signedUrl: string;
}

export interface UploadDocumentType {
  open: boolean;
  onClose: () => void;
  documentData: ContractObject | null;
  accountId: string;
  accountNumber: string;
}

async function getSignedURLResponse(accountNumber: string, fileType: string): Promise<signedUrlResponseType> {
  const result = await API.post(`/user/file/upload-url/account-contract-${accountNumber}`, { mimeType: fileType });
  return result.data.data;
}

function UploadContract(props: UploadDocumentType) {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch<AppDispatch>();

  const [value, setValue] = useState<string>('');
  const [_loading, setLoading] = useState<boolean>(false);
  const [fileData, setFileData] = useState<FileResultType>({ ...initFileData });

  useEffect(() => {
    if (!props.open) {
      setValue('');
      setFileData({ ...initFileData });
      setLoading(false);
    }
    if (props.open && props.documentData?.name?.trim()) setValue(props.documentData?.name?.trim());
  }, [props.open, props.documentData]);

  const onFileChange = (fileData: FileResultType[]) => setFileData(fileData[0]);

  const createDocument = async () => {
    if (!(fileData.fileData && value)) return;
    setLoading(true);

    const signedUrlResponse: signedUrlResponseType = await getSignedURLResponse(
      props.accountNumber || '',
      fileData.fileExtension,
    );
    await axios.put(signedUrlResponse.signedUrl, fileData.fileData, {
      headers: { 'Content-Type': fileData.fileType, 'Content-Encoding': 'base64' },
    });
    setLoading(false);
    const fileSize = fileData.fileSize?.split(' ')[0] || 0;
    dispatch(
      addNewContract([
        props.accountId,
        {
          contractName: value,
          fileType: signedUrlResponse.fileType,
          fileName: signedUrlResponse.fileName,
          fileSize: Number(fileSize),
        },
      ]),
    );
  };

  const onFileUpload = async () => {
    if (!props?.documentData?._id) return await createDocument();
  };

  return (
    <Modal
      maxWidth="sm"
      open={props.open}
      //   sx={{ minHeight: '470px', bottom: 0 }}
      // fullScreen={isFullScreen}
      classes={{ paper: isFullScreen ? 'items-center m-0' : 'rounded-md', container: isFullScreen ? 'items-end' : '' }}
    >
      <Grid container>
        <Grid container item justifyContent="center" className="mb-3">
          <Typography fontWeight="bold" variant="h6">
            Upload Document
          </Typography>
        </Grid>
        {!fileData.fileData ? (
          <DragDrop subText="" accept="image/*" onChange={onFileChange} />
        ) : (
          <FileUploadName
            filename={fileData.fileName}
            fileSize={fileData.fileSize}
            onFileRemove={() => setFileData({ ...initFileData })}
          />
        )}
        <SbTextField
          style={{ margin: '20px 0' }}
          label={'Contract Name'}
          className="w-full"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        <Grid container item spacing={2} justifyContent="end">
          <Grid item xs={12} sm={6} md={4}>
            <SbButton variant="outlined" fullWidth onClick={props.onClose}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SbLoadingButton
              variant="contained"
              fullWidth
              disabled={!(fileData.fileData && value)}
              onClick={() => {
                onFileUpload();
              }}
              loading={_loading}
            >
              Upload File
            </SbLoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

interface FileUploadNameProps {
  filename: string;
  fileSize: string;
  onFileRemove: () => void;
}

const ContainerFileName = styled(Grid)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.25)}`,
  padding: 14,
  borderRadius: 8,
}));

function FileUploadName(props: FileUploadNameProps) {
  return (
    <>
      <ContainerFileName container item xs={12} p={1} className="mt-1" justifyContent="space-between">
        <Grid container item xs={11} gap={2}>
          <Grid item xs={'auto'}>
            <Image src={'/images/file_document.svg'} alt={'Speedbox'} width={45} height={45} />
          </Grid>
          <Grid item xs={6} md={10}>
            <Typography variant="h6" className="font-medium truncate">
              {props.filename}
            </Typography>
            <Typography variant="h6" className="sb-text-gray font-light">
              {props.fileSize}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={1} columnGap={2} alignItems="center" justifyContent="end">
          <IconButton onClick={props.onFileRemove}>
            <CancelIcon className="sb-text-gray" />
          </IconButton>
        </Grid>
      </ContainerFileName>
    </>
  );
}

export default UploadContract;
