import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import moment from 'moment';

import { styled } from '@mui/system';
import { Checkbox, Grid, TableBody, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

// import Title from '../../components/Title';
import Loader from '@Components/Loader';
import FilterSearch from '@Components/Filter/Search';
// import FilterSelect from '@Components/Filter/Dropdown';
import SbButton from '@Components/Button';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import ModalError from '@Components/Modal/error';

import { AppDispatch, RootStateType } from '@Store';
import {
  deleteUnprocessedShipment,
  getUnprocessedShipmentList,
  resetSingleUnprocessedShipmentList,
} from '@Reducers/Unprocessed';
import { UNIT_OF_MEASUREMENT } from '../GetRatesV2/Constant';
import { UNIT, calculateWeights } from '@Services/calculateVolume';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { userLogin } from '@Services/hasAdminAccess';
import { FilterObj, UnprocessedShipmentQuery } from './type';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const TableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 0px',
  display: 'grid',
  // gridTemplateColumns: '510px 1fr 350px fit-content(140px) fit-content(140px) 140px',
  gridTemplateColumns: 'auto auto 117px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 0,
    // gridTemplateColumns: '490px 1fr 350px fit-content(140px) fit-content(140px) 140px',
    gridTemplateColumns: 'auto auto 117px',
  },
}));

const SearchFilterList = [{ label: 'Email ID', value: 'email' }];

function ShipmentTable(props: {
  handleDelete: (id: string) => void;
  selectedShipment: string[];
  onSelect: (id: string[]) => void;
}) {
  const navigate = useNavigate();
  const shipmentList = useSelector((state: RootStateType) => state.UnprocessedShipment.lists);
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isUnprocessedDeleteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Draft_Shipments.unprocessed_shipments,
    PERFORM_ACTION.write,
  );

  const handleSelectAll = () => {
    if (props.selectedShipment.length !== shipmentList.length) {
      const allShipmentIds = shipmentList.map(shipment => shipment._id);
      props.onSelect(allShipmentIds);
    } else {
      props.onSelect([]);
    }
  };

  const handleSelect = (id: string) => {
    if (!props.selectedShipment.includes(id)) {
      props.onSelect([...props.selectedShipment, id]);
    } else {
      const newSelected = props.selectedShipment.filter(i => i !== id);
      props.onSelect(newSelected);
    }
  };

  const handleRowClick = (id: string) => {
    if (isAdmin) navigate(`/shipment/admin/unprocessed/edit/${id}`);
  };

  return (
    <>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell>
              <Checkbox
                style={{ color: 'inherit' }}
                onChange={handleSelectAll}
                checked={shipmentList.length === props.selectedShipment.length}
              />
            </TableHeadCell>
            <TableHeadCell>Date</TableHeadCell>
            <TableHeadCell width={'200px'}>Account</TableHeadCell>
            <TableHeadCell>Shipper</TableHeadCell>
            <TableHeadCell>Consignee</TableHeadCell>
            <TableHeadCell width={'350px'}>Tracking</TableHeadCell>
            <TableHeadCell>Weight</TableHeadCell>
            <TableHeadCell width={'300px'}> Remarks</TableHeadCell>
            <TableHeadCell>Action</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(shipmentList) && shipmentList.length ? (
            shipmentList.map((shipment, _index) => {
              const weightUnit = shipment?._packageId?.unitOfMeasurement
                ? UNIT_OF_MEASUREMENT?.[shipment?._packageId?.unitOfMeasurement as keyof typeof UNIT_OF_MEASUREMENT]
                    ?.weightUnit
                : UNIT_OF_MEASUREMENT['kg-cm'].weightUnit;

              const weight = calculateWeights({
                boxes: shipment?._packageId?.box || [],
                unit: weightUnit as UNIT,
              });
              return (
                <SBTableRow
                  key={shipment?._id}
                  className={`${props.selectedShipment.includes(shipment._id) ? 'selected' : ''} pointer`}
                  onClick={() => {
                    handleRowClick(shipment.shipmentNumber);
                  }}
                >
                  <TableRowCell>
                    <Checkbox
                      onClick={e => e.stopPropagation()}
                      onChange={() => handleSelect(shipment._id)}
                      checked={props.selectedShipment.includes(shipment._id)}
                    />
                  </TableRowCell>
                  <TableRowCell className="md-text">
                    <div>{moment(shipment.createdAt).format('DD/MM/YYYY')}</div>
                  </TableRowCell>
                  <TableRowCell className="md-text">
                    <div>
                      <div className=" md-text flex sb-gap-1">
                        <span className="font-semibold">Account No. :</span>
                        <span>{shipment.accountNumber}</span>
                      </div>
                      <div className=" md-text flex sb-gap-1">
                        <span className="font-semibold">Email :</span>
                        <span className="truncate">{shipment._userId.email}</span>
                      </div>
                    </div>
                  </TableRowCell>
                  <TableRowCell className="md-text">
                    <div className="font-semibold md-text">{shipment?._shipperId?.name}</div>
                    <div className="sm-text">
                      {shipment?._shipperId?.city} - {shipment?._shipperId?.pincode}
                    </div>
                    <div className="sm-text">{shipment?._shipperId?.country}</div>
                  </TableRowCell>

                  <TableRowCell className="md-text capitalize">
                    <div className="font-semibold md-text">{shipment?._consigneeId?.name}</div>
                    <div className="sm-text">
                      {shipment?._consigneeId?.city} - {shipment?._consigneeId?.pincode}
                    </div>
                    <div className="sm-text">{shipment?._consigneeId?.country}</div>
                  </TableRowCell>
                  <TableRowCell className="md-text">
                    <div>
                      <div className="md-text">
                        <span className="font-semibold">Shipment Number</span> :{shipment.shipmentNumber}
                      </div>
                      <div className="md-text">
                        <span className="font-semibold">Ref Number</span> :
                        {shipment?._packageId?.userReferenceNumber || '-'}
                      </div>
                    </div>
                  </TableRowCell>
                  <TableRowCell className="md-text" align="center">
                    {weight.totalChargeableWeight.toFixed(2) || 0} {weightUnit}
                  </TableRowCell>
                  <TableRowCell className="md-text" align="center">
                    {shipment?.error?.msg}
                  </TableRowCell>
                  <TableRowCell
                    align="center"
                    className="md-text uppercase cursor-pointer"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    {isUnprocessedDeleteAllowed && (
                      <div>
                        <DeleteIcon onClick={() => props.handleDelete(shipment._id)} />
                      </div>
                    )}
                  </TableRowCell>
                </SBTableRow>
              );
            })
          ) : (
            <SBTableRow>
              <TableRowCell colSpan={8} className="md-text text-center">
                <div>No data to show</div>
              </TableRowCell>
            </SBTableRow>
          )}
        </TableBody>
      </SBTable>
    </>
  );
}

export default function UnprocessedList() {
  const [filterSelectValue, setFilterSelectValue] = useState('email');
  const [filterText, setFilterText] = useState('');

  const [showDelete, setShowDelete] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState<string[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const { loading, totalCount, currentPage, perPage } = useSelector(
    (state: RootStateType) => state.UnprocessedShipment,
  );
  useEffect(() => {
    dispatch(getUnprocessedShipmentList({ currentPage: 1, perPage: 10 }));
    return () => {
      dispatch(resetSingleUnprocessedShipmentList());
    };
  }, []);

  const handleDelete = (id: string) => {
    setShowDelete(!showDelete);
    setSelectedShipment([id]);
  };

  const handleClose = () => {
    setShowDelete(false);
  };

  const getFilterObj = (options: FilterObj): UnprocessedShipmentQuery => {
    // const filterSearchKey = filterSelectValue;
    // const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filterQuery: UnprocessedShipmentQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,

      // searchKey: filterSearchKey,
      // searchValue: filterSearchValue,
    };
    // if (!filterSearchValue) {
    //   delete filterQuery['searchKey'];
    //   delete filterQuery['searchValue'];
    // }
    return filterQuery;
  };

  const deleteShipment = () => {
    if (selectedShipment.length) {
      dispatch(
        deleteUnprocessedShipment({
          payload: { deleteId: selectedShipment },
          queryParams: { currentPage: 1, perPage: 30 },
          showModal: true,
        }),
      ).then(response => {
        if (response.meta.requestStatus === 'fulfilled') setShowDelete(false);
      });
    }
  };

  return (
    <Loader loading={loading} overly>
      <>
        <DeleteModalConformation handleClose={handleClose} showDelete={showDelete} deleteShipment={deleteShipment} />

        <TableActionContainer>
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={SearchFilterList}
            selectValue={filterSelectValue}
            textValue={filterText}
            onTextChange={e => {
              setFilterText(e.target.value);
            }}
            onTextSearch={() => {
              dispatch(
                getUnprocessedShipmentList({
                  currentPage: 1,
                  perPage: 10,
                  searchKey: filterSelectValue,
                  searchValue: filterText || undefined,
                }),
              );
            }}
            onTextClear={() => {
              setFilterText('');
              dispatch(
                getUnprocessedShipmentList({
                  currentPage: 1,
                  perPage: 10,
                }),
              );
            }}
          />
          <div></div>
          <SbButton
            className="xs-text ml-1"
            variant="outlined"
            sx={{ borderRadius: '8px !important', padding: '8px !important' }}
            disabled={!selectedShipment.length}
            onClick={() => {
              setShowDelete(true);
            }}
          >
            Delete All
          </SbButton>
        </TableActionContainer>
        <div
          style={{
            display: 'block',
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            maxWidth: '100%',
          }}
        >
          <div
            style={{
              minWidth: '600px',
            }}
          >
            <ShipmentTable
              selectedShipment={selectedShipment}
              onSelect={shipment => {
                setSelectedShipment(shipment);
              }}
              handleDelete={handleDelete}
            />
          </div>
          {!!totalCount && !!perPage && (
            <>
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                rowsPerPage={perPage}
                onPageChange={(_, page) => {
                  dispatch(getUnprocessedShipmentList(getFilterObj({ currentPage: page + 1 })));
                }}
                onRowsPerPageChange={event => {
                  dispatch(getUnprocessedShipmentList(getFilterObj({ perPage: parseInt(event.target.value) })));
                }}
              />
            </>
          )}
        </div>
      </>
    </Loader>
  );
}

const Title = styled(Typography)(({ theme }) => ({
  // fontSize: '32px',
  maxWidth: '500px',
  marginTop: theme.spacing(4),
}));

function DeleteModalConformation(props: { showDelete: boolean; handleClose: () => void; deleteShipment: () => void }) {
  return (
    <ModalError
      icon="/images/Boxes/Delete.svg"
      btnText="Delete"
      title=""
      onClose={props.handleClose}
      subTitle="Are you sure you want to delete this shipment?"
      open={props.showDelete}
    >
      <>
        <Title className="font-medium xl-text">Are you sure you want to delete this shipment?</Title>
        <Grid container gap={2} justifyContent="center">
          <SbButton
            onClick={props.deleteShipment}
            variant="contained"
            className="mt-4 rounded-full"
            style={{ height: '44px', width: '148px' }}
          >
            Yes
          </SbButton>
          <SbButton
            onClick={props.handleClose}
            variant="outlined"
            className="mt-4 rounded-full"
            style={{ height: '44px', width: '148px' }}
          >
            No
          </SbButton>
        </Grid>
      </>
    </ModalError>
  );
}
