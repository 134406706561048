import { PayloadAction } from '@reduxjs/toolkit';
import {
  ShipmentStateType,
  ShipmentByIdType,
  bulkShipmentSummary,
  SavedShipmentList,
  ShipmentPaymentType,
  PlaceShipmentSuccessResType,
  ShipmentKycType,
  UserFedXInformationResponse,
  UserAramexInformationResponse,
} from './Type';
import { ErrorType, SuccessPayload } from '../../../types';

function placeShipmentPending(state: ShipmentStateType) {
  state.loading = true;
  state.error = {};
}

function placeShipmentFulfilled(state: ShipmentStateType, action: PayloadAction<PlaceShipmentSuccessResType>) {
  const { shipmentData, showPaymentModal } = action.payload || {};
  state.loading = false;
  if (showPaymentModal) {
    state.openPaymentModal = true;
  } else {
    state.redirectToSuccess = true;
  }
  if (shipmentData) {
    state.editShipment = false;
    state.shipmentSummary = shipmentData;
  }
}

function placeBulkShipmentFulfilled(state: ShipmentStateType, action: PayloadAction<{ data: bulkShipmentSummary }>) {
  state.loading = false;
  state.redirectToSuccess = true;
  state.bulkShipmentSummary = action.payload.data;
}

function getShipmentByIdFulfilled(state: ShipmentStateType, action: PayloadAction<{ data: ShipmentByIdType }>) {
  state.loading = false;

  const data = action.payload.data;
  data._packageId.box.forEach((singleBox, ind) => (singleBox.id = ind.toString()));
  state.shipmentById = data;
}
function getShipmentBySNPending(state: ShipmentStateType) {
  state.loading = true;
}
function getShipmentBySNFulfilled(state: ShipmentStateType) {
  state.loading = false;
}
function getShipmentBySNRejected(state: ShipmentStateType) {
  state.loading = false;
}

function placeShipmentRejected(state: ShipmentStateType, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = {
    msg: action?.payload?.msg,
    errorDetails: action?.payload?.errorDetails,
    isError: true,
  };
}

/** Fetch draft shipments **/
function savedShipmentPending(state: ShipmentStateType) {
  state.loading = true;
  state.error = {};
}

function savedShipmentFulfilled(state: ShipmentStateType, action: PayloadAction<{ data: SavedShipmentList[] }>) {
  state.loading = false;
  state.savedShipmentList = action.payload.data;
}

function savedShipmentRejected(state: ShipmentStateType) {
  state.loading = false;
  state.error = {
    msg: 'Something went wrong in fetching saved shipments.',
    errorDetails: '',
    isError: true,
  };
}

/** Delete draft shipments **/
function deleteShipmentPending(state: ShipmentStateType) {
  state.loading = true;
  state.error = {};
  state.deletedResult = null;
}

function deleteShipmentFulfilled(state: ShipmentStateType, _action: PayloadAction<{ data: SavedShipmentList[] }>) {
  state.loading = false;
  state.deletedResult = null;
}

function deleteShipmentRejected(state: ShipmentStateType) {
  state.loading = false;
  state.error = {
    msg: 'Something went wrong in delete saved shipments.',
    errorDetails: '',
    isError: true,
  };
}

//shipment payment by shipment number
function getShipmentPaymentBySNPending(state: ShipmentStateType) {
  state.loading = true;
  state.error = {};
}

function getShipmentPaymentBySNFulfilled(
  state: ShipmentStateType,
  action: PayloadAction<SuccessPayload<ShipmentPaymentType>>,
) {
  state.loading = false;
  if (action.payload.data) state.shipmentPayment = action.payload.data;
}

function getShipmentPaymentBySNRejected(state: ShipmentStateType) {
  state.loading = false;
  state.error = {
    msg: 'Something went wrong in delete saved shipments.',
    errorDetails: '',
    isError: true,
  };
}

//shipment kyc by shipment number
function getShipmentKycPending(state: ShipmentStateType) {
  state.loading = true;
  state.error = {};
  state.isShipmentKycAdded = false;
  state.isShipmentKycDeleted = false;
}

function getShipmentKycRejected(state: ShipmentStateType) {
  state.loading = false;
  state.error = {
    msg: 'Something went wrong in delete saved shipments.',
    errorDetails: '',
    isError: true,
  };
}

function getShipmentKycFulfilled(state: ShipmentStateType, action: PayloadAction<SuccessPayload<ShipmentKycType[]>>) {
  state.loading = false;
  if (action.payload.data) state.shipmentKyc = action.payload.data;
}

function addNewShipmentKycFulfilled(state: ShipmentStateType) {
  state.loading = false;
  state.isShipmentKycAdded = true;
}

function deleteShipmentKycFulfilled(state: ShipmentStateType) {
  state.loading = false;
  state.isShipmentKycDeleted = true;
}

function getUserFedXDetailsFulfilled(
  state: ShipmentStateType,
  action: PayloadAction<SuccessPayload<UserFedXInformationResponse>>,
) {
  state.loading = false;
  if (action.payload.data) {
    if (action.payload.data.csp === 'fedex') {
      state.userFedXInformationDetails = action.payload.data;
    } else if (action.payload.data.csp === 'aramex') {
      state.userAramexInformationDetails = action.payload.data as UserAramexInformationResponse;
    } else {
      state.userDhlInformationDetails = action.payload.data;
    }
  }
}

export {
  placeShipmentPending,
  placeShipmentFulfilled,
  placeShipmentRejected,
  placeBulkShipmentFulfilled,
  getShipmentByIdFulfilled,
  getShipmentBySNPending,
  getShipmentBySNFulfilled,
  getShipmentBySNRejected,
  savedShipmentPending,
  savedShipmentFulfilled,
  savedShipmentRejected,
  deleteShipmentPending,
  deleteShipmentFulfilled,
  deleteShipmentRejected,
  getShipmentPaymentBySNPending,
  getShipmentPaymentBySNFulfilled,
  getShipmentPaymentBySNRejected,
  getShipmentKycPending,
  getShipmentKycFulfilled,
  getShipmentKycRejected,
  addNewShipmentKycFulfilled,
  deleteShipmentKycFulfilled,
  getUserFedXDetailsFulfilled,
};
