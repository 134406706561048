import React from 'react';
import { Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Title from '../../components/Title';
import { Link, Outlet, useLocation } from 'react-router-dom';
import SbButton from '../../components/Button';
import Divider from '../../components/Divider';
import Loader from '../../components/Loader';
import { RootStateType } from '../../store';
import { useSelector } from 'react-redux';
import BackButton from '@Components/BackButton';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

function System() {
  const location = useLocation();
  const loading = useSelector((state: RootStateType) => state.System.loading);
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const showCreateBtn = location.pathname === '/system';
  const showBackButton =
    location.pathname === '/system/create' ||
    location.pathname.startsWith('/system/edit') ||
    location.pathname.startsWith('/system/pincode/edit');

  const isCreateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.System.create_system,
    PERFORM_ACTION.write,
  );

  return (
    <Loader loading={loading} overly>
      <>
        <Grid container alignItems="center">
          <Grid item xs={6} sx={{ display: 'flex', gap: '0.5rem' }}>
            {showBackButton && <BackButton link="/system" />}
            <Title
              title="Systems"
              subTitle="System is a set of unique serviceable pincodes"
              removeBorder
              removePadding
            />
          </Grid>
          {isCreateAllowed && showCreateBtn && (
            <Grid item xs={6} textAlign="end">
              <Link
                className={`${isCreateAllowed ? '' : 'cursor-default'}`}
                to={isCreateAllowed ? '/system/create' : ''}
              >
                <SbButton
                  variant="contained"
                  className="ml-auto"
                  sx={{ borderRadius: '8px !important' }}
                  startIcon={<AddCircleIcon fontSize="small" />}
                  disabled={!isCreateAllowed}
                >
                  Create new
                </SbButton>
              </Link>
            </Grid>
          )}
        </Grid>
        <Divider style={{ margin: `calc(var(--mui-palette-spacing) * 3.5) 0` }} />
        <Outlet />
      </>
    </Loader>
  );
}

export default System;
