import { UpdatePickUpAndDropOff } from '@Reducers/AdminShipment/Type';
import { getAdminShipmentById, getShipmentWebsocketStream } from '@Reducers/AdminShipment/actions';
import { getShipmentById } from '@Reducers/Shipment/actions';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { BulkPaymentType, BulkStatusType } from '../../../pages/ShipmentHistory/BulkStatusUpload/types';
import API from '../../../services/apiAxios';
import { SuccessPayload } from '../../../types';
import {
  BulkShipmentHistoryQuery,
  DownloadShippingInvoiceQueryParams,
  DownloadShippingLabelQueryParams,
  ErrorType,
  ShipmentHistoryQuery,
  UpdateShipmentStatusPostData,
} from './Type';

const getShipmentList = createAsyncThunk('getShipmentList', async (queryParams: ShipmentHistoryQuery, { dispatch }) => {
  try {
    const res = await API.get(`/shipment/list?`, { params: queryParams });
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.errorDetails || 'Something went wrong.',
      }),
    );
    throw { msg: 'No such shipment.' };
  }
});

const ExportOrder = createAsyncThunk('ShipmentExport', async (queryParams: ShipmentHistoryQuery) => {
  const res = await API.get(`/shipment/export?`, {
    params: { fromDate: queryParams.fromDate, toDate: queryParams.toDate },
  });

  window.open(res.data.data, '_blank');
  return {};
});

const BulkExportOrder = createAsyncThunk('ShipmentExport', async (queryParams: BulkShipmentHistoryQuery) => {
  const res = await API.get(`/shipment/export/bulk?`, {
    params: queryParams,
  });

  window.open(res.data.data, '_blank');
  return {};
});

async function DownloadFile(url: string) {
  const res = await API.get(url);

  window.open(res.data.data, '_blank');
}

const downloadShippingLabel = createAsyncThunk(
  'ShippingLabelDownload',
  async (queryParams: DownloadShippingLabelQueryParams) => {
    const { shipmentNumber, formatType = 'A4' } = queryParams;

    return await DownloadFile(`shipment/download/label/sb/${formatType}/${shipmentNumber}`);
  },
);

const downloadCSPLabel = createAsyncThunk('CSPLabelDownload', async (queryParams: DownloadShippingLabelQueryParams) => {
  const { shipmentNumber, formatType = 'A4' } = queryParams;
  return await DownloadFile(`shipment/download/label/sb/${formatType}/${shipmentNumber}`);
});

const downloadShippingInvoice = createAsyncThunk(
  'ShippingInvoiceDownload',
  async (queryParams: DownloadShippingInvoiceQueryParams) => {
    const { shipmentNumber, fileType = 'xlsx' } = queryParams;
    return await DownloadFile(`shipment/download/invoice/sb/commercial/${fileType}/${shipmentNumber}`);
  },
);

const downloadProformaInvoice = createAsyncThunk(
  'ProformaInvoiceDownload',
  async (queryParams: { shipmentNumber: string }) => {
    return await DownloadFile(`shipment/download/invoice/sb/proforma/${queryParams.shipmentNumber}`);
  },
);
const downloadBulkProformaInvoice = createAsyncThunk(
  'BulkProformaInvoiceDownload',
  async (queryParams: { shipmentNumbers: string[] }) => {
    return await DownloadFile(
      `shipment/download/invoice/sb/proforma/bulk?shipmentNumbers[]=${queryParams.shipmentNumbers.join(
        '&shipmentNumbers[]=',
      )}`,
    );
  },
);
const downloadBulkShippingLabel = createAsyncThunk(
  'BulkShippingLabelDownload',
  async (queryParams: { fileType: string; shipmentNumber: string[] }) => {
    return await DownloadFile(
      `shipment/download/label/sb/bulk/${queryParams.fileType}?shipmentNumbers[]=${queryParams.shipmentNumber.join(
        '&shipmentNumbers[]=',
      )}`,
    );
  },
);
const downloadBulkShippingInvoice = createAsyncThunk(
  'BulkShippingInvoiceDownload',
  async (queryParams: { fileType: string; shipmentNumber: string[] }) => {
    return await DownloadFile(
      `shipment/download/invoice/sb/commercial/bulk/${
        queryParams.fileType
      }?shipmentNumbers[]=${queryParams.shipmentNumber.join('&shipmentNumbers[]=')}`,
    );
  },
);

const cancelShipment = createAsyncThunk<SuccessPayload, UpdateShipmentStatusPostData>(
  'shipment/cancel',
  async (postData: UpdateShipmentStatusPostData, { dispatch }) => {
    // const shipmentNumber = postData.shipmentNumbers?.length ? postData.shipmentNumbers[0] : '';
    const res = await API.put(`/tracking/track`, postData);
    dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: res.data.msg,
      }),
    );
    setTimeout(() => {
      dispatch(getAdminShipmentById(postData.shipmentNumbers[0]));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + postData.shipmentNumbers[0]]));
    }, 2000);
    return res.data;
  },
);

const UpdateShipmentStatus = createAsyncThunk(
  'shipment/status-update',
  async (postData: UpdateShipmentStatusPostData, { dispatch }) => {
    try {
      const res = await API.put(`/tracking/track`, postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const BulkStatusUpdate = createAsyncThunk(
  'shipment/bulk-status-update',
  async (postData: BulkStatusType[], { dispatch }) => {
    try {
      const res = await API.put(`/tracking/track/bulk-status`, postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const BulkPaymentUpdate = createAsyncThunk(
  'invoices/bulk-payment-update',
  async (postData: BulkPaymentType[], { dispatch }) => {
    try {
      const res = await API.post(`/invoice/payment/create/bulk`, { payments: postData });
      await API.post(`/payment/pay/invoice/bulk`, res?.data);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Invoice payments uploaded successfully!',
          subTitle: res.data.msg,
          btnText: 'Done',
        }),
      );

      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Payments Failed',
          btnText: 'Download',
          secondaryBtnText: 'Cancel',
          onConfirm: () => window.open(errorRes.data.downloadURL, '_blank'),
          subTitle:
            'There was an issue with the data in your uploaded file. Please download the report to review and correct the data.',
        }),
      );
      throw error;
    }
  },
);

const downloadBulkCarrierLabel = createAsyncThunk(
  'CarrierLabelDownload',
  async (queryParams: { shipmentNumber: string[] }) => {
    return await DownloadFile(
      `shipment/download/label/sb/bulk/A4?shipmentNumbers[]=${queryParams.shipmentNumber.join('&shipmentNumbers[]=')}`,
    );
  },
);

const downloadManifest = createAsyncThunk('downloadManifest', async (queryParams: { manifestNumber: string }) => {
  return await DownloadFile(`/shipment/manifest/download/${queryParams.manifestNumber}`);
});

const updateUserPickUpAndDropOff = createAsyncThunk<SuccessPayload, UpdatePickUpAndDropOff, { rejectValue: ErrorType }>(
  'updateUserPickUpAndDropOff',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.put(`/shipment/user/pickup-dropOff/${params.shipmentNumber}`, params);
      dispatch(getShipmentById(params.shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + params.shipmentNumber]));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );

      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      const errorRes = error?.response?.data?.msg || '';
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes || 'Something went to wrong.',
        }),
      );
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const downloadCarrierLabel = createAsyncThunk('CarrierLabelDownload', async (shipmentNumber: string) => {
  return await DownloadFile(`shipment/label/getLabel?shipmentNumber=${shipmentNumber}`);
});

export {
  BulkExportOrder,
  BulkStatusUpdate,
  BulkPaymentUpdate,
  ExportOrder,
  UpdateShipmentStatus,
  cancelShipment,
  downloadBulkCarrierLabel,
  downloadBulkProformaInvoice,
  downloadBulkShippingInvoice,
  downloadBulkShippingLabel,
  downloadCSPLabel,
  downloadManifest,
  downloadProformaInvoice,
  downloadShippingInvoice,
  downloadShippingLabel,
  getShipmentList,
  updateUserPickUpAndDropOff,
  downloadCarrierLabel,
};
