import Contact from '@Components/Contact';
import { usePickupDropoffAccess } from '@Hook/usePickupDropoffAccess';
import {
  FormHelperText,
  Grid,
  InputLabel,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ValidationResult } from 'joi';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Divider from '../../../components/Divider';
import SBDropdown from '../../../components/Dropdown';
import { DropdownOptionType } from '../../../components/Dropdown/Type';
import GoogleMaps from '../../../components/Location';
import SbTextField from '../../../components/Textfield';
import CreateShipmentContext from '../../../context/CreateShipment';
import { getFormattedLocation } from '../../../services/formateLocation';
import { AppDispatch, RootStateType } from '../../../store';
import { addAddress } from '../../../store/reducers/SavedAddress';
import { AddressType } from '../../../store/reducers/SavedAddress/Type';
import {
  createShipmentContextType,
  userDetailsErrorType,
  userDetailsProps,
  userDetailsType,
} from '../../../types/shipment';
import Actions from '../Actions';
import SavedAddresses from '../SavedAddresses';
import ShipmentPickupType from '../ShipmentPickupType';
import { USER_TYPE, defaultErrorState, defaultUserState } from '../constants';
import { ShipmentUserSchema } from './Schema';
// import { resetFormData } from '@Reducers/GetRates';

function UserDetails(props: userDetailsProps): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const countryHashById = useSelector((state: RootStateType) => state.global.countriesNameObj);
  const { countryList, shipmentIdTypeList } = useSelector((state: RootStateType) => state?.global);
  const { savedAddressById, shipperAddresses, consigneeAddresses } = useSelector(
    (state: RootStateType) => state?.savedAddress,
  );
  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);

  const { exportShipmentId, personalShipmentId, pickupTimeSlots } = shipmentSetting || {};

  const { activeStep, setActiveStep, shipper, setShipper, consignee, setConsignee, pickupType } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;
  const { detailsFor } = props;

  const [initials, setInitials] = useState<userDetailsType>({ ...defaultUserState });
  const [errors, setErrors] = useState<userDetailsErrorType>({ ...defaultErrorState });
  const [reinitialize, setReinitialize] = useState<boolean>(true);
  const [saveAs, setSaveAs] = useState<string>('');
  const [selectedAddress, setSelectedAddress] = useState<AddressType>();

  function saveSelectedAddress(address: AddressType) {
    const { phone, alternatePhone, name, companyName, line1, line2, city, state, country, pincode, email } = address;
    const tempAddress = {
      ...initials,
      name,
      email: email || '',
      companyName: companyName ? companyName : '',
      line1,
      line2: line2 ? line2 : '',
      city,
      state,
      country,
      pincode,
      phoneNumber: phone.number,
      telephoneCode: phone.telephoneCode,
      altContactTelephoneCode: alternatePhone?.telephoneCode ?? '',
      altContactNo: alternatePhone?.number ?? '',
    };

    setInitials({ ...tempAddress });
    return tempAddress;
  }

  useEffect(() => {
    if (savedAddressById?.name) {
      saveSelectedAddress(savedAddressById);
    }
  }, [savedAddressById]);

  useEffect(() => {
    if (detailsFor === USER_TYPE.SHIPPER) {
      setInitials({ ...shipper });
    } else if (detailsFor === USER_TYPE.CONSIGNEE) {
      setInitials({ ...consignee });
    }
  }, [shipper, consignee]);

  useEffect(() => {
    if (selectedAddress) {
      const data = saveSelectedAddress(selectedAddress);
      if (detailsFor === USER_TYPE.SHIPPER) {
        setShipper({ ...shipper, ...data });
      } else if (detailsFor === USER_TYPE.CONSIGNEE) {
        setConsignee({ ...consignee, ...data });
      }
    }
  }, [selectedAddress]);

  useEffect(() => {
    let data = {};
    if (detailsFor === USER_TYPE.SHIPPER) {
      data = { ...shipper };
    } else if (detailsFor === USER_TYPE.CONSIGNEE) {
      data = { ...consignee };
    }
    setInitials(initials => ({ ...initials, ...data }));
    setErrors({ ...defaultErrorState });
    setReinitialize(true);
    setSaveAs('');
  }, [activeStep]);
  function saveOrUpdateSaveAs(initials: userDetailsType) {
    const {
      telephoneCode,
      phoneNumber,
      altContactTelephoneCode,
      altContactNo,
      name,
      companyName,
      line1,
      line2,
      city,
      state,
      country,
      pincode,
      email,
    } = initials;
    const addressObj: AddressType = {
      ...savedAddressById,
      type: detailsFor?.toLocaleLowerCase(),
      phone: {
        telephoneCode: telephoneCode,
        number: phoneNumber,
      },
      name: name,
      email: email,
      companyName: companyName,
      line1: line1,
      line2: line2,
      city: city,
      state: state,
      country: country,
      _countryId: countryHashById?.[country]?._id,
      pincode: pincode,
      label: saveAs,
    };
    if (altContactNo)
      addressObj.alternatePhone = {
        telephoneCode: altContactTelephoneCode,
        number: altContactNo,
      };
    dispatch(addAddress({ payload: addressObj }));
  }

  const validateForm = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const obj: userDetailsType = { ...initials };

    if (!obj.telephoneCode) obj.telephoneCode = organizationCountry?.telephoneCode?.toString() || '';
    if (!obj.altContactTelephoneCode)
      obj.altContactTelephoneCode = organizationCountry?.telephoneCode?.toString() || '';

    setInitials({ ...obj });
    const result: ValidationResult = ShipmentUserSchema.validate(obj, { abortEarly: false });
    const { error } = result;
    const errorData: userDetailsErrorType = { ...defaultErrorState };
    if (error) {
      for (const item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name as keyof userDetailsErrorType] = message;
      }
      setErrors(errorData);
    }
    if (detailsFor === USER_TYPE.SHIPPER) {
      if (pickupType.type === 'dropOff' && !pickupType.warehouse) {
        errorData['warehouse'] = 'Please choose the drop-off warehouse.';
        setErrors(errorData);
        return errorData;
      } else if (pickupType.type === 'pickUp') {
        if (!pickupType.date) {
          errorData['date'] = 'Please choose a pickup date ';
          setErrors(errorData);
          return errorData;
        }
        if (pickupTimeSlots?.slots?.length && !pickupType.timeSlot) {
          errorData['timeSlot'] = 'Please choose a  time slot.';
          setErrors(errorData);
          return errorData;
        }
      }
    }
    if (error === undefined) {
      if (saveAs) saveOrUpdateSaveAs(obj);
      if (detailsFor === USER_TYPE.SHIPPER) {
        const data = { ...shipper, ...obj };
        setShipper({ ...data });
      } else if (detailsFor === USER_TYPE.CONSIGNEE) {
        const data = { ...consignee, ...obj };
        setConsignee({ ...data });
      } else {
        return;
      }
      setActiveStep(activeStep + 1);
    }
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    validateProperty(event);
    const { name, value } = event.target;
    const data = { ...initials };
    data[name as keyof userDetailsType] = value;
    setInitials(data);
  }

  function handleCountryCodeChange(name: string, value: string) {
    setInitials(initials => ({ ...initials, [name]: value }));
  }

  function handleDropdownChange(
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
    _child: React.ReactNode,
  ) {
    validateProperty(event);
    const { name, value } = event.target;
    const data = { ...initials };
    data[name as keyof userDetailsType] = value;
    setInitials(data);
  }

  function validateProperty(event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) {
    const { name, value } = event.target;
    const obj = { [name]: value };
    const result = ShipmentUserSchema.validate(obj);
    const { error } = result;
    const errorData = { ...errors };
    const errorMessage = error && error.details[0].context?.key === name ? error.details[0].message : null;
    if (errorMessage && name in defaultErrorState) {
      errorData[name as keyof userDetailsErrorType] = errorMessage;
    } else {
      errorData[name as keyof userDetailsErrorType] = '';
    }
    setErrors(errorData);

    // const { name, value } = event.target;
    // const obj = { [name]: value };
    // const result = ShipmentUserSchema.validate(obj);
    // const { error } = result;
    // return error && error.details[0].context?.key === name ? error.details[0].message : null;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  function handleSelectedAddress(event: any) {
    const address = getFormattedLocation(event.address_components);
    setInitials(state => ({ ...state, ...address }));
    setReinitialize(false);
  }

  const typeOption = useMemo(() => {
    const options =
      initials.type || personalShipmentId || exportShipmentId
        ? initials.type === 'personalShipments'
          ? personalShipmentId
          : exportShipmentId
        : [];
    return options?.map(({ label, value }) => ({ display: label, value }));
  }, [initials.type, exportShipmentId, personalShipmentId]);
  const { disablePickup } = usePickupDropoffAccess({ shipperCountry: initials.country });

  return (
    <>
      <Grid>
        <Grid container>
          {isMobile && (
            <Grid item xs={12} sx={{ pb: { md: 0, xs: 2 } }}>
              <Typography className="font-bold">{`${detailsFor} Details`}</Typography>{' '}
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justifyContent={{ xs: 'start' }}>
              <SavedAddresses
                key={detailsFor}
                addresses={
                  detailsFor === USER_TYPE.SHIPPER
                    ? shipperAddresses || []
                    : detailsFor === USER_TYPE.CONSIGNEE
                    ? consigneeAddresses || []
                    : []
                }
                setSelectedAddress={address => setSelectedAddress(address)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: { md: 4, xs: 2 } }} />
        <Grid container alignItems={'center'}>
          <Grid item lg={2} md={2} xs={12}>
            <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }} required>
              {`${detailsFor} Name`}
            </InputLabel>
          </Grid>
          <Grid item lg={10} md={10} xs={12}>
            <SbTextField
              onChange={handleChange}
              placeholder="Enter Name"
              name="name"
              required
              value={initials.name}
              error={errors.name}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: { md: 4, xs: 2 } }} />
        <Grid container alignItems={'center'}>
          <Grid item lg={2} md={2} xs={12}>
            <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
              Company Name
            </InputLabel>
          </Grid>
          <Grid item lg={10} md={10} xs={12}>
            <SbTextField
              placeholder="Enter Company Name"
              value={initials.companyName}
              onChange={handleChange}
              name="companyName"
              error={errors.companyName}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: { md: 4, xs: 2 } }} />
        <Grid container alignItems={'center'}>
          <Grid item lg={2} md={2} xs={12}>
            <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
              Contact
            </InputLabel>
          </Grid>
          <Grid container item lg={10} md={10} xs={12} columnSpacing={3}>
            <Grid item md={6} xs={12} sx={{ mb: { md: 0, xs: 2 } }}>
              <Contact
                label="Mobile No. *"
                phoneName="phoneNumber"
                phoneNumber={initials.phoneNumber || ''}
                onChangePhoneNumber={handleChange}
                telCountryCodeName="telephoneCode"
                telCountryCode={initials.telephoneCode || ''}
                onChangeTelCountryCode={handleChange}
                setDefaultTelephone={(value: number) => handleCountryCodeChange('telephoneCode', value.toString())}
                error={errors.phoneNumber || errors.telephoneCode}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SbTextField
                placeholder="Enter Email Id"
                label="Email Address"
                onChange={handleChange}
                name="email"
                value={initials.email}
                error={errors.email}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: { md: 4, xs: 2 } }} />
        <Grid container>
          <Grid item lg={2} md={2} xs={12}>
            <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
              Address
            </InputLabel>
          </Grid>
          <Grid container item lg={10} md={10} xs={12} spacing={3}>
            <Grid item md={12} xs={12}>
              <GoogleMaps
                onSelect={handleSelectedAddress}
                label={'Location'}
                placeholder="Start typing to search for location"
                reinitialize={reinitialize}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SbTextField
                placeholder="Line 1"
                label="Address Line 1"
                required
                value={initials.line1}
                onChange={handleChange}
                name="line1"
                error={errors.line1}
                helperText={`${initials.line1}`}
                showCharCount={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SbTextField
                placeholder="Line 2"
                label="Address Line 2"
                // required
                value={initials.line2}
                onChange={handleChange}
                name="line2"
                error={errors.line2}
                helperText={`${initials.line2}`}
                showCharCount={true}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SbTextField
                placeholder="City"
                label="City"
                required
                value={initials.city}
                onChange={handleChange}
                name="city"
                error={errors.city}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SbTextField
                placeholder="State"
                label="State"
                // required
                value={initials.state}
                onChange={handleChange}
                name="state"
                error={errors.state}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SBDropdown
                id="company-country-id"
                label={'Country'}
                required
                name="country"
                placeholder="Country"
                options={countryList}
                value={initials.country ? [initials.country] : []}
                onChange={handleDropdownChange}
                error={errors.country}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SbTextField
                placeholder="Pincode"
                label="Pincode"
                required
                value={initials.pincode}
                onChange={handleChange}
                name="pincode"
                error={errors.pincode}
              />
              {disablePickup === true && detailsFor === USER_TYPE.SHIPPER && (
                <FormHelperText error className="m-0">
                  Pickup serviceability not available for this pincode
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: { md: 4, xs: 2 } }} />
        <Grid container alignItems={'center'}>
          <Grid item lg={2} md={2} xs={12}>
            <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
              Alternate contact
            </InputLabel>
          </Grid>
          <Grid container item lg={10} md={10} xs={12} spacing={3}>
            <Grid item md={6} xs={12}>
              <SbTextField
                placeholder="Enter name"
                label="Name"
                name="altContactName"
                onChange={handleChange}
                value={initials.altContactName}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Contact
                label={initials.altContactName ? 'Mobile No. *' : 'Mobile No.'}
                phoneName="altContactNo"
                phoneNumber={initials.altContactNo || ''}
                onChangePhoneNumber={handleChange}
                telCountryCodeName="altContactTelephoneCode"
                telCountryCode={initials.altContactTelephoneCode || ''}
                onChangeTelCountryCode={handleChange}
                setDefaultTelephone={(value: number) =>
                  handleCountryCodeChange('altContactTelephoneCode', value.toString())
                }
                error={errors.altContactNo || errors.altContactTelephoneCode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: { md: 4, xs: 2 } }} />
        <Grid container className="mt-1">
          <Grid item lg={2} md={2} xs={12}>
            <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>{`${detailsFor} ID`}</InputLabel>
          </Grid>
          <Grid container item lg={10} md={10} xs={12} spacing={3}>
            <Grid item md={6} xs={12}>
              <SBDropdown
                id="company-country-id"
                label={`${detailsFor} Type`}
                placeholder="Personal Shipments"
                name="type"
                value={initials.type ? [initials.type] : []}
                options={shipmentIdTypeList}
                onChange={handleDropdownChange}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SBDropdown
                label={`${detailsFor} ID Type`}
                id="IdType"
                placeholder="Select ID ype"
                name="IdType"
                value={initials.IdType ? [initials.IdType] : []}
                options={typeOption || []}
                onChange={handleDropdownChange}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <SbTextField
                label={`${detailsFor} ID No.`}
                placeholder="ID Number"
                name="IdNumber"
                value={initials.IdNumber}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ my: { md: 4, xs: 2 } }} />
        <Grid container alignItems={'baseline'}>
          <Grid item lg={2} md={2} xs={12}>
            <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
              Save as
            </InputLabel>
          </Grid>
          <Grid container item lg={10} md={10} xs={12} spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                placeholder="Save as"
                // label={'⠀'}
                value={saveAs}
                variant="standard"
                onChange={event => setSaveAs(event.target.value)}
                sx={{ fontSize: '14px', height: '44px', width: '100%' }}
              />
            </Grid>
          </Grid>
        </Grid>

        {detailsFor === 'Shipper' && (
          <>
            <Divider sx={{ my: { md: 4, xs: 2 } }} />
            <Grid container>
              <Grid item lg={2} md={2} xs={12}>
                <InputLabel className="font-medium md-text" sx={{ mb: { xs: 1, md: 0 } }}>
                  Pickup or Drop-off?
                </InputLabel>
              </Grid>
              <Grid container item lg={10} md={10} xs={12} spacing={3}>
                <ShipmentPickupType
                  pincode={initials.pincode}
                  userId={props.userId}
                  warehouse={errors.warehouse || ''}
                  date={errors.date || ''}
                  timeSlot={errors.timeSlot || ''}
                  selectedCountry={initials.country}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Actions
        onNext={validateForm}
        details={{ ...initials }}
        detailsFor={detailsFor}
        userId={props.userId}
        accountNumber={props.accountNumber}
        accountId={props.accountId}
      />
    </>
  );
}

export default UserDetails;
