/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import {
  InvoiceSettingsPayloadType,
  KycSettingsPayloadType,
  OrganizationOnboardType,
} from '../../../types/organization';
import { SHIPMENT_STETTING_FORM } from '../../../pages/ShipmentSetting/type';
import { ErrorType } from '../../../types';
import { OrgRes } from './Type';
import { AxiosError } from 'axios';
import { OrganizationType } from '../../../pages/OrganizationSetup/OrganizationSetting/type';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';

const SaveOrganization = createAsyncThunk<
  { data: OrgRes },
  [OrganizationOnboardType, string],
  { rejectValue: ErrorType }
>('SaveOrganization', async ([postObj, token], { rejectWithValue }) => {
  try {
    const res = await API.post(`organization/save`, postObj, { headers: { sb_token: token } });
    return { data: { ...res.data.data, password: postObj.password } };
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const SaveKycSettings = createAsyncThunk(
  'SaveKycSettings',
  async ({ payload, showModal }: { payload: KycSettingsPayloadType } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.post(`/organization/setup/kyc`, payload);
      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'KYC Setting successfully updated.',
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getKycOrgSettings = createAsyncThunk('getKycSettings', async () => {
  const res = await API.get(`/organization/setup/kyc`);
  return res.data;
});

const getOrganizationDetails = createAsyncThunk('getOrganizationDetails', async () => {
  const res = await API.get(`/organization/organization`);
  return res.data;
});

const updateOrganizationDetails = createAsyncThunk(
  'OrganizationDetails/update',
  async ({ payload, showModal }: { payload: OrganizationType } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.put(`/organization/organization/update`, payload);
      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Organization Setting successfully updated.',
            onConfirm: () => {
              window.location.reload();
              thunkAPI.dispatch(SuccessErrorModalReset());
            },
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const saveInvoiceSettings = createAsyncThunk(
  'saveInvoiceSettings',
  async ({ payload, showModal }: { payload: InvoiceSettingsPayloadType } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.post(`/organization/setup/invoice`, payload);
      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Invoice Setting successfully updated.',
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getInvoiceSettings = createAsyncThunk('getInvoiceSettings', async () => {
  const res = await API.get(`/organization/setup/invoice`);
  return res.data;
});

const saveShipmentSettings = createAsyncThunk(
  'saveShipmentSettings',
  async (
    { payload: postObj, showModal }: { payload: SHIPMENT_STETTING_FORM } & SuccessErrorModalParamsType,
    thunkAPI,
  ) => {
    try {
      const res = await API.post(`/organization/setup/shipment`, postObj);
      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Shipment Setting successfully updated.',
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getShipmentSettings = createAsyncThunk('getShipmentSettings', async () => {
  const res = await API.get(`/organization/setup/shipment`);
  return res.data;
});

const getOrganizationPublic = createAsyncThunk('getOrganizationPublic', async () => {
  const res = await API.get(`/organization/save/public`);
  return res.data;
});

const getTaxInfoList = createAsyncThunk('getTaxInfoList', async () => {
  const res = await API.get(`/organization/setup/tax-list`);
  return res.data;
});

export {
  SaveOrganization,
  SaveKycSettings,
  saveInvoiceSettings,
  getInvoiceSettings,
  getKycOrgSettings,
  getShipmentSettings,
  saveShipmentSettings,
  getOrganizationPublic,
  getOrganizationDetails,
  updateOrganizationDetails,
  getTaxInfoList,
};
