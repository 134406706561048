import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import Home from '../pages/Home';
import Profile from '../pages/Profile';
import ComponentEx from '../pages/ComponentEx';
import HomeRoutes from '../pages/HomeRoutes';
import CreateShipment from '../pages/CreateShipment';
import GetRatesV2 from '../pages/GetRatesV2';
import CreateBulkShipment from '../pages/CreateBulkShipment';
import CreateBulkShipmentSheetUpload from '../pages/CreateBulkShipment/SheetUpload';
import ShipmentHistory from '../pages/ShipmentHistory';
import UnprocessedAndSaved from '../pages/Unprocessed';
import CreateBulkShipmentSheetMatchHeader from '../pages/CreateBulkShipment/SheetMatchHeader';
import CreateBulkShipmentSuccess from '../pages/CreateBulkShipment/Success';
import CreateBulkShipmentDetailsSummary from '../pages/CreateBulkShipment/ShipmentDetailsSummary';
import OrganizationSetup from '../pages/OrganizationSetup';
import OrganizationWelcome from '../pages/OrganizationSetup/OrganizationWelcome';
import InvoiceList from '../pages/InvoiceList';
// import InvoiceCreate from '../pages/Invoice/Create';
import InvoiceAdminView from '../pages/Invoice';
import InvoiceCreateNonAccount from '../pages/Invoice/NonAccount/CreateInvoice';
import DebitNoteCreateNonAccount from '../pages/Invoice/NonAccount/DebitNote';
import CreditNoteCreateNonAccount from '../pages/Invoice/NonAccount/CreditNote';

import InvoiceCreateAccountHolder from '../pages/Invoice/Account/CreateInvoice';
import DebitNoteCreateAccountHolder from '../pages/Invoice/Account/DebitNote';
import CreditNoteCreateAccountHolder from '../pages/Invoice/Account/CreditNote';
import BulkOrderSummary from '../pages/BulkOrderSummary';
import CreateSystem from '../pages/System/Create';
import Warehouse from '../pages/Warehouse';
import WarehouseCreate from '../pages/Warehouse/Create';
import OrgCreateSystem from '../pages/OrganizationSetup/CreateSystem';
import OrgWarehouseCreate from '../pages/OrganizationSetup/CreateWarehouse';
import OrgKYCSettings from '../pages/OrganizationSetup/KYCSettings';
import OrgOrganizationSetting from '../pages/OrganizationSetup/OrganizationSetting';
import SetupShipmentSetting from '../pages/OrganizationSetup/ShipmentSetting';
import WarehouseEdit from '../pages/Warehouse/Edit';
import WarehouseList from '../pages/Warehouse/List';
import OrganizationCompletion from '../pages/OrganizationSetup/OrganizationCompletion';
import OrgInvoiceSetting from '../pages/OrganizationSetup/InvoiceSettings';

import System from '../pages/System';
import SystemList from '../pages/System/List';
import SystemEdit from '../pages/System/Edit';
import PincodeEdit from '../pages/System/PincodeEdit';

import UserList from '../pages/UserList';
import AdminList from '../pages/Admin/List';
import CreateAdmin from '../pages/Admin/Create';
import EditAdmin from '../pages/Admin/Edit';
import ViewSingleShipment from '../pages/ViewSingleShipment';
import ViewSingleShipmentAdmin from '../pages/ViewSingleShipmentAdmin';
import CreateAccount from '../pages/CreateAccount';
import Pricing from '../pages/PricingList';
import CreateRate from '../pages/UploadRates/Create/Create';
import EditRate from '../pages/UploadRates/Create/Edit';

import ODA from '../pages/ODA';
import ODAList from '../pages/ODA/List';
import ODACreate from '../pages/ODA/Create';
import ODAEdit from '../pages/ODA/Edit';
import ODACharges from '../pages/ODA/Charges';

import ShipmentSetting from '../pages/ShipmentSetting';
import InvoiceSettings from '../pages/InvoiceSettings';
import KYCSettings from '../pages/KYCSettings';
import OrganizationSettings from '../pages/OrganizationSetting';
import Manifest from '../pages/Manifest';

import GlobalChargeList from '../pages/GlobalCharge/List';
import GlobalChargeCreate from '../pages/GlobalCharge/Create';
import GlobalChargeEdit from '../pages/GlobalCharge/Edit';
import GlobalCharge from '../pages/GlobalCharge';
import Transactions from '../pages/Transactions';
import CarrierAndService from '../pages/CarrierAndService';
import TermsAndCondition from '../pages/TermsAndCondition';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import CreateCarrier from '../pages/CarrierAndService/Carrier/Create/index';
import CreateService from '../pages/CarrierAndService/Service/Create';
import EditService from '../pages/CarrierAndService/Service/Edit';
import CarrierAndServiceList from '../pages/CarrierAndService/List';
import UnprocessedShipment from '../pages/ViewSingleShipmentAdmin/UnprocessedShipment';
import CreateSquad from '../pages/Squad/Create';
import SquadManagement from '../pages/Squad';
import RouteOptimizer from '../pages/RouteOptimizer';
import RouteOptimizerList from '../pages/RouteOptimizer/List';
import RouteOptimizeDetails from '../pages/RouteOptimizer/Details';
import TaskManagement from '../pages/TaskManagement/TaskManagement';
import SquadReport from '../pages/SquadReport/index';
import SquadReportDetail from '../pages/SquadReport/SquadReportDetail';
import ShipmentAddress from '../pages/ShipmentAddress';
import CreateShipperAddress from '../pages/ShipmentAddress/CreateShipperAddress';

import AccessGroup from '../pages/AccessGroup';
import AccessGroupList from '../pages/AccessGroup/list';
import AccessGroupCreate from '../pages/AccessGroup/create';

import TransactionCreditHistory from '../pages/Transactions/Credit';
import TransactionWalletHistory from '../pages/Transactions/Wallet';
import CreditsAndWallets from '../pages/Transactions/CreditsAndWallets';
import MyRates from '../pages/MyRates';
import TrackShipment from '../pages/TrackShipment';
import NotificationSettingsUI from '../pages/Notifications/NotificationSettingsUI';

const AccessGroupRoute = (
  <Route path="/access-group" element={<AccessGroup />}>
    <Route index element={<AccessGroupList />} />
    <Route path="/access-group/create" element={<AccessGroupCreate />} />
    <Route path="/access-group/edit" element={<AccessGroupCreate />} />
  </Route>
);
const NotificationsRoute = <Route path="/notifications-settings" element={<NotificationSettingsUI />}></Route>;

const OrganizationSetupRoute = (
  <Route path="/organization-setup" element={<OrganizationSetup />}>
    <Route index element={<OrganizationWelcome />} />
    <Route path="/organization-setup/system" element={<OrgCreateSystem />} />
    <Route path="/organization-setup/warehouse" element={<OrgWarehouseCreate />} />
    <Route path="/organization-setup/org-settings" element={<OrgOrganizationSetting />} />
    <Route path="/organization-setup/kyc-settings" element={<OrgKYCSettings />} />
    <Route path="/organization-setup/invoice-settings" element={<OrgInvoiceSetting />} />
    <Route path="/organization-setup/shipment-settings" element={<SetupShipmentSetting />} />
    <Route path="/organization-setup/complete" element={<OrganizationCompletion />} />
  </Route>
);

const WarehouseRoute = (
  <Route path="/warehouse" element={<Warehouse />}>
    <Route index element={<WarehouseList />} />
    <Route path="/warehouse/create" element={<WarehouseCreate />} />
    <Route path="/warehouse/edit/:id" element={<WarehouseEdit />} />
  </Route>
);

const SystemRoute = (
  <Route path="/system" element={<System />}>
    <Route index element={<SystemList />} />
    <Route path="/system/create" element={<CreateSystem />} />
    <Route path="/system/edit/:id" element={<SystemEdit />} />
    <Route path="/system/pincode/edit/:id" element={<PincodeEdit />} />
  </Route>
);

const CarrierAndServiceRoute = (
  <Route path="/carrier-and-services" element={<CarrierAndService />}>
    <Route index element={<CarrierAndServiceList />} />
    <Route path="/carrier-and-services/create/carrier" element={<CreateCarrier />} />
    <Route path="/carrier-and-services/create/service" element={<CreateService />} />
    <Route path="/carrier-and-services/edit/service/:id" element={<EditService />} />
  </Route>
);

const OdaRoute = (
  <Route path="/oda" element={<ODA />}>
    <Route index element={<ODAList />} />
    <Route path="/oda/create" element={<ODACreate />} />
    <Route path="/oda/edit/:id" element={<ODAEdit />} />
    <Route path="/oda/charges/:id" element={<ODACharges showBackgroundColor />} />
  </Route>
);

/***************************************************Rate Routes****************************************************** */
const contractSubT = 'View and add rates for account holders';
const baseSubT = 'Set base rates';
const tariffSubt = 'View and add rates for non-account holders';
const ContractPricing = <Pricing key="contract" pageType="contract" title="Contract Pricing" subTitle={contractSubT} />;
const BasePricing = <Pricing key="base" pageType="base" title="Base pricing" subTitle={baseSubT} />;
const TariffPricing = <Pricing key="tariff" pageType="tariff" title="Tariff pricing" subTitle={tariffSubt} />;
const pricingRoutes = (
  <Route path="/pricing">
    <Route index element={ContractPricing} />
    <Route path="/pricing/tariff" element={TariffPricing} />
    <Route
      path="/pricing/tariff/create"
      element={
        <CreateRate
          key="create-rate-tariff"
          pageType="tariff"
          rateFor="tariff"
          copyRateText="Copy this tariff pricing to other services and systems"
        />
      }
    />
    <Route
      path="/pricing/tariff/edit"
      element={<EditRate key="edit-rate-tariff" pageType="tariff" rateFor="tariff" />}
    />

    <Route path="/pricing/base" element={BasePricing} />
    <Route
      path="/pricing/base/create"
      element={
        <CreateRate
          key="create-rate-base"
          pageType="base"
          rateFor="base"
          copyRateText="Copy this base pricing to other services and systems"
        />
      }
    />
    <Route path="/pricing/base/edit" element={<EditRate key="edit-rate-base" pageType="base" rateFor="base" />} />

    <Route path="/pricing/contract" element={ContractPricing} />
    <Route
      path="/pricing/contract/create"
      element={
        <CreateRate
          key="create-rate-contract"
          pageType="contract"
          rateFor="contract"
          copyRateText="Copy this contract pricing to other services and systems"
        />
      }
    />
    <Route
      path="/pricing/contract/edit"
      element={<EditRate key="edit-rate-contract" pageType="contract" rateFor="contract" />}
    />
  </Route>
);
/********************************************************************************************************************** */

const GlobalChargeRoutes = (
  <Route path="/global-charges" element={<GlobalCharge />}>
    <Route index element={<GlobalChargeList />} />
    <Route path="/global-charges/create" element={<GlobalChargeCreate />} />
    <Route path="/global-charges/edit/:id" element={<GlobalChargeEdit />} />
  </Route>
);

// ******************************** SQUAD ROUTES ******************************** \\
const SquadRoute = (
  <Route path="/squad" element={<SquadManagement />}>
    <Route path="/squad/create" element={<CreateSquad />} />
    <Route path="/squad/edit/:squadId" element={<CreateSquad />} />
  </Route>
);

const TaskManagementRoute = <Route path="/tasks" element={<TaskManagement />} />;

const SquadReportRoute = (
  <Route path="/squad-reports">
    <Route index element={<SquadReport />} />
    <Route path="details/:squadId" element={<SquadReportDetail />} />
  </Route>
);

const RouteOptimizerRoute = (
  <Route path="/route-optimizer" element={<RouteOptimizer />}>
    <Route index element={<RouteOptimizerList />} />
    <Route path="details/:routeId/:tripId" element={<RouteOptimizeDetails />} />
  </Route>
);

// ******************************** SQUAD ROUTES ******************************** //

export default (
  <>
    <Route path="/track-shipment" element={<TrackShipment />} />
    <Route path="/" element={<Home />}>
      {OrganizationSetupRoute}
      <Route index element={<HomeRoutes />}></Route>
      <Route path="/shipment-settings" element={<ShipmentSetting />} />
      <Route path="/kyc-settings" element={<KYCSettings />} />
      <Route path="/invoice-settings" element={<InvoiceSettings />} />
      <Route path="/org-settings" element={<OrganizationSettings />} />
      <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/component-ex" element={<ComponentEx />} />
      <Route path="/get-rates" element={<GetRatesV2 />} />
      <Route path="/my-rates" element={<MyRates />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/shipment">
        <Route index element={<CreateShipment />} />
        <Route path="/shipment/admin/history" element={<ShipmentHistory />} />
        <Route path="/shipment/history" element={<ShipmentHistory />} />
        <Route path="/shipment/admin/unprocessed/edit/:sn" element={<UnprocessedShipment />} />
        <Route path="/shipment/admin/edit/:sn" element={<ViewSingleShipmentAdmin />} />
        <Route path="/shipment/history/:id" element={<ViewSingleShipment />} />
        <Route path="/shipment/draft" element={<UnprocessedAndSaved />} />
        <Route path="/shipment/create-single" element={<CreateShipment />} />
        <Route path="/shipment/bulk-summary" element={<BulkOrderSummary />} />
        <Route path="/shipment/create-bulk" element={<CreateBulkShipment />}>
          <Route index element={<CreateBulkShipmentSheetUpload />} />
          <Route path="/shipment/create-bulk/sheet" element={<CreateBulkShipmentSheetUpload />} />
          <Route path="/shipment/create-bulk/sheet-match-header" element={<CreateBulkShipmentSheetMatchHeader />} />
          <Route path="/shipment/create-bulk/summary" element={<CreateBulkShipmentDetailsSummary />} />
        </Route>
        <Route path="/shipment/create-bulk/success" element={<CreateBulkShipmentSuccess />} />
        <Route path="/shipment/addresses" element={<ShipmentAddress />} />
        <Route path="/shipment/create-shipment-address" element={<CreateShipperAddress />} />
      </Route>
      <Route path="/user">
        <Route index element={<UserList />} />
        <Route path="/user/create-account" element={<CreateAccount />} />
        <Route path="/user/edit/:id" element={<CreateAccount />} />
        {/* <Route path="/invoice/list" element={<InvoiceList />} /> */}
      </Route>
      <Route path="/invoice">
        <Route index element={<InvoiceList />} />
        <Route path="/invoice/list" element={<InvoiceList />} />
        {/* <Route path="/invoice/create" element={<InvoiceCreate />} /> */}
        <Route path="/invoice/view" element={<InvoiceAdminView />} />
        <Route path="/invoice/non-account/createinvoice" element={<InvoiceCreateNonAccount />} />
        <Route path="/invoice/non-account/createdebitnote" element={<DebitNoteCreateNonAccount />} />
        <Route path="/invoice/non-account/createcreditnote" element={<CreditNoteCreateNonAccount />} />
        <Route path="/invoice/accountholder/createinvoice" element={<InvoiceCreateAccountHolder />} />
        <Route path="/invoice/accountholder/createdebitnote" element={<DebitNoteCreateAccountHolder />} />
        <Route path="/invoice/accountholder/createcreditnote" element={<CreditNoteCreateAccountHolder />} />
      </Route>
      <Route path="/admin">
        <Route index element={<AdminList />} />
        <Route path="/admin/create" element={<CreateAdmin />} />
        <Route path="/admin/edit/:id" element={<EditAdmin />} />
      </Route>
      <Route path="/manifest" element={<Manifest />} />
      <Route path="/transactions">
        <Route index path="/transactions/list" element={<Transactions />} />
        <Route path="/transactions/wallet" element={<TransactionWalletHistory />} />
        <Route path="/transactions/credit" element={<TransactionCreditHistory />} />
        <Route path="/transactions/credits-and-wallets" element={<CreditsAndWallets />} />
      </Route>
      {WarehouseRoute}
      {SystemRoute}
      {OdaRoute}
      {pricingRoutes}
      {GlobalChargeRoutes}
      {CarrierAndServiceRoute}
      {SquadRoute}
      {RouteOptimizerRoute}
      {TaskManagementRoute}
      {SquadReportRoute}
      {AccessGroupRoute}
      {NotificationsRoute}
    </Route>
    <Route path="*" element={<Navigate to="/" replace />} />
  </>
);
