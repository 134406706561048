import SbButton from '@Components/Button';
import Modal from '@Components/Modal';
import SbTextField from '@Components/Textfield';

import { InvoiceObj } from '@Reducers/InvoiceList/Type';
import { createPaymentByCash } from '@Reducers/InvoiceList/actions';
import { AppDispatch } from '@Store';
import { AccountBalanceWallet, CancelOutlined } from '@mui/icons-material';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { PaymentOption } from './Options';
import { initialValuesTypes } from './Types';
import API from '@Services/apiAxios';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const PaymentModal = ({
  invoiceObj,
  handleSuccess,
  isAccountHolder,
}: {
  invoiceObj: InvoiceObj;
  handleSuccess: () => void;
  isAccountHolder: boolean;
}) => {
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [creditBalance, setCreditBalance] = useState({
    isSelected: false,
    amount: 0,
  });
  const accumulativeTDS =
    invoiceObj?.comments?.reduce((accumulator, transaction) => {
      return accumulator + transaction.tdsAmount;
    }, 0) || 0;
  const dispatch = useDispatch<AppDispatch>();
  const { values, handleChange, errors, handleSubmit, resetForm } = useFormik<initialValuesTypes>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      amount: 0,
      tdsAmount: 0,
      comment: '',
      invoiceNumber: invoiceObj.invoiceNo,
      creditNoteAmount: creditBalance?.isSelected
        ? invoiceObj.dueAmount >= creditBalance.amount
          ? creditBalance.amount
          : invoiceObj.dueAmount
        : 0,
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .typeError('Amount must be a number')
        .required('Amount is required')
        .test('decimal-places', 'Amount can have up to 2 decimal places', value =>
          /^-?\d+(\.\d{1,2})?$/.test(value?.toString() || ''),
        )
        .test('amount-zero-test', 'Amount cannot be zero', value => {
          if (value == 0) return false;
          return true;
        })
        .test('amount-positive-check', 'Amount must be negative when due amount is 0', function (value) {
          const { tdsAmount } = this.parent;
          const { dueAmount } = invoiceObj;
          if (tdsAmount < 0 && value > 0 && Math.abs(tdsAmount) <= accumulativeTDS) return true;
          return dueAmount !== 0 || (value ?? 0) <= 0;
        })
        .test('total-not-exceed-due', 'Total amount must not exceed due amount', function (value) {
          return (value ?? 0) + (this.parent.tdsAmount ?? 0) <= invoiceObj.dueAmount;
        })
        .test('zero-amount-check', 'Amount and TDS cannot both be 0', function (value) {
          return !(value === 0 && this.parent.tdsAmount === 0);
        }),
      tdsAmount: Yup.number()
        .typeError('TDS must be a number')
        .test('tds-positive-check', 'Entered Tds cannot be more than Actual Tds', function (value) {
          const absValue = value ?? 0;
          if (accumulativeTDS == 0 && value) {
            if (value > 0) return true;
          }
          if (absValue > 0) return true;
          else {
            if (absValue * -1 > accumulativeTDS) return false;
            return true;
          }
        })
        .test('zero-tds-check', 'Amount and TDS cannot both be 0', function (value) {
          return !(value === 0 && this.parent.amount === 0);
        }),
      comment: Yup.string()
        .trim()
        .max(255, 'Comment must be at most 255 characters long')
        .required('Comment is required'),
    }),
    onSubmit(values) {
      dispatch(
        createPaymentByCash({
          postData: values,
          handleSuccess: () => {
            handleSuccess();
            setOpenPaymentModal(false);
            resetForm();
          },
        }),
      );
    },
  });

  const fetchCreditBalance = async (userId: string) => {
    try {
      const res = await API.get(`/payment/wallet/credit-note-balance/${userId}`);
      if (res.data?.data?.balance > 0) {
        setCreditBalance({ isSelected: true, amount: res.data?.data?.balance });
      }
      return;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  };

  useEffect(() => {
    if (!invoiceObj._accountId?.isPrepaid && openPaymentModal) {
      fetchCreditBalance(invoiceObj._accountRefUserId._id);
    }
  }, [invoiceObj._accountRefUserId?._id, invoiceObj._accountId?.isPrepaid, openPaymentModal]);

  const onClose = () => {
    setOpenPaymentModal(false);
    resetForm();
  };

  return (
    <Box>
      <Modal
        open={openPaymentModal}
        maxWidth="lg"
        sx={{
          padding: '2rem',
          '@media (max-width: 600px)': {
            padding: '1rem',
          },
        }}
      >
        <Box
          width={'28rem'}
          sx={{
            '@media (max-width: 600px)': {
              width: '100%',
            },
          }}
        >
          <Stack gap={'1rem'}>
            <Stack direction={'row-reverse'}>
              <IconButton onClick={onClose}>
                <CancelOutlined
                  sx={{
                    fontSize: '2rem',
                  }}
                />
              </IconButton>
            </Stack>
            {isAccountHolder && !invoiceObj._accountId?.isPrepaid && (
              <PaymentOption
                isRadio={false}
                title={'Credit Note'}
                subTitle={`Available balance: ₹ ${creditBalance?.amount || 0}`}
                icon={<AccountBalanceWallet sx={{ height: '40px', width: '40px', color: 'primary.main' }} />}
                selectedAmount={creditBalance}
                onSelect={obj => {
                  setCreditBalance(obj);
                }}
                amount={creditBalance.amount}
                isDisabled={creditBalance.amount <= 0}
              />
            )}

            <Typography
              variant="subtitle1"
              fontSize={'20px'}
              sx={{ '@media (max-width: 600px)': { fontSize: '16px' } }}
            >
              Pay by Cash
            </Typography>
            <SbTextField
              label={`Amount Paid`}
              required
              disabled={invoiceObj.dueAmount <= creditBalance.amount && creditBalance.isSelected}
              value={values.amount}
              placeholder={`93,000`}
              onChange={handleChange}
              name="amount"
              error={errors.amount}
              sx={{
                width: '100%',
              }}
            />

            {isAccountHolder && (
              <SbTextField
                label={`TDS`}
                value={values.tdsAmount}
                placeholder={`2,000`}
                onChange={handleChange}
                name="tdsAmount"
                error={errors.tdsAmount}
                sx={{
                  width: '100%',
                }}
              />
            )}
            <SbTextField
              label={`Comment`}
              value={values.comment}
              required
              placeholder={`Paid By RTGS UTR NO 1234`}
              onChange={handleChange}
              name="comment"
              error={errors.comment}
              sx={{
                width: '100%',
              }}
            />

            <Typography
              variant="subtitle1"
              fontWeight={'700'}
              fontSize={'16px'}
              sx={{ '@media (max-width: 600px)': { fontSize: '14px' } }}
            >
              Total Amount Paid: {Number(values.amount) + Number(values.tdsAmount)} INR
            </Typography>

            <SbButton variant="contained" onClick={() => handleSubmit()} sx={{ width: '100%' }}>
              Update
            </SbButton>
          </Stack>
        </Box>
      </Modal>
      <SbButton
        variant="outlined"
        sx={{
          borderRadius: '8px !important',
          padding: '8px !important',
          '@media (max-width: 600px)': {
            width: '100%',
          },
        }}
        onClick={() => {
          setOpenPaymentModal(true);
        }}
      >
        Pay
      </SbButton>
    </Box>
  );
};

export default PaymentModal;
