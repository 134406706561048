import Title from '@Components/Title';
import { AppDispatch, RootStateType } from '@Store';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery, useTheme, Box, IconButton, Stack, Typography, styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { onMessageListener } from '../../firebase';
import { fetchShipmentReports } from '@Reducers/shipmentReportsDownloadSlice/shipmentReportsDownloadSlice';
import { fetchUserReports } from '@Reducers/userReportsSlice/userReportsSlice';
import UserReportCard from './UserReportCard';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import API from '@Services/apiAxios';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { resetInvoiceState } from '@Reducers/invoiceSliceReport/invoiceSliceReport';
import { resetExportState } from '@Reducers/DownloadTransactionReport/DownloadTransactionReportSlice';
import { resetUserExportState } from '@Reducers/userInvoiceSlice/userInvoiceSlice';
import { clearState } from '@Reducers/squadReports/squadReportSlice';
import { clearDownloadSquadShipmentReport } from '@Reducers/downloadSquadShipmentReport/downloadSquadShipmentReport';
import { resetAdminExportState } from '@Reducers/AdminReport/adminExportSlice';
import { resetSquadManagementReportExportState } from '@Reducers/SquadManagementReport/SquadManagementReport';
import { resetPostpaidExportState } from '@Reducers/PostpaidUserReport/PostpaidUserReport';
import { resetBasePriceState } from '@Reducers/BasePricingReport/BasePricingReport';
dayjs.extend(relativeTime);

interface PropsType {
  icon: React.ReactNode;
}

function Count({ icon }: PropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const { reports } = useSelector((state: RootStateType) => state.shipmentReportsDownload);
  const { url } = useSelector((state: RootStateType) => state.downloadTransactionReport);
  const { downloadUrl } = useSelector((state: RootStateType) => state.downloadSquadReports);
  const { downloadUrl: downloadSquadShipmentReports } = useSelector(
    (state: RootStateType) => state.downloadSquadShipmentReports,
  );
  const userReportUrl = useSelector((state: RootStateType) => state.downloadUserInvoiceReport);
  const { accountUrl, nonAccountUrl } = useSelector((state: RootStateType) => state.downloadInvoiceReport);
  const userReports = useSelector((state: RootStateType) => state.userReports);
  const { fileUrl } = useSelector((state: RootStateType) => state.adminExportReports);
  const postpaidUserReport = useSelector((state: RootStateType) => state.postpaidExportReports);
  const squadManagementReport = useSelector((state: RootStateType) => state.squadManagementReport);
  const basePriceReport = useSelector((state: RootStateType) => state.basePricingReport);
  const booleanValue = useSelector((state: RootStateType) => state.booleanState.value);
  const { limit } = useSelector((state: RootStateType) => state.notifications);
  const [allReportsComplete, setAllReportsComplete] = useState(false);
  const [allShipmentReportsComplete, setAllShipmentReportsComplete] = useState(false);

  console.log('allUrlsAvailable:', allReportsComplete, allShipmentReportsComplete);

  useEffect(() => {
    dispatch(fetchShipmentReports());
    dispatch(fetchUserReports());
  }, [dispatch]);

  useEffect(() => {
    const isAnyReportRunning = () => {
      return userReports?.reports?.some(report => report.status === 'running');
    };

    const fetchReports = () => {
      if (isAnyReportRunning()) {
        dispatch(fetchUserReports());
        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
      } else {
        setAllReportsComplete(true);
      }
    };

    const intervalId = setInterval(() => {
      fetchReports();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [dispatch, userReports?.reports]);

  useEffect(() => {
    const isAnyReportRunning = () => {
      return reports?.some(report => report.status === 'running');
    };

    const fetchReports = () => {
      if (isAnyReportRunning()) {
        dispatch(fetchShipmentReports());
        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
      } else {
        setAllShipmentReportsComplete(true);
      }
    };

    const intervalId = setInterval(() => {
      fetchReports();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [dispatch, reports]);

  const handleOpenNotifications = () => {
    dispatch(toggleBoolean());
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  //Notification Listener
  onMessageListener(dispatch);

  const handleClearAll = async () => {
    try {
      dispatch(resetInvoiceState());
      dispatch(resetExportState());
      dispatch(resetUserExportState());
      dispatch(clearState());
      dispatch(clearDownloadSquadShipmentReport());
      dispatch(resetAdminExportState());
      dispatch(resetPostpaidExportState());
      dispatch(resetSquadManagementReportExportState());
      dispatch(resetBasePriceState());

      const userResp = await API.delete('/user/userreport');
      const shipmentResp = await API.delete('/shipment/shipmentreport');
      if (userResp?.status === 200) {
        dispatch(fetchUserReports());
      }
      if (shipmentResp?.status === 200) {
        dispatch(fetchShipmentReports());
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    }
  };

  return (
    <>
      <Wrapper>
        <Box onClick={handleOpenNotifications} className="cursor-pointer">
          {icon}
          <span className="count">
            {reports?.length +
              userReports?.reports?.length +
              (url ? 1 : 0) +
              (accountUrl ? 1 : 0) +
              (nonAccountUrl ? 1 : 0) +
              (downloadUrl ? 1 : 0) +
              (downloadSquadShipmentReports ? 1 : 0) +
              (squadManagementReport.url ? 1 : 0) +
              (fileUrl ? 1 : 0) +
              (postpaidUserReport?.url ? 1 : 0) +
              (basePriceReport?.data ? 1 : 0) +
              (userReportUrl.url ? 1 : 0)}
          </span>
        </Box>
      </Wrapper>
      <Drawer
        anchor="right"
        open={booleanValue}
        onClose={() => {
          dispatch(toggleBoolean());
        }}
        variant={isSmallScreen ? 'temporary' : 'persistent'} // Use temporary drawer for small screens
        sx={{
          '& .MuiDrawer-paper': {
            width: isSmallScreen ? '100%' : '30rem', // Full-width on small screens, fixed width on large screens
          },
        }}
      >
        <Box
          sx={{
            width: isSmallScreen ? '100%' : '30rem',
            padding: isSmallScreen ? '1rem' : '2rem 1rem 2rem 2rem', // Adjust padding for small screens
            height: '100%',
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
            <Title title="Downloads" removeBorder removePadding />
            <IconButton
              sx={{
                fontSize: '1.5rem',
              }}
              onClick={() => dispatch(toggleBoolean())}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          {(userReports?.reports?.length > 0 ||
            reports?.length > 0 ||
            url ||
            accountUrl ||
            nonAccountUrl ||
            downloadUrl ||
            fileUrl ||
            postpaidUserReport.url ||
            basePriceReport?.data ||
            squadManagementReport.url ||
            downloadSquadShipmentReports ||
            userReportUrl.url) && (
            <Stack sx={{ margin: '10px' }} direction="row" justifyContent="flex-end">
              <Typography onClick={handleClearAll} className="md-text font-medium underline cursor-pointer">
                Clear All
              </Typography>
            </Stack>
          )}
          <div>
            {userReports?.reports?.length > 0 ||
            reports?.length > 0 ||
            url ||
            accountUrl ||
            nonAccountUrl ||
            downloadUrl ||
            downloadSquadShipmentReports ||
            fileUrl ||
            postpaidUserReport.url ||
            basePriceReport?.data ||
            squadManagementReport.url ||
            userReportUrl.url ? (
              <>
                {userReports?.reports?.map(report => (
                  <UserReportCard
                    key={report._id}
                    type={report.type}
                    url={report.url}
                    createdAt={report.createdAt}
                    status={report.status}
                  />
                ))}
                {reports?.map(report => (
                  <UserReportCard
                    key={report._id}
                    type={report.type}
                    url={report.url}
                    createdAt={report.createdAt}
                    status={report.status}
                  />
                ))}
                {url && <UserReportCard url={url} type="Transactions Report" />}
                {accountUrl && <UserReportCard url={accountUrl} type="Account Holder Invoice Report" />}
                {nonAccountUrl && <UserReportCard url={nonAccountUrl} type="Non Account Holder Invoice Report" />}
                {userReportUrl.url && <UserReportCard url={userReportUrl.url} type="Invoice Report" />}
                {downloadUrl && <UserReportCard url={downloadUrl} type="Squad Report" />}
                {downloadSquadShipmentReports && (
                  <UserReportCard url={downloadSquadShipmentReports} type="Squad Shipment Report" />
                )}
                {fileUrl && <UserReportCard url={fileUrl} type="Admin Report" />}
                {postpaidUserReport?.url && (
                  <UserReportCard url={postpaidUserReport?.url} type="Credits and Wallet Report" />
                )}
                {squadManagementReport?.url && (
                  <UserReportCard url={squadManagementReport?.url} type="Squad Management Report" />
                )}
                {basePriceReport?.data && <UserReportCard url={basePriceReport?.data} type="Base Price Report" />}
              </>
            ) : (
              <p
                style={{
                  fontSize: '24px',
                  color: '#0f0e47',
                  display: 'flex',
                  height: '80vh',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Reports not found
              </p>
            )}
          </div>
        </Box>
      </Drawer>
    </>
  );
}

export default Count;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '44px',
  width: '44px',
  backgroundColor: '#f4f3f7',
  borderRadius: '12px',
  marginRight: '10px',
  position: 'relative',
  '& .count': {
    position: 'absolute',
    right: '2px',
    bottom: '5px',
    background: theme.palette.secondary.main,
    height: '20px',
    padding: '0 5px',
    borderRadius: '50px',
    color: theme.palette.primary.contrastText,
  },
}));
