import { createSlice } from '@reduxjs/toolkit';
// import { getUser } from './actions';
import { incrementHandler, decrementHandler } from './case';

const userSlice = createSlice({
  name: 'user',
  initialState: { value: 0 },
  reducers: {
    increment: incrementHandler,
    decrement: decrementHandler,
  },
  // extraReducers: _ => {
  //   const addCase = _.addCase;

  //   addCase(getUser.fulfilled, getUserFullfilled);
  //   addCase(getUser.rejected, getUserRejected);
  // },
  extraReducers: {
    // [getUser.fulfilled.toString()]: getUserFullfilled,
    // [getUser.rejected.toString()]: getUserRejected,
  },
});

const { increment, decrement } = userSlice.actions;

export { increment, decrement };
export default userSlice.reducer;
