import { Grid, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import SbButton from '../../../components/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AddressType } from '../../../store/reducers/SavedAddress/Type';

interface PropsType {
  addresses: AddressType[] | [];
  setSelectedAddress: (address: AddressType) => void;
}

function SavedAddresses({ addresses, setSelectedAddress }: PropsType) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState(-1);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };

  const onMenuItemClick = (address: AddressType, index: number) => {
    setAnchor(null);
    setSelected(index);
    setSelectedAddress(address);
  };

  return (
    <>
      {/* <Grid> */}
      <Grid container>
        <Grid item xs={12}>
          <SbButton
            sx={{
              fontSize: '14px',
              width: { xs: '100%', sm: '300px' },
              borderRadius: '8px !important',
              textAlign: 'unset !important',
              justifyContent: 'space-between',
            }}
            className="rounded-md bg-p-light"
            variant="outlined"
            onClick={openMenu}
            endIcon={<ArrowDropDownIcon />}
            disabled={!addresses.length}
          >
            Select from saved addresses
          </SbButton>
          <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={closeMenu}
            keepMounted
            PaperProps={{
              sx: {
                maxHeight: 40 * 6,
                width: { xs: '85%', sm: '300px' },
              },
            }}
          >
            {addresses.map((address, index) => {
              const { name, line1, line2, city, state, country, pincode } = address;
              return (
                <MenuItem key={index} onClick={_ => onMenuItemClick(address, index)} selected={index === selected}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className="font-semibold">{name}</Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        <Typography className="font-normal truncate-multiline">{`${line1} ${
                          line2 ? `${line2},` : ''
                        }`}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="font-normal truncate-multiline">{`${city}, ${state}, ${country}, ${pincode}`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </>
  );
}

export default SavedAddresses;
