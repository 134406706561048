import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { DraftShipmentCreatePayload, DraftShipmentDeletePayload, DraftShipmentListQuery } from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';

const getDraftShipmentList = createAsyncThunk('getDraftShipmentList', async (queryParams: DraftShipmentListQuery) => {
  const res = await API.get(`/shipment/draft`, { params: queryParams });
  return res.data;
});

const getDraftShipmentById = createAsyncThunk(
  'getDraftShipmentById',
  async ({ id, navigate }: { id: string } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.get(`/shipment/draft/${id}`);

      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
          navigateTo: navigate || null,
        }),
      );
      throw error;
    }
  },
);

const updateDraftShipmentById = createAsyncThunk(
  'updateDraftShipmentById',
  async (
    {
      id,
      payload,
      showModal,
      navigate,
    }: { id: string; payload: DraftShipmentCreatePayload } & SuccessErrorModalParamsType,
    thunkAPI,
  ) => {
    try {
      const res = await API.put(`/shipment/draft/${id}`, payload);
      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'DraftShipment successfully updated.',
            navigateTo: navigate || null,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const createDraftShipment = createAsyncThunk(
  'createDraftShipment',
  async (data: { payload: DraftShipmentCreatePayload } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.post(`/shipment/draft?`, data.payload);

      if (data.showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Shipment successfully draft.',
            navigateTo: data.navigate || null,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);
const deleteDraftShipment = createAsyncThunk(
  'deleteDraftShipment',
  async (
    data: { payload: DraftShipmentDeletePayload; queryParams: DraftShipmentListQuery } & SuccessErrorModalParamsType,
    thunkAPI,
  ) => {
    try {
      const res = await API.delete(`/shipment/draft?`, { params: data.payload });
      thunkAPI.dispatch(getDraftShipmentList(data.queryParams));
      if (data.showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Draft Shipment successfully deleted.',
            navigateTo: data.navigate || null,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

export {
  deleteDraftShipment,
  getDraftShipmentList,
  getDraftShipmentById,
  createDraftShipment,
  updateDraftShipmentById,
};
