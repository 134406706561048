import React from 'react';
import { Modal, CircularProgress, Grid } from '@mui/material';

import { BadgePropsType } from './Type';

export function Loader(props: BadgePropsType): JSX.Element {
  const { children, overly, loading } = props;

  return (
    <>
      <Modal open={loading} sx={{ display: 'block' }}>
        <Grid container justifyContent="center" alignItems="center" className="h-full">
          <CircularProgress color="primary" />
        </Grid>
      </Modal>
      {overly && children}
    </>
  );
}

export default Loader;
