import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { BulkOrderSummaryQuery, BulkShipmentAmount, BulkShipmentAmountResponse } from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const getBulkOrderSummaryList = createAsyncThunk(
  'getBulkOrderSummaryList',
  async (queryParams: BulkOrderSummaryQuery, { dispatch }) => {
    try {
      const res = await API.get(`/shipment/list/bulk?`, { params: queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.errorDetails || 'Something went wrong.',
        }),
      );
      throw { msg: 'No such shipment.' };
    }
  },
);
const getBulkShipmentAmount = createAsyncThunk<BulkShipmentAmountResponse, BulkShipmentAmount>(
  'getBulkShipmentAmount',
  async (queryParams, { dispatch }) => {
    try {
      const res = await API.get(`/shipment/payment/payable-amount/bulk`, { params: queryParams });
      return res.data as BulkShipmentAmountResponse;
    } catch (error) {
      const errorRes = error.response?.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw new Error('No such shipments.');
    }
  },
);

const ExportBulkOrderSummary = createAsyncThunk(
  'BulkOrderSummaryExport',
  async (queryParams: BulkOrderSummaryQuery) => {
    const res = await API.get(`/bulkorder/export?`, {
      params: { fromDate: queryParams.fromDate, toDate: queryParams.toDate },
    });
    window.open(res.data.data, '_blank');
    return {};
  },
);

export { getBulkOrderSummaryList, ExportBulkOrderSummary, getBulkShipmentAmount };
