import React from 'react';
import SbButton from '.';
import Image from '../Image';

function DownloadButton({ name, onClick, disabled }: { name: string; onClick: () => void; disabled?: boolean }) {
  return (
    <SbButton
      //   fullWidth
      startIcon={
        <Image src="/images/file_download.svg" alt={`download ${name}`} style={{ opacity: disabled ? 0.3 : 1 }} />
      }
      className="xs-text font-normal"
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </SbButton>
  );
}

export default DownloadButton;
