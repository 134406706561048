import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import {
  OdaCreatePayload,
  OdaCreateResponse,
  OdaListQuery,
  OdaObj,
  OdaSuccessResponse,
  OdaUpdatePayload,
  RejectPayload,
} from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';

const getOdaList = createAsyncThunk('oda/list', async (queryParams?: OdaListQuery) => {
  const res = await API.get(`/shipment/oda/list?`, { params: queryParams });
  return res.data;
});

const getActiveOdaList = createAsyncThunk('oda/active-list', async () => {
  const res = await API.get(`/shipment/oda`);
  return res.data;
});

const createOda = createAsyncThunk<
  OdaCreateResponse,
  { payload: OdaCreatePayload } & SuccessErrorModalParamsType,
  { rejectValue: RejectPayload }
>('oda/create', async (params, thunkAPI) => {
  try {
    const res = await API.post(`/shipment/oda`, params.payload);
    if (params.showModal)
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: 'ODA successfully created.',
          navigateTo: params.navigate || null,
        }),
      );
    return res.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});

const getOdaById = createAsyncThunk('oda/single', async (id: string) => {
  const res = await API.get(`/shipment/oda/${id}`);
  return res.data;
});

const getOdaChargeById = createAsyncThunk('oda/single-charge', async (id: string) => {
  const res = await API.get(`/shipment/oda/charges/${id}`);
  return res.data;
});

const updateOdaById = createAsyncThunk<
  OdaSuccessResponse<OdaObj>,
  { payload: OdaUpdatePayload } & SuccessErrorModalParamsType,
  { rejectValue: RejectPayload }
>('oda/update', async (params, thunkAPI) => {
  try {
    const res = await API.put(`/shipment/oda/${params.payload._id}`, params.payload);
    if (params.showModal)
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg || 'ODA successfully updated.',
          navigateTo: params.navigate || null,
        }),
      );
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    thunkAPI.dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    return thunkAPI.rejectWithValue(error.response?.data);
  }
});

const downloadOdaCharges = createAsyncThunk('oda/charges-download', async function (id: string) {
  const res = await API.get(`/shipment/download/oda/charge/${id}`);

  window.open(res.data.data, '_blank');
});

const deleteOdaById = createAsyncThunk('oda/delete', async ([id, queryParams]: [string, OdaListQuery], thunkAPI) => {
  try {
    const res = await API.delete(`/shipment/oda/${id}`);
    thunkAPI.dispatch(getOdaList(queryParams));
    thunkAPI.dispatch(
      SuccessErrorModalOpen({
        type: 'success',
        title: 'Success',
        subTitle: res.data.msg || 'ODA successfully fetched!',
      }),
    );
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    thunkAPI.dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

export {
  getOdaList,
  createOda,
  getOdaById,
  getOdaChargeById,
  updateOdaById,
  getActiveOdaList,
  downloadOdaCharges,
  deleteOdaById,
};
