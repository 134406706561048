import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FormHelperText, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';

import { alpha, styled, useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

import API from '@Services/apiAxios';

import Modal from '@Components/Modal';
import DragDrop from '@Components/File/DragDrop';
import SbButton, { SbLoadingButton } from '@Components/Button';
import SbTextField from '@Components/Textfield';

import { UploadDocumentType } from './Type';
import { FileResultType } from '../../Helper/file';
import Image from '../Image';

import { AppDispatch, RootStateType } from '@Store';
import { UpdateKycPutPayload } from '@Reducers/kyc/Type';
import { updateLoggedInUserKyc, uploadLoggedInUserKyc, uploadUserKyc } from '@Reducers/kyc';

const initFileData: FileResultType = {
  fileName: '',
  fileType: '',
  fileExtension: '',
  fileData: '',
  fileSize: '',
};

interface signedUrlResponseType {
  fileName: string;
  fileType: string;
  signedUrl: string;
}

async function getSignedURLResponse(_userId: string, fileType: string): Promise<signedUrlResponseType> {
  const result = await API.post(`/user/file/upload-url/user-kyc-${_userId}`, { mimeType: fileType });
  return result.data.data;
}

function UploadDocument(props: UploadDocumentType) {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch<AppDispatch>();
  const profileUserId = useSelector((state: RootStateType) => state.loginUser.profile?._id);

  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [_loading, setLoading] = useState<boolean>(false);
  const [fileData, setFileData] = useState<FileResultType>({ ...initFileData });

  useEffect(() => {
    if (!props.open) {
      setValue('');
      setFileData({ ...initFileData });
      setLoading(false);
    }
    if (props.open && props.documentData?.value?.trim()) setValue(props.documentData?.value?.trim());
  }, [props.open, props.documentData]);

  const onFileChange = (fileData: FileResultType[]) => setFileData(fileData[0]);

  const createDocument = async () => {
    setLoading(true);
    if (!props.userId) return;
    const signedUrlResponse: signedUrlResponseType = await getSignedURLResponse(props.userId, fileData.fileExtension);
    await axios.put(signedUrlResponse.signedUrl, fileData?.fileData, {
      headers: { 'Content-Type': fileData.fileType, 'Content-Encoding': 'base64' },
    });
    setLoading(false);
    const fileSize = fileData.fileSize?.split(' ')[0] || 0;
    const payload = {
      kycType: props.documentLabel,
      fileType: signedUrlResponse.fileType,
      fileName: signedUrlResponse.fileName,
      fileSize: Number(fileSize),
      value: value,
    };
    if (profileUserId === props.userId) {
      dispatch(uploadLoggedInUserKyc(payload));
    } else {
      dispatch(uploadUserKyc({ ...payload, _userId: props.userId }));
    }
  };

  const updateDocument = async () => {
    if (!props.userId) return;
    if (!props.documentData) return;
    const kycId = props.documentData._id;
    let signedUrlResponse: signedUrlResponseType | null = null;
    if (fileData?.fileData) {
      setLoading(true);
      signedUrlResponse = await getSignedURLResponse(props.userId, fileData.fileExtension);
      await axios.put(signedUrlResponse.signedUrl, fileData?.fileData, {
        headers: { 'Content-Type': fileData.fileType, 'Content-Encoding': 'base64' },
      });
      setLoading(false);
    }
    const fileSize = fileData.fileSize?.split(' ')[0] || 0;
    const payload: UpdateKycPutPayload = { _id: kycId, value: value };
    if (signedUrlResponse) {
      payload.fileType = signedUrlResponse.fileType;
      payload.fileName = signedUrlResponse.fileName;
      payload.fileSize = Number(fileSize);
    }
    dispatch(updateLoggedInUserKyc(payload));
  };

  const onFileUpload = async () => {
    if (!fileData?.fileData) return setError(`Please upload document.`);
    if (!value) return setError(`${props.documentLabel} KYC value is required.`);
    setError('');
    if (!props?.documentData?._id) return await createDocument();
    return await updateDocument();
  };

  return (
    <Modal
      open={props.open}
      sx={{ minHeight: '470px', bottom: 0 }}
      maxWidth="sm"
      // fullScreen={isFullScreen}
      classes={{ paper: isFullScreen ? 'items-center m-0' : 'rounded-md', container: isFullScreen ? 'items-end' : '' }}
    >
      <Grid container>
        <Grid container item justifyContent="center" className="mb-3">
          <Typography fontWeight="bold" variant="h6">
            Upload Document
          </Typography>
        </Grid>
        {!fileData?.fileData ? (
          <DragDrop subText="" accept="image/*, application/pdf" onChange={onFileChange} />
        ) : (
          <FileUploadName
            filename={fileData.fileName}
            fileSize={fileData.fileSize}
            onFileRemove={() => setFileData({ ...initFileData })}
          />
        )}
        <SbTextField
          style={{ margin: '20px 0' }}
          label={props.documentLabel}
          className="w-full"
          value={value}
          onChange={e => {
            e.preventDefault();
            setValue(e.target.value.trim());
          }}
        />
        {error && <FormHelperText error>{error}</FormHelperText>}
        <Grid container item spacing={2} justifyContent="end">
          <Grid item xs={12} sm={6} md={4}>
            <SbButton variant="outlined" fullWidth onClick={props.onClose}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SbLoadingButton
              variant="contained"
              fullWidth
              disabled={!fileData?.fileData || !value ? true : false}
              onClick={() => {
                onFileUpload();
              }}
              loading={_loading}
            >
              Upload File
            </SbLoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

interface FileUploadNameProps {
  filename: string;
  fileSize: string;
  onFileRemove: () => void;
}

const ContainerFileName = styled(Grid)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.25)}`,
  padding: 14,
  borderRadius: 8,
}));

function FileUploadName(props: FileUploadNameProps) {
  return (
    <>
      <ContainerFileName container item xs={12} p={1} className="mt-1" justifyContent="space-between">
        <Grid container item xs={11} gap={2}>
          <Grid item xs={'auto'}>
            <Image src={'/images/file_document.svg'} alt={'Speedbox'} width={45} height={45} />
          </Grid>
          <Grid item xs={6} md={10}>
            <Typography variant="h6" className="font-medium truncate">
              {props.filename}
            </Typography>
            <Typography variant="h6" className="sb-text-gray font-light">
              {props.fileSize}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={1} columnGap={2} alignItems="center" justifyContent="end">
          <IconButton onClick={props.onFileRemove}>
            <CancelIcon className="sb-text-gray" />
          </IconButton>
        </Grid>
      </ContainerFileName>
    </>
  );
}

export default UploadDocument;
