import * as Yup from 'yup';
const SystemSchema = {
  name: Yup.string().trim().required('System name is required'),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .matches(/^[^\s@]{3,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
  supportEmail: Yup.string()
    .trim()
    .required('Support email is required')
    .matches(/^[^\s@]{3,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
  countryCode: Yup.string().trim().required('Country Code is required'),
  number: Yup.string().trim().required('Phone number is required'),
};
export const CreateSystemSchema = Yup.object({
  ...SystemSchema,
  pincodes: Yup.array(
    Yup.object({
      pincode: Yup.string().trim().required(),
      zoneName: Yup.string().trim(),
      isPickupServiceable: Yup.boolean().default(false),
    }),
  ).min(1, 'Pincodes is required'),
});

export const UpdateSystemSchema = Yup.object().shape({
  ...SystemSchema,
  pincodes: Yup.array(
    Yup.object({
      pincode: Yup.string().trim().required(),
      zoneName: Yup.string().trim(),
      isPickupServiceable: Yup.boolean().default(false),
    }),
  ),
});
