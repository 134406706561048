import React, { FC } from 'react';
import { NestedPath } from './Type';
import { forwardRef } from 'react';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Image from '../Image';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

interface MenuRoutesType {
  label: string;
  Icon: FC | (OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & { muiName: string });
  nestedPaths: NestedPath[];
}

interface ImageIconProps {
  style: React.CSSProperties;
}
const MenuRoutes: MenuRoutesType[] = [
  {
    label: 'Home',
    Icon: HomeOutlinedIcon,
    nestedPaths: [
      {
        pathURL: '/profile',
        label: 'Profile',
        matchActionName: 'Profile',
        matchPaths: ['/profile'],
      },
      {
        pathURL: '/get-rates',
        label: 'Get Rates',
        matchActionName: 'Get Rates',
        matchPaths: ['get-rates'],
      },
      {
        pathURL: '/my-rates',
        label: 'My Rates',
        matchActionName: 'My Rates',
        matchPaths: ['my-rates'],
      },
    ],
  },
  {
    label: 'User Manage',
    Icon: PeopleAltOutlinedIcon,
    nestedPaths: [
      {
        pathURL: '/user',
        label: 'User',
        matchActionName: 'User',
        matchPaths: ['/user'],
      },
      {
        pathURL: '/admin',
        label: 'Admin',
        matchActionName: 'Admin',
        matchPaths: ['/admin/create'],
      },
    ],
  },
  {
    label: 'Shipments',
    Icon: forwardRef(({ style, ...props }: ImageIconProps, ref: React.ForwardedRef<HTMLDivElement>) => (
      <div ref={ref} style={style} {...props}>
        <Image src="/images/icons/menu/shipment.svg" alt="Shipments" style={{ width: '100%', height: '100%' }} />
      </div>
    )),
    nestedPaths: [
      {
        pathURL: '/shipment/create-single',
        label: 'Create Single',
        matchActionName: 'Create Single',
        matchPaths: ['/shipment/create-single'],
      },
      {
        pathURL: '/shipment/create-bulk/sheet',
        label: 'Create Bulk',
        matchActionName: 'Create Bulk',
        matchPaths: [
          '/shipment/create-bulk/sheet',
          '/shipment/create-bulk/sheet-match-header',
          '/shipment/create-bulk/summary',
        ],
      },
      {
        pathURL: '/shipment/history',
        label: 'Shipment History',
        matchActionName: 'Shipment History',
        matchPaths: ['/shipment/history'],
      },
      {
        pathURL: '/shipment/draft',
        label: 'Draft Shipments',
        matchActionName: 'Draft Shipments',
        matchPaths: ['/shipment/draft'],
      },
      {
        pathURL: '/shipment/bulk-summary',
        label: 'Bulk Summary',
        matchActionName: 'Create Bulk',
        matchPaths: ['/shipment/bulk-summary'],
      },
      {
        pathURL: '/manifest',
        label: 'Manifest',
        matchActionName: 'Manifest',
        matchPaths: ['/manifest'],
      },
      {
        pathURL: '/shipment/addresses',
        label: 'Addresses',
        matchActionName: 'Saved Address',
        matchPaths: ['/shipment/addresses'],
      },
    ],
  },
  {
    label: 'Invoice',
    Icon: forwardRef(({ style, ...props }: ImageIconProps, ref: React.ForwardedRef<HTMLDivElement>) => (
      <div ref={ref} style={{ ...style, height: '20px', width: '20px' }} {...props}>
        <Image src="/images/icons/menu/invoice.svg" alt="Shipments" style={{ width: '100%', height: '100%' }} />
      </div>
    )),
    nestedPaths: [
      // {
      //   pathURL: '/invoice/list',
      //   label: 'View Invoices',
      //   matchPaths: ['/invoice/list'],
      // },
      {
        pathURL: '/invoice/view',
        label: 'View Invoices',
        matchActionName: 'View Invoices',
        matchPaths: ['/invoice/view'],
      },
      {
        pathURL: '/transactions/list',
        label: 'Transactions',
        matchActionName: 'Transactions',
        matchPaths: ['/transactions/list'],
      },
      {
        pathURL: '/transactions/wallet',
        label: 'Wallet & Transactions',
        matchActionName: 'Wallet & Transactions',
        matchPaths: ['/transactions/wallet'],
      },
      {
        pathURL: '/transactions/credit',
        label: 'Credits & Transactions',
        matchActionName: 'Credits & Transactions',
        matchPaths: ['/transactions/credit'],
      },
      {
        pathURL: '/transactions/credits-and-wallets',
        label: 'Credits & Wallet',
        matchActionName: 'Credits & Wallet',
        matchPaths: ['/transactions/credits-and-wallets'],
      },
    ],
  },
  {
    label: 'Squad',
    Icon: forwardRef(({ style, ...props }: ImageIconProps, ref: React.ForwardedRef<HTMLDivElement>) => (
      <div ref={ref} style={{ ...style, height: '20px', width: '20px' }} {...props}>
        <Image src="/images/icons/menu/squad.svg" alt="Shipments" style={{ width: '100%', height: '100%' }} />
      </div>
    )),
    nestedPaths: [
      {
        pathURL: '/squad',
        label: 'Squad Management',
        matchActionName: 'Squad Management',
        matchPaths: ['/squad/create', '/squad'],
      },
      {
        pathURL: '/tasks',
        label: 'Task Management',
        matchActionName: 'Task Management',
        matchPaths: ['/tasks'],
      },
      {
        pathURL: '/route-optimizer',
        label: 'Route Optimizer',
        matchActionName: 'Route Optimizer',
        matchPaths: ['/route-optimizer'],
      },
      {
        pathURL: '/squad-reports',
        label: 'Squad Reports',
        matchActionName: 'Squad Reports',
        matchPaths: ['/squad-reports'],
      },
    ],
  },

  {
    label: 'Settings',
    Icon: RoomPreferencesOutlinedIcon,
    nestedPaths: [
      // {
      //   pathURL: '/system',
      //   label: 'System',
      //   matchPaths: ['/system/create', '/system'],
      // },
      {
        pathURL: '/warehouse',
        label: 'Warehouse',
        matchActionName: 'Warehouse',
        matchPaths: ['/warehouse/create', '/warehouse'],
      },
      {
        pathURL: '/system',
        label: 'System',
        matchActionName: 'System',
        matchPaths: ['/system/create', '/system'],
      },
      {
        pathURL: '/shipment-settings',
        label: 'Shipment',
        matchActionName: 'Shipment',
        matchPaths: ['/shipment-settings'],
      },
      {
        pathURL: '/org-settings',
        label: 'Organization',
        matchActionName: 'Organization',
        matchPaths: ['/org-settings'],
      },
      {
        pathURL: '/kyc-settings',
        label: 'KYC',
        matchActionName: 'KYC',
        matchPaths: ['/kyc-settings'],
      },
      {
        pathURL: '/invoice-settings',
        label: 'Invoice',
        matchActionName: 'Invoice',
        matchPaths: ['/invoice-settings'],
      },
      {
        pathURL: '/carrier-and-services?tab=service',
        label: 'Carrier & Services',
        matchActionName: 'Carrier & Services',
        matchPaths: ['/carrier-and-services/create/service'],
      },
      {
        pathURL: '/access-group',
        label: 'Access Groups',
        matchActionName: 'Access Groups',
        matchPaths: ['/access-group/create', '/access-group'],
      },
      {
        pathURL: '/notifications-settings',
        label: 'Notifications',
        matchActionName: 'Notifications',
        matchPaths: ['/notifications-settings'],
      },
    ],
  },
  {
    label: 'Pricing',
    Icon: PriceChangeOutlinedIcon,
    nestedPaths: [
      {
        pathURL: '/oda',
        label: 'ODA',
        matchActionName: 'ODA',
        matchPaths: ['/oda/create', '/oda'],
      },
      {
        pathURL: '/pricing/base?ratesFor=outbound',
        label: 'Base',
        matchActionName: 'Base',
        matchPaths: ['/pricing/base'],
      },
      {
        pathURL: '/pricing/contract?ratesFor=outbound',
        label: 'Contract',
        matchActionName: 'Contract',
        matchPaths: ['/pricing/contract'],
      },
      {
        pathURL: '/pricing/tariff?ratesFor=outbound',
        label: 'Tariff',
        matchActionName: 'Tariff',
        matchPaths: ['/pricing/tariff'],
      },
      {
        pathURL: '/global-charges',
        label: 'Global Charges',
        matchActionName: 'Global Charges',
        matchPaths: ['/global-charges/create', '/global-charges'],
      },
    ],
  },
];

export default MenuRoutes;
