import SbButton from '@Components/Button';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { PropsType } from './Type';
import { useNavigate } from 'react-router';
import { getTrackShipment } from '@Reducers/Tracking';
import { AppDispatch } from '@Store';
import { useDispatch } from 'react-redux';

export default function SearchBox(props: PropsType) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  // const isAdmin = !userLogin();
  const { id, label, onSearch, onClear, placeholder, required, hideStartAdornment, style } = props;
  const [inputValue, setInputValue] = React.useState<string>('');
  const [allowClear, setAllowClear] = React.useState<boolean>(false);

  return (
    <Grid className="w-full">
      {label && (
        <InputLabel className="mb-1" id={`${id}-label`} required={required}>
          {label}
        </InputLabel>
      )}

      <TextField
        fullWidth
        onChange={e => {
          setInputValue(e.target.value);
        }}
        id={`${id}-label`}
        placeholder={`${placeholder}/Shipment Number/Reference Number`}
        InputProps={{
          style: { padding: '2px', paddingLeft: '10px', borderRadius: '50px', ...style },
          startAdornment: hideStartAdornment ? <></> : <SearchIcon sx={{ ml: 1 }} color="primary" />,
          endAdornment: (
            <SbButton
              sx={{
                width: '12rem',
              }}
              variant="contained"
              onClick={() => {
                if (inputValue) {
                  onSearch(inputValue);
                  // if (token?.accessGroup) {
                  //   dispatch(getTrackShipment(inputValue));
                  //   navigate(`/track-shipment?trackingNumber=${inputValue}`);
                  // } else {
                  //   dispatch(getTrackShipment(inputValue));
                  //   navigate(`/track-shipment?trackingNumber=${inputValue}`);
                  // }
                  dispatch(getTrackShipment(inputValue));
                  navigate(`/track-shipment?trackingNumber=${inputValue}`);
                  window.location.reload();
                } else {
                  onClear();
                }
                setAllowClear(!allowClear);
              }}
            >
              {/* {!isAdmin ? 'Track' : allowClear ? 'Clear' : 'Track'} */}
              {/* {!token?.accessGroup ? 'Track' : allowClear ? 'Clear' : 'Track'} */}
              Track
            </SbButton>
          ),
        }}
      />
    </Grid>
  );
}
