import React from 'react';
import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps, styled } from '@mui/material';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface PropsType {
  name?: string;
  label: string;
  value?: boolean;
  defaultChecked?: boolean;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  labelClass?: string;
  slotProps?: FormControlLabelProps['slotProps'];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
}

export function SbSwitch(props: PropsType) {
  const { labelClass, label, value, defaultChecked, name, onChange, labelPlacement, slotProps, disabled } = props;
  return (
    <FormControlLabel
      control={
        <IOSSwitch
          sx={{ m: 1 }}
          checked={value || false}
          defaultChecked={defaultChecked || false}
          disabled={disabled}
        />
      }
      value={value}
      onChange={onChange}
      label={label}
      name={name}
      labelPlacement={labelPlacement}
      className={labelClass}
      slotProps={slotProps}
    />
  );
}
