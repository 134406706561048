import React from 'react';
import OrganizationSetupContext from '../../../context/OrganizationSetup';
import { OrganizationSetupContextType } from '../../../types/organization';
import KYCSettingTabs from './KYCSettingsTabs';

function OrgKYCSetting() {
  const { activeStep, handleNext, handleBack } = React.useContext(
    OrganizationSetupContext,
  ) as OrganizationSetupContextType;

  return (
    <>
      <KYCSettingTabs handleNext={handleNext} handleBack={handleBack} activeStep={activeStep} viewFor="org-setup" />
    </>
  );
}

export default OrgKYCSetting;
