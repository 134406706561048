import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { Notification, NotificationResponse, NotificationStateType } from './Type';
import {
  getAllReadNotifications,
  getAllUnreadNotifications,
  putAllNotificationsRead,
  putNotificationReadById,
  putNotificationToken,
} from './actions';
import { getNotificationFulfilled, getReadNotificationFulfilled, handlePending, handleRejected } from './cases';

const initialState: NotificationStateType = {
  count: 0,
  loading: false,
  notifications: [],
  totalReadNotifications: 0,
  readNotifications: [],
  limit: 10,
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationCount: (state: NotificationStateType, action: PayloadAction<Notification>) => {
      state.count = state.count + 1;
      // IF already have some notifications, then
      if (state.notifications?.length > 0) {
        // IF the notifications object group present with current date, then
        if (
          state.notifications?.find(
            (notification: NotificationResponse) => notification?._id === dayjs().format('YYYY-MM-DD'),
          )
        ) {
          // THEN extract the first group from the notification and add current notification in it.
          state.notifications = state.notifications.map((notification, index) =>
            index === 0
              ? {
                  _id: notification._id,
                  notifications: [action.payload, ...notification.notifications],
                }
              : notification,
          );
        } else {
          // ELSE add current notification with current date and copy other notifications
          state.notifications = [
            {
              _id: dayjs().format('YYYY-MM-DD'),
              notifications: [action.payload],
            },
            ...state.notifications,
          ];
        }
      } else {
        // ELSE add current notification with current date
        state.notifications = [
          {
            _id: dayjs().format('YYYY-MM-DD'),
            notifications: [action.payload],
          },
        ];
      }
    },
  },
  extraReducers: builder => {
    const addCase = builder.addCase;
    addCase(putNotificationToken.pending, handlePending);
    addCase(putNotificationToken.rejected, handleRejected);
    addCase(getAllUnreadNotifications.pending, handlePending);
    addCase(getAllUnreadNotifications.fulfilled, getNotificationFulfilled);
    addCase(getAllUnreadNotifications.rejected, handleRejected);
    addCase(getAllReadNotifications.pending, handlePending);
    addCase(getAllReadNotifications.fulfilled, getReadNotificationFulfilled);
    addCase(getAllReadNotifications.rejected, handleRejected);
    addCase(putNotificationReadById.pending, handlePending);
    addCase(putNotificationReadById.fulfilled, handleRejected);
    addCase(putNotificationReadById.rejected, handleRejected);
    addCase(putAllNotificationsRead.pending, handlePending);
    addCase(putAllNotificationsRead.fulfilled, handleRejected);
    addCase(putAllNotificationsRead.rejected, handleRejected);
  },
});

export default notificationSlice.reducer;

export const { setNotificationCount } = notificationSlice.actions;
