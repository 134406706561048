import { StepLabel, styled } from '@mui/material';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.primary.main,
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export const StepIconRoot = styled('div')(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  '& .circle-icon': {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    color: theme.palette.primary.main,
  },
  '& .MuiStepLabel-labelContainer': {
    position: 'absolute',
  },
}));

export const SBStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-iconContainer': {
    paddingRight: 0,
  },
  '& .MuiStepLabel-labelContainer': {
    position: 'absolute',
    bottom: '25px',
    right: '-12px',
    display: 'block',
    width: 'max-content',
    color: theme.palette.text.dark,
  },
  '& .MuiStepLabel-label.Mui-active': {
    color: theme.palette.text.dark,
    fontWeight: '400',
  },
}));
