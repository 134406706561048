import { Grid, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import Divider from '../../../../components/Divider';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { createShipmentContextType } from '../../../../types/shipment';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import {
  calculateBoxWiseChargeableWt,
  calculateChargeableWt,
  kgToLb,
  twoDecimalPlace,
} from '../../../../utils/measurements';

interface PropsType {
  label: string;
  value: string;
}

const TotalValueBox = styled(Grid)`
  background: rgba(217, 217, 217, 0.2);
  // height: 86px;
  padding: calc(var(--mui-palette-spacing) * 2.5) calc(var(--mui-palette-spacing) * 3);
  border-radius: 8px;

  .label {
    font-weight: 400;
    font-size: 16px;
  }

  .value {
    font-weight: 700;
    font-size: 24px;
  }
`;

function TotalValue({ label, value }: PropsType) {
  return (
    <Grid container item xs={12} md={4} sx={{ pb: { xs: 2, md: 0 } }}>
      <TotalValueBox container item justifyContent={'space-between'} alignItems={'center'}>
        <Typography
          sx={{
            color: '#161616',
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            color: '#161616',
            fontWeight: 'bold',
          }}
        >
          {value}
        </Typography>
      </TotalValueBox>
    </Grid>
  );
}

export default function TotalValues() {
  const { totalShipmentValue, boxes, chargeableWeight, actualWeight, shipmentType, shipmentById, setChargeableWeight } =
    React.useContext(CreateShipmentContext) as createShipmentContextType;
  const { chareableWeight } = useSelector((state: RootStateType) => state.getRates);
  const unit = shipmentType.unit.split('-')[0];
  const activeServices = useSelector((state: RootStateType) => state.carrierAndService.serviceList);
  const adminShipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.symbol);
  const { minWt, maxWt } = useMemo(
    function () {
      let minWt = Infinity;
      let maxWt = -Infinity;
      activeServices.forEach(function (csp) {
        const isBoxWise = csp.boxWiseRates;
        if (isBoxWise) {
          const chargeableWt = calculateBoxWiseChargeableWt(
            boxes,
            csp.dimWtDivisor.kg,
            shipmentType.unit === 'lb-in',
          ).reduce((total, obj) => {
            return total + obj.chargeableWeight;
          }, 0);
          minWt = chargeableWt < minWt ? chargeableWt : minWt;
          maxWt = chargeableWt > maxWt ? chargeableWt : maxWt;
        } else {
          const { chargeableWeight } = calculateChargeableWt(
            0,
            boxes,
            csp.dimWtDivisor.kg,
            shipmentType.unit === 'lb-in',
          );
          minWt = Number(chargeableWeight) < minWt ? Number(chargeableWeight) : minWt;
          maxWt = Number(chargeableWeight) > maxWt ? Number(chargeableWeight) : maxWt;
        }
      });
      return {
        minWt: twoDecimalPlace(kgToLb(minWt, shipmentType.unit === 'lb-in')),
        maxWt: twoDecimalPlace(kgToLb(maxWt, shipmentType.unit === 'lb-in')),
      };
    },
    [boxes, shipmentType.unit],
  );
  const activeShipment = adminShipmentById || shipmentById;

  // let displayChargeableWt = activeShipment ? `${chargeableWeight} ${unit}` : `${minWt} - ${maxWt} ${unit}`;
  // let displayChargeableWt = activeShipment
  //   ? activeShipment?.sellingPricing?.chargeableWeight === chargeableWeight
  //     ? `${activeShipment?.sellingPricing?.chargeableWeight} ${unit}`
  //     : `${chargeableWeight} ${unit}`
  //   : `${minWt} - ${maxWt} ${unit}`;

  // -----------------------------------------------------------------------------------
  // let displayChargeableWt = activeShipment ? `${chargeableWeight} ${unit}` : `${minWt} - ${maxWt} ${unit}`;
  const minChargeableWt = chareableWeight?.minChargeableWt ? chareableWeight?.minChargeableWt : 0;
  const maxChargeableWt = chareableWeight?.maxChargeableWt ? chareableWeight?.maxChargeableWt : 0;

  let displayChargeableWt = activeShipment
    ? `${chareableWeight?.maxChargeableWt} ${unit}`
    : `${minChargeableWt} - ${maxChargeableWt} ${unit}`;
  if (minWt === maxWt && !shipmentById) displayChargeableWt = minWt + ' Kg';

  console.log(displayChargeableWt);

  useEffect(() => {
    if (activeShipment && chareableWeight?.maxChargeableWt) {
      setChargeableWeight(chareableWeight?.maxChargeableWt);
    }
  }, [chargeableWeight]);

  const selectedUnit = useMemo(() => unit.split('-')[0], [unit]);

  return (
    <>
      <Grid container columnSpacing={3}>
        <TotalValue
          label={'Actual Weight'}
          value={`${parseFloat(`${actualWeight ? actualWeight : 0}`).toFixed(2)} ${unit}`}
        />
        <TotalValue
          label="Chargeable Weight"
          value={`${
            chareableWeight?.boxWiseArr
              ? `${
                  selectedUnit === 'lb'
                    ? ((chareableWeight.minChargeableWt ?? 0) / 600).toFixed(2)
                    : (chareableWeight.minChargeableWt ?? 0).toFixed(2)
                } - ${
                  selectedUnit === 'lb'
                    ? ((chareableWeight.maxChargeableWt ?? 0) / 600).toFixed(2)
                    : (chareableWeight.maxChargeableWt ?? 0).toFixed(2)
                }`
              : (0).toFixed(2)
          } ${selectedUnit}`}
        />
        <TotalValue
          label={'Shipment Value'}
          value={`${shipmentType.currency || orgCountryCurrency} ${totalShipmentValue.toFixed(2)}`}
        />
      </Grid>
      <Divider className="my-4" />
    </>
  );
}
