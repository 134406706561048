import { Grid, Typography, alpha, styled } from '@mui/material';
import { GridWrapperType } from './Type';

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: '600',
}));
export const SubHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: '400',
  marginBottom: theme.spacing(1),
}));
export const Name = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(2),
  fontWeight: '400',
  marginBottom: '6px',
}));
export const Details = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '400',
  marginBottom: '6px',
  color: '#515151',
  display: 'flex',
  // alignItems: 'center',
  wordBreak: 'break-all',
  '& .MuiSvgIcon-root': {
    marginTop: 2,
  },
}));
export const Pincode = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: '400',
  marginBottom: '6px',
  color: '#667085',
}));
export const GridWrapper = styled(Grid)<GridWrapperType>(({ theme, hasBackground }) => ({
  padding: theme.spacing(3),
  borderRadius: 12,
  border: '1px solid var(--mui-palette-grey-300)',
  backgroundColor: hasBackground ? alpha(theme.palette.primary.main, 0.05) : 'unset',
}));
