import FilterSelect from '@Components/Filter/Dropdown';
import { RootStateType } from '@Store';
import DateRangePicker from '../../components/DateRangePicker';

import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import API from '@Services/apiAxios';
import { ActionPending, ApiResponseTracking } from '@Components/AdminHome/types/type';
import TrackingTable, { Shipment } from '@Components/AdminHome/TrackingTable';
// import DownloadButton from '@Components/AdminHome/DownlaodButton';
import TrackingGraph from '@Components/AdminHome/TrackingGraph';

const TrackingStatus = () => {
  const [system, setSystem] = useState<string[]>([]);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [trackingStatus, setTrackingStatus] = useState<ApiResponseTracking | null>(null);
  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
  };
  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
  };

  const fetchDataForTrackingStatus = async () => {
    try {
      const resp = await API.get('/analytics/shipment/general', {
        params: {
          'system[]': system,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        },
      });
      setTrackingStatus(resp.data);
    } catch (error) {
      console.error('Error in fetching data for customers', error);
    }
  };

  useEffect(() => {
    fetchDataForTrackingStatus();
  }, [system, dateRange]);

  const mapToShipments = (actionPending: ActionPending[]): Shipment[] => {
    return actionPending?.map(item => ({
      id: item?.shipmentNumber,
      status: item?.status,
      statusLabel: item?.statusLabel,
      account: item?._userId.companyName || item?._userId.username,
      accountId: item?._userId.accountNumber || item?._userId.accountNumber,
    }));
  };

  return (
    <Box
      sx={{
        borderRadius: '9px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        padding: '40px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row-reverse' },
          gap: '10px',
        }}
      >
        <Box>
          <DateRangePicker onChange={onDateChange} value={dateRange} />
        </Box>
        <Box>
          <FilterSelect
            id="system"
            label="Select system"
            options={loggedInUserSystemDropdownList}
            value={system}
            onChange={onSystemFilter}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          gap: '20px',
        }}
      >
        <Box sx={{ flexBasis: '100%', sm: { flexBasis: '50%' } }}>
          <TrackingGraph
            shipmentPercentage={trackingStatus?.data?.shipmentPercentage}
            trackingData={trackingStatus?.data?.trackingData}
          />
        </Box>
        <Box
          sx={{
            flexBasis: '100%',
            sm: { flexBasis: '50%' },
            borderLeft: { sm: '1px dashed rgba(189, 189, 189, 1)', xs: 'none' },
            paddingLeft: { sm: '40px', xs: '0' },
          }}
        >
          <TrackingTable
            shipments={trackingStatus?.data.actionPending && mapToShipments(trackingStatus?.data.actionPending)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TrackingStatus;
