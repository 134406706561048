import React, { useEffect, useState } from 'react';
import { PickupDataType, PickupType } from '../../../types/shipment';
import { PickupTypeList, defaultPickupState } from '../../CreateShipment/constants';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import { FormHelperText, Grid, SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import Title from '@Components/Title';
import RadioButton from '@Components/RadioButton';
import DatePicker from '@Components/DatePicker';
import SBWarehouseDropdown from '@Components/Dropdown/Warehouse';
import SBDropdown from '@Components/Dropdown';
import Actions from './Actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { updatePickUpAndDropOff } from '@Reducers/AdminShipment';
import { UpdatePickUpAndDropOff } from '@Reducers/AdminShipment/Type';
import { usePickupDropoffAccess } from '@Hook/usePickupDropoffAccess';
import useDebounce from '@Hook/useDebounce';
import { checkPincodeServiceabilityForShipment } from '@Reducers/pincode/actions';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
// import { getWarehouseListForShipment } from '@Reducers/Warehouse';
import { getSpecificWarehouseListForShipment } from '@Reducers/Warehouse/actions';

export default function PickupDropoff() {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isPickupDropoffWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.pick_up_or_drop_off,
    PERFORM_ACTION.write,
  );

  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const { pickupTimeSlots } = shipmentSetting || {};

  const { warehouseListForShipmentOptionsHashById } = useSelector((state: RootStateType) => state.Warehouse);
  const countryHashById = useSelector((state: RootStateType) => state.global.countriesObj);
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);

  const [pickupType, setPickupType] = useState<PickupDataType>({ ...defaultPickupState });
  const [timeSlotsOption, setTimeSlotsOption] = useState<DropdownOptionType[]>([]);

  const [error, setError] = useState<string>('');

  const { disablePickup } = usePickupDropoffAccess({
    shipperCountry: shipmentById?._shipperId.country,
  });
  const shipperPincode = shipmentById?._shipperId.pincode;

  const debouncedSend = useDebounce(({ pincode, pickupType }: { pincode: string; pickupType: string }) => {
    if (pincode && pickupType === 'pickUp') dispatch(checkPincodeServiceabilityForShipment({ pincode }));
    // if (pickupType !== 'pickUp') dispatch(getWarehouseListForShipment(pincode ? { pincode } : {}));
    if (pickupType !== 'pickUp')
      dispatch(getSpecificWarehouseListForShipment(shipmentById?.accountNumber ? shipmentById?.accountNumber : ''));
  }, 1000);

  useEffect(() => {
    // if (pickupType.type === 'pickUp')
    debouncedSend({ pincode: shipperPincode ? shipperPincode : '', pickupType: pickupType.type });
  }, [shipperPincode, pickupType.type]);

  useEffect(() => {
    const _packageId = shipmentById?._packageId;
    if (_packageId) {
      const warehouseId = shipmentById._warehouseId;
      const isDropOff = _packageId.isDropOff;
      const timeSlot = _packageId?.timeSlot;
      const pickupDate = _packageId?.pickupDate;
      const date = pickupDate ? dayjs(pickupDate) : dayjs();
      setPickupType({
        ...pickupType,
        type: isDropOff ? 'dropOff' : 'pickUp',
        date,
        timeSlot: timeSlot,
        warehouse: warehouseId || '',
      });
    }
  }, [shipmentById?._packageId]);

  useEffect(() => {
    const slots = pickupTimeSlots?.slots;
    if (slots) {
      setTimeSlotsOption(
        slots.map(({ from, to }) => {
          const isToday = dayjs(pickupType.date).isSame(dayjs(), 'day');
          const checkPastTime = dayjs(to, 'hh a').isBefore(dayjs(dayjs(), 'hh:mm a'));
          const str = `${from} - ${to}`;
          return { display: str, value: str, disable: isToday && checkPastTime };
        }),
      );
    }
  }, [pickupTimeSlots, pickupType.date]);

  const handlePickupTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === 'dropOff') {
      setPickupType({ ...defaultPickupState, type: value as PickupType, warehouse: shipmentById?._warehouseId || '' });
    } else {
      setPickupType({ ...defaultPickupState, type: value as PickupType });
    }
    setError('');
  };

  const handleTimeSlotChange = (
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
  ) => {
    const value = event.target.value;
    setPickupType({ ...pickupType, timeSlot: value });
  };

  const handlePickupDateChange = (date: Dayjs) => {
    setPickupType({ ...pickupType, date: date });
  };

  const handleWarehouseChange = (
    [_selected]: Array<string>,
    _selectedObj: DropdownOptionType[],
    _event: SelectChangeEvent<string>,
  ) => {
    const value = _selected;
    setPickupType({ ...pickupType, warehouse: value });
  };

  const onSava = () => {
    const isDropOff = pickupType.type === 'dropOff';
    if (!isDropOff && !(pickupType.date && pickupType.timeSlot)) {
      return setError('Pickup date and time is required.');
    } else if (isDropOff && !pickupType.warehouse) {
      return setError('Drop of warehouse is required.');
    }
    error && setError('');
    if (!shipmentById) return;
    const payload: UpdatePickUpAndDropOff = { shipmentNumber: shipmentById.shipmentNumber, isDropOff: isDropOff };
    if (isDropOff && pickupType.warehouse) {
      const selectedWarehouse = warehouseListForShipmentOptionsHashById[pickupType.warehouse];
      const coAttention = selectedWarehouse.coAttention;
      const [telephoneCode, number] = coAttention.contact;
      payload.warehouseId = pickupType.warehouse;
      const countryId = selectedWarehouse._addressId.country;
      payload.warehouseAddress = {
        ...selectedWarehouse._addressId,
        country: countryHashById?.[countryId]?.name,
        name: selectedWarehouse.name,
        phone: { telephoneCode, number, name: coAttention.name },
        email: coAttention.email,
      };
    } else if (pickupType.date && pickupType.timeSlot) {
      payload.pickupDate = pickupType.date.toISOString();
      payload.timeSlot = pickupType.timeSlot;
    }

    dispatch(updatePickUpAndDropOff(payload));
  };
  return (
    <>
      <Grid container mb={2}>
        <Title title="Pickup / Drop-off" removeBorder removePadding />
      </Grid>
      <Grid container item className="p-4 mb-3 border border-solid border-light rounded">
        <Grid container item md={7} sx={{ py: 6 }}>
          <Grid container item md={6} xs={12} alignItems={'center'} className={isMobile ? '' : 'border-right pr-3'}>
            <RadioButton
              containerClass="w-full"
              name="type"
              value={pickupType.type}
              onChange={handlePickupTypeChange}
              list={PickupTypeList}
              sx={{ display: 'flex', columnGap: 10 }}
            />
          </Grid>
          {pickupType.type === 'dropOff' && (
            <Grid item md={6} xs={12} sx={{ pl: { lg: 3, xs: 0 } }}>
              <SBWarehouseDropdown
                value={pickupType.warehouse || ''}
                onChange={handleWarehouseChange}
                // disabled={shipperPincode ? disableDropoff : false}
                disabled={!isPickupDropoffWriteAllowed}
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
            </Grid>
          )}
          {pickupType.type === 'pickUp' && (
            <>
              <Grid item md={timeSlotsOption?.length ? 3 : 6} xs={6} sx={{ pl: { lg: 3, xs: 0 } }}>
                <DatePicker
                  minDate={shipmentById?.createdAt ? dayjs(shipmentById?.createdAt) : dayjs()}
                  label="Date"
                  value={pickupType.date}
                  handleChange={handlePickupDateChange}
                  disabled={
                    !isPickupDropoffWriteAllowed
                      ? !isPickupDropoffWriteAllowed
                      : shipperPincode
                      ? !!disablePickup
                      : false
                  }
                />
              </Grid>
              {timeSlotsOption?.length ? (
                <Grid item md={3} xs={6} className="pl-3">
                  <SBDropdown
                    label={'Time Slot'}
                    id="timeSlot"
                    name="timeSlot"
                    isSortingAllowed={false}
                    placeholder="Select time slot"
                    options={timeSlotsOption}
                    value={pickupType.timeSlot ? [pickupType.timeSlot] : []}
                    onChange={handleTimeSlotChange}
                    disabled={
                      !isPickupDropoffWriteAllowed
                        ? !isPickupDropoffWriteAllowed
                        : shipperPincode
                        ? !!disablePickup
                        : false
                    }
                  />
                  {error && <FormHelperText error>{error}</FormHelperText>}
                </Grid>
              ) : (
                <></>
              )}
            </>
          )}
        </Grid>

        {/* {shipmentActionControl?.canUpdatePickupDropoff && (
          <Actions disabled={!isPickupDropoffWriteAllowed} onSave={onSava} />
        )} */}
        <Actions disabled={!isPickupDropoffWriteAllowed} onSave={onSava} />
      </Grid>
    </>
  );
}
