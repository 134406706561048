import { BoxExtraType } from './getBoxName';

export default function updateBoxId<T extends BoxExtraType>(boxes: T[], boxToBeCopied: T, copyCount: number) {
  const boxesTemp = structuredClone(boxes);

  if (copyCount && boxToBeCopied.id.toString()) {
    const emptyBoxes = new Array(copyCount).fill(undefined).map(_ => structuredClone(boxToBeCopied));
    const startIndex = parseInt(boxToBeCopied.id) + 1;
    boxesTemp.splice(startIndex, 0, ...emptyBoxes);
  }

  return boxesTemp.map((data, ind) => ({ ...data, id: ind.toString() }));
}
