import { OrgSetupScreens } from '@Constants/route';
import { RootStateType } from '@Store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useOrgSetupStatus() {
  const OrgSetup = useSelector((state: RootStateType) => state.orgSetup);

  const orgCurrentLevel: OrgSetupScreens = useMemo(() => {
    return !OrgSetup.organizationPublicData?.initConfigLevel
      ? 'first-screen'
      : OrgSetup.organizationPublicData?.initConfigLevel;
  }, [OrgSetup.organizationPublicData?.initConfigLevel]);

  return {
    orgCurrentLevel,
  };
}
