import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import ErrorModal from '../../components/Modal/error';
import { CreateManifestSuccessModalType } from './Types';
import SbButton from '@Components/Button';

function CreateManifestSuccessModal(props: CreateManifestSuccessModalType) {
  const navigate = useNavigate();
  function redirectToManifestList() {
    return navigate('/manifest');
  }

  return (
    <ErrorModal open={props.isOpen} onClose={props.handleClose} icon={'/images/icons/SuccessIcon.svg'}>
      <Grid container className="mb-3" gap={3} justifyContent="flex-end">
        <Grid container item justifyContent="center" flexDirection="column" textAlign="center" sx={{ mt: 3 }}>
          <Typography className="md-text font-medium">You have successfully created manifest.</Typography>
        </Grid>
        <Grid container item justifyContent="center" textAlign="center">
          <SbButton variant="contained" sx={{ borderRadius: '8px !important', mr: 1 }} onClick={redirectToManifestList}>
            View
          </SbButton>
          <SbButton variant="outlined" sx={{ borderRadius: '8px !important', ml: 1 }} onClick={props.handleClose}>
            Ok
          </SbButton>
        </Grid>
      </Grid>
    </ErrorModal>
  );
}
export default CreateManifestSuccessModal;
