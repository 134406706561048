import React from 'react';
import { Typography } from '@mui/material';

export default function Status({ status }: { status: string }) {
  const { label, bgColor, textColor } = USER_STATUS_COLOR[status];
  return (
    <Typography
      sx={{ color: textColor, bgcolor: bgColor, px: 1, py: 0.5, fontWeight: 500 }}
      className="w-fit rounded-xs m-auto"
    >
      {label}
    </Typography>
  );
}

type UserStatusColor = {
  textColor: string;
  bgColor: string;
  label: string;
};

interface UserStatusColorMap {
  [key: string]: UserStatusColor;
}

const USER_STATUS_COLOR: UserStatusColorMap = {
  Active: {
    textColor: '#277563',
    bgColor: '#E9FFFA',
    label: 'Active',
  },
  Deactivated: {
    textColor: 'info.main',
    bgColor: 'info.light',
    label: 'Deactivated',
  },
};
