import BackButton from '@Components/BackButton';
import SbButton from '@Components/Button';
import Contact from '@Components/Contact';
import SBDropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import Image from '@Components/Image';
import Loader from '@Components/Loader';
import RadioButton from '@Components/RadioButton';
import SearchDropdown from '@Components/SearchDropdown';
import SbTextField from '@Components/Textfield';
import Title from '@Components/Title';
import SbTooltip from '@Components/Tooltip';
import { CSP_TYPES, CarrierAccessType, newCarrierAccessType } from '@Constants/Shipment';
import { getAdminListForTag } from '@Reducers/Admin/actions';
import { getLoggedInUserSystemList } from '@Reducers/Systems';
import {
  resetAccountCreated,
  resetAccountData,
  resetUserCarrierSetting,
  resetUserUpdated,
  setCarrierSettings,
} from '@Reducers/User';
import { UserObj } from '@Reducers/User/Type';
import {
  addCarrierSettings,
  createAccount,
  deleteCarrierSetting,
  getAccountList,
  getCarrierSettings,
  getCarrierSettingsList,
  getUserById,
  updateAccount,
} from '@Reducers/User/actions';
import API from '@Services/apiAxios';
import { fetchCarrierData } from '@Reducers/integration';
import { AppDispatch, RootStateType } from '@Store';
import { InfoRounded } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ACCOUNT_TAG } from '../UserList/Constant';
import CarrierSettingModal from './CarrierSettingModal';
import IndividualAccountHolderModal from './IndividualAccountHolderModal';
import SuccessModal from './SuccessModal';
import { CreateAcountchema, updateAcountchema } from './schema';
import DeleteModal from '@Components/Modal/Delete';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const AccountTypeList = [
  { value: 'individual', label: 'Individual' },
  { value: 'company', label: 'Company' },
];

const PaymentTermList = [
  { value: 'prepaid', label: 'Prepaid' },
  { value: 'postpaid', label: 'Postpaid' },
];
const TDSAllowList = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

const DefaultAdminDetails: UserObj = {
  email: '',
  phoneNumber: '',
  telephoneCode: '',
  belongToAdminId: '',
  accountType: AccountTypeList[0].value,
  isPrepaid: true,
  _systems: [],
  isWalkingUser: false,
  isHybridUser: false,
  defaultSystemId: '',
  belongToAccountId: '',
  accountTag: '',
  isAccountHolder: false,
  locationId: '',
  d365CustomerAccount: '',
  accountNumber: '',
  isTaxAllowed: false,
};

export const getUserBalance = async (accountNumber: string) => {
  try {
    const res = await API.get(`/payment/credit/current-balance/${accountNumber}`);
    const payload = (await res)?.data;
    return payload;
  } catch (err) {
    console.log('error while (getUserBalance) -', err);
    return err.response?.data;
  }
};

function CreateAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id: userId } = useParams();
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const loginUserprofile = useSelector((state: RootStateType) => state.loginUser.profile);
  // const orgId = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData?._id || '');
  // const alliedOrgIds = ['6531fe313f35bba887158ab0', '6529002be91c5f94b4633f83', '65d3202a7bb24dbfebfd1cae'];
  // const showD365Fields = alliedOrgIds.includes(orgId);
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const [carrierAccessTypeList, setCarrierAccessTypeList] = useState([]);
  const [showD365Fields, setShowD365Fields] = useState<boolean>(false);
  const carrierIntegration = useSelector((state: RootStateType) => state.carrierIntegration.carriers);

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    if (subdomain !== 'uat-speedbox' && subdomain !== 'localhost' && subdomain !== 'ae') {
      setShowD365Fields(false);
    } else {
      setShowD365Fields(true);
    }
  }, []);
  useEffect(() => {
    dispatch(fetchCarrierData());
  }, [dispatch]);

  useEffect(() => {
    if (carrierIntegration) {
      const transformedData: any = carrierIntegration.map(carrier => ({
        display: carrier.name,
        value: carrier.code,
      }));

      setCarrierAccessTypeList(transformedData);
    }
  }, [carrierIntegration]);
  const AdminList = useSelector((state: RootStateType) => state.admin.adminListForTag).map(admin => ({
    display: admin.name,
    value: admin._id,
  }));
  const AccountList = useSelector((state: RootStateType) => state.user.accountList).map(account => ({
    display: account.accountNumber,
    value: account._id,
  }));
  const {
    accountCreated,
    userDataById,
    loading: userLoading,
    carrierSetting,
    carrierSettingList,
  } = useSelector((state: RootStateType) => state.user);

  const [initials, setInitials] = useState<UserObj>({ ...DefaultAdminDetails });
  const [_errorModal, setErrorModal] = useState<{ open: boolean; msg: string }>({
    open: false,
    msg: '',
  });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [carrierAccessType, setCarrierAccessType] = useState<string>('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<string>('');
  const [isInduvidualAccountModalOpen, setInduvidualAccountModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isCreateAccountHolderAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.create_account_holder,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    setLoading(userLoading);
  }, [userLoading]);

  useEffect(() => {
    if (accountCreated.isError) {
      setErrorModal({ open: true, msg: accountCreated.msg });
    } else if (accountCreated.isEmailSent || accountCreated.isInserted) {
      setIsSuccessModalOpen(true);
    }
  }, [accountCreated]);

  function closeSuccessModal() {
    setIsSuccessModalOpen(false);
    dispatch(resetAccountCreated());
    dispatch(resetUserUpdated());
    handleBack();
  }

  useEffect(() => {
    dispatch(getLoggedInUserSystemList());
    dispatch(getAdminListForTag());
    dispatch(getAccountList());
    if (!userId && loginUserprofile) {
      setInitials(state => ({ ...state, belongToAdminId: loginUserprofile._id }));
    }
    return () => {
      dispatch(resetAccountData());
      resetForm();
    };
  }, []);

  useEffect(() => {
    if (userId) dispatch(getUserById(userId));
  }, [userId]);

  useEffect(() => {
    if (userDataById && userDataById._id === userId) {
      setInitials({
        ...userDataById,
        accountType: userDataById._accountId?.accountType,
        accountTag: userDataById._accountId?.accountTag,
        defaultSystemId: userDataById._systems[0],
        isPrepaid: userDataById._accountId?.isPrepaid,
        belongToAdminId: userDataById?._belongToAdminId?._id || '',
        belongToAccountId: userDataById._accountId?._id || '',
        isAccountHolder: !!userDataById._accountId?.isAccountHolder,
        isDefaultAccount: !!userDataById._accountId?.isDefaultAccount,
        locationId: userDataById?.locationId,
        d365CustomerAccount: userDataById?.d365CustomerAccount,
        isTaxAllowed: userDataById._accountId?.isTaxAllowed,
      });
      dispatch(getCarrierSettingsList(userDataById._accountId?.accountNumber || ''));
    }
  }, [userDataById]);

  function onCreate(params: UserObj) {
    const postObj = { ...params, accessGroup: 'pu_all' };
    if (params.defaultSystemId) {
      postObj._systems = params._systems.filter(system => system !== params.defaultSystemId);
      postObj._systems.unshift(params.defaultSystemId);
    }
    if (params?.accountType === 'company') {
      delete postObj.isWalkingUser;
    } else if (params?.accountType === 'individual') {
      delete postObj?.isHybridUser;
      delete postObj?.accountTag;
      delete postObj?.belongToAccountId;
    }
    delete postObj?._accountId;
    delete postObj.defaultSystemId;
    if (userDataById) {
      const payload = {
        _id: postObj._id || '',
        belongToAdminId: postObj.belongToAdminId || '',
        _systems: postObj._systems,
        isPrepaid: postObj.isPrepaid || false,
        accountTag: postObj.accountTag || userDataById._accountId?.accountTag || '',
        accountType: postObj.accountType || '',
        isAccountHolder: !!postObj.isAccountHolder,
        isHybridUser: postObj.isHybridUser,
        isWalkingUser: postObj.isWalkingUser,
        locationId: postObj.locationId,
        d365CustomerAccount: postObj.d365CustomerAccount,
        email: postObj.email,
        phoneNumber: postObj.phoneNumber,
        telephoneCode: postObj.telephoneCode,
        isTaxAllowed: postObj.isTaxAllowed || false,
      };
      dispatch(
        updateAccount({
          payload: payload,
          invitationSend: userDataById?.onBoardedStatus === 'invitation_send' ? true : false,
        }),
      );
    } else {
      dispatch(
        createAccount({
          body: { ...postObj, systems: postObj._systems },
          nextActionBody: carrierSetting.fedex,
        }),
      );
    }
  }

  function handleBack() {
    navigate(-1);
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initials },
    validationSchema: userDataById ? updateAcountchema : CreateAcountchema,
    onSubmit(values) {
      onCreate(values);
    },
  });

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setFieldError, resetForm } = formik;

  const selectedSystemList = useMemo(() => {
    const arr: Array<DropdownOptionType> = [];
    values?._systems?.map(systemId => {
      const obj = loggedInUserSystemDropdownList.find(system => system.value === systemId);
      if (obj) {
        arr.push(obj);
      }
    });
    return arr;
  }, [values._systems]);

  const isCompany = useMemo(() => values.accountType === 'company', [values.accountType]);

  const handleAccountTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (e.target.value === 'individual') {
      setFieldValue('_systems', []);
      setFieldValue('isWalkingUser', false);
      setFieldValue('belongToAccountId', '');
      setFieldValue('accountTag', '');
    } else {
      setFieldValue('isHybridUser', false);
      setFieldValue('isAccountHolder', true);
    }
  };

  const handlePaymentTypeChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (userDataById?._accountId?._id) {
      setLoading(true);
      const res = await getUserBalance(userDataById._accountId._id);

      setLoading(false);
      if (res?.status) {
        return;
      } else if (res.data?.availableLimit !== 0) {
        setFieldError('isPrepaid', 'Outstanding balance is greater than 0');
      } else {
        setFieldValue('isPrepaid', e.target.value === PaymentTermList[0].value, false);
      }
    } else setFieldValue('isPrepaid', e.target.value === PaymentTermList[0].value, false);
  };
  const handleTdsChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFieldValue('isTaxAllowed', value === TDSAllowList[0].value);
  };
  const showSystemDropDown = !userDataById
    ? true
    : (values.isDefaultAccount && (values.accountType === 'company' || values.isAccountHolder)) || true;

  useEffect(() => {
    if (carrierAccessType === 'stage') {
      if (userDataById?._id) {
        const formattedValues = {
          csp: 'stage',
          accountNumber: userDataById?.accountNumber || 'default_account_number',
          _accountId: userDataById?.createdBy?._id || 'default_account_id',
        };
        if (carrierSetting) {
          dispatch(addCarrierSettings({ ...formattedValues }));
        }
      } else {
        dispatch(setCarrierSettings({ values: {}, carrierType: 'stage' }));
      }
    }
  }, [carrierAccessType, dispatch]);

  return (
    <>
      <SuccessModal isOpen={isSuccessModalOpen} handleClose={closeSuccessModal} userEmail={values.email} />
      <Loader loading={loading || userLoading} overly>
        <>
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            <BackButton link="/user" />
            <Title
              title={`${userId ? 'Edit' : 'Create'} Account`}
              subTitle="Send invite to your users to create an account "
            />
          </Box>

          <Grid container columnSpacing={4} rowSpacing={4} mt={1}>
            <Grid item xs={12} md={6} lg={4} xl={2.5}>
              <SbTextField
                required
                placeholder="Enter email id"
                label="Email"
                name="email"
                onChange={handleChange}
                value={values.email}
                error={touched.email && errors.email ? errors.email : ''}
                // disabled={!!userId}
                disabled={userDataById?.isEmailVerified ? !!userId : userDataById?.isEmailVerified}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} xl={2.5}>
              <Contact
                label="Contact No"
                phoneNumber={values.phoneNumber}
                onChangePhoneNumber={e => {
                  handleChange(e);
                  if (!values.telephoneCode) {
                    const defaultTelephoneCode = organizationCountry?.telephoneCode.toString() || '';
                    setFieldValue('telephoneCode', defaultTelephoneCode);
                  }
                }}
                telCountryCode={values.telephoneCode}
                onChangeTelCountryCode={handleChange}
                phoneName="phoneNumber"
                telCountryCodeName="telephoneCode"
                setDefaultTelephone={(value: number) => setFieldValue('telephoneCode', value)}
                error={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                disabled={userDataById?.isPhoneVerified ? !!userId : userDataById?.isPhoneVerified}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={4} mt={4}>
            <Grid item xs={12} md={6} lg={4} xl={2.5}>
              <InputLabel sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                Tagged To
                <SbTooltip placement="right" title={`Enter the admin username to whom this account belongs to`}>
                  <InfoRounded sx={{ ml: 1 }} />
                </SbTooltip>
              </InputLabel>
              <SearchDropdown
                label=""
                id="company-country-id"
                placeholder="Select Admin Username"
                options={AdminList}
                value={AdminList.filter(x => x.value === values.belongToAdminId)}
                hideStartAdornment
                onChange={value => {
                  setFieldValue('belongToAdminId', value?.[0]?.value, false);
                }}
              />
              {/* <SBDropdown
                // required
                id="company-country-id"
                // label=""
                placeholder="Select Admin Username"
                value={[values?.belongToAdminId || '']}
                options={AdminList}
                onChange={([value]) => setFieldValue('belongToAdminId', value, false)}
              /> */}
            </Grid>
          </Grid>
          <Grid container columnSpacing={4} mt={4}>
            <Grid item xs={4} md={4} lg={2} xl={1.5}></Grid>
            <Grid item xs={4} md={4} lg={2} xl={1.5} textAlign="center" marginTop="auto">
              <Image src="/images/icons/user/User.svg" className="pl-3" alt="User" />
            </Grid>
            <Grid item xs={4} md={4} lg={2} xl={1.5} textAlign="center">
              <Image src="/images/icons/user/Business.svg" className="pr-3" alt="Business" />
            </Grid>
          </Grid>
          <Grid container alignItems="center" columnSpacing={4}>
            <Grid container item xs={4} md={4} lg={2} xl={1.5} alignItems="flex-start">
              <InputLabel sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>Account Type</InputLabel>
            </Grid>
            <Grid item xs={8} md={8} lg={4} xl={3} textAlign="center">
              <RadioButton
                containerClass="w-full"
                name="accountType"
                defaultValue={values.accountType}
                value={values.accountType}
                onChange={handleAccountTypeChange}
                list={AccountTypeList}
                sx={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'start' }}
                disabled={!!(userId && userDataById?._accountId?.accountType === 'company')}
              />
            </Grid>
          </Grid>

          {values.accountType === 'individual' && (
            <Grid container item xs={12} alignItems={'center'}>
              <Typography
                className="sm-text"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 2,
                  color: '#344054',
                  maxWidth: { lg: '10%', md: '20%' },
                }}
              >
                Make this individual an account holder?
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isAccountHolder"
                    id="isAccountHolder"
                    checked={!!values.isAccountHolder}
                    onChange={() => {
                      if (!values.isAccountHolder) setInduvidualAccountModalOpen(true);
                      setFieldValue('isAccountHolder', !values.isAccountHolder);
                    }}
                  />
                }
                label=""
                disabled={!isCreateAccountHolderAllowed || values?._accountId?.isAccountHolder}
              />
            </Grid>
          )}

          <Grid container alignItems="center" columnSpacing={4} mt={2}>
            <Grid container item xs={4} md={4} lg={2} xl={1.5} alignItems="flex-start">
              <InputLabel sx={{ display: 'flex', alignItems: 'center', mb: 1 }}> Taxation Allowed</InputLabel>
            </Grid>
            <Grid item xs={8} md={8} lg={4} xl={3} textAlign="center">
              <RadioButton
                containerClass="w-full"
                name="isTaxAllowed"
                defaultValue={
                  values.isTaxAllowed === undefined || !values.isTaxAllowed
                    ? TDSAllowList[1].value
                    : TDSAllowList[0].value
                }
                value={values.isTaxAllowed ? TDSAllowList[0].value : TDSAllowList[1].value}
                onChange={handleTdsChange}
                list={TDSAllowList}
                sx={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'start' }}
              />
            </Grid>
            {errors.isTaxAllowed && (
              <Grid item xs={12} textAlign="center">
                <FormHelperText error>{errors.isTaxAllowed}</FormHelperText>
              </Grid>
            )}
          </Grid>
          <Grid container alignItems="center" columnSpacing={4} mt={2}>
            <Grid container item xs={4} md={4} lg={2} xl={1.5} alignItems="flex-start">
              <InputLabel sx={{ display: 'flex', alignItems: 'center', mb: 1 }}> Payment Type</InputLabel>
            </Grid>
            <Grid item xs={8} md={8} lg={4} xl={3} textAlign="center">
              <RadioButton
                containerClass="w-full"
                name="isPrepaid"
                defaultValue={values.isPrepaid ? PaymentTermList[0].value : PaymentTermList[1].value}
                value={values.isPrepaid ? PaymentTermList[0].value : PaymentTermList[1].value}
                onChange={handlePaymentTypeChange}
                list={PaymentTermList}
                sx={{ display: 'flex', justifyContent: 'space-evenly', textAlign: 'start' }}
                disabled={!values?.isAccountHolder}
              />
            </Grid>
            {errors.isPrepaid && (
              <Grid item xs={12} textAlign="center">
                <FormHelperText error>{errors.isPrepaid}</FormHelperText>
              </Grid>
            )}
          </Grid>
          <Grid container columnSpacing={4} rowSpacing={4} mt={0}>
            {showSystemDropDown && (
              <Grid item xs={12} md={6} lg={4} xl={2.5}>
                <SBDropdown
                  required
                  multiple={isCompany}
                  id="company-country-id"
                  label="System"
                  placeholder="Select System"
                  value={values._systems}
                  options={loggedInUserSystemDropdownList}
                  onChange={systems => {
                    setFieldValue('_systems', systems, false);
                    if (!systems.some(sys => sys === values?.defaultSystemId))
                      setFieldValue('defaultSystemId', systems[0], false);
                  }}
                  error={touched._systems && errors._systems ? errors._systems?.toString() : ''}
                />
              </Grid>
            )}
            {isCompany && (
              <Grid item xs={12} md={6} lg={4} xl={2.5}>
                <SBDropdown
                  required
                  disabled={selectedSystemList?.length < 2}
                  id="company-country-id"
                  label="Default System"
                  placeholder="Select Default System"
                  value={[values?.defaultSystemId || '']}
                  options={selectedSystemList}
                  onChange={([value]) => setFieldValue('defaultSystemId', value)}
                  error={touched.defaultSystemId && errors.defaultSystemId ? errors.defaultSystemId : ''}
                />
              </Grid>
            )}
          </Grid>
          {isCompany && (
            <Grid container columnSpacing={4} rowSpacing={4} mt={0}>
              <Grid item xs={12} md={6} lg={4} xl={2.5}>
                <SBDropdown
                  id="company-country-id"
                  label="Tag Existing Account"
                  placeholder="Select Exiting Account"
                  name="belongToAccountId"
                  value={[values?.belongToAccountId || '']}
                  options={AccountList}
                  onChange={([value], [selectedObj]) => {
                    setFieldValue('belongToAccountId', value, false);
                    dispatch(
                      setCarrierSettings({
                        values: {
                          _accountId: selectedObj.value || '',
                          accountNumber: selectedObj.display || '',
                        },
                        carrierType: 'fedex',
                      }),
                    );
                  }}
                  disabled={!!userId}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={2.5}>
                <SBDropdown
                  required
                  id="company-country-id"
                  label="Category"
                  placeholder="Select Category"
                  name="accountTag"
                  value={[values?.accountTag || '']}
                  options={ACCOUNT_TAG}
                  onChange={([value]) => setFieldValue('accountTag', value, true)}
                  error={touched.accountTag && errors.accountTag ? errors.accountTag : ''}
                />
              </Grid>
            </Grid>
          )}
          <Grid container columnSpacing={4} rowSpacing={4} mt={0}>
            {isCompany ? (
              <>
                <Grid container item xs={12} md={12} lg={12} alignItems={'end'}>
                  <InputLabel sx={{ display: 'flex', alignItems: 'center', mb: 1, mr: 2 }}>
                    Allow Carrier access
                    <SbTooltip placement="right" title={`Allow this user to create integrated carrier labels.`}>
                      <InfoRounded sx={{ ml: 1 }} />
                    </SbTooltip>
                  </InputLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isHybridUser"
                        id="isHybridUser"
                        checked={values.isHybridUser}
                        onChange={handleChange}
                      />
                    }
                    label=""
                  />
                </Grid>
                {values.isHybridUser ? (
                  <>
                    <Grid container item xs={12} md={6} lg={4} xl={2.5} gap={'2rem'}>
                      <SBDropdown
                        id="carrierAccessType"
                        name="carrierAccessType"
                        label={``}
                        placeholder="FedEX"
                        value={[carrierAccessType]}
                        options={carrierAccessTypeList}
                        onChange={([value]) => {
                          setCarrierAccessType(value);
                        }}
                      />
                      {carrierSettingList.map(item => (
                        <Stack
                          key={item.csp}
                          direction={'row'}
                          justifyContent={'space-between'}
                          minWidth={'18rem'}
                          padding={'0.5rem 1rem'}
                          alignItems={'center'}
                          border={'1px solid'}
                          borderColor={'border.grey'}
                          borderRadius={'5px'}
                        >
                          <Box>
                            {/* TODO: Add more carrier image */}
                            <Image
                              // src={item.csp === CSP_TYPES?.fedex.value ? '/images/fedex.svg' : ''}
                              src={item.csp ? `/images/${item.csp}.svg` : ''}
                              alt={item.csp}
                              style={{ width: '4' }}
                            />
                          </Box>
                          <Box>
                            <IconButton
                              onClick={() => {
                                const carrierType = item.csp as CarrierAccessType; // Type assertion
                                const newcarrierType = item.csp as newCarrierAccessType;
                                setCarrierAccessType(item.csp);
                                dispatch(
                                  getCarrierSettings({
                                    csp: CSP_TYPES[carrierType].value,
                                    accountNumber: carrierSetting[newcarrierType].accountNumber,
                                  }),
                                );
                              }}
                            >
                              {item?.csp !== 'stage' && (
                                <Image
                                  src="/images/icons/EditAction.svg"
                                  alt="Back Button"
                                  style={{ width: '1.2rem' }}
                                />
                              )}
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setIsDeleteModalOpen(item?.csp);
                                dispatch(resetUserCarrierSetting(item?.csp));
                              }}
                            >
                              <Image
                                src="/images/icons/DeleteAction.svg"
                                alt="Back Button"
                                style={{ width: '1.2rem' }}
                              />
                            </IconButton>
                          </Box>
                        </Stack>
                      ))}
                      {/* <button style={{ marginTop: '1rem' }} onClick={() => setOpenCarrierSettingModal(true)}>
                    carrier setting{' '}
                  </button> */}
                    </Grid>
                  </>
                ) : null}
              </>
            ) : (
              <></>
              // JIRA 52 -> Manage User>>User Listing>>Create User>>Remove "Walkin user" field
              // <Grid container item xs={12} md={6} lg={4} xl={2.5} alignItems={'end'}>
              //   <InputLabel sx={{ display: 'flex', alignItems: 'center', mb: 1, mr: 2 }}>
              //     Walk in user
              //     <SbTooltip
              //       placement="right"
              //       title={`Book shipments for walk-in customers who have not signed up, using this account. You can choose who to bill the proforma invoice while booking shipments from this account.`}
              //     >
              //       <InfoRounded sx={{ ml: 1 }} />
              //     </SbTooltip>
              //   </InputLabel>
              //   <FormControlLabel
              //     control={
              //       <Checkbox
              //         name="isWalkingUser"
              //         id="isWalkingUser"
              //         checked={values.isWalkingUser}
              //         onChange={handleChange}
              //       />
              //     }
              //     label=""
              //   />
              // </Grid>
            )}
          </Grid>
          {showD365Fields && (
            <>
              {userDataById?._accountId?.isAccountHolder || !userId ? (
                <>
                  <Grid container columnSpacing={4} rowSpacing={4} mt={1}>
                    <Grid item xs={12} md={6} lg={4} xl={2.5}>
                      <SbTextField
                        placeholder="Enter Customer Location ID"
                        label="Location ID"
                        name="locationId"
                        onChange={handleChange}
                        value={values.locationId}
                        error={touched.locationId && errors.locationId ? errors.locationId : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} xl={2.5}>
                      <SbTextField
                        placeholder="Enter Customer Account"
                        label="D365 Account"
                        name="d365CustomerAccount"
                        onChange={handleChange}
                        value={values.d365CustomerAccount}
                        error={
                          touched.d365CustomerAccount && errors.d365CustomerAccount ? errors.d365CustomerAccount : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </>
          )}
          <Grid container columnSpacing={4} rowSpacing={4} mt={2} justifyContent={'flex-end'}>
            <Grid item xs={12} sm={4} md={2} lg={1.5}>
              <SbButton
                variant="outlined"
                className="w-full"
                sx={{ mr: 2, borderRadius: `4px !important` }}
                onClick={handleBack}
              >
                Back
              </SbButton>
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={1.5}>
              <SbButton
                variant="contained"
                sx={{ borderRadius: `4px !important` }}
                className="w-full"
                onClick={() => handleSubmit()}
              >
                {userId ? 'Update' : 'Invite'}
              </SbButton>
            </Grid>
          </Grid>
        </>
      </Loader>
      {carrierAccessType !== 'stage' && (
        <CarrierSettingModal
          open={carrierAccessType || ''}
          onClose={() => {
            setCarrierAccessType('');
          }}
          carrierAccessType={carrierAccessType}
        />
      )}
      {!!isDeleteModalOpen && (
        <DeleteModal
          title={`Do you want to delete ${isDeleteModalOpen} carrier settings?`}
          open={true}
          onClose={() => {
            setIsDeleteModalOpen('');
          }}
          onConfirm={() => {
            dispatch(
              deleteCarrierSetting({
                csp: isDeleteModalOpen,
                accountNumber: userDataById?._accountId?.accountNumber || '',
              }),
            );
            setIsDeleteModalOpen('');
            window.location.reload();
          }}
        />
      )}
      <IndividualAccountHolderModal
        open={isInduvidualAccountModalOpen}
        onClose={() => setInduvidualAccountModalOpen(false)}
      />
    </>
  );
}

export default CreateAccount;
