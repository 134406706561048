import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useNavigate } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

import SbTextField, { TextFieldProps } from '../Textfield';
// import SbDropdown from '../Dropdown';
import SbButton from '../Button';
// import SbImage from '../Image';
import Footer from '../Footer';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import SearchBox from '@Components/SearchBox';
import { useNavigate } from 'react-router';

const BannerContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  background: "url('/images/login/Group.png')",
  backgroundPositionX: '547px',
  backgroundSize: 'cover',
  backgroundRepeatX: 'no-repeat',
  flex: 1,
  backgroundColor: 'white',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const WelcomeOrgh1 = styled('h1')(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.primary.main,
  fontWeight: '700',
  // fontSize: '36px',
  fontSize: '33px',
}));

interface bannerFields {
  trackingNo?: string;
}

const LoginSideBanner: React.FC = (): JSX.Element => {
  const [state, setState] = React.useState<bannerFields>({});
  const navigate = useNavigate();

  const organizationData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { name = '', logo = '/images/login/logo.png' } = organizationData || {};

  const handleLoginChange =
    (name: string): TextFieldProps['onChange'] =>
    event => {
      const { value } = event.target;

      setState({ [name]: value });
    };

  const handleGetRates = () => {
    navigate('/login/get-rates');
  };

  return (
    <BannerContainer item>
      <div
        style={{
          height: 'calc(100vh - 160px)',
        }}
      >
        <div
          className="header"
          style={{
            display: 'flex',
            marginTop: '61px',
            marginRight: '96px',
            marginLeft: '65px',
            alignItems: 'center',
          }}
        >
          {/* <SbImage src={logo} alt="logo" style={{ width: '208px', maxHeight: '121px', marginRight: '30px' }} /> */}
          <div
            style={{
              backgroundImage: `url(${logo.replace(/ /g, '%20')})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '210px',
              height: '140px',
              marginRight: '30px',
            }}
          ></div>
          <WelcomeOrgh1>Welcome to {name}</WelcomeOrgh1>
        </div>
        <div className="headerbody">
          <Grid container>
            <Grid item md={2}></Grid>
            <Grid item md={6}>
              {/* <img src="/images/login/logistics-2.gif" alt="logistics" style={{ height: '410px', width: '504px' }} /> */}
              <img src="/images/login/logistics-2.gif" alt="logistics" style={{ height: '360px', width: '474px' }} />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              background: '#FFFFFF',
              boxShadow: '0px 4px 43px rgba(206, 206, 206, 0.25)',
              borderRadius: '20px',
              padding: '1.5rem',
              width: 'fit-content',
            }}
            className="mx-auto my-2"
          >
            {/* <Grid item md={2}></Grid> */}
            {false && (
              <Grid item md={6}>
                <h1 style={{ fontWeight: '700', fontSize: '32px', color: '#2C2B6D' }}>Track your Shipment</h1>
                <SbTextField
                  placeholder="Enter your AWB"
                  label=""
                  style={{ width: '482px', height: '44px' }}
                  value={state.trackingNo || ''}
                  onChange={handleLoginChange('trackingNo')}
                  inputClassName="rounded-full"
                  startAdornment={<SearchIcon style={{ height: '44px', width: '30px', margin: '10px' }} />}
                  endAdornment={
                    <SbButton variant="contained" style={{ marginRight: '-13px', height: '44px', width: '114px' }}>
                      Track
                    </SbButton>
                  }
                />
              </Grid>
            )}
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gap: '20px' }}>
              <Box>
                <Typography variant="subtitle1" className=" l-text font-bold">
                  Tracking Status
                </Typography>
                <Box mt={'1rem'}>
                  <SearchBox
                    id="search_shipment"
                    label=""
                    onSearch={() => {
                      //
                    }}
                    onClear={() => {
                      //
                    }}
                    value={''}
                    placeholder="Enter you AWB"
                  />
                </Box>
              </Box>
              <Box sx={{ paddingLeft: '1rem', borderLeft: '2px dotted', borderColor: 'primary.main' }}>
                <Typography variant="subtitle1" className=" l-text font-bold">
                  Instant Quotes
                </Typography>
                <SbButton
                  variant="contained"
                  sx={{ backgroundColor: 'secondary.main', marginTop: '1rem' }}
                  onClick={handleGetRates}
                  fullWidth
                >
                  Get Rates
                </SbButton>
              </Box>
            </Box>
          </Grid>
        </div>
      </div>
      <Footer />
    </BannerContainer>
  );
};

export default LoginSideBanner;
