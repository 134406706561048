import { TabPanel } from '@mui/lab';
import { Checkbox, FormGroup, FormLabel, Grid, Typography, alpha, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SbTextField from '../../../../components/Textfield';
import SbTooltip from '../../../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import Divider from '../../../../components/Divider';
import { OrganizationSetupContextType, TermsAndConditionsType } from '../../../../types/organization';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';

const Container = styled('div')`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
`;

const SameAsBtn = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  width: 'fit-content',
  padding: '0px 0 0 12px',
  borderRadius: '4px',
  borderColor: alpha(theme.palette.border.light, 1),
  background: alpha(theme.palette.primary.light, 1),
}));

interface PropsType {
  data: OrganizationSetupContextType['termsAndConditions'];
  onChange: OrganizationSetupContextType['setTermsAndConditions'];
}

function TermsAndConditions(props: PropsType) {
  const { data: termsAndConditions, onChange: setTermsAndConditions } = props;
  const { proformaInvoice, shipmentInvoice, taxInvoice } = termsAndConditions;
  const [sameAsTaxName, setSameAsTaxName] = useState<boolean>(false);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isTCWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.t_and_c,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    const data = { ...termsAndConditions };
    if (sameAsTaxName) {
      data['proformaInvoice'] = taxInvoice;
    }
    setTermsAndConditions(data);
  }, [sameAsTaxName, taxInvoice]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    if (name === 'proformaInvoice') setSameAsTaxName(false);
    const data = { ...termsAndConditions };
    data[name as keyof TermsAndConditionsType] = value;
    setTermsAndConditions(data);
  }

  return (
    <TabPanel value="terms_and_conditions" className="p-0 w-full">
      <Container>
        <Grid container alignItems="top">
          <Grid container item xs={12} md={3} xl={2}>
            <FormLabel className="md-text font-medium sb-text-black mr-1" sx={{ mb: { xs: 1 } }}>
              Tax Invoice
            </FormLabel>
            <SbTooltip
              placement="right"
              title={`These will be  visible on the invoices you create on Speedbox to bill your customer.`}
            >
              <InfoRounded />
            </SbTooltip>
          </Grid>
          <Grid item xs={12} md={9} xl={10}>
            <SbTextField
              label=""
              name="taxInvoice"
              onChange={handleChange}
              value={taxInvoice}
              required
              multiline
              rows={5}
              disabled={!isTCWriteAllowed}
            />
          </Grid>
          <Divider sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 1, lg: 2 } }} />
        </Grid>
        <Grid container alignItems="top">
          <Grid container item xs={12} md={3} xl={2}>
            <FormLabel className="md-text font-medium sb-text-black mr-1" sx={{ mb: { xs: 1 } }}>
              Proforma Invoice
            </FormLabel>
            <SbTooltip
              placement="right"
              title={`These will be  visible on the Proforma invoice.Proforma  invoice is created and billed on the  name of your customer at the time on shipment booking`}
            >
              <InfoRounded />
            </SbTooltip>
          </Grid>
          <Grid item xs={12} md={9} xl={10}>
            <SbTextField
              label=""
              name="proformaInvoice"
              onChange={handleChange}
              value={proformaInvoice}
              required
              multiline
              rows={5}
              disabled={!isTCWriteAllowed}
            />
            <SameAsBtn className="mt-2">
              <Typography className="mr-2">Same as Tax Invoice</Typography>
              <FormGroup classes={{ root: 'flex' }}>
                <Checkbox
                  style={{ color: 'inherit' }}
                  checked={sameAsTaxName}
                  onChange={event => setSameAsTaxName(event.target.checked)}
                  disabled={!isTCWriteAllowed}
                />
              </FormGroup>
            </SameAsBtn>
          </Grid>
          <Divider sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 1, lg: 2 } }} />
        </Grid>
        <Grid container alignItems="top">
          <Grid container item xs={12} md={3} xl={2}>
            <FormLabel className="md-text font-medium sb-text-black mr-1" sx={{ mb: { xs: 1 } }}>
              Shipment Invoice
            </FormLabel>
            <SbTooltip
              placement="right"
              title={`These will be  visible on the shipment invoice auto generated by the  system according to the box details. Shipment invoice is the invoice containing details of commodities in the shipment along with their prices which the shipper is billing to the consignee.`}
            >
              <InfoRounded />
            </SbTooltip>
          </Grid>
          <Grid item xs={12} md={9} xl={10}>
            <SbTextField
              label=""
              name="shipmentInvoice"
              onChange={handleChange}
              value={shipmentInvoice}
              required
              multiline
              rows={5}
              disabled={!isTCWriteAllowed}
            />
          </Grid>
        </Grid>
      </Container>
    </TabPanel>
  );
}

export default TermsAndConditions;
