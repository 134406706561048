import { Grid, alpha, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SbButton from '../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './SheetMatchTable/Header';
import RowCell from './SheetMatchTable/RowCell';
import Row from './SheetMatchTable/Row';
import { COLUMNS_HEADER, ColumnsHeaderType } from './constant';
import { DropdownOptionType } from '../../components/Dropdown/Type';
import Loader from '../../components/Loader';
import { HeaderType } from './Type';
import { ProductType, BulkShipmentType } from '../../types/shipment';
import dayjs from 'dayjs';
import { BoxType } from '../../types/shipment';
import updateBoxId from '@Services/updateBoxId';

// const _TOTAL_COLUMNS_HEADER_LENGTH = COLUMNS_HEADER.length;
const _REQUIRED_HEADER_LISTS = COLUMNS_HEADER.filter(x => x.require);

const WrapperStyled = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(5)} ${theme.spacing(3.5)}`,
  background: alpha(theme.palette.primary.main, 0.03),
  borderRadius: theme.spacing(1.5),
  border: `1px solid #D1D1D1`,
  overflow: 'scroll',
  maxHeight: 550,
}));

function SheetUpload() {
  const location = useLocation();
  const navigate = useNavigate();

  const [headerList, setHeaderList] = useState<HeaderType[]>([]);
  const [requiredHeaderMatch, setRequiredHeaderMatch] = useState<boolean>(false);
  const [bodyList, setBodyList] = useState<[]>([]);
  const [dropDownOptions, setDropDownOptions] = useState<DropdownOptionType<ColumnsHeaderType>[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [packingInsurance, setPackingInsurance] = useState([
    {
      packaging: false,
      insurance: false,
    },
  ]);
  useEffect(() => {
    setLoader(true);
    const file = location.state;
    if (!file) return onBack();
    const [xlsHeaders, ...shipmentData] = file;
    const { header: matchHeader, dropDownOption } = checkHeaderIsMatch(xlsHeaders);
    setHeaderList(matchHeader);
    setDropDownOptions(dropDownOption);
    setBodyList(shipmentData);
    checkRequiredHeaderMatch(matchHeader);
    setTimeout(() => setLoader(false), 100);
  }, []);

  const onNext = () => {
    if (!requiredHeaderMatch) return;
    setLoader(true);
    const headerListCopy: { [key: string]: HeaderType } = {};

    headerList.forEach(currHeader => {
      if (currHeader.isMatchHeading) headerListCopy[currHeader.selectedValue] = currHeader;
    });

    const shipmentArrayObj: BulkShipmentType[] = [];
    let currentShiObj: BulkShipmentType | null = null;
    const shipperNameXlsIndex = headerListCopy.shipperName.xlsIndex;
    const boxWeightXlsIndex = headerListCopy.weight.xlsIndex;
    const productDesXlsIndex = headerListCopy.description.xlsIndex;
    bodyList.forEach((currRowArray: [], _rowIndex) => {
      if (currRowArray[shipperNameXlsIndex]) {
        if (currentShiObj) {
          shipmentArrayObj.push(currentShiObj);
        }
        currentShiObj = getShipmentObj(currRowArray, headerListCopy);
        setPackingInsurance([
          ...packingInsurance,
          { packaging: currentShiObj.package.packaging, insurance: currentShiObj.package.insurance },
        ]);
      } else if (currentShiObj && currRowArray[boxWeightXlsIndex]) {
        attachBoxObj(currentShiObj, currRowArray, headerListCopy);
      } else if (currentShiObj && currRowArray[productDesXlsIndex]) {
        const boxLen = currentShiObj.package.box.length;
        attachProductObj(currentShiObj.package.box[boxLen - 1], currRowArray, headerListCopy);
      }
      if (currentShiObj && bodyList.length === _rowIndex + 1) shipmentArrayObj.push(currentShiObj);
    });
    shipmentArrayObj.forEach((data, ind) => {
      const boxes = structuredClone(data.package.box);
      let updatedBoxes: BoxType[] = [];
      boxes.forEach(box => {
        if (box.boxCopyCount) {
          const currentBox = { ...box, id: updatedBoxes.length.toString() };
          updatedBoxes.push(currentBox);
          const boxToBeCopied = { ...currentBox, boxCopyCount: 0 };
          updatedBoxes = updateBoxId<BoxType>(updatedBoxes, boxToBeCopied, box.boxCopyCount);
        } else {
          updatedBoxes.push({ ...box, id: updatedBoxes.length.toString() });
        }
      });

      if (packingInsurance[ind + 1]) {
        data.package.packaging = packingInsurance[ind + 1].packaging;
        data.package.insurance = packingInsurance[ind + 1].insurance;
      }

      data.package.box = updatedBoxes;
      data.package.totalBoxWeight = data.package.box.reduce(
        (prev, curr) => prev + parseFloat(curr.weight.toString()),
        0,
      );
    });

    setTimeout(() => {
      setLoader(false);
      navigate('/shipment/create-bulk/summary', { state: shipmentArrayObj });
    }, 100);
  };

  const onBack = () => {
    navigate('/shipment/create-bulk/sheet');
  };

  const checkRequiredHeaderMatch = (headerList: HeaderType[]) => {
    // _REQUIRED_HEADER_LENGTH
    let matchedHeaderCount = 1;
    _REQUIRED_HEADER_LISTS.forEach(currReqCols => {
      if (headerList.findIndex(currHeader => currReqCols.key === currHeader.selectedValue)) matchedHeaderCount++;
    });

    setRequiredHeaderMatch(matchedHeaderCount === _REQUIRED_HEADER_LISTS.length);
  };

  const onResetHeader = () => {
    setHeaderList(new Array(headerList.length).fill(undefined).map(_ => ({ ...initHeaderData })));
    setDropDownOptions(
      COLUMNS_HEADER.map(currCol => ({
        display: `${currCol.label} ${(currCol.require && '*') || ''}`,
        value: currCol.key,
        disable: false,
        metadata: currCol,
      })),
    );
    setRequiredHeaderMatch(false);
  };

  const onChangeHeader = (index: number) => {
    return (_: string[], [selectedObj]: DropdownOptionType[]) => {
      const { position, key, require } = selectedObj.metadata as ColumnsHeaderType;
      const headerListCopy = [...headerList];
      const prevIndex = headerListCopy[index].selectedDropDownIndex;
      headerListCopy[index]['isMatchHeading'] = true;
      headerListCopy[index]['selectedValue'] = key;
      headerListCopy[index]['selectedDropDownIndex'] = position;
      headerListCopy[index]['require'] = require || false;
      headerListCopy[index]['xlsIndex'] = index;

      const dropDownOptionsCopy = [...dropDownOptions];
      if (prevIndex >= 0) dropDownOptionsCopy[prevIndex]['disable'] = false;
      dropDownOptionsCopy[position]['disable'] = true;
      let isEmpty = 0;
      headerListCopy.map(item => {
        if (item.selectedValue.trim() !== '') {
          isEmpty = 1;
        }
      });
      if (isEmpty === 1) {
        setRequiredHeaderMatch(false);
      } else {
        setRequiredHeaderMatch(true);
      }
      // const isAnySelectedValueEmpty = headerListCopy.some(item => item.selectedValue.trim() === '');
      // setRequiredHeaderMatch(!isAnySelectedValueEmpty);
      setHeaderList([...headerListCopy]);
      setDropDownOptions([...dropDownOptionsCopy]);
      checkRequiredHeaderMatch(headerListCopy);
    };
  };

  return (
    <Loader loading={loader} overly>
      <Grid container sx={{ background: '#fff' }}>
        <WrapperStyled container display="block">
          <Row>
            <>
              {headerList.map((_, i) => (
                <Header
                  {..._}
                  onChange={onChangeHeader(i)}
                  dropDownOptions={dropDownOptions}
                  id={'bulk_order_id' + i}
                  key={i}
                />
              ))}
            </>
          </Row>

          {bodyList.map((shipmentRow, index) => (
            <Row key={index + 'row'}>
              <>
                {headerList.map((_, i) => (
                  <RowCell label={shipmentRow[i]} key={i + index + 'col'} />
                ))}
              </>
            </Row>
          ))}
        </WrapperStyled>
        <Grid container item mt={2} alignItems="flex-start" spacing={2}>
          <Grid container item xs={12} sm={6}>
            <SbButton variant="text" sx={{ textDecoration: 'underline' }} onClick={onResetHeader}>
              Reset
            </SbButton>
          </Grid>
          <Grid container item xs={12} sm={6} spacing={4} justifyContent="end">
            <Grid item xs={12} sm={6} md={4}>
              <SbButton variant="outlined" fullWidth className="rounded-full" onClick={onBack}>
                Back
              </SbButton>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SbButton
                variant="contained"
                fullWidth
                className="rounded-full"
                onClick={onNext}
                disabled={!requiredHeaderMatch}
              >
                Next
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
}

const initHeaderData: HeaderType = {
  id: '',
  isMatchHeading: false,
  selectedValue: '',
  xlsIndex: -1,
  selectedDropDownIndex: -1,
  require: false,
};

function checkHeaderIsMatch(xlsFileHeaderList: string[] | { label: string }[]) {
  const _DefaultDropDownCopy: ColumnsHeaderType[] = [...COLUMNS_HEADER];
  const getHeader = xlsFileHeaderList.map((currHeader, xlsIndex) => {
    let _headerObj = { ...initHeaderData };
    const headerName = typeof currHeader === 'string' ? currHeader : currHeader.label;
    const _matchIndex = _DefaultDropDownCopy.findIndex(({ regEx }) => {
      return regEx.test(headerName);
    });

    if (_matchIndex >= 0 && _DefaultDropDownCopy[_matchIndex]['label']) {
      _DefaultDropDownCopy[_matchIndex]['isMatch'] = true;
      _headerObj = {
        ..._headerObj,
        isMatchHeading: true,
        selectedValue: _DefaultDropDownCopy[_matchIndex]['key'],
        require: _DefaultDropDownCopy[_matchIndex]['require'] || false,
        xlsIndex: xlsIndex,
        selectedDropDownIndex: _matchIndex,
      };
    }
    return _headerObj;
  });
  // if (_TOTAL_COLUMNS_HEADER_LENGTH && getHeader.length !== _TOTAL_COLUMNS_HEADER_LENGTH) {
  // const _fillRow = _TOTAL_COLUMNS_HEADER_LENGTH - getHeader.length;
  // getHeader = [getHeader, new Array(_fillRow).fill(undefined).map(() => ({ ...initHeaderData }))].flat();
  // }
  const DEFAULT_HEADER_OPTIONS = _DefaultDropDownCopy.map(currCol => ({
    display: `${currCol.label} ${(currCol.require && '*') || ''}`,
    value: currCol.key,
    disable: currCol.isMatch || false,
    metadata: currCol,
  }));

  return { header: getHeader, dropDownOption: DEFAULT_HEADER_OPTIONS };
}

export default SheetUpload;

function getShipmentObj(currRowArray: [], headerObj: { [key: string]: HeaderType }) {
  const shipmentObj: BulkShipmentType = {
    isSelected: true,
    carrierPreference: 'cheapest',
    shipper: {
      line1: currRowArray[headerObj.shipperAddressLine1.xlsIndex],
      line2: currRowArray[headerObj.shipperAddressLine2.xlsIndex],
      city: currRowArray[headerObj.shipperCity.xlsIndex],
      state: currRowArray[headerObj.shipperState.xlsIndex],
      country: currRowArray[headerObj.shipperCountry.xlsIndex],
      pincode: currRowArray[headerObj.shipperPincode.xlsIndex],
      companyName: currRowArray[headerObj.shipperCompany.xlsIndex],
      name: currRowArray[headerObj.shipperName.xlsIndex],
      phoneNumber: currRowArray[headerObj.shipperContactNumber.xlsIndex],
      telephoneCode: currRowArray[headerObj.shipperCountryCode.xlsIndex],
      email: '',
      // currRowArray[headerObj.shipper.xlsIndex]
    },
    consignee: {
      line1: currRowArray[headerObj.consigneeAddressLine1.xlsIndex],
      line2: currRowArray[headerObj.consigneeAddressLine2.xlsIndex],
      city: currRowArray[headerObj.consigneeCity.xlsIndex],
      state: currRowArray[headerObj.consigneeState.xlsIndex],
      country: currRowArray[headerObj.consigneeCountry.xlsIndex],
      pincode: currRowArray[headerObj.consigneePincode.xlsIndex],
      companyName: currRowArray[headerObj.consigneeCompany.xlsIndex],
      name: currRowArray[headerObj.consigneeName.xlsIndex],
      phoneNumber: currRowArray[headerObj.consigneeContactNumber.xlsIndex],
      telephoneCode: currRowArray[headerObj.consigneeCountryCode.xlsIndex],
      email: currRowArray[headerObj.consigneeEmail.xlsIndex],
    },
    package: {
      unitOfMeasurement: currRowArray[headerObj?.unitOfMeasurement?.xlsIndex],
      packaging: /^(y|yes)/i.test(currRowArray[headerObj?.packaging?.xlsIndex]),
      insurance: /^(y|yes)/i.test(currRowArray[headerObj?.insurance?.xlsIndex]),
      // isDocument: /^(doc|document|d)/i.test(currRowArray[headerObj?.BulkShipmentType?.xlsIndex]),
      isDocument: /^(doc|document|d)/i.test(currRowArray[headerObj?.shipmentType?.xlsIndex]),
      currency: currRowArray[headerObj?.currency?.xlsIndex],
      isCod: false,
      isDropOff: false,
      pickupDate: dayjs(),
      timeSlot: '',
      codAmount: 0,
      box: [],
      totalBoxWeight: 0,
      invoiceNumber: currRowArray[headerObj?.invoiceNumber?.xlsIndex] || '',
      userReferenceNumber: currRowArray[headerObj?.referenceNumber?.xlsIndex] || '',
    },
  };
  attachBoxObj(shipmentObj, currRowArray, headerObj);
  return shipmentObj;
}
function attachBoxObj(shipmentObj: BulkShipmentType, currRowArray: [], headerObj: { [key: string]: HeaderType }) {
  const boxObj: BoxType = {
    id: shipmentObj.package.box?.length.toString(),
    length: getNumber(currRowArray[headerObj.length.xlsIndex]),
    width: getNumber(currRowArray[headerObj.width.xlsIndex]),
    height: getNumber(currRowArray[headerObj.height.xlsIndex]),
    weight: getNumber(currRowArray[headerObj.weight.xlsIndex]),
    boxCopyCount: getNumber(currRowArray[headerObj.duplicateBoxes.xlsIndex]) | 0,
    products: [],
    selected: false,
  };
  attachProductObj(boxObj, currRowArray, headerObj);
  shipmentObj.package.box.push(boxObj);
}

function attachProductObj(boxObj: BoxType, currRowArray: [], headerObj: { [key: string]: HeaderType }) {
  const productObj: ProductType = {
    id: boxObj.products?.length.toString(),
    description: currRowArray[headerObj.description.xlsIndex],
    quantity: getNumber(currRowArray[headerObj.quantity.xlsIndex]),
    unitValue: getNumber(currRowArray[headerObj.value.xlsIndex]),
    HSNName: currRowArray?.[headerObj.HSNName?.xlsIndex] || '',
    HSNCode: currRowArray?.[headerObj.HSNName?.xlsIndex] || '',
    totalValue: 0,
    actions: null,
  };
  productObj.totalValue = productObj.quantity && productObj.unitValue ? productObj.quantity * productObj.unitValue : 0;
  boxObj.products = boxObj.products || [];
  boxObj.products.push(productObj);
  return {};
}

function getNumber(param1: string | number): number {
  if (!param1) return 0;
  return typeof param1 === 'string' ? Number(param1) : param1;
}
