import { AccountUnbilledListResponse, InvoiceListResponse, InvoiceListState } from './Type';

function getInvoiceListPending(state: InvoiceListState) {
  state.loading = true;
}

function getInvoiceListFulfilled(state: InvoiceListState, data: { payload: AccountUnbilledListResponse }) {
  const { invoiceResult, currentPage, perPage, totalCount } = data.payload.data;
  state.unBilledList.list = invoiceResult;
  state.unBilledList.totalCount = totalCount;
  state.unBilledList.currentPage = currentPage;
  state.unBilledList.perPage = perPage;
  state.loading = false;
}

function fetchInvoiceListPending(state: InvoiceListState) {
  state.loading = true;
}

function fetchInvoiceListFulfilled(state: InvoiceListState, data: { payload: InvoiceListResponse }) {
  const { invoiceResult, currentPage, perPage, totalCount } = data.payload.data;
  state.loading = false;
  state.data.list = invoiceResult;
  state.data.totalCount = totalCount;
  state.data.currentPage = currentPage;
  state.data.perPage = perPage;
}

function fetchNonAccountInvoiceListFulfilled(state: InvoiceListState, data: { payload: InvoiceListResponse }) {
  const { invoiceResult, currentPage, perPage, totalCount } = data.payload.data;
  state.loading = false;
  state.nonAccountData.list = invoiceResult;
  state.nonAccountData.totalCount = totalCount;
  state.nonAccountData.currentPage = currentPage;
  state.nonAccountData.perPage = perPage;
}

function fetchInvoiceListRejected(state: InvoiceListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// function getNonAccountUnbilledListFulfilled(state: InvoiceListState, data: { payload: InvoiceListResponse }) {
//   const { invoiceResult, currentPage, perPage, totalCount } = data.payload.data;
//   state.nonAccountData.list = invoiceResult;
//   state.nonAccountData.totalCount = totalCount;
//   state.nonAccountData.currentPage = currentPage;
//   state.nonAccountData.perPage = perPage;
// }

function getInvoiceListRejected(state: InvoiceListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function fulfilled(state: InvoiceListState) {
  state.loading = false;
}

export {
  getInvoiceListPending,
  getInvoiceListFulfilled,
  getInvoiceListRejected,
  // getNonAccountUnbilledListFulfilled,
  fetchInvoiceListPending,
  fetchInvoiceListFulfilled,
  fetchInvoiceListRejected,
  fetchNonAccountInvoiceListFulfilled,
  fulfilled,
};
