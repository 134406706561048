import React from 'react';
import Modal from '../../../../components/Modal/error';

interface PropsType {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function BoxDelete(props: PropsType) {
  const { onConfirm } = props;
  return (
    <Modal
      icon={'/images/Boxes/DeleteContrast.svg'}
      {...props}
      title={'Are you sure you want to delete this box?'}
      btnText="Yes, Delete!"
      onConfirm={onConfirm}
      closeIcon={true}
    />
  );
}
export default BoxDelete;
