import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Grid, StepConnector, alpha, stepConnectorClasses, styled } from '@mui/material';

interface PropsType {
  activeStep: number;
  steps: string[];
  getActiveStep?: (activeStep: number) => JSX.Element;
}

const StepperWrapper = styled(Grid)(({ theme }) => ({
  background: alpha(theme.palette.primary.light, 1),
  padding: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
  overflowX: 'auto',
  border: '1px solid',
  '.MuiStepLabel-iconContainer, .MuiStepLabel-iconContainer > svg': {
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  '.MuiStepLabel-label': {
    fontWeight: 500,
    fontSize: theme.spacing(1.5),
    minWidth: '150px',
  },
}));
const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    // top: theme.spacing(2.5),
    top: '30%',
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1,
    margin: 'auto',
    border: 0,
    backgroundColor: theme.palette.grey[400],
    borderRadius: 1,
    width: '80%',
    // [(theme.breakpoints.up('lg'), theme.breakpoints.up('md'))]: {
    //   width: theme.spacing(8),
    // },
    // [theme.breakpoints.down('md')]: {
    //   width: theme.spacing(4),
    // },
  },
}));

export default function SbStepper({ activeStep, steps, getActiveStep }: PropsType) {
  return (
    <Box sx={{ width: '100%' }}>
      <StepperWrapper className="border-grey rounded-md">
        <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
          {steps.map((label, ind) => (
            <Step key={ind.toString()}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </StepperWrapper>
      <>{getActiveStep ? getActiveStep(activeStep) : <></>}</>
    </Box>
  );
}
