import { Grid, Typography, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { GridWrapper, Heading, SubHeading } from '../ShipmentSummary/styles';
import Charges from '../ShipmentSummary/Charges';
import CreateShipmentContext from '../../../context/CreateShipment';
import { createShipmentContextType } from '../../../types/shipment';
import SbButton from '../../../components/Button';
import {
  defaultBoxState,
  defaultBoxesState,
  defaultConsignee,
  defaultPackageState,
  defaultPickupState,
  defaultProductState,
  defaultShipmentTypeState,
  defaultShipperState,
} from '../constants';
import { useTheme } from '@mui/system';
import CSPDetails from './CSPDetails';
import Divider from '../../../components/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadButton from '../../../components/Button/Download';
import Warning from '../../../components/Warning';
import { AppDispatch, RootStateType } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { ShipperConsigneeSummaryDetails } from '../../../components/Shipment/Summary/ShipperConsigneeSummaryDetails';
import { resetEditShipment } from '@Reducers/Shipment';
import useAccountLookup from '@Hook/useAccountLookup';
import { downloadProformaInvoice, downloadShippingLabel } from '@Reducers/ShipmentHistory/actions';
import ShipmentSummaryDetails from '@Components/Shipment/Summary/ShipmentSummaryDetails';

function ShipmentSuccess({ modalId }: { modalId: string }) {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    activeStep,
    setActiveStep,
    setNotes,
    setShipper,
    setConsignee,
    setPackageData,
    setPickupType,
    setShipmentType,
    setBoxes,
    setSelectedBox,
    setSelectedProduct,
    setChargeableWeight,
    setBoxValidation,
    setShowRates,
    setSelectedCSP,
  } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const shipmentSummary = useSelector((state: RootStateType) => state.shipment.shipmentSummary);
  const { warehouseListForShipmentOptions } = useSelector((state: RootStateType) => state.Warehouse);
  const { resetSelectedUser, onOpen } = useAccountLookup(modalId);

  const {
    _consigneeId,
    _shipperId,
    shipmentMode,
    _packageId,
    shipmentNumber,
    sellingPricing,
    _warehouseId,
    shipmentNotes,
    userReferenceNumber,
    extraDetails,
  } = shipmentSummary || {};

  const selectedWearhouse = useMemo(() => {
    return warehouseListForShipmentOptions.find(warehouse => warehouse.value === _warehouseId);
  }, [warehouseListForShipmentOptions, _warehouseId]);

  const { box, isDropOff, insurance, packaging, unitOfMeasurement, isDocument, pickupDate, timeSlot } =
    _packageId || {};

  function handlePlaceNewOrder() {
    setActiveStep(0);
    setShipper({ ...defaultShipperState });
    setConsignee({ ...defaultConsignee });
    setPackageData({ ...defaultPackageState });
    setPickupType({ ...defaultPickupState });
    setShipmentType({ ...defaultShipmentTypeState });
    setBoxes(structuredClone(defaultBoxesState));
    setSelectedBox({ ...defaultBoxState });
    setSelectedProduct({ ...defaultProductState });
    setChargeableWeight(0);
    setBoxValidation(false);
    setShowRates(false);
    setSelectedCSP(null);
    setNotes('');
    dispatch(resetEditShipment());
    resetSelectedUser();
    onOpen();
  }
  if (
    shipmentNumber === undefined ||
    box === undefined ||
    shipmentMode === undefined ||
    insurance === undefined ||
    packaging === undefined ||
    unitOfMeasurement === undefined
  )
    return <></>;

  return (
    <Grid>
      <Heading className="mb-3">Your shipment has been placed!</Heading>
      <Grid container sx={{ p: { xs: 0, lg: 3 } }} spacing={3}>
        <Grid container item mx={2} pb={2.5} borderBottom="1px solid #D0D0D0">
          <Grid container justifyContent={{ xs: 'center', sm: 'flex-start' }}>
            <CheckCircleIcon sx={{ height: 75, width: 75, color: '#32BEA6' }} />
            <Grid item ml={2.5}>
              <Typography className="l-text font-semibold">Shipment No.: {shipmentNumber}</Typography>
              <Typography className="md-text font-medium" color="#32BEA6">
                Thank you for shipping with us!
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt={4} display="grid" gridTemplateColumns={{ sm: '1fr 200px 200px' }} gap={2}>
            <Warning message="Please download the shipping label and stick it on the box" />
            <DownloadButton
              name={'Shipping Label'}
              onClick={() => dispatch(downloadShippingLabel({ shipmentNumber }))}
            />
            <DownloadButton
              name={'Proforma Invoice'}
              onClick={() => dispatch(downloadProformaInvoice({ shipmentNumber }))}
            />
          </Grid>
        </Grid>
        <Grid container item lg={8} xs={12} rowSpacing={{ lg: 3, xs: 3 }}>
          <Grid item lg={12} xs={12}>
            <GridWrapper container>
              <Grid container item lg={6} xs={12} className={isMobile ? '' : 'border-right pr-3'}>
                <ShipperConsigneeSummaryDetails
                  label="Shipper"
                  name={_shipperId?.name || ''}
                  line1={_shipperId?.line1 || ''}
                  line2={_shipperId?.line2 || ''}
                  city={_shipperId?.city || ''}
                  state={_shipperId?.state || ''}
                  country={_shipperId?.country || ''}
                  pincode={_shipperId?.pincode || ''}
                  email={_shipperId?.email || ''}
                  phoneNumber={_shipperId?.phone.number || ''}
                  telephoneCode={_shipperId?.phone.telephoneCode || ''}
                  companyName={_shipperId?.companyName || ''}
                  IdNumber={extraDetails?.shipper?.IdNumber}
                  IdType={extraDetails?.shipper?.IdType}
                  type={extraDetails?.shipper?.type}
                />
              </Grid>
              {isMobile && <Divider className="my-2" />}
              <Grid container item lg={6} xs={12} sx={{ pl: { lg: 3, xs: 0 } }}>
                <ShipperConsigneeSummaryDetails
                  label="Consignee"
                  name={_consigneeId?.name || ''}
                  line1={_consigneeId?.line1 || ''}
                  line2={_consigneeId?.line2 || ''}
                  city={_consigneeId?.city || ''}
                  state={_consigneeId?.state || ''}
                  country={_consigneeId?.country || ''}
                  pincode={_consigneeId?.pincode || ''}
                  email={_consigneeId?.email || ''}
                  phoneNumber={_consigneeId?.phone.number || ''}
                  telephoneCode={_consigneeId?.phone.telephoneCode || ''}
                  companyName={_consigneeId?.companyName || ''}
                  IdNumber={extraDetails?.consignee?.IdNumber}
                  IdType={extraDetails?.consignee?.IdType}
                  type={extraDetails?.consignee?.type}
                />
              </Grid>
            </GridWrapper>
          </Grid>
          <Grid item lg={12}>
            <GridWrapper container>
              <ShipmentSummaryDetails
                shipmentType={isDocument ? 'document' : 'parcel'}
                boxes={box?.length}
                chargeableWeight={sellingPricing?.displayChargeableWeight || 0}
                warehouse={selectedWearhouse?.display}
                pickupDate={pickupDate}
                timeSlot={timeSlot}
                type={isDropOff ? 'dropOff' : 'pickUp'}
                shipmentMode={shipmentMode}
                insurance={insurance}
                packaging={packaging}
                unitOfMeasurement={unitOfMeasurement}
                activeStep={activeStep}
                notes={shipmentNotes}
                referenceNumber={userReferenceNumber}
                purposeOfShipment={extraDetails?.other?.purposeOfShipment}
                ior={extraDetails?.other?.ior}
              />
              {isMobile && <Divider className="my-2" />}
              <Grid container item lg={6} sx={{ pl: { lg: 3, xs: 0 } }}>
                <Grid item lg={12}>
                  <SubHeading>Service</SubHeading>
                  <CSPDetails />
                </Grid>
              </Grid>
            </GridWrapper>
          </Grid>
        </Grid>
        <Grid container item lg={4} xs={12}>
          <Grid item lg={12}>
            <Charges />
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'}>
        <SbButton
          onClick={handlePlaceNewOrder}
          variant="contained"
          style={{ height: '44px' }}
          sx={{ my: { lg: 0, xs: 3 } }}
        >
          Place New order
        </SbButton>
      </Grid>
    </Grid>
  );
}

export default ShipmentSuccess;
