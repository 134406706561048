import React, { useEffect, useState } from 'react';
import Title from '../../components/Title';
import { BorderedWrapper } from './style';
import { Grid, Typography } from '@mui/material';
import Divider from '../../components/Divider';
import { AppDispatch, RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { PricingDataType } from '../../store/reducers/Shipment/Type';
import Image from '../../components/Image';
import { PayNow } from '@Components/PayNow';
import { useSnackbar } from 'notistack';
import { getShipmentBySN, getShipmentPaymentBySN } from '@Reducers/Shipment/actions';
import { getShipmentWebsocketStream } from '@Reducers/AdminShipment/actions';
import { formatNumber, useLocale } from '../../utils/formatNumber';

function ShipmentCharges() {
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const { shipmentById, shipmentPayment } = useSelector((state: RootStateType) => state.shipment);

  const { payableAmount = 0, totalPaidAmt, tdsAmount = 0, _transactionsId } = shipmentPayment || {};
  const successfulTransactions = _transactionsId?.filter(payment => payment.status === 'successful') || [];
  const totalAmount = successfulTransactions.reduce((sum, payment) => sum + payment.amount, 0);
  const totalTdsAmount = successfulTransactions.reduce((sum, payment) => sum + payment.tdsAmount, 0);

  const { sellingPricing } = shipmentById || {};
  const shipmentNumber = shipmentById?.shipmentNumber || '';
  const locale = useLocale();

  const pricing = sellingPricing?.pricing;
  const taxPricing = sellingPricing?.taxPricing;
  const totalPricing = sellingPricing?.totalPricing;

  const handlePaymentSuccess = () => {
    if (shipmentNumber) {
      dispatch(getShipmentPaymentBySN(shipmentNumber));
      dispatch(getShipmentBySN(shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + shipmentNumber]));
    }
    enqueueSnackbar('Payment done successfully!', { variant: 'success', key: new Date().getTime() });
  };

  const [isWyngsMy, setIsWyngsMy] = useState<boolean>(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    // setOrganisationNameRedirect(subdomain);
    // console.info('subdomain:', subdomain);
    if (subdomain === 'wyngs-my') {
      setIsWyngsMy(true);
    } else {
      setIsWyngsMy(false);
    }
  }, []);
  const afterTaxPay = payableAmount - tdsAmount;
  return (
    <>
      <Title title="Shipment Charges" removeBorder />
      <BorderedWrapper>
        <Typography variant="body1" className="md-text font-semibold">
          Charges
        </Typography>
        <Divider style={{ margin: '10px 0' }} />
        <Grid container rowSpacing={1}>
          <Grid item xs={12} rowSpacing={1}>
            {pricing?.length ? (
              pricing.map((price: PricingDataType, ind: number) => (
                <Grid key={`charges-breakup-${ind}`} container item xs={12} className="mb-1">
                  <Grid item xs={8}>
                    {price.displayKey}
                  </Grid>
                  <Grid container item xs={4} justifyContent={'end'}>
                    {formatNumber(price.value, locale)}
                  </Grid>
                </Grid>
              ))
            ) : (
              <></>
            )}
            {taxPricing?.length ? (
              taxPricing.map((price: PricingDataType, ind: number) => (
                <Grid key={`tax-charges-breakup-${ind}`} container item xs={12} className="mb-1">
                  <Grid item xs={8}>
                    {price.displayKey}
                  </Grid>
                  <Grid container item xs={4} justifyContent={'end'}>
                    {formatNumber(price.value, locale)}
                  </Grid>
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Grid>
          <Divider />
          <Grid container item className="my-1">
            <Grid item xs={8}>
              Total
            </Grid>
            <Grid container item xs={4} justifyContent={'end'} className="font-semibold">
              {isWyngsMy ? 'MYR' : 'INR'} {totalPricing && formatNumber(totalPricing, locale)}
            </Grid>
          </Grid>
          <Divider />
          <Typography variant="body1" className="md-text font-semibold mt-2">
            Payment
          </Typography>
          <Grid container item xs={12} className="mb-1">
            <Grid item xs={8}>
              Amount Paid
              <Image src="/images/paid.svg" alt="paid-icon" height={12} style={{ marginLeft: 5, marginTop: 2 }} />
            </Grid>
            <Grid container item xs={4} justifyContent={'end'} className="font-semibold">
              {isWyngsMy ? 'MYR' : 'INR'}{' '}
              {tdsAmount == 0 ? totalPaidAmt && formatNumber(totalPaidAmt, locale) : formatNumber(totalAmount, locale)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className="mb-1">
            <Grid item xs={8}>
              {payableAmount - tdsAmount < 0 ? 'Refund Amount' : 'Amount Due'}
            </Grid>
            <Grid container item xs={4} justifyContent={'end'} className="font-semibold">
              {isWyngsMy ? 'MYR' : 'INR'}{' '}
              {payableAmount == 0 ? payableAmount : formatNumber(Number(payableAmount - tdsAmount), locale)}
            </Grid>
          </Grid>
          <Grid container item xs={12} className="mb-1">
            <Grid item xs={8}>
              Tax
            </Grid>
            <Grid container item xs={4} justifyContent={'end'} className="font-semibold">
              {isWyngsMy ? 'MYR' : 'INR'}{' '}
              {tdsAmount != 0 ? formatNumber(tdsAmount, locale) : formatNumber(totalTdsAmount, locale)}
            </Grid>
          </Grid>
        </Grid>
        <PayNow paymentDetails={{ shipmentNumber, payableAmount: afterTaxPay, handlePaymentSuccess }} />
      </BorderedWrapper>
    </>
  );
}

export default ShipmentCharges;
