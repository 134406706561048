import React from 'react';
import Modal from '../../components/Modal';
import { UserObj } from '../../store/reducers/User/Type';
import { Grid, Typography, styled } from '@mui/material';
import Image from '../../components/Image';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '../../components/Divider';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';

interface PropsType {
  open: boolean;
  data?: UserObj;
  handleClose?: () => void;
}

const ModalDivider = styled(Divider)(() => ({
  width: '25%',
  margin: '10px 0',
  borderBottom: '1px solid #8A8F9D',
}));
const Header = styled('div')(({ theme }) => ({ padding: 20, backgroundColor: theme.palette.primary.main }));
const Main = styled('div')(() => ({ padding: 25 }));

export function CompanyDetailsModal({ open, handleClose, data }: PropsType) {
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const { email = '', _accountId, _systems } = data || {};
  const { accountNumber, _company } = _accountId || {};
  const defaultSystemId = loggedInUserSystemDropdownList.find(sys => sys.display === _systems?.[0])?.value;
  const defaultCompany = _company?.find(company => company._systemId === defaultSystemId);
  const { name, contact, address, documents = [] } = defaultCompany || {};
  const { line1 = '', line2 = '', city = '', state = '', country = '', pincode = '' } = address || {};
  const contactNumber = contact?.[0]?.number;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          width: '400px',
        },
        '.MuiGrid-root': {
          padding: 0,
        },
      }}
    >
      <Header>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Image src="/images/icons/user/Business_white.svg" alt="Business" />
              <div className="ml-1">
                <Typography className="sb-text-contrast font-bold md-text">{name}</Typography>
                <Typography className="sb-text-gray sm-text">{accountNumber}</Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item>
            <CloseIcon className="sb-text-gray cursor-pointer" onClick={handleClose} />
          </Grid>
        </Grid>
      </Header>
      <Main>
        <Typography className="sb-text-gray sm-text">{data?.name}</Typography>
        <Typography className="sb-text-gray sm-text">{accountNumber}</Typography>
        <Typography className="sb-text-gray sm-text">{email}</Typography>
        <Typography className="sb-text-gray sm-text">{contactNumber}</Typography>
        <ModalDivider />
        {documents.length ? (
          <>
            {documents?.map((tax, ind) => (
              <Typography key={`user-tax-${ind}`} className="sb-text-gray sm-text">
                {`${tax.name} : ${tax.value}`}
              </Typography>
            ))}
            <ModalDivider />
          </>
        ) : (
          <></>
        )}
        <Typography className="sb-text-gray sm-text">Address</Typography>
        <Typography className="sb-text-gray sm-text" sx={{ maxWidth: '70%' }}>
          {line1}, {line2 ? `${line2}, ` : ''}
        </Typography>
        <Typography className="sb-text-gray sm-text">
          {city}, {state ? `${state}, ` : ''}
        </Typography>
        <Typography className="sb-text-gray sm-text">
          {country}, {pincode}
        </Typography>
        <ModalDivider />
      </Main>
    </Modal>
  );
}
