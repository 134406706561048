import React, { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import {
  Button,
  ClickAwayListener,
  FormHelperText,
  Grid,
  Popper,
  Table,
  TableBody,
  TableHead,
  Typography,
} from '@mui/material';

import { TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { ChargesTableCell, ChargesTableHead, SBCalendarPicker } from './style';
import { CalendarPickerProps } from '@mui/x-date-pickers';
import { getGlobalChargeList } from '@Reducers/GlobalCharge';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalChargeResponseObject } from '@Reducers/GlobalCharge/Type';
import SbButton from '@Components/Button';
import { updateGlobalCharge } from '@Reducers/AdminShipment';

export interface DateWiseGlobalChargesProps {
  value?: any;
  handleChange: CalendarPickerProps<Dayjs>['onChange'];
  required?: boolean;
  minDate?: Dayjs;
  error?: string;
  disabled?: boolean;
}

function DateWiseGlobalCharges(props: DateWiseGlobalChargesProps): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const globalCharge = useSelector((state: RootStateType) => state.globalCharge.data);

  const { value, handleChange, minDate, error } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const shipmentNumber = shipmentById?.shipmentNumber;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (!anchorEl && shipmentById?.sellingPricing) {
      const { _courierId = '', _systemId = '' } = shipmentById?.sellingPricing || {};
      dispatch(getGlobalChargeList({ courierId: _courierId, systemId: _systemId }));
    }
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popper' : undefined;

  const selectedDateCharges = useMemo(() => {
    let charges: GlobalChargeResponseObject[] = [];
    if (globalCharge?.length) {
      charges = globalCharge.filter(({ fromDate, toDate }) => {
        const from = dayjs(fromDate).local().valueOf(),
          selected = dayjs(value).local().valueOf(),
          to = dayjs(toDate).local().valueOf();
        return from < selected && to > selected;
      });
    }
    if (charges.length) return charges[0];
    return;
  }, [globalCharge, value]);

  const handleApply = async () => {
    if (shipmentNumber && selectedDateCharges?._id) {
      await dispatch(updateGlobalCharge({ shipmentNumber, _selectedAdditionalChargeId: selectedDateCharges._id }));
      setAnchorEl(null);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <section>
        <Button
          aria-describedby={id}
          type="button"
          onClick={handleClick}
          variant="outlined"
          className="rounded-xs md-text font-medium capitalize"
          sx={{ height: 36 }}
          disabled={props.disabled}
        >
          Edit Global Charges
        </Button>
        <Popper
          id={id}
          anchorEl={anchorEl}
          open={open}
          sx={{ backgroundColor: 'white', borderRadius: '16px' }}
          className="shadow"
        >
          <Grid container mt={1} sx={{ maxWidth: '320px' }}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <SBCalendarPicker
                  date={value}
                  onChange={handleChange}
                  minDate={minDate}
                  sx={{ maxWidth: '320px', margin: 'auto' }}
                  disabled={props.disabled}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <GlobalChargesTable selectedCharge={selectedDateCharges} />
            </Grid>
            <Grid item xs={12} sx={{ px: 2, pb: 3 }}>
              <SbButton
                variant="contained"
                className="w-full"
                disabled={!selectedDateCharges?.charges?.length && !selectedDateCharges?.taxes?.length}
                onClick={handleApply}
              >
                Apply
              </SbButton>
            </Grid>
          </Grid>
        </Popper>

        {error && (
          <FormHelperText error className="m-0">
            {error}
          </FormHelperText>
        )}
      </section>
    </ClickAwayListener>
  );
}

function GlobalChargesTable({ selectedCharge }: { selectedCharge?: GlobalChargeResponseObject }) {
  return (
    <Table sx={{ width: '290px', marginInline: 'auto', marginBottom: '30px' }}>
      <TableHead>
        <SBTableRow>
          <ChargesTableHead sx={{ minWidth: '40%' }}>Charges</ChargesTableHead>
          <ChargesTableHead sx={{ minWidth: '20%' }} align="center">
            Fixed
          </ChargesTableHead>
          <ChargesTableHead sx={{ minWidth: '20%' }} align="center">
            %
          </ChargesTableHead>
          <ChargesTableHead sx={{ minWidth: '20%' }}>Apply on</ChargesTableHead>
        </SBTableRow>
      </TableHead>
      <TableBody>
        <></>
        {selectedCharge?.charges?.length || selectedCharge?.taxes?.length ? (
          <>
            {selectedCharge.charges.map(({ chargeName, fixed, percent, applyOn }, ind) => (
              <SBTableRow key={`datewise-global-charge-${ind}`} sx={{ borderBottom: 'none' }}>
                <ChargesTableCell className="xs-text">{chargeName}</ChargesTableCell>
                <ChargesTableCell className="xs-text" align="center">
                  {fixed || '-'}
                </ChargesTableCell>
                <ChargesTableCell className="xs-text" align="center">
                  {percent}
                </ChargesTableCell>
                <ChargesTableCell className="xs-text">{applyOn.join(', ')}</ChargesTableCell>
              </SBTableRow>
            ))}
            {selectedCharge?.taxes?.length &&
              selectedCharge?.taxes.map(({ displayKey, percentage }, ind) => (
                <SBTableRow key={`datewise-global-charge-${ind}`} sx={{ borderBottom: 'none' }}>
                  <ChargesTableCell className="xs-text">{displayKey}</ChargesTableCell>
                  <ChargesTableCell className="xs-text" align="center">
                    {'-'}
                  </ChargesTableCell>
                  <ChargesTableCell className="xs-text" align="center">
                    {percentage}
                  </ChargesTableCell>
                  <ChargesTableCell className="xs-text">-</ChargesTableCell>
                </SBTableRow>
              ))}
          </>
        ) : (
          <SBTableRow>
            <TableRowCell colSpan={6} sx={{ p: 0 }}>
              <Typography className="sb-text-gray font-medium text-center">No Data Found.</Typography>
            </TableRowCell>
          </SBTableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default DateWiseGlobalCharges;
