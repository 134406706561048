import SbButton from '@Components/Button';
import DatePicker from '@Components/DatePicker';
import Divider from '@Components/Divider';
import SBDropdown from '@Components/Dropdown';
import SBCountryDropdown from '@Components/Dropdown/Country';
import Image from '@Components/Image';
import RadioButton from '@Components/RadioButton';
import SbTextField from '@Components/Textfield';
import { setDocumentUploadModalOpen } from '@Reducers/AdminShipment';
import { FedXInformationType, vendorAccountType } from '@Reducers/AdminShipment/Type';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Grid, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BillDuties,
  Packaging_Type,
  Payor,
  PickupType,
  PoNo,
  ServiceType,
  ShipmentPurpose,
  ShipmentType,
  ShipmentTypeForShipperId,
  ShipperIdForExport,
  ShipperIdForPersonal,
  onForward,
} from './constants';
import { FedXInformationSchema } from './schema';
import { CSP_TYPES } from '@Constants/Shipment';
import { getCarrierSettings } from '@Reducers/User/actions';
import { downloadCarrierLabel } from '@Reducers/ShipmentHistory/actions';
import { userLogin } from '@Services/hasAdminAccess';
import UploadFedexDoc from '../../../ViewSingleShipmentAdmin/components/ShipmentProcessingV2/components/Fedex/UploadFedexDoc';
import { importShipments } from '../../../CreateShipment/constants';
import ShipmentTypeFields from '../../../ViewSingleShipmentAdmin/components/ShipmentProcessingV2/components/Fedex/ShipmentTypeFields';
import { OptionType } from '../../../CreateAccount/CarrierAccess/Type';

type Item = {
  display: string;
  value: string;
  _id: string;
};

type ItemWithoutId = Omit<Item, '_id'>;

interface propsTypes {
  onClickForward: () => void;
  onClose?: () => void;
  fedExDetails: FedXInformationType;
  isEditable?: boolean;
  showFedExDetails: boolean;
  btnText: string;
}
const UserFedXInformation = (props: propsTypes) => {
  const { onClickForward, onClose, fedExDetails, isEditable, showFedExDetails, btnText } = props;
  const dispatch = useDispatch<AppDispatch>();
  const isAdminLogin = !userLogin();
  const [isLoading, setIsLoading] = useState(false);

  // const fedexSettings = useSelector((state: RootStateType) => state.user.carrierSetting.fedex);
  const {
    shipmentById,
    vendersAccount,
    documentUploadModalOpen,
    insureShipment,
    fedExDetails: adminFedxDetails,
  } = useSelector((state: RootStateType) => state.adminShipment);
  const { shipmentById: userShipment, userFedXInformationDetails: fedexSettings } = useSelector(
    (state: RootStateType) => state.shipment,
  );

  const invoiceNo = useSelector((state: RootStateType) => state.shipment.shipmentById?._packageId.invoiceNumber);

  const _packageId = shipmentById?._packageId;
  const _shipperId = shipmentById?._shipperId;
  const productAmount = useMemo(() => {
    return Number(
      _packageId?.box
        ?.flatMap(item =>
          item?.products?.map(product =>
            product?.quantity != null && product?.unitValue != null ? product.quantity * product.unitValue : 0,
          ),
        )
        ?.reduce((acc, item) => acc + item, 0),
    );
  }, [_packageId?.box]);

  const { values, handleChange, setFieldValue, errors, handleSubmit } = useFormik<FedXInformationType>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ...fedExDetails,
      invoiceNo: shipmentById?._packageId.invoiceNumber || invoiceNo,
      customerReferenceNo: shipmentById?.shipmentNumber || '',
      isRequiredComplianceStatement:
        _shipperId?.country === 'United States Of America' && _packageId?.currency === 'USD' && productAmount > 2500,
      insureShipment,
    },
    validationSchema: FedXInformationSchema,
    onSubmit(values) {
      onForward({
        values,
        shipmentNumber: isAdminLogin ? shipmentById?.shipmentNumber || '' : userShipment?.shipmentNumber || '',
        dispatch,
        nextFunc: onClickForward,
        setIsLoading: setIsLoading,
      });
    },
  });

  useEffect(() => {
    if (shipmentById?._accountId.accountNumber) {
      dispatch(
        getCarrierSettings({
          csp: CSP_TYPES.fedex.value,
          accountNumber: shipmentById?._accountId.accountNumber,
        }),
      );
    }
  }, [shipmentById?._accountId.accountNumber]);

  const accountOptions = useMemo(
    () =>
      vendersAccount?.map((item: vendorAccountType) => ({
        value: `${item.accountNo}-${item._id}`,
        display: item.name,
      })),
    [vendersAccount],
  );

  function removeIdFromArray(arr: Item[]): ItemWithoutId[] {
    return arr.map(({ _id, ...rest }) => rest);
  }

  return (
    <>
      {documentUploadModalOpen && (
        <UploadFedexDoc
          shipmentNumber={shipmentById?.shipmentNumber || ''}
          csp={values?.csp}
          type={values?.type}
          open={documentUploadModalOpen}
          handleClose={() => {
            dispatch(setDocumentUploadModalOpen(false));
            // setFieldValue('attachedDocuments', []);
          }}
          fedExDetails={fedExDetails}
          // dragDropWrapperOnClickRegister
        />
      )}
      {showFedExDetails && (
        <Paper
          variant="outlined"
          sx={{
            my: '2rem',
            p: '2rem',
          }}
        >
          <Grid container item xs={12}>
            <Grid container item mb={3}>
              <Grid item xs={12} md={6}>
                <Typography className="font-medium l-text pb-3">Fedx Information</Typography>{' '}
              </Grid>
              {fedExDetails?.response && (
                <Grid item xs={12} md={6} container justifyContent={'flex-end'}>
                  <SbButton
                    variant="contained"
                    sx={{ borderRadius: `4px !important` }}
                    startIcon={<Image src={'/images/upload-cloud.svg'} alt={'documents'} width={26} />}
                    onClick={() => {
                      dispatch(setDocumentUploadModalOpen(true));
                    }}
                  >
                    Forward Shipment Documents
                  </SbButton>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="shipmentPurpose"
                    label={`Purpose of Shipment`}
                    required
                    placeholder="Select "
                    name="shipmentPurpose"
                    value={[values.shipmentPurpose]}
                    // options={fedexSettings?.shipmentPurpose?.length ? fedexSettings?.shipmentPurpose : ShipmentPurpose}
                    // options={
                    //   userFedXInformationDetails?.shipmentPurpose
                    //     ? removeIdFromArray(userFedXInformationDetails?.shipmentPurpose)
                    //     : ShipmentPurpose
                    // }
                    options={
                      fedexSettings?.shipmentPurpose
                        ? removeIdFromArray(fedexSettings?.shipmentPurpose)
                        : ShipmentPurpose
                    }
                    onChange={value => {
                      setFieldValue('shipmentPurpose', value[0]);
                    }}
                    error={errors.shipmentPurpose}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBCountryDropdown
                    disabled={!isEditable}
                    required
                    label="Country of Manufacture"
                    value={values?.countryOfManufacture}
                    // options={fedexSettings?.countryOfManufacture ? fedexSettings?.countryOfManufacture : undefined}
                    options={undefined}
                    onChange={value => {
                      setFieldValue('countryOfManufacture', value[0]);
                    }}
                    // setDefaultCountry={fedexSettings?.countryOfManufacture}
                    error={errors?.countryOfManufacture}
                  />
                </Grid>
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="account_no"
                    label={`Account No`}
                    required
                    placeholder="Select"
                    name="accountNo"
                    value={[
                      fedexSettings?.accountNo?.length
                        ? fedexSettings?.accountNo?.find(
                            (item: OptionType) => values?.accountNo && item?.value?.includes(values?.accountNo),
                          )?.value || ''
                        : accountOptions?.find(item => values?.accountNo && item.value?.includes(values?.accountNo))
                            ?.value || '',
                    ]}
                    // value={[
                    //   fedexSettings?.accountNo?.length
                    //     ? fedexSettings?.accountNo[0].value || ''
                    //     : // : accountOptions?.value || '',
                    //       '',
                    // ]}
                    // options={fedexSettings?.accountNo?.length ? fedexSettings?.accountNo : accountOptions}
                    // options={
                    //   userFedXInformationDetails?.accountNo?.length
                    //     ? removeIdFromArray(userFedXInformationDetails?.accountNo)
                    //     : accountOptions
                    // }
                    options={
                      fedexSettings?.accountNo?.length ? removeIdFromArray(fedexSettings?.accountNo) : accountOptions
                    }
                    onChange={value => setFieldValue('accountNo', value[0])}
                    error={errors.accountNo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SbTextField
                    disabled={!isEditable}
                    label={`Invoice No`}
                    required
                    value={values.invoiceNo || invoiceNo}
                    placeholder={`12345678`}
                    onChange={handleChange}
                    name="invoiceNo"
                    error={typeof errors.invoiceNo === 'string' ? errors.invoiceNo : undefined}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                item
                columnSpacing={10}
                rowSpacing={2}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="payor"
                    required
                    label={`Payor`}
                    placeholder="Select"
                    name="payor"
                    value={[values.payor]}
                    onChange={value => {
                      setFieldValue('payor', value[0]);
                      setFieldValue('thirdPartyPayor', '');
                    }}
                    error={errors.payor}
                    options={fedexSettings?.payor?.length ? fedexSettings?.payor : Payor}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {values?.payor === 'THIRD_PARTY' && (
                    <SBDropdown
                      disabled={!isEditable}
                      id="thirdPartyPayor"
                      label={`Third party Payor`}
                      required
                      placeholder="1254621561661"
                      name="thirdPartyPayor"
                      value={[
                        fedexSettings?.thirdPartyPayor?.length
                          ? fedexSettings?.thirdPartyPayor?.find(
                              (item: OptionType) =>
                                values?.thirdPartyPayor && item?.value?.includes(values?.thirdPartyPayor),
                            )?.value || ''
                          : accountOptions?.find(
                              (item: OptionType) =>
                                values?.thirdPartyPayor && item.value?.includes(values?.thirdPartyPayor),
                            )?.value || '',
                      ]}
                      onChange={value => setFieldValue('thirdPartyPayor', value[0])}
                      error={errors.thirdPartyPayor}
                      options={fedexSettings?.thirdPartyPayor?.length ? fedexSettings?.thirdPartyPayor : accountOptions}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid
                container
                item
                columnSpacing={10}
                rowSpacing={2}
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="bill"
                    required
                    label={`Bill Duties and Taxes to`}
                    placeholder="Select"
                    name="billDutiesAndTaxesTo"
                    value={[values.billDutiesAndTaxesTo]}
                    onChange={value => {
                      setFieldValue('billDutiesAndTaxesTo', value[0]);
                      setFieldValue('billDutiesAccountNo', '');
                    }}
                    options={
                      fedexSettings?.billDutiesAndTaxesTo?.length ? fedexSettings?.billDutiesAndTaxesTo : BillDuties
                    }
                    error={errors.billDutiesAndTaxesTo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {(values?.billDutiesAndTaxesTo === 'THIRD_PARTY' || values?.billDutiesAndTaxesTo === 'RECIPIENT') && (
                    <SbTextField
                      disabled={!isEditable}
                      // id="billDutiesAccountNo"
                      label={`Bill Duties Account No`}
                      required={values?.billDutiesAndTaxesTo === 'THIRD_PARTY'}
                      placeholder="1254621561661"
                      name="billDutiesAccountNo"
                      value={values.billDutiesAccountNo}
                      onChange={handleChange}
                      error={errors.billDutiesAccountNo}
                    />
                    // <SBDropdown
                    //   disabled={!isEditable}
                    //   id="billDutiesAccountNo"
                    //   label={`Bill Duties Account No`}
                    //   required
                    //   placeholder="1254621561661"
                    //   name="billDutiesAccountNo"
                    //   value={[
                    //     fedexSettings?.billDutiesAccountNo?.length
                    //       ? fedexSettings?.billDutiesAccountNo?.find(
                    //           (item: OptionType) =>
                    //             values?.billDutiesAccountNo && item?.value?.includes(values?.billDutiesAccountNo),
                    //         )?.value || ''
                    //       : accountOptions?.find(
                    //           (item: OptionType) =>
                    //             values?.billDutiesAccountNo && item.value?.includes(values?.billDutiesAccountNo),
                    //         )?.value || '',
                    //   ]}
                    //   onChange={value => setFieldValue('billDutiesAccountNo', value[0])}
                    //   error={errors.billDutiesAccountNo}
                    //   options={
                    //     fedexSettings?.billDutiesAccountNo?.length ? fedexSettings?.billDutiesAccountNo : accountOptions
                    //   }
                    // />
                  )}
                </Grid>
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="po_no"
                    label={`PO No`}
                    required
                    placeholder="Select"
                    name="poNo"
                    value={[values.poNo]}
                    onChange={value => setFieldValue('poNo', value[0])}
                    error={errors.poNo}
                    options={fedexSettings?.poNo?.length ? fedexSettings?.poNo : PoNo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SbTextField
                    disabled={true}
                    label={`Customer Reference  No`}
                    required
                    placeholder={`1254621561661`}
                    name="customerReferenceNo"
                    onChange={handleChange}
                    error={errors.customerReferenceNo}
                    value={values?.customerReferenceNo}
                  />
                </Grid>
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="packagingType"
                    label={`Packaging Type`}
                    placeholder="Your packaging "
                    required
                    name="packagingType"
                    options={fedexSettings?.packagingType?.length ? fedexSettings?.packagingType : Packaging_Type}
                    value={[values.packagingType]}
                    onChange={value => setFieldValue('packagingType', value[0])}
                    error={errors.packagingType}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="serviceType"
                    label={`Service Type`}
                    required
                    placeholder="Select"
                    name="serviceType"
                    options={fedexSettings?.serviceType?.length ? fedexSettings?.serviceType : ServiceType}
                    value={[values.serviceType]}
                    onChange={value => {
                      setFieldValue('serviceType', value[0]);
                      setFieldValue('bookingConfirmationNumber', '');
                    }}
                    error={errors.serviceType}
                  />
                </Grid>
                {(values?.serviceType === 'INTERNATIONAL_PRIORITY_FREIGHT' ||
                  values?.serviceType === 'INTERNATIONAL_ECONOMY_FREIGHT') && (
                  <Grid item xs={12} md={6}>
                    <SbTextField
                      disabled={!isEditable}
                      label={`Booking Number`}
                      required
                      placeholder="Enter booking number"
                      onChange={handleChange}
                      name="bookingConfirmationNumber"
                      value={values.bookingConfirmationNumber}
                      error={errors.bookingConfirmationNumber}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SbTextField
                    disabled={!isEditable}
                    label={`CS4/CS5  Details`}
                    required
                    placeholder="CS4/ASP/DDMMYYYY"
                    onChange={handleChange}
                    name="csDetails"
                    value={values.csDetails}
                    error={errors.csDetails}
                  />
                </Grid>
                {values?.isRequiredComplianceStatement && (
                  <Grid item xs={12} md={6}>
                    <SbTextField
                      disabled={!isEditable}
                      label={`AES/EEI NO`}
                      required
                      placeholder="12345678"
                      onChange={handleChange}
                      name="exportComplianceStatement"
                      value={values.exportComplianceStatement}
                      error={errors.exportComplianceStatement}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Divider className="mt-3 mb-3" />
            <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="shipperId"
                    label={`Shipment Type for Shipper Id`}
                    required
                    placeholder="Select"
                    name="shipperShipmentType"
                    options={
                      fedexSettings?.shipperShipmentType?.length
                        ? fedexSettings?.shipperShipmentType
                        : ShipmentTypeForShipperId
                    }
                    value={[values.shipperShipmentType]}
                    onChange={value => {
                      setFieldValue('shipperShipmentType', value[0]);
                      setFieldValue('shipmentIdType', '');
                      setFieldValue('shipmentId', '');
                    }}
                    error={errors.shipperShipmentType}
                  />
                </Grid>
                {values.shipperShipmentType && (
                  <Grid item xs={12} md={6}>
                    <SBDropdown
                      disabled={!isEditable}
                      id="shipperId"
                      label={`Shipper ID Type`}
                      required
                      placeholder="Select"
                      name="shipmentIdType"
                      options={
                        values.shipperShipmentType === 'PERSONAL_SHIPMENTS'
                          ? fedexSettings?.shipmentIdType?.length
                            ? fedexSettings?.shipmentIdType.filter((item: OptionType) =>
                                JSON.stringify(ShipperIdForPersonal)?.includes(item?.value),
                              )
                            : ShipperIdForPersonal
                          : fedexSettings?.shipmentIdType?.length
                          ? fedexSettings?.shipmentIdType.filter((item: OptionType) =>
                              JSON.stringify(ShipperIdForExport)?.includes(item?.value),
                            )
                          : ShipperIdForExport
                      }
                      value={[values.shipmentIdType]}
                      onChange={value => {
                        setFieldValue('shipmentIdType', value[0]);
                        setFieldValue('shipmentId', '');
                      }}
                      error={errors.shipmentIdType}
                    />
                  </Grid>
                )}
                {values.shipmentIdType && (
                  <Grid item xs={12} md={6}>
                    <SbTextField
                      disabled={!isEditable}
                      label={`Shipment ID`}
                      required
                      value={values.shipmentId}
                      placeholder="123456"
                      onChange={handleChange}
                      name="shipmentId"
                      error={errors.shipmentId}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="pickupType"
                    label={`Pickup Type`}
                    required
                    placeholder="Select "
                    name="pickupType"
                    value={[values.pickupType]}
                    options={fedexSettings?.pickupType?.length ? fedexSettings?.pickupType : PickupType}
                    onChange={value => {
                      setFieldValue('pickupType', value[0]);
                      setFieldValue('pickupDate', null);
                    }}
                    error={errors.pickupType}
                  />
                </Grid>
                {values.pickupType === 'USE_SCHEDULED_PICKUP' && (
                  <Grid item xs={12} md={6}>
                    <DatePicker
                      required
                      label="Pickup Date"
                      value={values?.pickupDate || null}
                      handleChange={value => {
                        setFieldValue('pickupDate', value);
                      }}
                      minDate={dayjs()}
                      disabled={!isEditable}
                      error={errors.pickupDate}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <RadioButton
                    label="Select laser for import shipments"
                    disabled={!isEditable}
                    containerClass="w-full"
                    name="printer"
                    required
                    value={values?.printer}
                    onChange={handleChange}
                    list={
                      fedexSettings?.printer?.length
                        ? fedexSettings?.printer.map(item => ({ value: item.value, label: item.display }))
                        : importShipments
                    }
                    sx={{ display: 'flex', columnGap: 10 }}
                    error={errors?.printer}
                  />
                </Grid>
              </Grid>
              {/* {_shipperId?.country === 'Canada' && (
                <Grid container item columnSpacing={10} rowSpacing={2}>
                  <Grid item xs={12} md={6}>
                    <SBDropdown
                      disabled={!isEditable}
                      id="b13AFilingOption"
                      label={`B13A Filing Option`}
                      placeholder="Select"
                      name="b13AFilingOption"
                      value={[
                        fedexSettings?.b13AFilingOption?.length
                          ? fedexSettings?.b13AFilingOption?.find(
                              (item: OptionType) =>
                                values?.b13AFilingOption && item?.value?.includes(values?.b13AFilingOption),
                            )?.value || ''
                          : b13AFilingOption?.find(
                              item => values?.b13AFilingOption && item.value === values?.b13AFilingOption,
                            )?.value || '',
                      ]}
                      options={
                        fedexSettings?.b13AFilingOption?.length ? fedexSettings?.b13AFilingOption : b13AFilingOption
                      }
                      onChange={value => setFieldValue('b13AFilingOption', value[0])}
                      error={errors.b13AFilingOption}
                    />
                  </Grid>
                  {(values?.b13AFilingOption === 'FILED_ELECTRONICALLY' ||
                    values?.b13AFilingOption === 'SUMMARY_REPORTING') && (
                    <Grid item xs={12} md={6}>
                      <SbTextField
                        disabled={!isEditable}
                        label={`POR No.`}
                        value={values.exportComplianceStatement}
                        placeholder={`POR Number`}
                        onChange={handleChange}
                        name="exportComplianceStatement"
                        error={errors.exportComplianceStatement}
                      />
                    </Grid>
                  )}
                  {values?.b13AFilingOption === 'NOT_REQUIRED' && (
                    <Grid item xs={12} md={6}>
                      <SBDropdown
                        id="Exemption"
                        disabled={!isEditable}
                        label={`Exemption No.`}
                        value={[values.exportComplianceStatement]}
                        placeholder={`Exemption Number`}
                        onChange={value => setFieldValue('exportComplianceStatement', value[0])}
                        name="exportComplianceStatement"
                        error={errors.exportComplianceStatement}
                        options={Array.from({ length: 16 }, (_, index) => ({
                          display: `${index + 1}`,
                          value: `${index + 1}`,
                        }))}
                      />
                    </Grid>
                  )}
                </Grid>
              )} */}
              <Grid container item columnSpacing={10}>
                {/* <Grid item xs={12} md={6}>
                  <SbTextField
                    disabled={!isEditable}
                    label={`Harmonized code`}
                    placeholder="12345678"
                    onChange={handleChange}
                    name="harmonizedCode"
                    value={values.harmonizedCode}
                    error={errors.harmonizedCode}
                  />
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="shipmentType"
                    multiple
                    label="Shipment Type"
                    placeholder="Shipment Type"
                    name="shipmentType"
                    options={fedexSettings?.shipmentType?.length ? fedexSettings?.shipmentType : ShipmentType}
                    value={values.shipmentType}
                    onChange={value => {
                      setFieldValue('shipmentType', value);
                    }}
                    error={Array.isArray(errors.shipmentType) ? errors.shipmentType[0] : errors.shipmentType}
                  />
                </Grid>
              </Grid>

              <ShipmentTypeFields
                {...{
                  handleChange,
                  setFieldValue,
                  values,
                  errors,
                  disabled: !isEditable || false,
                }}
              />

              <Grid container item sx={{ gap: '1rem' }}>
                <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                  <SbButton
                    disabled={!isEditable}
                    variant="contained"
                    sx={{ borderRadius: `4px !important` }}
                    className="w-full"
                    onClick={_event => {
                      handleSubmit();
                    }}
                    loading={isLoading}
                  >
                    {btnText}
                  </SbButton>
                </Grid>
                {onClose && (
                  <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                    <SbButton
                      sx={{ borderRadius: `4px !important` }}
                      className="w-full"
                      variant="outlined"
                      onClick={onClose}
                    >
                      Cancel
                    </SbButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
      {/* {fedExDetails?.response && ( */}
      {adminFedxDetails?.response && (
        <Grid container item>
          <Grid
            item
            xs={'auto'}
            border={'1px solid'}
            className={`border-grey font-medium md-text p-2`}
            borderRadius={1}
          >
            This shipment has been forwarded.
            <Box
              component={'span'}
              className="font-medium md-text ml-1"
              sx={{
                color: '#485fc7',
                textDecoration: 'underline',
              }}
              onClick={() => {
                // if (shipmentById?.shipmentNumber) {
                if (userShipment?.shipmentNumber) {
                  dispatch(downloadCarrierLabel(userShipment?.shipmentNumber));
                }
              }}
            >
              Download the carrier AWB
            </Box>
            {/* <Link
              className="font-medium md-text ml-1"
              to={`${CONFIG.API_URI}/shipment/label/getLabel?shipmentNumber=${shipmentById?.shipmentNumber}`}
              target="_blank"
              download={`${Date.now()}.pdf`}
            >
              Download the carrier AWB
            </Link> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserFedXInformation;
