import React, { useEffect, useState } from 'react';

import { Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { alpha } from '@mui/system';
import SbTextField from '../../../components/Textfield';
import SbButton from '../../../components/Button';
import SBDropdown from '../../../components/Dropdown';
import { useFormik } from 'formik';
import { CreateAdminSchema, EditAdminSchema } from './schema';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { getLoggedInUserSystemList } from '../../../store/reducers/Systems';
import { CreateAdminType } from '../../../store/reducers/Admin/Type';
import { createAdmin, editAdmin, getAdminListForTag } from '../../../store/reducers/Admin/actions';
import Info from '@mui/icons-material/Info';
import { grey } from '@mui/material/colors';
import { Autorenew } from '@mui/icons-material';
import { generatePassword } from '../../../services/generatePassword';
import SuccessModal from './SuccessModal';
import { resetAdminCreated, resetAdminUpdated, checkUsername, resetUsername } from '@Reducers/Admin';
import { getUserById } from '@Reducers/User/actions';
import Loader from '@Components/Loader';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import SbTooltip from '@Components/Tooltip';
import { USERNAME_REGEXP } from '@Constants/regEx';
import BackButton from '@Components/BackButton';
import { getAccessGroupList } from '@Reducers/AccessGroup/actions';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { getSubdomain, getUsernameFromEmail } from '../../../utils/getSubdomain';

interface CreateAdminFormProps {
  showBackgroundColor?: boolean;
  formType?: 'create' | 'edit';
  formState?: CreateAdminType;
}

const DefaultAdminDetails: CreateAdminType = {
  name: '',
  email: '',
  username: '',
  password: '',
  systems: [],
  belongToAdminId: '',
  accessGroup: '',
};

function CreateAdminForm(props: CreateAdminFormProps) {
  const { showBackgroundColor, formType = 'create' } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const subdomain = getSubdomain();

  const {
    adminListForTag: adminList,
    loading: adminLoading,
    adminCreated,
    adminUpdated,
  } = useSelector((state: RootStateType) => state.admin);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const { userDataById, loading: userDataByIdLoading } = useSelector((state: RootStateType) => state.user);
  const { isUsernameTaken } = useSelector((state: RootStateType) => state.admin);
  const { data } = useSelector((state: RootStateType) => state.accessGroup);
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isCreateTemplateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Access_Groups.Create_Template,
    PERFORM_ACTION.write,
  );

  const AccessGroupOptions = data?.list?.map(item => ({
    display: item.name,
    value: item.code,
  }));

  const { id: userId } = useParams();
  const isEditable = formType === 'edit';

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getLoggedInUserSystemList());
    dispatch(getAdminListForTag());
    return () => {
      dispatch(resetUsername());
    };
  }, []);

  useEffect(() => {
    if (userId) dispatch(getUserById(userId));
  }, [userId]);

  useEffect(() => {
    dispatch(getAccessGroupList({}));
  }, []);

  useEffect(() => {
    if (userDataById && userDataById._id === userId) {
      console.log({ userDataById });
      setValues({
        name: userDataById.name || '',
        username: userDataById.username || '',
        email: userDataById.email || '',
        password: '',
        systems: userDataById._systems,
        belongToAdminId: userDataById._belongToAdminId?._id || '',
        accessGroup: userDataById.accessGroup || '',
      });
    }
  }, [userDataById]);

  useEffect(() => {
    if (adminCreated) {
      dispatch(resetAdminCreated());
      setIsSuccessModalOpen(true);
    }
  }, [adminCreated]);

  useEffect(() => {
    if (adminUpdated) {
      dispatch(resetAdminUpdated());
      navigate('/admin');
      if (formkikValues.password) {
        setIsSuccessModalOpen(true);
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Admin details updated successfully.',
          }),
        );
      }
    }
  }, [adminUpdated]);

  const handleOnSubmit = (params: CreateAdminType) => {
    const { name, email, username, password, systems, belongToAdminId, accessGroup } = params;
    if (userId) {
      dispatch(
        editAdmin({
          _id: userId,
          name,
          email,
          password: password || null,
          systems: systems.filter(i => i !== ''),
          belongToAdminId,
          accessGroup,
        }),
      );
    } else {
      dispatch(
        createAdmin({
          name,
          email,
          username,
          password,
          systems: systems.filter(i => i !== ''),
          belongToAdminId,
          accessGroup,
        }),
      );
    }
  };

  const onAutoGeneratePassword = () => {
    setFieldValue('password', generatePassword(12));
  };

  const onCancel = () => {
    navigate('/admin');
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...DefaultAdminDetails },
    validationSchema: formType === 'create' ? CreateAdminSchema : EditAdminSchema,
    onSubmit(values) {
      if (values?.email) {
        values.username = getUsernameFromEmail(values.email);
      }
      handleOnSubmit(values);
    },
  });
  const { handleSubmit, handleChange, setFieldValue, values: formkikValues, errors, setValues, touched } = formik;

  const adminOptions =
    adminList?.filter(x => x._id !== userId).map(admin => ({ display: admin.name, value: admin._id })) || [];

  return (
    <Loader loading={userDataByIdLoading || adminLoading} overly>
      <>
        <Grid
          container
          direction="column"
          className="p-4"
          style={{
            borderRadius: '12px',
            backgroundColor: showBackgroundColor ? alpha(theme.palette.primary.light, 0.4) : '',
          }}
        >
          <Grid container spacing={1} item className="mb-3">
            <Grid item>
              <BackButton link="/admin" />
            </Grid>
            <Grid item xs={11}>
              <Typography className="org-text">{isEditable ? 'Edit' : 'Create'} admin</Typography>
              <Typography className="md-text flex items-center sb-text-gray">
                {isEditable ? 'Edit and Update Admin Account Details' : 'Create and give access to admin accounts'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container item columnSpacing={4} rowSpacing={2} className="mb-2">
            <Grid item xs={12} md={4}>
              <SbTextField
                required
                label="Name"
                name="name"
                placeholder="John Deo"
                value={formkikValues.name}
                onChange={handleChange}
                error={touched.name && errors.name ? errors.name : ''}
              />
            </Grid>
          </Grid>
          {!subdomain && (
            <Grid container item columnSpacing={4} rowSpacing={2} className="mb-2">
              <Grid item xs={12} md={4}>
                <SbTextField
                  required
                  label="Email"
                  name="email"
                  placeholder="Enter email"
                  value={formkikValues.email}
                  onChange={handleChange}
                  error={touched.email && errors.email ? errors.email : ''}
                />
              </Grid>
            </Grid>
          )}
          {subdomain && (
            <Grid container item columnSpacing={4} rowSpacing={2} className="mb-2">
              <Grid item xs={12} md={4}>
                <SbTextField
                  required={formType === 'create'}
                  label="Username"
                  name="username"
                  placeholder="johndeo02"
                  value={formkikValues.username}
                  onChange={handleChange}
                  error={
                    isUsernameTaken
                      ? 'That usernames is taken. Try another.'
                      : touched?.username && errors.username
                      ? errors.username
                      : ''
                  }
                  disabled={formType === 'edit'}
                  onBlur={() => {
                    if (formkikValues.username && USERNAME_REGEXP.test(formkikValues.username))
                      dispatch(checkUsername(formkikValues.username));
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid container item columnSpacing={4} rowSpacing={2} className="mb-2">
            <Grid item xs={12} md={4}>
              <SbTextField
                required={formType === 'create'}
                label="Password"
                name="password"
                placeholder="John@deo3"
                value={formkikValues.password}
                // disabled
                onChange={handleChange}
                error={touched?.password && errors.password ? errors.password : ''}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#667085',
                  },
                  '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--mui-palette-error-main) !important',
                  },
                }}
                endAdornment={
                  <SbTooltip title="Generate Password">
                    <Autorenew
                      htmlColor="#000000"
                      onClick={onAutoGeneratePassword}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </SbTooltip>
                }
              />
            </Grid>
          </Grid>
          <Grid container item columnSpacing={4} rowSpacing={2} className="mb-2">
            <Grid item xs={12} md={4}>
              <SBDropdown
                id="System"
                required
                label="System"
                placeholder="Mumbai"
                options={loggedInUserSystemDropdownList}
                onChange={value => {
                  setFieldValue('systems', value);
                  dispatch(getAdminListForTag({ systems: value }));
                }}
                name="systems"
                multiple
                value={formkikValues.systems || []}
                error={touched?.systems && errors.systems ? (errors.systems as string) : ''}
              />
            </Grid>
          </Grid>
          <Grid container item columnSpacing={4} rowSpacing={2} className="mb-3">
            <Grid item xs={12} md={4}>
              <SBDropdown
                id="ReportTo"
                label="Report to"
                placeholder="Select Admin"
                options={adminOptions}
                onChange={([value]) => setFieldValue('belongToAdminId', value)}
                name="belongToAdminId"
                value={formkikValues?.belongToAdminId ? [formkikValues.belongToAdminId] : []}
              />
            </Grid>
          </Grid>

          <Grid container item columnSpacing={4} rowSpacing={2} className="mb-3">
            <Grid item xs={12}>
              <Grid container item alignItems="center">
                <Typography className="org-text mr-1">Admin access</Typography>
                <Tooltip title="You can give access to pages and actions to admins.">
                  <Info htmlColor={grey[400]} fontSize="small" />
                </Tooltip>
              </Grid>
              <Typography className="md-text flex items-center sb-text-gray">
                Create and give access to admin accounts
              </Typography>
            </Grid>
          </Grid>

          <Grid container item columnSpacing={4} rowSpacing={2} className="mb-3">
            <Grid item xs={12} md={4}>
              <SBDropdown
                required
                id="AccessGroup"
                label="Access Template"
                placeholder="Select"
                options={AccessGroupOptions}
                onChange={([value]) => setFieldValue('accessGroup', value, false)}
                name="accessGroup"
                value={formkikValues.accessGroup ? [formkikValues.accessGroup] : []}
                error={touched?.accessGroup && errors.accessGroup ? errors.accessGroup : ''}
              />
            </Grid>
          </Grid>

          <Grid container item columnSpacing={4} rowSpacing={2} className="mb-3">
            <Grid item xs={12} md={4}>
              <SbButton
                variant="outlined"
                sx={{ borderRadius: '8px !important', padding: '8px 15px !important' }}
                disabled={!isCreateTemplateAllowed}
                onClick={() => {
                  navigate('/access-group/create');
                }}
              >
                Create Template
              </SbButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item mt={4} spacing={2} justifyContent="flex-end">
          <Grid item xs={6} sm={3} md={2} lg={1.5}>
            <SbButton fullWidth variant="outlined" sx={{ borderRadius: '8px !important' }} onClick={onCancel}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={1.5}>
            <SbButton
              fullWidth
              variant="contained"
              sx={{ borderRadius: '8px !important' }}
              onClick={() => handleSubmit()}
              disabled={!!isUsernameTaken}
            >
              {formType === 'create' ? 'Create' : 'Update'}
            </SbButton>
          </Grid>
        </Grid>

        <SuccessModal
          name={formkikValues?.name || ''}
          username={formkikValues?.username || ''}
          email={formkikValues?.email || ''}
          password={formkikValues?.password || ''}
          isOpen={isSuccessModalOpen}
          handleClose={() => {
            setIsSuccessModalOpen(false);
            if (formType === 'create') {
              navigate('/admin');
            }
            dispatch(resetAdminCreated());
            dispatch(resetAdminUpdated());
          }}
        />
      </>
    </Loader>
  );
}

export default CreateAdminForm;
