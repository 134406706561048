import { RazorPayOrderPayload } from './PaymentService';
import { rootStore } from '@Store';

function loadScript(src: string) {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export async function displayRazorpay(params: RazorPayOrderPayload, handlePaymentSuccess: () => void) {
  const profile = rootStore.getState().loginUser.profile;
  const email = profile?.email,
    name = profile?.name;

  const { keyId, amount, orderId, currency } = params;
  const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

  if (!res) {
    alert('Razorpay SDK failed to load. Are you online?');
    return;
  }

  const options = {
    // key: 'rzp_test_r6FiJfddJh76SI', // Enter the Key ID generated from the Dashboard
    // amount: amount.toString(),
    // currency: currency,
    // name: 'Soumya Corp.',
    // description: 'Test Transaction',
    // // image: { logo },
    // order_id: order_id,
    key: keyId,
    order_id: orderId,
    amount: amount,
    currency: currency,
    description: '',
    name: '',
    handler: function () {
      handlePaymentSuccess();
    },
    //  async function (response) {
    //   const data = {
    //     orderCreationId: orderId,
    //     razorpayPaymentId: response.razorpay_payment_id,
    //     razorpayOrderId: response.razorpay_order_id,
    //     razorpaySignature: response.razorpay_signature,
    //   };

    //   const result = await axios.post('http://localhost:5000/payment/success', data);

    //   alert(result.data.msg);
    // },
    prefill: {
      name,
      email,
      // contact: '9999999999',
    },
    theme: {
      color: '#0f0e47',
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
