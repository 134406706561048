import React, { useMemo, useState } from 'react';
import { BookBtn, CSPLogo, TableData, TableRow, SbChip } from '../styles';
import { Grid, Typography } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { CSPChargePropsType } from '../../../../store/reducers/GetRates/Type';
import ChargesBreakup from '../ChargesBreakup';
import Modal from '../../../../components/Modal';
import Image from '../../../../components/Image';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { createShipmentContextType } from '../../../../types/shipment';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import SbButton from '@Components/Button';
import { BackToLoginModal } from '../BackToLoginModal';

function SingleCSPMobile(props: CSPChargePropsType) {
  const { CSPCharge, handleBookCSP, isSelected } = props;
  const { transitTime, serviceName, serviceImage, totalPricing, fastest, cheapest, pricing, taxPricing } = CSPCharge;
  const [showBreakup, setShowBreakup] = useState<boolean>(false);
  const { token } = useSelector((state: RootStateType) => state.loginUser);
  const shipmentContext = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const orgCountryObj = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const [openBackToLoginModal, setBackToLoginModal] = useState(false);
  const currency = useMemo(
    () => shipmentContext?.packageData?.currency || orgCountryObj?.currency?.name || '',
    [shipmentContext, orgCountryObj],
  );

  function onClose() {
    setShowBreakup(false);
  }

  const bookCSP = (CSPCharge: CSPChargePropsType['CSPCharge'] | null) => {
    if (token?._id) {
      handleBookCSP(CSPCharge);
    } else {
      setBackToLoginModal(true);
    }
  };
  const handleCloseBackToLoginModal = () => {
    setBackToLoginModal(false);
  };

  return (
    <>
      <TableRow container item alignItems={'center'} xs={12}>
        <TableData container item justifyContent={'end'} xs={12}>
          {fastest && (
            <SbChip
              className="xs-text font-semibold"
              size="small"
              sx={{
                color: '#127E44',
                background: '#ADFFB5',
                '& .MuiChip-icon': {
                  color: '#127E44',
                },
              }}
              icon={<BoltIcon />}
              label="Fastest"
            />
          )}
          {cheapest && (
            <SbChip
              className="xs-text font-semibold"
              size="small"
              sx={{
                color: '#12647E',
                background: '#ADE6FF',
                '& .MuiChip-icon': {
                  color: '#12647E',
                },
              }}
              icon={<AccountBalanceWalletIcon />}
              label="Cheapest"
            />
          )}
        </TableData>
        <Grid container item xs={12} alignItems={'center'}>
          <TableData item xs={3}>
            <CSPLogo>
              <img src={serviceImage} height={'100%'} width={'100%'} style={{ borderRadius: '10px' }} />
            </CSPLogo>
          </TableData>
          <TableData item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className="md-text font-semibold">{transitTime} Days</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className="xs-text">{serviceName}</Typography>
              </Grid>
            </Grid>
          </TableData>
          <TableData item xs={3}>
            <Grid container alignItems={'center'}>
              <Typography className="md-text font-semibold">{`${currency} ${parseFloat(
                totalPricing?.toString(),
              )?.toFixed(2)}`}</Typography>
            </Grid>
          </TableData>
        </Grid>
        <TableData
          container
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ background: '#F3F3F3', padding: '13px' }}
        >
          <Grid
            container
            item
            xs={6}
            alignItems={'center'}
            textAlign={'center'}
            style={{ cursor: 'default' }}
            onClick={() => setShowBreakup(true)}
          >
            <Image src={'/images/Boxes/Help.svg'} alt="help" style={{ height: '14px', width: '14px' }} />
            <Typography style={{ textDecoration: 'underline' }} className="ml-1">
              View Breakup
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent={'end'}>
            {isSelected ? (
              <SbButton variant="contained" onClick={() => bookCSP(null)}>
                Selected
              </SbButton>
            ) : (
              <BookBtn variant="outlined" className="md-text font-semibold" onClick={() => bookCSP(CSPCharge)}>
                Book Now
              </BookBtn>
            )}
          </Grid>
        </TableData>
      </TableRow>
      <Modal open={showBreakup} onClose={onClose}>
        <ChargesBreakup pricing={pricing} totalPricing={totalPricing} taxPricing={taxPricing} currency={currency} />
      </Modal>
      <BackToLoginModal open={openBackToLoginModal} onClose={handleCloseBackToLoginModal} />
    </>
  );
}

export default SingleCSPMobile;
