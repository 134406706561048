import { Grid, Typography } from '@mui/material';
import React from 'react';
import { CSPLogo } from '../../../GetRatesV2/CSPList/styles';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { createShipmentContextType } from '../../../../types/shipment';

function CSPDetails() {
  const { selectedCSP } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const { serviceImage, transitTime, serviceName } = selectedCSP ?? {};
  return (
    <Grid container columnSpacing={2}>
      <Grid item>
        <CSPLogo>
          <img src={serviceImage} height={'100%'} width={'100%'} style={{ borderRadius: '10px' }} />
        </CSPLogo>
      </Grid>
      <Grid container item lg={10} xs={7} alignItems={'center'}>
        <Grid item xs={12}>
          <Typography className="md-text font-semibold">{transitTime} Days</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className="sm-text" color="#515151">
            {serviceName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CSPDetails;
