import { PincodeResponse, PincodeServiceabilityResponse, PincodeState } from './Type';

function getPincodeBySystemIdPending(state: PincodeState) {
  state.loading = true;
}

function getPincodeBySystemIdFulfilled(state: PincodeState, data: { payload: PincodeResponse }) {
  state.systemPincode = data.payload.data;
  state.totalCount = data.payload.data?.totalCount;
  state.currentPage = +data.payload.data?.currentPage;
  state.perPage = data.payload.data?.perPage;

  state.loading = false;
}

function getPincodeBySystemIdRejected(state: PincodeState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function updatePincodePending(state: PincodeState) {
  state.loading = true;
}

function updatePincodeFulfilled(state: PincodeState) {
  state.loading = false;
}

function updatePincodeRejected(state: PincodeState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function deletePincodePending(state: PincodeState) {
  state.loading = true;
}

function deletePincodeFulfilled(state: PincodeState) {
  state.loading = false;
}

function deletePincodeRejected(state: PincodeState) {
  state.loading = false;
  state.error = { msg: 'The desired system does not exist.', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function checkPincodeServiceabilityForShipmentPending(state: PincodeState) {
  state.loading = true;
}

function checkPincodeServiceabilityForShipmentFulfilled(
  state: PincodeState,
  action: { payload: PincodeServiceabilityResponse },
) {
  state.loading = false;
  const { payload } = action;
  state.pincodeServiceabilityForShipment = payload;
}

function checkPincodeServiceabilityForShipmentRejected(state: PincodeState) {
  state.loading = false;
}

export default {
  getPincodeBySystemIdPending,
  getPincodeBySystemIdFulfilled,
  getPincodeBySystemIdRejected,
  //
  updatePincodePending,
  updatePincodeFulfilled,
  updatePincodeRejected,
  //
  deletePincodePending,
  deletePincodeFulfilled,
  deletePincodeRejected,

  //
  checkPincodeServiceabilityForShipmentPending,
  checkPincodeServiceabilityForShipmentRejected,
  checkPincodeServiceabilityForShipmentFulfilled,
};
