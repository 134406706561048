import { FormHelperText, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';

import SbButton, { SbLoadingButton } from '@Components/Button';
import DragDrop from '@Components/File/DragDrop';
import Modal from '@Components/Modal';

import Image from '@Components/Image';
import { FileResultType } from '../../../Helper/file';
import { readXslAndGetBulkPaymentUploadObject } from './helper';
import { BulkPaymentType } from './types';
import { BulkPaymentUpdate } from '@Reducers/ShipmentHistory/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';

interface PropsType {
  open: boolean;
  handleClose: () => void;
}

interface FileErrorType {
  fileError: string;
}

function FileError({ fileError }: FileErrorType) {
  let showError = '';
  if (fileError) showError = fileError;

  if (!showError.trim()) return <></>;
  return (
    <FormHelperText error className="mt-1">
      {showError}
    </FormHelperText>
  );
}

function BulkPaymentUpload(props: PropsType) {
  const [xlsFileData, setXlsFileData] = useState<BulkPaymentType[] | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [fileError, setFileError] = useState<string>('');
  const { isBulkPaymentStatusUpdate } = useSelector((state: RootStateType) => state.shipmentHistory);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (isBulkPaymentStatusUpdate) {
      // onClose();
    }
  }, [isBulkPaymentStatusUpdate]);

  const onDone = () => {
    if (xlsFileData) {
      dispatch(BulkPaymentUpdate(xlsFileData));
      onClose();
    }
  };

  const onFileChange = (data: FileResultType[]) => {
    if (fileError) {
      setFileError('');
      setFileName('');
    }
    if (data?.[0]?.fileName) setFileName(data[0].fileName);
    try {
      if (!data.length) throw { msg: 'Please upload shipments with status to update.' };
      const bulkStatusData = readXslAndGetBulkPaymentUploadObject(data);
      if (bulkStatusData) setXlsFileData(bulkStatusData);
    } catch (error) {
      if (error?.msg) setFileError(error.msg);
    }
  };

  const onDownloadSampleSheet = () => {
    window.open('https://s3.ap-south-1.amazonaws.com/speedbox-static-file-2.0/Bulk+Payment+Sample+File.xlsx', '_blank');
  };

  const onClose = () => {
    props.handleClose();
    setFileError('');
    setFileName('');
  };

  return (
    <Modal open={props.open} sx={{ minHeight: '470px', bottom: 0 }} maxWidth="sm">
      <Grid container>
        <Grid container item mb={1}>
          <Typography fontWeight="bold" variant="h6">
            Upload
          </Typography>
        </Grid>
        <Grid container item>
          <DragDrop
            readType="xls"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            subText={fileName ? fileName : 'xlsx or xls file size no more than 5MB'}
            onChange={onFileChange}
          />
          <FileError fileError={fileError} />
        </Grid>
        <Grid container item mt={2} py={5} justifyContent="center">
          <Grid item xs={12} md={'auto'} alignItems="center">
            <DownloadSampleSheet
              fullWidth
              variant="outlined"
              onClick={onDownloadSampleSheet}
              startIcon={
                <Image
                  src={'/images/upload_document.svg'}
                  alt={'Speedbox'}
                  width={35}
                  height={35}
                  style={{ transform: 'rotate(180deg)' }}
                />
              }
            >
              Download sample file
            </DownloadSampleSheet>
            <Typography variant="subtitle2" className="sb-text-gray text-center">
              xlsx or xls file size no more than 5MB
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <SbButton variant="outlined" fullWidth onClick={onClose}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SbLoadingButton variant="contained" fullWidth disabled={!xlsFileData} onClick={onDone} loading={false}>
              Done
            </SbLoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default BulkPaymentUpload;

const DownloadSampleSheet = styled(SbButton)(({ theme }) => ({
  border: `1px solid #EAECF0`,
  borderRadius: '8px !important',
  cursor: 'pointer',
  backgroundColor: alpha(theme.palette.primary.main, 0.03),
  '&:hover': {
    border: `1px solid #EAECF0`,
  },
}));
