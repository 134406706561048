import { TransactionHistoryResponse, TransactionHistoryState } from './Type';

function pending(state: TransactionHistoryState) {
  state.loading = true;
}

function getTransactionListFulfilled(state: TransactionHistoryState, action: { payload: TransactionHistoryResponse }) {
  console.log('Transaction list data : ', action.payload.data);
  state.data = action.payload.data;
  state.loading = false;
}

function rejected(state: TransactionHistoryState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

export { pending, getTransactionListFulfilled, rejected };
