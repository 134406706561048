import React from 'react';
import { Grid } from '@mui/material';

import Divider from '@Components/Divider';
import { FormLabel, FormContent } from '@Components/Form';
import RadioButton from '@Components/RadioButton';

import { ApplyForRatesType } from '../type';
import { pageConfig } from '../constant';

function ApplyForRates(props: ApplyForRatesType<{ rateApplyFor: string }>) {
  // Filter out "custom-rate" if rateFor is "contract"
  const filteredApplyForRates =
    props.rateFor === 'contract'
      ? pageConfig[props.rateFor].applyForRates.filter(rate => rate.value !== 'custom-rate')
      : pageConfig[props.rateFor].applyForRates;

  return (
    <Grid item container xs={12} alignItems="center">
      <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
        <FormLabel>Apply For</FormLabel>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <FormContent>
          <RadioButton
            containerClass="w-full"
            name="type"
            defaultValue={pageConfig[props.rateFor].applyForRates[0].value}
            value={props.values.rateApplyFor}
            onChange={evt => {
              props.setFieldValue && props.setFieldValue('rateApplyFor', evt.target.value);
            }}
            list={filteredApplyForRates}
            sx={{ display: 'flex', columnGap: 10 }}
            disabled={props.disabled}
          />
        </FormContent>
      </Grid>
      <Divider className="my-2" />
    </Grid>
  );
}

export default ApplyForRates;
