/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import {
  BulkShipmentType,
  PlaceShipmentParamsType,
  PlaceShipmentSuccessResType,
  SavedShipmentList,
  ShipmentByIdType,
  ShipmentPaymentType,
  bulkShipmentSummary,
  CreateShipmentKycPostData,
  DeleteShipmentKycPathVariables,
  UserFedXInformationResponse,
  UsergetFedExDetailsType,
} from './Type';
import { userLogin } from '../../../services/hasAdminAccess';
import { AxiosError } from 'axios';
import { ErrorType, SuccessPayload } from '../../../types';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const placeShipment = createAsyncThunk<
  PlaceShipmentSuccessResType,
  PlaceShipmentParamsType,
  { rejectValue: ErrorType }
>('placeShipment', async (params, { rejectWithValue }) => {
  const { postObj, userId, showPaymentModal } = params;
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  try {
    const res = await API.post(`/shipment/place${isAdmin ? `/on-behalf/${userId}` : ''}`, postObj);
    return { shipmentData: res.data.data, showPaymentModal };
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const placeBulkShipment = createAsyncThunk<
  { data: bulkShipmentSummary },
  [BulkShipmentType[], string],
  { rejectValue: ErrorType }
>('placeBulkShipment', async ([postObj, userId], { rejectWithValue }) => {
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  try {
    const res = await API.post(`/shipment/place/bulk${isAdmin ? `/on-behalf/${userId}` : ''}`, postObj);
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const getShipmentById = createAsyncThunk<{ data: ShipmentByIdType }, string, { rejectValue: ErrorType }>(
  'getShipmentById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await API.get(`/shipment/${id}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);
const getShipmentBySN = createAsyncThunk<{ data: ShipmentByIdType }, string, { rejectValue: ErrorType }>(
  'getShipmentBySN',
  async (shipmentNumber, { dispatch }) => {
    try {
      const res = await API.get(`/shipment/${shipmentNumber}`);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw { msg: 'No such shipment.' };
    }
  },
);

const getSavedShipment = createAsyncThunk<{ data: SavedShipmentList[] }, undefined, { rejectValue: ErrorType }>(
  'getSavedShipment',
  async (_, { rejectWithValue }) => {
    try {
      const res = await API.get(`/shipment/draft`);
      // console.log({ res });
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const deleteSavedShipment = createAsyncThunk<{ data: SavedShipmentList[] }, string, { rejectValue: ErrorType }>(
  'deleteSavedShipment',
  async (id, { rejectWithValue }) => {
    try {
      const res = await API.delete(`/shipment/draft/${id}`);
      // console.log({ res });
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

const getShipmentPaymentBySN = createAsyncThunk<
  SuccessPayload<ShipmentPaymentType>,
  string,
  { rejectValue: ErrorType }
>('getShipmentPaymentBySN', async (id, { rejectWithValue }) => {
  try {
    const res = await API.get(`/shipment/transaction/${id}`);
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue(error.response.data);
  }
});

const getShipmentKyc = createAsyncThunk('shipment/kyc', async (shipmentNumber: string) => {
  const res = await API.get(`/shipment/kyc${`/${shipmentNumber}`}`);
  return res.data;
});

const addNewShipmentKyc = createAsyncThunk<SuccessPayload, [string, CreateShipmentKycPostData, string?]>(
  'createWarehouse',
  async (postData, { dispatch }) => {
    const [shipmentNumber, payload, docId] = postData;
    try {
      const res = await API.post(`/shipment/kyc/${shipmentNumber}`, payload);
      if (docId) {
        await API.delete(`/shipment/kyc/${shipmentNumber}/${docId}`);
      }
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: docId ? 'Document updated successfully.' : 'Document added successfully.',
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const deleteShipmentKyc = createAsyncThunk<SuccessPayload, DeleteShipmentKycPathVariables>(
  'updateWarehouseById',
  async ({ shipmentNumber, docId }: DeleteShipmentKycPathVariables, { dispatch }) => {
    try {
      const res = await API.delete(`/shipment/kyc/${shipmentNumber}/${docId}`);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: 'Document deleted successfully.',
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getVendorSettingList = createAsyncThunk<SuccessPayload<UserFedXInformationResponse>, UsergetFedExDetailsType>(
  'getVendorsList',
  async ({ csp, accountNumber }, { rejectWithValue }) => {
    try {
      const res = await API.get(`shipment/vendor/setting?csp=${csp}&accountNumber=${accountNumber}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  },
);

export {
  placeShipment,
  placeBulkShipment,
  getShipmentById,
  getShipmentBySN,
  getSavedShipment,
  deleteSavedShipment,
  getShipmentPaymentBySN,
  getShipmentKyc,
  addNewShipmentKyc,
  deleteShipmentKyc,
  getVendorSettingList,
};
