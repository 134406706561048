import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { styled } from '@mui/system';
import { Grid, TableBody, TableHead, TableRow, useTheme } from '@mui/material';

import { SBTable } from '../../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../../components/Table/TableCell';
import { SBTableRow } from '../../../components/Table/TableRow';
import Tooltip from '../../../components/Tooltip';

// ICONS

import { AppDispatch, RootStateType } from '../../../store';

import { getOdaList, deleteOdaById } from '../../../store/reducers/ODA';
import { Link, useLocation } from 'react-router-dom';
import { OdaListObj } from '../../../store/reducers/ODA/Type';
import Image from '../../../components/Image';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { downloadOdaCharges } from '../../../store/reducers/ODA/actions';
import DeleteModal from '../../../components/Modal/Delete';
import ServiceAndSystem from '@Components/ServiceAndSystem';
import dayjs from 'dayjs';
import SbButton from '@Components/Button';
import OdaDateRange from './OdaDateRange';
import OdaFilter from './OdaFilter';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const TableActionContainer = styled('div')(({ theme }) => ({
  // padding: '32px 16px',
  margin: '32px 0',
  display: 'grid',
  // gridTemplateColumns: '510px 1fr fit-content(140px) fit-content(140px) 140px',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: '15px',
  [theme.breakpoints.only('xs')]: {
    padding: '32px 0px',
    rowGap: '10px',
    // gridTemplateColumns: '1fr',
    justifyContent: 'center',
    '&>div': {
      // gridColumn: 'span 3',
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

function Actions(props: { data: OdaListObj; onDelete: (id: string, name: string) => void }) {
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();
  const isPastDate = dayjs().isAfter(props.data.endDate);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isODAChargesEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Oda_Charges.edit_ODA,
    PERFORM_ACTION.write,
  );

  return (
    <Grid
      container
      item
      justifyContent="center"
      gap={2}
      alignItems="center"
      // className="rounded"
      // py={1}
      // bgcolor={alpha(theme.palette.primary.main, 0.1)}
    >
      <Grid item textAlign="center">
        <Tooltip title="Edit ODA">
          <Link
            to={isODAChargesEditAllowed ? `/oda/edit/${props.data._id}` : ''}
            style={{ color: theme.palette.primary.main }}
          >
            {/* <ViewIcon style={actionIconStyle} /> */}
            {isODAChargesEditAllowed && <Image src="/images/icons/EditAction.svg" alt="Edit" />}
          </Link>
        </Tooltip>
      </Grid>
      <Grid item textAlign="center">
        <Tooltip title="View Charges">
          <Link to={`/oda/charges/${props.data._id}`} style={{ color: theme.palette.primary.main }}>
            <RemoveRedEyeIcon style={{ color: theme.palette.background.black }} />
          </Link>
        </Tooltip>
      </Grid>
      <Grid
        item
        textAlign="center"
        className="cursor-pointer"
        onClick={() => {
          if (isODAChargesEditAllowed) {
            dispatch(downloadOdaCharges(props.data._id));
          }
        }}
      >
        {isODAChargesEditAllowed && (
          <Tooltip title="Download ODA Charges">
            <DownloadIcon />
          </Tooltip>
        )}
      </Grid>
      <Grid
        item
        textAlign="center"
        className={`${isPastDate ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        onClick={() => {
          if (isODAChargesEditAllowed) {
            !isPastDate && props.onDelete(props.data._id, props.data._serviceProviderId.name);
          }
        }}
      >
        <Tooltip title={isPastDate ? 'ODA charges from the past cannot be deleted.' : 'Delete'}>
          <div>{isODAChargesEditAllowed && <Image src="/images/icons/DeleteAction.svg" alt="Edit" />}</div>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

function OdaTable() {
  const {
    data: { filteredList: list },
  } = useSelector((state: RootStateType) => state.oda);
  const [deleteId, setDeleteId] = useState<{ id: string; name: string } | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const onDelete = (id: string, name: string) => {
    setDeleteId({ id, name });
  };

  const onDeleteModalClose = () => {
    setDeleteId(null);
  };

  const onConfirmDelete = () => {
    if (deleteId?.id) {
      dispatch(deleteOdaById([deleteId?.id, { currentPage: 1, perPage: 10 }])).then(() => onDeleteModalClose());
    }
  };

  return (
    <>
      <DeleteModal
        icon="/images/icons/DeleteAction.svg"
        open={!!deleteId}
        title="Are You Sure?"
        subTitle={`Do you want to delete ODA charges for this service?`}
        onClose={onDeleteModalClose}
        onConfirm={onConfirmDelete}
      />
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell sx={{ maxWidth: '60%' }}>Service </TableHeadCell>
            <TableHeadCell sx={{ maxWidth: '30%' }}>Validity</TableHeadCell>
            <TableHeadCell sx={{ maxWidth: '30%' }}>Actions</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map(data => (
            <SBTableRow key={data._id}>
              <TableRowCell align="left" className="md-text">
                <ServiceAndSystem
                  serviceName={data._serviceProviderId.name}
                  serviceLogo={data._serviceProviderId.logo}
                />
              </TableRowCell>
              <TableRowCell align="center" className="md-text">
                {moment(data.endDate).format('DD/MM/YYYY')}
              </TableRowCell>

              <TableRowCell>
                <Actions data={data} onDelete={onDelete} />
              </TableRowCell>
            </SBTableRow>
          ))}
        </TableBody>
      </SBTable>
    </>
  );
}

function OdaList() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getOdaList({ currentPage: 1, perPage: 10 }));
  }, []);

  return (
    <>
      <TableActionContainer>
        <OdaDateRange />
        <CreateButton />
      </TableActionContainer>
      <OdaFilter />
      <OdaTable />
    </>
  );
}

function CreateButton() {
  const location = useLocation();

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Oda_Charges.edit_ODA,
    PERFORM_ACTION.write,
  );

  const showCreateBtn = location.pathname === '/oda';
  if (!showCreateBtn) return <></>;
  return (
    <Grid item xs={6} textAlign="end">
      <Link to="/oda/create">
        <SbButton
          variant="contained"
          className="ml-auto"
          sx={{ borderRadius: '8px !important' }}
          disabled={!isEditAllowed}
        >
          Create new
        </SbButton>
      </Link>
    </Grid>
  );
}

export default OdaList;
