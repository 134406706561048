import * as Yup from 'yup';
const ServiceSchema = {
  _serviceProviderId: Yup.string().trim().required('Service name is required'),
  startDate: Yup.string().required('Start Date is required'),
  endDate: Yup.string().required('End Date is required'),
};
export const CreateServiceSchema = Yup.object({
  ...ServiceSchema,
  charges: Yup.array(
    Yup.object({
      pincode: Yup.string().trim().required(),
      charge: Yup.number().required(),
      globalCharges: Yup.array(Yup.string()),
      canDeliver: Yup.boolean().default(false),
    }),
  ).min(1, 'ODA Charges is required'),
});

export const UpdateServiceSchema = Yup.object().shape({
  ...ServiceSchema,
  charges: Yup.array(
    Yup.object({
      pincode: Yup.string().trim().required(),
      charge: Yup.number().required(),
      globalCharges: Yup.array(Yup.string()),
      canDeliver: Yup.boolean().default(false),
    }),
  ),
});
