import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import VisibilityIcon from '@mui/icons-material/Visibility';

import SbTextField, { TextFieldProps } from '@Components/Textfield';
import SbButton from '@Components/Button';
import Loader from '@Components/Loader';
import SuccessError from '@Components/Modal/SuccessError';

import { AppDispatch, RootStateType } from '@Store';
import { confirmForgotPassword } from '@Reducers/User';

const ButtonContainer = styled(SbButton)({
  margin: '6px 0px',
  height: '48px',
  fontSize: '16px',
  letterSpacing: '0.46px',
  textTransform: 'capitalize',
});

interface resetPasswordProps {
  username: string;
}

interface ResetPasswordStateFields {
  username: string;
  otp?: string;
  password?: string;
  repeatPassword?: string;
}

const ResetPassword = (props: resetPasswordProps) => {
  const { username } = props;

  const dispatch = useDispatch<AppDispatch>();
  const userState = useSelector((state: RootStateType) => state?.user);

  const [state, setState] = useState<ResetPasswordStateFields>(() => {
    return { username: username || '' };
  });
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const { loading } = userState;
  const { otp, password, repeatPassword } = state;

  const togglePasswordView = () => {
    setViewPassword(!viewPassword);
  };

  const confirmPassword = () => {
    const { username } = props;
    const { otp, password } = state;

    dispatch(confirmForgotPassword({ username, otp, password }));
  };

  const handleInputChange =
    (name: keyof ResetPasswordStateFields): TextFieldProps['onChange'] =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newState = { ...state };
      newState[name] = value;

      setState({ ...newState });
    };

  const btnDisable = !username || !otp || !password || !repeatPassword || password !== repeatPassword || false;

  return (
    <>
      <SuccessError />
      <Loader loading={loading}></Loader>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={12}>
          <form action="">
            <SbTextField
              placeholder="Email ID/Phone no"
              label="Email ID/Phone no"
              name="username"
              value={username || ''}
              style={{ marginBottom: '10px' }}
              disabled
            />
            <SbTextField
              placeholder="Enter OTP"
              label="OTP"
              name="otp"
              value={otp || ''}
              onChange={handleInputChange('otp')}
              style={{ marginBottom: '10px' }}
            />
            <SbTextField
              type={viewPassword ? 'text' : 'password'}
              placeholder="Enter new password"
              label="Enter new password"
              name="password"
              value={password || ''}
              onChange={handleInputChange('password')}
              style={{ marginBottom: '10px' }}
              endAdornment={
                <VisibilityIcon
                  onClick={togglePasswordView}
                  style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
                />
              }
            />
            <SbTextField
              type="password"
              placeholder="Re-enter new password"
              label="Re-enter password"
              name="repeatPassword"
              value={repeatPassword || ''}
              onChange={handleInputChange('repeatPassword')}
              style={{ marginBottom: '10px' }}
            />
            {password !== repeatPassword ? <p>Repeat password does not match.</p> : ''}
            <ButtonContainer fullWidth disabled={btnDisable} variant="contained" onClick={confirmPassword}>
              Reset password
            </ButtonContainer>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
