import React, { FC } from 'react';
import { NestedPath } from './Type';
import { forwardRef } from 'react';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Image from '../Image';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface MenuRoutesType {
  label: string;
  Icon: FC | (OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & { muiName: string });
  nestedPaths: NestedPath[];
}

interface ImageIconProps {
  style: React.CSSProperties;
}
const UserMenuRoutes: MenuRoutesType[] = [
  {
    label: 'Home',
    Icon: HomeOutlinedIcon,
    nestedPaths: [
      {
        pathURL: '/profile',
        label: 'Profile',
        matchActionName: 'Profile',
        matchPaths: ['/profile'],
      },
      {
        pathURL: '/get-rates',
        label: 'Get Rates',
        matchActionName: 'Get Rates',
        matchPaths: ['get-rates'],
      },
      {
        pathURL: '/my-rates',
        label: 'My Rates',
        matchActionName: 'My Rates',
        matchPaths: ['my-rates'],
      },
    ],
  },
  {
    label: 'Shipments',
    Icon: forwardRef(({ style, ...props }: ImageIconProps, ref: React.ForwardedRef<HTMLDivElement>) => (
      <div ref={ref} style={style} {...props}>
        <Image src="/images/icons/menu/shipment.svg" alt="Shipments" style={{ width: '100%', height: '100%' }} />
      </div>
    )),
    nestedPaths: [
      {
        pathURL: '/shipment/create-single',
        label: 'Create Single',
        matchActionName: 'Create Single',
        matchPaths: ['/shipment/create-single'],
      },
      {
        pathURL: '/shipment/create-bulk/sheet',
        label: 'Create Bulk',
        matchActionName: 'Create Bulk',
        matchPaths: [
          '/shipment/create-bulk/sheet',
          '/shipment/create-bulk/sheet-match-header',
          '/shipment/create-bulk/summary',
        ],
      },
      {
        pathURL: '/shipment/history',
        label: 'Shipment History',
        matchActionName: 'Shipment History',
        matchPaths: ['/shipment/history'],
      },
      {
        pathURL: '/shipment/draft',
        label: 'Draft Shipments',
        matchActionName: 'Draft Shipments',
        matchPaths: ['/shipment/draft'],
      },
      {
        pathURL: '/shipment/bulk-summary',
        label: 'Bulk Summary',
        matchActionName: 'Bulk Summary',
        matchPaths: ['/shipment/bulk-summary'],
      },
      {
        pathURL: '/shipment/addresses',
        label: 'Addresses',
        matchActionName: 'Addresses',
        matchPaths: ['/shipment/addresses'],
      },
    ],
  },
  {
    label: 'Invoice',
    Icon: forwardRef(({ style, ...props }: ImageIconProps, ref: React.ForwardedRef<HTMLDivElement>) => (
      <div ref={ref} style={{ ...style, height: '20px', width: '20px' }} {...props}>
        <Image src="/images/icons/menu/invoice.svg" alt="Shipments" style={{ width: '100%', height: '100%' }} />
      </div>
    )),
    nestedPaths: [
      {
        pathURL: '/invoice/list',
        label: 'View Invoices',
        matchActionName: 'View Invoices',
        matchPaths: ['/invoice/list'],
      },
      {
        pathURL: '/transactions/list',
        label: 'Transactions',
        matchActionName: 'Transactions',
        matchPaths: ['/transactions/list'],
      },
      {
        pathURL: '/transactions/wallet',
        label: 'Wallet & Transactions',
        matchActionName: 'Wallet & Transactions',
        matchPaths: ['/transactions/wallet'],
      },
      {
        pathURL: '/transactions/credit',
        label: 'Credits & Transactions',
        matchActionName: 'Credits & Transactions',
        matchPaths: ['/transactions/credit'],
      },
      {
        pathURL: '/transactions/credits-and-wallets',
        label: 'Credits & Wallet',
        matchActionName: 'Credits & Wallet',
        matchPaths: ['/transactions/credits-and-wallets'],
      },
    ],
  },
];

export default UserMenuRoutes;
