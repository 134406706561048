import React from 'react';
import ErrorModal from '@Components/Modal/error';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { resetShipmentError as resetError, resetShipmentUploadState } from '@Reducers/AdminShipment';
import { useNavigate } from 'react-router';
function SuccessError() {
  const dispatch = useDispatch<AppDispatch>();

  const ShipmentUpdate = useSelector((state: RootStateType) => state.adminShipment);

  const shipmentUpdateError = !!ShipmentUpdate.shipmentUpdateError;
  const shipmentUpdateSuccessFully = !!ShipmentUpdate.shipmentUpdated;
  const modalMsg =
    (shipmentUpdateError && 'Oops!') ||
    (shipmentUpdateSuccessFully && 'Shipment successfully updated and placed') ||
    '';
  const modalSubMsg = (shipmentUpdateError && ShipmentUpdate.error?.msg) || (shipmentUpdateSuccessFully && '');

  const onClose = () => {
    if (shipmentUpdateSuccessFully) {
      dispatch(resetShipmentUploadState());
      return;
    }

    dispatch(resetError());
  };

  const navigate = useNavigate();

  function redirectToShipmentHistoryList() {
    return navigate('/shipment/history');
  }

  return (
    <>
      {shipmentUpdateSuccessFully ? (
        <ErrorModal
          icon={
            (shipmentUpdateSuccessFully && '/images/paid.svg') ||
            (shipmentUpdateError && '/images/Boxes/NoRatesFound.svg') ||
            ''
          }
          open={shipmentUpdateError || shipmentUpdateSuccessFully}
          title={modalMsg}
          subTitle={modalSubMsg}
          onClose={onClose}
          onConfirm={redirectToShipmentHistoryList}
          btnText="View"
          secondaryBtnText="Ok"
        />
      ) : (
        <ErrorModal
          icon={
            (shipmentUpdateSuccessFully && '/images/paid.svg') ||
            (shipmentUpdateError && '/images/Boxes/NoRatesFound.svg') ||
            ''
          }
          open={shipmentUpdateError || shipmentUpdateSuccessFully}
          title={modalMsg}
          subTitle={modalSubMsg}
          onClose={onClose}
          onConfirm={onClose}
          btnText="Close"
        />
      )}
    </>
  );
}

export default SuccessError;
