import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { UserObj } from '@Reducers/User/Type';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootStateType } from '@Store';
import Modal from '@Components/Modal';
import UploadDocument from '@Components/DocumentUpload/UploadDocument';
import DocumentUploadSuccessfully from '@Components/DocumentUpload/DocumentUploadSuccessfully';
import DocumentDelete from '@Components/DocumentUpload/DocumentDelete';
import Title from '@Components/Title';
import SbButton from '@Components/Button';

import { getKycSetting } from '@Reducers/Global';

import SingleDocument from './SingleDocument';
import { styled } from '@mui/system';
import { getAccountType } from '@Services/user';
import { KycType } from '@Reducers/kyc/Type';
import { docUploadSucModalClose, getUserKyc } from '@Reducers/kyc';
import { USER_STATUS_LIST } from '@Constants/user';

interface PropsType {
  open: boolean;
  data?: UserObj;
  handleClose?: () => void;
  fetchList: () => void;
}

const SingleDocumentWrapper = styled(Grid)(() => ({
  border: '1px solid #b2b2b2',
  borderRadius: '8px',
}));

function KycModal({ open, handleClose, data, fetchList }: PropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const kycSetting = useSelector((state: RootStateType) => state.global.kycSetting);
  const documentUploadSuccessfullyModal = useSelector(
    (state: RootStateType) => state.kyc.documentUploadSuccessfullyModal,
  );
  const _userId = data?._id || '';
  const _kyc = useSelector((state: RootStateType) => state.kyc.adminKyc?.[_userId] || {});
  const kycVerified = data?.onBoardedStatus === USER_STATUS_LIST.on_boarded.value;

  const [uploadDocumentModal, setUploadDocumentModal] = useState<boolean>(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState<boolean>(false);
  const [successfullyModal, setSuccessfullyModal] = useState<boolean>(false);
  const [documentLabel, setDocumentLabel] = useState<string>('');
  const [documentEdit, setDocumentEdit] = useState<KycType | null>(null);
  const [deleteDocId, setDeleteDocId] = useState<string>('');

  const individualDocumentLists = useMemo(() => {
    if (kycSetting && data && data._accountId) {
      const type = getAccountType(data._accountId);
      switch (type) {
        case 'account-individual':
          return kycSetting.individualAccountHolder.list;
        case 'account-individual-company':
          return kycSetting.companyAccountHolder.list;
        case 'individual':
          return kycSetting.nonAccountHolder.list;
        default:
          return [];
      }
    }
    return [];
  }, [kycSetting]);

  useEffect(() => {
    if (!kycSetting) dispatch(getKycSetting());
    if (_userId) dispatch(getUserKyc(_userId));
  }, []);

  useEffect(() => {
    if (documentUploadSuccessfullyModal) {
      setSuccessfullyModal(true);
    }
  }, [documentUploadSuccessfullyModal]);

  const uploadDocumentModalClose = () => setUploadDocumentModal(false);
  const uploadDocumentModalOpen = () => setUploadDocumentModal(true);

  const successModalClose = () => {
    dispatch(docUploadSucModalClose());
    uploadDocumentModalClose();
    setSuccessfullyModal(false);
    if (_userId) {
      dispatch(getUserKyc(_userId));
      fetchList();
    }
  };

  const onDocumentUploadModalOpen = useCallback((documentLabel: string, documentData: KycType | null): void => {
    setDocumentLabel(documentLabel);
    setDocumentEdit(documentData);

    uploadDocumentModalOpen();
  }, []);

  const deleteDocumentModalClose = (refetch: boolean) => {
    setDeleteDocId('');
    setDeleteDocumentModal(false);
    if (refetch && _userId) {
      dispatch(getUserKyc(_userId));
      fetchList();
    }
  };

  const onDocumentDeleteModalOpen = useCallback((id: string): void => {
    setDeleteDocId(id);
    setDeleteDocumentModal(true);
  }, []);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Grid container textAlign="center" sx={{ mb: 4 }}>
          <Title removeBorder removePadding title="KYC Details" />
          <Title removeBorder removePadding title={`User Account No : ${data?._accountId?.accountNumber}`} />
        </Grid>
        <UploadDocument
          open={uploadDocumentModal}
          onClose={uploadDocumentModalClose}
          documentLabel={documentLabel}
          documentData={documentEdit}
          userId={data?._id || ''}
        />
        <DocumentUploadSuccessfully open={successfullyModal} onClose={successModalClose} />
        <DocumentDelete
          id={deleteDocId}
          userId={_userId}
          open={deleteDocumentModal}
          onClose={deleteDocumentModalClose}
        />
        <SingleDocumentWrapper container item>
          {individualDocumentLists.map((kyc, index) => (
            <SingleDocument
              key={`${kyc.label}-${index}`}
              label={kyc.label}
              required={kyc.isRequired}
              documentData={_kyc?.[kyc.label] || null}
              onDocumentUploadModalOpen={onDocumentUploadModalOpen}
              onDocumentDeleteModalOpen={onDocumentDeleteModalOpen}
              fetchList={fetchList}
              accountNumber={data?._accountId?.accountNumber}
              kycVerified={kycVerified}
            />
          ))}
        </SingleDocumentWrapper>
        <SbButton
          onClick={handleClose}
          variant="contained"
          sx={{
            borderRadius: '8px !important',
            mt: 5,
            mb: 1,
          }}
          className="w-full"
        >
          Close
        </SbButton>
      </Modal>
    </>
  );
}

export default KycModal;
