import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getUserList } from './actions';
import { AccountLookupState, UserObj, UserQuery } from './Type';
import { getUserListFulfilled, handlePending, handleRejected } from './case';
/**
 * 
 *   loading: false,
  userList: [],
  viewListModal: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  userQuery: {},
  selectedUser: undefined,
 */

const initialState = <AccountLookupState>{};

const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    toggleListModal: function (state, action: PayloadAction<{ toggle: boolean; modalId: string }>) {
      const { modalId, toggle } = action.payload;
      state[modalId].viewListModal = toggle;
    },
    setSelectedUser: function (state, action: PayloadAction<[string, UserObj]>) {
      const [modalId, selectedUser] = action.payload;
      state[modalId].selectedUser = selectedUser;
      state[modalId].viewListModal = false;
    },
    resetSelectedUser: function (state, action: PayloadAction<{ modalId: string }>) {
      console.log('resetSelectedUser : ', resetSelectedUser);
      const { modalId } = action.payload;
      state[modalId] = state[modalId] || {};
      state[modalId].selectedUser = undefined;
      state[modalId].userQuery = {};
      state[modalId].userList = [];
    },
    setReduxState: function (state, action: PayloadAction<{ modalId: string }>) {
      const { modalId } = action.payload;

      state[modalId] = {
        loading: false,
        userList: [],
        viewListModal: false,
        error: {
          msg: '',
          errorDetails: '',
          isError: false,
        },
        userQuery: {},
        selectedUser: undefined,
        currentPage: 1,
        perPage: 10,
      };
    },
    setUserQuery: function (state, action: PayloadAction<{ modalId: string; userQuery: UserQuery }>) {
      const { modalId, userQuery } = action.payload;

      state[modalId].userQuery = { ...state[modalId].userQuery, ...userQuery };
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;

    addCase(getUserList.pending, handlePending);
    addCase(getUserList.fulfilled, getUserListFulfilled);
    addCase(getUserList.rejected, handleRejected);
  },
});

export { getUserList };
export const { toggleListModal, setSelectedUser, resetSelectedUser, setReduxState, setUserQuery } = userSlice.actions;
export default userSlice.reducer;
