import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import Modal from '../../components/Modal';
import Title from '../../components/Title';
import SbTextField from '../../components/Textfield';
import SBDropdown from '../../components/Dropdown';
import SbButton, { SbLoadingButton } from '../../components/Button';
import { AppDispatch, RootStateType } from '@Store';
import { getWarehouseList } from '@Reducers/Warehouse';
import { createManifest } from '@Reducers/Manifest';
import { getServiceList } from '@Reducers/CarrierAndService';
import { CreateManifestPostData } from '@Reducers/Manifest/Type';

interface PropsType {
  isOpen: boolean;
  handleClose: () => void;
  selectedShipments: string[];
}

export default function CreateManifest({ isOpen, handleClose, selectedShipments }: PropsType) {
  const [service, setService] = useState<string>('');
  const [warehouse, setWarehouse] = useState<string>('');
  const [name, setName] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();

  const { serviceDropdownList, loading: carrierAndServiceLoading } = useSelector(
    (state: RootStateType) => state.carrierAndService,
  );
  const { data: warehouseData, loading: warehouseLoading } = useSelector((state: RootStateType) => state.Warehouse);

  const { success, loading: manifestLoading } = useSelector((state: RootStateType) => state.manifest);
  const loading = manifestLoading || carrierAndServiceLoading || warehouseLoading;

  // const warehouseList = warehouseData?.list?.map(w => ({ display: w.name, value: w._id }));
  const warehouseList = warehouseData?.selectedList?.map(w => ({ display: w.name, value: w._id }));
  console.log({ serviceDropdownList, warehouseList, warehouseData });
  console.log(warehouse);

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [success]);

  // const {
  //   data: { list },
  // } = useSelector((state: RootStateType) => state.shipmentHistory);
  // const cspList =
  //   list
  //     .filter(shipment => selectedShipments.includes(shipment._id))
  //     ?.map(shipment => shipment.sellingPricing?.serviceName) || [];
  // const shipmentNumberList =
  //   list.filter(shipment => selectedShipments.includes(shipment._id))?.map(shipment => shipment.shipmentNumber) || [];

  useEffect(() => {
    dispatch(getServiceList({}));
    dispatch(getWarehouseList({ perPage: 25, currentPage: 1 }));
  }, []);

  function handleCreateManifest() {
    const warehouseObj = warehouseData?.list?.find(w => w._id === warehouse);
    const systemId = warehouseObj?._systemId?.map(s => s._id);
    // if (!service || !systemId) {
    //   return;
    // }
    const postData: CreateManifestPostData = {
      _courierId: service ? service : null,
      shipmentNumbers: selectedShipments,
      _systemId: systemId && systemId[0],
      manifestNumber: null,
      name: name ? name : null,
    };

    const filteredPostData = Object.fromEntries(Object.entries(postData).filter(([_, value]) => value !== null));

    dispatch(createManifest(filteredPostData));
  }

  return (
    <Modal
      open={isOpen}
      dialogContainerSx={{
        paddingTop: 1,
        paddingRight: 1,
        paddingLeft: 3,
      }}
      onClose={handleClose}
    >
      <div className="pr-2 pt-1" style={{ width: '580px' }}>
        <div style={{ textAlign: 'center' }}>
          <Title title="Create Manifest" removeBorder />
        </div>
        <Grid container mb={2}>
          <SBDropdown
            id="service-id"
            label="Service"
            name="service"
            // required={true}
            value={service ? [service] : []}
            options={serviceDropdownList || []}
            // options={[]}
            onChange={([value]) => setService(value)}
          />
        </Grid>
        <Grid container mb={2}>
          <SBDropdown
            id="warehouse-id"
            label="Warehouse"
            name="warehouse"
            // required={true}
            value={warehouse ? [warehouse] : []}
            options={warehouseList || []}
            // options={[]}
            onChange={([value]) => setWarehouse(value)}
          />
        </Grid>
        <Grid container mb={2}>
          <SbTextField
            label="Name"
            name="name"
            className="w-full"
            onChange={e => setName(e.target.value)}
            value={name}
          />
        </Grid>
        <Grid container mb={2}>
          <SbTextField
            label="Total Shipments"
            name="totalShipments"
            className="w-full"
            disabled
            value={selectedShipments?.length}
          />
        </Grid>
        <div className="flex mt-3" style={{ justifyContent: 'center' }}>
          <SbButton variant="outlined" sx={{ borderRadius: '8px !important', mr: 1 }} onClick={handleClose}>
            Cancel
          </SbButton>
          <SbLoadingButton
            variant="contained"
            sx={{ borderRadius: '8px !important', ml: 1 }}
            onClick={handleCreateManifest}
            loading={loading}
          >
            Create Manifest
          </SbLoadingButton>
        </div>
      </div>
    </Modal>
  );
}
