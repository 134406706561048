import React from 'react';
import { Grid, Typography } from '@mui/material';

interface PropsType {
  displayKey: string;
  value: number | string;
}

function SingleCharge({ displayKey, value }: PropsType) {
  return (
    <Grid container justifyContent={'space-between'} className="mb-1">
      <Grid item xs={10} lg={9}>
        <Typography className="md-text">{displayKey}</Typography>
      </Grid>
      <Grid item xs={2} lg={3}>
        <Typography className="md-text text-right">{value}</Typography>
      </Grid>
    </Grid>
  );
}

export default SingleCharge;
