import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
  Box,
  Checkbox,
  // FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  // MenuItem,
  Typography,
  // useTheme,
} from '@mui/material';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues } from 'formik';
import Divider from '../../../components/Divider';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { CreateServicePayload } from '@Reducers/CarrierAndService/Type';
import SbTextField from '@Components/Textfield';
import DragDrop from '@Components/File/DragDrop';
import { FileResultType } from '../../../Helper/file';
import { SbSwitch } from '@Components/Switch';
import { DIM_WT_DIVISOR } from '@Constants/Shipment';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import SBDropdown from '@Components/Dropdown';
// import { DropdownOptionType } from '@Components/Dropdown/Type';
// import { SelectOptions } from '../../GetRatesV2/StyleComponent';
import Image from '@Components/Image';
import SbTooltip from '@Components/Tooltip';
import { InfoRounded } from '@mui/icons-material';

interface CreateServiceFormProps {
  formType?: 'create' | 'edit';
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<CreateServicePayload>['setFieldValue'];
  errors?: FormikErrors<CreateServicePayload>;
}

// const dimWtOptions: DropdownOptionType[] = [
//   {
//     display: 'Kg',
//     value: 'kg',
//   },
//   {
//     display: 'pound',
//     value: 'pound',
//   },
// ];

export const initCreateService: CreateServicePayload = {
  name: '',
  logo: null,
  service: { legs: [] },
  dimWtDivisor: {
    kg: DIM_WT_DIVISOR.kg,
    pound: DIM_WT_DIVISOR.pound,
  },
  carrierId: '',
  copyCarrierLogo: false,
  boxWiseRates: false,
  totalWiseRates: false,
};

function CreateServiceForm(props: CreateServiceFormProps) {
  // const theme = useTheme();
  const { formType, values, errors, handleChange, setFieldValue } = props;
  const isEdit = formType === 'edit';
  const [dimWtOption] = useState<'kg' | 'pound'>('kg');
  const onFileChange = (fileData: FileResultType[]) => {
    setFieldValue('logo', fileData[0]);
  };
  const CarrierState = useSelector((state: RootStateType) => state.carrier);
  const carrierOptions = CarrierState.activeCarrierDropdownList;

  // const onDimWtOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   setDimWtOption(value as 'kg' | 'pound');
  // };

  const onDimWtChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newDimWtDivisor = {
      kg: values.dimWtDivisor.kg,
      pound: values.dimWtDivisor.pound,
    };
    if (dimWtOption === 'kg') newDimWtDivisor.kg = value;
    else newDimWtDivisor.pound = value;

    setFieldValue('dimWtDivisor', newDimWtDivisor);
  };

  // useEffect(
  //   function () {
  //     console.log({ values });
  //   },
  //   [values],
  // );

  useEffect(() => {
    if (values.boxWiseRates === true) {
      setFieldValue('totalWiseRates', false);
    }
    if (values.totalWiseRates === true) {
      setFieldValue('boxWiseRates', false);
    }
  }, [values.boxWiseRates, values.totalWiseRates]);

  return (
    <Grid>
      <Grid item xs={12}>
        <Typography className="org-text underline">{isEdit ? 'Edit' : 'Create'} new service</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
          <FormLabel className="md-text font-medium">Carrier *</FormLabel>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <FormContent>
            <SBDropdown
              id="carrier"
              required
              options={carrierOptions}
              placeholder="Select Carrier"
              onChange={([value]) => setFieldValue?.('carrierId', value)}
              name="carrierId"
              value={[values.carrierId]}
              error={errors?.carrierId}
              disabled={isEdit}
            />
          </FormContent>
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
          <FormLabel className="md-text font-medium">Service Name *</FormLabel>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <FormContent>
            <SbTextField
              required
              placeholder="Enter Service Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              error={errors?.name}
            />
          </FormContent>
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid container item xs={12} md={4} lg={3} mb={{ xs: 1 }} alignItems="center">
          <FormLabel className="md-text font-medium mr-1">Dimensional Divisor</FormLabel>
          <SbTooltip
            placement="bottom-start"
            title="Enter the divisor to calculate the volumetric weight (Vol. Weight = (LxBxH)/dimensional divisor)"
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <Grid item xs={12} md={5} lg={3} xl={2.5}>
          <FormContent>
            <SbTextField
              required
              type="number"
              placeholder="Enter Dimensional Divisor"
              name="dimWtDivisor"
              value={dimWtOption === 'kg' ? values.dimWtDivisor.kg : values.dimWtDivisor.pound}
              onChange={onDimWtChange}
              sx={{ paddingRight: 0 }}
              // endAdornment={
              //   <FormControl style={{ minWidth: 'min-content' }}>
              //     <SelectOptions
              //       labelId="dim-wt-option-label"
              //       id="dim-wt-option"
              //       value={dimWtOption}
              //       onChange={onDimWtOptionChange}
              //       style={{ backgroundColor: theme.palette.background.note }}
              //     >
              //       {dimWtOptions?.map(({ display, value }: DropdownOptionType) => (
              //         <MenuItem key={value} value={value}>
              //           {display}
              //         </MenuItem>
              //       ))}
              //     </SelectOptions>
              //   </FormControl>
              // }
            />
          </FormContent>
          <DimensionalDivisorText>for the metric system (kg-cm)</DimensionalDivisorText>
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
          <FormLabel className="md-text font-medium">Logo {!isEdit && `*`}</FormLabel>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <FormContent className="w-full">
            <Box className={values.copyCarrierLogo ? 'disabled no-pointer-events' : ''}>
              {values.logoUrl ? (
                <Grid container position="relative">
                  <Image src={values.logoUrl} alt={''} width={100} style={{ maxHeight: '200px' }} />
                  <span
                    style={{ top: 2 }}
                    onClick={() => {
                      props.setFieldValue && props.setFieldValue('logoUrl', '');
                    }}
                  >
                    <DeleteOutlineIcon />
                  </span>
                </Grid>
              ) : (
                <DragDrop
                  accept="image/*"
                  onChange={onFileChange}
                  subText={
                    values.logo && typeof values.logo === 'object'
                      ? `${values.logo.fileName} (${values.logo.fileSize})`
                      : 'Supported formats: JPEG, PNG (450x225px, File upto 5 mb)'
                  }
                  readType="base64"
                />
              )}
              {errors?.logo && (
                <FormHelperText error className="m-0">
                  {typeof errors.logo === 'string' ? errors.logo : 'Logo is required'}
                </FormHelperText>
              )}
            </Box>
            {!isEdit && (
              <FormGroup classes={{ root: 'flex' }}>
                <FormControlLabel
                  control={<Checkbox onChange={handleChange} id="copyCarrierLogo" name="copyCarrierLogo" />}
                  label="Same as carrier logo"
                />
              </FormGroup>
            )}
          </FormContent>
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
          <FormLabel className="md-text font-medium">Service type</FormLabel>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <FormContent className="w-full max-w-full">
            <FormGroup classes={{ root: 'flex' }}>
              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.service?.legs?.includes('d-d')}
                      onChange={handleChange}
                      id="d-d"
                      value="d-d"
                      name="service.legs"
                    />
                  }
                  label="Door to door"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.service?.legs?.includes('w-d')}
                      onChange={handleChange}
                      id="w-d"
                      value="w-d"
                      name="service.legs"
                    />
                  }
                  label="Warehouse to door"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.service?.legs?.includes('d-w')}
                      onChange={handleChange}
                      id="d-w"
                      value="d-w"
                      name="service.legs"
                    />
                  }
                  label="Door to warehouse"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values?.service?.legs?.includes('w-w')}
                      onChange={handleChange}
                      id="w-w"
                      value="w-w"
                      name="service.legs"
                    />
                  }
                  label="Warehouse to warehouse"
                />
              </Grid>
            </FormGroup>
          </FormContent>
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid container item xs={12} md={4} lg={3} mb={{ xs: 1 }} alignItems="center">
          <FormLabel className="md-text font-medium mr-1">Charge Box-Wise ?</FormLabel>
          <SbTooltip placement="bottom-start" title="Rates will be charged for each box seperately.">
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <SbSwitch
            label=""
            onChange={handleChange}
            name="boxWiseRates"
            value={values.boxWiseRates}
            defaultChecked={values.boxWiseRates}
          />
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid container item xs={12} md={4} lg={3} mb={{ xs: 1 }} alignItems="center">
          <FormLabel className="md-text font-medium mr-1">Charge Total-Weight ?</FormLabel>
          <SbTooltip placement="bottom-start" title="Rates will be charged for each box seperately.">
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <SbSwitch
            label=""
            onChange={handleChange}
            name="totalWiseRates"
            value={values.totalWiseRates}
            defaultChecked={values.totalWiseRates}
          />
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
    </Grid>
  );
}

// const FormLabel = styled(Typography)`
//   // min-width: 280px;
// `;

const FormContent = styled('div')`
  max-width: 512px;
`;

const DimensionalDivisorText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.text.light,
  backgroundColor: theme.palette.background.grey,
  paddingBlock: '3px',
  paddingInline: '10px',
  marginTop: '3px',
  width: 'fit-content',
}));

export default CreateServiceForm;
