import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { titleCase } from '@Services/getTitleCase';
// import { useNavigate } from 'react-router';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { setInvoiceNumber } from '@Reducers/AdminShipment';

function SingleAccountDetail({
  label,
  value,
  underline = false,
  navigateTo,
  isAccountHolder,
  invoiceNumber,
}: {
  label: string;
  value: string;
  underline?: boolean;
  navigateTo?: string;
  isAccountHolder?: boolean;
  invoiceNumber?: number | null;
}) {
  // const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => {
    if (navigateTo && navigateTo !== '') {
      // navigate(navigateTo);
      // navigate(navigateTo, {
      //   state: { isAccountHolder: isAccountHolder, invoiceNumber: invoiceNumber },
      // });
      dispatch(setInvoiceNumber({ isAccountHolder, invoiceNumber }));
      window.open(navigateTo, '_blank');
    }
  };

  return (
    <Grid item xs={4}>
      <Typography className="font-semibold sb-text-gray">{label}</Typography>
      <Typography
        className={`font-semibold ${underline ? 'underline color-p-main' : ''} ${
          navigateTo !== '' ? 'cursor-pointer' : ''
        } `}
        sx={{ display: 'block' }}
        onClick={handleClick}
      >
        {value}
      </Typography>
    </Grid>
  );
}

export default function AccountDetails() {
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  // const { sn: shipmentNumber } = useParams();
  // const userId = shipmentById?._accountId
  const userName = shipmentById?._userId.name || '';

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isAccountHolderCreateInvoiceAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.View_Invoices_Account_holder.create_invoices,
    PERFORM_ACTION.write,
  );
  const isNonAccountHolderCreateInvoiceAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.View_Invoices_Non_Account_holder.create_invoices,
    PERFORM_ACTION.write,
  );

  const permissionNavigationgAllowed = shipmentById?._accountId?.isAccountHolder
    ? isAccountHolderCreateInvoiceAllowed
    : isNonAccountHolderCreateInvoiceAllowed;

  // useEffect(() => {
  //   if (shipmentById?.isInvoiceGenerated) {
  //     if (shipmentNumber) {
  //       dispatch(shipmentLockRate(shipmentNumber));
  //     }
  //   }
  // }, [shipmentById?.isInvoiceGenerated, shipmentNumber]);

  return (
    <Grid container>
      <Grid container justifyContent={'space-between'} spacing={1} item xs={12} md={5} lg={6}>
        <SingleAccountDetail
          label="Account no"
          value={shipmentById?._accountId?.isAccountHolder ? shipmentById?.accountNumber || '' : '-'}
        />
        {/* <SingleAccountDetail label="Allied Aviation" value={titleCase(userName)} /> */}
        <SingleAccountDetail
          label={shipmentById?._accountId?._company[0]?.name || '-----'}
          value={titleCase(userName)}
        />
        <SingleAccountDetail
          label="Billed"
          value={shipmentById?.invoiceNumber ? `${shipmentById?.invoiceNumber}` : '-'}
          navigateTo={permissionNavigationgAllowed && shipmentById?.invoiceNumber ? '/invoice/view' : ''}
          isAccountHolder={shipmentById?._accountId?.isAccountHolder}
          underline={permissionNavigationgAllowed && shipmentById?.invoiceNumber ? true : false}
          invoiceNumber={shipmentById?.invoiceNumber}
        />
      </Grid>
    </Grid>
  );
}
