import API from '@Services/apiAxios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ExportState {
  url: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: ExportState = {
  url: null,
  loading: false,
  error: null,
};

// Async thunk to fetch the squad list export URL
export const fetchSquadListUrl = createAsyncThunk(
  'export/fetchSquadListUrl',
  async (queryParams: Record<string, any>, thunkAPI) => {
    try {
      const response = await API.get(`/squad/admin/downloadSquadList`, {
        params: queryParams,
      });
      return response.data.data.url;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.msg || 'Failed to fetch the squad list URL.');
    }
  },
);

const exportSlice = createSlice({
  name: 'squadManagementReportExport',
  initialState,
  reducers: {
    resetSquadManagementReportExportState(state) {
      state.url = initialState.url;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSquadListUrl.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSquadListUrl.fulfilled, (state, action: PayloadAction<string>) => {
        state.url = action.payload;
        state.loading = false;
      })
      .addCase(fetchSquadListUrl.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload as string;
        state.loading = false;
      });
  },
});

export const { resetSquadManagementReportExportState } = exportSlice.actions;

export default exportSlice.reducer;
