import * as Yup from 'yup';
import 'yup-phone-lite';

export const CreateAcountchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address.')
    .trim()
    .required('Email is required')
    .matches(/^[^\s@]{2,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
  _systems: Yup.array().of(Yup.string().trim()).min(1, 'Need to provide at least one systems'),
  isAccountHolder: Yup.boolean().default(false),
  accountTag: Yup.string().when('accountType', (accountType, schema) => {
    if (accountType[0] === 'company') return schema.required('Category is required');
    return schema;
  }),
  defaultSystemId: Yup.string().when('accountType', (accountType, schema) => {
    if (accountType[0] === 'company') return schema.required('Default system is required');
    return schema;
  }),
  phoneNumber: Yup.string(),
  // phoneNumber: Yup.string().phone(undefined, 'Invalid phone number'),
});

export const updateAcountchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address.')
    .trim()
    .required('Email is required')
    .matches(/^[^\s@]{2,}@[^@\s]+\.[^\s@]{2,}$/, 'Invalid email address'),
  _systems: Yup.array()
    .of(Yup.string().trim())
    .when(
      ['isDefaultAccount', 'accountType', 'isAccountHolder'],
      ([isDefaultAccount, accountType, isAccountHolder], schema) => {
        console.log('isDefaultAccount', { isDefaultAccount, accountType, isAccountHolder });
        if (isDefaultAccount && accountType === 'company') return schema.min(1, 'Need to provide at least one systems');
        else if (accountType === 'individual' && isAccountHolder) {
          return schema
            .min(1, 'Need to provide at least one systems')
            .max(1, 'There is only one system access per account type individual.');
        }
        return schema.min(1, 'Need to provide at least one systems');
      },
    ),
  isDefaultAccount: Yup.boolean().default(false),
  isAccountHolder: Yup.boolean().default(false),
  accountTag: Yup.string().when('accountType', (accountType, schema) => {
    if (accountType[0] === 'company') return schema.required('Category is required');
    return schema;
  }),
  defaultSystemId: Yup.string().when('accountType', (accountType, schema) => {
    if (accountType[0] === 'company') return schema.required('Default system is required');
    return schema;
  }),
  phoneNumber: Yup.string(),
  // phoneNumber: Yup.string().phone(undefined, 'Invalid phone number'),
});

export const InviteAdminSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address.').trim().required('Email is required.'),
});
