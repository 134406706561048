export const getSubdomain = () => {
  if (typeof window !== 'undefined') {
    const host = window.location.hostname;
    const subdomain = host.split('.')[0];
    const isSpecialSubdomain = ['awx', 'wyngs-my', 'ae', 'wal', 'uat-speedbox'].includes(subdomain);
    return isSpecialSubdomain;
  }
  return false;
};
export const getUsernameFromEmail = (email: string): string => {
  if (!email || !email.includes('@')) {
    return '';
  }
  return email.split('@')[0]; // Extract and return the part before '@'
};
