/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { DeleteAddressType, ErrorType, SaveAddressType, SavedAddressListQuery, SuccessType } from './Type';
import { SuccessPayload } from '../../../types';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';

const getAllSavedAddress = createAsyncThunk('getAllSavedAddress', async (queryParams?: SavedAddressListQuery) => {
  const res = await API.get(`/shipment/saved-address/all?`, { params: queryParams });
  return res.data;
});

const getSavedAddressById = createAsyncThunk('getSavedAddressById', async (id: string) => {
  const res = await API.get(`/shipment/saved-address/${id}`);
  return res.data;
});

const addAddress = createAsyncThunk<SuccessType, SaveAddressType, { rejectValue: ErrorType }>(
  'addAddress',
  async (body: SaveAddressType, { dispatch }) => {
    try {
      const { payload, next } = body;
      const res = await API.post(`/shipment/saved-address/${payload.type}`, payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      if (next) {
        next();
      }
      dispatch(getAllSavedAddress());
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.errorDetails || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const updateAddress = createAsyncThunk<SuccessPayload, SaveAddressType, { rejectValue: ErrorType }>(
  'addAddress',
  async (body: SaveAddressType, { dispatch }) => {
    try {
      const { payload, next } = body;
      const res = await API.put(`/shipment/saved-address/${payload._id}`, payload);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
      if (next) {
        next();
      }
      dispatch(getAllSavedAddress());
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.errorDetails || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const deleteAddress = createAsyncThunk<SuccessType, DeleteAddressType, { rejectValue: ErrorType }>(
  'addAddress',
  async (body: DeleteAddressType, { dispatch }) => {
    try {
      const { addressId } = body;
      const res = await API.delete(`/shipment/saved-address/${addressId}`);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg || 'Address successfully removed',
        }),
      );
      dispatch(getAllSavedAddress());
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

export { getAllSavedAddress, getSavedAddressById, addAddress, updateAddress, deleteAddress };
