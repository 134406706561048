import SbButton from '@Components/Button';
import Modal from '@Components/Modal';
import ViewDocument from '@Components/ViewDocument';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';

interface VerifyModalProps {
  open: boolean;
  onClose: () => void;
  verifyKyc: () => void;
  isKycVerified: boolean;
  previewImage: string;
  fileType: string;
  accountNumber?: string;
  kycVerified?: boolean;
}

const VerifyModal = (props: VerifyModalProps) => {
  const { isKycVerified, previewImage, accountNumber, kycVerified } = props;

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isVerifyKYCAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.verify_kyc,
    PERFORM_ACTION.write,
  );
  // const isVerifyKYCAllowed = false;

  const handleClose = () => {
    props.onClose();
  };

  const handleVerify = () => {
    props.verifyKyc();
    props.onClose();
  };

  return (
    <>
      <Modal open={props.open} onClose={handleClose}>
        <Grid container spacing={2} sx={{ width: '52vw' }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography className="md-text font-semibold ">
                KYC Verification
                <span>
                  {/* <ErrorOutlineIcon className="ml-1 md-text sb-text-error" /> */}
                  {kycVerified ? (
                    <CheckCircleIcon className="ml-1 md-text sb-text-success" />
                  ) : (
                    <ErrorOutlineIcon
                      className="ml-1 md-text sb-text-error"
                      // style={kycVerificationPending ? { color: '#FF9900' } : {}}
                    />
                  )}
                </span>
              </Typography>
              <Typography className="sm-text font-medium">
                User Account No : {accountNumber && accountNumber}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              className="rounded-xs"
              sx={{
                height: '16rem',
                bgcolor: 'background.grey',
                overflow: 'auto',
                iframe: {
                  width: '50%',
                  height: '100%',
                },
              }}
            >
              <ViewDocument fileType={props.fileType} src={previewImage} />
            </Box>
          </Grid>
          {!isKycVerified ? (
            <>
              <Grid item xs={12}>
                {/* <Button variant="contained">Ok</Button> */}
                {/* <Button variant="contained" className="rounded-xs">
              Verify
            </Button> */}
                {isVerifyKYCAllowed && (
                  <SbButton
                    onClick={handleVerify}
                    variant="contained"
                    sx={{
                      borderRadius: '8px !important',
                      mt: 5,
                      mb: 1,
                    }}
                    className="w-full"
                  >
                    Verify
                    <span className="flex">
                      <CheckCircleIcon className="ml-1 md-text sb-text-success pt-0" />
                    </span>
                  </SbButton>
                )}
              </Grid>
            </>
          ) : null}
        </Grid>
      </Modal>
    </>
  );
};

export default VerifyModal;
