import Loader from '@Components/Loader';
import { createSystem, getActiveSystemList, resetSystemRedirect, updateSystemById } from '@Reducers/Systems';
import { AppDispatch, RootStateType } from '@Store';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrganizationSetupContext from '../../../context/OrganizationSetup';
import { OrganizationSetupContextType } from '../../../types/organization';
import CreateSysForm, { initCreateSystem } from '../../System/Create/Form';
import { CreateSystemSchema, UpdateSystemSchema } from '../../System/Create/schema';
import { SystemType } from '../../System/Create/type';
import Actions from '../Actions';
import { OutletBox } from '../Style';

function OrgCreateSystem() {
  const { activeStep, handleNext, handleBack } = React.useContext(
    OrganizationSetupContext,
  ) as OrganizationSetupContextType;

  const dispatch = useDispatch<AppDispatch>();
  const SystemState = useSelector((state: RootStateType) => state.System);

  const [activeFirstSystem] = SystemState.activeSystemList;
  useEffect(() => {
    if (SystemState.isRedirect) {
      console.log({ activeStep });
      dispatch(resetSystemRedirect());
      handleNext(activeStep);
    }
  }, [SystemState.isRedirect]);

  useEffect(() => {
    dispatch(getActiveSystemList());
  }, []);

  // useEffect(() => {
  //   if (activeFirstSystem) {
  //     setSystem({
  //       name: activeFirstSystem.name,
  //       email: activeFirstSystem.contact.email,
  //       supportEmail: activeFirstSystem.contact.supportEmail,
  //       countryCode: activeFirstSystem.contact.phone.telCode,
  //       number: activeFirstSystem.contact.phone.number,
  //       pincodes: [],
  //     });
  //   }
  // }, [SystemState.activeSystemList]);

  const onCreate = (params: SystemType) => {
    const payload = {
      name: params.name,
      contact: {
        phone: {
          number: params.number,
          telCode: params.countryCode,
        },
        supportEmail: params.supportEmail,
        email: params.email,
      },
      address: {
        line1: params.addressLine1,
        line2: params.addressLine2,
        city: params.city,
        state: params.state,
        country: params.country,
        pincode: params.pincode,
      },
      pincode: params.pincodes,
    };

    if (activeFirstSystem) {
      dispatch(updateSystemById({ id: activeFirstSystem._id, payload })).then(() => {
        handleNext(activeStep);
      });
    } else {
      dispatch(createSystem({ payload }));
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: activeFirstSystem?._id
      ? {
          name: activeFirstSystem.name,
          email: activeFirstSystem.contact.email,
          supportEmail: activeFirstSystem.contact.supportEmail,
          countryCode: activeFirstSystem.contact.phone.telCode,
          number: activeFirstSystem.contact.phone.number,
          pincodes: [],
          addressLine1: activeFirstSystem?.address?.line1,
          addressLine2: activeFirstSystem?.address?.line2,
          city: activeFirstSystem?.address?.city,
          state: activeFirstSystem?.address?.state,
          country: activeFirstSystem?.address?.country,
          pincode: activeFirstSystem?.address?.pincode,
        }
      : initCreateSystem,
    validationSchema: activeFirstSystem?._id ? UpdateSystemSchema : CreateSystemSchema,
    onSubmit(values) {
      onCreate(values);
    },
  });

  const { handleSubmit, handleChange, setFieldValue, values: formkikValues, errors } = formik;

  return (
    <Loader loading={SystemState.loading} overly>
      <>
        <OutletBox className="border-grey rounded-md my-3">
          <CreateSysForm
            formType="create"
            showBackgroundColor={false}
            errors={errors}
            values={formkikValues}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </OutletBox>
        <Actions
          onNext={() => handleSubmit()}
          onBack={() => handleBack(activeStep)}
          disableNext={false}
          viewFor={'org-setup'}
          activeStep={0}
        />
      </>
    </Loader>
  );
}

export default OrgCreateSystem;
