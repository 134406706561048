import SBDropdown from '@Components/Dropdown';
import useConnectionTitle from '@Hook/useConnectionTitle';
import { AppDispatch, RootStateType } from '@Store';
import { TimelineContent, TimelineItem } from '@mui/lab';
import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateConnection from './components/CreateConnection';
import SideTimelineSeparator from './components/SideTimelineSeparator';
import { SingleConnectionContainer } from './style';
import SbButton from '@Components/Button';
import { editFinalForward } from '@Reducers/AdminShipment';
import { ShipmentControl } from '@Services/shipmentActionControl';

function NewConnectingWarehouse({ fromWarehouseId, position }: { fromWarehouseId: string; position: number }) {
  const dispatch = useDispatch<AppDispatch>();
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const shipmentActionControl = new ShipmentControl(shipmentById);
  const { warehouseListForShipmentOptions, warehouseListForShipmentOptionsHashById } = useSelector(
    (state: RootStateType) => state.Warehouse,
  );
  const connectionCountInWord = useConnectionTitle({
    position,
    addressObj: warehouseListForShipmentOptionsHashById[fromWarehouseId]?._addressId,
  });

  console.log('NewConnectingWarehouse', !shipmentById?.carrierDetails?.awb && shipmentById?.isFinalForward);
  return (
    <TimelineItem>
      <SideTimelineSeparator inTransit={!shipmentById?.isFinalForward} />
      <TimelineContent sx={{ pb: 0 }}>
        <SingleConnectionContainer container item mb={1} justifyContent="center" className="selected">
          <Grid item xs={12}>
            <Grid container justifyContent={'space-between'} item mb={1}>
              <Typography className="md-text font-semibold capitalize">{connectionCountInWord}</Typography>
              {!shipmentActionControl.doesShipmentDelivered &&
                // !shipmentById?.carrierDetails?.awb &&
                shipmentById?.isFinalForward && (
                  <Grid container xs={6} justifyContent={'end'}>
                    <SbButton
                      sx={{ borderRadius: `4px !important`, zIndex: 1 }}
                      variant="contained"
                      // className="absolute"
                      // disabled={!state.value.trim() || !state.label.trim()}
                      onClick={() => {
                        console.log('isFianlAWBEditable onEdit--');
                        dispatch(editFinalForward(true));
                      }}
                    >
                      Edit
                    </SbButton>
                  </Grid>
                )}
            </Grid>
            <Grid container item spacing={2} xs={12} sm={5.2}>
              <Grid item xs={12}>
                <SBDropdown
                  // required
                  label="Warehouse"
                  id="Select-Connecting-Warehouse"
                  placeholder="Warehouse"
                  options={warehouseListForShipmentOptions}
                  value={fromWarehouseId ? [fromWarehouseId] : []}
                  onChange={() => {
                    return;
                  }}
                  disabled
                />
              </Grid>
            </Grid>
            <CreateConnection fromWarehouseId={fromWarehouseId || ''} />
          </Grid>
          <Grid item xs={12}>
            <Divider className="mt-4" />
          </Grid>
        </SingleConnectionContainer>
      </TimelineContent>
    </TimelineItem>
  );
}

export default NewConnectingWarehouse;
