import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import API from '../../../services/apiAxios';
import { ErrorType, SuccessPayload } from '../../../types';
import { ContractListResponse, CreateContractPostData, DeleteContractPathVariables } from './Type';

const getContractList = createAsyncThunk<ContractListResponse, string, { rejectValue: ErrorType }>(
  'getUserContractList',
  async (accountId, { rejectWithValue }) => {
    try {
      const res = await API.get(`/user/contract/${accountId}?`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const addNewContract = createAsyncThunk<SuccessPayload, [string, CreateContractPostData], { rejectValue: ErrorType }>(
  'createWarehouse',
  async (postData, { rejectWithValue }) => {
    const [accountId, payload] = postData;
    try {
      const res = await API.post(`/user/contract/${accountId}`, payload);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const deleteContract = createAsyncThunk<SuccessPayload, DeleteContractPathVariables, { rejectValue: ErrorType }>(
  'updateWarehouseById',
  async ({ accountId, contractId }: DeleteContractPathVariables, { rejectWithValue }) => {
    try {
      const res = await API.delete(`/user/contract/${accountId}/${contractId}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

export { getContractList, addNewContract, deleteContract };
