import { rootStore } from '@Store';
import { AddressFormatTypes } from './Type';

const addressFormat: AddressFormatTypes = {
  locality: 'city',
  administrative_area_level_2: 'city',
  administrative_area_level_3: 'city',
  administrative_area_level_1: 'state',
  country: 'country',
  postal_code: 'pincode',
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getFormattedLocation(address: any[] | undefined) {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const countries = rootStore.getState().global.countries;

  const formattedAddress = address?.reduce((acc: { [x: string]: any }, curr: { types: any[]; long_name: any }) => {
    const found = curr.types.find((t: string) => Object.keys(addressFormat).includes(t));
    if (found && !acc[addressFormat[found as keyof AddressFormatTypes]]) {
      if (found === addressFormat.country) {
        acc[addressFormat[found as keyof AddressFormatTypes]] =
          countries.find(country => country.name === curr.long_name || country.alternateNames.includes(curr.long_name))
            ?.name ?? '';
      } else {
        acc[addressFormat[found as keyof AddressFormatTypes]] = curr.long_name;
      }
    }
    return acc;
  }, {});
  return formattedAddress;
}
