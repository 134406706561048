import React, { useState } from 'react';
import SbButton from '@Components/Button';
import Title from '@Components/Title';
import { Grid } from '@mui/material';
import AccountDetails from './AccountDetails';
import Charges from './Charges';
import ExtraCharges, { ExtraChargeType } from './ExtraCharge';
import DateWiseGlobalCharges from '@Components/DateWiseGlobalCharges';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import useScroll from '../../useScroll';
import { shipmentLockRate, updateExtraCharge } from '@Reducers/AdminShipment';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { ShipmentControl } from '@Services/shipmentActionControl';
import { shipmentUnlockRate } from '@Reducers/AdminShipment/actions';

export default function ShipmentCharges() {
  const dispatch = useDispatch<AppDispatch>();
  const { shipmentChargesRef } = useScroll();
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const { orgCountryObj } = useSelector((state: RootStateType) => state.orgSetup);

  const isLockRatesWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.lock_rates,
    PERFORM_ACTION.write,
  );
  const isUnlockRatesWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.unlock_rates,
    PERFORM_ACTION.write,
  );

  const isGlobalChargesWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.global_charges,
    PERFORM_ACTION.write,
  );
  const isSellingChargesReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.selling_charges,
    PERFORM_ACTION.read,
  );
  const isBuyingChargesReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.buying_charges,
    PERFORM_ACTION.read,
  );
  const isSellingChargesWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.selling_charges,
    PERFORM_ACTION.write,
  );
  const isBuyingChargesWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.buying_charges,
    PERFORM_ACTION.write,
  );

  const shipmentActionControl = new ShipmentControl(shipmentById);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

  if (!shipmentById) return <></>;
  const { sellingPricing, buyingPricing, shipmentNumber, extraCharges } = shipmentById || {};

  const handleLockRate = () => {
    if (shipmentNumber) dispatch(shipmentLockRate(shipmentNumber));
  };
  const handleUnlockRate = () => {
    if (shipmentNumber) dispatch(shipmentUnlockRate(shipmentNumber));
  };

  // const handleExtraCharge = (type: 'buying' | 'selling') => (payload: ExtraChargeType) => {
  //   console.info('payload:', payload);
  //   const shipmentCharge = extraCharges ? JSON.parse(JSON.stringify(extraCharges[type] || {})) : {};
  //   console.info('shipmentCharge:', shipmentCharge);
  //   const chargeObj = {
  //     name: payload.chargeName,
  //     value: payload.value,
  //     applyOn: payload.applyGlobalCharge,
  //     excludeFromTax: !payload.applyOnGst,
  //   };
  //   console.info('chargeObj:', chargeObj);
  //   shipmentCharge[payload.chargeType as 'add' | 'discount'] = {
  //     ...shipmentCharge[payload.chargeType as 'add' | 'discount'],
  //     [chargeObj.name]: chargeObj,
  //   };

  //   console.info('shipmentCharge-->', shipmentCharge);
  //   if (shipmentNumber) dispatch(updateExtraCharge({ shipmentNumber, type, charge: shipmentCharge }));
  // };

  const handleExtraCharge = (type: 'buying' | 'selling') => (payload: ExtraChargeType[]) => {
    console.info('payload:', payload);

    // Clone the existing extraCharges for the given type
    const shipmentCharge = extraCharges ? JSON.parse(JSON.stringify(extraCharges[type] || {})) : {};

    // Iterate over each charge in the payload array
    payload.forEach(charge => {
      const chargeObj = {
        name: charge.chargeName,
        value: charge.value,
        applyOn: charge.applyGlobalCharge,
        excludeFromTax: !charge.applyOnGst,
      };

      // console.info('chargeObj:', chargeObj);

      // Merge the charge into the appropriate type (add or discount)
      shipmentCharge[charge.chargeType as 'add' | 'discount'] = {
        ...shipmentCharge[charge.chargeType as 'add' | 'discount'],
        [chargeObj.name]: chargeObj,
      };
    });

    // console.info('shipmentCharge-->', shipmentCharge);

    // Dispatch the update action with the updated shipmentCharge
    if (shipmentNumber) {
      dispatch(updateExtraCharge({ shipmentNumber, type, charge: shipmentCharge }));
    }
  };

  const onDeleteExtraCharge =
    (type: 'buying' | 'selling') =>
    ({ chargeName, value }: { chargeName: string; value: number }) => {
      const shipmentCharge = extraCharges ? JSON.parse(JSON.stringify(extraCharges[type] || {})) : {};
      const chargeType = value > 0 ? 'add' : 'discount';
      delete shipmentCharge[chargeType][chargeName];
      if (shipmentNumber) dispatch(updateExtraCharge({ shipmentNumber, type, charge: shipmentCharge }));
    };
  // const isRatesLock = !!lockRates?.length || shipmentById.isManualRate || !shipmentActionControl.canUpdateCharges;
  const isRatesLock =
    shipmentById?.isRateLocked || shipmentById.isManualRate || !shipmentActionControl.canUpdateCharges;

  // const isInvocing =  || false;

  // console.info('shipmentById?.isRateLocked', shipmentById?.invoicing);

  return (
    <div ref={shipmentChargesRef}>
      <Grid container mb={2}>
        <Title title="Shipment Charges" removeBorder removePadding />
      </Grid>
      <Grid
        container
        gap={3}
        style={{
          pointerEvents: shipmentById?.invoicing ? 'none' : 'auto',
          inset: shipmentById?.invoicing ? '0' : '',
          backgroundColor: shipmentById?.invoicing ? 'rgba(0, 0, 0, 0.1)' : 'none',
        }}
        className="p-4 mb-3 border border-solid border-light rounded"
      >
        <AccountDetails />

        <Grid container justifyContent={'end'}>
          {isRatesLock ? (
            <SbButton
              variant="outlined"
              className="rounded-xs"
              sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 }, height: 36, borderRadius: '4px !important' }}
              onClick={handleUnlockRate}
              disabled={!isUnlockRatesWriteAllowed}
            >
              Unlock Rates
            </SbButton>
          ) : (
            <SbButton
              variant="outlined"
              className="rounded-xs"
              sx={{ mr: { xs: 0, md: 1 }, mb: { xs: 1, md: 0 }, height: 36, borderRadius: '4px !important' }}
              onClick={handleLockRate}
              disabled={!isLockRatesWriteAllowed || isRatesLock}
            >
              Lock Rates
            </SbButton>
          )}
          <DateWiseGlobalCharges
            value={selectedDate}
            handleChange={date => setSelectedDate(date)}
            disabled={!isGlobalChargesWriteAllowed || isRatesLock}
          />
        </Grid>

        <Grid container columnSpacing={4}>
          {sellingPricing && isSellingChargesReadAllowed && (
            <Grid item xs={12} md={6}>
              <Grid
                container
                sx={{ p: { xs: 4, md: 8, lg: 8 } }}
                className="mb-3 border border-solid border-light rounded"
              >
                <Charges
                  pricing={sellingPricing?.pricing || []}
                  taxPricing={sellingPricing?.taxPricing || []}
                  totalPricing={sellingPricing?.totalPricing || 0}
                  // currency="INR"
                  currency={orgCountryObj?.currency.name || 'INR'}
                  onDeleteExtraCharge={onDeleteExtraCharge('selling')}
                  disable={isRatesLock}
                  label="Selling Charges"
                  writeAccess={isSellingChargesWriteAllowed}
                />
                {!isRatesLock && (
                  <ExtraCharges
                    writeAccess={isSellingChargesWriteAllowed}
                    onSave={handleExtraCharge('selling')}
                    AdditionalChargeList={shipmentById?._selectedAdditionalChargeId}
                    calledFor={'selling'}
                  />
                )}
              </Grid>
            </Grid>
          )}
          {buyingPricing && isBuyingChargesReadAllowed && (
            <Grid item xs={12} md={6}>
              <Grid
                container
                sx={{ p: { xs: 4, md: 8, lg: 8 } }}
                className="mb-3 border border-solid border-light rounded"
              >
                <Charges
                  pricing={buyingPricing?.pricing || []}
                  taxPricing={buyingPricing?.taxPricing || []}
                  totalPricing={buyingPricing?.totalPricing || 0}
                  // currency="INR"
                  currency={orgCountryObj?.currency.name || 'INR'}
                  onDeleteExtraCharge={onDeleteExtraCharge('buying')}
                  disable={isRatesLock}
                  label="Buying Charges"
                  writeAccess={isBuyingChargesWriteAllowed}
                />
                {!isRatesLock && (
                  <ExtraCharges
                    writeAccess={isBuyingChargesWriteAllowed}
                    onSave={handleExtraCharge('buying')}
                    AdditionalChargeList={shipmentById?._selectedAdditionalChargeMaskedId}
                    calledFor={'buying'}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
