import styled from '@emotion/styled';
import SbButton from '../../../components/Button';
import { Box, Select } from '@mui/material';

export const BoxButton = styled(SbButton)`
  display: flex;
  align-items: center;
  background: rgba(233, 233, 233, 0.4);
  border-radius: 12px;
  padding: 10px;
  width: 100%;
  color: black;
  justify-content: flex-start;
  > svg {
    height: 18px;
    width: 18px;
    margin-right: 10px;
  }
`;

export const CopyCount = styled(Box)`
  // width: 29px;
  height: 18px;
  background-color: var(--mui-palette-border-main);
  border: 0.5px solid;
  border-color: var(--mui-palette-border-main);
  text-align: center;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: var(--mui-palette-primary-contrastText);
`;

export const SelectOptions = styled(Select)`
  & > .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
