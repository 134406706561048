import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

import moment from 'moment';
import { Grid, Typography } from '@mui/material';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { CalendarContainer } from './styles';

import Divider from '@Components/Divider';
import Dropdown from '@Components/Dropdown';
import SbButton from '@Components/Button';

import { AppDispatch, RootStateType } from '../../store';
import { getServiceList } from '@Reducers/CarrierAndService';
import { getActiveSystemList } from '@Reducers/Systems';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getGlobalChargeList } from '@Reducers/GlobalCharge';
import { GlobalChargeResponseObject } from '@Reducers/GlobalCharge/Type';
import { useSearchQueryParams } from '@Hook/useSearchQueryParams';
import { getUrlWithQueryParams } from '../../utils/urlWithqueryParams';
import dayjs from 'dayjs';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const localizer = dayjsLocalizer(dayjs);

function GlobalCharge() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const searchParams = useSearchQueryParams();

  const [system, setSystem] = useState<string>('');
  const [service, setService] = useState<string>('');

  const SystemList = useSelector((state: RootStateType) => state.System.activeSystemDropdownList);
  const ServiceList = useSelector((state: RootStateType) => state.carrierAndService.serviceDropdownList);
  const GlobalChargeState = useSelector((state: RootStateType) => state.globalCharge);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isGlobalChargesEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Global_charges.edit_global_charges,
    PERFORM_ACTION.write,
  );

  useEffect(function () {
    dispatch(getServiceList({}));
    dispatch(getActiveSystemList());
  }, []);

  useEffect(
    function () {
      if (service && system) getListData();
    },
    [service, system],
  );

  useEffect(
    function () {
      if (searchParams?.system) {
        setSystem(searchParams?.system);
      }
      if (searchParams?.service) {
        setService(searchParams?.service);
      }
    },
    [searchParams?.system, searchParams?.service],
  );

  function getFirstDayOfYear() {
    return moment().startOf('year').format('DD/MM/yyyy');
  }

  const getListData = function () {
    const firstDay: string = getFirstDayOfYear();
    const presentDay = moment().format('DD/MM/yyyy');

    dispatch(
      getGlobalChargeList({
        courierId: service,
        systemId: system,
        fromDate: firstDay,
        toDate: presentDay,
      }),
    );
  };

  function onEditGlobalCharge(data: GlobalChargeResponseObject) {
    navigate(`/global-charges/edit/${data._id}`);
  }

  function handleOnSystemChange([value]: string[]) {
    setSystem(value);
    setService('');
    const options = {
      pathname: '/global-charges',
      search: `?${createSearchParams({ system: value })}`,
    };
    navigate(options, { replace: true });
  }

  function handleOnServiceChange([value]: string[]) {
    setService(value);
    const options = {
      pathname: '/global-charges',
      search: `?${createSearchParams({ system, service: value })}`,
    };
    navigate(options, { replace: true });
  }

  const events = (GlobalChargeState.data || []).map(event => ({
    ...event,
    title: `${event.title} (${new Date(event.fromDate).toLocaleDateString()} - ${new Date(
      event.toDate,
    ).toLocaleDateString()})`,
  }));

  return (
    <>
      <Grid container className="mt-4" alignItems="center">
        <Grid item className="px-2" xs={3}>
          <Typography className="md-text">System*</Typography>
        </Grid>
        <Grid item xs={5}>
          <Dropdown
            id="system"
            placeholder="Select System"
            value={[system]}
            onChange={handleOnSystemChange}
            options={SystemList}
            disabled={!isGlobalChargesEditAllowed}
          />
        </Grid>
      </Grid>
      <Divider className="mt-3" />
      <Grid container className="mt-4" alignItems="center">
        <Grid item className="px-2" xs={3}>
          <Typography className="md-text">Service*</Typography>
        </Grid>
        <Grid item xs={5}>
          <Dropdown
            id="service"
            placeholder="Select Service"
            value={[service]}
            onChange={handleOnServiceChange}
            options={ServiceList}
            disabled={!isGlobalChargesEditAllowed || !system}
          />
        </Grid>
      </Grid>
      <Divider className="mt-3" />
      <Grid item xs={6} className="mt-4" textAlign="end">
        <Link
          to={isGlobalChargesEditAllowed ? getUrlWithQueryParams('/global-charges/create', { system, service }) : ''}
        >
          <SbButton
            variant="contained"
            className="ml-auto"
            sx={{ borderRadius: '8px !important' }}
            startIcon={<AddCircleIcon fontSize="small" />}
            disabled={!isGlobalChargesEditAllowed}
          >
            Add Global Charges
          </SbButton>
        </Link>
      </Grid>
      {system && service && (
        <CalendarContainer item container>
          <Grid item xs={12}>
            <Calendar
              views={['month']}
              localizer={localizer}
              events={events}
              startAccessor="fromDate"
              endAccessor={data => {
                const day = dayjs(data.toDate).local().toDate();
                return day;
              }}
              onSelectEvent={onEditGlobalCharge}
            />
          </Grid>
        </CalendarContainer>
      )}
    </>
  );
}

export default GlobalCharge;
