import React, { useEffect, useMemo } from 'react';
import {
  Checkbox,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

import { DropdownOptionType, DropdownPropsType, DropdownOptionTypeObjet } from './Type';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function SBDropdown(props: DropdownPropsType): JSX.Element {
  const {
    placeholder,
    id,
    multiple,
    label,
    options,
    defaultOpen,
    defaultValue,
    required,
    name,
    value,
    disabled,
    showSelectAll = multiple ? (props.showSelectAll === undefined ? true : props.showSelectAll) : props.showSelectAll,
    sx,
    isSortingAllowed = true,
    isSorted,
  } = props;
  const { onChange, renderValue } = props;
  const [selectedValue, setSelectedValue] = React.useState<string[]>([]);
  const [optionsObject, setOptionsObject] = React.useState<DropdownOptionTypeObjet>({});
  const [selectAll, setSelectAll] = React.useState<boolean>(false);

  useEffect(() => {
    if (value) setSelectedValue(value);
    const valueArr = value?.filter(i => i !== '');
    setSelectAll(options.length === valueArr?.length);
  }, [value, options?.length]);

  useEffect(() => {
    setOptionsObject(
      options.reduce(
        (op: DropdownOptionTypeObjet, option: DropdownOptionType) => ((op[option.value] = option), op),
        {},
      ),
    );
  }, [options]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValue>, child: React.ReactNode): void => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    let values: string[] | [];
    if (showSelectAll && typeof value === 'object' && value.some(val => val === 'selectAll')) {
      if (selectAll) {
        values = [];
        setSelectAll(false);
      } else {
        values = options.map(option => option.value);
        setSelectAll(true);
      }
    } else {
      if (showSelectAll && typeof value === 'object') setSelectAll(options.length === value.length);
      values = typeof value === 'string' ? value.split(',') : value;
    }
    setSelectedValue(values);
    const selectedObj = values.map(value => optionsObject[value]);
    onChange(values, selectedObj, event, child);
  };

  const sortedOptions = useMemo(() => {
    if (isSortingAllowed) {
      if (isSorted) {
        return [...options].sort((a, b) => {
          const displayA = a.value || '';
          const displayB = b.value || '';
          return displayA.localeCompare(displayB);
        });
      }
      return [...options].sort((a, b) => {
        const displayA = a.display || '';
        const displayB = b.display || '';
        return displayA.localeCompare(displayB);
      });
    } else {
      return options;
    }
  }, [options, isSortingAllowed]);

  return (
    <Grid container>
      {label && (
        <InputLabel sx={{ mb: '6px', color: '#000' }} className={'sm-text'} id={`${id}-label`} required={required}>
          {label}
        </InputLabel>
      )}
      <Select
        disabled={disabled}
        error={props.error ? true : false}
        className="w-full bg-white"
        labelId={`${id}-labe`}
        id={id}
        value={selectedValue}
        onChange={handleChange}
        name={name}
        input={<OutlinedInput notched={false} label={label} />}
        renderValue={
          renderValue ||
          function (selected: Array<string>): React.ReactNode {
            return renderSelectedValues(selected, placeholder, optionsObject);
          }
        }
        defaultOpen={defaultOpen}
        MenuProps={MenuProps}
        multiple={!!multiple}
        displayEmpty={!!placeholder}
        defaultValue={defaultValue}
        sx={sx}
      >
        <MenuItem className="p-0.5" disabled={required} value="">
          Select
        </MenuItem>
        {sortedOptions.length > 1 && showSelectAll && (
          <MenuItem className="p-0.5" value="selectAll">
            <Checkbox checked={selectAll} />
            <ListItemText primary={'Select All'} />
          </MenuItem>
        )}
        {sortedOptions.map((option: DropdownOptionType, index) => {
          const { display, value, disable, grouped, secondary = '' } = option;
          return (
            <MenuItem className="p-0.5" key={`${id}-${value}-${index}`} value={value} disabled={!!disable || !!grouped}>
              {multiple && !grouped && <Checkbox checked={selectedValue.indexOf(value) > -1} disabled={!!disable} />}
              <ListItemText primary={display} secondary={secondary} />
            </MenuItem>
          );
        })}
      </Select>
      {props.error && (
        <FormHelperText error className="m-0">
          {props.error}
        </FormHelperText>
      )}
    </Grid>
  );
}

function renderSelectedValues(
  selectedValue: Array<string>,
  placeholder: string | undefined,
  optionsObject: DropdownOptionTypeObjet,
): React.ReactNode {
  let values = selectedValue?.length
    ? selectedValue.reduce((op: string, value: any) => {
        const val = value?.name || optionsObject[value]?.display || '';
        op.length && (op += ', ');
        op += val;
        return op;
      }, '')
    : placeholder;
  const isPlaceholder = placeholder && (!selectedValue?.length || !values);
  if (placeholder && !values) values = placeholder;
  return (
    <Typography className="truncate" style={{ fontSize: '16px', color: isPlaceholder ? '#bfc3cc' : '' }}>
      {values}
    </Typography>
  );
}

export default SBDropdown;

// <Dropdown id="tag" onChange={console.log} label="Tag" options={names} multiple />
// const names: DropdownOptionType[] = [
//   { display: 'Oliver Hansen', value: 'Oliver_Hansen', metadata: 'HELLO' },
// ];
