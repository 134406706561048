import SbButton from '@Components/Button';
import Modal from '@Components/Modal';
import SbTextField from '@Components/Textfield';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { Grid, styled } from '@mui/material';
import React from 'react';

interface OtpModalProps {
  open: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  otpValue: string;
  setOtpValue: React.Dispatch<React.SetStateAction<string>>;
}

const ButtonContainer = styled(SbButton)({
  margin: '6px 0px',
  height: '48px',
  fontSize: '16px',
  letterSpacing: '0.46px',
  textTransform: 'capitalize',
});

const OtpModal = ({ open, onClose, onConfirm, otpValue, setOtpValue }: OtpModalProps) => {
  return (
    <>
      <Modal
        open={open}
        dialogContainerSx={{
          padding: 6,
        }}
        onClose={onClose}
      >
        <Grid container justifyContent={'space-between'}>
          <HighlightOff
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '12px',
              top: '15px',
              height: '28px',
              width: '28px',
              color: 'common.grey',
            }}
            color="action"
            onClick={onClose}
          />

          <Grid item className="mb-2">
            <SbTextField
              name="otp"
              label="OTP"
              placeholder="OTP"
              style={{ marginBottom: '10px' }}
              value={otpValue}
              onChange={e => {
                const numericValue = e.target.value.replace(/\D/g, '');
                setOtpValue(`${numericValue}`);
              }}
            />
            <p>A 6-digit code has been sent to your phone/email.</p>
            <br />
            <ButtonContainer onClick={onConfirm} fullWidth variant="contained">
              Verify
            </ButtonContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={5}></Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default OtpModal;
