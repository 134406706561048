import { Table, styled } from '@mui/material';

const SBTable = styled(Table)`
  border: 1px solid #eaecf0;
`;

const SBTableWrapper = styled('div')`
  width: 100%;
  overflow: auto;
  border: 1px solid #eaecf0;
`;

export { SBTable, SBTableWrapper };
