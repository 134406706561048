import { DropdownOptionType } from '../components/Dropdown/Type';

export type ExtensionsType =
  | 'image/png'
  | 'image/jpg'
  | 'image/jpeg'
  | 'application/pdf'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/vnd.ms-excel'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/json'
  | 'text/csv'
  | '';

export type validFileTypes = 'png' | 'jpg' | 'jpeg' | 'pdf' | 'xlsx' | 'xls' | 'doc' | 'docx' | 'json' | 'csv' | '';

export const fileType: { [key: string]: validFileTypes } = {
  'image/png': 'png',
  'image/jpg': 'jpg',
  'image/jpeg': 'jpeg',
  'application/pdf': 'pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.ms-excel': 'xls',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/json': 'json',
  'text/csv': 'csv',
};

export const extensionsType: { [key: string]: ExtensionsType } = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  json: 'application/json',
  csv: 'text/csv',
};

export type FileDownloadOptionType = 'fileDimention' | 'fileFormat';

export type DownloadFileFormatType = {
  xlsx: 'xlsx';
  pdf: 'pdf';
};
export type DownloadFileDimentionType = {
  A4: 'A4';
  A5: 'A5';
};

export const ShippingLabelFormatTypes: DownloadFileDimentionType = { A4: 'A4', A5: 'A5' };
export const ShippingInvoiceTypes: DownloadFileFormatType = { xlsx: 'xlsx', pdf: 'pdf' };

export const FileDimentionOptions: DropdownOptionType[] = [
  { value: 'A4', display: 'A4' },
  { value: 'A5', display: 'A5' },
];
export const FileFormatOptions: DropdownOptionType[] = [
  { value: 'xlsx', display: 'xlsx' },
  { value: 'pdf', display: 'pdf' },
];
