import SbButton from '@Components/Button';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import { getSystemList } from '@Reducers/Systems';
import { AppDispatch, RootStateType } from '@Store';
import { TableContainer, TablePagination } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteOptimizerTable } from './Table';
import DatePicker from '@Components/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { getSquadRouteDetails } from '@Reducers/Squad/actions';
import { fetchSquadDetailsProps, SquadRoutesType } from './Types';
import Loader from '@Components/Loader';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
const SearchFilterList = [{ label: 'Name.', value: 'name' }];
const shipmentTypeList = [
  {
    value: 'pickup',
    display: 'Pickup',
  },
  {
    value: 'delivery',
    display: 'Delivery',
  },
];
export default function RouteOptimizerList() {
  const dispatch = useDispatch<AppDispatch>();

  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const [filterText, setFilterText] = useState('');
  const [allSquadDetails, setAllSquadDetails] = useState([]);
  const [shipmentType, setShipmentType] = useState<string[]>([]);
  const [systemId, setSystemId] = useState<string[]>([]);
  // const [selectedDate] = useState<Date>(new Date());
  // const [selectedRange] = useState<Range[]>([{ startDate: new Date(), endDate: new Date(), key: 'selection' }]);
  const [tripDate, setTripDate] = useState<Dayjs>(dayjs());
  const { currentPage, perPage, totalCount, loading } = useSelector((state: RootStateType) => state.squadManagement);
  const _systems = useSelector((state: RootStateType) => state.loginUser?.profile?._systems);
  const SystemList = useSelector((state: RootStateType) => state.System.data.selectedList).map(system => ({
    display: system.name,
    value: system._id,
  }));
  const activeSystemList = useMemo(() => {
    if (_systems?.length) return SystemList.filter(system => _systems.includes(system.value));
    else return [];
  }, [SystemList, _systems]);
  useEffect(() => {
    dispatch(getSystemList({ currentPage, perPage }));
  }, []);
  const handleShipmentTypeFilter = (selected: Array<string>) => {
    setShipmentType(selected);
    fetchSquadDetails({
      name: filterText,
      currentPage,
      perPage,
      tripDate,
      shipmentType: selected,
      systemId,
    });
  };
  const handleShipmentSystemFilter = (selectedId: Array<string>) => {
    setSystemId(selectedId);
    fetchSquadDetails({
      name: filterText,
      currentPage,
      perPage,
      tripDate,
      shipmentType,
      systemId: selectedId,
    });
  };
  const fetchSquadDetails = async (props: fetchSquadDetailsProps) => {
    const { name, currentPage, perPage, tripDate, shipmentType, systemId } = props;
    const formattedTripDate = typeof tripDate === 'string' ? tripDate : dayjs(tripDate).toISOString().slice(0, 10);
    const queryParams = {
      name,
      currentPage,
      perPage,
      tripDate: formattedTripDate,
      shipmentType: shipmentType?.length != 0 ? JSON.stringify(shipmentType) : undefined,
      systemId: systemId?.length != 0 ? JSON.stringify(systemId) : undefined,
    };
    try {
      const result = await dispatch(getSquadRouteDetails(queryParams));
      const { data } = result.payload as SquadRoutesType;
      if (data.totalCount == 0) {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'No Data found',
            subTitle: '',
          }),
        );
      }
      setAllSquadDetails(data);
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: '',
        }),
      );
      console.error(error);
    }
  };
  useEffect(() => {
    fetchSquadDetails({ currentPage, perPage, tripDate, shipmentType, systemId });
  }, [tripDate]);
  return (
    <>
      <Loader loading={loading} overly />
      <TableActionContainer>
        <FilterSearch
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={filterText}
          onTextChange={e => {
            setFilterText(e.target.value);
          }}
          onTextSearch={() => {
            fetchSquadDetails({
              name: filterText,
              currentPage: 1,
              perPage,
              tripDate,
              shipmentType,
              systemId,
            });
          }}
          onTextClear={() => {
            setFilterText('');
            fetchSquadDetails({
              name: '',
              currentPage: 1,
              perPage,
              tripDate,
              shipmentType,
              systemId,
            });
          }}
        />
        <div></div>

        {/* <DateRangeCalendar
          date={selectedDate}
          ranges={selectedRange}
          onChange={() => {
            //
          }}
        /> */}
        <DatePicker
          label=""
          value={tripDate}
          handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
            setTripDate(date);
          }}
        />
        <FilterSelect
          id="delivery"
          label="Type"
          options={shipmentTypeList}
          value={shipmentType}
          onChange={handleShipmentTypeFilter}
        />
        <FilterSelect
          id="system"
          label="System"
          options={activeSystemList}
          value={systemId}
          onChange={handleShipmentSystemFilter}
        />

        <SbButton
          className="xs-text"
          variant="outlined"
          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
          onClick={() => {
            setFilterText('');
            setShipmentType([]);
            setSystemId([]);
            setTripDate(dayjs());
            fetchSquadDetails({ name: '', currentPage: 1, perPage, tripDate: dayjs() });
          }}
        >
          Clear Filters
        </SbButton>
      </TableActionContainer>
      <TableContainer id="fadedScroll">
        <RouteOptimizerTable allSquadDetails={allSquadDetails} currentPage={currentPage} perPage={perPage} />
      </TableContainer>
      {!!totalCount && !!perPage && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage - 1}
            rowsPerPage={perPage}
            onPageChange={(_, newPage) =>
              fetchSquadDetails({
                currentPage: newPage + 1,
                perPage,
                tripDate,
                shipmentType,
                systemId,
              })
            }
            onRowsPerPageChange={event => {
              const newPerPage = parseInt(event.target.value, 10);
              fetchSquadDetails({
                currentPage: 1,
                perPage: newPerPage,
                tripDate,
                shipmentType,
                systemId,
              });
            }}
          />
        </>
      )}
    </>
  );
}

const TableActionContainer = styled('div')(({ theme }) => ({
  // padding: '32px 16px',
  margin: '2rem 0',
  display: 'grid',
  gridTemplateColumns: '480px 1fr fit-content(180px) fit-content(150px) fit-content(150px) fit-content(150px) 100px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '0',
    columnGap: '8px',
    gridTemplateColumns: '380px 1fr fit-content(180px) fit-content(150px) fit-content(150px) fit-content(150px) 100px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));
