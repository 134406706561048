import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import Title from '../../components/Title';
import { Outlet, useLocation } from 'react-router-dom';
// import SbButton from '../../components/Button';
import Divider from '../../components/Divider';
import Loader from '../../components/Loader';
import { AppDispatch, RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceList } from '../../store/reducers/CarrierAndService';
import BackButton from '@Components/BackButton';

function ODA() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const loading = useSelector((state: RootStateType) => state.carrierAndService.loading || state.oda.loading);

  const showBackButton =
    location.pathname === '/oda/create' ||
    location.pathname.startsWith('/oda/edit') ||
    location.pathname.startsWith('/oda/charges');

  useEffect(() => {
    dispatch(getServiceList({}));
  }, []);
  return (
    <Loader loading={loading} overly>
      <>
        <Grid container alignItems="center">
          <Grid item xs={6} sx={{ display: 'flex', gap: '0.5rem' }}>
            {showBackButton && <BackButton link="/oda" />}
            <Title title="ODA Charges" subTitle="View and update out of delivery charges" removeBorder removePadding />
          </Grid>
        </Grid>
        <Divider style={{ margin: `calc(var(--mui-palette-spacing) * 3.5) 0` }} />
        <Outlet />
      </>
    </Loader>
  );
}

export default ODA;
