import { PayloadAction } from '@reduxjs/toolkit';
import {
  SingleSystemResponse,
  ActiveSystemListResponse,
  SystemCreateResponse,
  SystemListResponse,
  SystemState,
} from './Type';
import { ErrorType } from '../../../types';

function pending(state: SystemState) {
  state.loading = true;
}

function getSystemListFulfilled(state: SystemState, data: { payload: SystemListResponse }) {
  const { list } = data.payload.data;
  // state.data.list = list;
  // state.data.currentPage = currentPage;
  // state.data.perPage = perPage;
  state.loading = false;
  state.data.selectedList = list;
  state.data.totalCount = list?.length;
  const startIndex = state.data.currentPage * state.data.perPage;
  const endIndex = startIndex + state.data.perPage;
  state.data.list = list?.slice(startIndex, endIndex);
}

function getSystemListRejected(state: SystemState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function getActiveSystemListFulfilled(state: SystemState, data: { payload: ActiveSystemListResponse }) {
  const lists = data.payload.data;
  state.loading = false;
  state.activeSystemList = lists;
  state.activeSystemDropdownList = lists.map(({ name, _id }) => ({ display: name, value: _id }));
  const hashObj: SystemState['activeSystemListHashById'] = {};
  state.activeSystemListHashById = lists.reduce((op, system) => ((op[system._id] = system), op), hashObj);
}

function getActiveSystemListRejected(state: SystemState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function getLoggedInUserSystemListFulfilled(state: SystemState, data: { payload: ActiveSystemListResponse }) {
  const lists = data.payload.data;
  state.loading = false;
  state.loggedInUserSystemDropdownList = lists.map(({ name, _id }) => ({ display: name, value: _id }));
}

function getLoggedInUserSystemListRejected(state: SystemState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function createSystemFulfilled(state: SystemState, _data: { payload: SystemCreateResponse }) {
  state.isRedirect = true;
  state.loading = false;
}

function createSystemRejected(state: SystemState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = { msg: action.payload?.msg || 'Error while pincode upload.', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function getSystemByIdFulfilled(state: SystemState, data: { payload: SingleSystemResponse }) {
  state.singleSystem = data.payload.data;
  state.loading = false;
}

function getSystemByIdRejected(state: SystemState) {
  state.loading = false;
  state.error = { msg: 'The desired system does not exist.', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function updateSystemFulfilled(state: SystemState, data: { payload: SingleSystemResponse }) {
  state.singleSystem = data.payload.data;
  state.loading = false;
}

export {
  pending,
  getSystemListFulfilled,
  getSystemListRejected,
  //
  createSystemFulfilled,
  createSystemRejected,
  //
  getSystemByIdFulfilled,
  getSystemByIdRejected,
  //
  updateSystemFulfilled,
  getActiveSystemListFulfilled,
  getActiveSystemListRejected,
  //
  getLoggedInUserSystemListFulfilled,
  getLoggedInUserSystemListRejected,
};
