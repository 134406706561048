import SbButton from '@Components/Button';
import Image from '@Components/Image';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { ServiceAndCarrierState, ServiceObj } from '@Reducers/CarrierAndService/Type';
import { PayloadMarkup, RateState } from '@Reducers/RateSummary/Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { SystemState } from '@Reducers/Systems/Type';
import API from '@Services/apiAxios';
import { AppDispatch, RootStateType } from '@Store';
import { Stack, TableBody, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useSystemCspHashById() {
  const cspObj = useSelector<RootStateType>(
    state => state.carrierAndService.serviceListHashById,
  ) as ServiceAndCarrierState['serviceListHashById'];

  const systemObj = useSelector<RootStateType>(
    state => state.System.activeSystemListHashById,
  ) as SystemState['activeSystemListHashById'];

  return [cspObj, systemObj];
}

export function RatesTable() {
  const dispatch = useDispatch<AppDispatch>();
  const RateState = useSelector((state: RootStateType) => state.RateSummary);
  const [cspHashByid, systemHashById] = useSystemCspHashById();
  const RateConfig = useSelector<RootStateType>(state => state.RateSummary.config) as RateState['config'];
  const onDownload = async (rate: PayloadMarkup) => {
    const sheetData = RateState.sheets[rate?.csp + rate?.system];
    const rateParams = {
      accountNumber: sheetData.accountNumber,
      documentRates: sheetData.documentRates,
      startDate: sheetData.startDate,
      endDate: sheetData.endDate,
      csp: sheetData.csp,
      system: sheetData.system,
      isImport: RateConfig.isImport,
    };
    const accountNumber = sheetData.accountNumber || 'default';
    const searchParams = new URLSearchParams();
    searchParams.append('sheet', encodeURIComponent(JSON.stringify(rateParams)));
    const searchParamsStr = '?' + searchParams.toString();
    try {
      const result = await API.get('/shipment/download/rate/' + accountNumber + searchParamsStr);
      // downloadURI.searchParams.append('sheet', JSON.stringify(rateParams));
      window.open(result.data);
    } catch (error) {
      const errorRes = error?.response?.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
    }
  };
  return (
    <SBTable>
      <TableHead>
        <TableRow>
          <TableHeadCell>Services</TableHeadCell>
          <TableHeadCell>System </TableHeadCell>
          <TableHeadCell>Parcel </TableHeadCell>
          <TableHeadCell>Document </TableHeadCell>
          <TableHeadCell>Validity </TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {RateState.selectedDateRate.map((rate: PayloadMarkup, index) => {
          const serviceObj = cspHashByid?.[rate.csp] as ServiceObj;
          return (
            <SBTableRow key={index}>
              <TableRowCell align="center" className="sm-text">
                <Stack direction={'row'} gap={'1rem'} alignItems={'center'}>
                  <Stack justifyContent={'center'} alignItems={'center'} className="p-2 border-1px border-grey rounded">
                    <Image
                      src={serviceObj?.logo}
                      alt={serviceObj?.name}
                      width={83}
                      height={59}
                      style={{ objectFit: 'contain' }}
                    />
                  </Stack>
                  <div>{serviceObj?.name}</div>
                </Stack>
              </TableRowCell>
              <TableRowCell
                sx={{
                  justifyContent: 'center',
                }}
                align="center"
                className="sm-text text-center"
              >
                {systemHashById?.[rate.system]?.name}
              </TableRowCell>
              <TableRowCell align="center" className="sm-text">
                {!rate.documentRates ? (
                  <SbButton
                    onClick={() => {
                      onDownload(rate);
                    }}
                    // fullWidth
                    variant="contained"
                    sx={{
                      borderRadius: '8px !important',
                      fontWeight: 500,
                      color: 'common.black',
                      backgroundColor: 'primary.light',
                      boxShadow: 'none',
                      ':hover': {
                        color: 'common.black',
                        backgroundColor: 'primary.light',
                        boxShadow: 'none',
                      },
                    }}
                    endIcon={<Image src={'/images/file_download.svg'} alt={'Speedbox'} width={20} height={20} />}
                  >
                    Rate sheet
                  </SbButton>
                ) : (
                  '----'
                )}
              </TableRowCell>
              <TableRowCell align="center" className="sm-text">
                {rate.documentRates ? (
                  <SbButton
                    onClick={() => onDownload(rate)}
                    // fullWidth
                    variant="contained"
                    sx={{
                      borderRadius: '8px !important',
                      color: 'common.black',
                      fontWeight: 500,
                      backgroundColor: 'primary.light',
                      boxShadow: 'none',
                      ':hover': {
                        color: 'common.black',
                        backgroundColor: 'primary.light',
                        boxShadow: 'none',
                      },
                    }}
                    endIcon={<Image src={'/images/file_download.svg'} alt={'Speedbox'} width={20} height={20} />}
                  >
                    Rate sheet
                  </SbButton>
                ) : (
                  '----'
                )}
              </TableRowCell>
              <TableRowCell align="center" className="md-text">
                {moment(rate.endDate).format('DD/MM/YYYY')}
              </TableRowCell>
            </SBTableRow>
          );
        })}
      </TableBody>
    </SBTable>
  );
}
