import React, { useMemo, useState } from 'react';
import { Grid, Typography, alpha, styled } from '@mui/material';
import PayByCash from './PayByCash';
import Refund from './Refund';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { ShipmentControl } from '@Services/shipmentActionControl';

type PaymentOptions = 'cash' | 'refund';

function PaymentOptionSelections() {
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const shipmentActionControl = new ShipmentControl(shipmentById);
  const shipmentpayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);
  const { payableAmount, tdsAmount = 0 } = shipmentpayment || {};

  const [paymentOptions, setPaymentOption] = useState<PaymentOptions | null>(null);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isPaymentWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.payment,
    PERFORM_ACTION.write,
  );
  const canRedund = useMemo(() => {
    if (shipmentpayment?._transactionsId.length) {
      return shipmentpayment._transactionsId.some(t => t.mode === 'wallet' || t.mode === 'cash');
    }
  }, [shipmentpayment?._transactionsId]);

  return (
    <Grid item xs={12} lg={6}>
      {shipmentActionControl.canDoPayment && (
        <>
          {paymentOptions === null && (
            <Grid item xs={12} mb={1}>
              <Typography className="font-normal px_20-text">Select Payment Mode</Typography>
            </Grid>
          )}
          {!paymentOptions && (
            <Grid container>
              <PaymentSingleOption
                className={`${
                  !shipmentById?.isInvoiceGenerated &&
                  isPaymentWriteAllowed &&
                  payableAmount &&
                  shipmentById?._accountId?.isPrepaid &&
                  payableAmount - tdsAmount > 0
                    ? 'cursor-pointer'
                    : 'disabled-form cursor-not-allowed'
                } `}
                onClick={() => {
                  if (
                    !shipmentById?.isInvoiceGenerated &&
                    shipmentById?._accountId?.isPrepaid &&
                    isPaymentWriteAllowed &&
                    payableAmount &&
                    payableAmount - tdsAmount > 0
                  ) {
                    setPaymentOption('cash');
                  }
                }}
              >
                Pay by Cash
              </PaymentSingleOption>
              <PaymentSingleOption
                className={`${
                  (!shipmentById?.isInvoiceGenerated &&
                    isPaymentWriteAllowed &&
                    canRedund &&
                    payableAmount &&
                    payableAmount - tdsAmount < 0) ||
                  (shipmentById?.status === 'cancelled' &&
                    shipmentById?._accountId.isPrepaid &&
                    shipmentById?.paidAmount > 0)
                    ? 'cursor-pointer'
                    : 'disabled-form cursor-not-allowed'
                } mt-1`}
                onClick={() => {
                  if (
                    (!shipmentById?.isInvoiceGenerated &&
                      isPaymentWriteAllowed &&
                      canRedund &&
                      payableAmount &&
                      payableAmount - tdsAmount < 0) ||
                    (shipmentById?.status === 'cancelled' &&
                      shipmentById?._accountId.isPrepaid &&
                      shipmentById?.paidAmount > 0)
                  ) {
                    setPaymentOption('refund');
                  }
                }}
              >
                Refund
              </PaymentSingleOption>
            </Grid>
          )}
        </>
      )}
      {paymentOptions === 'cash' && <PayByCash onCancel={() => setPaymentOption(null)} />}
      {paymentOptions === 'refund' && <Refund onCancel={() => setPaymentOption(null)} />}
    </Grid>
  );
}

const PaymentSingleOption = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  width: '100%',
  boxShadow: '0px 2px 4px 0px rgba(156, 156, 156, 0.25)',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 1),
  },
}));

export default PaymentOptionSelections;
