import {
  SquadListState,
  SquadMemberPayload,
  squadReportsDetailsPayload,
  SquadReportsType,
  SquadRouteOptimizerPayload,
  SquadRouteOptimizerState,
  SquadTripDetailsPayload,
  TaskListState,
  TaskManagementPayload,
  squadRoutePayload,
  squadTripDetailsState,
  SquadList,
  SquadOnOffData,
  SquadOnOffState,
} from './type';

function getCreateSquadPending(state: SquadListState) {
  state.loading = true;
}

function getCreateSquadFulfilled(state: SquadListState) {
  state.loading = false;
}

function getCreateSquadRejected(state: SquadListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getAllSquadMemberPending(state: SquadListState) {
  state.loading = true;
}

function getAllSquadMemberFulfilled(state: SquadListState, data: { payload: SquadMemberPayload }) {
  state.loading = false;
  state.currentPage = data.payload.currentPage;
  state.perPage = data.payload.perPage;
  state.totalCount = data.payload.totalCount;
}

function getAllSquadMemberRejected(state: SquadListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function uploadKycDocumentPending(state: SquadListState) {
  state.loading = true;
}

function uploadKycDocumentFulfilled(state: SquadListState) {
  state.loading = false;
}

function uploadKycDocumentRejected(state: SquadListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function updateSquadPending(state: SquadListState) {
  state.loading = true;
}

function updateSquadFulfilled(state: SquadListState) {
  state.loading = false;
}

function updateSquadRejected(state: SquadListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getAllTaskShipmentsPending(state: TaskListState) {
  state.loading = true;
}

function getAllTaskShipmentsFulfilled(state: TaskListState, data: { payload: TaskManagementPayload }) {
  state.loading = false;
  state.totalCount = data.payload.totalCount;
  state.page = data.payload.page;
  state.pageSize = data.payload.pageSize;
}
function getAllTaskShipmentsRejected(state: TaskListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}
function getSquadReportsPending(state: SquadListState) {
  state.loading = true;
}

function getSquadReportsFulfilled(state: SquadListState, data: { payload: SquadReportsType }) {
  state.loading = false;
  state.totalCount = data.payload.totalCount;
  state.currentPage = data.payload.currentPage;
  state.perPage = data.payload.perPage;
}

function getSquadReportsRejected(state: SquadListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getSquadReportsDetailsPending(state: SquadListState) {
  state.loading = true;
}

function getSquadReportsDetailsFulfilled(state: SquadListState, data: { payload: squadReportsDetailsPayload }) {
  state.loading = false;
  state.totalCount = data.payload.totalCount;
  state.currentPage = data.payload.currentPage;
  state.pageSize = data.payload.pageSize;
}

function getSquadReportsDetailsRejected(state: SquadListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}
function getSquadRouteDetailsPending(state: SquadListState) {
  state.loading = true;
}

function getSquadRouteDetailsFulfilled(state: SquadListState, data: { payload: squadRoutePayload }) {
  state.loading = false;
  state.totalCount = data.payload.totalCount;
  state.currentPage = data.payload.currentPage;
  state.perPage = data.payload.perPage;
}

function getSquadRouteDetailsRejected(state: SquadListState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getSquadRouteOptimizerPending(state: SquadRouteOptimizerState) {
  state.loading = true;
}

function getSquadRouteOptimizerFulfilled(
  state: SquadRouteOptimizerState,
  data: { payload: SquadRouteOptimizerPayload },
) {
  state.loading = false;
  state.routeOptimizerTrips = data.payload;
}

function getSquadRouteOptimizerRejected(state: SquadRouteOptimizerState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getSquadTripDetailsPending(state: squadTripDetailsState) {
  state.loading = true;
}

function getSquadTripDetailsFulfilled(state: squadTripDetailsState, data: { payload: SquadTripDetailsPayload }) {
  state.loading = false;
  state.squadTripDetails = data.payload;
}

function getSquadtripDetailsRejected(state: squadTripDetailsState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getSquadMemberTaskPending(state: SquadList) {
  state.loading = true;
}

function getSquadMemberTaskFulfilled(state: SquadList, data: { payload: SquadMemberPayload }) {
  state.loading = false;
  state.totalCount = data.payload.totalCount;
  state.currentPage = data.payload.currentPage;
  state.perPage = data.payload.perPage;
}
function getSquadMemberTaskRejected(state: SquadList) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}
function getSquadOnOffCountRejected(state: SquadOnOffState) {
  state.loading = false;
  state.error = { msg: 'Failed to fetch squad on/off count', isError: true };
}

function getSquadOnOffCountPending(state: SquadOnOffState) {
  state.loading = true;
  state.error = { msg: 'error', isError: true };
}

function getSquadOnOffCountFulfilled(state: SquadOnOffState, data: { payload: SquadOnOffData }) {
  state.loading = false;
  state.data = data.payload;
}
export {
  getCreateSquadPending,
  getCreateSquadFulfilled,
  getCreateSquadRejected,
  getAllSquadMemberPending,
  getAllSquadMemberFulfilled,
  getAllSquadMemberRejected,
  uploadKycDocumentPending,
  uploadKycDocumentFulfilled,
  uploadKycDocumentRejected,
  updateSquadPending,
  updateSquadFulfilled,
  updateSquadRejected,
  getAllTaskShipmentsPending,
  getAllTaskShipmentsFulfilled,
  getAllTaskShipmentsRejected,
  getSquadReportsPending,
  getSquadReportsFulfilled,
  getSquadReportsRejected,
  getSquadReportsDetailsPending,
  getSquadReportsDetailsFulfilled,
  getSquadReportsDetailsRejected,
  getSquadRouteDetailsPending,
  getSquadRouteDetailsFulfilled,
  getSquadRouteDetailsRejected,
  getSquadRouteOptimizerPending,
  getSquadRouteOptimizerFulfilled,
  getSquadRouteOptimizerRejected,
  getSquadTripDetailsPending,
  getSquadTripDetailsFulfilled,
  getSquadtripDetailsRejected,
  getSquadMemberTaskPending,
  getSquadMemberTaskRejected,
  getSquadMemberTaskFulfilled,
  getSquadOnOffCountPending,
  getSquadOnOffCountRejected,
  getSquadOnOffCountFulfilled,
};
