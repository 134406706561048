import FilterSelect from '@Components/Filter/Dropdown';
import Image from '@Components/Image';
import { RootStateType, AppDispatch } from '@Store';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUserSystemList } from '../../store/reducers/Systems';
import API from '@Services/apiAxios';

interface AnalyticsStatsResponse {
  totalCount: number;
  cancelled: number;
  picked_up: number;
}

const TodayShipment = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [system, setSystem] = useState<string[]>([]);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const [analyticsData, setAnalyticsData] = useState<AnalyticsStatsResponse | null>(null);
  // const [loading, setLoading] = useState<boolean>(true);

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
  };

  useEffect(() => {
    dispatch(getLoggedInUserSystemList());
  }, []);

  const fetchAnalyticsStats = async () => {
    try {
      const currentDate = new Date().toISOString();
      const response = await API.get('/analytics/shipment/user/today', {
        params: {
          date: currentDate,
          'system[]': system,
        },
      });
      setAnalyticsData(response.data.data);
    } catch (error) {
      console.error('Error fetching analytics stats:', error);
    }
  };

  useEffect(() => {
    fetchAnalyticsStats();
  }, [system]);

  return (
    <Box
      sx={{
        borderRadius: '9px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '80px', sm: '40px' },
        padding: '20px 40px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Box
          sx={{
            width: { xs: '100%', sm: 'auto' },
            marginBottom: { xs: '10px', sm: '0' },
          }}
        >
          <FilterSelect
            id="system"
            label="Select system"
            options={loggedInUserSystemDropdownList}
            value={system}
            onChange={onSystemFilter}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box sx={{ flexBasis: '20%', display: 'flex', justifyContent: 'center', fontSize: '24px', fontWeight: '500' }}>
          <p style={{ color: 'rgba(0, 0, 0, 1)', position: 'relative', bottom: '40px' }}>Today's Shipments</p>
        </Box>
        <Box
          sx={{
            flexBasis: '80%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
            color: 'rgba(31, 30, 97, 1)',
          }}
        >
          <Box sx={{ display: 'flex', gap: '14px' }}>
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <p style={{ font: '500', fontSize: '32px' }}>{analyticsData?.totalCount ?? 0}</p>
              <p style={{ font: '500', fontSize: '20px' }}>Active Shipments</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              marginTop: { xs: '30px', sm: '0' },
              marginRight: { xs: '70px', sm: '0px' },
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <p style={{ font: '500', fontSize: '32px' }}>{analyticsData?.cancelled ?? 0}</p>
              <p style={{ font: '500', fontSize: '20px' }}>Canceled</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              marginTop: { xs: '30px', sm: '0' },
              marginRight: { xs: '70px', sm: '0px' },
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="revenue"
            />

            <Box>
              <p style={{ font: '500', fontSize: '32px' }}>{analyticsData?.picked_up ?? 0}</p>
              <p style={{ font: '500', fontSize: '20px' }}>Picked Up</p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TodayShipment;
