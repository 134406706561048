export const SQUAD_VEHICLES = [
  {
    display: 'Bike',
    value: 'bike',
  },
  {
    display: 'Truck',
    value: 'truck',
  },
  {
    display: 'Three Wheeler',
    value: 'three_wheeler',
  },
  {
    display: 'Vans',
    value: 'vans',
  },
  {
    display: 'Mini Truck',
    value: 'mini_truck',
  },
];
