import { Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { BoxType, createShipmentContextType } from '../../../../types/shipment';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { ConfirmBoxDeleteType } from '../Type';
import Boxes from '../BoxWise';
import { defaultBoxState } from '../../constants';
import SbButton from '../../../../components/Button';
import BoxDelete from '../../../GetRatesV2/BoxWise/BoxDelete/BoxDelete';
import { ScrollableBox } from '../../styles';
import updateBoxId from '@Services/updateBoxId';
import { AddressType } from '@Reducers/GetRates/Type';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { SHIPMENT_TYPES } from '../../../GetRatesV2/Constant';
import { getChargeableWeight } from '@Reducers/GetRates/actions';

function getAddressObj({ pincode, city, country, state }: AddressType) {
  return { city, state, country, pincode };
}

function BoxList({ writeAccess }: { writeAccess?: boolean }) {
  const { boxes, setBoxes, selectedBox, setSelectedBox, shipmentType } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const dispatch = useDispatch<AppDispatch>();

  const [openConfirmBoxDelete, setOpenConfirmBoxDelete] = useState<ConfirmBoxDeleteType>({ open: false, box: null });
  const [openCopyBox, setOpenCopyBox] = useState<ConfirmBoxDeleteType>({ open: false, box: null });

  const writePermission = writeAccess ?? true;

  function handleAddBox() {
    const boxesTemp = [...boxes];
    const box = structuredClone(defaultBoxState);
    box['id'] = boxes.length.toString();
    boxesTemp.push(box);
    setSelectedBox(box);
    // setSelectedProduct({ ...defaultProductState });
    const arr = updateBoxId<BoxType>(boxesTemp, defaultBoxState, 0);
    setBoxes([...arr]);
  }

  function onDelete(box: BoxType) {
    if (boxes.length < 2) return;
    setOpenConfirmBoxDelete({ open: true, box });
  }

  const handleCloseModal = useCallback(() => {
    if (openConfirmBoxDelete.open) setOpenConfirmBoxDelete({ open: false, box: null });
    if (openCopyBox.open) setOpenCopyBox({ open: false, box: null });
  }, [openConfirmBoxDelete, openCopyBox]);

  const handleDeleteBox = useCallback(() => {
    handleCloseModal();
    const selectedBoxToDelete = openConfirmBoxDelete.box;
    if (selectedBoxToDelete === null) return;
    let boxesTemp = [...boxes];
    const selectedBoxInd = boxesTemp.findIndex(box => box.id === selectedBoxToDelete.id);
    boxesTemp.splice(selectedBoxInd, 1);
    boxesTemp = boxesTemp.map((box, ind) => ({ ...box, id: ind.toString() }));
    if (selectedBox.id === selectedBoxInd.toString()) {
      setSelectedBox(boxesTemp[0]);
    }
    const arr = updateBoxId<BoxType>(boxesTemp, defaultBoxState, 0);
    setBoxes([...arr]);

    if (shipmentById?.shipmentNumber) {
      const obj = {
        destination: { ...getAddressObj(shipmentById?._consigneeId) },
        source: { ...getAddressObj(shipmentById?._shipperId) },
        package: {
          box: arr.map(box => ({
            weight: box.weight.toString(),
            length: Number(box.length),
            width: Number(box.width),
            height: Number(box.height),
          })),
          unitOfMeasurement: shipmentType.unit,
        },
        document: shipmentType.type === SHIPMENT_TYPES[1].value,
        _courierId: shipmentById?.sellingPricing?._courierId,
        shipmentEdit: true,
        inbound: shipmentById?.sellingPricing?.inbound,
        outbound: shipmentById?.sellingPricing?.outbound,
      };
      dispatch(getChargeableWeight({ ...obj }));
    }
  }, [openConfirmBoxDelete, handleCloseModal]);

  return (
    <>
      {/* <ConfirmBoxDelete open={openConfirmBoxDelete.open} onClose={handleCloseModal} onConfirm={handleDeleteBox} /> */}
      <BoxDelete open={openConfirmBoxDelete.open} onClose={handleCloseModal} onConfirm={handleDeleteBox} />
      {/* <CopyBox open={openCopyBox.open} onClose={handleCloseModal} onConfirm={handleCopyBox} /> */}

      <Grid container className="pr-2">
        <SbButton
          className="mb-3 rounded w-full"
          variant="outlined"
          onClick={handleAddBox}
          disabled={!writePermission ? !writePermission : shipmentType.type !== 'parcel'}
        >
          Add a Box
        </SbButton>
      </Grid>

      <ScrollableBox container sx={{ pr: { xs: 1, md: 2 } }}>
        {boxes?.map((box: BoxType, i: number) => (
          <Boxes
            key={i}
            boxData={box}
            handleDeleteBox={() => onDelete(box)}
            writePermission={writePermission}
            index={i}
          />
        ))}
      </ScrollableBox>
    </>
  );
}

export default BoxList;
