import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProductColumn, ProductRenderParamsType, ProductType } from '../../../../types/shipment';
import styled from '@emotion/styled';
import { Grid, Typography, IconButton } from '@mui/material';

const columns: ProductColumn[] = [
  { id: 'description', label: 'Description', maxWidth: 300, align: 'left' },
  { id: 'quantity', label: 'Quantity' },
  {
    id: 'unitValue',
    label: 'Unit value',
    maxWidth: 120,
    // align: 'right',
    // format: (value: number) => value.toLocaleString('en-US'),
  },
  {
    id: 'HSNName',
    label: 'HSN name',
    maxWidth: 100,
    // align: 'right',
  },
  {
    id: 'HSNCode',
    label: 'HSN Code',
    maxWidth: 30,
    // align: 'right',
  },
  {
    id: 'totalValue',
    label: 'Total Value',
    maxWidth: 30,
    render: ({ row }: ProductRenderParamsType) => {
      const totalValue = Number(row.quantity) * (row.unitValue ?? 0);
      const formattedTotalValue = totalValue.toFixed(2);
      return (
        <Typography align={'center'} width={'100%'} className="truncate-multiline">
          {row.unitValue ? formattedTotalValue : 0}
        </Typography>
      );
    },
    // align: 'right',
  },
  {
    id: 'actions',
    label: 'Action',
    maxWidth: 30,
    render: ({ row, ind, handleEdit, handleDelete, selectedProduct }: ProductRenderParamsType) => {
      return (
        <>
          <Grid item xs={6}>
            <IconButton
              onClick={() => handleEdit && handleEdit({ ...row, id: ind.toString() })}
              disabled={selectedProduct?.id ? selectedProduct.id === ind.toString() : false}
            >
              <ModeEditIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton
              onClick={() => handleDelete && handleDelete(ind)}
              disabled={selectedProduct?.id ? selectedProduct.id === ind.toString() : false}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </>
      );
    },
    // align: 'right',
  },
];

const TableWrapper = styled(Paper)`
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: none;
  border: 1px solid #d0d5dd;
  position: relative;
  z-index: 0;
`;

interface PropsType {
  title?: string;
  products?: ProductType[];
  enableActions: boolean;
  handleEdit?: (data: ProductType) => void;
  handleDelete?: (ind: number) => void;
  selectedProduct?: ProductType | null;
}

export default function ProductTable({
  title,
  products,
  enableActions,
  handleEdit,
  handleDelete,
  selectedProduct,
}: PropsType) {
  const [columnDefs, setColumnDefs] = useState<ProductColumn[]>(columns);

  useEffect(() => {
    if (!enableActions) {
      setColumnDefs(columnDefs.filter(col => col.id !== 'actions'));
    }
  }, [enableActions]);

  return (
    <>
      {title && <Typography className="md-text font-medium"> {title}</Typography>}
      <TableWrapper className="my-3">
        <TableContainer sx={{ maxHeight: 400 }} id={'fadedScroll'}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                background: 'var(--mui-palette-primary-main)',
              }}
            >
              <TableRow>
                <TableCell
                  className="font-semibold md-text"
                  style={{
                    background: 'var(--mui-palette-primary-main)',
                    color: 'var(--mui-palette-primary-contrastText)',
                    borderRight: '1px solid var(--mui-palette-primary-contrastText)',
                  }}
                >
                  Sr. No.
                </TableCell>
                {columnDefs.map(column => (
                  <TableCell
                    className="font-semibold md-text"
                    key={column.id}
                    // align={column.align}
                    style={{
                      background: 'var(--mui-palette-primary-main)',
                      color: 'var(--mui-palette-primary-contrastText)',
                      borderRight: '1px solid var(--mui-palette-primary-contrastText)',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((row, ind) => {
                return (
                  <TableRow key={`product-${ind}`} hover role="checkbox" tabIndex={-1}>
                    <TableCell key={`sr_no_${ind}`}>
                      <Typography align="center">{ind + 1}</Typography>
                    </TableCell>
                    {
                      // row.quantity &&
                      columnDefs.map(column => {
                        const value = row[column.id];
                        const displayValue = (typeof value === 'number' && column?.format?.(value)) || value;
                        return (
                          <TableCell key={column.id} sx={{ maxWidth: column.maxWidth, px: 0 }}>
                            <Grid container height={70} alignItems="center" borderLeft="1px solid #E7E7E7" px={2}>
                              {column?.render ? (
                                column.render({ row, ind, handleEdit, handleDelete, selectedProduct })
                              ) : (
                                <Typography
                                  align={column.align || 'center'}
                                  width={'100%'}
                                  className="truncate-multiline"
                                >
                                  {displayValue}
                                </Typography>
                              )}
                            </Grid>
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </>
  );
}
