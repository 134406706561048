import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Menu, MenuItem, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';

// import { AppDispatch, RootStateType } from '../../store';
import SbButton from '@Components/Button';
// import ExportBtn from '@Components/Button/Export';
import Image from '@Components/Image';
import Title from '@Components/Title';
import Account from './Account';
import NonAccount from './NonAccount';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { isActionAllowed } from '../../utils/allowedActions';
import { AppDispatch, RootStateType } from '@Store';
import Loader from '@Components/Loader';
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import BulkPaymentUpload from '../ShipmentHistory/BulkStatusUpload/BulkPaymentUpload';
// import { resetInvoiceState } from '@Reducers/InvoiceList';
import { fetchAccountInvoice, fetchNonAccountInvoice } from '@Reducers/invoiceSliceReport/invoiceSliceReport';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';

const ButtonContainers = styled('div')(() => ({
  marginTop: '2rem',
  bottom: '-3rem',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',
  position: 'relative',

  '& > .action-buttons': {
    display: 'flex',
    gap: '1rem',
  },

  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px',

    '& > .bulk-payment-button': {
      width: '100%',
    },

    '& > .action-buttons': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      gap: '1rem',
    },

    '& > .action-buttons > button': {
      flex: '1',
      maxWidth: 'calc(50% - 0.5rem)',
    },
  },
}));

interface DateRange {
  startDate: string;
  endDate: string;
}

export default function ViewAndCreateInvoice() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<number>(0);
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null);
  const { loading } = useSelector((state: RootStateType) => state.shipmentHistory);
  const { limit } = useSelector((state: RootStateType) => state.notifications);

  // const [selectedOptions, setSelectedOptions] = useState({});
  // const [loading, setLoading] = useState<boolean>(false);
  const [accountDateRange, setAccountDateRange] = useState<DateRange>({
    startDate: '',
    endDate: '',
  });
  const [nonAccountDateRange, setNonAccountDateRange] = useState<DateRange>({
    startDate: '',
    endDate: '',
  });

  const [accountSystem, setAccountSystem] = useState<string[]>([]);
  const [accountType, setAccountType] = useState<string[]>([]);
  const [accountStatus, setAccountStatus] = useState<string[]>([]);

  const [nonAccountSystem, setNonAccountSystem] = useState<string[]>([]);
  const [nonAccountType, setNonAccountType] = useState<string[]>([]);
  const [nonAccountStatus, setNonAccountStatus] = useState<string[]>([]);
  const [showBulkStatusModal, setShowBulkStatusModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  // const convertToDDMMYYYY = (isoDate: string): string => {
  //   const date = new Date(isoDate);
  //   const day = String(date.getUTCDate()).padStart(2, '0');
  //   const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  //   const year = date.getUTCFullYear();

  //   return `${day}/${month}/${year}`;
  // };

  // console.info('accountnonAccountDateRangeDateRange:', nonAccountDateRange);

  const options = [
    { value: 'summary', label: 'Summary' },
    { value: 'extensive', label: 'Extensive' },
  ];

  // const [searchParams] = useSearchParams();
  // const selectedTab = searchParams.get('tab');

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const { isAccountHolder, invoiceNumber } = useSelector((state: RootStateType) => state.adminShipment);

  const pageTypeActionsMap = {
    0: {
      upload_invoices: ACTION_NAMES.View_Invoices_Account_holder.upload_invoices,
      create_invoices: ACTION_NAMES.View_Invoices_Account_holder.create_invoices,
      download_report: ACTION_NAMES.View_Invoices_Account_holder.download_report,
    },
    1: {
      upload_invoices: ACTION_NAMES.View_Invoices_Non_Account_holder.upload_invoices,
      create_invoices: ACTION_NAMES.View_Invoices_Non_Account_holder.create_invoices,
      download_report: ACTION_NAMES.View_Invoices_Non_Account_holder.download_report,
    },
  };

  const isUploadInvoiceAllowed = isActionAllowed(
    accessGroupById?.actions,
    pageTypeActionsMap[tabValue as 0 | 1]?.upload_invoices,
    PERFORM_ACTION.write,
  );
  // const isExportAllowed = isActionAllowed(
  //   accessGroupById?.actions,
  //   pageTypeActionsMap[tabValue as 0 | 1]?.upload_invoices,
  //   PERFORM_ACTION.write,
  // );
  const isCreateAllowed = isActionAllowed(
    accessGroupById?.actions,
    pageTypeActionsMap[tabValue as 0 | 1]?.upload_invoices,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    if (invoiceNumber !== null) {
      if (isAccountHolder) {
        setTabValue(0);
      } else {
        setTabValue(1);
      }
    }
  }, [invoiceNumber, isAccountHolder]);

  // useEffect(() => {
  //   if (selectedTab) {
  //     if (selectedTab !== 'non-account') {
  //       setTabValue(0);
  //     } else {
  //       setTabValue(1);
  //     }
  //   }
  // }, [selectedTab]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('open menu');
    setAnchor(event.currentTarget);
  };
  const openExportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('open menu');
    setExportAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchor(null);
  };
  const closeExportMenu = () => {
    setExportAnchor(null);
  };

  const onMenuItemClick = (page: string) => {
    setAnchor(null);
    let obj = {};
    if (!tabValue) {
      const preStr = '/invoice/accountholder/create';
      obj = { invoice: `${preStr}invoice`, debitnote: `${preStr}debitnote`, creditnote: `${preStr}creditnote` };
    } else {
      const preStr = '/invoice/non-account/create';
      obj = { invoice: `${preStr}invoice`, debitnote: `${preStr}debitnote`, creditnote: `${preStr}creditnote` };
    }

    let url = '';
    const urlObj: { [key: string]: string } = { ...obj };
    url = urlObj[page];
    if (url) {
      navigate(url);
    }
  };

  const onExportMenuItemClick = async (page: string) => {
    setExportAnchor(null);
    if (!tabValue) {
      try {
        setIsLoading(true);
        await dispatch(
          fetchAccountInvoice({
            currentPage: 1,
            perPage: 10,
            fromDate: accountDateRange?.startDate,
            toDate: accountDateRange?.endDate,
            systems: accountSystem,
            status: accountStatus,
            type: accountType,
            isAccountHolder: true,
            downloadType: page,
          }),
        );
        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Download in Progress',
            subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
            btnText: 'Close',
            onConfirm: () => {
              dispatch(toggleBoolean());
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
      } catch (error) {
        console.error('Error:', error);
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Something went wrong',
            subTitle: 'please try after sometimes',
          }),
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        await dispatch(
          fetchNonAccountInvoice({
            currentPage: 1,
            perPage: 10,
            fromDate: nonAccountDateRange.startDate,
            toDate: nonAccountDateRange.endDate,
            isAccountHolder: false,
            systems: nonAccountSystem,
            status: nonAccountStatus,
            type: nonAccountType,
            downloadType: page,
          }),
        );

        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Download in Progress',
            subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
            btnText: 'Close',
            onConfirm: () => {
              dispatch(toggleBoolean());
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
      } catch (error) {
        console.error('Error:', error);
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Something went wrong',
            subTitle: 'please try after sometimes',
          }),
        );
      } finally {
        setIsLoading(false);
      }
    }
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const subTitleText = 'View and create invoices.';

  return (
    <>
      <Loader loading={loading || isLoading} overly />

      <Title title="Invoices" subTitle={subTitleText} removeBorder />
      <>
        <ButtonContainers>
          <SbButton
            startIcon={<Image src={'/images/file_upload.svg'} alt="upload" />}
            className="border-1px border-light bulk-payment-button"
            sx={{ borderRadius: '8px !important', minWidth: '225px' }}
            onClick={() => {
              setShowBulkStatusModal(true);
            }}
            variant="outlined"
            disabled={!isUploadInvoiceAllowed}
          >
            Bulk Payments
          </SbButton>

          <div className="action-buttons">
            <SbButton
              variant="contained"
              sx={{ borderRadius: '8px !important', padding: '8px !important', width: '120px' }}
              onClick={openExportMenu}
              endIcon={<ArrowDropDownCircleOutlined />}
            >
              Export
            </SbButton>
            <Menu
              open={Boolean(exportAnchor)}
              anchorEl={exportAnchor}
              onClose={closeExportMenu}
              keepMounted
              PaperProps={{
                sx: {
                  width: '150px',
                },
              }}
            >
              {options.map(option => (
                <MenuItem onClick={_ => onExportMenuItemClick(option.value)} key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Menu>

            <SbButton
              className="xs-text"
              variant="contained"
              sx={{ borderRadius: '8px !important', padding: '8px !important' }}
              onClick={openMenu}
              endIcon={<ArrowDropDownIcon />}
              disabled={!isCreateAllowed}
            >
              Create New
            </SbButton>
            <Menu
              open={Boolean(anchor)}
              anchorEl={anchor}
              onClose={closeMenu}
              keepMounted
              PaperProps={{
                sx: {
                  width: '135px',
                },
              }}
            >
              <MenuItem onClick={_ => onMenuItemClick('invoice')}>Invoice</MenuItem>
              <MenuItem onClick={_ => onMenuItemClick('debitnote')}>Debit Note</MenuItem>
              <MenuItem onClick={_ => onMenuItemClick('creditnote')}>Credit Note</MenuItem>
            </Menu>
          </div>
        </ButtonContainers>

        <BulkPaymentUpload open={showBulkStatusModal} handleClose={() => setShowBulkStatusModal(false)} />
      </>
      {isSmallScreen ? <br /> : <></>}
      <Box>
        <Box width={isSmallScreen ? '100%' : '50%'}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Account Holders" {...a11yProps(0)} />
            <Tab label="Non-Account Holders" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Account
            setAccountDateRange={setAccountDateRange}
            setAccountSystem={setAccountSystem}
            setAccountType={setAccountType}
            setAccountStatus={setAccountStatus}
          />
          {/* Tab 1 */}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <NonAccount
            setNonAccountDateRange={setNonAccountDateRange}
            setNonAccountSystem={setNonAccountSystem}
            setNonAccountType={setNonAccountType}
            setNonAccountStatus={setNonAccountStatus}
          />
        </TabPanel>
      </Box>
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: { fontSize: '1rem', fontWeight: '600' },
  };
}
