import { createSlice } from '@reduxjs/toolkit';
import {
  getInvoiceListFulfilled,
  getInvoiceListPending,
  getInvoiceListRejected,
  // getNonAccountUnbilledListFulfilled,
  fetchInvoiceListPending,
  fetchInvoiceListFulfilled,
  fetchInvoiceListRejected,
  fetchNonAccountInvoiceListFulfilled,
  // fulfilled,
} from './case';
import { InvoiceListState } from './Type';
import {
  getInvoiceList,
  // getNonAccountUnbilledList,
  fetchUserInvoiceList,
  fetchInvoiceList,
  fetchNonAccountInvoiceList,
  createPaymentByCash,
  completePaymentByCashByHash,
  getAccountUnbilledDebitList,
  getAccountUnbilledCreditList,
  getNonAccountUnbilledCreditList,
  getNonAccountUnbilledDebitList,
} from './actions';

const initialState = <InvoiceListState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },
  nonAccountData: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },
  openPaymentModal: '',
  unBilledList: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },
};

const userSlice = createSlice({
  name: 'userInvoice',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetInvoiceState: function (state) {
      state = { ...state, ...initialState };
    },
    togglePaymentModal: function (state, actions) {
      state.openPaymentModal = actions.payload;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getInvoiceList.pending, getInvoiceListPending);
    addCase(getInvoiceList.fulfilled, getInvoiceListFulfilled);
    addCase(getInvoiceList.rejected, getInvoiceListRejected);

    addCase(getAccountUnbilledDebitList.pending, fetchInvoiceListPending);
    addCase(getAccountUnbilledDebitList.fulfilled, getInvoiceListFulfilled);
    addCase(getAccountUnbilledDebitList.rejected, fetchInvoiceListRejected);

    addCase(getAccountUnbilledCreditList.pending, fetchInvoiceListPending);
    addCase(getAccountUnbilledCreditList.fulfilled, getInvoiceListFulfilled);
    addCase(getAccountUnbilledCreditList.rejected, fetchInvoiceListRejected);

    addCase(getNonAccountUnbilledCreditList.pending, getInvoiceListPending);
    addCase(getNonAccountUnbilledCreditList.fulfilled, getInvoiceListFulfilled);
    addCase(getNonAccountUnbilledCreditList.rejected, getInvoiceListRejected);

    addCase(getNonAccountUnbilledDebitList.pending, getInvoiceListPending);
    addCase(getNonAccountUnbilledDebitList.fulfilled, getInvoiceListFulfilled);
    addCase(getNonAccountUnbilledDebitList.rejected, getInvoiceListRejected);

    // addCase(getNonAccountUnbilledList.pending, getInvoiceListPending);
    // addCase(getNonAccountUnbilledList.fulfilled, getNonAccountUnbilledListFulfilled);
    // addCase(getNonAccountUnbilledList.rejected, getInvoiceListRejected);

    addCase(fetchUserInvoiceList.pending, fetchInvoiceListPending);
    addCase(fetchUserInvoiceList.fulfilled, fetchInvoiceListFulfilled);
    addCase(fetchUserInvoiceList.rejected, fetchInvoiceListRejected);

    addCase(fetchInvoiceList.pending, fetchInvoiceListPending);
    addCase(fetchInvoiceList.fulfilled, fetchInvoiceListFulfilled);
    addCase(fetchInvoiceList.rejected, fetchInvoiceListRejected);

    addCase(fetchNonAccountInvoiceList.pending, fetchInvoiceListPending);
    addCase(fetchNonAccountInvoiceList.fulfilled, fetchNonAccountInvoiceListFulfilled);
    addCase(fetchNonAccountInvoiceList.rejected, fetchInvoiceListRejected);

    addCase(createPaymentByCash.pending, fetchInvoiceListPending);
    // addCase(createPaymentByCash.fulfilled, fulfilled);
    addCase(createPaymentByCash.rejected, fetchInvoiceListRejected);

    addCase(completePaymentByCashByHash.pending, fetchInvoiceListPending);
    // addCase(completePaymentByCashByHash.fulfilled, fulfilled);
    addCase(completePaymentByCashByHash.rejected, fetchInvoiceListRejected);
  },
});

const { resetInvoiceState, togglePaymentModal } = userSlice.actions;

export {
  getInvoiceList,
  // getNonAccountUnbilledList,
  fetchUserInvoiceList,
  fetchInvoiceList,
  fetchNonAccountInvoiceList,
  resetInvoiceState,
  togglePaymentModal,
};
export default userSlice.reducer;
