import React, { useEffect, useState } from 'react';
import { FormHelperText, FormLabel, Grid, MenuItem, Select, styled, useTheme } from '@mui/material';
import { InfoRounded, Language as LanguageIcon, MailOutline as MailOutlineIcon } from '@mui/icons-material';
import SbTextField from '../../../components/Textfield';
import Contact from '../../../components/Contact';
import GoogleMaps from '../../../components/Location';
import SbButton from '../../../components/Button';
import ColorPicker from '../../../components/ColorPicker';
import SbTooltip from '../../../components/Tooltip';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues } from 'formik';
import { OrganizationType, TaxInformationType } from './type';
import DragDrop, { DragDropProps } from '../../../components/File/DragDrop';
import { GoogleMapsTypes } from '../../../components/Location/Type';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';
import Image from '@Components/Image';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { lighten, darken } from 'polished';

interface FormProps {
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue?: FormikHelpers<OrganizationType>['setFieldValue'];
  errors?: FormikErrors<OrganizationType>;
  handleSelectedAddress: GoogleMapsTypes['onSelect'];
  onFileChange: DragDropProps['onChange'];
}

export const ContainerStyle = styled(Grid)(({ theme }) => ({
  padding: '20px 0',
  borderTop: `1px solid ${theme.palette.border.grey}`,
}));

function OrganizationSetting(props: FormProps) {
  const { values, errors, handleChange, setFieldValue, handleSelectedAddress, onFileChange } = props;
  const [taxName, setTaxName] = useState<string>('');
  const [taxValue, setTaxValue] = useState<string>('');
  const theme = useTheme();
  const [primaryColor, setPrimaryColor] = useState<string>(values.theme.primary.main || theme.palette.primary.main);
  const [secondaryColor, setSecondaryColor] = useState<string>(
    values.theme.secondary.main || theme.palette.secondary.main,
  );
  const { countriesObj, countries } = useSelector((state: RootStateType) => state.global);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isGeneralWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Organization_Settings.General,
    PERFORM_ACTION.write,
  );

  const addTaxInfo = () => {
    if (!setFieldValue) return;

    setFieldValue('taxInformation', [...values.taxInformation, { name: taxName, value: taxValue }]);
    setTaxName('');
    setTaxValue('');
  };
  const countryName = countriesObj?.[values.contactInfo.address.country]?.name || '';

  useEffect(() => {
    const theme = {
      primary: {
        main: primaryColor,
        dark: darken(0.2, primaryColor),
        light: lighten(0.2, primaryColor),
        contrastText: '#fff',
      },
      secondary: {
        main: secondaryColor,
        light: lighten(0.2, secondaryColor),
      },
    };
    if (setFieldValue) {
      setFieldValue('theme', theme);
    } else {
      console.warn('setFieldValue is undefined');
    }
  }, [primaryColor, secondaryColor]);

  useEffect(() => {
    const theme = {
      primary: {
        main: primaryColor,
        dark: darken(0.2, primaryColor),
        light: lighten(0.2, primaryColor),
        contrastText: '#fff',
      },
      secondary: {
        main: secondaryColor,
        light: lighten(0.2, secondaryColor),
      },
    };
    if (setFieldValue) {
      setFieldValue('theme', theme);
    } else {
      console.warn('setFieldValue is undefined');
    }
  }, [primaryColor, secondaryColor]);

  return (
    <div>
      {/* ORG NAME----------------------------------------------------------------------------------------------------------------- */}
      <ContainerStyle container alignItems="center">
        <Grid item xs={12} lg={4} xl={3} mb={{ xs: 1 }}>
          <SBLabel
            label="Organization Name"
            required
            tooltipText="Your organization name visible to your customers & staff while using dashboard"
          />
        </Grid>
        <Grid container item xs={12} md={6} lg={4}>
          <Grid item xs={12}>
            <SbTextField
              name="name"
              placeholder="Enter Organization Name"
              value={values.name}
              onChange={handleChange}
              error={errors?.name || ''}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
        </Grid>
      </ContainerStyle>
      {/* EMAIL----------------------------------------------------------------------------------------------------------------- */}
      <ContainerStyle container alignItems="center">
        <Grid item xs={12} lg={4} xl={3} mb={{ xs: 1 }}>
          <SBLabel
            label="Organization Email"
            required
            tooltipText="This email will be visible on your dashboard & Log in Page"
          />
        </Grid>
        <Grid container item xs={12} md={6} lg={4}>
          <Grid item xs={12}>
            <SbTextField
              placeholder="Enter Organization Email"
              value={values.contactInfo.email}
              name={'contactInfo.email'}
              startAdornment={<MailOutlineIcon sx={{ mr: 1 }} />}
              error={errors?.contactInfo?.email || ''}
              onChange={handleChange('contactInfo.email')}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
        </Grid>
      </ContainerStyle>
      {/* SUPPORT EMAIL----------------------------------------------------------------------------------------------------------------- */}

      <ContainerStyle container alignItems="center">
        <Grid item xs={12} lg={4} xl={3} mb={{ xs: 1 }}>
          <SBLabel
            label="Organization Support Email"
            required
            tooltipText="This email will be used to send user onboarding invitation"
          />
        </Grid>
        <Grid container item xs={12} md={6} lg={4}>
          <Grid item xs={12}>
            <SbTextField
              placeholder="Enter Organization Support Email"
              name="contactInfo.supportEmail"
              value={values.contactInfo.supportEmail}
              startAdornment={<MailOutlineIcon sx={{ mr: 1 }} />}
              error={errors?.contactInfo?.supportEmail || ''}
              onChange={handleChange('contactInfo.supportEmail')}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
        </Grid>
      </ContainerStyle>
      {/* CONTACT----------------------------------------------------------------------------------------------------------------- */}

      <ContainerStyle container alignItems="center">
        <Grid item xs={12} lg={4} xl={3} mb={{ xs: 1 }}>
          <SBLabel
            label="Organization Contact"
            required
            tooltipText="This email will be used to send user onboarding invitation"
          />
        </Grid>
        <Grid container item xs={12} md={6} lg={4}>
          <Grid item xs={12}>
            <Contact
              phoneName="contact_number"
              phoneNumber={values.contactInfo.contactNumber}
              onChangePhoneNumber={handleChange('contactInfo.contactNumber')}
              telCountryCodeName="contactInfo.contactNumber"
              telCountryCode={values.contactInfo.telephoneCode}
              onChangeTelCountryCode={event => {
                if (!setFieldValue) return;
                setFieldValue('contactInfo.telephoneCode', event.target.value);
              }}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
        </Grid>
      </ContainerStyle>
      {/* ADDRESS----------------------------------------------------------------------------------------------------------------- */}

      <ContainerStyle container alignItems="start">
        <Grid item xs={12} lg={4} xl={3} mb={{ xs: 1 }}>
          <SBLabel
            label="Organization address"
            tooltipText="This address will be visible on your dashboard & Log in Page"
          />
        </Grid>
        <Grid container item xs={12} lg={8} xl={9} spacing={3}>
          <Grid item xs={12}>
            <GoogleMaps
              onSelect={handleSelectedAddress}
              label={'Location'}
              placeholder="Enter location"
              // reinitialize={reinitialize}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <SbTextField
              className="w-full"
              label="Address Line 1"
              placeholder="Enter Address Line 1"
              value={values.contactInfo.address.line1}
              onChange={handleChange('contactInfo.address.line1')}
              name="contactInfo.address.line1"
              error={errors?.contactInfo?.address?.line1 || ''}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <SbTextField
              className="w-full"
              label="Address Line 2"
              placeholder="Enter Address Line 2"
              value={values.contactInfo.address.line2}
              onChange={handleChange('contactInfo.address.line2')}
              name="contactInfo.address.line2"
              error={errors?.contactInfo?.address?.line2 || ''}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <SbTextField
              className="w-full"
              label="City"
              placeholder="Enter City"
              value={values.contactInfo.address.city}
              onChange={handleChange('contactInfo.address.city')}
              name="contactInfo.address.city"
              error={errors?.contactInfo?.address?.city || ''}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <SbTextField
              className="w-full"
              label="State"
              placeholder="Enter State"
              value={values.contactInfo.address.state}
              onChange={handleChange('contactInfo.address.state')}
              name="contactInfo.address.state"
              error={errors?.contactInfo?.address?.state || ''}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <SbTextField
              required
              disabled
              className="w-full"
              label="Country"
              placeholder="Enter Country"
              name="contactInfo.address.country"
              value={countryName}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <SbTextField
              className="w-full"
              label="Pincode"
              placeholder="Enter Pincode"
              value={values.contactInfo.address.pincode}
              onChange={handleChange('contactInfo.address.pincode')}
              name="contactInfo.address.pincode"
              error={errors?.contactInfo?.address?.pincode || ''}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
        </Grid>
      </ContainerStyle>
      {/* TAX INFO----------------------------------------------------------------------------------------------------------------- */}

      <ContainerStyle container alignItems="start">
        <Grid item xs={12} lg={4} xl={3} mb={{ xs: 1 }}>
          <SBLabel label="Tax Information" tooltipText="Add your company tax information like GSTIN & PAN" />
        </Grid>
        <Grid container item xs={12} lg={8} xl={9} spacing={3}>
          <Grid container item alignItems="center" spacing={3}>
            {values.taxInformation.map((taxInfo: TaxInformationType, index: number) => (
              <React.Fragment key={`org-company-tax-info-${index}`}>
                <Grid item xs={6} sm={6} md={6} lg={5}>
                  {taxInfo.name}
                </Grid>

                <Grid item xs={6} sm={6} md={6} lg={5}>
                  {taxInfo.value}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
          <Grid container item alignItems="flex-end" spacing={3}>
            <Grid container item xs={6} sm={6} md={6} lg={5}>
              <Grid item xs={12}>
                <SbTextField
                  placeholder="Tax Name"
                  // label="Tax name"
                  value={taxName}
                  onChange={event => setTaxName(event.target.value)}
                  name="taxName"
                  // error={errors.line1}
                  disabled={!isGeneralWriteAllowed}
                />
              </Grid>
            </Grid>
            <Grid container item xs={6} sm={6} md={6} lg={5}>
              <Grid item xs={12}>
                <SbTextField
                  placeholder="Tax Number"
                  // label="Tax number"
                  value={taxValue}
                  onChange={event => setTaxValue(event.target.value)}
                  name="taxNumber"
                  // error={errors.line1}
                  disabled={!isGeneralWriteAllowed}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={5}
              sm={3}
              md={2}
              alignItems="end"
              justifyContent={{ xs: 'flex-end', lg: 'flex-end' }}
            >
              <SbButton
                disabled={taxName.trim() && taxValue.trim() ? false : true}
                variant="contained"
                className="w-full"
                onClick={addTaxInfo}
              >
                Add
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </ContainerStyle>
      {/* ----------------------------------------------------------------------------------------------------------------- */}

      {/* <ContainerStyle container alignItems="center">
        <Grid item xs={12} lg={4} xl={3}>
          <SBLabel label="First User" tooltipText="The first user - Admin on the Speedbox Dashboard" />
        </Grid>
        <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
          <Grid item xs={12} md={11}>
            <SbTextField
              name="firstUserEmail"
              value={values.firstUserEmail}
              startAdornment={<MailOutlineIcon sx={{ mr: 1 }} />}
              disabled
              error={errors?.firstUserEmail || ''}
              //   onChange={handleChange}
            />
          </Grid>
        </Grid>
      </ContainerStyle> */}
      {/* ----------------------------------------------------------------------------------------------------------------- */}

      <ContainerStyle container alignItems="center">
        <Grid item xs={12} lg={4} xl={3}>
          <SBLabel label="Website Link" tooltipText="Website link of the organization if any" />
        </Grid>
        <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
          <Grid item xs={12} md={11}>
            <SbTextField
              name="websiteLink"
              placeholder="Enter Website Link"
              value={values.websiteLink}
              startAdornment={<LanguageIcon sx={{ mr: 1 }} />}
              onChange={handleChange}
              error={errors?.websiteLink || ''}
              disabled={!isGeneralWriteAllowed}
            />
          </Grid>
        </Grid>
      </ContainerStyle>
      <ContainerStyle container alignItems="center">
        <Grid item xs={12} lg={4} xl={3}>
          <SBLabel label="Select Currency" tooltipText="Select currency for the organization" />
        </Grid>
        <Grid container item xs={12} md={6} lg={4} alignItems="center" justifyContent={{ xs: 'space-between' }}>
          <Grid item xs={12} md={11}>
            <Select
              sx={{ width: '100%' }}
              name="currency"
              value={values.currency || ''}
              displayEmpty
              onChange={handleChange}
            >
              <MenuItem value="" disabled>
                Select a currency
              </MenuItem>
              {countries.map((item, ind) => (
                <MenuItem key={ind} value={item.currency.name}>
                  {item.currency.name} ({item.currency.symbol})
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </ContainerStyle>
      {/* ----------------------------------------------------------------------------------------------------------------- */}

      <ContainerStyle container alignItems="start">
        <Grid item xs={12} lg={4} xl={3}>
          <SBLabel label="Logo" />
        </Grid>
        <Grid container item xs={12} lg={8} xl={9} alignItems="center" justifyContent={{ xs: 'space-between' }}>
          <Grid item xs={12} md={11}>
            {values.logo ? (
              <Grid container position="relative">
                <Image src={values.logo} alt={values.name} width={100} style={{ maxHeight: '200px' }} />
                {isGeneralWriteAllowed && (
                  <span
                    // className="absolute cursor-pointer"
                    style={{ top: 2 }}
                    onClick={() => {
                      props.setFieldValue && props.setFieldValue('logo', '');
                    }}
                  >
                    <DeleteOutlineIcon />
                  </span>
                )}
              </Grid>
            ) : (
              <DragDrop
                accept="image/*"
                onChange={onFileChange}
                subText={
                  values.logoFile && typeof values.logoFile === 'object'
                    ? `${values.logoFile.fileName} (${values.logoFile.fileSize})`
                    : 'Supported formats: JPEG, PNG (450x225px, File upto 5 mb)'
                }
                // subText="Supported formats: JPEG, PNG (450x225px, File upto 5 mb)"
                readType="base64"
              />
            )}
            {errors?.logoFile && (
              <FormHelperText error className="m-0">
                {errors.logoFile}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </ContainerStyle>
      {/* ----------------------------------------------------------------------------------------------------------------- */}

      <ContainerStyle container alignItems="center">
        <Grid item xs={12} lg={4} xl={3}>
          <SBLabel label="Theme color" />
        </Grid>
        <Grid container item xs={12} lg={8} xl={9} alignItems="center" justifyContent={{ xs: 'space-between' }}>
          <Grid item xs={12} md={11}>
            <ColorPicker
              setValue={setPrimaryColor}
              value={primaryColor}
              disabled={false}
              sx={{ mr: { md: 3, xs: 0 }, mb: { md: 0, xs: 3 } }}
            />
            <ColorPicker value={secondaryColor} setValue={setSecondaryColor} disabled={false} />
          </Grid>
        </Grid>
      </ContainerStyle>
    </div>
  );
}

function SBLabel({ label, tooltipText, required }: { label: string; tooltipText?: string; required?: boolean }) {
  return (
    <Grid container alignItems={'center'} className="title">
      <FormLabel required={required} className="font-medium md-text mr-1">
        {label}
      </FormLabel>
      {tooltipText && (
        <SbTooltip placement="right" title={tooltipText}>
          <InfoRounded />
        </SbTooltip>
      )}
    </Grid>
  );
}

export default OrganizationSetting;
