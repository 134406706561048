import { Box, Dialog, Grid, InputLabel, styled } from '@mui/material';
import React, { useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SbTextField from '@Components/Textfield';
import SbButton from '@Components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { createAccessGroup } from '@Reducers/AccessGroup/actions';
import { resetPageAndActionIds } from '@Reducers/AccessGroup';
import { useNavigate } from 'react-router';

export interface InputModalProps {
  open: boolean;
  onClose: () => void;
}

const DialogContainer = styled(Grid)({
  maxWidth: '540px',
});

const DialogContent = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
}));

const Header = styled(Grid)`
  background: var(--mui-palette-border-main);
  height: 89px;

  div {
    width: 130px;
    height: 130px;
    background: var(--mui-palette-border-main);
    border-radius: 50%;
    margin-top: 10px;
    color: var(--mui-palette-primary-contrastText);
  }
  img {
    height: 45px;
    width: 45px;
  }
`;

const InputModal = ({ open, onClose }: InputModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { pageIds, actionsIds } = useSelector((state: RootStateType) => state.accessGroup);
  const { profile } = useSelector((state: RootStateType) => state.loginUser);
  const [input, setInput] = useState<string>('');

  const CodeName = (name: string): string => {
    // Convert to lowercase and split by space
    const words = name.toLowerCase().split(' ');
    // Join words with underscore
    const result = words.join('_');
    return result;
  };

  const handleCreateAccessGroup = () => {
    const actionsIdsArr = actionsIds.map(({ _id, selectedActions }) => ({ _id, selectedActions }));
    const payload = {
      name: input,
      pagesId: pageIds,
      code: CodeName(input),
      actions: actionsIdsArr,
      parentAccessGroupCode: null,
      // createdBy: token ? token.name : '',
      createdBy: profile ? profile.username : '',
    };
    dispatch(
      createAccessGroup({
        payload,
        next: () => {
          navigate('/access-group');
        },
      }),
    );
  };

  const handleSave = () => {
    handleCreateAccessGroup();
    setInput('');
    onClose();
    dispatch(resetPageAndActionIds());
    // navigate('/access-group');
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
  };

  return (
    <>
      <Dialog maxWidth="lg" open={open} onClose={onClose} scroll={'paper'}>
        <DialogContainer container justifyContent="flex-end">
          <Header container item justifyContent="center">
            <img
              src="/images/icons/crossIcon.svg"
              alt="cross icon"
              onClick={onClose}
              style={{ width: '1.5rem', position: 'absolute', right: '0.5rem', top: '0.3rem', cursor: 'pointer' }}
            />
            <Grid container item justifyContent="center" alignItems="center">
              {/* <img scr={} /> */}
              <Box
                sx={{
                  backgroundColor: 'white !important',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '3rem !important',
                  height: '4rem !important',
                }}
              >
                <CheckCircleIcon
                  className=" sb-text-success "
                  sx={{
                    fontSize: '5rem',
                  }}
                />
              </Box>
            </Grid>
          </Header>
          <DialogContent container item justifyContent="center" alignItems="center" flexDirection="column">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel className="font-medium md-text" sx={{ display: 'flex', justifyContent: 'center' }}>
                  Enter Template Name
                </InputLabel>
              </Grid>
              <Grid item xs={12}>
                <SbTextField
                  placeholder="Operrations 1"
                  label="Template Name"
                  name="altContactName"
                  onChange={handleInput}
                  value={input}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <SbButton onClick={handleSave} variant="contained">
                  Save
                </SbButton>
              </Grid>
            </Grid>
          </DialogContent>
        </DialogContainer>
      </Dialog>
    </>
  );
};

export default InputModal;
