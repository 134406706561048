import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import dayJs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';

import Title from '@Components/Title';
import Divider from '@Components/Divider';
import { FormLabel, FormContent } from '@Components/Form';

import SbTextField from '@Components/Textfield';

import RadioButton from '@Components/RadioButton';
import DatePicker from '@Components/DatePicker';

import { AppDispatch, RootStateType } from '@Store';
import { getActiveSystemList } from '@Reducers/Systems';
import { GetSingleRateObj, UpdateMarkup, UpdateZone, resetError } from '@Reducers/RateUpload';
import { getServiceList } from '@Reducers/CarrierAndService';
import { GetSingleRatesParams, GetSingleRates, Zone, UpdateZoneRate } from '@Reducers/RateUpload/Type';

import { editRateSchema } from './schema';
import { CreateRatePropsType } from '../type';
import SbButton from '@Components/Button';
import { APPLY_FOR_OPTIONS, RatesForTypeList, RatesTypeList, initGetRates, pageConfig } from '../constant';

import SuccessError from '../SucessError';
import Loader from '@Components/Loader';

import CSP from './csp';
import System from './System';
import useAccountLookup from '@Hook/useAccountLookup';
import MarkupUpload from './MarkupUpload';
import SheetUpload from './SheetUpload';
import ApplyForRates from './ApplyForRates';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from '@Components/BackButton';

type GetSingleRatesParamsKeys = keyof GetSingleRatesParams;

const booleanParamKeys = {
  document: true,
  isMarkup: true,
  isImport: true,
} as const;

function useQuery() {
  const { search } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const query: GetSingleRatesParams = {} as any;
  new URLSearchParams(search).forEach((value: string, key: GetSingleRatesParamsKeys) => {
    if (key in booleanParamKeys) {
      const booleanKey = key as keyof typeof booleanParamKeys;
      query[booleanKey] = value === 'true';
    } else {
      const stringKey = key as Exclude<GetSingleRatesParamsKeys, keyof typeof booleanParamKeys>;
      query[stringKey] = value;
    }
  });

  return React.useMemo(() => ({ query, search }), [search]);
}

function CreateRate(props: CreateRatePropsType) {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const showBackButton = location.pathname.includes('/create') || location.pathname.includes('/edit');

  const link = location.pathname.startsWith('/pricing/base')
    ? '/pricing/base'
    : location.pathname.startsWith('/pricing/tariff')
    ? '/pricing/tariff'
    : '/pricing/contract';

  const modalId = React.useId();
  const copyRatesModalId = React.useId();

  const _pageConfig = pageConfig[props.rateFor];
  const showAccountNumber = _pageConfig.showAccountNumber;
  const { loading: AccountLookupLoading } = useAccountLookup(modalId, true);
  const { loading: CopyAccountLookupLoading } = useAccountLookup(copyRatesModalId, true);
  // const [rateTense, setRateTense] = useState<'past' | 'present' | 'future'>();

  const dispatch = useDispatch<AppDispatch>();
  const RateUpload = useSelector((state: RootStateType) => state.RateUpload);

  const [markup, setMarkup] = useState<number>(0);
  const [sheet, setSheet] = useState<Zone[]>([]);

  const [markupError, setMarkupError] = useState<string>('');
  const [sheetError, setSheetError] = useState<string>('');
  const [singleRateData, setSingleRateData] = useState<GetSingleRates>({ ...initGetRates });

  useEffect(() => {
    dispatch(getActiveSystemList());
    dispatch(getServiceList({}));
    const rateQuery = query.query;
    dispatch(GetSingleRateObj(rateQuery));
    return () => {
      dispatch(resetError());
    };
  }, []);

  /**
   * rateUploadType === 'sheet' && isMarkup:  zone wise
   * rateUploadType === 'sheet' && !isMarkup: sheet
   * rateUploadType === 'markup':  markup
   */
  useEffect(() => {
    if (RateUpload.singleRate) {
      const { rateUploadType, isMarkup } = query.query;
      const rateApplyFor =
        rateUploadType === 'sheet'
          ? isMarkup
            ? APPLY_FOR_OPTIONS[1].value
            : APPLY_FOR_OPTIONS[2].value
          : APPLY_FOR_OPTIONS[0].value;
      if (RateUpload.singleRate.percentage) setMarkup(RateUpload.singleRate.percentage);
      setSingleRateData({ ...RateUpload.singleRate, rateApplyFor });
    }
  }, [RateUpload.singleRate]);

  const onFormClear = () => {
    // formik.handleReset({ ...initGetRates });
    setMarkup(0);
    setSheet([]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...singleRateData },
    validationSchema: editRateSchema,

    onSubmit(values) {
      onCreate(values);
    },
    validateOnChange: false,
  });
  const { handleChange, setFieldValue, values: formikValues, errors } = formik;
  const showMarkup = _pageConfig.onlyMarkup ? false : formikValues.rateApplyFor === 'markup';

  const onCreate = (params: GetSingleRates) => {
    let isError = false;
    if (showMarkup && !markup) (isError = true), setMarkupError('Markup value is required.');
    // if (!showMarkup && !sheet.length) (isError = true), setSheetError('Sheet is required.');
    setSheetError('');

    if (isError) return;
    const isBase = props.pageType === 'base';
    const payload = {
      isImport: params.isImport,
      document: params.documentRates,
      system: params._systemId,
      csp: params._courierId,
      accountNumber: params.accountNumber || 'default' || null,
      startDate: params.startDate,
      endDate: params.endDate,
    };

    if (showMarkup) {
      const updateObj = {
        minWeight: Number(params.minWeight) || null,
        maxWeight: params.maxWeight || null,
        markup: markup !== RateUpload?.singleRate?.percentage ? markup : null,
        endDate: formikValues.endDate || undefined,
      };

      // unset endDate if not changed
      // if (new Date(payload.endDate).getTime() === new Date(formikValues.endDate).getTime())
      if (new Date(singleRateData.endDate).getTime() === new Date(formikValues.endDate).getTime())
        updateObj.endDate = undefined;

      dispatch(UpdateMarkup({ ...payload, endDate: singleRateData.endDate, markup: markup, updated: updateObj }));
    } else {
      const { isMarkup } = query.query;
      const updateObj: UpdateZoneRate['updated'] = {
        minWeight: Number(params.minWeight),
        maxWeight: params.maxWeight,
        isMarkup,
        endDate: formikValues.endDate || undefined,
      };
      if (isBase) payload.accountNumber = null;
      if (sheet.length) updateObj.sheet = { zone: sheet };

      // unset endDate if not changed
      if (new Date(singleRateData.endDate).getTime() === new Date(formikValues.endDate).getTime()) {
        updateObj.endDate = undefined;
      }
      // console.log({ updateObj: { ...payload, updateObj } });
      dispatch(UpdateZone({ ...payload, endDate: singleRateData.endDate, updated: updateObj }));
    }
  };

  return (
    <Loader overly loading={RateUpload.loading || AccountLookupLoading || CopyAccountLookupLoading}>
      <>
        <SuccessError navigateTo={_pageConfig.redirectBack} onFormClear={onFormClear} />
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          {showBackButton && <BackButton link={link} />}
          <Title title={_pageConfig.title} subTitle={_pageConfig.subTitle} />
        </Box>
        <Grid container className="p-3">
          <Typography variant="h6" className="underline">
            Update rates
          </Typography>
          <Divider className="my-2" />
          {/****************************** Account Lookup **************************************/}
          {showAccountNumber && (
            <Grid item container xs={12} alignItems="start">
              <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
                <FormLabel>Account Number*</FormLabel>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <FormContent>
                  <SbTextField
                    required
                    type="number"
                    name="AccountNumber"
                    value={formikValues.accountNumber ?? undefined}
                    // onChange={handleChange}
                    disabled
                    placeholder="Account Number"
                  />
                </FormContent>
              </Grid>
              <Divider className="my-2" />
            </Grid>
          )}
          {/****************************** Service **************************************/}
          <CSP
            onChange={([value]) => {
              setFieldValue('csp', value, false);
            }}
            value={[formikValues._courierId]}
            error={errors._courierId}
            disabled
          />
          {/****************************** System **************************************/}
          <System
            onChange={([value]) => {
              setFieldValue('system', value, false);
            }}
            value={[formikValues._systemId]}
            error={Array.isArray(errors._systemId) ? errors._systemId.join(',') : errors._systemId}
            disabled
          />
          {/****************************** Rates For **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Rates For*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <RadioButton
                  disabled
                  containerClass="w-full"
                  name="isImport"
                  defaultValue={true}
                  value={formikValues.isImport}
                  onChange={evt => {
                    setFieldValue('isImport', evt.target.value === 'true');
                  }}
                  list={RatesForTypeList}
                  sx={{ display: 'flex', columnGap: 10 }}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Rates Type **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Rates Type*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <RadioButton
                  disabled
                  containerClass="w-full"
                  name="type"
                  defaultValue={false}
                  value={formikValues.documentRates}
                  onChange={evt => {
                    setFieldValue('document', evt.target.value === 'true');
                  }}
                  list={RatesTypeList}
                  sx={{ display: 'flex', columnGap: 10 }}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Apply For **************************************/}
          {(props.rateFor === 'contract' || props.rateFor === 'tariff') && (
            <ApplyForRates
              disabled
              values={formikValues}
              setFieldValue={setFieldValue}
              errors={errors}
              rateFor={props.rateFor}
            />
          )}
          {/****************************** Start Date **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Start Date*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <DatePicker
                  disabled
                  inputFormat="DD MMM YYYY"
                  label=""
                  value={formikValues.startDate ? dayJs(formikValues.startDate) : undefined}
                  required
                  handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
                    setFieldValue('startDate', date.format(), false);
                  }}
                  error={errors.startDate}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** End Date **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>End Date*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <DatePicker
                  disabled={
                    !(
                      new Date(formikValues.startDate).getTime() <= new Date().getTime() &&
                      new Date().getTime() <= new Date(formikValues.endDate).getTime()
                    )
                  }
                  inputFormat="DD MMM YYYY"
                  label=""
                  value={formikValues.endDate ? dayJs(formikValues.endDate) : undefined}
                  required
                  handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
                    setFieldValue('endDate', date.format(), false);
                  }}
                  error={errors.endDate}
                  minDate={
                    new Date().getTime() > new Date(formikValues.startDate).getTime()
                      ? dayJs()
                      : dayJs(new Date(formikValues.startDate))
                  }
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Min Weight **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Min Weight</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <SbTextField
                  type="string"
                  required
                  name="minWeight"
                  placeholder="Min Weight (in Kg)"
                  value={formikValues.minWeight ?? undefined}
                  onChange={e => {
                    const { value } = e.target;
                    if (!value || /^\d*\.?\d{0,2}$/.test(value)) {
                      setFieldValue('minWeight', value);
                    }
                    // handleChange();
                  }}
                  error={errors?.minWeight}
                />
                {/* <SbTextField
                  type="number"
                  required
                  name="minWeight"
                  value={formikValues.minWeight ?? undefined}
                  onChange={handleChange}
                  error={errors?.minWeight}
                /> */}
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Max Weight **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Max Weight</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <SbTextField
                  required
                  type="number"
                  name="maxWeight"
                  value={formikValues.maxWeight ?? undefined}
                  onChange={handleChange}
                  error={errors?.maxWeight}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Markup **************************************/}
          {showMarkup && <MarkupUpload value={markup || 0} handleChange={setMarkup} error={markupError} />}
          {/****************************** Rate Sheet **************************************/}
          {!showMarkup && (
            <SheetUpload
              rateApplyFor={formikValues.rateApplyFor}
              handleChange={setSheet}
              error={sheetError}
              maxWeight={formikValues.maxWeight}
            />
          )}

          {/****************************** Actions **************************************/}

          <Grid container item mt={4} spacing={2} justifyContent="flex-end">
            <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
              <SbButton fullWidth variant="outlined" onClick={() => navigate(_pageConfig.redirectBack)}>
                Cancel
              </SbButton>
            </Grid>
            <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
              <SbButton
                fullWidth
                variant="contained"
                onClick={_event => {
                  formik.handleSubmit();
                }}
              >
                Update
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Loader>
  );
}

export default CreateRate;
