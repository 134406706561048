import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import VisibilityIcon from '@mui/icons-material/Visibility';

import SbTextField, { TextFieldProps } from '@Components/Textfield';
import SbButton from '@Components/Button';
import SbImage from '@Components/Image';
import Loader from '@Components/Loader';
import SuccessError from '@Components/Modal/SuccessError';
import LoginSideBanner from '@Components/Login';

import { AppDispatch, RootStateType } from '@Store';
import { forgotPassword, confirmForgotPassword } from '@Reducers/User';
import { doLoginWithOtp } from '@Reducers/LoginForm';

const LoginGrid = styled(Grid)({
  boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
  borderRadius: '20px 0px 0px 20px',
  zIndex: 1,
  backgroundColor: '#fff',
  width: '700px',
  minHeight: '100vh',
});

const ButtonContainer = styled(SbButton)({
  margin: '6px 0px',
  height: '48px',
  fontSize: '16px',
  letterSpacing: '0.46px',
  textTransform: 'capitalize',
});

const PContainer = styled('p')({
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: '600',
  marginTop: '25px',
});

interface ForgotPasswordStateFields {
  username: string;
  otp?: string;
  password?: string;
  repeatPassword?: string;
}

const ForgotPassword: React.FC = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const userState = useSelector((state: RootStateType) => state?.user);
  const { loading } = userState;

  const [state, setState] = useState<ForgotPasswordStateFields>({ username: '' });
  const [isOtpSent, setIsOtpSent] = useState(false);

  const handleSendOtp = () => {
    const { username } = state;
    if (!username) return;
    dispatch(forgotPassword({ username })).then((val: { type: string }) => {
      if (val.type === 'forgotPassword/fulfilled') setIsOtpSent(true);
    });
  };

  const confirmPassword = () => {
    const { username, otp, password } = state;

    dispatch(confirmForgotPassword({ username, otp, password })).then((res: { type: string }) => {
      if (res.type === 'confirmForgotPassword/fulfilled') {
        setIsOtpSent(false);
      }
    });
  };

  const handleInputChange =
    (name: keyof ForgotPasswordStateFields): TextFieldProps['onChange'] =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const newState = { ...state };
      newState[name] = value;

      setState({ ...newState });
    };
  const { username, otp, password, repeatPassword } = state;

  return (
    <Loader loading={loading} overly>
      <Grid container style={{ backgroundColor: 'transparent' }}>
        <LoginSideBanner />
        <LoginGrid item>
          <div style={{ padding: '10px' }}>
            <div style={{ maxWidth: '80%', margin: '0px auto', marginTop: '30px' }}>
              <SbImage src="/images/login/logo.png" alt="logo" style={{ display: 'block', margin: '0 auto' }} />
            </div>
            <br />
            <Grid container>
              <Grid item xs={1}></Grid>

              <Grid item xs={10} style={{ border: '1px solid rgba(0, 0, 0, 0.1)', padding: '40px 40px 32px' }}>
                <div style={{ textAlign: 'center' }}>
                  <h1>Reset Password</h1>
                  <p>An OTP will be sent to your Email ID/Phone no. to recover your account.</p>
                </div>
                <br />
                <br />
                {/* <SendOtp username={username} handleInputChange={handleInputChange} handleSendOtp={handleSendOtp} /> */}
                {!isOtpSent ? (
                  <form action="">
                    <SbTextField
                      placeholder="Email ID/Phone no"
                      label="Email ID/Phone no"
                      name="username"
                      value={username || ''}
                      onChange={handleInputChange('username')}
                      style={{ marginBottom: '10px' }}
                    />
                    <ButtonContainer fullWidth variant="contained" onClick={handleSendOtp}>
                      Send OTP
                    </ButtonContainer>
                  </form>
                ) : (
                  <ResetPassword
                    handleInputChange={handleInputChange}
                    confirmPassword={confirmPassword}
                    username={username}
                    otp={otp}
                    password={password}
                    repeatPassword={repeatPassword}
                  />
                )}
                <br />
              </Grid>
            </Grid>
            <Grid container style={{ paddingTop: '40px' }}>
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <div style={{ opacity: '0.1', border: '1px solid #000000' }}></div>
                <PContainer>
                  Don't have an account?{' '}
                  <Link to="/signup" style={{ color: 'red', textDecoration: 'none' }}>
                    <span>Sign up</span>
                  </Link>
                </PContainer>
              </Grid>
            </Grid>
          </div>
        </LoginGrid>
        <SuccessError />
      </Grid>
    </Loader>
  );
};

interface resetPasswordState {
  username: string | undefined;
  otp?: string | undefined;
  password?: string | undefined;
  repeatPassword?: string | undefined;
  confirmPassword: () => void;
  handleInputChange: (name: string) => TextFieldProps['onChange'];
}
const ResetPassword = (props: resetPasswordState) => {
  const dispatch = useDispatch<AppDispatch>();
  const { username, otp, password, repeatPassword, confirmPassword, handleInputChange } = props;
  const btnDisable = !username || !otp || !password || !repeatPassword || password !== repeatPassword || false;
  const [viewPassword, setViewPassword] = useState<boolean>(false);

  const togglePasswordView = () => {
    setViewPassword(!viewPassword);
  };

  const handleLoginWithOTP = () => {
    if (!username) {
      return;
    }
    dispatch(doLoginWithOtp({ username }));
  };

  return (
    <form action="">
      <SbTextField
        placeholder="Email ID/Phone no"
        label="Email ID/Phone no"
        name="username"
        value={username || ''}
        // onChange={handleInputChange('username')}
        style={{ marginBottom: '10px' }}
        disabled
      />
      <SbTextField
        placeholder="Enter OTP"
        label="OTP"
        name="otp"
        value={otp || ''}
        onChange={handleInputChange('otp')}
        style={{ marginBottom: '10px' }}
      />
      <p
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
          color: 'blue',
          textDecoration: 'underline',
        }}
        onClick={handleLoginWithOTP}
      >
        Resend OTP
      </p>
      <SbTextField
        type={viewPassword ? 'text' : 'password'}
        placeholder="Enter new password"
        label="Enter new password"
        name="password"
        value={password || ''}
        onChange={handleInputChange('password')}
        style={{ marginBottom: '10px' }}
        endAdornment={
          <VisibilityIcon
            onClick={togglePasswordView}
            style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
          />
        }
      />
      <SbTextField
        type="password"
        placeholder="Re-enter new password"
        label="Re-enter password"
        name="repeatPassword"
        value={repeatPassword || ''}
        onChange={handleInputChange('repeatPassword')}
        style={{ marginBottom: '10px' }}
      />
      {password !== repeatPassword ? <p>Repeat password does not match.</p> : ''}
      <ButtonContainer fullWidth disabled={btnDisable} variant="contained" onClick={confirmPassword}>
        Reset password
      </ButtonContainer>
    </form>
  );
};

export default ForgotPassword;
