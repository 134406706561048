import SbButton from '@Components/Button';
import SBDropdown from '@Components/Dropdown';
import SBCountryDropdown from '@Components/Dropdown/Country';
import { vendorAccountType } from '@Reducers/AdminShipment/Type';
import { setCarrierSettings } from '@Reducers/User';
import { addCarrierSettings, updateCarrierSettings } from '@Reducers/User/actions';
import { AppDispatch, RootStateType } from '@Store';
import { Grid, Paper } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dhlCarrier } from './Type';
import { dhlSchema } from './constant';
import { Payor, ShipmentType, ShipperType } from '../../ViewSingleShipment/TrackingDetails/UserDHL/constants';

const DHLForm = ({ onCancel }: { onCancel: () => void }) => {
  // const [idAttached, setIdAttached] = useState<boolean>(false);
  const { vendersAccount } = useSelector((state: RootStateType) => state.adminShipment);
  const { userDataById, carrierSetting } = useSelector((state: RootStateType) => state.user);
  const dispatch = useDispatch<AppDispatch>();
  const { values, setFieldValue, errors, handleSubmit } = useFormik<dhlCarrier>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: carrierSetting.dhl,
    validationSchema: dhlSchema,
    onSubmit(values) {
      if (userDataById?._id) {
        if (carrierSetting.dhl._id) {
          dispatch(updateCarrierSettings({ ...carrierSetting.dhl, ...values }));
        } else {
          dispatch(addCarrierSettings({ ...carrierSetting.dhl, ...values }));
        }
      } else {
        dispatch(setCarrierSettings({ values, carrierType: 'dhl' }));
      }
      onCancel();
    },
  });

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    if (subdomain === 'uat-speedbox' || subdomain === 'localhost') {
      // setIdAttached(true);
    }
  }, []);

  // useEffect(() => {
  //   if (carrierSetting.dhl.accountNumber) {
  //     dispatch(
  //       getCarrierSettings({
  //         csp: CSP_TYPES.dhl.value,
  //         accountNumber: carrierSetting.fedex.accountNumber,
  //       }),
  //     );
  //   }
  // }, [carrierSetting.fedex.accountNumber, userDataById?._id]);

  const accountOptions = useMemo(
    () =>
      vendersAccount?.map((item: vendorAccountType) => ({
        // value: `${item.accountNo}-${item._id}`,
        value: `${item.accountNo}`,
        display: item.name,
      })),
    [vendersAccount],
  );

  return (
    <Paper elevation={0}>
      <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="accountNo"
              label={`DHL Account`}
              required
              placeholder="Select"
              name="accountNo"
              options={accountOptions}
              value={values.accountNo?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('accountNo', selectedObj);
              }}
              error={Array.isArray(errors.accountNo) ? errors.accountNo.join(', ') : errors.accountNo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBCountryDropdown
              // multiple
              // required
              label="Country of Manufacture"
              // value={values.countryOfManufacture?.map(item => item.value)}
              value={values.countryOfManufacture}
              // onChange={(_, selectedObj) => {
              //   setFieldValue('countryOfManufacture', selectedObj);
              // }}
              onChange={value => {
                setFieldValue('countryOfManufacture', value[0]);
              }}
              error={errors.countryOfManufacture}
              // error={
              //   Array.isArray(errors.countryOfManufacture)
              //     ? errors.countryOfManufacture.join(', ')
              //     : errors.countryOfManufacture
              // }
            />
          </Grid>
        </Grid>

        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              id="paymentMode"
              label={`Payment Mode`}
              required
              placeholder="Select"
              name="paymentMode"
              options={Payor}
              value={values.paymentMode?.map(item => item?.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('paymentMode', selectedObj);
              }}
              error={Array.isArray(errors.paymentMode) ? errors.paymentMode.join(', ') : errors.paymentMode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              required
              id="ShipperType"
              label={`Shipper Type`}
              options={ShipperType}
              placeholder="Select"
              name="ShipperType"
              value={values.ShipperType?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('ShipperType', selectedObj);
              }}
              error={Array.isArray(errors.ShipperType) ? errors.ShipperType.join(', ') : errors.ShipperType}
            />
          </Grid>
        </Grid>

        <Grid container item columnSpacing={10} rowSpacing={2}>
          <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              required
              id="ShipmentType"
              label={`Shipment Type`}
              placeholder="Select"
              name="ShipmentType"
              value={values.ShipmentType?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('ShipmentType', selectedObj);
              }}
              error={Array.isArray(errors.ShipmentType) ? errors.ShipmentType.join(', ') : errors.ShipmentType}
              options={ShipmentType}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <SBDropdown
              multiple
              required
              id="gstinType"
              label={`Id Type`}
              placeholder="Select"
              name="gstinType"
              value={values.thirdPartyPayor?.map(item => item.value)}
              onChange={(_, selectedObj) => {
                setFieldValue('thirdPartyPayor', selectedObj);
              }}
              error={Array.isArray(errors.thirdPartyPayor) ? errors.thirdPartyPayor.join(', ') : errors.thirdPartyPayor}
              options={GSTType}
            />
          </Grid> */}
        </Grid>

        <Grid container item sx={{ gap: '1rem' }} justifyContent={'flex-end'}>
          <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
            <SbButton sx={{ borderRadius: `4px !important` }} className="w-full" variant="outlined" onClick={onCancel}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
            <SbButton
              variant="contained"
              sx={{ borderRadius: `4px !important` }}
              className="w-full"
              onClick={_event => {
                handleSubmit();
              }}
            >
              Save
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default DHLForm;
