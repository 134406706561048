/**
 *
 *  Ref Link : https://quilljs.com/docs/api/
 *
 *
 */
import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import EditorToolbar, { formats, undoChange, redoChange, EditorOptions } from './EditorToolbar';
import 'react-quill/dist/quill.snow.css';
import './styles.css';

interface EditorProps {
  id: string;
  onChange: ReactQuillProps['onChange'];
  value: ReactQuillProps['value'];
  defaultValue?: ReactQuillProps['defaultValue'];
  style?: ReactQuillProps['style'];
  placeholder?: ReactQuillProps['placeholder'];
  editorOptions?: EditorOptions;
  disabled?: boolean;
}

export const Editor = (props: EditorProps) => {
  return (
    <div className="text-editor">
      <div id={props.id}>
        <EditorToolbar id={props.id} editorOptions={props.editorOptions} />
      </div>
      <ReactQuill
        id="fadedScroll"
        style={props.style}
        theme="snow"
        value={props.value || ''}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        modules={{
          toolbar: {
            container: `#${props.id}`,
            handlers: {
              undo: undoChange,
              redo: redoChange,
            },
          },
          history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
          },
        }}
        formats={formats}
        readOnly={props.disabled}
      />
    </div>
  );
};

export default Editor;
