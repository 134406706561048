import React, { useState } from 'react';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { SBTableRow } from '@Components/Table/TableRow';
import { squadReportsTableProps } from './Types';
import DisplaySquadVehicle from '../../utils/displaySquadVehicle';
import API from '@Services/apiAxios';
import BoxImageModal from './BoxImageModal';
import { formatNumber, useLocale } from '../../utils/formatNumber';

const SquadReportTable = React.memo((props: squadReportsTableProps) => {
  const { reportsList, currentPage, perPage } = props;
  const [odometerImages, setOdometerImages] = useState({
    startImage: '',
    endImage: '',
    type: 'Odometer',
  });
  const [isOdometerOpen, setOdometerOpen] = useState(false);
  const locale = useLocale();

  const handleOdometerClose = () => {
    setOdometerOpen(false);
  };

  const odometerImageView = async (startDocumentId: string, endDocumentId: string) => {
    try {
      setOdometerImages({
        startImage: '',
        endImage: '',
        type: 'Odometer',
      });

      if (startDocumentId) {
        const start = await API.get(`/squad/file/get-url/${startDocumentId}`);
        setOdometerImages(prev => ({
          ...prev,
          startImage: start.data.data,
        }));
      }

      if (endDocumentId) {
        const end = await API.get(`/squad/file/get-url/${endDocumentId}`);
        setOdometerImages(prev => ({
          ...prev,
          endImage: end.data.data,
        }));
      }
    } catch (error) {
      console.error('Error fetching document URL:', error);
    }

    setOdometerOpen(true);
  };

  return (
    <>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell>Sr.No.</TableHeadCell>
            <TableHeadCell>Squad Details</TableHeadCell>
            <TableHeadCell>Tasks</TableHeadCell>
            <TableHeadCell> Total Weight</TableHeadCell>
            <TableHeadCell>Vehicle </TableHeadCell>
            <TableHeadCell>Distance</TableHeadCell>
            <TableHeadCell>Amount Collected</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportsList.map((item: any, index: number) => (
            <SBTableRow key={index}>
              <TableRowCell align="center" className="md-text">
                <div>{index + 1 + (currentPage - 1) * perPage}</div>
              </TableRowCell>
              <TableRowCell align="center" className="md-text">
                <Link to={`/squad-reports/details/${item.squadId}`}>{item.Name}</Link>
              </TableRowCell>
              <TableRowCell align="center" className="md-text ">
                <Typography>{item.tasks}</Typography>
              </TableRowCell>
              <TableRowCell align="center" className="md-text">
                {item.totalWeight?.toFixed(2)} kg
              </TableRowCell>
              <TableRowCell align="center" className="md-text ">
                {DisplaySquadVehicle(item.vehicle)}
              </TableRowCell>
              <TableRowCell align="center" className="md-text ">
                {item.totalDistance != 0 ? item.totalDistance / 1000 : 0} km
                {(item.startTripOdometerReadingDocument || item.endTripOdometerReadingDocument) && (
                  <Typography
                    className="underline cursor-pointer"
                    sx={{ color: '#4047FF' }}
                    onClick={() => {
                      odometerImageView(item.startTripOdometerReadingDocument, item.endTripOdometerReadingDocument);
                    }}
                  >
                    Odometer Image
                  </Typography>
                )}
              </TableRowCell>
              <TableRowCell align="center" className="md-text ">
                Rs. {item.amountCollected === 0 ? 0 : `${formatNumber(parseFloat(item.amountCollected), locale)}/-`}
              </TableRowCell>
            </SBTableRow>
          ))}
        </TableBody>
      </SBTable>
      {isOdometerOpen && (
        <BoxImageModal open={isOdometerOpen} onClose={handleOdometerClose} imageData={odometerImages} />
      )}
    </>
  );
});

export default SquadReportTable;
