import { SuccessType, SavedAddressStateType, AddressType } from './Type';

function addAddressPending(state: SavedAddressStateType) {
  state.loading = true;
}

function addAddressFulfilled(state: SavedAddressStateType, data: { payload: SuccessType }) {
  state.loading = false;
  state.success = data.payload;
}

function addAddressRejected(state: SavedAddressStateType) {
  state.loading = false;
  // state.error = data?.payload ? data.payload : { msg: 'error' };
}

function getAddressByIdFulFilled(state: SavedAddressStateType, data: { payload: { data: AddressType } }) {
  state.loading = false;
  state.savedAddressById = data.payload.data;
}

function getAllAddressFulfilled(state: SavedAddressStateType, data: { payload: { data: { list: AddressType[] } } }) {
  state.loading = false;
  state.savedAddressList = data.payload.data.list;
  state.shipperAddresses = data.payload.data.list.filter(address => address.type === 'shipper');
  state.consigneeAddresses = data.payload.data.list.filter(address => address.type === 'consignee');
}

export { addAddressPending, addAddressFulfilled, addAddressRejected, getAddressByIdFulFilled, getAllAddressFulfilled };
