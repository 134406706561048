import { Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { SubHeading } from './Styles';
import { EditAction } from './ShipperConsigneeSummaryDetails';
import { PickupType, ShipmentDataType } from '../../../types/shipment';
import dayjs, { Dayjs } from 'dayjs';

const Details = styled(Typography)`
  color: var(--mui-palette-grey-600);
  font-weight: 500;
  margin-bottom: 12px;
`;

interface PropsType {
  boxes: number;
  chargeableWeight: number;
  warehouse?: string;
  type?: PickupType;
  pickupDate?: string | Dayjs;
  timeSlot?: string;
  shipmentType: ShipmentDataType['type'];
  shipmentMode: string;
  insurance: boolean;
  packaging: boolean;
  unitOfMeasurement: string;
  activeStep: number;
  editShipment?: boolean;
  referenceNumber?: string;
  notes?: string;
  purposeOfShipment?: string;
  ior?: string;
}

function ShipmentSummaryDetails({
  boxes,
  chargeableWeight,
  warehouse,
  type,
  pickupDate,
  timeSlot,
  shipmentType,
  shipmentMode,
  insurance,
  packaging,
  unitOfMeasurement,
  activeStep,
  editShipment,
  referenceNumber,
  notes,
  purposeOfShipment,
  ior,
}: PropsType) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container item xs={12} lg={6} className={isMobile ? '' : 'border-right pr-3'}>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={9}>
          <SubHeading>Shipment Details</SubHeading>
        </Grid>
        {editShipment && activeStep && (
          <Grid item>
            <EditAction activeStep={activeStep} />
          </Grid>
        )}
      </Grid>
      <Grid>
        {type === 'dropOff' ? (
          <Details>Drop-Off: {warehouse}</Details>
        ) : type === 'pickUp' ? (
          <Details>Pickup: {`${dayjs(pickupDate).format('DD/MM/YYYY')} ${timeSlot}`}</Details>
        ) : (
          ''
        )}
        <Details>Shipment type: {<span className="capitalize">{shipmentType}</span>}</Details>
        <Details>
          Total chargeable Weight: {chargeableWeight}
          {unitOfMeasurement ? unitOfMeasurement.split('-')[0] : 'kg'}
        </Details>
        <Details>Shipment mode: {shipmentMode}</Details>
        <Details>Number of boxes: {boxes}</Details>
        <Details>Insurance: {insurance ? 'Yes' : 'No'}</Details>
        <Details>Packaging: {packaging ? 'Yes' : 'No'}</Details>
        {referenceNumber && <Details>Reference no.: {referenceNumber}</Details>}
        {notes && <Details>Notes: {notes}</Details>}
        {purposeOfShipment && <Details>Purpose Of Shipment: {purposeOfShipment?.split('_').join(' ')}</Details>}
        {ior && <Details>IOR/EORI: {ior}</Details>}
      </Grid>
    </Grid>
  );
}

export default ShipmentSummaryDetails;
