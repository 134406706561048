import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { UserObj } from '@Reducers/AccountLookup/Type';

function UserCard({ label, value }: { label: string; value?: string }) {
  return (
    <Grid item xs={12} md={3} sm={3}>
      <Paper sx={{ px: 2, py: 2 }}>
        <Typography className="font-medium mb-1">{label}</Typography>
        <Typography className="truncate">{value || '-'}</Typography>
      </Paper>
    </Grid>
  );
}

export default function SelectedUserInfoCard({
  user,
  classname = '',
}: {
  user: UserObj | undefined;
  classname?: string;
}) {
  if (!user) return <></>;
  const { _accountId, email, _belongToAdminId, name } = user || {};
  const isIndividualAccount = _accountId?.accountType === 'individual';
  const companyName = isIndividualAccount ? name : _accountId?._company?.[0]?.name;

  return (
    <Grid container columnSpacing={2} rowSpacing={2} className={classname}>
      <UserCard label="Account number" value={_accountId?.accountNumber} />
      <UserCard label={isIndividualAccount ? 'Name' : 'Company name'} value={companyName} />
      <UserCard label="Email" value={email} />
      <UserCard label="Tagged To" value={_belongToAdminId?.name} />
    </Grid>
  );
}
