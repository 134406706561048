import React from 'react';
import { MenuItem, Select, FormControl, SelectChangeEvent } from '@mui/material';

interface Warehouse {
  _id: string;
  name: string;
  [key: string]: any; // For additional fields
}

interface WarehouseSelectProps {
  warehouses: Warehouse[];
  onSelect: (warehouse: Warehouse) => void;
}

const WarehouseSelect: React.FC<WarehouseSelectProps> = ({ warehouses, onSelect }) => {
  const [selectedWarehouseId, setSelectedWarehouseId] = React.useState<string>('');

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value as string; // Value will be a string
    setSelectedWarehouseId(selectedId);

    const selectedWarehouse = warehouses.find(warehouse => warehouse._id === selectedId);
    if (selectedWarehouse) {
      onSelect(selectedWarehouse); // Pass the complete warehouse object to the parent
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        labelId="warehouse-select-label"
        id="warehouse-select"
        value={selectedWarehouseId}
        onChange={handleSelectChange}
      >
        {warehouses.map(warehouse => (
          <MenuItem key={warehouse._id} value={warehouse._id}>
            {warehouse.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default WarehouseSelect;
