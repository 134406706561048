// src/redux/slices/invoiceSlice.ts
import API from '@Services/apiAxios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface InvoiceState {
  accountUrl: string | null;
  nonAccountUrl: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: InvoiceState = {
  accountUrl: null,
  nonAccountUrl: null,
  loading: false,
  error: null,
};

// Thunks for fetching URLs
export const fetchAccountInvoice = createAsyncThunk(
  'invoice/fetchAccountInvoice',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await API.get('/invoice/account/download-list', { params });
      return response.data.data.url;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  },
);

export const fetchNonAccountInvoice = createAsyncThunk(
  'invoice/fetchNonAccountInvoice',
  async (params: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await API.get('/invoice/non-account/download-list', { params });
      return response.data.data.url;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  },
);

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    resetInvoiceState: () => initialState, // Reset action to reset state to initialState
  },
  extraReducers: builder => {
    // Account Invoice
    builder
      .addCase(fetchAccountInvoice.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAccountInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.accountUrl = action.payload;
      })
      .addCase(fetchAccountInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });

    // Non-Account Invoice
    builder
      .addCase(fetchNonAccountInvoice.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchNonAccountInvoice.fulfilled, (state, action) => {
        state.loading = false;
        state.nonAccountUrl = action.payload;
      })
      .addCase(fetchNonAccountInvoice.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { resetInvoiceState } = invoiceSlice.actions;

export default invoiceSlice.reducer;
