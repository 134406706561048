import { PayloadAction } from '@reduxjs/toolkit';
import { SuccessPayload } from '../../../types';
import {
  AdminActionsResponse,
  AdminListForTagResponse,
  AdminListResponse,
  AdminObj,
  AdminState,
  CheckUsername,
  ErrorType,
} from './Type';

function pending(state: AdminState) {
  state.loading = true;
  state.success = '';
}

function getAdminListFulfilled(state: AdminState, action: { payload: AdminListResponse }) {
  const { list, currentPage, perPage, totalCount } = action.payload;
  state.loading = false;
  state.data.list = list;
  state.data.totalCount = totalCount;
  state.data.currentPage = currentPage; // remove after API fix
  state.data.perPage = perPage;
}

function getAdminListForTagFulfilled(state: AdminState, action: { payload: AdminListForTagResponse }) {
  state.loading = false;
  state.adminListForTag = action.payload.data;
}

function rejected(state: AdminState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = { msg: action.payload?.msg || 'Error', isError: true };
}

function deactivateAdminAccountFulfilled(state: AdminState, action: { payload: AdminActionsResponse }) {
  state.loading = false;
  state.success = action.payload.msg;
}
function restoreAdminAccountFulfilled(state: AdminState, action: { payload: AdminActionsResponse }) {
  state.loading = false;
  state.success = action.payload.msg;
}

function createAdminFulfilled(state: AdminState, _action: { payload: AdminActionsResponse }) {
  state.loading = false;
  state.adminCreated = true;
}

function editAdminFulfilled(state: AdminState, action: { payload: SuccessPayload<AdminObj> }) {
  state.loading = false;
  state.adminUpdated = true;
  if (action.payload.data) state.adminDataById = action.payload.data;
}

function getAdminDetailsByIdFulfilled(_state: AdminState) {
  console.log('ACTION getAdminDetailsByIdFulfilled called');
}

function shipmentPayByCashFulfilled(state: AdminState) {
  state.loading = false;
}

function shipmentAmtRefundFulfilled(state: AdminState) {
  state.loading = false;
}

function checkUsernameFulfilled(state: AdminState, action: { payload: SuccessPayload<CheckUsername> }) {
  state.loading = false;
  console.log('action.payload.data?.data', action.payload);
  state.isUsernameTaken = action.payload.data?.isTaken || false;
}

export {
  pending,
  getAdminListFulfilled,
  getAdminListForTagFulfilled,
  rejected,
  deactivateAdminAccountFulfilled,
  restoreAdminAccountFulfilled,
  createAdminFulfilled,
  editAdminFulfilled,
  getAdminDetailsByIdFulfilled,
  shipmentPayByCashFulfilled,
  checkUsernameFulfilled,
  shipmentAmtRefundFulfilled,
};
