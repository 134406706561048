import { Typography } from '@mui/material';
import React from 'react';
import { UserStatusColorMap } from './Types';

export function Status({ status }: { status: string }) {
  const statusDetails = USER_STATUS_COLOR[status];
  return (
    <Typography
      sx={{ color: statusDetails?.textColor, bgcolor: statusDetails?.bgColor, px: 1, py: 0.5, fontWeight: 500 }}
      className="w-fit rounded-xs m-auto"
    >
      {statusDetails?.label}
    </Typography>
  );
}

const USER_STATUS_COLOR: UserStatusColorMap = {
  paid: {
    textColor: '#277563',
    bgColor: '#E9FFFA',
    label: 'Paid',
  },
  unpaid: {
    textColor: 'error.main',
    bgColor: 'error.light',
    label: 'Unpaid',
  },
  partially_paid: {
    textColor: '275475',
    bgColor: 'E9F6FF',
    label: 'Unpaid',
  },
  void: {
    textColor: 'info.main',
    bgColor: 'info.light',
    label: 'Unpaid',
  },
};
