import SbButton from '@Components/Button';
import DateRangePicker from '@Components/DateRangePicker';
import SBDropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import Loader from '@Components/Loader';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import Title from '@Components/Title';
import { USER_STATUS_COLOR } from '@Constants/status';
import { getTransactionList } from '@Reducers/TransactionHistory';
import { TransactionObj } from '@Reducers/TransactionHistory/Type';
import { AppDispatch, RootStateType } from '@Store';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import {
  Box,
  SelectChangeEvent,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterObj } from './Types';
import { PaymentModeList, SearchFilterList, StatusList } from './constant';
import { AmountTypo, CreditedIcon, DebitedIcon, TableActionContainer, TransactionStatus } from './style';
import ExportBtn from '@Components/Button/Export';
import { fetchTransactionExport } from '@Reducers/DownloadTransactionReport/DownloadTransactionReportSlice';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { formatNumber, useLocale } from '../../utils/formatNumber';

interface TrasactionHistoryQuery {
  fromDate: string;
  toDate: string;
  currentPage: number;
  perPage: number;

  mode?: string[];
  status?: string[];
  searchKey?: string;
  searchValue?: string;
  accountType?: string;
  type?: string;
}

// const DaysFilterOptions: DropdownOptionType[] = [
//   {
//     display: '15 Days',
//     value: '15',
//   },
//   {
//     display: '30 Days',
//     value: '30',
//   },
// ];

function TransactionTable() {
  const {
    data: { list, currentPage = 1, perPage },
  } = useSelector((state: RootStateType) => state.transactionHistory);
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name || '');
  const locale = useLocale();

  function getTransactionType(obj: TransactionObj) {
    const label =
      obj.paymentFor === 'shipment' ? 'Shipment No' : obj.paymentFor === 'invoice' ? 'Invoice No' : 'Wallet';
    return (
      <div>
        <Typography>{label}</Typography>
        <Typography>{obj.paymentForNumber}</Typography>
      </div>
    );
  }

  // console.info('list:', list);

  return (
    <SBTableWrapper>
      <SBTable>
        <TableHead
          sx={{
            th: {
              textAlign: 'center !important',
            },
          }}
        >
          <TableRow>
            <TableHeadCell style={{ minWidth: '80px' }}>Sr No.</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '180px' }}>Account Details</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '250px' }}>Mode</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '200px' }}>Transaction Type</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '250px' }}>Transaction ID</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '160px' }}>Date</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '200px' }}>Amount ({orgCountryCurrency})</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '180px' }}>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => (
            <SBTableRow key={item._id}>
              <TableRowCell
                align="center"
                className="md-text"
                sx={{
                  div: {
                    justifyContent: 'center',
                  },
                }}
              >
                {index + 1 + (currentPage - 1) * perPage}
              </TableRowCell>
              <TableRowCell className="sm-text">
                {item?.isAccountHolder ? (
                  <>
                    <Typography className="sm-text">{item?.company ? item?.company : item?.name || 'N/A'} </Typography>
                    <Typography className="sm-text">{item?.accountNumber} </Typography>
                  </>
                ) : (
                  <Typography className="sm-text"> {item?.company ? item?.company : item?.name || 'N/A'} </Typography>
                )}
              </TableRowCell>
              <TableRowCell>
                <div className="flex">
                  {item.type === 'debit' ? (
                    <DebitedIcon>
                      <CallMadeIcon />
                    </DebitedIcon>
                  ) : (
                    <CreditedIcon>
                      <CallReceivedIcon />
                    </CreditedIcon>
                  )}
                  <Typography className="md-text">{PaymentModeList[item.mode]?.display}</Typography>
                </div>
              </TableRowCell>
              <TableRowCell className="sm-text">{getTransactionType(item)}</TableRowCell>
              <TableRowCell className="sm-text">{item?.gatewayPaymentId || item._id}</TableRowCell>
              <TableRowCell
                className="sm-text"
                align="center"
                sx={{
                  div: {
                    justifyContent: 'center',
                  },
                }}
              >
                {moment(item.createdAt).format('DD/MM/YYYY')}
              </TableRowCell>
              <TableRowCell
                className="sm-text"
                align="center"
                sx={{
                  div: {
                    justifyContent: 'center',
                  },
                }}
              >
                <AmountTypo type={item.type}>{formatNumber(Number(item.amount), locale)}</AmountTypo>
              </TableRowCell>
              <TableRowCell align="center" className="sm-text">
                <TransactionStatus
                  bgcolor={USER_STATUS_COLOR[item.status].bgColor}
                  color={USER_STATUS_COLOR[item.status].textColor}
                >
                  {USER_STATUS_COLOR[item.status]?.text}
                </TransactionStatus>
              </TableRowCell>
            </SBTableRow>
          ))}
        </TableBody>
      </SBTable>
    </SBTableWrapper>
  );
}

const FilterSectionContainer = styled('div')`
  display: grid;
  grid-template-columns: fit-content(80%);
  grid-gap: 24px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const FilterSection = styled('div')<{ hasTwoDivisions?: boolean }>(({ theme, hasTwoDivisions }) => ({
  height: '130px',
  padding: '20px',
  display: 'grid',
  gap: '3rem',
  gridTemplateColumns: hasTwoDivisions ? '1fr 1fr' : 'repeat(4,fit-content(300px))',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.border.grey}`,
  backgroundColor: theme.palette.primary.light,
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    height: 'auto',
  },
}));

const FilterLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.purple,
  fontSize: '20px',
  fontWeight: 500,
}));

function Navigation(props: {
  daysFilter: string;
  setDaysFilter: (val: string) => void;
  accountType: string;
  setAccountType: (val: string) => void;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setDateRange: (
    value: React.SetStateAction<{
      startDate: Date;
      endDate: Date;
    }>,
  ) => void;
}) {
  const { accountTypeList } = useSelector((state: RootStateType) => state?.global);
  const { setDaysFilter, accountType, setAccountType, setDateRange } = props;
  const {
    data: { totalCount },
  } = useSelector((state: RootStateType) => state.transactionHistory);
  const theme = useTheme();
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name || '');

  function handleDropdownChange(
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
    _child: React.ReactNode,
  ) {
    if (event.target.name === 'accountType') setAccountType(event.target.value);
    else if (event.target.name === 'dateFilter') {
      setDaysFilter(event.target.value);
      const dateRange = {
        startDate: moment().subtract(event.target.value, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
      };
      setDateRange(dateRange);
    }
  }

  return (
    <FilterSectionContainer>
      <FilterSection>
        <div>
          <FilterLabel>Transactions</FilterLabel>
          <FilterLabel className="md-text">{totalCount}</FilterLabel>
        </div>
        <div>
          <FilterLabel>Account Type</FilterLabel>
          <SBDropdown
            id="bank-acc-type"
            label=""
            required
            placeholder="Select Account Type"
            name="accountType"
            options={accountTypeList}
            value={[accountType]}
            onChange={handleDropdownChange}
            sx={{
              '&.MuiInputBase-root': {
                backgroundColor: 'transparent',
              },
              '&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '0px',
              },
              '.MuiSelect-select': {
                height: 'unset',
                padding: 0,
              },
              '.MuiTypography-root': {
                whiteSpace: 'pre-wrap',
                color: theme.palette.primary.main,
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderWidth: 0,
                minHeight: 0,
              },
            }}
          />
        </div>
        <div>
          <FilterLabel>Total Debit({orgCountryCurrency}) </FilterLabel>
          {/* <FilterLabel className="md-text">98,000 </FilterLabel> */}
          <Box
            onClick={() => {
              props.setType('debit');
            }}
            className="cursor-pointer underline color-p-main"
          >
            view transactions
          </Box>
        </div>
        <div>
          <FilterLabel>Total Credit({orgCountryCurrency}) </FilterLabel>
          {/* <FilterLabel className="cursor-pointer md-text">8,000</FilterLabel> */}
          <Box
            onClick={() => {
              props.setType('credit');
            }}
            className="cursor-pointer underline color-p-main"
          >
            view transactions
          </Box>
        </div>
      </FilterSection>
    </FilterSectionContainer>
  );
}

export default function Transactions() {
  const [filterSelectValue, setFilterSelectValue] = useState('accountNumber');
  const [filterText, setFilterText] = useState('');
  const [mode, setMode] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [daysFilter, setDaysFilter] = useState<string>('15');
  const [accountType, setAccountType] = useState<string>('');
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(daysFilter, 'days').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [type, setType] = useState('');
  const [loader, setLoader] = useState<boolean>(false);
  const { limit } = useSelector((state: RootStateType) => state.notifications);

  const dispatch = useDispatch<AppDispatch>();

  const {
    data: { currentPage = 1, perPage, totalCount },
    loading,
  } = useSelector((state: RootStateType) => state.transactionHistory);

  const [prevSearchObj, setPrevSearchObj] = useState<string>('');

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(getTransactionList(getFilterObj({ dateRange })));
  };

  const onStatusFilterChange = (selected: Array<string>) => {
    setStatus(selected);
    dispatch(getTransactionList(getFilterObj({ status: selected })));
  };

  const onModeFilterChange = (selected: Array<string>) => {
    setMode(selected);
    dispatch(getTransactionList(getFilterObj({ mode: selected })));
  };

  useEffect(() => {
    if (daysFilter || type) {
      dispatch(getTransactionList(getFilterObj({ daysFilter, type })));
    }
  }, [daysFilter, type]);

  useEffect(() => {
    // if (accountType) {
    dispatch(getTransactionList(getFilterObj({ accountType })));
    // }
  }, [accountType]);

  const getFilterObj = (options: FilterObj): TrasactionHistoryQuery => {
    let filterDate = options.dateRange || dateRange;
    if (options.daysFilter) {
      filterDate = {
        startDate: moment().subtract(daysFilter, 'days').startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
      };
    }
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterMode = options.mode || mode;
    const filterStatus = options.status || status;
    const filterAccountType = options.accountType || accountType;

    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const compareObj = {
      fromDate,
      toDate,
      mode: filterMode,
      status: filterStatus,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
      accountType: filterAccountType,
    };
    if (prevSearchObj !== '' && JSON.stringify(compareObj) !== prevSearchObj) {
      options.currentPage = 1;
    }
    setPrevSearchObj(JSON.stringify(compareObj));
    const payload: TrasactionHistoryQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      mode: filterMode,
      status: filterStatus,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
      accountType: filterAccountType,
    };
    if (!filterSearchValue) {
      delete payload['searchKey'];
      delete payload['searchValue'];
    }
    if (type) {
      payload.type = type;
    }
    return payload;
  };

  const handleExport = () => {
    try {
      setLoader(true);
      const fromDate = getFilterObj({}).fromDate;
      const toDate = getFilterObj({}).toDate;
      dispatch(fetchTransactionExport({ fromDate, toDate }));

      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit,
        }),
      );
      dispatch(getAllReadNotifications({ page: 1, limit }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Download in Progress',
          subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
          btnText: 'Close',
          onConfirm: () => {
            dispatch(toggleBoolean());
            dispatch(SuccessErrorModalReset());
          },
        }),
      );
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <Loader loading={loading} overly>
      <>
        <Title title="Transactions" removeBorder subTitle="View transactions for all users" />

        <Navigation
          daysFilter={daysFilter}
          setDaysFilter={setDaysFilter}
          accountType={accountType}
          setAccountType={setAccountType}
          setDateRange={setDateRange}
          setType={setType}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'flex-start', sm: 'flex-end' },
          }}
        >
          <Loader loading={loader} overly />
          <ExportBtn
            iconSrc="/images/file_download.svg"
            width={25}
            alt="Export"
            label="Export"
            onClick={() => {
              handleExport();
            }}
          />
        </Box>

        <TableActionContainer>
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={SearchFilterList}
            selectValue={filterSelectValue}
            textValue={filterText}
            onTextChange={e => {
              setFilterText(e.target.value);
            }}
            onTextSearch={() => {
              dispatch(getTransactionList(getFilterObj({})));
            }}
            onTextClear={() => {
              setFilterText('');
              dispatch(getTransactionList(getFilterObj({ searchValue: undefined })));
            }}
          />
          <div className="whiteSpace"></div>
          <DateRangePicker onChange={onDateChange} value={dateRange} />
          <FilterSelect
            id="mode"
            label="Mode"
            value={mode}
            options={Object.values(PaymentModeList)}
            onChange={onModeFilterChange}
          />
          <FilterSelect
            id="status"
            label="Status"
            value={status}
            options={Object.values(StatusList)}
            onChange={onStatusFilterChange}
          />
          <SbButton
            className="xs-text ml-1"
            variant="outlined"
            sx={{ borderRadius: '8px !important', padding: '8px !important' }}
            onClick={() => {
              setStatus([]);
              setFilterText('');
              setMode([]);
              setDateRange({
                startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
              });
              setType('');
              dispatch(getTransactionList(getFilterObj({ searchValue: '' })));
            }}
          >
            Clear Filters
          </SbButton>
        </TableActionContainer>
        <TransactionTable />
        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage - 1}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                dispatch(getTransactionList(getFilterObj({ currentPage: page + 1 })));
              }}
              onRowsPerPageChange={event => {
                dispatch(getTransactionList(getFilterObj({ perPage: parseInt(event.target.value) })));
              }}
            />
          </>
        )}
      </>
    </Loader>
  );
}
