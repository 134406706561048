import { ShipmentByIdType } from '@Reducers/Shipment/Type';
import { RootStateType } from '@Store';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  defaultBoxState,
  defaultBoxesState,
  defaultConsignee,
  defaultPackageState,
  defaultPickupState,
  defaultProductState,
  defaultShipmentTypeState,
  defaultShipperState,
} from '../../pages/CreateShipment/constants';
import { LENGTH_UNITS } from '../../pages/GetRatesV2/Constant';
import { documentWeight } from '../../pages/GetRatesV2/RatesInput/Schema';
import { UNIT, calculateWeights } from '../../services/calculateVolume';
import {
  getBoxStateObj,
  getPackageStateObj,
  getPickupStateObj,
  getShipmentTypeStateObj,
  getUserDetailsStateObj,
} from '../../services/getShipmentPayload';
import { CSPChargeType, BoxType as CommonBoxType } from '../../store/reducers/GetRates/Type';
import {
  BoxType,
  ConsigneeType,
  ExtraDetails,
  PackageType,
  PickupDataType,
  ProductType,
  ShipmentDataType,
  ShipperType,
} from '../../types/shipment';
import CreateShipmentContext from './index';
import { useSearchQueryParams } from '@Hook/useSearchQueryParams';
import { useParams } from 'react-router-dom';

function CreateShipmentWrapper(props: { children: React.ReactNode }) {
  const refId = new URLSearchParams(document.location.search).get('ref');
  // const dispatch = useDispatch<AppDispatch>();
  const { formData } = useSelector((state: RootStateType) => state.getRates);
  const { shipmentById } = useSelector((state: RootStateType) => state.shipment);
  const { singeDraftShipment, selectedDraftShipmentId: draftRefId } = useSelector(
    (state: RootStateType) => state.DraftShipment,
  );

  const [activeStep, setActiveStep] = useState<number>(0);
  const [shipper, setShipper] = useState<ShipperType>({
    ...defaultShipperState,
  });
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name);

  const [consignee, setConsignee] = useState<ConsigneeType>({ ...defaultConsignee });
  const [packageData, setPackageData] = useState<PackageType>({ ...defaultPackageState });
  const [pickupType, setPickupType] = useState<PickupDataType>({ ...defaultPickupState, date: null });
  const [shipmentType, setShipmentType] = useState<ShipmentDataType>({
    ...defaultShipmentTypeState,
    currency: orgCountryCurrency || '',
  });
  const [boxes, setBoxes] = useState<BoxType[]>(structuredClone(defaultBoxesState));
  const [selectedBox, setSelectedBox] = useState<BoxType>({ ...defaultBoxState });
  const [selectedProduct, setSelectedProduct] = useState<ProductType | null>({ ...defaultProductState });
  const [totalShipmentValue, setTotalShipmentValue] = useState<number>(0);
  const [actualWeight, setActualWeight] = useState<number>(0);
  const [chargeableWeight, setChargeableWeight] = useState<number>(0);
  const [boxValidation, setBoxValidation] = useState<boolean>(false);
  const [showRates, setShowRates] = useState<boolean>(false);
  const [selectedCSP, setSelectedCSP] = useState<CSPChargeType | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const [removeFirstBoxDeleteOption, setRemoveFirstBoxDeleteOption] = useState<boolean>(false);
  const [extraDetails, setExtraDetails] = useState<ExtraDetails>({ purposeOfShipment: '', ior: '' });
  const searchParams = useSearchQueryParams();
  const { sn: shipmentNumber } = useParams();

  // useEffect(() => {
  //   let totalChargeableWeight = 0;
  //   totalChargeableWeight = boxes.reduce((acc, { length, height, width, weight, products, boxCopyCount }) => {
  //     if (weight && products.length) {
  //       setBoxValidation(true);
  //     } else {
  //       setBoxValidation(false);
  //     }
  //     const unit: LengthUnitType = LENGTH_UNITS[shipmentType.unit.split('-')[0]].value;
  //     let volumetricWeight = calculateVolume(length, width, height, unit);
  //     return acc + (weight > volumetricWeight ? weight : volumetricWeight) * boxCopyCount;
  //   }, 0);
  //   setChargeableWeight(totalChargeableWeight);
  // }, [boxes, selectedBox]);

  function formatEditShipmentData(data: ShipmentByIdType) {
    setActiveStep(0);
    setShipper({ ...getUserDetailsStateObj(data._shipperId) });
    setConsignee({ ...getUserDetailsStateObj(data._consigneeId) });
    setPackageData({ ...getPackageStateObj(data._packageId) });
    if (!refId) {
      setPickupType({
        ...getPickupStateObj(data._packageId),
        warehouse: data._warehouseId,
        selectedWearhouseAddress: {
          ...data?._warehouseAddressId,
          label: data?._warehouseAddressId?.label !== null ? data?._warehouseAddressId?.label : '',
        },
      });
    }
    setShipmentType({ ...getShipmentTypeStateObj(data) });

    const boxDetails: BoxType[] = getBoxStateObj(data._packageId.box);

    if (boxDetails.length) {
      setBoxes([...boxDetails]);
      setSelectedBox({ ...boxDetails[0] });
      if (boxDetails[0].products?.length) {
        setSelectedProduct({ ...boxDetails[0].products[0] });
      }
    } else {
      setBoxes(structuredClone(defaultBoxesState));
      setSelectedBox({ ...defaultBoxState });
      setSelectedProduct({ ...defaultProductState });
    }

    setTotalShipmentValue(0);
    setChargeableWeight(0);
    setBoxValidation(false);
    setShowRates(false);
    setSelectedCSP(null);
    setNotes('');
  }

  function resetShipmentData() {
    setShipper({ ...defaultShipperState });
    setConsignee({ ...defaultConsignee });
    setPackageData({ ...defaultPackageState });
    setPickupType({ ...defaultPickupState });
    setShipmentType({ ...defaultShipmentTypeState, currency: orgCountryCurrency || '' });
    if (!shipmentNumber) {
      setSelectedBox({ ...defaultBoxState });
      setBoxes([...defaultBoxesState]);
    }
    setSelectedProduct({ ...defaultProductState });
    setTotalShipmentValue(0);
    setChargeableWeight(0);
    setBoxValidation(false);
    setShowRates(false);
    setSelectedCSP(null);
    setNotes('');

    // dispatch(resetFormData());
  }
  useEffect(() => {
    if (refId && shipmentById) {
      formatEditShipmentData(shipmentById);
    } else {
      resetShipmentData();
    }
  }, [refId, shipmentById]);
  useEffect(() => {
    if (draftRefId && singeDraftShipment) {
      const { shipper, consignee, shipmentMode } = singeDraftShipment;
      const data = {
        _shipperId: shipper as ShipmentByIdType['_shipperId'],
        _consigneeId: consignee as ShipmentByIdType['_consigneeId'],
        _packageId: (singeDraftShipment.package || {}) as ShipmentByIdType['_packageId'],
        shipmentMode: shipmentMode || '',
        _warehouseId: singeDraftShipment?._warehouseId,
      };
      formatEditShipmentData(data as ShipmentByIdType);
    } else {
      resetShipmentData();
    }
  }, [draftRefId, singeDraftShipment]);

  useEffect(() => {
    if (formData?.boxes && searchParams.redirectedFrom === 'GetRates') {
      const { boxes, pickup, destination, weight, weightUnit, shipmentType: _shipmentType } = formData;
      let boxesTemp: BoxType[] = [];
      if (boxes.length) {
        boxesTemp = boxes.map((box: CommonBoxType, ind) => ({
          ...box,
          products: [],
          id: ind.toString(),
          boxCopyCount: 0,
        }));
      } else {
        boxesTemp = [{ ...defaultBoxState, weight: weight }];
      }
      setShipper(prev => ({ ...prev, ...pickup }));
      setConsignee(prev => ({ ...prev, ...destination }));
      setBoxes(boxesTemp);
      setSelectedBox(boxesTemp[0]);
      setShipmentType({ ...shipmentType, type: _shipmentType, unit: weightUnit === 'kg' ? 'kg-cm' : 'lb-in' });
    }
  }, [formData, searchParams.redirectedFrom]);

  useEffect(() => {
    if (boxes.length) setShipmentType({ ...shipmentType, numberOfBoxes: `${boxes.length}` });
  }, [boxes]);

  useEffect(() => {
    const unit: UNIT = LENGTH_UNITS[shipmentType?.unit.split('-')[0]]?.value === UNIT.cm ? UNIT.cm : UNIT.in;
    for (let i = 0; i < boxes.length; i++) {
      const { products } = boxes[i];
      const weight = Number((boxes[i].weight && `${boxes[i].weight}`) || 0);
      if (weight && products?.length) {
        if (shipmentType.type === 'document' && weight > documentWeight[shipmentType.unit]) {
          setBoxValidation(false);
        } else {
          setBoxValidation(true);
        }
      } else {
        setBoxValidation(false);
      }
    }
    // const { totalValue, totalChargeableWeight, totalActualWeight } = calculateWeights<BoxType>({
    const { totalValue, totalActualWeight } = calculateWeights<BoxType>({
      boxes,
      unit,
    });
    setTotalShipmentValue(totalValue);
    // setChargeableWeight(Number(totalChargeableWeight.toFixed(2)));
    setActualWeight(totalActualWeight);
  }, [boxes, selectedBox, shipmentType.type, shipmentType.unit]);

  return (
    <CreateShipmentContext.Provider
      value={{
        activeStep,
        setActiveStep,
        shipper,
        setShipper,
        consignee,
        setConsignee,
        packageData,
        setPackageData,
        pickupType,
        setPickupType,
        shipmentType,
        setShipmentType,
        boxes,
        setBoxes,
        selectedBox,
        setSelectedBox,
        selectedProduct,
        setSelectedProduct,
        totalShipmentValue,
        chargeableWeight,
        setChargeableWeight,
        boxValidation,
        setBoxValidation,
        showRates,
        setShowRates,
        selectedCSP,
        setSelectedCSP,
        notes,
        setNotes,
        actualWeight,
        userId,
        setUserId,
        removeFirstBoxDeleteOption,
        setRemoveFirstBoxDeleteOption,
        extraDetails,
        setExtraDetails,
        shipmentById,
      }}
    >
      {props.children}
    </CreateShipmentContext.Provider>
  );
}

export default CreateShipmentWrapper;
