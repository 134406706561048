import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccessGroupState, ActionIdsType } from './Type';
import {
  createAccessGroup,
  getAccessGroupList,
  getAccessGroupListById,
  getActionsList,
  getPagesList,
  getSideNavbarPages,
  updateAccessGroup,
} from './actions';
import {
  checkAllowedActions,
  createAccessGroupFulfilled,
  createAccountRejected,
  getAccessGroupByIdFulfilled,
  getAccessGroupFulfilled,
  getActionsFulfilled,
  getPagesFulfilled,
  getSideNavbarPagesFulfilled,
  handlePending,
  handleRejected,
  updateAccessGroupFullfilled,
} from './case';

const initialState: AccessGroupState = {
  loading: false,
  success: '',
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
  },
  actionsList: {},
  pagesList: {},
  pageIds: [],
  SideNavbarPagesIds: [],
  actionsIds: [],
  SideNavbarActionsIds: [],
  PagesData: [],
};

const accessGroupSlice = createSlice({
  name: 'accessGroup',
  initialState: { ...initialState },
  reducers: {
    setViewAccessGroup: function (state, action: PayloadAction<{ pageId: string; actionIds: string[] }>) {
      const { pageId, actionIds } = action.payload;
      state.pageIds = [...state.pageIds, pageId];
      state.actionsIds = [
        ...state.actionsIds,
        ...(actionIds
          ? actionIds
              .filter((id: string) => state.actionsList?.[id]?.isReadOnly) // Optional chaining for actionsList
              .map(actionId => ({ _id: actionId, selectedActions: ['r'] }))
          : []),
      ];
    },
    setWriteAccessGroup: function (state, action: PayloadAction<{ actionIds: string[] }>) {
      const { actionIds } = action.payload;

      const writeOnlyActionIds = actionIds.filter(id => state.actionsList?.[id]?.isWriteOnly);

      writeOnlyActionIds.forEach(actionId => {
        // Check if the actionId already exists in state.actionsIds
        const existingActionIndex = state.actionsIds.findIndex(item => item._id === actionId);

        // If actionId exists, update the selectedActions
        if (existingActionIndex !== -1) {
          if (!state.actionsIds[existingActionIndex].selectedActions.includes('w')) {
            // Add write permission if it doesn't exist
            state.actionsIds[existingActionIndex].selectedActions.push('w');
          }
        } else {
          // If actionId doesn't exist, add a new object
          state.actionsIds.push({
            _id: actionId,
            selectedActions: ['w'],
          });
        }
      });
    },
    // -----------------------------------------------------------
    // setWriteAccessGroup: function (state, action: PayloadAction<{ actionIds: string[] }>) {
    //   const { actionIds } = action.payload;
    //   console.info('Action ids -> ', actionIds, state.actionsIds);
    //   state.actionsIds = [
    //     // Filter only actions that have only read access and are also selected
    //     // ...(state.actionsIds
    //     //   ? state.actionsIds.filter(
    //     //       (item: ActionIdsType) => actionIds?.includes(item?._id) && !state.actionsList?.[item?._id]?.isWriteOnly,
    //     //     )
    //     //   : []),
    //     ...state.actionsIds,
    //     ...(actionIds
    //       ? actionIds
    //           .filter((id: string) => state.actionsList?.[id]?.isWriteOnly) // Optional chaining for actionsList
    //           .map(actionId =>
    //             JSON.stringify(state.actionsIds)?.includes(actionId)
    //               ? { _id: actionId, selectedActions: ['r', 'w'] }
    //               : { _id: actionId, selectedActions: ['w'] },
    //           )
    //       : []),
    //   ];
    // },
    resetViewAccessGroup: function (state, action: PayloadAction<{ pageId: string; actionIds: string[] }>) {
      const { pageId, actionIds } = action.payload;
      state.pageIds = state.pageIds.filter(id => id !== pageId);
      state.actionsIds = state.actionsIds.filter((item: ActionIdsType) => !actionIds.includes(item._id));
    },
    resetWriteAccessGroup: function (state, action: PayloadAction<{ actionIds: string[] }>) {
      const { actionIds } = action.payload;
      state.actionsIds = state?.actionsIds?.reduce((acc: ActionIdsType[], curr: ActionIdsType) => {
        // compare for the same action if exits
        if (actionIds.includes(curr._id)) {
          const { isReadOnly } = checkAllowedActions(curr?.selectedActions);
          // if it have both actions read and write than remove write access
          if (isReadOnly) {
            acc = [...acc, { ...curr, selectedActions: ['r'] }];
          } else {
            // if it have only write actions than remove that object
            return acc;
          }
        } else {
          acc = [...acc, curr];
        }
        return acc;
      }, initialState.actionsIds);
    },

    setViewAccess: function (state, action: PayloadAction<{ actionId: string }>) {
      const { actionId } = action.payload;
      state.actionsIds = JSON.stringify(state.actionsIds)?.includes(actionId) // checking if write access exists or not.
        ? state.actionsIds.map((curr: ActionIdsType) => {
            // compare for the same action if exits mean it have write access only need to read access
            if (curr?._id === actionId) {
              return { ...curr, selectedActions: ['r', 'w'] };
            } else {
              return curr;
            }
          })
        : [...state.actionsIds, { _id: actionId, selectedActions: ['r'] }];
    },
    setWriteAccess: function (state, action: PayloadAction<{ pageId: string; actionId: string }>) {
      const { actionId } = action.payload;

      // Check if write access exists for the actionId
      const writeAccessExists = JSON.stringify(state.actionsIds)?.includes(actionId);

      // Update state.actionsIds based on the existence of write access
      state.actionsIds = writeAccessExists
        ? state.actionsIds.map((curr: ActionIdsType) => {
            // If actionId exists, update its permissions to include read and write
            if (curr?._id === actionId) {
              return { ...curr, selectedActions: ['r', 'w'] };
            } else {
              return curr;
            }
          })
        : [...state.actionsIds, { _id: actionId, selectedActions: ['w'] }]; // Add new object with write permission if not exists
    },
    // -------------------------------------------------------------------------
    // setWriteAccess: function (state, action: PayloadAction<{ pageId: string; actionId: string }>) {
    //   const { actionId } = action.payload;
    //   state.actionsIds = JSON.stringify(state.actionsIds)?.includes(actionId) // checking if write access exists or not.
    //     ? state.actionsIds.map((curr: ActionIdsType) => {
    //         // compare for the same action if exits mean it have write access only need to read access
    //         if (curr?._id === actionId) {
    //           return { ...curr, selectedActions: ['r', 'w'] };
    //         } else {
    //           return curr;
    //         }
    //       })
    //     : [...state.actionsIds, { _id: actionId, selectedActions: ['w'] }];
    // },
    resetViewAccess: function (state, action: PayloadAction<{ pageId: string; actionId: string }>) {
      const { actionId } = action.payload;
      state.actionsIds = state.actionsIds.reduce((acc: ActionIdsType[], curr: ActionIdsType) => {
        // compare for the same action
        if (curr?._id === actionId) {
          const { isReadOnly, isWriteOnly } = checkAllowedActions(curr.selectedActions);
          // checking for both access
          if (isReadOnly && isWriteOnly) {
            // removing only read access
            acc = [...acc, { ...curr, selectedActions: ['w'] }];
          } else {
            // removing whole action
            return acc;
          }
        } else {
          acc = [...acc, curr];
        }
        return acc;
      }, initialState.actionsIds);
    },
    resetWriteAccess: function (state, action: PayloadAction<{ pageId: string; actionId: string }>) {
      const { actionId } = action.payload;
      state.actionsIds = state.actionsIds.reduce((acc: ActionIdsType[], curr: ActionIdsType) => {
        // compare for the same action
        if (curr?._id === actionId) {
          const { isReadOnly, isWriteOnly } = checkAllowedActions(curr.selectedActions);
          // checking for both access
          if (isReadOnly && isWriteOnly) {
            // removing only write access
            acc = [...acc, { ...curr, selectedActions: ['r'] }];
          } else {
            // removing whole action
            return acc;
          }
        } else {
          acc = [...acc, curr];
        }
        return acc;
      }, initialState.actionsIds);
    },
    resetPageAndActionIds: function (state) {
      state.actionsIds = [];
      state.pageIds = [];
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getAccessGroupList.pending, handlePending);
    addCase(getAccessGroupList.fulfilled, getAccessGroupFulfilled);
    addCase(getAccessGroupList.rejected, handleRejected);

    addCase(getAccessGroupListById.pending, handlePending);
    addCase(getAccessGroupListById.fulfilled, getAccessGroupByIdFulfilled);
    addCase(getAccessGroupListById.rejected, handleRejected);

    addCase(getSideNavbarPages.pending, handlePending);
    addCase(getSideNavbarPages.fulfilled, getSideNavbarPagesFulfilled);
    addCase(getSideNavbarPages.rejected, handleRejected);

    addCase(createAccessGroup.pending, handlePending);
    addCase(createAccessGroup.fulfilled, createAccessGroupFulfilled);
    addCase(createAccessGroup.rejected, createAccountRejected);

    addCase(getPagesList.pending, handlePending);
    addCase(getPagesList.fulfilled, getPagesFulfilled);
    addCase(getPagesList.rejected, handleRejected);

    addCase(getActionsList.pending, handlePending);
    addCase(getActionsList.fulfilled, getActionsFulfilled);
    addCase(getActionsList.rejected, handleRejected);

    addCase(updateAccessGroup.pending, handlePending);
    addCase(updateAccessGroup.fulfilled, updateAccessGroupFullfilled);
    addCase(updateAccessGroup.rejected, handleRejected);
  },
});

export const {
  setViewAccessGroup,
  setWriteAccessGroup,
  setViewAccess,
  setWriteAccess,
  resetViewAccess,
  resetViewAccessGroup,
  resetWriteAccess,
  resetWriteAccessGroup,
  resetPageAndActionIds,
} = accessGroupSlice.actions;
export default accessGroupSlice.reducer;
