import React from 'react';
import { styled } from '@mui/system';
import { Grid, Typography, FormHelperText } from '@mui/material';
import { FormikErrors, FormikHandlers, FormikHelpers, FormikValues } from 'formik';
import Divider from '../../../../components/Divider';

import { CreateCarrierPayload } from '../../../../store/reducers/Carrier/Type';
import SbTextField from '@Components/Textfield';
import DragDrop from '@Components/File/DragDrop';
import { FileResultType } from '../../../../Helper/file';
import { SbSwitch } from '@Components/Switch';
import SbTooltip from '@Components/Tooltip';
import { InfoRounded } from '@mui/icons-material';

interface CreateCarrierFormProps {
  formType?: 'create' | 'edit';
  values: FormikValues;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: FormikHelpers<CreateCarrierPayload>['setFieldValue'];
  errors?: FormikErrors<CreateCarrierPayload>;
}

export const initCreateCarrier: CreateCarrierPayload = {
  name: '',
  logo: null,
  isAccountHolder: false,
};

function CreateCarrier(props: CreateCarrierFormProps) {
  const { formType, values, errors, handleChange, setFieldValue } = props;

  const onFileChange = (fileData: FileResultType[]) => {
    setFieldValue('logo', fileData[0]);
  };
  return (
    <Grid>
      <Grid item xs={12}>
        <Typography className="org-text underline">
          {(formType === 'edit' && 'Edit') || (formType === 'create' && 'Create')} new carrier
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
          <FormLabel className="md-text font-medium">Carrier Name *</FormLabel>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <FormContent>
            <SbTextField
              required
              placeholder="Enter Carrier Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              error={errors?.name}
            />
          </FormContent>
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
          <FormLabel className="md-text font-medium">Logo *</FormLabel>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <FormContent className="w-full">
            <DragDrop
              accept="image/*"
              onChange={onFileChange}
              subText={
                values.logo && typeof values.logo === 'object'
                  ? `${values.logo.fileName} (${values.logo.fileSize})`
                  : 'Supported formats: JPEG, PNG (450x225px, File upto 5 mb)'
              }
              readType="base64"
            />
            {errors?.logo && (
              <FormHelperText error className="m-0">
                {typeof errors.logo === 'string' ? errors.logo : 'Logo is required'}
              </FormHelperText>
            )}
          </FormContent>
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
      <Grid item container xs={12} alignItems="center">
        <Grid container item xs={12} md={4} lg={3} mb={{ xs: 1 }} alignItems="center">
          <AccountHolderFormLabel className="md-text font-medium">
            Are you an existing account holder ?
          </AccountHolderFormLabel>
          <SbTooltip placement="bottom-start" title="Select this option if you need integrations with this carrier.">
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <SbSwitch
            label=""
            onChange={handleChange}
            name="isAccountHolder"
            value={values.isAccountHolder}
            defaultChecked={values.isAccountHolder}
          />
        </Grid>
        <Divider className="mt-3 mb-3" />
      </Grid>
    </Grid>
  );
}

const FormLabel = styled(Typography)`
  min-width: 280px;
`;

const AccountHolderFormLabel = styled(Typography)`
  width: 200px;
  textwrap: balance;
`;

const FormContent = styled('div')`
  max-width: 512px;
`;

export default CreateCarrier;
