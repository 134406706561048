import Divider from '@Components/Divider';
import SBDropdown from '@Components/Dropdown';
import { DropdownPropsType } from '@Components/Dropdown/Type';
import { FormContent, FormLabel } from '@Components/Form';
import { RootStateType } from '@Store';
import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

interface PropsType {
  value: string[];
  onChange: DropdownPropsType['onChange'];
  multiple?: boolean;
  error?: string;
  disabled?: boolean;
  removerBorder?: boolean;
  showInputLabel?: boolean;
}

function CSP(props: PropsType) {
  const { showInputLabel = false } = props;
  const carrierAndService = useSelector((state: RootStateType) => state.carrierAndService);
  const cspOptions = carrierAndService?.selectedServiceList?.map(csp => ({ display: csp.name, value: csp._id }));
  return (
    <Grid item container xs={12} alignItems="center">
      {!showInputLabel && (
        <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
          <FormLabel>Service*</FormLabel>
        </Grid>
      )}
      <Grid item xs={12} md={showInputLabel ? 12 : 8} lg={showInputLabel ? 12 : 9}>
        <FormContent>
          <SBDropdown
            id="Service"
            required
            label={showInputLabel ? 'Service' : ''}
            options={cspOptions}
            onChange={props.onChange}
            name="csp"
            value={props.value}
            error={props.error}
            disabled={props.disabled}
            placeholder="Select Service"
          />
        </FormContent>
      </Grid>

      {!props.removerBorder && <Divider className="my-2" />}
    </Grid>
  );
}

export default CSP;
