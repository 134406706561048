import React, { useEffect } from 'react';

interface DynamicHeadProps {
  faviconUrl: string;
  title: string;
}

const DynamicHead: React.FC<DynamicHeadProps> = ({ faviconUrl, title }) => {
  useEffect(() => {
    // Set favicon
    const favicon: HTMLLinkElement | null =
      document.querySelector("link[rel*='icon']") || document.createElement('link');
    favicon.rel = 'icon';
    favicon.type = 'image/png';
    favicon.href = faviconUrl;
    document.head.appendChild(favicon);

    // Set title
    document.title = title;

    // Clean up on unmount (optional)
    return () => {
      document.head.removeChild(favicon);
    };
  }, [faviconUrl, title]);

  return null;
};

export default DynamicHead;
