import React, { useEffect } from 'react';
import SearchInput from './SearchInput';
import Modal from '@Components/Modal';
import DataTable from './DataTable';
import { Close } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { UserObj } from '@Reducers/AccountLookup/Type';
import useAccountLookup from '@Hook/useAccountLookup';

interface PropsType {
  children?: React.ReactNode;
  getSelectedUser?: (user: UserObj) => void;
  showSearchInput?: boolean;
  modalId: string;
  disableUserReset?: boolean;
}

function AccountLookup(props: PropsType) {
  const modalId = props.modalId;
  const { open, resetSelectedUser, onClose } = useAccountLookup(modalId);

  useEffect(() => {
    return () => {
      if (!props.disableUserReset) resetSelectedUser();
    };
  }, []);

  return (
    <>
      {props.showSearchInput && <SearchInput modalId={modalId} />}
      <Modal
        open={open}
        dialogContainerSx={{
          padding: 6,
        }}
        maxWidth={'lg'}
      >
        <>
          <Grid container justifyContent={'space-between'}>
            <Grid item className="mb-2">
              {props?.children}
            </Grid>
            {/* {selectedUser?._id && ( */}
            <Grid item>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
            {/* )} */}
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={6} md={5}>
              <SearchInput modalId={modalId} searchDefault />
            </Grid>
          </Grid>
          <DataTable modalId={modalId} getSelectedUser={props?.getSelectedUser} />
        </>
      </Modal>
    </>
  );
}

export default React.memo(AccountLookup);
