import React, { useState } from 'react';

import { Grid } from '@mui/material';
import CreateServiceForm, { initCreateService } from './Form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { createService } from '../../../store/reducers/CarrierAndService';
import { useFormik } from 'formik';
import SbButton from '../../../components/Button';
import { CreateServicePayload } from '@Reducers/CarrierAndService/Type';
import { CreateServiceSchema } from './schema';
import { useNavigate } from 'react-router-dom';

function CreateService() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [system, _setSystem] = useState<CreateServicePayload>({ ...initCreateService });

  const onCreate = (params: CreateServicePayload) => {
    const payload = {
      name: params.name,
      logo: params.logo,
      service: params.service,
      dimWtDivisor: params.dimWtDivisor,
      carrierId: params.carrierId,
      boxWiseRates: params.boxWiseRates,
      copyCarrierLogo: params.copyCarrierLogo,
      totalWiseRates: params.totalWiseRates,
    };
    dispatch(createService(payload));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...system },
    validationSchema: CreateServiceSchema,
    onSubmit(values) {
      // console.log({ values });
      onCreate(values);
    },
  });

  const onCancel = () => {
    navigate('/carrier-and-services?tab=service');
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="p-4">
          <CreateServiceForm
            formType="create"
            errors={formik.errors}
            values={formik.values}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
          />
        </Grid>
        <Grid container item mt={4} spacing={2} justifyContent="flex-end">
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton fullWidth variant="outlined" onClick={onCancel}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton
              fullWidth
              variant="contained"
              onClick={_event => {
                formik.handleSubmit();
              }}
            >
              Create
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateService;
