import Title from '@Components/Title';
import { Notification, NotificationResponse } from '@Reducers/Notifications/Type';
import {
  downloadNotificationReportFile,
  getAllReadNotifications,
  getAllUnreadNotifications,
  putAllNotificationsRead,
  putNotificationReadById,
} from '@Reducers/Notifications/actions';
import { AppDispatch, RootStateType } from '@Store';
import { FiberManualRecord } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, IconButton, Stack, Tab, Typography, styled } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { onMessageListener } from '../../firebase';
import { userLogin } from '@Services/hasAdminAccess';
import SbButton from '@Components/Button';
dayjs.extend(relativeTime);

interface PropsType {
  icon: React.ReactNode;
}

type TabValues = 'unread' | 'all' | string;

const TABS: { label: string; value: string }[] = [
  {
    value: 'unread',
    label: 'Unread',
  },
  {
    value: 'all',
    label: 'All',
  },
];

const dateFormat = (apiDate: string) => {
  // Get the current date
  const currentDate = dayjs();

  // Convert the API date to dayjs object
  const apiDateObject = dayjs(apiDate);

  // Check if the API date is the same as the current date
  if (apiDateObject.isSame(currentDate, 'day')) {
    // If it's the same day, display "Today"
    return 'Today';
  } else {
    // If it's a different day, format and display the date
    const formattedDate = `${apiDateObject.format('DD/MM/YYYY')}, ${apiDateObject.format('dddd')}`; // You can customize the format
    return formattedDate;
  }
};

function Count({ icon }: PropsType) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [readCurrentPage, setReadCurrentPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch<AppDispatch>();
  const [tabValue, setTabValue] = useState<TabValues>('unread');
  const { count, notifications, totalReadNotifications, readNotifications, limit } = useSelector(
    (state: RootStateType) => state.notifications,
  );

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    setTabValue(currentTab);
  };

  useEffect(() => {
    dispatch(
      getAllUnreadNotifications({
        page: 1,
        limit,
      }),
    );
    dispatch(getAllReadNotifications({ page: 1, limit }));
  }, []);

  const handleOpenNotifications = () => {
    setIsDrawerOpen(true);
    // dispatch(getAllUnreadNotifications());
  };

  //Notification Listener
  onMessageListener(dispatch);

  useEffect(() => {
    if (readCurrentPage > 1) {
      dispatch(getAllReadNotifications({ page: 1, limit: limit * readCurrentPage }));
    }
  }, [readCurrentPage]);

  useEffect(() => {
    if (currentPage > 1) {
      dispatch(getAllUnreadNotifications({ page: 1, limit: limit * currentPage }));
    }
  }, [currentPage]);

  return (
    <>
      <Wrapper>
        <Box onClick={handleOpenNotifications} className="cursor-pointer">
          {icon}
          <span className="count">{count}</span>
        </Box>
      </Wrapper>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
        }}
      >
        <Box sx={{ width: { xs: '27rem', sm: '30rem' }, padding: '2rem 1rem 2rem 2rem', height: '100%' }}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
            <Title title="Notifications" removeBorder removePadding />
            <IconButton
              sx={{
                fontSize: '1.5rem',
              }}
              onClick={() => setIsDrawerOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <TabContext value={tabValue}>
            <TabList
              onChange={onTabChange}
              indicatorColor="primary"
              TabIndicatorProps={{ sx: { height: '4px' } }}
              scrollButtons="auto"
              variant="scrollable"
              sx={{
                minHeight: '28px',
                mt: '1rem',
              }}
            >
              {TABS.map(cTab => (
                <Tab
                  sx={{ textTransform: 'none', padding: '0px 8px', minHeight: 'auto' }}
                  className="md-text font-medium sb-text-black"
                  value={cTab.value}
                  label={cTab.label}
                  key={cTab.value}
                />
              ))}
            </TabList>
            {/* {loading ? (
              <Stack justifyContent={'center'} alignItems={'center'} height={'80vh'}>
                <CircularProgress color="primary" />
              </Stack>
            ) : (
              <> */}
            <TabPanel value="unread" className="p-0 w-full">
              {notifications?.length > 0 && (
                <Stack direction={'row'} justifyContent={'flex-end'}>
                  <Typography
                    className="md-text font-medium underline cursor-pointer"
                    onClick={() => {
                      dispatch(putAllNotificationsRead());
                    }}
                  >
                    Clear All
                  </Typography>
                </Stack>
              )}
              <InfiniteScroll
                className="pr-4 fadedScroll mt-2"
                dataLength={notifications?.flatMap(data => data?.notifications).length}
                next={() => {
                  if (count > notifications?.flatMap(data => data?.notifications).length) {
                    setCurrentPage(prev => prev + 1);
                  }
                }}
                hasMore={count > notifications?.flatMap(data => data?.notifications).length}
                loader={<h4>Loading...</h4>}
                height={600}
                endMessage={
                  count > 0 && count === notifications?.flatMap(data => data?.notifications).length ? (
                    <p className="text-center mt-2">
                      <b>Yay! You have seen it all</b>
                    </p>
                  ) : (
                    <></>
                  )
                }
              >
                {notifications?.length > 0 ? (
                  notifications?.map((data: NotificationResponse) =>
                    data?.notifications?.map((notification: Notification) => (
                      <Card
                        key={notification?._id}
                        {...notification}
                        allowClose={true}

                        // borderColor=""
                      />
                    )),
                  )
                ) : (
                  <Stack justifyContent={'center'} alignItems={'center'} height={'60vh'}>
                    <Typography color={'primary.dark'} fontSize={'1.5rem'} variant="subtitle1">
                      Notifications not found
                    </Typography>
                  </Stack>
                )}
              </InfiniteScroll>
            </TabPanel>
            <TabPanel value="all" className="p-0 w-full">
              <InfiniteScroll
                className="pr-4 fadedScroll"
                style={{ overflow: 'hidden', overflowY: 'auto' }}
                dataLength={readNotifications?.flatMap(data => data?.notifications).length}
                next={() => {
                  if (totalReadNotifications > readNotifications?.flatMap(data => data?.notifications).length) {
                    setReadCurrentPage(prev => prev + 1);
                  }
                }}
                hasMore={totalReadNotifications > readNotifications?.flatMap(data => data?.notifications).length}
                loader={<h4>Loading...</h4>}
                height={640}
                endMessage={
                  totalReadNotifications > 0 &&
                  totalReadNotifications === readNotifications?.flatMap(data => data?.notifications).length ? (
                    <p className="text-center mt-2">
                      <b>Yay! You have seen it all</b>
                    </p>
                  ) : (
                    <></>
                  )
                }
              >
                {readNotifications?.length > 0 ? (
                  readNotifications?.map((data: NotificationResponse) => (
                    <div key={data?._id} className="mt-2">
                      <Typography color={'primary.dark'} fontWeight={600} variant="subtitle1">
                        {dateFormat(data?._id)}
                      </Typography>
                      {data?.notifications?.map((notification: Notification) => (
                        <Card
                          key={notification?._id}
                          {...notification}
                          allowClose={false}

                          // borderColor=""
                        />
                      ))}
                    </div>
                  ))
                ) : (
                  <Stack justifyContent={'center'} alignItems={'center'} height={'60vh'}>
                    <Typography color={'primary.dark'} fontSize={'1.5rem'} variant="subtitle1">
                      Notifications are not found!
                    </Typography>
                  </Stack>
                )}
              </InfiniteScroll>
            </TabPanel>
          </TabContext>
        </Box>
      </Drawer>
    </>
  );
}

function Card(
  props: Notification & {
    allowClose: boolean;
  },
) {
  const { title, createdAt, message, _id, data, allowClose } = props;
  const dispatch = useDispatch<AppDispatch>();
  const isUser = userLogin();
  // const withBorder = { border: '2px solid', borderLeft: {${borderColor} } };
  // const withoutBorder = { border: '2px solid', borderColor: 'border.grey' };

  // const boxStyles = borderColor ? withBorder : withoutBorder;
  const extractShipmentId = (message: any) => {
    const match = message.match(/You have been tagged in a new note on shipment (\d{12})/);
    return match ? match[1] : null;
  };

  const customUrl = () => {
    if (/http/.test(data?.url)) {
      return `${data?.url || ''}`;
    }

    if (title === 'You have been tagged in a new note') {
      return `/shipment/admin/edit/${extractShipmentId(message)}`;
    }
    if (/Status changed for Shipment Number (\d{12})/.test(title)) {
      const match = title.match(/Status changed for Shipment Number (\d{12})/);
      return `/track-shipment?trackingNumber=${match?.[1]}`;
    }
    if (/Shipment (\d{12}) has been cancelled!/.test(title)) {
      const match = title.match(/Shipment (\d{12}) has been cancelled!/);
      return `/track-shipment?trackingNumber=${match?.[1]}`;
    }
    if (/Shipment (\d{12}) Confirmed!/.test(title)) {
      const match = title.match(/Shipment (\d{12}) Confirmed!/);
      return `/track-shipment?trackingNumber=${match?.[1]}`;
    }
    if (title === 'Change in shipment charges') {
      const match = message.match(/shipment id (\d{12}) has been changed/);
      return match ? `/shipment/history/${match[1]}` : '';
    }

    if (title === 'New invoice generated.') {
      return '/invoice/list';
    }

    if (title === 'Bulk shipment summary') {
      return '/shipment/bulk-summary';
    }

    if (/Payment for order ID (\d{12}) successfully completed\./.test(title)) {
      const match = title.match(/Payment for order ID (\d{12}) successfully completed\./);
      return `/track-shipment?trackingNumber=${match?.[1]}`;
    }

    if (title === 'Funds successfully deposited to your wallet.') {
      return '/transactions/wallet';
    }

    if (title === 'Credit limited updated') {
      return '/transactions/credit';
    }
    if (title === 'Credit limit exhausted!') {
      return '/transactions/credit';
    }

    if (title === 'Payment received') {
      const match = message.match(/Amount of (\d{12}) received for Order number\/invoice number/);
      return match ? `/shipment/history/${match[1]}` : '';
    }
    if (title === 'New credit note generated.' || title === 'New debit note generated.') {
      return '/invoice/list';
    }

    if (data?.type === 'user' || data?.type === 'admin' || data?.type === 'squad') {
      return `/profile`;
    }
    if (data?.type === 'shipment') {
      return isUser ? `/shipment/history/${data?.url || ''}` : `/shipment/admin/edit/${data?.url || ''}`;
    }
    if (data?.type === 'invoice') {
      return '/invoice/list';
    }
    return '';
  };

  const downloadReportFile = async (reportId: string) => {
    await dispatch(downloadNotificationReportFile(reportId));
  };

  return (
    <Link to={customUrl()} className="sb-text-black text-decoration-none">
      <Box
        className="flex sb-gap-1 border-solid border border-light rounded-md px-4 py-2 mt-2"
        sx={{
          padding: '0.75rem',
          width: '100%',
        }}
      >
        <Box
          sx={{
            padding: '0.75rem',
            width: '100%',
          }}
        >
          <Box className="flex" sx={{ justifyContent: 'space-between' }}>
            <Box className="flex items-center" sx={{ gap: '0.5rem' }}>
              <Typography className="notification-grey xss-text" textTransform={'uppercase'}>
                {data?.type}
              </Typography>
              {allowClose && (
                <>
                  <FiberManualRecord className="notification-grey" sx={{ color: 'background.grey', width: '0.5rem' }} />
                  <Typography className="notification-grey xss-text">{dayjs(createdAt)?.fromNow()}</Typography>
                </>
              )}
            </Box>
            {allowClose && (
              <Box>
                <IconButton
                  onClick={e => {
                    dispatch(putNotificationReadById(_id));
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box>
            <Typography className="font-medium md-text mb-1">{title} </Typography>
          </Box>
          <Box>
            <Typography
              className="sm-text font-normal"
              component={'div'}
              sx={{
                lineHeight: 1.3,
                textDecoration: 'none !important',
              }}
              dangerouslySetInnerHTML={{
                __html: message.replace(/\n/g, '<br/>'),
                // __html: message,
              }}
            ></Typography>
          </Box>
          {data?.reportId && (
            <SbButton
              variant="outlined"
              sx={{
                textTransform: 'none',
              }}
              containerClass="rounded mt-2 border-solid border border-light"
              startIcon={<img src="/images/file_download.svg" />}
              onClick={() => {
                if (data?.reportId) {
                  downloadReportFile(data?.reportId);
                }
              }}
            >
              Download
            </SbButton>
          )}
        </Box>
      </Box>
    </Link>
  );
}

export default Count;

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '44px',
  width: '44px',
  backgroundColor: '#f4f3f7',
  borderRadius: '12px',
  marginRight: '10px',
  position: 'relative',
  '& .count': {
    position: 'absolute',
    right: '2px',
    bottom: '5px',
    background: theme.palette.secondary.main,
    height: '20px',
    padding: '0 5px',
    borderRadius: '50px',
    color: theme.palette.primary.contrastText,
  },
}));
