import { TableRow, styled } from '@mui/material';

const SBTableRow = styled(TableRow)`
  border-bottom: 1px solid var(--mui-palette-border-tableRow);

  & > td:first-of-type > div {
    border-left: 0px;
  }
  &.selected {
    background: var(--mui-palette-primary-light);
  }
  &:hover {
    background: var(--mui-palette-primary-light);
  }
`;

export { SBTableRow };
