import { fileType as validExtensions, ExtensionsType, validFileTypes } from '../constants/file';
import * as XLSX from 'xlsx';

export interface FileResultType {
  fileName: string;
  fileType: ExtensionsType;
  fileExtension: validFileTypes;
  fileData: FileReader['result'];
  fileSize: string;
}

function byteConverter(bytes: number, decimals?: number, onlyMb?: boolean): string {
  const K_UNIT = 1024;
  const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  if (bytes == 0) return '0 Byte';
  if (onlyMb) return (bytes / (K_UNIT * K_UNIT)).toFixed(decimals) + ' MB';
  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  const resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + ' ' + SIZES[i];
  return resp;
}

function fileDataConvertToBinary(file: File) {
  const { type: fileType, name: fileName } = file;
  const fileExtension = validExtensions[fileType];
  return new Promise((res, _rej) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const { target } = event;
      const fileData = target?.result;
      const fileSize = byteConverter(file.size);
      res({ fileName, fileType, fileExtension, fileData, fileSize });
    };
    reader.readAsArrayBuffer(file);
  });
}

async function getFileData(files: File[], readType?: 'xls' | 'base64') {
  const fileReadCallbackFunc =
    (readType === 'xls' && readXlsFile) || (readType === 'base64' && getBase64) || fileDataConvertToBinary;
  return await Promise.all(files.map(file => fileReadCallbackFunc(file)));
}

async function getBase64(file: File) {
  const { type: fileType, name: fileName } = file;
  const fileExtension = validExtensions[fileType];
  return new Promise((res, _rej) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const { target } = event;
      const fileData = target?.result;
      const fileSize = byteConverter(file.size);
      res({ fileName, fileType, fileExtension, fileData, fileSize });
    };
    reader.readAsDataURL(file);
  });
}

async function readXlsFile(file: File) {
  const { type: fileType, name: fileName } = file;
  const fileExtension = validExtensions[fileType];
  return new Promise((res, _rej) => {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      const { target } = event;
      const fileData = target?.result;
      const workbook = XLSX.read(fileData, { type: 'binary' });
      const sheetNames = workbook.SheetNames;
      const sheetData = sheetNames.map(currSheetName => {
        const workSheet = workbook.Sheets[currSheetName];
        const data = XLSX.utils.sheet_to_json(workSheet, { header: 1, blankrows: false });
        const obj = {
          sheetName: currSheetName,
          data: data,
        };
        return obj;
      });
      const fileSize = byteConverter(file.size);
      res({ fileName, fileType, fileExtension, fileData: sheetData, fileSize });
    };
    reader.readAsBinaryString(file);
  });
}

export default getFileData;
