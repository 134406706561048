import API from '@Services/apiAxios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface ExportState {
  url: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: ExportState = {
  url: null,
  loading: false,
  error: null,
};

// Async thunk for exporting data
export const fetchExportData = createAsyncThunk(
  'export/fetchExportData',
  async (
    params: {
      accountUserId: string | undefined;
      fromDate: string;
      toDate: string;
      currentPage: number;
      perPage: number;
    },
    { rejectWithValue },
  ) => {
    try {
      const { accountUserId, fromDate, toDate, perPage, currentPage } = params;
      const response = await API.get(`payment/wallet/download-all/${accountUserId}`, {
        params: {
          currentPage,
          perPage,
          fromDate,
          toDate,
        },
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.msg || 'Failed to fetch export data');
    }
  },
);

const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    // Reset the state to initial values
    resetUserExportState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchExportData.pending, state => {
        state.loading = true;
        state.error = null;
        state.url = null;
      })
      .addCase(fetchExportData.fulfilled, (state, action) => {
        state.loading = false;
        state.url = action.payload.url;
      })
      .addCase(fetchExportData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

// Export the reset action to use in the component
export const { resetUserExportState } = exportSlice.actions;

export default exportSlice.reducer;
