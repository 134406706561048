import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';

import { Typography } from '@mui/material';

import BalanceIcon from '@mui/icons-material/PaymentsOutlined';
import CreditIcon from '@mui/icons-material/CreditScoreOutlined';
import AvailableLimitIcon from '@mui/icons-material/MonetizationOnOutlined';
import SbButton from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../store';
import { getOutstandingBalanceForPrepaid, getWalletBalance } from '@Reducers/Wallet';
import AddMoneyModal from '../Transactions/Wallet/AddMoneyModal';
import { getCreditLimit, getOutstandingBalanceForPostpaid } from '@Reducers/Credit';
import { formatNumber, useLocale } from '../../utils/formatNumber';

const NavigationBtnContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, max-content)',
  gridGap: '24px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const NavigationBtn = styled('div')(({ theme }) => ({
  height: '92px',
  padding: '20px',
  display: 'grid',
  gridTemplateColumns: '32px 1fr',
  alignItems: 'center',
  border: '1px solid',
  borderColor: 'var(--mui-palette-border-light)',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    gridTemplateColumns: '32px 1fr !important',
  },
}));

const IconStyle = { width: '33px', height: '33px' };

function Navigation() {
  const dispatch = useDispatch<AppDispatch>();
  const loginUser = useSelector((state: RootStateType) => state.loginUser);
  const [addMoneyModal, setAddMoneyModal] = useState<boolean>(false);
  const { creditLimit, outstandingBalance, availableLimit } = useSelector((state: RootStateType) => state.Credit);
  const { currentBalance, outstandingBalance: WalletOutstandingBalance } = useSelector(
    (state: RootStateType) => state.Wallet,
  );
  const orgCountryCurrency = useSelector(
    (state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.symbol || '',
  );
  const isAccountHolder = loginUser?.profile?._accountId?.isAccountHolder;
  const isPrepaid = loginUser?.profile?._accountId?.isPrepaid;
  const locale = useLocale();

  useEffect(() => {
    if (isPrepaid) {
      dispatch(getWalletBalance());
      dispatch(getOutstandingBalanceForPrepaid());
    } else {
      dispatch(getCreditLimit());
      dispatch(getOutstandingBalanceForPostpaid());
    }
  }, [isPrepaid]);

  // const updateOutStandingLimit = isPrepaid ? WalletOutstandingBalance : outstandingBalance;

  // const updatedAvailableLimit = creditLimit - updateOutStandingLimit;

  return (
    <NavigationBtnContainer>
      {addMoneyModal && <AddMoneyModal onClose={() => setAddMoneyModal(false)} />}
      <NavigationBtn>
        <BalanceIcon style={IconStyle} />

        <div className="ml-4">
          <Typography className="md-text">Outstanding balance</Typography>
          <Typography className="l-text">
            {orgCountryCurrency}{' '}
            {isPrepaid ? formatNumber(WalletOutstandingBalance, locale) : formatNumber(outstandingBalance, locale)}
          </Typography>
        </div>
      </NavigationBtn>
      {isAccountHolder && !isPrepaid && (
        <>
          <NavigationBtn>
            <CreditIcon style={IconStyle} />

            <div className="ml-4">
              <Typography className="md-text">Credit Limit</Typography>
              <Typography className="l-text">
                {orgCountryCurrency} {formatNumber(creditLimit, locale)}
              </Typography>
            </div>
          </NavigationBtn>
          <NavigationBtn>
            <AvailableLimitIcon style={IconStyle} />
            <div className="ml-4">
              <Typography className="md-text">Available Limit</Typography>
              <Typography className="l-text">
                {orgCountryCurrency} {formatNumber(availableLimit, locale)}
                {/* {orgCountryCurrency} {updatedAvailableLimit?.toFixed(2)} */}
              </Typography>
            </div>
          </NavigationBtn>
        </>
      )}
      {isPrepaid && (
        <NavigationBtn style={{ gridTemplateColumns: '32px 1fr 1fr', gap: '10px' }}>
          <CreditIcon style={IconStyle} />
          <div className="ml-4">
            <Typography className="md-text">Wallet Balance</Typography>
            <Typography className="l-text">
              {orgCountryCurrency} {formatNumber(currentBalance, locale) || 0}
            </Typography>
          </div>
          <SbButton
            className="md-text"
            variant="outlined"
            sx={{ borderRadius: '8px !important', gridColumn: { xs: '1 / 3', md: 'unset' } }}
            onClick={() => setAddMoneyModal(true)}
          >
            Add Money
          </SbButton>
        </NavigationBtn>
      )}
    </NavigationBtnContainer>
  );
}

export default Navigation;
