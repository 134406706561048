import { alpha, styled } from '@mui/material';
import React from 'react';
import OrganizationForm from './OrganizationForm';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';

import Header from './Header';
import OrganizationSetupSuccess from './OrganizationSetupSuccess';
import Loader from '@Components/Loader';
// import { organizationSetupRoute } from '@Constants/route';
import { useSearchParams } from 'react-router-dom';

// const Wrapper = styled('div')(({ theme }) => ({
//   background: alpha(theme.palette.background.speedbox?.light, 1),
//   padding: '46px 70px',
//   [theme.breakpoints.down('md')]: {
//     padding: '20px',
//   },
//   height: '100vh',
//   overflow: 'auto',
// }));

const Wrapper = styled('div')(({ theme }) => {
  let backgroundColor = '#fff'; // Fallback color

  // Error handling for accessing nested properties
  try {
    backgroundColor = alpha(theme.palette.background.speedbox?.light || '#fff', 1);
  } catch (error) {
    console.error('Error accessing theme properties: ', error);
  }

  return {
    background: backgroundColor,
    padding: '46px 70px',
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
    height: '100vh',
    overflow: 'auto',
  };
});

// const ComponentWrapper = styled('div')(({ theme }) => ({
//   padding: '60px',
//   borderRight: '0',
//   borderBottom: '0',
//   borderRadius: '40px',
//   background: alpha(theme.palette.background.white, 1),
//   [theme.breakpoints.down('md')]: {
//     padding: '20px',
//   },
//   // [theme.breakpoints.down('xl')]: {
//   //   padding: '18px',
//   // },
// }));

const ComponentWrapper = styled('div')(({ theme }) => {
  let backgroundColor = '#fff'; // Fallback color

  // Error handling for accessing nested properties
  try {
    backgroundColor = alpha(theme.palette.background.white || '#fff', 1);
  } catch (error) {
    console.error('Error accessing theme properties: ', error);
  }

  return {
    padding: '60px',
    borderRight: '0',
    borderBottom: '0',
    borderRadius: '40px',
    background: backgroundColor,
    [theme.breakpoints.down('md')]: {
      padding: '20px',
    },
    // Uncomment if needed:
    // [theme.breakpoints.down('xl')]: {
    //   padding: '18px',
    // },
  };
});

function OrganizationOnboard() {
  const [searchParams] = useSearchParams();
  const { resetSaveOrgSuccess, organizationPublicData } = useSelector((state: RootStateType) => state?.orgSetup);
  const loading = useSelector((state: RootStateType) => state?.orgSetup.loading || state?.loginForm.loading);

  const token = searchParams.get('token');
  if (!token) return <>YOU DON'T HAVE ACCESS TO THIS PAGE!!</>;

  console.log('!resetSaveOrgSuccess || !organizationPublicData', !resetSaveOrgSuccess, organizationPublicData);
  return (
    <Wrapper id="speedbox">
      <ComponentWrapper>
        <Loader loading={loading} overly>
          <>
            <Header />
            {organizationPublicData ? (
              <OrganizationSetupSuccess />
            ) : !resetSaveOrgSuccess ? (
              <OrganizationForm />
            ) : (
              <OrganizationSetupSuccess />
            )}
          </>
        </Loader>
      </ComponentWrapper>
    </Wrapper>
  );
}

export default OrganizationOnboard;
