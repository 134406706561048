import { PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../types';
import { ContractListResponse, ContractReducerState } from './Type';

export function pending(state: ContractReducerState) {
  state.loading = true;
  state.isAdded = false;
  state.isDeleted = false;
}

export function onreset(state: ContractReducerState) {
  state.isAdded = false;
  state.isDeleted = false;
}

export function rejected(state: ContractReducerState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = { msg: action.payload?.msg || 'error', isError: true };
}

export function getContractListFulfilled(
  state: ContractReducerState,
  { payload }: PayloadAction<ContractListResponse>,
) {
  state.data = payload.data;
  state.loading = false;
}

export function addNewContractFulfilled(state: ContractReducerState) {
  state.loading = false;
  state.isAdded = true;
}

export function deleteContractFulfilled(state: ContractReducerState) {
  state.loading = false;
  state.isDeleted = true;
}
