import { PayloadAction } from '@reduxjs/toolkit';
import { AccountListResponse, AccountLookupState, ErrorType, UserQuery } from './Type';

function handlePending(
  state: AccountLookupState,
  action: PayloadAction<undefined, string, { arg: [string, UserQuery] }>,
) {
  const [modalId] = action.meta.arg;
  const stateObj = state[modalId] || {};
  stateObj.loading = true;
  stateObj.error = { msg: '', errorDetails: '', isError: false };
  state[modalId] = stateObj;
}

function handleRejected(state: AccountLookupState, action: PayloadAction<ErrorType | undefined>) {
  const { modalId } = action.payload || { modalId: '' };
  const stateObj = state[modalId] || {};

  stateObj.loading = false;
  stateObj.error = { msg: 'Oops! Something went wrong.', isError: true };
  // stateObj.viewListModal = false;
  state[modalId] = stateObj;
}

function getUserListFulfilled(state: AccountLookupState, action: { payload: AccountListResponse }) {
  const { modalId, lists, params: userQuery, totalCount, currentPage, perPage } = action.payload;

  const stateObj = state[modalId] || {};
  stateObj.userList = lists;
  stateObj.loading = false;
  stateObj.viewListModal = true;
  stateObj.userQuery = userQuery;
  state[modalId] = stateObj;
  stateObj.totalCount = totalCount;
  stateObj.currentPage = currentPage;
  stateObj.perPage = perPage;
}

export { handlePending, handleRejected, getUserListFulfilled };
