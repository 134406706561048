import React from 'react';
import { FiberManualRecord } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';

interface UserReportCardProps {
  type?: string;
  url: string;
  createdAt?: string;
  status?: string;
}

// Function to update the label based on the type
const updateLabel = (typeToMatch: string) => {
  const options = [
    { type: 'ACCOUNTS', label: 'Account Detailed' },
    { type: 'ACCOUNTS_SUMMARY', label: 'Accounts Summary' },
    { type: 'CS', label: 'Customer Service Detailed' },
    { type: 'OP', label: 'Operation Detailed' },
    { type: 'SALES', label: 'Sales Detailed' },
    { type: 'ALL', label: 'Shipment Report Detailed' },
    { type: 'CS_SUMMARY', label: 'Customer Service Summary' },
    { type: 'OP_SUMMARY', label: 'Operation Summary' },
    { type: 'SALES_SUMMARY', label: 'Sales Summary' },
    { type: 'ALL_SUMMARY', label: 'Shipment Report Summary' },
    { type: 'USER_SUMMARY', label: 'Shipment Report Summary' },
  ];

  // Find the matching type and return the updated label
  const matchedOption = options.find(option => option.type === typeToMatch);
  return matchedOption ? 'Shipment Report' : typeToMatch;
};

const UserReportCard: React.FC<UserReportCardProps> = ({ type, url, createdAt, status }) => {
  let label;
  if (type) {
    label = updateLabel(type);
  }
  const userName = useSelector((state: RootStateType) => state.loginUser.token?.name);

  // Function to handle download button click
  const handleDownload = () => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  // Determine button text and disabled state based on the url
  const isDownloading = url === '';
  const buttonText = isDownloading ? 'Download in Progress' : 'Click here to download';

  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: '16px',
        padding: '16px',
        border: '2px solid #d5d5d5',
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
        color: '#212062',
        cursor: isDownloading ? 'not-allowed' : 'pointer',
        marginTop: '10px',
      }}
      onClick={handleDownload}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <p className="notification-grey xss-text">{userName}</p>

        <FiberManualRecord className="notification-grey" sx={{ color: 'background.grey', width: '0.5rem' }} />
        <p className="notification-grey xss-text">{dayjs(createdAt)?.fromNow()}</p>
      </div>
      <p style={{ fontSize: '16px', fontWeight: '500' }}>{label} </p>
      <p
        style={{
          color: isDownloading ? '#aaa' : '#212062',
          borderRadius: '4px',
          border: 'none',
          transition: 'background-color 0.2s',
          width: 'fit-content',
          fontSize: '14px',
        }}
      >
        {status === 'failed' ? 'Failed to download, Please try again' : buttonText}
      </p>
    </div>
  );
};

export default UserReportCard;
