import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, InputLabel } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { OptionType, PropsType } from './Type';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Box } from '@mui/system';

export default function SearchDropdown(props: PropsType) {
  const { id, label, onChange, options, defaultValue, placeholder, required, hideStartAdornment, disabled } = props;
  const { value: propsValue } = props;

  const [inputValue, setInputValue] = React.useState<string>('');
  const multiSelectedValues: OptionType[] = propsValue instanceof Array ? [...propsValue] : [];
  const singleSelectedValues: OptionType | undefined = !props.multiple ? propsValue[0] : undefined;

  return (
    <Grid className="w-full">
      {label && (
        <InputLabel className="mb-1" id={`${id}-label`} required={required}>
          {label}
        </InputLabel>
      )}
      {props.multiple ? (
        <Autocomplete
          multiple
          id="tags-outlined"
          options={options.filter(
            option => !multiSelectedValues.some(selectedUser => selectedUser.value === option.value),
          )}
          getOptionLabel={option => (typeof option === 'string' ? option : option?.display)}
          defaultValue={defaultValue instanceof Array ? [...defaultValue] : []}
          value={multiSelectedValues}
          onChange={(_event: React.SyntheticEvent, newValue: OptionType[] | null): void => {
            onChange?.(newValue, _event);
          }}
          renderInput={params => <TextField {...params} label="" placeholder="User Name" />}
          disabled={disabled}
        />
      ) : (
        <Autocomplete
          disableClearable
          fullWidth
          defaultValue={defaultValue?.[0]}
          sx={{ width: 300 }}
          id={`${id}-dropdown`}
          getOptionLabel={option => (typeof option === 'string' ? option : option.display)}
          options={options}
          autoComplete
          includeInputInList
          classes={{ inputRoot: 'rounded-xs' }}
          className="w-full bg-white rounded-xs"
          filterSelectedOptions
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /**@ts-ignore */
          value={singleSelectedValues || ''}
          // noOptionsText="No locations"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /**@ts-ignore */
          onChange={(_event: React.SyntheticEvent, newValue: OptionType): void => {
            // setValue(newValue);
            onChange?.([newValue], _event);
          }}
          disabled={disabled}
          onInputChange={(_event: React.SyntheticEvent, newInputValue) => setInputValue(newInputValue)}
          renderInput={params => (
            <TextField
              {...params}
              id={`${id}-label`}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                style: { padding: '2px', paddingLeft: '10px' },
                startAdornment: hideStartAdornment ? <></> : <SearchIcon sx={{ ml: 1 }} color="primary" />,
              }}
            />
          )}
          renderOption={(props, option) => {
            const display = (option as OptionType).display || '';
            const matches = match(display, inputValue);
            const parts = parse(display, matches);
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item sx={{ width: 'calc(100% - 40px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component="span"
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                        // className={part.highlight ? 'font-bold' : 'font-normal'}
                      >
                        {part.text}
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    </Grid>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
//  <SearchDropdown id="test" label="TEST" onChange={console.log} options={[]} />
// const top100Films = [{ display: 'The Shawshank Redemption', value: 1994 }];
