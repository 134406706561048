import React from 'react';
import ErrorModal from '@Components/Modal/error';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { resetShipmentError as resetError, resetShipmentUploadState } from '@Reducers/AdminShipment';

function SuccessError() {
  const dispatch = useDispatch<AppDispatch>();

  const ShipmentUpdate = useSelector((state: RootStateType) => state.adminShipment);
  const { subCharges } = useSelector((state: RootStateType) => state.adminShipment);

  const shipmentUpdateError = !!ShipmentUpdate.shipmentUpdateError;
  const shipmentUpdateSuccessFully = !!ShipmentUpdate.shipmentUpdated;
  const modalMsg =
    (shipmentUpdateError && 'Oops!') || (shipmentUpdateSuccessFully && 'Shipment successfully updated') || '';
  const modalSubMsg = (shipmentUpdateError && ShipmentUpdate.error?.msg) || (shipmentUpdateSuccessFully && '');

  const onClose = () => {
    if (shipmentUpdateSuccessFully) {
      dispatch(resetShipmentUploadState());
      return;
    }

    dispatch(resetError());
  };

  return (
    <ErrorModal
      icon={
        (shipmentUpdateSuccessFully && '/images/paid.svg') ||
        (shipmentUpdateError && '/images/Boxes/NoRatesFound.svg') ||
        ''
      }
      open={shipmentUpdateError || shipmentUpdateSuccessFully}
      title={modalMsg}
      subTitle={subCharges ? subCharges : modalSubMsg}
      onClose={onClose}
      onConfirm={onClose}
      btnText="Close"
      format={subCharges ? true : false}
    />
  );
}

export default SuccessError;
