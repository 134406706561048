import React, { useEffect, useState } from 'react';
import OrganizationSetupContext from './index';
import {
  Steps,
  bankDetailsDefaultData,
  kycSettingsDefaultData,
  termsAndConditionsDefaultData,
} from '../../pages/OrganizationSetup/constants';
import { useNavigate } from 'react-router-dom';
import {
  BankDetailsType,
  KycSettingsType,
  SacCodeType,
  TaxInformationType,
  TaxType,
  TermsAndConditionsType,
  companyAccountHolderType,
} from '../../types/organization';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';
import { getFormattedKey } from '../../services/getFormattedKey';

function OrganizationSetupWrapper(props: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const { taxList, documentList } = useSelector((state: RootStateType) => state.orgSetup);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [nonAccountHolder, setNonAccountHolder] = useState<KycSettingsType>({ ...kycSettingsDefaultData });
  const [individualAccountHolder, setIndividualAccountHolder] = useState<KycSettingsType>({
    ...kycSettingsDefaultData,
  });
  const [companyAccountHolder, setCompanyAccountHolder] = useState<companyAccountHolderType>({
    ...kycSettingsDefaultData,
    companyDetailsMandatory: true,
  });
  const [taxInformation, setTaxInformation] = useState<TaxInformationType[]>([]);
  const [sacCode, setSacCode] = useState<SacCodeType[]>([]);
  const [termsAndConditions, setTermsAndConditions] = useState<TermsAndConditionsType>(termsAndConditionsDefaultData);
  const [bankDetails, setBankDetails] = useState<BankDetailsType>(bankDetailsDefaultData);

  useEffect(() => {
    const list: TaxInformationType[] = [];
    taxList.map(i => {
      list.push({
        display: i,
        key: getFormattedKey(i),
        value: '',
      });
    });
    setTaxInformation([...taxInformation, ...list]);
  }, [taxList]);

  useEffect(() => {
    const list: TaxType[] = [];
    const list1: TaxType[] = [];
    const list2: TaxType[] = [];
    documentList.map(i => {
      list.push({
        label: i,
        isRequired: false,
      });
      list1.push({
        label: i,
        isRequired: false,
      });
      list2.push({
        label: i,
        isRequired: false,
      });
    });
    setNonAccountHolder({
      ...nonAccountHolder,
      list: [...list],
    });
    setIndividualAccountHolder({
      ...individualAccountHolder,
      list: [...list1],
    });
    setCompanyAccountHolder({
      ...companyAccountHolder,
      list: [...list2],
    });
  }, [documentList]);

  console.log({
    taxInformation,
    sacCode,
    termsAndConditions,
    bankDetails,
  });

  const handleNext = (activeStep: number) => {
    setActiveStep(activeStep + 1);
    navigate(Steps[Object.keys(Steps)[activeStep + 1]]);
  };

  const handleBack = (activeStep: number) => {
    setActiveStep(activeStep - 1);
    navigate(Steps[Object.keys(Steps)[activeStep - 1]]);
  };

  return (
    <OrganizationSetupContext.Provider
      value={{
        activeStep,
        setActiveStep,
        handleNext,
        handleBack,
        nonAccountHolder,
        setNonAccountHolder,
        individualAccountHolder,
        setIndividualAccountHolder,
        companyAccountHolder,
        setCompanyAccountHolder,
        taxInformation,
        setTaxInformation,
        sacCode,
        setSacCode,
        termsAndConditions,
        setTermsAndConditions,
        bankDetails,
        setBankDetails,
      }}
    >
      {props.children}
    </OrganizationSetupContext.Provider>
  );
}

export default OrganizationSetupWrapper;
