import * as Yup from 'yup';
import { DIM_WT_DIVISOR } from '@Constants/Shipment';
const { object, string, number, boolean } = Yup;

const serviceSO = Yup.object({
  // types: YUP.array('types must be an array.').of(YUP.string().trim()).min(0),
  legs: Yup.array().of(Yup.string().trim()).min(0),
  // mode: YUP.array('mode must be an array.').of(YUP.string().trim()).min(0),
}).default({});

const fileSchema = {
  fileName: string().required(),
  fileExtension: string().oneOf(['png', 'jpg', 'jpeg']),
  fileData: string().required(),
};

export const CreateServiceSchema = Yup.object({
  carrierId: string().required('No Carrier selected. Please select a carrier'),
  name: string().required('Please enter carrier name'),
  logo: Yup.object(fileSchema)
    .nullable()
    .default(null)
    .test('is-logo-required', 'Logo is required', (value, ctx) => {
      if (!ctx.parent.copyCarrierLogo && !value?.fileData) {
        return false;
      }
      ctx.parent.logo = null;
      return true;
    }),
  service: serviceSO,
  dimWtDivisor: object().shape({
    kg: number().default(DIM_WT_DIVISOR.kg),
    pound: number().default(DIM_WT_DIVISOR.pound),
  }),
  boxWiseRates: boolean().default(false),
  copyCarrierLogo: boolean().default(false),
  totalWiseRates: boolean().default(false),
});

export const UpdateServiceSchema = Yup.object({
  name: string().required(),
  service: serviceSO,
  logo: object({
    fileName: string().trim().required(),
    fileExtension: string().oneOf(['png', 'jpg', 'jpeg']),
    fileData: string().trim().required(),
  }).nullable(),
  dimWtDivisor: object().shape({
    kg: number().default(DIM_WT_DIVISOR.kg),
    pound: number().default(DIM_WT_DIVISOR.pound),
  }),
  boxWiseRates: boolean().default(false),
  copyCarrierLogo: boolean().default(false),
  isActive: boolean().default(true),
});
