import React from 'react';

import SbButton from '@Components/Button';
import { usePaymentInvoice } from '@Hook/usePaymentInvoice';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';
import PaymentModal from './PaymentModal';
interface comments {
  comment: string;
  createdAt: string;
  paidAmount: number;
  tdsAmount: number;
}
export interface PaymentDetails {
  invoiceNumber: string;
  payableAmount: number;
  handlePaymentSuccess: () => void;
  invoiceComments: comments[];
}

interface PropsType {
  paymentDetails: PaymentDetails;
  handlePayNow?: () => void;
  disabled?: boolean;
}

export function PayNow({ paymentDetails, handlePayNow, disabled = false }: PropsType) {
  const { setOpenPaymentModal } = usePaymentInvoice();
  const { openPaymentModal } = useSelector((state: RootStateType) => state.InvoiceList);

  const handleClick = () => {
    if (disabled) return;
    if (handlePayNow) {
      handlePayNow();
    } else {
      setOpenPaymentModal(paymentDetails?.invoiceNumber);
    }
  };

  // if (!hasPaymentAccess || paymentDetails.payableAmount <= 0) return <></>;
  return (
    <>
      {openPaymentModal === paymentDetails?.invoiceNumber && (
        <PaymentModal
          isOpen={!!openPaymentModal}
          handleClose={() => setOpenPaymentModal('')}
          paymentDetails={paymentDetails}
        />
      )}

      <SbButton
        variant="outlined"
        sx={{ borderRadius: '8px !important', padding: '8px !important' }}
        onClick={handleClick}
        disabled={disabled}
      >
        Pay
      </SbButton>
    </>
  );
}
