import { TimelineConnector } from '@mui/lab';
import { Grid, styled } from '@mui/material';

export const HorizontalDash = styled(Grid)(({ theme }) => ({
  //   padding: theme.spacing(1),
  width: '1px',
  background: theme.palette.border.grey,
  height: '100%',
}));

export const SingleConnectionContainer = styled(Grid)(({ theme: _theme }) => ({
  // paddingBottom: theme.spacing(1),
  // position: 'relative',
  // '&:before': {
  //   top: '25px',
  //   left: '30px',
  //   bottom: 0,
  //   // height: '100%',
  //   content: '""',
  //   position: 'absolute',
  //   borderLeft: '0 solid #dadada',
  //   borderStyle: 'dashed',
  //   color: '#dadada',
  // },
  // '&.selected': {
  //   '&:before': { color: theme.palette.primary.main },
  // },
  // '&:last-child': {
  //   paddingBottom: 0,
  // },
}));

export const TimelineConnectorStyled = styled(TimelineConnector)(({ theme }) => ({
  '&.img': {
    backgroundImage: `url(/images/connector.svg)`,
    backgroundPosition: 'center',
    backgroundColor: 'unset',
  },
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
  },
}));
