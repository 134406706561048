import Title from '@Components/Title';
import { Grid, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BorderedWrapper, SelectedWrapper } from './style';
// ICONS

import FilterSearch from '@Components/Filter/Search';
import SbImage from '@Components/Image';
import HorizontalTrack from '@Components/Tracking/HorizontalTracking';
import VerticalTracking from '@Components/Tracking/VerticalTracking';
import { useDispatch, useSelector } from 'react-redux';
import { SHIPMENT_STATUS } from '../../../constants/Shipment';
import { AppDispatch, RootStateType } from '../../../store';
import { BoxType } from '../../../store/reducers/Shipment/Type';
import { trackMultipleShipments, trackShipment } from '../../../store/reducers/Tracking/actions';
import { ScrollableBox } from '../../CreateShipment/styles';
import ViewAction from '../../ViewSingleShipment/ViewAction';
import SingleBox from './SingleBox';

import SbButton from '@Components/Button';
import { CSP_TYPES } from '@Constants/Shipment';
import { editFinalForward, updateShipment } from '@Reducers/AdminShipment';
import { ExpandLessRounded } from '@mui/icons-material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import Image from '@Components/Image';
import { getFedExDetails, getVendorsAccount } from '@Reducers/AdminShipment/actions';
import UserFedXInformation from './UserFedX/UserFedXInformation';
import { getVendorSettingList } from '@Reducers/Shipment/actions';
import UserDHLInformation from './UserDHL/UserDHLInformation';
import UserAramexInformation from './UserAramex/userAramexInformation';

const SearchFilterList = [
  { label: 'Carrier AWB', value: 'carrierNumber' },
  { label: 'Shipment Number', value: 'shipmentNumber' },
];

function TrackingDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const [viewMore, setViewMore] = useState<boolean>(true);
  const [selectedBox, setSelectedBox] = useState<BoxType>();
  const [filterSelectValue, setFilterSelectValue] = useState('carrierNumber');
  const [filterText, setFilterText] = useState('');
  const [filteredBoxes, setFilteredBoxes] = useState<BoxType[]>([]);
  const carrierIntegration = useSelector((state: RootStateType) => state.carrierIntegration.carriers);
  const [showAWB, setShowAWB] = useState<string>('');
  // const [error, setError] = useState<{ carrierAwb: string }>({ carrierAwb: '' });

  const { shipmentById, aramexDetails, fedExDetails, dhlDetails, shipmentConnectOnWarehouse } = useSelector(
    (state: RootStateType) => state.shipment,
  );
  const { token } = useSelector((state: RootStateType) => state.loginUser);
  const shipmentpayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);
  // const isFinalForwardEditable = useSelector((state: RootStateType) => state.shipment.editFinalForward);
  const isFinalForwardEditable = useSelector((state: RootStateType) => state.adminShipment.editFinalForward);
  const { data: trackingData, trackingDataByShipmentId } = useSelector((state: RootStateType) => state.trackShipment);
  const statusDetails = trackingData?.statusDetails || [];
  const boxes = shipmentById?._packageId?.box || [];

  useEffect(() => {
    if (boxes?.length) {
      const newBoxCopy: BoxType[] = boxes.map(box => ({ ...box, selected: !!box.selected }));
      setFilteredBoxes(newBoxCopy);
      setSelectedBox({ ...newBoxCopy[0] });
      dispatch(trackMultipleShipments([...boxes.map(i => i.childAWBNumber || i.shipmentNumber || '')]));
    }
  }, [boxes]);

  useEffect(() => {
    if (selectedBox && selectedBox.childAWBNumber) {
      dispatch(trackShipment(selectedBox.childAWBNumber));
    }
  }, [selectedBox]);

  function getFilteredBoxDetails() {
    const temp: BoxType[] | undefined =
      filterText && filterSelectValue === 'carrierNumber'
        ? shipmentById?._packageId?.box.filter(i => i?.carrierNumber?.toLowerCase().includes(filterText))
        : filterText && filterSelectValue === 'shipmentNumber'
        ? shipmentById?._packageId?.box.filter(i => i?.childAWBNumber?.includes(filterText))
        : boxes;
    if (temp) setFilteredBoxes(temp);
  }

  let lastTrackingStatus = '',
    lastTrackingMessage = '',
    lastTrackingLocation = '';

  if (statusDetails.length) {
    lastTrackingStatus = SHIPMENT_STATUS[statusDetails[0].status as keyof typeof SHIPMENT_STATUS]?.label;
    lastTrackingMessage = statusDetails[0].msg;
    lastTrackingLocation = statusDetails[0].location;
  }
  console.log({ selectedBox });
  useEffect(() => {
    // console.info(trackingData, '>>>>HEHE');
  }, []);

  useEffect(() => {
    //TODO: Replace shipmentById?.buyingPricing?.code === "fedex" to shipmentById?.buyingPricing?.code, when other AWB integrated.
    if (
      shipmentById?.shipmentNumber &&
      shipmentById?.buyingPricing?.code &&
      (shipmentById?.buyingPricing?.code === CSP_TYPES.fedex.value ||
        shipmentById?.buyingPricing?.code === CSP_TYPES.dhlIndia.value ||
        shipmentById?.buyingPricing?.code === CSP_TYPES.dhl.value ||
        shipmentById?.buyingPricing?.code === CSP_TYPES.stage.value ||
        shipmentById?.buyingPricing?.code === CSP_TYPES.aramex.value)
    ) {
      dispatch(getVendorsAccount(shipmentById?.buyingPricing?.code));
      if (carrierIntegration.length > 0) {
        dispatch(
          getFedExDetails({
            shipmentNumber: shipmentById?.shipmentNumber,
            type: 'D-D',
            csp: shipmentById?.buyingPricing?.code,
          }),
        );
      }
      // if (shipmentConnectOnWarehouse && shipmentConnectOnWarehouse?.length > 0) {
      //   dispatch(
      //     getFedExDetails({
      //       shipmentNumber: shipmentById?.shipmentNumber,
      //       type: 'W-D',
      //       csp: shipmentById?.buyingPricing?.code,
      //     }),
      //   );
      // }
    }
  }, [shipmentById?.shipmentNumber, shipmentConnectOnWarehouse?.length, shipmentById?.buyingPricing?.code]);

  useEffect(() => {
    if (shipmentById?.buyingPricing?.code && token?.accountNumber)
      dispatch(
        getVendorSettingList({
          csp: shipmentById?.buyingPricing?.code,
          accountNumber: token?.accountNumber,
        }),
      );
  }, [shipmentById?.buyingPricing?.code, token?.accountNumber]);

  return (
    <Grid container>
      <Title title="Tracking Details" removeBorder />
      <BorderedWrapper
        container
        id="tracking_detail"
        rowGap={3}
        className="p-4 mb-3 border border-solid border-light rounded"
      >
        <div>
          <Typography className="l-text font-normal">{lastTrackingStatus}</Typography>
          <Typography className="md-text font-normal sb-text-gray">{lastTrackingMessage}</Typography>
        </div>
        <Grid container>
          <HorizontalTrack data={trackingData?.statusArray || []}></HorizontalTrack>
          {lastTrackingLocation && statusDetails?.length > 1 && (
            <Typography className="w-full flex sb-text-gray" justifyContent="flex-end">
              {lastTrackingLocation}
            </Typography>
          )}
        </Grid>
        <Grid container alignItems="baseline">
          <Grid item xs={6}>
            {shipmentById?.sellingPricing?.serviceImage && (
              <SbImage src={shipmentById?.sellingPricing.serviceImage} alt="logo" height={70} width={150} />
            )}
            {shipmentById?.sellingPricing?.serviceName && (
              <Typography className="md-text" sx={{ color: '#7D878C' }}>
                {shipmentById?.sellingPricing.serviceName}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} textAlign="end">
            <ViewAction setViewMore={setViewMore} viewMore={viewMore} />
          </Grid>
        </Grid>

        {viewMore && (
          <>
            <Grid container className="my-3" spacing={2}>
              <Grid item lg={4} xl={3} md={4} sm={12} xs={12}>
                <FilterSearch
                  onSelectChange={(value: string) => {
                    setFilterSelectValue(value);
                  }}
                  list={SearchFilterList}
                  selectValue={filterSelectValue}
                  textValue={filterText}
                  onTextChange={e => {
                    setFilterText(e.target.value);
                  }}
                  onTextSearch={() => {
                    getFilteredBoxDetails();
                  }}
                  onTextClear={() => {
                    setFilterText('');
                    setFilteredBoxes(boxes);
                  }}
                />
                <ScrollableBox container id={'fadedScroll'} sx={{ pr: { xs: 1, md: 2 }, mt: 2 }} rowGap={2}>
                  {filteredBoxes?.map((box: BoxType, i: number) => {
                    return (
                      <SingleBox
                        key={`tracking-box-${i}`}
                        box={{ ...box, id: i.toString() }}
                        setSelectedBox={setSelectedBox}
                        childAWBNumber={box.childAWBNumber || ''}
                        carrierNumber={box.carrierNumber || ''}
                        trackingData={
                          box.childAWBNumber && trackingDataByShipmentId
                            ? trackingDataByShipmentId[box.childAWBNumber]
                            : null
                        }
                        isSelected={selectedBox?.id === i.toString()}
                      />
                    );
                  })}
                </ScrollableBox>

                <Tooltip
                  title={
                    shipmentById?.status !== 'forwarded' &&
                    shipmentById?._accountId?.isPrepaid &&
                    Number(shipmentpayment?.payableAmount) > 0
                      ? 'Payment for this shipment is outstanding'
                      : ''
                  }
                  arrow
                >
                  <span>
                    <SbButton
                      containerClass="py-1 px-3 normal-case  mt-3 "
                      variant="outlined"
                      startIcon={<Image src="/images/icons/GenerateAwb.svg" alt="Edit" />}
                      endIcon={showAWB ? <ExpandLessRounded /> : <GridExpandMoreIcon />}
                      onClick={() => {
                        if (showAWB) {
                          setShowAWB('');
                        } else {
                          setShowAWB(shipmentById?.buyingPricing?.code || 'HI');
                        }
                      }}
                      disabled={
                        shipmentById?.status !== 'forwarded' &&
                        shipmentById?._accountId.isPrepaid &&
                        Number(shipmentpayment?.payableAmount) > 0
                      }
                    >
                      Generate AWB
                    </SbButton>
                  </span>
                </Tooltip>
              </Grid>

              <Grid item lg={8} xl={9} md={8} sm={12} xs={12}>
                <SelectedWrapper sx={{ p: 2 }}>
                  <BorderedWrapper sx={{ py: 3, px: 2.5, width: '100%', overflowX: 'auto' }}>
                    {trackingData?.reverseDateWise ? (
                      <VerticalTracking
                        data={trackingData?.reverseDateWise}
                        rtoShipmentNumber={shipmentById?.rtoShipmentNumber || ''}
                      />
                    ) : (
                      <></>
                    )}
                  </BorderedWrapper>
                </SelectedWrapper>
              </Grid>
              {/* {showAWB && ( */}
              <UserFedXInformation
                onClickForward={() => {
                  if (!shipmentById?.shipmentNumber) return;
                  dispatch(
                    updateShipment({
                      carrierDetails: { awbShowToUser: true },
                      shipmentNumber: shipmentById?.shipmentNumber,
                      isFinalForward: true,
                    }),
                  );
                  dispatch(editFinalForward(false));
                  setShowAWB('');
                }}
                onClose={() => {
                  dispatch(editFinalForward(false));
                  // setError({ carrierAwb: '' });
                  setShowAWB('');
                }}
                fedExDetails={{ ...fedExDetails, type: shipmentConnectOnWarehouse?.length ? 'W-D' : 'D-D' }}
                isEditable={shipmentById?.isFinalForward ? isFinalForwardEditable : true}
                showFedExDetails={showAWB === CSP_TYPES.fedex.value}
                // showFedExDetails={false}
                btnText={isFinalForwardEditable ? 'Update' : 'Forward'}
              />
              {/* )} */}

              <UserAramexInformation
                onClickForward={() => {
                  if (!shipmentById?.shipmentNumber) return;
                  dispatch(
                    updateShipment({
                      // carrierDetails: { awbShowToUser: carrierAwbShowToUser },
                      carrierDetails: { awbShowToUser: true },
                      shipmentNumber: shipmentById?.shipmentNumber,
                      isFinalForward: true,
                    }),
                  );
                  dispatch(editFinalForward(false));
                  setShowAWB('');
                }}
                onClose={() => {
                  dispatch(editFinalForward(false));
                  // setError({ carrierAwb: '' });
                  setShowAWB('');
                }}
                initialStates={{ ...aramexDetails, type: shipmentConnectOnWarehouse?.length ? 'W-D' : 'D-D' }}
                isEditable={shipmentById?.isFinalForward ? isFinalForwardEditable : true}
                showFedExDetails={showAWB === CSP_TYPES.aramex.value}
                // showFedExDetails={true}
                btnText={isFinalForwardEditable ? 'Update' : 'Forward'}
              />
              <UserDHLInformation
                onClickForward={() => {
                  if (!shipmentById?.shipmentNumber) return;
                  dispatch(
                    updateShipment({
                      // carrierDetails: { awbShowToUser: carrierAwbShowToUser },
                      carrierDetails: { awbShowToUser: true },
                      shipmentNumber: shipmentById?.shipmentNumber,
                      isFinalForward: true,
                    }),
                  );
                  dispatch(editFinalForward(false));
                  setShowAWB('');
                }}
                onClose={() => {
                  dispatch(editFinalForward(false));
                  // setError({ carrierAwb: '' });
                  setShowAWB('');
                }}
                initialStates={{ ...dhlDetails, type: shipmentConnectOnWarehouse?.length ? 'W-D' : 'D-D' }}
                isEditable={shipmentById?.isFinalForward ? isFinalForwardEditable : true}
                showFedExDetails={showAWB === CSP_TYPES.dhlIndia.value || showAWB === CSP_TYPES.dhl.value}
                // showFedExDetails={true}
                btnText={isFinalForwardEditable ? 'Update' : 'Forward'}
              />
            </Grid>
          </>
        )}
      </BorderedWrapper>
    </Grid>
  );
}

export default TrackingDetails;
