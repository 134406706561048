import { TabPanel } from '@mui/lab';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Company from './Company';
import Individual from './Individual';
import Divider from '../../../../components/Divider';
import { OrganizationSetupContextType } from '../../../../types/organization';

interface PropsType {
  accountHolder: OrganizationSetupContextType['individualAccountHolder'];
  onChangeAccountHolder: OrganizationSetupContextType['setIndividualAccountHolder'];
  company: OrganizationSetupContextType['companyAccountHolder'];
  onChangeCompany: OrganizationSetupContextType['setCompanyAccountHolder'];
}

function AccountHolder(props: PropsType) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { accountHolder, onChangeAccountHolder, company, onChangeCompany } = props;
  return (
    <TabPanel value="account_holder" className="p-0 w-full">
      <Grid container>
        <Grid container item md={12} lg={6} className={!isMobile ? 'border-right' : ''}>
          <Individual data={accountHolder} handleChange={onChangeAccountHolder} />
        </Grid>
        {isMobile && <Divider sx={{ m: 2 }} />}
        <Grid container item md={12} lg={6}>
          <Company data={company} handleChange={onChangeCompany} />
        </Grid>
      </Grid>
    </TabPanel>
  );
}

export default AccountHolder;
