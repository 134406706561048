import React from 'react';
import { GoogleMap, LoadScript, Marker, Polyline } from '@react-google-maps/api';

interface MapComponentProps {
  locations: Array<{ lat: number; lng: number }>;
}

const MapComponent: React.FC<MapComponentProps> = ({ locations }) => {
  const mapContainerStyle = {
    height: '400px',
    width: '100%',
  };

  const center = locations.length ? locations[0] : { lat: 0, lng: 0 };

  const lineOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 1.0,
    strokeWeight: 2,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBFD_fSwbp29dcajB97ReaHX2SB9V1VMvI">
      <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={8}>
        {locations.map((location, index) => (
          <Marker key={index} position={location} />
        ))}
        <Polyline path={locations} options={lineOptions} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
