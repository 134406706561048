// src/redux/slices/userReportsSlice.ts
import API from '@Services/apiAxios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

interface Report {
  _id: string;
  _createdBy: string;
  type: string;
  outstandingBalanceFromShipment: any[];
  outstandingBalanceFromInvoice: any[];
  responseFromShipment: boolean;
  responseFromInvoice: boolean;
  identifier: string;
  query: object;
  status: string;
  createdAt: string;
  updatedAt: string;
  expiresAt: string;
  __v: number;
  url: string;
}

interface UserReportsState {
  reports: Report[];
  loading: boolean;
  error: string | null;
}

const initialState: UserReportsState = {
  reports: [],
  loading: false,
  error: null,
};

// Async thunk for fetching reports
export const fetchUserReports = createAsyncThunk('userReports/fetchUserReports', async (_, { rejectWithValue }) => {
  try {
    const response = await API.get('/user/getUserReports');
    return response.data.data; // The array of reports
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const userReportsSlice = createSlice({
  name: 'userReports',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserReports.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserReports.fulfilled, (state, action) => {
        state.loading = false;
        state.reports = action.payload;
      })
      .addCase(fetchUserReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default userReportsSlice.reducer;
