import React, { useEffect, useMemo, useState } from 'react';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Checkbox, Grid, IconButton, Radio, Stack, Typography, styled, useTheme } from '@mui/material';

import SbButton from '@Components/Button';
import Loader from '@Components/Loader';
import Modal from '@Components/Modal';
import SbTextField from '@Components/Textfield';
import { usePaymentInvoice } from '@Hook/usePaymentInvoice';
import { RootStateType } from '@Store';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { PaymentDetails } from '.';
import {
  BeforePaymentPostData,
  PAYMENT_MODE,
  PaymentModes,
  createPaymentPayload,
  getWalletBalance,
} from './PaymentService';

interface PropsType {
  isOpen: boolean;
  handleClose: () => void;
  paymentDetails: PaymentDetails;
}

interface PaymentOptionType {
  title: string;
  subTitle: string;
  icon: React.ReactNode;
  onSelect: ({ isSelected, amount }: SelectedAccountType) => void;
  selectedAmount: SelectedAccountType;
  amount: number;
  isDisabled: boolean;
  isRadio: boolean;
}

interface SelectedAccountType {
  isSelected: boolean;
  amount: number;
}

const PaymentOptionWrapper = styled(Grid)<{ isDisabled: boolean }>(({ isDisabled }) => ({
  border: '1px solid',
  marginBottom: '30px',
  opacity: isDisabled ? 0.6 : 1,
}));

function PaymentOption({
  title,
  subTitle,
  icon,
  selectedAmount,
  isDisabled,
  onSelect,
  amount,
  isRadio,
}: PaymentOptionType) {
  return (
    <PaymentOptionWrapper container className="p-3 border-grey rounded" alignItems={'center'} isDisabled={isDisabled}>
      <Grid item xs={1} mr={4}>
        {icon}
      </Grid>
      <Grid item xs={7}>
        <Typography className="md-text font-semibold">{title}</Typography>
        <Typography>{subTitle}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography className="md-text font-semibold">{amount?.toFixed(2)} INR</Typography>
      </Grid>
      <Grid item xs={1}>
        {isRadio ? (
          <Radio
            className="ml-3"
            style={{ color: selectedAmount.isSelected ? 'green' : 'inherit' }}
            disabled={isDisabled}
            onChange={() => {
              onSelect({ isSelected: !selectedAmount.isSelected, amount: selectedAmount.amount });
            }}
            checked={selectedAmount.isSelected}
          />
        ) : (
          <Checkbox
            className="ml-3"
            style={{ color: isDisabled ? 'grey' : selectedAmount.isSelected ? 'green' : 'inherit' }}
            disabled={isDisabled}
            onChange={() => {
              onSelect({ isSelected: !selectedAmount.isSelected, amount: selectedAmount.amount });
            }}
            checked={selectedAmount.isSelected}
          />
        )}
      </Grid>
    </PaymentOptionWrapper>
  );
}

function PaymentModal({ isOpen, handleClose, paymentDetails }: PropsType) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const iconStyles = { color: theme.palette.primary.main, height: '40px', width: '40px' };
  const { accountNumber, setOpenPaymentModal } = usePaymentInvoice();
  const { invoiceNumber, payableAmount, handlePaymentSuccess } = paymentDetails;
  const { profile } = useSelector((state: RootStateType) => state.loginUser);
  const { orgCountryObj } = useSelector((state: RootStateType) => state?.orgSetup);
  const accountType = profile?._accountId?.accountType;
  const isPrepaid = profile?._accountId?.isPrepaid;
  const [isWallet, setIsWallet] = useState<SelectedAccountType>({ isSelected: true, amount: 0 });
  const [isNetBanking, setIsNetBanking] = useState<SelectedAccountType>({
    isSelected: true,
    amount: 0,
  });
  const [isCashPay, setIsCashPay] = useState<SelectedAccountType>({
    isSelected: true,
    amount: 0,
  });
  const [tds, setTds] = useState<number>(0);
  const [enablePay, setEnablePay] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [walletAmount, setWalletAmount] = useState<number>(0);

  const walletAmountToBePaid = useMemo(() => {
    return payableAmount < walletAmount ? payableAmount : walletAmount;
  }, [payableAmount, walletAmount]);

  useEffect(() => {
    if (isPrepaid) {
      (async () => {
        setLoading(true);
        const res = await getWalletBalance();
        setLoading(false);
        if (res?.data?.balance) {
          setWalletAmount(res?.data?.balance);
        } else if (res?.isError) {
          enqueueSnackbar(res.msg, { variant: 'error', key: new Date().getTime() });
          // setOpenPaymentModal(false);
        }
      })();
    }
  }, [isPrepaid]);

  useEffect(() => {
    setIsWallet({ isSelected: walletAmount > 0, amount: walletAmountToBePaid });
    setIsNetBanking({ isSelected: false, amount: 0 });
    setIsCashPay({ isSelected: false, amount: 0 });
  }, [payableAmount, walletAmount, walletAmountToBePaid]);

  useEffect(() => {
    let amount = Number(
      Number(
        (isWallet.isSelected ? isWallet.amount : 0) +
          ((isNetBanking.isSelected ? isNetBanking.amount : 0) || (isCashPay.isSelected ? isCashPay.amount : 0)),
      ).toFixed(2),
    );
    amount = Number(Number(amount + tds).toFixed(2));
    setEnablePay(amount === payableAmount);
  }, [isWallet, isNetBanking, payableAmount, isCashPay, tds]);

  useEffect(() => {
    const amount =
      accountType === 'individual'
        ? isWallet.isSelected
          ? Number(Number(payableAmount - isWallet.amount)?.toFixed(2))
          : payableAmount
        : Number(Number(payableAmount - tds)?.toFixed(2));
    setIsNetBanking(({ isSelected }) => ({
      isSelected: amount > 0 ? isSelected : false,
      amount: amount,
    }));
    setIsCashPay(({ isSelected }) => ({
      isSelected: amount > 0 ? isSelected : false,
      amount: amount,
    }));
  }, [isWallet, payableAmount, tds]);

  const onPaymentSuccess = () => {
    handlePaymentSuccess();
    setOpenPaymentModal('');
  };

  const handlePayment = async () => {
    try {
      setLoading(true);
      const paymentMode: PaymentModes =
        isWallet.isSelected && isNetBanking.isSelected
          ? PAYMENT_MODE.gatewayWallet
          : isWallet.isSelected && isCashPay.isSelected
          ? PAYMENT_MODE.WalletAndCash
          : isWallet.isSelected
          ? PAYMENT_MODE.wallet
          : PAYMENT_MODE.gateway;
      const postData: BeforePaymentPostData = {
        invoiceNumber,
        accountNumber,
        paymentMode,
        currency: orgCountryObj?.currency?.name || 'INR',
      };
      postData.totalAmount = payableAmount;
      if (paymentMode === PAYMENT_MODE.gatewayWallet) {
        postData.walletAmount = walletAmount;
      }
      if (isWallet.isSelected && isCashPay.isSelected) {
        postData.walletAmount = walletAmount;
      }
      if (accountType === 'company') {
        postData.totalAmount = payableAmount;
        postData.tdsAmount = Number(tds);
      }
      const res = await createPaymentPayload({ postData, handlePaymentSuccess: onPaymentSuccess });
      if (res?.isError) throw res;
    } catch (error) {
      if (error?.isPaid) return onPaymentSuccess();
      if (error?.isError) {
        error?.msg && enqueueSnackbar(error?.msg, { variant: 'error', key: new Date().getTime() });
        // handleClose();
      }
    }
    setLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      dialogContainerSx={{
        paddingTop: 1,
        paddingRight: 1,
        paddingLeft: 3,
      }}
      // onClose={handleClose}
    >
      <Loader loading={loading} overly>
        <div className="pr-2 pt-1" style={{ width: '580px' }}>
          <Grid container justifyContent={'space-between'}>
            <Typography className="md-text font-bold pt-2">Payment Options</Typography>
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Grid>
          <Grid container justifyContent={'center'}>
            <Typography className="l-text font-bold my-4"> Total: {payableAmount?.toFixed(2)} INR</Typography>
          </Grid>
          {accountType === 'individual' ? (
            isPrepaid ? (
              <PaymentOption
                isRadio={false}
                title={'Speedbox wallet'}
                subTitle={`Available balance: ₹ ${walletAmount}`}
                icon={<AccountBalanceWalletIcon sx={iconStyles} />}
                selectedAmount={isWallet}
                onSelect={setIsWallet}
                amount={walletAmountToBePaid}
                isDisabled={!walletAmountToBePaid}
              />
            ) : (
              <></>
            )
          ) : (
            <Box className="mb-4">
              <SbTextField
                label={`Less TDS`}
                value={tds}
                placeholder={`2,000`}
                onChange={e => {
                  if (Number(e.target.value) < payableAmount) setTds(Number(e.target.value));
                }}
                type="number"
                name="tds"
                error={''}
                startAdornment={
                  <Stack direction={'row'} gap={'10px'} paddingX={'10px'}>
                    <span>INR </span>
                    <span>|</span>
                  </Stack>
                }
              />
            </Box>
          )}
          <PaymentOption
            isRadio={false}
            title={'Card/ Netbanking/ UPI'}
            subTitle={'other payment mode'}
            icon={<CreditCardIcon sx={iconStyles} />}
            selectedAmount={isNetBanking}
            onSelect={data => {
              setIsNetBanking(data);
              setIsCashPay(prev => ({ ...prev, isSelected: false }));
            }}
            // amount={payableAmount < walletAmount ? 0 : isWallet.isSelected ? payableAmount - walletAmount : payableAmount}
            amount={isNetBanking.amount}
            isDisabled={!isNetBanking.amount}
          />
          <SbButton
            variant="contained"
            className="w-full"
            disabled={!enablePay}
            onClick={_ => {
              handlePayment();
            }}
          >
            Pay {payableAmount} INR
          </SbButton>
        </div>
      </Loader>
    </Modal>
  );
}

export default PaymentModal;
