import React, { useState } from 'react';

import { Grid } from '@mui/material';
import CreateSysForm, { initCreateSystem } from './Form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';
import { SystemType } from './type';
import { createSystem } from '../../../store/reducers/Systems';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { CreateSystemSchema } from './schema';
import SbButton from '../../../components/Button';
import Loader from '@Components/Loader';
import { ZonesEntity } from '@Reducers/Systems/Type';

function CreateSystem() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [system, _setSystem] = useState<SystemType>({ ...initCreateSystem });
  const SystemState = useSelector((state: RootStateType) => state.System);
  const [zones, setZonesPricing] = useState<ZonesEntity[]>([]);

  const onCreate = (params: SystemType) => {
    const payload = {
      name: params.name,
      contact: {
        phone: {
          number: params.number,
          telCode: params.countryCode,
        },
        supportEmail: params.supportEmail,
        email: params.email,
      },
      pincode: params.pincodes,
      address: {
        line1: params.addressLine1,
        line2: params.addressLine2,
        city: params.city,
        state: params.state,
        country: params.country,
        pincode: params.pincode,
      },
      squadzones: { zones },
    };
    dispatch(createSystem({ payload, navigate: '/warehouse/create', showModal: true, zones }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...system },
    validationSchema: CreateSystemSchema,
    onSubmit(values) {
      onCreate(values);
    },
  });

  return (
    <Loader loading={SystemState.loading} overly>
      <>
        <Grid container>
          <Grid item xs={12}>
            <CreateSysForm
              formType="create"
              showBackgroundColor={false}
              errors={formik.errors}
              values={formik.values}
              handleChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
              setZonesPricing={setZonesPricing}
            />
          </Grid>
          <Grid container item mt={4} spacing={2} justifyContent="flex-end">
            <Grid item>
              <SbButton
                fullWidth
                variant="outlined"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </SbButton>
            </Grid>
            <Grid item>
              <SbButton
                fullWidth
                variant="contained"
                onClick={_event => {
                  formik.handleSubmit();
                }}
              >
                Save and Next
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Loader>
  );
}

export default CreateSystem;
