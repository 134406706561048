import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import CreateServiceForm, { initCreateService } from './Form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getServiceById, updateServiceById } from '@Reducers/CarrierAndService';
import { useFormik } from 'formik';
import SbButton from '../../../components/Button';
import { CreateServicePayload } from '@Reducers/CarrierAndService/Type';
import { UpdateServiceSchema } from './schema';
import { useNavigate, useParams } from 'react-router-dom';

function CreateService() {
  const { id: serviceId } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [system, _setSystem] = useState<CreateServicePayload & { logoUrl: string }>({
    ...initCreateService,
    logoUrl: '',
  });
  const singleService = useSelector((state: RootStateType) => state.carrierAndService.singleService);

  useEffect(() => {
    if (serviceId) dispatch(getServiceById(serviceId));
  }, [serviceId]);

  useEffect(() => {
    if (singleService && serviceId) {
      _setSystem({
        ...system,
        name: singleService.name,
        logo: null,
        logoUrl: singleService.logo,
        service: singleService.service,
        carrierId: singleService.carrierId,
        boxWiseRates: singleService.boxWiseRates,
        totalWiseRates: singleService.totalWiseRates,

        dimWtDivisor: {
          kg: singleService.dimWtDivisor.kg,
          pound: singleService.dimWtDivisor.pound,
        },
        copyCarrierLogo: false,
      });
    }
  }, [serviceId, singleService]);

  const onUpdate = (params: CreateServicePayload) => {
    if (!serviceId) return;
    const payload = {
      name: params.name,
      logo: params.logo,
      service: params.service,
      dimWtDivisor: params.dimWtDivisor,
      carrierId: params.carrierId,
      boxWiseRates: params.boxWiseRates,
      copyCarrierLogo: params.copyCarrierLogo,
      totalWiseRates: params.totalWiseRates,
    };
    dispatch(updateServiceById([serviceId, payload]));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...system },
    validationSchema: UpdateServiceSchema,
    onSubmit(values) {
      onUpdate(values);
    },
  });

  const onCancel = () => {
    navigate('/carrier-and-services?tab=service');
  };

  // console.log({formik: formik.values})

  return (
    <>
      <Grid container>
        <Grid item xs={12} className="p-4">
          <CreateServiceForm
            formType={!serviceId ? 'create' : 'edit'}
            errors={formik.errors}
            values={formik.values}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
          />
        </Grid>
        <Grid container item mt={4} spacing={2} justifyContent="flex-end">
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton fullWidth variant="outlined" onClick={onCancel}>
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton
              fullWidth
              variant="contained"
              onClick={_event => {
                formik.handleSubmit();
              }}
            >
              Update
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateService;
