import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Modal from '@Components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { styled } from '@mui/system';
import SbButton from '@Components/Button';
import UploadKyc from './UploadKyc';
import { getShipmentKyc } from '@Reducers/Shipment';
import SingleDocument from './SingleDocument';
import DocumentDelete from './DocumentDelete';
import AddIcon from '@mui/icons-material/Add';
import { ShipmentKycType } from '@Reducers/Shipment/Type';

interface PropsType {
  open: boolean;
  shipmentNumber: string;
  handleClose?: () => void;
  dragDropWrapperOnClickRegister?: boolean;
}

const SingleDocumentWrapper = styled(Grid)(() => ({
  border: '1px solid #b2b2b2',
  borderRadius: '8px',
}));

function ShipmentKycUpload(props: PropsType) {
  const { dragDropWrapperOnClickRegister, open, handleClose, shipmentNumber } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { shipmentKyc, isShipmentKycAdded, isShipmentKycDeleted } = useSelector(
    (state: RootStateType) => state.shipment,
  );
  const [uploadDocumentModal, setUploadDocumentModal] = useState<boolean>(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState<boolean>(false);
  const [singleDoc, setSingleDoc] = useState<ShipmentKycType | null>(null);

  useEffect(() => {
    if (shipmentNumber) {
      dispatch(getShipmentKyc(shipmentNumber));
    }
  }, [shipmentNumber]);

  useEffect(() => {
    if (isShipmentKycAdded) {
      console.log('isShipmentKycAdded : ', isShipmentKycAdded);
      setUploadDocumentModal(false);
      dispatch(getShipmentKyc(shipmentNumber));
    }
  }, [isShipmentKycAdded]);

  useEffect(() => {
    if (isShipmentKycDeleted) {
      setDeleteDocumentModal(false);
      dispatch(getShipmentKyc(shipmentNumber));
    }
  }, [isShipmentKycDeleted]);

  const uploadDocumentModalClose = () => setUploadDocumentModal(false);
  const uploadDocumentModalOpen = () => setUploadDocumentModal(true);

  const onDocumentUploadModalOpen = useCallback(
    (_documentLabel: string, documentData: ShipmentKycType | null): void => {
      setSingleDoc(documentData);
      uploadDocumentModalOpen();
    },
    [],
  );

  const deleteDocumentModalClose = (refetch: boolean) => {
    setSingleDoc(null);
    setDeleteDocumentModal(false);
    if (refetch) dispatch(getShipmentKyc(shipmentNumber));
  };

  const onDocumentDeleteModalOpen = useCallback((documentData: ShipmentKycType | null): void => {
    setSingleDoc(documentData);
    setDeleteDocumentModal(true);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        dialogContainerSx={{
          // maxWidth: 'unset',
          width: { xs: 'unset', md: '700px' },
          maxHeight: '80vh',
        }}
      >
        <Grid container sx={{ mb: 4, flexDirection: 'column', alignItems: 'center' }}>
          <Typography className="md-text font-semibold ">Documents For Shipment Number: {shipmentNumber}</Typography>
        </Grid>
        <UploadKyc
          open={uploadDocumentModal}
          onClose={uploadDocumentModalClose}
          documentData={singleDoc}
          shipmentNumber={shipmentNumber}
          dragDropWrapperOnClickRegister={dragDropWrapperOnClickRegister}
          allowedFileTypes={[
            'image/png',
            'image/jpg',
            'image/jpeg',
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/json',
            'text/csv',
            '',
          ]}
        />
        <DocumentDelete
          shipmentNumber={shipmentNumber}
          docId={singleDoc?._id || ''}
          open={deleteDocumentModal}
          onClose={deleteDocumentModalClose}
        />
        <SingleDocumentWrapper container item>
          {shipmentKyc.map((kyc, index) => (
            <SingleDocument
              key={`${kyc.name}-${index}`}
              // label={kyc?.kycAttached ? kyc.name : kyc?.documentName}
              label={kyc?.documentName}
              required={false}
              documentData={kyc || null}
              onDocumentDeleteModalOpen={onDocumentDeleteModalOpen}
              onDocumentUploadModalOpen={onDocumentUploadModalOpen}
              IsEditDelteIconShow={!kyc?.kycAttached}
            />
          ))}
        </SingleDocumentWrapper>
        <AddNewKycButton onClick={() => onDocumentUploadModalOpen('', null)} variant="outlined" className="w-full">
          <AddIcon />
          <Typography>Add New Document</Typography>
        </AddNewKycButton>
        <SbButton
          onClick={handleClose}
          variant="contained"
          sx={{
            borderRadius: '8px !important',
            mt: 5,
            mb: 1,
          }}
          className="w-full"
        >
          Close
        </SbButton>
      </Modal>
    </>
  );
}

export default ShipmentKycUpload;

export const AddNewKycButton = styled(SbButton)(({ theme }) => ({
  borderRadius: '8px !important',
  borderStyle: 'dashed',
  borderColor: theme.palette.border.grey,
  color: theme.palette.text.light,
  '&:hover': {
    borderStyle: 'dashed',
    borderColor: theme.palette.border.grey,
  },
  marginTop: '30px',
}));
