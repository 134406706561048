import { rootStore } from '@Store';
import { StepsType } from '../../types/organization';

export const Steps: StepsType = {
  System: '/organization-setup/system',
  Warehouse: '/organization-setup/warehouse',
  'Org. settings': '/organization-setup/org-settings',
  'KYC settings': '/organization-setup/kyc-settings',
  'Invoice settings': '/organization-setup/invoice-settings',
  'Shipment settings': '/organization-setup/shipment-settings',
};
export const kycSettingsDefaultData = {
  requiredDocExist: true,
  list: [],
};
export const termsAndConditionsDefaultData = {
  taxInvoice: '',
  proformaInvoice: '',
  shipmentInvoice: '',
};
export const bankDetailsDefaultData = {
  systemId: '',
  accountName: '',
  accountNumber: '',
  bankName: '',
  accountType: '',
  ifscCode: '',
  upiId: '',
  gstNo: '',
  getwayIntegrationRequired: true,
  system: {
    _id: '',
    name: '',
  },
};
export const DEFAULT_SHIPMENT_CHARGES_LIST = [
  { label: 'ADC-NOC', value: 'adc-noc', isActive: true },
  { label: 'Address Correction', value: 'address correction', isActive: true },
  // { label: 'Base Rate', value: 'base rate', isActive: true },
  { label: 'Courier Charge', value: 'courier charge', isActive: true },
  { label: 'Broker Select Option', value: 'broker select option', isActive: true },
  { label: 'Clearance Charges', value: 'clearance charges', isActive: true },
  { label: 'Dangerous Goods Fees', value: 'dangerous goods fees', isActive: true },
  { label: 'Delivery Order', value: 'delivery order', isActive: true },
  { label: 'Dry Ice Charges', value: 'dry ice charges', isActive: true },
  { label: 'Duty & Taxes', value: 'duty & taxes', isActive: true },
  { label: 'Duty Handling Charges', value: 'duty handling charges', isActive: true },
  { label: 'Enhanced Security Surcharge', value: 'enhanced security surcharge', isActive: true },
  { label: 'FSC', value: 'fsc', isActive: true },
  { label: 'Fumigation', value: 'fumigation', isActive: true },
  { label: 'Handling Surcharge', value: 'handling surcharge', isActive: true },
  { label: 'Insurance', value: 'insurance', isActive: true },
  { label: 'Line Haul', value: 'line haul', isActive: true },
  { label: 'Loading/Unloading', value: 'loading/unloading', isActive: true },
  { label: 'Non - Stackable Surcharge', value: 'non-stackable surcharge', isActive: true },
  { label: 'Out of Area', value: 'out of area', isActive: true },
  { label: 'Packaging', value: 'packaging', isActive: true },
  { label: 'Special Delivery', value: 'special delivery', isActive: true },
  { label: 'Temporary Pandemic Surcharge', value: 'temporary pandemic surcharge', isActive: true },
  { label: 'Transportation', value: 'transportation', isActive: true },
  { label: 'Pickup Charge', value: 'pickup charge', isActive: true },
  { label: 'Other', value: 'other', isActive: true },
];
export const defaultChargesValues = DEFAULT_SHIPMENT_CHARGES_LIST.map(x => x.value);

const organizationCountry = rootStore.getState().global.organizationCountry;
export const DEFAULT_DOC_LIST = organizationCountry.documentRequirements.map(x => ({
  label: x,
  value: x.toLocaleLowerCase(),
  isActive: true,
}));
export const defaultDocValues = DEFAULT_DOC_LIST.map(x => x.value);
