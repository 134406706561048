import { createSlice } from '@reduxjs/toolkit';
import actionCase from './case';
import { PincodeState } from './Type';
import { getPincodeBySystemId, updatePincode, deletePincode, checkPincodeServiceabilityForShipment } from './actions';

const initialState = <PincodeState>{
  loading: false,
  systemPincode: null,
  pincodeServiceabilityForShipment: {},
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  currentPage: 1,
  perPage: 10,
  totalCount: 0,
};

const reduxStateSlice = createSlice({
  name: 'global',
  initialState: { ...initialState },
  reducers: {
    resetSystemError: function (state) {
      state.error = {
        msg: '',
        isError: false,
      };
    },
    resetPincde: function (state) {
      state.systemPincode = null;
    },
    resetPincodeServiceability: function (state) {
      state.systemPincode = null;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getPincodeBySystemId.pending, actionCase.getPincodeBySystemIdPending);
    addCase(getPincodeBySystemId.fulfilled, actionCase.getPincodeBySystemIdFulfilled);
    addCase(getPincodeBySystemId.rejected, actionCase.getPincodeBySystemIdRejected);

    addCase(updatePincode.pending, actionCase.updatePincodePending);
    addCase(updatePincode.fulfilled, actionCase.updatePincodeFulfilled);
    addCase(updatePincode.rejected, actionCase.updatePincodeRejected);

    addCase(deletePincode.pending, actionCase.deletePincodePending);
    addCase(deletePincode.fulfilled, actionCase.deletePincodeFulfilled);
    addCase(deletePincode.rejected, actionCase.deletePincodeRejected);

    addCase(checkPincodeServiceabilityForShipment.pending, actionCase.checkPincodeServiceabilityForShipmentPending);
    addCase(checkPincodeServiceabilityForShipment.fulfilled, actionCase.checkPincodeServiceabilityForShipmentFulfilled);
    addCase(checkPincodeServiceabilityForShipment.rejected, actionCase.checkPincodeServiceabilityForShipmentRejected);
  },
});

export { getPincodeBySystemId, updatePincode, deletePincode };
export const { resetSystemError, resetPincde, resetPincodeServiceability } = reduxStateSlice.actions;
export default reduxStateSlice.reducer;
