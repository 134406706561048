import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { AppDispatch, RootStateType } from '../../../store';
import { OutletBox } from '../Style';
import ShipmentSettingForm from '../../ShipmentSetting/Form';
import { SHIPMENT_STETTING_FORM } from '../../ShipmentSetting/type';
import { useFormik } from 'formik';
import { ShipmentSettingSchema } from '../../ShipmentSetting/schema';
import Actions from '../Actions';
import OrganizationSetupContext from '../../../context/OrganizationSetup';
import { OrganizationSetupContextType } from '../../../types/organization';
import {
  getShipmentSettings,
  saveShipmentSettings,
  resetShipmentSettings,
} from '../../../store/reducers/OrganizationSetup';
import Loader from '@Components/Loader';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_DOC_LIST, DEFAULT_SHIPMENT_CHARGES_LIST } from '../constants';

export const initForm: SHIPMENT_STETTING_FORM = {
  shipmentCharges: [],
  personalShipmentId: [],
  exportShipmentId: [],
  pickupTimeSlots: {
    slots: [],
    slotGroup: '',
  },
  packagingCharge: {
    charge: '',
    chargeType: 'fixed',
  },
  insuranceCharge: {
    charge: '',
    chargeType: 'fixed',
  },
};

function ShipmentSetting() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [shipmentSettingState, setShipmentSettingState] = useState<SHIPMENT_STETTING_FORM>({ ...initForm });
  const { shipmentSettingSuccess, shipmentSetting, loading } = useSelector((state: RootStateType) => state?.orgSetup);

  const { activeStep, handleNext, handleBack } = React.useContext(
    OrganizationSetupContext,
  ) as OrganizationSetupContextType;

  useEffect(() => {
    dispatch(getShipmentSettings());
  }, []);

  useEffect(() => {
    const shipmentCharges = shipmentSetting?.shipmentCharges.length
      ? [...(shipmentSetting?.shipmentCharges || [])]
      : DEFAULT_SHIPMENT_CHARGES_LIST;
    // shipmentSetting?.shipmentCharges.forEach((charge, ind) => {
    //   if (defaultChargesValues.includes(charge.value)) {
    //     shipmentCharges[ind] = { ...shipmentCharges[ind], isActive: true };
    //   }
    // });
    const personalShipmentId = shipmentSetting?.personalShipmentId
      ? [...(shipmentSetting?.personalShipmentId || [])]
      : DEFAULT_DOC_LIST;
    // shipmentSetting?.personalShipmentId.forEach((charge, ind) => {
    //   if (defaultDocValues.includes(charge.value)) {
    //     personalShipmentId[ind] = { ...personalShipmentId[ind], isActive: true };
    //   }
    // });
    if (shipmentSetting)
      setShipmentSettingState({
        ...shipmentSetting,
        shipmentCharges,
        personalShipmentId,
        enablePackagingCharge: !!shipmentSetting?.packagingCharge?.charge,
        enableInsuranceCharge: !!shipmentSetting?.insuranceCharge?.charge,
      });
    else setShipmentSettingState(state => ({ ...state, shipmentCharges, personalShipmentId }));
  }, [shipmentSetting]);

  useEffect(() => {
    if (shipmentSettingSuccess) {
      dispatch(resetShipmentSettings());
      handleNext(activeStep);
    }
  }, [shipmentSettingSuccess]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...shipmentSettingState },
    validationSchema: ShipmentSettingSchema,
    onSubmit(values) {
      dispatch(saveShipmentSettings({ payload: values })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          navigate('/organization-setup/complete');
        }
      });
    },
  });

  return (
    <Loader loading={loading} overly>
      <>
        <OutletBox className="border-grey rounded-md my-3">
          <ShipmentSettingForm
            setFieldValue={formik.setFieldValue}
            handleChange={formik.handleChange}
            values={formik.values}
            errors={formik.errors}
          />
        </OutletBox>
        <Actions
          onNext={() => {
            formik.handleSubmit();
          }}
          onBack={() => handleBack(activeStep)}
          disableNext={false}
          viewFor={'org-setup'}
        />
      </>
    </Loader>
  );
}

export default ShipmentSetting;
