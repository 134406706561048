import React, { useEffect, useState } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import SbTextField from '../../../../components/Textfield';
import { BoxType, CopyBoxType, GetRatesContextType } from '../../Type';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import GetRatesContext from '../../../../context/GetRates';
import { ValidationResult } from 'joi';
import { boxSchema } from './Schema';
import Actions from '../Actions';
import updateBoxId from '@Services/updateBoxId';

function CopyBox({ setBoxState, data }: CopyBoxType) {
  const { boxes, setBoxes } = React.useContext(GetRatesContext) as GetRatesContextType;
  const [count, setCount] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(true);

  // const { id, boxCopyCount } = data;

  // useEffect(() => {
  //   if (boxCopyCount) setCount(boxCopyCount ?? 0);
  // }, [boxCopyCount]);

  useEffect(() => {
    const error = validateData();
    if (error) setIsError(true);
    else setIsError(false);
  }, [count]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (value) setCount(parseInt(value));
    else setCount(0);
  }

  function handleAction(type: string) {
    const countTemp = type === 'remove' ? count - 1 : count + 1;
    setCount(countTemp);
  }

  function handleSubmit() {
    if (isError) return;
    setIsError(false);
    const selectedBoxToCopy = structuredClone(data);
    if (selectedBoxToCopy === null) return;
    const boxesTemp = structuredClone(boxes);
    const arr = updateBoxId<BoxType>(boxesTemp, data, count);
    setBoxes([...arr]);
    handleCancel();
  }

  function validateData() {
    const result: ValidationResult = boxSchema.validate({ boxCopyCount: count }, { abortEarly: false });
    const { error } = result;
    return error;
  }
  function handleCancel() {
    setBoxState('details');
  }
  return (
    <>
      <Grid container item style={{ height: '94px' }}>
        <Grid container justifyContent="center" columnSpacing={2} className="mb-1">
          <Grid item xs={12} textAlign={'center'}>
            <Typography className="sm-text">No. of boxes to duplicate</Typography>
          </Grid>
          <Grid item container alignItems={'center'} justifyContent={'center'}>
            <IconButton onClick={() => handleAction('remove')} disabled={count <= 0}>
              <RemoveCircleIcon />
            </IconButton>
            <Grid item xs={3.5}>
              <SbTextField
                inputClassName="rounded-xs input-height-small"
                type="number"
                value={count}
                onChange={handleChange}
              />
            </Grid>
            <IconButton onClick={() => handleAction('add')}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Actions onConfirm={handleSubmit} onCancel={handleCancel} disabled={isError || count < 0} />
    </>
  );
}

export default CopyBox;
