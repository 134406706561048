import React, { ReactNode } from 'react';

import { styled } from '@mui/system';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

const SummaryContainer = styled('div')(() => ({
  // padding: '32px 16px',
  color: 'rgba(31, 30, 97, 1)',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '16px',
  width: '100%',
}));

type AccordProps = { summary: ReactNode; details: ReactNode };
export default function Accord({ summary, details }: AccordProps) {
  return (
    <Accordion>
      <AccordionSummary
        style={{ background: 'rgba(244, 243, 247, 1)' }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {/* <Typography>{summary}</Typography> */}
        <SummaryContainer>{summary}</SummaryContainer>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{details}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
