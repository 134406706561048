import { CSP_TYPES } from '@Constants/Shipment';
import { dhlCarrier, fedexCarrier, AramexCarrier } from './Type';
import * as yup from 'yup';
export const fedexInitialValues: fedexCarrier = {
  csp: CSP_TYPES.fedex.value,
  shipmentPurpose: [],
  accountNo: [],
  // countryOfManufacture: [],
  countryOfManufacture: '',
  payor: [],
  thirdPartyPayor: [],
  poNo: [],
  billDutiesAndTaxesTo: [],
  billDutiesAccountNo: [],
  packagingType: [],
  serviceType: [],
  printer: [],
  pickupType: [],
  shipmentType: [],
  shipperShipmentType: [],
  shipmentIdType: [],
  b13AFilingOption: [],
};

export const aramexIntialValues: AramexCarrier = {
  csp: CSP_TYPES.aramex.value,
  accountNo: [],
  countryOfManufacture: '',
  invoiceDate: [],
  shipperTaxId: [],
  productType: [],
  paymentType: [],
};
// Define the schema for the detail object
const detailSchema = yup.object().shape({
  display: yup.string().required(),
  value: yup.string().required(),
});

// Define the schema for the shipment details object
export const fedExSchema = yup.object().shape({
  shipmentPurpose: yup
    .array()
    .min(1, 'Please select at least one shipment purpose')
    .of(detailSchema)
    .required('Shipment purpose is required'),
  accountNo: yup.array().min(1, 'Please provide at least one account number').of(detailSchema),
  // countryOfManufacture: yup
  //   .array()
  //   .min(1, 'Please select at least one country of manufacture')
  //   .of(detailSchema)
  //   .required('Country of manufacture is required'),
  countryOfManufacture: yup.string(),
  payor: yup.array().min(1, 'Please select at least one payor').of(detailSchema).required('Payor is required'),
  thirdPartyPayor: yup
    .array()
    .of(detailSchema)
    .when('$payor', ([payor], schema) => {
      return JSON.stringify(payor)?.includes('THIRD_PARTY')
        ? schema
            .min(1, 'Please select at least one third-party payor')
            .required('Booking confirmation number is required')
        : schema;
    }),

  poNo: yup.array().min(1, 'Please provide at least one PO number').of(detailSchema).required('PO number is required'),
  billDutiesAndTaxesTo: yup
    .array()
    .min(1, 'Please select at least one option for billing duties and taxes')
    .of(detailSchema)
    .required('Billing duties and taxes to is required'),
  billDutiesAccountNo: yup
    .array()
    .of(detailSchema)
    .when('$billDutiesAndTaxesTo', ([billDutiesAndTaxesTo], schema) => {
      return JSON.stringify(billDutiesAndTaxesTo)?.includes('THIRD_PARTY')
        ? schema
            .min(1, 'Please provide at least one billing duties account number')
            .required('Billing duties account number is required')
        : schema;
    }),
  packagingType: yup
    .array()
    .min(1, 'Please select at least one packaging type')
    .of(detailSchema)
    .required('Packaging type is required'),
  serviceType: yup
    .array()
    .min(1, 'Please select at least one service type')
    .of(detailSchema)
    .required('Service type is required'),
  printer: yup.array().min(1, 'Please select at least one printer').of(detailSchema).required('Printer is required'),
  pickupType: yup
    .array()
    .min(1, 'Please select at least one pickup type')
    .of(detailSchema)
    .required('Pickup type is required'),
  shipmentType: yup
    .array()
    .min(1, 'Please select at least one shipment type')
    .of(detailSchema)
    .required('Shipment type is required'),
  shipperShipmentType: yup
    .array()
    .min(1, 'Please select at least one shipment type')
    .of(detailSchema)
    .required('Shipment type is required'),
  shipmentIdType: yup
    .array()
    .min(1, 'Please select at least one shipment type')
    .of(detailSchema)
    .required('Shipment type is required'),
});

// -----------------> DHL <----------------------------

export const dhlInitialValues: dhlCarrier = {
  csp: CSP_TYPES.dhl.value,
  accountNo: [],
  // countryOfManufacture: [],
  countryOfManufacture: '',
  paymentMode: [],
  ShipmentType: [],
  ShipperType: [],
  DutyPaymentMode: [],
};

export const dhlSchema = yup.object().shape({
  accountNo: yup
    .array()
    .min(1, 'Please provide at least one account number')
    .of(detailSchema)
    .required('Account number is required'),
  countryOfManufacture: yup.string(),
  paymentMode: yup
    .array()
    .min(1, 'Please select at least one paymentMode')
    .of(detailSchema)
    .required('paymentMode is required'),
  ShipmentType: yup
    .array()
    .min(1, 'Please select at least one shipment type')
    .of(detailSchema)
    .required('Shipment type is required'),
  ShipperType: yup
    .array()
    .min(1, 'Please select at least one Shipper type')
    .of(detailSchema)
    .required('Shipment type is required'),
  // GSTINType: yup
  //   .array()
  //   .min(1, 'Please select at least one Shipper type')
  //   .of(detailSchema)
  //   .required('Shipment type is required'),
});
