import React, { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import SbTextField from '../../components/Textfield';
import SBDropdown from '../../components/Dropdown';
import SbButton, { SbLoadingButton } from '../../components/Button';
import { Grid } from '@mui/material';
import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { UpdateShipmentStatus } from '@Reducers/ShipmentHistory';
import { UpdateShipmentStatusPostData } from '@Reducers/ShipmentHistory/Type';

interface PropsType {
  isOpen: boolean;
  handleClose: () => void;
  selectedShipments: string[];
}

const StatusList = Object.values(SHIPMENT_STATUS).map(s => ({ display: s.label, value: s.value }));

export default function UpdateStatus({ isOpen, handleClose, selectedShipments }: PropsType) {
  const [messgae, setMessage] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const { loading, success } = useSelector((state: RootStateType) => state.shipmentHistory);
  const _userId = useSelector((state: RootStateType) => state.loginUser.profile?._id) || '';

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [success]);

  function handleStatusUpdate() {
    const postData: UpdateShipmentStatusPostData = {
      _userId,
      shipmentNumbers: [...selectedShipments],
      location,
      msg: messgae || SHIPMENT_STATUS[status]?.msg || '-',
      status: status,
    };
    console.log('postData : ', postData);
    dispatch(UpdateShipmentStatus(postData));
  }

  function handleModalClose() {
    setMessage('');
    setLocation('');
    setStatus('');
    handleClose();
  }
  const updatedStatusList = StatusList?.filter(status => status.value !== 'forwarded');

  return (
    <Modal
      open={isOpen}
      dialogContainerSx={{
        paddingTop: 1,
        paddingRight: 1,
        paddingLeft: 3,
      }}
      onClose={handleModalClose}
    >
      <div className="pr-2 pt-1" style={{ width: '580px' }}>
        <div style={{ textAlign: 'center' }}>
          <Title title="Status" subTitle="Update status for selected shipments" removeBorder />
        </div>
        <Grid container mb={2}>
          <SbTextField
            label="Message"
            name="message"
            className="w-full"
            onChange={e => setMessage(e.target.value)}
            value={messgae}
          />
        </Grid>
        <Grid container mb={2}>
          <SbTextField
            label="Location"
            name="location"
            className="w-full"
            onChange={e => setLocation(e.target.value)}
            value={location}
          />
        </Grid>
        <Grid container mb={2}>
          <SBDropdown
            id="company-country-id"
            label="Status"
            name="status"
            value={[status]}
            // options={StatusList}
            options={updatedStatusList}
            onChange={([value]) => setStatus(value)}
          />
        </Grid>
        <div className="flex mt-3" style={{ justifyContent: 'center' }}>
          <SbButton variant="outlined" sx={{ borderRadius: '8px !important', mr: 1 }} onClick={handleClose}>
            Cancel
          </SbButton>
          <SbLoadingButton
            variant="contained"
            sx={{ borderRadius: '8px !important', ml: 1 }}
            onClick={handleStatusUpdate}
            loading={loading}
          >
            Update
          </SbLoadingButton>
        </div>
      </div>
    </Modal>
  );
}
