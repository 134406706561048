import React, { useEffect, useState } from 'react';
import Modal from '@Components/Modal';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import SbTextField from '@Components/Textfield';
import SbButton from '@Components/Button';
import { rechargeWalletRazorpayPayload, resetWalletRazorpay, getWalletBalance } from '@Reducers/Wallet';
import { displayRazorpay } from '@Components/PayNow/helper';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { userLogin } from '@Services/hasAdminAccess';
import { addMoneyToWalletByAdmin } from '@Reducers/Wallet/actions';
import { HighlightOffRounded } from '@mui/icons-material';

function AddMoneyModal(props: { onClose: () => void; selectedUser?: UserObj | null }) {
  const [money, setMoney] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  const [comment, setComment] = useState<string>('');
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name || '');
  const currentBalance = useSelector((state: RootStateType) => state.Wallet.currentBalance);
  const addWalletRazorpay = useSelector((state: RootStateType) => state.Wallet.addWalletRazorpay);
  useEffect(() => {
    return () => {
      dispatch(resetWalletRazorpay());
    };
  }, []);

  useEffect(() => {
    if (addWalletRazorpay) {
      displayRazorpay(addWalletRazorpay, onSuccess);
    }
  }, [addWalletRazorpay]);
  const AddMoney = () => {
    if (!Number(money) || Number(money) === 0) return;
    if (isAdmin) {
      if (!props.selectedUser) return;
      dispatch(addMoneyToWalletByAdmin({ amount: Number(money), _userId: props.selectedUser._id })).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          onSuccess();
        }
      });
    } else {
      dispatch(rechargeWalletRazorpayPayload({ amount: Number(money), currency: orgCountryCurrency }));
    }
  };

  const onSuccess = () => {
    dispatch(resetWalletRazorpay());
    dispatch(getWalletBalance());
    props.onClose();
  };
  const theme = useTheme();
  return (
    <Modal open maxWidth="sm">
      <Grid
        sx={{
          width: '100%',
          maxWidth: 380,
          padding: '16px',
          [theme.breakpoints.down('sm')]: {
            padding: '12px',
          },
        }}
      >
        <Grid container justifyContent={'space-between'}>
          <Typography className="font-semibold l-text">Add Money</Typography>
          <IconButton onClick={props.onClose}>
            <HighlightOffRounded />
          </IconButton>
        </Grid>
        <Grid container p={2} border={'1px solid'} borderColor={'background.grey'} borderRadius={2} my={2}>
          <Grid container className="mt-1" gap={6}>
            <Typography className="font-medium md-text">Current Wallet Balance</Typography>
            <Typography className="font-semibold md-text ml-1">
              {orgCountryCurrency} {currentBalance}
            </Typography>
          </Grid>
          <Grid item xs={12} container className="mt-3">
            <SbTextField
              className="w-full"
              label="Enter Amount (INR)"
              required
              type="text"
              value={money}
              onChange={e => setMoney(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} container className="mt-3">
            <SbTextField
              className="w-full"
              label="Comment"
              type="string"
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container className="mt-1" spacing={2}>
          {/* <Grid item xs={6}>
            <SbButton variant="outlined" fullWidth >
              Cancel
            </SbButton>
          </Grid> */}
          <Grid item xs={12}>
            <SbButton variant="contained" fullWidth onClick={AddMoney}>
              Add Money
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default AddMoneyModal;
