import SbButton, { SbLoadingButton } from '@Components/Button';
import { alpha, Grid, styled, Typography } from '@mui/material';

interface TrackingStatusProps {
  bgcolor: string;
  color: string;
}
interface BoxContainerProps {
  isselected: string;
}
export const TrackingStatus = styled(Typography)<TrackingStatusProps>(({ bgcolor, color }) => ({
  background: bgcolor,
  color: color,
  width: 'fit-content',
  height: '28px',
  fontSize: '13px',
  fontWeight: 500,
  paddingInline: 8,
  paddingBlock: 4,
  borderRadius: 4,
}));

export const TrackingBoxContainer = styled(Grid)<BoxContainerProps>(({ theme, isselected }) => ({
  border: isselected === 'true' ? alpha(theme.palette.primary.light, 1) : `1px solid var(--mui-palette-primary-light)`,
  width: '100%',
  minHeight: '133px',
  padding: '15px',
  // paddingRight: '5px',
  cursor: 'pointer',
  '.isSelected': {
    background: 'var(--mui-palette-background-white) !important',
  },
  background: isselected === 'true' ? alpha(theme.palette.primary.light, 1) : 'unset',
}));

interface BorderedWrapperProps {
  marginBottom?: string;
}

export const BorderedWrapper = styled(Grid)<BorderedWrapperProps>(({ theme, marginBottom }) => ({
  border: '1px solid',
  borderColor: theme.palette.border.light,
  padding: '50px 40px',
  borderRadius: '8px',
  background: theme.palette.primary.contrastText,
  marginBottom: marginBottom || '40px',
  [theme.breakpoints.down('md')]: {
    padding: '30px 20px',
    marginBottom: marginBottom || '30px',
  },
}));

export const SelectedWrapper = styled('div')(({ theme }) => ({
  background: theme.palette.primary.light,
  borderRadius: '8px',
}));

export const EditButton = styled(SbButton)(({ theme }) => ({
  background: '#F4F3F7',
  color: theme.palette.primary.main,
  width: 'fit-content',
  height: '28px',
  fontSize: '13px',
  fontWeight: 500,
  borderRadius: '4px !important',
  minWidth: 62,
  textTransform: 'lowercase',
  padding: '0 !important',
}));

export const SaveButton = styled(SbLoadingButton)(() => ({
  width: 'fit-content',
  height: '28px',
  fontSize: '13px',
  fontWeight: 500,
  borderRadius: '4px !important',
  minWidth: 62,
  textTransform: 'lowercase',
  padding: '0 !important',
}));
