import SbTextField from '@Components/Textfield';
import { shipmentPayByCash } from '@Reducers/Admin/actions';
import { AppDispatch, RootStateType } from '@Store';
import { Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import Actions from '../Actions';

interface PayByCashType {
  onCancel: () => void;
}

function PayByCash(props: PayByCashType) {
  const dispatch = useDispatch<AppDispatch>();
  const shipmentpayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);
  const { payableAmount = 0, shipmentNumber, tdsAmount = 0 } = shipmentpayment || {};
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { amount: payableAmount - tdsAmount, comment: '', tds: tdsAmount },
    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .min(0)
        .max(payableAmount || 0)
        .required('Amount is required.'),
      comment: Yup.string().trim().min(5).required('Comment is required.'),
    }),
    onSubmit(values) {
      // handleOnSubmit(values);
      if (shipmentNumber)
        dispatch(
          shipmentPayByCash({
            amount: values.amount?.toString() || '',
            comment: values.comment,
            tdsAmount: tdsAmount,
            shipmentNumber: shipmentNumber,
            next: props.onCancel,
          }),
        );
    },
  });

  return (
    <Grid container p={4} boxShadow="0px 2px 4px 0px rgba(156, 156, 156, 0.25)">
      <Grid container item mb={1}>
        <Typography className="md-text">Pay By Cash</Typography>
      </Grid>
      <Grid container item spacing={2} mb={2}>
        <Grid item xs={12}>
          <SbTextField
            type="number"
            required
            label="Amount"
            name="amount"
            value={formik.values.amount}
            disabled={!!formik.values.amount}
            error={formik?.errors?.amount || ''}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SbTextField
            required
            label="Comment"
            name="comment"
            value={formik.values.comment}
            error={formik?.errors?.comment || ''}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SbTextField
            label="Tds"
            disabled={true}
            name="tds"
            value={formik.values.tds}
            error={formik?.errors?.tds || ''}
          />
        </Grid>
      </Grid>
      <Actions onSave={formik.handleSubmit} onCancel={props.onCancel} />
    </Grid>
  );
}
export default PayByCash;
