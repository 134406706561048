import SbButton from '@Components/Button';
// import { RootStateType } from '@Store';
import { Grid } from '@mui/material';
import React from 'react';
// import { useSelector } from 'react-redux';
// import { SHIPMENT_STATUS } from '@Constants/Shipment';

interface Props {
  onCancel?: () => void;
  onSave?: () => void;
  disabled?: boolean;
  saveButtonName?: string;
}

export default function Actions(props: Props) {
  // const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  // const enableSave: boolean = [
  //   SHIPMENT_STATUS.placed.value,
  //   SHIPMENT_STATUS.pickup_assigned.value,
  //   SHIPMENT_STATUS.picked_up.value,
  //   SHIPMENT_STATUS.in_scan.value,
  //   SHIPMENT_STATUS.at_warehouse.value,
  //   SHIPMENT_STATUS.forwarded.value,
  // ].includes(shipmentById?.status as string);

  const isDisabled = props.disabled ?? false;
  return (
    <Grid container justifyContent={'end'}>
      {props.onCancel && (
        <SbButton
          variant="outlined"
          className="mr-1 rounded-xs"
          sx={{ height: 36, borderRadius: '4px !important' }}
          onClick={props.onCancel}
        >
          Cancel
        </SbButton>
      )}
      <SbButton
        variant="contained"
        className="rounded-xs"
        sx={{ height: 36, borderRadius: '4px !important' }}
        onClick={props.onSave}
        // disabled={!enableSave}
        disabled={isDisabled}
      >
        {props.saveButtonName ? props.saveButtonName : 'Save'}
        {/* Save */}
      </SbButton>
    </Grid>
  );
}
