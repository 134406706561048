/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@Services/apiAxios';
import { CompanyInfoPayload } from './Type';

const getLoggedInUser = createAsyncThunk('loginUser/getLoggedInUser', async () => {
  const loginUserData = await API.get('/user/me');
  const profileData = await API.get(`/user/${loginUserData.data._id}`);
  return { tokenResult: loginUserData.data, profileResult: profileData.data.data };
});

const updateUserProfile = createAsyncThunk('loginUser/updateUserProfile', async (postData: { name: string }) => {
  const profileData = await API.put('/user/me', postData);
  return profileData.data.data;
});

const getCompanyDetails = createAsyncThunk('loginUser/getCompanyDetails', async () => {
  const profileData = await API.get(`/user/account-company/me`);
  return profileData.data.data;
});

const getCompanyDetailsBySystemId = createAsyncThunk(
  'loginUser/getCompanyDetailsBySystemId',
  async (systemId: string) => {
    const profileData = await API.get(`/user/account-company/${systemId}`);
    return profileData.data.data;
  },
);

const updateCompanyDetails = createAsyncThunk(
  'loginUser/updateCompanyDetails',
  async ({ postData, showModal }: { postData: CompanyInfoPayload; showModal: boolean }, { dispatch }) => {
    try {
      const res = await API.post('/user/account-company/me', postData);
      if (showModal)
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: res.data.msg,
          }),
        );
      dispatch(getLoggedInUser());
      return res.data.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const updatePassword = createAsyncThunk(
  'loginUser/updatePassword',
  async (postData: { newPassword: string; oldPassword: string }, { dispatch }) => {
    try {
      const profileData = await API.post('/user/change-password', postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: profileData.data.msg,
        }),
      );
      return profileData.data.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getTaxSetting = createAsyncThunk('loginUser/getTaxSetting', async () => {
  const result = await API.get('/user/setting/tax');
  return result.data;
});

export {
  getLoggedInUser,
  updateUserProfile,
  updatePassword,
  getTaxSetting,
  getCompanyDetails,
  updateCompanyDetails,
  getCompanyDetailsBySystemId,
};
