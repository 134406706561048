import React from 'react';
import { BorderedWrapper } from './style';
import Title from '../../components/Title';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Divider from '../../components/Divider';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';
import { ShipperConsigneeSummaryDetails } from './ShipperConsigneeSummaryDetails';

function ShipperConsigneeDetails() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { shipmentById } = useSelector((state: RootStateType) => state.shipment);
  const { _consigneeId, _shipperId, extraDetails } = shipmentById || {};
  const shipper = React.useMemo(
    () => ({
      companyName: _shipperId?.companyName || '',
      line1: _shipperId?.line1 || '',
      line2: _shipperId?.line2 || '',
      city: _shipperId?.city || '',
      state: _shipperId?.state || '',
      country: _shipperId?.country || '',
      pincode: _shipperId?.pincode || '',
      name: _shipperId?.name || '',
      phoneNumber: _shipperId?.phone.number || '',
      telephoneCode: _shipperId?.phone.telephoneCode || '',
      email: _shipperId?.email || '',
      altContactName: _shipperId?.alternatePhone?.name || '',
      altContactNo: _shipperId?.alternatePhone?.number || '',
      altContactTelephoneCode: _shipperId?.alternatePhone?.telephoneCode || '',
      IdType: extraDetails?.shipper?.IdType || '',
      IdNumber: extraDetails?.shipper?.IdNumber || '',
    }),
    [_shipperId],
  );
  const consignee = React.useMemo(
    () => ({
      companyName: _consigneeId?.companyName || '',
      line1: _consigneeId?.line1 || '',
      line2: _consigneeId?.line2 || '',
      city: _consigneeId?.city || '',
      state: _consigneeId?.state || '',
      country: _consigneeId?.country || '',
      pincode: _consigneeId?.pincode || '',
      name: _consigneeId?.name || '',
      phoneNumber: _consigneeId?.phone.number || '',
      telephoneCode: _consigneeId?.phone.telephoneCode || '',
      email: _consigneeId?.email || '',
      altContactName: _consigneeId?.alternatePhone?.name || '',
      altContactNo: _consigneeId?.alternatePhone?.number || '',
      altContactTelephoneCode: _consigneeId?.alternatePhone?.telephoneCode,
      IdType: extraDetails?.consignee?.IdType || '',
      IdNumber: extraDetails?.consignee?.IdNumber || '',
    }),
    [_consigneeId],
  );
  return (
    <>
      <Title title="Shipper and Consignee Details" removeBorder />
      <BorderedWrapper>
        <Grid container item lg={12}>
          <Grid container item lg={6} className={isMobile ? '' : 'border-right pr-3'}>
            {shipper && <ShipperConsigneeSummaryDetails label="Shipper" {...shipper} />}
          </Grid>
          {isMobile && <Divider className="my-2" />}
          <Grid container item lg={6} sx={{ pl: { lg: 3, xs: 0 } }}>
            {consignee && <ShipperConsigneeSummaryDetails label="Consignee" {...consignee} />}
          </Grid>
        </Grid>
      </BorderedWrapper>
    </>
  );
}

export default ShipperConsigneeDetails;
