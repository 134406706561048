import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import React from 'react';
import SbTooltip from '../../../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import DocTable from '../DocTable';
import { OrganizationSetupContextType } from '../../../../types/organization';
import { SectionSubText, SectionText, SectionTitle, SectionWrapper } from '../../Style';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';

interface PropsType {
  data: OrganizationSetupContextType['companyAccountHolder'];
  handleChange: OrganizationSetupContextType['setCompanyAccountHolder'];
}

function Company(props: PropsType) {
  const { data: companyAccountHolder, handleChange: setCompanyAccountHolder } = props;
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isAccountHolderWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.KYC_Settings.account_holder,
    PERFORM_ACTION.write,
  );
  return (
    <SectionWrapper>
      <Grid container item xs={12} className="mb-3">
        <Grid container justifyContent={'start'} alignItems={'center'} className="mb-1">
          <SectionTitle className="mr-2">Company</SectionTitle>
          <SbTooltip
            placement="right"
            title={`Below list of KYC will be applicable for Company users. You can select which ones you want to make mandatory by clicking the checkbox.)`}
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>
        {/* <SectionSubText>
          Below list of KYC will be applicable for Company users. You can select which ones you want to make mandatory
          by clicking the checkbox.
        </SectionSubText> */}
      </Grid>
      <Grid container item xs={12} className="mb-3">
        <SectionText className="mb-2">Do you want KYC mandatory for Company account holders ?*</SectionText>
        <Grid container justifyContent={'space-between'} alignItems={'center'} className="mb-1">
          <Grid item xs={11}>
            <SectionSubText sx={{ maxWidth: '80%' }}>
              If you make KYC mandatory, the user cannot place the shipment without submitting the KYC Documents.
            </SectionSubText>
          </Grid>
          <Grid item xs={1}>
            <FormGroup classes={{ root: 'flex' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={companyAccountHolder.requiredDocExist}
                    onChange={event =>
                      setCompanyAccountHolder({ ...companyAccountHolder, requiredDocExist: event.target.checked })
                    }
                    disabled={!isAccountHolderWriteAllowed}
                  />
                }
                label=""
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className="mb-3">
        <DocTable
          disabled={!isAccountHolderWriteAllowed}
          settings={companyAccountHolder}
          setList={setCompanyAccountHolder}
        />
      </Grid>
      <Grid container item xs={12} className="mb-3">
        <SectionText className="mb-2">Are Company details mandatory?*</SectionText>
        <Grid container justifyContent={'space-between'} alignItems={'center'} className="mb-1">
          <Grid item xs={11}>
            <SectionSubText sx={{ maxWidth: '80%' }}>
              If you make Company Details Mandatory, the users have to fill Company details like Name, Address,
              Contact,etc.
            </SectionSubText>
          </Grid>
          <Grid item xs={1}>
            <FormGroup classes={{ root: 'flex' }}>
              <Checkbox
                sx={{ height: 40, width: 40 }}
                defaultChecked
                checked={companyAccountHolder.companyDetailsMandatory}
                onChange={event =>
                  setCompanyAccountHolder({ ...companyAccountHolder, companyDetailsMandatory: event.target.checked })
                }
                disabled={!isAccountHolderWriteAllowed}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
}

export default Company;
