/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '@Services/apiAxios';

const getTnCAgreement = createAsyncThunk('terms-and-conditions', async () => {
  const res = await API.get(`/organization/agreement/terms-and-conditions`);
  return res.data;
});
const getTnC = createAsyncThunk('terms-and-conditions-agreement', async () => {
  const res = await API.get(`/organization/terms-and-conditions`);
  return res.data;
});

const getPPAgreement = createAsyncThunk('privacy-policy', async () => {
  const res = await API.get(`/organization/agreement/privacy-policy`);
  return res.data;
});

const getPP = createAsyncThunk('privacy-policy-agreement', async () => {
  const res = await API.get(`/organization/privacy-policy`);
  return res.data;
});
export { getTnCAgreement, getPPAgreement, getTnC, getPP };
