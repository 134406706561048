import FilterSearch from '@Components/Filter/Search';
import Footer from '@Components/Footer';
import Image from '@Components/Image';
import Loader from '@Components/Loader';
import SearchBox from '@Components/SearchBox';
import HorizontalTracking from '@Components/Tracking/HorizontalTracking';
import VerticalTracking from '@Components/Tracking/SingleVerticalTracking';
import { getTrackShipment } from '@Reducers/Tracking';
import { AppDispatch, RootStateType } from '@Store';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReverseDateWiseType } from '@Reducers/Tracking/type';
import SingleBox from './SingleBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { transformString } from '../../utils/transformString';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { removeConsecutiveDuplicates } from '../../utils/removeConsecutiveDuplicates';

function TrackShipment() {
  const {
    loading,
    data: ShipmentsData,
    getMutlipleShipmentsData,
  } = useSelector((state: RootStateType) => state.trackShipment);
  const dispatch = useDispatch<AppDispatch>();
  const query = new URLSearchParams(window.location.search || '');

  const [selectedBox, setSelectedBox] = useState<string>('');
  const [boxSearchValue, setBoxSearchValue] = useState<string>('');
  const [shipmentNumberText, setShipmentNumberText] = useState<string>('');
  const [verticalTrackingData, setVerticalTrackingData] = useState<ReverseDateWiseType[]>([]);
  const { token } = useSelector((state: RootStateType) => state.loginUser);
  const navigate = useNavigate();
  const organizationData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { name = '', logo = '/images/login/logo.png' } = organizationData || {};

  const [expanded, setExpanded] = useState<string | false>(false);
  console.info('getMutlipleShipmentsData:', getMutlipleShipmentsData);

  useEffect(() => {
    if (ShipmentsData?.shipmentNumber) {
      setSelectedBox(ShipmentsData?.shipmentNumber);
    }
  }, [ShipmentsData?.shipmentNumber]);

  useEffect(() => {
    const trackingNumber = query.get('trackingNumber');
    if (trackingNumber) {
      dispatch(getTrackShipment(trackingNumber));
    }
  }, []);

  const filteredData = getMutlipleShipmentsData?.map(shipment => {
    const shipmentDetails = shipment.statusDetails[0]; // Access the first statusDetails array
    const lastTrackingDetails =
      Array.isArray(shipmentDetails?.statusDetails) && shipmentDetails.statusDetails.length > 0
        ? shipmentDetails.statusDetails[shipmentDetails.statusDetails.length - 1]
        : undefined;

    const lastTrackingLocation =
      shipmentDetails.carrierAirWayBillNumber?.trim().length > 0
        ? shipmentDetails.createdAt
        : lastTrackingDetails?.timeStamp || '-';

    const lastTrackingStatus =
      Array.isArray(shipmentDetails?.statusDetails) && shipmentDetails.statusDetails.length > 0
        ? shipmentDetails.statusDetails[0].status || 'Unknown Status'
        : 'Unknown Status';

    const lastTrackingMessage =
      Array.isArray(shipmentDetails?.statusDetails) && shipmentDetails.statusDetails.length > 0
        ? shipmentDetails.statusDetails[0].msg
        : 'No message available';

    const status = Array.isArray(shipmentDetails?.statusDetails)
      ? shipmentDetails.statusDetails.map(detail => detail.status).reverse()
      : [];

    return {
      shipmentNumber: shipment.shipmentNumber,
      awbNumber: shipmentDetails.carrierAirWayBillNumber ? shipmentDetails.carrierAirWayBillNumber : '-',
      courierServiceName: shipmentDetails.courierServiceName,
      weight: shipmentDetails.weight,
      origin: shipmentDetails.origin,
      destination: shipmentDetails.destination,
      lastTrackingLocation,
      lastTrackingStatus,
      lastTrackingMessage,
      status,
      reverseDateWise: ShipmentsData?.reverseDateWise,
    };
  });

  const filterBoxes = useMemo(() => {
    if (shipmentNumberText?.trim().length === 0) {
      return getMutlipleShipmentsData;
    } else {
      return getMutlipleShipmentsData?.filter(item => item.shipmentNumber === shipmentNumberText);
    }
  }, [getMutlipleShipmentsData, shipmentNumberText]);

  const handleAccordionChange = (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  function getCorrespondingBox(shipmentNumber: string) {
    return filterBoxes.find(box => box.shipmentNumber === shipmentNumber);
  }

  return (
    <Loader loading={loading} overly>
      <Box sx={{ height: '100vh', overflowY: 'auto' }}>
        <Box p={'2rem 4rem'} position={'relative'} sx={{ marginBottom: '10rem' }}>
          <Box maxWidth={{ lg: '75%', xs: '100%' }} position={'relative'} zIndex={'1000'}>
            <div
              onClick={() => {
                if (token?.accessGroup) {
                  navigate('/');
                } else {
                  navigate('/login');
                }
              }}
            >
              <Image src={logo} alt="logistics" style={{ height: '6rem', marginLeft: '-3rem' }} />
            </div>
            <Box>
              <Typography className="font-semibold xl-text mt-2" color={'primary.main'}>
                Welcome to {name}
              </Typography>
              <Typography variant="subtitle1" className="mt-3 l-text font-semibold">
                Tracking Status
              </Typography>
            </Box>
            <Box m={'1rem 0'}>
              <SearchBox
                id="search_shipment"
                label=""
                onSearch={() => {
                  //
                }}
                onClear={() => {
                  //
                }}
                value={''}
                placeholder="Enter your AWB"
              />
            </Box>

            {filteredData && filteredData.length > 0 ? (
              filteredData.map((shipment, index) => (
                <Box key={index}>
                  <Accordion
                    key={shipment.shipmentNumber}
                    expanded={expanded === `panel${index}`}
                    sx={{ margin: '2rem 0' }}
                    onChange={handleAccordionChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      sx={{
                        bgcolor: 'primary.light',
                      }}
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            fontSize: '50px',
                          }}
                        />
                      }
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                      onClick={() => {
                        setSelectedBox(shipment?.shipmentNumber);
                        const singleShipmentData = getCorrespondingBox(shipment.shipmentNumber);
                        const verticalTrackingData = singleShipmentData?.statusDetails?.reduce(
                          (result, statusDetail) => {
                            const timeStamp = new Date(statusDetail.timeStamp).toLocaleDateString();
                            const existingEntry: ReverseDateWiseType | undefined = result.find(
                              entry => entry?.date === timeStamp,
                            );

                            const details = { ...statusDetail, time: moment(statusDetail.timeStamp).format('hh:mm A') };
                            if (existingEntry && existingEntry.details) {
                              existingEntry.details.push(details);
                            } else {
                              result.push({
                                date: timeStamp,
                                formattedDate: moment(statusDetail.timeStamp).format('DD MMMM YYYY, dddd'),
                                details: [details],
                              });
                            }
                            return result;
                          },
                          [] as ReverseDateWiseType[],
                        );
                        if (verticalTrackingData) setVerticalTrackingData(verticalTrackingData);
                      }}
                    >
                      <div
                        key={index}
                        style={{
                          padding: '1rem 2rem',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          // bgcolor: 'primary.light',
                          width: '100%',
                        }}
                      >
                        <Stack direction={'row'} justifyContent={'space-between'} className="w-full">
                          <Typography variant="subtitle1" className="md-text font-semibold">
                            {shipment?.shipmentNumber} - {transformString(shipment?.lastTrackingStatus)}
                          </Typography>
                          <Typography variant="subtitle1" className="md-text font-semibold">
                            AWB No :{shipment?.awbNumber}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} className="w-full">
                          <Typography variant="subtitle1" className="sm-text">
                            Hub : {shipment?.origin}{' '}
                          </Typography>
                          <Typography variant="subtitle1" className="sm-text">
                            Order Placed On : {dayjs(shipment.lastTrackingLocation).format('DD/MM/YYYY')}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} className="w-full">
                          <Typography variant="subtitle1" className="sm-text">
                            Origin : {shipment?.origin} <span className="mx-4">| </span> Destination :{' '}
                            {shipment?.destination}
                          </Typography>
                          <Typography variant="subtitle1" className="sm-text">
                            Weight : {shipment?.weight} kg
                          </Typography>
                        </Stack>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box mt={'1rem'} key={index}>
                        <Typography variant="subtitle1" className="md-text">
                          {transformString(shipment?.lastTrackingStatus)}
                        </Typography>
                        <Typography variant="subtitle1" className="sm-text sb-text-gray">
                          {shipment?.lastTrackingMessage}
                        </Typography>
                      </Box>
                      <Box mt={'1rem'} key={index}>
                        <HorizontalTracking data={removeConsecutiveDuplicates(shipment.status) || []} />
                      </Box>
                      <Box mt={'2.5rem'} key={index}>
                        {/* <Image src="/images/fedex.svg" alt="logistics" style={{ height: '1.5rem' }} /> */}
                        <Typography variant="subtitle1" className="md-text sb-text-gray">
                          {shipment.courierServiceName}
                        </Typography>
                      </Box>
                      <Divider className="mt-3 mb-3" />
                      <Grid container gap={2} mt={'1rem'} width={'100%'}>
                        <Grid item xs={12} lg={4}>
                          <div className="w-full">
                            <FilterSearch
                              onSelectChange={() => {
                                //
                              }}
                              list={[
                                { label: 'Shipment No', value: 'name' },
                                { label: 'Shipment No', value: 'name' },
                              ]}
                              selectValue={'name'}
                              textValue={boxSearchValue}
                              onTextChange={e => {
                                setBoxSearchValue(e.target.value);
                              }}
                              onTextSearch={() => {
                                setShipmentNumberText(boxSearchValue);
                              }}
                              onTextClear={() => {
                                setShipmentNumberText('');
                                setBoxSearchValue('');
                              }}
                            />
                          </div>

                          <Stack
                            gap={'1rem'}
                            overflow={'auto'}
                            mt={'1rem'}
                            maxHeight={'35rem'}
                            pr={'1rem'}
                            id="fadedScroll"
                          >
                            {/* {mutlipleShipmentsData?.map((singleShipmentData, index) => ( */}
                            {getMutlipleShipmentsData && (
                              <>
                                <SingleBox
                                  key={shipment.shipmentNumber}
                                  singleShipmentData={
                                    getCorrespondingBox(shipment.shipmentNumber) ?? {
                                      courierServiceName: '',
                                      customerReferenceNumber: [],
                                      shipmentNumber: '',
                                      carrierAirWayBillNumber: '',
                                      statusDetails: [],
                                      reverseDateWise: null,
                                      statusArray: [],
                                      status: '',
                                      origin: '',
                                      destination: '',
                                      weight: 0,
                                      unitOfMeasurement: '',
                                    }
                                  }
                                  setSelectedBox={setSelectedBox}
                                  setVerticalTrackingData={setVerticalTrackingData}
                                  index={index}
                                  selectedBox={selectedBox}
                                />
                              </>
                            )}
                          </Stack>
                        </Grid>
                        <Grid item xs={12} lg={7.7}>
                          <Paper
                            sx={{
                              width: '100%',
                              p: '1rem',
                              boxShadow: theme => `0px 0px 0px 6px  ${theme.palette.primary.light}`,
                            }}
                            variant="outlined"
                          >
                            <Box
                              sx={{
                                maxHeight: '30rem',
                                overflow: 'hidden',
                                overflowY: 'auto',
                                height: '30rem',
                              }}
                              id="fadedScroll"
                            >
                              <Box key={index}>
                                <VerticalTracking
                                  data={
                                    verticalTrackingData?.length > 0
                                      ? verticalTrackingData
                                      : shipment?.reverseDateWise || []
                                  }
                                  shipmentNumber={selectedBox}
                                />
                              </Box>
                            </Box>
                          </Paper>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))
            ) : (
              <Typography variant="subtitle1" className="md-text sb-text-gray">
                No Shipments Found
              </Typography>
            )}
          </Box>

          <Box
            position={'absolute'}
            top={'0'}
            right={'0'}
            sx={{
              height: '100%',
              display: {
                xs: 'none',
                lg: 'block',
              },
            }}
          >
            <Image src="/images/wave.svg" alt="Wave" style={{ width: '100%', height: '100%' }} />
          </Box>
        </Box>
        <Footer
          footerContainerStyle={{
            position: 'absolute !important',
            bottom: '0',
            zIndex: '1000',
          }}
        />
      </Box>
    </Loader>
  );
}

export default TrackShipment;
