import { FormHelperText, Grid, SelectChangeEvent, Typography } from '@mui/material';
import React, { useState } from 'react';
import RadioButton from '../../../../components/RadioButton';
import SbTextField from '../../../../components/Textfield';
import SBDropdown from '../../../../components/Dropdown';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { createShipmentContextType, LengthType, ShipmentDataType } from '../../../../types/shipment';
import { defaultBoxState, SHIPMENT_MODES, SHIPMENT_UNITS } from '../../constants';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../../store';
import { DropdownOptionType } from '../../../../components/Dropdown/Type';
import BoxValidationError from '../BoxValidationError';
import InsurancePackaging from '../../InsurancePackaging';
import Divider from '../../../../components/Divider';
// import { alpha, styled } from '@mui/system';
import { SHIPMENT_TYPE } from '@Constants/Shipment';

const ShipmentTypeList = [
  { value: 'parcel', label: 'Parcel' },
  { value: 'document', label: 'Document' },
];

// const FormControlLabelStyle = styled(FormControlLabel)(({ theme }) => ({
//   color: alpha(theme.palette.primary.main, 1),
// }));

const ShipmentTypeDetails: React.FC = (): JSX.Element => {
  const { shipmentType, setShipmentType, boxes, setBoxes, extraDetails, setExtraDetails } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;

  const { currencyList } = useSelector((state: RootStateType) => state?.global);
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name);

  const { type, invoiceNumber, numberOfBoxes, currency, unit, shipmentMode, referenceNumber } = shipmentType;

  const [openBoxValidationModal, setOpenBoxValidationModal] = useState(false);

  function addBoxes(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const boxCount = parseInt(value);
    const boxesTemp = [...boxes];
    if (boxes.length > boxCount) {
      // boxesTemp.splice(boxCount);
      setOpenBoxValidationModal(true);
    } else if (boxes.length < boxCount) {
      for (let i = 0; i < boxCount - boxes.length; i++) {
        const box = structuredClone(defaultBoxState);
        box['id'] = boxesTemp.length.toString();
        boxesTemp.push(box);
      }
    }
    setBoxes(boxesTemp);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = event.target;
    const data = { ...shipmentType };
    // if (name in defaultShipmentTypeState) {
    //   data[name as keyof ShipmentDataType] = value;
    // }
    if (name === 'type' && value === 'document' && boxes.length > 1) {
      setOpenBoxValidationModal(true);
    } else if (name === 'invoiceNumber' || name === 'numberOfBoxes' || name === 'referenceNumber' || name === 'type') {
      data[name] = value;
    }
    setShipmentType({ ...data });
  }

  function handleDropdownChange(
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
    _child: React.ReactNode,
  ) {
    // validateProperty(event);
    const { name, value } = event.target;
    const data: ShipmentDataType = { ...shipmentType };
    if (name === 'shipmentMode' || name === 'currency' || name === 'type') {
      data[name] = value;
    } else if (name === 'unit') {
      data[name] = value as LengthType;
    }
    setShipmentType({ ...data });
  }

  return (
    <>
      <BoxValidationError open={openBoxValidationModal} onClose={() => setOpenBoxValidationModal(false)} />
      <Grid>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item md={12} xs={12}>
            <Typography className="sm-text" fontWeight={'600'} style={{ height: '32px', lineHeight: '32px' }}>
              Select Shipment Type
            </Typography>
            <RadioButton
              containerClass="w-full"
              name="type"
              defaultValue={type}
              value={type}
              onChange={handleChange}
              list={ShipmentTypeList}
              sx={{ display: 'flex', columnGap: 10 }}
            />
            <Divider className="my-4" />
          </Grid>
          <Grid container item md={12} xs={12} spacing={5}>
            <Grid item md={2.5} xs={12}>
              <SBDropdown
                required
                label={'Shipment Mode'}
                id="shipmentMode"
                name="shipmentMode"
                placeholder=""
                options={SHIPMENT_MODES}
                defaultValue={shipmentMode ? [shipmentMode] : []}
                value={shipmentMode ? [shipmentMode] : []}
                onChange={handleDropdownChange}
              />
            </Grid>
            <Grid item md={2.5} xs={12}>
              <SBDropdown
                required
                id="company-country-id"
                label="Preferred Unit"
                name="unit"
                placeholder="kg-cm"
                options={SHIPMENT_UNITS}
                value={unit ? [unit] : []}
                onChange={handleDropdownChange}
              />
            </Grid>
            <Grid item md={2.5} xs={12}>
              <SbTextField
                placeholder=""
                label="No. of Boxes"
                onChange={event => {
                  event.target.value = `${Math.floor(Number(event.target.value))}`;
                  handleChange(event);
                }}
                name="numberOfBoxes"
                value={numberOfBoxes}
                required
                onBlur={addBoxes}
                type="number"
              />
            </Grid>
            <Grid item md={2.5} xs={12}>
              <SBDropdown
                id="company-country-id"
                label={'Currency'}
                placeholder=""
                options={currencyList}
                required
                defaultValue={orgCountryCurrency ? [orgCountryCurrency] : []}
                name="currency"
                value={currency ? [currency] : []}
                onChange={handleDropdownChange}
              />
            </Grid>
          </Grid>
          <Grid container item md={12} xs={13} spacing={5}>
            <Grid item md={2.5} xs={12}>
              <SbTextField
                placeholder=""
                label="Reference Number"
                value={referenceNumber}
                onChange={handleChange}
                name="referenceNumber"
              />
              <FormHelperText className="m-0 sb-text-info">Shipment can also be tracked with ref. no.</FormHelperText>
            </Grid>
            <Grid item md={2.5} xs={12}>
              <SbTextField
                placeholder=""
                label="Invoice Number"
                value={invoiceNumber}
                onChange={handleChange}
                name="invoiceNumber"
              />
              <FormHelperText className="m-0 sb-text-info">Auto-generated if not entered</FormHelperText>
            </Grid>
            <Grid item md={2.5} xs={12}>
              <SBDropdown
                id="purposeOfShipment"
                placeholder=""
                label="Purpose Of Shipment"
                name="purposeOfShipment"
                options={SHIPMENT_TYPE}
                value={extraDetails.purposeOfShipment ? [extraDetails.purposeOfShipment] : []}
                onChange={([value]) => setExtraDetails({ ...extraDetails, purposeOfShipment: value })}
              />
            </Grid>
            <Grid item md={2.5} xs={12}>
              <SbTextField
                label="IOR/EORI No."
                name="ior"
                placeholder=""
                value={extraDetails.ior}
                onChange={value => setExtraDetails({ ...extraDetails, ior: value.target.value })}
              />
            </Grid>
          </Grid>
        </Grid>
        <InsurancePackaging />
        <Divider className="my-4" />
        {/* {isMobile && <Actions />} */}
      </Grid>
    </>
  );
};

export default ShipmentTypeDetails;
