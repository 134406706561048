export interface ColumnsHeaderType {
  regEx: RegExp;
  key: string;
  label: string;
  require?: boolean;
  isMatch?: boolean;
  position: number;
}

const SHIPPER_COLUMNS_HEADER: ColumnsHeaderType[] = [
  {
    regEx: /^(shipper|s) Name/i,
    key: 'shipperName',
    label: 'Shipper Name',
    require: true,
    position: 0,
  },
  {
    regEx: /^(shipper|s) Company/i,
    key: 'shipperCompany',
    label: 'Shipper Company',
    require: false,
    position: 0,
  },
  {
    regEx: /^(shipper|s) Country Telephone Code/i,
    key: 'shipperCountryCode',
    label: 'Shipper Country Telephone Code',
    require: true,
    position: 0,
  },
  {
    regEx: /^(shipper|s) Contact Number/i,
    key: 'shipperContactNumber',
    label: 'Shipper Contact Number',
    require: true,
    position: 0,
  },

  {
    regEx: /^(shipper|s) Address Line 1/i,
    key: 'shipperAddressLine1',
    label: 'Shipper Address Line 1',
    require: true,
    position: 0,
  },
  {
    regEx: /^(shipper|s) Address Line 2/i,
    key: 'shipperAddressLine2',
    label: 'Shipper Address Line 2',
    require: false,
    position: 0,
  },
  {
    regEx: /^(shipper|s) City/i,
    key: 'shipperCity',
    label: 'Shipper City',
    require: true,
    position: 0,
  },
  {
    regEx: /^(shipper|s) State/i,
    key: 'shipperState',
    label: 'Shipper State',
    require: false,
    position: 0,
  },
  {
    regEx: /^(shipper|s) Country/i,
    key: 'shipperCountry',
    label: 'Shipper Country',
    require: true,
    position: 0,
  },
  {
    regEx: /^(shipper|s) Pincode/i,
    key: 'shipperPincode',
    label: 'Shipper Pincode',
    require: true,
    position: 0,
  },
];

const CONSIGNEE_COLUMNS_HEADER: ColumnsHeaderType[] = [
  {
    regEx: /^(consignee|c) Name/i,
    key: 'consigneeName',
    label: 'Consignee Name',
    require: true,
    position: 0,
  },
  {
    regEx: /^(consignee|c) Company/i,
    key: 'consigneeCompany',
    label: 'Consignee Company',
    require: false,
    position: 0,
  },
  {
    regEx: /^(consignee|c) Email/i,
    key: 'consigneeEmail',
    label: 'Consignee Email',
    require: false,
    position: 0,
  },
  {
    regEx: /^(consignee|c) Country Telephone Code/i,
    key: 'consigneeCountryCode',
    label: 'Consignee Country Telephone Code',
    require: true,
    position: 0,
  },
  {
    regEx: /^(consignee|c) Contact Number/i,
    key: 'consigneeContactNumber',
    label: 'Consignee Contact Number',
    require: true,
    position: 0,
  },

  {
    regEx: /^(consignee|c) Address Line 1/i,
    key: 'consigneeAddressLine1',
    label: 'Consignee Address Line 1',
    require: true,
    position: 0,
  },
  {
    regEx: /^(consignee|c) Address Line 2/i,
    key: 'consigneeAddressLine2',
    label: 'Consignee Address Line 2',
    require: false,
    position: 0,
  },
  {
    regEx: /^(consignee|c) City/i,
    key: 'consigneeCity',
    label: 'Consignee City',
    require: true,
    position: 0,
  },
  {
    regEx: /^(consignee|c) State/i,
    key: 'consigneeState',
    label: 'Consignee State',
    require: true,
    position: 0,
  },
  {
    regEx: /^(consignee|c) Country/i,
    key: 'consigneeCountry',
    label: 'Consignee Country',
    require: true,
    position: 0,
  },
  {
    regEx: /^(consignee|c) Pincode/i,
    key: 'consigneePincode',
    label: 'Consignee Pincode',
    require: true,
    position: 0,
  },
];

const SHIPMENT_INFO_COLUMNS_HEADER: ColumnsHeaderType[] = [
  {
    regEx: /^Shipment Type/i,
    key: 'shipmentType',
    label: 'Shipment Type (Parcel / Document)',
    require: true,
    position: 0,
  },
  {
    regEx: /packaging/i,
    key: 'packaging',
    label: 'Packaging (Yes/No)',
    require: false,
    position: 0,
  },
  {
    regEx: /insurance/i,
    key: 'insurance',
    label: 'Insurance (Yes/No)',
    require: false,
    position: 0,
  },
  {
    regEx: /^(invoice) number/i,
    key: 'invoiceNumber',
    label: 'Invoice Number',
    require: false,
    position: 0,
  },
  {
    regEx: /currency/i,
    key: 'currency',
    label: 'Currency',
    require: true,
    position: 0,
  },
  {
    regEx: /^unit of measurement/i,
    key: 'unitOfMeasurement',
    label: 'Unit Of Measurement (kg/lb)',
    require: true,
    position: 0,
  },
  {
    regEx: /reference number/i,
    key: 'referenceNumber',
    label: 'Reference Number',
    require: false,
    position: 0,
  },
];

const BOX_INFO_HEADER_COLUMNS: ColumnsHeaderType[] = [
  {
    regEx: /^box no/i,
    key: 'boxNo',
    label: 'Box No',
    require: true,
    position: 0,
  },
  {
    regEx: /^duplicate boxes/i,
    key: 'duplicateBoxes',
    label: 'Duplicate Boxes',
    position: 0,
  },
  {
    regEx: /^weight/i,
    key: 'weight',
    label: 'Weight',
    require: true,
    position: 0,
  },

  {
    regEx: /length/i,
    key: 'length',
    label: 'Length',
    position: 0,
  },
  {
    regEx: /width/i,
    key: 'width',
    label: 'Width',
    position: 0,
  },
  {
    regEx: /height/i,
    key: 'height',
    label: 'Height',
    position: 0,
  },
];

const PRODUCT_INFO_HEADER_COLUMNS: ColumnsHeaderType[] = [
  {
    regEx: /product description/i,
    key: 'description',
    label: 'Product Description',
    require: true,
    position: 0,
  },
  {
    regEx: /product quantity/i,
    key: 'quantity',
    label: 'Product Quantity',
    require: true,
    position: 0,
  },
  {
    regEx: /product unit value/i,
    key: 'value',
    label: 'Product Unit Value',
    require: true,
    position: 0,
  },

  {
    regEx: /product hsn name/i,
    key: 'HSNName',
    label: 'Product HSN Name',
    require: false,
    position: 0,
  },
  {
    regEx: /product hsn code/i,
    key: 'HSNCode',
    label: 'Product HSN Code',
    require: false,
    position: 0,
  },
];
const COLUMNS_HEADER: ColumnsHeaderType[] = [
  SHIPPER_COLUMNS_HEADER,
  CONSIGNEE_COLUMNS_HEADER,
  SHIPMENT_INFO_COLUMNS_HEADER,
  BOX_INFO_HEADER_COLUMNS,
  PRODUCT_INFO_HEADER_COLUMNS,
]
  .flat(1)
  .map((x, i) => ({ ...x, position: i }));
export {
  SHIPPER_COLUMNS_HEADER,
  CONSIGNEE_COLUMNS_HEADER,
  SHIPMENT_INFO_COLUMNS_HEADER,
  BOX_INFO_HEADER_COLUMNS,
  PRODUCT_INFO_HEADER_COLUMNS,
  COLUMNS_HEADER,
};
