import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// import { getLoggedInUser } from '../../store/reducers/loginUser/actions';
import { RootStateType } from '../../store';
import Loader from '../../components/Loader';
import { TabContext, TabList } from '@mui/lab';
import TabProfile from './ProfileTab';
import TabPasswordChange from './PasswordChangeTab';
import TabCompany from './CompanyTab';
import TabKyc from './KycTab';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { userLogin } from '@Services/hasAdminAccess';
import { getAccountType } from '@Services/user';

type TabValues = 'profile' | 'kyc' | 'company_details' | 'change_password' | string;

interface TabAccessKeyType {
  isCompanyInfoShow: boolean;
  isKYCInfoShow: boolean;
}

type TabAccessKey = keyof TabAccessKeyType;

const TABS: { label: string; value: TabValues; accessKey?: TabAccessKey }[] = [
  { label: 'Profile', value: 'profile' },
  { label: 'KYC', value: 'kyc', accessKey: 'isKYCInfoShow' },
  { label: 'Company Details', value: 'company_details', accessKey: 'isCompanyInfoShow' },
  { label: 'Change Password', value: 'change_password' },
];

const TAB_KEYS: TabValues[] = TABS.map(x => x.value);

const ProfileContainerStyle = styled(Grid)(({ theme: _ }) => ({
  background: '#fff',
  width: '100%',
  // maxHeight: '100vh',
}));

const TitleContentStyle = styled(Grid)(({ theme: _ }) => ({
  marginBottom: '24px',
}));

function Profile() {
  // const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();

  const [currentTap, setCurrentTap] = useState<TabValues>('profile');
  const accountState = useSelector((state: RootStateType) => state.loginUser.profile?._accountId);
  const loading = useSelector(
    (state: RootStateType) =>
      state.loginUser.loading ||
      state.kyc.loading ||
      state.loginUser.password.loading ||
      state.loginUser.companyDetailsLoading,
  );

  useEffect(() => {
    const currentActiveTab: TabValues = searchParams.get('tab') || 'profile';
    if (TAB_KEYS.includes(currentActiveTab)) setCurrentTap(currentActiveTab);
    // dispatch(getLoggedInUser());
  }, []);

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    navigation(`?tab=${currentTab}`);
    setCurrentTap(currentTab);
  };

  const companyUser = getAccountType(accountState) === 'account-individual-company';

  const tabAccessKey: TabAccessKeyType = {
    isCompanyInfoShow: getAccountType(accountState) === 'account-individual-company',
    isKYCInfoShow: !isAdmin && !companyUser,
  };
  return (
    <>
      <Loader loading={loading} />
      <ProfileContainerStyle container alignContent="flex-start">
        <TitleContentStyle container item>
          <Typography className="font-semibold l-text">Account Settings</Typography>
        </TitleContentStyle>
        <TabContext value={currentTap}>
          <Grid container sx={{ borderBottom: '1px solid #E4E4E4', marginBottom: '50px' }}>
            <TabList
              onChange={onTabChange}
              indicatorColor="primary"
              TabIndicatorProps={{ sx: { height: '4px' } }}
              scrollButtons="auto"
              variant="scrollable"
            >
              {TABS.filter(cTab => (cTab.accessKey ? tabAccessKey[cTab.accessKey] : true)).map(cTab => (
                // <Link to={`/${cTab.value}`}  >
                <Tab
                  sx={{ textTransform: 'none', padding: '16px 16px' }}
                  className="md-text font-medium sb-text-black"
                  value={cTab.value}
                  label={cTab.label}
                  key={cTab.value}
                />
                // </Link>
              ))}
            </TabList>
          </Grid>
          <TabProfile />
          <TabPasswordChange />
          <TabCompany />
          <TabKyc />
        </TabContext>
      </ProfileContainerStyle>
    </>
  );
}

export default Profile;
