import dayjs from 'dayjs';
import { DropdownOptionType } from '../../components/Dropdown/Type';
import {
  BoxErrorType,
  BoxType,
  ConsigneeType,
  LengthDropdownType,
  PackageType,
  PickupDataType,
  PickupTypeListType,
  ProductErrorType,
  ProductType,
  ShipmentDataType,
  ShipperType,
  userDetailsErrorType,
  userDetailsType,
  importShipmentsType,
} from '../../types/shipment';

export const defaultUserState: userDetailsType = {
  name: '',
  companyName: '',
  line1: '',
  line2: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
  telephoneCode: '',
  phoneNumber: '',
  email: '',
  altContactName: '',
  altContactNo: '',
  altContactTelephoneCode: '',
  type: '',
  IdType: '',
  IdNumber: '',
};
export const defaultShipperState: ShipperType = {
  ...defaultUserState,
};
export const defaultConsignee: ConsigneeType = {
  ...defaultUserState,
};
export const defaultErrorState: userDetailsErrorType = {
  ...defaultUserState,
  telephoneCode: '',
  altContactTelephoneCode: '',
};

// export const PICKUP_TYPE = {
//   DROP_OFF: 'dropOff',
//   PICKUP: 'pickUp',
// };

export const PickupTypeList: PickupTypeListType[] = [
  { value: 'pickUp', label: 'Pickup' },
  { value: 'dropOff', label: 'Drop-off' },
];

export const importShipments: importShipmentsType[] = [
  { value: 'thermalPrinter', label: 'Thermal Printer' },
  { value: 'laserPrinter', label: 'Laser Printer' },
];

export const defaultPickupState: PickupDataType = {
  type: 'pickUp',
  date: dayjs(),
  timeSlot: '',
  warehouse: '',
};

export const defaultProductState: ProductType = {
  // id: '0',
  description: '',
  quantity: null,
  unitValue: null,
  HSNName: '',
  HSNCode: '',
  totalValue: 0,
  actions: null,
};

export const defaultProductErrorState: ProductErrorType = {
  description: '',
  quantity: '',
  unitValue: '',
  HSNName: '',
  HSNCode: '',
};

export const defaultBoxesErrorState: BoxErrorType = {
  length: '',
  width: '',
  height: '',
  weight: '',
};

export const defaultBoxState: BoxType = {
  id: '0',
  length: '',
  width: '',
  height: '',
  weight: '',
  boxCopyCount: 0,
  products: [],
  selected: true,
};

export const defaultPackageState: PackageType = {
  unitOfMeasurement: '',
  packaging: false,
  insurance: false,
  isDocument: false,
  isCod: false,
  isDropOff: false,
  pickupDate: dayjs(new Date()),
  timeSlot: '',
  codAmount: 0.0,
  currency: '',
  box: [{ ...defaultBoxState }],
  invoiceNumber: '',
  userReferenceNumber: '',
};

export const defaultBoxesState: BoxType[] = [{ ...defaultBoxState }];

export const USER_TYPE = {
  SHIPPER: 'Shipper',
  CONSIGNEE: 'Consignee',
};

export const TIME_SLOTS: DropdownOptionType[] = [
  { display: '9am-1pm', value: '9am-1pm' },
  { display: '1pm-5pm', value: '1pm-5pm' },
  { display: '5pm-9pm', value: '5pm-9pm' },
];

export const SHIPMENT_UNITS: LengthDropdownType[] = [
  { display: 'kg-cm', value: 'kg-cm' },
  { display: 'lb-in', value: 'lb-in' },
];

export const defaultShipmentTypeState: ShipmentDataType = {
  type: 'parcel',
  invoiceNumber: '',
  numberOfBoxes: '',
  currency: 'INR',
  unit: SHIPMENT_UNITS[0].value,
  shipmentMode: 'b2b',
  referenceNumber: '',
};

export const SHIPMENT_MODES: DropdownOptionType[] = [
  { display: 'B2B', value: 'b2b' },
  { display: 'B2C', value: 'b2c' },
];
