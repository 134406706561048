import { Checkbox, FormGroup, Grid } from '@mui/material';
import React from 'react';
import SbTooltip from '../../../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import DocTable from '../DocTable';
import { OrganizationSetupContextType } from '../../../../types/organization';
import { SectionSubText, SectionText, SectionTitle, SectionWrapper } from '../../Style';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

interface PropsType {
  data: OrganizationSetupContextType['individualAccountHolder'];
  handleChange: OrganizationSetupContextType['setIndividualAccountHolder'];
}

function NonAccountHolderKYC(props: PropsType) {
  const { data: nonAccountHolder, handleChange: setNonAccountHolder } = props;

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isNonAccountHolderWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.KYC_Settings.non_account_holder,
    PERFORM_ACTION.write,
  );

  return (
    <SectionWrapper>
      <Grid container item xs={12} className="mb-3">
        <Grid container justifyContent={'start'} alignItems={'center'} className="mb-1">
          <SectionTitle className="mr-2">Non-Account holder</SectionTitle>
          <SbTooltip
            placement="right"
            title={`Individual users will be able to upload their KYC from below options. 
            You can select which ones you want to make mandatory by clicking the checkbox. 
            (Individual users can be account holder and non-account holder)`}
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>
        {/* <SectionSubText>
          Individual users will be able to upload their KYC from below options. You can select the ones you want to make
          mandatory by clicking the checkbox.
        </SectionSubText> */}
      </Grid>
      <Grid container item xs={12} className="mb-3">
        <SectionText className="mb-2">Do you want KYC mandatory for Non-Account holders ?*</SectionText>
        <Grid container justifyContent={'space-between'} alignItems={'center'} className="mb-1">
          <Grid item xs={11}>
            <SectionSubText sx={{ maxWidth: '80%' }}>
              If you make KYC mandatory, the user cannot place the shipment without submitting the KYC Documents.
            </SectionSubText>
          </Grid>
          <Grid item xs={1}>
            <FormGroup classes={{ root: 'flex' }}>
              <Checkbox
                sx={{ height: 40, width: 40 }}
                defaultChecked
                checked={nonAccountHolder.requiredDocExist}
                onChange={event => setNonAccountHolder({ ...nonAccountHolder, requiredDocExist: event.target.checked })}
                disabled={!isNonAccountHolderWriteAllowed}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} className="mb-3">
        <DocTable
          settings={nonAccountHolder}
          setList={setNonAccountHolder}
          disabled={!isNonAccountHolderWriteAllowed}
        />
      </Grid>
    </SectionWrapper>
  );
}

export default NonAccountHolderKYC;
