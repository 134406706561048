import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getRateFulfilled,
  getRatePending,
  getRateRejected,
  deleteRatesPending,
  deleteRatesFulfilled,
  deleteRatesRejected,
  getSelectedDateRate,
  getRateSheetFulfilled,
  // resetShipmentSummary,
} from './case';
import { RateState } from './Type';
import { getRates, deleteRates, getRatesSheet } from './actions';

const initialState = <RateState>(<unknown>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  config: {
    selectedAccountNumber: '',
    pageType: 'base',
    isImport: false,
    selectedDate: '',
  },
  selectedDateRate: [],
  allSelectedDateRate: [],
  mergedRatesArr: [],
  ratesForDayInYear: {},
  sheets: {},
  filterIndex: 0,
  filters: {
    system: [],
    csp: [],
    rateType: [],
  },
  rateDataByAccountNumber: {},
  totalCount: 0,
  currentPage: 0,
  perPage: 10,
  sheetCount: 0,
});

const RateListSlice = createSlice({
  name: 'rates',
  initialState: {
    ...initialState,
  },
  reducers: {
    changeNavigatedDate: function (
      state,
      action: PayloadAction<{ selectedDate: RateState['config']['selectedDate'] }>,
    ) {
      const selectedDateRate = getSelectedDateRate(
        state.rateDataByAccountNumber[state.config.selectedAccountNumber || 'default'] || [],
        new Date(action.payload.selectedDate),
      );
      state.selectedDateRate = selectedDateRate;
      state.config.selectedDate = action.payload.selectedDate;
    },
    updateFilters: function (state, action: PayloadAction<RateState['filters']>) {
      if (
        state.loading ||
        state.config.selectedAccountNumber === undefined ||
        state.config.selectedAccountNumber === ''
      ) {
        state.selectedDateRate = [];
        return state;
      }
      const selectedAccountNumber = state.config.selectedAccountNumber || 'default';

      const ratesData = state.rateDataByAccountNumber[selectedAccountNumber];

      const { csp, system, rateType } = action.payload;
      const cspFilterObj = csp.length ? csp.reduce((op: Record<string, boolean>, c) => ((op[c] = true), op), {}) : null;
      const sysFilterObj = system.length
        ? system.reduce((op: Record<string, boolean>, s) => ((op[s] = true), op), {})
        : null;
      const selectedDateRate = getSelectedDateRate(ratesData, new Date(state.config.selectedDate));

      const filteredRates = selectedDateRate.filter(rateObj => {
        if (cspFilterObj && !cspFilterObj[rateObj.csp]) return false;
        if (sysFilterObj && !sysFilterObj[rateObj.system]) return false;
        if (rateType === 'parcel' && rateObj.documentRates) return false;
        if (rateType === 'document' && !rateObj.documentRates) return false;
        return true;
      });
      state.selectedDateRate = filteredRates;
      state.totalCount = filteredRates.length;
    },
    resetRateSummary: function (state) {
      state = { ...initialState };
      return state;
    },
    onChangePagination: function (state, action) {
      state.currentPage = action.payload;
      const startIndex = state.currentPage * state.perPage;
      const endIndex = startIndex + state.perPage;
      state.selectedDateRate = state.allSelectedDateRate?.slice(startIndex, endIndex);
    },
    updatedIsImport: function (state, action) {
      state.config.isImport = action.payload;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getRates.pending, getRatePending);
    addCase(getRates.fulfilled, getRateFulfilled);
    addCase(getRates.rejected, getRateRejected);
    addCase(getRatesSheet.pending, getRatePending);
    addCase(getRatesSheet.fulfilled, getRateSheetFulfilled);
    addCase(getRatesSheet.rejected, getRateRejected);

    addCase(deleteRates.pending, deleteRatesPending);
    addCase(deleteRates.fulfilled, deleteRatesFulfilled);
    addCase(deleteRates.rejected, deleteRatesRejected);
  },
});

// const { setUser } = RateListSlice.actions;

export { getRates, deleteRates };
export const { changeNavigatedDate, updateFilters, resetRateSummary, onChangePagination, updatedIsImport } =
  RateListSlice.actions;
export default RateListSlice.reducer;
