import Joi from 'joi';
import { SHIPMENT_TYPES, WEIGHT_UNITS } from '../Constant';

// shipmentType: SHIPMENT_TYPES[0].value,
//   pickup: {},
//   destination: {},
//   pickupPinCode: '',
//   destinationPinCode: '',
//   weight: 0,
//   weightUnit: WEIGHT_UNITS[0].value,
//   boxCount: 0,

// function getDocumentWeightValidation(value: string, unit: TypeOfWeightUnit) {
//   const number = parseFloat(value);
//   let error = '';
//   if (unit === 'lb' && number > 5.512) {
//     error = 'Document should not be more than 5.512lb.';
//   } else if (unit === 'kg' && number > 2.5) {
//     error = 'Document should not be more than 2.5kg.';
//   }
//   return error;
// }

export const documentWeight: { [key: string]: number } = {
  kg: 2.5,
  lb: 5.512,
  'kg-cm': 2.5,
  'lb-in': 5.512,
};

const addressSchema = {
  city: Joi.string().allow('').optional(),
  state: Joi.string().allow('').optional(),
  country: Joi.string().required(),
  pincode: Joi.string().allow('').optional(),
};

export const inputRatesSchema = Joi.object().keys({
  pickup: Joi.object().keys(addressSchema),
  pickupPinCode: Joi.string().required().messages({ 'string.empty': 'Pickup pincode is required.' }),
  destination: Joi.object().keys(addressSchema),
  destinationPinCode: Joi.string().required().messages({ 'string.empty': 'Destination pincode is required.' }),
  weight: Joi.alternatives().conditional('shipmentType', [
    {
      is: SHIPMENT_TYPES[0].value,
      then: Joi.number().min(0.01).max(999999).required().messages({ 'number.base': `Please enter valid weight` }),
    },
    {
      is: SHIPMENT_TYPES[1].value,
      then: Joi.alternatives().conditional('weightUnit', [
        {
          is: WEIGHT_UNITS[0].value,
          then: Joi.number()
            .min(0.01)
            .max(documentWeight[WEIGHT_UNITS[0].value])
            .required()
            .messages({ 'number.base': `Please enter valid weight` }),
        },
        {
          is: WEIGHT_UNITS[1].value,
          then: Joi.number()
            .min(0.01)
            .max(documentWeight[WEIGHT_UNITS[1].value])
            .required()
            .messages({ 'number.base': `Please enter valid weight` }),
        },
      ]),
    },
  ]),
  weightUnit: Joi.string().required(),
  shipmentType: Joi.string().required(),
});
