import React from 'react';
import moment from 'moment';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Typography, styled, TableBody, TableHead, TableRow, Grid } from '@mui/material';

import ErrorModal from '@Components/Modal/error';
import SbButton from '@Components/Button';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { resetError as resetRateUploadError, resetRateUploadState } from '@Reducers/RateUpload';
import { RateRowService } from '../PricingList/RateRow';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Divider from '@Components/Divider';
import { rateFor } from './type';
import { updatedIsImport } from '@Reducers/RateSummary';

const DATE_FORMAT = 'DD/MM/YYYY';

interface Props {
  onFormClear: () => void;
  navigateTo?: string;
  pageType?: rateFor;
}

function SuccessError({ onFormClear, navigateTo, pageType }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const RateUpload = useSelector((state: RootStateType) => state.RateUpload);

  const rateUploadError = !!RateUpload.error?.isError;
  const rateUploadSuccessFully = !!RateUpload.rateSuccessfullyUpload;
  let modalMsg = (rateUploadError && 'Oops!') || (rateUploadSuccessFully && 'Rate successfully uploaded') || '';
  let modalSubMsg = (rateUploadError && RateUpload.error?.msg) || (rateUploadSuccessFully && '');
  if (rateUploadError) modalMsg = 'Oops!';
  else if (rateUploadSuccessFully) {
    modalMsg = 'Rate successfully uploaded';
    if (RateUpload.isActiveRate)
      modalSubMsg = `Uploaded rates will be applicable from tomorrow (${moment()
        .add('1', 'days')
        .format('DD/MM/YYYY')})`;
  }

  const onClose = () => {
    if (rateUploadSuccessFully) {
      const searchParams = new URLSearchParams(location.search);
      // const ratesFor = searchParams.get('ratesFor');
      const isImport = searchParams.get('isImport');
      onFormClear();
      // navigate(`/pricing/${pageType}?ratesFor=${ratesFor}`);
      if (isImport === 'true') {
        navigate(`/pricing/${pageType}?ratesFor=inbound`);
        dispatch(updatedIsImport(true));
      } else {
        navigate(`/pricing/${pageType}?ratesFor=outbound`);
        dispatch(updatedIsImport(false));
      }
      dispatch(resetRateUploadState());
      // return;
    }
    if (navigateTo) navigate(navigateTo);
    dispatch(resetRateUploadError());
  };

  return (
    <>
      {!RateUpload.copyErrorList?.length && (
        <ErrorModal
          icon={
            (rateUploadSuccessFully && '/images/paid.svg') ||
            (rateUploadError && '/images/Boxes/NoRatesFound.svg') ||
            ''
          }
          open={rateUploadError || rateUploadSuccessFully}
          title={modalMsg}
          subTitle={modalSubMsg}
          onClose={onClose}
          onConfirm={onClose}
          btnText="Close"
        />
      )}
      {RateUpload.copyErrorList?.length ? (
        <ErrorModal
          icon={'/images/paid.svg'}
          open={!!RateUpload.copyErrorList?.length}
          onClose={onClose}
          onConfirm={onClose}
        >
          <Title className="font-medium xl-text">Rates uploaded successfully</Title>
          <Divider className="mt-2" />
          <Grid container justifyContent="center" alignItems="center" className="mt-4">
            <Title className="w-full mt-0 font-medium text-center l-text flex items-center justify-center">
              <WarningAmberIcon className="mr-1" />
              Unable to copy below rates
            </Title>
          </Grid>
          <SBTable className="mt-3">
            <TableHead>
              <TableRow>
                <TableHeadCell>Service</TableHeadCell>
                <TableHeadCell>Colliding Date</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {RateUpload.copyErrorList.map(copy_error => (
                <TableRow>
                  <TableRowCell>
                    <RateRowService cspId={copy_error.csp} systemId={copy_error.system} />
                  </TableRowCell>
                  <TableRowCell>
                    <Typography className="sm-text text-center">
                      {moment(copy_error.startDate).format(DATE_FORMAT)} -{' '}
                      {moment(copy_error.endDate).format(DATE_FORMAT)}
                    </Typography>
                  </TableRowCell>
                </TableRow>
              ))}
            </TableBody>
          </SBTable>
          <SbButton
            onClick={onClose}
            variant="contained"
            className="mt-4 rounded-full"
            style={{ height: '44px', width: '148px' }}
          >
            Close
          </SbButton>
        </ErrorModal>
      ) : null}
    </>
  );
}

const Title = styled(Typography)(({ theme }) => ({
  // fontSize: '32px',
  maxWidth: '500px',
  marginTop: theme.spacing(4),
}));

export default SuccessError;
