import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getShipmentById, getShipmentPaymentBySN } from '../../store/reducers/Shipment/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch, RootStateType } from '../../store';
import PackagingDetails from './PackagingDetails';
import Title from '../../components/Title';
import ShipmentHistoryActions from './ShipmentHistoryActions';
import ShipperConsigneeDetails from './ShipperConsigneeDetails';
import { Grid } from '@mui/material';
import ShipmentCharges from './ShipmentCharges';
import ShipmentDetails from './ShipmentDetails';
import TrackingDetails from './TrackingDetails';
import { resetShipmentById } from '@Reducers/Shipment';
import { getShipmentSettings } from '@Reducers/OrganizationSetup';
import Loader from '@Components/Loader';
import CancelAndTrackShipment from '@Components/CancelAndTrackShipment';
import { useWebsocket } from '@Hook/useWebsocket';

export function ViewSingleShipment() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id: shipmentId } = useParams();
  const { loading, shipmentCancelled } = useSelector((state: RootStateType) => state.shipmentHistory);
  const { shipmentById } = useSelector((state: RootStateType) => state.shipment);

  useEffect(() => {
    if (shipmentId) {
      dispatch(getShipmentSettings());
      dispatch(getShipmentById(shipmentId));
      dispatch(getShipmentPaymentBySN(shipmentId));
    }
    return () => {
      dispatch(resetShipmentById());
    };
  }, []);

  useEffect(() => {
    if (shipmentCancelled) {
      navigate('/shipment/history');
    }
  }, [shipmentCancelled]);

  useWebsocket({
    query: {
      channel: 'shipment-update-' + shipmentId,
    },
  });

  return (
    <Loader loading={loading} overly>
      <>
        <Title title="Shipment History" removeBorder />
        <ShipmentHistoryActions />
        <Grid container justifyContent={'space-between'}>
          <Grid />
          <Grid>
            {shipmentById?.status && (
              <CancelAndTrackShipment shipmentStatus={shipmentById.status} accountId={shipmentById?._userId._id} />
            )}
          </Grid>
        </Grid>
        <TrackingDetails />
        <PackagingDetails />
        <Grid container columnSpacing={{ lg: 5, md: 7, sm: 0 }} rowSpacing={{ lg: 0, md: 0, sm: 7 }}>
          <Grid item lg={8} md={7} sm={12} xs={12}>
            <ShipperConsigneeDetails />
          </Grid>
          <Grid item lg={4} md={5} sm={12} xs={12}>
            <ShipmentCharges />
          </Grid>
        </Grid>
        <ShipmentDetails />
      </>
    </Loader>
  );
}

export default ViewSingleShipment;
