import { Grid, Typography } from '@mui/material';
import React from 'react';
// import { styled } from '@mui/material/styles';

// const ContainerStyle = styled(Grid)(() => ({
//   paddingBottom: '20px',
//   // borderBottom: '1px solid #EAECF0',
// }));

// const ContainerStyle = styled(Grid)<{ removeBorder?: boolean }>(({ removeBorder }) => ({
//   paddingBottom: '20px',
//   borderBottom: removeBorder ? 'unset' : '1px solid #EAECF0',
// }));

function Title(props: {
  title: string;
  subTitle?: string;
  titleClass?: string;
  removeBorder?: boolean;
  removePadding?: boolean;
  titleIconLeft?: React.ReactNode;
  subTextSize?: string;
}) {
  return (
    <Grid
      container
      gap={'4px'}
      sx={{
        borderBottom: props.removeBorder ? 'unset' : '1px solid #EAECF0',
        paddingBottom: props.removePadding ? 'unset' : 'calc(var(--mui-palette-spacing) * 2.5)',
      }}
    >
      <Grid container alignItems={'flex-start'}>
        <>{props.titleIconLeft ? props.titleIconLeft : <></>}</>
        <div className={`${props.titleIconLeft ? 'ml-2' : ''}`}>
          <Grid container item xs={12} alignItems={'center'}>
            <Typography className={`l-text font-semibold ${props.titleClass}`}>{props.title}</Typography>
          </Grid>
          {props.subTitle && (
            <Grid item xs={12}>
              <Typography color="#494949" className={`${props.subTextSize ? props.subTextSize : 'xs-text'}`}>
                {props.subTitle}
              </Typography>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default Title;
