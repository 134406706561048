import React from 'react';
import { Grid } from '@mui/material';

import Title from '../../../components/Title';
import { TabPanel } from '@mui/lab';
import DocumentUpload from '../../../components/DocumentUpload';
// import { styled } from '@mui/material/styles';
// import UploadDocument from './UploadDocument';
// import DocumentDownloadSuccessfully from './DocumentDownloadSuccessfully';

// const StyleContainer = styled(Grid)(({ theme }) => ({
//   padding: '40px 80px',
//   text: theme.breakpoints,
//   [theme.breakpoints.down('md')]: {
//     padding: '20px 40px',
//   },
// }));

function KycTab() {
  return (
    <>
      <TabPanel value="kyc" className="p-0 w-full">
        <Grid container p={{ md: '0 32px' }}>
          <Title title="KYC" subTitle="Update your kyc details." />
          <Grid item xs={12}>
            <DocumentUpload documentUploadType="kyc" />
          </Grid>
        </Grid>
      </TabPanel>
    </>
  );
}

export default KycTab;
