import React from 'react';
import { Grid, Typography } from '@mui/material';
import Modal from '../../../../components/Modal';
import SbButton from '../../../../components/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Props {
  open: boolean;
  onClose: () => void;
}

function BoxValidationError({ open, onClose }: Props) {
  return (
    <Modal open={open} onClose={onClose}>
      <Grid container justifyContent="flex-end">
        <HighlightOffIcon
          sx={{ cursor: 'pointer', position: 'absolute', right: '12px', top: '15px', height: '24px', width: '24px' }}
          color="action"
          onClick={onClose}
        />
        <Grid container item justifyContent="center">
          <img src="/images/shipment/error.gif" alt="logistics" style={{ height: '120px', width: '120px' }} />
        </Grid>
        <Grid container item justifyContent="center" alignItems={'center'} flexDirection="column" textAlign="center">
          <Typography className="md-text font-medium">Please delete the box first</Typography>
          <SbButton
            onClick={onClose}
            variant="contained"
            className="mt-4 rounded-full"
            style={{ height: '44px', width: '148px' }}
          >
            Confirm
          </SbButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
export default BoxValidationError;
