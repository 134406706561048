import React from 'react';

import { Grid } from '@mui/material';
import CreateForm, { initCreate } from './Form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { ODAType } from './type';
import { createOda } from '../../../store/reducers/ODA';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { CreateServiceSchema } from './schema';
import SbButton from '../../../components/Button';

function CreateOda() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onCreate = (payload: ODAType) => {
    dispatch(createOda({ payload: payload, showModal: true, navigate: `/oda` }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...initCreate },
    validationSchema: CreateServiceSchema,
    onSubmit(values) {
      onCreate(values);
    },
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <CreateForm
            formType="create"
            showBackgroundColor
            errors={formik.errors}
            values={formik.values}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
          />
        </Grid>
        <Grid container item mt={4} spacing={2} justifyContent="flex-end">
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton
              fullWidth
              variant="outlined"
              onClick={() => {
                navigate(`/oda`);
              }}
            >
              Cancel
            </SbButton>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton
              fullWidth
              variant="contained"
              onClick={_ => {
                formik.handleSubmit();
              }}
            >
              Create
            </SbButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateOda;
