import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '../../../services/apiAxios';
import { AccountDataPayload } from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { getShipmentWebsocketStream } from '@Reducers/AdminShipment/actions';

const sendAccountDataTo365 = createAsyncThunk(
  'sendAccountDataTo365',
  async (postData: AccountDataPayload, { dispatch }) => {
    try {
      const res = await API.post('/invoice/d365/account', postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg,
          navigateTo: '/invoice/view',
        }),
      );
      dispatch(
        getShipmentWebsocketStream(
          postData?.accountData?.flatMap(element => element?._shipmentNumbers?.map(item => 'shipment-update-' + item)),
        ),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

const sendAccountDebitDataTo365 = createAsyncThunk(
  'sendAccountDebitDataTo365',
  async (postData: AccountDataPayload, { dispatch }) => {
    try {
      const res = await API.post('/invoice/d365/account/postdebit', postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg,
          navigateTo: '/invoice/view',
        }),
      );
      dispatch(
        getShipmentWebsocketStream(
          postData?.accountData?.flatMap(element => element?._shipmentNumbers?.map(item => 'shipment-update-' + item)),
        ),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

const sendAccountCreditDataTo365 = createAsyncThunk(
  'sendAccountCreditDataTo365',
  async (postData: AccountDataPayload, { dispatch }) => {
    try {
      const res = await API.post('/invoice/d365/account/postcredit', postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg,
          navigateTo: '/invoice/view',
        }),
      );
      dispatch(
        getShipmentWebsocketStream(
          postData?.accountData?.flatMap(element => element?._shipmentNumbers?.map(item => 'shipment-update-' + item)),
        ),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

const sendNonAccountDataTo365 = createAsyncThunk(
  'sendNonAccountDataTo365',
  async (postData: { shipmentIds: string[]; shipmentNumbers: string[] }, { dispatch }) => {
    try {
      const res = await API.post('/invoice/d365/non-account', postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg,
          navigateTo: '/invoice/view?tab=non-account',
        }),
      );
      dispatch(getShipmentWebsocketStream(postData?.shipmentNumbers?.map(item => 'shipment-update-' + item)));
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

const sendNonAccountDebitDataTo365 = createAsyncThunk(
  'sendNonAccountDebitDataTo365',
  async (postData: { shipmentIds: string[]; shipmentNumbers: string[] }, { dispatch }) => {
    try {
      const res = await API.post('/invoice/d365/non-account/postdebit', postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg,
          navigateTo: '/invoice/view?tab=non-account',
        }),
      );
      dispatch(getShipmentWebsocketStream(postData?.shipmentNumbers?.map(item => 'shipment-update-' + item)));
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

const sendNonAccountCreditDataTo365 = createAsyncThunk(
  'sendNonAccountCreditDataTo365',
  async (postData: { shipmentIds: string[]; shipmentNumbers: string[] }, { dispatch }) => {
    try {
      const res = await API.post('/invoice/d365/non-account/postcredit', postData);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg,
          navigateTo: '/invoice/view?tab=non-account',
        }),
      );
      dispatch(getShipmentWebsocketStream(postData?.shipmentNumbers?.map(item => 'shipment-update-' + item)));
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went wrong.',
        }),
      );
      throw error;
    }
  },
);

export {
  sendNonAccountDataTo365,
  sendAccountDataTo365,
  sendAccountCreditDataTo365,
  sendAccountDebitDataTo365,
  sendNonAccountCreditDataTo365,
  sendNonAccountDebitDataTo365,
};
