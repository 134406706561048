export type OrgSetupScreens =
  | 'system'
  | 'warehouse'
  | 'org-setting'
  | 'kyc-settings'
  | 'invoice-settings'
  | 'shipment-settings'
  | 'first-screen';

export const organizationSetupRoute: { [key in OrgSetupScreens]: string } = {
  'first-screen': '/organization-setup',
  system: '/organization-setup/system',
  warehouse: '/organization-setup/warehouse',
  'org-setting': '/organization-setup/org-settings',
  'kyc-settings': '/organization-setup/kyc-settings',
  'invoice-settings': '/organization-setup/invoice-settings',
  'shipment-settings': '/organization-setup/complete',
};
