import { Formik } from 'formik';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// import SearchIcon from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import VisibilityIcon from '@mui/icons-material/Visibility';

import SbButton from '@Components/Button';
import SbDropdown from '@Components/Dropdown';
import Footer from '@Components/Footer';
import SbImage from '@Components/Image';
import Loader from '@Components/Loader';
import Modal from '@Components/Modal';
import ErrorModal from '@Components/Modal/error';
import SbTextField from '@Components/Textfield';

import Logo from '@Components/LeftSidebar/Logo';
import PoweredBy from '@Components/PoweredBy';
import { getCountryList } from '@Reducers/Global';
import { doSignup, resendVerification, resetSignupState, verifySignupEmail } from '@Reducers/Signup';
import { signupState } from '@Reducers/Signup/type';
import { AppDispatch, RootStateType } from '@Store';
import SuccessError from '@Components/Modal/SuccessError';

// const USERNAME_REGEXP = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// const PHONE_REGEXP = /^(0|91)?[6-9][0-9]{9}$/;

// matches(USERNAME_REGEXP, 'Invalid username.')
const SignupSchema = Yup.object().shape({
  name: Yup.string().trim().required('Name is required.').max(80, 'Name must be at most 80 characters.'),
  username: Yup.string()
    .trim()
    .required('Email is required.')
    .test('is-valid-username', 'Invalid username format', function (value) {
      if (!value) return true;
      const isEmail = /^[^\s@]{2,}@[^@\s]+\.[^\s@]{2,}$/.test(value);
      const isPhoneNumber = /^\d+$/.test(value);

      if (!isEmail && !isPhoneNumber) return false;
      if (isEmail) return Yup.string().email().isValidSync(value);

      return true;
    }),
  password: Yup.string()
    .trim()
    .required('Password is required.')
    .min(8, 'Must be 8 characters or more. One Uppercase, One Lowercase, One Number and One Special Case Character')
    .matches(/[A-Z]+/, 'One uppercase character')
    .matches(/[a-z]+/, 'One lowercase character')
    .matches(/\d+/, 'One number')
    .matches(/[@$!%*#?&]+/, 'One special character'),
  repassword: Yup.string()
    .trim()
    .when('password', (password, schema) => {
      return password
        ? schema.required('Confirm password is required.').oneOf([Yup.ref('password')], 'Confirm password must match')
        : schema;
    }),
  // country: Yup.string().required('Country is required.'),
});

const SignupGrid = styled(Grid)({
  boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
  borderRadius: '20px 0px 0px 20px',
  zIndex: 1,
  backgroundColor: '#fff',
  width: '700px',
  minHeight: '100vh',
});

const WelcomeOrgh1 = styled('h1')({
  alignItems: 'center',
  color: '#2C2B6D',
  fontWeight: '700',
  fontSize: '36px',
});

const BannerContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  background: "url('/images/login/Group.png')",
  backgroundPositionX: '547px',
  backgroundSize: 'cover',
  backgroundRepeatX: 'no-repeat',
  flex: 1,
  backgroundColor: 'white',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const ButtonContainer = styled(SbButton)({
  margin: '6px 0px',
  height: '48px',
  fontSize: '16px',
  letterSpacing: '0.46px',
  textTransform: 'capitalize',
});

const PContainer = styled('p')({
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: '600',
  marginTop: '25px',
});

// const OrgDetails = {
//   name: 'Allied Express',
//   logo: '/images/login/logo.png',
// };

interface SignupState {
  name?: string;
  username?: string;
  country?: string;
  password?: string;
  repassword?: string;
  otp?: string | null;
}

let initialState = {
  name: '',
  username: '',
  country: '',
  password: '',
  repassword: '',
  otp: null,
};

const Signup: React.FC = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  // const formState = useSelector((state: RootStateType) => state?.loginform?.value);
  const signupState = useSelector((state: RootStateType) => state?.signupForm);
  const orgPublicData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);

  const { name = '', logo = '/images/login/logo.png' } = orgPublicData || {};
  const theme = useTheme();

  const loading = signupState.loading;

  const [state, setState] = React.useState<SignupState>(initialState);
  const handleSubmit = (data: SignupState) => {
    setState({ ...data });
  };
  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  return (
    <>
      <SuccessError />
      <Loader loading={loading} overly>
        <Grid container style={{ backgroundColor: 'transparent' }}>
          <BannerContainer item>
            <div
              style={{
                height: 'calc(100vh - 160px)',
              }}
            >
              <div
                className="header"
                style={{
                  display: 'flex',
                  marginTop: '61px',
                  marginRight: '96px',
                  marginLeft: '65px',
                }}
              >
                <SbImage src={logo} alt="logo" style={{ width: '208px', maxHeight: '121px', marginRight: '30px' }} />
                <WelcomeOrgh1>Welcome to {name}</WelcomeOrgh1>
              </div>
              <div className="headerbody">
                <Grid container>
                  <Grid item md={6}>
                    <img
                      src="/images/login/logistics-2.gif"
                      alt="logistics"
                      style={{ height: '596px', width: '596px' }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <Footer />
          </BannerContainer>
          <SignupGrid item>
            <div style={{ padding: '10px' }}>
              {/* <div style={{ maxWidth: '80%', margin: '0px auto', marginTop: '30px' }}>
              <SbImage src={OrgDetails.logo} alt="logo" style={{ display: 'block', margin: '0 auto' }} />
            </div> */}
              <Logo />
              <br />
              <Grid container justifyContent="center">
                {/* <Grid item xs={1}></Grid> */}
                <Grid item xs={10}>
                  <Box
                    className="rounded"
                    style={{ border: '1px solid rgba(0, 0, 0, 0.1)', padding: '40px 40px 32px' }}
                  >
                    <Grid container justifyContent="center" mb={4} flexDirection="column" alignItems="center">
                      <h1>Sign up</h1>
                      <p>Let's get you started</p>
                    </Grid>

                    <SignupComponent data={state} handleSubmit={handleSubmit} />

                    <br />
                    {/* <hr /> */}
                    <div style={{ opacity: '0.1', border: '1px solid #000000' }}></div>
                    <PContainer>
                      Already have an account?{' '}
                      <Link to="/login" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
                        <span>Login now</span>
                      </Link>
                    </PContainer>
                  </Box>
                  <PoweredBy />
                </Grid>
              </Grid>
            </div>
          </SignupGrid>
          {false && (
            <Modal
              open={true}
              dialogContainerSx={{
                padding: 6,
              }}
              // maxWidth={'lg'}
              // onClose={toggleModalView}
            >
              <Grid container justifyContent={'space-between'}>
                {/* <Grid item className="mb-2">
            Verify OTP
          </Grid> */}
                <Grid item className="mb-2">
                  <SbTextField
                    name="username"
                    label="Email/Phone"
                    style={{ marginBottom: '10px' }}
                    value={state.username}
                    // onChange={handleChange}
                    // onBlur={handleBlur}

                    endAdornment={'Resend'}
                    startAdornment={<MailIcon />}
                  />
                  <SbTextField
                    name="otp"
                    label="OTP"
                    placeholder="OTP"
                    style={{ marginBottom: '10px' }}
                    // value={values.name}
                    // onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  <p>A 4-digit code has been sent to your phone/email.</p>
                  <br />
                  <ButtonContainer fullWidth variant="contained">
                    Verify
                  </ButtonContainer>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={5}></Grid>
              </Grid>
            </Modal>
          )}
        </Grid>
      </Loader>
    </>
  );
};

interface SignupProps {
  data: signupState;
  handleSubmit: (data: SignupState) => void;
}

const ATag = styled('a')({
  textDecoration: 'none',
  color: 'inherit',
});

const SignupComponent: React.FC<SignupProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const token = new URLSearchParams(document.location.search).get('token');
  let decoded = { username: '' };
  if (token) {
    decoded = jwt_decode<{ username: string }>(token);
    console.log({ decoded });
  }

  const globalState = useSelector((state: RootStateType) => state?.global);
  const signupState = useSelector((state: RootStateType) => state?.signupForm);
  const countryId = useSelector(
    (state: RootStateType) => state?.orgSetup?.organizationPublicData?.contactInfo?.telephoneCode,
  );
  const countryCodeId = useSelector((state: RootStateType) => state?.orgSetup?.organizationPublicData?._countryId);
  const { countryList, countries } = globalState;

  const findCountryNameByTelephoneCode = (countryObj: any, countryId: any): any => {
    // Convert the countryId to a number, removing the "+" sign
    const code = parseInt(countryId.replace('+', ''), 10);

    const country = countryObj.find((country: any) => country.telephoneCode === code);

    return country?.name;
  };

  const countryName = findCountryNameByTelephoneCode(countries, countryId);

  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState<boolean>(false);
  initialState = decoded.username ? { ...initialState, username: decoded.username } : initialState;
  initialState = countryName ? { ...initialState, country: countryName } : initialState;

  const [state, setState] = React.useState<SignupState>(initialState);
  const [selectedValue, setSelectedValue] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(resetSignupState());
    };
  }, []);

  useEffect(() => {
    if (countryName) {
      // console.info('countryName:', countryName, initialState);
      initialState = countryName ? { ...initialState, country: countryName } : initialState;
      // Formik.setFieldValue('country', countryName);
    }
  }, [countryName]);

  // console.info('initialState:', initialState);

  useEffect(() => {
    const { user, error, isOtp } = signupState;
    if (error?.isError) {
      setErrorModal(true);
    } else if (user && isOtp) {
      setViewModal(true);
    }
  }, [signupState.user]);

  useEffect(() => {
    if (signupState.verifyEmail && signupState.user) {
      setViewModal(false);
      setSignupSuccess(true);
    }
  }, [signupState.verifyEmail]);

  const redirectToLogin = () => {
    setSignupSuccess(false);
    dispatch(resetSignupState);
    navigate('/login');
  };

  const togglePasswordView = () => {
    setViewPassword(!viewPassword);
  };
  // const toggleModalView = () => {
  //   setViewModal(!viewModal);
  // };
  const handleOtpInput = (otp: string) => {
    setState({ ...state, otp });
  };
  const handleSubmit = (values: signupState) => {
    // console.log({ values });
    setState({ ...values });

    // const country = countries.find(c => {
    //   if (c.name === values.country) return c;
    // });

    const payload = {
      name: values.name,
      username: values.username,
      email: values.username,
      password: values.password,
      countryId: countryCodeId,
      termsAndCondition: true,
    };

    dispatch(doSignup(payload));
  };

  const handleResendVerification = () => {
    const payload = { username: state.username || '' };
    dispatch(resendVerification(payload));
  };

  const verifyOtp = () => {
    dispatch(verifySignupEmail({ otp: state.otp || '', userId: signupState.user?._userId || '' }));
  };

  const handleCheckClick = () => {
    setSelectedValue(!selectedValue);
    // console.info('checked', !selectedValue);
  };

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          username: decoded.username || '',
          country: state.country ? state.country : '',
          password: '',
          repassword: '',
        }}
        validationSchema={SignupSchema}
        // validate={values => {
        //   console.log('values ', values);
        //   const errors = {};
        //   if (!values.username) {
        //     errors.username = 'Required';
        //   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username)) {
        //     errors.username = 'Invalid email address';
        //   }
        //   console.log(errors);
        //   return errors;
        // }}
        onSubmit={values => {
          // console.log('in submit ', values);

          handleSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          // isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <SbTextField
              name="name"
              label="Name"
              placeholder="Enter name"
              style={{ marginBottom: '10px' }}
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <p style={{ color: 'red', marginBottom: '5px' }}>{errors.name && touched.name && errors.name}</p>
            <SbTextField
              name="username"
              label="Email ID"
              placeholder="Email ID"
              style={{ marginBottom: '10px' }}
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!!decoded?.username}
            />
            <p style={{ color: 'red', marginBottom: '5px' }}>
              {errors.username && touched.username && errors.username}
            </p>
            <SbDropdown
              id="country"
              name="country"
              label="Select Country"
              placeholder="country"
              disabled
              options={countryList}
              // value={values.country ? [values.country] : []}
              value={initialState.country ? [initialState.country] : []}
              onChange={handleChange}
            />
            <p style={{ color: 'red', marginBottom: '5px' }}>{errors.country && touched.country && errors.country}</p>
            <SbTextField
              name="password"
              label="Enter Password"
              placeholder="Password"
              type={'password'}
              style={{ marginBottom: '15px' }}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              // endAdornment={
              //   <VisibilityIcon
              //     onClick={togglePasswordView}
              //     style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
              //   />
              // }
            />
            <p style={{ color: 'red', marginBottom: '5px' }}>
              {errors.password && touched.password && errors.password}
            </p>
            <SbTextField
              name="repassword"
              label="Re - Enter Password"
              placeholder="Password"
              type={viewPassword ? 'text' : 'password'}
              style={{ marginBottom: '15px' }}
              value={values.repassword}
              onChange={handleChange}
              onBlur={handleBlur}
              onPaste={e => e.preventDefault()}
              endAdornment={
                <VisibilityIcon
                  onClick={togglePasswordView}
                  style={{ fontSize: '17px', marginRight: '5px', cursor: 'pointer' }}
                />
              }
            />
            <p style={{ color: 'red', marginBottom: '5px' }}>
              {errors.repassword && touched.repassword && errors.repassword}
            </p>

            <br />
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <Checkbox
                style={{ width: '16px', height: '16px', borderRadius: '4px', border: '1px', color: 'inherit' }}
                checked={selectedValue}
                onClick={handleCheckClick}
              />
              <Typography style={{ display: 'flex', gap: '2px', fontWeight: 400, marginLeft: '4px' }}>
                I agree to the
                <span style={{ fontWeight: 600, color: '#484649' }}>
                  <ATag target="_blank" href="/terms-and-conditions-agreement">
                    Terms and Conditions
                  </ATag>
                </span>
              </Typography>
            </div>

            <ButtonContainer
              type="submit"
              fullWidth
              variant="contained"
              disabled={
                Object.keys(errors).length > 0 || Object.values(values).map(i => i).length < 1 || !selectedValue
              }
            >
              Signup
            </ButtonContainer>
            <br />
          </form>
        )}
      </Formik>
      <Modal
        open={viewModal}
        dialogContainerSx={{
          padding: 6,
        }}
        maxWidth={'xl'}
        // onClose={toggleModalView}
      >
        <Grid container>
          <Grid item>
            <p>Verify OTP</p>
            <br />
          </Grid>
        </Grid>
        <Grid container justifyContent={'space-between'}>
          <Grid item className="mb-2">
            <SbTextField
              name="username"
              label="Email/Phone no."
              style={{ marginBottom: '10px', fontSize: '0.5rem' }}
              value={state.username}
              // onChange={handleChange}
              // onBlur={handleBlur}

              endAdornment={
                <span style={{ cursor: 'pointer', color: 'rgba(220, 54, 46, 1)' }} onClick={handleResendVerification}>
                  Resend OTP
                </span>
              }
              startAdornment={<MailIcon />}
            />
            <SbTextField
              name="otp"
              label="OTP"
              placeholder="Enter OTP"
              style={{ marginBottom: '10px' }}
              value={state.otp || ''}
              onChange={e => {
                const numericValue = e.target.value.replace(/\D/g, '');
                handleOtpInput(numericValue);
              }}
              // onBlur={handleBlur}
            />
            <p>A 6-digit code has been sent to your email/phone no.</p>
            <br />
            <ButtonContainer fullWidth variant="contained" onClick={verifyOtp}>
              Verify
            </ButtonContainer>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={5}></Grid>
        </Grid>
      </Modal>
      <ErrorModal
        open={errorModal}
        icon={'/images/Boxes/WarningContrast.svg'}
        btnText="Close"
        subTitle={signupState?.error?.msg}
        onClose={() => {
          setErrorModal(false);
        }}
        onConfirm={() => {
          setErrorModal(false);
        }}
      />
      <ErrorModal
        icon={'/images/icons/SuccessIcon.svg'}
        open={signupSuccess}
        btnText="Close"
        subTitle={'Account Verified'}
        onClose={() => {
          redirectToLogin();
        }}
        onConfirm={() => {
          redirectToLogin();
        }}
      />
    </>
  );
};

export default Signup;
