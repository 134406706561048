import { alpha, Paper, styled } from '@mui/material';

export const TableActionContainer = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'auto',
  margin: '32px 0',
  padding: '4px 0',
  display: 'grid',
  gridTemplateColumns: '510px 1fr 184px 200px 136px 113px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    columnGap: '10px',
    gridTemplateColumns: '490px 1fr 184px 200px 136px 113px',
  },
}));

export const SystemBox = styled(Paper)(({ theme }) => ({
  width: '126px',
  margin: 'auto',
  maxHeight: '100px',
  overflow: 'hidden',
  //   border: `0.5px solid ${alpha(theme.palette.border.grey, 0.5)}`,
  boxShadow: `0px 12px 16px -4px ${alpha(theme.palette.border.grey, 0.8)}`,
  '&:hover': {
    overflow: 'auto',
  },
}));
