import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Modal from '@Components/Modal';
import { UserObj } from '@Reducers/User/Type';
import DocumentUploadSuccessfully from '@Components/DocumentUpload/DocumentUploadSuccessfully';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { styled } from '@mui/system';
import SbButton from '@Components/Button';
import UploadContract from './contract/UploadContract';
import { ContractObject } from '@Reducers/Contract/Type';
import { getContractList, resetContractReducer } from '@Reducers/Contract';
import SingleDocument from './contract/SingleDocument';
import DocumentDelete from './contract/DocumentDelete';
import { AddNewContractButton } from './styles';
import AddIcon from '@mui/icons-material/Add';

interface PropsType {
  open: boolean;
  data?: UserObj;
  handleClose?: () => void;
}

const SingleDocumentWrapper = styled(Grid)(() => ({
  border: '1px solid #b2b2b2',
  borderRadius: '8px',
}));

function ContractDetailsModal({ open, handleClose, data }: PropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const { data: userContractList, isAdded } = useSelector((state: RootStateType) => state.contract);
  const [uploadDocumentModal, setUploadDocumentModal] = useState<boolean>(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState<boolean>(false);
  const [successfullyModal, setSuccessfullyModal] = useState<boolean>(false);
  const [singleDoc, setSingleDoc] = useState<ContractObject | null>(null);
  const userAccountId = data?._accountId?._id || '';
  const accountNumber = data?._accountId?.accountNumber || '';

  useEffect(() => {
    dispatch(getContractList(userAccountId));
  }, []);

  useEffect(() => {
    if (isAdded) {
      setUploadDocumentModal(false);
      setSuccessfullyModal(true);
    }
  }, [isAdded]);

  const uploadDocumentModalClose = () => setUploadDocumentModal(false);
  const uploadDocumentModalOpen = () => setUploadDocumentModal(true);

  const successModalClose = () => {
    dispatch(resetContractReducer());
    uploadDocumentModalClose();
    setSuccessfullyModal(false);
    dispatch(getContractList(userAccountId));
  };

  const onDocumentUploadModalOpen = useCallback((_documentLabel: string, documentData: ContractObject | null): void => {
    setSingleDoc(documentData);
    uploadDocumentModalOpen();
  }, []);

  const deleteDocumentModalClose = (refetch: boolean) => {
    setSingleDoc(null);
    setDeleteDocumentModal(false);
    if (refetch) dispatch(getContractList(userAccountId));
  };

  const onDocumentDeleteModalOpen = useCallback((documentData: ContractObject | null): void => {
    setSingleDoc(documentData);
    setDeleteDocumentModal(true);
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        dialogContainerSx={{ maxWidth: 'unset', minWidth: { xs: 'unset', lg: '600px' }, maxHeight: '80vh' }}
      >
        <Grid container sx={{ mb: 4, flexDirection: 'column', alignItems: 'center' }}>
          <Typography className="md-text font-semibold ">Contract Details</Typography>
          <Typography className="md-text font-semibold ">
            User Account No : {data?._accountId?.accountNumber}
          </Typography>
        </Grid>
        <UploadContract
          open={uploadDocumentModal}
          onClose={uploadDocumentModalClose}
          documentData={singleDoc}
          accountId={userAccountId}
          accountNumber={accountNumber}
        />
        <DocumentUploadSuccessfully open={successfullyModal} onClose={successModalClose} />
        <DocumentDelete
          accountId={singleDoc?._accountId || ''}
          contractId={singleDoc?._id || ''}
          open={deleteDocumentModal}
          onClose={deleteDocumentModalClose}
        />
        <SingleDocumentWrapper container item>
          {userContractList.map((contract, index) => (
            <SingleDocument
              key={`${contract.name}-${index}`}
              label={contract.name}
              required={false}
              documentData={contract || null}
              onDocumentDeleteModalOpen={onDocumentDeleteModalOpen}
            />
          ))}
        </SingleDocumentWrapper>
        <AddNewContractButton onClick={() => onDocumentUploadModalOpen('', null)} variant="outlined" className="w-full">
          <AddIcon />
          <Typography>Add New Contract</Typography>
        </AddNewContractButton>
        <SbButton
          onClick={handleClose}
          variant="contained"
          sx={{
            borderRadius: '8px !important',
            mt: 5,
            mb: 1,
          }}
          className="w-full"
        >
          Close
        </SbButton>
      </Modal>
    </>
  );
}

export default ContractDetailsModal;
