import React, { useMemo } from 'react';
import Dropdown from '../Dropdown';
import { DropdownOptionType } from '../Dropdown/Type';
import { SelectChangeEvent } from '@mui/material';
import { styled } from '@mui/system';

interface FilterSelectProps {
  id: string;
  label: string;
  onChange: (
    selected: Array<string>,
    selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string | string[]>,
    child: React.ReactNode,
  ) => void;
  options: Array<DropdownOptionType>;
  value: string[];
  showSelectAll?: boolean;
  isSorted?: boolean;
}

const SelectedCountContainer = styled('span')`
  background-color: var(--mui-palette-secondary-main);
  // border-radius: 50%;
  padding: 0 9px;
  // column-gap: 15px;
  color: var(--mui-palette-background-white);
`;

function FilterSelect(props: FilterSelectProps) {
  const { id, label, onChange, options, value, showSelectAll, isSorted } = props;
  const sortedOptions = useMemo(() => {
    return options.slice().sort((a, b) => a.display.localeCompare(b.display));
  }, [options]);

  return (
    <Dropdown
      id={id}
      onChange={onChange}
      options={sortedOptions}
      value={value}
      multiple
      showSelectAll={showSelectAll}
      isSorted={isSorted}
      renderValue={params => {
        return (
          <>
            {label}{' '}
            {params.length ? (
              <SelectedCountContainer className="rounded-full">{params.length}</SelectedCountContainer>
            ) : (
              ''
            )}
          </>
        );
      }}
      placeholder={label}
    />
  );
}

export default FilterSelect;
