import { Box, Grid, IconButton, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Image from '../Image';
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyleIconButton = styled(IconButton)(({ theme: _ }) => ({
  border: '1px solid #b2b2b2',
  padding: 6,
  borderRadius: 8,
  color: '#000',
  fontSize: '24px',
}));

function FilePreview(props: FileUploadNameProps) {
  return (
    <>
      <ContainerFileName container item xs={12} p={1} className="mt-1" justifyContent="space-between">
        <Box className="w-full items-center flex justify-between">
          <Grid container item xs={11} gap={2}>
            <Grid item xs={'auto'}>
              <Image src={'/images/file_document.svg'} alt={'Speedbox'} width={40} height={40} />
            </Grid>
            <Grid item xs={6} md={9}>
              <Typography variant="h6" className="font-medium truncate">
                {props.filename}
              </Typography>
              <Typography variant="h6" className="sb-text-gray font-light">
                {props.fileSize}
              </Typography>
            </Grid>
          </Grid>

          <StyleIconButton onClick={props.onClick} className="mr-1">
            <VisibilityIcon className="inherit" />
          </StyleIconButton>
          <StyleIconButton onClick={props.onFileRemove}>
            <CancelIcon className="sb-text-gray" />
          </StyleIconButton>
        </Box>
      </ContainerFileName>
    </>
  );
}
const ContainerFileName = styled(Grid)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.25)}`,
  padding: 14,
  borderRadius: 8,
}));

interface FileUploadNameProps {
  filename?: string;
  fileSize?: string;
  onFileRemove: () => void;
  onClick?: () => void;
}
export default FilePreview;
