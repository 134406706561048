import { Box, Grid } from '@mui/material';
import React, { useState, ReactNode } from 'react';
import DragDrop from '../../components/File/DragDrop';
import SbButton from '../../components/Button';
import Image from '../../components/Image';
import SbTooltip from '../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { FileResultType } from '../../Helper/file';
import ErrorModal from '../../components/Modal/error';
import { COLUMNS_HEADER } from './constant';

const _requireColumnHeader = COLUMNS_HEADER.filter(x => x.require);

const initErrorModal = {
  open: false,
  title: '',
  subTitle: '',
};

function SheetUpload() {
  const [xlsFileData, setXlsFileData] = useState<[] | null>(null);
  const [fileName, setFileName] = useState<string>('');

  const [errorModal, setErrorModal] = useState<{ open: boolean; title: string; subTitle: string | ReactNode }>({
    ...initErrorModal,
  });
  const navigate = useNavigate();

  const onCloseModal = () => {
    setErrorModal({ ...initErrorModal });
  };

  const onNext = () => {
    if (xlsFileData) navigate('/shipment/create-bulk/sheet-match-header', { state: xlsFileData });
  };

  const xlsFileRead = (data: FileResultType[]) => {
    const [{ fileData, fileName }] = data;
    if (!(fileData instanceof Array)) return;
    setFileName(fileName);
    const shipmentSheet = fileData?.find(sheet => sheet.sheetName.toLowerCase() === 'shipment');
    if (!shipmentSheet) {
      setErrorModal({ open: true, title: 'Oops!', subTitle: 'shipment sheet not found!' });
      return;
    }

    const [header] = shipmentSheet.data;

    const invalidCols = _requireColumnHeader.filter(currCol => {
      const isValid = header.find((x: string) => currCol.regEx.test(x));
      return !isValid;
    });
    if (invalidCols.length) {
      const missingHeaderCols = invalidCols.map(x => x.label).join(', ');
      setErrorModal({
        open: true,
        title: 'Oops!',
        subTitle: (
          <>
            Column header <span className="font-medium">"{missingHeaderCols}"</span> is missing from your sheet.
          </>
        ),
      });
      return;
    }
    setXlsFileData(shipmentSheet.data);
    // All the required fields are not there in the given sheet kindly update your sheet and try again.
    // const
  };

  const onDownloadSampleSheet = () => {
    window.open('https://s3.ap-south-1.amazonaws.com/speedbox-static-file-2.0/Bulk%2BShipment+(3).xlsx', '_blank');
  };

  return (
    <Grid container>
      <ErrorModal
        icon={'/images/Boxes/NoRatesFound.svg'}
        open={errorModal.open}
        title={errorModal.title}
        subTitle={errorModal.subTitle}
        onClose={onCloseModal}
        onConfirm={onCloseModal}
        btnText="Go back"
      />
      <Grid container item height={300}>
        <DragDrop
          readType="xls"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          subText={fileName ? fileName : 'xlsx or xls file size no more than 10MB'}
          onChange={xlsFileRead}
          // onRemove={}
        />
      </Grid>
      <Grid container item mt={4} alignItems="center" spacing={4}>
        <Grid item xs={12} md={'auto'}>
          <SbButton
            fullWidth
            variant="outlined"
            onClick={onDownloadSampleSheet}
            startIcon={
              <Image
                src={'/images/upload_document.svg'}
                alt={'Speedbox'}
                width={35}
                height={35}
                style={{ transform: 'rotate(180deg)' }}
              />
            }
          >
            Download sample file
          </SbButton>
        </Grid>
        <Grid container item xs={12} md={'auto'} alignItems="center">
          <Box component="span" className="mr-1" style={{ textDecoration: 'underline' }}>
            View guidelines
          </Box>
          <SbTooltip
            placement="top-end"
            title={
              <>
                1. Acceptable file formats: xls, xlsx <br />
                2. Star orders from the 2nd row <br />
                3. Use the first row for column headings and names <br />
                4. You can use upto 100 rows to give order related info <br />
              </>
            }
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>
      </Grid>
      <Grid container item mt={4} alignItems="flex-end" spacing={4} justifyContent="end">
        {/* <Grid item xs={6} sm={2} md={1} lg={1} xl={2}>
          <SbButton variant="outlined" fullWidth className="rounded-full">
            Back
          </SbButton>
        </Grid> */}
        <Grid item xs={12} sm={3} md={2}>
          <SbButton variant="contained" fullWidth className="rounded-full" onClick={onNext} disabled={!xlsFileData}>
            Next
          </SbButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SheetUpload;
