import { styled } from '@mui/material';
import React from 'react';

const ContainerStyled = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
  background: theme.palette.background.white,
  //   borderTopLeftRadius: theme.spacing(1.5),
  //   borderTopRightRadius: theme.spacing(1.5),
  width: 300,
  borderBottom: '1px solid #E0E0E0',
  borderTop: '1px solid #E0E0E0',
}));

function RowCell({ label }: { label: string }) {
  return <ContainerStyled className="text-center truncate">{label}</ContainerStyled>;
}
export default RowCell;
