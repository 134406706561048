import React from 'react';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Image from '@Components/Image';
import { kycModalType } from './Types';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SquadKycModal = (props: kycModalType) => {
  const { open, onClose, document, type } = props;
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="document-modal-title">
      <Box sx={{ ...modalStyle }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography id="document-modal-title" variant="h6" component="h2">
            {type}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Box
            className="rounded-xs"
            sx={{
              height: '16rem',
              bgcolor: 'background.grey',
              overflow: 'auto',
              iframe: {
                width: '50%',
                height: '100%',
              },
            }}
          >
            <Image src={document} alt="Kyc Document" style={{ width: '100%' }} />
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default SquadKycModal;
