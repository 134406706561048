import { Grid } from '@mui/material';
import Joi from 'joi';
import React, { useEffect, useMemo, useState } from 'react';
import SbTextField from '../../../../components/Textfield';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { BoxErrorType, BoxType, createShipmentContextType } from '../../../../types/shipment';
import { documentWeight } from '../../../GetRatesV2/RatesInput/Schema';
import { defaultBoxesErrorState, defaultProductErrorState } from '../../constants';

const BoxDimensions = ({ writePermission }: { writePermission: boolean }) => {
  const { selectedBox, setSelectedBox, boxes, setBoxes, shipmentType, setBoxValidation } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;

  const [errors, setErrors] = useState<BoxErrorType>({ ...defaultBoxesErrorState });
  const [placeholders, setPlaceHolders] = useState<{ lengthUnit: string; weightUnit: string }>({
    lengthUnit: 'In cm',
    weightUnit: 'In Kg',
  });

  const { length, width, height, weight } = selectedBox || {};
  const { unit, type } = shipmentType;

  useEffect(() => {
    if (unit) getPlaceholder();
  }, [unit]);

  const weightErrForDocument = useMemo(
    () => type === 'document' && Number(weight) > documentWeight[unit],
    [type, weight, unit],
  );

  useEffect(() => {
    if (weightErrForDocument) {
      setBoxValidation(true);
    }
  }, [weightErrForDocument]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    validateProperty(event);
    const name = event.target.name as keyof BoxType;
    const value = event.target.value;
    const boxesTemp = [...boxes];
    const ind = boxesTemp.findIndex(box => box.id === selectedBox.id);
    const selectedBoxIndex = ind > -1 ? ind : boxes.length + 1;
    const boxDetails = { ...boxesTemp[selectedBoxIndex] };
    if (name === 'length' || name === 'width' || name === 'height' || name === 'weight') boxDetails[name] = value;
    setSelectedBox(boxDetails);
    boxesTemp.splice(selectedBoxIndex, 1, boxDetails);
    setBoxes([...boxesTemp]);
  }

  const schema = Joi.object().keys({
    id: Joi.string(),
    height: Joi.number().min(1).messages({ 'number.min': 'Value should be greater than 1' }),
    width: Joi.number().min(1).messages({ 'number.min': 'Value should be greater than 1' }),
    length: Joi.number().min(1).messages({ 'number.min': 'Value should be greater than 1' }),
    weight: Joi.number()
      .min(0.01)
      .required()
      .messages({
        'number.base': `Please enter Weight`,
        'number.min':
          type === 'document'
            ? `For shipment type document Weight should be less than ${documentWeight[unit]} ${unit}`
            : 'Weight should be greater than 0',
      }),
    actions: Joi.number().min(1).optional().messages({ 'number.min': 'Value should be greater than 1' }),
  });

  function validateProperty(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const obj = { [name]: value };
    const result = schema.validate(obj);
    const { error } = result;
    const errorData = { ...errors };
    const errorMessage = error && error.details[0].context?.key === name ? error.details[0].message : null;
    if (errorMessage && name in defaultProductErrorState) {
      errorData[name as keyof BoxErrorType] = errorMessage;
    } else {
      errorData[name as keyof BoxErrorType] = '';
    }
    setErrors(errorData);
  }

  function getPlaceholder() {
    if (unit === 'kg-cm') {
      setPlaceHolders({
        lengthUnit: 'cm',
        weightUnit: 'kg',
      });
    } else {
      setPlaceHolders({
        lengthUnit: 'in',
        weightUnit: 'lb',
      });
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={3} xs={6}>
        <SbTextField
          // placeholder={placeholders.lengthUnit}
          label="Length"
          onChange={handleChange}
          name="length"
          value={length}
          type="number"
          error={errors.length}
          endAdornment={placeholders.lengthUnit}
          disabled={type === 'document' || !writePermission}
          // disabled={!writePermission}
          // showInputProps={false}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <SbTextField
          // placeholder={placeholders.lengthUnit}
          label="Width"
          onChange={handleChange}
          name="width"
          value={width}
          type="number"
          error={errors.width}
          endAdornment={placeholders.lengthUnit}
          disabled={type === 'document' || !writePermission}
          // showInputProps={false}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <SbTextField
          // placeholder={placeholders.lengthUnit}
          label="Height"
          onChange={handleChange}
          name="height"
          value={height}
          type="number"
          error={errors.height}
          endAdornment={placeholders.lengthUnit}
          disabled={type === 'document' || !writePermission}
          // showInputProps={false}
        />
      </Grid>
      <Grid item md={3} xs={6}>
        <SbTextField
          // placeholder={placeholders.weightUnit}
          label="Weight"
          onChange={handleChange}
          name="weight"
          required
          value={weight}
          type="number"
          error={
            weightErrForDocument
              ? `For shipment type document Weight should be less than ${documentWeight[unit]} ${unit}`
              : errors.weight
          }
          endAdornment={placeholders.weightUnit}
          disabled={!writePermission}
        />
      </Grid>
    </Grid>
  );
};

export default BoxDimensions;
