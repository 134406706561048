import React, { useCallback, useEffect, useMemo, useState } from 'react';
import UploadDocument from './UploadDocument';
import DocumentUploadSuccessfully from './DocumentUploadSuccessfully';
import DocumentDelete from './DocumentDelete';
import { Grid } from '@mui/material';
import SingleDocument from './SingleDocument';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';

import { DocumentUploadPropsType } from './Type';
import { getKycSetting } from '@Reducers/Global';
import { KycType, StateType } from '@Reducers/kyc/Type';
import { docUploadSucModalClose, getLoggedInUserKyc } from '@Reducers/kyc';

function DocumentUpload(props: DocumentUploadPropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const kycSetting = useSelector((state: RootStateType) => state.global.kycSetting);
  const profile = useSelector((state: RootStateType) => state.loginUser.profile);
  const companyDetails = useSelector((state: RootStateType) => state.loginUser.companyDetails);
  const documentUploadSuccessfullyModal = useSelector(
    (state: RootStateType) => state.kyc.documentUploadSuccessfullyModal,
  );
  // const _kyc = useSelector((state: RootStateType) => state.kyc.kyc);

  const [uploadDocumentModal, setUploadDocumentModal] = useState<boolean>(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState<boolean>(false);
  const [successfullyModal, setSuccessfullyModal] = useState<boolean>(false);
  const [documentLabel, setDocumentLabel] = useState<string>('');
  const [documentEdit, setDocumentEdit] = useState<KycType | null>(null);
  const [deleteDocId, setDeleteDocId] = useState<string>('');

  const documents = useMemo(() => {
    if (props.systemId && companyDetails) {
      return companyDetails.documents?.reduce((op: StateType['kyc'], kyc: KycType) => ((op[kyc.name] = kyc), op), {});
    }
  }, [props.systemId, companyDetails]);

  useEffect(() => {
    dispatch(getKycSetting());
    // dispatch(getLoggedInUserKyc());
  }, []);
  const kycDocumentList = useMemo(() => {
    return kycSetting?.companyAccountHolder?.list ?? [];
  }, [kycSetting]);

  useEffect(() => {
    if (documentUploadSuccessfullyModal) setSuccessfullyModal(true);
  }, [documentUploadSuccessfullyModal]);

  const uploadDocumentModalClose = () => setUploadDocumentModal(false);
  const uploadDocumentModalOpen = () => setUploadDocumentModal(true);

  const successModalClose = () => {
    dispatch(docUploadSucModalClose());
    uploadDocumentModalClose();
    setSuccessfullyModal(false);
    dispatch(getLoggedInUserKyc());
  };

  const onDocumentUploadModalOpen = useCallback((documentLabel: string, documentData: KycType | null): void => {
    setDocumentLabel(documentLabel);
    setDocumentEdit(documentData);

    uploadDocumentModalOpen();
  }, []);

  const deleteDocumentModalClose = (refetch: boolean) => {
    setDeleteDocId('');
    setDeleteDocumentModal(false);
    if (refetch) dispatch(getLoggedInUserKyc());
  };

  const onDocumentDeleteModalOpen = useCallback((id: string): void => {
    setDeleteDocId(id);
    setDeleteDocumentModal(true);
  }, []);
  // NEED TO CHECKED TODO:NAVIN

  return (
    <>
      <UploadDocument
        open={uploadDocumentModal}
        onClose={uploadDocumentModalClose}
        documentLabel={documentLabel}
        documentData={documentEdit}
        userId={profile?.accountNumber || ''}
        onSubmit={props.onSubmit}
      />
      <DocumentUploadSuccessfully open={successfullyModal} onClose={successModalClose} />
      <DocumentDelete
        userId={profile?.accountNumber || ''}
        id={deleteDocId}
        open={deleteDocumentModal}
        onClose={deleteDocumentModalClose}
      />
      <Grid container item xs={12} md={12} lg={8}>
        {kycDocumentList.map((kyc, index) => (
          <SingleDocument
            key={`${kyc.label}-${index}`}
            label={kyc.label}
            required={kyc.isRequired}
            documentData={documents?.[kyc.label] || null}
            onDocumentUploadModalOpen={onDocumentUploadModalOpen}
            onDocumentDeleteModalOpen={onDocumentDeleteModalOpen}
          />
        ))}
      </Grid>
    </>
  );
}

export default DocumentUpload;
