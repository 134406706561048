import { D365State } from './Type';
function Pending(state: D365State) {
  state.loading = true;
}

function Fulfilled(state: D365State) {
  state.loading = false;
}

function Rejected(state: D365State) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

export { Pending, Fulfilled, Rejected };
