import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Grid, Paper, Typography } from '@mui/material';
import { TableBody, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AutorenewOutlined, NotInterested, RemoveCircle } from '@mui/icons-material';

import Title from '@Components/Title';
import FilterSearch from '@Components/Filter/Search';
import Loader from '@Components/Loader';
import FilterSelect from '@Components/Filter/Dropdown';
import SbButton from '@Components/Button';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { SbMenu, SbMenuItem } from '@Components/Menu';
import Image from '@Components/Image';

// ICONS

import { SystemBox, TableActionContainer } from './styles';

import { AppDispatch, RootStateType } from '@Store';
import { FilterObj } from './Types';
import { UserObj, UserQuery } from '@Reducers/User/Type';
import { getLoggedInUserSystemList } from '@Reducers/Systems';
import { ACCOUNT_TYPE, USER_STATUS, USER_STATUS_COLOR, USER_STATUS_LIST } from '../../constants/user';
import { CompanyDetailsModal } from './CompanyDetailsModal';
import KycModal from './Kyc';

import {
  deactivateUserAccount,
  getUserList,
  resendInvite,
  restoreUserAccount,
  suspendUserAccount,
} from '@Reducers/User/actions';
import { getFormattedContact } from '../../services/getFormattedContact';
import ContractDetailsModal from './ContractDetailsModal';
import SuccessModal from '../CreateAccount/SuccessModal';
import { resetAccountCreated } from '@Reducers/User';
import DeleteModal from '../../components/Modal/Delete';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import ExportBtn from '@Components/Button/Export';
import API from '@Services/apiAxios';
import { fetchUserReports } from '@Reducers/userReportsSlice/userReportsSlice';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';

const SearchFilterList = [
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Account no.', value: 'accountNumber' },
  { label: 'Company Name', value: 'companyName' },
];

const StatusList = Object.values(USER_STATUS_LIST)
  .filter(s =>
    [
      'on_boarded',
      'invitation_send',
      'suspended',
      'verification_pending',
      'archive',
      'kyc_pending',
      'kyc_verification_pending',
    ].includes(s.value),
  )
  .map(s => ({ display: s.label, value: s.value }));

const AccountTypeList = Object.values(ACCOUNT_TYPE).map(s => ({ display: s.label, value: s.value }));

function UserTable(props: { fetchList: () => void }) {
  const {
    data: { list },
    selectedUser,
  } = useSelector((state: RootStateType) => state.user);

  const [showCompanyDetails, setShowCompanyDetails] = useState<{ open: boolean; data?: UserObj }>({
    open: false,
    data: undefined,
  });
  const [showKycModal, setShowKycModal] = useState<{ open: boolean; data?: UserObj }>({
    open: false,
    data: undefined,
  });

  return (
    <>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell sx={{ minWidth: '311px' }}>Account Details</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '195px' }}>System</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '214px' }}>Account type</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '170px' }}>KYC</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '250px' }}>Status</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '224px' }}>User Details</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '57px' }}></TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(user => (
            <SBTableRow key={user._id} className={user._id && selectedUser[user._id] ? 'selected' : ''}>
              <TableRowCell className="md-text">
                <AccountDetails user={user} />
              </TableRowCell>
              <TableRowCell className="md-text">
                <Systems systemList={user._systems} />
              </TableRowCell>
              <TableRowCell align="center">
                {user?._accountId?.accountType !== 'individual' ? (
                  <AccountTypeCompany
                    user={user}
                    setShowCompanyDetails={() => setShowCompanyDetails({ open: true, data: user })}
                  />
                ) : (
                  <AccountTypeUser user={user} />
                )}
              </TableRowCell>
              <TableRowCell align="center">
                <KYCDetails data={user} setShowKycModal={() => setShowKycModal({ open: true, data: user })} />
              </TableRowCell>
              <TableRowCell align="center">
                {user.onBoardedStatus && <Status status={user.onBoardedStatus} />}
                {/* {user.onBoardedStatus && <Status status={getStatus(user) || USER_STATUS.kyc_pending} />} */}
              </TableRowCell>
              <TableRowCell>
                <AccUserDetails user={user} />
              </TableRowCell>
              <TableRowCell>
                <UserActions user={user} />
              </TableRowCell>
            </SBTableRow>
          ))}
        </TableBody>
      </SBTable>
      {showCompanyDetails.open && showCompanyDetails.data && (
        <CompanyDetailsModal
          {...showCompanyDetails}
          handleClose={() => setShowCompanyDetails({ open: false, data: undefined })}
        />
      )}
      {showKycModal.open && (
        <KycModal
          {...showKycModal}
          handleClose={() => setShowKycModal({ open: false, data: undefined })}
          fetchList={props.fetchList}
        />
      )}
    </>
  );
}

function UserActions(props: { user: UserObj }) {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = props;
  const navigate = useNavigate();
  const loggedInUserId = useSelector((state: RootStateType) => state.loginUser.profile?._id);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.edit_user,
    PERFORM_ACTION.write,
  );

  const isDeactivateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.deactivate_user,
    PERFORM_ACTION.write,
  );

  // const isDeactivateAllowed = true;

  const isSuspendAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.suspend_user,
    PERFORM_ACTION.write,
  );

  const isViewContractAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.view_contract,
    PERFORM_ACTION.write,
  );

  const isRestoreAccounttAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.restore_account,
    PERFORM_ACTION.write,
  );

  const [showContractModal, setShowContractModal] = useState<{ open: boolean; data?: UserObj }>({
    open: false,
    data: user,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState<{
    open: boolean;
    handleConfirm?: () => void;
    msg?: string;
  }>({
    open: false,
  });

  const handleEditAction = () => {
    navigate(`/user/edit/${user._id}`);
  };
  const handleDeactivateAction = () => {
    setShowConfirmationModal({
      open: true,
      msg: 'Do you want to Deactivate the account?',
      handleConfirm: () => {
        if (user._id) dispatch(deactivateUserAccount({ id: user._id, showModal: true }));
      },
    });
  };
  const handleSuspendAction = () => {
    setShowConfirmationModal({
      open: true,
      msg: 'Do you want to Suspend the account?',
      handleConfirm: () => {
        if (user._id) dispatch(suspendUserAccount({ id: user._id, showModal: true }));
      },
    });
  };
  const handleResendInviteAction = () => {
    setShowConfirmationModal({
      open: true,
      msg: 'Do you want to Resend Invitation Link to the account?',
      handleConfirm: () => {
        if (user._id) dispatch(resendInvite({ id: user._id }));
      },
    });
  };
  const handleViewContractAction = () => {
    setShowContractModal({ open: true, data: user });
  };
  const handleRestoreAccountAction = () => {
    setShowConfirmationModal({
      open: true,
      msg: 'Do you want to Restore the account?',
      handleConfirm: () => {
        if (user._id) {
          dispatch(restoreUserAccount({ id: user._id, showModal: true }));
        }
      },
    });
  };

  const handleConfirm = () => {
    showConfirmationModal?.handleConfirm?.();
    setShowConfirmationModal({ open: false });
  };

  return (
    <>
      {showContractModal.open && (
        <ContractDetailsModal
          {...showContractModal}
          handleClose={() => setShowContractModal({ open: false, data: undefined })}
        />
      )}
      <DeleteModal
        icon="/images/Boxes/WarningContrast.svg"
        open={!!showConfirmationModal.open}
        title="Are You Sure?"
        subTitle={showConfirmationModal.msg}
        onClose={() => setShowConfirmationModal({ open: false })}
        onConfirm={handleConfirm}
      />
      <SbMenu disabled={loggedInUserId === user._id}>
        {isEditAllowed && (
          <SbMenuItem
            icon={<Image src="/images/icons/EditAction.svg" alt="Edit" style={{ marginLeft: 2 }} />}
            name="Edit"
            onClick={handleEditAction}
          ></SbMenuItem>
        )}

        {user.onBoardedStatus === USER_STATUS.invitation_send ? (
          <SbMenuItem
            name="Resend Invite"
            icon={<Image src="/images/icons/user/ResendInvoice.svg" alt="resend" style={{ marginLeft: 3 }} />}
            onClick={handleResendInviteAction}
          ></SbMenuItem>
        ) : (
          <>
            {isViewContractAllowed && (
              <SbMenuItem
                name="View Contract"
                // icon={<FindInPage className="sb-text-black" />}
                icon={
                  <Image
                    src="/images/icons/user/View.svg"
                    alt="resend"
                    style={{ marginLeft: 0, width: '22px', height: '22px' }}
                  />
                }
                onClick={handleViewContractAction}
              ></SbMenuItem>
            )}
            {user.onBoardedStatus !== USER_STATUS.archive && isDeactivateAllowed && (
              <SbMenuItem
                name="Deactivate"
                icon={<RemoveCircle className="sb-text-black" />}
                onClick={handleDeactivateAction}
              ></SbMenuItem>
            )}
            {user.onBoardedStatus &&
              ![USER_STATUS.archive, USER_STATUS.suspended].includes(user.onBoardedStatus) &&
              isSuspendAllowed && (
                <SbMenuItem
                  name="Suspend"
                  icon={<NotInterested className="sb-text-black" />}
                  onClick={handleSuspendAction}
                ></SbMenuItem>
              )}
            {(user.onBoardedStatus === USER_STATUS.archive || user.onBoardedStatus === USER_STATUS.suspended) &&
              isRestoreAccounttAllowed && (
                <SbMenuItem
                  name="Restore Account "
                  icon={<AutorenewOutlined className="sb-text-black" />}
                  onClick={handleRestoreAccountAction}
                ></SbMenuItem>
              )}
          </>
        )}
      </SbMenu>
    </>
  );
}

function Systems(props: { systemList: string[] }) {
  const { systemList } = props;
  return systemList.length <= 1 ? (
    <Typography className="text-center">{systemList[0]}</Typography>
  ) : (
    <>
      <SystemBox id={'fadedScroll'} variant="outlined">
        {systemList.map(system => (
          <Typography key={system} width={'120px'} sx={{ py: 1 / 4, px: 1 }}>
            {system}
          </Typography>
        ))}
      </SystemBox>
    </>
  );
}

function AccountDetails(props: { user: UserObj }) {
  const {
    name,
    email,
    phoneNumber,
    telephoneCode,
    _accountId: { accountNumber, isAccountHolder } = {},
    isEmailVerified,
    isPhoneVerified,
    _id,
  } = props.user;
  const loggedInUserId = useSelector((state: RootStateType) => state.loginUser.profile?._id);

  return (
    <>
      <div>
        <div className="flex">
          <Typography className="md-text font-medium">{name}</Typography>
          {loggedInUserId === _id && (
            <Typography className="sb-text-gray" style={{ marginLeft: '2px' }}>
              (You)
            </Typography>
          )}
        </div>
        <Typography className="md-text font-medium">{isAccountHolder ? accountNumber : null}</Typography>
        {email && (
          <Typography className="sb-text-gray flex items-center">
            {email}
            {isEmailVerified ? (
              <CheckCircleIcon className="ml-1 md-text sb-text-success" />
            ) : (
              <ErrorOutlineIcon className="ml-1 md-text sb-text-error" />
            )}
          </Typography>
        )}
        {phoneNumber && (
          <Typography className="sb-text-gray flex items-center">
            {getFormattedContact(`${telephoneCode}${phoneNumber}`)}
            {isPhoneVerified ? (
              <CheckCircleIcon className="ml-1 md-text sb-text-success" />
            ) : (
              <ErrorOutlineIcon className="ml-1 md-text sb-text-error" />
            )}
          </Typography>
        )}
      </div>
    </>
  );
}

function AccountTypeCompany(props: { user: UserObj; setShowCompanyDetails: () => void }) {
  const { _accountId: { _company, isPrepaid, isAccountHolder } = {}, _systems, isHybridUser } = props.user;
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const defaultSystemId = loggedInUserSystemDropdownList.find(sys => sys.display === _systems[0])?.value;
  const defaultCompany = _company?.find(company => company._systemId === defaultSystemId);
  return (
    <>
      <div className="">
        <Typography>
          <Image src="/images/icons/user/Business.svg" alt="Business" />
        </Typography>
        <Typography className="mb-1 sb-text-gray underline cursor-pointer" onClick={props.setShowCompanyDetails}>
          {defaultCompany?.name}
        </Typography>
        <Typography className="sb-text-gray font-medium">
          {isAccountHolder && !isPrepaid ? 'Postpaid' : 'Prepaid'}
        </Typography>
        {isHybridUser && <Typography className="sb-text-gray">Hybrid</Typography>}
      </div>
    </>
  );
}

function AccountTypeUser(props: { user: UserObj }) {
  const { _accountId: { isPrepaid, isAccountHolder } = {} } = props.user;
  return (
    <>
      <div className="">
        <Typography>
          <Image src="/images/icons/user/User.svg" alt="User" />
        </Typography>
        <Typography className="sb-text-gray font-medium">
          {isAccountHolder && !isPrepaid ? 'Postpaid' : 'Prepaid'}
        </Typography>
        {/* {isWalkingUser && <Typography className="sb-text-gray">Walk-in</Typography>} */}
      </div>
    </>
  );
}

function KYCDetails(props: { data: UserObj; setShowKycModal: () => void }) {
  const kycPending = props.data.onBoardedStatus === USER_STATUS_LIST.kyc_pending.value;
  // const updatedStatus = getStatus(props?.data);

  // const kycPending = updatedStatus === USER_STATUS_LIST.kyc_pending.value;
  const kycVerificationPending = props.data.onBoardedStatus === USER_STATUS_LIST.kyc_verification_pending.value;
  const kycVerified = props.data.onBoardedStatus === USER_STATUS_LIST.on_boarded.value;
  // --------------------------> changes <----------------------------------------------------
  // const kycPending = updatedStatus === USER_STATUS_LIST.kyc_pending.value;
  // const kycVerificationPending = updatedStatus === USER_STATUS_LIST.kyc_verification_pending.value;
  // const kycVerified = updatedStatus === USER_STATUS_LIST.on_boarded.value;

  const canViewKYC = kycPending || kycVerified || kycVerificationPending || false;
  return (
    <Paper
      elevation={3}
      className={`text-center p-1 m-auto ${!canViewKYC ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      sx={{ width: '70px', height: '74px' }}
      onClick={!canViewKYC ? undefined : props.setShowKycModal}
    >
      <Image src={`/images/icons/user/${kycVerified ? 'UserKycDocBlue' : 'UserKycDoc'}.svg`} alt="UserKycDoc" />
      <Typography className="sb-text-gray xs-text flex items-center justify-center font-medium">
        KYC
        {kycVerified ? (
          <CheckCircleIcon className="ml-1 md-text sb-text-success" />
        ) : (
          <ErrorOutlineIcon
            className="ml-1 md-text sb-text-error"
            style={kycVerificationPending ? { color: '#FF9900' } : {}}
          />
        )}
      </Typography>
    </Paper>
  );
}

function Status({ status }: { status: USER_STATUS }) {
  const { label, bgColor, textColor } = USER_STATUS_COLOR[status] || {};
  return (
    <Typography sx={{ color: textColor, background: bgColor, px: 1, py: 0.5 }} className="w-fit rounded-xs m-auto">
      {label || status}
    </Typography>
  );
}

function AccUserDetails(props: { user: UserObj }) {
  const { name, _belongToAdminId, createdBy, createdAt, _accountId } = props.user;
  return (
    <>
      <div className="">
        <Typography className="sb-text-gray">Created by : {createdBy?.name || name || '-'}</Typography>
        {_belongToAdminId && <Typography className="sb-text-gray">Belongs to : {_belongToAdminId?.name}</Typography>}
        {createdAt && (
          <Typography className="sb-text-gray">Created on : {moment(createdAt).format('DD/MM/YYYY')}</Typography>
        )}
        <Typography className="sb-text-gray capitalize">
          {_accountId?.displayAccountTag
            ? _accountId?.displayAccountTag !== 'Organic'
              ? _accountId?.displayAccountTag
              : ''
            : _accountId?.accountTag !== 'Organic'
            ? _accountId?.accountTag
            : ''}
        </Typography>
      </div>
    </>
  );
}

export default function UserList() {
  const navigate = useNavigate();
  const [filterSelectValue, setFilterSelectValue] = useState('email');
  const [filterText, setFilterText] = useState('');
  const [status, setStatus] = useState<string[]>([]);
  const [kyc, setKyc] = useState<string[]>([]);
  const [systems, setSystems] = useState<string[]>([]);
  const [accountType, setAccountType] = useState<string[]>([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [prevSearchObj, setPrevSearchObj] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const { limit } = useSelector((state: RootStateType) => state.notifications);

  const dispatch = useDispatch<AppDispatch>();
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isCreateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.User_Details.Create_Account,
    PERFORM_ACTION.write,
  );

  const {
    data: { currentPage, perPage, totalCount },
    selectedUser,
    accountCreated,
    success,
    error,
  } = useSelector((state: RootStateType) => state.user);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const loading = useSelector(
    (state: RootStateType) =>
      state.System.loading ||
      state.user.loading ||
      state.loginUser.loading ||
      state.kyc.loading ||
      state.global.loading,
  );

  useEffect(() => {
    dispatch(getUserList({ currentPage: 1, perPage: 10 }));
    dispatch(getLoggedInUserSystemList());
  }, []);

  // const onKycFilter = (selected: Array<string>) => {
  //   setKyc(selected);
  //   dispatch(getUserList(getFilterObj({ kyc: selected })));
  // };

  const onSystemFilter = (selected: Array<string>) => {
    setSystems(selected);
    dispatch(getUserList(getFilterObj({ systems: selected })));
  };

  const onStatusFilter = (selected: Array<string>) => {
    setStatus(selected);
    const filterLists = selected
      .map(s => USER_STATUS_LIST[s as 'on_boarded' | 'invitation_send' | 'suspended' | 'archive'].filterValue)
      .flat(1);
    dispatch(getUserList(getFilterObj({ onBoardedStatus: filterLists })));
  };

  const onAccountTypeFilter = (selected: Array<string>) => {
    setAccountType(selected);
    dispatch(getUserList(getFilterObj({ accountType: selected })));
  };

  const getFilterObj = (options: FilterObj): UserQuery => {
    const filterStatus = options.onBoardedStatus || status;
    const filterKyc = options.kyc || kyc;
    const filterAccType = options.accountType || accountType;
    const filterSystems = options.systems || systems;
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;

    const filterQuery: UserQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      onBoardedStatus: filterStatus,
      systems: filterSystems,
      kyc: filterKyc,
      accountType: filterAccType,

      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    const compareObj = {
      onBoardedStatus: filterStatus,
      systems: filterSystems,
      kyc: filterKyc,
      accountType: filterAccType,
      searchKey: filterQuery['searchKey'],
      searchValue: filterQuery['searchValue'],
    };
    if (prevSearchObj !== '' && JSON.stringify(compareObj) !== prevSearchObj) {
      filterQuery.currentPage = 1;
    }
    setPrevSearchObj(JSON.stringify(compareObj));
    return filterQuery;
  };

  function closeSuccessModal() {
    dispatch(resetAccountCreated());
    setIsSuccessModalOpen(false);
  }

  useEffect(() => {
    if (accountCreated.isEmailSent) {
      setIsSuccessModalOpen(true);
    }
  }, [accountCreated]);

  useEffect(() => {
    if (success) {
      dispatch(getUserList(getFilterObj(error.isError ? {} : { currentPage: 1, perPage: 10 })));
    }
  }, [success]);

  const handleExport = async () => {
    try {
      setLoader(true);
      const response = await API.get('/user/list/download-user', {
        params: {
          currentPage: currentPage,
          perPage: perPage,
          systems,
          onBoardedStatus: status,
          accountType,
        },
      });

      if (response?.data) {
        dispatch(fetchUserReports());
        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Download in Progress',
            subTitle: response?.data?.msg,
            btnText: 'Close',
            onConfirm: () => {
              dispatch(toggleBoolean());
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
      }
      // console.info('response:', response?.data);
      // window.open(response.data.url, '_blank');
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        handleClose={closeSuccessModal}
        msg={'You’ve successfully resent an invite!'}
        userEmail={accountCreated.data?.email || ''}
      />
      <Loader loading={loading} overly>
        <>
          <Title title="User Details" subTitle="View and create user account" removeBorder />
          <Grid
            container
            justifyContent="end"
            className="rounded-xs"
            style={{ marginTop: '25px', marginBottom: '25px', gap: '10px' }}
          >
            <SbButton
              variant="contained"
              sx={{ borderRadius: '8px !important' }}
              onClick={() => {
                if (isCreateAllowed) {
                  navigate('/user/create-account');
                }
              }}
              disabled={!isCreateAllowed}
            >
              Create Account
            </SbButton>
            <ExportBtn
              iconSrc="/images/file_download.svg"
              width={25}
              alt="Export"
              label="Export"
              onClick={() => {
                handleExport();
              }}
            />
            <Loader loading={loader} overly />
          </Grid>
          {Object.keys(selectedUser).length === 0 && (
            <TableActionContainer className="scroll-h-1">
              <>
                <FilterSearch
                  onSelectChange={(value: string) => {
                    setFilterSelectValue(value);
                  }}
                  list={SearchFilterList}
                  selectValue={filterSelectValue}
                  textValue={filterText}
                  onTextChange={e => {
                    setFilterText(e.target.value);
                  }}
                  onTextSearch={() => {
                    dispatch(getUserList(getFilterObj({})));
                  }}
                  onTextClear={() => {
                    setFilterText('');
                    dispatch(getUserList(getFilterObj({ searchValue: undefined })));
                  }}
                />
                <div></div>
                {/* <DateRangePicker onChange={onDateChange} value={dateRange} /> */}
                {/* <FilterSelect id="kyc" label="KYC" options={KycStatusList} value={kyc} onChange={onKycFilter} /> */}
                <FilterSelect
                  id="system"
                  label="System"
                  options={loggedInUserSystemDropdownList}
                  value={systems}
                  onChange={onSystemFilter}
                />
                <FilterSelect
                  id="accountType"
                  label="Account Type"
                  options={AccountTypeList}
                  value={accountType}
                  onChange={onAccountTypeFilter}
                />
                <FilterSelect
                  id="status"
                  label="Status"
                  options={StatusList}
                  value={status}
                  onChange={onStatusFilter}
                />
                <SbButton
                  className="xs-text ml-1"
                  variant="outlined"
                  sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                  onClick={() => {
                    setStatus([]);
                    setKyc([]);
                    setSystems([]);
                    setAccountType([]);
                    setFilterText('');
                    dispatch(
                      getUserList(
                        getFilterObj({
                          onBoardedStatus: [],
                          accountType: [],
                          kyc: [],
                          systems: [],
                          searchValue: '',
                          currentPage: 1,
                        }),
                      ),
                    );
                  }}
                >
                  Clear Filters
                </SbButton>
              </>
            </TableActionContainer>
          )}

          <TableContainer id="fadedScroll">
            <UserTable
              fetchList={() => {
                dispatch(getUserList(getFilterObj({})));
              }}
            />
          </TableContainer>

          {!!totalCount && !!perPage && (
            <>
              <TablePagination
                component="div"
                count={totalCount}
                page={currentPage}
                rowsPerPage={perPage}
                onPageChange={(_, page) => {
                  dispatch(getUserList(getFilterObj({ currentPage: page + 1 })));
                }}
                onRowsPerPageChange={event => {
                  dispatch(getUserList(getFilterObj({ perPage: parseInt(event.target.value) })));
                }}
              />
            </>
          )}
        </>
      </Loader>
    </>
  );
}
