import React from 'react';
import { TableCell, TableCellProps } from '@mui/material';
import { styled } from '@mui/system';

const TableHeadCell = styled(
  TableCell,
  {},
)<{ is_light?: string }>(({ theme, is_light }) => ({
  height: '60px',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'ceter',
  backgroundColor: is_light ? 'var(--mui-palette-border-tableCell)' : theme.palette.primary.main,
  color: is_light ? theme.palette.text.light : theme.palette.background.white,
  fontSize: '16px',
  fontWeight: 600,
  border: `1px solid ${is_light ? theme.palette.background.white : theme.palette.border.tableCell}`,
  textAlign: 'center',
}));

const TableRCell = styled(TableCell)(({ theme }) => ({
  padding: '20px 0px',
  height: '1px',
  color: theme.palette.background.black,
  '& > .row-container': {
    padding: '16px',
    borderRight: '1px solid var(--mui-palette-border-tableCell)',
    height: '100%',
    display: 'grid',
    alignItems: 'center',
  },
  '&:last-of-type > .row-container': {
    borderRight: 'unset !important',
  },
}));

interface TableRowProps extends TableCellProps {
  children: React.ReactNode;
}

const TableRowCell = function (props: TableRowProps) {
  const { children, ...TableCellProps } = props;
  return (
    <TableRCell {...TableCellProps}>
      <div className="row-container">{children}</div>
    </TableRCell>
  );
};

const ShipmentActionsContainer = styled('div')(() => ({
  padding: '10px 14px',
}));

export { TableHeadCell, TableRowCell, ShipmentActionsContainer };
