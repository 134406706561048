import React, { useEffect, useState } from 'react';
import GlobalChargeForm, { initCreateGlobalCharge } from './Create/Form';
import { Grid } from '@mui/material';
import SbButton from '../../components/Button';
import { AppDispatch, RootStateType } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { createGlobalChargeSchema } from './schema';
import { getGlobalChargeById, resetSingleGlobalCharge, updateGlobalChargeById } from '@Reducers/GlobalCharge';
import { GlobalChargeFormType } from './Create/type';
import { GlobalChargePostData } from '@Reducers/GlobalCharge/Type';

function EditGlobalCharge() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [globalCharge, setGlobalCharge] = useState<GlobalChargeFormType>({ ...initCreateGlobalCharge });
  const { singeGlobalCharge } = useSelector((state: RootStateType) => state.globalCharge);
  const { id } = useParams() as { id: string };

  useEffect(() => {
    if (id) {
      dispatch(getGlobalChargeById(id));
    }
    return () => {
      dispatch(resetSingleGlobalCharge());
    };
  }, []);

  useEffect(() => {
    if (singeGlobalCharge) {
      const { fromDate, toDate, _courierId, _systemId, charges, taxes, selectedTax } = singeGlobalCharge;
      setGlobalCharge({
        fromDate,
        toDate,
        _courierId,
        _systemId,
        charges,
        taxes,
        selectedTax,
      });
    }
  }, [singeGlobalCharge]);

  const onEdit = (params: GlobalChargeFormType) => {
    const payload: GlobalChargePostData = {
      fromDate: params.fromDate,
      toDate: params.toDate,
      _systemId: params._systemId,
      _courierId: params._courierId,
      charges: params.charges.map(c => ({
        chargeName: c.chargeName,
        ...(c.chargeType === 'fixed' ? { fixed: c.value } : { percent: c.value }),
        applyOn: c.selectedApplyOn,
      })),
    };
    if (params.taxes) {
      payload.taxes = params.taxes instanceof Array ? params.taxes : [params.taxes];
    }
    dispatch(updateGlobalChargeById([id, payload]));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...globalCharge },
    validationSchema: createGlobalChargeSchema,
    onSubmit(values) {
      onEdit(values);
    },
  });

  return (
    <>
      <GlobalChargeForm
        showBackgroundColor
        formType="edit"
        errors={formik.errors}
        values={formik.values}
        handleChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
        validateForm={formik.validateForm}
      />
      <Grid container item mt={4} spacing={2} justifyContent="flex-end">
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          <SbButton fullWidth variant="outlined" onClick={() => navigate(-1)}>
            Back
          </SbButton>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          <SbButton
            fullWidth
            variant="contained"
            onClick={_event => {
              formik.handleSubmit();
            }}
          >
            Save
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

export default EditGlobalCharge;
