import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { styled } from '@mui/system';

import Title from '../../components/Title';
import { Grid, TableBody, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import FilterSearch from '@Components/Filter/Search';
import FilterSelect from '@Components/Filter/Dropdown';
import Loader from '@Components/Loader';
import DateRangePicker from '@Components/DateRangePicker';
import SbButton from '@Components/Button';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import Tooltip from '@Components/Tooltip';

import { AppDispatch, RootStateType } from '../../store';
import { deleteManifest, getManifest, setManifestSingleData } from '@Reducers/Manifest';
import { FilterObj } from './Types';
import { SingleManifest } from '@Reducers/Manifest/Type';

import { downloadBulkShippingLabel, downloadManifest } from '@Reducers/ShipmentHistory/actions';

// import { SystemState } from '@Reducers/Systems/Type';

import { ManifestQuery } from '@Reducers/Manifest/Type';
import { getActiveSystemList } from '@Reducers/Systems';
import { getServiceList } from '@Reducers/CarrierAndService';
import Image from '@Components/Image';
import ManifestEdit from './Edit';
import { RateRowService } from '../PricingList/RateRow';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { isActionAllowed } from '../../utils/allowedActions';
import DeleteModal from '../../components/Modal/error';

const SearchFilterList = [
  { label: 'Manifest No', value: 'manifestNumber' },
  { label: 'Shipment No', value: 'shipmentNumbers' },
];

const TableActionContainer = styled('div')<{ is_admin?: string }>(({ theme }) => ({
  padding: '32px 0px',
  display: 'grid',
  gridTemplateColumns: '510px 1fr fit-content(140px) fit-content(140px) 140px 140px 140px',

  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 0,
    gridTemplateColumns: '490px 1fr fit-content(140px) fit-content(140px) 140px 140px 140px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

const actionIconStyle = { width: '20px', height: '20px', cursor: 'pointer' };

function TableActions(props: { manifest: SingleManifest; onDelete: (_id: string) => void; refreshList: () => void }) {
  const { manifest, refreshList } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const onClose = () => {
    setDeleteModalOpen(false);
  };

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Manifest.edit_manifest,
    PERFORM_ACTION.write,
  );
  const isDeleteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Manifest.delete_manifest,
    PERFORM_ACTION.write,
  );
  const isDownloadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Manifest.download_manifest,
    PERFORM_ACTION.write,
  );
  const isDownloadShippingLabelAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Manifest.download_shipping_label,
    PERFORM_ACTION.write,
  );

  function onDelete() {
    props.onDelete(manifest._id);
  }

  return (
    <div className="p-2 flex flex-col items-center justify-center">
      <ManifestEdit refreshList={refreshList} id={manifest._id} />
      <Grid container item justifyContent="space-around" style={{ width: '175px' }}>
        {isDownloadShippingLabelAllowed && (
          <Tooltip title="Shipping Label">
            <div
              onClick={() =>
                dispatch(downloadBulkShippingLabel({ fileType: 'A4', shipmentNumber: manifest.shipmentNumbers }))
              }
            >
              <Image src="/images/icons/ShippingLabel.svg" alt="Shipping Label" style={actionIconStyle} />
            </div>
          </Tooltip>
        )}
        {isDownloadAllowed && (
          <Tooltip title="Download Manifest">
            <div
              onClick={() => {
                dispatch(downloadManifest({ manifestNumber: manifest.manifestNumber }));
              }}
            >
              <Image src="/images/icons/Download.svg" alt="Edit" style={actionIconStyle} />
            </div>
          </Tooltip>
        )}
        {isEditAllowed && (
          <Tooltip title="Edit Manifest">
            <div
              onClick={() => {
                dispatch(setManifestSingleData(manifest));
              }}
            >
              <Image src="/images/icons/EditAction.svg" alt="Edit" style={actionIconStyle} />
            </div>
          </Tooltip>
        )}
        {isDeleteAllowed && (
          <Tooltip title="Delete Manifest">
            <div
              onClick={() => {
                // props.onDelete(manifest._id);
                setDeleteModalOpen(true);
              }}
            >
              <Image src="/images/icons/DeleteAction.svg" alt="Edit" style={actionIconStyle} />
            </div>
          </Tooltip>
        )}
      </Grid>
      <DeleteModal
        icon="/images/Boxes/DeleteContrast.svg"
        open={deleteModalOpen}
        title=" Are you sure you want to Delete this Manifest?"
        onClose={onClose}
        onConfirm={onDelete}
        closeIcon={true}
        btnText={'Yes'}
        secondaryBtnText={'No'}
      />
    </div>
  );
}

function ManifestTable(props: { onDelete: (_id: string) => void; refreshList: () => void }) {
  const {
    data: { list = [], currentPage, perPage },
  } = useSelector((state: RootStateType) => state.manifest);

  return (
    <SBTableWrapper>
      <SBTable className=" border-none">
        <TableHead>
          <TableRow>
            <TableHeadCell>Sr no.</TableHeadCell>
            <TableHeadCell>Date</TableHeadCell>
            <TableHeadCell>Manifest No.</TableHeadCell>
            <TableHeadCell>Service </TableHeadCell>
            <TableHeadCell>System </TableHeadCell>
            <TableHeadCell>Shipments</TableHeadCell>
            <TableHeadCell>Actions</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list && list.length ? (
            list?.map((manifest, index) => (
              <SBTableRow key={manifest._id}>
                <TableRowCell align="center" className="md-text">
                  <div>{index + 1 + (currentPage - 1) * perPage}</div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text">
                  {moment(manifest.createdAt).format('DD/MM/YYYY')}
                </TableRowCell>
                <TableRowCell align="center">
                  <Grid container>
                    <Grid item xs={12} className="font-semibold md-text mb-1/2">
                      {manifest.manifestNumber}
                    </Grid>
                    <Grid item xs={12} className="sm-text ">
                      {manifest.name || ''}
                    </Grid>
                  </Grid>
                </TableRowCell>
                <TableRowCell>
                  <RateRowService cspId={manifest._courierId?._id} />
                </TableRowCell>

                <TableRowCell className="font-semibold" align="center">
                  <Typography className="md-text font-semibold">{manifest._systemId?.name || '-'}</Typography>
                </TableRowCell>
                <TableRowCell align="center">
                  <p className="font-semibold md-text mx-auto">{manifest.shipmentNumbers.length}</p>
                </TableRowCell>
                <TableRowCell>
                  <TableActions manifest={manifest} onDelete={props.onDelete} refreshList={props.refreshList} />
                </TableRowCell>
              </SBTableRow>
            ))
          ) : (
            <SBTableRow>
              <TableRowCell colSpan={7} sx={{ p: 0 }}>
                <Typography className="sb-text-gray font-medium text-center">No Data Found.</Typography>
              </TableRowCell>
            </SBTableRow>
          )}
        </TableBody>
      </SBTable>
    </SBTableWrapper>
  );
}

const initialDateRange = {
  startDate: moment().subtract(3, 'months').startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
};
export default function Manifest() {
  const [filterSelectValue, setFilterSelectValue] = useState(SearchFilterList[0].value);
  const [filterText, setFilterText] = useState('');
  const [isClear, setClear] = useState(false);
  const [service, setService] = useState<string[]>([]);
  const [system, setSystem] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState(initialDateRange);

  const dispatch = useDispatch<AppDispatch>();
  const {
    loading,
    data: { currentPage, perPage, totalCount },
  } = useSelector((state: RootStateType) => state.manifest);
  const { loading: shipmentLoading } = useSelector((state: RootStateType) => state.shipment);

  const SystemList = useSelector((state: RootStateType) => state.System.activeSystemDropdownList);
  const serviceDropdownList = useSelector((state: RootStateType) => state.carrierAndService.serviceDropdownList);

  useEffect(() => {
    const fromDate = moment(dateRange.startDate);
    const toDate = moment(dateRange.endDate);
    dispatch(getManifest({ currentPage: 1, perPage: 10, fromDate, toDate }));
    dispatch(getActiveSystemList());
    dispatch(getServiceList({}));
  }, []);

  useEffect(() => {
    dispatch(getManifest(getFilterObj({ currentPage: 1, perPage: 10, searchValue: '' })));
    return () => {
      setClear(false);
    };
  }, [isClear]);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(getManifest(getFilterObj({ dateRange, currentPage: 1 })));
  };

  const onServiceFilter = (selected: Array<string>) => {
    setService(selected);
    dispatch(getManifest(getFilterObj({ csp: selected, currentPage: 1 })));
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    dispatch(getManifest(getFilterObj({ system: selected, currentPage: 1 })));
  };

  const getFilterObj = (options: FilterObj): ManifestQuery => {
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate);
    const toDate = moment(filterDate.endDate);

    const filterSystem = options.system || system;
    const filterCsp = options.csp || [];

    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filterQuery: ManifestQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };

    filterQuery['system'] = filterSystem;
    filterQuery['csp'] = filterCsp;
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    return filterQuery;
  };

  const onClear = () => {
    setService([]);
    setSystem([]);
    setFilterText('');
    setDateRange(initialDateRange);
    setClear(true);
  };

  const onDeleteManifest = (_id: string) => {
    dispatch(deleteManifest([_id, getFilterObj({})]));
  };

  return (
    <Loader loading={loading || shipmentLoading} overly>
      <>
        <Title title={'Manifest'} subTitle="View and edit manifest reports" removeBorder />

        <div className="overflow-auto" id="fadedScroll">
          <TableActionContainer is_admin="1">
            <FilterSearch
              onSelectChange={(value: string) => {
                setFilterSelectValue(value);
              }}
              list={SearchFilterList}
              selectValue={filterSelectValue}
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={() => {
                dispatch(getManifest(getFilterObj({ currentPage: 1 })));
              }}
              onTextClear={() => {
                setFilterText('');
                dispatch(getManifest(getFilterObj({ searchValue: undefined, currentPage: 1 })));
              }}
            />
            <div></div>
            <div></div>
            <DateRangePicker onChange={onDateChange} value={dateRange} />
            <FilterSelect
              id="service"
              label="Service"
              options={serviceDropdownList}
              value={service}
              onChange={onServiceFilter}
            />
            <FilterSelect id="system" label="System" options={SystemList} value={system} onChange={onSystemFilter} />

            <SbButton
              className="xs-text ml-1"
              variant="outlined"
              sx={{ borderRadius: '8px !important', padding: '8px !important' }}
              onClick={onClear}
            >
              Clear Filters
            </SbButton>
          </TableActionContainer>
        </div>

        <ManifestTable onDelete={onDeleteManifest} refreshList={onClear} />
        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage - 1}
              rowsPerPage={perPage || 10}
              onPageChange={(_, page) => {
                dispatch(getManifest(getFilterObj({ currentPage: page + 1 })));
              }}
              onRowsPerPageChange={event => {
                dispatch(getManifest(getFilterObj({ perPage: parseInt(event.target.value), currentPage: 1 })));
              }}
            />
          </>
        )}
      </>
    </Loader>
  );
}

// function SystemName({ systemId }: { systemId: string }) {
//   const systemHashById = useSelector<RootStateType>(
//     state => state.System.activeSystemListHashById,
//   ) as SystemState['activeSystemListHashById'];

//   const systemNames = systemHashById?.[systemId]?.name;
//   return (
//     <Grid container className="font-semibold">
//       {systemNames}
//     </Grid>
//   );
// }
