import SbButton from '@Components/Button';
import ExportBtn from '@Components/Button/Export';
import DateRangePicker from '@Components/DateRangePicker';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import Image from '@Components/Image';
import Loader from '@Components/Loader';
import { SubHeading } from '@Components/Shipment/Summary/Styles';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import Title from '@Components/Title';
import { USER_STATUS_COLOR } from '@Constants/status';
import useAccountLookup from '@Hook/useAccountLookup';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { CreditHistoryQuery, CreditTransactionObj } from '@Reducers/Credit/Type';
import {
  getCreditLimit,
  getCreditTransactionList,
  getOutstandingBalanceForPostpaid,
  getOutstandingBalanceInvoiceForPostpaid,
} from '@Reducers/Credit/actions';
import { userLogin } from '@Services/hasAdminAccess';
import { AppDispatch, RootStateType } from '@Store';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CreditIcon from '@mui/icons-material/CreditScoreOutlined';
import AvailableLimitIcon from '@mui/icons-material/MonetizationOnOutlined';
import BalanceIcon from '@mui/icons-material/PaymentsOutlined';
import { Divider, Grid, Stack, TableBody, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterObj, TransactionType } from '../Types';
import { CreditWalletSearchFilterList, PaymentModeList, StatusList } from '../constant';
import {
  AmountTypo,
  CreditWalletTableActionContainer,
  CreditedIcon,
  DebitedIcon,
  IconStyle,
  NavigationBtn,
  NavigationBtnContainer,
  TransactionStatus,
} from '../style';
import AddCreditModal from './AddCreditModal';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import API from '@Services/apiAxios';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { fetchExportUrl } from '@Reducers/PostpaidUserReport/PostpaidUserReport';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { formatNumber, useLocale } from '../../../utils/formatNumber';

function TransactionTable() {
  const {
    data: { list, currentPage, perPage },
  } = useSelector((state: RootStateType) => state.Credit);
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name || '');
  const locale = useLocale();

  function getTransactionType(obj: CreditTransactionObj) {
    const label =
      obj?.against === 'shipment'
        ? 'Shipment No'
        : obj?.against === 'invoice'
        ? 'Invoice No'
        : obj?.against === 'credit'
        ? 'Credit'
        : '';
    return (
      <div>
        <Typography>{label}</Typography>
        <Typography>{obj.metadata ? Object.values(obj.metadata)[0] : ''}</Typography>
      </div>
    );
  }

  return (
    <SBTableWrapper>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell style={{ minWidth: '80px' }}>Sr No.</TableHeadCell>
            <TableHeadCell align="left" sx={{ minWidth: '250px' }}>
              Mode
            </TableHeadCell>
            <TableHeadCell align="left" sx={{ minWidth: '200px' }}>
              Transaction Type
            </TableHeadCell>
            <TableHeadCell align="left" sx={{ minWidth: '250px' }}>
              Transaction ID
            </TableHeadCell>
            <TableHeadCell sx={{ minWidth: '160px' }}>Date</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '200px' }}>Amount({orgCountryCurrency})</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '180px' }}>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item, index) => {
            const type: TransactionType = Number(item.amount) > 0 ? 'credit' : 'debit';
            return (
              <SBTableRow key={item._id}>
                <TableRowCell align="center" className="md-text">
                  <div>{index + 1 + (currentPage - 1) * perPage}</div>
                </TableRowCell>
                <TableRowCell>
                  {item._transactionId ? (
                    <div className="flex">
                      {type === 'debit' ? (
                        <DebitedIcon>
                          <CallMadeIcon />
                        </DebitedIcon>
                      ) : (
                        <CreditedIcon>
                          <CallReceivedIcon />
                        </CreditedIcon>
                      )}
                      <Typography className="md-text">
                        {PaymentModeList[item._transactionId?.mode]?.display || ''}
                      </Typography>
                    </div>
                  ) : (
                    '--'
                  )}
                </TableRowCell>
                <TableRowCell className="sm-text">{getTransactionType(item)}</TableRowCell>
                <TableRowCell className="sm-text">{item?._transactionId?._id || '--'}</TableRowCell>
                <TableRowCell className="sm-text" align="center">
                  {moment(item.createdAt).format('DD/MM/YYYY')}
                </TableRowCell>
                <TableRowCell className="sm-text" align="center">
                  <AmountTypo type={type}>{formatNumber(item.amount, locale)}</AmountTypo>
                </TableRowCell>
                <TableRowCell align="center" className="sm-text">
                  <TransactionStatus
                    bgcolor={USER_STATUS_COLOR['successful'].bgColor}
                    color={USER_STATUS_COLOR['successful'].textColor}
                  >
                    {USER_STATUS_COLOR['successful']?.text}
                  </TransactionStatus>
                </TableRowCell>
              </SBTableRow>
            );
          })}
        </TableBody>
      </SBTable>
    </SBTableWrapper>
  );
}

export function Navigation(props: { selectedUser: UserObj | null; disabled?: boolean }) {
  const [addCreditsModal, setAddCreditsModal] = useState<boolean>(false);
  const [creditNoteBalance, setCreditNoteBalance] = useState<number>(0);
  const dispatch = useDispatch<AppDispatch>();
  const { creditLimit, totalInvoiceAmtPaid, totalShipmentAmt, totalShipmentAmtPaid, totalInvoiceAmt } = useSelector(
    (state: RootStateType) => state.Credit,
  );
  const locale = useLocale();
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  // const orgCountryCurrencySub = useSelector(
  //   (state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.symbol || '',
  // );
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name || '');

  const fetchCreditBalance = async (userId: string) => {
    try {
      const res = await API.get(`/payment/wallet/credit-note-balance/${userId}`);
      setCreditNoteBalance(res.data?.data?.balance);
      return;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  };
  useEffect(() => {
    if (props?.selectedUser?._id) {
      fetchCreditBalance(props?.selectedUser?._id);
    }
  }, [props?.selectedUser?._id]);

  const outStandingBalanceResult = totalShipmentAmt + totalInvoiceAmt - (totalInvoiceAmtPaid + totalShipmentAmtPaid);
  const updateAvailableLimit = creditLimit - outStandingBalanceResult;

  return (
    <>
      <NavigationBtnContainer>
        {addCreditsModal && (
          <AddCreditModal
            onClose={() => setAddCreditsModal(false)}
            // availableLimit={availableLimit}
            availableLimit={creditLimit}
            selectedUser={props.selectedUser}
          />
        )}
        <NavigationBtn>
          <BalanceIcon style={IconStyle} />
          <div className="ml-4">
            <Typography className="sm-text">Outstanding Balance({orgCountryCurrency})</Typography>
            <Typography className="md-text">{formatNumber(outStandingBalanceResult, locale)}</Typography>
            {/* <Typography className="md-text">{outStandingBalanceResult.toFixed(2)}</Typography> */}
          </div>
        </NavigationBtn>

        <NavigationBtn>
          <CreditIcon style={IconStyle} />
          <Stack className="ml-4" direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
            <div>
              <Typography className="sm-text">Credit Limit({orgCountryCurrency})</Typography>
              <Typography className="md-text">{formatNumber(creditLimit, locale)}</Typography>
            </div>
            {isAdmin ? (
              <SbButton
                className="sm-text"
                size="small"
                variant="outlined"
                sx={{ borderRadius: '8px !important', width: 'fit-content' }}
                onClick={() => setAddCreditsModal(true)}
                disabled={props.disabled}
              >
                Update Limit
              </SbButton>
            ) : null}
          </Stack>
        </NavigationBtn>

        <NavigationBtn>
          <AvailableLimitIcon style={IconStyle} />
          <div className="ml-4">
            <Typography className="sm-text">Available Limit({orgCountryCurrency})</Typography>
            <Typography className="md-text">{formatNumber(updateAvailableLimit, locale)}</Typography>
            {/* <Typography className="md-text">{updateAvailableLimit?.toFixed(2)}</Typography> */}
          </div>
        </NavigationBtn>
        <NavigationBtn>
          <Image src="/images/icons/CreditNote.svg" style={IconStyle} alt="Credit Note" />
          <div className="ml-4">
            <Typography className="sm-text">Credit Note Balance({orgCountryCurrency})</Typography>
            <Typography className="md-text">{formatNumber(creditNoteBalance, locale)}</Typography>
          </div>
        </NavigationBtn>
      </NavigationBtnContainer>
    </>
  );
}

export default function CreditHistory({ user }: { user?: UserObj }) {
  const modalId = 'credit';
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector((state: RootStateType) => state?.loginUser?.profile?._id) || null;
  const {
    loading: accountLookupLoader,
    onOpen: openAccountLookupModal,
    resetSelectedUser,
    handleSetUserQuery,
  } = useAccountLookup(modalId, true);

  const [accountUser, setAccountUser] = useState<UserObj | null>(user || null);

  // console.info('user:', user);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isUpdateLimitEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Credits_And_Wallet.update_limit,
    PERFORM_ACTION.write,
  );

  const isExportDataEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Credits_And_Wallet.export_data,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    if (user) {
      setAccountUser(user);
    }
  }, [user]);

  // const getSelectedUser = (user: UserObj) => {
  //   setAccountUser(user);
  // };

  const [filterSelectValue, setFilterSelectValue] = useState('shipmentNumber');
  const [filterText, setFilterText] = useState('');
  const [mode, setMode] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [loader, setLoader] = useState<boolean>(false);
  const { limit } = useSelector((state: RootStateType) => state.notifications);
  const accountID = useSelector((state: RootStateType) => state.loginUser.token?.accountId);

  const {
    data: { currentPage = 1, perPage, totalCount },
    loading,
  } = useSelector((state: RootStateType) => state.Credit);
  const { loading: creditLimitLoader, success: creditLimitSuccess } = useSelector(
    (state: RootStateType) => state.Credit,
  );

  function getListData(filterParams: CreditHistoryQuery) {
    console.log('Credit getListData');

    if (accountUser?._accountId?._id) {
      dispatch(getCreditTransactionList([accountUser?._accountId?._id, filterParams]));
    } else {
      dispatch(getCreditTransactionList([null, filterParams]));
    }
  }

  useEffect(() => {
    if (isAdmin) {
      resetSelectedUser();
      openAccountLookupModal();
      handleSetUserQuery({ accountType: ['postpaid'] });
    } else {
      getListData(getFilterObj({}));
      dispatch(getCreditLimit());
      dispatch(getOutstandingBalanceForPostpaid());
      dispatch(getOutstandingBalanceInvoiceForPostpaid());
    }
  }, [isAdmin]);

  useEffect(() => {
    if (accountUser) {
      const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
      const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
      getListData({ currentPage: 1, perPage: 10, fromDate, toDate });
      dispatch(getCreditLimit(accountUser?._accountId?._id));
      dispatch(getOutstandingBalanceForPostpaid(accountUser?._accountId?._id));
      dispatch(getOutstandingBalanceInvoiceForPostpaid(accountUser?._accountId?._id));
    }
  }, [accountUser]);

  useEffect(() => {
    getListData(getFilterObj({}));
    console.log('on credit added : ', { creditLimitSuccess, accountUser });
    if (creditLimitSuccess) {
      if (accountUser) {
        dispatch(getCreditLimit(accountUser?._accountId?._id));
        dispatch(getOutstandingBalanceForPostpaid(accountUser?._accountId?._id));
        dispatch(getOutstandingBalanceInvoiceForPostpaid(accountUser?._accountId?._id));
      } else {
        dispatch(getCreditLimit());
        dispatch(getOutstandingBalanceForPostpaid());
        dispatch(getOutstandingBalanceInvoiceForPostpaid());
      }
    }
  }, [creditLimitSuccess]);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    getListData(getFilterObj({ dateRange }));
  };

  const onStatusFilterChange = (selected: Array<string>) => {
    setStatus(selected);
    getListData(getFilterObj({ status: selected }));
  };

  const onModeFilterChange = (selected: Array<string>) => {
    setMode(selected);
    getListData(getFilterObj({ mode: selected }));
  };

  const getFilterObj = (options: FilterObj): CreditHistoryQuery => {
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterStatus = options.status || status;
    const filterMode = options.mode || mode;

    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    return {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      status: filterStatus,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
      mode: filterMode,
    };
  };

  // console.info('accountUser?._id:', accountUser);

  const handleExport = async () => {
    try {
      setLoader(true);
      const id = accountID ? accountID : userId;
      const filters = {
        currentPage: getFilterObj({}).currentPage,
        perPage: getFilterObj({}).perPage,
        fromDate: getFilterObj({}).fromDate,
        toDate: getFilterObj({}).toDate,
      };

      // Await the fetchExportUrl dispatch
      await dispatch(fetchExportUrl({ id, filters }));

      // If fetchExportUrl succeeds, execute the following code
      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit,
        }),
      );
      dispatch(getAllReadNotifications({ page: 1, limit }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Download in Progress',
          subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
          btnText: 'Close',
          onConfirm: () => {
            dispatch(toggleBoolean());
            dispatch(SuccessErrorModalReset());
          },
        }),
      );
    } catch (error) {
      console.info('Error:', error);
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'Please try again later',
        }),
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <Loader loading={accountLookupLoader || loading || creditLimitLoader} overly>
      <>
        {!isAdmin && <Title title="Credits and transactions" removeBorder />}
        {/* {isAdmin && (
          <>
            <Grid container className="rounded-lg p-5 shadow mt-3 mb-4">
              <Grid item xs={12} md={6} lg={4} xl={3} className="mb-3">
                <AccountLookup modalId={modalId} showSearchInput={true} getSelectedUser={getSelectedUser}>
                  <Title title="Transactions" removeBorder />
                </AccountLookup>
              </Grid>
              <Grid xs={12}>
                <Divider className="mt-2 mb-4" />
              </Grid>
              {accountUser && <UserDetailCard user={accountUser} />}
            </Grid>
            <Divider className="my-3" />
          </>
        )} */}

        <SubHeading className="mb-4">Credit Details</SubHeading>
        <Navigation selectedUser={accountUser} disabled={!isUpdateLimitEditAllowed} />
        <Divider className="my-4" />

        <Grid container justifyContent="space-between" mt={'3rem'}>
          <SubHeading className="font-medium">All transactions</SubHeading>
          <Loader loading={loader} />
          <ExportBtn
            iconSrc="/images/file_download.svg"
            height={25}
            alt="Export"
            label="Export"
            onClick={() => {
              handleExport();
            }}
            // onClick={() => {
            //   dispatch(ExportOrder(props.getFilterObj({})));
            // }}
            disabled={!isExportDataEditAllowed}
          />
        </Grid>

        <CreditWalletTableActionContainer>
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={CreditWalletSearchFilterList}
            selectValue={filterSelectValue}
            textValue={filterText}
            onTextChange={e => {
              setFilterText(e.target.value);
            }}
            onTextSearch={() => {
              getListData(getFilterObj({}));
            }}
            onTextClear={() => {
              setFilterText('');
              getListData(getFilterObj({ searchValue: undefined }));
            }}
          />
          <div></div>
          <DateRangePicker onChange={onDateChange} value={dateRange} />
          <FilterSelect
            id="mode"
            label="Mode"
            value={mode}
            options={Object.values(PaymentModeList)}
            onChange={onModeFilterChange}
          />
          <FilterSelect
            id="status"
            label="Status"
            value={status}
            options={Object.values(StatusList)}
            onChange={onStatusFilterChange}
          />
          <SbButton
            className="xs-text ml-1"
            variant="outlined"
            sx={{ borderRadius: '8px !important', padding: '8px !important' }}
            onClick={() => {
              setFilterText('');
              setMode([]);
              setStatus([]);
              setDateRange({
                startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
              });
              getListData(
                getFilterObj({
                  status: [],
                  searchValue: undefined,
                  dateRange: {
                    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                    endDate: moment().endOf('day').toDate(),
                  },
                  mode: [],
                  currentPage: 1,
                }),
              );
            }}
          >
            Clear Filters
          </SbButton>
        </CreditWalletTableActionContainer>

        <TransactionTable />

        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage - 1}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                getListData(getFilterObj({ currentPage: page + 1 }));
              }}
              onRowsPerPageChange={event => {
                getListData(getFilterObj({ perPage: parseInt(event.target.value) }));
              }}
            />
          </>
        )}
      </>
    </Loader>
  );
}
