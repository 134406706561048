import React, { useRef, useEffect, useMemo, useState } from 'react';
import SbButton from '@Components/Button';
import Modal from '@Components/Modal';
import Loader from '@Components/Loader';
import { AppDispatch, RootStateType } from '@Store';
import { Grid, Typography, styled } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { calculateImageHeightAndWidth } from '../../utils/imageUtil';
import { GetApp } from '@mui/icons-material';
import { getPP } from '@Reducers/Agreement/actions';

const AgreementWrapperList = styled(Grid)(_ => ({
  '& ul': {
    margin: 0,
    '& li': {
      padding: '5px',
      letterSpacing: '1px',
      cursor: 'pointer',
      '&:hover': {
        fontWeight: '600',
      },
    },
  },
}));

const DownloadContainer = styled(Grid)(_ => ({
  top: '85vh',
  right: '2%',
  width: 'max-content',
  cursor: 'pointer',
  position: 'fixed',
}));

function PrivacyPolicyBeforeAuth() {
  const pdfContainer = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  const [archivedIndex, setArchivedIndex] = useState<number | null>(null);
  const [isArchivedModal, setArchivedModal] = useState<boolean>(false);

  const loading = useSelector((state: RootStateType) => state.Agreement.loading);
  const organizationPublicData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const _agreements = useSelector((state: RootStateType) => state.Agreement.PP);

  useEffect(() => {
    dispatch(getPP());
  }, []);

  const __html = useMemo(() => {
    if (archivedIndex === null) {
      return _agreements.find(x => x.isActive);
    }
    return _agreements[archivedIndex];
  }, [archivedIndex, _agreements]);

  const effectiveDate = __html?.createdAt ? moment(__html?.createdAt).format('LL') : '';

  const printPDF = async () => {
    if (!pdfContainer.current || !organizationPublicData) return;
    // append image
    const imgContainer = document.createElement('div');
    const HTMLWrapper = document.createElement('div');
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ctx: any = canvas.getContext('2d');
    canvas.style.display = 'block';
    canvas.style.margin = '0 15px 10px auto';
    canvas.classList.add('logo');

    HTMLWrapper.classList.add('wrapper');
    HTMLWrapper.innerHTML = pdfContainer.current.innerHTML;

    const img = new Image();
    img.onload = _imgObj => {
      const imgH = img.height;
      const imgW = img.width;
      const result = calculateImageHeightAndWidth({ imgH, imgW });
      canvas.height = result.newImgH;
      canvas.width = result.newImgW;
      ctx.drawImage(img, 0, 0, result.newImgW, result.newImgH);

      imgContainer.appendChild(canvas);

      if (window.navigator.appName == 'Microsoft Internet Explorer') return window.print();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w: any = window.open('about:blank');
      const style = `<style>@page {size: auto;margin: 4mm;} p{margin:0.9em}
                    /* .logo{min-height: 50px;max-width: 250px;max-height: 100px; }*/
                    .wrapper{padding: 15px 15px;border-top: 1px solid #ccc;}
                    [data-heading="true"] {font-size:1.5rem;font-weight:600} 
                    [data-effective-date="true"]{font-size: 0.9rem;font-style: oblique;margin: 4px 10px 0;font-family: sans-serif;}
                    [data-hidden="true"] {display:none;}
                    [data-content="true"]{padding-left:10px}</style> `;
      w.document.head.innerHTML = style;
      w.document.body.appendChild(imgContainer);
      w.document.body.appendChild(HTMLWrapper);
      w.print();
      setTimeout(() => w.close(), 500);
    };

    img.src = organizationPublicData.logo;
  };

  return (
    <Loader loading={loading} overly>
      <>
        <Modal open={isArchivedModal} maxWidth="sm" onClose={() => setArchivedModal(false)}>
          <Grid container>
            <Grid container>
              <Typography gutterBottom variant="h5" color="primary" className="font-semibold">
                Archived versions
              </Typography>
            </Grid>
            <AgreementWrapperList container px={4}>
              <ul>
                {_agreements.map((x, i) => (
                  <li
                    className="cursor-pointer"
                    key={x._id}
                    onClick={() => {
                      setArchivedIndex(i);
                      setArchivedModal(false);
                    }}
                  >
                    {moment(x.createdAt).format('LL')} {x.isActive && '( Current )'}
                  </li>
                ))}
              </ul>
            </AgreementWrapperList>
          </Grid>
        </Modal>
        <DownloadContainer container onClick={printPDF} className="rounded-full border border-solid border-light p-1">
          <GetApp />
        </DownloadContainer>
        <Grid container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid xs={6}>
              <Typography className="uppercase font-semibold l-text">Privacy Policy</Typography>
              {effectiveDate && (
                <Typography className="font-oblique">
                  Effective from <time title={effectiveDate}>{effectiveDate}</time>
                </Typography>
              )}
            </Grid>
            <Grid xs={6} textAlign="end">
              <SbButton variant="outlined" onClick={() => setArchivedModal(true)}>
                Archived versions
              </SbButton>
            </Grid>
          </Grid>
          <Grid container className="mt-2" px={4} ref={pdfContainer}>
            <Grid dangerouslySetInnerHTML={{ __html: __html?.content || '' }} />
          </Grid>
        </Grid>
      </>
    </Loader>
  );
}

export default PrivacyPolicyBeforeAuth;
