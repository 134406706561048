import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { BoxEditType, BoxType, GetRatesContextType } from '../../Type';
import SbTextField from '../../../../components/Textfield';
import { boxSchema } from './Schema';
import GetRatesContext from '../../../../context/GetRates';
import { ValidationResult } from 'joi';
import { LENGTH_UNITS } from '../../Constant';
import Actions from '../Actions';

function BoxEdit({ setBoxState, data }: BoxEditType) {
  const { boxes, setBoxes, ratesInput } = React.useContext(GetRatesContext) as GetRatesContextType;
  const [initials, setInitials] = useState<BoxType>(data);
  const [isError, setIsError] = useState<boolean>(true);
  const { weightUnit } = ratesInput;

  const { id, length, height, width, weight } = initials;

  useEffect(() => {
    setInitials({ ...data });
  }, [data]);

  useEffect(() => {
    const error = validateData();
    if (error) setIsError(true);
    else setIsError(false);
  }, [initials]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.target.name as keyof BoxType;
    const { value } = event.target;
    const boxTemp = { ...initials };
    if (name === 'length' || name === 'width' || name === 'height' || name === 'weight') boxTemp[name] = value;
    setInitials(boxTemp);
  }

  function handleSubmit() {
    if (isError) return;
    setIsError(false);
    const boxesTemp = [...boxes];
    boxesTemp[parseInt(id)] = { ...boxesTemp[parseInt(id)], ...initials };
    setBoxes([...boxesTemp]);
    handleCancel();
  }

  function validateData() {
    const result: ValidationResult = boxSchema.validate(initials, { abortEarly: false });
    const { error } = result;
    return error;
  }

  function handleCancel() {
    setBoxState('details');
  }

  return (
    <>
      <Grid container item>
        <Grid container justifyContent="flex-start" columnSpacing={2} className="mb-1">
          <Grid item xs={3.5}>
            <SbTextField
              inputClassName="input-height-small rounded-xs"
              name="weight"
              value={weight}
              type="number"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={1}>
            <Typography className="sm-text pt-1">{weightUnit}</Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-start" columnSpacing={2} className="mb-1">
          <Grid item xs={3.5} textAlign={'center'}>
            <SbTextField
              inputClassName="input-height-small rounded-xs"
              name="length"
              type="number"
              value={length}
              onChange={handleChange}
            />
            <Typography className="sm-text">Length</Typography>
          </Grid>
          <Grid item xs={3.5} textAlign={'center'}>
            <SbTextField
              inputClassName="input-height-small rounded-xs"
              name="width"
              type="number"
              value={width}
              onChange={handleChange}
            />
            <Typography className="sm-text">Width</Typography>
          </Grid>
          <Grid item xs={3.5} textAlign={'center'}>
            <SbTextField
              inputClassName="input-height-small rounded-xs"
              name="height"
              type="number"
              value={height}
              onChange={handleChange}
            />
            <Typography className="sm-text">Height</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography className="sm-text pt-1">{LENGTH_UNITS[weightUnit].value}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Actions onConfirm={handleSubmit} onCancel={handleCancel} disabled={isError} />
    </>
  );
}

export default BoxEdit;
