import { useEffect, useRef } from 'react';

export type Timer = ReturnType<typeof setTimeout>;
export type callbackFunction = (...args: unknown[]) => void;

export default function useDebounce<Func extends callbackFunction>(func: Func, delay = 1000) {
  const timer = useRef<Timer>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  const debouncedFunction = ((...args) => {
    const newTimer = setTimeout(() => {
      func(...args);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  }) as Func;

  return debouncedFunction;
}
