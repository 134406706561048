import React from 'react';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import SortIcon from '../../../public/images/icons/AdminHome/sortIcon.svg';
import Image from '@Components/Image';
import { formatNumber, useLocale } from '../../utils/formatNumber';

interface Carrier {
  name: string;
  logo: string;
  totalShipment: number;
  totalRevenue: number;
  totalWeight: number;
  shipmentPercentage: number;
  weightPercentage: number;
  revenuePercentage: number;
  _id: string;
}

interface TableStyle {
  fontFamily: string;
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
  color: string;
}

const tableStyle: TableStyle = {
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: `16px`,
  lineHeight: `20px`,
  color: '#181D25',
};

interface Props {
  data: Carrier[];
  setCarrierShipmentSort?: any;
  setCarrierWeightSort?: any;
  setCarrierRevenueSort?: any;
  carrierWeightSort: boolean;
  carrierShipmentSort: boolean;
  carrierRevenueSort: boolean;
}

const TopTenCarriersTable: React.FC<Props> = ({
  data,
  setCarrierWeightSort,
  setCarrierShipmentSort,
  carrierShipmentSort,
  carrierWeightSort,
  carrierRevenueSort,
  setCarrierRevenueSort,
}) => {
  const handleShipmentSort = () => {
    setCarrierShipmentSort((prev: any) => !prev);
    setCarrierWeightSort(false);
    setCarrierRevenueSort(false);
  };
  const handleWeightSort = () => {
    setCarrierWeightSort((prev: any) => !prev);
    setCarrierShipmentSort(false);
    setCarrierRevenueSort(false);
  };
  const handleRevenueSort = () => {
    setCarrierRevenueSort((prev: any) => !prev);
    setCarrierWeightSort(false);
    setCarrierShipmentSort(false);
  };
  const locale = useLocale();

  return (
    <Box sx={{ padding: 0 }}>
      <TableContainer sx={{ border: 'none', width: '100%', boxShadow: 'none' }} component={Paper}>
        <Table sx={{ border: 'none' }}>
          <TableHead>
            <TableRow sx={{ borderRight: 'none' }}>
              <TableCell sx={{ ...tableStyle }}>Name</TableCell>
              <TableCell
                onClick={handleShipmentSort}
                sx={{
                  ...tableStyle,
                  textAlign: 'center',
                  cursor: 'pointer',
                  color: carrierShipmentSort ? '#03A01C' : '',
                  fontWeight: carrierShipmentSort ? 600 : 400,
                }}
              >
                Shipments
                {/* <Image src={SortIcon} alt="Sort Icon" /> */}
              </TableCell>
              <TableCell
                onClick={handleWeightSort}
                sx={{
                  ...tableStyle,
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  textAlign: 'center',
                  color: carrierWeightSort ? '#03A01C' : '',
                  fontWeight: carrierWeightSort ? 600 : 400,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Weight(Kg)
                  {/* <Image src={SortIcon} alt="Sort Icon" /> */}
                </Box>
              </TableCell>
              <TableCell
                onClick={handleRevenueSort}
                sx={{
                  ...tableStyle,
                  cursor: 'pointer',
                  color: carrierRevenueSort ? '#03A01C' : '',
                  fontWeight: carrierRevenueSort ? 600 : 400,
                  textAlign: 'center',
                }}
              >
                Revenue(INR)
                {/* <Image src={SortIcon} alt="Sort Icon" /> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((carrier: Carrier) => (
              <TableRow key={carrier._id}>
                <TableCell sx={{ ...tableStyle, color: '#929FB1' }}>
                  <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Box>
                      <img src={carrier.logo} alt={`${carrier.name} logo`} style={{ width: '50px', height: 'auto' }} />
                    </Box>
                    <Box>{carrier.name}</Box>
                  </Box>
                </TableCell>

                <TableCell sx={{ textAlign: 'center', color: '#929FB1' }}>
                  <Box sx={{ marginLeft: '' }}>
                    <Box>
                      <Typography sx={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>
                        {formatNumber(carrier.totalShipment, locale)}{' '}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',
                          backgroundColor: carrier.shipmentPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: carrier.shipmentPercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            carrier.shipmentPercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(carrier.shipmentPercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', color: '#929FB1' }}>
                  <Box sx={{ marginLeft: '' }}>
                    <Box>
                      <Typography sx={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>
                        {formatNumber(carrier.totalWeight, locale)}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',

                          backgroundColor: carrier.weightPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: carrier.weightPercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            carrier.weightPercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(carrier.weightPercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', color: '#929FB1' }}>
                  <Box sx={{ marginLeft: '' }}>
                    <Box>
                      <Typography sx={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>
                        {formatNumber(carrier.totalRevenue, locale)}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',
                          backgroundColor: carrier.revenuePercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: carrier.revenuePercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            carrier.revenuePercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(carrier.revenuePercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TopTenCarriersTable;
