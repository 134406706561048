import React, { useEffect } from 'react';
import ResponsiveSidebar from '@Components/LeftSidebar';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { getOrganizationDetails, setOrgCountry } from '@Reducers/OrganizationSetup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { organizationSetupRoute } from '@Constants/route';
import { useOrgSetupStatus } from '@Hook/useOrgSetupStatus';
import Header from '@Components/Header';
import SuccessError from '@Components/Modal/SuccessError';

const Home: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { pickupPinCode } = useSelector((state: RootStateType) => state.getRates.formData);
  const { isBookNow_WithAuth } = useSelector((state: RootStateType) => state.getRates);

  useEffect(() => {
    if (pickupPinCode && !isBookNow_WithAuth) {
      navigate('/shipment/create-single');
    }
  }, []);

  // const loginUser = useSelector((state: RootStateType) => state.loginUser);
  const orgPublicData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const countriesObj = useSelector((state: RootStateType) => state.global.countriesObj);

  const { orgCurrentLevel } = useOrgSetupStatus();

  useEffect(() => {
    if (!orgPublicData?._countryId) return;
    const country = countriesObj[orgPublicData._countryId];
    dispatch(setOrgCountry(country));
  }, [countriesObj, orgPublicData?._countryId]);

  useEffect(() => {
    dispatch(getOrganizationDetails());
  }, []);

  // useEffect(
  //   function () {
  //     if (loginUser.pageLoaded && OrgSetup.organizationPublicData) {
  //       if (!loginUser.profile) navigate('/login');
  //     }
  //   },
  //   [loginUser],
  // );

  useEffect(() => {
    if (orgCurrentLevel !== 'shipment-settings') {
      navigate(organizationSetupRoute[orgCurrentLevel]);
    }
  }, [orgCurrentLevel]);

  // if (loginUser.pageLoaded && !loginUser.profile && !OrgSetup.organizationPublicData)
  //   return <>ORGANIZATION IS NOT SET, PLEASE CONTACT TO SPEEDBOX BUSINESS TEAM TO COMPLETE ONBOARD SET UP.</>;
  if (orgCurrentLevel !== 'shipment-settings') return <Outlet />;

  return (
    <>
      <section style={{ display: 'flex' }}>
        <SuccessError />
        <ResponsiveSidebar />
        <OutletContainer />
      </section>
    </>
  );
};

const OutletWrapperStyle = styled('div')(({ theme }) => ({
  flex: 1,
  height: '100vh',
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    backgroundColor: '#fff',
  },
}));

const OutletContainerStyle = styled('div')(({ theme }) => ({
  width: '100%',
  // paddingTop: `70px`,
  display: 'flex',
  flexDirection: 'column',
  borderLeft: '30px',
  borderTop: '30px',
  borderColor: theme.palette.primary.main,
  // gap: 20,
  [theme.breakpoints.down('md')]: {
    padding: `25px 18px`,
  },
}));

const ComponentWrapper = styled('div')(({ theme }) => ({
  padding: '60px',
  borderLeft: '30px',
  borderTop: '30px',
  borderRight: '0',
  borderBottom: '0',
  borderTopLeftRadius: '40px',
  borderStyle: 'solid',
  borderColor: theme.palette.primary.main,
  overflowY: 'auto',
  height: 'calc(100vh - 94px)',
  [theme.breakpoints.down('xl')]: {
    padding: '18px',
    // borderTopLeftRadius: '0',
  },
  [theme.breakpoints.down('md')]: {
    borderLeft: '0px',
    borderTop: '0px',
    height: 'auto',
    padding: '0',
    borderTopLeftRadius: '0',
  },
}));

function OutletContainer(): JSX.Element {
  return (
    <OutletWrapperStyle>
      <NavBar />
      <OutletContainerStyle>
        <Header />
        <ComponentWrapper className="curve">
          <Outlet />
        </ComponentWrapper>
      </OutletContainerStyle>
    </OutletWrapperStyle>
  );
}

const NavBarContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  height: '71px',
  position: 'fixed',
  top: 0,
  right: '0.7em',
  zIndex: 1,
  transition: 'width 0.2s ease-in',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

function NavBar() {
  return <NavBarContainer />;
}

export default Home;
