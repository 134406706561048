import React, { useState, useEffect } from 'react';
import { Grid, IconButton } from '@mui/material';
import Modal from '@Components/Modal';
import RadioButton from '@Components/RadioButton';
import DatePicker from '@Components/DatePicker';
import SBDropdown from '@Components/Dropdown';
import SBWarehouseDropdown from '@Components/Dropdown/Warehouse';
import SbButton from '../../components/Button';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloseIcon from '@mui/icons-material/Close';
import { PickupTypeList, defaultPickupState } from '../CreateShipment/constants';
import { PickupType, PickupDataType } from '../../types/shipment';
import { SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updatePickUpAndDropOff } from '@Reducers/AdminShipment';
import { checkPincodeServiceabilityForShipment } from '@Reducers/pincode/actions';
import { UpdatePickUpAndDropOff } from '../../store/reducers/AdminShipment/Type';
import { RootStateType } from '../../store';
import { DropdownOptionType } from '../../components/Dropdown/Type';
import dayjs, { Dayjs } from 'dayjs';
import { getShipmentSettings } from '@Reducers/OrganizationSetup';
import Loader from '@Components/Loader';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
interface RescheduleModalProps {
  open: boolean;
  onClose: () => void;
  selectedId: string;
  pincode: string;
}

const RescheduleModal = (props: RescheduleModalProps) => {
  const [value, setValue] = useState('1');
  const dispatch = useDispatch<any>();
  const countryHashById = useSelector((state: RootStateType) => state.global.countriesObj);
  const { warehouseListForShipmentOptionsHashById } = useSelector((state: RootStateType) => state.Warehouse);
  const [timeSlotsOption, setTimeSlotsOption] = useState<DropdownOptionType[]>([]);
  const [pickupType, setPickupType] = useState<PickupDataType>({ ...defaultPickupState });
  const [isPickupAble, setIsPickupAble] = useState(false);
  const [SelectedWarehouse, setSelectedWarehouse] = useState<string>('');
  const [WareHouseDetail, setWareHouseDetail] = useState<any>([]);
  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const { pickupTimeSlots } = shipmentSetting || {};
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (SelectedWarehouse) {
      const selectedWarehouseDetails = warehouseListForShipmentOptionsHashById[SelectedWarehouse];
      if (selectedWarehouseDetails) {
        setWareHouseDetail(selectedWarehouseDetails);
      }
    }
  }, [SelectedWarehouse, warehouseListForShipmentOptionsHashById]);

  const handlePickupDateChange = (date: Dayjs) => {
    setPickupType({ ...pickupType, date: date });
  };

  useEffect(() => {
    const slots = pickupTimeSlots?.slots;
    if (slots) {
      setTimeSlotsOption(
        slots.map(({ from, to }) => {
          const isToday = dayjs(pickupType.date).isSame(dayjs(), 'day');
          const checkPastTime = dayjs(to, 'hh a').isBefore(dayjs(dayjs(), 'hh:mm a'));
          const str = `${from} - ${to}`;
          return { display: str, value: str, disable: isToday && checkPastTime };
        }),
      );
    }
  }, [pickupTimeSlots, pickupType.date]);

  useEffect(() => {
    setLoading(true);
    const checkServiceability = async () => {
      const res = await dispatch(checkPincodeServiceabilityForShipment({ pincode: props.pincode }));
      if (res.payload && res.payload.isPickupServiceable) {
        setIsPickupAble(true);
      } else {
        setIsPickupAble(false);
        setPickupType({ ...pickupType, type: 'dropOff' });
      }
      await dispatch(getShipmentSettings());
    };

    if (props.open) {
      checkServiceability();
    }
    setLoading(false);
  }, [props.pincode, props.open]);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleTimeSlotChange = (
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
  ) => {
    const value = event.target.value;
    setPickupType({ ...pickupType, timeSlot: value });
  };
  const handleSave = async () => {
    const payload: UpdatePickUpAndDropOff = {
      shipmentNumber: props.selectedId,
      isDropOff: pickupType.type === 'dropOff',
    };

    if (pickupType.type === 'dropOff') {
      const selectedWarehouse = WareHouseDetail;
      const coAttention = selectedWarehouse.coAttention;
      const [telephoneCode, number] = coAttention.contact.split(' ');
      payload.warehouseId = SelectedWarehouse;
      const countryId = selectedWarehouse._addressId.country;
      payload.warehouseAddress = {
        ...selectedWarehouse._addressId,
        label: selectedWarehouse._addressId.label !== null ? selectedWarehouse._addressId.label : '',
        country: countryHashById?.[countryId]?.name,
        name: selectedWarehouse.name,
        phone: { telephoneCode, number, name: coAttention.name },
        email: coAttention.email,
      };
    } else {
      payload.timeSlot = pickupType.timeSlot;
      payload.pickupDate = dayjs(pickupType.date).toISOString();
    }
    try {
      setLoading(true);
      const response = await dispatch(updatePickUpAndDropOff(payload));
      if (response.error || response.status >= 400) {
        throw new Error('Request failed');
      }

      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Successfully Rescheduled!',
          subTitle: '',
        }),
      );
    } catch (err) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Unable to Reschedule',
          subTitle: err.message || '',
        }),
      );
    }
    setLoading(false);
    props.onClose();
  };

  const handlePickupTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setPickupType({ ...defaultPickupState, type: selectedValue as PickupType });
  };

  const handleWarehouseChange = (
    selected: string[],
    // selectedObj: unknown[],
    // event: SelectChangeEvent<string | string[]>,
    // child: React.ReactNode,
  ) => {
    setSelectedWarehouse(selected[0]);
  };

  return (
    <>
      <Loader loading={loading} overly />
      <Modal open={props.open} onClose={handleClose} maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: {
              xs: '100%',
              sm: '100%',
              md: '750px',
            },
            height: {
              xs: '100%',
              sm: '100%',
              md: '420px',
            },
            // maxHeight: '90vh',
            position: 'relative',
          }}
        >
          <Box sx={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Reschedule" value="1" className="md-text font-medium" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ height: '55vh', overflow: 'auto', paddingBottom: '80px' }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                  <Grid item lg={10} md={10} xs={12}>
                    <RadioButton
                      containerClass="w-full"
                      name="type"
                      defaultValue={'dropOff'}
                      value={pickupType.type}
                      onChange={handlePickupTypeChange}
                      list={PickupTypeList.map(item => ({
                        ...item,
                        disabled: item.value === 'pickUp' && !isPickupAble,
                      }))}
                      sx={{ display: 'flex', columnGap: 10 }}
                    />
                  </Grid>

                  {pickupType.type == 'pickUp' && (
                    <>
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <DatePicker
                          label="Date"
                          value={pickupType.date}
                          handleChange={handlePickupDateChange}
                          minDate={dayjs()}
                          disabled={!isPickupAble}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <SBDropdown
                          id="timeSlot"
                          name="timeSlot"
                          isSortingAllowed={false}
                          label="Time slot"
                          placeholder="Select time"
                          value={[pickupType.timeSlot]}
                          onChange={handleTimeSlotChange}
                          options={timeSlotsOption}
                          disabled={!isPickupAble}
                        />
                      </Grid>
                    </>
                  )}

                  {pickupType.type == 'dropOff' && (
                    <Grid item md={6} xs={12} sx={{ pl: { lg: 3, xs: 0 } }}>
                      <SBWarehouseDropdown
                        pincode={props.pincode}
                        value={SelectedWarehouse}
                        onChange={handleWarehouseChange}
                        placeholder="Select Warehouse"
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: '16px',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <SbButton variant="contained" onClick={handleSave}>
              Save
            </SbButton>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RescheduleModal;
