import { Chip, Grid, styled } from '@mui/material';
import SbButton from '../../../components/Button';

export const TableHeader = styled(Grid)(
  ({ theme }) => `
      height: 40px;
      background: var(--mui-palette-primary-main);
      border: 1px solid var(--mui-palette-primary-main);
      color: var(--mui-palette-primary-contrastText);
      font-size: ${theme.spacing(2)};
      font-weight:600;
      margin-bottom: 14px;
      padding: 0 24px;
  `,
);

export const TableRow = styled(Grid)<{ compactview?: string }>(({ theme, compactview }) => ({
  [theme.breakpoints.up('xs')]: {
    height: '192px',
    padding: '14px',
  },
  [theme.breakpoints.up('md')]: {
    height: '95px',
    padding: '0px 24px',
  },
  marginBottom: compactview === '1' ? 0 : '10px',
  borderBottom: compactview === '1' ? '1px solid rgba(0, 0, 0, 0.1)' : 'none',
  border: compactview === '1' ? 'none' : '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: compactview === '1' ? 0 : '8px',
}));

export const TableData = styled(Grid)({});

export const CSPLogo = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '65px',
    height: '46px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '65px',
    height: '49px',
  },
  [theme.breakpoints.up('md')]: {
    width: '65px',
    height: '40px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '83px',
    height: '59px',
  },
  border: '0.5px solid #aeaeae',
  borderRadius: '10px',
  '> img': {
    height: '100%',
  },
}));

export const ChargeableWeight = styled(Grid)`
  font-weight: 400;
  font-size: 14px;
  color: #494949;
  border: 1px solid #e7e7e7;
  background: #f7f7ff;
  padding: 10px 16px;
  border-radius: 4px;
  font-weight: 500;
  width: fit-content;
`;

export const BookBtn = styled(SbButton)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.up('xs')]: {
    height: '36px',
    background: theme.palette.primary.contrastText,
    fontSize: '14px',
  },
  [theme.breakpoints.up('md')]: {
    height: '44px',
    fontSize: '16px',
  },
}));

export const SbChip = styled(Chip)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    height: '16px',
    '& .MuiChip-icon': {
      height: '12px',
      width: '12px',
    },
  },
  [theme.breakpoints.up('md')]: {
    height: '28px',
    '& .MuiChip-icon': {
      height: '16px',
      width: '16px',
    },
  },
  marginLeft: theme.spacing(2),
}));
