import React, { useEffect, useState } from 'react';
// import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import { styled } from '@mui/system';
import { Checkbox, Grid, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

// import Title from '../../components/Title';
import Loader from '@Components/Loader';
import FilterSearch from '@Components/Filter/Search';
// import FilterSelect from '@Components/Filter/Dropdown';
import SbButton from '@Components/Button';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import ModalError from '@Components/Modal/error';

import { AppDispatch, RootStateType } from '@Store';
import { getDraftShipmentList, deleteDraftShipment } from '@Reducers/DraftShipment/actions';
import moment from 'moment';
import { UNIT, calculateWeights } from '@Services/calculateVolume';
import { UNIT_OF_MEASUREMENT } from '../GetRatesV2/Constant';
import { setDraftShipmentId } from '@Reducers/DraftShipment';
import { useNavigate } from 'react-router-dom';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { AccountIdType } from '@Reducers/User/Type';

const TableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 0px',
  display: 'grid',
  // gridTemplateColumns: '510px 1fr 350px fit-content(140px) fit-content(140px) 140px',
  gridTemplateColumns: 'auto auto 117px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 0,
    gridTemplateColumns: 'auto auto 117px',
  },
}));

const SearchFilterList = [{ label: 'Email ID', value: 'email' }];

function ShipmentTable(props: {
  handleDelete: (id: string) => void;
  selectedShipment: string[];
  onSelect: (id: string[]) => void;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const savedShipmentList = useSelector((state: RootStateType) => state.DraftShipment.lists);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isSavedDeleteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Draft_Shipments.saved_shipments,
    PERFORM_ACTION.write,
  );

  const handleSelectAll = () => {
    if (props.selectedShipment.length !== savedShipmentList.length) {
      const allShipmentIds = savedShipmentList.map(shipment => shipment._id);
      props.onSelect(allShipmentIds);
    } else {
      props.onSelect([]);
    }
  };

  const handleSelect = (id: string) => {
    if (!props.selectedShipment.includes(id)) {
      props.onSelect([...props.selectedShipment, id]);
    } else {
      const newSelected = props.selectedShipment.filter(i => i !== id);
      props.onSelect(newSelected);
    }
  };

  const handleRowClick = (id: string, onbehalf: UserObj | undefined, accountId: AccountIdType) => {
    navigate(`/shipment/create-single`, { state: { userId: onbehalf, accountId: accountId } });
    dispatch(setDraftShipmentId({ id }));
  };

  return (
    <>
      <div
        style={{
          display: 'block',
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          maxWidth: '100%',
        }}
      >
        <div
          style={{
            minWidth: '600px',
          }}
        >
          <SBTable>
            <TableHead>
              <TableRow>
                <TableHeadCell>
                  <Checkbox
                    style={{ color: 'inherit' }}
                    onChange={handleSelectAll}
                    checked={savedShipmentList.length === props.selectedShipment.length}
                  />
                </TableHeadCell>
                <TableHeadCell>Date</TableHeadCell>
                <TableHeadCell>Account</TableHeadCell>
                <TableHeadCell>Shipper</TableHeadCell>
                <TableHeadCell>Consignee</TableHeadCell>
                <TableHeadCell>Weight</TableHeadCell>
                <TableHeadCell>Action</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(savedShipmentList) && savedShipmentList.length ? (
                savedShipmentList.map((shipment, _index) => {
                  const weightUnit = shipment?.package?.unitOfMeasurement
                    ? UNIT_OF_MEASUREMENT?.[shipment?.package?.unitOfMeasurement as keyof typeof UNIT_OF_MEASUREMENT]
                        ?.weightUnit
                    : UNIT_OF_MEASUREMENT['kg-cm'].weightUnit;

                  const weight = calculateWeights({
                    boxes: shipment?.package?.box || [],
                    unit: weightUnit as UNIT,
                  });
                  return (
                    <SBTableRow
                      key={shipment?._id}
                      className={props.selectedShipment.includes(shipment._id) ? 'selected' : ''}
                      onClick={() => handleRowClick(shipment?._id, shipment?._onbehalf, shipment?._accountId)}
                    >
                      <TableRowCell>
                        <Checkbox
                          onChange={() => handleSelect(shipment._id)}
                          checked={props.selectedShipment.includes(shipment._id)}
                        />
                      </TableRowCell>
                      <TableRowCell className="md-text">
                        <div>{moment(shipment.createdAt).format('DD/MM/YYYY')}</div>
                      </TableRowCell>
                      <TableRowCell className="md-text">
                        <div className=" md-text flex sb-gap-1">
                          <span className="font-semibold">Account No. :</span>
                          <span>{shipment.accountNumber}</span>
                        </div>
                        <div className=" md-text flex sb-gap-1">
                          <span className="font-semibold">Email :</span>
                          <span className="truncate">{shipment._userId.email}</span>
                        </div>
                      </TableRowCell>
                      <TableRowCell className="md-text">
                        <div className="font-semibold md-text">{shipment?.shipper?.name}</div>
                        <div className="sm-text">
                          {shipment?.shipper?.city} - {shipment?.shipper?.pincode}
                        </div>
                        <div className="sm-text">{shipment?.shipper?.country}</div>
                      </TableRowCell>
                      <TableRowCell className="md-text capitalize">
                        <div className="font-semibold md-text">{shipment?.consignee?.name}</div>
                        <div className="sm-text">
                          {shipment?.consignee?.city} - {shipment?.consignee?.pincode}
                        </div>
                        <div className="sm-text">{shipment?.consignee?.country}</div>
                      </TableRowCell>
                      <TableRowCell className="md-text" align="center">
                        {weight.totalChargeableWeight || 0} {weightUnit}
                      </TableRowCell>
                      <TableRowCell
                        align="center"
                        className="md-text uppercase cursor-pointer"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        {isSavedDeleteAllowed && (
                          <div>
                            <DeleteIcon onClick={() => props.handleDelete(shipment._id)} />
                          </div>
                        )}
                      </TableRowCell>
                    </SBTableRow>
                  );
                })
              ) : (
                <SBTableRow>
                  <TableRowCell colSpan={8} className="md-text text-center">
                    <div>No data to show</div>
                  </TableRowCell>
                </SBTableRow>
              )}
            </TableBody>
          </SBTable>
        </div>
      </div>
    </>
  );
}

export default function SavedList() {
  // const dispatch = useDispatch<AppDispatch>();
  const [filterSelectValue, setFilterSelectValue] = useState('email');
  const [filterText, setFilterText] = useState('');
  const [showDelete, setShowDelete] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState<string[]>([]);

  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector((state: RootStateType) => state.DraftShipment?.loading);
  useEffect(() => {
    dispatch(getDraftShipmentList({ currentPage: 1, perPage: 0 }));
  }, []);

  const handleDelete = (id: string) => {
    setShowDelete(!showDelete);
    setSelectedShipment([id]);
  };

  const handleClose = () => {
    setShowDelete(false);
  };

  const deleteShipment = () => {
    if (selectedShipment.length) {
      dispatch(
        deleteDraftShipment({
          payload: { deleteId: selectedShipment },
          queryParams: { currentPage: 1, perPage: 0 },
          showModal: true,
        }),
      ).then(response => {
        if (response.meta.requestStatus === 'fulfilled') setShowDelete(false);
      });
    }
  };

  return (
    <Loader loading={loading} overly>
      <>
        <DeleteModalConformation handleClose={handleClose} showDelete={showDelete} deleteShipment={deleteShipment} />
        <TableActionContainer>
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={SearchFilterList}
            selectValue={filterSelectValue}
            textValue={filterText}
            onTextChange={e => {
              setFilterText(e.target.value);
            }}
            onTextSearch={() => {
              dispatch(
                getDraftShipmentList({
                  currentPage: 1,
                  perPage: 0,
                  searchKey: filterSelectValue,
                  searchValue: filterText,
                }),
              );
            }}
            onTextClear={() => {
              setFilterText('');
              dispatch(getDraftShipmentList({ currentPage: 1, perPage: 0 }));
            }}
          />
          <div></div>
          <SbButton
            className="xs-text ml-1"
            variant="outlined"
            sx={{ borderRadius: '8px !important', padding: '8px !important' }}
            disabled={!selectedShipment.length}
            onClick={() => {
              setShowDelete(true);
            }}
          >
            Delete All
          </SbButton>
        </TableActionContainer>
        <ShipmentTable
          selectedShipment={selectedShipment}
          onSelect={shipment => {
            setSelectedShipment(shipment);
          }}
          handleDelete={handleDelete}
        />
      </>
    </Loader>
  );
}
const Title = styled(Typography)(({ theme }) => ({
  // fontSize: '32px',
  maxWidth: '500px',
  marginTop: theme.spacing(4),
}));

function DeleteModalConformation(props: { showDelete: boolean; handleClose: () => void; deleteShipment: () => void }) {
  return (
    <ModalError
      icon="/images/Boxes/Delete.svg"
      btnText="Delete"
      title=""
      onClose={props.handleClose}
      subTitle="Are you sure you want to delete this shipment?"
      open={props.showDelete}
    >
      <>
        <Title className="font-medium xl-text">Are you sure you want to delete this shipment?</Title>
        <Grid container gap={2} justifyContent="center">
          <SbButton
            onClick={props.deleteShipment}
            variant="contained"
            className="mt-4 rounded-full"
            style={{ height: '44px', width: '148px' }}
          >
            Yes
          </SbButton>
          <SbButton
            onClick={props.handleClose}
            variant="outlined"
            className="mt-4 rounded-full"
            style={{ height: '44px', width: '148px' }}
          >
            No
          </SbButton>
        </Grid>
      </>
    </ModalError>
  );
}
