import SbButton from '@Components/Button';
import Divider from '@Components/Divider';
import SBDropdown from '@Components/Dropdown';
import SBCountryDropdown from '@Components/Dropdown/Country';
import Image from '@Components/Image';
import SbTextField from '@Components/Textfield';
import { CSP_TYPES } from '@Constants/Shipment';
import { setDocumentUploadModalOpen } from '@Reducers/AdminShipment';
import { DHLInformationType, vendorAccountType } from '@Reducers/AdminShipment/Type';
import { postDhlVerifyOtp } from '@Reducers/AdminShipment/actions';
import { getCarrierSettings } from '@Reducers/User/actions';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OptionType } from '../../../../../CreateAccount/CarrierAccess/Type';
import OtpModal from './OtpModal';
import UploadDHLDoc from './UploadDHLDoc';
import { getFedExDetails } from '@Reducers/AdminShipment/actions';
import {
  GSTType,
  Payor,
  ShipperType,
  ShipmentType,
  onForward,
  DutyPaymentMode,
} from '../../../../../ViewSingleShipment/TrackingDetails/UserDHL/constants';
import { dhlInformationSchema } from '../../../../../ViewSingleShipment/TrackingDetails/UserDHL/schema';
import { setIsWarehouseSelectedINFedX } from '@Reducers/Warehouse';
import { downloadCarrierLabel } from '@Reducers/ShipmentHistory/actions';
import DatePicker from '@Components/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

interface propsTypes {
  onClickForward: () => void;
  onClose?: () => void;
  initialStates: DHLInformationType;
  isEditable?: boolean;
  showFedExDetails: boolean;
  btnText: string;
  isCodeMatched: boolean;
}

const DHLInformation = (props: propsTypes) => {
  const { onClickForward, onClose, initialStates, isEditable, showFedExDetails, btnText, isCodeMatched } = props;
  const dispatch = useDispatch<AppDispatch>();
  const [openDocumentModalDHL, setDocumentModalDHL] = useState(false);
  const [isDocument, setIsDocument] = useState(false);
  const { dhl: dhlSettings } = useSelector((state: RootStateType) => state.user.carrierSetting);
  const { isWarehouseSelectedINFedX } = useSelector((state: RootStateType) => state.Warehouse);
  const [formValues, setFormValues] = useState({});
  const { shipmentById, shipmentConnectOnWarehouse, vendersAccount, dhlOtpPostShipmentPayload, fedExDetails } =
    useSelector((state: RootStateType) => state.adminShipment);
  const [otpModalOpen, setOtpModalOpen] = useState<boolean>(false);
  const [otpDetails, setOtpDetails] = useState({
    otp: '',
    token: dhlOtpPostShipmentPayload || '',
  });
  const carrierIntegration = useSelector((state: RootStateType) => state.carrierIntegration.carriers);

  const handleOtpModalClose = () => {
    setOtpModalOpen(false);
    setOtpDetails({
      otp: '',
      token: '',
    });
  };

  const handleOtpModalOpen = () => {
    setOtpModalOpen(true);
    setDocumentModalDHL(false);
  };

  const { values, handleChange, setFieldValue, errors, handleSubmit } = useFormik<DHLInformationType>({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ...initialStates,
    },
    validationSchema: dhlInformationSchema,
    onSubmit(values) {
      setIsDocument(shipmentById?._packageId.isDocument || false);
      setFormValues(values);
      if (isWarehouseSelectedINFedX === '' || isWarehouseSelectedINFedX === 'false') {
        dispatch(setIsWarehouseSelectedINFedX('false'));
      } else {
        if (shipmentById?._packageId.isDocument) {
          onForward({
            values,
            shipmentNumber: shipmentById?.shipmentNumber || '',
            dispatch,
            nextFunc: onClickForward,
            handleOtpModalOpen,
          });
        }
        setDocumentModalDHL(true);
      }
    },
  });

  useEffect(() => {
    if (shipmentConnectOnWarehouse?.length === 0 && carrierIntegration.length > 0 && isCodeMatched) {
      dispatch(
        getFedExDetails({
          shipmentNumber: shipmentById?.shipmentNumber || '',
          type: 'D-D',
          csp: shipmentById?.buyingPricing?.code || '',
        }),
      );
    }
    if (
      shipmentConnectOnWarehouse &&
      shipmentConnectOnWarehouse?.length > 0 &&
      carrierIntegration.length > 0 &&
      isCodeMatched
    ) {
      dispatch(
        getFedExDetails({
          shipmentNumber: shipmentById?.shipmentNumber || '',
          type: 'W-D',
          csp: shipmentById?.buyingPricing?.code || '',
        }),
      );
    }
    if (shipmentById?._accountId.accountNumber) {
      dispatch(
        getCarrierSettings({
          csp: CSP_TYPES.fedex.value,
          accountNumber: shipmentById?._accountId.accountNumber,
        }),
      );
    }
  }, [shipmentConnectOnWarehouse?.length, shipmentById?._accountId.accountNumber]);

  const accountOptions = useMemo(
    () =>
      vendersAccount?.map((item: vendorAccountType) => ({
        value: `${item.accountNo}-${item._id}`,
        display: item.name,
      })),
    [vendersAccount],
  );
  return (
    <>
      {openDocumentModalDHL && !isDocument && (
        <UploadDHLDoc
          formValues={formValues}
          onClickForward={onClickForward}
          handleOtpModalOpen={handleOtpModalOpen}
          setFormValues={setFormValues}
          shipmentNumber={shipmentById?.shipmentNumber || ''}
          csp={values?.csp}
          type={values?.type}
          open={openDocumentModalDHL}
          handleClose={() => {
            // dispatch(setDocumentUploadModalOpen(false));
            setDocumentModalDHL(false);
            setFieldValue('attachedDocuments', []);
          }}
          fedExDetails={fedExDetails}
          // dragDropWrapperOnClickRegister
        />
      )}
      {showFedExDetails && (
        <Paper
          variant="outlined"
          sx={{
            my: '2rem',
            p: '2rem',
          }}
        >
          <Grid container item xs={12}>
            <Grid container item mb={3}>
              <Grid item xs={12} md={6}>
                <Typography className="font-medium l-text pb-3">DHL Information</Typography>{' '}
              </Grid>
              {initialStates?.response && (
                <Grid item xs={12} md={6} container justifyContent={'flex-end'}>
                  <SbButton
                    variant="contained"
                    sx={{ borderRadius: `4px !important` }}
                    startIcon={<Image src={'/images/upload-cloud.svg'} alt={'documents'} width={26} />}
                    onClick={() => {
                      dispatch(setDocumentUploadModalOpen(true));
                    }}
                  >
                    Forward Shipment Documents
                  </SbButton>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="accountNo"
                    label={`DHL Account`}
                    required
                    placeholder="Select"
                    name="accountNo"
                    value={[
                      dhlSettings?.accountNo?.length
                        ? dhlSettings?.accountNo?.find(
                            (item: OptionType) => values?.accountNo && item?.value?.includes(values?.accountNo),
                          )?.value || ''
                        : accountOptions?.find(item => values?.accountNo && item.value?.includes(values?.accountNo))
                            ?.value || '',
                    ]}
                    options={dhlSettings?.accountNo?.length ? dhlSettings?.accountNo : accountOptions}
                    onChange={value => setFieldValue('accountNo', value[0])}
                    error={errors.accountNo}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBCountryDropdown
                    disabled={!isEditable}
                    required
                    label="Country of Manufacture"
                    value={values?.countryOfManufacture}
                    // options={dhlSettings?.countryOfManufacture?.length ? dhlSettings?.countryOfManufacture : undefined}
                    options={undefined}
                    onChange={value => {
                      setFieldValue('countryOfManufacture', value[0]);
                    }}
                    error={errors?.countryOfManufacture}
                  />
                </Grid>
              </Grid>
              <Grid container item columnSpacing={10} rowSpacing={2}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    id="paymentMode"
                    required
                    label={`Payment Mode`}
                    placeholder="Select"
                    name="paymentMode"
                    value={[values.paymentMode]}
                    onChange={value => {
                      setFieldValue('paymentMode', value[0]);
                    }}
                    error={errors.paymentMode}
                    options={dhlSettings?.paymentMode?.length ? dhlSettings?.paymentMode : Payor}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    required
                    id="ShipperType"
                    label={`Shipper Type`}
                    placeholder="Select"
                    name="ShipperType"
                    value={[values.ShipperType]}
                    onChange={value => {
                      setFieldValue('ShipperType', value[0]);
                    }}
                    error={errors.ShipperType}
                    options={dhlSettings?.ShipperType?.length ? dhlSettings?.ShipperType : ShipperType}
                  />
                </Grid>
              </Grid>

              <Grid container item columnSpacing={10} rowSpacing={2} sx={{ display: 'flex' }}>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    required
                    id="gstinType"
                    label={`Id Type`}
                    placeholder="Select"
                    name="gstinType"
                    value={[values.GSTINType]}
                    onChange={value => {
                      setFieldValue('GSTINType', value[0]);
                    }}
                    error={errors.GSTINType}
                    options={dhlSettings?.GSTINType?.length ? dhlSettings?.GSTINType : GSTType}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SbTextField
                    disabled={!isEditable}
                    label={`Id No`}
                    required
                    value={values.GSTINNumber}
                    placeholder={`12345678`}
                    onChange={handleChange}
                    name="GSTINNumber"
                    error={errors.GSTINNumber}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SBDropdown
                    disabled={!isEditable}
                    required
                    id="shipmentType"
                    label={`Shipment Type`}
                    placeholder="Select"
                    name="shipmentType"
                    value={[values.ShipmentType]}
                    onChange={value => {
                      setFieldValue('ShipmentType', value[0]);
                    }}
                    error={errors.ShipmentType}
                    options={dhlSettings?.ShipmentType?.length ? dhlSettings?.ShipmentType : ShipmentType}
                  />
                </Grid>

                {!isDocument && (
                  <Grid item xs={12} md={6}>
                    <SBDropdown
                      disabled={!isEditable}
                      // required
                      id="dutyPaymentMode"
                      label={`Duty Payment Mode`}
                      placeholder="Select"
                      name="dutyPaymentMode"
                      value={[values.dutyPaymentMode]}
                      onChange={value => {
                        setFieldValue('dutyPaymentMode', value[0]);
                      }}
                      error={errors.dutyPaymentMode}
                      options={dhlSettings?.DutyPaymentMode?.length ? dhlSettings?.DutyPaymentMode : DutyPaymentMode}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label="Invoice Date"
                    value={values.gstInvNoDate ? dayjs(values.gstInvNoDate) : null}
                    maxDate={dayjs()}
                    handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
                      setFieldValue('gstInvNoDate', date);
                    }}
                    error={errors.gstInvNoDate}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SbTextField
                    disabled={!isEditable}
                    label={`Invoice Number`}
                    value={values.gstInvNo}
                    placeholder={`12345678`}
                    onChange={handleChange}
                    name="gstInvNo"
                    error={errors.gstInvNo}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider className="mt-3 mb-3" />
            <Grid container item xs={12} md={12} lg={12} columnSpacing={4} rowSpacing={4}>
              <Grid container item sx={{ gap: '1rem' }}>
                <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                  <SbButton
                    disabled={!isEditable}
                    variant="contained"
                    sx={{ borderRadius: `4px !important` }}
                    className="w-full"
                    onClick={_event => {
                      handleSubmit();
                    }}
                  >
                    {btnText}
                  </SbButton>
                </Grid>
                {onClose && (
                  <Grid item xs={12} sm={5} md={2.6} lg={2.7}>
                    <SbButton
                      sx={{ borderRadius: `4px !important` }}
                      className="w-full"
                      variant="outlined"
                      onClick={onClose}
                    >
                      Cancel
                    </SbButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}

      <OtpModal
        open={otpModalOpen}
        onClose={handleOtpModalClose}
        onConfirm={() => {
          dispatch(
            postDhlVerifyOtp({
              shipmentNumber: `${shipmentById?.shipmentNumber}`,
              body: {
                payload: { ...values, otp: otpDetails.otp },
                Postshipment_Payload: otpDetails.token,
              },
            }),
          ).then((val: { type: string }) => {
            if (val.type === 'postDhlVerifyOtp/fulfilled') {
              onClickForward();
              handleOtpModalClose();
            }
          });
        }}
        otpValue={otpDetails?.otp}
        setOtpValue={(value: string) => {
          setOtpDetails({
            token: dhlOtpPostShipmentPayload || '',
            otp: value || '',
          });
        }}
      />

      {initialStates?.response && (
        <Grid container item>
          <Grid
            item
            xs={'auto'}
            border={'1px solid'}
            className={`border-grey font-medium md-text p-2`}
            borderRadius={1}
          >
            This shipment has been forwarded.
            <Box
              component={'span'}
              className="font-medium md-text ml-1"
              sx={{
                color: '#485fc7',
                textDecoration: 'underline',
              }}
              onClick={() => {
                if (shipmentById?.shipmentNumber) {
                  dispatch(downloadCarrierLabel(shipmentById?.shipmentNumber));
                }
              }}
            >
              Download the carrier AWB
            </Box>
            {/* <Link
              className="font-medium md-text ml-1"
              to={`${CONFIG.API_URI}/shipment/label/getLabel?shipmentNumber=${shipmentById?.shipmentNumber}`}
              target="_blank"
              download={`${Date.now()}.pdf`}
            >
              Download the carrier AWB
            </Link> */}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DHLInformation;
