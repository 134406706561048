import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox, Grid, SelectChangeEvent, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import { defaultPickupState, defaultProductState } from '../CreateShipment/constants';

import Loader from '../../components/Loader';
import SbButton from '../../components/Button';
import PickupDrop from '../../components/Shipment/PickupDrop';
import Divider from '../../components/Divider';
import SBDropdown from '../../components/Dropdown';
import Image from '../../components/Image';
import ErrorModal from '../../components/Modal/error';
import Modal from '../../components/Modal';

import { TableHeadCell, TableRowCell } from '../../components/Table/TableCell';
import { SBTableRow } from '../../components/Table/TableRow';
import { SBTable } from '../../components/Table/Table';
import {
  BoxType,
  BulkShipmentType,
  GetShipmentPayloadType,
  PickupDataType,
  PickupType,
  createShipmentContextType,
  userDetailsType,
} from '../../types/shipment';
import { DropdownOptionType } from '../../components/Dropdown/Type';
import BoxList from '../CreateShipment/ShipmentDetails/BoxList';
import ProductDescription from '../CreateShipment/ShipmentDetails/ProductDescription';
import CreateShipmentContext from '../../context/CreateShipment';
import { defaultBoxState } from '../CreateShipment/constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../store';
import { placeBulkShipment } from '../../store/reducers/Shipment/actions';
import { getShipmentPayload } from '../../services/getShipmentPayload';
import { getServiceList } from '@Reducers/CarrierAndService';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
// import { AddressType } from './Type';
// import useAccountLookup from '@Hook/useAccountLookup';

const TABLE_HEADER_COLS = ['Sr. No.', 'Shipper', 'Consignee', 'Weight', 'Action', 'Carrier preference'];

const initErrorModal = {
  open: false,
  title: '',
  subTitle: '',
};

const defaultCarrierPreference = [
  { display: 'Fastest', value: 'fastest' },
  { display: 'Cheapest', value: 'cheapest' },
];

function ShipmentDetailsSummary() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const { pickupTimeSlots } = shipmentSetting || {};

  const {
    shipment: {
      loading: shipmentLoading,
      // redirectToSuccess,
      error: { isError, errorDetails, msg },
    },
  } = useSelector((state: RootStateType) => state);

  const { boxes, setBoxes, setSelectedBox, setSelectedProduct, userId } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;

  const [isBoxModal, setBoxModal] = useState<boolean>(false);
  const [selectedShipmentIndex, setSelectedShipmentIndex] = useState<number>(-1);
  const [shipments, setShipments] = useState<BulkShipmentType[]>([]);
  const [selectedShipmentCount, setSelectedShipmentCount] = useState<number>(0);
  const [pickupType, setPickupType] = useState<PickupDataType>({ ...defaultPickupState, date: dayjs() });
  const [errorModal, setErrorModal] = useState<{ open: boolean; title: string; subTitle: string | ReactNode }>({
    ...initErrorModal,
  });
  const [carrierPreferenceList, setCarrierPreferenceList] = useState([...defaultCarrierPreference]);
  const [carrierPreference, setCarrierPreference] = useState<string>('');

  const loading = shipmentLoading || false;
  const { serviceDropdownList, serviceListHashById } = useSelector((state: RootStateType) => state.carrierAndService);
  const countryHashById = useSelector((state: RootStateType) => state.global.countriesObj);
  const { warehouseListForShipmentOptionsHashById } = useSelector((state: RootStateType) => state.Warehouse);

  useEffect(() => {
    const _shipments = location.state;
    if (!_shipments) return navigate('/shipment/create-bulk/sheet');
    setSelectedShipmentCount(_shipments.length);
    setShipments(_shipments);
    dispatch(getServiceList({}));
  }, []);

  useEffect(() => {
    if (serviceDropdownList.length) {
      setCarrierPreferenceList([
        ...defaultCarrierPreference,
        { display: 'Others', value: 'other', disable: true },
        ...serviceDropdownList,
      ]);
    }
  }, [serviceDropdownList]);

  useEffect(() => {
    if (isError) {
      setErrorModal({ open: true, title: 'Oops!', subTitle: errorDetails || msg || 'Something went wrong' });
    }
  }, [isError]);

  // useEffect(() => {
  //   if (redirectToSuccess) {
  //     navigate('/shipment/create-bulk/success', {
  //       state: { ...pickupType, date: dayjs(pickupType.date).format('DD/MM/YYYY') },
  //     });
  //   }
  // }, [redirectToSuccess]);

  const onNext = async () => {
    const orgSlots = pickupTimeSlots?.slots?.length;

    if (pickupType.type === 'dropOff' && !pickupType.warehouse)
      return setErrorModal({ open: true, title: 'Oops!', subTitle: 'Please choose the drop-off warehouse.' });
    else if (pickupType.type === 'pickUp' && (!pickupType.date || (orgSlots && !pickupType.timeSlot)))
      return setErrorModal({ open: true, title: 'Oops!', subTitle: 'Please choose a pickup date and time slot.' });

    const isDropOff = pickupType.type === 'dropOff';
    // let pickupDate: Dayjs, timeSlot: string;
    let pickupDate: string, timeSlot: string;
    const warehouseId = pickupType.warehouse;
    const warehouseAddress = pickupType.selectedWearhouseAddress;

    let payload: GetShipmentPayloadType = { shipments, isDropOff };

    payload.shipments.forEach(shipment => {
      if (pickupType.date) {
        shipment.package.pickupDate = dayjs(pickupType.date).toISOString();
      } else {
        shipment.package.pickupDate = dayjs().toISOString();
      }
      // console.error('Invalid pickupType date:', pickupType?.date);

      // ------------------------------------------------------------------------
      // if (pickupType?.date && dayjs(pickupType.date).isValid()) {
      //   // shipment.package.pickupDate = dayjs(pickupType.date).toISOString() || dayjs().toISOString();

      //   shipment.package.pickupDate = dayjs(pickupType.date) || dayjs();
      //   console.info(' shipment.package.pickupDate --> ', shipment.package.pickupDate);
      // } else {
      //   // console.error('Invalid pickupType date:', pickupType?.date);
      // }
    });

    if (!isDropOff) {
      // pickupDate = pickupType.date || dayjs();
      pickupDate = dayjs(pickupType?.date).toISOString() || dayjs().toISOString();
      timeSlot = pickupType.timeSlot;
      payload = { ...payload, pickupDate, timeSlot };
    }
    try {
      const res = await dispatch(
        placeBulkShipment([getShipmentPayload({ ...payload, warehouseId, warehouseAddress }), userId]),
      );
      if (res.payload && 'msg' in res.payload) {
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            subTitle: res.payload.msg || 'Bulk shipment created',
            title: 'Success',
            onConfirm: () => {
              navigate('/shipment/bulk-summary');
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
      }
    } catch (err) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          subTitle: 'Something went wrong',
          title: 'error',
        }),
      );
      console.error(err);
    }
  };

  const onCancel = () => {
    navigate('/shipment/create-bulk/sheet');
  };

  const onSelectShipment = (shipmentIndex: number, isChecked: boolean) => {
    const _shipmentCopy = [...shipments];
    _shipmentCopy[shipmentIndex]['isSelected'] = isChecked;
    setShipments([..._shipmentCopy]);
    setSelectedShipmentCount(isChecked ? selectedShipmentCount + 1 : selectedShipmentCount - 1);
  };

  const checkAllShipments = (isChecked: boolean) => {
    const _shipmentCopy = shipments.map(currShip => ({ ...currShip, isSelected: isChecked }));
    setShipments([..._shipmentCopy]);
    setSelectedShipmentCount(isChecked ? _shipmentCopy.length : 0);
  };

  const onChangeCarrierProvider = ([carrierPreference]: string[]) => {
    const _shipmentCopy = shipments.map(currShip => {
      if (currShip.isSelected) currShip.carrierPreference = carrierPreference;
      return { ...currShip };
    });
    setCarrierPreference(carrierPreference);
    setShipments([..._shipmentCopy]);
  };

  const handlePickupTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPickupType({ ...defaultPickupState, type: value as PickupType });
  };

  const handleTimeSlotChange = (
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
  ) => {
    const value = event.target.value;
    setPickupType({ ...pickupType, timeSlot: value });
  };

  const handlePickupDateChange = (date: Dayjs) => {
    setPickupType({ ...pickupType, date: date });
  };
  const handleWarehouseChange = (
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    _event: SelectChangeEvent<string>,
  ) => {
    // const selectedWearhouseAddress: AddressType | undefined =
    //   _selectedObj.length > 0 ? (_selectedObj[0]?.metadata as AddressType | undefined) : undefined;

    const [selectedWearhouse] = _selected;
    if (selectedWearhouse) {
      const selectedWarehouse = warehouseListForShipmentOptionsHashById[selectedWearhouse];
      const coAttention = selectedWarehouse.coAttention;
      const [telephoneCode, number] = coAttention.contact;
      const countryId = selectedWarehouse._addressId.country;
      const warehouseAddress = {
        ...selectedWarehouse._addressId,
        country: countryHashById?.[countryId]?.name || '',
        name: selectedWarehouse.name,
        phone: { telephoneCode, number, name: coAttention.name },
        email: coAttention.email,
      };

      setPickupType({
        ...pickupType,
        warehouse: selectedWearhouse,
        selectedWearhouseAddress: warehouseAddress,
      });
    }
  };

  const onCloseModal = () => {
    setErrorModal({ ...initErrorModal });
  };

  const onBoxModalOpen = (index: number) => {
    const box: BoxType[] =
      shipments[index].package.box?.map(currBox => ({ ...currBox, products: currBox.products.map(x => ({ ...x })) })) ||
      [];
    setBoxes(box);
    setSelectedBox(box[0]);
    setBoxModal(true);
    setSelectedShipmentIndex(index);
    setSelectedProduct({ ...defaultProductState, id: `${box[box.length - 1].products.length}` });
  };

  const onBoxModalClose = () => {
    setBoxModal(false);
    setTimeout(() => {
      setSelectedShipmentIndex(-1);
      setBoxes([]);
      setSelectedBox({ ...defaultBoxState });
      setSelectedProduct({ ...defaultProductState });
    }, 1);
  };

  const onSaveBoxChanges = () => {
    const _shipmentsCopy = [...shipments];
    _shipmentsCopy[selectedShipmentIndex].package.box = boxes;
    _shipmentsCopy[selectedShipmentIndex].package.totalBoxWeight = _shipmentsCopy[
      selectedShipmentIndex
    ].package.box.reduce((prev, curr) => prev + parseFloat(curr.weight.toString()), 0);

    setShipments(_shipmentsCopy);
    onBoxModalClose();
  };

  return (
    <>
      <Modal
        open={isBoxModal}
        dialogContainerSx={{ maxWidth: 'unset', minWidth: { xs: 'unset', lg: '800px' }, maxHeight: '80vh' }}
        onClose={onBoxModalClose}
      >
        <Grid container justifyContent="center">
          <Typography className="l-text font-medium ">Box Details</Typography>
        </Grid>
        <Grid container className="my-2" sx={{ maxHeight: 500, overflowY: 'scroll' }}>
          <Grid item lg={4} xl={3} md={3} sm={12} xs={12}>
            <BoxList />
          </Grid>
          <Grid item lg={8} xl={9} md={9} sm={12} xs={12}>
            <ProductDescription />
          </Grid>
        </Grid>
        <div>
          <Grid container justifyContent="center" className="py-2" gap={2}>
            <SbButton variant="outlined" onClick={onBoxModalClose}>
              Cancel
            </SbButton>
            <SbButton variant="contained" onClick={onSaveBoxChanges}>
              Save Changes
            </SbButton>
          </Grid>
        </div>
      </Modal>
      <Loader loading={loading} overly>
        <>
          <ErrorModal
            icon={'/images/Boxes/NoRatesFound.svg'}
            open={errorModal.open}
            title={errorModal.title}
            subTitle={errorModal.subTitle}
            onClose={onCloseModal}
            onConfirm={onCloseModal}
            btnText="Go back"
          />
          <Grid container item xs={12} lg={8} xl={6} sx={{ background: '#fff' }}>
            <PickupDrop
              handlePickupTypeChange={handlePickupTypeChange}
              pickupType={pickupType.type}
              handleTimeSlotChange={handleTimeSlotChange}
              timeSlot={pickupType.timeSlot}
              handlePickupDateChange={handlePickupDateChange}
              pickupDate={pickupType.date}
              warehouse={pickupType.warehouse}
              handleWarehouseChange={handleWarehouseChange}
            />
          </Grid>
          <Divider style={{ margin: `32px 0` }} />
          <Grid container item sx={{ background: '#fff' }}>
            <Grid item xs={12} mb={3}>
              <Typography className="font-medium md-text">Service selection</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <SBDropdown
                id="carrierPreference"
                placeholder="Select carrier preference"
                options={carrierPreferenceList}
                value={[carrierPreference]}
                onChange={onChangeCarrierProvider}
                disabled={!selectedShipmentCount}
              />
            </Grid>
            {[carrierPreference][0] !== 'cheapest' ? (
              <>
                <Grid container item xs={12} alignItems={'center'} mt={3}>
                  <Image src={'/images/shipment/warning.svg'} alt="warning" height={40} width={40} />
                  <Typography className="sm-text font-normal" ml={2}>
                    Cheapest carrier preference is selected for all shipments. You can update the carrier preference as
                    per your requirement
                  </Typography>
                </Grid>
              </>
            ) : null}

            <Grid container item xs={12} mt={4} sx={{ overflowX: 'auto' }}>
              <SBTable sx={{ minWidth: 700, border: '1px solid #D9D9D9' }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableHeadCell>
                      {selectedShipmentCount ? selectedShipmentCount : <></>}
                      <Checkbox
                        style={{ color: 'inherit' }}
                        checked={shipments.length === selectedShipmentCount}
                        onChange={event => checkAllShipments(event.target.checked)}
                      />
                    </TableHeadCell>
                    {TABLE_HEADER_COLS.map((x, i) => (
                      <TableHeadCell key={`bulk-order-summary-table-head${i}`}>{x}</TableHeadCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shipments.map((currShipment, i) => (
                    <SBTableRow key={`bulk-order-summary-table-body${i}`}>
                      <TableRowCell align="center">
                        <div>
                          <Checkbox
                            color="primary"
                            onChange={e => onSelectShipment(i, e.target.checked)}
                            checked={!!currShipment.isSelected}
                          />
                        </div>
                      </TableRowCell>
                      <TableRowCell align="center">{i + 1}</TableRowCell>
                      <TableRowCell>
                        <UserInfo userObj={currShipment.shipper} />
                      </TableRowCell>
                      <TableRowCell>
                        <UserInfo userObj={currShipment.consignee} />
                      </TableRowCell>
                      <TableRowCell align="center" className="font-bold md-text lowercase">
                        {currShipment.package.totalBoxWeight} {currShipment.package.unitOfMeasurement.split('-')[0]}
                      </TableRowCell>
                      <TableRowCell>
                        <Typography
                          align="center"
                          color="primary"
                          sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                          onClick={() => onBoxModalOpen(i)}
                        >
                          Box details
                        </Typography>
                      </TableRowCell>
                      <TableRowCell>
                        <Typography align="center" className="capitalize">
                          {serviceListHashById[currShipment.carrierPreference]?.name ||
                            currShipment.carrierPreference ||
                            '--'}
                        </Typography>
                      </TableRowCell>
                    </SBTableRow>
                  ))}
                </TableBody>
              </SBTable>
            </Grid>
            <Grid container item mt={2} justifyContent="flex-end" spacing={4}>
              <Grid item xs={12} sm={3} md={2}>
                <SbButton variant="outlined" fullWidth className="rounded-full" onClick={onCancel}>
                  Cancel
                </SbButton>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <SbButton variant="contained" fullWidth className="rounded-full" onClick={onNext}>
                  Next
                </SbButton>
              </Grid>
            </Grid>
          </Grid>
        </>
      </Loader>
    </>
  );
}

function UserInfo(props: { userObj: userDetailsType }) {
  const { userObj } = props;
  return (
    <>
      <div className="p-3">
        <p className="font-semibold md-text">{userObj.name}</p>
        <p className="sm-text">
          {userObj.city} - {userObj.pincode}
        </p>
        <p className="sm-text">{userObj.country}</p>
      </div>
    </>
  );
}

export default ShipmentDetailsSummary;

// export default () => (
//   <CreateShipmentProvider>
//     <ShipmentDetailsSummary />
//   </CreateShipmentProvider>
// );
