import { userLogin } from '@Services/hasAdminAccess';
import { RootStateType } from '@Store';
import { Alert, Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

interface ShipmentErrorProps {
  sellingChargeMsgShow?: boolean;
}

export default function ShipmentError(props: ShipmentErrorProps) {
  const { shipmentError } = useSelector((state: RootStateType) => state.adminShipment);
  const { availableLimit } = useSelector((state: RootStateType) => state.Credit);
  const shipmentpayment = useSelector((state: RootStateType) => state.adminShipment.shipmentpayment);
  const { profile } = useSelector((state: RootStateType) => state.loginUser);
  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const isUser = userLogin();

  return (
    <Grid item my={'1rem'}>
      {isUser ? (
        <>
          {profile?._accountId.isPrepaid ? (
            <>
              {Number(shipmentpayment?.payableAmount) > 0 && (
                <Grid item xs={12} sx={{ height: '44px' }}>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="w-fit border-grey sb-text-error sm-text"
                    sx={{ height: '44px', py: '2px' }}
                  >
                    Payment for this pre-paid shipment is outstanding. Please collect the payment in order to proceed
                    with processing the shipment.
                  </Alert>
                </Grid>
              )}
            </>
          ) : (
            <>
              {availableLimit < 0 && (
                <Grid item xs={12} sx={{ height: '44px' }}>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="w-fit border-grey sb-text-error sm-text"
                    sx={{ height: '44px', py: '2px' }}
                  >
                    The account has crossed its credit limit. Ensure the customer's credit limit is adjusted before
                    processing the shipment!
                  </Alert>
                </Grid>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {shipmentById?._accountId?.isPrepaid ? (
            <>
              {Number(shipmentpayment?.payableAmount) > 0 && (
                <Grid item xs={12} sx={{ height: '44px' }}>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="w-fit border-grey sb-text-error sm-text"
                    sx={{ height: '44px', py: '2px' }}
                  >
                    Payment for this pre-paid shipment is outstanding. Please collect the payment in order to proceed
                    with processing the shipment.
                  </Alert>
                </Grid>
              )}
            </>
          ) : (
            <>
              {availableLimit < 0 && (
                <Grid item xs={12} sx={{ height: '44px' }}>
                  <Alert
                    variant="outlined"
                    severity="error"
                    className="w-fit border-grey sb-text-error sm-text"
                    sx={{ height: '44px', py: '2px' }}
                  >
                    The account has crossed its credit limit. Ensure the customer's credit limit is adjusted before
                    processing the shipment!
                  </Alert>
                </Grid>
              )}
            </>
          )}
        </>
      )}

      {props.sellingChargeMsgShow && shipmentError?.msg ? (
        <Grid item xs={12} sx={{ height: '44px' }} className="mt-2">
          <Alert
            variant="outlined"
            severity="error"
            className="w-fit border-grey sb-text-error sm-text"
            sx={{ height: '44px', py: '2px' }}
          >
            {shipmentError?.msg}
          </Alert>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}
