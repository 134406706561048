import React, { useState } from 'react';
import { BorderedWrapper } from './style';
import { Grid, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { RootStateType } from '../../store';

import moment from 'moment';
import DownloadShipmentLabelAndInvoice from '@Components/DownloadShipmentLabelAndInvoice';
import ShipmentKycUpload from '@Components/ShipmentKycUpload';
import { CSP_TYPES, hideDownloadCarrierAWBAction } from '@Constants/Shipment';

export function ShipmentHistoryActions() {
  const [documentUploadModalOpen, setDocumentUploadModalOpen] = useState(false);

  const { shipmentById: shipmentData } = useSelector((state: RootStateType) => state.shipment);
  const shipmentNumber = shipmentData?.shipmentNumber || '';

  return (
    <>
      <BorderedWrapper className="mb-2">
        <Grid container justifyContent="space-between">
          <Grid item justifyContent="space-around">
            <Typography style={{ fontSize: '32px' }} className="font-semibold">
              {shipmentNumber}
            </Typography>
            <Typography className="md-text">{moment(shipmentData?.createdAt).format('DD MMMM YYYY')}</Typography>
            <Typography className="md-text" textTransform="capitalize">
              Placed By: {shipmentData?._placedById?.name}
            </Typography>
          </Grid>

          {documentUploadModalOpen && (
            <ShipmentKycUpload
              shipmentNumber={shipmentNumber}
              open={documentUploadModalOpen}
              handleClose={() => setDocumentUploadModalOpen(false)}
            />
          )}

          <DownloadShipmentLabelAndInvoice
            handleView={() => setDocumentUploadModalOpen(true)}
            shipmentNumber={shipmentNumber}
            hideCarrierAWB={hideDownloadCarrierAWBAction(shipmentData)}
            carrierAWBIcon={
              shipmentData?.vendorShipmentModel &&
              (shipmentData?.buyingPricing?.code === CSP_TYPES.fedex.value ||
                shipmentData?.buyingPricing?.code === CSP_TYPES.dhlIndia.value ||
                shipmentData?.buyingPricing?.code === CSP_TYPES.stage.value ||
                shipmentData?.buyingPricing?.code === CSP_TYPES.dhl.value)
                ? shipmentData?.buyingPricing?.serviceImage
                : ''
            }
            // carrierAWBType={shipmentData?.buyingPricing?.code}
          />
        </Grid>
      </BorderedWrapper>
    </>
  );
}

export default ShipmentHistoryActions;
