import { PayloadAction } from '@reduxjs/toolkit';
import {
  AccountCreatedType,
  AccountListResponse,
  ErrorType,
  UserListResponse,
  UserObj,
  UserState,
  ForgotPasswordSuccess,
  fedexCarrierPayload,
  CarrierSettingsList,
  dhlCarrierPayload,
  aramexCarrierPayload,
} from './Type';
import { SuccessPayload } from '../../../types';
import {
  fedexInitialValues,
  dhlInitialValues,
  aramexIntialValues,
} from '../../../pages/CreateAccount/CarrierAccess/constant';

function handlePending(state: UserState) {
  state.loading = true;
  state.success = '';
  state.error = {
    msg: '',
    errorDetails: '',
    isError: false,
  };
}

function handleRejected(state: UserState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = { msg: action.payload?.msg || action.payload?.errorDetails || 'Error', isError: true };
}

function getUserListFulfilled(state: UserState, action: { payload: UserListResponse }) {
  const { lists, currentPage, perPage, totalCount } = action.payload;
  state.data.list = lists;
  state.data.totalCount = totalCount;
  state.data.currentPage = currentPage;
  state.data.perPage = perPage;
  state.loading = false;
}

function getAccountListFulfilled(state: UserState, action: { payload: AccountListResponse }) {
  state.accountList = action.payload.data;
  state.loading = false;
}

function createAccountFullfilled(state: UserState, action: PayloadAction<AccountCreatedType>) {
  console.log('createAccountFullfilled action.payload', action.payload);
  state.accountCreated = action.payload;
  state.loading = false;
}
function createAccountRejected(state: UserState, action: { payload: ErrorType | undefined }) {
  state.accountCreated = {
    isInserted: false,
    isEmailSent: false,
    isError: true,
    msg: action.payload?.msg || action.payload?.errorDetails || 'Unable to create user account.',
  };
  state.loading = false;
}

function resendInviteFulfilled(state: UserState, action: PayloadAction<AccountCreatedType>) {
  state.loading = false;
  state.accountCreated = action.payload;
}
function viewContractFulfilled(state: UserState, action: { payload: SuccessPayload }) {
  state.loading = false;
  state.success = action.payload.msg;
}
function deactivateUserAccountFulfilled(state: UserState, action: { payload: SuccessPayload }) {
  state.loading = false;
  state.success = action.payload.msg;
}
function suspendUserAccountFulfilled(state: UserState, action: { payload: SuccessPayload }) {
  state.loading = false;
  state.success = action.payload.msg;
}
function restoreUserAccountFulfilled(state: UserState, action: { payload: SuccessPayload }) {
  state.loading = false;
  state.success = action.payload.msg;
}
function getUserByIdFullfilled(state: UserState, action: { payload: UserObj }) {
  state.userDataById = action.payload;
  state.carrierSetting.fedex._accountId = action.payload._accountId?._id || '';
  state.carrierSetting.fedex.accountNumber = action.payload._accountId?.accountNumber || '';
  state.carrierSetting.dhl._accountId = action.payload._accountId?._id || '';
  state.carrierSetting.dhl.accountNumber = action.payload._accountId?.accountNumber || '';
  state.carrierSetting.aramex._accountId = action.payload._accountId?._id || '';
  state.carrierSetting.aramex.accountNumber = action.payload._accountId?.accountNumber || '';
  state.loading = false;
}

function updateAccountFullfilled(state: UserState, action: { payload: UserObj }) {
  state.userDataById = action.payload;
  state.isUserUpdated = true;
  state.loading = false;
}

function forgotPasswordFullfilled(state: UserState, action: { payload: ForgotPasswordSuccess }) {
  state.resetPassword = action.payload;
  state.loading = false;
}

function confirmForgotPasswordFullfilled(state: UserState, action: { payload: { msg: string } }) {
  state.resetPassword = action.payload;
  state.loading = false;
}

function isFedexPayload(payload: any): payload is fedexCarrierPayload {
  // return 'accountNumber' in payload && 'csp' in payload;
  return 'packagingType' in payload && 'csp' in payload;
}

function isDhlPayload(payload: any): payload is dhlCarrierPayload {
  return 'paymentMode' in payload && 'csp' in payload;
}
function isAramexPayload(payload: any): payload is aramexCarrierPayload {
  return 'paymentType' in payload && 'csp' in payload;
}

function getCarrierSettingsFullfilled(
  state: UserState,
  action: {
    payload:
      | SuccessPayload<fedexCarrierPayload>
      | SuccessPayload<dhlCarrierPayload>
      | SuccessPayload<aramexCarrierPayload>;
  },
) {
  state.loading = false;
  if (action.payload.data && isFedexPayload(action.payload.data)) {
    const carrierType = action.payload.data.csp as keyof typeof state.carrierSetting;
    state.carrierSetting.fedex = {
      ...state.carrierSetting[carrierType],
      ...action.payload.data,
    };
  } else if (action.payload.data && isDhlPayload(action.payload.data)) {
    const carrierType = action.payload.data.csp as keyof typeof state.carrierSetting;
    state.carrierSetting.dhl = {
      ...state.carrierSetting[carrierType],
      ...action.payload.data,
    };
  } else if (action.payload.data && isAramexPayload(action.payload.data)) {
    const carrierType = action.payload.data.csp as keyof typeof state.carrierSetting;
    state.carrierSetting.aramex = {
      ...state.carrierSetting[carrierType],
      ...action.payload.data,
    };
  } else if (action.payload.data === null) {
    // state.carrierSetting.fedex = action.payload.data;
    state.carrierSetting.fedex = { ...fedexInitialValues, _accountId: '', accountNumber: '' };
    state.carrierSetting.dhl = { ...dhlInitialValues, _accountId: '', accountNumber: '' };
    state.carrierSetting.aramex = { ...aramexIntialValues, _accountId: '', accountNumber: '' };
  }

  state.loading = false;
}

function getCarrierSettingsListFullfilled(state: UserState, action: { payload: SuccessPayload<CarrierSettingsList> }) {
  state.carrierSettingList = action.payload.data || [];
  state.loading = false;
}

// function deactivateAccountFulfilled() {}
// function suspendAccountFulfilled() {}
// function restoreAccountFulfilled() {}

export {
  getUserListFulfilled,
  handlePending,
  handleRejected,
  resendInviteFulfilled,
  viewContractFulfilled,
  deactivateUserAccountFulfilled,
  suspendUserAccountFulfilled,
  restoreUserAccountFulfilled,
  getAccountListFulfilled,
  createAccountFullfilled,
  createAccountRejected,
  getUserByIdFullfilled,
  updateAccountFullfilled,
  forgotPasswordFullfilled,
  confirmForgotPasswordFullfilled,
  getCarrierSettingsFullfilled,
  getCarrierSettingsListFullfilled,
};
