import React, { useContext, useEffect } from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Tooltip, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MenuContext } from '../../context/Menu';
import { MenuItemType } from './Type';
import MenuRoutes from './MenuList';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import OrganizationContainer from './OrgInfo';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { hasAdminAccess, userLogin } from '@Services/hasAdminAccess';
import { CheckCondition, isRouteAllowed } from '../../utils/allowedActions';
import UserMenuRoutes from './UserMenuList';

const ListItem = styled('div')`
  display: flex;
  align-items: center;
  padding: 11px;
  padding-left: 16px;
  padding-right: 16px;
`;

const MenuItemWrapper = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;
  margin: 0 !important;
`;

const MenuItemLabel = styled('label')`
  margin-left: 15.6px;
  font-weight: 400;
`;

const NestedMenuItem = styled('li')`
  width: 235px;
  height: 46px;
  padding: 12px;
  padding-left: 60px;
  cursor: pointer;
  &:hover {
    background-color: var(--mui-palette-background-hoverMenu);
  }
`;

function MenuItem(props: MenuItemType) {
  const { isMenuOpen, Icon, value, nestedPaths } = props;

  const loginUser = useSelector((state: RootStateType) => state.loginUser);
  const isPrepaid = loginUser?.profile?._accountId?.isPrepaid;
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  const hideWallet = !(!isAdmin && isPrepaid); // If admin with prepaid
  const hideCredit = !(!isAdmin && !isPrepaid); // If admin with not prepaid
  // menuWidth
  // const location = useLocation();
  // const isMenuSelected = location

  return (
    <div
    // style={{
    //   width: isMenuOpen ? `${menuWidth - 70}px` : '56px',
    // }}
    >
      {!isMenuOpen && (
        <div
          style={{
            height: '48px',
            padding: '',
            cursor: 'pointer',
            marginLeft: '-20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Tooltip placement="left-start" title={value}>
            {/* <div style={{ width: '27px', height: '27px', border: '1px solid' }}></div> */}
            <Icon className="" style={{ width: '27px', height: '27px' }} />
          </Tooltip>
        </div>
      )}
      {isMenuOpen && (
        <MenuItemWrapper
          sx={{
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ padding: 0, margin: 0 }}
            classes={{ content: 'm-0 p-0', expanded: '!m-0 p-0 h-46 items-center' }}
          >
            <ListItem
              style={{
                justifyContent: isMenuOpen ? 'start' : 'center',
                alignItems: 'center',
              }}
            >
              <Icon style={{ width: '24px', height: '24px' }} />
              {isMenuOpen && <MenuItemLabel className="md-text">{value}</MenuItemLabel>}
            </ListItem>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <ul style={{ listStyleType: 'none' }}>
              {nestedPaths
                .filter(pathObj => {
                  if (
                    (hideWallet && ['/transactions/wallet'].includes(pathObj.pathURL)) ||
                    (hideCredit && ['/transactions/credit'].includes(pathObj.pathURL)) ||
                    (!isAdmin && ['/transactions/credits-and-wallets', '/transactions/list'].includes(pathObj.pathURL))
                  ) {
                    return false;
                  }
                  if (isAdmin && ['/my-rates'].includes(pathObj.pathURL)) {
                    return false;
                  }

                  return true;
                })
                .map((pathObj, index) => (
                  <Link
                    key={`${pathObj.pathURL}-${index}`}
                    to={pathObj.pathURL}
                    className="text-decoration-none"
                    style={{ color: 'black' }}
                  >
                    <NestedMenuItem>{pathObj.label}</NestedMenuItem>
                  </Link>
                ))}
            </ul>
          </AccordionDetails>
        </MenuItemWrapper>
      )}
    </div>
  );
}

// function MenuItemExpand(props: MenuItemType) {
//   const { isMenuOpen, Icon, value } = props;
//   const location = useLocation();
//   // const isMenuSelected = location
//   const isMenuSelected = props.matchPaths.find(url => url === location.pathname);
//   return (
//     <ListItemContainer className={isMenuSelected ? 'selected' : ''} component={'li'} style={{}}>
//       <Link to={props.pathURL} style={{ textDecoration: 'none', color: 'inherit' }}>
//         <ListItem
//           style={{
//             justifyContent: isMenuOpen ? 'start' : 'center',
//             alignItems: 'center',
//             width: isMenuOpen ? '235px' : '56px',
//           }}
//         >
//           <Icon style={{ width: '24px', height: '24px' }} />
//           {isMenuOpen && <MenuItemLabel>{value}</MenuItemLabel>}
//         </ListItem>
//       </Link>
//     </ListItemContainer>
//   );
// }

function Menu() {
  const { isMenuOpen, menuWidth } = useContext(MenuContext);
  // const [MenuData, setMenuData] = useState([]);

  // const { PagesData, accessGroupById, SideNavbarPagesIds } = useSelector((state: RootStateType) => state.accessGroup);
  const { PagesData, accessGroupById, SideNavbarPagesIds, SideNavbarActionsIds } = useSelector(
    (state: RootStateType) => state.accessGroup,
  );
  const { profile } = useSelector((state: RootStateType) => state.loginUser);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isUserLogin = userLogin();
  const isAdmin = hasAdminAccess();
  // const isAdmin = !userLogin();

  const filteredPagesData = PagesData.filter(page => SideNavbarPagesIds.includes(page._id));

  useEffect(() => {
    const check = CheckCondition(pathname);

    if (profile?.accessGroup === 'pu_all') {
      let matchFound = false;
      for (const menuRoute of UserMenuRoutes) {
        for (const nestedPath of menuRoute.nestedPaths) {
          // if (nestedPath.pathURL === pathname) {
          if (pathname.toLowerCase().startsWith(nestedPath.pathURL.toLowerCase())) {
            matchFound = true;
            break;
          }
        }
        if (matchFound) break;
      }
      if (!matchFound && pathname !== '/') {
        navigate('/');
      }
    } else {
      if (check) {
        const actions = accessGroupById?.actions || [];
        if (actions.length > 0) {
          const res = isRouteAllowed(pathname, actions);
          if (!res) {
            navigate(-1);
          }
        }
      }
    }
  }, [accessGroupById?.pagesId, PagesData]);

  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="space-between"
      style={{ height: 'calc(100vh - 164px)', overflowY: 'auto', overflowX: 'hidden' }}
    >
      <div
        style={{
          // marginTop: '35px',
          padding: '15px',
          // paddingTop: 0,
          width: '100%',
        }}
      >
        {/* <ul> */}

        {!isUserLogin ? (
          <>
            {isAdmin ? (
              <>
                {MenuRoutes.map(menu => {
                  return (
                    <MenuItem
                      key={menu.label}
                      isMenuOpen={isMenuOpen}
                      menuWidth={menuWidth || 308}
                      value={menu.label}
                      Icon={menu.Icon}
                      nestedPaths={menu.nestedPaths}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {MenuRoutes?.map(menu => {
                  const filteredNestedPaths = menu?.nestedPaths?.filter(nestedPath => {
                    // return (
                    //   // filteredPagesData?.some(page => page?.name === nestedPath?.label) ||
                    //   filteredPagesData?.some(page =>
                    //     page?.name.toLowerCase().startsWith(nestedPath?.label.toLowerCase()),
                    //   ) ||
                    //   SideNavbarActionsIds?.some(page => {
                    //     return page?.details?.name?.includes(nestedPath?.label?.toLowerCase());
                    //   })
                    // );
                    // --------------------------------------------------------------------
                    if (menu.label === 'Invoice') {
                      return filteredPagesData?.some(page =>
                        page?.name.toLowerCase().startsWith(nestedPath?.matchActionName.toLowerCase()),
                      );
                    } else {
                      // return filteredPagesData?.some(page => page?.name === nestedPath?.label);
                      // -----------------new updated start---------------
                      // return (
                      //   filteredPagesData?.some(page =>
                      //     page?.name.toLowerCase().startsWith(nestedPath?.label.toLowerCase()),
                      //   )||
                      // SideNavbarActionsIds?.some(page => {
                      //   return page?.details?.name?.includes(nestedPath?.label?.toLowerCase());
                      // });

                      return (
                        filteredPagesData?.some(page => page?.name === nestedPath?.matchActionName) ||
                        SideNavbarActionsIds?.some(page => {
                          return page?.details?.name?.includes(nestedPath?.matchActionName?.toLowerCase());
                        })
                      );

                      // -----------------new updated end ---------------
                    }
                  });
                  if (filteredNestedPaths && filteredNestedPaths.length > 0) {
                    return (
                      <MenuItem
                        key={menu.label}
                        isMenuOpen={isMenuOpen}
                        menuWidth={menuWidth || 308}
                        value={menu.label}
                        Icon={menu.Icon}
                        nestedPaths={filteredNestedPaths}
                      />
                    );
                  }

                  return null; // If no matching nestedPaths, don't render MenuItem
                })}
              </>
            )}
          </>
        ) : (
          <>
            {UserMenuRoutes.map(menu => {
              return (
                <MenuItem
                  key={menu.label}
                  isMenuOpen={isMenuOpen}
                  menuWidth={menuWidth || 308}
                  value={menu.label}
                  Icon={menu.Icon}
                  nestedPaths={menu.nestedPaths}
                />
              );
            })}
          </>
        )}

        {/* </ul> */}
      </div>
      {isMenuOpen && <OrganizationContainer />}
    </Grid>
  );
}

export default Menu;
