import { SHIPMENT_STATUS } from '@Constants/Shipment';
import { Typography } from '@mui/material';
import { TrackShipmentDataType } from '@Reducers/Tracking/type';
import React from 'react';
import { BoxType } from '../../../types/shipment';
import { TrackingBoxContainer, TrackingStatus } from './style';

interface PropsType {
  key: string;
  box: BoxType;
  setSelectedBox: (box: BoxType) => void;
  isSelected: boolean;
  childAWBNumber: string;
  carrierNumber: string;
  trackingData: TrackShipmentDataType | null;
}

function SingleBox({ key, box, setSelectedBox, isSelected, childAWBNumber, trackingData, carrierNumber }: PropsType) {
  const { id } = box;
  const status = trackingData?.status || 'placed';

  return (
    <>
      <TrackingBoxContainer
        container
        flexDirection={'column'}
        className={`rounded-lg`}
        isselected={String(isSelected)}
        onClick={() => {
          if (isSelected) return;
          setSelectedBox(box);
        }}
        key={key}
      >
        <Typography className="md-text font-bold" sx={{ marginBottom: '5px' }}>{`Box ${Number(id) + 1}`}</Typography>
        <Typography className="sm-text" sx={{ marginBottom: '3px' }}>
          {childAWBNumber || '-'}
        </Typography>
        <div className="flex items-center" style={{ marginBottom: '10px' }}>
          <Typography className="sm-text">Carrier AWB: </Typography>
          <Typography className="sm-text" sx={{ marginLeft: '5px' }}>
            {carrierNumber || '--'}
          </Typography>
        </div>

        <div className="flex justify-between">
          <TrackingStatus bgcolor={SHIPMENT_STATUS[status]?.bgColor} color={SHIPMENT_STATUS[status]?.textColor}>
            {SHIPMENT_STATUS[status]?.label}
          </TrackingStatus>
        </div>
      </TrackingBoxContainer>
    </>
  );
}

export default SingleBox;
