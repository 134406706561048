import { GetSingleRates, UploadRate, rateApplyFor } from '@Reducers/RateUpload/Type';
import { CopyRate } from './type';

const pageConfig = {
  tariff: {
    title: 'Tariff Pricing',
    subTitle: 'View and add rates for non-account holders.',
    createTitle: 'Add Rates',
    onlySheet: false,
    showAccountNumber: false,
    applyForRates: [
      { value: 'markup', label: 'All Zones' },
      { value: 'zone-wise', label: 'Zone Wise' },
      { value: 'custom-rate', label: 'Custom Rate' },
    ],
    redirectBack: '/pricing/tariff',
    onlyMarkup: false,
  },
  base: {
    title: 'Base Pricing',
    subTitle: 'Set base rates.',
    createTitle: 'Add Rates',
    onlySheet: true,
    showAccountNumber: false,
    applyForRates: [],
    redirectBack: '/pricing/base',
    onlyMarkup: false,
  },
  contract: {
    title: 'Contract Pricing',
    subTitle: 'Add rates for account holder.',
    createTitle: 'Set Markup',
    showAccountNumber: true,
    onlySheet: false,
    applyForRates: [
      { value: 'markup', label: 'All Zones' },
      { value: 'zone-wise', label: 'Zone Wise' },
      { value: 'rate-sheet', label: 'Rate Sheet' },
      { value: 'custom-rate', label: 'Custom Rate' },
    ],
    redirectBack: '/pricing/contract',
    onlyMarkup: false,
  },
};

const RatesTypeList = [
  { value: false, label: 'Parcel' },
  { value: true, label: 'Document' },
];

const RatesForTypeList = [
  { value: true, label: 'Inbound' },
  { value: false, label: 'Outbound' },
];

const APPLY_FOR_OPTIONS: { value: rateApplyFor; label: string }[] = [
  { value: 'markup', label: 'All Zones' },
  { value: 'zone-wise', label: 'Zone Wise' },
  { value: 'rate-sheet', label: 'Rate Sheet' },
  { value: 'custom-rate', label: 'Custom Rate' },
];

const initCreateRate: UploadRate = {
  accountNumber: null,
  copyRates: [],
  csp: '',
  system: '',
  document: false,
  endDate: new Date().toDateString(),
  startDate: new Date().toDateString(),
  maxWeight: null,
  minWeight: null,
  isImport: false,
  rateApplyFor: APPLY_FOR_OPTIONS[0].value,
};

const initGetRates: GetSingleRates = {
  accountNumber: null,
  _courierId: '',
  _systemId: '',
  documentRates: false,
  endDate: new Date().toDateString(),
  startDate: new Date().toDateString(),
  maxWeight: null,
  minWeight: null,
  isImport: false,
  rateApplyFor: APPLY_FOR_OPTIONS[0].value,
};
const initCopyRate: CopyRate = {
  csp: '',
  system: [],
  endDate: new Date().toDateString(),
  startDate: new Date().toDateString(),
  maxWeight: null,
  minWeight: null,
};

export { RatesForTypeList, pageConfig, RatesTypeList, APPLY_FOR_OPTIONS, initCreateRate, initCopyRate, initGetRates };
