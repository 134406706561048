import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, TableBody, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Range, RangeKeyDict } from 'react-date-range';

import AccountLookup from '@Components/AccountLookup';

import Divider from '@Components/Divider';
import Title from '@Components/Title';
import Dropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import DateRangeCalendar from '@Components/DateRangeCalendar';
import SbButton from '@Components/Button';
import { TableHeadCell } from '@Components/Table/TableCell';
import { SBTableWrapper, SBTable } from '@Components/Table/Table';

import { AppDispatch, RootStateType } from '../../store';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { getRates, resetRateSummary } from '@Reducers/RateSummary';
import { getServiceList } from '@Reducers/CarrierAndService';
import { getActiveSystemList } from '@Reducers/Systems';

import UserDetailCard from './UserDetailCard';
import { RateRow } from './RateRow';
import RateFilter from './RateFilter';
import { pageType } from '@Reducers/RateSummary/Type';
import Loader from '@Components/Loader';
import useAccountLookup from '@Hook/useAccountLookup';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
// import { getUserList } from '@Reducers/AccountLookup';
import { getUserList } from '@Reducers/AccountLookup';
import { exportRates } from '@Reducers/BasePricingReport/BasePricingReport';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
// import { exportRates } from '@Reducers/RateSummary/actions';

const RateForOptions: DropdownOptionType<string>[] = [
  { value: 'outbound', display: 'Outbound' },
  { value: 'inbound', display: 'Inbound' },
];

interface PricingProps {
  pageType: pageType;
  title: string;
  subTitle: string;
}

function Pricing(props: PricingProps) {
  const modalId = 'contractPricing';
  const dispatch = useDispatch<AppDispatch>();

  const RateState = useSelector((state: RootStateType) => state.RateSummary);
  const { currentPage, totalCount, perPage } = RateState;
  const navigate = useNavigate();
  const { loading: accountLookupLoader, selectedUser: accountUser, setSelectedUser } = useAccountLookup(modalId);
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const pageTypeActionsMap = {
    base: {
      add_rates: ACTION_NAMES.Base_Pricing.add_rates,
      download_report: ACTION_NAMES.Base_Pricing.download_report,
    },
    tariff: {
      add_rates: ACTION_NAMES.Tariff_Pricing.add_rates,
      download_report: ACTION_NAMES.Tariff_Pricing.download_report,
    },
    contract: {
      add_rates: ACTION_NAMES.Contract_Pricing.add_rates,
      download_report: ACTION_NAMES.Contract_Pricing.download_report,
    },
  };

  const isAddRatesAllowed = isActionAllowed(
    accessGroupById?.actions,
    pageTypeActionsMap[props.pageType]?.add_rates,
    PERFORM_ACTION.write,
  );

  const isDownLoadReportAllowed = isActionAllowed(
    accessGroupById?.actions,
    pageTypeActionsMap[props.pageType]?.download_report,
    PERFORM_ACTION.write,
  );

  // const [accountUser, setAccountUser] = useState<UserObj | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [rateFor, setRateFor] = useState<string[]>([RateState.config.isImport ? 'inbound' : 'outbound']);
  const [selectedRange, setSelectedRange] = useState<Range[]>([
    { startDate: new Date(), endDate: new Date(), key: 'selection' },
  ]);
  const [systemValues, setSystemValues] = useState<string[] | any>([]);
  const [rateType, setRateType] = useState<string[] | any>([]);
  const [cspValues, setCspValues] = useState<string[] | any>([]);
  const { limit } = useSelector((state: RootStateType) => state.notifications);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const ratesFor = searchParams.get('ratesFor');
    if (ratesFor) setRateFor([ratesFor]);
  }, []);

  useEffect(function () {
    dispatch(getServiceList({}));
    dispatch(getActiveSystemList());
    return () => {
      dispatch(resetRateSummary());
    };
  }, []);

  useEffect(
    function () {
      onRefetch();
    },

    [selectedDate, rateFor, accountUser?._accountId?.accountNumber],
  );

  // useEffect(function() {
  //   console.log({RATE_CONFIG: Rate})
  // }, [RateState.config])
  // console.log({ accountUser });
  useEffect(
    function () {
      const selectedRange = RateState.mergedRatesArr.map(({ startDate, endDate }) => ({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
      }));
      selectedRange.unshift({ startDate: selectedDate, endDate: selectedDate, key: 'selection' });
      selectedRange.push({ startDate: selectedDate, endDate: selectedDate, key: 'selection' });
      setSelectedRange(selectedRange);
    },

    [RateState.selectedDateRate, selectedDate],
  );

  const getSelectedUser = (user: UserObj) => {
    setSelectedUser(user);
  };

  const onRefetch = function () {
    // if (props.pageType === 'contract' && !accountUser) return;
    dispatch(
      getRates({
        navigatedDate: selectedDate.toISOString(),
        pageType: props.pageType,
        accountNumber: accountUser?._accountId?.accountNumber || '',
        isImport: rateFor[0] === 'inbound',
        system: [],
        csp: [],
        rateType: [],
      }),
    );
  };

  const handleRateForChange = (selected: Array<string>) => {
    navigate(`/pricing/${props.pageType}?ratesFor=${selected[0]}`);
    setRateFor(selected);
  };

  const onDateChange = (range: RangeKeyDict) => {
    return setSelectedDate(range?.selection?.startDate || new Date());
  };

  const redirectToCreate = () => {
    if (isAddRatesAllowed) {
      let redirectURI = '/pricing/' + props.pageType + '/create?ratesFor=' + rateFor;
      if (props.pageType === 'contract') redirectURI += '&accountNumber=' + accountUser?._accountId?.accountNumber;
      return navigate(redirectURI);
    }
    return navigate('');
  };

  const handleDownload = () => {
    try {
      dispatch(
        exportRates({
          navigatedDate: selectedDate.toISOString(),
          pageType: props.pageType,
          accountNumber: accountUser?._accountId?.accountNumber || '',
          isImport: rateFor[0] === 'inbound',
          system: [],
          csp: [],
          rateType: [],
        }),
      );
      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit,
        }),
      );
      dispatch(getAllReadNotifications({ page: 1, limit }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Download in Progress',
          subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
          btnText: 'Close',
          onConfirm: () => {
            dispatch(toggleBoolean());
            dispatch(SuccessErrorModalReset());
          },
        }),
      );
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    }
  };

  const showFilterAndTable = props.pageType === 'contract' ? !!accountUser : true;
  const handleChangePage = (_: any, page: number) => {
    dispatch(
      getRates({
        navigatedDate: selectedDate.toISOString(),
        pageType: props.pageType,
        accountNumber: accountUser?._accountId?.accountNumber || '',
        isImport: rateFor[0] === 'inbound',
        currentPage: page + 1,
        perPage,
        system: systemValues ? systemValues : [],
        csp: cspValues ? cspValues : [],
        rateType: rateType ? rateType : [],
      }),
    );
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    const newPerPage = parseInt(event.target.value, 10);
    dispatch(
      getRates({
        navigatedDate: selectedDate.toISOString(),
        pageType: props.pageType,
        accountNumber: accountUser?._accountId?.accountNumber || '',
        isImport: rateFor[0] === 'inbound',
        currentPage: 1,
        perPage: newPerPage,
        system: systemValues ? systemValues : [],
        csp: cspValues ? cspValues : [],
        rateType: rateType ? rateType : [],
      }),
    );
  };

  useEffect(() => {
    if (props.pageType === 'contract' && !accountUser && modalId) dispatch(getUserList([modalId, {}]));
  }, [accountUser, props.pageType, modalId]);

  return (
    <Loader loading={RateState.loading || accountLookupLoader} overly>
      <>
        <Title title={props.title} subTitle={props.subTitle}></Title>
        <Divider />
        {/* Only for CONTRACT PAGE */}
        {props.pageType === 'contract' && (
          <Grid container className="rounded-lg p-4 shadow mt-3">
            <Grid item xs={12} md={6} lg={4} xl={3} className="mb-3">
              <AccountLookup
                modalId={modalId}
                showSearchInput={true}
                getSelectedUser={getSelectedUser}
                disableUserReset
              >
                <Title title={props.title} subTitle={props.subTitle} />
              </AccountLookup>
            </Grid>
            {accountUser && <UserDetailCard user={accountUser} />}
          </Grid>
        )}
        {/* ************** */}
        {showFilterAndTable && (
          <>
            <Grid container className="mt-4" style={{ minWidth: '200px' }} alignItems="center">
              <Grid item className="px-2">
                <Typography className="font-medium" sx={{ fontSize: '20px' }} align="center">
                  For
                </Typography>
              </Grid>
              <Grid item style={{ minWidth: '200px' }}>
                <Dropdown
                  id="ratesFor"
                  value={rateFor}
                  onChange={handleRateForChange}
                  options={RateForOptions}
                  required={true}
                />
              </Grid>
            </Grid>
            <Grid container className="mt-4" justifyContent="space-between" alignItems="center">
              <Grid item>
                <DateRangeCalendar date={selectedDate} ranges={selectedRange} onChange={onDateChange} />
              </Grid>
              <Grid item alignSelf={'flex-end'}>
                <Grid container alignItems={'center'}>
                  <Grid item>
                    <SbButton variant="contained" onClick={redirectToCreate} disabled={!isAddRatesAllowed}>
                      Add Rates
                    </SbButton>
                  </Grid>
                  <Grid item className="ml-2">
                    <SbButton variant="outlined" onClick={handleDownload} disabled={!isDownLoadReportAllowed}>
                      <DownloadIcon />
                    </SbButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <RateFilter /> */}
            <RateFilter
              rateFor={rateFor}
              pageType={props.pageType}
              system={systemValues}
              setSystem={setSystemValues}
              csp={cspValues}
              setCsp={setCspValues}
              rateType={rateType}
              setRate={setRateType}
              selectedDate={selectedDate}
            />
            <RateTable pagetype={props.pageType} />
            {!!totalCount && !!perPage && (
              <>
                <TablePagination
                  component="div"
                  count={totalCount}
                  page={currentPage}
                  rowsPerPage={perPage}
                  // onPageChange={(_, page) => {
                  //   // dispatch(onChangePagination(page));
                  //   dispatch(
                  //     getRates({
                  //       navigatedDate: selectedDate.toISOString(),
                  //       pageType: props.pageType,
                  //       accountNumber: accountUser?._accountId?.accountNumber || '',
                  //       isImport: rateFor[0] === 'inbound',
                  //       currentPage: page + 1,
                  //     }),
                  //   );
                  // }}
                  // onRowsPerPageChange={(event: { target: { value: string } }) => {
                  //   const newPerPage = parseInt(event.target.value);
                  //   dispatch(
                  //     getRates({
                  //       perPage: newPerPage,
                  //       navigatedDate: '',
                  //       pageType: props.pageType,
                  //       accountNumber: null,
                  //       isImport: rateFor[0] === 'inbound',
                  //     }),
                  //   );
                  // }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </>
        )}
      </>
    </Loader>
  );
}
function RateTable({ pagetype }: { pagetype: pageType }) {
  const RateState = useSelector((state: RootStateType) => state.RateSummary);
  const pageType = RateState.config.pageType;
  let RateColumnName = 'Base rate';
  if (pageType === 'tariff') RateColumnName = 'Tariff markup';
  else if (pageType === 'contract') RateColumnName = 'Custom markup';
  return (
    <>
      <SBTableWrapper className="mt-2">
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell>Services</TableHeadCell>
              <TableHeadCell>Type</TableHeadCell>
              <TableHeadCell>{RateColumnName}</TableHeadCell>
              <TableHeadCell>Validity</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {RateState.selectedDateRate.map((rate, index) => {
              return <RateRow key={index} rate={rate} pagetype={pagetype} />;
            })}
            {/* rate.system + rate.csp + rate.documentRates */}
          </TableBody>
        </SBTable>
      </SBTableWrapper>
    </>
  );
}

export default Pricing;
