import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { TransactionHistoryQuery } from './Type';

const getTransactionList = createAsyncThunk('getTransactionList', async (queryParams: TransactionHistoryQuery) => {
  const res = await API.get(`/payment/transaction/all`, { params: queryParams });
  return res.data;
});

const ExportTransactions = createAsyncThunk('WalletExport', async (queryParams: TransactionHistoryQuery) => {
  const res = await API.get(`/wallet/export?`, {
    params: { fromDate: queryParams.fromDate, toDate: queryParams.toDate },
  });
  window.open(res.data.data, '_blank');
  return {};
});

export { getTransactionList, ExportTransactions };
