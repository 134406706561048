import { SbMenu, SbMenuItem } from '@Components/Menu';
import SbTooltip from '@Components/Tooltip';
import {
  downloadCarrierLabel,
  downloadProformaInvoice,
  downloadShippingInvoice,
  downloadShippingLabel,
} from '@Reducers/ShipmentHistory/actions';
import { Box, Grid } from '@mui/material';
import React from 'react';
import { actionIconStyle } from '../../pages/ViewSingleShipment/style';
// import ViewIcon from '@mui/icons-material/Visibility';
// import ShippingLabelIcon from '@mui/icons-material/InsertDriveFileOutlined';
// import CspLabelIcon from '@mui/icons-material/HandshakeOutlined';
// import ShipmentInvoiceIcon from '@mui/icons-material/SaveOutlined';
// import ProformaInvoiceIcon from '@mui/icons-material/SummarizeOutlined';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';

// import ClearIcon from '@mui/icons-material/Close';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CspLabelIcon from '@mui/icons-material/HandshakeOutlined';
import ShippingLabelIcon from '@mui/icons-material/InsertDriveFileOutlined';
// import ReplayIcon from '@mui/icons-material/Replay';
import Image from '@Components/Image';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import ShipmentInvoiceIcon from '@mui/icons-material/SaveOutlined';
import ProformaInvoiceIcon from '@mui/icons-material/SummarizeOutlined';
import ViewIcon from '@mui/icons-material/Visibility';
import { isActionAllowed } from '../../utils/allowedActions';

interface PropsType {
  handleView?: (e: React.MouseEvent<SVGSVGElement>) => void;
  shipmentNumber: string;
  hideCarrierAWB?: boolean;
  carrierAWBIcon?: string;
}

function DownloadShipmentLabelAndInvoice({ handleView, shipmentNumber, hideCarrierAWB, carrierAWBIcon }: PropsType) {
  const dispatch = useDispatch<AppDispatch>();

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isViewDocsAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.view_docs,
    PERFORM_ACTION.write,
  );
  const isDownloadShipmentlabelAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.download_shipment_label,
    PERFORM_ACTION.write,
  );
  const isDownloadProformaInvoiceAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Shipment_Details.download_proforma_invoice,
    PERFORM_ACTION.write,
  );
  // console.info('isDownloadShipmentlabelAllowed:', shipmentNumber);
  // console.info('carrierAWBIcon:', carrierAWBIcon);
  return (
    <Grid container item justifyContent="space-around" style={{ width: '175px' }}>
      {handleView && isViewDocsAllowed && (
        <SbTooltip title="View Document">
          <ViewIcon style={actionIconStyle} onClick={handleView} />
        </SbTooltip>
      )}

      {isDownloadShipmentlabelAllowed && (
        <SbMenu icon={<ShippingLabelIcon />} title="Shipping Label">
          <SbMenuItem
            name="A4"
            onClick={() => {
              dispatch(downloadShippingLabel({ shipmentNumber, formatType: 'A4' }));
            }}
          ></SbMenuItem>
          <SbMenuItem
            name="A5"
            onClick={() => {
              dispatch(downloadShippingLabel({ shipmentNumber, formatType: 'A5' }));
            }}
          ></SbMenuItem>
        </SbMenu>
      )}

      {/* <SbMenu icon={<CspLabelIcon />} title="Csp Label">
        <SbMenuItem
          name="A4"
          onClick={() => {
            dispatch(downloadCSPLabel({ shipmentNumber, formatType: 'A4' }));
          }}
        ></SbMenuItem>
        <SbMenuItem
          name="A5"
          onClick={() => {
            dispatch(downloadCSPLabel({ shipmentNumber, formatType: 'A5' }));
          }}
        ></SbMenuItem>
      </SbMenu> */}
      {!hideCarrierAWB && (
        <SbTooltip title="Carrier AWB">
          <Box
            className="font-medium md-text text-decoration-none sb-text-black relative cursor-pointer"
            onClick={() => {
              if (carrierAWBIcon) {
                // const awbLink = `${CONFIG.API_URI}/shipment/label/getLabel?shipmentNumber=${shipmentNumber}`;
                // const link = document.createElement('a');
                // link.href = awbLink;
                // link.download;
                // document.body.appendChild(link);
                // link.click();
                // document.body.removeChild(link);

                dispatch(downloadCarrierLabel(shipmentNumber));
              }
            }}
          >
            {carrierAWBIcon ? (
              <Image
                src={carrierAWBIcon || ''}
                alt="logo"
                style={{
                  height: '22px',
                  width: '44px',
                  position: 'relative',
                  zIndex: 1,
                }}
                // className="border-1 border-solid border-black rounded-xs"
              />
            ) : (
              <CspLabelIcon
                sx={{
                  position: 'relative',
                  zIndex: 1,
                }}
              />
            )}
          </Box>
        </SbTooltip>
      )}

      <SbMenu icon={<ShipmentInvoiceIcon />} title="Shipment Invoice">
        <SbMenuItem
          name="PDF"
          onClick={() => {
            dispatch(downloadShippingInvoice({ shipmentNumber, fileType: 'pdf' }));
          }}
        ></SbMenuItem>
        <SbMenuItem
          name="XLSX"
          onClick={() => {
            dispatch(downloadShippingInvoice({ shipmentNumber, fileType: 'xlsx' }));
          }}
        ></SbMenuItem>
      </SbMenu>
      {isDownloadProformaInvoiceAllowed && (
        <SbTooltip title="Proforma Invoice">
          <ProformaInvoiceIcon
            style={actionIconStyle}
            onClick={() => dispatch(downloadProformaInvoice({ shipmentNumber }))}
          />
        </SbTooltip>
      )}
    </Grid>
  );
}

export default DownloadShipmentLabelAndInvoice;
