import React from 'react';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DocumentDeleteType } from './Types';
import Image from '@Components/Image';
import Modal from '@Components/Modal';
import SbButton from '@Components/Button';
import { AppDispatch } from '@Store';
import { useDispatch } from 'react-redux';
import { deleteShipmentKyc } from '@Reducers/Shipment';

function DocumentDelete(props: DocumentDeleteType) {
  const { shipmentNumber, docId } = props;
  const theme = useTheme();
  const dispatch = useDispatch<AppDispatch>();

  const onDelete = async () => {
    dispatch(deleteShipmentKyc({ shipmentNumber, docId }));
  };

  return (
    <Modal open={props.open} maxWidth={'sm'}>
      <Grid container className="mb-3" gap={3} justifyContent="flex-end">
        <IconButton onClick={() => props.onClose(false)}>
          <CloseIcon />
        </IconButton>
        <Grid container item justifyContent="center">
          {/* <TaskAltIcon sx={{ width: '100px', height: '100px' }} color="success" /> */}
          <Image src="/images/Boxes/Delete.svg" alt="delete" height={100} />
        </Grid>
        <Grid container item justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={6} justifyContent="center">
            <Typography fontWeight="bold" variant="h5">
              Are you sure you want to Delete this document?
            </Typography>
          </Grid>
          <Typography variant="h6" mt={1} color={theme.palette.primary.main}>
            Current document will be deleted permanently.
          </Typography>
        </Grid>
        <Grid container item>
          <SbButton
            variant="contained"
            fullWidth
            onClick={() => {
              onDelete();
            }}
          >
            Yes, delete!
          </SbButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
export default DocumentDelete;
