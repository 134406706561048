import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { styled } from '@mui/system';

import { Grid, TableBody, TableHead, TablePagination, TableRow, Typography, useTheme } from '@mui/material';

import FilterSearch from '../../../components/Filter/Search';

import { SBTable } from '../../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../../components/Table/TableCell';
import { SBTableRow } from '../../../components/Table/TableRow';
import Tooltip from '../../../components/Tooltip';

// ICONS

import { AppDispatch, RootStateType } from '../../../store';
import { getWarehouseList, onChangePagination, resetCurrentPage } from '../../../store/reducers/Warehouse';
import { FilterObj } from './Types';
import { WarehouseListQuery, WarehouseObj, AddressType, coAttentionType } from '../../../store/reducers/Warehouse/Type';
import { Link } from 'react-router-dom';
import Image from '../../../components/Image';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const SearchFilterList = [
  { label: 'Name', value: 'name' },
  { label: 'System', value: 'systemName' },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  // padding: '32px 16px',
  margin: '32px 0',
  display: 'grid',
  gridTemplateColumns: '510px 1fr fit-content(140px) fit-content(140px) 140px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: 0,
    gridTemplateColumns: '490px 1fr fit-content(140px) fit-content(140px) 140px',
  },
}));

// const actionIconStyle = { width: '20px', height: '20px', cursor: 'pointer' };

function AddressInfo(props: { address: AddressType; coAttention: coAttentionType }) {
  const { address, coAttention } = props;
  const countriesObj = useSelector((state: RootStateType) => state.global.countriesObj);

  const countryName = countriesObj?.[address.country]?.name;
  return (
    <>
      <div className="">
        <Typography className="md-text">
          <span className="font-semibold">Address: </span> {address.line1}, {address.line2}, {address.state}
        </Typography>
        <Typography className="md-text">
          {address.city}, {countryName} - {address.pincode}
        </Typography>
        <Typography className="md-text mt-1">
          <span className="font-semibold">C/O Attn Name : </span> {coAttention.name}
        </Typography>
        <Typography className="md-text mt-0.5">
          <span className="font-semibold">Contact: </span> +{coAttention.contact}
        </Typography>
        <Typography className="md-text mt-0.5">
          <span className="font-semibold">Contact Email: </span> {coAttention.email}
        </Typography>
      </div>
    </>
  );
}

function Actions(props: { warehouse: WarehouseObj }) {
  const theme = useTheme();
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const isCreateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Warehouse.edit_warehouse,
    PERFORM_ACTION.read,
  );

  return (
    <Grid container item justifyContent="space-around" alignItems="center">
      <Grid xs={6} textAlign="center">
        {(isCreateAllowed || accessGroupById?.code === 'SUPERADMIN') && (
          <Tooltip title="View">
            <Link to={`/warehouse/edit/${props.warehouse._id}`} style={{ color: theme.palette.primary.main }}>
              {/* <ViewIcon style={actionIconStyle} /> */}
              <Image src="/images/icons/EditAction.svg" alt="Edit" />
            </Link>
          </Tooltip>
        )}
      </Grid>
      {/* <Grid xs={6} textAlign="center">
        <Tooltip title="View">
          <Link to={`/warehouse/edit/${props.warehouse._id}`} style={{ color: theme.palette.primary.main }}>
            <Image src="/images/icons/DeleteAction.svg" alt="Delete" />
          </Link>
        </Tooltip>
      </Grid> */}

      {/* <Tooltip title="Shipping Label">
          <ShippingLabelIcon style={actionIconStyle} />
        </Tooltip> */}
    </Grid>
  );
}

function WarehouseTable() {
  const {
    data: { list, currentPage = 1, perPage = 100 },
  } = useSelector((state: RootStateType) => state.Warehouse);

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell width="100px">Sr no.</TableHeadCell>
            <TableHeadCell width="30%">Name</TableHeadCell>
            <TableHeadCell>Contact</TableHeadCell>
            <TableHeadCell width="20%">System</TableHeadCell>
            <TableHeadCell>Actions</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list?.map((warehouse, index) => (
            <SBTableRow key={warehouse._id}>
              <TableRowCell align="center" className="md-text">
                <div>{index + currentPage * perPage + 1}</div>
                {/* <div>{index + 1 + (currentPage - 1) + perPage}</div> */}
              </TableRowCell>
              <TableRowCell align="left" className="md-text">
                <Grid container>
                  <Grid xs={12} className="font-semibold">
                    {warehouse.name}
                  </Grid>
                  <Grid xs={12} className="font-medium mt-0.5">
                    Created On:{' '}
                    <time className="ml-1 font-normal">{moment(warehouse.createdAt).format('DD/MM/YYYY, hh:mm')}</time>
                  </Grid>
                  <Grid xs={12} className="font-medium">
                    Updated On:{' '}
                    <time className="ml-1 font-normal">{moment(warehouse.updatedAt).format('DD/MM/YYYY, hh:mm')}</time>
                  </Grid>
                </Grid>
              </TableRowCell>
              <TableRowCell>
                <AddressInfo address={warehouse._addressId} coAttention={warehouse.coAttention} />
              </TableRowCell>
              <TableRowCell align="center" className="md-text">
                {warehouse._systemId.map(x => x.name).join(', ')}
              </TableRowCell>
              <TableRowCell>
                <Actions warehouse={warehouse} />
              </TableRowCell>
            </SBTableRow>
          ))}
        </TableBody>
      </SBTable>
    </div>
  );
}

export default function WarehouseList() {
  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const [filterText, setFilterText] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const {
    data: { currentPage, perPage, totalCount },
  } = useSelector((state: RootStateType) => state.Warehouse);

  useEffect(() => {
    dispatch(getWarehouseList({ currentPage: 1, perPage: 10 }));
    dispatch(resetCurrentPage());
  }, []);

  const getFilterObj = (options: FilterObj): WarehouseListQuery => {
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filterQuery: WarehouseListQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,

      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    return filterQuery;
  };

  return (
    <div style={{ width: '100', overflow: 'hidden' }}>
      <TableActionContainer>
        <FilterSearch
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={filterText}
          onTextChange={e => {
            setFilterText(e.target.value);
          }}
          onTextSearch={() => {
            dispatch(getWarehouseList(getFilterObj({})));
            dispatch(resetCurrentPage());
          }}
          onTextClear={() => {
            setFilterText('');
            dispatch(getWarehouseList(getFilterObj({ searchValue: undefined })));
            dispatch(resetCurrentPage());
          }}
        />
      </TableActionContainer>

      <WarehouseTable />
      {/* {!!totalCount && !!perPage && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage - 1}
            rowsPerPage={perPage}
            onPageChange={(_, page) => {
              dispatch(getWarehouseList(getFilterObj({ currentPage: page + 1 })));
            }}
            onRowsPerPageChange={event => {
              dispatch(getWarehouseList(getFilterObj({ perPage: parseInt(event.target.value) })));
            }}
          />
        </>
      )} */}
      {!!totalCount && !!perPage && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage}
            rowsPerPage={perPage}
            onPageChange={(_, page) => {
              dispatch(onChangePagination(page));
            }}
            onRowsPerPageChange={event => {
              dispatch(getWarehouseList(getFilterObj({ perPage: parseInt(event.target.value) })));
            }}
          />
        </>
      )}
    </div>
  );
}
