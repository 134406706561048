/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */
import { getLoggedInUser } from '@Reducers/loginUser/actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { ErrorType, UserLoginQuery, UserLoginRejectType, UserLoginRes } from './type';
import { requestPermission } from '../../../firebase';
import { setToResetRequired } from '@Reducers/LoginForm';

const doLogin = createAsyncThunk<UserLoginRes, UserLoginQuery, { rejectValue: UserLoginRejectType }>(
  'doLogin',
  async (body, { dispatch }) => {
    try {
      const res = await API.post(`/app/login`, { ...body });
      dispatch(getLoggedInUser());
      requestPermission(dispatch);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.errorObj?.msg || 'Something went wrong.',
        }),
      );
      if (errorRes?.errorObj?.isResetPassword) {
        dispatch(setToResetRequired());
      }
      throw { msg: 'No such user.' };
    }
  },
);

const doLoginWithOtp = createAsyncThunk<null, { username: string }, { rejectValue: { errorObj: ErrorType } }>(
  'doLoginWithOtp',
  async (body, { rejectWithValue }) => {
    try {
      const res = await API.post(`/app/login/otp`, { ...body });
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const verifyLoginOtp = createAsyncThunk(
  'verifyLoginOtp',
  async (body: { username: string; otp: string }, { rejectWithValue, dispatch }) => {
    try {
      const res = await API.post(`/app/login/verify-otp`, { ...body });
      dispatch(getLoggedInUser());
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.errorObj?.msg || 'Something went wrong.',
        }),
      );
      return rejectWithValue(error.response.data);
    }
  },
);

const doLogout = createAsyncThunk<null, null, { rejectValue: { errorObj: ErrorType } }>(
  'doLogout',
  async (_body, { rejectWithValue }) => {
    try {
      const res = await API.post(`/app/logout`);
      // dispatch(getLoggedInUser());
      return res.data;
    } catch (error) {
      // console.log({ thunkerror: error });
      return rejectWithValue(error.response.data);
    }
  },
);

export { doLogin, doLoginWithOtp, verifyLoginOtp, doLogout };
