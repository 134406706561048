import Joi from 'joi';

export const productDescriptionSchema = Joi.object().keys({
  id: Joi.string().optional(),
  description: Joi.string().required().messages({ 'string.empty': `Please enter Description` }),
  quantity: Joi.number()
    .min(1)
    .required()
    .messages({ 'number.base': `Please enter Quantity`, 'number.min': 'Weight should be greater than 0' }),
  unitValue: Joi.number()
    .min(0.1)
    .required()
    .messages({ 'number.base': `Please enter unit value`, 'number.min': 'Unit value should be 0.1 or above' }),
  // HSNName: Joi.string().required().messages({ 'string.empty': `Please enter HNS Name` }),
  HSNName: Joi.optional(),
  HSNCode: Joi.optional(),
  totalValue: Joi.optional(),
  actions: Joi.optional(),
  productName: Joi.optional(),
  value: Joi.optional(),
});
