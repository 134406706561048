import React from 'react';
import ErrorModal from '@Components/Modal/error';
import { Typography, styled } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@Store';
import { resetRTOShipment } from '@Reducers/AdminShipment';
interface PropsType {
  shipmentNumber: string;
  handleClose: () => void;
  open: boolean;
}

const Title = styled(Typography)(({ theme }) => ({
  // fontSize: '32px',
  maxWidth: '360px',
  marginTop: theme.spacing(4),
}));

export function RTOSuccessModal({ shipmentNumber, handleClose, open }: PropsType) {
  const dispatch = useDispatch<AppDispatch>();
  const handleNavigate = () => {
    // navigate(`/shipment/admin/edit/${shipmentNumber}`);
    handleClose();
    dispatch(resetRTOShipment());
  };

  return (
    <ErrorModal icon={'/images/paid.svg'} open={open} onClose={handleClose}>
      <Title className="font-medium xl-text">Success</Title>

      <Typography variant="h6" className="mt-1 font-normal">
        RTO shipment has been booked with shipment number :{' '}
        <a href={`/shipment/admin/edit/${shipmentNumber}`} target="_blank" className="link" onClick={handleNavigate}>
          {shipmentNumber}
        </a>
      </Typography>
    </ErrorModal>
  );
}
