import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProductDescription from './ProductDescription';
import ShipmentTypeDetails from './ShipmentTypeDetails';
import BoxList from './BoxList';
import TotalValues from './TotalValue';
import CreateShipmentContext from '../../../context/CreateShipment';
import { createShipmentContextType } from '../../../types/shipment';
import Actions from '../Actions';
import { getRates, setCreateShipmentCSP } from '../../../store/reducers/GetRates';
import { useDispatch, useSelector } from 'react-redux';
import { SHIPMENT_TYPES } from '../../GetRatesV2/Constant';
import { AddressType } from '../../../store/reducers/GetRates/Type';
import { AppDispatch, RootStateType } from '../../../store';
import NoCSPFound from '../../GetRatesV2/CSPList/NoCSPFound/NoCSPFound';
import ViewAction from '../../ViewSingleShipment/ViewAction';

// let postObj = {
//   destination: { city: 'Mumbai', state: 'Maharashtra', country: 'India', pincode: '400008' },
//   source: { city: 'Mumbai', state: 'Maharashtra', country: 'India', pincode: '400001' },
//   shipmentType: 'parcel',
//   userId: '63bd4bf3abcd13bf437829ba',
//   weight: '200',
//   package: {
//     box: [
//       { weight: '0', length: 0, width: 0, height: 0 },
//       { weight: '0', length: 0, width: 0, height: 0 },
//     ],
//     unitOfMeasurement: 'kg',
//   },
//   document: false,
// };

function getAddressObj({ pincode, city, country, state }: AddressType) {
  return { city, state, country, pincode };
}

function ShipmentDetails({
  removeShipmentInfo,
  showViewMoreAction,
  isShipmentDetailsWriteAllowed,
}: {
  removeShipmentInfo?: boolean;
  showViewMoreAction?: boolean;
  isShipmentDetailsWriteAllowed?: boolean;
}) {
  const [viewMore, setViewMore] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();
  const {
    activeStep,
    setActiveStep,
    setShowRates,
    chargeableWeight,
    boxes,
    consignee,
    shipper,
    totalShipmentValue: value,
    shipmentType,
    boxValidation,
    userId,
    pickupType: { selectedWearhouseAddress },
    pickupType,
    packageData,
  } = React.useContext(CreateShipmentContext) as createShipmentContextType;

  const rates = useSelector((state: RootStateType) => state.getRates);
  const { chareableWeight = { maxChargeableWt: 0 } } = useSelector((state: RootStateType) => state.getRates);
  console.log('chargeableWeight:', chargeableWeight);
  //add product button removed
  // function handleAddNewProduct() {
  //   // const productsTemp = [...selectedBox.products];
  //   // const boxesTemp = [...boxes];
  //   // productsTemp.push({ ...defaultProductState });
  //   setSelectedProduct({ ...defaultProductState, id: `${selectedBox?.products?.length}` });
  //   // setSelectedBox({ ...selectedBox, products: [...productsTemp] });
  //   // setBoxes([...boxesTemp]);
  // }

  useEffect(() => {
    if (rates.redirectToCreateShipmentCSP) {
      setActiveStep(activeStep + 1);
      dispatch(setCreateShipmentCSP(false));
    }
  }, [rates.redirectToCreateShipmentCSP]);

  function handleGetRates() {
    const obj = {
      destination: { ...getAddressObj(consignee) },
      source: { ...getAddressObj(selectedWearhouseAddress ? selectedWearhouseAddress : shipper) },
      shipmentType: shipmentType.type,
      userId,
      weight: chareableWeight?.maxChargeableWt || 0,
      value,
      package: {
        box: boxes.map(box => ({
          weight: box.weight.toString(),
          length: box.length,
          width: box.width,
          height: box.height,
        })),
        unitOfMeasurement: shipmentType.unit,
      },
      document: shipmentType.type === SHIPMENT_TYPES[1].value,
      insurance: packageData.insurance,
      packaging: packageData.packaging,
      dropOff: pickupType.type === 'dropOff' ? true : false,
    };
    // {
    //   destination: { city: 'Mumbai', state: 'Maharashtra', country: 'India', pincode: '400008' },
    //   source: { city: 'Mumbai', state: 'Maharashtra', country: 'India', pincode: '400001' },
    //   shipmentType: 'parcel',
    //   userId: '63bd4bf3abcd13bf437829ba',
    //   weight: 298.08,
    //   package: { box: [{ weight: '90', length: 27, width: 69, height: 800 }], unitOfMeasurement: 'kg-cm' },
    //   document: false,
    // }
    dispatch(getRates([{ ...obj, rateFor: 'create-shipment' }, false]));

    // setActiveStep(activeStep + 1);
  }

  return (
    <>
      <Grid container>
        {!removeShipmentInfo && <ShipmentTypeDetails />}
        <Grid container item>
          <TotalValues />
        </Grid>
        {showViewMoreAction && (
          <Grid container justifyContent="flex-end">
            <ViewAction setViewMore={setViewMore} viewMore={viewMore} />
          </Grid>
        )}
        {(viewMore || !showViewMoreAction) && (
          <Grid container className="my-3" spacing={2}>
            <Grid item lg={4} xl={3} md={4} sm={12} xs={12}>
              <BoxList writeAccess={isShipmentDetailsWriteAllowed} />
            </Grid>
            <Grid item lg={8} xl={9} md={8} sm={12} xs={12}>
              <ProductDescription writeAccess={isShipmentDetailsWriteAllowed} />
              {/* <DashedBtn classes="w-full" label={'Add a Product'} onClick={handleAddNewProduct} /> */}
            </Grid>
          </Grid>
        )}
      </Grid>
      {!removeShipmentInfo && <Actions onNext={handleGetRates} disableNext={!boxValidation} />}
      <NoCSPFound setShowRates={setShowRates} />
    </>
  );
}

export default ShipmentDetails;
