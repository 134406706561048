import React from 'react';
import SbButton from '../Button';
import PaymentModal from './PaymentModal';
import { usePayment } from '@Hook/usePayment';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';

export interface PaymentDetails {
  shipmentNumber: string;
  payableAmount: number;
  handlePaymentSuccess: () => void;
}

interface PropsType {
  paymentDetails: PaymentDetails;
  handlePayNow?: () => void;
  disabled?: boolean;
}

export function PayNow({ paymentDetails, handlePayNow, disabled = false }: PropsType) {
  const { setOpenPaymentModal, hasPaymentAccess } = usePayment();
  const { openPaymentModal } = useSelector((state: RootStateType) => state.shipment);

  const handleClick = () => {
    if (disabled) return;
    if (handlePayNow) {
      handlePayNow();
    } else {
      setOpenPaymentModal(true);
    }
  };

  if (!hasPaymentAccess || paymentDetails.payableAmount <= 0) return <></>;
  return (
    <>
      {openPaymentModal && (
        <PaymentModal
          isOpen={openPaymentModal}
          handleClose={() => setOpenPaymentModal(false)}
          paymentDetails={paymentDetails}
        />
      )}

      <SbButton variant="contained" className="w-full mt-4" onClick={handleClick} disabled={disabled}>
        Pay Now
      </SbButton>
    </>
  );
}
