import FilterSelect from '@Components/Filter/Dropdown';
import Image from '@Components/Image';
import { RootStateType, AppDispatch } from '@Store';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInUserSystemList } from '../../store/reducers/Systems';
import API from '@Services/apiAxios';
import { AnalyticsData } from './types/type';
import { formatNumber, useLocale } from '../../utils/formatNumber';

const TopPerformanceView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [system, setSystem] = useState<string[]>([]);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(null);
  const locale = useLocale();

  // const [loading, setLoading] = useState<boolean>(true);

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
  };

  useEffect(() => {
    dispatch(getLoggedInUserSystemList());
  }, []);

  const fetchAnalyticsStats = async () => {
    try {
      const currentDate = new Date().toISOString();
      const response = await API.get('/analytics/stats/today', {
        params: {
          date: currentDate,
          'system[]': system,
        },
      });
      setAnalyticsData(response.data.data);
    } catch (error) {
      console.error('Error fetching analytics stats:', error);
    }
  };

  // console.info('analyticsData:', analyticsData);

  useEffect(() => {
    fetchAnalyticsStats();
  }, [system]);

  return (
    <Box
      sx={{
        borderRadius: '9px',
        border: '1px solid #F1F1F1',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        padding: '20px 40px',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Box
          sx={{
            width: { xs: '100%', sm: 'auto' },
            marginBottom: { xs: '20px', sm: '0' },
          }}
        >
          <FilterSelect
            id="system"
            label="Select system"
            options={loggedInUserSystemDropdownList}
            value={system}
            onChange={onSystemFilter}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box
          sx={{
            flexBasis: { xs: '100%', sm: '20%' },
            display: 'flex',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: '500',
          }}
        >
          <p style={{ color: 'rgba(0, 0, 0, 1)', position: 'relative', bottom: '40px' }}>Today's Performance</p>
        </Box>
        <Box
          sx={{
            flexBasis: { xs: '100%', sm: '80%' },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
            color: 'rgba(31, 30, 97, 1)',
          }}
        >
          <Box sx={{ display: 'flex', gap: '14px', flexDirection: 'row', marginRight: '70px' }}>
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor: analyticsData?.revenuePercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      analyticsData?.revenuePercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : `/images/icons/AdminHome/downArrow.svg`
                    }
                    alt="revenue"
                  />
                </Box>
                <Box sx={{ color: analyticsData?.revenuePercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303' }}>
                  {formatNumber(analyticsData?.revenuePercentage, locale)}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px' }}>
                {analyticsData?.totalShipment && formatNumber(analyticsData?.totalShipment, locale)}
              </p>
              <p style={{ font: '500', fontSize: '20px' }}>Shipment</p>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '14px', flexDirection: 'row', marginTop: { xs: '30px', sm: '0' } }}>
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end' }}
              src="/images/icons/AdminHome/weight.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor: analyticsData?.weightPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      analyticsData?.weightPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box sx={{ color: analyticsData?.weightPercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303' }}>
                  {formatNumber(analyticsData?.weightPercentage, locale)}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px' }}>
                {analyticsData?.totalWeight && formatNumber(analyticsData?.totalWeight, locale)}
              </p>
              <p style={{ font: '500', fontSize: '20px' }}>Total Weight (KG)</p>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: '14px', flexDirection: 'row', marginTop: { xs: '30px', sm: '0' } }}>
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end' }}
              src="/images/icons/AdminHome/revenue.png"
              alt="revenue"
            />

            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor: analyticsData?.shipmentPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      analyticsData?.shipmentPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box sx={{ color: analyticsData?.shipmentPercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303' }}>
                  {formatNumber(analyticsData?.shipmentPercentage, locale)}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px' }}>
                {analyticsData?.totalRevenue && formatNumber(analyticsData?.totalRevenue, locale)}
              </p>
              <p style={{ font: '500', fontSize: '20px' }}>Total Revenue (INR)</p>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TopPerformanceView;
