import Modal from '@Components/Modal';
import Title from '@Components/Title';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Grid, Radio, Stack, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import FedEx from './CarrierAccess/FedEx';
import { getVendorsAccount } from '@Reducers/AdminShipment/actions';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@Store';
import { CSP_TYPES, CarrierAccessType } from '@Constants/Shipment';
import DHLForm from './CarrierAccess/DHLForm';
import Aramex from './CarrierAccess/Aramex';

type TabValues = 'FedEx' | string;

interface CarrierSettingProps {
  open: string;
  onClose: () => void;
  carrierAccessType: string;
}

const CarrierSettingModal = (props: CarrierSettingProps) => {
  const [currentTap, setCurrentTap] = useState<TabValues>(CSP_TYPES.fedex.value);
  const dispatch = useDispatch<AppDispatch>();
  const handleClose = () => {
    props.onClose();
  };

  const onTabChange = (_e: React.SyntheticEvent, currentTab: TabValues) => {
    setCurrentTap(currentTab);
  };

  const carrierType = props?.carrierAccessType as CarrierAccessType;
  useEffect(() => {
    if (props.open) {
      dispatch(getVendorsAccount(CSP_TYPES[carrierType]?.value || props.open));
    }
    setCurrentTap(CSP_TYPES[carrierType]?.value);
  }, [currentTap, props.open]);
  return (
    <>
      <Modal open={!!props.open} onClose={handleClose} maxWidth="md">
        <Title title="Carrier Setting" />
        <TabContext value={currentTap || props.open}>
          <Grid container sx={{ borderBottom: '1px solid #E4E4E4', marginBottom: '20px' }}>
            <TabList
              onChange={onTabChange}
              indicatorColor="primary"
              TabIndicatorProps={{ sx: { height: '4px' } }}
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab
                sx={{ textTransform: 'none', padding: '16px 16px' }}
                className="md-text font-medium sb-text-black"
                value={CSP_TYPES[carrierType]?.value}
                label={
                  <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                    <Radio checked={currentTap === CSP_TYPES[carrierType]?.value} />
                    <p>{CSP_TYPES[carrierType]?.display}</p>
                  </Stack>
                }
              />

              {/* <Tab
                sx={{ textTransform: 'none', padding: '16px 16px' }}
                className="md-text font-medium sb-text-black"
                value={CSP_TYPES.dhl.value}
                label={
                  <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                    <Radio checked={currentTap === CSP_TYPES.dhl.value} />
                    <p>{CSP_TYPES.dhl.display}</p>
                  </Stack>
                }
              /> */}
            </TabList>
          </Grid>

          <TabPanel value={CSP_TYPES[carrierType]?.value}>
            {props?.carrierAccessType === CSP_TYPES?.fedex?.value && <FedEx onCancel={props.onClose} />}
            {props?.carrierAccessType === CSP_TYPES?.dhl?.value && <DHLForm onCancel={props.onClose} />}
            {props?.carrierAccessType === CSP_TYPES?.aramex?.value && (
              <Aramex isEditable={true} onClose={props.onClose} />
            )}
          </TabPanel>
          {/* <TabPanel value={CSP_TYPES.dhl.value}>
            <FedEx onCancel={props.onClose} />
          </TabPanel> */}
        </TabContext>
      </Modal>
    </>
  );
};

export default CarrierSettingModal;
