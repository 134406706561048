import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  pending,
  createManifestFulfilled,
  rejected,
  getManifestFulfilled,
  updateManifestFulfilled,
  deleteManifestFulfilled,
} from './case';
import { ManifestState } from './Type';
import { createManifest, deleteManifest, getManifest, updateManifest } from './actions';

const initialState = <ManifestState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 0,
  },
  success: '',
  singleData: null,
  successDelete: false,
};

const manifestSlice = createSlice({
  name: 'manifest',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetManifest: (state: ManifestState) => {
      state.success = '';
      state.error = initialState.error;
      state.data = initialState.data;
      state.singleData = null;
    },
    resetManifestSuccess: (state: ManifestState) => {
      state.success = '';
    },
    resetManifestDelete: (state: ManifestState) => {
      state.success = '';
      state.successDelete = false;
    },
    setManifestSingleData: (state: ManifestState, action: PayloadAction<ManifestState['singleData']>) => {
      const { payload } = action;
      // console.log([action.payload]);
      state.singleData = payload
        ? {
            ...state.singleData,
            ...payload,
            // _courierId: payload?._courierId,
          }
        : payload;
      if (!payload) state.singleData = payload;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(createManifest.pending, pending);
    addCase(createManifest.fulfilled, createManifestFulfilled);
    addCase(createManifest.rejected, rejected);

    addCase(getManifest.pending, pending);
    addCase(getManifest.fulfilled, getManifestFulfilled);
    addCase(getManifest.rejected, rejected);

    addCase(updateManifest.pending, pending);
    addCase(updateManifest.fulfilled, updateManifestFulfilled);
    addCase(updateManifest.rejected, rejected);

    addCase(deleteManifest.pending, pending);
    addCase(deleteManifest.fulfilled, deleteManifestFulfilled);
    addCase(deleteManifest.rejected, rejected);
  },
});

export const { resetManifest, resetManifestDelete, setManifestSingleData, resetManifestSuccess } =
  manifestSlice.actions;
export { createManifest, getManifest, updateManifest, deleteManifest };
export default manifestSlice.reducer;
