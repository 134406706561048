import { Box, Typography } from '@mui/material';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Customer } from './types/type';
// import SortIcon from '../../../public/images/icons/AdminHome/sortIcon.svg';
import Image from '@Components/Image';
import { formatNumber, useLocale } from '../../utils/formatNumber';

export interface Shipment {
  id: string;
  status: string;
  account: string;
}
export interface TableStyle {
  fontFamily: string;
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
  color: string;
}

const tableStyle: TableStyle = {
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: `16px`,
  lineHeight: `0`,
  color: '#181D25',
};

interface TopTenViewTableProps {
  data: Customer[];
  setCustomerShipmentSort?: any;
  setCustomerWeightSort?: any;
  setCustomerRevenueSort?: any;
  customerRevenueSort: boolean;
  customerWeightSort: boolean;
  customerShipmentSort: boolean;
}

const TopTenViewTable: React.FC<TopTenViewTableProps> = ({
  data,
  setCustomerShipmentSort,
  setCustomerRevenueSort,
  setCustomerWeightSort,
  customerWeightSort,
  customerShipmentSort,
  customerRevenueSort,
}) => {
  const handleShipmentSort = () => {
    // setCustomerShipmentSort(prev => !prev);
    setCustomerShipmentSort((prev: any) => !prev);
    setCustomerWeightSort(false);
    setCustomerRevenueSort(false);
  };
  const handleWeightSort = () => {
    setCustomerWeightSort((prev: any) => !prev);
    setCustomerShipmentSort(false);
    setCustomerRevenueSort(false);
  };
  const handleRevenueSort = () => {
    setCustomerRevenueSort((prev: any) => !prev);
    setCustomerWeightSort(false);
    setCustomerShipmentSort(false);
  };
  const locale = useLocale();

  return (
    <Box sx={{ padding: 0 }}>
      <TableContainer sx={{ border: 'none', width: '100%', boxShadow: 'none' }} component={Paper}>
        <Table sx={{ border: 'none' }}>
          <TableHead sx={{}}>
            <TableRow sx={{ borderRight: 'none' }}>
              <TableCell sx={{ ...tableStyle }}>Name</TableCell>
              <TableCell sx={{ ...tableStyle }}>Tag Admin</TableCell>
              <TableCell
                onClick={handleShipmentSort}
                sx={{
                  ...tableStyle,
                  cursor: 'pointer',
                  color: customerShipmentSort ? '#03A01C' : '',
                  fontWeight: customerShipmentSort ? 600 : 400,
                  textAlign: 'center',
                }}
              >
                Shipments
                {/* <Image src={SortIcon} alt="Sort Icon" /> */}
              </TableCell>
              <TableCell
                onClick={handleWeightSort}
                sx={{
                  ...tableStyle,
                  display: 'flex',
                  color: customerWeightSort ? '#03A01C' : '',
                  fontWeight: customerWeightSort ? 600 : 400,
                  justifyContent: 'center',
                  cursor: 'pointer',
                  textAlign: 'center',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Weight(Kg)
                  {/* <Image src={SortIcon} alt="Sort Icon" /> */}
                </Box>
              </TableCell>
              <TableCell
                onClick={handleRevenueSort}
                sx={{
                  ...tableStyle,
                  cursor: 'pointer',
                  color: customerRevenueSort ? '#03A01C' : '',
                  fontWeight: customerRevenueSort ? 600 : 400,
                  textAlign: 'center',
                }}
              >
                Revenue(INR)
                {/* <Image src={SortIcon} alt="Sort Icon" /> */}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map(customer => (
              <TableRow key={customer._id}>
                <TableCell
                  sx={{
                    ...tableStyle,
                    color: '#929FB1',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '150px',
                  }}
                >
                  {customer?.name ? customer?.name : 'N/A'}
                </TableCell>
                <TableCell
                  sx={{
                    ...tableStyle,
                    color: '#929FB1',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '150px',
                  }}
                >
                  {customer._belongToAdminId?.name ? customer._belongToAdminId?.name : 'N/A'}
                </TableCell>
                <TableCell sx={{ color: '#929FB1', textAlign: 'center' }}>
                  <Box>
                    <Box>
                      <Typography sx={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>
                        {formatNumber(customer.totalShipment, locale)}{' '}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',
                          backgroundColor: customer.shipmentPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: customer.shipmentPercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            customer.shipmentPercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(customer.shipmentPercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', color: '#929FB1' }}>
                  <Box>
                    <Box>
                      <Typography sx={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>
                        {formatNumber(customer.totalWeight, locale)}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',
                          backgroundColor: customer.weightPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: customer.weightPercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            customer.weightPercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(customer.weightPercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: 'center', color: '#929FB1' }}>
                  <Box sx={{ marginLeft: '' }}>
                    <Box>
                      <Typography sx={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>
                        {formatNumber(customer.totalRevenue, locale)}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'red',
                          borderRadius: '5px',
                          padding: '4px 6px',
                        }}
                        style={{
                          width: 'fit-content',
                          margin: 'auto',
                          backgroundColor: customer.revenuePercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                          color: customer.revenuePercentage >= 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                        }}
                      >
                        <Image
                          style={{ width: '8px', height: '10px', marginRight: '5px' }}
                          src={
                            customer.revenuePercentage >= 0
                              ? '/images/icons/AdminHome/upArrow.svg'
                              : `/images/icons/AdminHome/downArrow.svg`
                          }
                          alt="revenue"
                        />
                        {formatNumber(customer.revenuePercentage, locale)}%
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TopTenViewTable;
