import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@mui/material';

import { AppDispatch, RootStateType } from '../../../store';

import FilterSelect from '@Components/Filter/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import { ServiceObj } from '@Reducers/CarrierAndService/Type';
import { clearFilters, updateFilters } from '@Reducers/ODA';
import SbButton from '@Components/Button';

type GetOptionsArr<keys extends string> = {
  [displayKey in keys]: string;
};

function getOptionFromList<D extends string, I extends string>(
  arr: GetOptionsArr<D | I>[],
  displayKey: D,
  idKey: I,
): DropdownOptionType[] {
  const optionsList = arr.map(item => ({ display: item[displayKey], value: item[idKey] }));
  return optionsList;
}

function OdaFilter() {
  const [cspOptions, setCspOptions] = useState<DropdownOptionType[]>([]);
  const [cspValues, setCspValues] = useState<string[]>([]);
  const CspList: ServiceObj[] = useSelector<RootStateType>(
    state => state.carrierAndService.selectedServiceList,
  ) as ServiceObj[];

  // const CspList: ServiceObj[] = useSelector<RootStateType>(
  //   state => state.carrierAndService.serviceList,
  // ) as ServiceObj[];

  const dispatch = useDispatch<AppDispatch>();

  useEffect(
    function () {
      dispatch(updateFilters({ csp: cspValues }));
    },
    [cspValues],
  );

  useEffect(
    function () {
      setCspOptions(getOptionFromList(CspList, 'name', '_id'));
    },
    [CspList, setCspOptions],
  );
  const onClearFilter = () => {
    setCspValues([]);
    dispatch(clearFilters());
  };
  return (
    <Grid container gap={'20px'} justifyContent={'flex-end'} className="mb-2">
      <Grid item justifySelf={'flex-end'}>
        <FilterSelect
          id="csp-filter"
          value={cspValues}
          options={cspOptions}
          label="Service"
          onChange={selected => setCspValues(selected)}
        />
      </Grid>
      <Grid item>
        <SbButton
          variant="outlined"
          onClick={onClearFilter}
          className="sm-text"
          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
        >
          Clear Filters
        </SbButton>
      </Grid>
    </Grid>
  );
}

export default OdaFilter;
