import { LoginUserStateType, UserPayloadType } from './Type';

export function getLoggedInUserFulfilled(state: LoginUserStateType, data: UserPayloadType) {
  if (!state.pageLoaded) state.pageLoaded = true;
  const {
    payload: { tokenResult, profileResult },
  } = data;
  state.loading = false;
  state.token = tokenResult;
  state.profile = profileResult;
}

export function getLoggedInUserRejected(state: LoginUserStateType) {
  Object.assign(state, {
    loading: false,
    isError: true,
    isSuccess: false,
    pageLoaded: true,
    // message: payload?.error?.msg || payload?.error?.message,
  });
}

export function getLoggedInUserPending(state: LoginUserStateType) {
  Object.assign(state, {
    loading: true,
    isError: false,
    isSuccess: false,
  });
}

// -------------------------------------------------------------------------
export function updateUserProfileFulfilled(
  state: LoginUserStateType,
  data: { payload: LoginUserStateType['profile'] },
) {
  const { payload } = data;
  state.loading = false;
  state.profile = payload;
}

export function updateUserProfileRejected(state: LoginUserStateType) {
  Object.assign(state, {
    loading: false,
    isError: true,
    isSuccess: false,
    // message: payload?.error?.msg || payload?.error?.message,
  });
}

export function updateUserProfilePending(state: LoginUserStateType) {
  Object.assign(state, {
    loading: true,
    isError: false,
    isSuccess: false,
  });
}

// -------------------------------------------------------------------------
export function getCompanyDetailsFulfilled(
  state: LoginUserStateType,
  data: { payload: LoginUserStateType['companyDetails'] },
) {
  const { payload } = data;
  state.companyDetailsLoading = false;
  state.companyDetails = payload;
}

export function getCompanyDetailsRejected(state: LoginUserStateType) {
  Object.assign(state, {
    companyDetailsLoading: false,
    isError: true,
    isSuccess: false,
    // message: payload?.error?.msg || payload?.error?.message,
  });
}

export function getCompanyDetailsPending(state: LoginUserStateType) {
  Object.assign(state, {
    companyDetails: null,
    companyDetailsLoading: true,
    isError: false,
    isSuccess: false,
  });
}

// -------------------------------------------------------------------------
export function updateCompanyDetailsFulfilled(
  state: LoginUserStateType,
  data: { payload: LoginUserStateType['companyDetails'] },
) {
  const { payload } = data;
  state.companyDetailsLoading = false;
  state.companyDetails = payload;
}

export function updateCompanyDetailsRejected(state: LoginUserStateType) {
  Object.assign(state, {
    companyDetailsLoading: false,
    isError: true,
    isSuccess: false,
    // message: payload?.error?.msg || payload?.error?.message,
  });
}

export function updateCompanyDetailsPending(state: LoginUserStateType) {
  Object.assign(state, {
    companyDetailsLoading: true,
    isError: false,
    isSuccess: false,
  });
}

// -------------------------------------------------------------------------
export function updatePasswordFulfilled(state: LoginUserStateType) {
  state.password = {
    loading: false,
    isUpdated: true,
  };
}

export function updatePasswordRejected(state: LoginUserStateType) {
  state.password.loading = false;
}

export function updatePasswordPending(state: LoginUserStateType) {
  state.password = {
    loading: true,
    isUpdated: false,
  };
}

// ------------------------------------------------------------------------- TAX Setting
export function getTaxSettingFulfilled(state: LoginUserStateType, data: { payload: { data: string[] } }) {
  state.loading = false;
  state.setting.tax = data.payload.data.map(tax => ({ label: tax, required: true }));
}

export function getTaxSettingRejected(state: LoginUserStateType) {
  Object.assign(state, {
    loading: false,
    isError: true,
    isSuccess: false,
    // message: payload?.error?.msg || payload?.error?.message,
  });
}

export function getTaxSettingPending(state: LoginUserStateType) {
  Object.assign(state, {
    loading: true,
    isError: false,
    isSuccess: false,
  });
}
