import UserReducer from './User';
import loginUser from './loginUser';
import Signup from './Signup';
import CounterReducer from './Counter';
import LoginFormReducer from './LoginForm';
import GlobalReducer from './Global';
import SavedAddressReducer from './SavedAddress';
import GetRatesReducer from './GetRates';
import ShipmentReducer from './Shipment';
import ShipmentHistoryReducer from './ShipmentHistory';
import InvoiceListReducer from './InvoiceList';
import InvoiceReducer from './Invoice';
import D365Reducer from './d365';
import BulkOrderSummaryReducer from './BulkOrderSummary';
import OrgSetupReducer from './OrganizationSetup';
import WarehouseReducer from './Warehouse';
import SystemReducer from './Systems';
import AdminReducer from './Admin';
import RateSummaryReducer from './RateSummary';
import RateUploadReducer from './RateUpload';
import PincodeReducer from './pincode';
import CarrierAndServiceReducer from './CarrierAndService';
import CarrierReducer from './Carrier';
import OdaReducer from './ODA';
import Tracking from './Tracking';
import AccountLookup from './AccountLookup';
import ManifestReducer from './Manifest';
import AdminShipment from './AdminShipment';
import GlobalChargeReducer from './GlobalCharge';
import WalletReducer from './Wallet';
import TransactionHistory from './TransactionHistory';
import ContractReducer from './Contract';
import KycReducer from './kyc';
import SuccessErrorModalReducer from './SuccessErrorModal';
import AgreementReducer from './Agreement';
import DraftShipmentReducer from './DraftShipment';
import UnprocessedShipmentReducer from './Unprocessed';
import CreditReducer from './Credit';
// import AppSecretReducer from './AppSecret';
// import AppConfigReducer from './AppConfig';
import AccessGroupReducer from './AccessGroup';
import NotificationsReducer from './Notifications';
import SquadManagementReducer from './Squad';
import carrierReducer from './../reducers/integration/index';
import shipmentReportsReducer from './../reducers/shipmentReportsDownloadSlice/shipmentReportsDownloadSlice';
import userReportsReducer from './../reducers/userReportsSlice/userReportsSlice';
import booleanReducer from './../reducers/booleanSlice/booleanSlice';
import downloadTransactionReducer from './../reducers/DownloadTransactionReport/DownloadTransactionReportSlice';
import downloadInvoiceReducer from './../reducers/invoiceSliceReport/invoiceSliceReport';
import downloadUserInvoiceReducer from './../reducers/userInvoiceSlice/userInvoiceSlice';
import squadReportDownloadReducer from './../reducers/squadReports/squadReportSlice';
import downloadSquadShipmentReportReducer from './../reducers/downloadSquadShipmentReport/downloadSquadShipmentReport';
import adminExportReportReducer from './../reducers/AdminReport/adminExportSlice';
import postpaidUserExportReportReducer from './../reducers/PostpaidUserReport/PostpaidUserReport';
import squadManagementExportReportReducer from './../reducers/SquadManagementReport/SquadManagementReport';
import basePricingReportReducer from './../reducers/BasePricingReport/BasePricingReport';

const rootReducer = {
  global: GlobalReducer,
  getRates: GetRatesReducer,
  user: UserReducer,
  loginUser: loginUser,
  signupForm: Signup,
  counter: CounterReducer,
  loginForm: LoginFormReducer,
  savedAddress: SavedAddressReducer,
  shipment: ShipmentReducer,
  shipmentHistory: ShipmentHistoryReducer,
  trackShipment: Tracking,
  InvoiceList: InvoiceListReducer,
  Invoice: InvoiceReducer,
  D365: D365Reducer,
  bulkOrderSummary: BulkOrderSummaryReducer,
  orgSetup: OrgSetupReducer,
  Warehouse: WarehouseReducer,
  System: SystemReducer,
  admin: AdminReducer,
  RateSummary: RateSummaryReducer,
  RateUpload: RateUploadReducer,
  pincode: PincodeReducer,
  oda: OdaReducer,
  carrierAndService: CarrierAndServiceReducer,
  carrier: CarrierReducer,
  accountLookup: AccountLookup,
  manifest: ManifestReducer,
  adminShipment: AdminShipment,
  globalCharge: GlobalChargeReducer,
  Wallet: WalletReducer,
  transactionHistory: TransactionHistory,
  Credit: CreditReducer,
  contract: ContractReducer,
  kyc: KycReducer,
  successErrorModal: SuccessErrorModalReducer,
  Agreement: AgreementReducer,
  DraftShipment: DraftShipmentReducer,
  UnprocessedShipment: UnprocessedShipmentReducer,
  accessGroup: AccessGroupReducer,
  notifications: NotificationsReducer,
  squadManagement: SquadManagementReducer,
  carrierIntegration: carrierReducer,
  shipmentReportsDownload: shipmentReportsReducer,
  userReports: userReportsReducer,
  booleanState: booleanReducer,
  downloadTransactionReport: downloadTransactionReducer,
  downloadInvoiceReport: downloadInvoiceReducer,
  downloadUserInvoiceReport: downloadUserInvoiceReducer,
  downloadSquadReports: squadReportDownloadReducer,
  downloadSquadShipmentReports: downloadSquadShipmentReportReducer,
  adminExportReports: adminExportReportReducer,
  postpaidExportReports: postpaidUserExportReportReducer,
  squadManagementReport: squadManagementExportReportReducer,
  basePricingReport: basePricingReportReducer,
};

export default rootReducer;
