import { TabPanel } from '@mui/lab';
import { Grid } from '@mui/material';
import React from 'react';
import SACCode from './SacCode';
import TaxInformation from './TaxInformation';
import { OrganizationSetupContextType } from '../../../../types/organization';
import { SectionWrapper } from '../../Style';
import GenerateInvoices from './GenerateInvoices';

interface PropsType {
  taxInformation: OrganizationSetupContextType['taxInformation'];
  onChangeTaxInformation: OrganizationSetupContextType['setTaxInformation'];
  sacCode: OrganizationSetupContextType['sacCode'];
  onChangeSacCode: OrganizationSetupContextType['setSacCode'];
  generateInvoices: string[];
  onchangeGenerateInvoices: React.Dispatch<React.SetStateAction<string[]>>;
}

function TaxInformationMain(props: PropsType) {
  const {
    taxInformation,
    onChangeTaxInformation,
    sacCode,
    onChangeSacCode,
    generateInvoices,
    onchangeGenerateInvoices,
  } = props;
  return (
    // <TabPanel value="tax_information" className="p-0 w-full">
    <TabPanel value="billing" className="p-0 w-full">
      <Grid container>
        <SectionWrapper>
          <GenerateInvoices data={generateInvoices} onchange={onchangeGenerateInvoices} />
          <TaxInformation data={taxInformation} onChange={onChangeTaxInformation} />
          <SACCode data={sacCode} onChange={onChangeSacCode} />
        </SectionWrapper>
        {/* </Grid> */}
      </Grid>
    </TabPanel>
  );
}

export default TaxInformationMain;
