import SbButton from '@Components/Button';
import ExportBtn from '@Components/Button/Export';
import DateRangePicker from '@Components/DateRangePicker';
import FilterSelect from '@Components/Filter/Dropdown';
import FilterSearch from '@Components/Filter/Search';
import Loader from '@Components/Loader';
import { SubHeading } from '@Components/Shipment/Summary/Styles';
import { SBTable, SBTableWrapper } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import Title from '@Components/Title';
import useAccountLookup from '@Hook/useAccountLookup';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CreditIcon from '@mui/icons-material/CreditScoreOutlined';
import BalanceIcon from '@mui/icons-material/PaymentsOutlined';
import { Divider, Grid, Stack, TableBody, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { TransactionObj } from '@Reducers/TransactionHistory/Type';
import {
  getOutstandingBalanceForPrepaid,
  getOutstandingBalanceInvoiceForPrepaid,
  getUserTransactionList,
  getWalletBalance,
  getWalletTransactionList,
} from '@Reducers/Wallet/actions';
import { WalletHistoryQuery } from '@Reducers/Wallet/Type';
import { userLogin } from '@Services/hasAdminAccess';
import { AppDispatch, RootStateType } from '@Store';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreditWalletSearchFilterList, PaymentModeList, StatusList } from '../constant';

import { USER_STATUS_COLOR } from '@Constants/status';
import {
  AmountTypo,
  CreditedIcon,
  CreditWalletTableActionContainer,
  DebitedIcon,
  IconStyle,
  NavigationBtn,
  NavigationBtnContainer,
  TransactionStatus,
} from '../style';
import { FilterObj, TransactionModes } from '../Types';
import AddMoneyModal from './AddMoneyModal';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { fetchExportData } from '@Reducers/userInvoiceSlice/userInvoiceSlice';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { formatNumber, useLocale } from '../../../utils/formatNumber';
interface TrasactionHistoryQuery {
  fromDate: string;
  toDate: string;
  currentPage: number;
  perPage: number;
  mode?: string[];
  status?: string[];
  searchKey?: string;
  searchValue?: string;
}

function TransactionTable() {
  const {
    data: { list, currentPage, perPage },
  } = useSelector((state: RootStateType) => state.Wallet);
  const orgCountryCurrency = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name || '');
  const locale = useLocale();

  function getTransactionType(obj: TransactionObj) {
    const label =
      obj.paymentFor === 'shipment' ? 'Shipment No' : obj.paymentFor === 'invoice' ? 'Invoice No' : 'Wallet';
    return (
      <div>
        <Typography>{label}</Typography>
        <Typography>{obj.paymentForNumber}</Typography>
      </div>
    );
  }

  const getDisplayValue = (item: any): string => {
    const mode = item?._transactionId?.mode || item?.mode;
    return PaymentModeList[mode as TransactionModes]?.display || '';
  };

  return (
    <SBTableWrapper>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell style={{ minWidth: '80px' }}>Sr No.</TableHeadCell>
            <TableHeadCell align="left" sx={{ minWidth: '250px' }}>
              Mode
            </TableHeadCell>
            <TableHeadCell align="left" sx={{ minWidth: '200px' }}>
              Transaction Type
            </TableHeadCell>
            <TableHeadCell align="left" sx={{ minWidth: '250px' }}>
              Transaction ID
            </TableHeadCell>
            <TableHeadCell sx={{ minWidth: '160px' }}>Date</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '200px' }}>Amount</TableHeadCell>
            <TableHeadCell sx={{ minWidth: '180px' }}>Status</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.length
            ? list.map((item, index) => {
                return (
                  <SBTableRow key={item._id}>
                    <TableRowCell align="center" className="md-text">
                      <div>{index + 1 + (currentPage - 1) * perPage}</div>
                    </TableRowCell>
                    <TableRowCell>
                      <div className="flex">
                        {(item?._transactionId?.type || item?.type) === 'debit' ? (
                          <DebitedIcon>
                            <CallMadeIcon />
                          </DebitedIcon>
                        ) : (
                          <CreditedIcon>
                            <CallReceivedIcon />
                          </CreditedIcon>
                        )}
                        <Typography className="md-text">
                          {/* {PaymentModeList[item?._transactionId?.mode || item?.mode].display} */}
                          {getDisplayValue(item?._transactionId?.mode || item?.mode)}
                        </Typography>
                      </div>
                    </TableRowCell>
                    <TableRowCell className="sm-text">
                      {getTransactionType(
                        item?._transactionId || {
                          paymentFor: item?.paymentFor,
                          paymentForNumber: item?.paymentForNumber,
                        },
                      )}
                    </TableRowCell>
                    <TableRowCell className="sm-text">{item?._transactionId?._id || item?._id || '--'}</TableRowCell>
                    <TableRowCell className="sm-text" align="center">
                      {moment(item.createdAt).format('DD/MM/YYYY')}
                    </TableRowCell>
                    <TableRowCell className="sm-text" align="center">
                      <AmountTypo type={item?._transactionId?.type || item?.type}>
                        {formatNumber(item.amount, locale)} {orgCountryCurrency}
                      </AmountTypo>
                    </TableRowCell>
                    <TableRowCell align="center" className="sm-text">
                      <TransactionStatus
                        bgcolor={USER_STATUS_COLOR[item.status].bgColor}
                        color={USER_STATUS_COLOR[item.status].textColor}
                      >
                        {USER_STATUS_COLOR[item.status]?.text}
                      </TransactionStatus>
                    </TableRowCell>
                  </SBTableRow>
                );
              })
            : null}
        </TableBody>
      </SBTable>
    </SBTableWrapper>
  );
}

export function Navigation(props: { selectedUser: UserObj | null; refetch: () => void; disabled?: boolean }) {
  const { currentBalance, totalInvoiceAmtPaid, totalShipmentAmt, totalShipmentAmtPaid, totalInvoiceAmt } = useSelector(
    (state: RootStateType) => state.Wallet,
  );
  const orgCountryCurrency = useSelector(
    (state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.symbol || '',
  );
  const loginUser = useSelector((state: RootStateType) => state.loginUser);
  const locale = useLocale();

  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  const isPrepaid = isAdmin ? props.selectedUser?._accountId?.isPrepaid : loginUser?.profile?._accountId?.isPrepaid;
  const [addMoneyModal, setAddMoneyModal] = useState<boolean>(false);

  const hadAddMoneyOption = !!isPrepaid;
  const outStandingBalanceResult = totalShipmentAmt + totalInvoiceAmt - (totalInvoiceAmtPaid + totalShipmentAmtPaid);

  return (
    <NavigationBtnContainer>
      {addMoneyModal && (
        <AddMoneyModal
          selectedUser={props.selectedUser}
          onClose={() => {
            setAddMoneyModal(false);
            props?.refetch?.();
          }}
        />
      )}
      <NavigationBtn>
        <BalanceIcon style={IconStyle} />
        <div className="ml-4">
          <Typography className="sm-text">Outstanding balance</Typography>
          <Typography className="md-text">
            {/* {orgCountryCurrency} {outstandingBalance} */}
            {orgCountryCurrency} {formatNumber(outStandingBalanceResult, locale)}
          </Typography>
        </div>
      </NavigationBtn>
      <NavigationBtn>
        <CreditIcon style={IconStyle} />
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          className="ml-1"
          width={'100%'}
        >
          <div>
            <Typography className="sm-text">Wallet Balance</Typography>
            <Typography className="md-text">
              {orgCountryCurrency} {formatNumber(currentBalance, locale) || 0}
            </Typography>
          </div>
          {hadAddMoneyOption ? (
            <SbButton
              className="sm-text"
              variant="outlined"
              sx={{ borderRadius: '8px !important' }}
              onClick={() => {
                setAddMoneyModal(true);
              }}
              disabled={props.disabled}
            >
              Add Money
            </SbButton>
          ) : null}
        </Stack>
      </NavigationBtn>
    </NavigationBtnContainer>
  );
}

export default function WalletHistory({ user }: { user?: UserObj }) {
  const modalId = 'wallet';
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();

  const {
    loading: accountLookupLoader,
    onOpen: openAccountLookupModal,
    resetSelectedUser,
    handleSetUserQuery,
  } = useAccountLookup(modalId, true);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isAddMoneyEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Credits_And_Wallet.add_money,
    PERFORM_ACTION.write,
  );

  const isExportDataEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Credits_And_Wallet.export_data,
    PERFORM_ACTION.write,
  );

  const [accountUser, setAccountUser] = useState<UserObj | null>(null);

  useEffect(() => {
    if (user && !accountUser) {
      setAccountUser(user);
    }
  }, [user]);

  // const getSelectedUser = (user: UserObj) => {
  //   setAccountUser(user);
  // };

  const [filterSelectValue, setFilterSelectValue] = useState('shipmentNumber');
  const { limit } = useSelector((state: RootStateType) => state.notifications);

  const [filterText, setFilterText] = useState('');
  const [mode, setMode] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [loader, setLoader] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const {
    data: { currentPage = 1, perPage = 10, totalCount },
  } = useSelector((state: RootStateType) => state.Wallet);

  const loading = useSelector((state: RootStateType) => state.transactionHistory.loading || state.Wallet.loading);
  const loginUserId = useSelector((state: RootStateType) => state.loginUser?.profile?._id);

  function getWalletListData(filterParams: WalletHistoryQuery) {
    if (accountUser?._id) {
      dispatch(getWalletTransactionList([accountUser?._id, filterParams]));
    } else {
      dispatch(getUserTransactionList(filterParams));
    }
  }

  useEffect(() => {
    if (isAdmin) {
      resetSelectedUser();
      openAccountLookupModal();
      handleSetUserQuery({ accountType: ['prepaid'] });
    } else {
      getWalletListData(getFilterObj({}));
      dispatch(getWalletBalance());
      dispatch(getOutstandingBalanceForPrepaid());
    }
  }, [isAdmin]);

  useEffect(() => {
    if (accountUser) {
      getWalletListData(getFilterObj({}));
      dispatch(getWalletBalance(accountUser?._id));
      dispatch(getOutstandingBalanceForPrepaid(accountUser?._id));
      dispatch(getOutstandingBalanceInvoiceForPrepaid(accountUser?._id));
    }
  }, [accountUser]);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    getWalletListData(getFilterObj({ dateRange }));
  };

  const onStatusFilterChange = (selected: Array<string>) => {
    setStatus(selected);
    getWalletListData(getFilterObj({ status: selected }));
  };

  const onModeFilterChange = (selected: Array<string>) => {
    setMode(selected);
    getWalletListData(getFilterObj({ mode: selected }));
  };

  const getFilterObj = (options: FilterObj): TrasactionHistoryQuery => {
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterMode = options.mode || mode;
    const filterStatus = options.status || status;

    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const payload: TrasactionHistoryQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      mode: filterMode,
      status: filterStatus,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };

    if (!filterSearchValue) {
      delete payload.searchKey;
      delete payload.searchValue;
    }

    return payload;
  };

  const handleExport = async () => {
    try {
      setLoader(true);

      const accountUserId = accountUser?._id ? accountUser?._id : loginUserId;
      const currentPage = getFilterObj({}).currentPage;
      const perPage = getFilterObj({}).perPage;
      const fromDate = getFilterObj({}).fromDate;
      const toDate = getFilterObj({}).toDate;

      dispatch(fetchExportData({ accountUserId, fromDate, toDate, currentPage, perPage }));
      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit,
        }),
      );
      dispatch(getAllReadNotifications({ page: 1, limit }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Download in Progress',
          subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
          btnText: 'Close',
          onConfirm: () => {
            dispatch(toggleBoolean());
            dispatch(SuccessErrorModalReset());
          },
        }),
      );
    } catch (error) {
      console.info('Error:', error);
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <Loader loading={accountLookupLoader || loading} overly>
      <>
        {!isAdmin && <Title title="Wallets and transactions" removeBorder />}
        {/* {isAdmin && (
          <>
            <Grid container className="rounded-lg p-4 shadow mt-3 mb-4">
              <Grid item xs={12} md={6} lg={4} xl={3} className="mb-3">
                <AccountLookup modalId={modalId} showSearchInput={true} getSelectedUser={getSelectedUser}>
                  <Title title="Transactions" removeBorder />
                </AccountLookup>
              </Grid>
              <Grid xs={12}>
                <Divider className="mt-2 mb-4" />
              </Grid>
              {accountUser && <UserDetailCard user={accountUser} />}
            </Grid>
            <Divider />
          </>
        )} */}
        <SubHeading className="mb-2 mt-3">Wallet Details</SubHeading>
        <Navigation
          selectedUser={accountUser}
          refetch={() => {
            getWalletListData(getFilterObj({}));
          }}
          disabled={!isAddMoneyEditAllowed}
        />
        <Divider className="my-4" />
        <Grid container justifyContent="space-between" mt={'3rem'} sx={{ mb: { xs: '20px', sm: 0 } }}>
          <SubHeading className="font-medium">All transactions</SubHeading>
          <Loader loading={loader} />
          <ExportBtn
            iconSrc="/images/file_download.svg"
            width={25}
            alt="Export"
            label="Export"
            // onClick={console.log}
            disabled={!isExportDataEditAllowed}
            onClick={() => {
              handleExport();
            }}
          />
        </Grid>
        <CreditWalletTableActionContainer>
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={CreditWalletSearchFilterList}
            selectValue={filterSelectValue}
            textValue={filterText}
            onTextChange={e => {
              setFilterText(e.target.value);
            }}
            onTextSearch={() => {
              getWalletListData(getFilterObj({}));
            }}
            onTextClear={() => {
              setFilterText('');
              getWalletListData(getFilterObj({ searchValue: undefined }));
            }}
          />
          <div></div>
          <DateRangePicker onChange={onDateChange} value={dateRange} />
          <FilterSelect
            id="mode"
            label="Mode"
            value={mode}
            options={Object.values(PaymentModeList)}
            onChange={onModeFilterChange}
          />
          <FilterSelect
            id="status"
            label="Status"
            value={status}
            options={Object.values(StatusList)}
            onChange={onStatusFilterChange}
          />
          <SbButton
            className="xs-text ml-1"
            variant="outlined"
            sx={{ borderRadius: '8px !important', padding: '8px !important' }}
            onClick={() => {
              setFilterText('');
              setMode([]);
              setStatus([]);
              setDateRange({
                startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
              });
              getWalletListData(
                getFilterObj({
                  status: [],
                  searchValue: undefined,
                  dateRange: {
                    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                    endDate: moment().endOf('day').toDate(),
                  },
                  mode: [],
                  currentPage: 1,
                }),
              );
            }}
          >
            Clear Filters
          </SbButton>
        </CreditWalletTableActionContainer>
        <TransactionTable />
        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage - 1}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                getWalletListData(getFilterObj({ currentPage: page + 1 }));
              }}
              onRowsPerPageChange={event => {
                getWalletListData(getFilterObj({ perPage: parseInt(event.target.value) }));
              }}
            />
          </>
        )}
      </>
    </Loader>
  );
}
