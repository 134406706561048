import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import API from '../../../services/apiAxios';
import { SuccessPayload } from '../../../types';
import {
  CreateCarrierPayload,
  CarrierListQuery,
  CarrierListResponse,
  ErrorType,
  CarrierCreateResponse,
  CarrierType,
} from './Type';

const getCarrierList = createAsyncThunk<CarrierListResponse, CarrierListQuery | undefined>(
  'getCarrierList',
  async (queryParams, { rejectWithValue }) => {
    try {
      const res = await API.get(`/organization/carrier?`, { params: queryParams });
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const createCarrier = createAsyncThunk<CarrierCreateResponse, CreateCarrierPayload, { rejectValue: ErrorType }>(
  'createCarrier',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await API.post(`/organization/carrier`, payload);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const getCarrierById = createAsyncThunk<CarrierType, string, { rejectValue: ErrorType }>(
  'getCarrierById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await API.get(`/organization/carrier/${id}`);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

const updateCarrierById = createAsyncThunk<SuccessPayload, [string, CreateCarrierPayload], { rejectValue: ErrorType }>(
  'updateCarrierById',
  async ([id, payload]: [string, CreateCarrierPayload], { rejectWithValue }) => {
    try {
      const res = await API.put(`/organization/carrier/${id}`, payload);
      return res.data;
    } catch (e) {
      const error: AxiosError<ErrorType> = e;
      if (!error.response) throw error;
      return rejectWithValue({ ...error.response.data });
    }
  },
);

export { getCarrierList, createCarrier, getCarrierById, updateCarrierById };
