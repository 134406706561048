import { Grid } from '@mui/material';
import React from 'react';
import SbButton from '../../../../components/Button';
import { useDispatch } from 'react-redux';
import { resetCharges } from '../../../../store/reducers/GetRates';

interface PropsType {
  setShowRates?: (show: boolean) => void;
}

function Actions({ setShowRates }: PropsType): JSX.Element {
  const dispatch = useDispatch();
  return (
    <Grid container justifyContent={{ xs: 'center', md: 'end' }} spacing={2} className="pt-2">
      <Grid container item xs={12} md={2}>
        <SbButton
          onClick={() => {
            if (setShowRates) setShowRates(false);
            dispatch(resetCharges());
          }}
          variant="outlined"
          className="rounded-full w-full"
          style={{ height: '44px', zIndex: '2' }}
        >
          Back
        </SbButton>
      </Grid>
    </Grid>
  );
}

export default Actions;
