import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { defaultBoxes, DefaultRatesInput } from '../../../pages/GetRatesV2/Constant';
import { getRates, getBuyingRates, getDefaultRates, getChargeableWeight } from './actions';
import {
  getChargeableWeightFulfilled,
  getRatesBuyingFulfilled,
  getRatesFulfilled,
  getRatesPending,
  getRatesRejected,
} from './case';
import { GetRateFormData, GetRatesStateType } from './Type';

const initialState = <GetRatesStateType>{
  CSPChargesData: null,
  loading: false,
  redirectToCreateShipmentCSP: false,
  ratesNotFund: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  formData: { ...DefaultRatesInput, boxes: [...defaultBoxes], hideBoxes: true },
  routeHistory: [],
  buyingRates: null,
  isBookNow_WithAuth: false,
  chareableWeight: null,
  MaximumCharegableWeight: 0,
};

const ratesSlice = createSlice({
  name: 'GetRate',
  initialState: {
    ...initialState,
  },
  reducers: {
    // setUser: setCountryHandler,
    setCreateShipmentCSP: function (state, action: PayloadAction<GetRatesStateType['redirectToCreateShipmentCSP']>) {
      state.redirectToCreateShipmentCSP = action.payload;
    },
    setNoRatesFund: function (state, action: PayloadAction<GetRatesStateType['ratesNotFund']>) {
      state.ratesNotFund = action.payload;
    },
    resetCharges: function (state) {
      state.CSPChargesData = initialState.CSPChargesData;
    },
    resetGetRatesError: function (state) {
      state.error = initialState.error;
    },
    setFormData(state, action: PayloadAction<GetRateFormData>) {
      state.formData = action.payload;
    },
    resetFormData(state) {
      state.formData = { ...DefaultRatesInput, boxes: [...defaultBoxes], hideBoxes: true };
    },
    setPrevRoute(state, action: PayloadAction<string>) {
      const history = (state.routeHistory?.length ? [...state.routeHistory] : null) || [];
      if (history.length > 1) history.shift();
      history.push(action.payload);
      state.routeHistory = history;
    },
    setBookNowAuth(state, action: PayloadAction<boolean>) {
      state.isBookNow_WithAuth = action.payload;
    },
    setMaximumCharegableWeight(state, action: PayloadAction<number>) {
      state.MaximumCharegableWeight = action.payload;
    },
    resetChareableWeight(state) {
      state.chareableWeight = null;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getRates.pending, getRatesPending);
    addCase(getRates.fulfilled, getRatesFulfilled);
    addCase(getRates.rejected, getRatesRejected);

    addCase(getBuyingRates.pending, getRatesPending);
    addCase(getBuyingRates.fulfilled, getRatesBuyingFulfilled);
    addCase(getBuyingRates.rejected, getRatesRejected);

    addCase(getDefaultRates.pending, getRatesPending);
    addCase(getDefaultRates.fulfilled, getRatesFulfilled);
    addCase(getDefaultRates.rejected, getRatesRejected);

    addCase(getChargeableWeight.pending, getRatesPending);
    addCase(getChargeableWeight.fulfilled, getChargeableWeightFulfilled);
    addCase(getChargeableWeight.rejected, getRatesRejected);
  },
});

// const { setUser } = ratesSlice.actions;

export { getRates, getBuyingRates };
export const {
  setCreateShipmentCSP,
  setNoRatesFund,
  resetCharges,
  resetGetRatesError,
  setFormData,
  resetFormData,
  setPrevRoute,
  setBookNowAuth,
  setMaximumCharegableWeight,
  resetChareableWeight,
} = ratesSlice.actions;
export default ratesSlice.reducer;
