import { Grid, Typography } from '@mui/material';
import React from 'react';

function ChargeTitle() {
  return (
    <Grid container justifyContent={'space-between'}>
      <Typography className="md-text font-semibold">Payment</Typography>
      <Typography color="primary" className="md-text font-semibold">
        Amount
      </Typography>
    </Grid>
  );
}

export default ChargeTitle;
