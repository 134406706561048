import * as Yup from 'yup';
export const ShipmentSettingSchema = Yup.object().shape({
  shipmentCharges: Yup.array(
    Yup.object({
      value: Yup.string().trim().required('Shipment charge value is required'),
      label: Yup.string().trim().required('Shipment charge name is required'),
    }),
  ),
  personalShipmentId: Yup.array(
    Yup.object({
      value: Yup.string().trim().required('Personal shipment value is required'),
      label: Yup.string().trim().required('Personal shipment name is required'),
    }),
  ),
  exportShipmentId: Yup.array(
    Yup.object({
      value: Yup.string().trim().required('Personal shipment value is required'),
      label: Yup.string().trim().required('Personal shipment name is required'),
    }),
  ),
  pickupTimeSlots: Yup.object({
    slots: Yup.array(
      Yup.object({
        from: Yup.string().trim().required(),
        to: Yup.string().trim().required(),
      }),
    ),
    slotGroup: Yup.string().trim().required(),
  }).required(),
  packagingCharge: Yup.object().when('enablePackagingCharge', {
    is: true,
    then: () =>
      Yup.object().shape({
        charge: Yup.string().trim().required('Packaging charge is required'),
        chargeType: Yup.string().default('fixed'),
      }),
    otherwise: () =>
      Yup.object().shape({
        charge: Yup.string().trim(),
        chargeType: Yup.string().default('fixed'),
      }),
  }),
  insuranceCharge: Yup.object().when('enableInsuranceCharge', {
    is: true,
    then: () =>
      Yup.object().shape({
        charge: Yup.string().trim().required('Insurance percentage is required'),
        chargeType: Yup.string().default('fixed'),
      }),
    otherwise: () =>
      Yup.object().shape({
        charge: Yup.string().trim(),
        chargeType: Yup.string().default('fixed'),
      }),
  }),
});
