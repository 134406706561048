import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import SbStepper from '../../components/Stepper';
import OrganizationSetupWrapper from '../../context/OrganizationSetup/Provider';
import { OrganizationSetupContextType } from '../../types/organization';
import { Steps } from './constants';
import { getCountryList } from '@Reducers/Global';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { ComponentWrapper, OutletContainerStyle, OutletWrapperStyle } from './Style';
import OrganizationSetupContext from '../../context/OrganizationSetup';
import { useOrgSetupStatus } from '@Hook/useOrgSetupStatus';
import { setOrgCountry } from '@Reducers/OrganizationSetup';

const Organization: React.FC = (): JSX.Element => {
  return (
    <>
      <OrganizationSetupWrapper>
        <section style={{ display: 'flex' }}>
          <OutletContainer />
        </section>
      </OrganizationSetupWrapper>
    </>
  );
};

function OutletContainer(): JSX.Element {
  const { pathname } = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { activeStep, setActiveStep } = React.useContext(OrganizationSetupContext) as OrganizationSetupContextType;
  // const { loading } = useSelector((state: RootStateType) => state?.orgSetup);
  const { orgCurrentLevel } = useOrgSetupStatus();

  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
  React.useEffect(() => {
    ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [activeStep]);

  const isOrganizationWelcome = ['/organization-setup', '/organization-setup/', '/organization-setup/complete'].some(
    path => path === pathname,
  );

  const orgPublicData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const countriesObj = useSelector((state: RootStateType) => state.global.countriesObj);

  useEffect(() => {
    if (!orgPublicData?._countryId) return;
    const country = countriesObj[orgPublicData._countryId];
    dispatch(setOrgCountry(country));
  }, [countriesObj, orgPublicData?._countryId]);

  useEffect(() => {
    dispatch(getCountryList());
  }, []);

  useEffect(() => {
    const pathInd = Object.values(Steps).findIndex(path => path === pathname);
    if (pathInd > -1) setActiveStep(pathInd);
  }, [pathname]);

  // const handleNext = () => {
  //   setActiveStep(activeStep + 1);
  //   navigate(Steps[Object.keys(Steps)[activeStep + 1]]);
  // };

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  //   navigate(Steps[Object.keys(Steps)[activeStep - 1]]);
  // };
  useEffect(() => {
    if (orgCurrentLevel === 'shipment-settings') {
      navigate('/');
    }
  }, []);

  return (
    <OutletWrapperStyle className="OutletWrapperStyle">
      <OutletContainerStyle className="OutletContainerStyle">
        <ComponentWrapper className="ComponentWrapper" ref={ref}>
          {/* <Loader loading={loading} overly>  */}
          {!isOrganizationWelcome ? (
            <div className="p-3">
              <Header></Header>
              <SbStepper activeStep={activeStep} steps={Object.keys(Steps)} />
              <Outlet />
            </div>
          ) : (
            <Outlet />
          )}
          {/* </Loader> */}
        </ComponentWrapper>
      </OutletContainerStyle>
    </OutletWrapperStyle>
  );
}

export default Organization;
