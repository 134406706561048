import React, { useState } from 'react';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DocumentDeleteType } from './Type';
import Modal from '@Components/Modal';
import API from '@Services/apiAxios';
import Image from '@Components/Image';
import SbButton from '@Components/Button';

function DocumentDelete(props: DocumentDeleteType) {
  const theme = useTheme();

  const [loading, setLoading] = useState<boolean>(false);

  const onDelete = async () => {
    setLoading(true);
    try {
      await API.delete(`/user/kyc/${props.userId}/${props.id}`);
      props.onClose(true);
    } catch (error) {
      console.log('DELETE ERROR', error);
    }
    setLoading(false);
  };

  return (
    <Modal maxWidth="sm" open={props.open}>
      <Grid container className="mb-3" gap={3} justifyContent="flex-end">
        <IconButton onClick={() => props.onClose(false)}>
          <CloseIcon />
        </IconButton>
        <Grid container item justifyContent="center">
          {/* <TaskAltIcon sx={{ width: '100px', height: '100px' }} color="success" /> */}
          <Image src="/images/Boxes/Delete.svg" alt="delete" height={100} />
        </Grid>
        <Grid container item justifyContent="center" alignItems="center" textAlign="center">
          <Grid item xs={12} sm={6} justifyContent="center">
            <Typography fontWeight="bold" variant="h5">
              Are you sure you want to Delete this document?
            </Typography>
          </Grid>
          <Typography variant="h6" mt={1} color={theme.palette.primary.main}>
            Current document will be deleted permanently.
          </Typography>
        </Grid>
        <Grid container item>
          <SbButton
            variant="contained"
            fullWidth
            onClick={() => {
              onDelete();
            }}
          >
            {loading ? 'Deleting KYC...' : 'Yes, delete!'}
          </SbButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
export default DocumentDelete;
