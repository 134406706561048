import { DropdownOptionType } from '../../../components/Dropdown/Type';
import { countryStateType } from './Type';
import { countryPayloadType, globalStateType } from './Type';

function getCountryFullfilled(state: globalStateType, data: countryPayloadType) {
  const { payload } = data;
  const countryData: DropdownOptionType[] = [];
  const countryCodeData: DropdownOptionType[] = [];
  const currencyData: DropdownOptionType[] = [];
  const countriesObj: { [key: string]: countryStateType } = {};
  const countriesNameObj: { [key: string]: countryStateType } = {};
  const alternateCountryNameObj: { [key: string]: countryStateType } = {};
  payload.data.forEach((country: countryStateType) => {
    const { _id, name, telephoneCode, currency, alternateNames } = country;
    countryData.push({ display: name, value: name, countryId: _id });
    countryCodeData.push({
      display: `${name} (+${telephoneCode}) `,
      value: telephoneCode + '',
    });
    if (!JSON.stringify(currencyData)?.includes(JSON.stringify({ display: currency.name, value: currency.name }))) {
      currencyData.push({ display: currency.name, value: currency.name });
    }
    countriesObj[country._id] = country;
    countriesNameObj[name] = country;

    alternateNames.forEach(alternateName => {
      alternateCountryNameObj[alternateName.toLowerCase()] = country;
    });
  });
  state.countries = payload.data;
  state.countryList = countryData;
  state.countryCodeList = countryCodeData;
  state.currencyList = currencyData?.sort((a, b) => a.display.localeCompare(b.display));
  state.countriesObj = countriesObj;
  state.countriesNameObj = countriesNameObj;
  state.alternateCountryNameObj = alternateCountryNameObj;
}

function getCountryRejected() {
  console.log('Call rejected');
}

// ------------------------------------------------------------------------- KYC Setting
export function getKycSettingFulfilled(
  state: globalStateType,
  data: { payload: { data: globalStateType['kycSetting'] } },
) {
  state.loading = false;
  state.kycSetting = data.payload.data;
}

export function getKycSettingRejected(state: globalStateType) {
  Object.assign(state, {
    loading: false,
    isError: true,
    isSuccess: false,
    // message: payload?.error?.msg || payload?.error?.message,
  });
}

export function getKycSettingPending(state: globalStateType) {
  Object.assign(state, {
    loading: true,
    isError: false,
    isSuccess: false,
  });
}

// function setCountryHandler(state: countryStateType, data: { payload: countryStateType[] }) {
//   const { payload } = data;
//   state.value = payload;
// }

export { getCountryFullfilled, getCountryRejected };
