import { Grid, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

interface Props {
  ranges: Range[];
  date: Date;
  onChange?: (rangesByKey: RangeKeyDict) => void;
}

const CalendarClasses = ['rdrNextPrevButton', 'rdrMonthPicker', 'rdrYearPicker'];

export default function DateRangeCalendar(props: Props) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [rangeColors, setRangeColors] = useState<string[]>([]);
  const theme = useTheme();
  const { ranges } = props;

  const toggleCalendar = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowCalendar(x => !x);
  };

  const hideCalendar = useCallback(
    (ev: Event) => {
      const targetElem: HTMLElement = ev.target as HTMLElement;
      const classList = targetElem.classList.length ? targetElem.classList : targetElem.parentElement?.classList;
      const className = classList?.[0];
      if (className && CalendarClasses.includes(className)) return;
      setShowCalendar(false);
      return '';
    },
    [setShowCalendar],
  );

  useEffect(() => {
    const rangeColors = new Array(props.ranges.length).fill(theme.palette.secondary.main);
    rangeColors[rangeColors.length - 1] = theme.palette.primary.main;
    setRangeColors(rangeColors);
  }, [props.ranges, setRangeColors]);

  useEffect(
    function () {
      if (showCalendar) document.addEventListener('click', hideCalendar);
      return () => {
        if (showCalendar) document.removeEventListener('click', hideCalendar);
      };
    },
    [showCalendar],
  );

  return (
    <section className="relative cursor-pointer">
      <Grid
        container
        item
        onClick={toggleCalendar}
        className="px-1 py-1 rounded border border-solid border-light hover"
      >
        <Grid item className="font-medium" style={{ marginTop: '2px' }}>
          Date :
        </Grid>
        <Grid item mx={1} style={{ marginTop: '2px' }}>
          {moment(props.date).format('DD/MM/YYYY')}
        </Grid>
        <Grid item display="flex">
          <CalendarMonthIcon />
        </Grid>
      </Grid>
      {showCalendar && (
        <aside className="absolute left-0" style={{ top: '100%' }}>
          <DateRange
            showDateDisplay={false}
            date={props.date}
            ranges={ranges}
            rangeColors={rangeColors}
            moveRangeOnFirstSelection={false}
            onChange={props.onChange}
          />
        </aside>
      )}
    </section>
  );
}
