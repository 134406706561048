import { CSP_TYPES } from '@Constants/Shipment';
import { AppDispatch } from '@Store';

import { postFedExDetails } from '@Reducers/AdminShipment/actions';
import dayjs from 'dayjs';
import { StageInformation } from './StageInformation';

export const initStageInformation: any = {
  _id: '',
  accountNo: '',
  pin: '',
  name: '',
  csp: CSP_TYPES.stage.value,
  api_key: '',
  secret_key: '',
  isDefault: false,
  _system: [],
  isActive: false,
  createdAt: '',
  updatedAt: '',
  __v: 0,
};

function splitPhoneNumber(input: string): [string, string] {
  const [countryCode, phoneNumber] = input.split(' ');
  return [countryCode, phoneNumber];
}

interface DropdownOptionType {
  value: string;
  display: string;
}

// Enums for productType and paymentType
export enum ProductTypeEnum {
  PPX = 'PPX',
  DPX = 'DPX',
  GPX = 'GPX',
}

export enum PaymentTypeEnum {
  P = 'P',
  C = 'C',
  Three = '3',
}

export const onForward = ({
  values,
  shipmentNumber,
  countryHashById,
  selectedWarehouse,
  dispatch,
  nextFunc,
}: {
  values: StageInformation;
  shipmentNumber: string;
  selectedWarehouse: any;
  countryHashById: any;
  dispatch: AppDispatch;
  nextFunc: () => void;
}) => {
  const body = { ...JSON.parse(JSON.stringify(values)) };

  if (selectedWarehouse !== null) {
    const [countryCode, phoneNumber] = splitPhoneNumber(selectedWarehouse?.coAttention?.contact);
    const phone = {
      telephoneCode: countryCode,
      number: phoneNumber,
    };

    body.warehouseId = selectedWarehouse?._id;
    body.warehouseAddress = selectedWarehouse?._addressId;
    body.warehouseAddress.name = selectedWarehouse?.name;
    body.warehouseAddress.phone = phone;
    body.warehouseAddress._countryId = selectedWarehouse._addressId.country;
    body.warehouseAddress.country = countryHashById?.[selectedWarehouse._addressId.country]?.name;
  }

  body.csp = 'stage';
  body.isDropOff = selectedWarehouse?._systemId ? true : false;
  for (const key in body) {
    if (body[key]) {
      if (key === 'accountNo') {
        body[key] = body[key]?.split('-')?.[0];
      }
      if (key === 'service') {
        body['_systemId'] = body[key]?.split('-')?.[1];
      }
      if (key === 'service') {
        body['_courierId'] = body[key]?.split('-')?.[0];
      }
    }
  }

  const { service, ...rest } = body;
  dispatch(
    postFedExDetails({
      shipmentNumber: `${shipmentNumber}`,
      body: rest,
      date: values?.invoiceDate ? dayjs(values?.invoiceDate).format() : '',
      nextFunc,
    }),
  );
};

// String literals for productType and paymentType
export type ProductType = 'PPX' | 'DPX' | 'GPX';
export type PaymentType = 'P' | 'C' | '3';

export const ProductTypeOptions: DropdownOptionType[] = [
  { value: ProductTypeEnum.PPX, display: 'PPX' },
  { value: ProductTypeEnum.DPX, display: 'DPX' },
  { value: ProductTypeEnum.GPX, display: 'GPX' },
];

export const PaymentTypeOptions: DropdownOptionType[] = [
  { value: PaymentTypeEnum.P, display: 'Third Party' },
  { value: PaymentTypeEnum.C, display: 'Consignee' },
  { value: PaymentTypeEnum.Three, display: 'Prepaid' },
];
