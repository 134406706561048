import { BoxType } from '../store/reducers/Shipment/Type';

export enum UNIT {
  'in' = 'in',
  'cm' = 'cm',
}

export function calculateVolume(
  l: string | number,
  w: string | number,
  h: string | number,
  unit: keyof typeof UNIT,
  withoutUnitValue?: boolean,
) {
  let volumetricWeight = 0;
  l = typeof l === 'string' ? parseFloat(l) : l;
  w = typeof w === 'string' ? parseFloat(w) : w;
  h = typeof h === 'string' ? parseFloat(h) : h;
  const totalDimension = l * w * h;
  if (withoutUnitValue) return totalDimension;

  if (unit === UNIT.cm) {
    volumetricWeight = totalDimension / 5000;
  } else {
    volumetricWeight = totalDimension / 306;
  }

  volumetricWeight = volumetricWeight ? Number(volumetricWeight.toFixed(2)) : volumetricWeight;

  return volumetricWeight || 0;
}

export function calculateWeights<T extends BoxType>({ boxes, unit }: { boxes: T[]; unit: UNIT }) {
  console.log('---', boxes, unit);
  let totalValue = 0,
    totalChargeableWeight = 0,
    totalActualWeight = 0;
  for (let i = 0; i < boxes.length; i++) {
    const currBox: T = boxes[i];
    const { length, height, width, weight: tempWeight, products } = currBox;
    // const copyCount = boxCopyCount ? boxCopyCount + 1 : 1;
    const weight: number = typeof tempWeight === 'string' ? Number(tempWeight) : tempWeight;
    const volumetricWeight = calculateVolume(length, width, height, unit);

    // totalChargeableWeight = totalChargeableWeight + (weight > volumetricWeight ? weight : volumetricWeight) * copyCount;
    totalChargeableWeight = totalChargeableWeight + (weight > volumetricWeight ? weight : volumetricWeight);
    // totalActualWeight = totalActualWeight + weight * copyCount;
    totalActualWeight = totalActualWeight + weight;
    const total = products?.reduce((acc, { quantity, unitValue }) => acc + Number(quantity) * (unitValue || 0), 0);

    // totalValue += total * copyCount;
    totalValue += total;
  }
  return { totalValue, totalChargeableWeight, totalActualWeight };
}
