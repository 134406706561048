import { createSlice } from '@reduxjs/toolkit';
import {
  createServiceFulfilled,
  createServicePending,
  createServiceRejected,
  deleteServiceFulfilled,
  deleteServicePending,
  deleteServiceRejected,
  getServiceByIdFulfilled,
  getServiceByIdPending,
  getServiceByIdRejected,
  getServiceListFulfilled,
  getServiceListPending,
  getServiceListRejected,
  updateServiceFulfilled,
  updateServicePending,
  updateServiceRejected,
} from './case';
import { ServiceAndCarrierState } from './Type';
import { getServiceList, createService, getServiceById, updateServiceById, deleteServiceById } from './actions';

const initialState = <ServiceAndCarrierState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  singleService: null,
  serviceList: [],
  serviceListHashById: {},
  serviceDropdownList: [],
  activeServiceList: [],
  filter: {
    searchKey: 'name',
    searchValue: '',
  },
  selectedServiceList: [],
  totalCount: 0,
  currentPage: 0,
  perPage: 10,
};

const userSlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    // resetShipmentSummary: resetShipmentSummary,
    resetCurrentPage: function (state) {
      state.currentPage = 0;
    },
    onChangePagination: function (state, action) {
      state.currentPage = action.payload;
      const startIndex = state.currentPage * state.perPage;
      const endIndex = startIndex + state.perPage;
      state.serviceList = state.selectedServiceList?.slice(startIndex, endIndex);
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getServiceList.pending, getServiceListPending);
    addCase(getServiceList.fulfilled, getServiceListFulfilled);
    addCase(getServiceList.rejected, getServiceListRejected);

    addCase(createService.pending, createServicePending);
    addCase(createService.fulfilled, createServiceFulfilled);
    addCase(createService.rejected, createServiceRejected);

    addCase(getServiceById.pending, getServiceByIdPending);
    addCase(getServiceById.fulfilled, getServiceByIdFulfilled);
    addCase(getServiceById.rejected, getServiceByIdRejected);

    addCase(updateServiceById.pending, updateServicePending);
    addCase(updateServiceById.fulfilled, updateServiceFulfilled);
    addCase(updateServiceById.rejected, updateServiceRejected);

    addCase(deleteServiceById.pending, deleteServicePending);
    addCase(deleteServiceById.fulfilled, deleteServiceFulfilled);
    addCase(deleteServiceById.rejected, deleteServiceRejected);
  },
});

// const { setUser } = userSlice.actions;

export { getServiceList, createService, getServiceById, updateServiceById, deleteServiceById };
export const { onChangePagination, resetCurrentPage } = userSlice.actions;
export default userSlice.reducer;
