/** ONLY API'S ARE ALLOWED IN THIS ACTION FILE. */

import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import API from '../../../services/apiAxios';
import { SuccessPayload } from '../../../types';
import {
  AdminActionsResponse,
  AdminListForTagResponse,
  AdminListQuery,
  AdminListResponse,
  AdminObj,
  AdminSendInvitePayload,
  AdminSendInviteResponse,
  CheckUsername,
  CreateAdminType,
  EditAdminType,
  ErrorType,
  ShipmentPayByCash,
  ShipmentRefund,
} from './Type';
import {
  getAdminShipmentById,
  getShipmentPaymentBySN,
  getShipmentWebsocketStream,
} from '@Reducers/AdminShipment/actions';

const getAdminList = createAsyncThunk<AdminListResponse, AdminListQuery | undefined, { rejectValue: ErrorType }>(
  'getAdminList',
  async (queryParams, { dispatch }) => {
    try {
      const res = await API.get('/user/list/admin', { params: queryParams });
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getAdminListForTag = createAsyncThunk<
  AdminListForTagResponse,
  { systems?: string[] } | undefined,
  { rejectValue: ErrorType }
>('getAdminList/forTag', async (queryParams, { rejectWithValue }) => {
  try {
    const res = await API.get('/user/list/admin/tag', { params: queryParams });
    return res.data;
  } catch (e) {
    const error: AxiosError<ErrorType> = e;
    if (!error.response) throw error;
    return rejectWithValue({ ...error.response.data });
  }
});

const deactivateAdminAccount = createAsyncThunk<
  AdminActionsResponse,
  { id: string; showModal: true },
  { rejectValue: ErrorType }
>('deactivateAdminAccount', async (queryParams, { dispatch }) => {
  const { id, showModal } = queryParams;
  try {
    const res = await API.post(`/user/on-board-status/deactivate/${id}`);
    if (showModal)
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const restoreAdminAccount = createAsyncThunk<
  AdminActionsResponse,
  { id: string; showModal: true },
  { rejectValue: ErrorType }
>('restoreAdminAccount', async (queryParams, { dispatch }) => {
  const { id, showModal } = queryParams;
  try {
    const res = await API.post(`/user/on-board-status/restore/${id}`);
    if (showModal)
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res.data.msg,
        }),
      );
    return res.data;
  } catch (error) {
    const errorRes = error.response.data;
    dispatch(
      SuccessErrorModalOpen({
        type: 'error',
        title: 'Oops!',
        subTitle: errorRes?.msg || 'Something went to wrong.',
      }),
    );
    throw error;
  }
});

const createAdmin = createAsyncThunk<AdminActionsResponse, CreateAdminType, { rejectValue: ErrorType }>(
  'createAdmin',
  async (postData, { dispatch }) => {
    const { email, ...rest } = postData;
    // Only pass the email if it's not an empty string
    const dataToSend = email === '' ? { ...rest } : postData;
    try {
      const res = await API.post('/user/admin/on-board', dataToSend);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const editAdmin = createAsyncThunk<AdminActionsResponse, EditAdminType, { rejectValue: ErrorType }>(
  'editAdmin',
  async (postData, { dispatch }) => {
    const { email, ...rest } = postData;
    // Only pass the email if it's not an empty string
    const dataToSend = email === '' ? { ...rest } : postData;
    try {
      const res = await API.put(`/user/admin/${postData._id}`, dataToSend);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getAdminDetailsById = createAsyncThunk<SuccessPayload<AdminObj>, string, { rejectValue: ErrorType }>(
  'getAdminDetailsById',
  async (id: string, { dispatch }) => {
    try {
      const res = await API.get(`/user/${id}`);
      return res.data;
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: 'An error occurred while retrieving admin details.',
        }),
      );
      throw error;
    }
  },
);

const adminSendInvite = createAsyncThunk<AdminSendInviteResponse, AdminSendInvitePayload, { rejectValue: ErrorType }>(
  'adminSendInvite',
  async (body, { dispatch }) => {
    try {
      const res = await API.post(`/user/admin/send-invite`, body);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: res?.data?.msg || 'Something went to wrong.',
        }),
      );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const checkUsername = createAsyncThunk<SuccessPayload<CheckUsername>, string, { rejectValue: ErrorType }>(
  'checkUsername',
  async (id: string) => {
    try {
      const res = await API.get(`/user/check-username/${id}`);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      throw errorRes;
    }
  },
);

const shipmentPayByCash = createAsyncThunk<AdminActionsResponse, ShipmentPayByCash, { rejectValue: ErrorType }>(
  'shipmentPayByCash',
  async (postData, { dispatch }) => {
    try {
      const res = await API.post(`/shipment/payment/create/cash`, postData);
      await API.post(`/payment/pay/shipment/admin/cash`, res.data);
      setTimeout(() => {
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Shipment payment completed.',
            onConfirm: () => {
              window.location.reload();
            },
          }),
        );
        if (postData.next) {
          postData.next();
        }
        dispatch(getAdminShipmentById(postData.shipmentNumber));
        dispatch(getShipmentWebsocketStream(['shipment-update-' + postData.shipmentNumber]));
        dispatch(getShipmentPaymentBySN(postData.shipmentNumber));
      }, 3000);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const shipmentAmtRefund = createAsyncThunk<AdminActionsResponse, ShipmentRefund, { rejectValue: ErrorType }>(
  'shipmentAmtRefund',
  async (postData, { dispatch }) => {
    try {
      const res = await API.post(`/shipment/payment/create/refund`, postData);
      await API.post(`/payment/refund/shipment`, res.data);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Success',
          subTitle: 'Shipment payment completed.',
        }),
      );
      dispatch(getAdminShipmentById(postData.shipmentNumber));
      dispatch(getShipmentWebsocketStream(['shipment-update-' + postData.shipmentNumber]));
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

export {
  getAdminList,
  deactivateAdminAccount,
  restoreAdminAccount,
  getAdminListForTag,
  createAdmin,
  editAdmin,
  getAdminDetailsById,
  shipmentPayByCash,
  checkUsername,
  shipmentAmtRefund,
  adminSendInvite,
};
