import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Title from '../../components/Title';
import { Grid, styled, Chip, FormHelperText } from '@mui/material';

import Modal from '@Components/Modal';
import SuccessError from '@Components/Modal/SuccessError';

// ICONS

import { AppDispatch, RootStateType } from '../../store';

// import { getServiceList } from '@Reducers/CarrierAndService';

import FilterSearch from '@Components/Filter/Search';
import CSP from '../UploadRates/Create/csp';
import SbButton from '@Components/Button';
import { useFormik } from 'formik';
import { editSchema } from './schema';
import { setManifestSingleData, updateManifest } from '@Reducers/Manifest';
import { getShipmentBySN } from '@Reducers/Shipment/actions';
import { UpdateManifest } from '@Reducers/Manifest/Type';

const DialogContainer = styled(Grid)({
  minWidth: '340px',
  maxWidth: '525px',
});

const SearchFilterList = [{ label: 'Shipment Number', value: 'shipmentNumber' }];

const initManifestData: UpdateManifest = {
  _id: '',
  createdAt: '',
  manifestNumber: '',
  _courierId: '',
  _systemId: null,
  shipmentNumbers: [],
};

export default function ManifestEdit(props: { refreshList: () => void; id: string }) {
  const [filterSelectValue, setFilterSelectValue] = useState(SearchFilterList[0].value);
  const [filterText, setFilterText] = useState('');
  const [singleRateData, setSingleRateData] = useState<UpdateManifest>({ ...initManifestData });

  const dispatch = useDispatch<AppDispatch>();
  const { refreshList } = props;
  const singleData = useSelector((state: RootStateType) => state.manifest.singleData);

  // useEffect(() => {
  //   dispatch(getServiceList({}));
  // }, []);

  useEffect(() => {
    if (singleData) setSingleRateData({ ...singleData, _courierId: singleData._courierId._id });
  }, [singleData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...singleRateData },
    validationSchema: editSchema,

    onSubmit(values) {
      dispatch(updateManifest(values)).then(() => {
        refreshList();
      });
    },
    validateOnChange: false,
  });

  const { values: formikValues, setFieldValue } = formik;

  const onShipmentSNDelete = (sn: string) => () => {
    if (formikValues.shipmentNumbers) {
      const copySN = [...formikValues.shipmentNumbers];
      const index = copySN.indexOf(sn);
      copySN.splice(index, 1);
      setFieldValue('shipmentNumbers', copySN);
    }
  };

  return (
    <>
      <Modal open={props.id === singleData?._id} sx={{ minWidth: 'lg' }}>
        <DialogContainer>
          <Title title="Edit" />
          <Grid container px={1} py={4} gap={3}>
            <Grid item xs={12}>
              <CSP
                showInputLabel
                removerBorder
                onChange={([value]) => {
                  setFieldValue('_courierId', value, false);
                }}
                value={[formikValues._courierId]}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterSearch
                inputClassName="w-full"
                onSelectChange={(value: string) => {
                  setFilterSelectValue(value);
                }}
                list={SearchFilterList}
                selectValue={filterSelectValue}
                textValue={filterText}
                onTextChange={e => {
                  setFilterText(e.target.value);
                }}
                onTextSearch={() => {
                  if (filterText.trim() && !formikValues.shipmentNumbers.includes(filterText.trim())) {
                    dispatch(getShipmentBySN(filterText)).then((res: { type: string }) => {
                      if (res?.type === 'getShipmentBySN/fulfilled') {
                        setFieldValue('shipmentNumbers', [...formikValues.shipmentNumbers, filterText]);
                        setFilterText('');
                      }
                    });
                  }
                }}
                onTextClear={() => {
                  setFilterText('');
                  console.log('onTextClear');
                }}
              />
              {formik.errors.shipmentNumbers && (
                <FormHelperText error className="m-0">
                  {formik.errors.shipmentNumbers}
                </FormHelperText>
              )}
            </Grid>
            <Grid container item gap={1} xs={12}>
              {formikValues.shipmentNumbers.map((x, index) => (
                <Chip
                  key={`${x}-${index}`}
                  sx={{ borderRadius: 1 }}
                  label={x}
                  variant="outlined"
                  onDelete={onShipmentSNDelete(x)}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container item mt={1} spacing={2} justifyContent="flex-end">
            <Grid item xs={6} sm={3}>
              <SbButton
                fullWidth
                variant="outlined"
                onClick={() => {
                  dispatch(setManifestSingleData(null));
                }}
              >
                Cancel
              </SbButton>
            </Grid>
            <Grid item xs={6} sm={3}>
              <SbButton
                fullWidth
                variant="contained"
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Update
              </SbButton>
            </Grid>
          </Grid>
        </DialogContainer>
      </Modal>
      <SuccessError />
    </>
  );
}
