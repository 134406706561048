import Divider from '@Components/Divider';
import Loader from '@Components/Loader';
import Modal from '@Components/Modal';
import { downloadInvoiceDoc } from '@Reducers/Invoice';
import { DownloadRounded, HighlightOffRounded, ModeCommentOutlined } from '@mui/icons-material';
import {
  Grid,
  IconButton,
  Stack,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SbButton from '../../components/Button';
import DateRangePicker from '../../components/DateRangePicker';
import FilterSelect from '../../components/Filter/Dropdown';
import FilterSearch from '../../components/Filter/Search';
import { SBTable, SBTableWrapper } from '../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../components/Table/TableCell';
import { SBTableRow } from '../../components/Table/TableRow';
import Title from '../../components/Title';
import { AppDispatch, RootStateType } from '../../store';
import { fetchUserInvoiceList } from '../../store/reducers/InvoiceList';
import { InvoiceListQuery } from '../../store/reducers/InvoiceList/Type';
import { Status } from '../Invoice/Status';
import Navigation from './Navigation';
import { PayNow } from './PayNow';
import { FilterObj } from './Types';
import { ArrowDropDownCircleOutlined } from '@mui/icons-material';
import { fetchAccountInvoice, fetchNonAccountInvoice } from '@Reducers/invoiceSliceReport/invoiceSliceReport';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { formatNumber, useLocale } from '../../utils/formatNumber';

// import { isActionAllowed } from '../../utils/allowedActions';
// import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const SearchFilterList = [{ label: 'Invoice No', value: 'invoiceNumber' }];

const StatusList = [
  {
    value: 'paid',
    display: 'Paid',
  },
  {
    value: 'unpaid',
    display: 'Unpaid',
  },
  {
    value: 'partially_paid',
    display: 'Partially paid',
  },
  {
    value: 'void',
    display: 'Void',
  },
];

const InvoiceTypeList = [
  {
    value: 'invoice',
    display: 'Invoice',
  },
  {
    value: 'credit',
    display: 'Credit',
  },
  {
    value: 'debit',
    display: 'Debit',
  },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  margin: '2rem 0',
  display: 'grid',
  gridTemplateColumns: '380px 1fr  fit-content(150px) fit-content(150px) fit-content(150px) 150px',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    columnGap: '8px',
    gridTemplateColumns: '380px 1fr  fit-content(150px) fit-content(150px) fit-content(150px) 120px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

function InvoiceTable({ handlePaymentSuccess }: { handlePaymentSuccess: () => void }) {
  const [selectedInvoices] = useState<{ [x: string]: boolean }>({});
  const [organisationName, setOrganisationName] = useState<string>();
  const locale = useLocale();

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    setOrganisationName(subdomain);
  }, []);

  const {
    data: { list },
  } = useSelector((state: RootStateType) => state.InvoiceList);
  const dispatch = useDispatch<AppDispatch>();
  const [showCommentsModal, setShowCommentsModal] = useState<{ open: boolean; invId?: string }>({ open: false });

  const selectedComment = list.find(item => item._id === showCommentsModal.invId);
  let comments;
  if (selectedComment) {
    comments = selectedComment?.comments;
  }
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [exportAnchor, setExportAnchor] = useState(null);
  const handleExportButtonClick = (event: any) => {
    setExportAnchor(event.currentTarget);
  };
  // Handle closing the menu
  const closeExportMenu = () => {
    setExportAnchor(null);
  };
  const options = [
    { value: 'invoice', label: 'Invoice' },
    { value: 'annexure', label: 'Annexure' },
  ];
  // const onSelectInvoice = function (invoiceId: string, isChecked: boolean) {
  //   const newSelectedInvoice = { ...selectedInvoices };
  //   if (isChecked) newSelectedInvoice[invoiceId] = true;
  //   else delete newSelectedInvoice[invoiceId];
  //   // const newSelectedShipment = [...selectedInvoices];
  //   // if (isChecked) newSelectedShipment.push(shipmentId);
  //   // else {
  //   //   const index = newSelectedShipment.findIndex(id => id === shipmentId);
  //   //   newSelectedShipment.splice(index, 1);
  //   // }
  //   setSelectedInvoices(newSelectedInvoice);
  // };

  // const checkAllInvoices = function (event: React.ChangeEvent<HTMLInputElement>) {
  //   if (event.target.checked) {
  //     const newInvoiceCheckedList = list.reduce((op: { [k: string]: boolean }, invoice) => {
  //       op[invoice._id] = true;
  //       return op;
  //     }, {});
  //     setSelectedInvoices(newInvoiceCheckedList);
  //   } else {
  //     setSelectedInvoices({});
  //   }
  // };
  // const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  // const isDownloadInvoiceAllowed = isActionAllowed(
  //   accessGroupById?.actions,
  //   ACTION_NAMES.View_Invoices_Account_holder.download_invoices,
  //   PERFORM_ACTION.write,
  // );
  return (
    <>
      <Modal open={showCommentsModal.open} onClose={() => setShowCommentsModal({ invId: '', open: false })}>
        <Grid
          container
          style={{
            minWidth: '100%',
            maxWidth: '430px',
            margin: '0 auto',
            padding: '16px',
          }}
        >
          <Grid item container justifyContent={'flex-end'}>
            <IconButton
              onClick={() => {
                setShowCommentsModal({ open: false, invId: '' });
              }}
            >
              <HighlightOffRounded
                sx={{
                  fontSize: '2rem',
                  color: 'rgba(0,0,0,0.7)',
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12} padding={2}>
            <Typography className="md-text font-medium my-3">Comments</Typography>
            <Stack
              gap={2}
              maxHeight={400}
              overflow={'auto'}
              id="fadedScroll"
              pr={1}
              sx={{
                '@media (max-width: 600px)': {
                  maxWidth: '100%',
                  paddingRight: 0,
                },
              }}
            >
              {comments?.map((c, i) => (
                <div
                  key={i}
                  className="rounded"
                  style={{ background: '#F1F1F1', padding: '20px', paddingBottom: '5px' }}
                >
                  <Typography>
                    {selectedComment?._accountRefUserId?.name} <span>{moment(c?.createdAt).format('HH:MM A')}</span>
                  </Typography>
                  <Typography>
                    {moment(c.createdAt).format('DD/MM/YYYY')} - Paid {selectedComment?.currency?.name} {c.paidAmount}
                  </Typography>
                  <Typography>Comment: {c.comment}</Typography>
                </div>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Modal>
      <SBTableWrapper>
        <SBTable>
          <TableHead>
            <TableRow>
              <TableHeadCell>Sr no.</TableHeadCell>
              <TableHeadCell>Invoice</TableHeadCell>
              <TableHeadCell>Type</TableHeadCell>
              <TableHeadCell>Ref Invoice No</TableHeadCell>
              <TableHeadCell>Bill date</TableHeadCell>
              <TableHeadCell>Due date</TableHeadCell>
              <TableHeadCell>Total Due (₹)</TableHeadCell>
              <TableHeadCell>Amount Paid (₹)</TableHeadCell>
              <TableHeadCell>TDS(₹)</TableHeadCell>
              <TableHeadCell>Pending (₹)</TableHeadCell>
              <TableHeadCell>Status</TableHeadCell>
              <TableHeadCell>Actions</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.length ? (
              list.map((invoice, index) => (
                <SBTableRow key={invoice.invoiceNo} className={selectedInvoices[invoice._id] ? 'selected' : ''}>
                  <TableRowCell align="center" className="md-text">
                    <div>{index + 1}</div>
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text capitalize">
                    {invoice.invoiceNo}
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text capitalize">
                    <p className="capitalize">
                      {invoice.invoiceType === 'debit'
                        ? 'Debit Note'
                        : invoice.invoiceType === 'credit'
                        ? 'Credit Note'
                        : invoice.invoiceType}
                    </p>
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text  uppercase">
                    {invoice.accountNo}
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text">
                    {moment(invoice.billDate).format('DD/MM/YYYY')}
                  </TableRowCell>
                  <TableRowCell align="center" className="md-text">
                    {moment(invoice.dueDate).format('DD/MM/YYYY')}
                  </TableRowCell>
                  <TableRowCell className="md-text" align="center">
                    {formatNumber(invoice.dueAmount, locale)}
                  </TableRowCell>
                  <TableRowCell className="md-text" align="center">
                    {formatNumber(invoice.paidAmount, locale)}
                  </TableRowCell>
                  <TableRowCell className="md-text">
                    {invoice?.comments?.length
                      ? formatNumber(
                          invoice.comments.reduce((accumulator, transaction) => accumulator + transaction.tdsAmount, 0),
                          locale,
                        )
                      : 'NA'}
                  </TableRowCell>

                  <TableRowCell className="md-text">{invoice.dueAmount}</TableRowCell>
                  <TableRowCell className="md-text">
                    {invoice.status ? <Status status={invoice.status} /> : ''}
                  </TableRowCell>
                  <TableRowCell sx={{ display: 'block', width: '250px', border: 'none !important' }}>
                    <Grid container gap={1}>
                      <Grid item xs="auto">
                        <PayNow
                          paymentDetails={{
                            invoiceNumber: invoice.invoiceNo,
                            payableAmount: invoice.dueAmount,
                            invoiceComments: invoice.comments ?? [],
                            handlePaymentSuccess,
                          }}
                          disabled={invoice.status === 'paid'}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <SbButton
                          variant="outlined"
                          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                          onClick={e => {
                            setInvoiceNumber(invoice.invoiceNo);
                            handleExportButtonClick(e);
                          }}
                          disabled={
                            !invoice.docId &&
                            !(
                              (organisationName === 'wyngs-my' ||
                                organisationName === 'uat-speedbox' ||
                                organisationName === 'localhost') &&
                              invoice.invoiceType
                            )
                          }
                        >
                          <DownloadRounded />
                        </SbButton>
                        <Menu
                          open={Boolean(exportAnchor)}
                          anchorEl={exportAnchor}
                          onClose={closeExportMenu}
                          keepMounted
                          PaperProps={{
                            sx: {
                              width: '150px',
                              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            },
                          }}
                        >
                          {options.map(option => (
                            <MenuItem
                              onClick={() => {
                                if (option.value === 'invoice') {
                                  dispatch(downloadInvoiceDoc({ invoiceNo: invoiceNumber, annexure: false }));
                                } else {
                                  dispatch(downloadInvoiceDoc({ invoiceNo: invoiceNumber, annexure: true }));
                                }
                                closeExportMenu();
                              }}
                              key={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </Menu>
                      </Grid>
                      <Grid item xs="auto">
                        <SbButton
                          variant="contained"
                          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
                          onClick={() => {
                            setShowCommentsModal({ open: true, invId: invoice._id });
                          }}
                        >
                          <ModeCommentOutlined />
                        </SbButton>
                      </Grid>
                    </Grid>
                  </TableRowCell>
                </SBTableRow>
              ))
            ) : (
              <SBTableRow>
                <TableRowCell colSpan={9}>
                  <Typography className="sb-text-gray font-medium text-center">No Data Found.</Typography>
                </TableRowCell>
              </SBTableRow>
            )}
          </TableBody>
        </SBTable>
      </SBTableWrapper>
    </>
  );
}

export default function InvoiceList() {
  const { limit } = useSelector((state: RootStateType) => state.notifications);
  const [filterSelectValue, setFilterSelectValue] = useState('invoiceNumber');
  const [filterText, setFilterText] = useState('');
  const [status, setStatus] = useState<string[]>([]);
  const [invoiceType, setInvoiceType] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [isClear, setClear] = useState(false);
  const [exportAnchor, setExportAnchor] = useState<null | HTMLElement>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();

  const handlePaymentSuccess = () => {
    const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
    const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
    dispatch(fetchUserInvoiceList({ currentPage: 1, perPage: 10, fromDate, toDate }));
    enqueueSnackbar('Payment done successfully!', { variant: 'success', key: new Date().getTime() });
  };
  const {
    data: { currentPage = 1, perPage, totalCount },
    loading: invoiceLoading,
  } = useSelector((state: RootStateType) => state.InvoiceList);
  const { loading: downloading } = useSelector((state: RootStateType) => state.Invoice);
  const isAccountHolder = useSelector((state: RootStateType) => state.loginUser.profile?._accountId?.isAccountHolder);
  const loading = useSelector((state: RootStateType) => state.Wallet.loading);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
  //   const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
  //   dispatch(fetchUserInvoiceList({ currentPage: 1, perPage: 10, fromDate, toDate }));
  // }, []);

  useEffect(() => {
    dispatch(fetchUserInvoiceList(getFilterObj({ searchValue: '' })));
    return () => {
      setClear(false);
    };
  }, [isClear]);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(fetchUserInvoiceList(getFilterObj({ dateRange })));
  };

  const onStatusFilter = (selected: Array<string>) => {
    setStatus(selected);
    dispatch(fetchUserInvoiceList(getFilterObj({ status: selected })));
  };

  const onTypeFilter = (selected: Array<string>) => {
    setInvoiceType(selected);
    dispatch(fetchUserInvoiceList(getFilterObj({ type: selected })));
  };

  const getFilterObj = (options: FilterObj): InvoiceListQuery => {
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterStatus = options.status || status;
    const filterType = options.type || invoiceType;
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText?.toUpperCase();
    return {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      status: filterStatus,
      invoice_type: filterType,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
  };

  const openExportMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('open menu');
    setExportAnchor(event.currentTarget);
  };

  const closeExportMenu = () => {
    setExportAnchor(null);
  };

  const onExportMenuItemClick = async (page: string) => {
    setExportAnchor(null);
    if (isAccountHolder) {
      try {
        setIsLoading(true);
        await dispatch(
          fetchAccountInvoice({
            currentPage: 1,
            perPage: 10,
            fromDate: getFilterObj({}).fromDate,
            toDate: getFilterObj({}).toDate,
            systems: getFilterObj({}).systems,
            status: getFilterObj({}).status,
            type: getFilterObj({}).invoice_type,
            isAccountHolder: true,
            downloadType: page,
          }),
        );
        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Download in Progress',
            subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
            btnText: 'Close',
            onConfirm: () => {
              dispatch(toggleBoolean());
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
      } catch (error) {
        console.error('Error:', error);
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Something went wrong',
            subTitle: 'please try after sometimes',
          }),
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        await dispatch(
          fetchNonAccountInvoice({
            currentPage: 1,
            perPage: 10,
            fromDate: getFilterObj({}).fromDate,
            toDate: getFilterObj({}).toDate,
            systems: getFilterObj({}).systems,
            status: getFilterObj({}).status,
            type: getFilterObj({}).invoice_type,
            isAccountHolder: true,
            downloadType: page,
          }),
        );

        dispatch(
          getAllUnreadNotifications({
            page: 1,
            limit,
          }),
        );
        dispatch(getAllReadNotifications({ page: 1, limit }));
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Download in Progress',
            subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
            btnText: 'Close',
            onConfirm: () => {
              dispatch(toggleBoolean());
              dispatch(SuccessErrorModalReset());
            },
          }),
        );
      } catch (error) {
        console.error('Error:', error);
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Something went wrong',
            subTitle: 'please try after sometimes',
          }),
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const options = [
    { value: 'summary', label: 'Summary' },
    { value: 'extensive', label: 'Extensive' },
  ];

  return (
    <Loader overly loading={loading || invoiceLoading || downloading || isLoading}>
      <>
        <Title title="Invoice Details" removeBorder />
        <Navigation />
        <Divider className="my-3" />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="flex justify-end mb-3">
          <SbButton
            // className="xs-text"
            variant="contained"
            sx={{ borderRadius: '8px !important', padding: '8px !important', width: '120px' }}
            onClick={openExportMenu}
            endIcon={<ArrowDropDownCircleOutlined />}
          >
            Export
          </SbButton>
          <Menu
            open={Boolean(exportAnchor)}
            anchorEl={exportAnchor}
            onClose={closeExportMenu}
            keepMounted
            PaperProps={{
              sx: {
                width: '150px',
              },
            }}
          >
            {options.map(option => (
              <MenuItem onClick={_ => onExportMenuItemClick(option.value)} key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <TableActionContainer>
          <FilterSearch
            onSelectChange={(value: string) => {
              setFilterSelectValue(value);
            }}
            list={SearchFilterList}
            selectValue={filterSelectValue}
            textValue={filterText}
            onTextChange={e => {
              setFilterText(e.target.value);
            }}
            onTextSearch={() => {
              dispatch(fetchUserInvoiceList(getFilterObj({})));
            }}
            onTextClear={() => {
              setFilterText('');
              dispatch(fetchUserInvoiceList(getFilterObj({ searchValue: undefined })));
            }}
          />
          <div></div>
          <DateRangePicker onChange={onDateChange} value={dateRange} />
          <FilterSelect id="status" label="Status" value={status} options={StatusList} onChange={onStatusFilter} />
          <FilterSelect id="type" label="Type" value={invoiceType} options={InvoiceTypeList} onChange={onTypeFilter} />
          <SbButton
            className="xs-text ml-1"
            variant="outlined"
            sx={{ borderRadius: '8px !important', padding: '8px !important' }}
            onClick={() => {
              setStatus([]);
              setFilterText('');
              setInvoiceType([]);
              setDateRange({
                startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                endDate: moment().endOf('day').toDate(),
              });
              setClear(true);
            }}
          >
            Clear Filters
          </SbButton>
        </TableActionContainer>
        <InvoiceTable handlePaymentSuccess={handlePaymentSuccess} />
        {totalCount > 0 && perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage - 1}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                dispatch(fetchUserInvoiceList(getFilterObj({ currentPage: page + 1 })));
              }}
              onRowsPerPageChange={event => {
                dispatch(fetchUserInvoiceList(getFilterObj({ perPage: parseInt(event.target.value) })));
              }}
            />
          </>
        )}
      </>
    </Loader>
  );
}
