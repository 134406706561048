import React from 'react';
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import { SxProps } from '@mui/material';

interface PropsType {
  className?: string;
  sx?: SxProps;
  disabled?: boolean;
  value?: string;
  setValue?: (value: string) => void;
}

const ColorPicker = ({ className, sx, disabled, value, setValue }: PropsType) => {
  const handleChange = (value: string, _colors: MuiColorInputColors) => {
    if (setValue) setValue(value);
  };

  return (
    <MuiColorInput
      value={value || '#ffffff'}
      onChange={handleChange}
      className={className}
      sx={sx}
      disabled={disabled}
    />
  );
};

export default ColorPicker;
