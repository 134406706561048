import React, { useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { createShipmentContextType } from '../../../../types/shipment';
import { defaultProductState } from '../../constants';
import BoxDetails from './BoxDetails';
import { BoxPropsType } from '../Type';
import CopyBox from './CopyBox';
import { BoxContainer } from '../../styles';

function Boxes({ index, handleDeleteBox, boxData, writePermission }: BoxPropsType) {
  return (
    <>
      <Grid container item className="mb-2">
        <SingleBoxView
          handleDeleteBox={handleDeleteBox}
          boxData={boxData}
          writePermission={writePermission}
          index={index}
        />
      </Grid>
    </>
  );
}

function SingleBoxView(props: BoxPropsType) {
  const { selectedBox, setSelectedBox, setSelectedProduct } = React.useContext(
    CreateShipmentContext,
  ) as createShipmentContextType;

  const [copyBox, setCopyBox] = useState(false);

  const { boxData, writePermission, index } = props;

  const isSelected = useMemo(() => boxData.id === selectedBox.id, [boxData, selectedBox]);

  // console.info('boxData', boxData);

  return (
    <BoxContainer
      container
      alignItems={'center'}
      className={`rounded-lg`}
      isselected={String(isSelected)}
      onClick={() => {
        setSelectedBox(boxData);
        setSelectedProduct({ ...defaultProductState });
      }}
    >
      {!copyBox ? (
        <BoxDetails
          {...props}
          setCopyBox={setCopyBox}
          isSelected={isSelected}
          writePermission={writePermission}
          index={index}
        />
      ) : (
        <CopyBox {...props} setCopyBox={setCopyBox} isSelected={isSelected} />
      )}
    </BoxContainer>
  );
}

export default Boxes;
