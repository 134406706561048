import Dropdown from '@Components/Dropdown';
import SbTextField from '@Components/Textfield';
import Title from '@Components/Title';
import React, { Checkbox, Grid, Typography } from '@mui/material';
// import GppGoodIcon from '@mui/icons-material/GppGood';
// import Inventory2Icon from '@mui/icons-material/Inventory2';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import { SHIPMENT_MODES, SHIPMENT_UNITS } from '../../CreateShipment/constants';

import { SHIPMENT_TYPE } from '@Constants/Shipment';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { setInsureShipment, updateShipment } from '@Reducers/AdminShipment';
import { getSingleShipmentPackagePayload } from '@Services/getShipmentPayload';
import { ShipmentControl } from '@Services/shipmentActionControl';
import { useContext, useEffect, useMemo } from 'react';
import CreateShipmentContext from '../../../context/CreateShipment';
import CreateShipmentWrapper from '../../../context/CreateShipment/Provider';
import { LengthType, createShipmentContextType } from '../../../types/shipment';
import { isActionAllowed } from '../../../utils/allowedActions';
import ShipmentDetailsBoxWise from '../../CreateShipment/ShipmentDetails';
import useScroll from '../useScroll';
import Actions from './Actions';

const SHIPMENT_TYPE_LITS = [
  { value: 'parcel', display: 'Parcel' },
  { value: 'document', display: 'Document' },
];

function ShipmentDetails() {
  const dispatch = useDispatch<AppDispatch>();

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentDetailsWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_details,
    PERFORM_ACTION.write,
  );

  const { shipmentDetailsRef } = useScroll();
  const {
    boxes,
    setBoxes,
    setRemoveFirstBoxDeleteOption,
    packageData,
    shipmentType,
    pickupType,
    setShipmentType,
    setPackageData,
    boxValidation,
    // selectedBox,
    setSelectedBox,
    extraDetails,
    setExtraDetails,
  } = useContext(CreateShipmentContext) as createShipmentContextType;
  const shipmentById = useSelector((state: RootStateType) => state.adminShipment.shipmentById);
  const shipmentActionControl = new ShipmentControl(shipmentById);

  useEffect(() => {
    if (shipmentById) {
      const { _packageId, shipmentMode, extraDetails, userReferenceNumber } = shipmentById;
      const { box, insurance, packaging, isDocument, unitOfMeasurement, currency, invoiceNumber } = _packageId;
      const boxData = structuredClone(box);
      setBoxes(boxData);
      setRemoveFirstBoxDeleteOption(true);
      setShipmentType({
        ...shipmentType,
        type: isDocument ? 'document' : 'parcel',
        shipmentMode,
        currency,
        unit: unitOfMeasurement === 'kg-cm' ? 'kg-cm' : 'lb-in',
        invoiceNumber: invoiceNumber || '',
        referenceNumber: userReferenceNumber,
      });
      setPackageData({
        ...packageData,
        unitOfMeasurement,
        insurance,
        packaging,
        userReferenceNumber: userReferenceNumber,
      });
      setSelectedBox(boxData[0]);
      if (extraDetails?.other) setExtraDetails({ ...extraDetails?.other });
    }
  }, [shipmentById]);

  const { currencyList } = useSelector((state: RootStateType) => state?.global);

  const onSave = () => {
    const packageObj = getSingleShipmentPackagePayload({
      boxes,
      packageData,
      shipmentType: { ...shipmentType, unit: packageData.unitOfMeasurement as LengthType },
      pickupType,
    });
    // console.log('BOXES', { packageObj, boxValidation, shipmentType });

    if (shipmentById?.shipmentNumber && boxValidation) {
      dispatch(
        updateShipment({
          shipmentNumber: shipmentById.shipmentNumber,
          package: packageObj,
          extraDetails: { other: extraDetails },
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(setInsureShipment(packageData.insurance));
  }, [packageData.insurance]);

  return (
    <>
      <Grid container mb={2} ref={shipmentDetailsRef}>
        <Title title="Shipment Details" removeBorder removePadding />
      </Grid>
      <Grid container gap={3} className="p-4 mb-3 border border-solid border-light rounded">
        <Grid container item columnSpacing={2} rowSpacing={3}>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <Dropdown
              id="ShipmentType"
              required
              label="Shipment Type"
              name="name"
              options={SHIPMENT_TYPE_LITS}
              value={shipmentType.type ? [shipmentType.type] : []}
              onChange={([value]) => setShipmentType({ ...shipmentType, type: value })}
              disabled={!isShipmentDetailsWriteAllowed}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <Dropdown
              id="ShipmentMode"
              required
              label="Shipment Mode"
              name="shipmentMode"
              options={SHIPMENT_MODES}
              value={shipmentType.shipmentMode ? [shipmentType.shipmentMode] : []}
              onChange={([value]) => setShipmentType({ ...shipmentType, shipmentMode: value })}
              disabled={!isShipmentDetailsWriteAllowed}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <Dropdown
              id="preferredUnit"
              required
              label="Preferred Unit"
              name="name"
              options={SHIPMENT_UNITS}
              value={packageData.unitOfMeasurement ? [packageData.unitOfMeasurement] : []}
              onChange={([value]) => setPackageData({ ...packageData, unitOfMeasurement: value })}
              disabled={!isShipmentDetailsWriteAllowed}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <Dropdown
              id="Currency"
              required
              label="Currency"
              name="name"
              options={currencyList}
              value={shipmentType.currency ? [shipmentType.currency] : []}
              onChange={([value]) => setShipmentType({ ...shipmentType, currency: value })}
              disabled={!isShipmentDetailsWriteAllowed}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <SbTextField
              label="No Of Boxes"
              name="name"
              value={shipmentById?._packageId?.box?.length || ''}
              disabled={!isShipmentDetailsWriteAllowed}
              //   onChange={handleChange}
              //   error={errors?.name}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <SbTextField
              label="Reference No."
              name="name"
              value={shipmentType?.referenceNumber}
              onChange={e => setShipmentType({ ...shipmentType, referenceNumber: e.target.value })}
              //   error={errors?.name}
              disabled={!isShipmentDetailsWriteAllowed}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <SbTextField
              label="Invoice No."
              name="name"
              disabled={!isShipmentDetailsWriteAllowed}
              value={shipmentType?.invoiceNumber || ''}
              onChange={e => setShipmentType({ ...shipmentType, invoiceNumber: e.target.value })}
              //   error={errors?.name}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <Dropdown
              id="purposeOfShipment"
              required
              label="Purpose Of Shipment"
              name="name"
              options={SHIPMENT_TYPE}
              value={extraDetails.purposeOfShipment ? [extraDetails.purposeOfShipment] : []}
              onChange={([value]) => setExtraDetails({ ...extraDetails, purposeOfShipment: value })}
              disabled={!isShipmentDetailsWriteAllowed}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={3.5} xl={2}>
            <SbTextField
              label="IOR/EORI No."
              name="name"
              value={extraDetails.ior}
              onChange={value => setExtraDetails({ ...extraDetails, ior: value.target.value })}
              disabled={!isShipmentDetailsWriteAllowed}
              //   error={errors?.name}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" className="pt-3" spacing={6}>
          <Insurance
            value={packageData.insurance}
            onChange={value => setPackageData({ ...packageData, insurance: value })}
          />
          <Packaging
            value={packageData.packaging}
            onChange={value => setPackageData({ ...packageData, packaging: value })}
          />
        </Grid>
        <ShipmentDetailsBoxWise
          removeShipmentInfo
          showViewMoreAction
          isShipmentDetailsWriteAllowed={isShipmentDetailsWriteAllowed}
        />
        {shipmentActionControl.canUpdateShipmentDetails && (
          <Actions disabled={!isShipmentDetailsWriteAllowed} onSave={onSave} />
        )}
      </Grid>
    </>
  );
}

// const IconStyles = { color: 'var(--mui-palette-primary-main)', height: '24px', width: '24px' };
interface InsuranceProps {
  value: boolean;
  onChange: (value: boolean) => void;
}
function Insurance(props: InsuranceProps) {
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentDetailsWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_details,
    PERFORM_ACTION.write,
  );

  const dispatch = useDispatch();

  const shipmentSetting = useSelector((state: RootStateType) => state.orgSetup.shipmentSetting);
  const disable = useMemo(() => !shipmentSetting?.insuranceCharge?.charge, [shipmentSetting?.insuranceCharge?.charge]);
  return (
    <Grid container item md="auto" alignItems={'center'} className={`${disable ? 'disabled-form' : 'cursor-pointer'}`}>
      <Checkbox
        name="insurance"
        id="insurance"
        className="mr-1/2"
        checked={!!props.value}
        onChange={() => {
          props.onChange(!props.value);
          dispatch(setInsureShipment(!props.value));
        }}
        // onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        // disabled={!isShipmentDetailsWriteAllowed}
        disabled={disable || !isShipmentDetailsWriteAllowed}
      />
      {/* <GppGoodIcon sx={IconStyles} /> */}
      <img src="/images/Boxes/Insurance.svg" alt="logistics" className="mr-1/2 sm-text" />
      <Typography
        component={'label'}
        htmlFor="insurance"
        className="md-text font-medium sb-text-info ml-1"
        variant="caption"
      >
        Insure Your Shipment
      </Typography>
    </Grid>
  );
}

interface PackagingProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

function Packaging(props: PackagingProps) {
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isShipmentDetailsWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Single_Shipment_View_OR_Processing.shipment_details,
    PERFORM_ACTION.write,
  );

  const shipmentSetting = useSelector((state: RootStateType) => state.orgSetup.shipmentSetting);
  const disable = useMemo(() => !shipmentSetting?.packagingCharge?.charge, [shipmentSetting?.packagingCharge?.charge]);
  return (
    <Grid container item md="auto" alignItems={'center'} className={`${disable ? 'disabled-form' : 'cursor-pointer'}`}>
      <Checkbox
        name="packaging"
        id="packaging"
        className="mr-1/2"
        checked={!!props.value}
        onChange={() => props.onChange(!props.value)}
        inputProps={{ 'aria-label': 'controlled' }}
        // disabled={!isShipmentDetailsWriteAllowed}
        disabled={disable || !isShipmentDetailsWriteAllowed}
      />
      {/* <Box sx={IconStyles} /> */}
      <img src="/images/Boxes/Box.svg" alt="logistics" className="mr-1/2 sm-text" />
      <Typography
        component={'label'}
        htmlFor="packaging"
        className="md-text font-medium sb-text-info ml-1"
        variant="caption"
      >
        Add Packaging
      </Typography>
    </Grid>
  );
}

function ContextWrapper() {
  return (
    <CreateShipmentWrapper>
      <ShipmentDetails />
    </CreateShipmentWrapper>
  );
}

export default ContextWrapper;
