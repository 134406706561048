import { PayloadAction } from '@reduxjs/toolkit';
import { SuccessPayload } from '../../../types';
import {
  PayloadType,
  OrganizationSetupStateType,
  kycSetting,
  invoiceSetting,
  ShipmentSetting,
  OrgRes,
  ErrorType,
  OrganizationPublicData,
  organizationData,
} from './Type';

function pending(state: OrganizationSetupStateType) {
  state.loading = true;
}

function rejected(state: OrganizationSetupStateType) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}
//---------------------------------------------------org onboard

function saveOrganizationFulfilled(state: OrganizationSetupStateType, action: PayloadAction<{ data: OrgRes }>) {
  state.resetSaveOrgSuccess = true;
  state.loading = false;
  state.savedOrgRes = action.payload.data;
}

function saveOrganizationRejected(state: OrganizationSetupStateType, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = {
    msg: action?.payload?.msg,
    errorDetails: action?.payload?.errorDetails,
    isError: true,
  };
}

//---------------------------------------------------save kyc settings
function saveKycSettingsPending(state: OrganizationSetupStateType) {
  state.loading = true;
}

function saveKycSettingsFulfilled(state: OrganizationSetupStateType, _data: PayloadType) {
  state.loading = false;
  state.kycSettingsSuccess = true;
}

//---------------------------------------------------save invoice settings
function SaveInvoicePending(state: OrganizationSetupStateType) {
  state.loading = true;
}

function SaveInvoiceFulfilled(state: OrganizationSetupStateType, _data: PayloadType) {
  state.loading = false;
  state.saveInvoiceSuccess = true;
}

function SaveInvoiceRejected(state: OrganizationSetupStateType) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

//---------------------------------------------------save kyc settings
function getKycSettingsPending(state: OrganizationSetupStateType) {
  state.loading = true;
}

function getKycSettingsFulfilled(state: OrganizationSetupStateType, _data: PayloadType<kycSetting>) {
  state.kycSetting = _data.payload.data;
  state.loading = false;
}

function getKycSettingsRejected(state: OrganizationSetupStateType) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

//---------------------------------------------------Get invoice settings
function getInvoiceSettingsFulfilled(state: OrganizationSetupStateType, data: PayloadType<invoiceSetting>) {
  state.invoiceSetting = data.payload.data;
  state.loading = false;
}

//---------------------------------------------------Get Shipment settings
function getShipmentSettingsFulfilled(state: OrganizationSetupStateType, data: PayloadType<ShipmentSetting>) {
  state.shipmentSetting = data.payload.data;
  state.loading = false;
}

//---------------------------------------------------save invoice settings
function saveShipmentSettingPending(state: OrganizationSetupStateType) {
  state.loading = true;
}

function saveShipmentSettingFulfilled(state: OrganizationSetupStateType, data: PayloadType<ShipmentSetting>) {
  state.shipmentSettingSuccess = true;
  state.loading = false;
  state.shipmentSetting = data.payload.data;
}

function saveShipmentSettingRejected(state: OrganizationSetupStateType) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

function getOrganizationPublicPending(state: OrganizationSetupStateType) {
  state.loading = true;
}

function getOrganizationPublicFulfilled(state: OrganizationSetupStateType, _data: PayloadType<OrganizationPublicData>) {
  state.loading = false;
  state.organizationPublicData = _data.payload.data;
}

function getOrganizationPublicRejected(state: OrganizationSetupStateType) {
  state.loading = false;
  state.orgNotFound = true;
  state.error = { msg: 'error', isError: true };
}

//---------------------------------------------------ORG DETAILS
function getOrganizationDetailsPending(state: OrganizationSetupStateType) {
  state.loading = true;
}

function getOrganizationDetailsFulfilled(state: OrganizationSetupStateType, _data: PayloadType<organizationData>) {
  state.organizationData = _data.payload.data;
  console.log('state.organizationData', state.organizationData);
  state.loading = false;
}

function getOrganizationDetailsRejected(state: OrganizationSetupStateType) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

//---------------------------------------------------ORG DETAILS
function updateOrganizationDetailsPending(state: OrganizationSetupStateType) {
  state.loading = true;
}

function updateOrganizationDetailsFulfilled(state: OrganizationSetupStateType, _data: PayloadType<organizationData>) {
  state.loading = false;
}

function updateOrganizationDetailsRejected(state: OrganizationSetupStateType) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

//---------------------------------------------------TAX INFO LIST
function getTaxInfoListFulfilled(
  state: OrganizationSetupStateType,
  action: PayloadAction<SuccessPayload<organizationData['taxInformation']>>,
) {
  const lists = action.payload.data;
  state.loading = false;
  state.dropdownTaxList = lists?.length
    ? lists.map(({ display, value, _id, key }) => ({
        display: `${display} @${value}%`,
        value: _id,
        metadata: { _id, key, displayKey: display, percentage: value, breakDowns: [] },
      }))
    : [];
}

export {
  pending,
  rejected,
  //
  saveOrganizationFulfilled,
  saveOrganizationRejected,
  //
  saveKycSettingsPending,
  saveKycSettingsFulfilled,
  //
  getKycSettingsPending,
  getKycSettingsFulfilled,
  getKycSettingsRejected,
  //
  SaveInvoicePending,
  SaveInvoiceFulfilled,
  SaveInvoiceRejected,

  //
  getInvoiceSettingsFulfilled,

  //
  getShipmentSettingsFulfilled,
  //
  saveShipmentSettingPending,
  saveShipmentSettingFulfilled,
  saveShipmentSettingRejected,

  //
  getOrganizationPublicPending,
  getOrganizationPublicFulfilled,
  getOrganizationPublicRejected,

  //
  getOrganizationDetailsPending,
  getOrganizationDetailsFulfilled,
  getOrganizationDetailsRejected,

  //
  updateOrganizationDetailsPending,
  updateOrganizationDetailsFulfilled,
  updateOrganizationDetailsRejected,

  //
  getTaxInfoListFulfilled,
};
