import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import SbTooltip from '../../components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import SBRadioButton from '../../components/RadioButton';
import { FormikType, SHIPMENT_STETTING_FORM } from './type';

type PROPS_TYPE = FormikType & {
  values: SHIPMENT_STETTING_FORM['pickupTimeSlots'];
  disabled?: boolean;
};

const PICKUP_TIME_SLOT = {
  office_hr: {
    label: 'Office Hours',
    value: 'office_hr',
    slots: [{ from: '9 am', to: '6 pm' }],
  },
  '3_hr': {
    label: '3 Hours',
    value: '3_hr',
    slots: [
      { from: '9 am', to: '12 pm' },
      { from: '12 pm', to: '3 pm' },
      { from: '3 pm', to: '6 pm' },
    ],
  },
  '4_hr': {
    label: '4 Hours',
    value: '4_hr',
    slots: [
      { from: '9 am', to: '1 pm' },
      { from: '1 pm', to: '5 pm' },
      { from: '5 pm', to: '6 pm' },
    ],
  },
  '6_hr': {
    label: '6 Hours',
    value: '6_hr',
    slots: [
      { from: '9 am', to: '3 pm' },
      { from: '3 pm', to: '6 pm' },
    ],
  },
  not_required: {
    label: 'Not Required',
    value: 'not_required',
    slots: [],
  },
};

const TIME_SLOTS_VALUES = Object.values(PICKUP_TIME_SLOT).map(({ label, value }) => ({ label, value }));

function ShipmentPickupTime(props: PROPS_TYPE) {
  const theme = useTheme();

  const onChangeTimeSlot = (event: React.ChangeEvent<HTMLInputElement>) => {
    type keys = keyof typeof PICKUP_TIME_SLOT;
    const value: keys = event.target.value as keys;
    const slot = PICKUP_TIME_SLOT[value];
    props.setFieldValue?.('pickupTimeSlots', {
      slotGroup: slot.value,
      slots: slot.slots,
    });
  };

  return (
    <Grid item container xs={12} alignItems="baseline" spacing={2}>
      <Grid container item xs={12} md={2.5} lg={2.5} xl={2} alignItems="center">
        <Typography className="md-text font-semibold mr-1">Pick up Time Slot</Typography>
        <SbTooltip
          placement="right"
          title="These are some preferred time slot for shipment pick up which will visible at  the time of shipment booking."
        >
          <InfoRounded />
        </SbTooltip>
      </Grid>
      <Grid container item xs={12} md={9.5} lg={9.5} xl={10} rowGap={2}>
        <Grid item xs={12}>
          <SBRadioButton
            list={TIME_SLOTS_VALUES}
            value={props.values.slotGroup}
            onChange={onChangeTimeSlot}
            disabled={props.disabled}
          />
        </Grid>
        <Grid
          className="text-center"
          display="grid"
          item
          xs={12}
          gap={2}
          gridTemplateColumns="repeat(auto-fill, 110px)"
        >
          {props.values.slots.map(slot => (
            <Typography
              key={`${slot.from} - ${slot.to}`}
              className="xs-text border-1px rounded-xs font-medium p-1"
              borderColor={theme.palette.border.light}
            >
              {slot.from} - {slot.to}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ShipmentPickupTime;
