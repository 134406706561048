import React from 'react';
// import SbButton from '../../../../components/Button';
import Modal from '../../../../components/Modal/error';

interface PropsType {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function BoxCountValidation(props: PropsType) {
  return (
    <Modal
      icon={'/images/Boxes/WarningContrast.svg'}
      {...props}
      title={'Please delete the box first!'}
      btnText="Close"
      closeIcon={true}
    />
  );
}
export default BoxCountValidation;
