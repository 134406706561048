import React from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import { RootStateType } from '@Store';
import PoweredBy from '@Components/PoweredBy';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';

const FooterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.primary.main,
  height: '157px',
  padding: '0 20px',
  paddingTop: '10px',
  position: 'absolute',
  // bottom: '0px',
  bottom: '0',
  left: '0px',
  right: '0px',
}));

const FHead = styled('p')({
  textTransform: 'uppercase',
  fontSize: '20px',
  fontWeight: '700',
});

const FBody = styled('p')({
  fontSize: '12px',
});

const ATag = styled('a')({
  textDecoration: 'none',
  color: 'inherit',
});

const Footer = (props: { footerContainerStyle?: object }) => {
  const { pathname } = useLocation();
  const orgPublicData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { contactInfo } = orgPublicData || {};
  const { address, contactNumber, email, telephoneCode } = contactInfo || {};
  return (
    <FooterContainer sx={props?.footerContainerStyle}>
      <div style={{ color: '#fff', padding: '20px', flex: '1' }}>
        <FHead>
          <LocationOnIcon style={{ fontSize: '17px', marginRight: '5px' }} />
          Address
        </FHead>
        <FBody>
          {/* 34, Adarsh Industrial Estate, Sahar Road, Andheri East, Landmark Cigarette factory, Mumbai, Maharashtra,
          400099 */}
          {address?.line1} {address?.line2} {address?.city} {address?.state} {address?.country?.name} {address?.pincode}
        </FBody>
      </div>
      <div style={{ color: '#fff', paddingTop: '20px', flex: '1' }}>
        <FHead>
          <MailIcon style={{ fontSize: '17px', marginRight: '5px' }} />
          E-mail
        </FHead>
        <FBody>
          <ATag href={`mailto:${email}`}>{email}</ATag>
        </FBody>
      </div>
      <div style={{ color: '#fff', paddingTop: '20px', flex: '1' }}>
        <FHead>
          <AddIcCallIcon style={{ fontSize: '17px', marginRight: '5px' }} />
          Call
        </FHead>
        <FBody>
          {telephoneCode && contactNumber && (
            <ATag href={`tel:+${telephoneCode + contactNumber}`}>
              {telephoneCode} {contactNumber}
            </ATag>
          )}
        </FBody>
      </div>
      <div style={{ color: '#fff', paddingTop: '20px', flex: '1' }}>
        <FHead>
          <InsertDriveFileIcon style={{ fontSize: '17px', marginRight: '5px' }} />
          <ATag target="_blank" href="/terms-and-conditions-agreement">
            Terms And Conditions
          </ATag>
        </FHead>
        <FBody>
          <ATag target="_blank" href="/privacy-policy-agreement">
            Privacy Policy
          </ATag>
        </FBody>
      </div>
      {pathname === '/track-shipment' && (
        <Box position={'absolute'} bottom={'2rem'} right={'2rem'}>
          <PoweredBy color="#fff" />
        </Box>
      )}
    </FooterContainer>
  );
};

export default Footer;
