import React from 'react';

import { Grid } from '@mui/material';
import Title from '../../components/Title';
import { Outlet, useLocation } from 'react-router-dom';
import Divider from '../../components/Divider';
import Loader from '../../components/Loader';
import { RootStateType } from '../../store';
import { useSelector } from 'react-redux';
import BackButton from '@Components/BackButton';

function GlobalCharge() {
  const location = useLocation();
  const systemListLoading = useSelector((state: RootStateType) => state.System.loading);
  const serviceListLoading = useSelector((state: RootStateType) => state.carrierAndService.loading);
  const taxInfoListLoading = useSelector((state: RootStateType) => state.orgSetup.loading);
  const { loading: globalChargeLoading } = useSelector((state: RootStateType) => state.globalCharge);

  const showBackButton =
    location.pathname.startsWith('/global-charges/create') || location.pathname.startsWith('/global-charges/edit');

  return (
    <Loader loading={systemListLoading || serviceListLoading || globalChargeLoading || taxInfoListLoading} overly>
      <>
        <Grid container alignItems="center">
          <Grid item xs={6} sx={{ display: 'flex', gap: '0.5rem' }}>
            {showBackButton && <BackButton link="/global-charges" />}
            <Title title="Global Charges" subTitle="View and update global charges"></Title>
          </Grid>
        </Grid>
        <Divider />
        <Outlet />
      </>
    </Loader>
  );
}

export default GlobalCharge;
