import { togglePaymentModal } from '@Reducers/Shipment';
import { userLogin } from '@Services/hasAdminAccess';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';

export function usePayment() {
  const dispatch = useDispatch<AppDispatch>();
  const { profile } = useSelector((state: RootStateType) => state.loginUser);
  // const { paymentGetwayList } = useSelector((state: RootStateType) => state.Wallet);
  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();

  const isPrepaid = profile?._accountId.isPrepaid || false;
  const isAccountHolder = profile?._accountId?.isAccountHolder;
  const accountNumber = profile?.accountNumber || '';
  const isPrepaidUser = isAccountHolder ? isPrepaid : true;
  const isTaxAllowed = profile?._accountId.isTaxAllowed;

  const setOpenPaymentModal = (open: boolean) => {
    dispatch(togglePaymentModal(open));
  };
  return {
    setOpenPaymentModal,
    isPrepaidUser,
    accountNumber,
    profile,
    isTaxAllowed,
    hasPaymentAccess: isAdmin ? false : isPrepaidUser ? true : false,
  };
}
