import { PayloadAction } from '@reduxjs/toolkit';
import {
  OdaSuccessResponse,
  OdaCreateResponse,
  OdaListResponse,
  OdaState,
  OdaObj,
  SingleOdaCharges,
  OdaListObj,
  MergedChargesArr,
} from './Type';

function getOdaListPending(state: OdaState) {
  state.loading = true;
}

function getOdaListFulfilled(state: OdaState, data: { payload: OdaListResponse }) {
  const { list, currentPage, perPage, totalCount } = data.payload.data;
  const { mergedCharges } = mergedChargesArr(list, new Date(state.filters.selectedDate));
  state.mergedChargesArr = mergedCharges;
  state.data.list = list;
  state.data.filteredList = filterODAChargesByDate(state);
  state.data.totalCount = totalCount;
  state.data.currentPage = currentPage;
  state.data.perPage = perPage;
  state.loading = false;
}

function getOdaListRejected(state: OdaState) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function createOdaPending(state: OdaState) {
  state.loading = true;
}

function createOdaFulfilled(state: OdaState, _data: { payload: OdaCreateResponse }) {
  state.loading = false;
}

//
function getOdaChargeByIdFulfilled(state: OdaState, data: { payload: OdaSuccessResponse<SingleOdaCharges> }) {
  state.loading = false;
  state.singleOdaCharge = data.payload.data;
}

// -----------------------------------------------------------------------------------------------------------------
function getOdaByIdPending(state: OdaState) {
  state.loading = true;
}

function getOdaByIdFulfilled(state: OdaState, data: { payload: OdaSuccessResponse<OdaObj> }) {
  state.singleOda = data.payload.data;
  state.loading = false;
}

function getOdaByIdRejected(state: OdaState) {
  state.loading = false;
  state.isRedirect = true;
  // state.error = { msg: 'The desired ODA does not exist.', isError: true };
}

// -----------------------------------------------------------------------------------------------------------------
function updateOdaFulfilled(state: OdaState, data: { payload: OdaSuccessResponse<OdaObj> }) {
  state.singleOda = data.payload.data;
  state.loading = false;
}

// -----------------------------------------------------------------------------------------------------------------
function deleteOdaFulfilled(state: OdaState) {
  state.loading = false;
}

function filterODAChargesByDate(state: OdaState) {
  const selectedDate = new Date(state.filters.selectedDate).getTime();
  const updatedList = state.data.list.filter(
    x => new Date(x.startDate).getTime() <= selectedDate && selectedDate <= new Date(x.endDate).getTime(),
  );
  return updatedList;
}

function clearFilters(state: OdaState) {
  const newList = filterODAChargesByDate(state);
  state.data.filteredList = newList;
  state.filters.csp = [];
}

function updateFilters(state: OdaState, action: PayloadAction<{ csp: string[] }>) {
  const csp = action.payload.csp;
  console.log({ csp });
  if (!csp.length) return clearFilters(state);
  const chargeListByDate = filterODAChargesByDate(state);
  state.filters.csp = csp;
  state.data.filteredList = chargeListByDate.filter(x => {
    return csp.includes(x._serviceProviderId._id);
  });
}

function changeNavigatedDate(state: OdaState, action: PayloadAction<{ selectedDate: string }>) {
  state.filters.selectedDate = action.payload.selectedDate;
  updateFilters(state, { payload: { csp: state.filters.csp }, type: '' });
}

function mergedChargesArr(data: OdaListObj[], selectedDate: Date) {
  const mergedCharges: MergedChargesArr[] = [];
  const selectedDateCharges: OdaListObj[] = [];
  const selectedDateTime = selectedDate.getTime();

  const sortedChargesArr = data.sort((r1, r2) => new Date(r1.startDate).getTime() - new Date(r2.startDate).getTime());

  sortedChargesArr.forEach(chargeObj => {
    const startDateTime = new Date(chargeObj.startDate).getTime();
    const endDateTime = new Date(chargeObj.endDate).getTime();

    if (startDateTime <= selectedDateTime && endDateTime >= selectedDateTime) {
      selectedDateCharges.push(chargeObj);
    }

    const activeRange = mergedCharges.at(-1);
    if (!activeRange) return mergedCharges.push({ startDate: startDateTime, endDate: endDateTime });

    if (startDateTime <= activeRange.endDate) {
      if (endDateTime > activeRange.endDate) activeRange.endDate = endDateTime;
    } else if (startDateTime > activeRange.endDate) {
      mergedCharges.push({ startDate: startDateTime, endDate: endDateTime });
    }
  });
  // console.log({ mergedCharges: mergedCharges.map(x => ({ sd: new Date(x.startDate), ed: new Date(x.endDate) })) });
  return { mergedCharges, selectedDateCharges, data };
}

export {
  getOdaListPending,
  getOdaListFulfilled,
  getOdaListRejected,
  //
  createOdaPending,
  createOdaFulfilled,
  //
  getOdaByIdPending,
  getOdaByIdFulfilled,
  getOdaByIdRejected,
  //
  updateOdaFulfilled,

  //
  getOdaChargeByIdFulfilled,

  //
  deleteOdaFulfilled,

  //
  clearFilters,
  updateFilters,
  changeNavigatedDate,
};
