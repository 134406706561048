import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { styled } from '@mui/system';

import { Checkbox, Grid, TableBody, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import Title from '../../components/Title';

import SbButton from '../../components/Button';
import DateRangePicker from '../../components/DateRangePicker';
import FilterSelect from '../../components/Filter/Dropdown';
import FilterSearch from '../../components/Filter/Search';
import { SBTable } from '../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../components/Table/TableCell';
import { SBTableRow } from '../../components/Table/TableRow';
import { SbTooltip } from '../../components/Tooltip';
// ICONS

import Loader from '@Components/Loader';
import { SbMenu, SbMenuItem } from '@Components/Menu';
import { BulkExportOrder, downloadBulkShippingLabel } from '@Reducers/ShipmentHistory/actions';
import ShippingLabelIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ProformaInvoiceIcon from '@mui/icons-material/SummarizeOutlined';
import { AppDispatch, RootStateType } from '../../store';
import { getBulkOrderSummaryList, setSelectedBulkOrderSummary } from '../../store/reducers/BulkOrderSummary';
import { BulkOrderSummaryObj, BulkOrderSummaryQuery } from '../../store/reducers/BulkOrderSummary/Type';
import { getBulkShipmentAmount } from '@Reducers/BulkOrderSummary/actions';
import { FilterObj, BulkOrderSummaryTableType } from './Types';
import { usePayment } from '@Hook/usePayment';
import PaymentModal from './BulkPayment/PaymentModal';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const SearchFilterList = [{ label: 'Batch No.', value: 'batchNumber' }];

const StatusList = [
  { display: 'Processed', value: 'processed' },
  { display: 'Unprocessed', value: 'unprocessed' },
];

const TableActionContainer = styled('div')(({ theme }) => ({
  padding: '32px 16px',
  display: 'grid',
  // gridTemplateColumns: '510px 1fr 350px fit-content(140px) fit-content(140px) 140px',
  gridTemplateColumns: '410px 1fr 200px 240px 136px 113px',
  alignItems: 'center',
  columnGap: '15px',
  [theme.breakpoints.down('xl')]: {
    padding: '32px 0px',
    columnGap: '8px',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto auto',
    marginBottom: '10px',
    gap: '10px',
    '& > :nth-child(1), & > :nth-child(2)': {
      gridColumn: 'span 2',
    },
    '& > :nth-child(4), & > :nth-child(5)': {
      gridColumn: 'span 1',
    },
  },
}));

export const actionIconStyle = { width: '20px', height: '20px', cursor: 'pointer' };

function BulkOrderActions(props: { summary: BulkOrderSummaryObj }) {
  const dispatch = useDispatch<AppDispatch>();
  const { successfulShipmentNumber, batchNumber } = props.summary;
  return (
    <Grid container item justifyContent="space-around" style={{ width: '68px' }}>
      <SbTooltip title="Summary">
        <ProformaInvoiceIcon
          style={actionIconStyle}
          // onClick={() => dispatch(downloadBulkProformaInvoice({ shipmentNumbers: successfulShipmentNumber }))}
          onClick={() => dispatch(BulkExportOrder({ batchNumber: [batchNumber] }))}
        />
      </SbTooltip>

      <SbMenu icon={<ShippingLabelIcon />} title="Shipping Label">
        <SbMenuItem
          name="A4"
          onClick={() => {
            dispatch(
              downloadBulkShippingLabel({
                fileType: 'A4',
                shipmentNumber: successfulShipmentNumber,
              }),
            );
          }}
        ></SbMenuItem>
        <SbMenuItem
          name="A5"
          onClick={() => {
            dispatch(
              downloadBulkShippingLabel({
                fileType: 'A5',
                shipmentNumber: successfulShipmentNumber,
              }),
            );
          }}
        ></SbMenuItem>
      </SbMenu>
    </Grid>
  );
}

function BulkOrderSummaryTable(props: BulkOrderSummaryTableType) {
  const {
    data: { list, currentPage, perPage },
    selectedBulkOrders,
  } = useSelector((state: RootStateType) => state.bulkOrderSummary);
  const { setBulkPayment } = props;
  const dispatch = useDispatch<AppDispatch>();
  const onSelectBulkOrder = function (summaryId: string, isChecked: boolean) {
    const newSelectedBulkOrderSummary = { ...selectedBulkOrders };
    if (isChecked) newSelectedBulkOrderSummary[summaryId] = true;
    else delete newSelectedBulkOrderSummary[summaryId];

    dispatch(setSelectedBulkOrderSummary(newSelectedBulkOrderSummary));
  };

  const checkAllBulkOrderSummary = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newBulkSummaryCheckedList = list.reduce((op: { [k: string]: boolean }, summary: BulkOrderSummaryObj) => {
        op[summary._id] = true;
        return op;
      }, {});
      dispatch(setSelectedBulkOrderSummary(newBulkSummaryCheckedList));
    } else {
      dispatch(setSelectedBulkOrderSummary({}));
    }
  };
  useEffect(() => {
    setBulkPayment(Object.keys(selectedBulkOrders).length > 0);
  }, [selectedBulkOrders]);
  useEffect(() => {
    dispatch(setSelectedBulkOrderSummary({}));
  }, []);
  return (
    <SBTable>
      <TableHead>
        <TableRow>
          <TableHeadCell>
            <Checkbox
              style={{ color: 'inherit' }}
              checked={Object.keys(selectedBulkOrders).length === list.length}
              onChange={checkAllBulkOrderSummary}
            />
          </TableHeadCell>
          <TableHeadCell>Sr no.</TableHeadCell>
          <TableHeadCell>Shipment Date</TableHeadCell>
          <TableHeadCell>Batch No</TableHeadCell>
          <TableHeadCell>Status</TableHeadCell>
          <TableHeadCell>Download</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list?.length
          ? list.map((summary, index) => (
              <SBTableRow key={summary._id} className={selectedBulkOrders[summary._id] ? 'selected' : ''}>
                <TableRowCell align="center">
                  <div>
                    <Checkbox
                      style={{ color: 'inherit' }}
                      onChange={e => onSelectBulkOrder(summary._id, e.target.checked)}
                      checked={!!selectedBulkOrders[summary._id]}
                    />
                  </div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text font-medium">
                  <div>{index + 1 + (currentPage - 1) * perPage}</div>
                </TableRowCell>
                <TableRowCell align="center" className="md-text font-medium">
                  {summary.createdAt ? moment(summary.createdAt).format('DD/MM/YYYY') : '--'}
                </TableRowCell>
                <TableRowCell align="center" className="md-text font-medium">
                  {summary.batchNumber}
                </TableRowCell>
                <TableRowCell>
                  <Grid container justifyContent={'center'}>
                    {!!summary.successfulShipment && (
                      <SbButton
                        variant="contained"
                        className="mr-2"
                        sx={{
                          borderRadius: '8px !important',
                          backgroundColor: '#F0F0F0',
                          color: 'var(--mui-palette-background-black)',
                          boxShadow: 'unset',
                          ':hover': {
                            backgroundColor: '#F0F0F0',
                            boxShadow: 'unset',
                          },
                        }}
                      >
                        Processed : {summary.successfulShipment}
                      </SbButton>
                    )}
                    {!!summary.failedShipment && (
                      <SbButton
                        variant="contained"
                        sx={{
                          borderRadius: '8px !important',
                          backgroundColor: '#F0F0F0',
                          color: 'var(--mui-palette-background-black)',
                          boxShadow: 'unset',
                          ':hover': {
                            backgroundColor: '#F0F0F0',
                            boxShadow: 'unset',
                          },
                        }}
                      >
                        Unprocessed : {summary.failedShipment}
                      </SbButton>
                    )}
                    {summary.status == 'processing' && (
                      <SbButton
                        variant="contained"
                        sx={{
                          borderRadius: '8px !important',
                          backgroundColor: '#F0F0F0',
                          color: 'var(--mui-palette-background-black)',
                          boxShadow: 'unset',
                          ':hover': {
                            backgroundColor: '#F0F0F0',
                            boxShadow: 'unset',
                          },
                        }}
                      >
                        Processing
                      </SbButton>
                    )}
                  </Grid>
                </TableRowCell>
                <TableRowCell>
                  <div className="flex justify-center">
                    <BulkOrderActions summary={summary} />
                  </div>
                </TableRowCell>
              </SBTableRow>
            ))
          : null}
      </TableBody>
    </SBTable>
  );
}

export default function BulkOrderSummary() {
  const [filterSelectValue, setFilterSelectValue] = useState(SearchFilterList[0].value);
  const [filterText, setFilterText] = useState('');
  const [status, setStatus] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const [prevSearchObj, setPrevSearchObj] = useState<string>('');
  const [bulkPayment, setBulkPayment] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const { setOpenPaymentModal, hasPaymentAccess } = usePayment();
  const { openPaymentModal } = useSelector((state: RootStateType) => state.shipment);
  const { selectedBulkOrders } = useSelector((state: RootStateType) => state.bulkOrderSummary);
  const { enqueueSnackbar } = useSnackbar();
  const [bulkPaymentData, setPaymentData] = useState({
    batchNumber: '',
    shipmentNumbers: [''],
    payableAmount: 0,
  });
  const navigate = useNavigate();
  const {
    data: { currentPage, perPage, totalCount, list },
  } = useSelector((state: RootStateType) => state.bulkOrderSummary);
  const loading = useSelector(
    (state: RootStateType) => state.shipmentHistory.loading || state.bulkOrderSummary.loading,
  );
  const handleBulkPaymentClick = async () => {
    const selectedIds = Object.keys(selectedBulkOrders);

    if (selectedIds.length === 1) {
      const selectedOrder = list.find(order => order._id === selectedIds[0]);

      if (selectedOrder && selectedOrder.successfulShipment != 0) {
        try {
          setLoading(true);
          const getBulkPayable = await dispatch(
            getBulkShipmentAmount({ shipmentNumbers: selectedOrder.successfulShipmentNumber }),
          ).unwrap();
          setLoading(false);
          const payableAmount = getBulkPayable.payableAmount;
          if (payableAmount === 0) {
            dispatch(
              SuccessErrorModalOpen({
                type: 'error',
                title: 'Already paid',
                subTitle: 'All shipments of this batch are already paid!',
              }),
            );
          } else {
            setPaymentData({
              batchNumber: selectedOrder.batchNumber,
              shipmentNumbers: getBulkPayable.shipmentNumbers,
              payableAmount,
            });
            setOpenPaymentModal(true);
          }
        } catch (err) {
          console.error(err);
          setLoading(false);
        }
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Oops',
            subTitle: 'Please select only processed order',
          }),
        );
      }
    } else {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops',
          subTitle: 'Please select only one processed order',
        }),
      );
    }
  };
  function handlePaymentSuccess() {
    navigate('/shipment/history');
    enqueueSnackbar('Payment done successfully!', { variant: 'success', key: new Date().getTime() });
  }
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fromDate = moment(dateRange.startDate).format('DD/MM/YYYY');
    const toDate = moment(dateRange.endDate).format('DD/MM/YYYY');
    dispatch(getBulkOrderSummaryList({ currentPage: 1, perPage: 10, fromDate, toDate }));
  }, []);

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    dispatch(getBulkOrderSummaryList(getFilterObj({ dateRange })));
  };

  const onStatusFilter = (selected: Array<string>) => {
    setStatus(selected);
    dispatch(getBulkOrderSummaryList(getFilterObj({ status: selected })));
  };

  const getFilterObj = (options: FilterObj): BulkOrderSummaryQuery => {
    const filterDate = options.dateRange || dateRange;
    const fromDate = moment(filterDate.startDate).format('DD/MM/YYYY');
    const toDate = moment(filterDate.endDate).format('DD/MM/YYYY');
    const filterStatus = options.status || status;
    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;
    const filterQuery: BulkOrderSummaryQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,
      fromDate,
      toDate,
      status: filterStatus,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }
    const compareObj = {
      fromDate,
      toDate,
      status: filterStatus,
      searchKey: filterQuery['searchKey'],
      searchValue: filterQuery['searchValue'],
    };
    if (prevSearchObj !== '' && JSON.stringify(compareObj) !== prevSearchObj) {
      filterQuery.currentPage = 1;
    }
    setPrevSearchObj(JSON.stringify(compareObj));
    return filterQuery;
  };
  return (
    <Loader loading={loading || isLoading} overly>
      <>
        <Title title="Bulk Shipments History" removeBorder />

        <TableActionContainer>
          <>
            <FilterSearch
              onSelectChange={(value: string) => {
                setFilterSelectValue(value);
              }}
              list={SearchFilterList}
              selectValue={filterSelectValue}
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={() => {
                dispatch(getBulkOrderSummaryList(getFilterObj({})));
              }}
              onTextClear={() => {
                setFilterText('');
                dispatch(getBulkOrderSummaryList(getFilterObj({ searchValue: undefined })));
              }}
            />
            <div></div>
            <div>
              {' '}
              {hasPaymentAccess && (
                <Tooltip title={!bulkPayment ? 'Please select a batch number for bulk payment' : ''} arrow>
                  <span>
                    <SbButton onClick={handleBulkPaymentClick} variant="contained" disabled={!bulkPayment}>
                      Bulk Payment
                    </SbButton>
                  </span>
                </Tooltip>
              )}
            </div>
            <DateRangePicker onChange={onDateChange} value={dateRange} />
            <FilterSelect id="status" label="Status" options={StatusList} value={status} onChange={onStatusFilter} />
            <SbButton
              className="xs-text ml-1"
              variant="outlined"
              sx={{ borderRadius: '8px !important', padding: '8px !important' }}
              onClick={() => {
                setStatus([]);
                setFilterText('');
                setDateRange({
                  startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                  endDate: moment().endOf('day').toDate(),
                });
                dispatch(
                  getBulkOrderSummaryList(
                    getFilterObj({
                      status: [],
                      searchValue: '',
                      dateRange: {
                        startDate: moment().subtract(3, 'months').startOf('day').toDate(),
                        endDate: moment().endOf('day').toDate(),
                      },
                    }),
                  ),
                );
              }}
            >
              Clear Filters
            </SbButton>
          </>
        </TableActionContainer>

        <div
          style={{
            display: 'block',
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            maxWidth: '100%',
          }}
        >
          <div
            style={{
              minWidth: '600px',
            }}
          >
            <BulkOrderSummaryTable setBulkPayment={setBulkPayment} />
          </div>
          {!!totalCount && !!perPage && (
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage - 1}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                dispatch(getBulkOrderSummaryList(getFilterObj({ currentPage: page + 1 })));
              }}
              onRowsPerPageChange={event => {
                dispatch(getBulkOrderSummaryList(getFilterObj({ perPage: parseInt(event.target.value) })));
              }}
            />
          )}
        </div>
        {openPaymentModal && (
          <PaymentModal
            isOpen={openPaymentModal}
            handleClose={() => setOpenPaymentModal(false)}
            paymentDetails={{
              shipmentNumbers: bulkPaymentData.shipmentNumbers,
              batchNumber: bulkPaymentData.batchNumber,
              payableAmount: bulkPaymentData.payableAmount,
              handlePaymentSuccess,
            }}
          />
        )}
      </>
    </Loader>
  );
}
