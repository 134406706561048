import { createSlice } from '@reduxjs/toolkit';
import {
  getWarehouseListForShipmentFulfilled,
  getWarehouseListFulfilled,
  getWarehouseListPending,
  getWarehouseListRejected,
  updateWarehouseFulfilled,
} from './case';
import { createWarehouseFulfilled, createWarehousePending, createWarehouseRejected } from './case';
import { getWarehouseByIdFulfilled, getWarehouseByIdPending, getWarehouseByIdRejected } from './case';
import { WarehouseState } from './Type';
import {
  getWarehouseList,
  createWarehouse,
  getWarehouseById,
  updateWarehouseById,
  getWarehouseListForShipment,
} from './actions';

const initialState = <WarehouseState>{
  singeWarehouse: null,
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    selectedList: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 10,
  },
  warehouseListOptions: [],
  warehouseListOptionsHashById: {},

  warehouseListForShipmentOptions: [],
  warehouseListForShipmentOptionsHashById: {},
  warehouseListForShipent: {
    list: [],
  },
  isWarehouseSelectedINFedX: '',
};

const reduxStateSlice = createSlice({
  name: 'global',
  initialState: { ...initialState },
  reducers: {
    resetWarehouseRedirect: function (state) {
      state.isRedirect = false;
    },
    resetWarehouseError: function (state) {
      state.error = {
        msg: '',
        isError: false,
      };
    },
    resetSingleWarehouse: function (state) {
      state.singeWarehouse = null;
    },
    resetCurrentPage: function (state) {
      state.data.currentPage = 0;
    },
    onChangePagination: function (state, action) {
      state.data.currentPage = action.payload;
      const startIndex = state.data.currentPage * state.data.perPage;
      const endIndex = startIndex + state.data.perPage;
      state.data.list = state.data.selectedList?.slice(startIndex, endIndex);
    },
    setIsWarehouseSelectedINFedX: function (state, action) {
      state.isWarehouseSelectedINFedX = action.payload;
    },
    resetIsWarehouseSelectedINFedX: function (state) {
      state.isWarehouseSelectedINFedX = '';
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getWarehouseList.pending, getWarehouseListPending);
    addCase(getWarehouseList.fulfilled, getWarehouseListFulfilled);
    addCase(getWarehouseList.rejected, getWarehouseListRejected);

    addCase(getWarehouseById.pending, getWarehouseByIdPending);
    addCase(getWarehouseById.fulfilled, getWarehouseByIdFulfilled);
    addCase(getWarehouseById.rejected, getWarehouseByIdRejected);

    addCase(createWarehouse.pending, createWarehousePending);
    addCase(createWarehouse.fulfilled, createWarehouseFulfilled);
    addCase(createWarehouse.rejected, createWarehouseRejected);

    addCase(updateWarehouseById.pending, createWarehousePending);
    addCase(updateWarehouseById.fulfilled, updateWarehouseFulfilled);
    addCase(updateWarehouseById.rejected, createWarehouseRejected);

    addCase(getWarehouseListForShipment.pending, getWarehouseListPending);
    addCase(getWarehouseListForShipment.fulfilled, getWarehouseListForShipmentFulfilled);
    addCase(getWarehouseListForShipment.rejected, getWarehouseListRejected);
  },
});

export { getWarehouseList, createWarehouse, updateWarehouseById, getWarehouseById, getWarehouseListForShipment };
export const {
  resetWarehouseRedirect,
  resetWarehouseError,
  resetSingleWarehouse,
  onChangePagination,
  resetCurrentPage,
  setIsWarehouseSelectedINFedX,
  resetIsWarehouseSelectedINFedX,
} = reduxStateSlice.actions;
export default reduxStateSlice.reducer;
