import Image from '@Components/Image';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

interface BackButtonProps {
  link: string;
}

function BackButton(props: BackButtonProps) {
  const navigate = useNavigate();

  const handleClick = (): void => {
    navigate(props.link);
  };

  return (
    <>
      <Box onClick={handleClick} sx={{ zIndex: '1', cursor: 'pointer' }}>
        <Image src="/images/backbutton.svg" alt="Back Button" style={{ width: '1.5rem', marginTop: '0.2rem' }} />
      </Box>
    </>
  );
}
export default BackButton;
