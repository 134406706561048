import Joi from 'joi';

export const boxSchema = Joi.object().keys({
  id: Joi.number().required(),
  startCount: Joi.optional(),
  length: Joi.number().min(1).required(),
  width: Joi.number().min(1).required(),
  height: Joi.number().min(1).required(),
  weight: Joi.number().min(0.01).required(),
  boxCopyCount: Joi.number().min(0).required(),
});
