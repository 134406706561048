import React from 'react';
import API from '../../../services/apiAxios';
import { DocumentDeleteType } from './Types';

import DeleteModal from '../../../components/Modal/error';

function DocumentDelete(props: DocumentDeleteType) {
  const { accountId, contractId } = props;
  // const theme = useTheme();

  const onDelete = async () => {
    await API.delete(`/user/contract/${accountId}/${contractId}`);
    props.onClose(true);
  };

  return (
    // <Modal open={props.open} maxWidth={'sm'}>
    //   <Grid container className="mb-3" gap={3} justifyContent="flex-end">
    //     <IconButton onClick={() => props.onClose(false)}>
    //       <CloseIcon />
    //     </IconButton>
    //     <Grid container item justifyContent="center">
    //       {/* <TaskAltIcon sx={{ width: '100px', height: '100px' }} color="success" /> */}
    //       <Image src="/images/Boxes/Delete.svg" alt="delete" height={100} />
    //     </Grid>
    //     <Grid container item justifyContent="center" alignItems="center" textAlign="center">
    //       <Grid item xs={12} sm={6} justifyContent="center">
    //         <Typography fontWeight="bold" variant="h5">
    //           Are you sure you want to Delete this document?
    //         </Typography>
    //       </Grid>
    //       <Typography variant="h6" mt={1} color={theme.palette.primary.main}>
    //         Current document will be deleted permanently.
    //       </Typography>
    //     </Grid>
    //     <Grid container item>
    //       <SbButton
    //         variant="contained"
    //         fullWidth
    //         onClick={() => {
    //           onDelete();
    //         }}
    //       >
    //         Yes, delete!
    //       </SbButton>
    //     </Grid>
    //   </Grid>
    // </Modal>
    <DeleteModal
      icon="/images/Boxes/DeleteContrast.svg"
      open={props.open}
      title=" Are you sure you want to Delete this document?"
      subTitle={'Current document will be deleted permanently.'}
      onClose={() => {
        props.onClose(false);
      }}
      onConfirm={onDelete}
      closeIcon={true}
      btnText={'Yes, delete!'}
    />
  );
}
export default DocumentDelete;
