import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getCompanyDetailsBySystemId, getLoggedInUser } from './actions';
import { getLoggedInUserFulfilled, getLoggedInUserPending, getLoggedInUserRejected } from './case';

import { getCompanyDetails } from './actions';
import { getCompanyDetailsFulfilled, getCompanyDetailsPending, getCompanyDetailsRejected } from './case';

import { updateCompanyDetails } from './actions';
import { updateCompanyDetailsFulfilled, updateCompanyDetailsPending, updateCompanyDetailsRejected } from './case';

import { updateUserProfile } from './actions';
import { updateUserProfileFulfilled, updateUserProfilePending, updateUserProfileRejected } from './case';

import { updatePassword } from './actions';
import { updatePasswordFulfilled, updatePasswordPending, updatePasswordRejected } from './case';

import { getTaxSetting } from './actions';
import { getTaxSettingFulfilled, getTaxSettingPending, getTaxSettingRejected } from './case';

import { DocToUploadPayload, LoginUserStateType } from './Type';

const initialState = <LoginUserStateType>{
  pageLoaded: false,
  companyDetailsLoading: false,
  token: null,
  profile: null,
  companyDetails: null,
  setting: {
    kyc: [],
    tax: [],
  },
  loading: false,
  isError: false,
  isSuccess: false,
  message: '',
  documentUploadSuccessfullyModal: false,
  kyc: {},
  password: {
    loading: false,
    isUpdated: false,
  },
  docsToUpload: null,
};

const slice = createSlice({
  name: 'loginUser',
  initialState: { ...initialState },
  reducers: {
    resetPasswordState: function (state) {
      state.password.isUpdated = false;
    },
    setDocsToUpload: function (state, action: PayloadAction<DocToUploadPayload | undefined>) {
      state.docsToUpload = action.payload
        ? state.docsToUpload?.length
          ? [...state.docsToUpload, action.payload]
          : [action.payload] || null
        : null;
    },
  },
  extraReducers: builder => {
    const { addCase } = builder;

    addCase(getLoggedInUser.fulfilled, getLoggedInUserFulfilled);
    addCase(getLoggedInUser.rejected, getLoggedInUserRejected);
    addCase(getLoggedInUser.pending, getLoggedInUserPending);

    addCase(updateUserProfile.fulfilled, updateUserProfileFulfilled);
    addCase(updateUserProfile.rejected, updateUserProfileRejected);
    addCase(updateUserProfile.pending, updateUserProfilePending);

    addCase(getCompanyDetails.fulfilled, getCompanyDetailsFulfilled);
    addCase(getCompanyDetails.rejected, getCompanyDetailsRejected);
    addCase(getCompanyDetails.pending, getCompanyDetailsPending);

    addCase(getCompanyDetailsBySystemId.fulfilled, getCompanyDetailsFulfilled);
    addCase(getCompanyDetailsBySystemId.rejected, getCompanyDetailsRejected);
    addCase(getCompanyDetailsBySystemId.pending, getCompanyDetailsPending);

    addCase(updateCompanyDetails.fulfilled, updateCompanyDetailsFulfilled);
    addCase(updateCompanyDetails.rejected, updateCompanyDetailsRejected);
    addCase(updateCompanyDetails.pending, updateCompanyDetailsPending);

    addCase(updatePassword.fulfilled, updatePasswordFulfilled);
    addCase(updatePassword.rejected, updatePasswordRejected);
    addCase(updatePassword.pending, updatePasswordPending);

    addCase(getTaxSetting.fulfilled, getTaxSettingFulfilled);
    addCase(getTaxSetting.rejected, getTaxSettingRejected);
    addCase(getTaxSetting.pending, getTaxSettingPending);
  },
});

export const { resetPasswordState, setDocsToUpload } = slice.actions;
export default slice.reducer;
