import { CSP_TYPES } from '@Constants/Shipment';
import { AppDispatch } from '@Store';
import { AramexInformation } from './AramexInformation';
import { postFedExDetails } from '@Reducers/AdminShipment/actions';
import dayjs from 'dayjs';

export const initAramexInformation: any = {
  _id: '',
  accountNo: '',
  pin: '',
  name: '',
  csp: CSP_TYPES.aramex.value,
  api_key: '',
  secret_key: '',
  isDefault: false,
  _system: [],
  isActive: false,
  createdAt: '',
  updatedAt: '',
  __v: 0,
};

interface DropdownOptionType {
  value: string;
  display: string;
}

// Enums for productType and paymentType
export enum ProductTypeEnum {
  PPX = 'PPX',
  DPX = 'DPX',
  GPX = 'GPX',
}

export enum PaymentTypeEnum {
  P = 'P',
  C = 'C',
  Three = '3',
}

export const onForward = ({
  values,
  shipmentNumber,
  dispatch,
  nextFunc,
}: {
  values: AramexInformation;
  shipmentNumber: string;
  dispatch: AppDispatch;
  nextFunc: () => void;
}) => {
  const body = { ...JSON.parse(JSON.stringify(values)) };
  body.csp = 'aramex';
  for (const key in body) {
    if (body[key]) {
      if (key === 'accountNo') {
        body[key] = body[key]?.split('-')?.[0];
      }
      if (key === 'invoiceDate') {
        body[key] = dayjs(values?.invoiceDate).format();
      }
      if (key === 'productType' && Array.isArray(body[key])) {
        body[key] = body[key].join(',');
      }
      if (key === 'paymentType' && Array.isArray(body[key])) {
        body[key] = body[key].join(',');
      }
      if (key === 'invoiceDate') {
        body[key] = dayjs(values?.invoiceDate).format('MM/DD/YYYY');
      }
    }
  }
  dispatch(
    postFedExDetails({
      shipmentNumber: `${shipmentNumber}`,
      body,
      date: values?.invoiceDate ? dayjs(values?.invoiceDate).format() : '',
      nextFunc,
    }),
  );
};

// String literals for productType and paymentType
export type ProductType = 'PPX' | 'DPX' | 'GPX';
export type PaymentType = 'P' | 'C' | '3';

export const ProductTypeOptions: DropdownOptionType[] = [
  { value: ProductTypeEnum.PPX, display: 'PPX' },
  { value: ProductTypeEnum.DPX, display: 'DPX' },
  { value: ProductTypeEnum.GPX, display: 'GPX' },
];

export const PaymentTypeOptions: DropdownOptionType[] = [
  { value: PaymentTypeEnum.P, display: 'Third Party' },
  { value: PaymentTypeEnum.C, display: 'Consignee' },
  { value: PaymentTypeEnum.Three, display: 'Prepaid' },
];
