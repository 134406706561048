import SbButton from '@Components/Button';
import Modal from '@Components/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';

interface BoxImageModalProps {
  open: boolean;
  onClose: () => void;
  imageData?: {
    startImage?: string;
    endImage?: string;
    type: string;
  };
  shipmentNumber?: string;
}

const BoxImageModal = (props: BoxImageModalProps) => {
  const handleClose = () => {
    props.onClose();
  };

  const { startImage, endImage, type } = props.imageData || {};
  const { shipmentNumber } = props;
  return (
    <Modal open={props.open} onClose={handleClose}>
      <Grid container spacing={2} sx={{ width: '52vw' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography className="md-text font-semibold">{type} Image</Typography>
            {shipmentNumber && <Typography className="sm-text font-medium">Shipment No: {shipmentNumber}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            className="rounded-xs"
            sx={{
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              padding: '10px',
            }}
          >
            <Box
              sx={{
                width: type != 'Box' ? '48%' : '100%',
                height: '15rem',
                border: '2px dashed #BDBDBD',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                bgcolor: 'background.grey',
              }}
            >
              {startImage ? (
                <img
                  src={startImage}
                  style={{ objectFit: 'contain', height: '15rem', width: '100%' }}
                  alt={`${type} Start`}
                />
              ) : (
                'Signature image is not Available'
              )}
            </Box>

            {type != 'Box' && (
              <Box
                sx={{
                  width: '48%',
                  height: '15rem',
                  border: '2px dashed #BDBDBD',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  bgcolor: 'background.grey',
                }}
              >
                {endImage ? (
                  <img
                    src={endImage}
                    style={{ objectFit: 'contain', height: '15rem', width: '100%' }}
                    alt={`${type} End`}
                  />
                ) : (
                  'Box image is not Available'
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <SbButton sx={{ width: '150px', height: '40px' }} variant="contained" onClick={handleClose}>
            Ok
          </SbButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default BoxImageModal;
