import * as React from 'react';
import SbButton from '@Components/Button';
import Image from '@Components/Image';
import getFileData, { FileResultType } from '../../Helper/file';

interface PropsType {
  accept: string;
  title: string;
  handleChange: (file: FileResultType[]) => void;
  readType?: 'xls' | 'base64';
  disabled?: boolean;
}

export default function UploadButton({ accept, handleChange, title, readType, disabled }: PropsType) {
  const fileRef = React.useRef<HTMLInputElement>(null);

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputFile = event.target.files as unknown as File[];
    const fileResult = (await getFileData(event.target.files ? [...inputFile] : [], readType)) as FileResultType[];
    handleChange(fileResult);
  };
  return (
    <>
      <input
        ref={fileRef}
        hidden
        type="file"
        accept={accept}
        onChange={onFileChange}
        onClick={event => {
          (event.target as HTMLInputElement).value = '';
        }}
      />
      <SbButton
        variant="outlined"
        className="mt-1"
        onClick={() => fileRef.current?.click()}
        startIcon={<Image src={'/images/file_upload.svg'} alt="upload" />}
        disabled={!!disabled}
      >
        {title}
      </SbButton>
    </>
  );
}
