import API from '@Services/apiAxios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface DownloadState {
  loading: boolean;
  error: string | null;
  downloadUrl: string | null;
}

const initialState: DownloadState = {
  loading: false,
  error: null,
  downloadUrl: null,
};

// Async thunk for downloading the shipment report
export const downloadSquadShipmentReport = createAsyncThunk(
  'reports/downloadShipmentReport',
  async (params: any, thunkAPI) => {
    try {
      const queryParams = {
        currentPage: params.currentPage,
        pageSize: params.pageSize,
        squadId: params.squadId,
        shipmentType: JSON.stringify(params.shipmentType),
        shipmentSystem: JSON.stringify(params.shipmentSystem),
        shipmentNumber: params.shipmentNumber,
        date: params.date,
      };
      const response = await API.get('/squad/admin/reports/download-shipment', { params: queryParams });
      console.info('Response:', response.data);
      return response.data.data.url; // Return the URL from the response
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data?.msg || 'Failed to download report');
    }
  },
);

// Create slice
const reportSlice = createSlice({
  name: 'downloadSquadShipmentReports',
  initialState,
  reducers: {
    clearDownloadSquadShipmentReport: state => {
      state.loading = false;
      state.error = null;
      state.downloadUrl = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(downloadSquadShipmentReport.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadSquadShipmentReport.fulfilled, (state, action) => {
        state.loading = false;
        state.downloadUrl = action.payload;
      })
      .addCase(downloadSquadShipmentReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearDownloadSquadShipmentReport } = reportSlice.actions;
export default reportSlice.reducer;
