import React from 'react';
import { Typography } from '@mui/material';
import useAccountLookup from '@Hook/useAccountLookup';

export default function SelectedUserAccountDetails({ modalId }: { modalId: string }) {
  const { selectedUser } = useAccountLookup(modalId);
  const { _accountId, email, name } = selectedUser || {};
  const isIndividualAccount = _accountId?.accountType === 'individual';
  const companyName = isIndividualAccount ? name : _accountId?._company?.[0]?.name;

  return (
    <div className="mb-4">
      <Typography className="xl-text font-semibold mb-0">Account No: {_accountId?.accountNumber}</Typography>
      <Typography className="md-text mb-1/2">{email}</Typography>
      <Typography className="md-text mb-1/2">{companyName}</Typography>
    </div>
  );
}
