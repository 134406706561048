import { SHIPMENT_STATUS, ShipmentStatusDetailsType } from '@Constants/Shipment';
import { ShipmentByIdType } from '@Reducers/AdminShipment/Type';

export class ShipmentControl {
  shipment: ShipmentByIdType | null = null;
  statusObj: ShipmentStatusDetailsType | null = null;
  constructor(shipment: ShipmentByIdType | null) {
    if (!shipment) return;
    this.shipment = shipment;
    this.statusObj = SHIPMENT_STATUS[shipment.status];
  }
  get isInvoiceBilled() {
    return this.shipment?.isInvoiceGenerate;
  }
  get canUpdateShipper() {
    const { canUpdateShipperAddress } = this.statusObj || {};
    return canUpdateShipperAddress ? !this.isInvoiceBilled : false;
  }
  get canUpdateConsignee() {
    const { canUpdateConsigneeAddress } = this.statusObj || {};
    return canUpdateConsigneeAddress ? !this.isInvoiceBilled : false;
  }
  get canUpdatePickupDropoff() {
    const { canUpdatePickupDropoff } = this.statusObj || {};
    return canUpdatePickupDropoff;
  }
  get canUpdateShipmentDetails() {
    const { canUpdateShipmentDetails } = this.statusObj || {};
    return canUpdateShipmentDetails ? !this.isInvoiceBilled : false;
  }
  get canUpdateTrackingDetails() {
    const { canUpdateTracking } = this.statusObj || {};
    return !!canUpdateTracking;
  }
  get canConnectToWareshouse() {
    const { canConnectToWarehouse } = this.statusObj || {};
    if (this.shipment?.carrierDetails?.awb || this.shipment?.isFinalForward) return false;
    return canConnectToWarehouse
      ? this.shipment?.buyingPricing?.outbound !== null
        ? true
        : !!canConnectToWarehouse
      : false;
  }
  get canUpdateBuyingDetails() {
    const { canUpdateBuyingDetails } = this.statusObj || {};
    if (this.shipment?.isFinalForward && ['forwarded', 'at_warehouse'].includes(this.shipment.status)) return true;
    return !!canUpdateBuyingDetails;
  }
  get canUpdateSellingDetails() {
    const { canUpdateSellingDetails } = this.statusObj || {};
    if (this.shipment?.isFinalForward && ['forwarded', 'at_warehouse'].includes(this.shipment.status)) return true;
    return !!canUpdateSellingDetails;
  }
  get canUpdateNotes() {
    const { canUpdateNotes } = this.statusObj || {};
    return !!canUpdateNotes || this.isInvoiceBilled;
  }
  get canUpdateCharges() {
    return !this.isInvoiceBilled;
  }
  get canDoPayment() {
    return !this.isInvoiceBilled;
  }
  get canUpdateFinalFwdAWB() {
    const { canUpdateFinalFwdAWB } = this.statusObj || {};
    if (this.shipment?.isFinalForward) return true;
    return canUpdateFinalFwdAWB;
  }
  get canUpdateReferenceNo() {
    const { canUpdateReferenceNo } = this.statusObj || {};
    return !!canUpdateReferenceNo;
  }
  get doesShipmentDelivered() {
    const { shipmentDelivered } = this.statusObj || {};
    return !!shipmentDelivered;
  }
  get canAddTransitHub() {
    const { canAddTransitHub } = this.statusObj || {};
    if (this.shipment?.isFinalForward && ['forwarded', 'at_warehouse'].includes(this.shipment.status)) return true;
    return !!canAddTransitHub;
  }
}
