// src/redux/booleanSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the initial state
interface BooleanState {
  value: boolean;
}

const initialState: BooleanState = {
  value: false, // Default value
};

// Create the slice
const booleanSlice = createSlice({
  name: 'booleanState',
  initialState,
  reducers: {
    toggleBoolean: state => {
      state.value = !state.value; // Toggle the boolean value
    },
    setBoolean: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload; // Set a specific boolean value
    },
  },
});

// Export the actions
export const { toggleBoolean, setBoolean } = booleanSlice.actions;

// Export the reducer to be used in the store
export default booleanSlice.reducer;
