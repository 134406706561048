import { PayloadAction } from '@reduxjs/toolkit';
import { State, OpenModalPayload } from './Type';

export const initState: State = {
  title: '',
  subTitle: '',
  type: 'success',
  navigateTo: null,
  open: false,
  onConfirm: undefined,
  secondaryBtnText: '',
  closeIcon: false,
  btnText: '',
};

function OpenModal(state: State, action: PayloadAction<OpenModalPayload>) {
  const payload = action.payload;
  state = {
    ...state,
    open: true,
    title: payload.title,
    subTitle: payload.subTitle,
    type: payload.type,
    navigateTo: payload.navigateTo || null,
    onConfirm: payload.onConfirm,
    secondaryBtnText: payload.secondaryBtnText,
    closeIcon: payload.closeIcon,
    btnText: payload.btnText,
  };
  return state;
}

function resetModal(state: State) {
  state = { ...initState };
  return state;
}

export { resetModal, OpenModal };
