import React from 'react';
import GetRatesV2 from '../GetRatesV2';
import { Box, Grid, Typography, styled } from '@mui/material';
import SbImage from '../../components/Image';
import Image from '../../components/Image';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';

const Root = styled(Grid)({
  background: "url('/images/login/Group.png')",
  backgroundPositionX: '1400px',
  backgroundSize: '934px',
  // backgroundSize: 'contain',
  backgroundRepeatX: 'no-repeat',
  flex: 1,
  backgroundColor: 'white',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
});

const Header = styled(Grid)({
  marginTop: '60px',
});

function GetRatesWithoutLogin() {
  const organizationData = useSelector((state: RootStateType) => state.orgSetup.organizationPublicData);
  const { name = '', logo = '/images/login/logo.png' } = organizationData || {};
  return (
    <Root container>
      <Grid item xs={12} md={10}>
        <Header container justifyContent={'start'} alignItems={'center'}>
          <SbImage src={logo} alt="logo" style={{ marginLeft: '40px', maxHeight: '90px' }} />
          <Typography
            variant="h4"
            style={{ fontWeight: '700', color: 'var(--mui-palette-primary-main)', paddingLeft: '1.5rem' }}
          >
            Welcome to {name}
          </Typography>
        </Header>
        <Box paddingLeft="3rem">
          <GetRatesV2 />
        </Box>
      </Grid>
      {/* <Grid item xs={2}> */}
      {/* <SbImage src="/images/login/Group.png" alt="logo" style={{ display: 'block', margin: '0 auto' }} /> */}
      {/* </Grid> */}
      <Box
        position={'absolute'}
        top={'0'}
        right={'0'}
        sx={{
          height: '100%',
          display: {
            xs: 'none',
            lg: 'block',
          },
        }}
      >
        <Image src="/images/wave.svg" alt="Wave" style={{ width: '100%', height: '100%' }} />
      </Box>
    </Root>
  );
}

export default GetRatesWithoutLogin;
