import { Grid, SelectChangeEvent, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../components/RadioButton';
import { SBDropdown } from '../../../../components/Dropdown';
import DatePicker from '../../../../components/DatePicker';
import { PickupType, createShipmentContextType } from '../../../../types/shipment';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { DropdownOptionType } from '../../../../components/Dropdown/Type';
import { Dayjs } from 'dayjs';
import { PickupTypeList } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import WarehouseDropdownList from '@Components/Dropdown/Warehouse';
import { ErrorType } from '../Type';
// import { usePickupDropoffAccess } from '@Hook/usePickupDropoffAccess';
import { getAddressObj } from '@Services/getShipmentPayload';
import { SHIPMENT_TYPES } from '../../../GetRatesV2/Constant';
import { getRates, setCreateShipmentCSP } from '@Reducers/GetRates';
import { EditAction } from '@Components/Shipment/Summary/ShipperConsigneeSummaryDetails';
import { SubHeading } from '@Components/Shipment/Summary/Styles';
// import { usePickupDropoffAccess } from '@Hook/usePickupDropoffAccess';

// const Wrapper = styled(Grid)(({ theme }) => ({
//   [theme.breakpoints.down('xs')]: {
//     // padding: 0,
//   },
//   [theme.breakpoints.up('md')]: {
//     padding: `calc(var(--mui-palette-spacing) * 3) calc(var(--mui-palette-spacing) * 2)`,
//   },
// }));
function ShipmentSummaryPickupType(props: { errorData?: ErrorType }) {
  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const { errorData } = props;

  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);
  const { warehouseListForShipmentOptionsHashById } = useSelector((state: RootStateType) => state.Warehouse);
  const countryHashById = useSelector((state: RootStateType) => state.global.countriesObj);
  const rates = useSelector((state: RootStateType) => state.getRates);

  const { pickupTimeSlots } = shipmentSetting || {};

  const {
    pickupType,
    setPickupType,
    packageData,
    setPackageData,
    // shipper,
    consignee,
    shipmentType,
    userId,
    chargeableWeight,
    boxes,
    activeStep,
    setActiveStep,
  } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  // const { disablePickup, disableDropoff } = usePickupDropoffAccess({ shipperCountry: shipper.country });

  const { type, date, warehouse, timeSlot } = pickupType;

  const [timeSlotsOption, setTimeSlotsOption] = useState<DropdownOptionType[]>([]);

  // useEffect(() => {
  //   if (date) {
  //     setTimeSlotsOption(getActiveTimeSlot(date));
  //   }
  // }, [date]);

  useEffect(() => {
    console.log({ rates, activeStep });
    if (rates.redirectToCreateShipmentCSP) {
      setActiveStep(activeStep - 1);
      dispatch(setCreateShipmentCSP(false));
    }
  }, [rates.redirectToCreateShipmentCSP]);

  useEffect(() => {
    const slots = pickupTimeSlots?.slots;
    if (slots) {
      setTimeSlotsOption(
        slots.map(({ from, to }) => {
          const str = `${from} - ${to}`;
          return { display: str, value: str };
        }),
      );
    }
  }, [pickupTimeSlots]);

  function handlePickupTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setPickupType({ ...pickupType, type: value as PickupType });
  }

  function handleTimeSlotChange(
    _selected: Array<string>,
    _selectedObj: DropdownOptionType[],
    event: SelectChangeEvent<string>,
    _child: React.ReactNode,
  ) {
    const value = event.target.value;
    setPickupType({ ...pickupType, timeSlot: value });
  }

  function handleWarehouseChange(value: string[]) {
    const [selectedWearhouse] = value;
    if (selectedWearhouse) {
      const selectedWarehouse = warehouseListForShipmentOptionsHashById[selectedWearhouse];
      const coAttention = selectedWarehouse.coAttention;
      const [telephoneCode, number] = coAttention.contact;
      const countryId = selectedWarehouse._addressId.country;
      const warehouseAddress = {
        ...selectedWarehouse._addressId,
        country: countryHashById?.[countryId]?.name || '',
        name: selectedWarehouse.name,
        phone: { telephoneCode, number, name: coAttention.name },
        email: coAttention.email,
      };

      setPackageData({ ...packageData, isDropOff: true });
      setPickupType({ ...pickupType, selectedWearhouseAddress: warehouseAddress, warehouse: selectedWearhouse });

      const obj = {
        destination: { ...getAddressObj(consignee) },
        source: {
          ...getAddressObj({
            // ...selectedWarehouse._addressId,
            line1: selectedWarehouse._addressId.line1,
            line2: selectedWarehouse._addressId.line2,
            city: selectedWarehouse._addressId.city,
            state: selectedWarehouse._addressId.state,
            pincode: selectedWarehouse._addressId.pincode,
            country: countryHashById?.[countryId]?.name || '',
            name: selectedWarehouse.name,
            phoneNumber: ' ',
            telephoneCode: ' ',
            email: coAttention.email,
            companyName: ' ',
          }),
        },
        shipmentType: shipmentType.type,
        userId,
        weight: chargeableWeight,
        package: {
          box: boxes.map(box => ({
            weight: box.weight.toString(),
            length: box.length,
            width: box.width,
            height: box.height,
          })),
          unitOfMeasurement: shipmentType.unit,
        },
        document: shipmentType.type === SHIPMENT_TYPES[1].value,
        insurance: packageData.insurance,
        packaging: packageData.packaging,
      };
      dispatch(getRates([{ ...obj, rateFor: 'create-shipment' }, false]));
    }
  }

  // function checkDisable() {
  //   let disable = true;
  //   if (type === 'dropOff' && warehouse) {
  //     disable = false;
  //   } else if (type === 'pickUp' && date && timeSlot) {
  //     disable = false;
  //   }
  //   return disable;
  // }

  return (
    <>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <SubHeading>Pickup or Drop-off?</SubHeading>
        </Grid>
        <Grid item>
          <EditAction activeStep={0} />
        </Grid>
      </Grid>
      <Grid container item md={6} xs={12} alignItems={'center'} className={isMobile ? '' : 'border-right pr-3'}>
        <RadioButton
          containerClass="w-full"
          name="type"
          defaultValue={type}
          onChange={handlePickupTypeChange}
          list={PickupTypeList}
          sx={{ display: 'flex', columnGap: 10 }}
          disabled={true}
        />
      </Grid>
      {type === 'dropOff' && (
        <Grid item md={6} xs={12} sx={{ pl: { lg: 3, xs: 0 } }}>
          <WarehouseDropdownList
            error={props?.errorData?.warehouse ? props?.errorData?.warehouse : ''}
            value={warehouse || ''}
            onChange={handleWarehouseChange}
            // disabled={disableDropoff}
            disabled={true}
          />
          {/* <SBDropdown
            label={'Warehouse list'}
            id="warehouse"
            placeholder="Select warehouse"
            options={[]}
            onChange={console.log}
          /> */}
        </Grid>
      )}
      {type === 'pickUp' && (
        <>
          <Grid item md={timeSlotsOption?.length ? 3 : 6} xs={6} sx={{ pl: { lg: 3, xs: 0 } }}>
            <DatePicker
              label="Date"
              value={date}
              // required
              handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) =>
                setPickupType({ ...pickupType, date: date })
              }
              error={props?.errorData?.date ? props?.errorData?.date : ''}
              // disabled={disablePickup}
              disabled={true}
            />
          </Grid>
          {timeSlotsOption?.length ? (
            <Grid item md={3} xs={6} className="pl-3">
              <SBDropdown
                label={'Time Slot'}
                id="timeSlot"
                name="timeSlot"
                isSortingAllowed={false}
                placeholder="Select time slot"
                options={timeSlotsOption}
                value={timeSlot ? [timeSlot] : []}
                onChange={handleTimeSlotChange}
                error={props?.errorData?.timeSlot ? props?.errorData?.timeSlot : ''}
                // disabled={disablePickup}
                disabled={true}
              />
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default ShipmentSummaryPickupType;
