import { createSlice } from '@reduxjs/toolkit';
import { addNewContract, deleteContract, getContractList } from './actions';
import {
  addNewContractFulfilled,
  deleteContractFulfilled,
  getContractListFulfilled,
  onreset,
  pending,
  rejected,
} from './case';
import { ContractReducerState } from './Type';

const initialState = <ContractReducerState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: [],
  isAdded: false,
  isDeleted: false,
};

const ContractSlice = createSlice({
  name: 'contract',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetContractReducer: onreset,
  },
  extraReducers: _ => {
    const addCase = _.addCase;

    addCase(getContractList.fulfilled, getContractListFulfilled);
    addCase(getContractList.rejected, rejected);
    addCase(getContractList.pending, pending);

    addCase(addNewContract.fulfilled, addNewContractFulfilled);
    addCase(addNewContract.rejected, rejected);
    addCase(addNewContract.pending, pending);

    addCase(deleteContract.fulfilled, deleteContractFulfilled);
    addCase(deleteContract.rejected, rejected);
    addCase(deleteContract.pending, pending);
  },
});

const { resetContractReducer } = ContractSlice.actions;
export { getContractList, addNewContract, deleteContract, resetContractReducer };

export default ContractSlice.reducer;
