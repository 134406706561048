import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SbMenuItemPropsType, SbMenuPropsType } from './Type';
import { ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import SbTooltip from '../Tooltip';

export function SbMenuItem(props: SbMenuItemPropsType) {
  const { icon, name, onClick } = props;
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        minHeight: '30px',
      }}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{name}</ListItemText>
    </MenuItem>
  );
}

export function SbMenu(props: SbMenuPropsType) {
  const theme = useTheme();
  const { children } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (props.disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemStyles: React.CSSProperties = props?.icon ? { paddingRight: 0, paddingLeft: 0, paddingTop: 0 } : {};

  return (
    <div>
      <SbTooltip title={props.title}>
        <IconButton
          aria-label="more"
          id="menu-button"
          aria-controls={open ? 'menu-popover' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleClick}
          style={menuItemStyles}
          color="inherit"
          disabled={props.disabled}
        >
          {props?.icon || <MoreHoriz />}
        </IconButton>
      </SbTooltip>
      <Menu
        id="menu-popover"
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        // transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            marginRight: 100,
            '& .MuiMenuItem-root:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
        }}
      >
        {children}
      </Menu>
    </div>
  );
}
