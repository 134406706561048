import Modal from '@Components/Modal';
import Title from '@Components/Title';
import React, { useEffect, useState } from 'react';
import Divider from '@Components/Divider';
import { Grid } from '@mui/material';
import { FormContent, FormLabel } from '@Components/Form';
import { copyRatesSchema } from '../schema';
import { useFormik } from 'formik';
import dayJs from 'dayjs';
import DatePicker from '@Components/DatePicker';
import SbButton from '@Components/Button';
import SBDropdown from '@Components/Dropdown';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';

import { CopyRateFormType, CopyRatePropsType } from './type';
import dayjs from 'dayjs';

const initCopyRate: CopyRateFormType = { fromDate: dayJs(''), toDate: dayJs(''), _systemId: [], _courierId: '' };

function CopyRate(props: CopyRatePropsType) {
  const SystemList = useSelector((state: RootStateType) => state.System.activeSystemDropdownList);
  const ServiceList = useSelector((state: RootStateType) => state.carrierAndService.serviceDropdownList);
  const [copyRateEdit, setCopyRateEdit] = useState<CopyRateFormType>({ ...initCopyRate });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...copyRateEdit },
    validationSchema: copyRatesSchema,
    onSubmit(values) {
      props.onAdd({ ...values });
      onModalClose();
    },
    validateOnChange: false,
  });

  const onModalClose = () => {
    formik.resetForm();
    props.onCloseModal();
    setCopyRateEdit({ ...initCopyRate });
  };

  useEffect(() => {
    if (props.modalOpen && props.edit) {
      setCopyRateEdit(props.edit);
    }
  }, [props.modalOpen]);

  const { setFieldValue, values, errors } = formik;
  const onSubmit = () => {
    formik.handleSubmit();
  };
  return (
    <Modal maxWidth="lg" open={props.modalOpen}>
      <>
        <Title title={`Copy Global Charges of ${props.serviceName} - ${props.systemName}`} removeBorder />
        <Divider className="my-2" />
        <Grid container>
          {/****************************** Service **************************************/}
          <Grid container item alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel className="md-text font-semibold">Service*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <SBDropdown
                  id="Service"
                  required
                  options={ServiceList}
                  onChange={([value]) => setFieldValue?.('_courierId', value, false)}
                  name="_courierId"
                  placeholder="Select"
                  value={[values._courierId]}
                  error={errors?._courierId as string}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** System **************************************/}
          <Grid container item alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel className="md-text font-semibold">System*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <SBDropdown
                  id="System"
                  required
                  multiple
                  options={SystemList}
                  onChange={value => setFieldValue?.('_systemId', value, false)}
                  name="_systemId"
                  placeholder="Select"
                  value={values._systemId}
                  error={errors?._systemId as string}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Start Date **************************************/}
          <Grid container item alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel className="md-text font-semibold">Start Date*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <DatePicker
                  error={errors?.fromDate as string}
                  value={dayJs(values.fromDate) || ''}
                  inputFormat="DD/MM/YYYY"
                  handleChange={date => {
                    if (values.toDate && date.isAfter(values.toDate)) {
                      setFieldValue('toDate', date.format());
                    }
                    setFieldValue('fromDate', date.format());
                  }}
                  minDate={dayJs()}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** End Date **************************************/}
          <Grid container item alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel className="md-text font-semibold">End Date*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <DatePicker
                  error={errors?.toDate as string}
                  value={dayjs(values.toDate) || ''}
                  inputFormat="DD/MM/YYYY"
                  handleChange={date => {
                    if (values.toDate && values.fromDate && dayjs(values.fromDate).isAfter(date)) return;
                    setFieldValue('toDate', date.format());
                  }}
                  minDate={values.fromDate ? dayJs(values.fromDate) : undefined}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          <Grid container item mt={4} spacing={2} justifyContent="flex-end">
            <Grid item xs={6} sm={3} md={2} lg={1.5}>
              <SbButton fullWidth variant="outlined" onClick={onModalClose}>
                Cancel
              </SbButton>
            </Grid>
            <Grid item xs={6} sm={3} md={2} lg={1.5}>
              <SbButton fullWidth variant="contained" onClick={onSubmit}>
                {copyRateEdit ? 'Save' : 'Add'}
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
}

export default CopyRate;
