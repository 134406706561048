import SbButton from '@Components/Button';
import Divider from '@Components/Divider';
import SBDropdown from '@Components/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import SbTextField from '@Components/Textfield';
import { AdditionalCharge } from '@Reducers/AdminShipment/Type';
import { RootStateType } from '@Store';
import { Button, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

const CHARGES_TYPE = [
  { display: 'Discount', value: 'discount' },
  { display: 'Add', value: 'add' },
];

// const chargesNames = [{ display: 'insurance', value: 'insurance' }];

const ExtraChargeSchema = Yup.object().shape({
  chargeType: Yup.string().trim().required('Charge type is required.'),
  chargeName: Yup.string().trim().required('Charge Name is required.'),
  value: Yup.string().trim().required('Charges value is required.'),
  applyGlobalCharge: Yup.array().of(Yup.string().trim()),
  // applyOnGst: Yup.boolean().default(true),
  applyOnGst: Yup.boolean(),
});

export interface ExtraChargeType {
  chargeType: string;
  chargeName: string;
  value: string;
  applyGlobalCharge: string[];
  applyOnGst: boolean;
}

const initExtraCharge = {
  chargeType: '',
  chargeName: '',
  value: '',
  applyGlobalCharge: [],
  applyOnGst: true,
};

export default function ExtraCharge(props: {
  onSave: (charge: any) => void;
  writeAccess?: boolean;
  AdditionalChargeList?: AdditionalCharge | null;
  calledFor: string;
}) {
  const [addExtraCharge, setAddExtraCharge] = useState<boolean>(false);
  const ShipmentCharges = useSelector((state: RootStateType) => state?.orgSetup?.shipmentSetting?.shipmentCharges);
  const [charges, setCharges] = useState<ExtraChargeType[]>([]);
  // const AdditionalChargeList = useSelector(
  //   (state: RootStateType) => state?.adminShipment.shipmentById?._selectedAdditionalChargeId,
  // );
  const { AdditionalChargeList } = props;

  const { shipmentById } = useSelector((state: RootStateType) => state.adminShipment);
  const sellingPricingCharges = shipmentById?.sellingPricing;
  const buyingPricingCharges = shipmentById?.buyingPricing;

  const ChargesNameOptions: DropdownOptionType[] = useMemo(() => {
    if (ShipmentCharges?.length) {
      const filteredExtraCharges = ShipmentCharges.filter(extraCharge => {
        return !sellingPricingCharges?.pricing.some(
          priceItem =>
            priceItem?.key === extraCharge?.value && priceItem?.rateType === 'extra' && priceItem?.invoiced === false,
        );
      });
      return filteredExtraCharges
        .filter(item => item.isActive === true)
        .map(c => ({ display: c.label, value: c.label }));
      // return ShipmentCharges.map(c => ({ display: c.label, value: c.label }));
    }
    return [];
  }, [ShipmentCharges, sellingPricingCharges]);

  const ChargesNameOptionsForBuying: DropdownOptionType[] = useMemo(() => {
    if (ShipmentCharges?.length) {
      const filteredExtraCharges = ShipmentCharges.filter(extraCharge => {
        return !buyingPricingCharges?.pricing.some(
          priceItem => priceItem?.key === extraCharge?.value && priceItem?.invoiced === false,
        );
      });
      return filteredExtraCharges.map(c => ({ display: c.label, value: c.label }));
    }
    return [];
  }, [ShipmentCharges, buyingPricingCharges]);

  const AdditionalChargeOptions: DropdownOptionType[] = useMemo(() => {
    if (AdditionalChargeList?.charges.length) {
      return AdditionalChargeList?.charges
        ?.filter(item => !!item?.percent)
        .map(c => ({ display: c.chargeName, value: c.chargeName }));
    }
    return [];
  }, [AdditionalChargeList]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initExtraCharge },
    validationSchema: ExtraChargeSchema,
    onSubmit: values => {
      setCharges([...charges, values]);
      formik.resetForm();
    },
  });

  const onCancel = () => {
    formik.handleReset({ ...initExtraCharge });
    setAddExtraCharge(false);
  };

  const removeCharge = (index: number) => {
    setCharges(charges.filter((_, i) => i !== index)); // Remove charge by index
  };

  const handleSave = () => {
    props.onSave(charges as any); // Send all charges to the parent on save
    setCharges([]); // Clear the added charges after saving
  };

  return (
    <>
      <Grid container justifyContent={'end'} className="mt-1">
        {!shipmentById?.invoicing && (
          <Typography
            className={`underline ${props.writeAccess ? 'cursor-pointer' : 'cursor-not-allowed'}  `}
            sx={{ color: '#3F51B5' }}
            onClick={() => {
              if (props.writeAccess) {
                setAddExtraCharge(!addExtraCharge);
              }
            }}
          >
            Add Charge/Discount
          </Typography>
        )}
      </Grid>
      <Divider className="my-2" />

      {charges.length > 0 && (
        <Grid container direction="column" spacing={1} className="mb-3">
          {charges.map((charge, index) => (
            <Grid container key={index} justifyContent="space-between" alignItems="center">
              <Typography>{`${charge.chargeType} - ${charge.chargeName} - ${charge.value}`}</Typography>
              <Button variant="outlined" color="error" size="small" onClick={() => removeCharge(index)}>
                Remove
              </Button>
            </Grid>
          ))}
        </Grid>
      )}
      {addExtraCharge && (
        <>
          <Grid container className="my-1">
            <SBDropdown
              required
              id="charge-type"
              label="Charge Type"
              name="chargeType"
              options={CHARGES_TYPE}
              value={formik.values.chargeType ? [formik.values.chargeType] : []}
              onChange={([value]) => formik.setFieldValue('chargeType', value)}
              error={formik.errors.chargeType}
            />
          </Grid>
          <Grid container className="my-1">
            <SBDropdown
              required
              id="charge-name"
              label={'Charge Name'}
              name="chargeName"
              options={props.calledFor === 'selling' ? ChargesNameOptions : ChargesNameOptionsForBuying}
              value={formik.values.chargeName ? [formik.values.chargeName] : []}
              onChange={([value]) => formik.setFieldValue('chargeName', value)}
              error={formik.errors.chargeName}
            />
          </Grid>
          <Grid container className="my-1">
            <SbTextField
              required
              type="string"
              placeholder=""
              label="Value"
              className="w-full"
              name="value"
              value={formik.values.value || ''}
              // onChange={formik.handleChange}
              onChange={e => {
                const val = e.target.value;

                // Regular expression to match valid decimal numbers
                const decimalRegex = /^\d*\.?\d*$/;

                if (decimalRegex.test(val)) {
                  formik.setFieldValue('value', val);
                }
              }}
              onBlur={e => {
                const val = e.target.value;
                const numVal = parseFloat(val);

                if (isNaN(numVal) || numVal <= 0) {
                  formik.setFieldValue('value', '');
                } else {
                  formik.setFieldValue('value', numVal);
                }
              }}
              error={formik.errors.value}
            />
          </Grid>
          <Grid container className="my-1">
            <SBDropdown
              id="applyGlobalCharge"
              label={'Apply Global Charge '}
              name="applyGlobalCharge"
              placeholder=""
              multiple
              options={AdditionalChargeOptions}
              value={formik.values.applyGlobalCharge || []}
              onChange={value => formik.setFieldValue('applyGlobalCharge', value)}
              disabled={!AdditionalChargeOptions.length}
              error={
                Array.isArray(formik.errors.applyGlobalCharge)
                  ? formik.errors.applyGlobalCharge?.join(',')
                  : formik.errors.applyGlobalCharge
              }
            />
          </Grid>
          <FormGroup classes={{ root: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  value={formik.values.applyOnGst}
                  onChange={value => {
                    formik.setFieldValue('applyOnGst', value.target.checked);
                  }}
                />
              }
              label="Apply Tax"
            />
          </FormGroup>
          <Grid container justifyContent={'end'} className="mt-2" spacing={2}>
            <Grid item>
              <SbButton
                variant="outlined"
                className="rounded-xs"
                sx={{ height: 36, borderRadius: '4px !important' }}
                onClick={onCancel}
              >
                Cancel
              </SbButton>
            </Grid>
            <Grid item>
              <SbButton
                variant="contained"
                className="mr-1 rounded-xs"
                sx={{ height: 36, borderRadius: '4px !important' }}
                onClick={_ => {
                  formik.handleSubmit();
                }}
              >
                Add More Charges
              </SbButton>
            </Grid>
          </Grid>
        </>
      )}
      {charges.length > 0 && (
        <Grid container justifyContent="end" className="mt-3">
          <SbButton variant="contained" color="primary" onClick={handleSave}>
            Save
          </SbButton>
        </Grid>
      )}
    </>
  );
}
