import React from 'react';
import {
  Grid,
  Stack,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Image from '@Components/Image';
import { SBTable } from '@Components/Table/Table';
import { TableHeadCell, TableRowCell } from '@Components/Table/TableCell';
import { SBTableRow } from '@Components/Table/TableRow';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { UserObj as UserObjAccountLookup } from '@Reducers/AccountLookup/Type';
import { SystemBox } from '../../pages/UserList/styles';
import { AppDispatch, RootStateType } from '@Store';
import useAccountLookup from '@Hook/useAccountLookup';
import { getUserList } from '@Reducers/AccountLookup';

function AccountTypeCompany(props: { user: UserObj; setShowCompanyDetails?: () => void }) {
  const { _accountId } = props.user;
  const { _company, isPrepaid, accountType, isAccountHolder } = _accountId || {};
  const { name } = _company?.[0] || {};

  return (
    <Stack alignItems={'center'}>
      <Image
        src={accountType === 'company' ? '/images/icons/user/Business.svg' : '/images/icons/user/User.svg'}
        alt="Business"
      />
      <Typography
        sx={{ mb: 0 }}
        className="sb-text-gray underline cursor-pointer"
        //   onClick={props.setShowCompanyDetails}
      >
        {name}
      </Typography>
      <Typography sx={{ mb: 0 }} className="sb-text-gray font-medium">
        {isAccountHolder && !isPrepaid ? 'Postpaid' : 'Pre-paid'}
      </Typography>
    </Stack>
  );
}

function Systems(props: { systemList: string[] }) {
  const { systemList } = props;
  return systemList?.length ? (
    systemList?.length === 1 ? (
      <Typography className="text-center">{systemList[0]}</Typography>
    ) : (
      <>
        <SystemBox id={'fadedScroll'} variant="outlined">
          {systemList.map(system => (
            <Typography key={system} sx={{ py: 1 / 4, px: 1 }}>
              {system}
            </Typography>
          ))}
        </SystemBox>
      </>
    )
  ) : (
    <></>
  );
}

function DataTable(props: { getSelectedUser?: (user: UserObjAccountLookup) => void; modalId: string }) {
  const { userList } = useSelector((state: RootStateType) => state.accountLookup?.[props.modalId] || {});
  const { selectedUser, setSelectedUser } = useAccountLookup(props.modalId);
  const { totalCount, currentPage, perPage } = useSelector(
    (state: RootStateType) => state.accountLookup[props.modalId],
  );
  function handleRowSelect(user: UserObjAccountLookup) {
    if (props?.getSelectedUser) props.getSelectedUser(user);
    setSelectedUser(user);
  }
  const dispatch = useDispatch<AppDispatch>();

  return (
    <TableContainer id="fadedScroll" sx={{ mt: 3 }}>
      <SBTable>
        <TableHead>
          <TableRow>
            <TableHeadCell is_light="1" sx={{ minWidth: '150px' }}>
              Account No.
            </TableHeadCell>
            <TableHeadCell is_light="1" sx={{ minWidth: '130px' }}>
              System
            </TableHeadCell>
            <TableHeadCell is_light="1" sx={{ minWidth: '214px' }}>
              Account Type
            </TableHeadCell>
            <TableHeadCell is_light="1" sx={{ minWidth: '180px' }}>
              Name
            </TableHeadCell>
            <TableHeadCell is_light="1" sx={{ minWidth: '140px' }}>
              Email
            </TableHeadCell>
            <TableHeadCell is_light="1" sx={{ minWidth: '231px' }}>
              Address
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userList.map(user => {
            const { _id, _systems, _accountId, name, email } = user;
            const { accountNumber } = _accountId || {};
            const {
              line1 = '',
              line2 = '',
              city = '',
              state = '',
              country = '',
            } = _accountId?._company?.[0]?._address || {};
            return (
              <SBTableRow
                key={_id}
                className={`cursor-pointer ${selectedUser && selectedUser?._id === _id ? 'selected' : ''}`}
                hover
                onClick={() => handleRowSelect(user)}
              >
                <TableRowCell className="md-text" align="center">
                  {accountNumber}
                </TableRowCell>
                <TableRowCell className="md-text">
                  <Systems systemList={_systems} />
                </TableRowCell>
                <TableRowCell align="center">
                  <AccountTypeCompany
                    user={user}
                    //   setShowCompanyDetails={() => setShowCompanyDetails({ open: true, data: user })}
                  />
                </TableRowCell>
                <TableRowCell align="center">{name}</TableRowCell>
                <TableRowCell align="center">{email}</TableRowCell>
                <TableRowCell align="center">{`${line1}, ${
                  line2 ? `${line2}, ` : ''
                } ${city}, ${state}, ${country}`}</TableRowCell>
              </SBTableRow>
            );
          })}
        </TableBody>
      </SBTable>
      {!userList?.length && (
        <Grid container justifyContent={'center'} sx={{ margin: '8px 0px' }}>
          No data found
        </Grid>
      )}
      <Grid container justifyContent="flex-end">
        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage - 1}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                dispatch(getUserList([props.modalId, { currentPage: page + 1, perPage }]));
              }}
              onRowsPerPageChange={event => {
                const newPerPage = parseInt(event.target.value, 10);
                dispatch(getUserList([props.modalId, { currentPage: 1, perPage: newPerPage }]));
              }}
            />
          </>
        )}
      </Grid>
    </TableContainer>
  );
}

export default DataTable;
