import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import SBDropdown from '../../../components/Dropdown';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import SbTextField from '../../../components/Textfield';
import Contact from '../../../components/Contact';
import SbButton from '../../../components/Button';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';
import { TabPanel } from '@mui/lab';
import Title from '../../../components/Title';
import { useFormik } from 'formik';
import { CompanyTabchema } from './schema';
import { getCompanyDetails, getCompanyDetailsBySystemId, updateCompanyDetails } from '@Reducers/loginUser/actions';
import { CompanyInfo } from '@Reducers/loginUser/Type';
import Country from '@Components/Dropdown/Country';
import { getLoggedInUserSystemList } from '@Reducers/Systems';
import DocumentUpload from './DocumentUpload';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { useNavigate } from 'react-router';

const ContainerStyle = styled(Grid)(({ theme: _ }) => ({
  padding: '20px 0',
  borderBottom: '1px solid #EAECF0',
}));

const initCompanyInfo = {
  _systemId: '',
  name: '',
  email: '',
  contact: {
    number: '',
    countryCode: '',
  },
  address: { line1: '', line2: '', city: '', state: '', country: '', pincode: '', _country: '' },
  // tax_info: [],
};

function CompanyTab() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({ ...initCompanyInfo });
  const accountState = useSelector((state: RootStateType) => state.loginUser.profile?._accountId);
  const { docsToUpload } = useSelector((state: RootStateType) => state.loginUser);
  const companyDetails = useSelector((state: RootStateType) => state.loginUser.companyDetails);
  const countryId = useSelector((state: RootStateType) => state.orgSetup.organizationData?._countryId);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const countriesObj = useSelector((state: RootStateType) => state.global.countriesObj);
  const kycDocumentList = useSelector((state: RootStateType) => state.global.kycSetting?.companyAccountHolder.list);

  if (accountState?.isDefaultAccount) return <></>;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...companyInfo },
    validationSchema: CompanyTabchema,
    onSubmit(values) {
      // onCreate(values);
      const isValidTaxInfo = kycDocumentList?.some(
        doc =>
          doc.isRequired &&
          (companyDetails?.documents?.some(item => item.name === doc.label) ||
            docsToUpload?.some(item => item.kycType === doc.label)),
      );
      if (!isValidTaxInfo || isValidTaxInfo) {
        dispatch(
          updateCompanyDetails({
            postData: { ...values, contact: [values.contact], email: [values.email], taxInfo: docsToUpload ?? [] },
            showModal: true,
          }),
        );
      } else {
        dispatch(
          SuccessErrorModalOpen({
            type: 'error',
            title: 'Oops!',
            subTitle: 'Please update required Company KYC(Tax details).',
          }),
        );
      }
    },
  });

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } = formik;

  useEffect(() => {
    if (loggedInUserSystemDropdownList?.length === 1) {
      setFieldValue('_systemId', loggedInUserSystemDropdownList[0].value);
    }
  }, [loggedInUserSystemDropdownList]);

  useEffect(() => {
    const { _systemId, name, email, contact, address } = companyDetails || {};
    if (!countryId) return;
    if (companyDetails?._id) {
      setCompanyInfo({
        _systemId: _systemId || '',
        name: name || '',
        email: email?.[0] || '',
        contact: {
          number: contact?.[0]?.number || '',
          countryCode: contact?.[0]?.countryCode || '',
        },
        address: address ? { ...address, _country: countryId || '' } : initCompanyInfo.address,
        // tax_info: tax_info ? tax_info : initCompanyInfo.tax_info,
      });
    } else {
      const orgCountryObj = countriesObj[countryId];
      if (orgCountryObj)
        setCompanyInfo({
          ...initCompanyInfo,
          _systemId: values?._systemId,
          // contact{...initCompanyInfo.contact,countryCode},
          contact: { ...initCompanyInfo.contact, countryCode: String(orgCountryObj.telephoneCode) },
          address: {
            ...initCompanyInfo?.address,
            _country: countryId,
            country: orgCountryObj?.name,
          },
        });
    }
  }, [companyDetails, countryId, countriesObj]);

  useEffect(() => {
    // dispatch(getActiveSystemList());
    dispatch(getLoggedInUserSystemList());
    dispatch(getCompanyDetails());
  }, []);

  return (
    <TabPanel value="company_details" className="p-0 w-full">
      <Grid container p={{ md: '0 32px' }}>
        {loggedInUserSystemDropdownList?.length > 1 ? (
          <Grid container>
            <Grid item xs={12} md={3}>
              <Typography className="font-semibold">Select a system to add company details</Typography>
              <SBDropdown
                required
                // disabled={selectedSystemList?.length < 2}
                id="company-country-id"
                placeholder="Select System"
                value={[values?._systemId || '']}
                options={loggedInUserSystemDropdownList}
                onChange={([value]) => {
                  dispatch(getCompanyDetailsBySystemId(value));
                  setFieldValue('_systemId', value);
                }}
                error={touched._systemId && errors._systemId ? errors._systemId : ''}
              />
            </Grid>
          </Grid>
        ) : (
          <Title title="Company Details" subTitle="Update your company details here." />
        )}
        <Grid container className="mt-2">
          {/* ---------------------------------------------------------------------------------------------------------- COMPANY NAME */}
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} md={3}>
              <Typography className="font-medium md-text">Company name*</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <Grid item xs={12} md={8} sx={{ pr: { md: '38px' } }}>
                <SbTextField
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  placeholder="Company Name"
                  error={errors.name && touched.name ? errors.name : ''}
                />
              </Grid>
            </Grid>
          </ContainerStyle>

          {/* ---------------------------------------------------------------------------------------------------------- EMAIL ADDRESS */}
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} md={3}>
              <Typography className="font-medium md-text">Email Address*</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <Grid item xs={12} md={8} sx={{ pr: { md: '38px' } }}>
                <SbTextField
                  name="email"
                  placeholder="Email Address"
                  onChange={handleChange}
                  value={values.email}
                  startAdornment={<MarkunreadOutlinedIcon sx={{ mr: '10px' }} />}
                  error={errors.email && touched.email ? errors.email : ''}
                />
              </Grid>
            </Grid>
          </ContainerStyle>

          {/* ---------------------------------------------------------------------------------------------------------- CONTACT */}
          <ContainerStyle container alignItems="center">
            <Grid item xs={12} md={3}>
              <Typography className="font-medium md-text">Contact*</Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={7}>
              <Grid item xs={12} md={8} sx={{ pr: { md: '38px' } }}>
                <Contact
                  phoneName="contact.number"
                  phoneNumber={values.contact.number}
                  onChangePhoneNumber={handleChange}
                  telCountryCodeName="contact.countryCode"
                  telCountryCode={values.contact.countryCode}
                  onChangeTelCountryCode={handleChange}
                  error={errors.contact?.countryCode && touched.contact?.countryCode ? errors.contact?.countryCode : ''}
                  setDefaultTelephone={(value: number) => setFieldValue('contact.countryCode', value)}
                />
              </Grid>
            </Grid>
          </ContainerStyle>

          {/* ---------------------------------------------------------------------------------------------------------- ADDRESS */}
          <ContainerStyle container alignItems="baseline">
            <Grid item xs={12} md={3}>
              <Typography className="font-medium md-text">Address*</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              lg={9}
              rowSpacing={'16px'}
              columnSpacing={'32px'}
              sx={{ mt: { xs: '6px', md: '0' } }}
            >
              {/* ------------------------------------------------------------------------------- LINE 1 */}
              <Grid item xs={12} md={6}>
                <SbTextField
                  onChange={handleChange}
                  value={values.address.line1}
                  name="address.line1"
                  label="Address line 1"
                  placeholder="Address line 1"
                  required
                  error={errors.address?.line1 && touched.address?.line1 ? errors.address.line1 : ''}
                />
              </Grid>
              {/* ------------------------------------------------------------------------------- LINE 2 */}
              <Grid item xs={12} md={6}>
                <SbTextField
                  onChange={handleChange}
                  value={values.address.line2}
                  name="address.line2"
                  label="Address line 2"
                  placeholder="Address line 2"
                  error={errors.address?.line2 && touched.address?.line2 ? errors.address.line2 : ''}
                />
              </Grid>
              {/* ------------------------------------------------------------------------------- CITY */}
              <Grid item xs={12} md={6}>
                <SbTextField
                  onChange={handleChange}
                  value={values.address.city}
                  name="address.city"
                  label="City"
                  placeholder="City"
                  required
                  error={errors.address?.city && touched.address?.city ? errors.address.city : ''}
                />
              </Grid>
              {/* ------------------------------------------------------------------------------- STATE */}
              <Grid item xs={12} md={6}>
                <SbTextField
                  onChange={handleChange}
                  value={values.address.state}
                  name="address.state"
                  label="State"
                  placeholder="State"
                  error={errors.address?.state && touched.address?.state ? errors.address.state : ''}
                />
              </Grid>
              {/* ------------------------------------------------------------------------------- PINCODE */}
              <Grid item xs={12} md={6}>
                <SbTextField
                  onChange={handleChange}
                  value={values.address.pincode}
                  name="address.pincode"
                  label="Pincode"
                  placeholder="Pincode"
                  error={errors.address?.pincode && touched.address?.pincode ? errors.address.pincode : ''}
                  required
                />
              </Grid>
              {/* ------------------------------------------------------------------------------- COUNTRY */}
              <Grid item xs={12} md={6}>
                <Country
                  onChange={handleChange}
                  value={values.address.country}
                  error={
                    errors.address?.country && touched.address?.country
                      ? errors.address.country
                      : errors?.address?._country
                      ? errors?.address?._country
                      : ''
                  }
                  key={'address.country'}
                  label={'Country'}
                  required
                  // setDefaultCountry={countryId => setFieldValue('address.country', countryId)}
                  setDefaultCountry={(countryId, countryName) => {
                    setFieldValue('address.country', countryName);
                    setFieldValue('address._country', countryId);
                  }}
                  disabled
                />
                {/* <SBDropdown
                  name="country"
                  required
                  label="Country"
                  id="country"
                  value={values.country ? [values.country] : []}
                  options={countryList}
                  onChange={handleChange}
                  error={errors.country && touched.country ? errors.country : ''}
                /> */}
              </Grid>
            </Grid>
          </ContainerStyle>

          {/* ---------------------------------------------------------------------------------------------------------- TAX */}
          <ContainerStyle container alignItems="baseline">
            <Grid item xs={12} md={3}>
              <Typography className="font-medium md-text">Company KYC(Tax details)</Typography>
            </Grid>
            <Grid item xs={12} md={9} lg={7}>
              <Grid item xs={12} md={12} sx={{ pr: { md: '38px' } }}>
                <DocumentUpload systemId={values?._systemId} onSubmit={handleSubmit} />
              </Grid>
            </Grid>
          </ContainerStyle>
          {/* ---------------------------------------------------------------------------------------------------------- BTN */}
          <Grid container item justifyContent={{ xs: 'space-evenly', md: 'flex-end' }} mt={'32px'} gap={{ md: '12px' }}>
            <Grid item xs={5} md={2}>
              <SbButton variant="contained" className="rounded-full" fullWidth onClick={() => navigate('/')}>
                Cancel
              </SbButton>
            </Grid>
            <Grid item xs={5} md={2}>
              <SbButton variant="outlined" className="rounded-full" fullWidth onClick={() => handleSubmit()}>
                Save
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabPanel>
  );
}

export default CompanyTab;
