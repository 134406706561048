import { DispatchError, DispatchSuccess } from '@Reducers/SuccessErrorModal';
import { PayloadAction } from '@reduxjs/toolkit';
import { ServiceResponse, ServiceAndCarrierState, ServiceCreateResponse, SingleService, ErrorType } from './Type';

function getServiceListPending(state: ServiceAndCarrierState) {
  state.loading = true;
}

function getServiceListFulfilled(state: ServiceAndCarrierState, data: { payload: ServiceResponse }) {
  const { lists } = data.payload.data;
  // state.serviceList = lists;
  state.selectedServiceList = lists;
  state.totalCount = lists?.length;
  const startIndex = state.currentPage * state.perPage;
  const endIndex = startIndex + state.perPage;
  state.serviceList = lists?.slice(startIndex, endIndex);
  state.refreshList = false;
  const hashObj: ServiceAndCarrierState['serviceListHashById'] = {};
  state.serviceListHashById = lists.reduce((op, csp) => ((op[csp._id] = csp), op), hashObj);
  state.serviceDropdownList = lists.map(({ name, _id }) => ({ display: name, value: _id }));
  state.loading = false;
}

function getServiceListRejected(state: ServiceAndCarrierState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
  DispatchError('Oops!', action.payload?.msg || 'Unable to load services list.');
}

// -----------------------------------------------------------------------------------------------------------------
function createServicePending(state: ServiceAndCarrierState) {
  state.loading = true;
}

function createServiceFulfilled(state: ServiceAndCarrierState, _data: { payload: ServiceCreateResponse }) {
  DispatchSuccess('Success', 'Service details created.', '/carrier-and-services?tab=service');
  state.loading = false;
}

function createServiceRejected(state: ServiceAndCarrierState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = { msg: 'error', isError: true };
  DispatchError('Oops!', action.payload?.msg || 'The desired service does not created.');
}
// -----------------------------------------------------------------------------------------------------------------
function getServiceByIdPending(state: ServiceAndCarrierState) {
  state.loading = true;
}

function getServiceByIdFulfilled(state: ServiceAndCarrierState, data: { payload: SingleService }) {
  state.singleService = data.payload;
  state.loading = false;
}

function getServiceByIdRejected(state: ServiceAndCarrierState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  // state.error = { msg: 'The desired carrier does not exist.', isError: true };
  DispatchError(
    'Oops!',
    action.payload?.msg || 'The desired service does not exist.',
    '/carrier-and-services?tab=service',
  );
}
// -----------------------------------------------------------------------------------------------------------------
function updateServicePending(state: ServiceAndCarrierState) {
  state.loading = true;
  state.error = { msg: '', isError: false };
}
function updateServiceFulfilled(state: ServiceAndCarrierState) {
  // state.singleService = data.payload.data;
  state.loading = false;
  DispatchSuccess('Success', 'Service details updated.', '/carrier-and-services?tab=service');
}
function updateServiceRejected(state: ServiceAndCarrierState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;

  DispatchError('Oops!', action.payload?.msg || 'The desired service does not updated.');
}

//------------------------------------------------------------------------------------------------------------------
function deleteServicePending(state: ServiceAndCarrierState) {
  state.loading = true;
  state.error = { msg: '', isError: false };
}
function deleteServiceFulfilled(state: ServiceAndCarrierState) {
  // state.singleService = data.payload.data;
  state.loading = false;
  state.refreshList = true;
  DispatchSuccess('Success', 'Service Provider Deleted Succcessfully.');
}
function deleteServiceRejected(state: ServiceAndCarrierState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;

  DispatchError('Oops!', action.payload?.msg || 'Unable to delete Service Provider.');
}

export {
  getServiceListPending,
  getServiceListFulfilled,
  getServiceListRejected,
  createServicePending,
  createServiceFulfilled,
  createServiceRejected,
  getServiceByIdPending,
  getServiceByIdFulfilled,
  getServiceByIdRejected,
  updateServicePending,
  updateServiceFulfilled,
  updateServiceRejected,
  deleteServicePending,
  deleteServiceFulfilled,
  deleteServiceRejected,
};
