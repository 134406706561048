import React from 'react';
import { Grid } from '@mui/material';
import CancelShipment from './CancelOrder';
import RepeatOrder from './RepeatOrder';

interface PropsType {
  shipmentStatus: string;
  accountId?: string;
}

function CancelAndTrackShipment({ shipmentStatus, accountId }: PropsType) {
  return (
    <Grid item display={'flex'}>
      <div>{shipmentStatus === 'placed' && <CancelShipment />}</div>
      {/* <div>{shipmentStatus !== 'cancelled' && <RepeatOrder accountId={accountId} />}</div> */}
      <div>{<RepeatOrder accountId={accountId} />}</div>
    </Grid>
  );
}

export default CancelAndTrackShipment;
