import SbButton from '@Components/Button';
import { AppDispatch, RootStateType } from '@Store';
import { Box, Grid, TableContainer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteOptimizerDetailsTable } from './Table';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSquadRouteOptimizer,
  getSquadTripDetails,
  optimizeRoute,
  updateRouteoptimizer,
} from '@Reducers/Squad/actions';
import { setRouteOptimizerTrips } from '@Reducers/Squad';
import { Activity, Stop, RouteData, updateRoutePayload } from './Types';
import Loader from '@Components/Loader';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { SbLoadingButton } from '@Components/Button';
export default function RouteOptimizeDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { routeId, tripId } = useParams<{ routeId: string; tripId: string }>();
  const { routeOptimizerTrips, squadTripDetails, loading } = useSelector(
    (state: RootStateType) => state.squadManagement,
  );

  const [routeDetails, setRouteTripDetails] = useState<RouteData | null>(null);
  const [currRoutedata, setCurrRouteData] = useState<RouteData | null>(null);
  const [SquadMembervehicle, setSquadMemberVehicle] = useState('');
  const [isOptimized, setIsOptimized] = useState(false);
  const [optimizeLoading, setOptimizeLoading] = useState(false);
  const [isManual, SetIsManual] = useState(false);
  const [AllStops, setAllStops] = useState<RouteData['stops'] | null>(null);
  const [isErrorInTripApi, setErrorInTripApi] = useState(false);
  const onOptimizeClick = async () => {
    setOptimizeLoading(true);
    const { vehicle, vehicleCapacity, tripDetails } = squadTripDetails;
    if (!tripDetails || !vehicle || !vehicleCapacity) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something Went Wrong',
          subTitle: '',
        }),
      );
      setOptimizeLoading(false);
      return;
    }

    if (tripId) {
      const result = await dispatch(optimizeRoute({ postData: { tripId } }));
      setOptimizeLoading(false);
      const updatedPayload = {
        ...result.payload,
        stops: result.payload.stops.map((stop: Stop) => {
          return {
            ...stop,
            activities: stop.activities.filter(
              (activity: Activity) => activity.shipmentId !== 'departure' && activity.shipmentId !== 'arrival',
            ),
          };
        }),
      };

      if (
        routeOptimizerTrips?.totalDistance < updatedPayload.totalDistance &&
        routeOptimizerTrips?.totalDuration < updatedPayload.totalDuration
      ) {
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Already Optimized',
            subTitle: 'No optimization Required',
          }),
        );
        setOptimizeLoading(false);
        return;
      }
      setCurrRouteData(updatedPayload);
      setAllStops(updatedPayload.stops);
      setIsOptimized(true);
      setOptimizeLoading(false);
      SetIsManual(false);
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Your Route has Been updated',
          subTitle: 'Click on save to update route',
        }),
      );
    }
  };
  const onSaveClick = async () => {
    let stopsPayload;
    let totalDistance = 0;

    if (isManual && AllStops) {
      totalDistance = AllStops.reduce((acc: number, stop) => acc + stop.distance, 0);

      const updatedRouteData = {
        ...routeOptimizerTrips,
        stops: AllStops.map(stop => ({
          addressId: stop.addressId._id,
          type: stop.type,
          isWarehouse: stop.isWarehouse,
          query: stop.query,
          activities: stop.activities.map((activity: Activity) => ({
            shipmentId: activity.shipmentId,
            isPickup: activity.isPickup,
          })),
          location: stop.location,
          distance: stop.distance,
          _id: stop._id,
        })),
        totalDistance,
      };

      setCurrRouteData(updatedRouteData);

      stopsPayload = AllStops.map(stop => ({
        addressId: stop.addressId._id,
        type: stop.type,
        isWarehouse: stop.isWarehouse,
        query: stop.query,
        activities: stop.activities.map((activity: Activity) => ({
          shipmentId: activity.shipmentId,
          isPickup: activity.isPickup,
        })),
        location: stop.location,
        distance: stop.distance,
        _id: stop._id,
      }));
    }

    if (currRoutedata?.stops && routeDetails) {
      stopsPayload = currRoutedata.stops.map((stop, index) => ({
        addressId: stop.addressId._id,
        type: stop.type,
        isWarehouse: stop.isWarehouse,
        query: stop.query,
        activities: stop.activities.map((activity: Activity) => ({
          shipmentId: activity.shipmentId,
          isPickup: activity.isPickup,
        })),
        location: stop.location,
        distance: stop.distance,
        _id: routeDetails.stops[index]._id,
      }));
      totalDistance = currRoutedata.totalDistance;
    }

    const payload = {
      totalDuration: routeOptimizerTrips.totalDuration,
      totalDistance,
      stops: stopsPayload,
      _id: routeDetails?._id,
      ...(isOptimized && { isOptimized: true }),
    };

    try {
      const update = await dispatch(updateRouteoptimizer(payload));
      const { status } = (update.payload as updateRoutePayload) || 500;
      if (status == 200) {
        dispatch(setRouteOptimizerTrips(currRoutedata));
        dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Route Updated Successfully',
            subTitle: '',
          }),
        );
        return;
      }
      throw { msg: 'unable to update' };
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: '',
        }),
      );
    } finally {
      setCurrRouteData(null);
      navigate('/route-optimizer');
    }
  };

  const onBackClick = async () => {
    setAllStops(routeOptimizerTrips.stops);
    setCurrRouteData(null);
    setIsOptimized(false);
    SetIsManual(false);
  };

  useEffect(() => {
    const fetchRouteOptimizer = async () => {
      if (routeId && tripId) {
        try {
          const res1 = await dispatch(getSquadTripDetails({ tripId }));
          if (res1.payload) {
            setSquadMemberVehicle(res1.payload.vehicle);
          } else {
            console.error('Error fetching squad trip details');
          }
        } catch (error) {
          console.error('Error in fetching squad trip details:', error);
        }

        try {
          const res2 = await dispatch(getSquadRouteOptimizer(routeId));
          if (res2.payload) {
            setRouteTripDetails(res2.payload);
            setAllStops(res2.payload.stops);
          } else {
            console.error('Error fetching squad route optimizer');
          }
        } catch (error) {
          setErrorInTripApi(true);
          console.error('Error in fetching squad route optimizer:', error);
        }
      }
    };

    fetchRouteOptimizer();
  }, [routeId, tripId]);

  return (
    <>
      <Loader loading={loading} overly />
      <TableContainer id="fadedScroll" sx={{ marginTop: '20px' }}>
        <RouteOptimizerDetailsTable
          AllStops={AllStops}
          setAllStops={setAllStops}
          SquadMembervehicle={SquadMembervehicle}
          setIsOptimized={setIsOptimized}
          SetIsManual={SetIsManual}
        />
      </TableContainer>

      <Box my="1rem">
        <SbLoadingButton
          sx={{
            borderRadius: '8px !important',
            width: '8rem',
          }}
          variant="contained"
          disabled={(routeDetails?.isOptimized && !isOptimized) || !isManual}
          loading={optimizeLoading}
          onClick={() => (isErrorInTripApi ? undefined : onOptimizeClick())}
        >
          Optimize
        </SbLoadingButton>
      </Box>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={2}>
          <SbButton size="large" fullWidth variant="outlined" onClick={() => onBackClick()} disabled={!isOptimized}>
            Back
          </SbButton>
        </Grid>
        <Grid item xs={2}>
          <SbButton size="large" fullWidth variant="contained" onClick={() => onSaveClick()} disabled={!isOptimized}>
            Save
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}
