import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import FilterSelect from '@Components/Filter/Dropdown';
import { DropdownOptionType } from '@Components/Dropdown/Type';
import { AppDispatch, RootStateType } from '@Store';
import { SystemObj } from '@Reducers/Systems/Type';
import { ServiceObj } from '@Reducers/CarrierAndService/Type';
import SbButton from '@Components/Button';
import { getRates } from '@Reducers/RateSummary';
import { pageType } from '@Reducers/RateSummary/Type';

interface RatingProps {
  pageType: pageType;
  rateFor: string[];
  system: string[];
  setSystem: React.Dispatch<React.SetStateAction<string[]>>;
  csp: string[];
  setCsp: React.Dispatch<React.SetStateAction<string[]>>;
  rateType: string[];
  setRate: React.Dispatch<React.SetStateAction<string[]>>;
  selectedDate: Date;
}
// type GetOptionsArr = Record<string, string>;
type GetOptionsArr<keys extends string> = {
  [displayKey in keys]: string;
};

function getOptionFromList<D extends string, I extends string>(
  arr: GetOptionsArr<D | I>[],
  displayKey: D,
  idKey: I,
): DropdownOptionType[] {
  const optionsList = arr.map(item => ({ display: item[displayKey], value: item[idKey] }));
  return optionsList;
}

const RateTypeOptions: DropdownOptionType<string>[] = [
  { value: 'parcel', display: 'Parcel' },
  { value: 'document', display: 'Document' },
];

function RateFilter(props: RatingProps) {
  const { pageType, rateFor, system, setSystem, csp, setCsp, rateType, setRate, selectedDate } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [systemOptions, setSystemOptions] = useState<DropdownOptionType[]>([]);
  const [cspOptions, setCspOptions] = useState<DropdownOptionType[]>([]);

  const SystemList: SystemObj[] = useSelector<RootStateType>(state => state.System.activeSystemList) as SystemObj[];
  const CspList: ServiceObj[] = useSelector<RootStateType>(
    // state => state.carrierAndService.serviceList,
    state => state.carrierAndService.selectedServiceList,
  ) as ServiceObj[];

  const PricingRateFilters = () => {
    dispatch(
      getRates({
        navigatedDate: selectedDate.toISOString(),
        pageType: pageType,
        accountNumber: '',
        isImport: rateFor[0] === 'inbound',
        system: system || [],
        csp: csp || [],
        rateType: rateType || [],
      }),
    );
  };

  useEffect(() => {
    PricingRateFilters();
  }, [system, csp, rateType, pageType]);

  useEffect(
    function () {
      setSystemOptions(getOptionFromList(SystemList, 'name', '_id'));
    },
    [SystemList, setSystemOptions],
  );

  useEffect(
    function () {
      setCspOptions(getOptionFromList(CspList, 'name', '_id'));
    },
    [CspList, setCspOptions],
  );

  const onClearFilter = () => {
    setSystem([]);
    setRate([]);
    setCsp([]);
  };

  useEffect(() => {
    onClearFilter();
  }, [rateFor]);

  return (
    <Grid container className="mt-2" gap={2} gridTemplateColumns="repeat(4, max-content)" justifyContent="flex-end">
      <Grid item>
        <FilterSelect
          id="rate-type"
          value={rateType}
          options={RateTypeOptions}
          label="Rate Type"
          onChange={selected => setRate(selected)}
        />
      </Grid>
      <Grid item>
        <FilterSelect
          id="system-filter"
          value={system}
          options={systemOptions}
          label="System"
          onChange={selected => setSystem(selected)}
        />
      </Grid>
      <Grid item>
        <FilterSelect
          id="csp-filter"
          value={csp}
          options={cspOptions}
          label="Service"
          onChange={selected => setCsp(selected)}
        />
      </Grid>

      <Grid item>
        <SbButton
          variant="outlined"
          onClick={onClearFilter}
          className="sm-text"
          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
        >
          Clear Filters
        </SbButton>
      </Grid>
    </Grid>
  );
}

export default RateFilter;
