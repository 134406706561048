import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilterSearch from '@Components/Filter/Search';
import { Link } from 'react-router-dom';
import SbButton from '@Components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getAccessGroupList } from '@Reducers/AccessGroup/actions';
import Table from './Table';
import { resetPageAndActionIds } from '@Reducers/AccessGroup';
import Loader from '@Components/Loader';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { TableTemplateDataObj } from '@Reducers/AccessGroup/Type';

const SearchFilterList = [{ label: 'Name', value: 'name' }];
function AccessGroupList() {
  const dispatch = useDispatch<AppDispatch>();
  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const [filterText, setFilterText] = useState('');

  const { data, loading } = useSelector((state: RootStateType) => state.accessGroup);
  const [searchName, setSearchName] = useState<string>('');
  const [updatedList, setUpdatedList] = useState<TableTemplateDataObj[] | []>([]);
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isCreateTemplateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Access_Groups.Create_Template,
    PERFORM_ACTION.write,
  );
  const isSavedTemplateReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Access_Groups.Save_Template,
    PERFORM_ACTION.read,
  );

  const isSavedTemplateWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Access_Groups.Save_Template,
    PERFORM_ACTION.write,
  );
  const isDefaultTemplateReadAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Access_Groups.Draft_Template,
    PERFORM_ACTION.read,
  );
  const isDefaultTemplateWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Access_Groups.Draft_Template,
    PERFORM_ACTION.write,
  );

  useEffect(() => {
    dispatch(getAccessGroupList({ listDefault: true }));
  }, []);

  const handleCreateNew = () => {
    if (isCreateTemplateAllowed) {
      dispatch(resetPageAndActionIds());
    }
  };

  const handleUpdateList = () => {
    if (data) {
      const updatedList = data.list.filter(
        (item: TableTemplateDataObj) =>
          item?.name.toLowerCase().startsWith(filterText.toLowerCase()) && !item.isDefault,
      );
      setUpdatedList(updatedList);
      setSearchName(filterText);
    }
  };

  const defaultTemplatesData = useMemo(() => {
    return data?.list?.filter((item: TableTemplateDataObj) => item && item.isDefault === true);
  }, [data]);

  const savedTemplateData = useMemo(() => {
    return data?.list?.filter((item: TableTemplateDataObj) => item.isDefault !== true);
  }, [data]);

  return (
    <Loader loading={loading} overly>
      <Grid container item xs={12} sm={9} xl={8}>
        <Grid container item justifyContent="space-between">
          <Grid item>
            <FilterSearch
              onSelectChange={(value: string) => {
                setFilterSelectValue(value);
              }}
              list={SearchFilterList}
              selectValue={filterSelectValue}
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={() => {
                console.log('ON SEARCH');
                handleUpdateList();
              }}
              onTextClear={() => {
                setFilterText('');
                console.log('ON CLEAR');
                setUpdatedList([]);
                setSearchName('');
              }}
            />
          </Grid>
          <Grid item>
            <Link
              to={`${isCreateTemplateAllowed ? '/access-group/create' : ''} `}
              className={`${isCreateTemplateAllowed ? '' : 'cursor-not-allowed'}`}
            >
              <SbButton
                variant="contained"
                className={`ml-auto  `}
                sx={{ borderRadius: '8px !important' }}
                startIcon={<AddCircleIcon fontSize="small" />}
                onClick={handleCreateNew}
                disabled={!isCreateTemplateAllowed}
              >
                Create new
              </SbButton>
            </Link>
          </Grid>
        </Grid>
        {isSavedTemplateReadAllowed && (
          <Table
            EditPermission={isSavedTemplateWriteAllowed}
            data={searchName.trim().length === 0 ? savedTemplateData : updatedList}
            header="Saved Template"
          />
        )}
        {isDefaultTemplateReadAllowed && (
          <Table EditPermission={isDefaultTemplateWriteAllowed} data={defaultTemplatesData} header="Default Template" />
        )}
      </Grid>
    </Loader>
  );
}

// import { useMediaQuery, useTheme } from '@mui/material';

// interface ActionsProps {
//   onNext?: (event: React.SyntheticEvent) => void;
//   disableNext?: boolean;
// }

// function Actions(props: ActionsProps) {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//   const handleBack = console.log;
//   const onSave = console.log;

//   return (
//     <Grid container justifyContent={isMobile ? 'space-between' : 'end'} columnSpacing={2} className="my-1">
//       <Grid item xs={6} sm={1}>
//         <SbButton fullWidth onClick={handleBack} variant="outlined">
//           Back
//         </SbButton>
//       </Grid>
//       <Grid item xs={6} sm={1}>
//         <SbButton fullWidth onClick={onSave} variant="contained" disabled={props.disableNext}>
//           Next
//         </SbButton>
//       </Grid>
//     </Grid>
//   );
// }

export default AccessGroupList;
