import Modal from '@Components/Modal';
import Title from '@Components/Title';
import React, { useEffect, useState } from 'react';
import { initCopyRate, pageConfig } from '../constant';
import { CopyRate as CopyRateType, CopyRatePropsType } from '../type';
import Divider from '@Components/Divider';
import { FormHelperText, Grid } from '@mui/material';
import SelectedAccountNumber from '../SelectedAccountNumber';
import { UserObj } from '@Reducers/AccountLookup/Type';
import AccountLookup from '@Components/AccountLookup';
import { FormContent, FormLabel } from '@Components/Form';

import { CopyRateSchema } from './schema';
import { useFormik } from 'formik';
import SbTextField from '@Components/Textfield';
import dayJs, { Dayjs } from 'dayjs';
import DatePicker from '@Components/DatePicker';
import CSP from './csp';
import System from './System';
import SbButton from '@Components/Button';

function CopyRate(props: CopyRatePropsType) {
  const modalId = props.modalId;
  const _pageConfig = pageConfig[props.rateFor];
  const showAccountNumber = _pageConfig.showAccountNumber;

  const [copyRateEdit, setCopyRateEdit] = useState<CopyRateType>({ ...initCopyRate });
  const [selectedAccNum, setSelectedAccNum] = useState<(string | null)[]>([]);
  const [accountNumberError, setAccountNumberError] = useState<string>('');

  // useEffect(
  //   function () {
  //     console.log({ selectedAccNum: props.selectedAccNum });
  //     if (props.selectedAccNum) setSelectedAccNum([props.selectedAccNum]);
  //   },
  //   [props.selectedAccNum],
  // );

  const onSelectUser = (user: UserObj) => {
    const accountNumber = user._accountId?.accountNumber || '';
    if (selectedAccNum.includes(accountNumber)) return setAccountNumberError('Account number already added.');
    if (accountNumberError) setAccountNumberError('');
    setSelectedAccNum(x => [...x, accountNumber]);
  };

  const onRemoveAccNo = (currAcc: string) => {
    const _tempAcc = [...selectedAccNum];
    const index = selectedAccNum.indexOf(currAcc);
    _tempAcc.splice(index, 1);
    setSelectedAccNum([..._tempAcc]);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...copyRateEdit,
      startDate: props.edit?.startDate || props.startDate || '',
      endDate: props.edit?.endDate || props.endDate || '',
      minWeight: props.edit?.minWeight || props.minWeight || '',
      maxWeight: props.edit?.maxWeight || props.maxWeight || '',
    },
    validationSchema: CopyRateSchema,

    onSubmit(values) {
      props.onAdd({ ...values, accountNumber: !showAccountNumber ? ['default'] : selectedAccNum });
      onModalClose();
    },
    validateOnChange: false,
  });

  const onModalClose = () => {
    formik.resetForm();
    setSelectedAccNum([]);
    props.onCloseModal();
    setCopyRateEdit({ ...initCopyRate });
  };

  useEffect(() => {
    if (props.modalOpen && props.edit) {
      setCopyRateEdit(props.edit);
      if (props.edit.accountNumber) setSelectedAccNum(props.edit.accountNumber);
    } else if (props.modalOpen) {
      // Initialize with props when not in edit mode
      setCopyRateEdit({
        ...initCopyRate,
        startDate: props.startDate || '',
        endDate: props.endDate || '',
        minWeight: props.minWeight || '',
        maxWeight: props.maxWeight || '',
        system:
          props.edit?.system ||
          (Array.isArray(props.defaultSystemIds)
            ? props.defaultSystemIds
            : props.defaultSystemIds
            ? [props.defaultSystemIds]
            : []),
        csp: props.edit?.csp || props.defaultCspId || '',
      });
    }
  }, [props.modalOpen]);

  const { handleChange, setFieldValue, values: formikValues, errors } = formik;
  const onSubmit = () => {
    if (showAccountNumber && !selectedAccNum.length) {
      setAccountNumberError('Account Number is required.');
      return;
    }
    formik.handleSubmit();
  };

  return (
    <Modal maxWidth="lg" open={props.modalOpen}>
      <>
        <Title title={`Copy ${_pageConfig.title} of ${props.serviceName} - ${props.systemName}`} removeBorder />
        <Divider className="my-2" />
        <Grid container>
          {showAccountNumber && (
            <Grid item container xs={12} alignItems="start">
              <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
                <FormLabel>Select Accounts To Copy Rates*</FormLabel>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <FormContent>
                  <AccountLookup modalId={modalId} showSearchInput getSelectedUser={onSelectUser} />
                </FormContent>
                {accountNumberError && (
                  <FormHelperText error className="mt-1">
                    {accountNumberError}
                  </FormHelperText>
                )}
                {
                  <Grid item className="mt-2">
                    {selectedAccNum.map((currAcc, index) => (
                      <SelectedAccountNumber
                        key={`${modalId}-copy-rates-${index}`}
                        accountNumber={currAcc || ''}
                        onRemove={() => onRemoveAccNo(currAcc || '')}
                      />
                    ))}
                  </Grid>
                }
              </Grid>
              <Divider className="my-2" />
            </Grid>
          )}
          {/****************************** Service **************************************/}
          <CSP
            onChange={([value]) => setFieldValue('csp', value, false)}
            value={[formikValues.csp]}
            error={errors.csp}
          />
          {/****************************** System **************************************/}
          <System
            onChange={value => setFieldValue('system', value, false)}
            value={formikValues.system}
            error={Array.isArray(errors.system) ? errors.system.join(',') : errors.system}
            multiple
          />
          {/****************************** Start Date **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Start Date*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <DatePicker
                  inputFormat="DD MMM YYYY"
                  label=""
                  value={formikValues.startDate ? dayJs(formikValues.startDate) : undefined}
                  required
                  minDate={dayJs()}
                  handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
                    if (date) setFieldValue('startDate', date.format(), false);
                    else return setFieldValue('startDate', null, false);
                    if (formikValues.endDate && date.valueOf() > new Date(formikValues.endDate).getTime()) {
                      formik.setFieldError('endDate', 'End date should be greater than start date');
                    }
                  }}
                  error={errors.startDate}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** End Date **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>End Date*</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <DatePicker
                  inputFormat="DD MMM YYYY"
                  label=""
                  value={formikValues.endDate ? dayJs(formikValues.endDate) : undefined}
                  required
                  handleChange={(date: Dayjs, _keyboardInputValue?: string | undefined) => {
                    formik.setFieldError('endDate', '');
                    if (date) setFieldValue('endDate', date.format(), false);
                    else setFieldValue('endDate', null, false);
                  }}
                  error={errors.endDate}
                  minDate={formikValues.startDate ? dayJs(formikValues.startDate) : undefined}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Min Weight **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Min Weight</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <SbTextField
                  type="string"
                  required
                  name="minWeight"
                  value={formikValues.minWeight ?? undefined}
                  onChange={e => {
                    const { value } = e.target;
                    // change validation for minWeight
                    if (!value || /^\d*\.?\d{0,2}$/.test(value)) {
                      setFieldValue('minWeight', value);
                    }
                  }}
                  error={errors?.minWeight}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          {/****************************** Max Weight **************************************/}
          <Grid item container xs={12} alignItems="center">
            <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
              <FormLabel>Max Weight</FormLabel>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <FormContent>
                <SbTextField
                  required
                  type="number"
                  name="maxWeight"
                  value={formikValues.maxWeight ?? undefined}
                  onChange={handleChange}
                  error={errors?.maxWeight}
                />
              </FormContent>
            </Grid>
            <Divider className="my-2" />
          </Grid>
          <Grid container item mt={4} spacing={2} justifyContent="flex-end">
            <Grid item xs={6} sm={3} md={2} lg={1.5}>
              <SbButton fullWidth variant="outlined" onClick={onModalClose}>
                Cancel
              </SbButton>
            </Grid>
            <Grid item xs={6} sm={3} md={2} lg={1.5}>
              <SbButton fullWidth variant="contained" onClick={onSubmit} disabled={!!errors.endDate}>
                {'Add'}
              </SbButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
}

export default CopyRate;
