import React from 'react';
import CreateAdminForm from './Form';

function CreateAdmin() {
  return (
    <>
      <CreateAdminForm />
    </>
  );
}

export default CreateAdmin;
