import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import CreateSysForm, { initCreate } from '../Create/Form';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';
import { ODAType } from '../Create/type';

import { getOdaById, resetSingleOda, updateOdaById } from '../../../store/reducers/ODA';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { UpdateServiceSchema } from '../Create/schema';
import SbButton from '../../../components/Button';
import { OdaUpdatePayload } from '../../../store/reducers/ODA/Type';
import dayjs from 'dayjs';

function EditOda() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id: odaId } = useParams() as { id: string };
  const [oda, setOda] = useState<ODAType>({ ...initCreate });
  const OdaState = useSelector((state: RootStateType) => state.oda);

  useEffect(() => {
    if (odaId) dispatch(getOdaById(odaId));
    return () => {
      dispatch(resetSingleOda());
    };
  }, []);

  useEffect(() => {
    if (OdaState.singleOda) {
      const _OdaState = OdaState.singleOda;

      setOda({
        _serviceProviderId: _OdaState._serviceProviderId,
        startDate: _OdaState.startDate,
        endDate: _OdaState.endDate,
        charges: [],
      });
    }
  }, [OdaState.singleOda]);

  const onUpdate = (params: ODAType) => {
    if (!params.endDate && !params.charges.length) return;

    const _OdaState = OdaState.singleOda;
    const payload: OdaUpdatePayload = { _id: odaId };

    if (params.endDate !== _OdaState?.endDate) payload.endDate = params.endDate;
    if (params.charges) payload.charges = params.charges;
    dispatch(updateOdaById({ payload, showModal: true, navigate: '/oda' }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...oda },
    validationSchema: UpdateServiceSchema,
    onSubmit(values) {
      onUpdate(values);
    },
  });
  const isPastDate = OdaState.singleOda ? dayjs().isAfter(OdaState.singleOda.endDate) : false;
  return (
    <>
      {/* <Grid container> */}

      <Grid item xs={12}>
        <CreateSysForm
          formType="edit"
          showBackgroundColor={false}
          errors={formik.errors}
          values={formik.values}
          handleChange={formik.handleChange}
          setFieldValue={formik.setFieldValue}
        />
      </Grid>
      <Grid container item mt={4} spacing={2} justifyContent="flex-end">
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          <SbButton
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </SbButton>
        </Grid>
        {!isPastDate && (
          <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
            <SbButton
              fullWidth
              variant="contained"
              onClick={_event => {
                formik.handleSubmit();
              }}
            >
              Update
            </SbButton>
          </Grid>
        )}
      </Grid>
      {/* </Grid> */}
    </>
  );
}

export default EditOda;
