import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';

export function useLocale() {
  const orgCountryObj = useSelector((state: RootStateType) => state?.orgSetup?.orgCountryObj);
  return orgCountryObj?.name?.toLowerCase() === 'india' ? 'en-IN' : 'en-US';
}

export function formatNumber(
  value: number,
  locale: string,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value);
}
