import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, TableBody, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import FilterSearch from '../../../components/Filter/Search';
import FilterSelect from '../../../components/Filter/Dropdown';
import SbButton from '../../../components/Button';
import { SBTable } from '../../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../../components/Table/TableCell';
import { SBTableRow } from '../../../components/Table/TableRow';

// ICONS

import { AppDispatch, RootStateType } from '../../../store';
import { FilterObj } from './Types';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Image from '../../../components/Image';
import { useNavigate } from 'react-router-dom';
import { SystemBox, TableActionContainer } from './styles';
import { SbMenu, SbMenuItem } from '../../../components/Menu';
import { DoNotDisturbOn, Autorenew } from '@mui/icons-material';
import { AdminListQuery, AdminObj } from '../../../store/reducers/Admin/Type';
import { deactivateAdminAccount, getAdminList, restoreAdminAccount } from '../../../store/reducers/Admin/actions';
import Title from '../../../components/Title';
import { getLoggedInUserSystemList, getSystemList } from '../../../store/reducers/Systems';
import { USER_STATUS_COLOR, USER_STATUS, USER_STATUS_LIST } from '../../../constants/user';
// import { getFormattedContact } from '../../../services/getFormattedContact';
import Loader from '@Components/Loader';
import moment from 'moment';
import DeleteModal from '../../../components/Modal/Delete';
import { isActionAllowed } from '../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';
import { getAccessGroupList } from '@Reducers/AccessGroup/actions';
import SuccessModal from '../Create/SuccessModal';
import { resetAdminCreated, resetAdminUpdated } from '@Reducers/Admin';
import ExportBtn from '@Components/Button/Export';

import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
import { exportUserList } from '@Reducers/AdminReport/adminExportSlice';

// interface PrevSearchObject {
//   searchKey: string;
//   searchValue: string;
// }

const SearchFilterList = [
  { label: 'Name', value: 'name' },
  { label: 'Username', value: 'username' },
  // { label: 'Account no.', value: 'accountNumber' },
  // { label: 'Company Name', value: 'companyName' },
];

// const AccessList = [
//   { display: 'Primary Admin', value: 'pa_all' },
//   // { display: 'Secondary Admin', value: 'su_all' },
// ];

const AdminStatusList = Object.values(USER_STATUS_LIST)
  .filter(s => ['on_boarded', 'suspended', 'archive'].includes(s.value))
  .map(s => ({ display: s.label, value: s.value }));

function AdminActions(props: { admin: AdminObj }) {
  const { admin } = props;
  const loggedInUserId = useSelector((state: RootStateType) => state.loginUser.profile?._id);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Admin_Details.edit_admin,
    PERFORM_ACTION.write,
  );

  const isDeactivateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Admin_Details.deactive_admin,
    PERFORM_ACTION.write,
  );

  const isRestoreAccounttAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Admin_Details.restore_admin,
    PERFORM_ACTION.write,
  );

  const [showConfirmationModal, setShowConfirmationModal] = useState<{
    open: boolean;
    handleConfirm?: () => void;
    msg?: string;
  }>({
    open: false,
  });
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const handleEditAction = () => {
    navigate(`/admin/edit/${admin._id}`);
  };
  const handleDeactivateAction = () => {
    setShowConfirmationModal({
      open: true,
      msg: 'Do you want to Deactivate the account?',
      handleConfirm: () => {
        if (admin._id) dispatch(deactivateAdminAccount({ id: admin._id, showModal: true }));
      },
    });
  };
  const handleRestoreAccountAction = () => {
    setShowConfirmationModal({
      open: true,
      msg: 'Do you want to Restore the account?',
      handleConfirm: () => {
        if (admin._id) {
          dispatch(restoreAdminAccount({ id: admin._id, showModal: true }));
        }
      },
    });
  };

  const handleConfirm = () => {
    showConfirmationModal?.handleConfirm?.();
    setShowConfirmationModal({ open: false });
  };

  const handleResendInviteAction = () => {
    setIsSuccessModalOpen(true);
  };

  return (
    <>
      <DeleteModal
        icon="/images/Boxes/WarningContrast.svg"
        open={!!showConfirmationModal.open}
        title="Are You Sure?"
        subTitle={showConfirmationModal.msg}
        onClose={() => setShowConfirmationModal({ open: false })}
        onConfirm={handleConfirm}
      />
      <SuccessModal
        name={admin?.name || ''}
        email={admin?.email || ''}
        username={admin?.username || ''}
        isOpen={isSuccessModalOpen}
        handleClose={() => {
          setIsSuccessModalOpen(false);
          // if (formType === 'create') {
          //   navigate('/admin');
          // }
          dispatch(resetAdminCreated());
          dispatch(resetAdminUpdated());
        }}
      />

      <SbMenu disabled={loggedInUserId === admin._id}>
        {isEditAllowed && (
          <SbMenuItem
            icon={<Image src="/images/icons/EditAction.svg" alt="Edit" style={{ marginLeft: 3 }} />}
            name="Edit"
            onClick={handleEditAction}
          />
        )}
        {admin.onBoardedStatus !== USER_STATUS.archive && isDeactivateAllowed && (
          <SbMenuItem name="Deactivate" icon={<DoNotDisturbOn />} onClick={handleDeactivateAction} />
        )}
        {admin.onBoardedStatus === USER_STATUS.archive && isRestoreAccounttAllowed && (
          <SbMenuItem name="Restore Account" icon={<Autorenew />} onClick={handleRestoreAccountAction} />
        )}
        <SbMenuItem
          name="Resend Invite"
          icon={<Image src="/images/icons/user/ResendInvoice.svg" alt="resend" style={{ marginLeft: 3 }} />}
          onClick={handleResendInviteAction}
        ></SbMenuItem>
      </SbMenu>
    </>
  );
}

function AdminAccountTable() {
  const {
    data: { list },
  } = useSelector((state: RootStateType) => state.admin);

  function formatString(inputString: string): string {
    if (inputString) {
      return inputString
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    return '';
  }

  return (
    <SBTable>
      <TableHead>
        <TableRow>
          <TableHeadCell sx={{ minWidth: '311px' }}>Account Details</TableHeadCell>
          <TableHeadCell sx={{ minWidth: '195px' }}>System</TableHeadCell>
          <TableHeadCell sx={{ minWidth: '214px' }}>Access</TableHeadCell>
          <TableHeadCell sx={{ minWidth: '250px' }}>Status</TableHeadCell>
          <TableHeadCell sx={{ minWidth: '224px' }}>User Details</TableHeadCell>
          <TableHeadCell sx={{ minWidth: '57px' }}></TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list?.length ? (
          list.map(admin => (
            <SBTableRow key={admin._id}>
              <TableRowCell className="md-text">
                <AccountDetails admin={admin} />
              </TableRowCell>
              <TableRowCell className="md-text">
                <Systems systemList={admin._systems} />
              </TableRowCell>
              <TableRowCell align="center">
                <Typography className="sb-text-black">
                  {admin.accessGroup === 'pa_all' ? 'Primary Admin' : formatString(admin.accessGroup)}
                </Typography>
              </TableRowCell>
              <TableRowCell align="center">
                <Status status={admin.onBoardedStatus} />
              </TableRowCell>
              <TableRowCell>
                <AccUserDetails admin={admin} />
              </TableRowCell>
              <TableRowCell>
                <AdminActions admin={admin} />
              </TableRowCell>
            </SBTableRow>
          ))
        ) : (
          <SBTableRow>
            <TableRowCell colSpan={6}>
              <Typography className="sb-text-gray font-medium text-center">No Data Found.</Typography>
            </TableRowCell>
          </SBTableRow>
        )}
      </TableBody>
    </SBTable>
  );
}

function Systems(props: { systemList: string[] }) {
  const { systemList } = props;
  return systemList.length <= 1 ? (
    <Typography className="text-center">{systemList[0]}</Typography>
  ) : (
    <>
      <SystemBox id={'fadedScroll'} variant="outlined">
        {systemList.map((system, index) => (
          <Typography key={`${index}-${system}`} width={'120px'} sx={{ py: 1 / 4, px: 1 }}>
            {system}
          </Typography>
        ))}
      </SystemBox>
    </>
  );
}
function AccountDetails(props: { admin: AdminObj }) {
  const { name, email, username, isEmailVerified, _id } = props?.admin || {};
  const loggedInUserId = useSelector((state: RootStateType) => state.loginUser.profile?._id);
  return (
    <>
      <div>
        <div className="flex">
          <Typography className="sb-text-black font-semibold">{name}</Typography>
          {loggedInUserId === _id && (
            <Typography className="sb-text-gray" style={{ marginLeft: '2px' }}>
              (You)
            </Typography>
          )}
        </div>
        <Typography className="sb-text-black">{username}</Typography>
        {email && (
          <Typography className="sb-text-gray flex items-center">
            {email}
            {isEmailVerified ? (
              <CheckCircleIcon className="ml-1 md-text sb-text-success" />
            ) : (
              <ErrorOutlineIcon className="ml-1 md-text sb-text-error" />
            )}
          </Typography>
        )}
        {/* {_company[0]?.contact?.[0]?.number && (
          <Typography className="sb-text-gray flex items-center">
            {getFormattedContact(`${_company[0]?.contact[0].country_code}  ${_company[0]?.contact[0].number}`)}
            {isPhoneVerified ? (
              <CheckCircleIcon className="ml-1 md-text sb-text-success" />
            ) : (
              <ErrorOutlineIcon className="ml-1 md-text sb-text-error" />
            )}
          </Typography>
        )} */}
      </div>
    </>
  );
}
// function AccountTypeCompany() {
//   return (
//     <>
//       <div className="">
//         <Typography>
//           <Image src="/images/icons/user/Business.svg" alt="Business" />
//         </Typography>
//         <Typography className="mb-1 sb-text-gray underline">Allied Express</Typography>
//         <Typography className="sb-text-gray font-medium">Postpaid</Typography>
//         <Typography className="sb-text-gray">Hybrid</Typography>
//       </div>
//     </>
//   );
// }

// function AccountTypeUser() {
//   return (
//     <>
//       <div className="">
//         <Typography>
//           <Image src="/images/icons/user/User.svg" alt="User" />
//         </Typography>
//         <Typography className="sb-text-gray font-medium">Postpaid</Typography>
//         <Typography className="sb-text-gray">Walk -in</Typography>
//       </div>
//     </>
//   );
// }

function Status({ status }: { status: USER_STATUS }) {
  const { label, bgColor, textColor } = USER_STATUS_COLOR[status] || {};
  return (
    <Typography sx={{ color: textColor, background: bgColor, px: 1, py: 0.5 }} className="w-fit rounded-xs m-auto">
      {label}
    </Typography>
  );
}

function AccUserDetails({ admin }: { admin: AdminObj }) {
  const { createdAt, _belongToAdminId, createdBy } = admin;

  return (
    <>
      <div className="">
        {createdBy && <Typography className="sb-text-gray">Created by : {createdBy?.name}</Typography>}
        {_belongToAdminId && <Typography className="sb-text-gray">Report to : {_belongToAdminId?.name}</Typography>}
        {createdAt && (
          <Typography className="sb-text-gray">Created on : {moment(createdAt).format('DD/MM/YYYY')}</Typography>
        )}
      </div>
    </>
  );
}

export default function AdminDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    data: { currentPage, perPage, totalCount },
    success,
    loading,
  } = useSelector((state: RootStateType) => state.admin);

  const {
    data: { list: accessGroupList },
  } = useSelector((state: RootStateType) => state.accessGroup);

  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);
  const isCreateAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Admin_Details.create_admin,
    PERFORM_ACTION.write,
  );

  const [filterSelectValue, setFilterSelectValue] = useState('name');
  const [filterText, setFilterText] = useState('');
  const [system, setSystem] = useState<string[]>([]);
  const [access, setAccess] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [prevSearchObj, setPrevSearchObj] = useState<string>('');
  const [accountType, setAccountType] = useState<string[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const { limit } = useSelector((state: RootStateType) => state.notifications);

  // const SystemList = useSelector((state: RootStateType) => state.System.data.list).map(system => ({
  //   display: system.name,
  //   value: system._id,
  // }));
  // const _systems = useSelector((state: RootStateType) => state.loginUser?.profile?._systems);

  useEffect(() => {
    dispatch(getAdminList({ currentPage: 1, perPage: 10 }));
  }, []);

  useEffect(() => {
    dispatch(getSystemList());
    dispatch(getLoggedInUserSystemList());
    dispatch(getAccessGroupList({}));
  }, []);

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
    dispatch(getAdminList(getFilterObj({ system: selected })));
  };

  const onAccessFilter = (selected: Array<string>) => {
    setAccountType(selected);
    setAccess(selected);
    dispatch(getAdminList(getFilterObj({ access: selected })));
  };

  const onStatusFilter = (selected: Array<string>) => {
    setStatus(selected);
    dispatch(getAdminList(getFilterObj({ status: selected })));
  };

  const getFilterObj = (options: FilterObj): AdminListQuery => {
    const filterSystem = options.system || system;
    const filterAccess = options.access || access;
    const filterStatus = options.status || status;

    const filterSearchKey = filterSelectValue;
    const filterSearchValue = 'searchValue' in options ? options.searchValue : filterText;

    const filterQuery: AdminListQuery = {
      currentPage: options.currentPage || currentPage,
      perPage: options.perPage || perPage,

      onBoardedStatus: filterStatus,
      systems: filterSystem,
      accessGroup: filterAccess,
      searchKey: filterSearchKey,
      searchValue: filterSearchValue,
    };
    if (!filterSearchValue) {
      delete filterQuery['searchKey'];
      delete filterQuery['searchValue'];
    }

    const compareObj = {
      onBoardedStatus: filterStatus,
      systems: filterSystem,
      accessGroup: filterAccess,
      searchKey: filterQuery['searchKey'],
      searchValue: filterQuery['searchValue'],
    };
    if (prevSearchObj !== '' && JSON.stringify(compareObj) !== prevSearchObj) {
      filterQuery.currentPage = 1;
    }
    setPrevSearchObj(JSON.stringify(compareObj));

    return filterQuery;
  };

  const onCreateAdmin = () => {
    if (isCreateAllowed) {
      navigate('/admin/create');
    }
  };

  const AccessList = useMemo(() => {
    const list =
      accessGroupList?.map(item => ({
        display: item.name,
        value: item.code,
      })) || [];

    if (list.length) {
      list.push({
        display: 'Primary Admin',
        value: 'pa_all',
      });
    }

    return list;
  }, [accessGroupList]);

  useEffect(() => {
    if (success) {
      dispatch(getAdminList(getFilterObj({ currentPage: 1, perPage: 10 })));
    }
  }, [success]);

  const handleExport = async () => {
    try {
      setLoader(true);
      dispatch(
        exportUserList({
          currentPage: currentPage,
          perPage: perPage,
          system,
          onBoardedStatus: status,
          accessGroup: accountType,
        }),
      );

      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit,
        }),
      );
      dispatch(getAllReadNotifications({ page: 1, limit }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Download in Progress',
          subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
          btnText: 'Close',
          onConfirm: () => {
            dispatch(toggleBoolean());
            dispatch(SuccessErrorModalReset());
          },
        }),
      );
    } catch (error) {
      console.error('Error:', error);
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    } finally {
      setLoader(false);
    }
  };

  return (
    <Loader loading={loading} overly>
      <>
        <Title title="Admin" subTitle="View and create admin account" removeBorder />
        <Grid container gap={2} justifyContent="end">
          <SbButton
            className="ml-1"
            variant="contained"
            sx={{ borderRadius: '8px !important', padding: '0 20px !important' }}
            onClick={onCreateAdmin}
            disabled={!isCreateAllowed}
          >
            Create Admin
          </SbButton>
          <ExportBtn
            iconSrc="/images/file_download.svg"
            width={25}
            alt="Export"
            label="Export"
            onClick={() => {
              handleExport();
            }}
          />
          <Loader loading={loader} overly />
        </Grid>
        <TableActionContainer className="scroll-h-1">
          <>
            <FilterSearch
              onSelectChange={(value: string) => {
                setFilterSelectValue(value);
              }}
              list={SearchFilterList}
              selectValue={filterSelectValue}
              textValue={filterText}
              onTextChange={e => {
                setFilterText(e.target.value);
              }}
              onTextSearch={() => {
                dispatch(getAdminList(getFilterObj({})));
              }}
              onTextClear={() => {
                setFilterText('');
                dispatch(getAdminList(getFilterObj({ searchValue: undefined })));
              }}
            />
            <div></div>
            <FilterSelect
              id="system"
              label="System"
              // options={activeSystemList}
              options={loggedInUserSystemDropdownList}
              value={system}
              onChange={onSystemFilter}
            />
            <FilterSelect id="access" label="Access" options={AccessList} value={access} onChange={onAccessFilter} />
            <FilterSelect
              id="status"
              label="Status"
              options={AdminStatusList}
              value={status}
              onChange={onStatusFilter}
            />

            <SbButton
              className="xs-text ml-1"
              variant="outlined"
              sx={{ borderRadius: '8px !important', padding: '8px !important' }}
              onClick={() => {
                setSystem([]);
                setAccess([]);
                setStatus([]);
                setFilterText('');
                dispatch(
                  getAdminList(getFilterObj({ status: [], searchValue: '', access: [], system: [], currentPage: 1 })),
                );
              }}
            >
              Clear Filters
            </SbButton>
          </>
        </TableActionContainer>
        <TableContainer id="fadedScroll">
          <AdminAccountTable />
        </TableContainer>
        {!!totalCount && !!perPage && (
          <>
            <TablePagination
              component="div"
              count={totalCount}
              page={currentPage}
              rowsPerPage={perPage}
              onPageChange={(_, page) => {
                dispatch(getAdminList(getFilterObj({ currentPage: page + 1 })));
              }}
              onRowsPerPageChange={event => {
                dispatch(getAdminList(getFilterObj({ perPage: parseInt(event.target.value) })));
              }}
            />
          </>
        )}
      </>
    </Loader>
  );
}
