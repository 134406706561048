import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ButtonPropsType, LoadingButtonPropsType } from './Type';

export function SbLoadingButton(props: LoadingButtonPropsType): JSX.Element {
  const { sx, fullWidth, variant, color, className = '', loading, onClick, startIcon, endIcon, disabled } = props;
  const btnClassName = `py-1 px-2 normal-case rounded-full ${className}`;
  return (
    <LoadingButton
      fullWidth={fullWidth}
      className={btnClassName}
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      loading={loading}
      disabled={disabled}
      sx={{ height: '44px', fontSize: '16px', ...sx }}
    >
      {props.children}
    </LoadingButton>
  );
}

export function SbButton(props: ButtonPropsType): JSX.Element {
  const {
    sx,
    fullWidth,
    variant,
    color,
    className = '',
    containerClass = '',
    onClick,
    startIcon,
    endIcon,
    style,
    type,
    loading = false,
  } = props;
  const { disabled } = props;
  // rounded-full
  const btnClassName = `py-2 px-3 normal-case rounded-full ${className}`;
  return (
    <Button
      fullWidth={fullWidth}
      className={containerClass || btnClassName}
      style={style}
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{ height: '44px', fontSize: '16px', ...sx }}
      disabled={!!disabled}
      type={type || 'button'}
    >
      {loading ? <CircularProgress size={24} /> : props.children}
      {/* {props.children} */}
    </Button>
  );
}

export default SbButton;
