import React, { useEffect, useState } from 'react';
import CreateSysForm, { initCreateWarehouse } from '../Create/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '../../../store';
import { resetSingleWarehouse } from '../../../store/reducers/Warehouse';

import { getWarehouseById, updateWarehouseById } from '../../../store/reducers/Warehouse/actions';
import { useFormik } from 'formik';
import { CreateWareHouseSchema } from '../Create/schema';
import { WarehouseType } from '../Create/type';
import { Grid } from '@mui/material';
import SbButton from '../../../components/Button';

function EditWarehouse() {
  const { id: warehouseId } = useParams() as { id: string };
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const _warehouseState = useSelector((state: RootStateType) => state.Warehouse);
  const [warehouse, setWarehouse] = useState<WarehouseType>({ ...initCreateWarehouse });
  const organizationCountry = useSelector((state: RootStateType) => state.global.organizationCountry);

  useEffect(() => {
    if (warehouseId) dispatch(getWarehouseById({ id: warehouseId, navigate: '/warehouse' }));
    return () => {
      dispatch(resetSingleWarehouse());
    };
  }, []);

  useEffect(() => {
    if (_warehouseState.singeWarehouse) {
      // formik.se
      const [{ _id: systemId }] = _warehouseState.singeWarehouse._systemId || [{}];
      const [countryCode = '', number = ''] = _warehouseState.singeWarehouse.coAttention.contact?.split(' ') || [];
      setWarehouse({
        name: _warehouseState.singeWarehouse.name,
        attendanceName: _warehouseState.singeWarehouse.coAttention.name,
        email: _warehouseState.singeWarehouse.coAttention.email || '',
        countryCode,
        number,
        addressLine1: _warehouseState.singeWarehouse._addressId.line1,
        addressLine2: _warehouseState.singeWarehouse._addressId.line2,
        city: _warehouseState.singeWarehouse._addressId.city,
        state: _warehouseState.singeWarehouse._addressId.state,
        country: _warehouseState.singeWarehouse._addressId.country,
        pincode: _warehouseState.singeWarehouse._addressId.pincode,
        systemId: systemId,
      });
    }
  }, [_warehouseState.singeWarehouse]);

  const onUpdate = (params: WarehouseType) => {
    const payload = {
      name: params.name,
      _systemId: [params.systemId],
      coAttention: {
        name: params.attendanceName,
        contact: `${params.countryCode} ${params.number}`,
        email: params.email,
      },
      address: {
        line1: params.addressLine1,
        line2: params.addressLine2,
        city: params.city,
        state: params.state,
        country: organizationCountry._id,
        pincode: params.pincode,
      },
    };
    dispatch(updateWarehouseById({ id: warehouseId, payload, showModal: true }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...warehouse },
    validationSchema: CreateWareHouseSchema,
    onSubmit(values) {
      onUpdate(values);
    },
  });

  return (
    <>
      <CreateSysForm
        showBackgroundColor={false}
        formType="edit"
        errors={formik.errors}
        values={formik.values}
        handleChange={formik.handleChange}
        setFieldValue={formik.setFieldValue}
      />
      <Grid container item mt={4} spacing={2} justifyContent="flex-end">
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          <SbButton
            fullWidth
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </SbButton>
        </Grid>
        <Grid item xs={6} sm={3} md={2} lg={1.5} xl={1}>
          <SbButton
            fullWidth
            variant="contained"
            onClick={_event => {
              formik.handleSubmit();
            }}
          >
            Save
          </SbButton>
        </Grid>
      </Grid>
    </>
  );
}

export default EditWarehouse;
