import React, { useEffect, useState } from 'react';
import Actions from '../Actions';
import OrganizationSetupContext from '../../../context/OrganizationSetup';
import { OrganizationSetupContextType } from '../../../types/organization';
import CreateWarehouseForm, { initCreateWarehouse } from '../../Warehouse/Create/Form';
import { OutletBox } from '../Style';
import { useDispatch, useSelector } from 'react-redux';
import { WarehouseType } from '../../Warehouse/Create/type';
import { AppDispatch, RootStateType } from '../../../store';
import {
  createWarehouse,
  resetWarehouseRedirect,
  getWarehouseList,
  updateWarehouseById,
} from '../../../store/reducers/Warehouse';
import { useFormik } from 'formik';

import { CreateWareHouseSchema } from '../../Warehouse/Create/schema';
import Loader from '@Components/Loader';

function OrgWarehouseCreate() {
  const dispatch = useDispatch<AppDispatch>();

  const [warehouse, setWarehouse] = useState<WarehouseType>({ ...initCreateWarehouse });
  const WarehouseState = useSelector((state: RootStateType) => state.Warehouse);
  const organizationCountry = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);
  const [activeFirstWarehouse] = WarehouseState?.data?.list || [];
  useEffect(() => {
    if (WarehouseState.isRedirect) {
      handleNext(activeStep);
      dispatch(resetWarehouseRedirect());
    }
  }, [WarehouseState.isRedirect]);

  useEffect(() => {
    if (activeFirstWarehouse) {
      const [countryCode, number] = activeFirstWarehouse.coAttention.contact.split(' ');
      setWarehouse({
        name: activeFirstWarehouse.name,
        attendanceName: activeFirstWarehouse.coAttention.name,
        email: activeFirstWarehouse.coAttention.email,
        countryCode: countryCode,
        number: number,
        addressLine1: activeFirstWarehouse._addressId.line1,
        addressLine2: activeFirstWarehouse._addressId.line2,
        city: activeFirstWarehouse._addressId.city,
        state: activeFirstWarehouse._addressId.state,
        country: activeFirstWarehouse._addressId.country,
        // TODO; REMOVED TO
        pincode: activeFirstWarehouse._addressId.pincode,
        systemId: activeFirstWarehouse._systemId?.[0]?._id || '',
      });
    }
  }, [activeFirstWarehouse]);

  useEffect(() => {
    dispatch(getWarehouseList({ currentPage: 0, perPage: 1 }));
  }, []);

  const onCreate = (params: WarehouseType) => {
    const payload = {
      name: params.name,
      _systemId: [params.systemId],
      coAttention: {
        name: params.attendanceName,
        contact: `${params.countryCode} ${params.number}`,
        email: params.email,
      },
      address: {
        line1: params.addressLine1,
        line2: params.addressLine2,
        city: params.city,
        state: params.state,
        country: organizationCountry?._id || '',
        pincode: params.pincode,
      },
    };
    if (activeFirstWarehouse) {
      dispatch(updateWarehouseById({ id: activeFirstWarehouse._id, payload })).then(response => {
        if (response.meta.requestStatus === 'fulfilled') handleNext(activeStep);
      });
    } else {
      dispatch(createWarehouse({ payload })).then(response => {
        if (response.meta.requestStatus === 'fulfilled') handleNext(activeStep);
      });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { ...warehouse },
    validationSchema: CreateWareHouseSchema,
    onSubmit(values) {
      onCreate(values);
    },
  });
  const { handleSubmit, handleChange, setFieldValue, values: formkikValues, errors } = formik;

  const { activeStep, handleNext, handleBack } = React.useContext(
    OrganizationSetupContext,
  ) as OrganizationSetupContextType;
  return (
    <Loader loading={WarehouseState.loading} overly>
      <>
        <OutletBox className="border-grey rounded-md my-3">
          <CreateWarehouseForm
            showBackgroundColor={false}
            errors={errors}
            values={formkikValues}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </OutletBox>
        <Actions
          onNext={() => handleSubmit()}
          onBack={() => handleBack(activeStep)}
          disableNext={false}
          viewFor={'org-setup'}
        />
      </>
    </Loader>
  );
}

export default OrgWarehouseCreate;
