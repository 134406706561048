import { Grid, styled, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { TableRowCell } from '@Components/Table/TableCell';
import Image from '@Components/Image';
import DeleteModal from '@Components/Modal/Delete';
import moment from 'moment';
import SbButton from '@Components/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { pageType, RateObj, RateState } from '@Reducers/RateSummary/Type';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { ServiceAndCarrierState, ServiceObj } from '@Reducers/CarrierAndService/Type';
import API from '../../services/apiAxios';
import { useNavigate } from 'react-router-dom';
import { SystemState } from '@Reducers/Systems/Type';
import { deleteRates } from '@Reducers/RateSummary';
import { getDeleteParams, getEditURI } from './helper';
import { isActionAllowed } from '../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

const ActionRow = styled(TableRowCell)(() => ({
  width: '1%',
  whiteSpace: 'nowrap',
}));

function useSystemCspHashById() {
  const cspObj = useSelector<RootStateType>(
    state => state.carrierAndService.serviceListHashById,
  ) as ServiceAndCarrierState['serviceListHashById'];

  const systemObj = useSelector<RootStateType>(
    state => state.System.activeSystemListHashById,
  ) as SystemState['activeSystemListHashById'];

  return [cspObj, systemObj];
}

interface RateRowProps {
  rate: RateObj;
  pagetype: pageType;
}

function RateRow({ rate, pagetype }: RateRowProps) {
  return (
    <TableRow>
      <TableRowCell>
        <RateRowService cspId={rate.csp} systemId={rate.system} />
      </TableRowCell>
      <TableRowCell className="md-text text-center">{rate.documentRates ? 'Document' : 'Parcel'}</TableRowCell>
      <TableRowCell>
        <RateData rate={rate} />
      </TableRowCell>
      <TableRowCell className="md-text text-center ">{moment(rate.endDate).format('DD/MM/YYYY')}</TableRowCell>
      <ActionRow>
        <Actions
          pagetype={pagetype}
          rate={rate}
          key={rate.csp + rate.system + rate.documentRates + rate.startDate + rate.endDate}
        />
      </ActionRow>
    </TableRow>
  );
}

interface RateRowServiceProps {
  cspId: string;
  systemId?: string;
}

function RateRowService({ cspId, systemId }: RateRowServiceProps) {
  const [cspHashByid, systemHashById] = useSystemCspHashById();
  const serviceObj = cspHashByid?.[cspId] as ServiceObj;
  return (
    <Grid container alignItems="center" display="grid" gridTemplateColumns="100px 1fr" gap={1}>
      {/* <Grid item className="rounded mr-2 border-1px border-light border-solid" style={{ minWidth: '83px' }}>
        <Image width={83} height={59} src={serviceObj?.logo} alt="" style={{ objectFit: 'contain' }} />
      </Grid> */}
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        p={0.5}
        className="border border-1px rounded border-light bg-white"
      >
        <Image src={serviceObj?.logo} alt={serviceObj?.name} width={83} height={59} style={{ objectFit: 'contain' }} />
      </Grid>
      <Grid
        item
        container
        className="md-text "
        alignItems={'flex-start'}
        flexDirection={'column'}
        justifyContent={'center'}
        style={{ maxWidth: 'fit-content', lineHeight: '1.5' }}
      >
        <Grid item>{serviceObj?.name}</Grid>
        {systemId && (
          <Grid className="sm-text font-light" item>
            {systemHashById?.[systemId]?.name}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

interface RateDataProps {
  rate: RateObj;
}

const RateData = React.memo(({ rate }: RateDataProps) => {
  if ('data' in rate)
    return (
      <section className="px-2 py-1 md-text cursor-pointer rounded text-center bg-p-light">
        {rate?.data[0]?.percentage}%
      </section>
    );

  const RateConfig = useSelector<RootStateType>(state => state.RateSummary.config) as RateState['config'];
  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isViewRateSheetAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Base_Pricing.view_rate_sheet,
    PERFORM_ACTION.write,
  );

  const SheetText = rate?.isMarkup ? 'Zone-wise' : 'Rate Sheet';

  const onDownload = async () => {
    const rateParams = {
      accountNumber: RateConfig.pageType === 'base' ? null : RateConfig.selectedAccountNumber,
      documentRates: rate.documentRates,
      startDate: rate.startDate,
      endDate: rate.endDate,
      csp: rate.csp,
      system: rate.system,
      isImport: RateConfig.isImport,
    };
    const accountNumber = RateConfig.pageType === 'base' ? null : RateConfig.selectedAccountNumber || 'default';
    const searchParams = new URLSearchParams();
    searchParams.append('sheet', encodeURIComponent(JSON.stringify(rateParams)));
    const searchParamsStr = '?' + searchParams.toString();
    const result = await API.get('/shipment/download/rate/' + accountNumber + searchParamsStr);
    console.log({ rateParams });
    // downloadURI.searchParams.append('sheet', JSON.stringify(rateParams));
    window.open(result.data);
  };
  return (
    <section
      onClick={() => {
        if (isViewRateSheetAllowed) {
          onDownload();
        }
      }}
      className="px-2 py-1 md-text underline cursor-pointer rounded text-center bg-p-light"
    >
      {SheetText}
    </section>
  );
});

function Actions({ rate, pagetype }: RateRowProps) {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const pageTypeActionsMap = {
    base: {
      edit_pricing: ACTION_NAMES.Base_Pricing.edit_pricing,
      delete_pricing: ACTION_NAMES.Base_Pricing.delete_pricing,
    },
    tariff: {
      edit_pricing: ACTION_NAMES.Tariff_Pricing.edit_pricing,
      delete_pricing: ACTION_NAMES.Tariff_Pricing.delete_pricing,
    },
    contract: {
      edit_pricing: ACTION_NAMES.Contract_Pricing.edit_pricing,
      delete_pricing: ACTION_NAMES.Contract_Pricing.delete_pricing,
    },
  };

  const isEditAllowed = isActionAllowed(
    accessGroupById?.actions,
    pageTypeActionsMap[pagetype]?.edit_pricing,
    PERFORM_ACTION.write,
  );

  const isDeleteAllowed = isActionAllowed(
    accessGroupById?.actions,
    // ACTION_NAMES.Base_Pricing.delete_pricing,
    pageTypeActionsMap[pagetype]?.delete_pricing,
    PERFORM_ACTION.write,
  );
  const RateConfig = useSelector<RootStateType>(state => state.RateSummary.config) as RateState['config'];

  const [cspObj, systemObj] = useSystemCspHashById();
  const navigate = useNavigate();

  const onEdit = () => {
    const redirectURI = getEditURI(rate, RateConfig);
    // console.info('rate:', rate);
    navigate(redirectURI.pathname + redirectURI.search);
  };

  const onDeleteRequest = () => setShowModal(true);
  const onCloseModal = () => setShowModal(false);

  const onDelete = () => {
    const deleteParams = getDeleteParams(rate, RateConfig);
    dispatch(deleteRates(deleteParams));
  };

  return (
    <>
      <section className="px-2 py-1 w-full md-text underline cursor-pointer rounded text-center bg-p-light">
        {isEditAllowed && (
          <SbButton onClick={onEdit}>
            <EditIcon />
          </SbButton>
        )}
        {isDeleteAllowed && (
          <SbButton className="ml-2" onClick={onDeleteRequest}>
            <DeleteIcon />
          </SbButton>
        )}
      </section>
      {showModal && (
        <DeleteModal
          title={'Delete Rates for ' + cspObj[rate.csp]?.name + ', ' + systemObj[rate.system]?.name}
          open={true}
          onClose={onCloseModal}
          onConfirm={onDelete}
        />
      )}
    </>
  );
}

export { RateRow, RateRowService };
