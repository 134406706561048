import { ChangeEvent } from 'react';
import { WorkBook, WorkSheet, utils as XLSXUtils, read as XLSXRead } from 'xlsx';
import { CountryList, PriceList, PriceListMetadata, ZoneCharges, ZoneChargeType, Zone, rateApplyFor } from './Type';
import { MAX_ZONEWISE_WT } from '@Constants/Shipment';
import { globalStateType } from '@Reducers/Global/Type';

export async function transformExcelToRateObj(
  fileEvent: ChangeEvent<HTMLInputElement>,
  rateApplyFor: rateApplyFor | undefined,
  alternateCountryNameObj: globalStateType['alternateCountryNameObj'],
  maxWeight?: number | null,
) {
  const workbook = await getXLXWorkbook(fileEvent);

  const { zoneData: zone, zoneChargeObj } = await zoneUpload(workbook, alternateCountryNameObj);

  const { rateData: rate, rateHeaderSequence } = await rateUpload(workbook, rateApplyFor, maxWeight);

  console.log({ rate, zone });

  if (zone && rate) {
    const combinedData = combindRateWithZone(rate, zone, zoneChargeObj);
    console.log({ combinedData });
    if (checkedAllZones(combinedData, rateHeaderSequence, rateApplyFor)) {
      return { zone: combinedData };
    }
  }
}

function checkedAllZones(combinedData: Zone[], rateHeaderSequence: string[], rateApplyFor: rateApplyFor | undefined) {
  const zoneNotFound: string[] = [];
  let emptyZoneWiseList: string[] = [];
  rateHeaderSequence &&
    rateHeaderSequence.forEach((item, index) => {
      const zoneData = combinedData.find(x => x.zoneName === item.trim());
      // console.log({ zoneData });
      if (!zoneData) return zoneNotFound.push(item.trim());
      // if (!zoneData.priceList.length) emptyZoneWiseList.push(zoneData.zoneName);
      zoneData.ratePosition = index;
    });
  if (rateApplyFor === 'zone-wise') {
    emptyZoneWiseList = combinedData.filter(x => !rateHeaderSequence.includes(x.zoneName)).map(x => x.zoneName);
    if (emptyZoneWiseList.length) throw { errorMsg: `No markup found for Zones ${emptyZoneWiseList.join(', ')}` };
  }
  if (zoneNotFound.length) throw { errorMsg: `No zone found for Zone Name ${zoneNotFound.join(', ')}` };
  return true;
}

export async function getXLXWorkbook(event: ChangeEvent<HTMLInputElement>) {
  const file = event.target.files?.[0];
  if (!file) throw { errorMsg: 'File now found' };
  const fileName = file.name;
  if (!isXlsFile(fileName)) throw { errorMsg: 'Not a xls file' };
  const workbook = await readWorkBook(file);
  return workbook;
}

export function isXlsFile(fileName: string) {
  return validateFile(fileName, ['xls', 'xlsx']);
}

export function convertExcelToPlainJson(workbook: WorkSheet) {
  return XLSXUtils.sheet_to_json(workbook);
}

export function validateFile(fileName: string, expectedExtension: string[] = []) {
  if (expectedExtension && !Array.isArray(expectedExtension)) {
    expectedExtension = [expectedExtension];
  }
  const fileExtention = fileName.split('.').pop() as string;
  if (fileExtention && expectedExtension.includes(fileExtention.toLowerCase())) return true;
  return false;
}

export function readWorkBook(file: File) {
  return new Promise<WorkBook>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = _event => {
      // let data = event.target.result;
      const data = reader.result;
      const workbook = XLSXRead(data, { type: 'binary' });
      resolve(workbook);
    };
    reader.onerror = error => {
      reject(error);
    };
    return reader.readAsBinaryString(file);
  });
}

async function zoneUpload(workbook: WorkBook, alternateCountryNameObj: globalStateType['alternateCountryNameObj']) {
  // const workbook = await getXLXWorkbook(fileEvent);
  const { zoneDataArray, zoneChargeObj } = await processExcelDataToJson(workbook, alternateCountryNameObj);
  const zoneData = await excelDataToZoneFormat(zoneDataArray);
  const csvInJson = await convertExcelToPlainJson(workbook.Sheets[workbook.SheetNames[0]]);
  return { zoneData, zoneChargeObj, zoneSheetJson: csvInJson };
}

const chargeSheetRegEx = /charge/i;
const zoneSheetRegEx = /zones/i;
const rateSheetRegEx = /rates/i;
// Erro Obj
// {
//  errorMsg: ""
// }

const chargesType = {
  'per kg': 1,
  percent: 1,
  fixed: 1,
};

interface FormattedZoneDataArray {
  courierServiceName: string;
  cityArray: string[];
  stateArray: string[];
  countryArray: string[];
  countryName: string;
  zoneName: string;
  destinationCode: string;
  transitTime: string;
  reputation: string;
}

interface ZoneToChargesMap {
  [zoneName: string]: { [otherCharge: string]: ZoneCharges };
}

export function processExcelDataToJson(
  workbook: WorkBook,
  alternateCountryNameObj: globalStateType['alternateCountryNameObj'],
) {
  // let processedZoneData = new Array();
  const header = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
  //workbook.SheetNames.forEach((sheetName, index) => {
  const sheetName = workbook.SheetNames[0];
  const chargeWorkSheetName = workbook.SheetNames.find(x => x.match(chargeSheetRegEx)) as string;
  const zoneWorkSheetName = workbook.SheetNames.find(x => x.match(zoneSheetRegEx)) as string;
  const workSheet = workbook.Sheets[zoneWorkSheetName];
  const chargeWorkSheetObj = workbook.Sheets[chargeWorkSheetName];

  if (!workSheet) throw { errorMsg: `Zone Sheet not found. Please make sure the zone sheet name is "zones"` };

  const chargeWorkSheetArr = XLSXUtils.sheet_to_json(chargeWorkSheetObj, { header, blankrows: false, defval: '' });

  chargeWorkSheetArr.shift();
  const zoneCharges: ZoneToChargesMap = {};
  chargeWorkSheetArr.forEach(
    (charge: { A: string; B: string; C: string; D: string; E: string; F: string; G: string }, index) => {
      const chargeName = charge.A.toString();
      if (!chargeName) throw { errorMsg: `Charge Name is required (Cell:A${index + 2})` };

      const chargeValueString = charge.B.toString();
      let chargeValue: number;
      if (!chargeValueString)
        throw { errorMsg: `Charge value is required (Charge:${chargeName}, Error Cell:B${index + 2})` };
      if (!Number(chargeValueString))
        throw { errorMsg: `Charge value can only be a number (Charge:${chargeName}, Error Cell:B${index + 2})` };
      else chargeValue = Number(chargeValueString);

      const chargeType = charge.C.toString().toLowerCase() as ZoneChargeType;
      if (!chargeType) throw { errorMsg: `Charge type is required (Charge:${chargeName}, Error Cell::C${index + 2})` };
      if (!(chargeType in chargesType))
        throw {
          errorMsg: `Charge type can only be "per kg", "percent" or "fixed" (Charge:${chargeName}, Error Cell:C${
            index + 2
          })`,
        };

      let min: number | string | null = charge.D.toString();
      if (min) {
        if (Number(min)) min = Number(min);
        else throw { errorMsg: `Min can only be a number (Charge: ${chargeName}, Error Cell:D${index + 2})` };
      } else min = null;

      let max: number | string | null = charge.E.toString();
      if (max) {
        if (Number(max)) max = Number(max);
        else throw { errorMsg: `Max can only be a number (Charge: ${chargeName}, Error Cell:E${index + 2})` };
      } else max = null;

      const chargesApplied = charge.F.toString()
        .split(',')
        .map(x => x.trim())
        .filter(x => x);

      const charge_zones_str: string = charge.G.toString().trim();
      if (!charge_zones_str) throw { errorMsg: `Zone is required (Charge: ${chargeName}, Error Cell:G${index + 2})` };
      const charge_zones: string[] = charge_zones_str.split(',').map(x => x.trim());

      const chargeObj: ZoneCharges = {
        name: chargeName,
        value: chargeValue,
        charge_type: chargeType,
        min,
        max,
        charges_applied: chargesApplied,
      };

      charge_zones.forEach(x => {
        const currZoneCharge = (zoneCharges[x] = zoneCharges[x] || {});
        const formattedChargeName = chargeName.toLowerCase();
        if (currZoneCharge[formattedChargeName])
          throw { errorMsg: `Cannot have duplicate charges (Charge: ${chargeName}, Error Cell:G${index + 2})` };
        currZoneCharge[formattedChargeName] = chargeObj;
      });
    },
  );

  const zoneDataArray = XLSXUtils.sheet_to_json(workSheet, { header, blankrows: false, defval: '' });

  /*** remove Header */
  zoneDataArray.shift();
  /*********** */
  const FormattedZoneDataArray: FormattedZoneDataArray[] = zoneDataArray.map(
    (zone: { A: string; B: string; C: string; D: string; E: string; F: string; G: string }, index) => {
      const cityArray = zone.A.toString().trim().toLowerCase();
      const stateArray = zone.B.toString().trim().toLowerCase();
      const countryArray = zone.C.toString().trim().toLowerCase();
      const destinationCode = zone.D.toString().trim();
      const zoneName = zone.E.toString().trim() || 'N/A';
      const transitTime = zone.F || '';
      const reputation = zone.G || '';

      let zoneCountryName = '';
      for (const country of countryArray.split(',')) {
        const lowerCountry = country.trim();
        zoneCountryName = alternateCountryNameObj[lowerCountry]?.name?.toLowerCase();
        if (zoneCountryName) break;
      }
      if (!zoneCountryName)
        throw { errorMsg: 'No country found with name ' + countryArray + ` (Sheet: Zone, Cell: C${index + 2})` };

      const dataObj = {
        courierServiceName: sheetName,
        cityArray: new Array<string>(),
        stateArray: new Array<string>(),
        countryArray: new Array<string>(),
        countryName: countryArray,
        zoneName,
        destinationCode,
        transitTime,
        reputation,
      };
      dataObj.cityArray = cityArray
        ? cityArray
            .split(',')
            .slice()
            .map(x => x.trim())
        : [''];
      dataObj.stateArray = stateArray
        ? stateArray
            .split(',')
            .slice()
            .map(x => x.trim())
        : [''];
      dataObj.countryArray = [zoneCountryName];
      return { ...dataObj };
    },
  );

  return { zoneDataArray: [FormattedZoneDataArray], zoneChargeObj: zoneCharges };
}

interface ZoneArrayListObj {
  [zoneName: string]: {
    [Zones: string]: Array<CountryList>;
  };
}

export function excelDataToZoneFormat(processedData: FormattedZoneDataArray[][]) {
  return new Promise<ZoneArrayListObj>(resolve => {
    const allZoneArrayList: ZoneArrayListObj = {};
    processedData.forEach(zoneDataArray => {
      zoneDataArray.forEach(element => {
        // const cityOne = element.cityArray[0] ? element.cityArray[0].toString().trim() + ',' : '';
        // const stateOne = element.stateArray[0] ? element.stateArray[0].toString().trim() + ',' : '';
        // const countryOne = element.countryArray[0] ? element.countryArray[0].toString().trim() : '';
        // const countryName = cityOne + stateOne + countryOne;
        const countryListObject = {
          countryName: element.countryName,
          countryCode: element.destinationCode,
          preferredName: new Array<string>(),
          transitionTime: Number(element.transitTime) ? Number(element.transitTime) : 0,
          reputation: Number(element.reputation) ? Number(element.reputation) : 0,
          city: element.cityArray,
          state: element.stateArray,
        };

        if (!allZoneArrayList[element.zoneName]) {
          allZoneArrayList[element.zoneName] = {};
        }

        if (!allZoneArrayList[element.zoneName][element.courierServiceName])
          allZoneArrayList[element.zoneName][element.courierServiceName] = new Array<CountryList>();

        element.cityArray.forEach((city: string) => {
          element.stateArray.forEach((state: string) => {
            element.countryArray.forEach((country: string) => {
              const cityName = city ? city.toString().trim() + ',' : '';
              const stateName = state ? state.toString().trim() + ',' : '';
              const countryName = country ? country.toString().trim() : '';
              const preferredName = cityName + stateName + countryName;
              countryListObject.preferredName.push(preferredName);
            });
          });
        });
        allZoneArrayList[element.zoneName][element.courierServiceName].push(countryListObject);
      });
    });
    resolve(allZoneArrayList);
  });
}

function getNumByRegex(stringData: string, regEx: RegExp) {
  let { value: matches } = stringData.matchAll(regEx).next();
  if (!matches) return null;
  matches = matches.filter((x: string) => Number(x));
  if (matches.length === 0) return null;
  const numMatched = matches[0];
  return Number(numMatched);
}

const addOnRegex = /every\s*(\d+(\.\d+)?)/gi;
const multiplierRegex = /per\s*(\d+(\.\d+)?)/gi;
const flatRegex = /flat/gi;
const weightRegex = /-*(\d*\.?\d*)/g;

interface SpecialParams {
  weight: number;
  value: number;
  disWt: string;
  isAddOn?: boolean;
  isMultiplier?: boolean;
  unit?: number;
  flat?: boolean;
  lastRowObj?: PriceList;
}
interface FormattedRateData {
  [key: string]: PriceList[];
}
export function processRateSheetData(workbook: WorkBook) {
  return new Promise<FormattedRateData>(resolve => {
    const rateWorkSheetName = workbook.SheetNames.find(x => x.match(rateSheetRegEx)) as string;
    const workSheet = workbook.Sheets[rateWorkSheetName];

    if (!workSheet) throw { msg: `Rate Sheet not found. please make sure the rate sheet name is "rates"` };

    const ratesSheetCsvStr = XLSXUtils.sheet_to_csv(workSheet, { blankrows: false });
    const ratesSheetCsv: string[] = ratesSheetCsvStr.split('\n');
    let headers = ratesSheetCsv.shift()?.split(',');
    if (!headers) headers = [];
    const rateHeadersPos: { name: string; value?: string; rows?: SpecialParams[] }[] = [];
    let emptyHeaderPos: boolean | null = null;

    if (!workSheet.A1) {
      headers[0] = 'Weight';
    }

    headers.forEach((header, index) => {
      if (header && emptyHeaderPos !== null) {
        // previous row position is equals to current row index
        throw { errorMsg: `Zone Name in Header cannot be empty Error Cell:A${index}` };
      } else if (!header && emptyHeaderPos === null) {
        emptyHeaderPos = true;
        return;
      }
      if (index === 0) return rateHeadersPos.push({ name: 'weight', value: header });
      return rateHeadersPos.push({
        name: header,
        rows: [],
      });
    });

    let lastWtObj: { weight: number; wtCellVal: string } | null = null;
    ratesSheetCsv.forEach(function (rowString, index) {
      const rowArr = rowString.split(',');

      const wtCellVal = rowArr.shift();
      if (!wtCellVal) return;

      const addOnUnit = getNumByRegex(wtCellVal, addOnRegex);
      const mulUnit = getNumByRegex(wtCellVal, multiplierRegex);
      const flat = wtCellVal.match(flatRegex);

      /****IF MULTIPLIER OR ADDON OR FLAT ROW THEN ADD METADATA TO ALL ZONE ROWS */
      if (addOnUnit || mulUnit || flat) {
        const specialParams: SpecialParams = {
          weight: 0,
          value: 0,
          disWt: '0',
        };
        if (addOnUnit) {
          specialParams.isAddOn = true;
          specialParams.unit = addOnUnit;
        } else if (mulUnit) {
          specialParams.isMultiplier = true;
          specialParams.unit = mulUnit;
        } else if (flat) {
          specialParams.flat = true;
        }
        console.log(rateHeadersPos, 'hjkl');
        rateHeadersPos.forEach((_, index) => {
          if (index === 0) return;
          if (!_.rows) _.rows = [];
          _.rows.push(specialParams);
        });
        return;
      }

      const weight = getNumByRegex(wtCellVal, weightRegex);
      /**INDEX + 2 because this array doesn't include header row */
      if (!weight) throw { errorMsg: `Error weight value found at Cell A${index + 2}` };
      if (weight < 0)
        throw { errorMsg: `Weight cannot be negative (Error Cell: A${index + 2}, Error Value: ${wtCellVal})` };
      /***************************************************************** */

      rowArr.forEach(function (cellVal, index) {
        if (!cellVal) return;
        const rateHeaderIndex = index + 1;
        if (!rateHeadersPos[rateHeaderIndex]) return;

        const rows = rateHeadersPos[rateHeaderIndex].rows;
        const value = Number(cellVal);
        if (!value) {
          throw {
            errorMsg: `Error rate value found for Weight: ${weight}, Zone: ${rateHeadersPos[rateHeaderIndex].name}.
            Please enter only number as rates`,
          };
        }

        const { weight: lastWt = -1, wtCellVal: lastDisWt } = lastWtObj || {};

        if (weight < lastWt)
          throw {
            errorMsg: `Please add rates from minimum to maximum sequence, Rate for Weight ${wtCellVal} cannot appear after Weight ${lastDisWt}`,
          };

        rows?.push({
          weight,
          value,
          disWt: wtCellVal,
        });
      });
      /**STORE LAST WT DETAILS TO COMPARE IF NEXT WT IS LOWER THAN CURRENT WT IF YES THEN THROW ERROR */
      lastWtObj = { weight, wtCellVal };
    });

    const v2Data: FormattedRateData = {};
    for (const { name, rows } of rateHeadersPos) {
      if (name === 'weight') continue;
      const priceList: PriceList[] = [];
      v2Data[name] = priceList;
      let specialParams: SpecialParams[] = [];
      for (let indx = 0; indx < (rows?.length || 0); indx++) {
        const row = rows?.[indx] as SpecialParams;
        if (row.isAddOn || row.isMultiplier) {
          specialParams.push(row);
          if (row.isAddOn) {
            const lastRowObj = priceList[priceList.length - 1];
            row.lastRowObj = lastRowObj;
          }
          continue;
        } else if (row.flat) {
          specialParams = [row];
          continue;
        }
        const { weight, value, disWt } = row;
        if (specialParams.length >= 2) {
          const specialRateType = row.isAddOn ? 'Every Kg' : 'Per Kg';
          throw {
            errorMsg: `You cannot have ${specialRateType} rate without flat rate row, Error Zone: ${name}, Error Weight:${weight}`,
          };
        }

        const { isAddOn, isMultiplier, unit, flat, lastRowObj } = specialParams[0] || {};
        let metadata: PriceListMetadata | undefined = undefined;
        const priceObj: PriceList = {
          weight,
          price: value,
        };

        if (flat) {
          const lastPriceObj = priceList[priceList.length - 1];
          if (lastPriceObj?.metadata?.addOn || lastPriceObj?.metadata?.multiplier) {
            lastPriceObj.weight = weight - 0.001;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          metadata = {} as any;
          if (!metadata) throw { errorMsg: 'Metadata error' };
          metadata.disWt = disWt;
          metadata.flat = true;
          specialParams = [];
        }

        if (isAddOn || isMultiplier) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          metadata = {} as any;
          if (!metadata) throw { errorMsg: 'Metadata error' };
          metadata.disWt = disWt;
          metadata.perUnit = unit as number;
          const lastPriceObj = priceList[priceList.length - 1];
          /**IF LAST RATE IS SPECIAL RATE THEN CURRENT WT SHOULD BE LAST BLOCK WT value
           * 12+ 10
           * 14+ 20
           * FOR 12+ weight row, weight value would be 14 since 10 is to be charged until 14kg
           */
          lastPriceObj.weight = weight;
          // if (lastPriceObj?.metadata?.addOn || lastPriceObj?.metadata?.multiplier) {
          //   lastPriceObj.weight = weight;
          // }
          /**IF LAST ROW IS SPECIAL ROW THEN ASSIGN MAX_SAFE_INTEGER TO THIS ROW WEIGHT AS THERE IS NO NEXT ROW. QUERY WILL FILTER zone.maxWeight if present */
          if (indx === (rows?.length || 0) - 1) priceObj.weight = Number.MAX_SAFE_INTEGER;
        }

        if (isAddOn) {
          if (!metadata) throw { errorMsg: 'Metadata error' };
          metadata.addOn = true;
          metadata.lastWt = lastRowObj?.weight as number;
          metadata.lastWtValue = lastRowObj?.price as number;
        } else if (isMultiplier) {
          if (!metadata) throw { errorMsg: 'Metadata error' };
          metadata.multiplier = true;
        }

        if (metadata) priceObj.metadata = metadata;

        priceList.push(priceObj);
      }
    }

    resolve(v2Data);
  });
}

async function processZoneWiseRateSheetData(workbook: WorkBook, maxWeight?: number | null) {
  const rateHeaderSequence: string[] = [];
  const rateWorkSheetName = workbook.SheetNames.find(x => x.match(rateSheetRegEx)) as string;
  const workSheet = workbook.Sheets[rateWorkSheetName];

  if (!workSheet) throw { msg: `Rate Sheet not found. please make sure the rate sheet name is "rates"` };

  const ratesSheetCsvStr = XLSXUtils.sheet_to_csv(workSheet, { blankrows: false, FS: ';' });
  const ratesSheetCsv: string[] = ratesSheetCsvStr.split('\n');
  const maxWeightRow = maxWeight || MAX_ZONEWISE_WT;
  /**REMOVE HEADERS */
  ratesSheetCsv.shift()?.split(',');
  const resJSON: { [key: string]: PriceList[] } = {};

  ratesSheetCsv.map((rowCsv, index) => {
    const [zone, markup] = rowCsv.split(';');
    zone.split(',').map((z: string) => {
      z = z.trim();
      if (resJSON[z]) throw { errorMsg: `Zone ${z} found with multiple markup, ErrorCell:A${index + 2}` };
      if (isNaN(Number(markup))) throw { errorMsg: `Markup should be a number ErrorCell:B${index + 2}` };
      rateHeaderSequence.push(z);
      resJSON[z] = [
        { weight: 0.001, percent: Number(markup) },
        { weight: maxWeightRow, percent: Number(markup) },
      ];
    });
    if (!zone || !zone.trim())
      throw { errorMsg: `Atleast one zone is required to apply markup on, Error Cell:A${index + 2}` };
  });
  return { rateData: resJSON, rateHeaderSequence };
}

export async function rateUpload(
  workbook: WorkBook,
  rateApplyFor: rateApplyFor | undefined,
  maxWeight?: number | null,
) {
  // const workbook = await getXLXWorkbook(fileEvent);

  const rateWorkSheetName = workbook.SheetNames.find(x => x.match(/rates/i)) as string;
  const workSheet = workbook.Sheets[rateWorkSheetName];
  if (!workSheet) throw { errorMsg: `Rate Sheet not found. please make sure the rate sheet name is "rates"` };

  const csvInJson = await convertExcelToPlainJson(workSheet);
  let rateHeaderSequence: string[] = Object.keys(csvInJson[0] as { [k: string]: object });
  if (workSheet.A1) {
    const weightColumnKey = workSheet.A1.v;
    const weightIndex = rateHeaderSequence.findIndex(head => head?.trim?.() === weightColumnKey);

    if (weightIndex > -1) {
      rateHeaderSequence.splice(weightIndex, 1);
    }
  }

  rateHeaderSequence = rateHeaderSequence.filter(x => x !== '__EMPTY');

  let rateData = {};
  // console.info({ rateApplyFor });
  if (rateApplyFor === 'rate-sheet' || rateApplyFor === 'custom-rate') rateData = await processRateSheetData(workbook);
  else if (rateApplyFor === 'zone-wise') {
    const { rateData: zoneWiseRateData, rateHeaderSequence: zoneWiseRateHeaderSequence } =
      await processZoneWiseRateSheetData(workbook, maxWeight);
    rateData = zoneWiseRateData;
    rateHeaderSequence = zoneWiseRateHeaderSequence;
  }
  // console.info({ rateData });
  return { rateData, rateHeaderSequence, rateSheetJson: csvInJson };
}

export function combindRateWithZone(
  rateData: FormattedRateData,
  zoneData: ZoneArrayListObj,
  zoneCharges: ZoneToChargesMap,
): Zone[] {
  // const _rateKey = Object.keys(rateData)[0];
  return Object.keys(zoneData).map((zoneName, _index) => {
    const zoneKey = Object.keys(zoneData[zoneName])[0];
    let zoneChargeArr: ZoneCharges[] = [];
    if (zoneCharges[zoneName]) {
      const ZoneChargesMap = zoneCharges[zoneName];
      zoneChargeArr = Object.values(ZoneChargesMap);
    }
    return {
      zoneName: zoneName,
      charges: zoneChargeArr,
      priceList: rateData[zoneName],
      countryList: zoneData[zoneName][zoneKey],
    };
  });
}
