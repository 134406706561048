import FilterSelect from '@Components/Filter/Dropdown';
import Image from '@Components/Image';
import { RootStateType, AppDispatch } from '@Store';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { DropdownOptionType } from '@Components/Dropdown/Type';

import API from '@Services/apiAxios';
import { getLoggedInUserSystemList } from '../../store/reducers/Systems';

// import SBDropdown from '@Components/Dropdown';
// import DownloadButton from '@Components/AdminHome/DownlaodButton';
import CustomTabs from '@Components/AdminHome/CustomTabs';
import ExportImportBarChart from './ExportImportBarChart';
import WeightChart from './WeightBarChart';
import ShipmentsChart from './ShipmentsChart';

interface MonthlyData {
  totalWeight: number;
  totalShipment: number;
  exportShipments: number;
  importShipments: number;
  month: string;
  year: string;
}

interface MonthlyStatsResponse {
  totalShipment: number;
  totalWeight: number;
  shipmentPercentage: number;
  weightPercentage: number;
  monthlyData: MonthlyData[];
}

const generateMonthOptions = (): DropdownOptionType[] => {
  const currentMonth = moment().month();
  const currentYear = moment().year();
  const options: DropdownOptionType[] = [];

  for (let i = 0; i <= currentMonth; i++) {
    const date = moment().month(i).year(currentYear);
    options.push({
      value: date.format('MM'),
      display: date.format('MMMM YYYY'),
    });
  }

  return options;
};

const monthOptions = generateMonthOptions();

const UserGeneralOverview = () => {
  const [system, setSystem] = useState<string[]>([]);
  const { loggedInUserSystemDropdownList } = useSelector((state: RootStateType) => state.System);
  const [selectedValues, setSelectedValues] = useState<string[]>([moment().format('MM')]);
  const dispatch = useDispatch<AppDispatch>();
  const [stats, setStats] = useState<MonthlyStatsResponse | null>(null);

  const tabLabels = ['Type', 'Weight', 'Shipment'];

  const getMonthlyStats = async () => {
    try {
      const response = await API.get(`/analytics/shipment/user/monthly`, {
        params: { month: selectedValues, 'system[]': system },
      });
      setStats(response.data.data);
    } catch (error) {
      console.error('Error fetching monthly stats:', error);
      throw error;
    }
  };

  useEffect(() => {
    getMonthlyStats();
  }, [selectedValues, system]);

  useEffect(() => {
    dispatch(getLoggedInUserSystemList());
  }, []);

  const tabContent = {
    Type: (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: 'rgba(31, 30, 97, 1)',
            gap: '17px',
            '@media (max-width: 600px)': {
              gridTemplateColumns: '1fr',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.shipmentPercentage && stats?.shipmentPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.shipmentPercentage && stats?.shipmentPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : `/images/icons/AdminHome/downArrow.svg`
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.shipmentPercentage && stats?.shipmentPercentage > 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                  }}
                >
                  {stats?.shipmentPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.totalShipment}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Shipment</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/weight.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.weightPercentage && stats?.weightPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.weightPercentage && stats?.weightPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color: stats?.weightPercentage && stats?.weightPercentage > 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                  }}
                >
                  {stats?.weightPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.totalWeight}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Weight (KG)</p>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '30px' }}>{stats?.monthlyData && <ExportImportBarChart data={stats?.monthlyData} />}</Box>
      </Box>
    ),
    Weight: (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: 'rgba(31, 30, 97, 1)',
            gap: '17px',
            '@media (max-width: 768px)': {
              gridTemplateColumns: '1fr',
              gap: '10px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.shipmentPercentage && stats?.shipmentPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.shipmentPercentage && stats?.shipmentPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.shipmentPercentage && stats?.shipmentPercentage > 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                  }}
                >
                  {stats?.shipmentPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.totalShipment}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Shipment</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/weight.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.weightPercentage && stats?.weightPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.weightPercentage && stats?.weightPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color: stats?.weightPercentage && stats?.weightPercentage > 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                  }}
                >
                  {stats?.weightPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.totalWeight}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Weight (KG)</p>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '30px' }}>{stats?.monthlyData && <WeightChart data={stats?.monthlyData} />}</Box>
      </Box>
    ),
    Shipment: (
      <Box sx={{ height: '100%', width: '100%' }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3,1fr)',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            color: 'rgba(31, 30, 97, 1)',
            gap: '17px',
            '@media (max-width: 768px)': {
              gridTemplateColumns: '1fr',
              gap: '10px',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/shipment.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.shipmentPercentage && stats?.shipmentPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.shipmentPercentage && stats?.shipmentPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : `/images/icons/AdminHome/downArrow.svg`
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color:
                      stats?.shipmentPercentage && stats?.shipmentPercentage > 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                  }}
                >
                  {stats?.shipmentPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.totalShipment}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Shipment</p>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '14px',
              border: '1px solid #F1F1F1',
              borderRadius: '9px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
              flex: 1,
              padding: '26px 28px',
            }}
          >
            <Image
              style={{ width: '70px', height: '67px', alignSelf: 'end', color: '#1F1E61' }}
              src="/images/icons/AdminHome/weight.png"
              alt="weight"
            />
            <Box>
              <Box
                sx={{
                  borderRadius: '6px',
                  padding: '4px 6px',
                  display: 'flex',
                  // width: '51px',
                  height: '22px',
                  gap: '4px',
                  // backgroundColor: 'rgba(206, 253, 222, 1)',
                  alignItems: 'center',
                }}
                style={{
                  width: 'fit-content',
                  backgroundColor:
                    stats?.weightPercentage && stats?.weightPercentage >= 0 ? 'rgba(206, 253, 222, 1)' : '#FDCECE',
                }}
              >
                <Box>
                  <Image
                    style={{ width: '8px', height: '10px' }}
                    src={
                      stats?.weightPercentage && stats?.weightPercentage >= 0
                        ? '/images/icons/AdminHome/upArrow.svg'
                        : '/images/icons/AdminHome/downArrow.svg'
                    }
                    alt="revenue"
                  />
                </Box>
                <Box
                  sx={{
                    color: stats?.weightPercentage && stats?.weightPercentage > 0 ? 'rgba(3, 160, 28, 1)' : '#A00303',
                  }}
                >
                  {stats?.weightPercentage}%
                </Box>
              </Box>
              <p style={{ font: '500', fontSize: '32px', lineHeight: '48px', color: '#1F1E61' }}>
                {stats?.totalWeight}
              </p>
              <p style={{ font: '500', fontSize: '18px', lineHeight: '30px', color: '#1F1E61' }}>Total Weight (KG)</p>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: '30px' }}>{stats?.monthlyData && <ShipmentsChart data={stats?.monthlyData} />}</Box>
      </Box>
    ),
  };

  const handleChange = (selected: any) => {
    setSelectedValues(selected);
  };

  const onSystemFilter = (selected: Array<string>) => {
    setSystem(selected);
  };
  return (
    <Box
      sx={{
        borderRadius: '9px',
        border: '1px solid #F1F1F1',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 40px',
      }}
    >
      <Box sx={{ color: 'rgba(24, 29, 37, 1)', fontSize: '24px', fontWeight: 600, lineHeight: '33.6px' }}>
        General Overview
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', sm: 'row-reverse' },
            gap: '6px',
            height: 'fit-content',
            position: 'relative',
            top: '45px',
          }}
        >
          {/* <Box>
            <DownloadButton
              name={'Download'}
              onClick={() => {
                console.info('click');
              }}
            />
          </Box> */}
          <Box>
            <FilterSelect
              id="month"
              label="Select month"
              options={monthOptions}
              value={selectedValues}
              onChange={handleChange}
              isSorted={true}
            />
            {/* <SBDropdown
              id="month"
              placeholder="Select month"
              name="IdType"
              value={selectedValues ? [selectedValues] : []}
              options={monthOptions}
              onChange={handleChange}
              isSortingAllowed={false}
            /> */}
          </Box>
          <Box>
            <FilterSelect
              id="system"
              label="Select system"
              options={loggedInUserSystemDropdownList}
              value={system}
              onChange={onSystemFilter}
            />
          </Box>
        </Box>
        <Box sx={{ marginTop: { xs: '70px', sm: '0px' }, marginBottom: { xs: '70px', sm: '0px' } }}>
          <CustomTabs tabs={tabLabels} tabContent={tabContent} />
        </Box>
      </Box>
    </Box>
  );
};

export default UserGeneralOverview;
