import {
  resetSelectedUser,
  setReduxState,
  setSelectedUser,
  setUserQuery,
  toggleListModal,
} from '@Reducers/AccountLookup';
import { UserObj, UserQuery } from '@Reducers/AccountLookup/Type';
import { AppDispatch, RootStateType } from '@Store';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function useAccountLookup(modalId: string, canSetReduxState?: boolean) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { viewListModal, selectedUser, loading, userQuery } = useSelector(
    (state: RootStateType) => state.accountLookup[modalId] || {},
  );

  useEffect(() => {
    if (canSetReduxState) dispatch(setReduxState({ modalId }));
  }, []);

  function handleOpenModal() {
    dispatch(toggleListModal({ modalId: modalId, toggle: true }));
  }
  function handleCloseModal() {
    dispatch(toggleListModal({ modalId: modalId, toggle: false }));
    if (!selectedUser?._id) {
      navigate('/');
    }
  }
  function handleSelectedUser(user: UserObj) {
    dispatch(setSelectedUser([modalId, user]));
  }
  function handleResetSelectedUser() {
    dispatch(resetSelectedUser({ modalId }));
  }
  function handleSetUserQuery(params: UserQuery) {
    dispatch(setUserQuery({ modalId, userQuery: params }));
  }
  return {
    onOpen: handleOpenModal,
    onClose: handleCloseModal,
    setSelectedUser: handleSelectedUser,
    resetSelectedUser: handleResetSelectedUser,
    handleSetUserQuery: handleSetUserQuery,
    open: !!viewListModal,
    selectedUser: selectedUser || null,
    modalId,
    loading: !!loading,
    userQuery: userQuery || {},
  };
}
