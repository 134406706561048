import { PayloadAction } from '@reduxjs/toolkit';
import { UploadRateState, ErrorType, PayloadType, GetSingleRates } from './Type';

function pending(state: UploadRateState) {
  state.loading = true;
}

function reject(state: UploadRateState, action: PayloadAction<ErrorType | undefined>) {
  state.loading = false;
  state.error = {
    isError: true,
    msg: action.payload?.msg,
    collidingMarkup: action.payload?.collidingMarkup,
    collidingZone: action.payload?.collidingMarkup,
  };
}

function rateUploadFulfilled(state: UploadRateState, { payload }: PayloadType) {
  state.loading = false;
  state.rateSuccessfullyUpload = true;
  state.copyErrorList = payload?.copyErrorList || [];
  state.isActiveRate = !!payload?.isActiveRate;
}

function getSingleRateObjFulfilled(state: UploadRateState, { payload }: PayloadType<GetSingleRates>) {
  state.loading = false;
  if (!payload?.data)
    state.error = {
      isError: true,
      msg: 'No Rate found.',
    };
  state.singleRate = payload?.data || null;
}

export { pending, reject, rateUploadFulfilled, getSingleRateObjFulfilled };
