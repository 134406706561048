import React from 'react';

import { Grid } from '@mui/material';

import Divider from '@Components/Divider';
import { FormLabel, FormContent } from '@Components/Form';
import SbTextField from '@Components/Textfield';

import { MarkupUploadType } from '../type';

function MarkupUpload(props: MarkupUploadType) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleChange(parseFloat(event.target.value));
  };
  return (
    <Grid item container xs={12} alignItems="center">
      <Grid item xs={12} md={4} lg={3} mb={{ xs: 1 }}>
        <FormLabel>Markup*</FormLabel>
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <FormContent>
          <SbTextField
            type="number"
            required
            name="Markup"
            value={props.value}
            onChange={handleChange}
            error={props?.error}
            placeholder="Markup Percentage"
          />
        </FormContent>
      </Grid>
      <Divider className="my-2" />
    </Grid>
  );
}

export default MarkupUpload;
