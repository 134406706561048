import AccountLookup from '@Components/AccountLookup';
import SelectedUserAccountDetails from '@Components/AccountLookup/SelectedUserAccountDetails';
import Image from '@Components/Image';
import Title from '@Components/Title';
import { UserObj } from '@Reducers/AccountLookup/Type';
import { Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateShipmentContext from '../../context/CreateShipment';
import CreateShipmentWrapper from '../../context/CreateShipment/Provider';
import { userLogin } from '../../services/hasAdminAccess';
import { AppDispatch, RootStateType } from '../../store';
import { getAllSavedAddress } from '../../store/reducers/SavedAddress/actions';
import { createShipmentContextType } from '../../types/shipment';
// import ShipmentStepper from './ShipmentStepper';
import Loader from '@Components/Loader';
import useAccountLookup from '@Hook/useAccountLookup';
import { getServiceList } from '@Reducers/CarrierAndService';
import { getDraftShipmentById } from '@Reducers/DraftShipment';
import { getShipmentSettings } from '@Reducers/OrganizationSetup';
import { getShipmentById } from '@Reducers/Shipment/actions';
import { getUserById } from '@Reducers/User/actions';
import { resetPincodeServiceability } from '@Reducers/pincode';
import ShipmentStepper from './ShipmentStepper';
import { useLocation } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  boxShadow: 'none',
}));

function CreateShipmentMain() {
  const modalId = 'CreateShipment';
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { selectedUser, onOpen, setSelectedUser } = useAccountLookup(modalId, true);
  // const { selectedUser, setSelectedUser } = useAccountLookup(modalId, true);

  const refId = new URLSearchParams(document.location.search).get('ref');
  const accountId = new URLSearchParams(document.location.search).get('accountId');
  const { userDataById } = useSelector((state: RootStateType) => state.user);
  const { selectedDraftShipmentId: draftId } = useSelector((state: RootStateType) => state.DraftShipment);

  useEffect(() => {
    dispatch(getServiceList({}));
  }, []);

  useEffect(() => {
    if (refId) dispatch(getShipmentById(`${refId}`));
  }, [refId]);

  useEffect(() => {
    if (draftId) {
      dispatch(getDraftShipmentById({ id: draftId }));
    }
  }, [draftId]);

  useEffect(() => {
    if (location?.state?.userId) {
      getSelectedUser(location?.state?.userId);
      setSelectedUser({
        _id: location?.state?.userId?._id,
        email: location?.state?.userId?.email,
        name: location?.state?.userId?.name || '',
        telephoneCode: location?.state?.userId?.telephoneCode,
        _accountId: location?.state?.accountId || null,
        _systems: location?.state?.userId?._systems,
        onBoardedStatus: location?.state?.userId?.onBoardedStatus,
        phoneNumber: location?.state?.userId?.phoneNumber,
      });
    }
  }, [location?.state?.userId]);

  useEffect(() => {
    if (accountId) {
      dispatch(getUserById(accountId));
    }
  }, [accountId]);

  useEffect(() => {
    if (userDataById && userDataById._id === accountId && userDataById.onBoardedStatus) {
      console.log({ userDataById });
      setSelectedUser({
        _id: userDataById._id,
        email: userDataById.email,
        name: userDataById.name || '',
        telephoneCode: userDataById.telephoneCode,
        _accountId: userDataById._accountId || null,
        _systems: userDataById._systems,
        onBoardedStatus: userDataById.onBoardedStatus,
        phoneNumber: userDataById.phoneNumber,
      });
      setUserId(userDataById._id);
    }
  }, [userDataById]);

  const { setUserId, activeStep } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const loggedInUserId = useSelector((state: RootStateType) => state.loginUser.profile?._id);

  function getSelectedUser(user: UserObj) {
    setUserId(user._id);
  }

  const isAdmin = !userLogin();

  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);

  React.useEffect(() => {
    ref.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }, [activeStep]);

  useEffect(() => {
    if (!isAdmin && loggedInUserId) {
      setUserId(loggedInUserId);
    }
  }, [isAdmin, loggedInUserId]);

  // -------- for opening the user modal
  useEffect(() => {
    if (isAdmin && location?.state === null)
      if (!refId) {
        onOpen();
      }
  }, [isAdmin]);

  useEffect(() => {
    dispatch(getAllSavedAddress({ pageType: 'shipment' }));
    dispatch(getShipmentSettings());
    return () => {
      dispatch(resetPincodeServiceability());
    };
  }, []);

  return (
    <>
      {isAdmin && (
        <AccountLookup modalId={modalId} getSelectedUser={getSelectedUser}>
          <Title
            titleIconLeft={<Image src="/images/icons/SingleShipment.svg" alt="contract_pricing" />}
            title="Place Single shipment"
            subTitle="Select the user to place the shipment "
            removeBorder
          />
        </AccountLookup>
      )}
      <Grid ref={ref} container spacing={4}>
        <Grid item justifyContent="center" lg={12} md={12} xs={12}>
          <Item className="rounded">
            <Box className="rounded mx-auto">
              {isAdmin && selectedUser && <SelectedUserAccountDetails modalId={modalId} />}
              <ShipmentStepper modalId={modalId} />
            </Box>
          </Item>
        </Grid>
      </Grid>
    </>
  );
}

function CreateShipment() {
  const orgCountryObj = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj?.currency?.name);
  if (!orgCountryObj) return <Loader loading={true}></Loader>;
  return (
    <CreateShipmentWrapper>
      <CreateShipmentMain />
    </CreateShipmentWrapper>
  );
}

export default CreateShipment;
