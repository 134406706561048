import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import SingleCSP from './SingleCSP';
import { ChargeableWeight, TableHeader } from './styles';
import Actions from './Actions';
import SingleCSPMobile from './SingleCSPMobile';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../../store';
import { HandleBookCSPType } from '../../../store/reducers/GetRates/Type';

interface PropsType {
  weightUnit: string;
  setShowRates?: (show: boolean) => void;
  handleBookCSP: HandleBookCSPType;
}

function CSPList({ /*weightUnit,*/ setShowRates, handleBookCSP }: PropsType) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CSPChargesData = useSelector((state: RootStateType) => state?.getRates?.CSPChargesData);

  return (
    <Grid container item sx={{ padding: { xs: '0 1.5rem', sm: '0 3rem' } }}>
      <Grid container className="mb-1" alignItems="center">
        <Grid item xs={6}>
          <Typography className="org-text">Services Available</Typography>
        </Grid>
        <Grid container item xs={6} justifyContent="end">
          <ChargeableWeight>{`Chargeable Weight: ${CSPChargesData?.chargeableWeight}`}</ChargeableWeight>
        </Grid>
      </Grid>
      <Grid container>
        {!isMobile && (
          <TableHeader container alignItems={'center'} item xs={12}>
            <Grid item className="sm-text" xs={1}></Grid>
            <Grid item className="sm-text" xs={5} sm={6} md={4.3} lg={5.3}>
              Carrier
            </Grid>
            {/* <Grid item xs={2}></Grid> */}
            <Grid item className="sm-text text-center" xs={2} sm={3} md={2.5} lg={1.5}>
              Chargeable Wt
            </Grid>
            <Grid item className="sm-text" xs={2} lg={1.5} textAlign={'center'}>
              Cost
            </Grid>
            <Grid item className="sm-text" xs={2}></Grid>
          </TableHeader>
        )}
        {CSPChargesData?.charges?.map((charge, ind) =>
          isMobile ? (
            <SingleCSPMobile key={ind} CSPCharge={charge} handleBookCSP={handleBookCSP} />
          ) : (
            <SingleCSP key={ind} CSPCharge={charge} handleBookCSP={handleBookCSP} />
          ),
        )}
      </Grid>
      <Actions setShowRates={setShowRates} />
    </Grid>
  );
}

export default CSPList;
