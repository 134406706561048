import { Divider, Grid, IconButton, TableBody, TableFooter, TableHead, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { InfoRounded } from '@mui/icons-material';
import SbTooltip from '../../../../components/Tooltip';
import { SectionSubText, SectionTitle } from '../../Style';
import { OrganizationSetupContextType } from '../../../../types/organization';
import { getFormattedKey } from '../../../../services/getFormattedKey';
import { SBTable, SBTableWrapper } from '../../../../components/Table/Table';
import { TableHeadCell, TableRowCell } from '../../../../components/Table/TableCell';
import { SBTableRow } from '../../../../components/Table/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import SbTextField from '../../../../components/Textfield';
import SbButton from '../../../../components/Button';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
import { isActionAllowed } from '../../../../utils/allowedActions';
import { ACTION_NAMES, PERFORM_ACTION } from '@Constants/actionsNames';

interface TaxInfoType {
  taxName: string;
  percentage: string;
  id?: string;
}
interface PropsType {
  data: OrganizationSetupContextType['taxInformation'];
  onChange: OrganizationSetupContextType['setTaxInformation'];
}

const TABLE_HEADER_COLS = ['Sr. No.', 'Tax name', 'Tax Percentage', 'Action'];

const defaultData = {
  taxName: '',
  percentage: '',
  id: '',
};

function TaxInformation(props: PropsType) {
  const { data: taxInformation, onChange: setTaxInformation } = props;

  const [details, setDetails] = useState<TaxInfoType>(defaultData);

  const { menuAcccessGroupById: accessGroupById } = useSelector((state: RootStateType) => state.accessGroup);

  const isBillingWriteAllowed = isActionAllowed(
    accessGroupById?.actions,
    ACTION_NAMES.Invoice_Settings.billing,
    PERFORM_ACTION.write,
  );

  function handleAddMore() {
    const tempData = [...taxInformation];
    const obj = {
      display: details.taxName,
      key: getFormattedKey(details.taxName),
      value: details.percentage,
    };
    if (details.id && parseInt(details.id) >= 0) {
      tempData[parseInt(details.id)] = obj;
    } else if (!tempData.some(tax => details.taxName === tax.display)) {
      tempData.push(obj);
    }
    setTaxInformation([...tempData]);
    setDetails(defaultData);
  }

  function handleDelete(ind: number) {
    const tempData = [...taxInformation];
    tempData.splice(ind, 1);
    if (details?.id && parseInt(details.id) === ind) {
      setDetails(defaultData);
    }
    setTaxInformation([...tempData]);
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const data = { ...details };
    data[name as keyof TaxInfoType] = value;
    setDetails(data);
  }

  function handleEdit(tax: TaxInfoType) {
    setDetails({ ...tax });
  }

  return (
    <>
      <Grid container className="mb-3">
        <Grid container justifyContent={'start'} alignItems={'center'} className="mb-1">
          <SectionTitle className="mr-2">Tax information</SectionTitle>
          <SbTooltip
            placement="right"
            title={`Select the taxes to be applied on your shipments. You can also customize the Tax Name as per  your requirement.`}
          >
            <InfoRounded />
          </SbTooltip>
        </Grid>
        <SectionSubText width={{ xs: '100%', md: '60%' }}>
          Individual users will be able to upload their KYC from below options. You can select the ones you want to make
          mandatory by clicking the checkbox.
        </SectionSubText>
      </Grid>
      <Grid container item xs={12} md={12} lg={10} className="mb-3">
        <SBTableWrapper>
          <SBTable className="rounded-md">
            <TableHead>
              <TableRow>
                {TABLE_HEADER_COLS.map(x => (
                  <TableHeadCell key={x}>{x}</TableHeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {taxInformation.map(({ display, value }, ind) => (
                <SBTableRow key={ind}>
                  <TableRowCell width={'10%'} align="center" className="md-text font-medium">
                    {ind + 1}
                  </TableRowCell>
                  <TableRowCell width={'35%'} align="left" className="md-text font-medium">
                    {display}
                  </TableRowCell>
                  <TableRowCell width={'35%'} align="center" className="md-text font-medium">
                    {value}
                  </TableRowCell>
                  <TableRowCell align="center">
                    {isBillingWriteAllowed && (
                      <Grid container justifyContent="center">
                        <IconButton onClick={() => handleDelete(ind)}>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleEdit({ taxName: display, percentage: value, id: ind.toString() })}
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </TableRowCell>
                </SBTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <SBTableRow>
                <TableRowCell>
                  <></>
                </TableRowCell>
                <TableRowCell>
                  <SbTextField
                    className="px-2"
                    placeholder="Enter"
                    value={details.taxName}
                    name="taxName"
                    onChange={handleChange}
                    style={{ minWidth: '230px' }}
                    disabled={!isBillingWriteAllowed}
                  />
                </TableRowCell>
                <TableRowCell>
                  <SbTextField
                    className="px-2"
                    type={'number'}
                    placeholder="Enter"
                    value={details.percentage}
                    name="percentage"
                    onChange={handleChange}
                    style={{ minWidth: '230px' }}
                    disabled={!isBillingWriteAllowed}
                  />
                </TableRowCell>
                <TableRowCell align="center">
                  <SbButton
                    variant="contained"
                    disabled={!details.taxName || !details.percentage}
                    onClick={handleAddMore}
                    style={{ minWidth: '130px' }}
                  >
                    {details.id ? 'Update' : 'Add more'}
                  </SbButton>
                </TableRowCell>
              </SBTableRow>
            </TableFooter>
          </SBTable>
        </SBTableWrapper>
      </Grid>
      <Divider sx={{ my: { md: 8, xs: 2 } }} />
    </>
  );
}

export default TaxInformation;
