import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getShipmentByIdFulfilled,
  getShipmentBySNPending,
  getShipmentBySNFulfilled,
  getShipmentBySNRejected,
  placeBulkShipmentFulfilled,
  placeShipmentFulfilled,
  placeShipmentPending,
  placeShipmentRejected,
  savedShipmentPending,
  savedShipmentFulfilled,
  savedShipmentRejected,
  deleteShipmentPending,
  deleteShipmentFulfilled,
  deleteShipmentRejected,
  getShipmentPaymentBySNPending,
  getShipmentPaymentBySNFulfilled,
  getShipmentPaymentBySNRejected,
  getShipmentKycPending,
  getShipmentKycFulfilled,
  getShipmentKycRejected,
  addNewShipmentKycFulfilled,
  deleteShipmentKycFulfilled,
  getUserFedXDetailsFulfilled,
} from './case';
import { AramexInformationType, FedXInformationType, ShipmentStateType } from './Type';
import {
  getShipmentById,
  getShipmentBySN,
  placeBulkShipment,
  placeShipment,
  getSavedShipment,
  deleteSavedShipment,
  getShipmentPaymentBySN,
  getShipmentKyc,
  addNewShipmentKyc,
  deleteShipmentKyc,
  getVendorSettingList,
} from './actions';
import { CSP_TYPES } from '@Constants/Shipment';
// import { initUserDHLInformation } from '../../../pages/ViewSingleShipment/TrackingDetails/UserDHL/constants';
import { DHLInformationType } from '@Reducers/AdminShipment/Type';
// import { initFedXInformation } from '../../../pages/ViewSingleShipmentAdmin/components/ShipmentProcessingV2/components/Fedex/constants';

export const initFedXInformation: FedXInformationType = {
  _id: '',
  csp: CSP_TYPES.fedex.value,
  accountNo: '',
  packagingType: '',
  pickupType: '',
  payor: '',
  printer: '',
  countryOfManufacture: '',
  bookingConfirmationNumber: '',
  shipmentPurpose: '',
  dryIceShipments: [],
  battery: [],
  specialHandlingOptions: [],
  locationId: '',
  thirdPartyPayor: '',
  dangerousGoodsType: '',
  invoiceNo: '',
  billDutiesAndTaxesTo: '',
  billDutiesAccountNo: '',
  poNo: '',
  customerReferenceNo: '',
  csDetails: '',
  serviceType: '',
  shipmentIdType: '',
  shipmentId: '',
  shipmentType: [],
  shipperShipmentType: '',
  broker: {
    accountNo: '',
    shipmentType: '',
    tinNo: '',
    phoneNumber: '',
    telephoneCode: '',
    contactId: '',
    companyName: '',
    name: '',
    title: '',
    faxNo: '',
    email: '',
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  },
  uplodedDocuments: [],
  uplodedImages: [],
  createdAt: '',
  updatedAt: '',
  shipperTinNo: '',
  commercialRoutingNo: '',
  exportComplianceStatement: '',
  isRequiredComplianceStatement: false,
  pickupDate: null,
  warehouseId1: '',
  warehouseId2: '',
  type: 'D-D',
  connectOn: {},
  pickup: {
    location: '',
    _requestedBy: '',
    pickupConfirmationCode: '',
    readyDateTimestamp: null,
  },
  b13AFilingOption: '',
  // harmonizedCode: '',
};

export const initUserDHLInformation: DHLInformationType = {
  _id: '',
  csp: CSP_TYPES.dhlIndia.value,
  accountNo: '530993773',
  countryOfManufacture: '',
  GSTINNumber: '',
  paymentMode: '',
  ShipperType: '',
  type: '',
  GSTINType: '',
  ShipmentType: '',
  dutyPaymentMode: '',
};

export const initUserAramexInformation: AramexInformationType = {
  _id: '',
  csp: CSP_TYPES.aramex.value,
  accountNo: [],
  countryOfManufacture: '',
  paymentType: [],
  productType: [],
  invoiceNumber: '',
  shipperTaxId: '',
  invoiceDate: '',
};

const initialState = <ShipmentStateType>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  redirectToSuccess: false,
  openPaymentModal: false,
  shipmentSummary: null,
  bulkShipmentSummary: null,
  shipmentById: null,
  savedShipmentList: [],
  deletedResult: null,
  editShipment: true,
  shipmentPayment: null,
  shipmentKyc: [],
  isShipmentKycAdded: false,
  isShipmentKycDeleted: false,
  fedExDetails: initFedXInformation,
  dhlDetails: initUserDHLInformation,
  aramexDetails: initUserAramexInformation,
  shipmentConnectOnWarehouse: null,
  editFinalForward: false,
  userFedXInformationDetails: null,
  userDhlInformationDetails: null,
  userAramexInformationDetails: null,
  finalChargeableWeight: 0,
};

const shipmentSlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetShipmentSummary: function (state) {
      state.shipmentSummary = initialState.shipmentSummary;
    },
    redirectToSuccessPage: function (state, actions: PayloadAction<ShipmentStateType['redirectToSuccess']>) {
      state.redirectToSuccess = actions.payload;
    },
    togglePaymentModal: function (state, actions: PayloadAction<ShipmentStateType['openPaymentModal']>) {
      state.openPaymentModal = actions.payload;
    },
    resetShipmentError: function (state) {
      state.error = initialState.error;
    },
    resetEditShipment: function (state) {
      state.editShipment = initialState.editShipment;
    },
    resetShipmentById: function (state) {
      state.shipmentById = initialState.shipmentById;
    },
    resetShipmentKycActions: function (state) {
      state.isShipmentKycAdded = false;
      state.isShipmentKycDeleted = false;
    },
    setFinalChargeableWeight: function (state, actions: PayloadAction<number>) {
      state.finalChargeableWeight = actions.payload;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(placeShipment.pending, placeShipmentPending);
    addCase(placeShipment.fulfilled, placeShipmentFulfilled);
    addCase(placeShipment.rejected, placeShipmentRejected);

    addCase(placeBulkShipment.pending, placeShipmentPending);
    addCase(placeBulkShipment.fulfilled, placeBulkShipmentFulfilled);
    addCase(placeBulkShipment.rejected, placeShipmentRejected);

    addCase(getShipmentById.pending, placeShipmentPending);
    addCase(getShipmentById.fulfilled, getShipmentByIdFulfilled);
    addCase(getShipmentById.rejected, placeShipmentRejected);

    addCase(getSavedShipment.pending, savedShipmentPending);
    addCase(getSavedShipment.fulfilled, savedShipmentFulfilled);
    addCase(getSavedShipment.rejected, savedShipmentRejected);

    addCase(deleteSavedShipment.pending, deleteShipmentPending);
    addCase(deleteSavedShipment.fulfilled, deleteShipmentFulfilled);
    addCase(deleteSavedShipment.rejected, deleteShipmentRejected);

    addCase(getShipmentPaymentBySN.pending, getShipmentPaymentBySNPending);
    addCase(getShipmentPaymentBySN.fulfilled, getShipmentPaymentBySNFulfilled);
    addCase(getShipmentPaymentBySN.rejected, getShipmentPaymentBySNRejected);

    addCase(getShipmentKyc.pending, getShipmentKycPending);
    addCase(getShipmentKyc.fulfilled, getShipmentKycFulfilled);
    addCase(getShipmentKyc.rejected, getShipmentKycRejected);

    addCase(addNewShipmentKyc.pending, getShipmentKycPending);
    addCase(addNewShipmentKyc.fulfilled, addNewShipmentKycFulfilled);
    addCase(addNewShipmentKyc.rejected, getShipmentKycRejected);

    addCase(deleteShipmentKyc.pending, getShipmentKycPending);
    addCase(deleteShipmentKyc.fulfilled, deleteShipmentKycFulfilled);
    addCase(deleteShipmentKyc.rejected, getShipmentKycRejected);

    addCase(getShipmentBySN.pending, getShipmentBySNPending);
    addCase(getShipmentBySN.fulfilled, getShipmentBySNFulfilled);
    addCase(getShipmentBySN.rejected, getShipmentBySNRejected);

    addCase(getVendorSettingList.pending, getShipmentBySNPending);
    addCase(getVendorSettingList.fulfilled, getUserFedXDetailsFulfilled);
    addCase(getVendorSettingList.rejected, getShipmentBySNRejected);
  },
});

export const {
  redirectToSuccessPage,
  resetShipmentError,
  togglePaymentModal,
  resetEditShipment,
  resetShipmentById,
  resetShipmentKycActions,
  setFinalChargeableWeight,
} = shipmentSlice.actions;
export { placeShipment, getSavedShipment, deleteSavedShipment, getShipmentKyc, addNewShipmentKyc, deleteShipmentKyc };
export default shipmentSlice.reducer;
