import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import Title from '../../components/Title';
import { Outlet } from 'react-router-dom';
import AccountLookup from '@Components/AccountLookup';
import Image from '@Components/Image';
import { userLogin } from '../../services/hasAdminAccess';
import SelectedUserAccountDetails from '@Components/AccountLookup/SelectedUserAccountDetails';
import useAccountLookup from '@Hook/useAccountLookup';
import CreateShipmentProvider from '../../context/CreateShipment/Provider';
import CreateShipmentContext from '../../context/CreateShipment';
import { createShipmentContextType } from '../../types/shipment';
import { getShipmentSettings } from '@Reducers/OrganizationSetup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import Loader from '@Components/Loader';

// const SHIPMENT_ROUTES: { [key: string]: string } = {
//   default: '/shipment/create-bulk/sheet',
//   '/create-bulk-shipment': '/shipment/create-bulk/sheet',
//   '/shipment/create-bulk/sheet': '/shipment/create-bulk/sheet',
//   '/shipment/create-bulk/sheet-match-header': '/shipment/create-bulk/sheet-match-header',
// };

function CreateBulkShipment() {
  const dispatch = useDispatch<AppDispatch>();
  // const location = useLocation();
  // const navigation = useNavigate();
  // useEffect(() => {
  //   // navigation('SHIPMENT_ROUTES[location.pathname] || SHIPMENT_ROUTES.default');
  // }, []);

  const { userId, setUserId } = React.useContext(CreateShipmentContext) as createShipmentContextType;

  const modalId = 'createBulkShipment';
  const { selectedUser, onOpen, loading } = useAccountLookup(modalId, true);
  const shipmentSettingLoading = useSelector((state: RootStateType) => state.orgSetup.loading);

  // const isAdmin = hasAdminAccess();
  const isAdmin = !userLogin();
  useEffect(() => {
    if (isAdmin && !userId) {
      onOpen();
    }
  }, [isAdmin, userId]);
  useEffect(() => {
    dispatch(getShipmentSettings());
  }, []);

  return (
    <Loader loading={loading || shipmentSettingLoading} overly>
      <Grid container sx={{ background: '#fff' }}>
        <Grid container item mb={4}>
          {isAdmin && (
            <>
              <AccountLookup modalId={modalId} getSelectedUser={user => setUserId(user._id)}>
                <Title
                  titleIconLeft={<Image src="/images/icons/BulkShipment.svg" alt="bulk_shipment" />}
                  title="Place Bulk shipment"
                  subTitle="Select the user to place the bulk shipment"
                  removeBorder
                />
              </AccountLookup>
              {selectedUser && <SelectedUserAccountDetails modalId={modalId} />}
            </>
          )}
          <Title title="Bulk Shipment" subTitle="Uploaded sheet to book shipment in bulk" />
        </Grid>
        <Outlet />
      </Grid>
    </Loader>
  );
}

export default () => (
  <CreateShipmentProvider>
    <CreateBulkShipment />
  </CreateShipmentProvider>
);
