import SbButton from '@Components/Button';
import moment from 'moment';
import ExportBtn from '@Components/Button/Export';
import DateRangePicker from '@Components/DateRangePicker';
import FilterSearch from '@Components/Filter/Search';
import Title from '@Components/Title';
import { Box, TablePagination } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import SquadReportTable from './SquadReportsTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootStateType } from '@Store';
import { getSquadReports } from '@Reducers/Squad/actions';
import { SquadReportsType } from '@Reducers/Squad/type';
import { fetchSquadReportsProps } from './Types';
import Loader from '@Components/Loader';
import { downloadReport } from '@Reducers/squadReports/squadReportSlice';
import { getAllReadNotifications, getAllUnreadNotifications } from '@Reducers/Notifications/actions';
import { SuccessErrorModalOpen, SuccessErrorModalReset } from '@Reducers/SuccessErrorModal';
import { toggleBoolean } from '@Reducers/booleanSlice/booleanSlice';
const SearchFilterList = [{ label: 'Username', value: 'username' }];

const TableActionContainer = styled('div')(({ theme }) => ({
  margin: '2rem 0',
  display: 'grid',
  gridTemplateColumns: '490px 1fr 220px 110px ',
  columnGap: '15px',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    div: {
      width: 'auto',
    },
  },
}));

const SquadReport = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [filterSelectValue, setFilterSelectValue] = useState('username');
  const [filterText, setFilterText] = useState('');
  const [reportsList, setReportsList] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(3, 'months').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate(),
  });
  const { limit } = useSelector((state: RootStateType) => state.notifications);
  const { totalCount, currentPage, perPage, loading } = useSelector((state: RootStateType) => state.squadManagement);

  const fetchSquadReports = async (props: fetchSquadReportsProps) => {
    const { name, currentPage, perPage, endDate, startDate } = props;
    const queryParams = {
      name,
      page: currentPage,
      pageSize: perPage,
      endDate,
      startDate,
    };
    const result = await dispatch(getSquadReports(queryParams));
    const { data } = result.payload as SquadReportsType;
    setReportsList(data?.data);
  };

  const handleDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }, isOpen: boolean) => {
    if (isOpen) return;
    const dateRange = { startDate, endDate };
    setDateRange(dateRange);
    fetchSquadReports({
      name: filterText,
      currentPage,
      perPage,
      startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
      endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
    });
  };

  const handleClearFilters = () => {
    setFilterText('');
    setDateRange({
      startDate: moment().subtract(3, 'months').startOf('day').toDate(),
      endDate: moment().endOf('day').toDate(),
    });
    fetchSquadReports({
      name: '',
      currentPage: 1,
      perPage,
      startDate: moment().subtract(3, 'months').startOf('day').format('YYYY-MM-DD'),
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
    });
  };
  const handleExport = () => {
    try {
      dispatch(
        downloadReport({
          pageSize: totalCount || 10000,
          page: currentPage,
          name: filterText,
          startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
          endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
        }),
      );
      dispatch(
        getAllUnreadNotifications({
          page: 1,
          limit,
        }),
      );
      dispatch(getAllReadNotifications({ page: 1, limit }));
      dispatch(
        SuccessErrorModalOpen({
          type: 'success',
          title: 'Download in Progress',
          subTitle: 'Your report is being generated. It will be available shortly in the downloads bar',
          btnText: 'Close',
          onConfirm: () => {
            dispatch(toggleBoolean());
            dispatch(SuccessErrorModalReset());
          },
        }),
      );
    } catch (error) {
      dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Something went wrong',
          subTitle: 'please try after sometimes',
        }),
      );
    }
  };

  useEffect(() => {
    fetchSquadReports({
      name: filterText,
      currentPage: 1,
      perPage: 10,
      startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
      endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
    });
  }, []);

  return (
    <>
      <Loader loading={loading} overly />
      <Title
        title="Squad Reports"
        subTitle="View and analyze daily squad tasks"
        removeBorder
        subTextSize="px_20-text"
      />
      <Box className="flex " sx={{ justifyContent: 'flex-end' }}>
        <ExportBtn iconSrc="/images/upload_document.svg" alt="Export Reports" label="Export " onClick={handleExport} />
      </Box>

      <TableActionContainer>
        <FilterSearch
          placeholder="Search here"
          onSelectChange={(value: string) => {
            setFilterSelectValue(value);
          }}
          list={SearchFilterList}
          selectValue={filterSelectValue}
          textValue={filterText}
          onTextChange={e => {
            setFilterText(e.target.value);
          }}
          onTextSearch={() => {
            fetchSquadReports({
              name: filterText,
              currentPage: 1,
              perPage,
              startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
              endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
            });
          }}
          onTextClear={() => {
            setFilterText('');
            fetchSquadReports({
              name: '',
              currentPage: 1,
              perPage,
              startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
              endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
            });
          }}
        />
        <div></div>
        <DateRangePicker onChange={handleDateChange} value={dateRange} />
        <SbButton
          className="xs-text ml-1"
          variant="outlined"
          sx={{ borderRadius: '8px !important', padding: '8px !important' }}
          onClick={handleClearFilters}
        >
          Clear Filters
        </SbButton>
      </TableActionContainer>

      <SquadReportTable reportsList={reportsList} currentPage={currentPage} perPage={perPage} />

      {!!totalCount && !!perPage && (
        <>
          <TablePagination
            component="div"
            count={totalCount}
            page={currentPage - 1}
            rowsPerPage={perPage}
            onPageChange={(_, newPage) =>
              fetchSquadReports({
                name: filterText,
                currentPage: newPage + 1,
                perPage,
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
              })
            }
            onRowsPerPageChange={event => {
              const newPerPage = parseInt(event.target.value, 10);
              fetchSquadReports({
                name: filterText,
                currentPage: 1,
                perPage: newPerPage,
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
              });
            }}
          />
        </>
      )}
    </>
  );
};

export default SquadReport;
