import { FormHelperText, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton, { RadioButtonProps } from '../RadioButton';
import SBDropdown from '../Dropdown';
import DatePicker, { DatePickerProps } from '../DatePicker';
import { PickupTypeList } from '../../pages/CreateShipment/constants';
import { DropdownOptionType, DropdownPropsType } from '../Dropdown/Type';
import dayjs, { Dayjs } from 'dayjs';
import { AppDispatch, RootStateType } from '@Store';
import { useDispatch, useSelector } from 'react-redux';
import SBWarehouseDropdown from '@Components/Dropdown/Warehouse';
import { usePickupDropoffAccess } from '@Hook/usePickupDropoffAccess';
import { checkPincodeServiceabilityForShipment } from '@Reducers/pincode/actions';
import useDebounce from '@Hook/useDebounce';
import { getWarehouseListForShipment } from '@Reducers/Warehouse';
import { PickupTypeListType } from '../../types/shipment';

export interface PickupDropType {
  handleTimeSlotChange: DropdownPropsType['onChange'];
  timeSlot: string;
  handlePickupTypeChange: RadioButtonProps<string>['onChange'];
  pickupType: string;
  handleWarehouseChange: DropdownPropsType['onChange'];
  warehouse?: string;
  handlePickupDateChange: DatePickerProps['handleChange'];
  pickupDate?: Dayjs | null;
  pincode?: string;
  selectedCountry?: string;
  error?: string;
}

function PickupDrop(props: PickupDropType) {
  const dispatch = useDispatch<AppDispatch>();
  const {
    handleTimeSlotChange,
    handlePickupTypeChange,
    handlePickupDateChange,
    handleWarehouseChange,
    selectedCountry,
    error,
  } = props;
  const { disablePickup, disableDropoff } = usePickupDropoffAccess({ shipperCountry: selectedCountry });

  const { shipmentSetting } = useSelector((state: RootStateType) => state?.orgSetup);

  const { pickupTimeSlots } = shipmentSetting || {};

  const { timeSlot, pickupType, pickupDate, warehouse, pincode } = props;
  const [timeSlotsOption, setTimeSlotsOption] = useState<DropdownOptionType[]>([]);

  // useEffect(() => {
  //   if (pickupDate) setTimeSlotsOption(getActiveTimeSlot(pickupDate));
  // }, [pickupDate]);

  // const debouncedSend = useDebounce((pincode: string) => {
  //   if (pincode) dispatch(checkPincodeServiceabilityForShipment({ pincode }));
  // }, 1000);

  // useEffect(() => {
  //   if (pickupType === 'pickUp') debouncedSend(pincode ? pincode : '');
  // }, [pincode, pickupType]);

  const debouncedSend = useDebounce(({ pincode, pickupType }: { pincode: string; pickupType: string }) => {
    if (pincode && pickupType === 'pickUp') dispatch(checkPincodeServiceabilityForShipment({ pincode }));
    if (pickupType !== 'pickUp') dispatch(getWarehouseListForShipment(pincode ? { pincode } : {}));
  }, 1000);

  useEffect(() => {
    // if (pickupType.type === 'pickUp')
    debouncedSend({ pincode: pincode ? pincode : '', pickupType: pickupType });
  }, [pincode, pickupType]);

  useEffect(() => {
    const slots = pickupTimeSlots?.slots;
    if (slots) {
      setTimeSlotsOption(
        slots.map(({ from, to }) => {
          const isToday = dayjs(pickupDate).isSame(dayjs(), 'day');
          const checkPastTime = dayjs(to, 'hh a').isBefore(dayjs(dayjs(), 'hh:mm a'));
          const str = `${from} - ${to}`;
          return { display: str, value: str, disable: isToday && checkPastTime };
        }),
      );
    }
  }, [pickupTimeSlots, pickupDate]);

  const updatedPickupTypeList: PickupTypeListType[] = PickupTypeList.map(item => ({
    ...item,
    disabled: item.value === 'pickUp' ? (pincode ? !!disablePickup : false) : false,
  }));
  return (
    <Grid container item>
      <Grid item xs={12}>
        <Typography className="font-medium md-text">Pickup or Drop-off?</Typography>
      </Grid>
      <Grid item xs={12} ml={1}>
        <RadioButton
          containerClass="w-full"
          // list={PickupTypeList}
          list={updatedPickupTypeList}
          sx={{ display: 'flex', columnGap: 10 }}
          onChange={handlePickupTypeChange}
          value={pickupType}
        />
      </Grid>
      <Grid container item columnSpacing={2} mt={2}>
        {pickupType === 'dropOff' && (
          <Grid item md={6} xs={12}>
            <SBWarehouseDropdown
              value={warehouse || ''}
              onChange={handleWarehouseChange}
              disabled={pincode ? disableDropoff : false}
            />
            {error && <FormHelperText error>{error}</FormHelperText>}
            {/* <SBDropdown
              label="Warehouse list"
              id="bulk-shipment-warehouse-list"
              placeholder="Select warehouse"
              options={[]}
              value={[warehouse || '']}
              onChange={handleWarehouseChange}
            /> */}
          </Grid>
        )}
        {pickupType === 'pickUp' && (
          <>
            <Grid item md={6} xs={6}>
              <DatePicker
                label="Date"
                value={pickupDate}
                handleChange={handlePickupDateChange}
                minDate={dayjs()}
                disabled={pincode ? !!disablePickup : false}
              />
            </Grid>
            {timeSlotsOption?.length ? (
              <Grid item md={6} xs={6}>
                <SBDropdown
                  label={'Time Slot *'}
                  id="timeSlot"
                  name="timeSlot"
                  isSortingAllowed={false}
                  placeholder="Select time slot"
                  value={[timeSlot]}
                  onChange={handleTimeSlotChange}
                  options={timeSlotsOption}
                  disabled={pincode ? !!disablePickup : false}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
              </Grid>
            ) : (
              <></>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

export default PickupDrop;
