import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getBulkOrderSummaryListFulfilled,
  getBulkOrderSummaryListPending,
  getBulkOrderSummaryListRejected,
  // resetShipmentSummary,
} from './case';
import { BulkOrderSummaryState } from './Type';
import { getBulkOrderSummaryList } from './actions';

const initialState = <BulkOrderSummaryState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: {
    list: [],
    totalCount: 0,
    currentPage: 0,
    perPage: 0,
  },
  selectedBulkOrders: {},
};

const shipmentHistorySlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    setSelectedBulkOrderSummary: function (state, action: PayloadAction<BulkOrderSummaryState['selectedBulkOrders']>) {
      const newSelectedBulkOrders = action.payload;
      state.selectedBulkOrders = newSelectedBulkOrders;
    },
    // resetShipmentSummary: resetShipmentSummary,
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getBulkOrderSummaryList.pending, getBulkOrderSummaryListPending);
    addCase(getBulkOrderSummaryList.fulfilled, getBulkOrderSummaryListFulfilled);
    addCase(getBulkOrderSummaryList.rejected, getBulkOrderSummaryListRejected);
  },
});

// const { setUser } = shipmentHistorySlice.actions;

export { getBulkOrderSummaryList };
export const { setSelectedBulkOrderSummary } = shipmentHistorySlice.actions;
export default shipmentHistorySlice.reducer;
