import React from 'react';
import { Chart as ChartJs, ArcElement, Tooltip, Legend } from 'chart.js';

import { Doughnut } from 'react-chartjs-2';
import { Box } from '@mui/material';

ChartJs.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  chartData: {
    datasets: {
      label?: string;
      data: number[];
      backgroundColor: string[];
    }[];
  };
}

const options = {
  // Customize chart options if needed
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({ chartData }) => {
  return (
    <>
      <Box sx={{ width: '5rem' }}>
        <Doughnut data={chartData} options={options} />
      </Box>
    </>
  );
};

export default DoughnutChart;
