import { createSlice } from '@reduxjs/toolkit';
import {
  addMoneyToWalletByAdminFulfilled,
  getAllPaymentGetWayFulfilled,
  getOutstandingBalanceForPrepaidFulfilled,
  getOutstandingBalanceInvoiceForPrepaidFulfilled,
  getWalletBalanceFulfilled,
  getWalletTransactionListFulfilled,
  pending,
  rechargeWalletRazorpayPayloadFulfilled,
  rejected,

  // resetShipmentSummary,
} from './case';
import { WalletState } from './Type';
import {
  addMoneyToWalletByAdmin,
  getAllPaymentGetWay,
  getOutstandingBalanceForPrepaid,
  getOutstandingBalanceInvoiceForPrepaid,
  getUserTransactionList,
  getWalletBalance,
  getWalletTransactionList,
  rechargeWalletRazorpayPayload,
} from './actions';

const initialState = <WalletState>{
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  currentBalance: 0,
  addWalletRazorpay: null,
  paymentGetwayList: null,
  outstandingBalance: 0,
  totalInvoiceAmtPaid: 0,
  totalShipmentAmt: 0,
  totalShipmentAmtPaid: 0,
  totalInvoiceAmt: 0,
  data: {
    list: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 10,
  },
};

const shipmentHistorySlice = createSlice({
  name: 'global',
  initialState: {
    ...initialState,
  },
  reducers: {
    resetWalletRazorpay: (state: WalletState) => {
      state.addWalletRazorpay = null;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getWalletBalance.pending, pending);
    addCase(getWalletBalance.fulfilled, getWalletBalanceFulfilled);
    addCase(getWalletBalance.rejected, rejected);

    addCase(rechargeWalletRazorpayPayload.pending, pending);
    addCase(rechargeWalletRazorpayPayload.fulfilled, rechargeWalletRazorpayPayloadFulfilled);
    addCase(rechargeWalletRazorpayPayload.rejected, rejected);

    addCase(getAllPaymentGetWay.pending, pending);
    addCase(getAllPaymentGetWay.fulfilled, getAllPaymentGetWayFulfilled);
    addCase(getAllPaymentGetWay.rejected, rejected);

    addCase(getWalletTransactionList.pending, pending);
    addCase(getWalletTransactionList.fulfilled, getWalletTransactionListFulfilled);
    addCase(getWalletTransactionList.rejected, rejected);

    addCase(getUserTransactionList.pending, pending);
    addCase(getUserTransactionList.fulfilled, getWalletTransactionListFulfilled);
    addCase(getUserTransactionList.rejected, rejected);

    addCase(getOutstandingBalanceForPrepaid.pending, pending);
    addCase(getOutstandingBalanceForPrepaid.fulfilled, getOutstandingBalanceForPrepaidFulfilled);
    addCase(getOutstandingBalanceForPrepaid.rejected, rejected);

    addCase(getOutstandingBalanceInvoiceForPrepaid.pending, pending);
    addCase(getOutstandingBalanceInvoiceForPrepaid.fulfilled, getOutstandingBalanceInvoiceForPrepaidFulfilled);
    addCase(getOutstandingBalanceInvoiceForPrepaid.rejected, rejected);

    addCase(addMoneyToWalletByAdmin.pending, pending);
    addCase(addMoneyToWalletByAdmin.fulfilled, addMoneyToWalletByAdminFulfilled);
    addCase(addMoneyToWalletByAdmin.rejected, rejected);
  },
});

export { getWalletBalance, rechargeWalletRazorpayPayload, getWalletTransactionList, getOutstandingBalanceForPrepaid };
export const { resetWalletRazorpay } = shipmentHistorySlice.actions;
export default shipmentHistorySlice.reducer;
