import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import {
  updatePincodePayload,
  PincodeState,
  PincodeServiceabilityShipmentQuery,
  PincodeListQuery,
  PincodeZones,
  getPincodeZones,
} from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';

const getPincodeBySystemId = createAsyncThunk(
  'getSystemPincodeById',
  async ({ id, queryParams }: { id: string; queryParams?: PincodeListQuery }) => {
    const res = await API.get(`/organization/pincode/system/${id}`, { params: queryParams });
    return res.data;
  },
);

const updatePincode = createAsyncThunk(
  'updatePincode',
  async (
    { pincode, payload, showModal }: { pincode: string; payload: updatePincodePayload } & SuccessErrorModalParamsType,
    thunkAPI,
  ) => {
    try {
      const res = await API.put(`/organization/pincode/${pincode}`, payload);

      const { pincode: pincodeState } = thunkAPI.getState() as { pincode: PincodeState };
      const systemId = pincodeState?.systemPincode?.system._id;
      if (systemId) thunkAPI.dispatch(getPincodeBySystemId({ id: systemId }));

      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Pincode successfully updated.',
          }),
        );

      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const deletePincode = createAsyncThunk(
  'deletePincode',
  async ({ pincode, showModal }: { pincode: string } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.delete(`/organization/pincode/${pincode}`);

      const { pincode: pincodeState } = thunkAPI.getState() as { pincode: PincodeState };
      const systemId = pincodeState?.systemPincode?.system._id;
      if (systemId) thunkAPI.dispatch(getPincodeBySystemId({ id: systemId }));
      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Pincode successfully deleted.',
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const getPincodeZones = createAsyncThunk<getPincodeZones[], PincodeZones>(
  'AllZone/pincode',
  async (queryParamas: PincodeZones) => {
    const res = await API.get(`/shipment/pincode/getPincodes`, { params: queryParamas });
    return res.data.data;
  },
);

const checkPincodeServiceabilityForShipment = createAsyncThunk(
  'pincode/check-pickup-serviceability',
  async (queryParams?: PincodeServiceabilityShipmentQuery) => {
    const res = await API.get(`/shipment/pincode/check-pickup-serviceability`, { params: queryParams });
    return res.data.data;
  },
);

export { getPincodeBySystemId, updatePincode, deletePincode, checkPincodeServiceabilityForShipment, getPincodeZones };
