import Loader from '@Components/Loader';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { alpha, Grid, styled, Typography } from '@mui/material';
import { redirectToSuccessPage } from '@Reducers/Shipment';
import { downloadBulkProformaInvoice, downloadBulkShippingLabel } from '@Reducers/ShipmentHistory/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SbButton from '../../components/Button';
import DownloadButton from '../../components/Button/Download';
import Warning from '../../components/Warning';
import { AppDispatch, RootStateType } from '../../store';
import DownloadOptions from '../ShipmentHistory/DonloadOptions';
import { FileDownloadOptionPropsType } from '../ShipmentHistory/Types';

const SummaryContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  border: `1px solid ${theme.palette.border.grey}`,
  borderRadius: theme.spacing(1),
  backgroundColor: alpha(theme.palette.primary.main, 0.03),
  padding: theme.spacing(4),
}));

const fileDownloadDefaultOption: FileDownloadOptionPropsType = {
  isOpen: false,
  title: '',
  downloadOption: 'fileFormat',
  handleDownload: () => {
    console.log('handle File Download');
  },
};

function BulkShipmentSuccess() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { bulkShipmentSummary, loading } = useSelector((state: RootStateType) => state.shipment);
  const { warehouseListForShipmentOptionsHashById } = useSelector((state: RootStateType) => state.Warehouse);
  const pickupType = location.state;
  const {
    shipment: { redirectToSuccess },
  } = useSelector((state: RootStateType) => state);
  const [fileDownloadOption, setFileDownloadOption] = useState<FileDownloadOptionPropsType>(fileDownloadDefaultOption);

  function handlePlaceNewOrder() {
    navigate('/shipment/create-bulk/sheet');
  }

  useEffect(() => {
    if (redirectToSuccess) {
      dispatch(redirectToSuccessPage(false));
    } else {
      navigate('/shipment/create-bulk');
    }
  }, []);

  return (
    <Loader loading={loading} overly>
      <>
        <DownloadOptions {...fileDownloadOption} handleClose={() => setFileDownloadOption(fileDownloadDefaultOption)} />
        <Grid container>
          <Grid container item mb={4}>
            <Typography className="l-text font-semibold">Your shipment has been placed!</Typography>
          </Grid>
          <Grid container item mx={2} pb={2.5} borderBottom="1px solid #D0D0D0">
            <Grid container justifyContent={{ xs: 'center', sm: 'flex-start' }}>
              <CheckCircleIcon sx={{ height: 75, width: 75, color: '#32BEA6' }} />
              <Grid item ml={2.5}>
                <Typography className="l-text font-semibold">{`Batch no.: ${
                  bulkShipmentSummary?.batchNumber || ''
                }`}</Typography>
                <Typography className="md-text font-medium" color="#32BEA6">
                  Thank you for shipping with us!
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              mt={4}
              display="grid"
              gridTemplateColumns={{ sm: bulkShipmentSummary?.successfulShipment ? '1fr 200px 200px' : '1fr' }}
              gap={2}
            >
              <Warning message="Please download the shipping label and stick it on the box" />
              {bulkShipmentSummary?.successfulShipmentNumber?.length ? (
                <>
                  <DownloadButton
                    name={'Shipping Label'}
                    onClick={() =>
                      setFileDownloadOption({
                        isOpen: true,
                        downloadOption: 'fileDimention',
                        title: 'Download Shipping Label',
                        handleDownload: fileType =>
                          dispatch(
                            downloadBulkShippingLabel({
                              fileType,
                              shipmentNumber: bulkShipmentSummary.successfulShipmentNumber,
                            }),
                          ),
                      })
                    }
                  />
                  <DownloadButton
                    name={'Proforma Invoice'}
                    onClick={() => {
                      dispatch(
                        downloadBulkProformaInvoice({
                          shipmentNumbers: bulkShipmentSummary.successfulShipmentNumber,
                        }),
                      );
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid container item mx={2} pt={2.5}>
            {pickupType?.type === 'dropOff' && pickupType?.warehouse && warehouseListForShipmentOptionsHashById && (
              <Typography className="md-text font-medium" color="#707070">
                Drop-Off: {warehouseListForShipmentOptionsHashById[pickupType.warehouse].name}
              </Typography>
            )}
            {pickupType?.type === 'pickUp' && pickupType?.date && (
              <Typography className="md-text font-medium" color="#707070">
                Pickup Date: {pickupType.date}
              </Typography>
            )}
          </Grid>
          <SummaryContainer item mx={2} xs={12} sm={5}>
            <Grid container item xs={12} pb={2} borderBottom="1px solid #D0D0D0">
              <Grid item xs={8}>
                <Typography className="l-text font-semibold">Total Chargeable Weight</Typography>
              </Grid>
              <Grid item xs={4} textAlign="end">
                {/* <Typography className="l-text font-semibold">{`${bulkShipmentSummary?.totalChargableWeight} kg`}</Typography> */}
                <Typography className="l-text font-semibold">
                  {bulkShipmentSummary?.totalChargableWeight &&
                    `${parseFloat(bulkShipmentSummary?.totalChargableWeight).toFixed(2)} kg`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={2} spacing={2}>
              <LabelValue label="Total Shipments" value={bulkShipmentSummary?.totalShipment || '0'} />
              <LabelValue label="Shipment Processed" value={bulkShipmentSummary?.successfulShipment || '0'} />
              <LabelValue label="Shipment Unprocessed" value={bulkShipmentSummary?.failedShipment || '0'} />
            </Grid>
          </SummaryContainer>
          <Grid container item mt={4} alignItems="flex-end" spacing={4} justifyContent="center">
            <Grid item xs={6} sm={2} md={1} lg={1} xl={2}>
              <SbButton
                onClick={handlePlaceNewOrder}
                variant="outlined"
                style={{ height: '44px' }}
                sx={{ my: { lg: 0, xs: 3 } }}
              >
                Place New order
              </SbButton>
            </Grid>
            {/* <Grid item xs={12} sm={2} md={2}>
          <SbButton variant="contained" fullWidth className="rounded-full">
            Pay now
          </SbButton>
        </Grid> */}
          </Grid>
        </Grid>
      </>
    </Loader>
  );
}

function LabelValue({ label, value }: { label: string; value: string }) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={8}>
        <Typography className="md-text font-normal" color="#707070">
          {label}
        </Typography>
      </Grid>
      <Grid item xs={4} textAlign="end">
        <Typography className="md-text font-normal" color="#707070">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default BulkShipmentSuccess;
