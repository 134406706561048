import SbButton from '@Components/Button';
import { AppDispatch, RootStateType } from '@Store';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BoltIcon from '@mui/icons-material/Bolt';
import { Grid, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Image from '../../../../components/Image';
import SbTooltip from '../../../../components/Tooltip';
import CreateShipmentContext from '../../../../context/CreateShipment';
import { CSPChargePropsType } from '../../../../store/reducers/GetRates/Type';
import { createShipmentContextType } from '../../../../types/shipment';
import { BackToLoginModal } from '../BackToLoginModal';
import ChargesBreakup from '../ChargesBreakup';
import { BookBtn, CSPLogo, SbChip, TableData, TableRow } from '../styles';
import { setBookNowAuth } from '@Reducers/GetRates';
import { useDispatch } from 'react-redux';
import { setFinalChargeableWeight } from '@Reducers/Shipment';

function SingleCSP(props: CSPChargePropsType) {
  const { CSPCharge, handleBookCSP, renderedFrom, isSelected = false } = props;
  const [openBackToLoginModal, setBackToLoginModal] = useState(false);
  const {
    transitTime,
    serviceName,
    serviceImage,
    totalPricing,
    fastest,
    cheapest,
    pricing,
    taxPricing,
    displayChargeableWeight,
  } = CSPCharge;
  const shipmentContext = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const orgCountryObj = useSelector((state: RootStateType) => state.orgSetup.orgCountryObj);

  const { token } = useSelector((state: RootStateType) => state.loginUser);
  const dispatch = useDispatch<AppDispatch>();
  const currency = useMemo(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];

    if (subdomain !== 'uat-speedbox' && subdomain !== 'localhost' && subdomain !== 'ae') {
      return shipmentContext?.packageData?.currency || orgCountryObj?.currency?.name || '';
    } else {
      return shipmentContext?.packageData?.currency || orgCountryObj?.currency?.name || 'INR';
    }
  }, [shipmentContext, orgCountryObj]);
  const bookCSP = (CSPCharge: CSPChargePropsType['CSPCharge'] | null) => {
    if (token?._id) {
      handleBookCSP(CSPCharge);
      dispatch(setBookNowAuth(true));
    } else {
      setBackToLoginModal(true);
      dispatch(setBookNowAuth(false));
    }
  };

  const handleCloseBackToLoginModal = () => {
    setBackToLoginModal(false);
  };

  return (
    <>
      <TableRow
        container
        item
        alignItems={'center'}
        xs={12}
        spacing={1}
        compactview={renderedFrom === 'admin-shipment-history' ? '1' : '0'}
      >
        <TableData item className="mr-2" xs={1} sm={1}>
          <CSPLogo>
            <img src={serviceImage} height={'100%'} width={'100%'} style={{ borderRadius: '10px' }} />
          </CSPLogo>
        </TableData>
        <TableData item sm={6} md={4} lg={5}>
          <Grid container wrap="nowrap" justifyItems={'start'}>
            <Grid container item className="w-auto" sm={8} md={7} lg={7.5}>
              <Grid item lg={12}>
                <Typography className="md-text font-semibold">{transitTime} Days</Typography>
              </Grid>
              <Grid item lg={12}>
                <Typography className="sm-text">{serviceName}</Typography>
              </Grid>
            </Grid>
            <Grid container item alignItems={'center'} sm={6} md={4.5} lg={4}>
              {fastest && (
                <SbChip
                  className="md-text font-semibold"
                  sx={{
                    color: '#127E44',
                    background: '#ADFFB5',
                    '& .MuiChip-icon': {
                      color: '#127E44',
                    },
                  }}
                  icon={<BoltIcon />}
                  label="Fastest"
                />
              )}
              {cheapest && (
                <SbChip
                  className="md-text font-semibold"
                  sx={{
                    color: '#12647E',
                    background: '#ADE6FF',
                    '& .MuiChip-icon': {
                      color: '#12647E',
                    },
                  }}
                  icon={<AccountBalanceWalletIcon />}
                  label="Cheapest"
                />
              )}
            </Grid>
          </Grid>
        </TableData>
        {/* <TableData item lg={3}></TableData> */}
        <TableData item sm={2} md={2} lg={1.5}>
          <Typography className="font-semibold text-center">{displayChargeableWeight?.toFixed(2)} Kg</Typography>
        </TableData>
        <TableData item sm={2.5} md={2.5} lg={2}>
          <Grid container justifyContent={'center'}>
            <Typography className="md-text font-semibold mr-1 w-full" textAlign="center">
              {totalPricing ? `${currency} ${parseFloat(totalPricing?.toString())?.toFixed(2)}` : ''}
            </Typography>
            <SbTooltip
              title={
                <ChargesBreakup
                  pricing={pricing}
                  totalPricing={totalPricing}
                  taxPricing={taxPricing}
                  currency={currency}
                />
              }
              placement={'left'}
            >
              <Grid
                container
                item
                alignItems={'center'}
                textAlign={'center'}
                justifyContent={'end'}
                style={{ cursor: 'default', width: 'fit-content' }}
              >
                <Image src={'/images/Boxes/Help.svg'} alt="help" style={{ height: '16px', width: '16px' }} />
                <Typography style={{ textDecoration: 'underline' }} className="ml-1/2">
                  View Breakup
                </Typography>
              </Grid>
            </SbTooltip>
          </Grid>
        </TableData>
        <TableData container item justifyContent={'end'} sm={12} md={2.2} lg={2}>
          {isSelected ? (
            <SbButton variant="contained" onClick={() => bookCSP(null)}>
              Selected
            </SbButton>
          ) : (
            <BookBtn
              variant="outlined"
              className="font-semibold"
              onClick={() => {
                bookCSP(CSPCharge);
                const res = displayChargeableWeight?.toFixed(2);
                dispatch(setFinalChargeableWeight(parseFloat(res)));
              }}
              style={{ zIndex: '2' }}
            >
              Book Now
            </BookBtn>
          )}
        </TableData>
      </TableRow>
      <BackToLoginModal open={openBackToLoginModal} onClose={handleCloseBackToLoginModal} />
    </>
  );
}

export default SingleCSP;
