import React from 'react';
import { Tooltip } from '@mui/material';

import Fade from '@mui/material/Fade';
import { TooltipPropsType } from './Type';

export function SbTooltip(props: TooltipPropsType): JSX.Element {
  const { children } = props;
  const { title, open, placement, onClose, onOpen } = props;

  return (
    <Tooltip
      arrow
      placement={placement ? placement : 'bottom-end'}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={title}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      disableHoverListener={false}
      componentsProps={{ tooltip: { sx: { maxWidth: '300px' } } }}
    >
      {children}
    </Tooltip>
  );
}

export default SbTooltip;
