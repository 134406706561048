import * as React from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel, FormHelperText, InputLabel, Radio, SxProps } from '@mui/material';
import { Theme } from '@emotion/react';

export interface RadioButtonProps<T> {
  list: { label: string; value: T; disabled?: boolean }[];
  defaultValue?: T;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  containerClass?: string;
  name?: string;
  value?: T;
  required?: boolean;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  error?: string;
}

const RadioButton = <T,>(props: RadioButtonProps<T>) => {
  // function <T,>RadioButton(props: RadioButtonProps<T>) {
  return (
    <>
      {props.label && (
        <InputLabel className={props.label ? ' mb-1' : ''} required={props.required}>
          {props.label}
        </InputLabel>
      )}
      <FormControl className={props.containerClass}>
        <RadioGroup
          row
          // className="flex sb-flx-row"
          defaultValue={props.defaultValue}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          sx={props.sx}
        >
          {props.list.map(list => (
            <FormControlLabel
              key={list.label}
              label={list.label}
              value={list.value}
              control={<Radio />}
              disabled={props.disabled || list?.disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {props.error && (
        <FormHelperText error className="m-0">
          {props.error}
        </FormHelperText>
      )}
    </>
  );
};

export default RadioButton;
