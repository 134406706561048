import React, { useState } from 'react';
import { Button } from '@mui/material';
import Dropdown from '../components/Dropdown';
// import SearchDropdown from '../components/SearchDropdown';
import Location from '../components/Location';
import TextField from '../components/Textfield';
import { DropdownOptionType } from '../components/Dropdown/Type';
import LocationIcon from '@mui/icons-material/PinDropOutlined';
import RadioButton from '../components/RadioButton';
import { useSnackbar, VariantType } from 'notistack';
import FilterSearch from '../components/Filter/Search';
import DateRangePicker from '../components/DateRangePicker';
import FilterSelect from '../components/Filter/Dropdown';

const names: DropdownOptionType<string>[] = [{ display: 'Oliver Hansen', value: 'Oliver_Hansen', metadata: 'HELLO' }];

function ComponentEx() {
  const [dateRange, setDateRange] = useState({ startDate: new Date(), endDate: new Date() });

  const { enqueueSnackbar } = useSnackbar();
  const handleClickVariant = (variant: VariantType) => () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('This is a success message!', { variant, key: new Date().getTime() });
  };

  const onDateChange = ({ startDate, endDate }: { startDate: Date; endDate: Date }) => {
    setDateRange({ startDate, endDate });
  };

  return (
    <>
      <DateRangePicker onChange={onDateChange} value={dateRange} />
      <Button onClick={handleClickVariant('success')}>Show success snackbar</Button>
      <Dropdown
        id="tag"
        onChange={console.log}
        options={names}
        multiple
        renderValue={() => (
          <>
            Tag <span>25</span>
          </>
        )}
        placeholder="Tag"
      />
      <Location onSelect={console.log} />
      {/* <SearchDropdown id="test" label="TEST" onChange={console.log} options={[]} /> */}
      <TextField
        className="mt-3"
        value=""
        label="Location"
        endAdornment={<LocationIcon sx={{ width: 30, height: 30 }} />}
        error="Something wring"
      />
      <RadioButton list={[]} label="Gender" containerClass="mt-3"></RadioButton>
      <FilterSearch
        onSelectChange={(value: string) => {
          console.log('Filter Search Value', value);
        }}
        list={[{ label: 'Shipment No', value: 'ShipmentNo' }]}
        selectValue={'ShipmentNo'}
        textValue=""
        onTextChange={() => {
          console.log('Text Changed');
        }}
        onTextSearch={() => {
          console.log('Text Searched');
        }}
      />
      <FilterSelectEx />
    </>
  );
}

function FilterSelectEx() {
  const [value, setValue] = useState<Array<string>>([]);
  const [list, _upload_document] = useState([
    { display: 'Placed', value: 'placed' },
    { display: 'In Transit', value: 'in-transit' },
    { display: 'Delivered', value: 'delivered' },
  ]);

  const onSelectChange = (selected: Array<string>) => {
    setValue(selected);
  };

  return (
    <div className="mt-3" style={{ width: 'fit-content' }}>
      <FilterSelect value={value} id="ex" label="Status" options={list} onChange={onSelectChange} />
    </div>
  );
}

export default ComponentEx;
