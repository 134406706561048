import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../../services/apiAxios';
import {
  UnprocessedShipmentCreatePayload,
  UnprocessedShipmentDeletePayload,
  UnprocessedShipmentListQuery,
} from './Type';
import { SuccessErrorModalOpen } from '@Reducers/SuccessErrorModal';
import { SuccessErrorModalParamsType } from '@Reducers/SuccessErrorModal/Type';

const getUnprocessedShipmentList = createAsyncThunk(
  'getUnprocessedShipmentList',
  async (queryParams: UnprocessedShipmentListQuery) => {
    const res = await API.get(`/shipment/unprocessed`, { params: queryParams });
    return res.data;
  },
);

const getUnprocessedShipmentById = createAsyncThunk(
  'getUnprocessedShipmentById',
  async ({ id, navigate }: { id: string } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.get(`/shipment/unprocessed/${id}`);

      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
          navigateTo: navigate || null,
        }),
      );
      throw error;
    }
  },
);

const updateUnprocessedShipmentById = createAsyncThunk(
  'updateUnprocessedShipmentById',
  async (
    {
      id,
      payload,
      showModal,
      navigate,
    }: { id: string; payload: UnprocessedShipmentCreatePayload } & SuccessErrorModalParamsType,
    thunkAPI,
  ) => {
    try {
      const res = await API.put(`/shipment/unprocessed/${id}`, payload);
      if (showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'UnprocessedShipment successfully updated.',
            navigateTo: navigate || null,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

const createUnprocessedShipment = createAsyncThunk(
  'createUnprocessedShipment',
  async (data: { payload: UnprocessedShipmentCreatePayload } & SuccessErrorModalParamsType, thunkAPI) => {
    try {
      const res = await API.post(`/shipment/unprocessed?`, data.payload);

      if (data.showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Shipment successfully unprocessed.',
            navigateTo: data.navigate || null,
          }),
        );
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);
const deleteUnprocessedShipment = createAsyncThunk(
  'deleteUnprocessedShipment',
  async (
    data: {
      payload: UnprocessedShipmentDeletePayload;
      queryParams: UnprocessedShipmentListQuery;
    } & SuccessErrorModalParamsType,
    thunkAPI,
  ) => {
    try {
      const res = await API.delete(`/shipment/unprocessed?`, { params: data.payload });

      if (data.showModal)
        thunkAPI.dispatch(
          SuccessErrorModalOpen({
            type: 'success',
            title: 'Success',
            subTitle: 'Unprocessed Shipment successfully deleted.',
            navigateTo: data.navigate || null,
          }),
        );
      setTimeout(() => {
        thunkAPI.dispatch(getUnprocessedShipmentList(data.queryParams));
      }, 100);
      return res.data;
    } catch (error) {
      const errorRes = error.response.data;
      thunkAPI.dispatch(
        SuccessErrorModalOpen({
          type: 'error',
          title: 'Oops!',
          subTitle: errorRes?.msg || 'Something went to wrong.',
        }),
      );
      throw error;
    }
  },
);

export {
  deleteUnprocessedShipment,
  getUnprocessedShipmentList,
  getUnprocessedShipmentById,
  createUnprocessedShipment,
  updateUnprocessedShipmentById,
};
