import { createSlice } from '@reduxjs/toolkit';

import {
  doSignupPending,
  doSignupFulfilled,
  doSignupRejected,
  verifySignupEmailPending,
  verifySignupEmailFulfilled,
  verifySignupEmailRejected,
  resendVerificationPending,
  resendVerificationFulfilled,
  resendVerificationRejected,
  // resetSignupState,
} from './case';

import { doSignup, verifySignupEmail, resendVerification } from './actions';
import { signupStateForm } from './type';

const initialState = <signupStateForm>{
  value: {
    name: '',
    username: '',
    password: '',
    countryId: '',
    termsAndCondition: true,
  },
  user: {},
  verifyEmail: null,
  resendVerification: null,
  error: {},
  isOtp: false,
  loading: false,
};
const signupFormSlice = createSlice({
  name: 'signupform',
  initialState: { ...initialState },
  reducers: {
    resetSignupState: function (state) {
      state.value = {};
      state.verifyEmail = null;
      state.resendVerification = null;
      state.isOtp = false;
      state.user = { _userId: '', isSignedUp: false, msg: '', isError: false };
      state.error = {
        msg: '',
        isError: false,
      };
      state.loading = false;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(doSignup.pending, doSignupPending);
    addCase(doSignup.fulfilled, doSignupFulfilled);
    addCase(doSignup.rejected, doSignupRejected);

    addCase(verifySignupEmail.pending, verifySignupEmailPending);
    addCase(verifySignupEmail.fulfilled, verifySignupEmailFulfilled);
    addCase(verifySignupEmail.rejected, verifySignupEmailRejected);

    addCase(resendVerification.pending, resendVerificationPending);
    addCase(resendVerification.fulfilled, resendVerificationFulfilled);
    addCase(resendVerification.rejected, resendVerificationRejected);
  },
});

export const { resetSignupState } = signupFormSlice.actions;
export { doSignup, verifySignupEmail, resendVerification };
export default signupFormSlice.reducer;
