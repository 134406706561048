import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import SbButton from '../../components/Button';
import Loader from '../../components/Loader';
import Title from '../../components/Title';
import ExportBtn from '@Components/Button/Export';
import BackButton from '@Components/BackButton';
import { RootStateType } from '@Store';
import { useSelector } from 'react-redux';
import SquadList from './List';
const SquadManagement: React.FC = () => {
  const { pathname } = useLocation();
  const showCreateBtn = pathname === '/squad';
  const showBackButton = pathname.includes('/create') || pathname.includes('/edit');
  const [isExport, setExport] = useState(false);
  const loading = useSelector((state: RootStateType) => state.squadManagement.loading);

  return (
    <>
      <Loader loading={loading} overly>
        <Box p={2}>
          <Grid container alignItems="center">
            <Grid item xs={12} sx={{ display: 'flex', gap: '0.5rem' }}>
              {showBackButton && <BackButton link="" />}
              <Title
                title={pathname === '/squad' ? 'Squad Management' : 'Squad Onboarding'}
                subTitle={pathname === '/squad' ? 'View and edit squad members' : ''}
                removeBorder
                removePadding
                subTextSize="px_20-text"
              />
            </Grid>
            {showCreateBtn && (
              <Grid
                item
                xs={12}
                gap={{
                  xs: 'auto',
                  sm: 1.5,
                }}
                container
                justifyContent={{ sm: 'flex-end', xs: 'flex-start' }}
                mt={1}
              >
                <Grid xs={6} sm="auto">
                  <ExportBtn
                    iconSrc="/images/file_download.svg"
                    alt="Export"
                    label="Export"
                    width={28}
                    height={28}
                    onClick={() => {
                      setExport(true);
                    }}
                  />
                </Grid>

                <Grid xs={6} sm="auto">
                  <Link to="/squad/create">
                    <SbButton
                      variant="contained"
                      className="ml-auto"
                      sx={{
                        borderRadius: '8px !important',
                        p: {
                          xs: '12px !important',
                          md: 'auto',
                        },
                      }}
                      startIcon={<AddCircleIcon fontSize="small" />}
                    >
                      Create new
                    </SbButton>
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Outlet />
        </Box>
      </Loader>
      {pathname === '/squad' && <SquadList setExport={setExport} isExport={isExport} />}
    </>
  );
};

export default SquadManagement;
