import { Grid, Typography, styled } from '@mui/material';

export const Root = styled(Grid)(
  ({ theme }) => `
    .rates-row{
      margin-bottom: ${theme.spacing(3)};
    }
    .mb-input{
      margin: 0;
    }
    ${[theme.breakpoints.down('sm')]} {
      .rates-row{
        margin-bottom:0;
      }
      .mb-input{
        margin: ${theme.spacing(2)} 0;
      }
    }
  `,
);

export const Address = styled(Typography)`
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
