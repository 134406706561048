import { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
// import InsuranceSvg from './icons/InsuranceSvg';
// import GppGoodIcon from '@mui/icons-material/GppGood';
// import Inventory2Icon from '@mui/icons-material/Inventory2';
import Checkbox from '@mui/material/Checkbox';
// import styled from '@emotion/styled';
// import { Box } from '@mui/system';
// import SbButton from '../../../components/Button';
import CreateShipmentContext from '../../../context/CreateShipment';
import { createShipmentContextType, PackageType } from '../../../types/shipment';
import { useSelector } from 'react-redux';
import { RootStateType } from '@Store';
// import { defaultPackageState } from '../constants';

// const IconStyles = { color: 'var(--mui-palette-primary-main)', height: '24px', width: '24px' };
// const TotalValueBox = styled(Box)`
//   background: rgba(217, 217, 217, 0.2);
// `;

const InsurancePackaging: React.FC = (): JSX.Element => {
  const { packageData, setPackageData } = React.useContext(CreateShipmentContext) as createShipmentContextType;
  const shipmentSetting = useSelector((state: RootStateType) => state.orgSetup.shipmentSetting);

  const { insurance, packaging } = packageData;

  const disableInsurance = useMemo(
    () => !shipmentSetting?.insuranceCharge?.charge,
    [shipmentSetting?.insuranceCharge?.charge],
  );
  const disablePackaging = useMemo(
    () => !shipmentSetting?.packagingCharge?.charge,
    [shipmentSetting?.packagingCharge?.charge],
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    const name = event.target.name as 'insurance' | 'packaging';
    const packageDataTemp: PackageType = { ...packageData };
    if (['insurance', 'packaging'].includes(name)) packageDataTemp[name] = checked;
    setPackageData({ ...packageDataTemp });
  }

  // function checkDisable() {
  //   let disable = true;
  //   const boxInd = boxes.findIndex(box => box.weight === null || box.weight < 1);
  //   if (boxes.length && boxInd < 0 && totalShipmentValue) {
  //     disable = false;
  //   }
  //   return disable;
  // }

  return (
    <Grid container alignItems="center" className="pt-3" spacing={6}>
      <Grid
        container
        item
        md="auto"
        alignItems={'center'}
        className={`${disableInsurance ? 'disabled-form' : 'cursor-pointer'}`}
      >
        <Checkbox
          name="insurance"
          id="insurance"
          className="mr-1/2"
          checked={insurance}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={disableInsurance}
        />
        {/* <GppGoodIcon sx={IconStyles} /> */}
        <img src="/images/Boxes/Insurance.svg" alt="logistics" className="mr-1/2 sm-text" />
        <Typography
          component={'label'}
          htmlFor="insurance"
          className="md-text font-medium sb-text-info ml-1"
          variant="caption"
        >
          Insure Your Shipment
        </Typography>
      </Grid>

      <Grid
        container
        item
        md="auto"
        alignItems={'center'}
        className={`${disablePackaging ? 'disabled-form' : 'cursor-pointer'}`}
      >
        <Checkbox
          name="packaging"
          id="packaging"
          className="mr-1/2"
          checked={packaging}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={disablePackaging}
        />
        {/* <Box sx={IconStyles} /> */}
        <img src="/images/Boxes/Box.svg" alt="logistics" className="mr-1/2 sm-text" />
        <Typography
          component={'label'}
          htmlFor="packaging"
          className="md-text font-medium sb-text-info ml-1"
          variant="caption"
        >
          Add Packaging
        </Typography>
      </Grid>

      {/* <Grid container item xs={12}>
        <TotalValueBox className="p-3 w-full">
          <Typography className="label">Total Shipment Value(₹):</Typography>
          <Typography>{totalShipmentValue ? totalShipmentValue : '-'}</Typography>
        </TotalValueBox>
      </Grid>
      <Grid item xs={12} className="mt-2">
        <SbButton onClick={console.log} variant="contained" className="w-full" disabled={checkDisable()}>
          Get Rates
        </SbButton>
      </Grid> */}
    </Grid>
  );
};

export default InsurancePackaging;
