import { createSlice } from '@reduxjs/toolkit';
import {
  createGlobalChargeFulfilled,
  createGlobalChargePending,
  createGlobalChargeRejected,
  //
  updateGlobalChargeFulfilled,
  //
  getGlobalChargeListFulfilled,
  getGlobalChargeListPending,
  getGlobalChargeListRejected,
  //
  getGlobalChargeByIdPending,
  getGlobalChargeByIdFulfilled,
  getGlobalChargeByIdRejected,
} from './case';
import { GlobalChargeReducerState } from './Type';
import { createGlobalCharge, updateGlobalChargeById, getGlobalChargeList, getGlobalChargeById } from './actions';

const initialState = <GlobalChargeReducerState>{
  singeGlobalCharge: null,
  loading: false,
  error: {
    msg: '',
    errorDetails: '',
    isError: false,
  },
  data: undefined,
};

const reduxStateSlice = createSlice({
  name: 'global-charge',
  initialState: { ...initialState },
  reducers: {
    resetGlobalChargeRedirect: function (state) {
      state.isRedirect = false;
    },
    resetGlobalChargeError: function (state) {
      state.error = {
        msg: '',
        isError: false,
      };
    },
    resetSingleGlobalCharge: function (state) {
      state.singeGlobalCharge = null;
    },
  },
  extraReducers: _ => {
    const addCase = _.addCase;
    addCase(getGlobalChargeList.pending, getGlobalChargeListPending);
    addCase(getGlobalChargeList.fulfilled, getGlobalChargeListFulfilled);
    addCase(getGlobalChargeList.rejected, getGlobalChargeListRejected);

    addCase(getGlobalChargeById.pending, getGlobalChargeByIdPending);
    addCase(getGlobalChargeById.fulfilled, getGlobalChargeByIdFulfilled);
    addCase(getGlobalChargeById.rejected, getGlobalChargeByIdRejected);

    addCase(createGlobalCharge.pending, createGlobalChargePending);
    addCase(createGlobalCharge.fulfilled, createGlobalChargeFulfilled);
    addCase(createGlobalCharge.rejected, createGlobalChargeRejected);

    addCase(updateGlobalChargeById.pending, createGlobalChargePending);
    addCase(updateGlobalChargeById.fulfilled, updateGlobalChargeFulfilled);
    addCase(updateGlobalChargeById.rejected, createGlobalChargeRejected);
  },
});

export { getGlobalChargeList, createGlobalCharge, updateGlobalChargeById, getGlobalChargeById };
export const { resetGlobalChargeRedirect, resetGlobalChargeError, resetSingleGlobalCharge } = reduxStateSlice.actions;
export default reduxStateSlice.reducer;
