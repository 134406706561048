import Image from '@Components/Image';
import { Grid, Typography } from '@mui/material';
import React from 'react';

interface PropsType {
  align?: 'flex-start' | 'flex-end';
  color?: string;
}

function PoweredBy(props: PropsType) {
  const { align = 'flex-end' } = props;
  return (
    <Grid container justifyContent={align} mt={6}>
      <Typography variant="body2" mr={1} color={props?.color}>
        Powered By
      </Typography>
      <Image
        src="/images/speedbox_logo.png"
        alt="Speedbox"
        style={{ width: '120px', aspectRatio: 16.296296296296298 }}
      />
    </Grid>
  );
}

export default PoweredBy;
